<div class="cannot-move-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div class="cannot-move-modal-body">
      <div>{{ description }}</div>
      <div>
        <a class="btn btn-link mission-control-link" href="/#/category/One-to-One_Campaigns/mission-control" target="_blank">
          <span>
            {{ "features.mission_control.eligibility_and_exclusion_modal.explore_campaigns_tooltip" | translate }}
          </span>
          <i class="material-icons">launch</i>
        </a>
      </div>
    </div>
  </opti-logic-modal>
</div>
