import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import { Brand, TemplatesMetadata } from '../models/metadataResponse';
import { LocalStorageCacheService } from './localStorageCache.service';

@Injectable({
	providedIn: 'root'
})
export class BrandService {
	private baseUrl: string = '/ManageTemplates';

	private getMetadataUrl: string = `${this.baseUrl}/GetMetaData`;

	private brandsByChannelId$: BehaviorSubject<Brand[]> = new BehaviorSubject<Brand[]>([]);
	brandsByChannelId: Observable<Brand[]> = this.brandsByChannelId$.asObservable();

	constructor(private cache: LocalStorageCacheService, private readonly httpService: HttpService) {}

	updateBrandsByChannelId(channelId: number): Observable<Brand[]> {
		return new Observable((observer: Subscriber<Brand[]>) => {
			this.cache
				.getOrCreate(this.getMetadataUrl, this.httpService.get<AjaxResponse<TemplatesMetadata>>(this.getMetadataUrl))
				.pipe(map((res) => this.extractData(res)))
				.subscribe((metadata) => {
					const channelIds = Object.keys(metadata.channels);
					const channels = channelIds.map((id) => metadata.channels[id]);
					const filteredChannel = channels.find((channel) => channel.id == channelId);
					this.brandsByChannelId$.next(filteredChannel.brands);
					observer.next(filteredChannel.brands);
					observer.complete();
				});
		});
	}

	getBrandId(brandName: string, subMethodId: number): string | undefined {
		const brand = this.brandsByChannelId$.value.find((b) => b.name === brandName);
		if (!brand) {
			return;
		}
		return brand.subMethods.find((sm) => sm.subMethodId === subMethodId)?.executionMethodInnerID;
	}

	private extractData<T>(response?: AjaxResponse<T>) {
		if (response?.isSuccess === false) {
			return response;
		}
		if (response?.data === null && response?.isSuccess) {
			return response;
		}
		if (response?.data !== undefined) {
			return response.data;
		}
		if ((response as any)?.Data !== undefined) {
			return (response as any).Data;
		}
		return response;
	}
}
