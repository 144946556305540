import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { TemplateDetails } from "../../manageTemplates/models/templateDetails";
import { ManageTemplatesService } from "../../manageTemplates/services/manageTemplates.service";
import { TemplateContextService } from "../../manageTemplates/services/templateContext.service";

@Injectable()
export class Customer360TemplateService {

    OPEN_TAG_BRACKET = '[%';
	CLOSE_TAG_BRACKET = '%]';
	VISITOR_TAGS_GROUPS = ['General', 'Visitor Data', 'Realtime data', 'Content Functions', 'Content Managment', 'Activity Data'];
	VISITOR_TAGS_ALLOWED_PREFIXES = ['', 'UPPER:', 'LOWER:', 'TITLE_CASE:'];

	constructor(
        private manageTemplatesService: ManageTemplatesService
    ) {}

    public doesTemplateContainNonVisitorTags(templateDetails: TemplateDetails): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.manageTemplatesService.getInfoForSendTestAndPreview(`${templateDetails.executionMethodId}`)
			.pipe(filter((sendAndPreviewInfo) => !!sendAndPreviewInfo))
			.pipe(
				switchMap((sendAndPreviewInfo) => {
					if (
						sendAndPreviewInfo?.SubMethodTypesThatSupportVisitors &&
						sendAndPreviewInfo.SubMethodTypesThatSupportVisitors.includes(templateDetails.subMethodType)
					) {
						return this.manageTemplatesService.getAggregatedPersonalizationTagsByBrandType(templateDetails.subMethodType);
					} else {
						resolve(false);
					}
					return of(null);
				})
			)
			.subscribe((personalizationTags) => {
				if (personalizationTags) {
					const tags = this.getPersonalizationTagsOslObject(personalizationTags);
					resolve(this.checkForNonVisitorTags(templateDetails, tags));
				}
			});
        })
    }

    private checkForNonVisitorTags(template: TemplateDetails, personalizationTags): boolean {
		let containsNonVisitorTags = false;
		const tagsAllowedForVisitors = this.getTagsAllowedForVisitors(personalizationTags);

		if (template && (typeof template === 'string' || template instanceof String)) {
			containsNonVisitorTags = this.doesStrContainNonVisitorTags(template, tagsAllowedForVisitors);
        }
        
		return containsNonVisitorTags;
	}

    private doesStrContainNonVisitorTags(str, tagsAllowedForvisitors): boolean {
		let containsNonVisitorTags = false;
		if (!(typeof str === 'string' || str instanceof String)) {
			return containsNonVisitorTags;
		}
		let strLeft = str;
		let closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		while (closeBracketIndex >= 0 && !containsNonVisitorTags) {
			let openBracketIndex = strLeft.substring(0, closeBracketIndex).lastIndexOf(this.OPEN_TAG_BRACKET);
			if (openBracketIndex >= 0) {
				let curAtomicTag = strLeft.substring(openBracketIndex + this.OPEN_TAG_BRACKET.length, closeBracketIndex);
				curAtomicTag = curAtomicTag.replace(/\s/g, '');
				if (curAtomicTag.startsWith('IF:')) {
					curAtomicTag = curAtomicTag.substring('IF:'.length);
				} else if (curAtomicTag.startsWith('ELSEIF:')) {
					curAtomicTag = curAtomicTag.substring('ELSEIF:'.length);
				}
				const operatorIndex = this.getFirstOperatorIndex(curAtomicTag);
				if (operatorIndex >= 0) {
					curAtomicTag = curAtomicTag.substring(0, operatorIndex);
				}

				if (curAtomicTag) {
					if (
						!(
							curAtomicTag.startsWith('CURRENT_TIME:') ||
							curAtomicTag.startsWith('CURRENT_DATE:') ||
							curAtomicTag.startsWith('TOMORROW_DATE:') ||
							curAtomicTag == 'ELSE' ||
							curAtomicTag == 'END:IF'
						)
					) {
						containsNonVisitorTags = !tagsAllowedForvisitors.some((t) => curAtomicTag.endsWith(t));
					}
				}
			}

			strLeft = strLeft.substring(closeBracketIndex + this.CLOSE_TAG_BRACKET.length);
			closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		}

		return containsNonVisitorTags;
	}

    private getFirstOperatorIndex(tag) {
		const operatorsIndices = [tag.indexOf('<'), tag.indexOf('>'), tag.indexOf('='), tag.indexOf('.')];
		const existingOperatorsIndices = operatorsIndices.filter((i) => i >= 0);
		if (!existingOperatorsIndices || existingOperatorsIndices.length == 0) {
			return -1;
		} else {
			return Math.min(...existingOperatorsIndices);
		}
	}

    private getTagsAllowedForVisitors(personalizationTags): any {
		const filtered = personalizationTags.items.filter((t) => this.VISITOR_TAGS_GROUPS.indexOf(t.categoryId) !== -1);
		var tagsAllowerdForVisitors = new Set(
			filtered.map((t) => t.value.split(this.OPEN_TAG_BRACKET).join('').split(this.CLOSE_TAG_BRACKET).join(''))
		);
		tagsAllowerdForVisitors.add('EMAIL');
		var tagsWithPossiblePrefixes = [];
		this.VISITOR_TAGS_ALLOWED_PREFIXES.forEach((prefix) => {
			tagsAllowerdForVisitors.forEach((tag) => {
				tagsWithPossiblePrefixes.push(`${prefix}${tag}`);
			});
		});
		return tagsWithPossiblePrefixes;
	}

    private getPersonalizationTagsOslObject(tags) {
		if (!tags) return {};

		let config = this.getPersonalizationTagsOslObjectConfig();
		let items = this.getPersonalizationTagsOslObjectItems(tags);
		return { config, items };
	}

	private getPersonalizationTagsOslObjectConfig() {
		return {
			keyProperty: 'tag',
			valueProperty: 'displayName',
			isMultiSelect: false,
			itemNameTranslateKey: 'em_json_text_not_required',
			groupKeyProperty: 'categoryId',
			groupValueProperty: 'categoryName',
			shouldIgnoreSorting: true,
		};
	}

	private getPersonalizationTagsOslObjectItems(tags) {
		let items = [];
		if (Object.keys(tags).length === 0) {
			return items;
		}

		tags.forEach((category) => {
			category.PersonalizationTags.forEach((tag) => {
				items.push({
					tag: category.Name + '/' + tag.value,
					displayName: tag.value.slice(2, -2),
					value: tag.value,
					categoryId: category.Name,
					categoryName: category.Name,
				});
			});
		});
		return items;
	}
}