import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewEncapsulation, SimpleChanges } from "@angular/core";
import './optiSearchListDropDown.component.scss';
import { SearchItem } from "../optiSearchList/optiSearchListComponent/optiSearchList.component";
import { IOptiSearchListDropDownButtonConfig, } from "./models/optiSearchListDropDownButtonConfig.model";
import { IOptiSearchListDropDownSearchListConfig } from "./models/optiSearchListDropDownSearchListConfig.model";
import { OptiSearchListDropDownAlignHorizontal } from "./models/optiSearchListDropDownAlignHorizontal.enum";
import { OptiSearchListDropDownAlignVertical } from "./models/optiSearchListDropDownAlignVertical.enum";
import { OslValueType } from '../optiSearchList/models/oslValueType.enum';


@Component({
    selector: 'opti-search-list-drop-down',
    templateUrl: './optiSearchListDropDown.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./optiSearchListDropDown.component.scss']
})
export class OptiSearchListDropDownComponent implements OnInit, OnChanges {
    @Input() button: IOptiSearchListDropDownButtonConfig;
    @Input() searchList: IOptiSearchListDropDownSearchListConfig;
    @Input() align: OptiSearchListDropDownAlignHorizontal = OptiSearchListDropDownAlignHorizontal.Left;
    @Input() vAlign: OptiSearchListDropDownAlignVertical = OptiSearchListDropDownAlignVertical.Down;
    @Input() set dynamicLabelEnabled(value: boolean) {
        this._dynamicLabelEnabled = value;
        this.defineButtonClasses();
    }
    @Input() isSearchEnabled: boolean = true;
    @Output() select: EventEmitter<SearchItem> = new EventEmitter();

    public alignHorizontalTypes = OptiSearchListDropDownAlignHorizontal;
    public alignVerticalTypes = OptiSearchListDropDownAlignVertical;
    public dynamicClasses: string;

    private _dynamicLabelEnabled: boolean = false;

    constructor(private eRef: ElementRef) {
    }

    @HostListener('document:click', ['$event']) clickOutSide(event) {
        if (!this.isSearchListVisible) return;

        if (!this.eRef.nativeElement.contains(event.target)) {
            this.closeSearchList();
        }
    }

    public ngOnInit(): void {
        this.setDropDownConfig(this.searchList, this.vAlign);
        this.defineButtonClasses();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.searchList) {
            this.setDropDownConfig(this.searchList, this.vAlign);
        }
    }

    private setDropDownConfig(searchList: IOptiSearchListDropDownSearchListConfig, vAlign: OptiSearchListDropDownAlignVertical) {
        if (searchList && searchList.config) {
            if (vAlign === OptiSearchListDropDownAlignVertical.Up) {
                searchList.config.isDropDownDirectionUp = true;
            }
            searchList.config.isExternalDropDownMode = true;
        }
    }

    public isSearchListVisible = false;

    public toggleSearchList() {
        if (this.isSearchListVisible) {
            this.closeSearchList();
        } else {
            this.isSearchListVisible = true;
        }
    }

    public onSelectionChange(event) {
        this.closeSearchList();
        if (event && event.length > 0) {
            this.select.emit(event[0]);
        } else {
            this.select.emit(event);
        }

        if (this._dynamicLabelEnabled) {
            if (this.searchList.config.valueType == OslValueType.Object) {
                this.button.label = event[this.searchList.config.valueProperty];
            } else if (this.searchList.config.valueType == OslValueType.Key) {
                this.button.label = event;
            }
        }
    }

    public onSelectedListHide(){
        this.closeSearchList();
    }

    private closeSearchList() {
        this.isSearchListVisible = false;
    }

    private defineButtonClasses(){
        if(!this._dynamicLabelEnabled) {
            this.dynamicClasses = 'btn btn-secondary dropdown-btn';
        }
        else{
            this.dynamicClasses = 'dropdown-btn-dynamic-label dropdown-btn-open';
        }
    }
}
