import {Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, ViewEncapsulation, Input} from '@angular/core';

@Component({
    selector: 'overflow-menu-item',
    templateUrl: 'overflowMenuItem.component.html',
    styleUrls: ['./overflowMenuItem.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OverflowMenuItemComponent implements OnInit {
    @Input() hasSubMenu: boolean;

    constructor() { }

    ngOnInit() { }
}