import {NgModule} from "@angular/core";
import {SettingsFooterComponent} from "./settingsFooter/settingsFooter.component";
import {ComponentsModule} from "../../../components/components.module";
import {CommonModule} from "@angular/common";
import {EditDeleteCellRendererComponent} from "./editDeleteCellRenderer/editDeleteCellRenderer.component";
import {SettingsSharedService} from "./settingsShared.service";

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule
    ],
    exports: [
        SettingsFooterComponent,
        EditDeleteCellRendererComponent
    ],
    declarations: [
        SettingsFooterComponent,
        EditDeleteCellRendererComponent
    ],
    providers: [
        SettingsSharedService
    ]
})
export class SettingsSharedModule{}