<div class="new-template-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div class="row justify-content-md-center">
      <div class="col col-md-6">
        <div class="beefree-editor editor-box" (click)="selectEditor('beefree')" data-qa-id="visual-editor">
          <h2>
            {{ "features.manage_templates.newTemplate.DRAG_EDITOR_TITLE" | translate }}
          </h2>
          <div class="icon-drag-and-drop"></div>
          <p class="description">
            {{ "features.manage_templates.newTemplate.DRAG_EDITOR_DESC" | translate }}
          </p>
        </div>
      </div>
      <div class="col col-md-6">
        <div class="froala-editor editor-box" (click)="selectEditor('froala')" data-qa-id="html-editor">
          <h2>
            {{ "features.manage_templates.newTemplate.HTML_EDITOR_TITLE" | translate }}
          </h2>
          <div class="icon material-icons code-icon">code</div>
          <p class="description">
            {{ "features.manage_templates.newTemplate.HTML_EDITOR_DESC" | translate }}
          </p>
        </div>
      </div>
    </div>
  </opti-logic-modal>
</div>
