<div class="templates-preview-carousel-container">
    <div class="template-preview-container" *ngIf="showTemplatesPreview">
        <ng-container *ngIf="!isLoading">
            <button *ngIf="templatesPreviewData[currentTemplateIndex].isExists" class="image-preview btn-link" (click)="onShowTemplatesPreviewCarouselModal()">
                <div class="hover-preview"></div>
                <div class="hover-preview-text">{{'components.templatePreview.TEMPLATE_PREVIEW' | translate}}</div>
                <figure>
                    <img [src]="templatesPreviewData[currentTemplateIndex].url" />
                </figure>
            </button>
    
            <template-not-available *ngIf="!templatesPreviewData[currentTemplateIndex].isExists" [isLargeSize]="false"></template-not-available>
        </ng-container>
    
        <ng-container *ngIf="isLoading">
            <div class="loader-wrapper">
                <span class="loader" *ngIf="showLoader"></span>
            </div>
        </ng-container>
    </div>

    <div class="multi-templates-browser" *ngIf="templatesLength > 1">
        <div class="templates-browsing">
            <button class="btn-link" (click)="showPrevTemplate()">
                <i class="material-icons">chevron_left</i>
            </button> 
            <span>{{'components.templatePreview.BROWSE_STATE' | translate: {currentTemplateIndex: currentTemplateIndex + 1, templatesLength: templatesLength} }}</span>
            <button class="btn-link" (click)="showNextTemplate()">
                <i class="material-icons">chevron_right</i>
            </button>
        </div>

        <button class="btn-link btn-show-all" (click)="showTemplatesGridView()" *ngIf="!isLoading && showTemplatesPreview">
            {{'components.templatePreview.SHOW_ALL' | translate }}
        </button>
    </div>
</div>