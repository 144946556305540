import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { OslValueType } from '../../../../components/optiSearchList/models/oslValueType.enum';
import { SearchListConfig } from '../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { selectedOption, subMethods } from './copyTemplate.model';
import { CopyTemplateModalService } from './copyTemplateModal.service';

@Component({
	selector: 'copy-template-modal',
	templateUrl: './copyTemplateModal.component.html',
	styleUrls: [
		'./copyTemplateModal.component.scss',
		'../../../../components/optiLogicModal/modals/successFailModal/successFailModal.component.scss'
	]
})
export class CopyTemplateModalComponent implements OnInit {
	@Input() channelId: number;
	@Input() brands: any[];
	@Input() template: any;

	public selectedOption: selectedOption = {
		brand: false,
		subMethodId: false
	};
	subMethods: subMethods[];
	modalConf: IModalConf;
	isSuccess: boolean = false;
	subMethodsDisabled: boolean = true;

	public brandSearchConfig: SearchListConfig = {
		keyProperty: 'name',
		valueProperty: 'name',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	public typeSearchConfig: SearchListConfig = {
		keyProperty: 'id',
		valueProperty: 'name',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private copyTemplateModalService: CopyTemplateModalService
	) {}
	ngOnInit() {
		this.setModalConf();
	}

	public brandsChannelChanged(event: string): void {
		this.selectedOption.brand = event;
		this.selectedOption.subMethodId = false;
		this.subMethodsDisabled = false;

		const subMethods: any[] = this.brands.filter((i) => i.name == event);
		if (subMethods.length) {
			this.subMethods = subMethods[0]['subMethods'].map((method: subMethods) => {
				return {
					id: method.subMethodId,
					name: method.name
				};
			});
			if (this.subMethods.length == 1) {
				this.typeChanged(this.subMethods[0].id);
			}
		}
	}

	public typeChanged(event): void {
		this.selectedOption.subMethodId = event;
	}

	private saveTemplate() {
		this.copyTemplateModalService
			.copyTemplate(this.template.id, this.channelId, this.selectedOption.subMethodId)
			.subscribe((data: boolean) => {
				this.isSuccess = true;
				this.modalConf.hideHeaderPanel = true;
				this.modalConf.hideFooterPanel = true;

				setTimeout(() => {
					this.bsModalRef.hide();
				}, 1500);
			});
	}

	setModalConf(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.copy_template_modal.TITLE'),
			buttons: [
				{
					isDisabled: () => {
						return !this.selectedOption.brand || !this.selectedOption.subMethodId;
					},
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.components.copy_template_modal.OK_BTN'),
					action: () => {
						this.saveTemplate();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.copy_template_modal.CANCEL_BTN'),
					action: () => {
						this.bsModalRef.hide();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
}
