<div class="calculated-attributes">
    <div class="panel-heading" >
        <div class="header">
            <div class="back-button">
                <div class="btn btn-link" (click)="openConfirmModal()">
                    <i class="material-icons">arrow_back</i>
                    <span translate>{{'general.BACK' | translate}}</span>
                </div>
            </div>
            <div *ngIf="editMode !== 'create'" class="published-title">
                <h2 *ngIf="editMode !== 'create'"> {{translateKeys.editTitle | translate}}</h2>
                <colored-tag [coloredTagType]="attributeStatus.color" style="padding: 7px;">
                    {{ attributeStatus.description }}
                </colored-tag>
            </div>
            <div *ngIf="editMode === 'create'">
                <h2> {{ translateKeys.title | translate }} </h2>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <form [formGroup]="addAttributeForm" class="form-container">
            <div class="form-group" style="display: flex;">
                <div>
                    <div class="row">
                        <div class="label-column">
                            {{translateKeys.displayName | translate}}
                        </div>
                        <div>
                            <input 
                                [ngClass]="{'errorBorder': displayNameError}"
                                class="form-control value-column"
                                type="text" 
                                [placeholder]="translateKeys.placeholder | translate"
                                formControlName="displayName" />
                            <p  *ngIf="displayNameError"
                                class="inputs-validations error-msg">
                                {{translateKeys.errorAliasFieldName | translate}}
                            </p>
                            <span data-qa-id="attribute-name-error-msg" *ngIf="displayNameFormControl.errors?.specialSymbols" class="inputs-validations error-msg" >
                                {{translateKeys.specialSymbolsErrorAliasFieldName | translate }}
                              </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="label-column">
                            {{translateKeys.description | translate}}
                        </div>
                        <div>
                            <textarea  
                                class="form-control value-column"
                                type="text"
                                formControlName="description" 
                                placeholder="(Optional)">
                            </textarea>            
                        </div>
                    </div>
                </div>
                <attribute-settings
                    class="attribute-settings"
                    (chengePersonalizationEvent)="changePersonalization($event)" 
                    (changeAttributeVisibilityEvent)="changeAttributeVisibility()"
                    [isPersonalization]="attribute.IsPersonalization"
                    [showAttribute]="!attribute.IsHidden"
                    [disableShowHideCheckbox]="!canAttributeBeHidden"
                    *ngIf="isPublished()">
                </attribute-settings>
            </div>

            <div class="form-group">
                <div class="subtitle row">
                    <h3 class="label-column">
                        {{translateKeys.attributeCalculation | translate}}
                    </h3>
                </div>
                <div class="row">
                    <div class="label-column">
                        {{translateKeys.function | translate}}
                    </div>
                    <div class="value-column">
                        <opti-search-list 
                            formControlName="function"
                            [items]="functions"
                            [config]="dropdownOslConfig"
                            [isDefaultDisabled]="editMode === 'edit'">
                        </opti-search-list>
                    </div>
                </div>
                <div class="row">
                    <div class="label-column">
                        {{translateKeys.activity | translate}}
                    </div>
                    <div class="value-column">
                        <opti-search-list 
                            formControlName="activity"
                            [items]="activities"
                            [config]="dropdownOslConfig"
                            [isDefaultDisabled]="editMode === 'edit'">
                        </opti-search-list>
                    </div>
                </div>
                <div class="row">
                    <div class="label-column">
                        {{translateKeys.timeframe | translate}}
                    </div>
                    <div class="value-column">
                        <opti-search-list 
                            formControlName="timeframe"
                            [items]="timeframes"
                            [config]="dropdownOslConfig"
                            [isDefaultDisabled]="editMode === 'edit'">
                        </opti-search-list>
                    </div>
                    <div class="timeframe-interval-column" 
                        *ngIf="showTimeframeIntervalInput">
                        <input 
                            data-qa-id="timeframe-interval"
                            formControlName="timeframeInterval"
                            type="number" 
                            [min]="1" 
                            [max]="730"
                            [ngClass]="{'errorBorder': timeframeIntervalFormControl.invalid && timeframeIntervalFormControl.touched}"
                            (keypress)="isInputEventIsRegularNumber($event)"/>
                            
                        <div *ngIf="timeframeFormControl.value[0].key === '1,,1'" class="label-days">
                            {{translateKeys.days | translate}}
                        </div>
                        <div *ngIf="timeframeFormControl.value[0].key === '2,,1'" class="label-days">
                            {{translateKeys.daysAgo | translate}}
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="last-modified-stamp">
        <label class="small">{{lastUpdate}}</label>
    </div>
    <div class="controls col-md-6 pull-right text-right">
        <ul class="list-inline">
            <li>
                <div class="btn btn-default" (click)="openConfirmModal()">
                    <span>Cancel</span>
                </div>
            </li>
            <li>
                <loading-button
                        [showLoader]="isSaving"
                        (onClick)="attribute.Name ? updateAttributeClick() : saveAttributeClick()"
                        [isDisabled]="(!addAttributeForm.valid || !addAttributeForm.dirty) && !IsPersonalizationCheckBoxChecked && !isAttributeVisibilityChanged"
                        [buttonCssClass]="'btn btn-primary'"
                        [loadingCssClass]="'saving-loader'"
                        [loadingText]="editMode === 'create' ? 'Saving' : 'Updating'"
                        [buttonText]="editMode === 'create' ? 'Save' : 'Update'">
                </loading-button>
            </li>
        </ul>
    </div>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="isSaving"></div>
