<div class="panel-body">
  <div class="title-panel">
    <label class="title-label">
      {{ "features.manage_templates.optimail.translationPanel.SET_DEFAULT_LANGUAGE" | translate }}
    </label>
    <button class="btn button-cross" (click)="close()">
      <i class="cross-icon material-icons">close</i>
    </button>
  </div>
  <div>
    <label class="title-description">
      {{ "features.manage_templates.optimail.translationPanel.SET_DEFAULT_LANGUAGE_DESCRIPTION" | translate }}
    </label>
  </div>
  <div class="separator"></div>
  <div class="content-container">
    <div class="radio radio-primary radio-label-top">
      <input
        id="fallbackOptionNo"
        name="selectedOptionRadios"
        class="form-control"
        type="radio"
        [(ngModel)]="selectedOption"
        [value]="FallbackOption.NoFallback" />
      <label for="fallbackOptionNo" class="radio-label">
        {{ "features.manage_templates.optimail.translationPanel.NO_FALLBACK_OPTION" | translate }}
      </label>
    </div>
    <div class="radio radio-primary">
      <input
        id="fallbackOptionLanguage"
        name="selectedOptionRadios"
        class="form-control"
        type="radio"
        [(ngModel)]="selectedOption"
        [value]="FallbackOption.FallbackLanguage" />
      <label for="fallbackOptionLanguage" class="radio-label">
        {{ "features.manage_templates.optimail.translationPanel.LANGUAGE_FALLBACK_OPTION" | translate }}
      </label>
    </div>
    <div>
      <label class="radio-label-description">
        {{ "features.manage_templates.optimail.translationPanel.FALLBACK_OPTION_DESCRIPTION" | translate }}
      </label>
    </div>
    <div class="languages-select-container">
      <opti-select
        [items]="listOfLanguages"
        [(ngModel)]="fallbackLanguageId"
        [placeholder]="'features.manage_templates.optimail.defaultLanguageModal.SELECT_LANGUAGE' | translate"
        [isDisabled]="selectedOption !== FallbackOption.FallbackLanguage"></opti-select>
    </div>
  </div>
  <div class="separator"></div>
  <ul class="list-inline action-buttons pull-right action-buttons">
    <li class="col-md-6">
      <button (click)="close()" type="button" class="btn btn-default cancel-button">{{ "general.CANCEL" | translate }}</button>
    </li>
    <li class="col-md-6">
      <button
        (click)="save()"
        type="button"
        class="btn btn-primary"
        [disabled]="selectedOption === FallbackOption.FallbackLanguage && !fallbackLanguageId"
        >{{ "general.SAVE" | translate }}</button
      >
    </li>
  </ul>
</div>
