import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    SearchItem,
    SearchListConfig
} from "../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { Observable, Subscription, timer } from "rxjs";
import { RealtimeProfileDataManageService } from "../../realtimeProfileDataManage.service";
import { SsmService } from "../../../../../services/ssm.service";
import {
    Attribute,
    TableAttribute,
    AttributeBasedOnTypes,
    AttributeEvent,
    AttributeOption,
    Formats,
    Formulas,
    FormulasDic,
    Operators,
    ParamTypes
} from "../../models/profileDetails.model";
import { OptiSearchListDropDownAlignHorizontal } from "../../../../../components/optiSearchListDropDown/models/optiSearchListDropDownAlignHorizontal.enum";
import { Router } from "@angular/router";
import {
    IModalButtonConf,
    IModalConf,
    OptiLogicModalService
} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { first, map } from "rxjs/operators";
import { Attribute as RealtimeAttribute } from "../../models/profileDetails.model";
import { cannotStartWithNumbersValidator } from "../../../../../directives/customValidation/cannotStartWithNumbersValidation.directive";
import { AttributesManagerService } from "../../../attributes/services/attributesManager.service";


@Component({
    selector: 'realtime-profile-add-attribute',
    templateUrl: './realtimeProfileAddAttribute.component.html',
    styleUrls: ['./realtimeProfileAddAttribute.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class RealtimeProfileAddAttributeComponent implements OnInit, OnDestroy {

    public addAttributeForm: FormGroup;
    public translateKeys = translateKeys;
    public attributeBasedOnTypes = AttributeBasedOnTypes;
    public existingAttributes: SearchItem[];
    public formats: SearchItem[];
    public formulas: SearchItem[];
    public events: SearchItem[];
    public eventParams: SearchItem[];
    public eventParamsLatestValue: SearchItem[];
    public eventParamsRelatingValues: SearchItem[];
    public operatorsSearchItems: SearchItem[];
    public showAddParameterButton: boolean = true;
    public isSpecialOperatorChosen: boolean = false;
    public newAttrDbFieldName: string;
    public isSaving = false;
    public newAttrDbFieldNameDesc = "Display name: ";
    public formulasDic = FormulasDic;
    public formulasEnum = Formulas;
    public latestValueConfig;
    public optiSearchListDropDownAlignHorizontal = OptiSearchListDropDownAlignHorizontal;
    public latestValueRelatingSearchList = false;
    public newAttrNameInvalidChar = '';
    public editMode = false;
    public loading = false;
    public attribute: Attribute;
    public initialValue: Attribute;
    public isDefinedEventParamValue = false;

    public selectionConfig: SearchListConfig = {
        keyProperty: "key",
        valueProperty: "value",
        isMultiSelect: false,
        itemNameTranslateKey: translateKeys.calculate.event
    };
    public existingAttributesOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        placeholderTranslateKey: translateKeys.attributeBasedOn.selectExistingAttr,
        itemNameTranslateKey: translateKeys.calculate.event
    };
    public formatsOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        placeholderTranslateKey: translateKeys.attributeBasedOn.selectFormat,
        itemNameTranslateKey: translateKeys.calculate.event
    };
    public formulasOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        itemNameTranslateKey: translateKeys.attributeBasedOn.label,
    };
    public eventsOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        placeholderTranslateKey: translateKeys.calculate.event,
        itemNameTranslateKey: translateKeys.calculate.event,
    };
    public eventParamsOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        placeholderTranslateKey: translateKeys.calculate.eventParameter,
        itemNameTranslateKey: translateKeys.calculate.eventParameter,
    };
    public operatorsOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        placeholderTranslateKey: translateKeys.calculate.operator,
        itemNameTranslateKey: translateKeys.calculate.operator,
    };

    public latestValueEventParamsOslConfig: SearchListConfig = {
        ...this.selectionConfig,
        itemNameTranslateKey: translateKeys.calculate.eventParameter,
    };


    private ssm: any;
    private eventsWithParamsData: any;
    private onEventParamChangeSubscription: Subscription;
    private onEventChangeSubscription: Subscription;
    private onRadioButtonsBasedOnChange: Subscription;
    private conditionEventParamType;
    private lastExistingAttributeSelected: any;
    private lastNewAttributeDisplayName: string;
    private subscriptions: Subscription[] = [];

    constructor(private formBuilder: FormBuilder,
                private realtimeProfileDataManageService: RealtimeProfileDataManageService,
                private attributeService: AttributesManagerService,
                private ssmService: SsmService,
                private router: Router,
                private modalService: OptiLogicModalService,
                private translate: TranslateService,
                private bsModalRef: BsModalRef,
                @Inject('manageTargetGroupsDataService') private tgService: any) {
        this.ssm = ssmService.getSSM();
    }

    ngOnDestroy(): void {
        if (this.onRadioButtonsBasedOnChange)
            this.onRadioButtonsBasedOnChange.unsubscribe();

        if (this.onEventChangeSubscription)
            this.onEventChangeSubscription.unsubscribe();

        if (this.onEventParamChangeSubscription)
            this.onEventParamChangeSubscription.unsubscribe();

        this.subscriptions.forEach(x => x.unsubscribe());
    }

    ngOnInit() {
        this.createForm();
        this.checkExistedAttribute();
        this.setAttributes();
        this.setEvents();
        this.setFormulas();
        this.setFormats();
        this.observeOnEventChanged();
        this.observeOnRadioButtonsBasedOnChange();
        this.setCombinedRadioButtonChecked();
        this.setFormulaDefaultValue();
        this.initLatestValueDD();
    }

    private checkExistedAttribute() {
        this.initialValue = this.router.getCurrentNavigation().extras.state as TableAttribute;
        if (this.initialValue) {
            this.editMode = true;
            this.loading = true;
        }
    }

    private setMode() {
        if (this.initialValue) {
            const option: AttributeOption = this.initialValue.status === "Published" ? "Publish" : "Save";
            this.attribute = this.realtimeProfileDataManageService
                .getSingleSubscription(this.initialValue?.displayName, this.initialValue.status);
            this.setAttrForEditing();
        }
    }

    private initLatestValueDD() {
        this.latestValueConfig = {items: this.eventParamsLatestValue, config: this.latestValueEventParamsOslConfig};
    }

    private setConfigAndDataForLatestValueDD() {
        const canUseEventDate = 
            (this.isCombinedOptionChosen() && this.existingAttributesFormControl.value[0]?.attributeType?.toLowerCase() === 'date')
            || (!this.isCombinedOptionChosen() && this.formatsFormControl.value[0].key === 'date');
        if (canUseEventDate) {
            this.eventParamsLatestValue = [{key: 'event_date', value: 'event_date'} as SearchItem];
            this.constValueOREventParamFormControl.setValue('event_date', {
                emitEvent: true,
                emitModelToViewChange: true
            });
        } else {
            this.updateEventParamsLatestValue();
            if (this.constValueOREventParamFormControl.value === 'event_date') {
                this.constValueOREventParamFormControl.setValue('', {
                    emitEvent: true,
                    emitModelToViewChange: true
                });
            }
        } 
        this.latestValueConfig = {items: this.eventParamsLatestValue, config: this.latestValueEventParamsOslConfig};
    }

    private setFormats() {
        this.formats = Object.keys(Formats).filter(key => !isNaN(Number(Formats[key]))).map((format) => {
            return {
                key: format,
                value: format
            } as SearchItem
        });
    }

    private setFormulas() {
        this.formulas = Object.keys(FormulasDic).map((formulaKey) => {
            return {
                key: formulaKey,
                value: FormulasDic[formulaKey]
            } as SearchItem
        });
    }

    private setAttributes() {
        let attributesDic = this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes);
        this.existingAttributes = Object.keys(attributesDic)
        .filter(key => key !== "" 
            && !attributesDic[key]?.RealFieldName?.includes("_RT")
            && !attributesDic[key]?.RealFieldName?.includes("VF_CA_FB_")
            && !attributesDic[key]?.RealFieldName?.includes("IF_CA_AH_"))
        .map((attributeFieldKey) => {
            return {
                key: attributeFieldKey,
                value: attributesDic[attributeFieldKey].Alias,
                realFieldName: attributesDic[attributeFieldKey].RealFieldName,
                attributeType: attributesDic[attributeFieldKey].FieldType
            } as SearchItem
        });
    }

    private setEvents() {
        this.realtimeProfileDataManageService.getAllEventsWithParameters().subscribe((res) => {
            this.eventsWithParamsData = res;
            this.events = Object.keys(res).map(key => {
                return {
                    key: key,
                    value: res[key].EventName
                } as SearchItem
            });
            this.setMode();
        });
    }

    private updateEventParamsLatestValue() {
        //clone array
        this.eventParamsLatestValue = this.eventParams?.slice(0) ?? [];
    }

    private setEventParams(eventKey: string) {
        let eventParamsObj = this.eventsWithParamsData[eventKey].EventParameters;
        this.eventParams = Object.keys(eventParamsObj)
            .filter((eventParamKey) => {
                return !eventParamsObj[eventParamKey].IsDeleted
            })
            .map((eventParamKey) => {
                return {
                    key: eventParamKey,
                    value: eventParamsObj[eventParamKey].Name
                } as SearchItem
            });
    }

    private setOperators(paramType: number) {
        this.operatorsSearchItems = Operators[ParamTypes[paramType]].map(operator => {
            return {
                key: operator.key,
                value: operator.value
            } as SearchItem
        });
    }

    private createForm() {
        this.addAttributeForm = this.formBuilder.group({
            attributeBasedOn: '',
            existingAttributes: ['', Validators.required],
            newAttrName: '',
            formats: '',
            formulas: ['', Validators.required],
            events: ['', Validators.required],
            eventParamContext: '',
            eventParamsCondition: '',
            operators: '',
            eventParamRelatingValue: '',
            constValueOREventParam: ''
        });
    }

    public onLatestValueEventsParams(event) {
        this.latestValueRelatingSearchList = true;
        this.constValueOREventParamFormControl.setValue(event.value);
        this.constValueOREventParamFormControl.markAsDirty();
    }

    onLatestValueConstantChanged(event: Event) {
        this.latestValueRelatingSearchList = false;
        const input = event.target as HTMLInputElement;

        if (!this.eventParamsLatestValue.find(item => item.value === input.value)) {
            this.constValueOREventParamFormControl.setValue("", {
                emitEvent: true,
                emitModelToViewChange: true
            });
        }
    }

    public onEventParamChanged(item: any) {
        const selectedParam = this.eventParamsConditionFormControl.value[0];
        const selectedEvent = this.eventsFormControl.value[0];
        const event = this.eventsWithParamsData[selectedEvent.key];
        this.conditionEventParamType = event.EventParameters[selectedParam.key].Type;
        if (ParamTypes[this.conditionEventParamType] == null){
            this.conditionEventParamType = ParamTypes.text;
        }
        this.setOperators(this.conditionEventParamType);
        this.setAttributeRelatingValues(event.Id, event.EventParameters[selectedParam.key].Id);
    }

    private setAttributeRelatingValues(eventId: string, attributeIds: string, initialValue: string = null) {
        this.tgService.getOptitrackSDKAttributeParameters(eventId, attributeIds).then((res) => {
            if (res.Data && Object.keys(res.Data).length === 0) {
                this.isDefinedEventParamValue = false;
            } else {
                this.isDefinedEventParamValue = true;
                let possibleValues = res.Data[attributeIds] as any[];
                const boolValues = possibleValues.length === 1 ? this.getBoolSource(possibleValues[0].DisplayName) : [];
                if (boolValues.length > 0) possibleValues = boolValues;
                this.eventParamsRelatingValues = possibleValues.map((x) => {
                    return {
                        key: x.DisplayName,
                        value: x.DisplayName
                    } as SearchItem;
                });
            }

            if (initialValue) {
                this.eventParamRelatingValueFormControl.setValue(this.isDefinedEventParamValue ? [{
                    key: initialValue,
                    value: initialValue
                } as SearchItem] : initialValue);
            }
        });
    }

    private getBoolSource = (str: string) => {
        if (str === "true" || str === "false") {
            return [
                { DisplayName: "true" },
                { DisplayName: "false" },
            ];
        } else return [];
    }

    private observeOnEventChanged() {
        this.onEventChangeSubscription = this.eventsFormControl.valueChanges.subscribe((val) => {
            this.lastExistingAttributeSelected = this.existingAttributesFormControl.value;
            this.lastNewAttributeDisplayName = this.newAttrDbFieldName
            this.cleanOptionalData();
            if (val) this.setEventParams(val[0].key);
            this.updateEventParamsLatestValue();
            this.setConfigAndDataForLatestValueDD();
        });
    }

    private observeOnRadioButtonsBasedOnChange() {
        this.onRadioButtonsBasedOnChange = this.attributeBasedOn.valueChanges.subscribe((val) => {
            this.setFormulas();
            if (this.isCombinedOptionChosen()) {
                this.existingAttributesFormControl.setValidators([Validators.required]);
                this.newAttrFormControl.setValidators([]);
                this.newAttrFormControl.updateValueAndValidity();
                this.formatsFormControl.setValidators([]);
                this.formatsFormControl.updateValueAndValidity();
            } else {
                this.newAttrFormControl.setValidators([Validators.required, this.ValidateNewAttrName, cannotStartWithNumbersValidator()]);
                this.formatsFormControl.setValidators([Validators.required]);
                this.existingAttributesFormControl.setValidators([]);
                this.existingAttributesFormControl.updateValueAndValidity();
            }
        });
    }

    public onFormulaItemChanged(event) {
        this.eventParamsContextFormControl.setValidators([]);
        this.eventParamsContextFormControl.updateValueAndValidity();
        this.constValueOREventParamFormControl.setValidators([]);
        this.constValueOREventParamFormControl.updateValueAndValidity();

        if (this.formulasFormControl.value[0].key === this.formulasEnum.sum) {
            this.eventParamsContextFormControl.setValidators([Validators.required]);
        } else if (this.formulasFormControl.value[0].key === this.formulasEnum.latest) {
            this.constValueOREventParamFormControl.setValidators([Validators.required]);
        }
    }


    onFormatItemChanged($event: any) {
        this.checkValidFormulaFor($event[0].key);
        this.setConfigAndDataForLatestValueDD();
    }

    private checkValidFormulaFor(format: string) {
        if (["date", "string"].includes(format)) {
            let currentFormula = this.formulasFormControl.value[0];
            if (this.eventsFormControl.dirty && currentFormula.value != "Latest Value") {
                this.showModal(ModalConfig.dataType);
            } else {
                this.filterInvalidFormulas();
                this.setFormulaDefaultValueToLatestValue();
            }
        } else {
            this.setFormulas();
            if (!this.eventsFormControl.dirty) {
                this.setFormulaDefaultValue();
            }
        }
    }

    private filterInvalidFormulas() {
        this.formulas = this.formulas.filter(function (searchItem, index, arr) {
            return !["Sum", "Count"].includes(searchItem.value);
        });
    }

    public onOperatorChanged(item: any) {
        const selectedOperator = this.operatorsFormControl.value[0];
        this.isSpecialOperatorChosen = selectedOperator.key === "extractIsEmpty" ||
            selectedOperator.key === "extractIsNotEmpty";

        if (this.isSpecialOperatorChosen) {
            this.eventParamRelatingValueFormControl.setValidators([]);
            this.eventParamRelatingValueFormControl.updateValueAndValidity();
        } else {
            this.eventParamRelatingValueFormControl.setValidators([Validators.required]);
            this.eventParamRelatingValueFormControl.updateValueAndValidity();
        }
    }

    public onExistingAttributeSelect(item: any, originalName?: string) {
        const selectedAttribute= this.existingAttributesFormControl.value[0];
        let fullAttrObj = this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes)[selectedAttribute.key];
        this.newAttrDbFieldName = this.editMode ? originalName : this.determineNewAttrFieldName(fullAttrObj.RealFieldName);
        let format = this.determineFormat(fullAttrObj)
        this.checkValidFormulaFor(Formats[format]);
        this.setConfigAndDataForLatestValueDD();
    }

    private determineNewAttrFieldName(chosenFieldName: string) {
        let max = 0;
        let isRTSuffix = false;

        this.realtimeProfileDataManageService.attributes?.forEach((attrRow) => {
            let regex1 = new RegExp(`${chosenFieldName}_RT$`, 'i');
            let regex2 = new RegExp(`${chosenFieldName}_RT_\\d+$`, 'i');

            if (attrRow.displayName.match(regex2)) {
                isRTSuffix = true;
                let splits = attrRow.displayName.split('_RT_');
                max = Number(splits[splits.length - 1]) > max ? Number(splits[splits.length - 1]) : max;
            }
            if (attrRow.displayName.match(regex1)) {
                isRTSuffix = true;
            }
        });

        if (isRTSuffix) {
            return chosenFieldName.replace('-', '_') + '_RT_' + (++max);
        }

        return chosenFieldName.replace('-', '_') + "_RT";
    }

    private isCombinedOptionChosen(): boolean {
        return this.attributeBasedOn.value === AttributeBasedOnTypes.combined;
    }

    private optimoveFieldDeletionError() {
        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant('features.user_settings.body.realtimeProfileDataManage.invalidErrors.CANT_UPDATE_ATTRIBUTE'),
                buttons: [{
                    class: 'btn-primary',
                    label: this.translate.instant("general.OK"),
                    action: this.closeModal.bind(this)
                }]
            },
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

    public async updateExistingAttribute() {
            this.isSaving = true;
            const option: AttributeOption = this.initialValue.status === "Published" ? "Publish" : "Save";
            if (option === "Save") {
                this.saveAttributeClicked();
            } else {
                let fullAttrObj = Object.values(this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes))
                    .find(attrObj => attrObj['RealFieldName'].toLocaleLowerCase() === this.attribute.displayName.toLocaleLowerCase());
                if (fullAttrObj) {
                    const result = await this.attributeService.deleteAttribute(fullAttrObj['RealFieldName'], "Realtime")
                        .pipe(first()).toPromise();
                    if (!result.IsSuccess) {
                        this.optimoveFieldDeletionError();
                        throw new Error();
                    }
                    let updatedProfile = this.realtimeProfileDataManageService.deleteAttribute(this.attribute.displayName, option);
                    const res = await this.realtimeProfileDataManageService.updateSubscription(updatedProfile)
                        .pipe(first()).toPromise();
                    if (res.IsSuccess) {
                        await this.realtimeProfileDataManageService.updateCustomerAttributes();
                    }
                }
                this.saveAttributeClicked();
            }
        }

    public getBasedOnAttribute() {
        return this.isCombinedOptionChosen() ? this.existingAttributesFormControl.value[0]?.key: null;
    }

    public saveAttributeClicked() {
        this.isSaving = true;
        let newAttr = this.determineNewAttr();  
        this.saveIntoProfile(newAttr).subscribe(async (res) => {
            if (res.IsSuccess) {
                await this.router.navigate(['/user-settings/realTimeProfileDataManager/list'], {
                    state: { displayName: this.newAttrDbFieldName }
                });
            } else {
                this.openAttributeCreationFailedModal();
                this.isSaving = false;
            }
        }, (err) => {
            this.openAttributeCreationFailedModal();
            this.isSaving = false;
        });
    }

    openAttributeCreationFailedModal() {
        this.modalService.openSuccessFailModal(
            'sm',
            {
                isSuccess: false,
                title: this.translate.instant('general.FAILED'),
                message: this.translate.instant('components.realTimeProfile.ATTRIBUTE_CREATION_FAILED_MODAL_MESSAGE'),
                primaryButtonText: this.translate.instant('general.OK')
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true
            }
        );
    }
    
    private setAttrForEditing(): void {
        const event = this.attribute.events[0];
        const existedEvent = this.events.find(x => x.key === event.type);
        if (!existedEvent) {
            this.router.navigate(['/user-settings/realTimeProfileDataManager/list']);
            this.loading = false;
            return;
        }
        this.setupExistingEventData(existedEvent);
        // wait for updating UI changes
        this.setupExistingParams(event);
    }

    private setupExistingParams(event: AttributeEvent) {
        this.subscriptions.push(timer(100).pipe(map(() => {
            if (!this.attribute.import) {
                this.formatsFormControl.setValue([{
                    key: this.attribute.format,
                    value: this.attribute.format
                }]);
                this.checkValidFormulaFor(this.attribute.format);
                this.newAttrFormControl.setValue(this.attribute.displayName);
            }

            const eventParamsData = this.eventsWithParamsData[event.type].EventParameters;

            if (this.attribute.aggregation === this.formulasEnum.sum
                || this.attribute.aggregation === this.formulasEnum.latest) {
                const eventParam = event.value.replace("$.", "");

                if (eventParamsData[eventParam]) {
                    this.latestValueRelatingSearchList = true;
                }

                const formControlValue = {
                    key: eventParam,
                    value: eventParamsData[eventParam]?.Name ?? eventParam
                };

                if (this.attribute.aggregation === this.formulasEnum.sum) {
                    this.eventParamsContextFormControl.setValue([formControlValue]);
                } else {
                    this.constValueOREventParamFormControl.setValue(formControlValue.key);
                }
            }

            this.formulasFormControl.setValue([{key: this.attribute.aggregation, value: FormulasDic[this.attribute.aggregation]}]);

            if (!this.showAddParameterButton) {
                this.setupExistingCondition(event);
            }

            this.setConfigAndDataForLatestValueDD();
        })).subscribe(() => {
            this.loading = false;
        }));
    }

    private setupExistingEventData(existedEvent) {
        this.attributeBasedOn.setValue(this.attribute.import ? 0 : 1);
        if (this.attribute.import) {
            const existingAttribute = this.existingAttributes.find(x => x.realFieldName === this.attribute.import);
            if (existingAttribute) {
                this.existingAttributesFormControl.setValue([existingAttribute]);
                this.onExistingAttributeSelect(existingAttribute, this.attribute.displayName);
            } else {
                this.showModal(ModalConfig.emptyDbAttr);
                return;
            }
        }
        this.formulasFormControl.setValue([{
            key: this.attribute.aggregation,
            value: FormulasDic[this.attribute.aggregation]
        }]);
        this.onFormulaItemChanged(this.formulasFormControl.value);
        this.eventsFormControl.setValue([existedEvent]);
        if (this.attribute.events.length > 0 && this.attribute.events[0].conditionField) {
            this.toggleAddParameterArea();
        }
    }

    private setupExistingCondition(event: AttributeEvent) {
        const eventParamsData = this.eventsWithParamsData[event.type].EventParameters;

        const eventParam = event.conditionField.replace("$.", "");
        this.eventParamsConditionFormControl.setValue([{
            key: eventParam,
            value: eventParamsData[eventParam].Name
        }]);
        this.conditionEventParamType = eventParamsData[eventParam].Type;
        if (ParamTypes[this.conditionEventParamType] == null) {
            this.conditionEventParamType = ParamTypes.text;
        }
        this.setOperators(this.conditionEventParamType);
        this.operatorsFormControl.setValue([Operators[ParamTypes[this.conditionEventParamType]]
            .find(x => x.key === event.conditionType)]);

        const selectedEvent = this.eventsFormControl.value[0];
        const eventKey = this.eventsWithParamsData[selectedEvent.key];
        this.isSpecialOperatorChosen = ["extractIsEmpty", "extractIsNotEmpty"].includes(event.conditionType);
        if (!this.isSpecialOperatorChosen) {
            this.setAttributeRelatingValues(eventKey.Id, eventKey.EventParameters[eventParam].Id, event.conditionValue.replace(/["']/g, ""));
        } else {
            this.eventParamRelatingValueFormControl.setValidators([]);
            this.eventParamRelatingValueFormControl.updateValueAndValidity();
        }
    }

    private initEventData(): AttributeEvent {
        const selectedEvent = this.addAttributeForm.get('events').value[0].key;
        const selectedFormula = this.addAttributeForm.get('formulas').value[0].key;
        const constOREventParamValue = this.constValueOREventParamFormControl.value;
        const isEventDateSelected = constOREventParamValue === "event_date";
        let eventBy = { type: selectedEvent } as AttributeEvent;

        switch (selectedFormula) {
            case this.formulasEnum.sum:
                const selectedEventParamContext = this.eventParamsContextFormControl.value[0];
                eventBy.valueType = "contextExtract";
                eventBy.value = `$.${selectedEventParamContext.key}`;
                break;
            case this.formulasEnum.latest:
                const eventValue = this.eventParamsLatestValue
                    .find(item => item.value === constOREventParamValue || item.key === constOREventParamValue);
                eventBy.valueType = isEventDateSelected && this.latestValueRelatingSearchList
                    ? "dateExtract"
                    : this.latestValueRelatingSearchList || !!eventValue ? "contextExtract" : "constant";

                eventBy.value = this.latestValueRelatingSearchList || !!eventValue
                    ? `$.${eventValue?.key}`
                    :  `${constOREventParamValue}`;
                break;
        }

        if (!this.showAddParameterButton) {
            const selectedEventParam = this.addAttributeForm.get('eventParamsCondition').value[0].key;
            const selectedOperator = this.addAttributeForm.get('operators').value[0].key;
            const relatingValue = this.addAttributeForm.get('eventParamRelatingValue').value;
            const eventParamRelatingValue = Array.isArray(relatingValue) ? relatingValue[0].value : relatingValue;
            eventBy.conditionField= `$.${selectedEventParam}`;
            eventBy.conditionType = selectedOperator;
            eventBy.conditionValue = !this.isSpecialOperatorChosen
                ? this.conditionEventParamType === ParamTypes.text
                    ? `'${eventParamRelatingValue}'`
                    : eventParamRelatingValue
                : "";
        }

        return eventBy;
    }

    private determineNewAttr(): RealtimeAttribute {
        const isAlreadyPublished = this.attribute != null ? this.attribute.isAlreadyPublished : false;
        const isBasedOnExistedAttr = this.isCombinedOptionChosen();
        const selectedProfileAttr = this.addAttributeForm.get('existingAttributes').value[0];
        const selectedFormula = this.addAttributeForm.get('formulas').value[0].key;
        const fullAttrObj = this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes)[selectedProfileAttr?.key];
        const selectedFormat = isBasedOnExistedAttr
            ? Formats[this.determineFormat(fullAttrObj)]
            : this.addAttributeForm.get('formats').value[0].key;
        const event = this.initEventData();
        
        let newAttr = {
            displayName: isBasedOnExistedAttr
                ? this.newAttrDbFieldName
                : `${this.newAttrFormControl.value.replace(/\s/g, '').replace(/_RT/g, '')}_RT`,
            format: selectedFormat,
            aggregation: selectedFormula,
            export: true,
            events: [event],
            isAlreadyPublished : isAlreadyPublished
        } as RealtimeAttribute;

        if (isBasedOnExistedAttr) {
            newAttr.import = fullAttrObj.RealFieldName;
        } else {
            this.newAttrDbFieldName = `${newAttr.displayName.replace(/\s/g, '').replace(/_RT/g, '')}_RT`;
        }

        return newAttr;
    }

    private saveIntoProfile(newAttr: RealtimeAttribute): Observable<any> {
        let newProfile = this.realtimeProfileDataManageService.addAttributeToProfile(this.newAttrDbFieldName, "Save", newAttr);
        return this.realtimeProfileDataManageService.updateSubscription(newProfile);
    }

    private determineFormat(fullAttrObj: any): Formats {
        switch (fullAttrObj.DataType) {
            case 'decimal':
                return Formats.currency;
            case 'int':
            case 'bigint':
                return Formats.number;
            case 'nvarchar':
            case 'varchar':
                return Formats.string;
            case 'date':
                return Formats.date;

        }
    }

    toggleAddParameterArea() {
        this.isSpecialOperatorChosen = false;
        this.showAddParameterButton = !this.showAddParameterButton;
        if (this.showAddParameterButton) {
            this.eventParamsConditionFormControl.setValidators([]);
            this.operatorsFormControl.setValidators([]);
            this.eventParamRelatingValueFormControl.setValidators([]);
        } else {
            this.eventParamsConditionFormControl.setValidators([Validators.required]);
            this.operatorsFormControl.setValidators([Validators.required]);
            this.eventParamRelatingValueFormControl.setValidators([Validators.required]);
        }
    }

    private setCombinedRadioButtonChecked() {
        this.attributeBasedOn.setValue(AttributeBasedOnTypes.combined);
    }

    cleanOptionalData() {
        this.eventParamsConditionFormControl.setValue(undefined);
        this.operatorsFormControl.setValue(undefined);
        this.eventParamRelatingValueFormControl.setValue(undefined);
    }

    private setFormulaDefaultValue() {
        this.formulasFormControl.setValue([{key: "COUNT", value: "Count"}]);
    }

    private setFormulaDefaultValueToLatestValue() {
        this.formulasFormControl.setValue([{key: "LATEST_BY_OFFSET", value: "Latest Value"}]);
        this.constValueOREventParamFormControl.setValidators([Validators.required]);
        this.newAttrFormControl.updateValueAndValidity();
    }

    private ValidateNewAttrName(formControl: FormControl): { [key: string]: any } | null {
        let regex = /^([a-zA-Z0-9_@]+)$/;
        let newAttrName = formControl.value;
        let i = newAttrName.length;
        while (i--) {
            if (!regex.test(newAttrName[i])) {
                //this.newAttrNameInvalidChar = newAttrName[i];
                return {'newAttrNameInvalid': newAttrName[i] === ' ' ? 'space' : newAttrName[i]};
            }
        }
        return null;

    }


    showModal(config: ModalConfig) {
        const modalConf = this.getModalConf(config);
        this.modalService.openModalMessage(
            'sm',
            modalConf,
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

    changeFormula() {
        this.filterInvalidFormulas();
        this.setFormulaDefaultValueToLatestValue();
        this.eventsFormControl.reset();
    }

    getModalConf(config: ModalConfig): IModalConf {
        const message = config === ModalConfig.dataType
            ? this.translate.instant('components.realTimeProfile.DATA_TYPE_VALIDATION_MESSAGE')
            : this.translate.instant('components.realTimeProfile.DB_ATTRIBUTE_WAS_REMOVED_OR_RENAMED');
        return {
            message: message,
            buttons: this.getButtonsConf(config)
        };
    }

    getButtonsConf(config: ModalConfig): IModalButtonConf[] {
        switch (config) {
            case ModalConfig.dataType:
                return [
                    {
                        class: 'btn-primary',
                        label: 'Yes',
                        action: this.changeFormula.bind(this)
                    },
                    {
                        class: 'btn-default',
                        label: 'No',
                        action: this.closeModal.bind(this)
                    }
                ];
            case ModalConfig.emptyDbAttr:
                return [
                    {
                        class: 'btn-primary',
                        label: 'OK',
                        action: this.resetEditMode.bind(this)
                    }
                ];
        }
    }

    public openConfirmModal(){
        if(this.addAttributeForm.dirty) {
            this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant(this.translateKeys.confirmBackMessage),
                buttons: [
                    {
                    class: 'btn-primary',
                    label: this.translate.instant(this.translateKeys.discardChanges),
                    action: this.discardChanges.bind(this),
                    },
                    {
                    class: 'btn-default',
                    label: this.translate.instant(this.translateKeys.continueEditing),
                    action: this.closeModalWithoutSeve.bind(this),
                    },
                ]
            },
            <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
            );
        }
        else {
            this.discardChanges();
        }
    }

    private discardChanges() {
        this.router.navigateByUrl("/user-settings/realTimeProfileDataManager/list");
        this.bsModalRef.hide();
    }

    private closeModalWithoutSeve() {
        this.bsModalRef.hide();
    }

    private resetEditMode() {
        this.bsModalRef.hide();
        this.attribute = null;
        this.editMode = false;
        this.loading = false;
    }

    private closeModal() {
        this.bsModalRef.hide();
        this.existingAttributesFormControl.setValue(this.lastExistingAttributeSelected);
        this.newAttrDbFieldName = this.lastNewAttributeDisplayName;
        this.formatsFormControl.reset();
    }

    get newAttrFormControl(): FormControl {
        return this.addAttributeForm.get('newAttrName') as FormControl;
    }

    get formatsFormControl(): FormControl {
        return this.addAttributeForm.get('formats') as FormControl;
    }

    get formulasFormControl(): FormControl {
        return this.addAttributeForm.get('formulas') as FormControl;
    }

    get attributeBasedOn(): FormControl {
        return this.addAttributeForm.get('attributeBasedOn') as FormControl;
    }

    get eventsFormControl(): FormControl {
        return this.addAttributeForm.get('events') as FormControl;
    }

    get operatorsFormControl(): FormControl {
        return this.addAttributeForm.get('operators') as FormControl;
    }

    get existingAttributesFormControl(): FormControl {
        return this.addAttributeForm.get('existingAttributes') as FormControl;
    }

    get eventParamsContextFormControl(): FormControl {
        return this.addAttributeForm.get('eventParamContext') as FormControl;
    }

    get eventParamsConditionFormControl(): FormControl {
        return this.addAttributeForm.get('eventParamsCondition') as FormControl;
    }

    get eventParamRelatingValueFormControl(): FormControl {
        return this.addAttributeForm.get('eventParamRelatingValue') as FormControl;
    }

    get constValueOREventParamFormControl(): FormControl {
        return this.addAttributeForm.get('constValueOREventParam') as FormControl;
    }
}

const translateKeys = {
    cancel: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.CANCEL',
    save: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.SAVE',
    attributeBasedOn: {
        label: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.BASED_ON',
        radioLabel1: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.RADIO_LABEL_1',
        radioLabel2: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.RADIO_LABEL_2',
        selectExistingAttr: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.SELECT_EXISTING_ATTR',
        newAttrName: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.NEW_ATTRIBUTE_NAME',
        selectFormat: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.SELECT_FORMAT'
    },
    calculate: {
        label: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.CALCULATE',
        of: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.OF',
        by: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.BY',
        event: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.EVENT',
        when: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.WHEN',
        addParamOptional: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.ADD_PARAMETER_OPTIONAL',
        eventParameter: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.EVENT_PARAMETER',
        operator: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.Operator',
        value: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.Value',
    },
    returnLatestValue: {
        label: 'features.user_settings.body.realtimeProfileDataManage.addAttribute.RETURN_LATEST_VALUE',
    },
    invalidMsg: {
        newAttrName: 'features.user_settings.body.realtimeProfileDataManage.invalidErrors.NEW_ATTR_NAME'
    },
    confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
    continueEditing: 'general.CONTINUE_EDITING',
    discardChanges: 'general.DISCARD_CHANGES'
};

enum ModalConfig {
    dataType,
    emptyDbAttr
}
