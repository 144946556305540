import { Component, Input, EventEmitter, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { IModalConf, OptiLogicModalService } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ChangeAttributeModal } from '../changeAttributeModal/changeAttributeModal.component';
import { BsModalRef } from "ngx-bootstrap/modal";
import { IAttributeMetric } from '../../customer360.models';
import { IMultipleOptionsButtonItem } from '../../../../components/multipleOptionsButton/models/multipleOptionsButtonItem.model';

@Component({
	selector: 'setup-gear-button',
	templateUrl: './setupGearButton.component.html',
  	styleUrls: ['./setupGearButton.component.scss']
})
export class SetupGearButton implements OnInit {
  @Input() defaultAttributeAliasName: string;
  @Output() onChangeAttribute: EventEmitter<IAttributeMetric> = new EventEmitter<IAttributeMetric>();

  public modalConf: IModalConf;
  public menuItems: IMultipleOptionsButtonItem[] = [];

  public config = {
    translateKeys: translateKeys,
    isMenuOpen: false
  };
  

  constructor(
    private bsModalRef: BsModalRef, 
    private optiLogicModalService: OptiLogicModalService,
    private eRef: ElementRef) { }

  ngOnInit(): void {
    this.menuItems.push({
      id: 1,
      label: this.config.translateKeys.changeAttr
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
      if (!this.config.isMenuOpen) {
          return;
      }

      if (!this.eRef.nativeElement.contains(event.target)) {
          this.config.isMenuOpen = false;
      }
  }

  public openChangeAttributeModal() {
    this.optiLogicModalService.open(
      ChangeAttributeModal,
       'md',
        <ModalOptions<any>>{
          title: "Change Attribute",
          ignoreBackdropClick: true,
          class: '',
          initialState:{
            defaultAttributeAliasName: this.defaultAttributeAliasName,
            onChangeAttribute: (attributeNames: IAttributeMetric) => { this.onChangeAttribute.emit(attributeNames) },
          }
        });
  }

  public onItemClicked(item: IMultipleOptionsButtonItem) {
    if (item.disabled) {
        return;
    }

    switch (item.id) {
      case 1:
        this.openChangeAttributeModal();
        break;
      default:
        break;
    }

    this.config.isMenuOpen = false;
}

  public showPopover() {
    this.config.isMenuOpen = !this.config.isMenuOpen;
  }

  public closeClick() {
    this.close();
  }

  public close() {
    this.bsModalRef.hide();
  }
}

const translateKeys = {
  changeAttr: 'features.customer360.body.change_attribute_modal.TITLE'
};
