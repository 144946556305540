import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Brand } from '../models/metadataResponse';
import { TemplateSearchResult } from '../models/templateResponse';

@Injectable({
	providedIn: 'root'
})
export class SearchContextService {
	public readonly minSearchTextLength: number = 2;

	private $searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public searchText: Observable<string> = this.$searchText.asObservable().pipe(auditTime(1000)).pipe(distinctUntilChanged()); // ignore text change too often
	private $brand: BehaviorSubject<Brand | null> = new BehaviorSubject<Brand | null>(null);
	public brand: Observable<Brand | null> = this.$brand.asObservable();
	private $searchTemplates: BehaviorSubject<TemplateSearchResult[]> = new BehaviorSubject<TemplateSearchResult[]>([]);
	public searchTemplates: Observable<TemplateSearchResult[]> = this.$searchTemplates.asObservable();
	private $searchableTemplatesChanged: Subject<number> = new Subject<number>();
	public searchableTemplatesChanged: Observable<number> = this.$searchableTemplatesChanged.asObservable();

	constructor() {}

	getSearchTemplateIds(): Observable<number[]>{
		return this.$searchTemplates.pipe(
			map((templates: TemplateSearchResult[]) => templates.map((t) => t.templateID))
		) as Observable<number[]>;
	}


	updateSearchTemplateCache(templates: TemplateSearchResult[]): void {
		this.$searchTemplates.next(templates);
	}

	updateSearchText(text: string): void {
		this.$searchText.next(text);
	}

	updateBrand(brand: Brand) {
		this.$brand.next(brand);
	}

	highlightSearchText(text: string): string {
		return this.highlightThisSearchText(text, this.$searchText.value);
	}

	highlightThisSearchText(text: string, searchText: string): string {
		if (text && searchText && searchText.length > 2) {
			const searchStringIndex = text.toUpperCase().indexOf(searchText.toUpperCase());
			const originalSearchString = text.substring(searchStringIndex, searchStringIndex + searchText.length);
			const escapedSearchText = searchText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape special characters
			return text.replace(new RegExp(escapedSearchText, 'i'), `<b>${originalSearchString}</b>`);
		}
		return text;
	}

	isMatch(text: string): boolean {
		return this.isMatchSearchText(text, this.$searchText.value);
	}

	isMatchSearchText(text: string, searchText: string): boolean {
		const searchString = searchText;
		if (!searchString || searchString.length < this.minSearchTextLength) {
			return true;
		}

		return text.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
	}

	isSearchTextValid(searchText: string): boolean {
		return searchText && searchText.length > this.minSearchTextLength;
	}

	triggerSearchableTemplatesChanged(): void {
		this.$searchableTemplatesChanged.next();
	}
}
