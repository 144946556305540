import { Injectable } from '@angular/core';
import { Observable, of, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpService } from '../../services/optimove-http/optimove-http.model';

@Injectable()
export class PersonalizationTagsService {
    readonly API_URL: string  =  '/OptiMail';
    private tagsObjectSub = new Subject<PersonalizationTagsInfo>();
    public tagsObject$ = this.tagsObjectSub.asObservable();
    private tagPicked = new Subject<any>();
    public tagPicked$ = this.tagPicked.asObservable();

    constructor(private readonly http: HttpService) {
    }

    public updateChosenTag(tag: string): void {
        this.tagPicked.next(tag);
    }

    public updateTagsObject(tags: any) {
        setTimeout(() => {
            this.tagsObjectSub.next(tags);
        });
    }



    public GetPersonalizationTagsForInfinityScroll(search:string,brandType:number, category:string ,offset:number,count:number): Observable<PersonalizationTagsInfo>{
        var params = HttpService.toHttpParams({
            brandType : brandType,
            search : search,
            category : category,
            offset : offset,
            count : count
        });

        let url: string  = `${this.API_URL}/GetPersonalizationTagsForInfinityScroll`
        return this.http.get<any>(url, params)
            .pipe(catchError(err => of(err))).pipe(map((res)=>{
                return res.Data;
            }));
    }
    public GetCategories(brandType:string, search:string): Observable<any>{
        var params = HttpService.toHttpParams({
            brandType : brandType,
            search : search
        });
        let url: string  = `${this.API_URL}/GetCategoriesOfPT`
        return this.http.get<any>(url, params)
            .pipe(catchError(err => of(err))).pipe(map((res)=>{
                return res.Data;
            }));
    }
}
export interface PersonalizationTagsInfo{
    Rows: string[],
}