
    <div class="create-api-user-container" data-qa-id="createApiUser">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-1 back-button back-btn">
                    <a data-qa-id="backBtn" class="btn btn-link" [routerLink]="['/user-settings/apiManagement']"
                    [routerLinkActive]="['active']">
                        <i class="material-icons">arrow_back</i>
                        <span>{{ translateKeys.back | translate }}</span>
                    </a>
                </div>
                <div class="col-md-11" data-qa-id="createApiUserTitle">
                    <h2> {{ translateKeys.title| translate }} </h2>
                </div>
            </div>
        </div>
    
        <form [formGroup]="parentForm" data-qa-id="parentFrom">
            <api-user-details [parentForm]="parentForm" #apiUserDetails [userExistsMsg]="userExistsMsg"></api-user-details>
            <div class="panel-footer panel-footer-bottom-stick footer-btns">
                <div class="controls col-md-6 pull-right text-right">
                    <ul class="list-inline">
                        <li>
                            <a class="btn btn-default" data-qa-id="cancelBtn" [routerLink]="['/user-settings/apiManagement']"
                            [routerLinkActive]="['active']">
                                <span>{{ translateKeys.cancel| translate}}</span>
                            </a>
                        </li>
                        <li>
                            <button type="button" *ngIf="!isSaving" data-qa-id="saveBtn" (click)="onSubmit()" 
                            class="btn btn-default btn-primary ng-binding" [disabled]="(!this.parentForm.valid || apiUserDetails.containsExistingUser()) ? true : null">
                                <span>{{ translateKeys.save| translate}}</span>
                            </button>  
                        </li>
                        <li *ngIf="isSaving">
                            <span class="click-loader loader-wrapper" data-qa-id="savingloader">
                                <span class="loader"></span>
                                <span>{{ translateKeys.saving| translate}}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>