import { Inject, Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map, tap } from "rxjs/operators";
import { HttpService } from './optimove-http/optimove-http.model';
import { AngularJsSsmService, AngularJsSsmServiceInstance, SsmServiceResourceDetails } from './optimove-ssm/optimove-ssm.model';
import { WindowRefService } from './windowRef.service';

@Injectable()
export class SsmService {
    constructor(
        @Inject('ssmService') private readonly ssmService: AngularJsSsmService,     
        private readonly http: HttpService,
        private readonly windowRef: WindowRefService
    ) {
		(window.top as any).ssmService = this; // For very old AngularJs pages
    }

    getSSM(): AngularJsSsmServiceInstance {
         return this.ssmService.getSSM();
    }

    GetGeneric<T>(resource: SsmServiceResourceDetails): Observable<T> {
        const sessionStorageValue = this.windowRef.nativeWindow.sessionStorage.getItem(resource.sessionVarName);
        if(sessionStorageValue) {
            return of<T>(this.isJson(sessionStorageValue) ? JSON.parse(sessionStorageValue) : sessionStorageValue);
        }

        return this.http.get<T>({ controller: 'Main', action: resource.functionName }, { responseType: 'text', headers: { 'Content-type': 'application/json; charset=utf-8' } })
                .pipe(tap(result => {
                    this.windowRef.nativeWindow.sessionStorage.setItem(resource.sessionVarName, result);
                }), map((result: any) => {
                    return this.isJson(result) ? JSON.parse(result) : result;
                }));
    }

    async GetGenericAsync<T>(resource: SsmServiceResourceDetails): Promise<T> {
        const sessionStorageValue = this.windowRef.nativeWindow.sessionStorage.getItem(resource.sessionVarName);
        if(sessionStorageValue) {
            return this.isJson(sessionStorageValue) ? JSON.parse(sessionStorageValue) : sessionStorageValue;
        }

        const result = await (this.http.get<T>({ controller: 'Main', action: resource.functionName }, { responseType: 'text', headers: { 'Content-type': 'application/json; charset=utf-8' } })
            .pipe(tap(result => {
                this.windowRef.nativeWindow.sessionStorage.setItem(resource.sessionVarName, result);
            }), map((result: any) => {
                return this.isJson(result) ? JSON.parse(result) : result;
            }))
            .toPromise());
        return result;
    }

    private isJson(str: string): boolean {
        try {
          JSON.parse(str);
          return true;
        } catch (error) {
          return false;
        }
      }
}