import { Observable } from 'rxjs';
import { AngularRouterUIRouterAdapter } from './angularRouterUIRouterAdapter.service';
import {Injectable, NgZone} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import {CookieService} from "ngx-cookie-service";

// *********************************************************************
// **  Handles redirect to default route if no other route was found  **
// *********************************************************************
@Injectable()

export class DefaultRoutingActivate implements CanActivate {
  constructor(private routingAdapter: AngularRouterUIRouterAdapter,
              private ngZone: NgZone,
              private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
      return this.routingAdapter.getDefaultUrl().then((defaultUrl) => {
        if (!defaultUrl){
          return false;
        }
        return this.routingAdapter.isUnauthorized(defaultUrl).then(isUnauthorized => {
            // important to wrap in ngZon.run - https://github.com/angular/angular/issues/18254#issuecomment-318329250
            if (isUnauthorized) {
                this.ngZone.run(() =>  this.router.navigate(['unauthorized'], { replaceUrl: true }) );
                return false;
            }
            this.ngZone.run(() =>  this.router.navigateByUrl(defaultUrl, { replaceUrl: true}) );
            return true;
        })
    });
  }
}