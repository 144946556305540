import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from 'src/app/components/optiLogicModal/optiLogicModal.service';

@Component({
	selector: 'import-result-modal',
	templateUrl: './import-result-modal.component.html',
	styleUrls: ['./import-result-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImportResultModalComponent implements OnInit {
  @Input() total: number;
  @Input() successfullyImported: number;
  @Input() alreadyExists: number;
  @Input() duplicates: number;
  @Input() invalidEmail: number;
  public modalConfig: IModalConf;
  public summary: string;
  constructor(private translate: TranslateService, public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  	this.setModalConfig();
  	this.summary = this.translate.instant(`features.user_settings.body.importedCustomers.IMPORT_RESULT.TOTAL_COUNT_OF_IMPORTED_CUSTOMERS`, { parameters: this.total.toLocaleString() });
  }

  public close(){
  	this.bsModalRef.hide();
  }

  private setModalConfig() {
  	this.modalConfig = {
  		hideFooterBorder: false,
  		noBodyPadding: true,
  		title: this.translate.instant(`features.user_settings.body.importedCustomers.TITLE`),
  		buttons:[
  			{
  				class: "btn-primary",
  				isDisabled: () => false,
  				label: this.translate.instant("general.OK"),
  				action: () => { this.close() },
  			},
  		]
  	};
  }
}
