import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import {SsmService} from "../../services/ssm.service";


export class RoleGuard {   
    static forRoles(roles: string[]){
        
        @Injectable({ providedIn: 'root' })
        class RoleCheck implements CanActivate {
            private ssm: any;

            constructor(private ssmService: SsmService) {
                this.ssm = this.ssmService.getSSM();
            }
            
            canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {                
                let tenantDetails = this.ssm.GetGeneric(this.ssm.Resx.TenantDetails);
        
                return roles.indexOf(tenantDetails.userRole) > -1;        
            }
        }
        return RoleCheck;
    }    
}