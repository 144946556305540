<div>
  <div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <h3>{{ "features.manage_templates.components.validationErrorModal.TITLE" | translate }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>
          <span>{{ "features.manage_templates.components.validationErrorModal.MESSAGE_PART_1" | translate }}</span>
          <button (click)="openValidationModal()" class="decision-add-btn btn btn-link">
            {{ "features.manage_templates.components.validationErrorModal.MESSAGE_CLICK_HERE" | translate }}
          </button>
          <span>{{ "features.manage_templates.components.validationErrorModal.MESSAGE_PART_2" | translate }}</span>
        </p>
      </div>
    </div>
    <ul class="list-inline action-buttons pull-right">
      <li class="col-md-6">
        <button (click)="close()" type="button" class="btn btn-default">{{ "general.CANCEL" | translate }}</button>
      </li>
      <li class="col-md-6">
        <button (click)="save()" type="button" class="btn btn-primary">{{ "general.SAVE" | translate }}</button>
      </li>
    </ul>
  </div>
</div>
