import { Injectable } from '@angular/core';
import { ProcessCellForExportParams } from "@ag-grid-community/all-modules";
import { FieldType } from "../customer360.enums";
import * as moment from "moment";
import { AppConstant } from "../../../app.constatnt";

@Injectable()
export class Customer360CellService {
    constructor() { }

    public process(params: ProcessCellForExportParams): string {
        const column = params.column;
        const colDef = column.getColDef();
        const colId = column.getColId();
        const cellRenderer = colDef.cellRenderer;
        
        switch (cellRenderer) {
            case 'loadingColumn':
                return this.getCellValueFromCellObject(params.value);
            case 'campaignGridTemplateColumnComponent':
                return this.getCampaignTemplates(params.value);
            case 'campaignGridPreviewColumnComponent':
                return null;
            case 'campaignGridChannelsColumnComponent':
                return this.getCampaignChannels(params.value);
            case 'campaignGridActionColumnComponent':
                return params.value;
        }

        switch (colId) {
            case FieldType.StartDate:
                return this.getValueForDateCell(params.value);
            case FieldType.TargetGroup:
            case FieldType.Trigger:
            case FieldType.PromotionName:
            case FieldType.PromotionCode:
                return this.getCellValueFromCellObject(params.value);
        }

        
        return params.value;
    }

    private getCampaignChannels(value: any){
        if (value) {
            const channelArr = value ? value
                .filter(channel => channel.Name !== "DoNothing")
                .map(channel => channel.Name) : [];
            const uniqueChannels = [...new Set(channelArr)];
            return uniqueChannels.join(", ");
        }
    }

    private getCampaignTemplates(value: any){
        if (value) {
            return value
                .map(template => template.TemplateName)
                .join(", ");
        }
    }

    private getCellValueFromCellObject(value: any){
        return value.cellValue;
    }

    private getValueForDateCell(value: any){
        return value != null ? moment(value).format(AppConstant.DATE_FORMATS.server) : null;
    }
}