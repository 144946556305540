import { Inject, Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class OptiSideBarModalService {
    constructor(@Inject('optiSideBarModalService') private sideBarServiceJS: any) { }

    public open(options: OpenOptions) {
        this.sideBarServiceJS.open(options.config)
            .result.then(options.afterAction ? options.afterAction : () => {});
    }
}

export interface OpenOptions {
    config: SideBarConfig,
    afterAction?: Function,
}

export interface SideBarConfig {
    confirmLabel?: string,
    denyLabel?: string,
    fixedElementSelector?: string, //(css selector)
    isSaveDisabled?: Function,
    message?: string,
    preCloseFunc?: Function, //function that execute before calling close function
    preDismissFunc?: Function, //function that execute before calling dismiss function
    scope?: any,
    scrollElementSelector?: string, //(css selector)
    showFooter?: boolean,
    showHeader?: boolean,
    templateUrl?: string,
    title?: string | Function,
    windowClass?: string,
    vm: SideBarContext,
}

export interface SideBarContext {
    checkBoxClicked: any,
    optiSideBarButtons: SideBarButtonConfig[],
    setAllAttributes: any,
    sideBarNumOfChosenAttr: number,
    sideBarAttributes: any[],
}

export interface SideBarButtonConfig {
    action: string, //"close" || "dismiss"
    class: string,
    disabled: boolean | string, //a boolean or an angular js condition like a string "vm.numOfChosenAttr == 0"
    text: string,
}