import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { OptiLogicModalService } from "../optiLogicModal/optiLogicModal.service";
import { PersonalizationTagsModalComponent } from "./personalizationTagsModal/personalizationTagsModal.component";
import { PersonalizationTagsService } from "./personalizationTags.service";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";

@Component({
  selector: "personalization-tags-container",
  templateUrl: "./personalizationTagsContainer.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./personalizationTagsContainer.component.scss"],
})
export class PersonalizationTagsContainerComponent
  implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();
  modalRef: BsModalRef;
  @Output() chosen: EventEmitter<string> = new EventEmitter<string>();
  @Input() subMethodId: any;
  @Input() isBeeFree: boolean;

  constructor(
    private modalService: OptiLogicModalService,
    private personalizationTagsService: PersonalizationTagsService,
    private bsModalService: BsModalService
  ) {
    this.subscription.add(this.bsModalService.onHide.subscribe(
        (reason: string) => {
          // skip if hide event triggered for inner modal
          if (this.bsModalService.getModalsCount() > 1)
            return;

            const noTagPickedString = "";
            setTimeout(() => {
              this.chosen.emit(noTagPickedString);
          });
        }
    ));
  }

  public openModal(): void {
    this.modalRef = this.modalService.open(
      PersonalizationTagsModalComponent,
      "md",
      <ModalOptions<any>>{
        initialState: {
          submethodType: this.subMethodId,
          isBeeFree: this.isBeeFree,
        },
      }
    );
  }

  ngOnInit(): void {
    this.openModal();

    this.subscription.add(
        this.personalizationTagsService.tagPicked$.subscribe((val) => {
          if (val) {
            this.chosen.emit(val);
            this.modalRef?.hide();
          }
        }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
