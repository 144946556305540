import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  IModalButtonConf,
  IModalConf,
} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { Router } from "@angular/router";
import { ICreateNewUniquePromoCodeResults } from "../../models/icreateNewUniquePromoCodeResults.modal";
import { UniquePromoErrors } from "../../models/uniquePromoErrors.enum";

@Component({
  selector: "import-csv-loader-modal-modal",
  templateUrl: "./importCsvLoaderModal.component.html",
  styleUrls: ["./importCsvLoaderModal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ImportCsvLoaderModal implements OnInit {
  public titleModalText: string;
  public isLoading = false;
  public isSuccess = false;
  public createdOrUpdatedSuccessfully = false;
  public editMode = false;
  public loadingTitle: string;
  public loadingText: string;
  public errorMessage: string;
  public errorTitle: string;
  public foundTotalMessage: string;
  public createNewUniquePromoCodeResults: ICreateNewUniquePromoCodeResults;

  public modalConfig: IModalConf;

  private readonly translationPrefix =
    "features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal";

  constructor(
    private translate: TranslateService,
    private router: Router,
    private modalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  // prevents from tab closing, browser closing, refresh, change url.
  // @HostListener('window:beforeunload',  ['$event'])
  // beforeunloadHandler(): Observable<boolean> | boolean {
  //     return false;
  // }

  ngOnInit(): void {
    this.isLoading = true;
    this.titleModalText = this.translate.instant(
      `${this.translationPrefix}.IMPORT_CSV_FILE`
    );
    this.loadingText = this.translate.instant(
      `${this.translationPrefix}.IMPORTING_CSV_FILE_DONT_CLOSE`
    );
    this.setModalConfig();
  }

  public loadResults(
    createNewUniquePromoCodeResults: ICreateNewUniquePromoCodeResults
  ) {
    this.createNewUniquePromoCodeResults = createNewUniquePromoCodeResults;
    this.isLoading = false;
    this.isSuccess = createNewUniquePromoCodeResults.isSuccess;
    if (this.isSuccess) {
      this.handleSuccess(createNewUniquePromoCodeResults);
    } else {
      this.handleError(createNewUniquePromoCodeResults);
    }
    this.changeDetectorRef.detectChanges();
  }

  private handleError(
    createNewUniquePromoCodeResults: ICreateNewUniquePromoCodeResults
  ) {
    let errorTitleTranslateKey;
    switch (createNewUniquePromoCodeResults.errorCodeStatus) {
      case UniquePromoErrors.PromoNameNotUnique: {
        this.modalConfig.hideHeaderPanel = true;
        this.modalConfig.hideFooterBorder = true;
        errorTitleTranslateKey = "PROMO_NAME_ALREADY_EXIST_ERROR";
        break;
      }
      case UniquePromoErrors.FailedToCreatePromotion: {
        errorTitleTranslateKey = "FAILED_TO_CREATE_NEW_PROMOTION";
        this.modalConfig.title = this.translate.instant(
          `${this.translationPrefix}.NEW_PROMOTION`
        );
        this.errorMessage = this.translate.instant(
          `${this.translationPrefix}.PLEASE_TRY_AGAIN_IN_A_FEW_MINUTES`
        );
        break;
      }
      case UniquePromoErrors.FailedToUpdatePromotion: {
        errorTitleTranslateKey = "FAILED_TO_SAVE_CHANGES";
        this.modalConfig.title = this.translate.instant(
          `${this.translationPrefix}.EDIT_PROMOTION`
        );
        this.errorMessage = this.translate.instant(
          `${this.translationPrefix}.PLEASE_TRY_AGAIN_IN_A_FEW_MINUTES`
        );
        break;
      }
      case UniquePromoErrors.CSVNoRowsToImport: {
        if (this.editMode) {
          errorTitleTranslateKey = "FAILED_TO_UPLOAD_FILE_SAVED_NO_CODES_FOUND";
        } else {
          errorTitleTranslateKey =
            "FAILED_TO_UPLOAD_FILE_CREATED_NO_CODES_FOUND";
        }
        break;
      }
      case UniquePromoErrors.FailedToUploadCSVFile: {
        if (this.editMode) {
          errorTitleTranslateKey = "FAILED_TO_UPLOAD_CSV_CHANGES_SAVED";
        } else {
          errorTitleTranslateKey = "FAILED_TO_UPLOAD_CSV_PROMO_CREATED";
        }
        break;
      }
      default: {
        if (this.editMode) {
          errorTitleTranslateKey = "FAILED_TO_UPLOAD_CSV_CHANGES_SAVED";
        } else {
          errorTitleTranslateKey = "FAILED_TO_UPLOAD_CSV_PROMO_CREATED";
        }
        break;
      }
    }
    this.errorTitle = this.translate.instant(
      `${this.translationPrefix}.${errorTitleTranslateKey}`
    );
  }

  private handleSuccess(
    createNewUniquePromoCodeResults: ICreateNewUniquePromoCodeResults
  ) {
    this.isLoading = false;
    this.foundTotalMessage = this.translate.instant(
      `${this.translationPrefix}.FOUND_TOTAL_X_CODES_IN_FILES`,
      { codes: this.createNewUniquePromoCodeResults.totalFound }
    );
  }

  private setModalConfig() {
    this.modalConfig = {
      hideFooterBorder: false,
      noBodyPadding: true,
      title: this.titleModalText,
      buttons: this.getButtons(),
    };
  }

  private getButtons(): IModalButtonConf[] {
    return [
      {
        action: () => {
          this.confirmModal();
        },
        class: "btn-primary",
        label: this.translate.instant(`${this.translationPrefix}.OK`),
        isDisabled: () => {
          return this.isLoading;
        },
      },
    ];
  }

  confirmModal() {
    if (this.createdOrUpdatedSuccessfully) {
      this.backToList();
    } else {
      this.closeModal();
    }
  }

  backToList() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/user-settings/uniquePromoCodes"])
    );
    window.open("/#" + url, "_self");
  }

  closeModal() {
    this.modalRef.hide();
  }
}
