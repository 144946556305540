import { SearchItem } from './../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';
import { EventEmitter, OnDestroy } from '@angular/core';
import { ActivityAttribute } from './../../../../../features/customer360/customer360.models';
import { Component, Input, OnInit, Output } from '@angular/core';
import { IModalConf } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SearchListConfig } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { TranslateService } from '@ngx-translate/core';
import { purchaseHistoryTranslateKeys } from '../../../customer360.translate.key';

@Component({
  selector: 'select-attributes-modal',
  templateUrl: './selectAttributesModal.component.html',
  styleUrls: ['./selectAttributesModal.component.scss']
})
export class SelectAttributesModalComponent implements OnInit, OnDestroy {
  @Input() attributesToSelect: ActivityAttribute[];
  @Input() selectedAttributes: ActivityAttribute[] = [];
  @Input() activityType: string = "";
  @Output() onDone: any;

  public translateKeys = purchaseHistoryTranslateKeys;
  public modalConf: IModalConf;
  public form: FormGroup;

  private subscriptions: Subscription[] = [];
  private initialSelectedAttributes: ActivityAttribute[] = [];
  
  public defaultAttributeItem: SearchItem[] = [{
    RealFieldName: "PurchaseDate", 
    AliasAttributeName: "Purchase Date"
  } as ActivityAttribute as SearchItem];

private defaultAttributeMultiAttributeFlowItem: SearchItem[] = [{
  RealFieldName: "TransactionDate", 
  AliasAttributeName: "Transaction Date"
} as ActivityAttribute as SearchItem];

  public attributeSearchListConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.MODAL_SEARCH_LIST_PLACEHOLDER),
    keyProperty: "RealFieldName",
    valueProperty: "AliasAttributeName",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.MODAL_SEARCH_LIST_PLACEHOLDER),
    isMultiSelect: false
  };

  constructor(private bsModalRef: BsModalRef, private translate: TranslateService,) { }

  ngOnInit(): void {    
    this.defaultAttributeItem = this.activityType.length > 0 ? this.defaultAttributeMultiAttributeFlowItem : this.defaultAttributeItem;
    this.setModalConf();
    this.form = this.createForm();
    this.initialSelectedAttributes = this.selectedAttributes;
    this.defaultAttribute.disable({emitEvent: false});
    this.form.markAsPristine();
    this.initFormSubs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onRemoveAttribute(index: number) {
    this.attributes.removeAt(index);
    this.attributes.markAsDirty();
  }

  public onAddAttribute() {
    this.attributes.push(new FormControl('', [Validators.required]));
  }

  get defaultAttribute(): FormArray {
    return this.form.get('defaultAttribute') as FormArray
  }

  get attributes(): FormArray {
    return this.form.get('attributes') as FormArray
  }

  private createForm() {
    const attributes: FormControl[] = this.selectedAttributes.map(attribute => {
      return new FormControl([attribute as SearchItem], [Validators.required]);
    });

    return new FormGroup({
      defaultAttribute: new FormControl(this.defaultAttributeItem),
      attributes: new FormArray(attributes),
    });
  }

  private initFormSubs() {
    const sub$1 = this.attributes.valueChanges.subscribe((attributes) => { 
      this.selectedAttributes = this.attributes.value.map(x => x[0]);
    });
    this.subscriptions.push(sub$1);
  }

  private setModalConf(): void {
    this.modalConf = {
      title: `${this.translate.instant(this.translateKeys.TITLE_MODAL)} ${this.activityType}`,
      xButton: {action: () => this.bsModalRef.hide()},
      buttons:[
        {
            isDisabled: () => this.form.invalid 
                           || this.form.pristine 
                           || this.selectedAttributes.length === 0 
                           || this.isSelectedAttributesEquelToInitial(),
            class: 'btn btn-primary',
            label: this.translate.instant(this.translateKeys.DONE),
            action: this.onDoneBtnClicked.bind(this),
        },
        {
            isDisabled: () => false,
            class: 'btn-default',
            label: this.translate.instant(this.translateKeys.CANCEL),
            action: this.closeModal.bind(this),
        }
      ],
      withScale: false,
    };
  }

  private onDoneBtnClicked() {
    this.onDone(this.selectedAttributes);
    this.closeModal();
  }
  
  private closeModal() {
    this.bsModalRef.hide()
  }

  private isSelectedAttributesEquelToInitial(): boolean {
    return this.selectedAttributes.length === this.initialSelectedAttributes.length 
    && this.selectedAttributes.every((x, i) => this.initialSelectedAttributes[i].RealFieldName === x.RealFieldName);
  }
}
