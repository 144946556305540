import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/featureFlag.service';

@Directive({
  selector: '[ifFeatureFlag]'
})
export class IfFeatureFlagDirective {

  constructor(
    private featureFlagSerice: FeatureFlagService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }
    
    @Input()
    set ifFeatureFlag(val) {
      if(this.featureFlagSerice.isEnabled(val)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
}
