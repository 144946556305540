<div class="row high-chart-header">
    <div class="col-md-6">
        <h3 translate>
            {{ translateKeys.subtitle }}
        </h3>
    </div>

    <div class="col-md-6 text-right inline-trend">
        <div class="row trend-by">
            <div class="trend-label col-md-4">
                <label translate>
                    {{ translateKeys.viewTrendBy }}
                </label>
            </div>
            <div class="trend-list col-md-5 stick-left">
                <opti-search-list *ngIf="searchListConfig && trendByOptions"
                                  data-qa-id="TrendBySearchList"
                                  [config]="searchListConfig"
                                  [items]="trendByOptions"
                                  [formControl]="trendByControl"
                ></opti-search-list>
                <add-kpis-modal #modal
                                (onSubmit)="refreshTrendByOptions($event)"
                ></add-kpis-modal>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <highcharts-chart
                data-qa-id="HighchartsChart"
                *ngIf="!dateRangeChanged"
                [options]="activityTrendChartConfiguration"
                style="width: 100%; height: 500px; display: block;"
        ></highcharts-chart>
    </div>
</div>