import { Component, Input } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from "@ag-grid-community/core";

@Component({
    templateUrl: './addAttributeHeader.component.html'
})
export class AddAttributeHeaderComponent implements IHeaderAngularComp  {

    public disableAddAttr = false;

    agInit(params: any): void  {
        this.disableAddAttr = params.context.disableAddAttr || params.context.isLockedDuringPublishing;
    }

    refresh(params: IHeaderParams): boolean {
        return true
    }
}
