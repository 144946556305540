import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { ModalOptions } from 'ngx-bootstrap/modal/public_api';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { OslCellType } from 'src/app/components/optiSearchList/models/osl-cell-type.enum';
import {
  SearchItem,
  SearchListConfig
} from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { PurchaseHistoryFamily } from 'src/app/features/settings/attributes/attributesList.enums';
import {
  BaseAttribute,
  CalculationSectionComponent,
  defaultTimeframes,
  functionList,
  PurchaseHistoryAttribute,
  PurchaseHistoryFlowItem
} from 'src/app/features/settings/attributes/attributesList.model';
import {
  PurchaseAttributeConditionComponent
} from 'src/app/features/settings/attributes/components/purchaseHistoryAttribute/purchaseAttributeCondition/purchaseHistoryAttributeCondition.component';
import {
  ConditionalValidatorService
} from 'src/app/features/settings/attributes/services/conditionalValidator.service';
import { PurchaseAttributesService } from 'src/app/features/settings/attributes/services/purchaseAttributes.service';
import { FeatureFlag, FeatureFlagService } from 'src/app/services/featureFlag.service';
import { AttributeHelper } from '../../../attributesList.helper';

@Component({
  selector: 'purchase-attribute-calculation-section',
  templateUrl: './purchaseHistoryAttributeCalculationSection.component.html',
  styleUrls: ['./purchaseHistoryAttributeCalculationSection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PurchaseCalculationSectionComponent,
      multi: true
    }]
})
export class PurchaseCalculationSectionComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, CalculationSectionComponent {
  @ViewChildren('condition') conditionComponents: QueryList<PurchaseAttributeConditionComponent>;
  @Output() isFormCreated: EventEmitter<boolean> = new EventEmitter();

  public activityTypes: SearchItem[] = [];
  public timeframes: SearchItem[] = [];
  public numericAttributes: SearchItem[] = [];
  public textAttributes: SearchItem[] = [];
  public functionList: SearchItem[] = [];
  public isAddButtonDisabled: boolean;
  public maxConditionsCount: number = 1;
  
  private currentFunction: SearchItem;

  public get hasFuncValue(): boolean {
    return this.currentFunction && this.currentFunction.key;
  };

  public get isTextFunction(): boolean {
    return this.currentFunction ? ['first','last','mostFrequent','mostSpent'].includes(this.currentFunction.key) : false;
  };
  
  public get isNumericFunction(): boolean {
    return this.currentFunction ? this.currentFunction.key == 'sum' : false;
  };

  public get isWithMetric(): boolean {
    return this.currentFunction ? this.currentFunction.key === 'mostSpent' : false;
  }
  
  public get hasActivityTypes(): boolean {
    return this.activityTypes?.length > 1;
  };
  public get needShowTimeframePeriod(): boolean {
    return this.timeframe?.value && (this.timeframe.value[0].key === 1 || this.timeframe.value[0].key === 2);
  };

  public translateKeys = translateKeys;
  public form: FormGroup;
  public activityTypesSearchListConfig: SearchListConfig;
  public timeframeSearchListConfig: SearchListConfig;
  public resultAttributeSearchListConfig: SearchListConfig;
  public funcSearchListConfig: SearchListConfig;

  private subscriptions: Subscription[] = [];
  private PHFlowData;
  private isFormDisabledByDefault: boolean = false;

  constructor(private purchaseHistoryService: PurchaseAttributesService,
    private conditionValidator: ConditionalValidatorService,
    private modalService: OptiLogicModalService,
    private translate: TranslateService,
    private featureFlagService: FeatureFlagService) { }

  async ngOnInit(): Promise<void> {
    this.initSearchListsConfigs();
    this.form = this.createForm();
    this.initFunctions();
    this.setDisabledState(this.isFormDisabledByDefault);
    this.isFormCreated.emit(true);

    await this.initFlowData();
    this.initFormSubscriptions();
  }

  ngAfterViewInit(): void {
    const subscription$1 = this.conditionComponents.changes.subscribe(conditionComponents => {
      this.validateCondition(true, conditionComponents);
    });
    this.subscriptions.push(subscription$1);
  }

  addAttributeCondition(emitEvent: boolean = true) {
    this.conditions.push(new FormControl('', [Validators.required]), { emitEvent });
    if (emitEvent) this.conditions.updateValueAndValidity();
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionsCount;
  }

  onRemoveAttributeCondition(index: number) {
    this.conditions.removeAt(index);
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionsCount;
  }

  buildAttributeBody(initialAttribute: BaseAttribute): PurchaseHistoryAttribute {
    return this.isTextFunction
            ? this.buildAttributeBodyWithTextFunc(initialAttribute)
            : this.isNumericFunction 
              ? this.buildAttributeBodyWithSumFunc(initialAttribute)
              : null;
  }

  private buildAttributeBodyWithTextFunc (initialAttribute: BaseAttribute): PurchaseHistoryAttribute {

    // ProductAttributes = 0      ==> 2
    // TransactionAttributes = 1 with dropdown list  ==> 3
    // TransactionAttributes = 1 without dropdown list  ==> 1
    const resAttribute = this.textAttributes.find(x => this.resultAttribute.value[0].key === x.key);

    const attributeType = resAttribute?.attributeType === 0 
        ? 2 
        : resAttribute?.hasValuesList ? 3 : 1;

    const attribute = {
      ActivityTypeId: this.activityType.value[0].key,
      Conditions: [], 
      AttributeType: attributeType,
      TimeframeId: this.timeframe.value[0].key,
      TimeframePeriod: this.timeframePeriod.value,
      ResultAttribute: this.resultAttribute.value[0].key,
      AttributeBaseType: 'string',
      FunctionName: AttributeHelper.getFunctionType(this.currentFunction.key),
      Family: AttributeHelper.getAttributeFamily(this.currentFunction.key, false),
      FieldFormat: this.resultAttribute.value[0].attributeFormat,
      Formatting: this.resultAttribute.value[0].attributeFormat,
      TransactionAttributeID: this.resultAttribute.value[0].attributeID,
      FieldName: initialAttribute.FieldName,
      Description: initialAttribute.Description,
      PublishStatus: initialAttribute.PublishStatus,
      Type: initialAttribute.Type,
      IsHidden: initialAttribute.IsHidden,
      IsPersonalization: initialAttribute.IsPersonalization,
    } as PurchaseHistoryAttribute;

    if (attribute.Family === PurchaseHistoryFamily.New_PurchaseHistoryMostSpent) {
      attribute.MetricTransactionAttributeID = this.metricAttribute.value[0].attributeID
    }
    
    return attribute;
  }

  private buildAttributeBodyWithSumFunc (initialAttribute: BaseAttribute): PurchaseHistoryAttribute {
    return {
      ActivityTypeId: this.activityType.value[0].key,
      Conditions: this.conditionComponents.map(x => x.buildCondition(PurchaseHistoryFamily.New_PurchaseHistory)),
      TimeframeId: this.timeframe.value[0].key,
      TimeframePeriod: this.timeframePeriod.value,
      ResultAttribute: this.resultAttribute.value[0].key,
      AttributeBaseType: 'number',
      FunctionName: AttributeHelper.getFunctionType(this.currentFunction.key),
      Family: AttributeHelper.getAttributeFamily(this.currentFunction.key, false),
      FieldFormat: this.resultAttribute.value[0].attributeFormat,
      Formatting: this.resultAttribute.value[0].attributeFormat,
      TransactionAttributeID: this.resultAttribute.value[0].attributeID,
      FieldName: initialAttribute.FieldName,
      Description: initialAttribute.Description,
      PublishStatus: initialAttribute.PublishStatus,
      Type: initialAttribute.Type,
      IsHidden: initialAttribute.IsHidden,
      IsPersonalization: initialAttribute.IsPersonalization,
    } as PurchaseHistoryAttribute;
  }

  isInputEventIsRegularNumber(event) {
    // Between 1-9 validation 
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  // ====| ValueAccessor region |==== \\

  async writeValue(attribute: PurchaseHistoryAttribute): Promise<void> {
    if (!attribute) {
      return;
    }
    if (!this.PHFlowData || this.activityTypes.length === 0) {
      await this.initFlowData();
    }
    this.initAttributeData(attribute);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.activityType.disable({ emitEvent: false });
      this.conditions.disable({ emitEvent: false });
      this.timeframe.disable({ emitEvent: false });
      this.timeframePeriod.disable({ emitEvent: false });
      this.resultAttribute.disable({ emitEvent: false });
      this.metricAttribute.disable({ emitEvent: false });
      this.func.disable({ emitEvent: false });
    }
    else {
      this.activityType.enable({ emitEvent: false });
      this.conditions.enable({ emitEvent: false });
      this.timeframe.enable({ emitEvent: false });
      this.timeframePeriod.enable({ emitEvent: false });
      this.resultAttribute.enable({ emitEvent: false });
      this.metricAttribute.enable({ emitEvent: false });
      this.func.enable({ emitEvent: false });
    }
    this.isFormDisabledByDefault = isDisabled;
  }

  registerOnChange(fn: any): void { this.onChange = fn }

  onChange = (fn: any) => { }

  registerOnTouched(fn: any): void { this.onTouched = fn }

  onTouched = (fn: any) => { }

  // ====| FormControls region |==== \\

  get activityType(): FormControl {
    return this.form?.get('activityType') as FormControl;
  }

  get conditions(): FormArray {
    return this.form?.get('conditions') as FormArray;
  }

  get timeframe(): FormControl {
    return this.form?.get('timeframe') as FormControl;
  }

  get timeframePeriod(): FormControl {
    return this.form?.get('timeframePeriod') as FormControl;
  }

  get resultAttribute(): FormControl {
    return this.form?.get('resultAttribute') as FormControl;
  }

  get metricAttribute(): FormControl {
    return this.form?.get('metricAttribute') as FormControl;
  }

  get func(): FormControl {
    return this.form?.get('func') as FormControl;
  }

  // ====| private region |==== \\

  private createForm(): FormGroup {
    return new FormGroup({
      func: new FormControl('', [Validators.required]),
      activityType: new FormControl('', [Validators.required]),
      conditions: new FormArray([new FormControl('', [Validators.required])], [Validators.required, this.conditionValidator.validFormArray()]),
      timeframe: new FormControl('', [Validators.required]),
      timeframePeriod: new FormControl('', [Validators.required]),
      resultAttribute: new FormControl('', [Validators.required]),
      metricAttribute: new FormControl('', [Validators.required]),
    });
  }

  private initFormSubscriptions() {
    this.subscriptions.push(
      this.func.valueChanges.subscribe(this.onFuncChanged.bind(this)),
      this.activityType.valueChanges.subscribe(this.onActivityTypeChanged.bind(this)),
      this.conditions.valueChanges.subscribe(this.onConditionsChanged.bind(this)),
      this.timeframe.valueChanges.subscribe(this.onTimeframesChanged.bind(this)),
      this.form.valueChanges.subscribe(formValue => { this.onChange(formValue) })
    );
  }


  private initFunctions() {
    this.functionList = this.featureFlagService.isEnabled(FeatureFlag.PurchaseHistoryAttributesNewScenario)
          ? [...functionList] : functionList.filter(x => !['first','last','mostFrequent','mostSpent'].includes(x.key));
    this.currentFunction = this.functionList[0];
  }
  private async initFlowData(): Promise<void> {
    this.initFunctions();

    this.timeframes = defaultTimeframes.map(x => {
      return {
        key: x.key,
        value: x.value,
        groupId: x.groupId,
        order: x.order,
        type: OslCellType.Item
      } as SearchItem;
    });

    this.PHFlowData = await this.purchaseHistoryService.GetPHFlowData().pipe(first()).toPromise();
    this.activityTypes = this.purchaseHistoryService.getActivityTypes(this.PHFlowData);

    if (this.numericAttributes.length === 0 && this.textAttributes.length === 0) {
      this.textAttributes = this.getAttributes(this.activityTypes[0].key, false);
      this.numericAttributes = this.getAttributes(this.activityTypes[0].key, true);
    }

    if (!this.func.value) {
      let defaultFunc = this.functionList.find(x => x.key === "sum");
      this.func.setValue(defaultFunc ? [ defaultFunc ] : [ this.functionList[0] ], { emitEvent: false });
      this.onFuncChangedApproved(this.func.value, false);
    }
  }

  private initAttributeData(attribute: PurchaseHistoryAttribute) {
    this.setDisabledState(this.isFormDisabledByDefault);

    const func = [ this.functionList.find(x => x.key.toLowerCase() === attribute.FunctionName.toLowerCase()) ];
    this.func.setValue(func, { emitEvent: false });
    this.currentFunction = func[0];

    const activityType = this.activityTypes.find(x => x.key === attribute.ActivityTypeId);
    this.activityType.setValue([activityType], { emitEvent: false });
    this.onActivityTypeChanged([activityType], false);

    const timeframe = this.timeframes.find(x => x.key === attribute.TimeframeId);
    this.timeframe.setValue([timeframe], { emitEvent: false });
    this.onTimeframesChanged([timeframe], false);

    if ([1, 2].includes(timeframe.key)) {
      this.timeframePeriod.setValue(attribute.TimeframePeriod, { emitEvent: false });
    }

    attribute.Conditions.forEach((x, i) => {
      if (!this.conditions.controls[i]) this.addAttributeCondition(false);
      this.conditions.controls[i].setValue(attribute.Conditions[i], { emitEvent: false });
    });

    switch (attribute.FunctionName) {
      case "First":
      case "Last":
      case "MostFrequent":
        this.resultAttribute.setValue([this.textAttributes.find(x => x.key === attribute.ResultAttribute)], { emitEvent: false });
        this.conditions.disable({ emitEvent: false });
        this.metricAttribute.disable({ emitEvent: false });
        break;
      case "MostSpent":
        this.resultAttribute.setValue([this.textAttributes.find(x => x.key === attribute.ResultAttribute)], { emitEvent: false });
        this.metricAttribute.setValue([this.numericAttributes.find(x => x.attributeID === attribute.MetricTransactionAttributeID)], { emitEvent: false });
        this.conditions.disable({ emitEvent: false });
        break;
      case "Sum":
      default:
        this.resultAttribute.setValue([this.numericAttributes.find(x => x.key === attribute.ResultAttribute)], { emitEvent: false });
        break;
    }
    
  }

  private onActivityTypeChanged(activityType: SearchItem[], emitEvent: boolean = true) {
    const currentFunction = this.func.value[0];
    if (activityType[0]?.key) {
      this.textAttributes = this.getAttributes(activityType[0].key, false);
      this.numericAttributes = this.getAttributes(activityType[0].key, true);
    }

    switch (currentFunction?.key) {
      case 'first':
      case 'last':
      case 'mostFrequent':
      case 'mostSpent': {
        if (this.textAttributes.length > 1 && !this.isFormDisabledByDefault) {
          this.resultAttribute.enable({ emitEvent: false });
        }
        else if (this.textAttributes.length === 1) {
          this.resultAttribute.setValue(this.textAttributes, { emitEvent: false });
          this.resultAttribute.enable({ emitEvent: false });
        }
        else {
          this.resultAttribute.setValue('', { emitEvent: false });
          this.resultAttribute.disable({ emitEvent: false });
        }

        if (currentFunction?.key === 'mostSpent') {
          if (this.numericAttributes.length > 1 && !this.isFormDisabledByDefault) {
            this.metricAttribute.enable({ emitEvent: false });
          }
          else if (this.numericAttributes.length === 1) {
            this.metricAttribute.setValue(this.numericAttributes, { emitEvent: false });
            this.metricAttribute.enable({ emitEvent: false });
          }
          else {
            this.metricAttribute.setValue('', { emitEvent: false });
            this.metricAttribute.disable({ emitEvent: false });
          }
        }
        break;
      }
      case 'sum': 
      default: {
        if (this.numericAttributes.length > 1 && !this.isFormDisabledByDefault) {
          this.resultAttribute.enable({ emitEvent: false });
        }
        else if (this.numericAttributes.length === 1) {
          this.resultAttribute.setValue(this.numericAttributes, { emitEvent: false });
          this.resultAttribute.enable({ emitEvent: false });
        }
        else {
          this.resultAttribute.setValue('', { emitEvent: false });
          this.resultAttribute.disable({ emitEvent: false });
        }
        this.metricAttribute.setValue('', { emitEvent: false });
        this.metricAttribute.disable({ emitEvent: false });
        this.resetConditions(emitEvent);
        break;
      }
    }
  }

  private getAttributes(activityType: any = null, isNumeric: boolean = false): SearchItem[] {
    let attrs: PurchaseHistoryFlowItem[] = [];
    if (activityType) {
      attrs = (this.PHFlowData[activityType].attributes as PurchaseHistoryFlowItem[]);
    }
    else {
      for (const prop in this.PHFlowData) {
        if (this.PHFlowData.hasOwnProperty(prop) && this.PHFlowData[prop].attributes?.length) {
          attrs.push(...this.PHFlowData[prop].attributes);
        }
      }
    }
    attrs = isNumeric
        ? attrs.filter(x => ['decimal', 'int', 'number'].includes(x.attributeDataType.toLowerCase()))
        : attrs.filter(x => !['decimal', 'int', 'number'].includes(x.attributeDataType.toLowerCase()));

    return attrs.map(x => {
      return {
        key: x.realFieldName,
        value: x.aliasAttributeName,
        attributeID: x.attributeId,
        attributeType: x.attributeType,
        dataType: x.attributeDataType.toLowerCase(),
        attributeFormat: x.attributeFormat,
        hasValuesList: x.hasValuesList
      } as SearchItem
    });
  }

  private onTimeframesChanged(timeframes: SearchItem[], emitEvent: boolean = true) {
    if ([1, 2].includes(timeframes[0].key)) {
      this.timeframePeriod.setValidators([Validators.required]);
    } else {
      this.timeframePeriod.setValue(1, { emitEvent: false });
      this.timeframePeriod.setValidators([]);
    }
    this.timeframePeriod.updateValueAndValidity({ emitEvent });
  }

  private onFuncChanged(funcs: SearchItem[], emitEvent: boolean = false) {
    const isCalcBodyPristine = () => { return this.purchaseHistoryService.isCalculationBodyPristine(this.form, this.func) };
    
    if (funcs && this.isNewFuncType(funcs[0]) && !isCalcBodyPristine()) {
      this.openConfirmModal(() => {
        this.discardFuncChanging([this.currentFunction]);
      }, () => {
        this.onFuncChangedApproved(funcs, emitEvent);
      });
    }
    else if (funcs) {
      this.onFuncChangedApproved(funcs, emitEvent);
    }
  }

  private onFuncChangedApproved(funcs: SearchItem[], emitEvent: boolean = true) {
    const func = funcs && funcs.length ? funcs[0] : null;
    if (func) {
      this.updateForm(func?.key ?? 'sum');
      const activityType = this.activityTypes[0];
      this.activityType.setValue([ activityType ], { emitEvent: false });
      this.onActivityTypeChanged([ activityType ], false);
    }
    this.currentFunction = func;
  }

  private discardFuncChanging(prevFuncValue: SearchItem[]) {
    this.func.setValue(prevFuncValue, {emitEvent: false});
  }

  private isNewFuncType(nextFunc: SearchItem) {
    // return true if the type of the current function is not the same as the type of the next function   
    return (!this.currentFunction && nextFunc) 
        || (this.isTextFunction && nextFunc.key === "sum") 
        || (!this.isWithMetric && nextFunc.key === 'mostSpent')
        || ((this.isNumericFunction || this.isWithMetric) && ['first','last','mostFrequent'].includes(nextFunc.key));
  }

  private onConditionsChanged(conditions) {
    if (conditions.length > 0 && this.conditionComponents) {
      this.validateCondition(false);
    }
  }

  private updateForm(func) {
    switch (func) {
      case 'first':
      case 'last':
      case 'mostFrequent':
        this.conditions.disable({ emitEvent: false });
        this.metricAttribute.disable({ emitEvent: false });
        break;
      case 'mostSpent':
        this.metricAttribute.enable({ emitEvent: false });
        this.conditions.disable({ emitEvent: false });
        break;
      case 'sum':
        this.conditions.enable({ emitEvent: false });
        this.metricAttribute.disable({ emitEvent: false });
      default:
        break;
    }
    for (const control in this.form.controls) {
      this.form.controls[control].markAsPristine();
      if (this.form.controls[control] !== this.func) {
        if (this.form.controls[control] === this.conditions) {
          this.form.controls[control].setValue([''], {emitEvent: false});
        }
        else {
          this.form.controls[control].setValue('', {emitEvent: false});
        }
      }
      
    }
  }

  private validateCondition(emitEvent: boolean, conditionComponents: QueryList<PurchaseAttributeConditionComponent> = null) {
    this.conditions.controls.forEach((x, i) => {
      let invalidCondition = false;
      let areSomeControlsDisabled = false;
      const conditionComponent = conditionComponents ? conditionComponents.get(i) : this.conditionComponents.get(i);

      if (conditionComponent) {
        invalidCondition = conditionComponent.form.invalid;

        for (const control in conditionComponent.form.controls) {
          areSomeControlsDisabled = conditionComponent.form.controls[control].disabled;
          if (areSomeControlsDisabled) break;
        }
      }
      else {
        invalidCondition = true;
      }

      x.setErrors(invalidCondition || areSomeControlsDisabled ? { 'invalidCondition': true } : null, { emitEvent })
    });
  }

  private initSearchListsConfigs() {
    let defaultSearchListConfig = {
      keyProperty: "key",
      valueProperty: "value",
      isMultiSelect: false,
    };

    this.activityTypesSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.activityType,
      placeholderTranslateKey: this.translateKeys.activityType,
    }

    this.timeframeSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.timeframe,
      placeholderTranslateKey: this.translateKeys.timeframe,
    }

    this.resultAttributeSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.select,
      placeholderTranslateKey: this.translateKeys.select,
    }

    this.funcSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.select,
      placeholderTranslateKey: this.translateKeys.select,
    }
  }

  private resetConditions(emitEvent: boolean = true) {
    this.conditions.clear({ emitEvent: false });
    this.conditionComponents = new QueryList<PurchaseAttributeConditionComponent>();
    this.addAttributeCondition(emitEvent);
    if (this.isFormDisabledByDefault) {
      this.conditions.disable({ emitEvent: false });
      this.metricAttribute.disable({ emitEvent: false });
    }
  }

  private openConfirmModal(confirmAction: () => void, discardAction: () => void) {
    if (this.form.dirty) {
      this.modalService.openModalMessage(
        'sm',
        {
          message: this.translate.instant(this.translateKeys.confirmBackMessage),
          buttons: [
            {
              class: 'btn-primary',
              label: this.translate.instant(this.translateKeys.continueEditing),
              action: confirmAction.bind(this),
            },
            {
              class: 'btn-default',
              label: this.translate.instant(this.translateKeys.discardChanges),
              action: discardAction.bind(this),
            },
          ]
        },
        <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
      );
    }
    else {
      confirmAction();
    }
  }
}

const translateKeys = {
  attributeCalculation: 'features.user_settings.body.attributes.addCalculatedAttribute.ATTRIBUTE_CALCULATION',
  activityType: 'features.user_settings.body.attributes.purchaseAttributes.ACTIVITY_TYPE',
  of: 'features.user_settings.body.attributes.purchaseAttributes.OF',
  timeframe: 'features.user_settings.body.attributes.purchaseAttributes.TIMEFRAME',
  days: 'features.user_settings.body.attributes.purchaseAttributes.DAYS',
  daysAgo: 'features.user_settings.body.attributes.purchaseAttributes.DAYS_AGO',
  attributes: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTES',
  attribute: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTE',
  add: 'features.user_settings.body.attributes.purchaseAttributes.ADD',
  attributeResultLable: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTE_RESULT_LABLE',
  select: 'features.user_settings.body.attributes.purchaseAttributes.SELECT',
  confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
  continueEditing: 'general.CONTINUE_EDITING',
  discardChanges: 'general.DISCARD_CHANGES',
  where: 'features.user_settings.body.attributes.purchaseAttributes.WHERE',
  function: 'features.user_settings.body.attributes.purchaseAttributes.FUNCTION',
  in: 'features.user_settings.body.attributes.purchaseAttributes.IN',
  on: 'features.user_settings.body.attributes.purchaseAttributes.ON'
};
