export interface TemplateTestEmailRequest {
	channelID: number;
	emailAddresses: string;
	templateJson: string;
	idSelectionValue: number;
	isShouldAddPrefix: boolean;
	customerId: string;
	containsNonVisitorTags: boolean;
}

export interface TemplateTestEmailResponse {
	isSuccess: boolean;
	errorMsg: ErrorMessage | string;
	data: any;
}

export enum ErrorMessage {
	customerNotExists = 'CustomerNotExists'
}
