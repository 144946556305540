/* eslint-disable indent */
import { AttributesManagerService } from './../../services/attributesManager.service';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { OptiLogicModalService } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { OptiSearchListDropDownAlignHorizontal } from "../../../../../components/optiSearchListDropDown/models/optiSearchListDropDownAlignHorizontal.enum";
import { Router } from "@angular/router";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { BaseAttribute, AttributeLog } from "../../attributesList.model";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { FeatureFlag, FeatureFlagService } from '../../../../../services/featureFlag.service';
import { AttributeHelper } from "../../attributesList.helper";
import { filter, first } from "rxjs/operators";
import { AttributeValidatorService } from '../../services/attributeValidator.service';
import { AttributesListService } from '../../services/attributesList.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'edit-base-attribute',
    templateUrl: './editBaseAttribute.component.html',
    styleUrls: ['./editBaseAttribute.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class EditBaseAttributeComponent implements OnInit {

    public editAttributeForm: FormGroup;
    public isSaving = false;
    public attributeStatus = {
        color: ColoredTagType.Type4,
        description: "Published"
    };
    public error: SaveAttributeError = {
        displayName: false,
        message: ""
    }
    public optiSearchListDropDownAlignHorizontal = OptiSearchListDropDownAlignHorizontal;
    public translateKeys = translateKeys;
    public attribute: BaseAttribute;
    public lastUpdate: string;
    public isPersonalozationTag: boolean;
    public IsPersonalizationCheckBoxChecked: boolean;
    public isAttributeVisibilityChanged: boolean;
    public canAttributeBeHidden = false;
    constructor(
        private formBuilder: FormBuilder,
        private attrManagerService: AttributesManagerService,
        private router: Router,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private translate: TranslateService,
        private featureFlagService: FeatureFlagService,
        private attributeValidatorService: AttributeValidatorService,
        private attributeListService: AttributesListService)
    {
        this.isPersonalozationTag = this.featureFlagService.isEnabled(FeatureFlag.isPersonalisationTagAttribute)
    }

    ngOnInit() {
        this.createForm();
        this.setData();
    }
    private setData() {
        const attribute = this.router.getCurrentNavigation().extras.state as BaseAttribute;
        if (attribute) {
            this.attributeValidatorService.canAttributeBeHidden(attribute.FieldName).pipe(first()).subscribe((res) => {
                this.canAttributeBeHidden = res.IsSuccess;
            });

            this.attribute = attribute;
            this.attribute.OldName = this.attribute.Name;
            this.displayNameFormControl.setValue(this.attribute.DisplayName);
            this.descriptionFormControl.setValue(this.attribute.Description);
            this.attrManagerService.getLastActivity(this.attribute.Name)
            .pipe(first(), filter(x => !!x))
            .subscribe((res: AttributeLog) => {
                this.lastUpdate = `Last modified on ${AttributeHelper.getTimestamp(res.Time)} by ${res.User}`
            });
        }
    }

    private createForm() {
        this.editAttributeForm = this.formBuilder.group({
            displayName: ['', [Validators.required, Validators.maxLength(100)]],
            description: [],
        });
    }

    public changePersonalization(isPeronalization: boolean){
        this.IsPersonalizationCheckBoxChecked = !this.IsPersonalizationCheckBoxChecked;
        this.attribute.IsPersonalization = !isPeronalization;
    }

    public changeAttributeVisibility(showAttribute: boolean) {
        this.isAttributeVisibilityChanged = !this.isAttributeVisibilityChanged;
    }

    public saveAttributeClick() {
        if(!this.attribute.IsPersonalization && this.IsPersonalizationCheckBoxChecked) {
            this.modalService.openModalMessage(
                'md',
                {
                    title: "Personalization tag successfully removed",
                    message: "Templates that already include this attribute will not be affected, and will continue to work as normal",
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: 'OK',
                            action: this.updateAttribute.bind(this)
                        },
                    ]
                },
                <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
            );
        }else
        {
            this.updateAttribute();
        }
    }
    
    public isPublished(): boolean {
        return this.attribute.PublishStatus === "Published";
    }

    private updateAttribute()
    {
        this.setupAttribute();

        forkJoin(
    		this.isAttributeVisibilityChanged 
    			? {
    				updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "Regular"),
    				changeAttributeVisibility: this.attributeListService.changeAttributeVisibility(this.attribute.FieldName, !this.attribute.IsHidden)
    			} 
    			: {
    				updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "Regular")
    			}
    	).pipe(first()).subscribe(async (res) => {
    		if (res.updateAttribute.IsSuccess) {
                await this.attrManagerService.updateCustomerAttributes();
                this.router.navigate(['/user-settings/attributes/list'], {
                    state: {
                        name: this.attribute.DisplayName,
                        action: "update"
                    }
                });
            } else {
                this.updateErrorState(res.updateAttribute.ErrorMsg);
                if (this.error.displayName) {
                    const attr = this.attrManagerService.searchInProfile(this.attribute.DisplayName);
                    this.showNotUniqueAttribute(attr?.DisplayName);
                }
            }
    	}, (err) => console.error(err));
    }

    private updateErrorState(error: string) {
        this.error.displayName = error === "is not unique";
        this.error.message = this.error.displayName 
            ? this.translate.instant(this.translateKeys.errorMsg)
            : error;
    }

    private setupAttribute() {
        this.updateErrorState("");
        if (this.attribute) {
            this.attribute.DisplayName = this.displayNameFormControl.value;
            this.attribute.Description = this.descriptionFormControl.value;
        }
    }

    showNotUniqueAttribute(attributeName: string) {
        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant(translateKeys.errorMsg, { attributeName: attributeName }),
                buttons: [{
                    class: 'btn-primary',
                    label: 'OK',
                    action: this.closeModal.bind(this)
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    public openConfirmModal(){
        if(this.editAttributeForm.dirty) {
            this.modalService.openModalMessage(
                'sm',
                {
                    message: this.translate.instant(this.translateKeys.confirmBackMessage),
                    buttons: [
                        {
                        class: 'btn-primary',
                        label: this.translate.instant(this.translateKeys.discardChanges),
                        action: this.discardChanges.bind(this),
                        },
                        {
                        class: 'btn-default',
                        label: this.translate.instant(this.translateKeys.continueEditing),
                        action: this.closeModal.bind(this),
                        },
                    ]
                },
                <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
                );
        }
        else {
            this.discardChanges();
        }
    }

    private discardChanges() {
        this.router.navigateByUrl("/user-settings/attributes/list");
        this.bsModalRef.hide();
    }

    private closeModal() {
        this.bsModalRef.hide();
    }

    get displayNameFormControl(): FormControl {
        return this.editAttributeForm.get('displayName') as FormControl;
    }

    get descriptionFormControl(): FormControl {
        return this.editAttributeForm.get('description') as FormControl;
    }
}

interface SaveAttributeError {
    displayName: boolean;
    message: string;
}

const translateKeys = {
    cancel: 'features.user_settings.body.attributes.addAttribute.CANCEL',
    save: 'features.user_settings.body.attributes.addAttribute.SAVE',
    select: 'features.user_settings.body.attributes.addAttribute.SELECT',
    displayName: 'features.user_settings.body.attributes.addAttribute.DISPLAY_NAME',
    description: 'features.user_settings.body.attributes.addAttribute.DESCRIPTION', 
    title: 'features.user_settings.body.attributes.addAttribute.TITLE',
    editTitle: 'features.user_settings.body.attributes.addAttribute.EDIT_TITLE',
    errorMsg: 'features.user_settings.body.attributes.addAttribute.ERROR_MSG',
    confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
    continueEditing: 'general.CONTINUE_EDITING',
    discardChanges: 'general.DISCARD_CHANGES',
    personalizationTag: 'features.user_settings.body.attributes.addAttribute.PERSONALIZATION'
};
