import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    AfterViewInit,
    Output,
    EventEmitter, OnDestroy
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BsDaterangepickerConfig, BsDaterangepickerDirective, DatepickerDateCustomClasses} from "ngx-bootstrap/datepicker";

@Component({
    selector: 'date-range-picker-inline',
    templateUrl: './dateRangePickerInline.component.html',
    styleUrls: ['./dateRangePickerInline.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerInlineComponent),
        multi: true
    }]
})

export class DateRangePickerInlineComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() dateRangePickerData?: IDateRangePicker;
    @Input() dateCustomClasses?: DatepickerDateCustomClasses[];
    @Output() dateRangeClicked = new EventEmitter<Event>();

    public isDisabled: boolean;
    public model: Date[];
    private onTouchedCallback: () => void;
    private onChangeCallback: (_: any) => void;
    private dateRangePickerContainer: HTMLElement;

    public bsConfig: Partial<BsDaterangepickerConfig> = {
        containerClass: 'theme-blue',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: false,
        adaptivePosition: false
    };

    @ViewChild('dateRangePicker', {static: false}) dateRangePicker;
    @ViewChild('drp', {static: false}) dateRangePickerPopup: BsDaterangepickerDirective;
    
    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateRangePickerData && changes.dateRangePickerData.previousValue !== changes.dateRangePickerData.currentValue) {
            this.bsConfig = {
                ...this.bsConfig,
                minDate: this.dateRangePickerData.minDate,
                maxDate: this.dateRangePickerData.maxDate,
            }
        }
    }
    
    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.observeDateRangeClick();
    }

    observeDateRangeClick() {
        this.dateRangePickerContainer = document.querySelector('bs-daterangepicker-inline-container');
        if (this.dateRangePickerContainer) {
            this.dateRangePickerContainer.addEventListener('click', this.getClickCallback());
        }
    }

    getClickCallback() {
        return (e: Event) => {
            this.dateRangeClicked.emit(e);
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(dates: Date[]): void {
        if (dates == null) {
            dates = [];
        }
        this.model = dates;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    onValueChange(e: Date[]): void {
        if (e == null || e.length == 0) {
            return;
        }

        if (this.onTouchedCallback) {
            this.onTouchedCallback();
        }
        
        this.onChangeCallback(e);
    }

    ngOnDestroy(): void {
        this.dateRangePickerContainer.removeEventListener('click', this.getClickCallback());
    }
}

export interface IDateRangePicker {
    minDate?: Date,
    maxDate?: Date,
}
