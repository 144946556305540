export class WebhookConfiguration{
    public integrationId: string;
    public webhookName: string;
    public webhookData: WebhookData;
    public integrationAttributes: string[] = [];
    public integrationIdentifier: string;
    public genericEngagerWebhookEvents: WebhookEvent[] = [];

    constructor(){
        this.webhookData = new WebhookData();
    }
}

export class WebhookData{
    public readonly eventId: number = 14;
    public emails: string;
    public channelId: number;
    public channelName: string;
    public webhookUrl: string;
    public webhookName: string;
    public additionalData: WebhookConfigurationAdditionalData;
    public isNew?: boolean;    

    constructor() {
        this.additionalData = new WebhookConfigurationAdditionalData();
    }
}

// The identifier and selectedCustomerAttributes will be deprecated when we fully switch to the new integrations service
export class WebhookConfigurationAdditionalData{
    public selectedCustomerAttributes: string[];
    public batchSize: number;
    public internalConfiguration: boolean = false;
    public identifier: string;
    public applyFormatting: boolean = false;
}

export interface WebhookChannel {
    id: number;
    actionChannelId?: number;
    name: string;
    order: number;
    groupType: number;
    isDisabled: boolean;
    account?: string;
    isExecuted: boolean;
    isRealTimeAPI: boolean;
    campaignType: any;
    channelProperty?: any;
    delayUnit?: any;
    delayValue?: number;
    executionChannelType?: number;
    realTimePanelTemplateType?: number;
}

export interface WebhookSettings {
    channels: WebhookChannel[];
    batchSizeMinLimit: number;
    batchSizeMaxLimit: number;
    events: WebhookEvent[];
}

export interface WebhookEvent {
    genericEngagerEventTypeID: number;
    genericEngagerEventName: string;
    webhookUrl?: string;
    webhookName?: string;
}