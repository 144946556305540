<div class="filter-base">
    <label class="filter-label">
        <input
            type="checkbox"
            name="realtime"
            [(ngModel)]="isSupportedByRealtime"
            (ngModelChange)="updateFilter()"
        />
        <p>Supported by realtime</p>
    </label>
    <label class="filter-label">
        <input
            type="checkbox"
            name="api"
            [(ngModel)]="isSupportedByAPI"
            (ngModelChange)="updateFilter()"
        />
        <p>Supported by API</p>
      </label>
    <label class="filter-label">
        <input
            type="checkbox"
            name="calculated"
            [(ngModel)]="isCalculated"
            (ngModelChange)="updateFilter()"
        />
        <p>Calculated</p>
    </label>
    <!--<label class="filter-label">
        <input
            type="checkbox"
            name="calculated"
            [(ngModel)]="isFormulaBased"
            (ngModelChange)="updateFilter()"
        />
        <p>Formula based</p>
    </label>-->
    <label class="filter-label">
        <input
            type="checkbox"
            name="database"
            [(ngModel)]="isDefault"
            (ngModelChange)="updateFilter()"
        />
        <p>Database</p>
    </label>
  </div>