import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from "@angular/core";

@Directive({
    selector: '[ellipsis-title]'
})

export class EllipsisTitleDirective implements AfterViewInit {

    @Input() lines: number;
    @Input() overrideTooltipMessage: string;

    private bindedHandler: Function;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        this.setDefaults();
        this.setElementStyle();

        this.setEventListener();

    }

    private setEventListener() {
        this.elementRef.nativeElement.addEventListener('mouseover', this.bindedHandler, true);
    }

    private addTitle() {
        if(this.shouldAddTitle()){
            const tooltipText = this.overrideTooltipMessage ? this.overrideTooltipMessage : this.elementRef.nativeElement.textContent.trim();
            this.renderer.setAttribute(this.elementRef.nativeElement, 'title', tooltipText);
        } else {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'title', '');
        }
    }


    private setDefaults() {
        this.bindedHandler = this.addTitle.bind(this);
        if (!this.lines) {
            this.lines = 1;
        }
    }

    private setElementStyle() {
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-multi-line-' + this.lines);
        this.renderer.setStyle(this.elementRef.nativeElement, '-webkit-box-orient', 'vertical');
    }

    private shouldAddTitle() {
        return (this.elementRef.nativeElement.scrollWidth > Math.ceil(this.elementRef.nativeElement.offsetWidth)) ||
            (this.elementRef.nativeElement.scrollHeight > Math.ceil(this.elementRef.nativeElement.offsetHeight));
    }

}