import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalButtonConf, IModalConf } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { IListItem } from "@optimove/ui-sdk/common/models";
import { MappingAttributes } from '../../models/response/mapping-attributes.model';
import { ImportedSelectedAttribute } from '../../models/internal/imported-selected-attribute.model';
import { ColumnMappingModalResult } from '../../models/internal/column-mapping-modal-result';
import { Subject } from 'rxjs';

@Component({
	selector: 'add-imported-customers-modal',
	templateUrl: './add-imported-customers-modal.component.html',
	styleUrls: ['./add-imported-customers-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AddImportedCustomersModalComponent implements OnInit {
  @Input() isInitialMapping: boolean;
  private readonly translationPrefix = "features.user_settings.body.importedCustomers.COLUMNS_MAPPING";
  private selectedAttributesPrev: ImportedSelectedAttribute[] = [];
  private titleModalText: string;
  private description: string;
  public isLoading = false;
  public loadingText: string;
  public modalConfig: IModalConf;
  public attributeItems: IListItem[];
  public attributesPreview: MappingAttributes[] = [];
  public selectedAttributes: ImportedSelectedAttribute[] = [];
  public isValid: boolean = true;

  public onClose: Subject<ColumnMappingModalResult> = new Subject<ColumnMappingModalResult>();

  constructor(private translate: TranslateService,
              public bsModalRef: BsModalRef){}


  ngOnInit(): void {
  	this.isLoading = true;
  	this.titleModalText = this.translate.instant(
  		`${this.translationPrefix}.COLUMNS_MAPPING_TITLE`
  	);
  	this.description = this.translate.instant(`${this.translationPrefix}.COLUMN_MAPPING_DESCRIPTION`);
  	this.loadingText = this.translate.instant(`${this.translationPrefix}.UPLOAD_CSV_LOADING_TEXT`);
  	this.setModalConfig(); 
  }

  public getSelectedAttributesSummary(){
  	return `${this.getMappedColumnsCount()}/${this.getTotalColumnsCount()}`;
  }

  public getButtons(): IModalButtonConf[] {
  	return [
  		{
  			action: () => {
  				this.confirmModal();
  			},
  			class: "btn-primary",
  			dataQaId: "apply-mapping",
  			label: this.translate.instant(`${this.translationPrefix}.APPLY_MAPPING_BUTTON`),
  			isDisabled: () => {
  				return this.isLoading || !this.isValid;
  			},
  		},
  		{
  			action: () => {
  				this.closeModal();
  			},
  			class: "btn-default",
  			label: this.translate.instant(`general.CANCEL`),
  		}
  	];
  }

  public getWarningTitle(): string{
  	let failedAttributes = this.selectedAttributes.filter(x=> x.isValid!=null && !x.isValid).map(x=> '"' + this.attributeItems.find(a => a.id === x.selectedAttributeRealFieldName).name + '"');
  	let unique = failedAttributes.filter((n, i) => failedAttributes.indexOf(n) === i);
  	return this.translate.instant(
  		`${this.translationPrefix}.DUPLICATION_COLUMN_MAPPING_GENERAL`, { parameters: unique.toString()}
  	);
  }

  public onSelectedAttributeChange(_: ImportedSelectedAttribute){
  	this.isValid = this.validateSelectedAttributes();
  }

  public closeModal(){
  	this.bsModalRef.hide();
  	if(!this.isInitialMapping){
  		this.selectedAttributes = this.selectedAttributesPrev;
  	}
  	this.onClose.next({
  		isConfirmed:false,
  		isReopened: !this.isInitialMapping,
  		totalColumns:this.getTotalColumnsCount(),
  		mappedColumns: this.getMappedColumnsCount(),
  		selectedAttributes: this.selectedAttributes,
  	} as ColumnMappingModalResult);
  }

  public loadData(mappingAttributes: MappingAttributes[], attributeItems: IListItem[], selectedAttributes: ImportedSelectedAttribute[]){
  	this.attributesPreview = mappingAttributes;
  	this.attributeItems = attributeItems;
  	this.selectedAttributes = selectedAttributes;

  	if(!this.isInitialMapping){
  		this.selectedAttributesPrev = selectedAttributes.map(x => {return {...x}});
  	}

  	this.isLoading = false;
  	window.dispatchEvent(new Event('resize'));
  }
  
  private setModalConfig() {
  	this.modalConfig = {
  		hideFooterBorder: false,
  		noBodyPadding: true,
  		title: this.titleModalText,
  		description: this.description,
  		buttons: this.getButtons(),
  		withScale: true
  	};
  }

  private getTotalColumnsCount(): number{
  	return this.attributesPreview.length;
  }

  private getMappedColumnsCount(): number{
  	return this.selectedAttributes.filter(x => x.selectedAttributeRealFieldName != null).length;
  }

  private confirmModal(){
  	if(this.isValid){
  		this.bsModalRef.hide();
  		this.onClose.next({
  			isConfirmed:true,
  			isReopened: !this.isInitialMapping,
  			totalColumns:this.getTotalColumnsCount(),
  			mappedColumns: this.getMappedColumnsCount(),
  			selectedAttributes: this.selectedAttributes,
  		} as ColumnMappingModalResult);
  	}
  }

  private validateSelectedAttributes(): boolean{
  	let isSelectionValid = true;
  	const attributesMap = this.groupByAttributeId();
  	this.selectedAttributes.map((val, i) => {
  		if((val.selectedAttributeRealFieldName != null)){
  			if(attributesMap[val.selectedAttributeRealFieldName].length > 1){
  				val.isValid = false;
  				isSelectionValid = false;
  			} else {
  				val.isValid = true;
  			}
  		}
  	});
  	return isSelectionValid;
  }

  private groupByAttributeId(): Record<string, ImportedSelectedAttribute[]>{
  	return this.selectedAttributes.reduce((res, x) => {
  		(res[x.selectedAttributeRealFieldName] = res[x.selectedAttributeRealFieldName] || []).push(x);
  		return res;
  	}, {} as Record<string, ImportedSelectedAttribute[]>);
  }
}
