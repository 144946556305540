import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AllModules, GridApi, GridOptions, Module, ColDef, GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { ManageTemplatesService } from '../services/manageTemplates.service';
import { ActivatedRoute } from '@angular/router';
import { AvailableIDSelectionValues } from '../models/templatePreview';

@Component({
	selector: 'smart-preview-table',
	templateUrl: './smartPreviewTable.component.html',
	styleUrls: ['./smartPreviewTable.component.scss']
})
export class SmartPreviewTableComponent implements OnInit, OnChanges {
	public rowData: [] = [];
	public tableRequestData: { templateJson: string; channelID: number };
	public gridOptions: GridOptions;
	public modules: Module[] = AllModules;
	public isTemplateHasPersonalizationTags: boolean = true;
	public isTargetGroupHasCustomers: boolean = true;
	private gridApi: GridApi;
	isTableLoading: boolean;

	@Input() targetGroupId: number;
	@Input() templateJson: string;
	@Output() disableTargetGroupsListEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() previewEvaluationEmitter: EventEmitter<{ customerId: string; customerType: AvailableIDSelectionValues }> = new EventEmitter<{
		customerId: string;
		customerType: AvailableIDSelectionValues;
	}>();

	constructor(private manageTemplatesService: ManageTemplatesService, private route: ActivatedRoute) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.isTargetGroupHasCustomers = true;
		if (changes['targetGroupId'].firstChange === false) {
			this.getTableData(Object.assign(this.tableRequestData, { targetGroup: +this.targetGroupId }));
		}
	}

	async ngOnInit() {
		this.tableRequestData = this.setTemplateDataForRequest(this.templateJson);

		await this.manageTemplatesService
			.checkIfPersonalizationInfoExits(this.tableRequestData)
			.toPromise()
			.then((res) => {
				if (res === false) {
					this.disableTargetGroupsListEmitter.emit(true);
					this.isTemplateHasPersonalizationTags = false;
				}
			});
		if (this.targetGroupId) {
			this.getTableData(Object.assign(this.tableRequestData, { targetGroup: +this.targetGroupId }));
		}
	}

	public onCidClick($event) {
		if ($event.colDef.headerName === 'CUSTOMER_ID') {
			this.previewEvaluationEmitter.emit({ customerId: $event.value, customerType: AvailableIDSelectionValues.ExplicitId });
		}
	}

	public setTemplateDataForRequest(templateJson: string): { channelID: number; templateJson: any } {
		const channelID = +this.route.snapshot.queryParams['channelId'];

		return { channelID, templateJson: templateJson };
	}

	public getTableData({ targetGroup, channelID, templateJson }) {
		this.isTableLoading = true;
		this.manageTemplatesService.getTargetGroupTableData({ targetGroup, channelID, templateJson }).subscribe((res) => {
			this.isTableLoading = false;
			if (!res?.rowData) {
				this.isTargetGroupHasCustomers = false;
				this.gridOptions = undefined;
				this.gridApi = undefined;
			} else {
				this.createGrid(res.columnDefs, res.rowData);
			}
		});
	}
	public scrollToColumnIndex($columnIndex) {
		this.gridApi.ensureColumnVisible(this.gridOptions.columnDefs[$columnIndex].headerName);
	}
	public createGrid(columnDefs, rowData) {
		this.gridOptions = {
			context: this,
			columnDefs: this.CreateColumnDefs(columnDefs),
			suppressHorizontalScroll: false,
			rowData: rowData,
			headerHeight: 32,
			rowHeight: 48,
			suppressCellSelection: true
		};
	}

	public CreateColumnDefs(columnDefs): ColDef[] {
		let columnDef = [];
		columnDefs?.forEach((column) => {
			columnDef.push({
				headerName: column.field,
				field: column.field,
				initialWidth: 174,
				suppressMenu: true,
				cellStyle: {
					'text-align': 'left',
					font: 'normal normal normal 12px/18px Roboto',
					'letter-spacing': '0px',
					color: '#37474F',
					opacity: '1',
					'margin-top': '16px'
				}
			});
		});
		return columnDef;
	}

	public OnGridReady(params) {
		this.gridApi = params.api;
	}
}
