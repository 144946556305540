import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import { SsmService } from '../../../services/ssm.service';
import {
  Attribute,
  AttributeOption,
  AttributeStatus,
  FormulasDic,
  RealTimeAttributeModel,
  TableAttribute
} from './models/profileDetails.model';

@Injectable()
export class RealtimeProfileDataManageService {
  public attributes: TableAttribute[] = [];
  baseUrl: string = '/RealTimeProfileDataManager';
  private rtSuffix = '_RT';
  private publishErrorTimePropertyName = 'publishErrorTime';
  private tenantProfileRawData: any;
  private sessionStorageManager: any;
  private customerAttributes: {
    list: any;
    keys: string[];
  };

  private profileVersion: number | null;

  constructor(private httpClient: HttpService, ssmService: SsmService) {
    this.sessionStorageManager = ssmService.getSSM();
  }

  public searchInProfile(attrName: string): any {
    return this.attributes.find((x) => x.displayName === attrName);
  }

  public deleteAttributeFromProfile(attributeName: string): void {
    this.attributes = this.attributes.filter(
      (x) => x.displayName !== attributeName
    );
  }

  public addAttributeToProfile(
    attributeKey: string,
    option: AttributeOption,
    obj: Attribute
  ): any {
    let copy = Object.assign({}, this.tenantProfileRawData);
    let section = option === 'Publish' ? 'profile' : 'notPublished';
    if (!copy[section]) {
      copy[section] = {};
    }
    copy[section][attributeKey] = obj;
    return copy;
  }

  public deleteAttribute(attributeName: string, option?: AttributeOption): any {
    let copy = Object.assign({}, this.tenantProfileRawData);
    let section = option === 'Publish' ? 'profile' : 'notPublished';
    // delete property from relevant section
    delete copy[section][attributeName];
    this.attributes = this.attributes.filter(
      (node) => !attributeName.includes(node.displayName)
    );
    return copy;
  }

  public setPublishErrorTimeToAttribute(
    attributeKey: string,
    option: AttributeOption,
    publishErrorTime: string
  ): any {
    let copy = Object.assign({}, this.tenantProfileRawData);
    let section = option === 'Publish' ? 'profile' : 'notPublished';
    if (!copy[section]) {
      copy[section] = {};
    }
    copy[section][attributeKey][this.publishErrorTimePropertyName] =
      publishErrorTime;
    return copy;
  }

  public getAllEventsWithParameters(): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}/GetEventsWithParameters`)
      .pipe(
        map((res: any) => {
          return res.Data;
        })
      );
  }

  public getAttributes(): Observable<TableAttribute[]> {
    return this.httpClient.get(`${this.baseUrl}/GetSubscription`).pipe(
      map((res: any) => {
        let data = JSON.parse(res.Data);
        this.tenantProfileRawData = data;
        this.attributes = [];
        this.profileVersion = parseInt(data.version);

        ['profile', 'notPublished'].forEach((section) => {
          if (!data[section]) {
            data[section] = {};
          }

          let attributes = Object.keys(data[section]).reduce(
            (filtered, key) => {
              if (data[section][key] != null)
                filtered[key] = data[section][key];

              switch (section) {
                case 'profile':
                  filtered[key].status = 'Published';
                  //to back compatibility
                  filtered[key].isAlreadyPublished = true;
                  break;
                case 'notPublished':
                  if (filtered[key] != null) {
                    if (!filtered[key].status) {
                      filtered[key].status = 'NotPublished';
                    }
                  }
                  break;
                default:
                  break;
              }

              return filtered;
            },
            {}
          );

          this.attributes = [
            ...this.attributes,
            ...Object.keys(attributes).map((key) =>
              this.setupTableAttribute(attributes[key])
            ),
          ];
        });
        return this.attributes;
      })
    );
  }

  private setupTableAttribute(attribute: TableAttribute): TableAttribute {
    attribute.exists = !!attribute.import;
    attribute.formula = FormulasDic[attribute.aggregation];
    attribute.eventsParameter = this.getAttributeEventParam(attribute);
    attribute.eventsType = this.getEventsType(attribute.events);
    attribute.tableDisplayName = attribute.displayName;

    if (attribute.exists) {
      attribute.basedOnFieldName = this.customerAttributes.keys.find(
        (x) =>
          this.customerAttributes.list[x].RealFieldName === attribute.import
      );
    }

    return attribute;
  }

  public getSingleSubscription(
    nodeKey: string,
    status: AttributeStatus
  ): Attribute {
    let attribute = {} as Attribute;
    if (!nodeKey.includes(this.rtSuffix)) {
      nodeKey = `${nodeKey}${this.rtSuffix}`;
    }
    if (this.tenantProfileRawData) {
      const section = status === 'Published' ? 'profile' : 'notPublished';
      attribute =
        this.tenantProfileRawData[section][nodeKey] ?? ({} as Attribute);
      attribute.status = status;
    }
    return attribute;
  }

  public updateSubscription(
    config: any = this.tenantProfileRawData
  ): Observable<DefaultAjaxResponse> {
    const url: string = `${this.baseUrl}/UpdateSubscription`;
    const configWithVersion = {
      ...config,
      version: this.profileVersion,
    };
    const body: any = {
      profile: JSON.stringify(configWithVersion),
    };
    return this.httpClient.post(url, body).pipe(
      map((res: DefaultAjaxResponse) => {
        this.profileVersion = parseInt(res.Data);
        return res;
      })
    );
  }

  public updateKsql(): Observable<any> {
    let copy = Object.assign({}, this.tenantProfileRawData);
    const url = `${this.baseUrl}/UpdateKSQL`;
    return this.httpClient.post(url, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getAttributeEventParam(attr: Attribute): string {
    if (attr.aggregation === 'SUM') {
      if (typeof attr.events[0].value === 'string') {
        let jsonPathSplit = attr.events[0].value.split('.');
        if (jsonPathSplit.length > 0) {
          return jsonPathSplit[1];
        }
      }
      return attr.events[0].value;
    }
    return '';
  }

  public getEventsType = (arr: any[]): string[] =>
    arr.map((x) => x.type as string);

  public addFieldToOptimoveDb(
    realTimeAttribute: RealTimeAttributeModel
  ): Observable<any> {
    const url: string = `${this.baseUrl}/AddFieldToOptimoveDb`;
    const body: RealTimeAttributeModel = realTimeAttribute;
    return this.httpClient.post(url, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public async updateCustomerAttributes() {
    const customerAttributes = await this.sessionStorageManager.getGenericAsync(
      this.sessionStorageManager.Resx.CustomerAttributes,
      true
    );
    this.customerAttributes = {
      list: customerAttributes,
      keys: Object.keys(customerAttributes),
    };
  }

  public triggerSqlToKafkaUploadCustomerProfile() {
    const url: string = `${this.baseUrl}/TriggerSqlToKafkaUploadCustomerProfile`;
    return this.httpClient.post(url, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public publishAttributes(): Observable<DefaultAjaxResponse> {
    return this.httpClient.post<DefaultAjaxResponse>(
      `${this.baseUrl}/PublishAttributes`,
      null
    );
  }
}
