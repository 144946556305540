import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { NavbarIconType } from 'src/app/modules/navbar/models/navbarTreeNode.model';
import { OptiAccordionBlockComponent } from './optiAccordionBlock/optiAccordionBlock.component';

@Component({
  selector: 'opti-accordion-group',
  templateUrl: './optiAccordionGroup.component.html',
  styleUrls: ['./optiAccordionGroup.component.scss']
})
export class OptiAccordionGroupComponent implements OnInit {
  @ViewChildren('accordionPanelsRef', { read: AccordionPanelComponent }) accordionPanelsRef: QueryList<AccordionPanelComponent>;

  @ContentChildren(OptiAccordionBlockComponent) accordionBlocks: QueryList<OptiAccordionBlockComponent> = new QueryList<OptiAccordionBlockComponent>();

  @Input() closeOthers = true;
  @Input() chevronAlignment: 'start' | 'end' = 'start';

  NavbarIconType = NavbarIconType;

  constructor() { }

  ngOnInit() { }
}