import { Component, Input, HostListener, ElementRef, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { IMultipleOptionsButtonObject } from "./models/multipleOptionsButtonObject.model";
import { IMultipleOptionsButtonItem } from "./models/multipleOptionsButtonItem.model";

@Component({
    selector: 'multiple-options-button',
    templateUrl: './multipleOptionsButton.component.html',
    styleUrls: ['./multipleOptionsButton.component.scss'],
})

export class MultipleOptionsButtonComponent {
    @Input() inputObj: IMultipleOptionsButtonObject;
    @Input() disabled: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() loadingText: string = '';

    @Output() itemClicked: EventEmitter<string> = new EventEmitter();

    public isMenuOpen: boolean = false;

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (!this.isMenuOpen) {
            return;
        }

        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isMenuOpen = false;
        }
    }

    constructor(private eRef: ElementRef) { }


    public onMenuButtonClicked() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    public onItemClicked(item: IMultipleOptionsButtonItem) {
        if (this.disabled || item.disabled) {
            return;
        }

        this.itemClicked.emit(item.id);
        this.isMenuOpen = false;
    }
}
