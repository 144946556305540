export type AttributeStatus = "NotPublished" | "Published" | "Publishing" | "FailedToPublish";
export type AttributeType = "calculated" | "realtime" | "base" | "customer" | "conditional" | "purchase";
export type AttributeFamily = "ActivityHistory" | "Regular" | "FormulaBased" | "Conditional" | "PurchaseHistory" | "Realtime";
export type AttributeOption = "Publish" | "Save";
export type TimeframeScenarioCode = "Last" | "This" | "Last Calendar";
export type AttributeDataType = "number" | "date" | "string";
export type AttributeDataTypeConditional = "number" | "date" | "string" | "multiValues";
export type AttributeDataTypePurchase = "number" | "string";
export type AttributeFormulaType = "formula" | "ifelse";
export type ConditionValueFormat = "text" | "number" | "date" | "dropdown" | "between numbers" | "between dates" | "disable";
export type PurchaseHistoryFunctionName = "Last" | "First" | "Sum" | "MostFrequent" | "MostSpent";


export interface AttributeDetails {
    Name: string;
    Type: AttributeDataType;
    Format?: string;
}

export enum AttributeDetailsType {
    Number,
    Date,
    String
}

export enum AttributeFormat {
    Number = 0,
    Decimal = 1,
    Currency = 2,
    Percentage = 3,
    String = 4,
    Boolean = 5
}

export enum FormulaType {
    FormulaBase,
    DateBase,
    NotSet
}


export enum AttributeFunctions {
    sum = "SUM",
    average = "AVERAGE",
    max = "MAX",
    min = "MIN"
}

export enum TimeframePeriod {
    "Day" = 1,
    "Week" = 2,
    "Month" = 3,
    "Quarter" = 4,
    "Year" = 5
}

export enum PurchaseHistoryFamily { 
    OLD_PurchaseHistory = 0, 
    New_PurchaseHistory = 1, 
    Old_PurchaseHistoryFirstLast = 2, 
    New_PurchaseHistoryFirstLast = 3, 
    Old_PurchaseHistoryMostFrequent = 4, 
    New_PurchaseHistoryMostFrequent = 5,
    Old_PurchaseHistoryMostSpent = 6, 
    New_PurchaseHistoryMostSpent = 7,
};