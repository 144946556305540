import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../services/optimove-http/optimove-http.model";

@Injectable()
export class AcademyLinksService {
    private userToken: string;

    constructor(private readonly http: HttpService) {
    }

    public getUserToken(): Observable<string> {
        if (this.userToken) {
            return of(this.userToken);
        }

        return this.http.get('/Account/GetUserToken', {})
            .pipe(map((response: any) => {
                this.userToken = response.Data;
                return this.userToken;
            }));
    }
}