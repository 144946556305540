import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ManageTemplatesService } from '../services/manageTemplates.service';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { TemplateContextService } from '../services/templateContext.service';
import { SearchContextService } from '../services/searchContext.service';
import { TemplateNavigationService } from '../services/templateNavigation.service';
import { filter, first, skip} from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import {
	AllModules,
	CellClickedEvent,
	GridApi,
	GridOptions,
	IServerSideDatasource,
	IServerSideGetRowsParams,
	Module
} from '@ag-grid-enterprise/all-modules';
import { TemplatePreviewCardComponent } from '../folderTemplatePreviewCard/templatePreviewCard.component';
import { LoadingTemplateCartComponent } from '../loadingTemplateCart/loadingTemplateCart.component';
import { fadeIn } from './detailsPanel.animation';
import { RelationType, TemplateSearchResult } from '../models/templateResponse';
import { ToastModel } from '../../missionControl/models/toast.model';
import { locationParameter } from 'src/app/models/locationParameter.enum';
import { TranslateService } from '@ngx-translate/core';
import { IconType } from '@optimove/ui-sdk/common/models';
import { FolderService } from '../services/folder.service';
import { ChannelService } from '../services/channel.service';
import { DuplicatedPayload, TemplateDuplicateService } from '../services/templateDuplicate.service';
import moment from 'moment';
import { TemplatesSortingService } from '../services/templatesSorting.service';
import { DragComponent } from '../dragComponent/drag.component';
import { TemplateSelectionService } from '../services/templateSelection.service';
import { TemplateDeleteService } from '../services/templateDelete.service';
import { TemplatesDragDropService } from '../services/templatesDragDrop.service';
import { TemplatesBatchMove } from '../models/templateMove';
import { RoutingConsts } from '../models/routing.consts';
import { TemplateNewService } from '../services/templateNew.service';
import { FeatureFlag, FeatureFlagService } from '../../../services/featureFlag.service';

@Component({
	selector: 'details-panel',
	templateUrl: './detailsPanel.component.html',
	styleUrls: ['./detailsPanel.component.scss'],
	animations: [fadeIn]
})
export class DetailsPanelComponent implements OnInit, OnDestroy {
	public templateIds: number[] = [];
	public gridOptions: GridOptions;
	public previousHoveredTemplate: number;
	public modules: Module[] = AllModules;
	public channelId: number;
	public folderId: number;
	public templatesCount: number = 0;
	public isGridReady: boolean = false;
	public readonly pagingCount = 20;
	public readonly defauldToastCloseTimeout = 1000;
	public frameworkComponents;
	public actionText = '';
	public loadingCellRenderer: any = LoadingTemplateCartComponent;
	channelName: string;
	public isAnyTemplatesOrFolders: boolean;
	searchText: string = '';
	public toastParams: ToastModel;
	public sortingType: number;
	public numberOfInvalid: number;
	private gridApi: GridApi;
	private subMethodId: number;
	private duplicatedTemplate: DuplicatedPayload;
	private deletedTemplates: FolderTemplatePreview[];
	private componentSubscriptions: Subscription;
	private searchRefreshingSubscription: Subscription;
	private brandId: string;
	private isSearchForAllFolders: boolean;

	constructor(
		private templatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private searchContextService: SearchContextService,
		private templateNavigationService: TemplateNavigationService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private folderService: FolderService,
		public channelService: ChannelService,
		private templatesSortingService: TemplatesSortingService,
		private templateDuplicateService: TemplateDuplicateService,
		private templateSelectionService: TemplateSelectionService,
		private templateDeleteService: TemplateDeleteService,
		private templatesDragDropService: TemplatesDragDropService,
		private templateNewService: TemplateNewService,
		private featureFlagService: FeatureFlagService
	) {
		this.frameworkComponents = {
			templatePreview: TemplatePreviewCardComponent,
			loadingComponent: LoadingTemplateCartComponent,
			dragGrab: DragComponent
		};
	}

	ngOnInit(): void {
		this.gridOptions = this.initGridOptions();
		this.initToastParams();
		this.componentSubscriptions = this.templateContextService.queryTemplateParams
			.pipe(
				filter(
					(params) =>
						params.folderId &&
						(this.channelId !== +params.channelId || this.folderId !== +params.folderId || this.subMethodId !== +params.typeId)
				)
			)
			.subscribe((params) => {
				if (this.folderId !== +params.folderId &&
					this.brandId === params.brandId &&
					this.searchContextService.isSearchTextValid(params.search)){
					this.isSearchForAllFolders = false;
				}

				this.isGridReady = false;
				this.channelId = +params.channelId;
				this.brandId = params.brandId;
				this.folderId = +params.folderId;
				this.subMethodId = +params.typeId;

				this.templatesService.getMetadata().subscribe((metadata) => {
					this.channelName = Object.keys(metadata.channels)
						.map((id) => metadata.channels[id])
						.find((channel) => channel.id == this.channelId)?.name;
				});

				if (this.gridApi) {
					this.templatesSortingService.sortingType.subscribe((type) => {
						this.sortingType = type;
						this.loadFolderTemplates(type, this.folderId, this.subMethodId);
					});
				}
			});

		this.componentSubscriptions.add(
			this.templateContextService.isAnyTemplatesOrFolders.subscribe((value) => {
				this.isAnyTemplatesOrFolders = value;
			})
		);

		this.componentSubscriptions.add(
			this.templateDuplicateService.duplicating.subscribe((isStarting: boolean) => {
				if (isStarting) {
					this.openToastMessage(
						this.translate.instant('features.manage_templates.optimail.templateFolderActions.DUPLICATING'),
						null,
						this.defauldToastCloseTimeout
					);
				}
			})
		);
		this.componentSubscriptions.add(
			this.templateDuplicateService.duplicated.subscribe((payload: DuplicatedPayload) => {
				this.duplicatedTemplate = payload;
				this.isGridReady = false;
				this.onTemplateDuplicated();
				this.openToastMessage(
					this.translate.instant(
						payload.isBatchDuplication
							? 'features.manage_templates.toast_notifications.TEMPLATES_DUPLICATED'
							: 'features.manage_templates.toast_notifications.TEMPLATE_DUPLICATED'
					),
					payload.isBatchDuplication ? '' : 'Edit'
				);
			})
		);
		this.componentSubscriptions.add(
			this.templateDeleteService.deleting.subscribe((isStarting: boolean) => {
				if (isStarting) {
					this.openToastMessage(
						this.translate.instant('features.manage_templates.toast_notifications.TEMPLATE_DELETING'),
						null,
						11000
					);
				}
			})
		);
		this.componentSubscriptions.add(
			this.templateDeleteService.deleted.subscribe((templates: FolderTemplatePreview[]) => {
				this.deletedTemplates = templates;
				this.isGridReady = false;
				this.onTemplateBatchDeleted();
				this.openToastMessage(
					this.translate.instant(
						templates && templates.length > 1
							? 'features.manage_templates.toast_notifications.TEMPLATES_DELETED'
							: 'features.manage_templates.toast_notifications.TEMPLATE_DELETED'
					),
					'Undo',
					10000
				);
			})
		);
		this.componentSubscriptions.add(
			this.templateDeleteService.undoing.subscribe((isStarting: boolean) => {
				if (isStarting) {
					this.openToastMessage(
						this.translate.instant('features.manage_templates.toast_notifications.TEMPLATE_DELETE_UNDOING'),
						null,
						this.defauldToastCloseTimeout
					);
				}
			})
		);
		this.componentSubscriptions.add(
			this.templateDeleteService.undoCompleted.subscribe((isStarting: boolean) => {
				if (isStarting) {
					this.onTemplateBatchUndoDelete();
				}
			})
		);
		this.componentSubscriptions.add(this.folderService.templateMoved.subscribe((templateMove) => {
			this.onTemplateMoved(
				templateMove.templateId,
				templateMove.sourceSubMethodId,
				templateMove.sourceFolderId,
				templateMove.destinationSubMethodId,
				templateMove.destinationFolderId
			);
		}));

		this.componentSubscriptions.add(this.folderService.templatesBatchMoved.subscribe((payload: TemplatesBatchMove) => {
			this.onTemplatesBatchMoved(payload);
		}));
		this.componentSubscriptions.add(this.folderService.templatesBatchCopied.subscribe((payload) => {
			this.onTemplateCopied(payload.templates.map((t) => t.templateID));
		}));
	}

	ngOnDestroy() {
		this.componentSubscriptions.unsubscribe();
	}

	public initGridOptions(): GridOptions {
		return <GridOptions>{
			columnDefs: [
				{
					field: 'f',
					width: 0,
					maxWidth: 20,
					cellRenderer: 'dragGrab',
					cellRendererParams: {
						onTemplateMoved: this.onTemplateMoved.bind(this)
					}
				},
				{
					field: 'field',
					cellRenderer: 'templatePreview',
					cellRendererParams: {
						onTemplateDeleted: this.onTemplateDeleted.bind(this),
						onEditTemplate: this.onPreviewClick.bind(this),
						onTemplateMoved: this.onTemplateMoved.bind(this),
						onTemplateCopied: this.onTemplateCopied.bind(this),
						getEditTemplateLink: this.getEditTemplateLink.bind(this)
					},
					valueGetter: (params) => {
						return params.data;
					},
					headerName: 'header',
					cellClass: 'mc-cell template-preview-cell',
					sortable: true,
					autoHeight: true
				}
			],
			headerHeight: 0,
			rowBuffer: 0,
			rowHeight: 138,
			rowStyle: { display: 'block' },
			animateRows: true,
			suppressContextMenu: true,
			rowSelection: 'single',
			suppressHorizontalScroll: true,
			rowModelType: 'serverSide',
			serverSideStoreType: 'partial',
			paginationPageSize: 20,
			cacheBlockSize: 20, // how many rows returned from the server at a time
			cacheOverflowSize: 0, // extra blank rows to display to the user at the end of the dataset
			maxConcurrentDatasourceRequests: 1, // requests to hit the server with concurrently
			infiniteInitialRowCount: 2, // How many extra blank rows to display to the user at the end of the dataset
			maxBlocksInCache: 1,
			loadingCellRenderer: 'loadingComponent',
			getRowId: (params) => params.data.templateID,
			getRowNodeId: (params) => {
				return params.templateID;
			},
			onGridReady: (params) => {
				this.isGridReady = false;
				this.componentSubscriptions.add(
					this.searchContextService.searchText.subscribe((text) => {
						this.searchText = text;
						if (!this.searchContextService.isSearchTextValid(text)) {
							if (this.folderId) {
								this.isGridReady = false;
								this.templatesSortingService.sortingType.subscribe((type) => {
									this.sortingType = type;
									this.loadFolderTemplates(type, this.folderId, this.subMethodId);
								});
							}
							return;
						}
						this.isSearchForAllFolders = true;
						this.refreshSearch();
					})
				);

				this.gridApi = params.api;
				this.gridApi.sizeColumnsToFit();
				this.onResize(null);
				if (this.folderId) {
					this.componentSubscriptions.add(
						// need to create data source only after folders cache initialized
						this.templateContextService.isAnyTemplatesOrFolders.pipe(first()).subscribe((_) => {
							const datasource = this.createServerSideDataSource();
							this.gridApi.setServerSideDatasource(datasource);
						})
					);
				}
			},
			onCellClicked: (event: CellClickedEvent) => {
				if ((<PointerEvent>event.event).ctrlKey) {
					return;
				}
				this.onPreviewClick(event.value);
			}
		};
	}

	public loadFolderTemplates(sortingType: number, folderId: number, subMethodId: number): void {
		if (this.isSearchForAllFolders && this.searchContextService.isSearchTextValid(this.searchText))
			return;

		this.templateSelectionService.removeAll();
		this.folderService.getFolderTemplates(folderId, subMethodId, this.brandId, this.channelId).subscribe((folderTemplates) => {
			if(this.searchContextService.isSearchTextValid(this.searchText) && folderTemplates){
				const folderSearchResult = folderTemplates.map((template)=>{
					return {
						templateID: template.id,
						subMethodId: template.subMethodId,
						channelId: this.channelId,
						folderId: template.parentId

					}as TemplateSearchResult;
				});
				this.searchContextService.updateSearchTemplateCache(folderSearchResult);
			}
			this.templateIds = this.templatesSortingService.getSortedTemplatesIds(folderTemplates, sortingType);
			const datasource = this.createServerSideDataSource();
			this.gridApi.setServerSideDatasource(datasource);
		});
	}

	onResize(event) {
		const myElement = document.getElementById('agGridTemplates');
		myElement.style.height = `${window.innerHeight - 210}px`;
	}
	public getNumberOfInvalid(templates: TemplateSearchResult[]){
		if(templates) {
			this.numberOfInvalid = this.folderService.countInvalidItems(templates.map(templateInfo => {
				return {
					id: templateInfo.templateID,
					subMethodId: templateInfo.subMethodId
				};
			}));
		}
	}
	public createServerSideDataSourceWithSearchResult(templates: TemplateSearchResult[]) {
		return {
			getRows: (params) => {
				const { startRow, endRow } = params.request;
				if (this.folderId) {
					this.loadTemplatesToDisplay(templates, startRow, endRow, params);
				} else {
					this.createServerSideDataSourceWithoutFolderId(params, endRow);
				}
			}
		};
	}
	public createServerSideDataSource(): IServerSideDatasource {
		return {
			getRows: (params) => {
				const { startRow, endRow } = params.request;
				if (this.folderId) {
					const templates = this.templateIds.map((id) => {
						return {
							templateID: id,
							subMethodId: this.subMethodId,
							channelId: this.channelId,
							folderId: this.folderId
						};
					});
					this.loadTemplatesToDisplay(templates, startRow, endRow, params);
				} else {
					this.createServerSideDataSourceWithoutFolderId(params, endRow);
				}
			}
		};
	}
	public createServerSideDataSourceWithoutFolderId(params, endRow) {
		this.isGridReady = true;
		params.success({
			rowData: [],
			rowCount: endRow - this.pagingCount
		});
	}

	public templatesDisplay(response: FolderTemplatePreview[], params, endRow) {
		let result = response && Array.isArray(response) ? response : [];
		result.forEach((r) => {
			var minDate = moment.utc('0001-01-01');

			var receiveDate = moment(r.lastModifiedDate);
			if (!moment.utc(receiveDate).isAfter(minDate)) {
				r.lastModifiedDate = null;
			}
		});
		this.isGridReady = true;
		this.cd.detectChanges();
		if (result.length > 0 && result.length === this.pagingCount) {
			params.success({
				rowData: result,
				rowCount: endRow + 1
			});
		} else if (result.length !== this.pagingCount) {
			params.success({
				rowData: result,
				rowCount: endRow - this.pagingCount + result.length
			});
		}

		if (response?.map) {
			this.templateContextService.refreshTemplatesInUse(
				response.map((r) => {
					return { templateId: r.templateID, subMethodId: r.subMethodId };
				})
			);
			if(this.featureFlagService.isEnabled(FeatureFlag.TemplatesOptimailMultiLanguageFlow)) {
				this.templateContextService.refreshNumberOfLanguages(
					response.map((r) => {
						return { templateId: r.templateID, subMethodId: r.subMethodId };
					})
				);
			}
		}
	}

	onPreviewClick(template: FolderTemplatePreview): void {
		if (!template) return;
		this.templateNavigationService.navigateToEditTemplate(
			template.channelId,
			template.templateID,
			template.folderId,
			template.subMethodId,
			template.otherEditor,
			this.searchText
		);
	}

	getEditTemplateLink(template: FolderTemplatePreview): string {
		if (!template) return;
		return this.templateNavigationService.getEditTemplateLink(
			template.channelId,
			template.templateID,
			template.folderId,
			template.subMethodId,
			template.otherEditor,
			this.searchText
		);
	}
	onCellMouseOver($event) {
		if (this.previousHoveredTemplate) {
			const previousTemplate = document.getElementById(`${this.previousHoveredTemplate}-drag`);
			if (previousTemplate) {
				previousTemplate.style.visibility = 'hidden';
			}
		}
		const templateId = $event.data.templateID;
		this.previousHoveredTemplate = templateId;
		const element = document.getElementById(`${templateId}-drag`);
		if (element) {
			this.templateSelectionService.templatesSelected.pipe(first()).subscribe((selectedTemplates) => {
				if (this.templatesDragDropService.isTemplateDraggable(templateId, selectedTemplates)) {
					element.style.visibility = 'visible';
				}
			});
		}
	}

	onTemplateMoved(
		templateId: number,
		sourceSubMethodId: number,
		sourceFolderId: number,
		destinationSubMethodId: number,
		destinationFolderId: number
	): void {
		this.onTemplatesBatchMoved({
			templates: [{ templateID: templateId, folderId: sourceFolderId, subMethodId: sourceSubMethodId } as FolderTemplatePreview],
			destinationSubMethodId,
			destinationFolderId
		});
	}

	onTemplatesBatchMoved(payload: TemplatesBatchMove) {
		this.isGridReady = false;
		payload.templates.forEach((template) => {
			const treeItem = this.folderService.removeTemplateFromCache(template.templateID, template.folderId, template.subMethodId);
			if (!treeItem) return;
			treeItem.subMethodId = payload.destinationSubMethodId;
			treeItem.parentId = payload.destinationFolderId;
			this.folderService.addTemplateToCache(payload.destinationFolderId, payload.destinationSubMethodId, treeItem);
			this.templateIds.splice(this.templateIds.indexOf(template.templateID), 1);
		});
		this.refreshTemplates();
		this.openToastMessage(
			this.translate.instant(
				payload.templates.length > 1
					? 'features.manage_templates.toast_notifications.TEMPLATES_MOVE_SUCCESSFULLY'
					: 'features.manage_templates.toast_notifications.TEMPLATE_MOVE_SUCCESSFULLY'
			)
		);
	}

	openBatchMovingToast(event) {
		this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.TEMPLATES_MOVING'));
	}

	onTemplateCopied(templateIds?: number[]): void {
		this.refreshTemplates();
		this.openToastMessage(
			this.translate.instant(
				templateIds && templateIds.length > 1
					? 'features.manage_templates.toast_notifications.TEMPLATES_COPIED'
					: 'features.manage_templates.toast_notifications.TEMPLATE_COPIED'
			)
		);
	}

	onTemplateBatchUndoDelete(): void {
		this.isGridReady = false;
		if (!this.deletedTemplates) {
			return;
		}
		this.deletedTemplates.forEach((t) => {
			this.folderService.addTemplateToCache(t.folderId, t.subMethodId, {
				id: t.templateID,
				parentId: t.folderId,
				rel: RelationType.leaf,
				text: t.templateName,
				displayText: null,
				isRootFolder: false,
				children: null,
				subMethodId: t.subMethodId,
				isValid: t.isValid
			});
		});

		this.loadFolderTemplates(this.sortingType, this.folderId, this.subMethodId);
		this.refreshTemplates();
		this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.TEMPLATE_DELETE_UNDO_COMPLETED'));
	}

	onTemplateBatchDeleted(): void {
		this.templateSelectionService.removeAll();
		if (!this.deletedTemplates) {
			return;
		}
		this.deletedTemplates.forEach((template) => {
			this.folderService.removeTemplateFromCache(template.templateID, template.folderId, template.subMethodId);
			const templateIdToRemove = this.templateIds.find((id) => id === template.templateID);
			this.templateIds.splice(this.templateIds.indexOf(templateIdToRemove), 1);
		});
		this.refreshTemplates();
	}

	onTemplateDeleted(template: FolderTemplatePreview): void {
		this.folderService.removeTemplateFromCache(template.templateID, template.folderId, template.subMethodId);
		const templateIdToRemove = this.templateIds.find((id) => id === template.templateID);
		this.templateIds.splice(this.templateIds.indexOf(templateIdToRemove), 1);
		this.refreshTemplates();
		this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.TEMPLATE_DELETED'));
	}

	onTemplateDuplicated(): void {
		this.loadFolderTemplates(this.sortingType, this.folderId, this.subMethodId);
		this.refreshTemplates();
	}

	newTemplate(): void {
		this.templateNewService.newTemplate(this.channelId);
	}

	private refreshTemplates(): void {
		this.templateSelectionService.removeAll();
		if (this.searchContextService.isSearchTextValid(this.searchText)) {
			this.refreshSearch();
		} else {
			//currently, ag grid doesn`t support deletion for server side models without refresh
			this.gridApi?.refreshServerSideStore({ purge: true });
		}
		this.searchContextService.triggerSearchableTemplatesChanged();
	}

	openToastMessage(message: string, actionText?: string, closeTimeout?: number) {
		this.actionText = actionText;
		this.toastParams = {
			isOpen: true,
			message: message,
			icon: 'info',
			showCloseIcon: true,
			location: locationParameter.Default,
			toastIconType: IconType.AngularMaterial,
			closeTimeout: closeTimeout ?? 3000
		};
		//to trigger toast, we need to make click
		document.getElementById('detailsPanel').click();
	}

	private initToastParams(): void {
		this.toastParams = {
			isOpen: false,
			message: '',
			icon: '',
			showCloseIcon: false,
			location: locationParameter.Default,
			toastIconType: IconType.AngularMaterial
		};
		this.folderService.folderMoved.pipe(skip(1)).subscribe((_) => {
			this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.FOLDER_MOVE_SUCCESSFULLY'));
		});

		this.folderService.folderDeleted.subscribe((_) => {
			if (!this.toastParams.isOpen)
				this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.FOLDER_DELETED'));
		});

		this.folderService.folderNameUpdated.subscribe((_) => {
			this.openToastMessage(this.translate.instant('features.manage_templates.toast_notifications.FOLDER_RENAMED'));
		});
	}

	private loadTemplatesToDisplay(
		templates: TemplateSearchResult[],
		startRow: number,
		endRow: number,
		params: IServerSideGetRowsParams
	): void {
		this.numberOfInvalid = 0;
		this.templatesCount = templates.length;
		if (templates.length) {
			const templateBatch = templates.slice(startRow, startRow + this.pagingCount);
			this.templatesService.getFolderTemplates(templateBatch).subscribe((response: FolderTemplatePreview[]) => {
				this.templatesDisplay(response, params, endRow);
				if(this.channelId === RoutingConsts.OPTIMAIL_CHANNEL)
					this.getNumberOfInvalid(templates);
			});
		} else {
			this.templatesDisplay([], params, endRow);
		}
	}
	onToastActionClick() {
		switch (this.actionText) {
			case 'Edit':
				this.templateNavigationService.navigateToEditTemplate(
					this.channelId,
					this.duplicatedTemplate.templateId,
					this.folderId,
					this.duplicatedTemplate.subMethodId,
					this.duplicatedTemplate.otherEditor,
					this.searchText
				);
			case 'Undo':
				this.templateDeleteService.updateIsDeleted(this.deletedTemplates, false);
		}
	}

	private refreshSearch(): void {
		if (this.searchRefreshingSubscription) {
			this.componentSubscriptions.remove(this.searchRefreshingSubscription);
		}
		const brand = this.searchContextService.brand;
		const sortingType = this.templatesSortingService.sortingType;

		this.searchRefreshingSubscription = combineLatest([brand, sortingType])
			.pipe(filter(() => this.searchContextService.isSearchTextValid(this.searchText)))
			.subscribe(([brand, type]) => {
				if (!brand) return;

				if (this.brandId !== brand.name)
					this.isSearchForAllFolders = true;

				const subMethodIds = brand.subMethods.map((sm) => sm.subMethodId as number);
				this.sortingType = type;

				if(!this.isSearchForAllFolders){
					this.loadFolderTemplates(this.sortingType, this.folderId, this.subMethodId);
					return;
				}

				this.templatesService
					.findTemplates({ channelId: this.channelId, subMethodIds, searchText: this.searchText, sortingType: type })
					.subscribe((templates: TemplateSearchResult[]) => {
						if (!templates) return;
						this.isGridReady = false;
						this.searchContextService.updateSearchTemplateCache(templates);
						const datasource = this.createServerSideDataSourceWithSearchResult(templates);
						this.gridApi.setServerSideDatasource(datasource);
					});
			});
		this.componentSubscriptions.add(this.searchRefreshingSubscription);
	}
}
