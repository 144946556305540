<div class="channel-metrics-table">
    <table class="table">
        <tbody>
            <tr *ngFor="let metric of metrics; let i = index" [class.only-one-metric]="metrics.length === 1">
                <td class="col-xs-8">
                    <ng-template #popTemplate><div [innerHtml]="tooltips[metric.name]"></div></ng-template>
                    <span [tooltip]="popTemplate">
                        {{baseTranslatePath + 'METRICS.' + metric.name | translate}}
                    </span>
                </td>
                <td class="col-xs-2 cmnt-value">
                    <p class="small" *ngIf="!isNavigatable(metric)">{{metric.number | formatter:'0,'}}</p>
                    <span class="navigatable" *ngIf="isNavigatable(metric)" (click)="onMetricClick(metric)">{{metric.number | formatter:'0,'}}</span>
                </td>
                <td class="col-xs-2 cmnt-percent">
                    <span *ngIf="metric.percent < 0" class="emdash"></span>
                    <span *ngIf="metric.percent >= 0">
                        {{ metric.percent | formatter:'0%':{shouldHandleSmallNumbers: true} }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
