<div class="details-panel-container">
  <div class="header-panel">
    <div class="create-new-button-container" *ngIf="shouldShowCreateNew">
      <div class="overflow-menu-container" data-qa-id="create-new">
        <overflow-menu
          [position]="newButtonMenuPosition"
          [items]="newButtonMenuItems"
          [overflowMenuButtonClasses]="'btn-primary'"
          [menuPopoverClass]="'nested-folders-popover-menu'"
          [overflowMenuButtonText]="'features.manage_templates.components.details.CREATE_NEW_FOLDER_OR_TEMPLATE' | translate">
          <ng-template #overflowMenuItemTemplate let-overflowMenuItem>
            <overflow-menu-item [hasSubMenu]="overflowMenuItem.children?.length > 0">
              <span overflowMenuItemText>{{ overflowMenuItem.text }}</span>
            </overflow-menu-item>
          </ng-template>
        </overflow-menu>
      </div>
    </div>
    <span class="header" [innerHtml]="getTitle(searchText, folderTitle)"></span>
  </div>
</div>
