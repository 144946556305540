export class TenantDetails {
    private _userInitials: string;

    constructor(public name: string, public lastName: string, public userName: string, public userRole: string, public tenantId: number, public userId: string) {
        if (!this.name || this.name.length === 0) {
            this.name = 'Optimove';
        }
        if (!this.lastName || this.lastName.length === 0) {
            this.lastName = 'User';
        }
    }

    get userInitials() {
        if (this.name?.length > 0 && this.lastName?.length > 0) {
                return  `${this.name[0]}${this.lastName[0]}`
        } else if (this.name?.length > 1 && this.lastName?.length === 0) {
                return  `${this.name[0]}${this.name[1]}`
        } else {
                return  'OU';
        }
    }
    get fullName() {
        return `${this.name} ${this.lastName}`;
    }
}