import { Injectable } from "@angular/core";
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { HttpService } from "../../../services/optimove-http/optimove-http.model";
import { OptiApiUser } from "./models/optiApiUser.model";

@Injectable({providedIn: 'root'})
export class ApiManagementService {
    private baseUrl: string = '/UserSettings';

    constructor(private readonly http: HttpService) {
    }

    public getApiEndPoint(): Observable<string> {
        return this.http.get(`${this.baseUrl}/GetApiEndPoint`, {responseType: 'text'});
    }

    public getApiUsers(): Observable<DefaultAjaxResponse> {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetApiUsers`);
    }

    public createApiUser(optiApiUser: OptiApiUser): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/CreateApiUser`, { optiApiUser });
    }

    public updateApiUser(optiApiUser: OptiApiUser): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/UpdateApiUser`, { optiApiUser });
    }

    public deleteApiUser(userName: string): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/DeleteApiUser`, { userName });
    }
}