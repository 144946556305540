import {Component, Input, Output, EventEmitter, ViewEncapsulation, OnChanges} from "@angular/core";
import { IChannelMetricsElement, CHANNEL_METRICS_TRANSLATION_ROOT } from "../../campaignAnalysis.models";
import { Navigatable } from "../../navigatable";
import { ChannelMetricsUtilitiesService } from "../../channelMetricsUtilities.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'channel-metrics-table',
    templateUrl: './channelMetricsTable.component.html',
    styleUrls: ['./channelMetricsTable.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsTableComponent extends Navigatable implements OnChanges{

    @Input() metrics: IChannelMetricsElement[];
    @Input() channelId: number;
    @Output() metricClick: EventEmitter<{ metricId, metricName }> = new EventEmitter();

    baseTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT;
    newMetricsTooltipTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'NEW_METRICS_TOOLTIP.';

    public tooltips: any;

    constructor(utils: ChannelMetricsUtilitiesService,
                private translate: TranslateService) {
        super(utils);
    }

    onMetricClick(metric: IChannelMetricsElement) {

        if (!this.isNavigatable(metric))
            return;

        this.metricClick.emit({
            metricId: metric.id,
            metricName: metric.name
        });
    }
    ngOnChanges() {
        this.tooltips = {};
        this.metrics.forEach(metric => {
            if(this.channelId == 29){
                this.tooltips[metric.name] = this.translate.instant(this.newMetricsTooltipTranslatePath + metric.name, { denominator: metric.denominatorName.toLowerCase()});
            }else {
                this.tooltips[metric.name] = (metric.name != 'Impressions') && (metric.name != 'Reach') ? this.translate.instant(this.baseTranslatePath + 'METRICS_TOOLTIP.' + metric.name) : null;
            }
        });
    }
}