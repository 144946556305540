<div class="customer-attributes">
  <div class="panel">
    <div class="panel-heading" style="padding-left: 5px;">
      <div class="back-button">
          <div class="btn btn-link" (click)="onBackClick()" >
            <i class="material-icons">arrow_back</i> <span translate>{{ "general.BACK" | translate }}</span>
          </div>
      </div>
      <div *ngIf="config.isAddMode; else elseHeaderBlock">
        <h2>{{ config.translateKeys.title | translate }}</h2>
      </div>
      
      <ng-template #elseHeaderBlock>
        <div  class="published-title">
          <h2> {{ config.translateKeys.editTitle | translate }} </h2>
        </div>
      </ng-template>
     
      <colored-tag [coloredTagType]="config.attributeStatus.color" style="padding: 7px;" >
        {{ config.attributeStatus.description | translate }}
      </colored-tag>
    </div>
    <div class="panel-body">
      <form id="attributeForm" [formGroup]="attributeForm" class="form-horizontal">
        <div class="row">
          <div class="col-sm-7 pl-0 pr-0">
            <div class="form-group row" style="display: flex;">
              <label class="control-label"> {{ config.translateKeys.displayName | translate }}</label>
              <div class="control-input">
                <input data-qa-id="attrinute-name-input" [ngClass]="{ errorBorder: config.error.attributeName }" class="form-control" type="text"
                  placeholder="Enter a unique attribute name" formControlName="attributeName" />
    
                  <div class="inputs-validations" *ngIf="config.error.attributeName || f.attributeName.invalid && (f.attributeName.dirty || f.attributeName.touched)">
                    <span data-qa-id="attribute-name-error-msg" *ngIf="config.error.attributeName" class="error-msg" >
                      {{ config.translateKeys.errorAliasFieldName | translate }}
                    </span>
                    <span data-qa-id="attribute-name-error-msg" *ngIf="f.attributeName.errors?.required" class="error-msg" >
                      Field is required
                    </span>
                    <span data-qa-id="attribute-name-error-msg" *ngIf="f.attributeName.errors?.specialSymbols" class="error-msg" >
                      {{ config.translateKeys.specialSymbolsErrorAliasFieldName | translate }}
                    </span>
    
                  </div>
              </div>
            </div>
            <div class="form-group row" style="display: flex;">
              <label  class="control-label">{{ config.translateKeys.description | translate }}</label>
              <div class="control-input">
                <textarea data-qa-id="attribute-description-input" class="form-control" type="text" formControlName="description" placeholder="(Optional)"></textarea>
              </div>
            </div>
          </div>
          <attribute-settings
              class="col-sm-5 pl-0 pr-0"
              (chengePersonalizationEvent)="changePersonalization($event)" 
              (changeAttributeVisibilityEvent)="changeAttributeVisibility()"
              [isPersonalization]="attribute.IsPersonalization"
              [showAttribute]="!attribute.IsHidden"
              [disableShowHideCheckbox]="!canAttributeBeHidden"
              *ngIf="config.isPublished">
          </attribute-settings>
        </div>
        <div class="form-group row">
          <label  class="col-sm-2  pl-0 pr-0">{{ config.translateKeys.calculationType | translate }}</label>
          <div class="btn-group col-sm-3 pl-0">
            <input data-qa-id="calc-type-formula" type="radio" id="option1" autocomplete="off" value="formula" name="calculationType" formControlName="calculationType" 
              (change)="onCalculationTypeCange($event)" />
            <label [ngClass]="{'radio-disabled' : config.isPublished}" class="btn btn-default " for="option1" >{{ config.translateKeys.formula | translate }}</label>
            <input data-qa-id="calc-type-ifelse" type="radio" id="option2" autocomplete="off" value="ifelse" name="calculationType" formControlName="calculationType" 
              (change)="onCalculationTypeCange($event)"/>
            <label [ngClass]="{'radio-disabled' : config.isPublished}" class="btn btn-default" for="option2">{{ config.translateKeys.ifElse | translate }}</label>
          </div>
          <div class="col-sm-3 pl-0 pr-0 format-row" *ngIf="config.calculationType === 'ifelse'">
            <span>{{ config.translateKeys.format | translate }}</span>
            <opti-search-list 
              data-qa-id="format-dropdown"
              formControlName="attributeFormat"
              [(ngModel)]="config.formulaFormatFormValue"
              [items]="config.formulaFormats" 
              [config]="formatConfig" 
              [isDefaultDisabled]="config.calculationType === 'ifelse' 
                || calculatorConfig.isPublished || conditionConfig.isDisabled"
              (selectedItemsChange)="onFormatChanged($event)"></opti-search-list>
          </div> 
        </div>
        <div class="row" *ngIf="!config.isAddMode && attribute.PublishStatus === 'Published' && !attrManagerService.isDailyCompleted.value">
          <div class="col-sm-2 pl-0 pr-0" ></div>
          <span class="col-sm-10 pl-0 pr-0 alert-msg">{{ config.translateKeys.editConditionMsg | translate }}</span>
        </div>
      <div class="form-group row">
          <label  class="col-sm-2  pl-0 pr-0">{{ config.translateKeys.calculation | translate }}</label>
          <div class="col-sm-10  pl-0 pr-0">
            <ng-template customerAttrEditorRef></ng-template>
            <div class="col-sm-12 pl-0 format-row" *ngIf="config.calculationType === 'formula'">
              <span>{{ config.translateKeys.format | translate }}</span>
              <opti-search-list 
                  data-qa-id="format-dropdown"
                  formControlName="attributeFormat"
                  [(ngModel)]="config.formulaFormatFormValue"
                  [items]="config.formulaFormats" 
                  [config]="formatConfig" 
                  [isDefaultDisabled]="calculatorConfig.formulaType === enumFormulaType.DateBase || conditionConfig.isDisabled || !isDailyCompleted"
                  (selectedItemsChange)="onFormatChanged($event)">
              </opti-search-list>
              <button data-qa-id="test-formula-btn" class="btn btn-secondary" (click)="openTestFormulaModal()" [disabled]="attrEditorFormControl.invalid">
                <i class="icon-test icon"></i>
                {{ config.translateKeys.testFormula | translate }}
              </button>
            </div>
          </div>
        </div>
      </form> 
  </div>
  <div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="last-modified-stamp">
      <label class="small">{{config.lastUpdate}}</label>
    </div>
    <div class="controls col-md-6 pull-right text-right">
      <ul class="list-inline">
        <li>
          <div data-qa-id="cancel-btn" class="btn btn-default" (click)="onBackClick()">
            <span>Cancel</span>
        </div>
        </li>
        <ng-template #popTemplate class="popover">
          <ul>
            <li *ngIf="errorMessage.emtpyFields">
              {{ config.translateKeys.popoverMessage1 | translate }}
            </li>
            <li *ngIf="errorMessage.notAllowedValues">
              {{ config.translateKeys.popoverMessage2 | translate }}
            </li>
          </ul>
        </ng-template>
        <li *ngIf="config.isAddMode; else elseLoadingButton">
          <loading-button
              data-qa-id="save-btn"
              [showLoader]="config.isSaving"
              (onClick)="saveAttributeClick()"
              [isDisabled]="!(attributeForm.valid  && attributeForm.dirty && isFormValuesChanged && isEditorValuesChanged)"
              [buttonCssClass]="'btn btn-primary'"
              [loadingCssClass]="'saving-loader'"
              [loadingText]="'Saving'"
              [buttonText]="'Save'" >
          </loading-button>
          <div *ngIf="config.calculationType === 'ifelse' && (errorMessage.emtpyFields || errorMessage.notAllowedValues)" (click)="scrollToInvalidEditor()" class="error-icon-bg" [popover]="popTemplate" [outsideClick]="true">
            <i class="material-icons error-icon">error</i>
          </div>
        </li>
        <ng-template #elseLoadingButton>
          <li>
            <loading-button
                data-qa-id="update-btn"
                [showLoader]="config.isSaving"
                (onClick)="editPublishedAttribute()"
                [isDisabled]="!(attributeForm.valid && (isFormValuesChanged || isEditorValuesChanged || isPersonalizationChanged || isAttributeVisibilityChanged))"
                [buttonCssClass]="'btn btn-primary'"
                [loadingCssClass]="'saving-loader'"
                [loadingText]="'Updating'"
                [buttonText]="'Update'" >
            </loading-button>
            <div *ngIf="config.calculationType === 'ifelse' && (errorMessage.emtpyFields || errorMessage.notAllowedValues)" (click)="scrollToInvalidEditor()" class="error-icon-bg" [popover]="popTemplate" [outsideClick]="true">
              <i class="material-icons error-icon">error</i>
            </div>
          </li>
        </ng-template>  
      </ul>
    </div>
  </div>
  </div>

</div>
<div  class="saving-process-disable-inputs-overlay"  *ngIf="config.isSaving"></div>
