<opti-logic-modal [modalConf]="modalConfig" data-qa-id="cy_opti_logic_modal">
    <div class="panel-body run-now-body import-csv-loader-modal-body">
        <opti-loading [show]="isLoading" [title]="loadingTitle" [text]="loadingText"></opti-loading>
        <div *ngIf="!isLoading">
            <div *ngIf="isSuccess" data-qa-id="cy_opti_logic_modal_success" >
                <div class="import-csv-loader-modal-row">
                    <div class="found-total-message-container">
                        {{ foundTotalMessage }}
                    </div>
                </div>
                <div class="import-csv-loader-modal-row">
                    <div class="import-csv-loader-modal-label">
                        {{
                        'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal.SUCCESSFULLY_UPLOADED'
                        | translate}}
                    </div>
                    <div class="import-csv-loader-modal-result">
                        {{createNewUniquePromoCodeResults?.totalUploaded  | number}}
                    </div>
                </div>
                <div class="import-csv-loader-modal-row">
                    <div class="import-csv-loader-modal-label">
                        {{
                        'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal.INVALID_CHARACTERS'
                        | translate}}
                    </div>
                    <div class="import-csv-loader-modal-result">
                        {{createNewUniquePromoCodeResults?.codesAmountWithInvalidCharacters  | number}}
                    </div>
                </div>
                <div class="import-csv-loader-modal-row">
                    <div class="import-csv-loader-modal-label">
                        {{
                        'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal.DUPLICATED_CODES'
                        | translate}}
                    </div>
                    <div class="import-csv-loader-modal-result">
                        {{createNewUniquePromoCodeResults?.duplicatedCodes  | number}}
                    </div>
                </div>
            </div>


            <div *ngIf="!isSuccess" data-qa-id="cy_opti_logic_modal_error">
                <p class="modal-error-title" data-qa-id="cy_modal-error-title">{{errorTitle}}</p>
                <p class="modal-description" data-qa-id="cy_modal-error-desc">{{errorMessage}}</p>
            </div>
        </div>
    </div>
</opti-logic-modal>