import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SecretService } from '../../../services/secret.service';
import { OptiLogicModalService } from '../../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { GcsSecretModel } from '../../../models/gcsSecretModel';

@Component({
  selector: "gcs-credentials-form",
  templateUrl: "gcsCredentialsForm.component.html",
  styleUrls: ["gcsCredentialsForm.component.scss"],
})
export class GcsCredentialsFormComponent {
  @ViewChild('jsonFileInput', { static: false }) jsonFileInput;
  @Input() gcsSecret: GcsSecretModel = {} as GcsSecretModel;
  @Output() gcsSecretChange: EventEmitter<GcsSecretModel> = new EventEmitter<GcsSecretModel>();
  @Output() verificationCredentials: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private secretService: SecretService, private modalService: OptiLogicModalService, private translate: TranslateService) {
  }

  verifyCredentials(): void {
   this.verificationCredentials.emit(true);
  }

  uploadJson() {
    this.jsonFileInput.nativeElement.value = "";
    this.jsonFileInput.nativeElement.click();
  }

  handleFileJsonInput(event: Event) {
    let files = (event.target as HTMLInputElement & EventTarget).files;
    let file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      // this 'text' is the content of the file
      this.gcsSecret.ServiceAccountJson = reader.result.toString();
    };
    reader.readAsText(file);
  }
}