import {Directive, Input} from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export const patternEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9._-]+\.[a-z]{2,4}$');

export function emailValidator() : ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
        if(control.value === "" || control.value === null || control.value === undefined) return null;
        
        if (!patternEmail.test(control.value)) {
            return { 
                invalidEmail: true 
            };
        }

        return null;
    };
  }


@Directive({
    selector: '[emailValidation]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmailValidationDirective,
        multi: true
    }]
})
export class EmailValidationDirective implements Validator {
    validate(c: AbstractControl): { [key: string]: any; } {
        return emailValidator()(c);
    }
}
