<div class="popup-button-container" (click)="$event.stopPropagation()">
  <div class="separator"></div>
  <button
    class="btn btn-link btn-link-default popup-button"
    #folderOverlayButton
    (click)="showHideOverlay()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [disabled]="isSearchActive"
    data-qa-id="visual-editor-folder-popup">
    <i class="folder-icon material-icons" [ngClass]="isSearchActive ? 'empty-folder' : ''">folder</i>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="showfolderOverlay"
    [cdkConnectedOverlayOffsetX]="-10"
    [cdkConnectedOverlayOffsetY]="-40">
    <div class="folder-popup-overlay">
      <folder-popup-overlay [navigateExternally]="navigateExternally" (templateNavigate)="templateNavigate($event)"></folder-popup-overlay>
    </div>
  </ng-template>
</div>
