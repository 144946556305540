<header class="logo"><a routerLink="/"></a></header>
<nav [class.collapsed]="isCollapsed" data-qa-id="sidenavbar">
    <div class="nav-scrollbox">
    <opti-accordion-group
        #mainAccordionGroup
        class="menu-item-parent"
        [chevronAlignment]="'end'">
        <opti-accordion-block
            #accordionBlock
            *ngFor="let menuItem of menuItems; let i = index;"
            [className]="{ 'active': activeItems[0] === menuItem }"
            [dataQaId]="menuItem.name"
            [isRouterLink]="menuItem.routerLink != null"
            [isAccordionGroupOpen]="!isCollapsed && accordionBlock.isExpand || !isCollapsed && activeItems[0] === menuItem"
            [hideChevron]="menuItem?.children?.length === 0"
            [disabled]="menuItem.isDisabled">

            <ng-container accordionHeader>
                <!-- Collapsed Popover -->
                <opti-popover class="arrow-4 sidenav-popover" [isOpen]="true" *ngIf="isCollapsed && !menuItem.isDisabled && menuItem?.children?.length > 0">
                    <div class="opti-popover-menu">
                        <div class="children-item label">
                            {{ menuItem.name }}
                        </div>
                        <ng-container *ngTemplateOutlet="innerMenuLevel"></ng-container>
                    </div>
                </opti-popover>

                <!-- TITLE -->
                <div class="title" [title]="menuItem.name">
                    <!-- ICON -->
                    <i *ngIf="menuItem.icon"  [routerLink]="menuItem.isDisabled ? null : menuItem.routerLink"
                    [ngClass]="{ 'material-icons': menuItem.icon.iconType === NavbarIconType.Fill, 'material-icons-outlined': menuItem.icon.iconType === NavbarIconType.Outlined}"
                    class="customIcon" [class]="menuItem.icon.iconName ? menuItem.icon.iconName : ''">{{menuItem.icon.iconType !== NavbarIconType.OptiFont ? menuItem.icon.iconName : ""}}</i>

                    <!-- TITLE -->
                    <a *ngIf="menuItem.routerLink" class="accordion-link" [routerLink]="menuItem.isDisabled ? null : menuItem.routerLink">{{menuItem.name}}</a>
                    <span *ngIf="menuItem?.children?.length > 0" class="accordion-link">{{menuItem.name}}</span>

                    <!-- BADGE -->
                    <div class="navbar-badge new" *ngIf="menuItem.isNew && !isCollapsed">{{ 'components.navbar.New' | translate }}</div>
                    <div class="navbar-badge notifications" *ngIf="menuItem?.isOptibot && optibotNotificationsCount > 0">{{ optibotNotificationsCount > 9 ? '+10' : optibotNotificationsCount }}</div>
                </div>
            </ng-container>
            
            <!-- Second Level-->
            <section class="menu-item-children" *ngIf="menuItem?.children?.length > 0 && !isCollapsed">
                <ng-container *ngTemplateOutlet="innerMenuLevel"></ng-container>
            </section>

            <ng-template #innerMenuLevel>
                <div class="children-item" [title]="childrenItem.name"
                    [ngClass]="{ active: activeItems[1] === childrenItem , 'disabled': childrenItem.isDisabled, 'parent': childrenItem?.children?.length > 0 && !childrenItem.isDisabled}"
                    [class.disabled]="childrenItem.isDisabled" *ngFor="let childrenItem of menuItem.children">
                    
                    <a *ngIf="childrenItem.isDisabled || childrenItem?.children?.length === 0" [ngClass]="{ active: activeItems[1] === childrenItem, 'has-badge': childrenItem.isNew }" class="router-link" [routerLink]="childrenItem.isDisabled ? null : childrenItem.routerLink">{{childrenItem.name}}</a>
                    <div class="navbar-badge new" *ngIf="childrenItem.isNew">{{ 'components.navbar.New' | translate }}</div>

                    <!-- Third Level-->
                    <opti-accordion-group class="menu-item-parent inner-level"
                        [chevronAlignment]="'end'"
                        *ngIf="!childrenItem.isDisabled && childrenItem?.children?.length > 0">
                        <opti-accordion-block #accordionBlockInner
                        [isAccordionGroupOpen]="!isCollapsed && accordionBlockInner.isExpand || secondLevelAccordion === childrenItem">
                            <ng-container accordionHeader>
                                <div class="title" [title]="childrenItem.name">
                                    <span class="accordion-link">{{childrenItem.name}}</span>
                                </div>
                            </ng-container>
                            
                            <section class="menu-item-children inner-level">
                                <div class="children-item"
                                    [title]="thirdLevelChildren.name"
                                    
                                    [ngClass]="{active: activeItems[2] === thirdLevelChildren, disabled: thirdLevelChildren.isDisabled}"
                                    *ngFor="let thirdLevelChildren of childrenItem.children">

                                    <a class="router-link" [ngClass]="{active: activeItems[2] === thirdLevelChildren, 'has-badge': thirdLevelChildren.isNew}" [routerLink]="thirdLevelChildren.isDisabled ? null : thirdLevelChildren.routerLink" [queryParams]="thirdLevelChildren.isDisabled ? null : thirdLevelChildren.queryParams">{{thirdLevelChildren.name}}</a>
                                    <div class="navbar-badge new" *ngIf="thirdLevelChildren.isNew">{{ 'components.navbar.New' | translate }}</div>
                                    <div class="navbar-badge new" *ngIf="thirdLevelChildren.isBeta">{{ 'components.navbar.Beta' | translate }}</div>
                                </div>
                            </section>
                        </opti-accordion-block>
                    </opti-accordion-group>
                </div>
            </ng-template>
        </opti-accordion-block>
    </opti-accordion-group>
    </div>
</nav>
<footer>
    <div class="menu-item settings" routerLink="/user-settings" [title]="'components.navbar.Settings' | translate">
        <i class="material-icons icon">settings</i>
        <a class="menu-label" routerLink="/user-settings">
            {{ 'components.navbar.Settings' | translate }}
        </a>
    </div>
    <div class="toggle-sidebar" [class.collapsed]="isCollapsed" (click)="setCollapseState()">
        <i class="icon-Collapse-Left"></i>
    </div>
</footer>