<div class="preview-optimail-template">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="header-content">
        <button (click)="close()" class="btn btn-link btn-link-default close-dialog">
          <i class="material-icons">clear</i>
        </button>
        <div class="btn-group target_groups_tabs" role="group" aria-label="..." *ngIf="previewEnabled">
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{active: isOnCustomerData}"
            (click)="isOnCustomerData = true; isBrowserPreviewType = true">
            {{ "features.manage_templates.components.optimailPreview.CUSTOMER_DATA" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-default"
            [ngClass]="{active: !isOnCustomerData}"
            (click)="isOnCustomerData = false; isBrowserPreviewType = true">
            {{ "features.manage_templates.components.optimailPreview.TARGET_GROUP" | translate }}
          </button>
        </div>
        <div class="previewActions">
          <button [hidden]="!isBrowserPreviewType" class="btn btn-link" (click)="isBrowserPreviewType = false">
            {{ "features.manage_templates.components.optimailPreview.INSTANT_PREVIEWS" | translate }}
          </button>
        </div>
        <div class="previewActions">
          <button [hidden]="!isBrowserPreviewType" class="btn btn-link" (click)="openInNewTab()">
            {{ "features.manage_templates.components.previewTemplate.OPEN_IN_NEW_TAB_TEXT" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="panel-body preview-container">
      <preview-template-with-data
        (templateBodyEvaluated)="templateBody = $event"
        [hidden]="!isBrowserPreviewType"
        [isOnCustomerData]="isOnCustomerData"
        [template]="template"></preview-template-with-data>
      <preview-template-instant
        [hidden]="isBrowserPreviewType"
        [template]="template"
        [evaluatedTemplateBody]="templateBody"></preview-template-instant>
    </div>
  </div>
</div>
