import { Injectable } from '@angular/core';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class ErrorModalService {
	constructor(private modalService: OptiLogicModalService, private translate: TranslateService) {}

	public openErrorModal(title: string, actionName: string) {
		this.modalService.openModalMessage('sm', {
			title: title,
			html: this.translate.instant('features.manage_templates.optimail.templateFolderActions.NOT_FOUND_ERROR', {
				actionName: actionName
			}),
			hideFooterBorder: false,
			hideHeaderBorder: false,
			buttons: [
				{
					class: 'btn-primary',
					label: 'OK',
					action: () => {}
				}
			]
		});
	}
}
