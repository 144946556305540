import { Injectable } from '@angular/core';
import { SsmService } from '../../../services/ssm.service';
import { NotificationInfo, NotificationResult } from '../models/optibotNotification.model';
import { connect } from 'getstream'
import { TenantDetails } from '../../../modules/navbar/models/tenantDetails.model';
import { SsmServiceEndPoints } from '../../../services/optimove-ssm/optimove-ssm-resources.model';

@Injectable({ providedIn: 'root' })
export class OptibotNotificationService {
    _isInitialized = false;
    _client = null;
    _notifications = null;
    _eventHandlers = {};
    events = Object.freeze({
        "updated": "updated",
        "read": "read"
    });
    _pendingRes: NotificationResult = null;

    constructor(private ssmService: SsmService) {
    }

    getDefaultValue() {
        return {
            next: "",
            results: [],
            unread: 0,
            unseen: 0,
        };
    }

    resetPending() {
        this._pendingRes = null;
    }

    isCardRead(cardId: number) {
        if (!this._isInitialized) {
            return false;
        }
        if (this._pendingRes == null || this._pendingRes.results == null || this._pendingRes.results.length == 0) {
            return true;
        }

        const cardNotification = this._pendingRes.results.find(r => r.verb == cardId.toString())
        if (cardNotification == null) {
            return true;
        }

        return cardNotification.is_read;
    }

    getPending() {
        if (!this._isInitialized) {
            return Promise.resolve([]);;
        }
        return this._notifications.get({ limit: 100, offset: 0 }).then((data: NotificationResult) => {
            this._pendingRes = data;
            return data;
        });
    }

    readAll() {
        if (!this._isInitialized) {
            return Promise.resolve([]);
        }
        const params = { mark_read: true };
        return this._notifications.get(params).then((res: NotificationResult) => {
            this.broadcast(this.events.read, true);
            return res;
        });
    }

    readNotifications(notifications) {
        if (!this._isInitialized || notifications.length == 0) {
            return Promise.resolve([]);
        }
        const notificationsIds = notifications.map((notification) => {
            return notification.id;
        });
        const params = { mark_read: notificationsIds.join(',') };
        return this._notifications.get(params).then((result) => {
            this.broadcast(this.events.read, notificationsIds);
            return result;
        });
    }

    readCard(cardId: number) {
        if (!this._isInitialized || !cardId) {
            return Promise.resolve([]);
        }
        return this._findNotification(cardId, { limit: 100, offset: 0 }, true).then((notification) => {
            if (notification) {
                return this.readNotifications([notification]);
            } else {
                console.log('Couldn\'t find notification matching cardId');
                return null;
            }
        });
    }

    private _findNotification(searchKey, params, isUnread) {
        const context = this;
        return this._notifications.get(params).then((res) => {
            const notifications = res.results;
            const matchingNotification = notifications.find((notification) => {
                const activity = notification.activities[0];
                if (isUnread && notification.is_read) {
                    return false;
                }
                return activity.foreign_id === searchKey.toString();
            });
            if (matchingNotification) {
                return matchingNotification;
            }
            if (res.next) {
                const querystring = context._extractQuerystring(res.next)
                params.id_lt = querystring.get('id_lt');
                return this._findNotification(searchKey, params, isUnread);
            }
            return null;
        });
    }

    private _extractQuerystring(url: string) {
        const parser = document.createElement('a');
        parser.href = url;
        return new URLSearchParams(parser.search.slice(1));
    }

    register(eventType: string, handler: Function) {
        if (!this._isInitialized) {
            return;
        }
        if (!this._eventHandlers[eventType]) {
            this._eventHandlers[eventType] = {};
        }
        const identifier = this._guid();
        this._eventHandlers[eventType][identifier] = handler;
        return identifier;
    }

    deregister(eventType: string, identifier) {
        if (!this._isInitialized) {
            return;
        }
        if (this._eventHandlers[eventType] && this._eventHandlers[eventType][identifier]) {
            delete this._eventHandlers[eventType][identifier];
        }
    }

    broadcast(eventType: string, data) {
        if (!this._isInitialized) {
            return;
        }

        for (const handlerId in this._eventHandlers[eventType]) {
            const handlers = this._eventHandlers[eventType];
            if (handlers && handlers[handlerId]) {
                handlers[handlerId](data);
            }
        }
    }

    async init() {
        if (this._isInitialized) {
            console.log("optibot notification service was initialized more than once, returning");
            return false;
        }
        const tenantDetails = await this.ssmService.GetGenericAsync<TenantDetails>(SsmServiceEndPoints.TenantDetails);
        try {
            let shouldInitialize = this.isAutomationUser(tenantDetails);
            if (!shouldInitialize) {
                return false;
            }
            const notificationInfo = await this.ssmService.GetGenericAsync<NotificationInfo>(SsmServiceEndPoints.NotificationInfo);
            this._isInitialized = true;
            if (this._validateNotificationInfo(notificationInfo)) {
                this._client = connect(notificationInfo.apiKey, null, notificationInfo.appId, { timeout: 3000 });
                this._notifications = this._client.feed('notification', notificationInfo.userId, notificationInfo.token);
                this.setupNotificationFeed();
                return true;
            }
            return false;
        } catch(error) {
            console.log('getGenericAsync Error: ' + error);
            return false;
        }
    }

    private isAutomationUser(tenantDetails: any): boolean {
        let isMesuvagDomain = false;
        if (tenantDetails.userName) {
            let username = tenantDetails.userName.split('@').slice(-1)[0];
            isMesuvagDomain = username.toLowerCase().indexOf('mesuvag.com') === -1;
        }
        let isTestName = false;
        if (tenantDetails.name) {
            isTestName = tenantDetails.name && tenantDetails.name.toLowerCase().indexOf('automation') === -1;
        }
        return isMesuvagDomain || isTestName;
    }

    private _validateNotificationInfo(notificationInfo: NotificationInfo) {
        return notificationInfo && notificationInfo.apiKey && notificationInfo.appId && notificationInfo.userId && notificationInfo.token;
    }

    setupNotificationFeed() {
        this._notifications.subscribe(this.callback.bind(this)).then(this.successCallback, this.failCallback);
    }

    callback(data: any) {
        return this.getPending().then((data) => {
            this.broadcast(this.events.updated, data);
        });
    }

    successCallback() {
        console.log('now listening to changes in realtime');
    }

    failCallback(data) {
        console.log(data);
    }

    private _guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    };
}