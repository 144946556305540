<div class="android-notification-channels" *ngIf="this.channelList">
    <div class="row dynamic-row form-group">
        <p class="col-sm-2">{{'features.manage_templates.components.optipushNotificationChannels.ANDROID' | translate}}</p>
        <div class="col-sm-9">
            <div class="radio radio-primary radio-amount-type" *ngFor='let channel of channelList'>
                <input class="form-control radio-btn-chooser" type="radio" name="notificationChannels" [value]="channel.channelId"
                       [ngModel]="chosenChannel.channelId" (change)="chosenChannelChanged(channel)">
                <label>{{channel.name}}</label>
            </div>
        </div>
    </div>
</div>

