import { IModalConf, IModalButtonConf } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { ConsentModalService } from "./consent-modal.service";
import { IBrandDetails } from "../../../settings/settingsSubscribers/models/brandDetails.model";
import { BrandWithAudit } from "../../../settings/settingsSubscribers/models/brandWithAuditData";
import {forkJoin, Observable} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import { IServerSideDatasource, ColumnApi, AllEnterpriseModules } from "@ag-grid-enterprise/all-modules";

import {
    AllCommunityModules,
    ColDef,
    GridOptions,
    Module,
} from "@ag-grid-enterprise/all-modules";
import moment from "moment";
import {FeatureFlag, FeatureFlagService} from "../../../../services/featureFlag.service";

@Component({
    selector: 'consent-history-modal',
    templateUrl: './consentModal.component.html',
    styleUrls: ['./consentModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ConsentModalComponent implements OnInit {
    public modalConf: IModalConf;
    @Input() featureflag: boolean;
    @Input() customerId : number ;
    @Input() clientCustomerId : string ;
    @Input() buttondisabled : boolean;
    public coloredTagType: ColoredTagType;
    public coloredTagTypeIn: ColoredTagType;
    public optedOutBrandList;
    public optedInBrands;
    public imageSrc: string;
    public optimailOptenInBrandsList: IBrandDetails[];
    public isLoading : boolean;
    public brands;
    public auditDataAllBrands: BrandWithAudit[] = [];
    public email: string;
    public offset: number = 0;
    public pagingCount: number = 20;
    public gridOptionsArray: GridOptions[] = [];
    private gridApi: {} = {};
    public modules: Module[] = [...AllCommunityModules, ...AllEnterpriseModules];
    public columnDefs = this.createColumnDefs();
    private gridColumnApi: ColumnApi;

    constructor(private bsModalRef: BsModalRef,
                public translateService: TranslateService,
                private consnetModalService:ConsentModalService,
                private featureFlagService: FeatureFlagService) {
    }

    async ngOnInit(): Promise<void> {
        this.setModalConf();
        this.onCancel.bind(this);
        this.coloredTagType = ColoredTagType.Type5;
        this.coloredTagTypeIn = ColoredTagType.Type4;
        this.isLoading = true;
        this.offset = 0;
        this.pagingCount = 20;
        const getAuditBrandsAfterEmail$: Observable<BrandWithAudit[]> =  this.getUserEmail(this.customerId).pipe(
            switchMap((email: string) => {
                this.email = email;
                let identifier = this.featureFlagService.isEnabled(FeatureFlag.useAuditByEmail) ? this.email : this.clientCustomerId;
                return this.consnetModalService.getAuditAllBrands(identifier);
            })
        )
        this.optedInBrands = [];
        forkJoin([
            this.consnetModalService.getBrands(),
            this.consnetModalService.optedOutBrandListByCustomerId(this.customerId),
            getAuditBrandsAfterEmail$,
        ]).subscribe(([brands, optedOutBrandList, auditDataAllBrands]) => {
            this.brands = brands;
            this.optedOutBrandList = this.getOptedOutBrandList(optedOutBrandList);
            this.optimailOptenInBrandsList = this.getOptedInBrandList(this.brands, this.optedOutBrandList);
            this.auditDataAllBrands = auditDataAllBrands;
            //get brands that don`t have audit
            let brandsWithoutAudit = this.brands.filter(value => !this.auditDataAllBrands.map(x => x.BrandName).includes(value.brandName) && (!value.isTransactional));
            let brandsWithoutAuditName = []
            brandsWithoutAudit.forEach(brand => brandsWithoutAuditName.push(brand.brandName));
           let brandsWithoutAuditUnique = brandsWithoutAuditName.filter((item, i, ar) => ar.indexOf(item) === i);
            //add those brands to audit data array with empty audit
            brandsWithoutAuditUnique.forEach(brand => this.auditDataAllBrands.push(new BrandWithAudit(
                brand, false, 0)))
            //check out opted in brands and set IsOptedIn = true in audit data array
            this.optimailOptenInBrandsList.forEach(brand => this.auditDataAllBrands.filter(x => x.BrandName === brand.brandName)[0].IsOptedIn = true)
            this.auditDataAllBrands = this.auditDataAllBrands.sort((a, b) => (a.BrandName.toLowerCase() < b.BrandName.toLowerCase()) ? -1 : 1);
            var number = 0;
            this.auditDataAllBrands.forEach(value => {
                value['GridNumber'] = number++;
                this.gridOptionsArray = this.gridOptionsArray.concat([this.createGrid(value)]);
            })
            this.isLoading = false;
            window.dispatchEvent(new Event('resize'));
        });
    }

    public createGrid(value) : GridOptions {
        return <GridOptions> {
            context: this,
            columnDefs: this.createColumnDefs(),
            defaultColDef: this.createDefaultColDef(),
            headerHeight: 44,
            rowHeight: 44,
            rowBuffer: 0,
            onGridSizeChanged: this.onGridSizeChanged,
            rowModelType: "serverSide",
            serverSideStoreType: "partial",
            blockLoadDebounceMillis: 1000, // debounce the loading to prevent blocks loading until scrolling has stopped
            cacheBlockSize: 20, // how many rows returned from the server at a time
            cacheOverflowSize: 2, // extra blank rows to display to the user at the end of the dataset
            maxConcurrentDatasourceRequests: 1, // requests to hit the server with concurrently
            infiniteInitialRowCount: 2, // How many extra blank rows to display to the user at the end of the dataset
            maxBlocksInCache: 20, // How many blocks to keep in the store
            onGridReady: (params) => {
                this.gridApi[value.BrandName] = params.api;
                this.gridColumnApi = params.columnApi;
                var datasource = this.createServerSideDatasource(value.BrandName);
                params.api!.setServerSideDatasource(datasource);
            },
        }
    }

    private createServerSideDatasource(brand): IServerSideDatasource {
        return {
            getRows: (params) => {
                const { startRow, endRow } = params.request;
                let identifier = this.featureFlagService.isEnabled(FeatureFlag.useAuditByEmail) ? this.email : this.clientCustomerId;
                this.consnetModalService.getAudit(identifier, brand, startRow, endRow)
                    .pipe(tap(resp => {
                        if (!resp) {
                            params.fail();
                        }
                        params.success({
                            rowData: resp.auditData,
                            rowCount: resp.total,
                        });
                    }))
                    .subscribe();
            },
        };
    }

    private onGridSizeChanged(params) {
        params.api.sizeColumnsToFit();
    }

    private createDefaultColDef(): ColDef {
        return {
            minWidth: 25,
            lockPinned: true,
            headerClass: 'header-class',
        };
    }

    private createCellStyle() {
        return {
            'text-align': 'left',
            'font': 'normal normal normal 14px/16px Roboto',
            'color': '#37474E',
            'padding-left': '16px',
            'padding-right': '16px',
            'margin-top': '13px'
        }
    }

    private createColumnDefs(): ColDef[] {
        return [
            { headerName: 'Date', field: 'AuditDate', initialWidth: 150,   cellStyle: () => { return this.createCellStyle()}, valueFormatter: this.dateFormatter},
            { headerName: 'Event', field: 'OptIn', initialWidth: 108, cellStyle: () => { return this.createCellStyle()}},
            { headerName: 'Event Method', field: 'EventOrigin', initialWidth: 100,   cellStyle: () => { return this.createCellStyle()}},
        ];
    }

    dateFormatter(params) {
        return moment(params.value,'DD/MM/YYYY HH:mm:ss').format('DD MMM YYYY, HH:mm:ss');
    }

    setModalConf(): void {

        this.modalConf = {
            title: `${this.translateService.instant('features.customer360.consent_modal.TITLE')}: ${this.clientCustomerId}`,
            xButton: this.getXButtonsConf(),
            hideHeaderBorder: false,
            hideFooterBorder: true ,
            hideFooterPanel: true,
        };
    }

    onCancel(): void {
        this.bsModalRef.hide();
    }

    getXButtonsConf(): IModalButtonConf {
        return {
            action: this.onCancel.bind(this)
        };
    }

    getUserEmail(customerId): Observable<string> {
        return this.consnetModalService.getUserEmail(customerId);
    }

    getOptedOutBrandList(input) : string[]{
        return input.value.filter(brand => brand.length > 0);
    }

    getOptedInBrandList(allBrands: IBrandDetails[], optedOutBrands: string[]) : IBrandDetails[]{
        return allBrands.filter( brand => !optedOutBrands.includes(brand.brandName) && brand.brandName.length > 0 && !brand.isTransactional);
    }
}
