import { Injectable } from "@angular/core";
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { SsmService } from "../../../../services/ssm.service";
import { AttributeTypeFamilyArray } from '../attributesList.constants';
import { AttributeHelper } from "../attributesList.helper";
import { AttributeConfiguration, BaseAttribute } from "../attributesList.model";
import { AttributeFamily, AttributeType } from './../attributesList.enums';
import { AttributesManagerService } from "./attributesManager.service";
import { AttributesListExportService } from './attributesListExport.service';
import { AttributesTitles } from '../models/attributesTitles.model';
import { MenuItemDef } from '@ag-grid-community/all-modules';

@Injectable()
export class AttributesListService {
    public internalFields: string[] = [];
    private sessionStorageManager: any;

    constructor(
        private readonly http: HttpService,
        ssmService: SsmService,
        private attrManagerService: AttributesManagerService,
        private exportService: AttributesListExportService) {
            this.sessionStorageManager = ssmService.getSSM();
    }

    public saveAttribute(type: AttributeType, attribute: BaseAttribute): Observable<DefaultAjaxResponse> {
        return this.attrManagerService.saveAttribute(attribute, this.attrTypeToFamily(type));
    }

    public publishAll(): Observable<DefaultAjaxResponse> {
        return this.attrManagerService.publishAll();
    }


    public getCountOfCreatedAttributes(): number {
        return this.attrManagerService.getCountOfCreatedAttributes();
    }

    public updateAttribute(type: AttributeType, attribute: any): Observable<DefaultAjaxResponse> {
        return this.attrManagerService.updateAttribute(attribute, this.attrTypeToFamily(type)); 
    }

    public getAttributeConfiguration(): Observable<AttributeConfiguration> {
        return this.attrManagerService.getAttributeConfiguration();
    }

    public getAttributesTags(): Observable<AttributesTitles> {
        if(!AttributeHelper.attributesTypes) {
            return this.getAttributesTitles(["realtime", "api", "calculated"]).pipe(tap((attributesTitles: AttributesTitles) => {
                AttributeHelper.attributesTypes = attributesTitles;
            }));
        } else {
            return of(AttributeHelper.attributesTypes);
        }
    }

    public getAttributes(): Observable<any[]> {
        return this.attrManagerService.getAttributes();
    }

    public addAttributeToProfile(attr: any): void {
        this.attrManagerService.addAttributeToProfile(attr);
    }

    public deleteAttributeFromProfile(attributeName: string): void {
        this.attrManagerService.deleteAttributeFromProfile(attributeName);
    }

    public searchInProfile(attrName: string): any {
        return this.attrManagerService.searchInProfile(attrName);
    }

    public deleteAttribute(type: AttributeType, realFieldName: string): Observable<DefaultAjaxResponse> {
        return this.attrManagerService.deleteAttribute(realFieldName, this.attrTypeToFamily(type))
    }

    public async updateCustomerAttributes() {
        await this.sessionStorageManager.getGenericAsync(this.sessionStorageManager.Resx.CustomerAttributesEnums, true);
        await this.sessionStorageManager.getGenericAsync(this.sessionStorageManager.Resx.CustomerAttributes, true);
    }

    public getLastPublishTime(): Observable<DefaultAjaxResponse> {
        return this.attrManagerService.getLastPublishTime();
    }

    public getInternalFieldsList(): Observable<any> {
        return this.attrManagerService.getInternalFieldsList()
        .pipe(map((res) => {
            this.internalFields = res.Data;
        }));
    }
    
    public changeAttributeVisibility(fieldName: string, hide: boolean): Observable<DefaultAjaxResponse> {
        return new Observable<DefaultAjaxResponse>((observer) => {
            this.changeAttributeVisibilityRequest(fieldName, hide).pipe(first()).subscribe(async res => {
                if(res.IsSuccess) {
                    await this.sessionStorageManager.getGenericAsync(this.sessionStorageManager.Resx.CustomerAttributesEnums, true);
                    await this.sessionStorageManager.getGenericAsync(this.sessionStorageManager.Resx.CustomerAttributes, true);
                }
                observer.next(res);
                observer.complete();
            });
        });
    }

    public getContextMenuItems(params: any) {
        console.log(params);
        var result: (string | MenuItemDef)[] = [
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            {
                icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
                name: 'Export',
                subMenu: [
                    {
                        name: "CSV Export",
                        action: function() {
                            params.api.exportDataAsCsv({columnKeys: AttributeHelper.columnsToExport, processCellCallback: this.exportService.customProcessCell});
                        }.bind(this)
                    },
                    {
                        name: "Excel Export (.xlsx)",
                        action: function() {
                            params.api.exportDataAsExcel({columnKeys: AttributeHelper.columnsToExport, processCellCallback: this.exportService.customProcessCell});
                        }.bind(this)
                    },
                    {
                        name: "Excel Export (.xml)",
                        action: function() {
                            params.api.exportDataAsExcel({columnKeys: AttributeHelper.columnsToExport, processCellCallback: this.exportService.customProcessCell, exportMode: 'xml'});
                        }.bind(this)
                    }
                ],
            }
        ];
        return result;
    }

    // ==== private ====
    private changeAttributeVisibilityRequest(fieldName: string, hide: boolean) {
        const params = HttpService.toHttpParams({ fieldName, hide });
        return this.http.get<DefaultAjaxResponse>('/AttributesManager/ChangeAttributeVisibility', params);
    }

    private getAttributesTitles(types: string[]): Observable<AttributesTitles> {
        const url: string = `/CustomerExplorerNew/GetAttributesTitles?types=${types.join()}`;

        return this.http.get(url).pipe(
            map((res: DefaultAjaxResponse) => res.Data as AttributesTitles)
        );
    }

    private attrTypeToFamily(type: AttributeType): AttributeFamily {
        return AttributeTypeFamilyArray.find(x => x.type == type).family;
    }
}
 
