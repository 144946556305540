import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HttpService } from "./optimove-http/optimove-http.model";
import { OptimoveHttpService } from "./optimove-http/optimove-http.service";
import { WindowRefService } from "./windowRef.service";
import { Observable } from "rxjs";

@Injectable()
export class SignalrService6 {
  private isSignalRCore: boolean | undefined; 
  private connections: { [key: string]: signalR.HubConnection } = {};

  constructor(private windowService: WindowRefService, private readonly http: HttpService) {}
  async init(): Promise<void> {
    this.isSignalRCore = await this.doesSignalRCoreExist().toPromise();
    this.setupExpiry();
  }

  //this method is needed to check if ourbackend knows to handle signalr core
  doesSignalRCoreExist(): Observable<boolean> {
    return this.http.get<boolean>('Info/IsSignalRCore');
  }

  getConnection(hubName: string): signalR.HubConnection {
    let connection = this.connections[hubName];
    if (!connection && this.isSignalRCore) {
        connection = this.createConnection(`${OptimoveHttpService.baseApiUrlString}/signalrcore/${hubName}`);
        this.connections[hubName] = connection;
        (async () => await this.startConnection(hubName, connection))();
      }
    return connection;
  }

  private createConnection(
    hubUrl: string
  ): signalR.HubConnection {
    return new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        headers: {            
			    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
    })      
      .withAutomaticReconnect()
      .build();
  }
  
  private async startConnection(
    hubName: string,
    connection: signalR.HubConnection
  ): Promise<void> {
    try {
      await connection.start();
      connection.onreconnecting((error) => {
        console.log(`SignalR ${hubName} 6.0 reconnecting due to ` + error);
      });
      connection.onreconnected((connectionId) => {
        console.log(
          `SignalR ${hubName} 6.0 reconnected. Connection Id: ` + connectionId
        );
      });
      connection.onclose((error) => {
        console.log(`SignalR ${hubName} 6.0 disconnected due to ` + error);
        setTimeout(() => {
          connection.start();
        }, 5000);
      });
    } catch (error) {
      console.warn(`Error while starting SignalR ${hubName} 6.0`);
    }
  }

  private setupExpiry() {
    if(this.isSignalRCore){
      this.getConnection("sessionExpiry")?.on("loggedOut", () => {
        this.windowService.nativeWindow.location.href =
          HttpService.baseApiUrlString + "/auth/login";
      });

      this.getConnection("sessionExpiry")?.on("expired", () => {
        sessionStorage.setItem("loggedOutOfPage", location.hash);
        this.windowService.nativeWindow.location.href = "/ErrorPages/SessionExpired.aspx";
      });
    }
  }
}
