import { Injectable } from "@angular/core";
import { IChannelMetricsElement } from "./campaignAnalysis.models";

@Injectable({
    providedIn: 'root'
})
export class ChannelMetricsUtilitiesService {
    isNavigatable(metric: IChannelMetricsElement): boolean {
        return metric && metric.showLink && !!metric.number;
    }
}