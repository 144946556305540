import { AttributeFamily } from './../../attributesList.enums';
import { AttributeValidatorService } from './../../services/attributeValidator.service';
import { ConditionalAttributeService } from './../../services/condition.service';
import { ConditionalAttribute, FormulaBasedAttribute, Condition, MultyExpressionsConditionalAttribute, AttributeLog, ConditionConfig, ConditionalEditorArrayConfig} from './../../attributesList.model';
import { conditionFormatItems, formulaFormatItems, specialCharactersRegex } from './../../attributesList.constants';
import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnInit, Type, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { OptiLogicModalService } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { Router } from "@angular/router";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { AttributeCalculatorComponent } from "../attributeCalculator/attributeCalculator.component";
import { AttributeFormat, FormulaType } from "../../attributesList.enums";
import { CalculatorConfig } from "../attributeCalculator/attributeCalculator.model";
import { FeatureFlag, FeatureFlagService } from '../../../../../services/featureFlag.service';
import {  SearchListConfig } from "../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { CustomerAttrEditorRef } from '../../directives/customerAttributeEditorRef.directive';
import { ConditionalEditorComponent } from '../conditionalEditor/conditionalEditor.component';
import { IAttributeEditor } from '../../interfaces/editor.interface';
import { TestFormulaModalComponent } from '../testFormulaModal/testFormulaModal.component';
import { FormulaService } from '../../services/formula.service';
import { AttributeHelper } from '../../attributesList.helper';
import { filter, first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AttributesManagerService } from '../../services/attributesManager.service';
import { ConditionalEditorArrayComponent } from '../conditionalEditorArray/conditionalEditorArray.component';
import { AttributesListService } from '../../services/attributesList.service';

@Component({
    selector: 'customer-attribute',
    templateUrl: './customerAttribute.component.html',
    styleUrls: ['./customerAttribute.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomerAttributeComponent implements OnInit, AfterViewInit {
    @ViewChild(CustomerAttrEditorRef, {static: true}) editorRefDir: CustomerAttrEditorRef;
    
    private editorComponentFactory: ComponentFactory<IAttributeEditor>;
    private editorComponentRef: ComponentRef<IAttributeEditor>;
    private activeModalRef: BsModalRef;
    private multipleCondFF = false; 
    
    public attributeForm: FormGroup;
    public attribute: FormulaBasedAttribute | MultyExpressionsConditionalAttribute;
    public initialAttribute: FormulaBasedAttribute | MultyExpressionsConditionalAttribute;
    public config = {
        isSaving: false,
        isAddMode : true,
        isPublished: false,
        initialName: "",
        translateKeys: translateKeys,
        family: "Customer Attributes",
        calculationType: 'formula',
        formulaFormats: formulaFormatItems,
        attributeFormat: AttributeFormat.Number,
        lastUpdate: "",
        formulaFormatFormValue: [{ key: AttributeFormat.Number, text: 'Number' }],
        isAttributeValid : false,
        error: {
            attributeName: false,
            formula: false,
            formulaFormat: false,
            message: ""
        },
        attributeStatus: {
            color: ColoredTagType.Type12,
            description: "Not Published"
        },
    };

    public enumFormulaType = FormulaType;
    public isFormValuesChanged = false;
    public isEditorValuesChanged = false;
    public isPersonalizationChanged = false;
    public isAttributeVisibilityChanged = false;
    public canAttributeBeHidden = false;
    public isDailyCompleted = true;
    public errorMessage = {"emtpyFields": false, "notAllowedValues": false};
    public calculatorConfig: CalculatorConfig = {
        attributeName: '',
        items: [],
        actionItems: [],
        numberOfAttributesAllowed: 3,
        numberOfShowsPerAttribute: 3,
        attributes: [],
        isAddMode : true,
        formulaType:  FormulaType.NotSet,
        formula: '', 
        params: '',
        isPublished: false,
        isDisabled: false,
        ignoreYear: false
    }

    public conditionConfig: ConditionConfig = {
        attributeName: '',
        conditions: [] as Condition[],
        showComplexExpression: true,
        complexExpression: '',
        values: ['', ''],
        isDisabled: false,
        isAddMode: true,
        format: AttributeFormat.String
    }

    public multipleConditionConfig: ConditionalEditorArrayConfig = {
        attributeName: '',
        attributeBody: null,
        isDisabled: false,
        isAddMode: true
    }

    constructor(
        public attrManagerService: AttributesManagerService,
        private formBuilder: FormBuilder,
        private router: Router,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private translate: TranslateService,
        private featureFlagService: FeatureFlagService,
        private resolver: ComponentFactoryResolver,
        private formulaService: FormulaService,
        private conditionService: ConditionalAttributeService,
        private attributeValidatorService: AttributeValidatorService,
        private attributeListService: AttributesListService,) {
        this.attribute = {} as FormulaBasedAttribute | MultyExpressionsConditionalAttribute;
        this.multipleCondFF = this.featureFlagService.isEnabled(FeatureFlag.multipleConditionAttribute);
    }

    ngOnInit() {
        this.createForm();
        const attribute = this.router.getCurrentNavigation().extras.state as MultyExpressionsConditionalAttribute | FormulaBasedAttribute;
        this.isDailyCompleted = this.attrManagerService.isDailyCompleted.value;

        if (attribute) {
            this.attributeValidatorService.canAttributeBeHidden(attribute.FieldName).pipe(first()).subscribe((res) => {
                this.canAttributeBeHidden = res.IsSuccess;
            });

            this.initialAttribute = attribute;
            this.config.isAddMode  = false;
            this.initPageInEditMode(attribute);
            this.attrManagerService.getLastActivity(this.attribute.Name)
            .pipe(first(), filter(x => !!x))
            .subscribe((res: AttributeLog) => {
                this.config.lastUpdate = `Last modified on ${AttributeHelper.getTimestamp(res.Time)} by ${res.User}`
            });
        }
        else {
            this.changeCalculationType(this.config.calculationType);
        }

        this.attributeNameFormControl.valueChanges.subscribe((val: string) => { 
            this.config.error.attributeName = false;
            this.calculatorConfig.attributeName = val;
            this.conditionConfig.attributeName = val;
        });

        this.attrManagerService.isAttributeEditorValid.subscribe((isValid: boolean) => {
            this.attrEditorFormControl.setErrors(isValid ? null : {'incorrect': true});
        });
    }

    ngAfterViewInit() {
        let orginaleValues:any = {
            attributeName: this.attributeNameFormControl.value,
            description: this.descriptionFormControl.value,
            attributeFormat: this.attributeFormatFormControl.value,
        };

        this.attributeForm
                .valueChanges
                .subscribe((next: any) => {
                    let nextValuesToCompare = {
                        attributeName: next.attributeName,
                        description: next.description,
                        attributeFormat: next.attributeFormat,
                    };
                    if(  JSON.stringify(orginaleValues) === JSON.stringify(nextValuesToCompare)){
                        this.isFormValuesChanged = false;
                    } else {
                        this.isFormValuesChanged = true;
                    }
                });
    }

    public changePersonalization(isPeronalization: boolean){
        this.isPersonalizationChanged = !this.isPersonalizationChanged;
        this.attribute.IsPersonalization = !isPeronalization;
    }

    public changeAttributeVisibility() {
        this.isAttributeVisibilityChanged = !this.isAttributeVisibilityChanged;
    }

    private setupCalculatorConfig() {
        this.calculatorConfig.attributeName = this.attribute.DisplayName;
        this.calculatorConfig.isAddMode  = false;
        this.calculatorConfig.formulaType = (this.attribute as FormulaBasedAttribute).SourceFieldName 
            ? FormulaType.DateBase : FormulaType.FormulaBase;
        this.calculatorConfig.formula = (this.attribute as FormulaBasedAttribute).Formula;
        this.calculatorConfig.params = (this.attribute as FormulaBasedAttribute).AttributeDetails.map(x => x.Name).toString();
        this.calculatorConfig.isPublished = this.attribute.PublishStatus === "Published";
        this.calculatorConfig.ignoreYear = (this.attribute as FormulaBasedAttribute).IgnoreYear;
        this.calculatorConfig.isDisabled = this.attribute.PublishStatus === "Published" && !this.isDailyCompleted;
    }

    private setupConditionConfig() {
        if(this.multipleCondFF) {
            this.conditionConfig.attributeName = this.attribute.DisplayName;
            this.multipleConditionConfig.isDisabled = this.attribute.PublishStatus === "Published" && !this.isDailyCompleted;
            this.multipleConditionConfig.isAddMode = false;
            this.multipleConditionConfig.attributeBody = this.attribute as MultyExpressionsConditionalAttribute;
        } else {
            // REMOVE AFTER REALISE
            let attribute = this.conditionService.MultyExpressionsToConditionalAttribute(this.attribute as MultyExpressionsConditionalAttribute);
            this.conditionConfig.attributeName = attribute.DisplayName;
            this.conditionConfig.conditions = (attribute as ConditionalAttribute).Conditions;
            this.conditionConfig.complexExpression = (attribute as ConditionalAttribute).ComplexExpression;
            this.conditionConfig.values = (attribute as ConditionalAttribute).Values;
            this.conditionConfig.isDisabled = this.attribute.PublishStatus === "Published" && !this.isDailyCompleted;
            this.conditionConfig.isAddMode = false;
    
            this.conditionConfig.showComplexExpression = (this.attribute as MultyExpressionsConditionalAttribute)
                .ExpressionsArray.map(x => x.ShowComplexExpression).reduce((a,b) => a && b);
        }
    }

    private initPageInEditMode(attribute: MultyExpressionsConditionalAttribute | FormulaBasedAttribute) {
        this.attribute = attribute;

        if ((attribute as MultyExpressionsConditionalAttribute).ExpressionsArray) {
            this.config.calculationType = "ifelse";
            this.config.formulaFormats = conditionFormatItems;
            this.attributeFormatFormControl.setValue([{ key: AttributeFormat.String, text: 'String' }]);
            this.config.attributeFormat = AttributeFormat.String;
            this.setupConditionConfig();
        }
        else if ((attribute as FormulaBasedAttribute).Formula) {
            this.config.calculationType = 'formula';
            this.config.formulaFormats = formulaFormatItems;
            this.attributeFormatFormControl.setValue([{ key: AttributeFormat.Number, text: 'Number' }]);
            this.config.attributeFormat = AttributeFormat.Number;
            this.setupCalculatorConfig();
        }
        this.changeCalculationType(this.config.calculationType);

        this.config.attributeFormat = this.attribute.Format;
        this.config.isPublished = attribute.PublishStatus === "Published";
        this.config.initialName = attribute.Name;
        this.config.isAttributeValid = true;
        this.config.attributeStatus = {
            color: attribute.PublishStatus === "Published" ? ColoredTagType.Type4 : ColoredTagType.Type12,
            description: attribute.PublishStatus === "Published" ? "Published" : "Not Published"
        }
        this.attributeNameFormControl.setValue(this.attribute.DisplayName, { emitEvent: false });
        this.descriptionFormControl.setValue(this.attribute.Description, { emitEvent: false });
        this.attributeFormatFormControl.setValue([{ key: this.attribute.Format, text: this.attribute.Format.toString() }]);
        this.config.formulaFormatFormValue = [{ key: this.attribute.Format, text: AttributeFormat[this.attribute.Format].toString() }];
    }
    
    private createForm() {
        this.attributeForm = this.formBuilder.group({
            attributeName: ['', [Validators.required, Validators.maxLength(100), this.attributeValidatorService.validateEmptySpaces(), this.attributeValidatorService.validateSpecialSymbols()]],
            description: [],
            calculationType:[], 
            attrEditor:[],
            attributeFormat: [],
        });
    }

    private createCalculator() {
        this.setEditorComponent(AttributeCalculatorComponent);
        this.editorComponentRef.instance.config = this.calculatorConfig;
    }

    private createConditionEditor() {
        if(this.multipleCondFF) {
            this.setEditorComponent(ConditionalEditorArrayComponent);
            this.editorComponentRef.instance.config = this.multipleConditionConfig;
            this.editorComponentRef.instance.errorMessageEmitter.subscribe(
                (errorMessage: {"emtpyFields": boolean, "notAllowedValues": boolean}) => {
                     this.errorMessage = errorMessage; 
                }
            );
        }
        else {
            // REMOVE AFTER REALISE
            this.setEditorComponent(ConditionalEditorComponent);
            this.editorComponentRef.instance.config = this.conditionConfig;
        }
    }

    private setEditorComponent(componentType: Type<IAttributeEditor>) {
        this.attrManagerService.isAttributeEditorValid.next(false);
        this.editorRefDir.editorRef.clear();
        this.editorComponentFactory = this.resolver.resolveComponentFactory(componentType);
        this.editorComponentRef = this.editorRefDir.editorRef.createComponent(this.editorComponentFactory);
        this.isEditorValuesChanged = false;
        this.editorComponentRef.instance.changeEmitter.subscribe(
            (isChanged: boolean) => { this.isEditorValuesChanged = isChanged }
        );
    }

    //Multiple conditions 
    public scrollToInvalidEditor() {
        this.editorComponentRef.instance.scrollToInvalidEditor();
    }

    public saveAttributeClick() {
        if(this.config.calculationType == 'ifelse') {
            this.attribute = this.setupConditionalAttribute();
            this.attrManagerService.saveAttribute(this.attribute, "Conditional")
            .pipe(first(), filter(x => !!x))
            .subscribe(async (result: DefaultAjaxResponse) => {
                if (result.IsSuccess) {
                this.attrManagerService.addAttributeToProfile(this.attribute);
                this.router.navigate(['/user-settings/attributes/list']);
                } else {
                    this.attribute = {} as FormulaBasedAttribute;
                    this.updateErrorState(result.ErrorMsg);
                }
            }, (err) => console.error(err));
        } else if(this.config.calculationType == 'formula') {
            this.attribute = this.setupAttribute();
            this.attrManagerService.saveAttribute(this.attribute as FormulaBasedAttribute, "FormulaBased")
            .pipe(first(), filter(x => !!x))
            .subscribe(async (result: DefaultAjaxResponse) => {
                if (result.IsSuccess) {
                    this.attrManagerService.addAttributeToProfile(this.attribute);
                    this.router.navigate(['/user-settings/attributes/list']);
                } else {
                    this.attribute = {} as FormulaBasedAttribute;
                    this.updateErrorState(result.ErrorMsg);
                }
            }, (err) => console.error(err));
        }
    }

    public updateAttributeClick() {
        if(!this.attribute.IsPersonalization && this.isPersonalizationChanged) {
            this.modalService.openModalMessage(
                'md',
                {
                    title: "Personalization tag successfully removed",
                    message: "Templates that already include this attribute will not be affected, and will continue to work as normal",
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: 'OK',
                            action: this.updateAttribute.bind(this)
                        },
                    ]
                },
                <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
            );
        } else {
            this.updateAttribute();
        }
    }

    public updateAttribute()
    {
        const isPersonalization = this.attribute.IsPersonalization;
        if(this.config.calculationType === 'ifelse') {
            this.attribute = this.setupConditionalAttribute();
            this.restoreAttributeProperties(isPersonalization);
            this.attribute.OldName = this.config.initialName;
            this.sendUpdateRequests("Conditional");

        } else if(this.config.calculationType === 'formula') {
            this.attribute = this.setupAttribute();
            this.attribute.OldName = this.config.initialName;
            this.restoreAttributeProperties(isPersonalization);
            this.sendUpdateRequests("FormulaBased");
        }
    }

    private sendUpdateRequests(family: AttributeFamily) {
        forkJoin(
            this.isAttributeVisibilityChanged 
            ? {
                updateAttribute: this.attrManagerService.updateAttribute(this.attribute, family),
                changeAttributeVisibility: this.attributeListService.changeAttributeVisibility(this.attribute.FieldName, !this.initialAttribute.IsHidden)
            } 
            : {
                updateAttribute: this.attrManagerService.updateAttribute(this.attribute, family),
            }
            ).pipe(first()).subscribe(async (res) => {
                if (res.updateAttribute.IsSuccess) {
                    await this.attrManagerService.updateCustomerAttributes();
                    this.router.navigate(['/user-settings/attributes/list']);
                } else {
                    this.attribute = {
                        Name: this.attribute.Name
                    } as FormulaBasedAttribute;
                    this.updateErrorState(res.updateAttribute.ErrorMsg);
                }
            }, (err) => console.error(err));
    }

    public openSwitchTypeModal(type: string) {
        if(this.isEditorValuesChanged) {
            this.modalService.openModalMessage(
                'sm',
                {
                    message: this.translate.instant(this.config.translateKeys.confirmBackMessage),
                    buttons: [{
                        class: 'btn-default',
                        label: this.translate.instant(this.config.translateKeys.continueEditing),
                        action: () => {
                            this.config.calculationType = type === "ifelse" ? "formula" : "ifelse";
                            this.closeModal();
                        },
                    }, {
                        class: 'btn-primary',
                        label: this.translate.instant(this.config.translateKeys.discardChanges),
                        action: () => {
                            this.closeModal();
                            this.changeCalculationType(type);
                        },
                    }]
                },
                <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
            );
        } else {
            this.changeCalculationType(type);
        }
    }

    public openTestFormulaModal() {
        const calculator = this.editorComponentRef.instance as AttributeCalculatorComponent;
        calculator.buildAttribute();
        this.activeModalRef = this.modalService.open(
            TestFormulaModalComponent,
            "lg",
            <ModalOptions<any>>{
                initialState: {
                    attributes: calculator.config.attributes
                        .filter(x => calculator.metadata.params.includes(x.FieldName))
                        .sort((a,b) => calculator.metadata.params.indexOf(a.FieldName) >= 
                        calculator.metadata.params.indexOf(b.FieldName) ? 1 : -1),
                    formula: this.formulaService.getValidationFormula(calculator.tagify, 
                        calculator.config.attributes, 
                        calculator.getFormulaType),
                    title: calculator.config.attributeName,
                    details: calculator.metadata.details,
                    format: this.config.attributeFormat,
                    ignoreYear: calculator.ignoreYearFormControl.value
                },
                ignoreBackdropClick: false,
                keyboard: true,
                withScale: false
            });
    }

    private updateErrorState(error: string) {
        this.config.error.attributeName = error.includes("Attribute name was not unique");
        if (error.includes("Attribute formula is invalid")) {
            //The next line is needed to refactoring after the conditions editor becomes done
            (this.editorComponentRef.instance as AttributeCalculatorComponent).equationEditorFormControl.setErrors({'incorrect': true});
        }
        this.config.error.message = error;
    }

    private setupConditionalAttribute(): MultyExpressionsConditionalAttribute {
        this.updateErrorState("");
        let attribute = this.editorComponentRef.instance.buildAttribute();

        attribute.Format = this.config.attributeFormat;
        attribute.DisplayName = this.attributeNameFormControl.value.trim();
        attribute.Description = this.descriptionFormControl.value;
        attribute.FieldName = this.initialAttribute && this.initialAttribute.FieldName ? this.initialAttribute.FieldName : " ";
        attribute.PublishStatus = this.initialAttribute ? this.initialAttribute.PublishStatus : "NotPublished";
        attribute.Name = this.attributeNameFormControl.value.replace(specialCharactersRegex, '').replace("VF_CA_FB_", "IF_CA_CC_");
        attribute.Type = 'conditional';
        attribute.AttributeBaseType = "string";
        attribute.IsPersonalization = false;
        attribute.OldAliasName = this.initialAttribute && this.initialAttribute.PublishStatus === "Published" ? this.initialAttribute.DisplayName : null;
        
        if (this.initialAttribute && attribute.PublishStatus === "Published") {
            attribute.Name = this.initialAttribute.Name;
        }

        return attribute;
    }

    private setupAttribute(): FormulaBasedAttribute {
        this.updateErrorState("");
        //The next line and the same ones in this method are needed to refactor after the conditions editor becomes done
        this.editorComponentRef.instance.buildAttribute();
        let attribute: FormulaBasedAttribute = {
            DisplayName: this.attributeNameFormControl.value.trim(),
            Description: this.descriptionFormControl.value,
            Name: this.attribute.Name ? this.attribute.Name.replace("IF_CA_CC_", "VF_CA_FB_") : this.attributeNameFormControl.value.replace(specialCharactersRegex, ''),
            Family: this.config.family,
            SourceFieldName: (this.editorComponentRef.instance as AttributeCalculatorComponent).config.formulaType === FormulaType.DateBase ? "DAYS" : null,
            Format: this.config.attributeFormat,
            AttributeDetails: (this.editorComponentRef.instance as AttributeCalculatorComponent).metadata.details ?? [],
            Formula: (this.editorComponentRef.instance as AttributeCalculatorComponent).metadata.formula.trim(),
            FieldName: this.initialAttribute && this.initialAttribute.FieldName ? this.initialAttribute.FieldName : " ",
            PublishStatus: this.initialAttribute ? this.initialAttribute.PublishStatus : "NotPublished",
            AttributeBaseType: "number",
            Type: "customer",
            IsPersonalization: false,
            IgnoreYear: (this.editorComponentRef.instance as AttributeCalculatorComponent).ignoreYearFormControl.value
        }

        if (this.initialAttribute && attribute.PublishStatus === "Published") {
            attribute.Name = this.initialAttribute.Name;
        }

        return attribute;
    }

    private restoreAttributeProperties(isPersonalization: boolean) {
        this.attribute.IsPersonalization = isPersonalization;
        this.attribute.OldName = this.config.initialName;
    }

    showNotUniqueAttribute(attributeName: string) {
        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant(translateKeys.errorRealFieldName, { attributeName: attributeName }),
                buttons: [{
                    class: 'btn-primary',
                    label: 'OK',
                    action: this.closeModal.bind(this)
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    editPublishedAttribute() {
        if(!this.config.isAddMode 
            && this.attribute.PublishStatus === 'Published' 
            && this.isEditorValuesChanged)
        {
            this.modalService.openModalMessage(
                'sm',
                {
                    message: this.translate.instant(translateKeys.confirmEditMessage),
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: this.translate.instant(this.config.translateKeys.saveChanges),
                            action: () => {
                                this.closeModal();
                                this.updateAttributeClick();
                            },
                        },
                        {
                            class: 'btn-default',
                            label: this.translate.instant(this.config.translateKeys.cancel),
                            action: () => {
                                this.closeModal();
                                this.discardAttibuteChanges();
                            },
                        }
                ]
                },
                <ModalOptions<any>>{
                    ignoreBackdropClick: true,
                    keyboard: false
                }
            );
        }
        else{
            this.updateAttributeClick();
        }   
    }

    public formatConfig: SearchListConfig = {
        itemNameTranslateKey: this.translate.instant(this.config.translateKeys.brandLabel),
        keyProperty: "key",
        valueProperty: "text",
        placeholderTranslateKey: this.translate.instant(this.config.translateKeys.selectLabel),
        isMultiSelect: false
    };

    onFormatChanged(event: any) {
        if (this.config.attributeFormat != event[0].key){
            this.isEditorValuesChanged = true;
        }
        this.config.attributeFormat = event[0].key;
    }

    onCalculationTypeCange(event: any) {
        if (!this.config.isPublished && this.isEditorValuesChanged) {
            const continueAction = () => {
                this.attrCalculationTypeControl.setValue(event.target.value === "ifelse" ? "formula" : "ifelse");
            } 
            this.openConfirmModal({actionOnDiscard: this.changeCalculationType, discardParameters: event.target.value, 
                actionOnContinue: continueAction});
        }
        else if (!this.config.isPublished && !this.isEditorValuesChanged) {
            this.changeCalculationType(event.target.value);
        }
    }

    onBackClick() {
        ((this.attributeForm.dirty && this.isFormValuesChanged) || this.isEditorValuesChanged) 
            ? this.openConfirmModal({actionOnDiscard: this.discardAttibuteChanges})
            : this.discardAttibuteChanges();
    }

    private discardAttibuteChanges() {
        this.router.navigateByUrl("/user-settings/attributes/list");
        this.bsModalRef.hide();
    }

    private closeModal() {
        this.bsModalRef.hide();
    }

    private changeCalculationType(type: string) {
        this.errorMessage.emtpyFields = false;
        this.errorMessage.notAllowedValues = false;
        switch (type) {
            case "ifelse":
                this.config.formulaFormats = conditionFormatItems;
                this.attributeFormatFormControl.setValue([{ key: AttributeFormat.String, text: 'String' }]);
                this.config.attributeFormat = AttributeFormat.String;
                this.createConditionEditor();
                break;
            case "formula":
                this.config.formulaFormats = formulaFormatItems;
                this.attributeFormatFormControl.setValue([{ key: AttributeFormat.Number, text: 'Number' }]);
                this.config.attributeFormat = AttributeFormat.Number;
                this.createCalculator();
                break;
            default: break;
        }
        this.config.calculationType = type;
        this.attrCalculationTypeControl.setValue(type);
    }

    private openConfirmModal(actions: {actionOnDiscard: Function, discardParameters?: any, actionOnContinue?: Function, continueParameters?: any}){
        const actionOnContinue = (params?: any) => {
            if( actions.actionOnContinue !== undefined ) {
                params !== undefined ? actions.actionOnContinue(params) : actions.actionOnContinue();
            }
            this.closeModal();
        }

        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant(this.config.translateKeys.confirmBackMessage),
                buttons: [
                    {
                    class: 'btn-primary',
                    label: this.translate.instant(this.config.translateKeys.discardChanges),
                    action: actions.discardParameters !== undefined ? actions.actionOnDiscard.bind(this, actions.discardParameters) : actions.actionOnDiscard.bind(this),
                    },
                    {
                    class: 'btn-default',
                    label: this.translate.instant(this.config.translateKeys.continueEditing),
                    action: actions.continueParameters !== undefined ? actionOnContinue.bind(this, actions.continueParameters) : actionOnContinue.bind(this),
                    },
                ]
            },
            <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
        );
    }

    get attributeNameFormControl(): FormControl {
        return this.attributeForm.get('attributeName') as FormControl;
    }

    get descriptionFormControl(): FormControl {
        return this.attributeForm.get('description') as FormControl;
    }

    get attrCalculationTypeControl(): FormControl {
        return this.attributeForm.get('calculationType') as FormControl;
    }

    get attributeFormatFormControl(): FormControl {
        return this.attributeForm.get('attributeFormat') as FormControl;
    }

    get attrEditorFormControl(): FormControl {
        return this.attributeForm.get('attrEditor') as FormControl;
    }

    get f() { return this.attributeForm.controls; }
}

const translateKeys = {
    cancel: 'features.user_settings.body.attributes.addCalculatedAttribute.CANCEL',
    save: 'features.user_settings.body.attributes.addCalculatedAttribute.SAVE',
    select: 'features.user_settings.body.attributes.addCalculatedAttribute.SELECT',
    displayName: 'features.user_settings.body.attributes.addCalculatedAttribute.DISPLAY_NAME',
    description: 'features.user_settings.body.attributes.addCalculatedAttribute.DESCRIPTION',
    attributeCalculation: 'features.user_settings.body.attributes.addCalculatedAttribute.ATTRIBUTE_CALCULATION',
    function: 'features.user_settings.body.attributes.addCalculatedAttribute.FUNCTION',
    activity: 'features.user_settings.body.attributes.addCalculatedAttribute.ACTIVITY',
    timeframe: 'features.user_settings.body.attributes.addCalculatedAttribute.TIMEFRAME',
    title: 'features.user_settings.body.attributes.addCalculatedAttribute.TITLE',
    editTitle: 'features.user_settings.body.attributes.addCalculatedAttribute.EDIT_TITLE',
    errorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.NEW_ATTR_NAME',
    specialSymbolsErrorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.SPECIAL_SYMBOLS',
    errorRealFieldName: 'features.user_settings.body.attributes.invalidErrors.REAL_FIELD_NAME',
    calculationType: 'features.user_settings.body.attributes.addCalculatedAttribute.CALCULATION_TYPE',
    calculation: 'features.user_settings.body.attributes.addCalculatedAttribute.CALCULATION',
    formula: 'features.user_settings.body.attributes.addCalculatedAttribute.FORMULA',
    ifElse: 'features.user_settings.body.attributes.addCalculatedAttribute.IF_ELSE',
    confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
    continueEditing: 'general.CONTINUE_EDITING',
    discardChanges: 'general.DISCARD_CHANGES',
    format: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.FORMAT',
    brandLabel: 'features.user_settings.body.subscribers.BRAND_LABEL',
    selectLabel: 'features.user_settings.body.subscribers.SELECT_LABEL',
    testFormula: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.TEST_FORMULA',
    popoverMessage1: 'features.user_settings.body.attributes.addCalculatedAttribute.ERROR_POPOVER_MESSAGE_1',
    popoverMessage2: 'features.user_settings.body.attributes.addCalculatedAttribute.ERROR_POPOVER_MESSAGE_2',
    editConditionMsg: 'features.user_settings.body.attributes.addCalculatedAttribute.EDIT_CONDITION',
    confirmEditMessage: 'features.user_settings.body.attributes.addCalculatedAttribute.CONFIRMATION_POPUP',
    saveChanges: 'features.user_settings.body.attributes.addCalculatedAttribute.SAVE_CHANGES',
};
