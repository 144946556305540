<form [formGroup]="form" data-qa-id="apiKeyForm">
    <div class="form-row">
        <label class="col1-inner" data-qa-id="legalChannelsLabel">{{ translateKeys.channels | translate }}</label>
        <div class="col2-channels">
            <opti-search-list required data-qa-id="legalChannels"
                formControlName="LegalChannels"
                [items]="legalChannels"
                [config]="legalChannelsDropdownOslConfig"
                [isDefaultDisabled]="isWhenValuesHasOneOption">
            </opti-search-list>
        </div>
        <div class="col3"></div>
    </div>
    <div class="form-row">
        <label class="col1-inner" data-qa-id="legalAttributesLabel">{{ translateKeys.permittedAttributes | translate }}</label>
        <div class="col2-permitted-attributes">
            <opti-search-list required data-qa-id="legalAttributes"
                formControlName="LegalAttributes"
                [items]="legalAttributes"
                [config]="legalAttributesDropdownOslConfig"
                [isDefaultDisabled]="isWhenValuesHasOneOption">
            </opti-search-list>
        </div>
        <div class="col3"></div>
    </div>
</form>


