import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'preference-history-modal',
    templateUrl: './preferenceHistoryModal.component.html',
    styleUrls: ['./preferenceHistoryModal.component.scss']
})

export class PreferenceHistoryModalComponent implements OnInit {
    @Input() clientCustomerId: string;

    constructor() {
    }

    ngOnInit() {}
}
