import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ComponentsTransitionalModule } from "../../../components/components_transitional.module";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../../shared.module";
import { OptiModalModule } from "../../../modules/OptiModal.module";
import {PipesModule} from "../../../pipes/pipes.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { ColoredTagModule } from '@optimove/ui-sdk/components/colored-tag'
import { PreferenceCenterService } from './services/preferenceCenter.service';
import { TopicsListComponent } from './components/topicsList/topicsList.component';
import { AddUpdateTopicComponent } from './components/addUpdateTopic/addUpdateTopic.component';
import { SdkIdDialogComponent } from './components/sdkIdDialog/sdkIdDialog.component';

@NgModule({
  imports: [
    BrowserModule,
    ColoredTagModule,
    ComponentsTransitionalModule,
    CommonModule,
    SharedModule,
    OptiModalModule.forRoot(),
    PipesModule,
    TooltipModule
  ],
  declarations: [
    TopicsListComponent,
    AddUpdateTopicComponent,
    SdkIdDialogComponent
  ],
  providers: [PreferenceCenterService],
})
export class PreferenceCenterModule {}
