<div class="panel-heading">
    <h2 translate="features.user_settings.body.subscribers.TITLE"></h2>
</div>

<div class="panel-body">
    <div class="settings-subscribers-form-group-container">
        <form [formGroup]="rootForm" *ngIf='rootForm' class="settings-subscribers-form">
            <div class="row form-group">
                <div class="col-md-12 label-value-container">
                    <div class="col-md-2 label-column">
                        {{ 'features.user_settings.body.subscribers.BRAND_LABEL' | translate}}
                    </div>
                    <div class="col-md-10">
                        <div class="col-md-5 value-column" style="padding-left:0px">
                            <opti-search-list formControlName="brandId" [items]="brandList" [config]="brandConfig"></opti-search-list>
                        </div>
                        <div class="col-md-7 value-column export-button">
                            <button class="btn btn-link add-action-btn" [disabled]="!brandControl.value || brandControl.pristine" (click)="onExportButtonPressed()">
                                {{ 'features.user_settings.body.subscribers.EXPORT_LABEL' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12 label-value-container">
                    <div class="col-md-2 label-column">
                        {{ 'features.user_settings.body.subscribers.LIST_OF' | translate }}
                    </div>
                    <div class="col-md-10 value-column">
                        <div class="radio radio-primary radio-exclusion exclude-radio-container">
                            <input type="radio" formControlName="addOrRemove" name="addOrRemove" id="rdoExclude" [value]="true">
                            <label for="rdoExclude">{{ 'features.user_settings.body.subscribers.ADD_OPTION_LABEL' | translate}}</label>
                        </div>
                        <div class="radio radio-primary radio-exclusion">
                            <input type="radio" formControlName="addOrRemove" name="addOrRemove" id="rdoInclude" [value]="false">
                            <label for="rdoInclude">{{ 'features.user_settings.body.subscribers.REMOVE_OPTION_LABEL' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12 label-value-container">
                    <div class="col-md-2 label-column">
                        {{ 'features.user_settings.body.subscribers.LOAD_CSV_FILE' | translate }}
                    </div>
                    <div class="col-md-10">
                        <div class="load-csv-file-row" *ngIf="!this.isFinished">
                            <div class="col-md-5 value-column" style="padding-left:0px">
                                <div class="browse-button">
                                    <button class="btn btn-secondary" (click)="uploadCSV()">{{ 'features.user_settings.body.subscribers.BROWSE' | translate }}</button>
                                    <input type="file" id="file" accept=".csv" class="upload-file-input" formControlName="file"
                                           (change)="handleFileCsvInput($event)" #csvFileInput>
                                </div>
                                <div class="spinner-class">
                                    <opti-loading size="xs" [show]="isLoading"></opti-loading>
                                        <span class="spinner-text" *ngIf="isLoading">
                                        Processing...
                                        </span>
                                </div>
                            </div>
                            <div class="col-md-12 value-column" style="padding-left:0px">
                            <p class="email-caption">
                                {{ 'features.user_settings.body.subscribers.NOTE' | translate }}
                            </p>
                            </div>
                        </div>
                        <div class="finish-load-csv" *ngIf="this.isFinished">
                          <p class="file-name">
                              {{ this.state.file.name }}
                              <button type="button" class="trash-button" (click)="reset()">
                                  <span class="glyphicon glyphicon-trash"></span>
                              </button>
                          </p>
                         <p class="file-description">
                            <span>&#10003;</span>
                            {{ 'features.user_settings.body.subscribers.FINISH' | translate : {emailCount: this.state.emailCount} }}
                         </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-12 label-value-container">
                    <div class="col-md-2 label-column">
                        {{ 'features.user_settings.body.subscribers.EMAIL_REPORT_TO' | translate }}
                    </div>
                    <div class="col-md-10">
                        <div class="col-md-5 value-column" style="padding-left:0px">
                            <input formControlName="email"
                                   [placeholder]=this.email
                                   class="form-control"
                                   readonly>
                        </div>
                        <div class="col-md-12 value-column" style="padding-left:0px">
                            <p class="email-caption">
                                {{ 'features.user_settings.body.subscribers.EMAIL_REPORT_NOTE' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="row">
        <div class="col-md-6 pull-right text-right">
            <ul class="list-inline">
                <li *ngIf="!isInUpdateProcess">
                    <button class="btn btn-default" [disabled]="!rootForm.dirty" (click)="reset()">
                        {{ 'general.CANCEL' | translate }}
                    </button>
                </li>
                <li *ngIf="!isInUpdateProcess">
                    <button class="btn btn-primary" [disabled]="rootForm.invalid || !isFinished || this.state?.emailCount == 0" (click)="onUpdateButtonPressed()">{{ 'features.user_settings.body.subscribers.UPDATE_SUBSCRIPTION_BUTTON' | translate}}</button>
                </li>
                <li *ngIf="isInUpdateProcess">
                    <span class="click-loader loader-wrapper">
                        <span class="loader"></span>
                        <span translate>
                            {{ 'features.user_settings.body.loaders.SAVING' | translate}}
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
