import {Injectable} from "@angular/core";
import {SearchItem} from "../optiSearchListComponent/optiSearchList.component";
import {OslCellType} from "../models/osl-cell-type.enum";



@Injectable()
export class SearchListUtilsService {
    filterOnlySearchItems(items): SearchItem[]{
        return items.filter((item) => {
            return this.predicate(item)
        });
    }

    filterOnlySearchNodes(nodes: any): SearchItem[]{
        return nodes.filter((node) => {
            return this.predicate(node.data);
        });
    }

    forceInRange(value: number, min: number, max: number): number {
        if (min > max) {
            return value;
        }
        return Math.max(Math.min(value, max), min);
    }

    private predicate(item: SearchItem): boolean {
        const unsupportedNodeTypes = [OslCellType.AggregatedAction, OslCellType.FooterAction];
        return !item || typeof item.type === 'undefined' || unsupportedNodeTypes.indexOf(item.type) === -1;
    }
}