import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AjaxResponse } from "@optimove/ui-sdk/common/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DateService } from "../../services/date.service";
import { HttpService } from "../../services/optimove-http/optimove-http.model";
import { SsmService } from "../../services/ssm.service";
import {
    TemplateClickMetricsResponse,
    TemplateDetails, TemplateDetailsPreviewData
} from "./components/templateHTMLModalContainer/models/templateModalData.model";

@Injectable()
export class TemplatesService {

    private _systemTimeZones = [];
    private _ssm: any;
    private _isScheduledCampaign: any;

    constructor(    private readonly http: HttpService,
                    private dateService: DateService,
                    private ssmService: SsmService,
                    private translateService: TranslateService) {

        this._ssm = this.ssmService.getSSM();
        this._systemTimeZones = this._ssm.GetGeneric(this._ssm.Resx.SystemTimeZones);
    }

    public getTemplateDetails(templateIds, channelId): Observable<TemplateDetails[]> {
        let params = new HttpParams();
        templateIds.forEach((templateId:string) =>{
            params = params.append('templateIds', templateId);
        })

        const campaignSettings = this._ssm.GetGeneric(this._ssm.Resx.CampaignSettings);
        const previewFolder = campaignSettings.templatesFolder;

        return this.http.get("/ManageTemplates/GetOptimailTemplatesDetails", {params})
            .pipe(
                map((res) => {
                    // @ts-ignore
                    return res.data.map(t => {
                        return {
                            isExists: true,
                            name: t.templateName,
                            templateId: t.templateId,
                            lastModifiedDate: t.lastModified.date,
                            lastModifiedTime: t.lastModified.time,
                            lastModifiedBy: t.lastModified.username,
                            html: t.html,
                            htmlToShow: t.html,
                            plainTextLinkOffset: t.plainTextLinkOffset,
                            url: previewFolder + '/' + channelId + '/' + t.templateId + '.jpeg'
                        }
                    });
                })
            );
    }

    public getTemplateMetrics(startDate, endDate, planId, actionSerial, isScheduledCampaign) : Observable<TemplateClickMetricsResponse[]> {

        let params = HttpService.toHttpParams({ planId: planId, actionSerial: actionSerial, startDate: startDate, isScheduledCampaign:isScheduledCampaign,endDate: endDate })
        return this.http.get<AjaxResponse<TemplateClickMetricsResponse[]>>('/CampaignAnalysis/GetChannelClicksMetricsData', params).
        pipe(
            map((response) => {
                return response["Data"];
            }));
    }

    // Aggregation of all data for display click metrics: template details, metrics (for campaign time, clicks metrics)
    public getTemplateDetailsPreviewData(translatedClickMetricsData, templatesData, templateDetails, isScheduledCampaign) : TemplateDetailsPreviewData[]{
        var fullTemplateDetails: TemplateDetailsPreviewData[] = [];

        var self = this;
        Object.keys(translatedClickMetricsData).forEach(function(index) {
            const set = translatedClickMetricsData[index];
            let templateMetaData;
            if (isScheduledCampaign) {
                templateMetaData = templatesData.find(templateMetricsData => (Number(templateMetricsData.templateId) == set.TemplateId && templateMetricsData.multiId == set.MultiId));
            } else {
                // in triggered campaigns there is no MuliId in templateMetricsData that comes from MissionControl/GetChannelMetricsDetails in missionControl.service.cs->SP:[rt].[usp_GetMetricsPerDate]
                templateMetaData = templatesData.find(templateMetricsData => (Number(templateMetricsData.templateId) == set.TemplateId));
            }

            const templateDetailsData = templateDetails.find(templateDetail => templateDetail.templateId == set.TemplateId);
            if (typeof templateMetaData === 'undefined' || typeof templateDetailsData === 'undefined') return;

            const timeZoneMetaData = self.getTimeZone(templateMetaData.timeZone);
            const timeZoneMetaDataString = (templateMetaData.shouldEngageByCustomerLocalTime == "True") ? self.translateService.instant("features.scheduled_campaign_builder.STEPS.EXECUTION_PLAN.CHANNEL_FORM.LOCAL_TIME") :
                ((timeZoneMetaData !== undefined) ? self.formatTemplateTimeZoneName(timeZoneMetaData.DisplayName, timeZoneMetaData.utcOffset) : templateMetaData.timeZone);
            const time24HoursFormat = self.dateService.convertTimeTo24HoursClock(templateMetaData.campaignTime);
            const time12HoursFormat = self.dateService.convertTimeTo12HoursClock(time24HoursFormat);
            fullTemplateDetails.push({  ...templateDetailsData,
                                        channelId: 15,
                                        channelName: "Optimail",
                                        time: time12HoursFormat,
                                        timeZone: timeZoneMetaDataString,
                                        metrics: set});
        });
        return fullTemplateDetails;
    }

    private getTimeZone(id: string) {
        return this._systemTimeZones.find(timeZone => timeZone.Id === id);
    }

    private formatTemplateTimeZoneName(timeZoneName: string, utcOffset: number): string {
        const timeHours = this.dateService.formatUtcOffSetToHours(utcOffset);
        return `${timeZoneName} ${timeHours}`;
    }
}
