<accordion [isAnimated]="true" [closeOthers]="closeOthers" class="opti-generic-accordion">
  <accordion-group
        #accordionPanelsRef
        [attr.data-qa-id]="ab.dataQaId"
        class="opti-accordion-group"
        panelClass="opti-accordion"
        *ngFor="let ab of accordionBlocks"
        [isOpen]="ab.isAccordionGroupOpen"
        [isDisabled]="ab.disabled"
        [ngClass]="ab.className"
        [class.disabled]="ab.disabled"
        (isOpenChange)="ab.onSectionExpandToggle($event)">
    <button
      class="btn btn-link shadow-none accordion-heading-btn"
      [ngClass]="{'item-disabled': ab.disabled, 'end': chevronAlignment === 'end'}"
      [tabindex]="ab.isRouterLink ? -1 : 0"
      accordion-heading
      type="button">
      <div class="block-title">
        <ng-container [ngTemplateOutlet]="ab.headerTemplateRef"></ng-container>
      </div>
      <div class="chevron-icon" *ngIf="!ab.hideChevron" [ngClass]="{'right': chevronAlignment === 'start'}">
        <i class="material-icons chevron"
        [ngClass]="{'chevron-open': ab.isExpand, 'right': chevronAlignment === 'end'}">keyboard_arrow_right</i>
      </div>
    </button>
    <ng-container [ngTemplateOutlet]="ab.bodyTemplateRef"></ng-container>
  </accordion-group>
</accordion>