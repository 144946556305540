<div class="settings-attributes">
    <div class="panel-heading">
        <h2 translate="features.user_settings.body.realtimeProfileDataManage.TITLE"></h2>
    </div>
    <div class="panel-body" style="height: 100%; overflow: hidden;" *ngIf="!noAttributes">
        <p *ngIf="publishError" class="error-msg" translate>
            {{publishErrorString | translate}}
        </p>
        <ag-grid-angular *ngIf="gridOptions" [gridOptions]="gridOptions"
                         [modules]="modules" 
                         [ngClass]="{'with-error': publishError, 'without-error': !publishError}"
                         [ngStyle]="{'padding-bottom': publishError ? 65 + 'px' : 15 + 'px'}"
                         class="realtime-profile-data-manage ag-theme-balham ag-theme-white">
        </ag-grid-angular>
    </div>
    <div class="panel-body" style="height: 100%;" *ngIf="noAttributes">
        <div class="empty-state-wrapper">
            <div class="no-attributes">{{ translateKeys.noAttributes | translate }}</div>
            <div class="attribute-adding-desc-wrapper">
                <div class="attribute-adding-desc">{{ translateKeys.addAttributeDesc | translate }}</div>
            </div>
            <span class="">
                <a routerLink="/user-settings/realTimeProfileDataManager/addAttribute"
                   class="btn btn-font-size btn-link tab-item" routerLinkActive="active">
                    <i class="material-icons">add </i>
                    <span>{{ translateKeys.addFirstAttribute | translate }}</span>
                </a>
            </span>
        </div>
    </div>
    <div class="panel-footer panel-footer-bottom-stick footer-btns" *ngIf="!noAttributes">
        <div class="controls col-md-6 pull-right text-right">
            <ul class="list-inline">
                <li *ngIf="allPublished">
                    <i class="material-icons check-icon">done</i>
                </li>
                <li *ngIf="allPublished">
                    <p>
                        {{ translateKeys.allPublished | translate }}
                    </p>
                </li>
                <li>
                    <loading-button
                            [showLoader]="loading"
                            (onClick)="publishClick()"
                            [isDisabled]="!isClickable"
                            [buttonCssClass]="'btn btn-primary'"
                            [loadingCssClass]="'saving-loader'"
                            [loadingText]="'Publishing'"
                            [buttonText]="'Publish Attributes'">
                    </loading-button>
                </li>
            </ul>
        </div>
    </div>
    <opti-loading [show]="loading" [size]="'md'" ></opti-loading>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="inDeletion"></div>


