import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IChipItem} from "./models/chipItem.model";
import { ChipsMode } from "./models/chipsMode.model";

@Component({
    selector: 'chips',
    templateUrl: './chips.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./chips.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ChipsComponent),
        }
    ]
})
export class ChipsComponent implements ControlValueAccessor, OnChanges {
    @Input() items: IChipItem[];
    @Input() mode: ChipsMode = ChipsMode.PRIMARY;

    public itemsWithSelection: IChipItemSelection[];
    public ChipsMode = ChipsMode;
    public isDisabled: boolean = false;

    private onChangeEmitterFunc: any;
    private onTouchEmitterFunc: any;
    private radioSubList: IChipItemSelection[];
    private allRadiosInDisabledMode: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        this.itemsWithSelection = this.items.map((item): IChipItemSelection => {
            return { ...item, isSelected: false };
        });

        this.radioSubList = this.itemsWithSelection.filter(item => item.isRadioButton);
        const disabledRadios = this.radioSubList.filter(item => item.isDisabled);
        this.allRadiosInDisabledMode = disabledRadios.length === this.radioSubList.length;
    }

    public onSelectionChanged(item: IChipItemSelection) {
        item.isSelected = !item.isSelected;

        if (item.isRadioButton) {
            if (item.isSelected) {
                this.handleOneRadioSelection(item);
                this.disabledOtherRadios(item);
            } else {
                this.enableAllRadios();
            }
        }

        const selectedIds = this.getSelectedIds(this.itemsWithSelection);
        this.onChangeEmitterFunc && this.onChangeEmitterFunc(selectedIds);
        this.onTouchEmitterFunc && this.onTouchEmitterFunc();
    }

    public onKeyDown(event: KeyboardEvent, item: IChipItemSelection) {
        if (event.keyCode === 13) { // enter
            this.onSelectionChanged(item);
            event.stopPropagation();
            event.preventDefault();
        }
    }

    writeValue(selectedIds: any[]) {
        this.setSelectedItems(selectedIds);

        if (this.radioSubList.length > 0 && !this.allRadiosInDisabledMode) {
            const selectedRadio = this.radioSubList.find(item => item.isSelected);
            if (selectedRadio) {
                this.disabledOtherRadios(selectedRadio);
            } else {
                this.enableAllRadios();
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChangeEmitterFunc = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchEmitterFunc = fn;
    }

    private setSelectedItems(selectedIds: any[]) {
        this.itemsWithSelection.forEach(item => item.isSelected = false);

        selectedIds && selectedIds.forEach(selectedId => {
            const selectedItem = this.itemsWithSelection.find(item => item.id === selectedId);
            selectedItem.isSelected = true;
        });
    }

    private getSelectedIds(items: IChipItemSelection[]): any[] {
        if (!items) {
            return [];
        }

        return items
            .filter(item => item.isSelected)
            .map(selectedItem => selectedItem.id);
    }

    private handleOneRadioSelection(selectedRadioItem: IChipItemSelection) {
        this.radioSubList.forEach(item => {
           if (item !== selectedRadioItem) {
                item.isSelected = false;
           }
        });
    }

    private disabledOtherRadios(selectedRadioItem: IChipItemSelection) {
        this.radioSubList.forEach(item => {
            if (item !== selectedRadioItem) {
                item.isDisabled = true;
            }
        });
    }

    private enableAllRadios() {
        this.radioSubList.forEach(item => {
            item.isDisabled = false;
        });
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

interface IChipItemSelection extends IChipItem {
    isSelected: boolean
}