<div class="mp-deprecation-container vertical-center">
    <div class="mp-deprecation-col-md-6 mp-deprecation-col-md-offset-2 vertical-center">
        <div class="message-container">
            <h1 class="mp-deprecation-title">Goodbye Marketing Plan, Hello Mission Control!</h1>
            <p class='mp-deprecation-message'><span class="mp-deprecation-academy-link"
                    (click)="mpDeprecationAcademyClick()">Mission Control</span> empowers marketing teams to scale their personalization efforts and increase customer retention and lifetime value as a result.</p>

                <button class="btn btn-lg btn-grape btn-deprecation" (click)="redirectToMissionControl()">
                    <span>Take me to Mission Control</span>
                </button>
        </div>
    </div>
    <div class="mp-deprecation-col-md-4"> </div>
</div>