<div *ngIf="!isSearchMode" class="translation-panel-root">
  <div class="translation-panel">
    <div class="translations-header">
      <article class="template-translations">
        <h2 class="template-translation-sign">
          {{ "features.manage_templates.optimail.translationPanel.TEMPLATE_TRANSLATIONS" | translate }}
        </h2>
      </article>
      <div class="header-actions" *ngIf="template?.languages?.length">
        <div *ifFeatureFlag="'TemplatesSearchTranslations'" class="search-icon-container">
          <button class="button-search" (click)="isSearchMode = true">
            <i class="search-icon material-icons">search</i>
          </button>
        </div>
        <div *ifFeatureFlag="'TemplatesEnableFallbackLanguage'">
          <div class="more-menu">
            <overflow-menu [position]="menuPosition" [items]="overflowMenuItems">
              <ng-template #overflowMenuItemTemplate let-overflowMenuItem>
                <overflow-menu-item [hasSubMenu]="overflowMenuItem.children?.length > 0">
                  <i overflowMenuItemIcon class="material-icons">{{ overflowMenuItem.icon }}</i>
                  <span overflowMenuItemText>{{ overflowMenuItem.text }}</span>
                </overflow-menu-item>
              </ng-template>
            </overflow-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="sync-status-block">
      <div *ngIf="isSyncing">
        <div class="loader-container">
          <span class="click-loader loader-wrapper">
            <span class="loader preview-loader"></span>
          </span>
        </div>
        <p class="translations-description">{{ "features.manage_templates.optimail.translationPanel.SYNCING" | translate }}</p>
      </div>
      <div *ngIf="!isSyncing">
        <div *ngIf="isMultiLanguage">
          <div class="multi-language-container">
            <div class="top-description-container">
              <p class="translations-description top-description" *ngIf="isAllLanguagesNew">
                {{ "features.manage_templates.optimail.translationPanel.NOT_SYNCED" | translate }}
              </p>
              <p class="translations-description top-description" *ngIf="!isAllLanguagesNew">
                {{ "features.manage_templates.optimail.translationPanel.LAST_UPDATE" | translate }}
              </p>
            </div>
            <div class="bottom-description-container">
              <p class="translations-description" *ngIf="isAllLanguagesNew">
                {{ "features.manage_templates.optimail.translationPanel.SYNC_CROWDIN" | translate }}
              </p>
              <p class="translations-description" *ngIf="!isAllLanguagesNew && !isAnyLanguageTranslated">
                {{ "features.manage_templates.optimail.translationPanel.FROM_OPTIMAIL" | translate }} | {{ lastUpdated | date : "medium" }}
              </p>
              <p class="translations-description" *ngIf="!isAllLanguagesNew && isAnyLanguageTranslated">
                {{ "features.manage_templates.optimail.translationPanel.FROM_CROWDIN" | translate }} | {{ lastUpdated | date : "medium" }}
              </p>
            </div>
            <div class="sync-button-container">
              <button
                [disabled]="isSyncDisabled"
                class="btn btn-secondary sync-button"
                [ngClass]="isSyncDisabled ? 'disabled-button' : ''"
                (click)="syncTranslations()">
                <i class="material-icons">sync</i>
                <span *ngIf="!isAllLanguagesInProgressOrTranslated">
                  {{ "features.manage_templates.optimail.translationPanel.SYNC" | translate }}
                </span>
                <span *ngIf="isAllLanguagesInProgressOrTranslated">
                  {{ "features.manage_templates.optimail.translationPanel.FORCE_SYNC" | translate }}
                </span>
              </button>
            </div>
            <div class="below-sync-message-container" *ngIf="isTemplateDirty">
              <p class="below-sync-message">
                {{ "features.manage_templates.optimail.translationPanel.SAVE_BEFORE_SYNC" | translate }}
              </p>
            </div>
            <div class="below-sync-message-container" *ngIf="isChangeSyncHintVisible">
              <p class="below-sync-message orange-message">
                <i class="material-icons warning-icon">warning</i>
                {{ "features.manage_templates.optimail.translationPanel.CHANGE_PLEASE_SYNC" | translate }}
              </p>
            </div>
            <div class="below-sync-message-container" *ngIf="isNewSyncHintVisible">
              <p class="below-sync-message">
                {{ "features.manage_templates.optimail.translationPanel.NEW_PLEASE_SYNC" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="!isMultiLanguage && defaultLanguage" class="no-languages-container">
          <div>
            <p class="translations-description no-translations-text">
              {{ "features.manage_templates.optimail.translationPanel.NO_TRANSLATIONS" | translate }}
            </p>
          </div>
          <div class="please-select-container">
            <p class="please-select">{{ "features.manage_templates.optimail.translationPanel.SELECT_LANGUAGES_BEGIN" | translate }}</p>
          </div>
          <div class="sync-button-container">
            <button [disabled]="isTemplateDirty" class="btn btn-secondary sync-button" [ngClass]="isTemplateDirty ? 'disabled-button' : ''">
              <i class="material-icons">sync</i>
              {{ "features.manage_templates.optimail.translationPanel.SYNC_TO_CROWDIN" | translate }}
            </button>
          </div>
        </div>
        <div class="add-default-language-container" *ngIf="!isMultiLanguage && !defaultLanguage">
          <div class="no-languages-message">
            {{ "features.manage_templates.optimail.translationPanel.NO_LANGUAGES_YET" | translate }}
          </div>
          <div class="add-default-language-message">
            {{ "features.manage_templates.optimail.translationPanel.ADD_SOURCE_LANGUAGE_TO_BEGIN" | translate }}
          </div>
          <button class="btn btn-secondary add-default-language-btn" (click)="openDefaultLanguageModal()">
            {{ "features.manage_templates.optimail.translationPanel.ADD_SOURCE_LANGUAGE" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="languages-area"
      [ngClass]="isTemplateDirty || isChangeSyncHintVisible || isNewSyncHintVisible ? 'languages-area-small' : ''"
      *ngIf="defaultLanguage">
      <section class="counter-selector">
        <div class="counter-selection">
          <div>
            <p class="language-sign">
              {{ languageStatusList.length }} {{ "features.manage_templates.optimail.translationPanel.LANGUAGES" | translate }}
            </p>
          </div>
          <button (click)="openLanguageTranslationModal()" class="btn-link header-action">
            <i class="material-icons">translate</i>
            <span>{{ "features.manage_templates.optimail.translationPanel.SELECT_LANGUAGES" | translate }}</span>
          </button>
        </div>
      </section>
      <div class="divider-counter-list"></div>
      <div id="listTranslations" class="translation-list">
        <div *ngFor="let languageStatusNode of languageStatusList">
          <div class="node-translation">
            <div class="language-name">{{ languageStatusNode.name }}</div>
            <div class="source-sign" *ngIf="languageStatusNode.isDefault">
              {{ "features.manage_templates.optimail.translationPanel.SOURCE" | translate }}
            </div>
            <div class="other-sign" *ngIf="!languageStatusNode.isDefault && languageStatusNode.status === languageStatusTypes.New">
              <colored-tag [coloredTagType]="coloredTypeAccess.Type1">
                <div>{{ "features.manage_templates.optimail.translationPanel.NEW" | translate }}</div>
              </colored-tag>
            </div>
            <div class="other-sign" *ngIf="!languageStatusNode.isDefault && languageStatusNode.status === languageStatusTypes.InProgress">
              <colored-tag [coloredTagType]="coloredTypeAccess.Type11">
                <div class="in-progress-status">{{ "features.manage_templates.optimail.translationPanel.IN_PROGRESS" | translate }}</div>
              </colored-tag>
            </div>
            <div class="other-sign" *ngIf="!languageStatusNode.isDefault && languageStatusNode.status === languageStatusTypes.Translated">
              <colored-tag [coloredTagType]="coloredTypeAccess.Type4">
                <i class="material-icons done-icon">done</i>
                <div>{{ "features.manage_templates.optimail.translationPanel.TRANSLATED" | translate }}</div>
              </colored-tag>
            </div>
            <div
              class="other-sign"
              *ngIf="!languageStatusNode.isDefault && languageStatusNode.status === languageStatusTypes.ContentChanged">
              <colored-tag [coloredTagType]="coloredTypeAccess.Type9">
                <div>{{ "features.manage_templates.optimail.translationPanel.CONTENT_CHANGED" | translate }}</div>
              </colored-tag>
            </div>
          </div>
          <div class="divider-languages"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isSearchMode" class="translation-panel-root">
  <search-panel (backButtonClick)="isSearchMode = false"></search-panel>
</div>
