export const c360TranslateKeys = {
	HEADER_TITLE: "features.customer360.header.TITLE",
	HEADER_DESCRIPTION: "features.customer360.header.DESCRIPTION",
	MOCK_TITLE: "features.customer360.body.state_messages.no_search_string_supplied.TITLE",
	MOCK_MESSAGE: "features.customer360.body.state_messages.no_search_string_supplied.DESCRIPTION",
}

export const searchInputTranslateKeys = {
	SEARCH_TITLE: "features.customer360.body.customers_list.CUSTOMER_SEARCH",
	SEARCH_INPUT_PLACEHOLDER: "features.customer360.body.customers_list.CUSTOMER_SEARCH_INPUT_PLACEHOLDER",
	SEARCH: "features.customer360.header.SEARCH",
}

export const impactHeaderTranslateKeys = {
	TITLE: "features.customer360.body.customer_activity_trend.TITLE",
	DESCRIPTION: "features.customer360.body.customer_activity_trend.DESCRIPTION",
	NOTE: "features.customer360.body.customer_activity_trend.NOTE",
}

export const analysisRangeTranslateKeys = {
	ANALYSIS_RANGE: "general.ANALYSIS_RANGE",
}

export const dataSummaryTranslateKeys = {
	CONSENT_STATUS: "features.customer360.body.summary.CONSENT_STATUS",
	VIEW_CONSENT_HISTORY: "features.customer360.consent_modal.BUTTON",
	VIEW_PREF_HISTORY: "features.customer360.consent_modal.PREF_CENTER_BUTTON"
}

export const customerSnapshotsTranslateKeys = {
	TITLE: "features.customer360.body.explore_customer.TITLE",
	DESCRIPTION: "features.customer360.body.explore_customer.DESCRIPTION",
	SELECT_ATTRIBUTE: "features.customer360.body.explore_customer.SELECT_ATTR",
	EXPORT: "general.EXPORT_TO_CSV",
	SELECT_ATTRIBUTES: "components.optiSideBarModal.SELECT_ATTRIBUTES_TO_DISPLAY"
}

export const customerImpactCampaignsTableTranslateKeys = {
	ALL_CAMPAIGNS: "features.customer360.body.customer_activity_trend.trend.ALL_CAMPAIGNS",
	SCHEDULED_CAMPAIGNS: "features.customer360.body.customer_activity_trend.trend.SCHEDULED_CAMPAIGNS",
	TRIGGERED_CAMPAIGNS: "features.customer360.body.customer_activity_trend.trend.TRIGGERED_CAMPAIGNS",
	EXPORT_SCHEDULED_CAMPAIGNS: "general.EXPORT_SCHEDULED_CAMPAIGNS",
	EXPORT_TRIGGERED_CAMPAIGNS: "general.EXPORT_TRIGGERED_CAMPAIGNS",
	EXPORT_ALL_CAMPAIGNS: "general.EXPORT_ALL_CAMPAIGNS",
	START_DATE: "features.customer360.body.campaign_list.COLUMNS_TITLES.START_DATE",
	TARGET_GROUP: "features.customer360.body.campaign_list.COLUMNS_TITLES.TARGET_GROUP",
	TRIGGER: "features.customer360.body.campaign_list.COLUMNS_TITLES.TRIGGER",
	ACTION: "features.customer360.body.campaign_list.COLUMNS_TITLES.ACTION",
	CHANNELS: "features.customer360.body.campaign_list.COLUMNS_TITLES.CHANNELS",
	TEMPLATES: "features.customer360.body.campaign_list.COLUMNS_TITLES.TEMPLATES",
}

export const sideBarTranslateKeys = {
	DEFAULT_ATTRIBUTE: "components.optiSideBarModal.DEFAULT_ATTRIBUTES",
	ALL_ATTRIBUTES: "components.optiSideBarModal.ALL_ATTRIBUTES",
	TITLE: "components.optiSideBarModal.SELECT_ATTRIBUTES_TO_DISPLAY",
	SELECTED_ATTRIBUTES_LABEL: "components.optiSideBarModal.SELECTED_ATTRIBUTES_LABEL",
	CLEAR_ALL: "components.optiSideBarModal.CLEAR_ALL",
	SEARCH: "components.optiSideBarModal.SEARCH",
	REAL_TIME_TOOLTIP: "general.REAL_TIME_TOOLTIP"
}

export const purchaseHistoryTranslateKeys = {
	TITLE: "features.customer360.body.purchase_history_multi_attribute_flow.TITLE",
	DESCRIPTION: "features.customer360.body.purchase_history_multi_attribute_flow.DESCRIPTION",
	SELECT_ATTRIBUTE: "features.customer360.body.explore_customer.SELECT_ATTR",
	EXPORT: "general.EXPORT_TO_CSV",
	SELECT_ATTRIBUTES: "components.optiSideBarModal.SELECT_ATTRIBUTES_TO_DISPLAY",
    SAVE: "features.user_settings.body.attributes.addAttribute.SAVE",
	TRANSACTION_DATE: "features.customer360.body.purchase_history_multi_attribute_flow.TRANSACTION_DATE",
	TRANSACTION_ID: "features.customer360.body.purchase_history_multi_attribute_flow.TRANSACTION_ID",
	PURCHASE_DATE: "features.customer360.body.purchase_history.PURCHASE_DATE",
	EMPTY_TABLE_MESSAGE: "features.customer360.body.purchase_history_multi_attribute_flow.EMPTY_TABLE_MESSAGE",
	NO_ATTRIBUTE: "features.customer360.body.purchase_history_multi_attribute_flow.NO_ATTRIBUTE",
	MODAL_SEARCH_LIST_PLACEHOLDER: "general.SELECT",
	ADD_ATTRIBUTE: "features.customer360.body.purchase_history_multi_attribute_flow.ADD_ATTRIBUTE",
	DONE: "general.DONE",
	CANCEL: "general.CANCEL",
	TITLE_MODAL: "features.customer360.body.purchase_history_multi_attribute_flow.MODAL_TITLE"
}