import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OptiLogicModalService } from "../components/optiLogicModal/optiLogicModal.service";
import { HttpService } from "../services/optimove-http/optimove-http.model";
import { WindowRefService } from "../services/windowRef.service";

@Injectable({
    providedIn: 'root'
})
export class ApiErrorHandlingService {

    constructor(
        private readonly modalService: OptiLogicModalService,
        private readonly translate: TranslateService,
        private readonly windowService: WindowRefService,
        ) { }

    showErrorCodeMessageModal(errorCode: string, isSmallModal: boolean, defaultErrorMessage: string = null, isDefaultErrorMessageSmallModal: boolean = true) {
        let errorMessage = '';
        let isErrorCodeMessage = true;
        let errorCodeMessage: string = this.translate.instant(`errorCodeMessages.${errorCode}.errorMsg`);
        const generalErrorMessagePath = 'general.validation_error_messages.GENERIC_ERROR';


        if (errorCode == null || errorCodeMessage.includes('errorCodeMessages')) {
            isErrorCodeMessage = false;
            errorMessage = defaultErrorMessage ?? this.translate.instant(generalErrorMessagePath);
        }
        else {
            errorMessage = errorCodeMessage;
        }

        const modalSize = (isErrorCodeMessage ? isSmallModal : (defaultErrorMessage ? isDefaultErrorMessageSmallModal : true)) ? 'sm' : 'md';

        this.modalService.openModalMessage(modalSize, {
            message: errorMessage,
            buttons: [
                {
                    class: 'btn-primary',
                    label: this.translate.instant("general.OK"),
                    action: () => {
                    }
                }
            ]
        },
            { ignoreBackdropClick: true }
        );
    }

    showErrorMessageModal(message: string, isSmallModal: boolean, isDefaultErrorMessageSmallModal: boolean = true) {
        this.showErrorCodeMessageModal(null, isSmallModal, message, isDefaultErrorMessageSmallModal);
    }

    showGeneralErrorMessageModal() {
        this.showErrorCodeMessageModal(null, true);
    }

    navigateToErrorPage() {
        this.windowService.nativeWindow.location.href = `/ErrorPages/ErrorPage.html`;
    }
}