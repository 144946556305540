import { Component , ViewEncapsulation } from "@angular/core";
import { AngularRouterUIRouterAdapter } from "../../routing/angularRouterUIRouterAdapter.service";
import {Router, NavigationEnd, ActivatedRoute, RoutesRecognized, ResolveEnd, ActivationEnd} from "@angular/router";

@Component({
    selector: 'angularjs-container',
    templateUrl: './angularJsContainer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class AngularJsContainer {
    routeSubscription: any;

    constructor(private routingAdapterService: AngularRouterUIRouterAdapter, 
                private activatedRoute: ActivatedRoute, 
                private router: Router) {
    }
    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    ngOnInit() {
        this.routingAdapterService.triggerAngularJsRoute();
        let toSnapshot = null;
        this.routeSubscription = this.router.events.subscribe((event: any) =>  {
            // ok read this carefully, it's a tricky problem here:
            // when a user navigates to routes that are siblings and have the same component angular-router automatically
            // performs an optimization that doesn't actually perform the route. Couldn't find a way to prevent this behavior
            // In order to force the routing in angularjs we manually trigger it if we detect the route is done to an angularjs page.
            // Notice that the page data property is created ahead for this use-case. When navigating to page that does recreate the
            // angularjs container we unsubscribe from the events so we won't trigger two angularjs rendering events.
            if (event instanceof RoutesRecognized) {
                toSnapshot = null;
                return;
            }
            if (event instanceof ActivationEnd) {
                toSnapshot = this.routingAdapterService.getRouteChildSnapshot(event.snapshot.root);
            }
            if (event instanceof NavigationEnd && toSnapshot) {
                let isLegacy = toSnapshot.data && toSnapshot.data['isLegacy'];
                if (isLegacy){
                    this.routingAdapterService.triggerAngularJsRoute();
                }
            }
        });
    }

    getNormalizedPageName(page: string): string {
        if (!page) {
            return page;
        }
        return page.split("/")[0];
    }
}