import { Injectable } from '@angular/core';

export interface TenantInformation {
	userName: string;
	userRole: string;
	tenantId: string;
	userId: string;
	name: string;
}

@Injectable({
	providedIn: 'root'
})
export class TenantInformationService {
	constructor() {}

	getTenantInformation(): TenantInformation {
		return JSON.parse(sessionStorage.getItem('tenantDetails')) as unknown as TenantInformation;
	}
}
