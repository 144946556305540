import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { WindowRefService } from "../../services/windowRef.service";
import { GenericLogicModalComponent } from "./modals/genericLogicModal/genericLogicModal";
import { GenericModalComponent } from "./modals/genericModal/genericModal.component";
import { IframeModalComponent } from "./modals/iframeModal/iframeModal.component";
import { SuccessFailModalParams } from "./modals/successFailModal/models/SuccessFailModalParams.model";
import { SuccessFailModalResult } from "./modals/successFailModal/models/SuccessFailModalResult.model";
import { SuccessFailModalComponent } from './modals/successFailModal/successFailModal.component';

export interface OptiLogicModalOptions<T> extends ModalOptions<T> {
    notNeedToCloseOnRouteChange?: boolean
}

/**
 * This service designed to initialize optiLogicModal by calling to open method
 *
 */
@Injectable({
    providedIn: 'root'
})
export class OptiLogicModalService {
    private closeOnRouteChangeSubscription: Subscription;
    private activeIframeModalRef: BsModalRef;
    private activeClassModal: string;
    private activeModalRef: BsModalRef;
    constructor(private modalService: BsModalService, private windowRefService: WindowRefService, private route: Router) {
        var context: OptiLogicModalService = this;
        windowRefService.nativeWindow.closeModal = () => {
            context.activeIframeModalRef.hide();
            var onComplete$ = context.activeIframeModalRef.content.onComplete$;
            onComplete$.next();
        };
    }

    /**
     * open optiLogicModal
     * @param component - modal component
     * @param {string} size - modal size : 'sm' | 'md' | 'lg' | 'xl'
     * @param {OptiLogicModalOptions<any>} modalOptions for full details please see OptiLogicModalOptions<any>
     */

    open(component: any, size: string, modalOptions?: OptiLogicModalOptions<any>) {
        if (modalOptions.notNeedToCloseOnRouteChange) {
            this.unsubscribeToCloseModalOnRouteChange();
        }
        else {
            this.subscribeToCloseModalOnRouteChange();
        }
        modalOptions = modalOptions ? modalOptions : {};
        modalOptions.class = modalOptions.class ? `${modalOptions.class} ` : '';
        this.activeClassModal = modalOptions.class;
        modalOptions.class += this.setModalSize(size);
        modalOptions.animated = false;
        this.setParentModalBackdropClass(modalOptions);
        this.activeModalRef = this.modalService.show(component, modalOptions);
        return this.activeModalRef;
    }

    hide() {
        this.modalService.hide()
    }

    //This function open the component <opti-logic-modal> with the modal options that provided (in different from the openModalMessage function that open the <opti-modal>)
    // You can use the openModalMessage to open <opti-modal>
    openOptiLogicModal(size: string, modalConf: IModalConf, modalOptions?: OptiLogicModalOptions<any>) {
        modalOptions = Object.assign({}, modalOptions, { initialState: { modalConf } });
        return this.open(GenericLogicModalComponent, size, modalOptions);
    }

    //This function open the component <opti-modal> with the modal options that provided
    openModalMessage(size: string, modalConf: IModalConf, modalOptions?: OptiLogicModalOptions<any>) {
        modalOptions = Object.assign({}, modalOptions, { initialState: { modalConf } });
        return this.open(GenericModalComponent, size, modalOptions);
    }

    openSuccessFailModal(size: string, params: SuccessFailModalParams, modalOptions?: OptiLogicModalOptions<any>): SuccessFailModalResult {
        modalOptions = { ...modalOptions, initialState: { ...params } }
        const modelRef = this.open(SuccessFailModalComponent, size, modalOptions);
        return {
            primaryButtonClicked$: modelRef.content.primaryButtonClicked$,
            secondaryButtonClicked$: modelRef.content.secondaryButtonClicked$,
            thirdButtonClicked$: modelRef.content.thirdButtonClicked$
        };
    }

    setModalClass(size: string) {
        let newClass = this.activeClassModal;
        newClass += this.setModalSize(size);
        this.activeModalRef.setClass(newClass);
    }

    private setModalSize(size: string): string {
        return `opti-modal modal-${size} transition: width .5s`;
    }

    openIframeModal(size: string, modalParameters: IIframeModalConf, modalOptions?: OptiLogicModalOptions<any>) {
        modalOptions = {
            initialState: { modalConf: modalParameters },
            animated: false,
            keyboard: false,
            ...modalOptions
        }
        const iframeModalRef = this.open(IframeModalComponent, size, modalOptions);
        this.activeIframeModalRef = iframeModalRef;
        const onClose$: Observable<any> = iframeModalRef.content.onClose$;
        const unsubscribe$ = new Subject<void>();
        onClose$.pipe(takeUntil(unsubscribe$)).subscribe(() => {
            if (modalParameters.confirmClose) {
                const modalConf: IModalConf = {
                    title: modalParameters.confirmCloseTitle,
                    message: modalParameters.confirmCloseMessage,
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: 'Continue',
                            action: () => { }
                        },
                        {
                            class: 'btn-default',
                            label: 'Close',
                            action: () => {
                                confirmationModalRef.hide();
                                iframeModalRef.hide();
                                unsubscribe$.next();
                            }
                        }
                    ]
                };
                const confirmationModalRef = this.openModalMessage('md', modalConf);
            }
            else {
                iframeModalRef.hide();
                unsubscribe$.next();
            }
        });

        return iframeModalRef;
    }

    private setParentModalBackdropClass(modalOptions: OptiLogicModalOptions<any>) {
        if (modalOptions && modalOptions.backdrop === false) {
            return;
        }

        const cssClassValue = modalOptions.class ? modalOptions.class : '';
        modalOptions.class = `${cssClassValue} parent-modal-backdrop`;
    }

    private subscribeToCloseModalOnRouteChange() {
        if (!this.closeOnRouteChangeSubscription || this.closeOnRouteChangeSubscription.closed) {
            this.closeOnRouteChangeSubscription = this.route.events.subscribe(routeEvent => {
                if (routeEvent instanceof NavigationStart) {
                    this.activeModalRef?.hide();
                    this.activeIframeModalRef?.hide();
                }
            });
        }
    }

    private unsubscribeToCloseModalOnRouteChange() {
        this.closeOnRouteChangeSubscription?.unsubscribe();
    }
}

export interface IIframeModalConf {
    iframeSrc: string;
    confirmClose: boolean,
    confirmCloseTitle: string,
    confirmCloseMessage: string,
}

export interface IModalConf {
    /**
     * Set icon before title
     */
    materialIconBeforeTitle?: string;

    /**
     * Set modal title
     */
    title?: string,

    /**
     * Set modal title
     */
    subtitle?: string,

    /**
     * Set modal description beneath modal title
     */
    description?: string,

    /**
     * Set modal message
     */
    message?: string,

    /**
     * Set modal Icon
     */
    iconMaterial?: IIconMaterial

    /**
     * Declaration of modal buttons at modal footer, see IModalButtonConf interface
     */
    buttons?: IModalButtonConf[],

    /**
     * Declaration of close button (X) at modal header, see IModalButtonConf interface.
     *
     * You should to close your modal by yourself by using hide method in BsModalRef
     *
     * for more details see logicModalExampleComponent
     */
    xButton?: IModalButtonConf,

    /**
     * Declaration of link button at modal footer, see IModalButtonConf interface.
     *
     * You should to close your modal by yourself by using hide method in BsModalRef
     *
     * for more details see LogicModalExampleComponent
     */
    linkConf?: IModalButtonConf,

    /**
     * Boolean indicator to hide header border
     */
    hideHeaderBorder?: boolean,

    /**
     * Boolean indicator to hide footer border
     */
    hideFooterBorder?: boolean

    /**
     * Boolean indicator to hide header panel
     */
    hideHeaderPanel?: boolean,

    /**
     * Boolean indicator to hide footer panel
     */
    hideFooterPanel?: boolean

    /**
     * Set modal message with html tags
     */
    html?: string;

    /**
     * Boolean indicator to remove body layout padding
     */
    noBodyPadding?: boolean;
    /**
     * Disable modal scroll
     */
    disableScroll?: boolean;
    /**
     * turn on scale even when disableScroll = true
     */
    withScale?: boolean;
    /**
     * turn off min-height if scroll is required
     */
    withoutMinHeight?: boolean;
}

export interface IModalButtonConf {
    /**
     * Button label
     */
    label?: string,
    /**
     * Button action function which called when key pressed, please note that you
     *
     * have to bind 'this' in order to use it
     */
    action: IModalCallback,
    /**
     * Function which determine if the current button enabled
     */
    isDisabled?: Function,
    /**
     * Function which determine if the current button is loading
     */
    isLoading?: Function,
    /**
     * CSS class for current button
     */
    class?: string,
    /**
     * button label while loading
     */
    loadingText?: string,
    /**
     * CSS data-qa-id for current button
     */
    dataQaId?: string,
    /**
     * material icon name
     */
    materialIcon?: string


}



export interface IModalCallback {
    (modalRef?: BsModalRef): void
}

export interface IIconMaterial {
    type: string,
    name: string
}