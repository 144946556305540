<opti-logic-modal [modalConf]="modalConfig">
    <div class="success-fail-modal">

        <ng-container *ngIf="!iconOptions">
            <ng-container *ngIf="isSuccess">
                <i class="top-icon success-icon material-icons">done</i>
            </ng-container>
            <ng-container *ngIf="!isSuccess">
                <i class="top-icon fail-icon material-icons">close</i>
            </ng-container>
        </ng-container>

        <i 
            *ngIf="iconOptions" 
            class="top-icon material-icons custom-icon" 
            [ngStyle]="{
                backgroundColor: iconOptions.colorCode,
                fontSize: iconOptions.fontSize ?? '24px',
                fontWeight: iconOptions.fontWeight ?? 'bold'
            }">{{ iconOptions.iconName }}</i>

        <h2 class="success-fail-title">
            {{ title }}
        </h2>
        <p class="success-fail-message" [innerHTML]="message"></p>

        <button type="button" class="btn btn-primary success-fail-primary-btn" (click)="onPrimaryButtonClick()">
            {{ primaryButtonText }}
        </button>
        <button *ngIf="secondaryButtonText" type="button" class="btn btn-link success-fail-secondary-btn" (click)="onSecondaryButtonClick()">
            {{ secondaryButtonText }}
        </button>
        <button *ngIf="thirdButtonText" type="button" class="btn btn-link success-fail-secondary-btn thirdButton" (click)="onThirdButtonClick()">
            {{ thirdButtonText }}
        </button>
    </div>
</opti-logic-modal>