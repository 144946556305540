import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'manage-templates-page',
	templateUrl: './manageTemplatesPage.component.html',
	styleUrls: ['./manageTemplatesPage.component.scss']
})
export class ManageTemplatesPageComponent {
	constructor() {}
}
