<div class="update-api-user-modal-container">
    <opti-loading [show]="loading" [size]="'sm'"></opti-loading>
    <opti-logic-modal [modalConf]="modalConf">
        <div class="row msg-info">
            <div class="col-md-12">
                <div *ngIf="recommendToBlock; else notRecommendToBlock">
                    <p data-qa-id="blockUserMsg">
                        <i class="material-icons">warning</i>
                        <span>{{ translateKeys.blockUserMsg | translate }}</span><br>
                        <span class="block-msg">{{ translateKeys.deactivateBlockUserMsg | translate }}</span>
                    </p>
                </div>
                <ng-template #notRecommendToBlock>
                    <p data-qa-id="deleteUserMsg">
                        {{ translateKeys.deleteUserMsg | translate }}
                    </p>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <h4>{{ translateKeys.apiUserHeader | translate }}</h4>
                <div data-qa-id="apiUserHeader" class="input-box-info">
                    <h5 class="margins-inside-box">{{apiUser.NickName}}</h5>
                </div>
            </div>
            <div class="col-md-6 p-0">
                <h4>{{ translateKeys.lastLoginHeader | translate }}</h4>
                <div data-qa-id="lastLoginHeader" class="input-box-info">
                    <h5 class="margins-inside-box">{{lastLogin}}</h5>
                </div>
            </div>
        </div>
    </opti-logic-modal>
</div>
