<opti-logic-modal [modalConf]="modalConf">
    <div class="attributes">
        <div class="attribute-row" *ngFor="let attribute of defaultAttributesToExport">
            <div> {{attribute.Alias}} </div>
            <button
                data-qa-id="delete-attribute-btn"
                class="btn btn-link btn-link-default delete-button" 
                [disabled]="true">
                <i class="material-icons">delete_forever</i>
            </button>
        </div>
        <div class="attribute-row" *ngFor="let attribute of attributesToExport">
            <div> {{attribute.Alias}} </div>
            <button
                data-qa-id="delete-attribute-btn"
                class="btn btn-link btn-link-default delete-button" 
                (click)="removeAttributeFromExport(attribute)">
                <i class="material-icons" >delete_forever</i>
            </button>
        </div>
    </div>
    <div *ngIf="!isAddAttributeMode" class="add-attribute-btn">
        <button style="width: max-content;" 
            class="btn-link" 
            [disabled]="isAddAttributeBtnDisable" 
            (click)="isAddAttributeMode = true">
            <i class="material-icons" >playlist_add</i>
            {{ translateKeys.ADD_ATTRIBUTE_BTN | translate }} 
        </button>
    </div>
    <div class="add-attribute-panel" *ngIf="isAddAttributeMode">
        <opti-search-list
            [items]="attributesSearchList"
            [config]="attributeSearchListConfig"
            (selectedItemsChange)="setSelectedAttribute($event)">
        </opti-search-list>   
        <button 
            class="btn add-btn"
            (click)="addAttributesToExport(selectedAttributes)"
            [disabled]="selectedAttributes.length === 0">
            {{ translateKeys.ADD_BTN | translate }} 
        </button> 
    </div>
    
    <button 
        class="btn-link save-export-btn" 
        (click)="saveAndExport()"
        [disabled]="isSaveDisabled()">
        {{ translateKeys.SAVE_EXPORT_BTN | translate }} 
    </button> 
</opti-logic-modal>

