import { Injectable } from "@angular/core";
import { HttpService } from "./optimove-http/optimove-http.model";
import { OptimoveHttpService } from "./optimove-http/optimove-http.service";
import { WindowRefService } from "./windowRef.service";

@Injectable()
export class SignalrService48 {
  private isInitialized = false;
  private connections: { [key: string]: any } = {};
  private connection;

  constructor(private windowService: WindowRefService) {}

  loadHubs(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src =`${OptimoveHttpService.baseApiUrlString}/signalr/hubs`;
      script.addEventListener("load", function () {
        resolve(true);
      });
      script.addEventListener("onerror", function () {
        reject();
      });
      document.head.appendChild(script);
    });
  }

  async init(): Promise<void> {
    if (this.isInitialized) {
      console.warn(
        "Attempt to initialize an already initialized signalR service"
      );
      return;
    }
    await this.loadHubs();
    this.isInitialized = true;
    this.connection = this.windowService.nativeWindow.$.connection;
    this.connection.hub.url = `${OptimoveHttpService.baseApiUrlString}/signalr`;
    this.connection.hub.qs = { "OptiJWT": `${localStorage.getItem('accessToken')}` };
    this.setupHubs();

    this.connection.hub.start().done(() => {
      console.log("SignalR 4.8 started");
    });

    this.connection.hub.reconnecting(() => {
      console.log("SignalR 4.8 reconnecting");
    });

    this.connection.hub.reconnected(() => {
      console.log("SignalR 4.8 reconnected");
    });

    this.connection.hub.disconnected(() => {
      console.log("SignalR 4.8 disconnected");
      setTimeout(() => {
        this.connection.hub.start();
      }, 5000);
    });
    this.setupExpiry();
  }

  private setupHubs() {
    this.connection.sessionExpiry.client.register = () => {};
    this.connections["sessionExpiry"] = this.connection.sessionExpiry;

    this.connection.optiBot.client.register = () => {};
    this.connections["optiBot"] = this.connection.optiBot;

    this.connection.uniquePromo.client.register = () => {};
    this.connections["uniquePromo"] = this.connection.uniquePromo;
    
    this.connection.action.client.register = () => {};
    this.connections["action"] = this.connection.action;
  }

  getConnection(hubName: string) {
    return this.connections[hubName];
  }

  private setupExpiry() {
    this.getConnection("sessionExpiry").client.loggedOut = () => {
      this.windowService.nativeWindow.location.href =
        HttpService.baseApiUrlString + "/auth/login";
    };
    this.getConnection("sessionExpiry").client.expired = () => {
      sessionStorage.setItem("loggedOutOfPage", location.hash);
      this.windowService.nativeWindow.location.href = "/ErrorPages/SessionExpired.html";
    };
  }
}
