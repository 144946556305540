import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { CreateUpdateFolderResponse } from '../../models/createUpdateFolder';
import { ManageTemplatesService } from '../../services/manageTemplates.service';

@Component({
	selector: 'folder-rename',
	templateUrl: './folderRename.component.html',
	styleUrls: ['./folderRename.component.scss']
})
export class FolderRenameComponent implements OnInit, AfterViewInit {
	@ViewChild('folderNameInput') folderNameElement: ElementRef;

	@Input() title: string;
	@Input() folderId: number;
	@Input() subMethodId: number;
	@Input() currentName: string;
	callback: (updateResponse: CreateUpdateFolderResponse) => void;

	newName: string;

	constructor(
		private bsModalRef: BsModalRef,
		private modalService: OptiLogicModalService,
		private manageTemplatesService: ManageTemplatesService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.newName = this.currentName;
	}

	ngAfterViewInit(): void {
		// focus doesn't work without a timeout because element is not in DOM yet
		setTimeout(() => {
			this.folderNameElement.nativeElement.focus();
		}, 1);
	}

	onSave(): void {
		if (!this.newName.trim()) {
			return;
		}
		if (this.currentName === this.newName) {
			this.bsModalRef.hide();
			return;
		}

		this.manageTemplatesService
			.isFolderNameAlreadyUsed({
				folderId: this.folderId,
				proposedName: this.newName,
				subMethodId: this.subMethodId
			})
			.subscribe((isNameInUse) => {
				if (isNameInUse) {
					this.modalService.openModalMessage('sm', {
						html: this.translate.instant('features.manage_templates.messages.FOLDER_NAME_EXIST_MESSAGE', {
							folder: this.newName
						}),
						buttons: [
							{
								class: 'btn-primary',
								label: this.translate.instant('general.OK'),
								action: () => {}
							}
						]
					});
				} else {
					var fixedName = this.fixNewNameIfNeeded(this.currentName, this.newName);
					this.manageTemplatesService
						.saveAndUpdateFolder({
							folderNode: {
								id: this.folderId < 0 ? 0 : this.folderId,
								text: fixedName
							},
							subMethodId: this.subMethodId
						})
						.subscribe((response) => {
							this.callback(response);
						});
				}
			});

		this.bsModalRef.hide();
	}

	onCancel(): void {
		this.bsModalRef.hide();
	}

	private fixNewNameIfNeeded(oldFolderName, newFolderName) {
		if (oldFolderName.endsWith('_{X}') && !newFolderName.endsWith('_{X}')) {
			var fixedName = newFolderName;
			if (fixedName.endsWith('_{X')) fixedName += '}';
			else if (fixedName.endsWith('_{')) fixedName += 'X}';
			else if (fixedName.endsWith('_')) fixedName += '{X}';
			else fixedName += '_{X}';
			return fixedName;
		}
		return newFolderName;
	}
}
