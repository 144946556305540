import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'sdk-id-dialog',
  templateUrl: './sdkIdDialog.component.html',
  styleUrls: ['./sdkIdDialog.component.scss']
})
export class SdkIdDialogComponent implements OnInit {
  sdkConfig: string = '';
  brandGroupId: string = '';
  justCopied: boolean;
  justCopiedBrandGroup: boolean;

  constructor(private bsModalRef: BsModalRef, private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  close(): void {
    this.bsModalRef.hide();
  }

  copy(): void {
    this.clipboard.copy(this.sdkConfig);
    this.justCopied = true;
		setTimeout(() => {
			this.justCopied = false;
		}, 2000);
  }

  copyBrandGroupId(): void {
    this.clipboard.copy(this.brandGroupId);
    this.justCopiedBrandGroup = true;
    setTimeout(() => {
      this.justCopiedBrandGroup = false;
    }, 2000);
  }
}
