import {Component, ViewEncapsulation} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {SettingsSharedService} from "../settingsShared.service";

@Component({
    selector: 'editDeleteCellRenderer',
    templateUrl: 'editDeleteCellRenderer.component.html',
    styleUrls: ['./editDeleteCellRenderer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditDeleteCellRendererComponent implements ICellRendererAngularComp {

    private rowId: string;
    public rowName: string;

    public hideDelete: boolean;

    constructor(private settingsSharedService: SettingsSharedService) {
    }

    agInit(params: any): void {
        this.rowId = params.node.id;
        this.rowName = params.data.webhookName ? params.data.webhookName : params.data.promotionName;
        this.hideDelete = params.hideDelete == undefined ? false : params.hideDelete;
    }

    refresh(params: any): boolean {
        return false;
    }

    delete(){
        this.settingsSharedService.deleteSettingClick(this.rowId);
    }

    edit(){
        this.settingsSharedService.editSettingClick(this.rowId);
    }
}