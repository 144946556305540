import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { FeatureFlag, FeatureFlagService } from '../../services/featureFlag.service';

@Injectable()
export class MarketingPlanDeprecationGuard implements CanActivate {

    constructor(private featureFlagService: FeatureFlagService, private router: Router, private ngZone: NgZone) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.router.navigate(['marketing-plan-deprecation']);
        return of(false);
    }

}
