import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComplexExpressionsElement, ConditionalEditorArrayConfig, MultipleConditionEditorConfig, MultipleConditionValue, MultyExpressionsConditionalAttribute } from '../../attributesList.model';
import { IAttributeEditor } from '../../interfaces/editor.interface';
import { AttributesManagerService } from '../../services/attributesManager.service';
import { ConditionalAttributeService } from '../../services/condition.service';
import { ConditionalValidatorService } from '../../services/conditionalValidator.service';
import { MultipleConditionEditorComponent } from './multipleConditionEditor/multipleConditionEditor.component';
import { ConditionalAttributesMaxLength as maxLength } from '../../attributesList.constants';

@Component({
  selector: 'conditional-editor-array',
  templateUrl: './conditionalEditorArray.component.html',
  styleUrls: ['./conditionalEditorArray.component.scss']
})
export class ConditionalEditorArrayComponent implements OnInit, AfterViewInit, IAttributeEditor  {
  @ViewChildren(MultipleConditionEditorComponent) allEditors: QueryList<MultipleConditionEditorComponent>;

  @Input() config: ConditionalEditorArrayConfig;
  @Output() changeEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() errorMessageEmitter: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public translateKeys = translateKeys;
  public maxEditorsCount = 12;
  public editorsConfigs: MultipleConditionEditorConfig[] = [];
  public editorsValues: {value: string, alreadyExist: boolean}[] = [];

  constructor(private attrManagerService: AttributesManagerService,
    private validationService: ConditionalValidatorService,
    private conditionService: ConditionalAttributeService) { }

  ngOnInit(): void {
    this.createForm();
    this.elseValue.setValue(this.config.attributeBody?.ElseValue);
    if(this.config.isDisabled) this.elseValue.disable();
  }

  ngAfterViewInit() {
    this.elseValue.addValidators(this.validationService.notAllowedValues(() => this.editors?.value.map((x) => x.Value)));
    this.form.valueChanges.subscribe(() => {
      this.emitChanges();
      this.errorMessageEmitter.emit(this.buildErrorMessage());
    });
    this.errorMessageEmitter.emit(this.buildErrorMessage());
  }

  public duplicateEditor(editorIndex: number) {
    let newEditorConfig: MultipleConditionEditorConfig = this.emptyConfig(editorIndex + 2);
    this.allEditors.forEach((x,i) => {
      if(i === editorIndex && x.isComplexSelection && x.complexSelection) {
        newEditorConfig.showComplexExpression = true;
      }
    });
    let newArray = this.conditionService
    .pushAfter(this.editors.controls, 
               editorIndex, 
               new FormControl(this.editors.value[editorIndex], [Validators.required, 
                                                                 this.validationService.validMultipleConditionValue(),
                                                                 this.validationService.validEmptyMultipleConditionValue()])
              );

    this.editorsConfigs = this.conditionService.pushAfter(this.editorsConfigs, editorIndex, newEditorConfig);
    this.editorsValues = this.conditionService.pushAfter(this.editorsValues, editorIndex, {value: this.editorsValues[editorIndex].value, alreadyExist: true});
    this.editors.clear({emitEvent: false});
    newArray.forEach((x) => this.editors.push(x, {emitEvent: false}));
    this.editorsConfigs.forEach((x, i) => x.priority = i + 1);

    this.updateEditorsValues();
    this.editors.updateValueAndValidity();
  }

  public deleteEditor(editorIndex: number) {
    if(this.editors.length != 1 ) {
      this.editors.removeAt(editorIndex);
      this.editorsValues.splice(editorIndex, 1);
      this.updateEditorsValues();
      this.editorsConfigs.splice(editorIndex, 1);
      this.editorsConfigs.forEach((x, i) => x.priority = i + 1);
    }
    else {
      this.editors.controls[0].setValue(this.emptyMultipleConditionValue());
      this.editorsConfigs[editorIndex] = this.emptyConfig(1);
      this.editorsValues[editorIndex] = {value: '', alreadyExist: false};
    }
    this.editors.updateValueAndValidity();
  }

  public addEditor() {
    this.editorsValues.push({value: '', alreadyExist: false});
    this.editorsConfigs.push(this.emptyConfig(this.editors.length + 1));
    this.editors.push(new FormControl(this.emptyMultipleConditionValue(), [Validators.required, 
                                                                            this.validationService.validMultipleConditionValue(),
                                                                            this.validationService.validEmptyMultipleConditionValue()]));
    this.editors.updateValueAndValidity();
  }

  public onEditorValueChanged(index: number, value: string) {
    this.editorsValues[index].value = value;
    this.updateEditorsValues();
    this.elseValue.updateValueAndValidity();
  }

  public scrollToInvalidEditor() {
    let firstInvalidEditorIndex = -1;
    this.editors.controls.find((x, i) => {
      if(x.invalid) {
        firstInvalidEditorIndex = i;
        return true;
      }
      return false;
    });

    if (firstInvalidEditorIndex > -1) {
      const firstInvalidEditor = document.getElementById(`condition-editor-${firstInvalidEditorIndex}`);
      firstInvalidEditor.scrollIntoView({behavior: 'smooth'});
      this.allEditors.forEach((x, i) => {
        if (i === firstInvalidEditorIndex) {
          x.setFirstInvalidInputAsTouched();
        }
      });
    }
    else if (this.elseValue.invalid) {
      document.getElementById('elseValue').scrollIntoView({behavior: 'smooth'});
      this.elseValue.markAsTouched();
    }
  }
  
  public buildAttribute(): MultyExpressionsConditionalAttribute {
    let lastConditionPosition = 0;
    let attribute = {
      ExpressionsArray: [],
      ElseValue: this.elseValue.value,
    }

    this.editors.controls.forEach((x, i) => {
      let value = x.value as MultipleConditionValue;
      attribute.ExpressionsArray.push({
        Priority: i + 1,
        ShowComplexExpression: value.ComplexExpression !== '',
        ComplexExpression: value.ComplexExpression === '' 
          ? this.conditionService.getDefaultComplexExpression(lastConditionPosition, value.Conditions) 
          : this.conditionService.parseComplexExpression(lastConditionPosition, value.ComplexExpression, 'build'),
        Conditions: value.Conditions.map((x) => {
          x.Position = ++lastConditionPosition;
          return x;
        }),
        Value: value.Value
      } as ComplexExpressionsElement);
    });

    return attribute as MultyExpressionsConditionalAttribute;
  }

  get editors(): FormArray {
    return this.form.get('multipleConditionEditors') as FormArray;
  }

  get elseValue(): FormControl {
    return this.form.get('elseValue') as FormControl;
  }

  private emitChanges() {
    this.changeEmitter.emit(true);
    this.attrManagerService.isAttributeEditorValid.next(this.config.isDisabled ? true : this.form.valid);
  }

  private createForm() {
    let conditionEditorsValues: MultipleConditionValue[];

    if(this.config.attributeBody) {
      conditionEditorsValues = this.conditionService.splitToMultipleConditionValue(this.config);
      this.editorsConfigs = this.conditionService.splitToMultipleConditionEditorConfigs(this.config);
      this.editorsValues = this.config.attributeBody.ExpressionsArray.map(x => { return {value: x.Value, alreadyExist: false} });
    } else {
      conditionEditorsValues = [this.emptyMultipleConditionValue()];
      this.editorsConfigs = [this.emptyConfig(1)];
      this.editorsValues = [{value: '', alreadyExist: false}];
    }

    this.form = new FormGroup({
      multipleConditionEditors: new FormArray(conditionEditorsValues.map((x) => {
        x.Conditions.map((y, i) => y.Position = i + 1);
        return new FormControl(x, [Validators.required, 
                                   this.validationService.validMultipleConditionValue(),
                                   this.validationService.validEmptyMultipleConditionValue()])
      })),
      elseValue: new FormControl('', [Validators.required, Validators.maxLength(maxLength)]),
    });
  }

  private emptyConfig(priority: number): MultipleConditionEditorConfig {
    return {
      priority: priority,
      showComplexExpression: false,
      isDisabled: this.config.isDisabled,
      isAddMode: this.config.isAddMode
    }
  }

  private emptyMultipleConditionValue(): MultipleConditionValue {
    return {
      ComplexExpression: "",
      Conditions: [],
      Value: '',
      EmptyRequiredFields: true,
    } as MultipleConditionValue;
  }

  private updateEditorsValues() {
    const valuesArr = this.editorsValues.map(x => x.value);
    const duplicates = valuesArr.filter((item, index) => index !== valuesArr.indexOf(item));

    this.editorsValues.forEach((x, i) => {
      this.editorsValues[i].alreadyExist = duplicates.indexOf(x.value) > -1;
    });
  }

  private buildErrorMessage() {
    return {
      "emtpyFields": this.editors.controls.some(x => x.hasError("emptyFields")) || !this.elseValue.value,
      "notAllowedValues": this.editorsValues.some(x => x.alreadyExist) || this.elseValue.hasError("notAllowedValue"),
    };
  }
}

const translateKeys = {
  value: 'features.user_settings.body.attributes.addConditionAttribute.VALUE',
  add: 'features.user_settings.body.attributes.multipleConditionAttribute.ADD',
  else: 'features.user_settings.body.attributes.multipleConditionAttribute.NONE_ELSE',
  elseVal: 'features.user_settings.body.attributes.multipleConditionAttribute.ELSE_VALUE',
}
