export class LabelTranslateKeys {
    sectionPrefix = 'features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.';
    title = this.sectionPrefix + 'TITLE';
    neverEndPreview = this.sectionPrefix + 'NEVER_END_PREVIEW';
    endAfterPreview = this.sectionPrefix + 'END_AFTER_PREVIEW';
    endByPreview = this.sectionPrefix + 'END_BY_PREVIEW';
    dailyTimeUnitPreviewSingular = this.sectionPrefix + 'DAILY_TIMEUNIT_PREVIEW_SINGULAR';
    dailyTimeUnitPreviewPlural = this.sectionPrefix + 'DAILY_TIMEUNIT_PREVIEW_PLURAL';
    weeklyTimeunitPreviewSingular = this.sectionPrefix + 'WEEKLY_TIMEUNIT_PREVIEW_SINGULAR';
    weeklyTimeunitPreviewPlural = this.sectionPrefix + 'WEEKLY_TIMEUNIT_PREVIEW_PLURAL';
    monthlyTimeunitPreviewSingular = this.sectionPrefix + 'MONTHLY_TIMEUNIT_PREVIEW_SINGULAR';
    monthlyTimeunitPreviewPlural = this.sectionPrefix + 'MONTHLY_TIMEUNIT_PREVIEW_PLURAL';
    minEndDateError = this.sectionPrefix + 'MIN_END_DATE_ERROR';
    endDateAlreadyPassedError = this.sectionPrefix + 'END_DATE_PASSED_ERROR';
    day = this.sectionPrefix + 'DAY';
    week = this.sectionPrefix + 'WEEK';
    month = this.sectionPrefix + 'MONTH';
    days = this.sectionPrefix + 'DAYS';
    weeks = this.sectionPrefix + 'WEEKS';
    months = this.sectionPrefix + 'MONTHS';
    numberOfOccurrencesPastError = this.sectionPrefix + 'NUMBER_OF_OCCURRENCES_PAST_ERROR';
}
