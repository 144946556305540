import { Component, OnInit, OnDestroy , ViewEncapsulation } from '@angular/core';
import './conditionalLanguageModal.component.scss'
import { ConditionalLanguageService } from "../conditionalLanguage.service";
import { Subscription, EMPTY, timer, BehaviorSubject } from 'rxjs';
import { GridOptions, GridApi } from "@ag-grid-enterprise/all-modules";
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AllCommunityModules, Module } from '@ag-grid-enterprise/all-modules';


@Component({
    selector: 'conditional-language-modal',
    templateUrl: './conditionalLanguageModal.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ConditionalLanguageModalComponent implements OnInit, OnDestroy {

    public snippetsObject: any;
    // public searchFilter$ = new BehaviorSubject<string>('');
    public snippetsObjectSubscription: Subscription;
    public isGridReady: boolean = false;
    public modules: Module[] = AllCommunityModules;
    public hasScroll = false;
    private gridApi: GridApi;

    constructor(private conditionalLanguageService: ConditionalLanguageService, public bsModalRef: BsModalRef) { }

    ngOnInit(): void {
        this.snippetsObjectSubscription = this.conditionalLanguageService.snippetsObject$.subscribe(val => {
            if (val) {
                this.snippetsObject = val;
                this.gridOptions.api && this.setGridData();
            }
        });

        setTimeout(() => {
            this.isGridReady = true;
        }, 750);
    }

    private setGridData(): void {
        this.gridOptions.api.setRowData(this.snippetsObject);
    }

    public gridOptions = <GridOptions>{
        columnDefs: [
            {
                headerName: '', field: 'html', rowDrag: false, suppressMovable: true, cellRenderer: this.cellHtml, autoHeight: true
                // , getQuickFilterTextQuickFilterText: this.quickFilterText.bind(this)
            },
            // { field: 'tag', hide: true }
        ],

        overlayNoRowsTemplate: '<span class="overlay-no-rows">No conditional language snippets found</span>',
        headerHeight: 0,
        // rowHeight: 100,
        animateRows: false,
        domLayout: 'autoHeight',
        rowSelection: 'single',

        onGridReady: (params) => {
            this.gridApi = params.api;
            //this.gridColumnApi = params.columnApi;
            this.gridApi.sizeColumnsToFit();
            this.snippetsObject && this.snippetsObject.length && this.setGridData();
        },

        getRowClass: (params) => {
            return 'conditional-language-snippet-row';
        }
    };

    public onSelectionChanged(e: any): void {
        let selectedRows = this.gridApi.getSelectedRows();
        if (selectedRows && selectedRows[0]) {
            let row = selectedRows[0];
            this.conditionalLanguageService.updateChosenSnippet(row.html);
        }
    }

    public onModelUpdated(e: any): void {
        if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
            this.gridApi.showNoRowsOverlay();
        }
        if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
            this.gridApi.hideOverlay();
        }
    }

    private cellHtml(params: any): string {
        return `<div class="conditional-language-snippet">
                    <h3 class="snippet-title">${params.data.title}</h3>
                    <div class="snippet-html">${params.data.displayHtml ? params.data.displayHtml : params.data.html}</div>
                </div>`;
    }
    ngOnDestroy(): void {
        this.snippetsObjectSubscription && this.snippetsObjectSubscription.unsubscribe();
    }
}