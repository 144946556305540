import {
  Component,
  SimpleChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  OnChanges,
} from "@angular/core";
import { ColDef } from "@ag-grid-community/all-modules";
import { TranslateService } from "@ngx-translate/core";
import {
  AllCommunityModules,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  Module,
  RowNode,
} from "@ag-grid-enterprise/all-modules";
import { forkJoin, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UniquePromoCodesService } from "../services/uniquePromoCodes.service";
import { IUniquePromoCodesLobby } from "../models/iuniquePromoCodesLobby.model";
import { StatusCellRendererComponent } from "../components/statusCellRenderer/statusCellRenderer.component";
import { EditDeleteCellRendererComponent } from "../../settingsShared/editDeleteCellRenderer/editDeleteCellRenderer.component";
import * as moment from "moment";
import { WindowRefService } from "../../../../services/windowRef.service";
import { SettingsSharedService } from "../../settingsShared/settingsShared.service";

@Component({
  selector: "unique-promo-codes",
  templateUrl: "./uniquePromoCodes.component.html",
  styleUrls: ["./uniquePromoCodes.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UniquePromoCodesComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public modules: Module[] = AllCommunityModules;
  public rowData: IUniquePromoCodesLobby[];
  public emptyState: boolean = false;
  public loading: boolean = true;
  public isLoaded: boolean = true;
  public filterText = "";
  readonly headerHeight: number = 50;
  readonly rowHeight: number = 46;

  private gridApi: GridApi;
  private subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private uniquePromoCodesService: UniquePromoCodesService,
    private settingsSharedService: SettingsSharedService,
    private windowRefService: WindowRefService
  ) {}

  ngOnInit() {
    this.setupGridOptions();
    this.getUniquePromoCodesList();
    this.addEditSubscription();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getUniquePromoCodesList() {
    this.uniquePromoCodesService
      .getUniquePromoCodesList()
      .pipe(
        map((uniquePromoCodes) => {
          return {
            uniquePromoCodes,
          };
        })
      )
      .subscribe((res) => {
        this.rowData = res.uniquePromoCodes;
        this.loading = false;
      });
  }

  public addNewPromoCode() {
    let URItoRedirectTo =
      "/#/user-settings/uniquePromoCodes/addUniquePromoCode";
    this.windowRefService.nativeWindow.open(URItoRedirectTo, "_self");
  }

  private addEditSubscription() {
    let editSubscription =
      this.settingsSharedService.onEditSettingClick.subscribe((rowId) => {
        this.editUniquePromotion(rowId);
      });
    this.subscriptions.push(editSubscription);
  }

  public editUniquePromotion(rowId?: string) {
    let URIParams = "?editMode=1&";
    if (rowId) {
      let params = this.gridApi.getRowNode(rowId).data;
      URIParams =
        URIParams +
        Object.keys(params)
          .filter((k) => k == "promotionId" || k == "promotionName")
          .map(function (k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
          })
          .join("&");
    }

    let URItoRedirectTo =
      "/#/user-settings/uniquePromoCodes/addUniquePromoCode" + URIParams;
    this.windowRefService.nativeWindow.open(URItoRedirectTo, "_self");
  }

  private setupGridOptions() {
    const columnDefs = this.getColumnDefinitions();
    this.gridOptions = {
      columnDefs: columnDefs,
      context: this,
      headerHeight: this.headerHeight,
      rowHeight: this.rowHeight,
      suppressRowTransform: true,
      suppressCellSelection: true,
      animateRows: true,
      doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
      isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      onGridReady: (params) => {
        this.gridApi = params.api;
      },
      onFirstDataRendered: (params: FirstDataRenderedEvent) => {
        params.api.sizeColumnsToFit();
      },
      defaultColDef: {
        sortable: true,
      },
    };
  }

  public onTextFilterChanged(filterText: string) {
    this.filterText = filterText;
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  private getColumnDefinitions(): ColDef[] {
    let colDef: ColDef[] = [
      {
        field: "promotionName",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.PROMOTION_NAME"
        ),
        sort: "asc",
        sortable: false,
        minWidth: 160,
        tooltipField: "promotionName",
        flex: 1,
        resizable: true,
      },
      {
        field: "status",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.STATUS"
        ),
        cellRendererFramework: StatusCellRendererComponent,
        minWidth: 110,
        sortable: false,
      },
      {
        field: "remaining",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.REMAINING"
        ),
        minWidth: 130,
        sortable: false,
      },
      {
        field: "total",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.TOTAL"
        ),
        sortable: false,
        minWidth: 140,
        cellRenderer: (data) => {
          return `${data.data.total} (${data.data.used})`;
        },
      },
      {
        field: "dateCreated",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.DATE_CREATED"
        ),
        sortable: true,
        minWidth: 148,
        cellRenderer: (data) => {
          return moment(data.value).format("DD MMM YYYY");
        },
        valueFormatter: (params) => moment(params.value).format("DD MMM YYYY"),
      },
      {
        field: "defaultCodeValue",
        headerName: this.translate.instant(
          "features.user_settings.body.uniquePromoCodes.DEFAULT_VALUE"
        ),
        sortable: false,
        minWidth: 140,
        tooltipField: "defaultCodeValue",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        cellRendererFramework: EditDeleteCellRendererComponent,
        cellRendererParams: { hideDelete: true }, //hidden in US 163700
        minWidth: 70,
        maxWidth: 70, 
      },
    ];
    return colDef;
  }

  public isExternalFilterPresent(): boolean {
    return this.filterText.length > 0;
  }

  public doesExternalFilterPass(node: RowNode): boolean {
    const formatFilterText = this.filterText.toLowerCase();
    return node.data.promotionName.toLowerCase().includes(formatFilterText);
  }
}
