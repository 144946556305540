import { Component, OnInit } from '@angular/core';
import { IModalConf, OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { TemplateDetails } from '../../../models/templateDetails';
import { Utm } from '../../../models/utm';
import { TemplateQueryParams } from '../../../models/templateQueryParams';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { TranslateService } from '@ngx-translate/core';
import { GTMDataLayerService } from '../../../../../directives/optitrack/optitrack.service';
import { TemplateContextService } from '../../../services/templateContext.service';
import { EditorType } from '../../../models/editorType';
import { SubMethodService } from '../../../services/subMethodService';
import $ from 'jquery';
import { first } from 'rxjs/operators';

enum Utm_Type {
	utm_source = 'utm_source',
	utm_medium = 'utm_medium',
	utm_campaign = 'utm_campaign',
	utm_term = 'utm_term',
	utm_content = 'utm_content',
	utm_id = 'utm_id'
}

@Component({
	selector: 'utm-params',
	templateUrl: 'utmParams.component.html',
	styleUrls: ['utmParams.component.scss']
})
export class UtmParamsComponent implements OnInit {
	public subMethodType: number;
	public focused_source: boolean;
	public isLoadSource: boolean;
	public focused_medium: boolean;
	public isLoadMedium: boolean;
	public focused_campaign: boolean;
	public isLoadCampaign: boolean;
	public focused_params: boolean;
	public isLoadTerm: boolean;
	public focused_content: boolean;
	public isLoadContent: boolean;
	public focused_utm_id: boolean;
	public isLoadUtmId: boolean;
	public modalConf: IModalConf;
	public froalaTemplate: any;

	public template: TemplateDetails;
	public queryTemplateParams: TemplateQueryParams;
	public utm: Utm = {
		utms: {
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			utm_term: '',
			utm_content: '',
			utm_id: ''
		}
	} as Utm;
	public sample_url: string = '';
	public res_url: string;

	constructor(
		public bsModalRef: BsModalRef,
		private templateContext: TemplateContextService,
		private manageTemplatesService: ManageTemplatesService,
		public translateService: TranslateService,
		private gtmDataLayerService: GTMDataLayerService,
		private optiModalService: OptiLogicModalService,
		public subMethodService: SubMethodService
	) {}

	ngOnInit(): void {
		this.templateContext.current.pipe(first()).subscribe((t) => {
			this.template = this.froalaTemplate ?? t;
			if (this.template.utm) {
				this.utm = JSON.parse(this.template.utm);
			}
		});
		this.templateContext.queryTemplateParams.subscribe((queryTemplateParams) => {
			this.queryTemplateParams = queryTemplateParams;

			this.manageTemplatesService
				.getMetadata()
				.subscribe(
					(metadata) => (this.subMethodType = this.subMethodService.findSubmethodType(metadata, +this.queryTemplateParams.typeId))
				);
		});

		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.optimail.FORM.TRACKING_PARAMS_BTN'),
			hideHeaderPanel: false,
			hideFooterPanel: false,
			hideFooterBorder: true,
			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('general.APPLY'),
					action: () => {
						return this.onApply();
					}
				},
				{
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						this.bsModalRef.hide();
					}
				}
			]
		};

		this.populatePreviouslyConfiguredUtmParams();
	}

	generate(): void {
		this.manageTemplatesService
			.generatetUtmParamsInHref({
				...this.utm.utms,
				chanelID: this.queryTemplateParams.channelId,
				generateuUrl: this.sample_url,
				subMethodType: this.subMethodType
			})
			.subscribe((data) => (this.res_url = data));
	}

	onApply(): BsModalRef<any> {
		this.gtmDataLayerService.addGTMEvent('CLICKED_BUTTON', 'Manage Templates', {
			button_name: 'Append'
		});

		return this.optiModalService.openModalMessage(
			'sm',
			{
				message: this.translateService.instant('features.manage_templates.components.utmParams.CONFIRMATION_DIALOG'),
				buttons: [
					{
						class: 'btn-primary',
						label: this.translateService.instant('general.APPLY'),
						action: () => {
							this.save();
							this.bsModalRef.hide();
						}
					},
					{
						class: 'btn-default',
						label: this.translateService.instant('general.CANCEL'),
						action: () => {}
					}
				]
			},
			<ModalOptions<any>>{ ignoreBackdropClick: false, keyboard: false }
		);
	}

	save(): void {
		this.utm.isOverrideManualInsertedUtm = this.shouldOverrideManualInsertedUtm();
		this.utm.utms.utm_source = this.utm.utms.utm_source || '';
		this.utm.utms.utm_medium = this.utm.utms.utm_medium || '';
		this.utm.utms.utm_campaign = this.utm.utms.utm_campaign || '';
		this.utm.utms.utm_term = this.utm.utms.utm_term || '';
		this.utm.utms.utm_content = this.utm.utms.utm_content || '';
		this.utm.utms.utm_id = this.utm.utms.utm_id || '';

		this.template.utm = JSON.stringify(this.utm);
		if (this.froalaTemplate) {
			(this.template as any).utmObj = this.utm;
		}

		if (this.templateContext.templateType(this.template) === EditorType.froala) {
			this.manageTemplatesService
				.upsertUtmParamsInAllHrefInTemplate({
					executionMethodId: this.template.executionMethodId,
					templateId: this.template.templateId,
					html: this.template.html,
					isAutoGeneratePlainText: this.template.autoGeneratePlainText,
					...this.utm.utms,
					subMethodType: this.subMethodType
				})
				.subscribe((response) => {
					this.template.html = response.revisedHtml;
					if (this.template.autoGeneratePlainText) {
						this.template.text = response.revisedText;
					}
					const editor = $('#opti-froala').data('froala.editor');
					const CM = editor.codeView.getCM();
					if (CM) {
						CM.setValue(response.revisedHtml);
					}
				});
		}
	}

	shouldOverrideManualInsertedUtm(): boolean {
		for (const property in this.utm.utms) {
			if (this.utm.utms[property]) {
				return true;
			}
		}
		return false;
	}

	onTagChosenForUtmSource($event: any) {
		this.isLoadSource = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_source += $event;
	}

	onTagChosenForUtmMedium($event: any) {
		this.isLoadMedium = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_medium += $event;
	}

	onTagChosenForUtmCampaign($event: any) {
		this.isLoadCampaign = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_campaign += $event;
	}

	onTagChosenForUtmTerm($event: any) {
		this.isLoadTerm = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_term += $event;
	}

	onTagChosenForUtmContent($event: any) {
		this.isLoadContent = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_content += $event;
	}

	onTagChosenForUtmId($event: any) {
		this.isLoadUtmId = false;
		if (!$event) {
			return;
		}
		this.utm.utms.utm_id += $event;
	}

	populatePreviouslyConfiguredUtmParams() {
		if (
			this.templateContext.templateType(this.template) !== EditorType.beefree &&
			this.templateContext.templateType(this.template) !== EditorType.froala
		) {
			let previouslyConfiguredUtm = this.retrivePreviousUtmFromScannedHtml();
			this.utm.utms = {
				utm_source: previouslyConfiguredUtm.utm_source,
				utm_medium: previouslyConfiguredUtm.utm_medium,
				utm_campaign: previouslyConfiguredUtm.utm_campaign,
				utm_term: previouslyConfiguredUtm.utm_term,
				utm_content: previouslyConfiguredUtm.utm_content,
				utm_id: previouslyConfiguredUtm.utm_id
			};
		}
	}

	retrivePreviousUtmFromScannedHtml() {
		let el = document.createElement('html');
		el.innerHTML = this.template.html;
		let aList = el.getElementsByTagName('a');

		if (aList.length == 0) {
			return {};
		}

		let links = [];
		for (let a of aList as any) {
			if (!a.outerHTML.includes('[%UNSUB%]') && !a.outerHTML.includes('[%UNSUB_PREFERENCE%]') && !a.href.includes('/#')) {
				links.push({ origin: a.origin, queryParams: this.parseQueryParams(a.search) });
			}
		}

		return {
			utm_source: this.getUnanimousUtm(links, Utm_Type.utm_source),
			utm_medium: this.getUnanimousUtm(links, Utm_Type.utm_medium),
			utm_campaign: this.getUnanimousUtm(links, Utm_Type.utm_campaign),
			utm_term: this.getUnanimousUtm(links, Utm_Type.utm_term),
			utm_content: this.getUnanimousUtm(links, Utm_Type.utm_content),
			utm_id: this.getUnanimousUtm(links, Utm_Type.utm_id)
		};
	}

	parseQueryParams(queryParams: string): any {
		queryParams = queryParams.replace('?', '');
		const paramsArr = queryParams.split(/&amp;|&/);

		let result = {};
		paramsArr.forEach((p) => {
			let splitParts = p.split('=');
			let paramObj = { name: splitParts[0], value: splitParts[1] };
			if ((<any>Object).values(Utm_Type).includes(paramObj.name)) {
				result[paramObj.name] = paramObj.value;
			}
		});

		return result;
	}

	getUnanimousUtm(links: any, utmType: Utm_Type): string {
		const firstLink = links[0];
		if (!firstLink || !firstLink.queryParams || !firstLink.queryParams[utmType]) {
			return null;
		}

		const utmParamValueOfFirstLink = firstLink.queryParams[utmType];

		for (let index = 1; index < links.length; index++) {
			const link = links[index];
			if (!link.queryParams || !link.queryParams[utmType] || link.queryParams[utmType] != utmParamValueOfFirstLink) {
				return null;
			}
		}

		return utmParamValueOfFirstLink;
	}
}
