import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter , ViewEncapsulation } from "@angular/core";
import { IChannelData, MainMetricTypes, TableMetricTypes, IChannelMetricsElement, ActionType } from "../../campaignAnalysis.models";
import { ChannelMetricsColorsService } from "../../channelMetricsColors.service";
import { SpecialChannelsService } from "../../specialChannels.service";

@Component({
    selector: 'channel-metrics-data-bar',
    templateUrl: './channelMetricsDataBar.component.html',
    styleUrls: ['./channelMetricsDataBar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsDataBarComponent implements OnChanges {

    @Input() channelData: IChannelData;
    @Input() datesData: string[];
    @Input() planIdActionSerial: [];
    @Input() planDetailChannelId: number;
    @Input() isScheduledCampaign: any;
    @Input() actionType: ActionType;
    @Output() metricClick: EventEmitter<{ metricId, channelId, channelName, planDetailChannelId, metricName, templateId,executionStartDate,executionEndDate }> = new EventEmitter();

    numbersData: IChannelMetricsElement[];
    numbersDataSparse: IChannelMetricsElement[];
    tableData: IChannelMetricsElement[];
    delivered: IChannelMetricsElement;
    opened: IChannelMetricsElement;
    clicked: IChannelMetricsElement;


    constructor(private channelMetricsColorsService: ChannelMetricsColorsService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.channelData)
            this.prepareData();
    }

    onMetricClick({ metricId, metricName }) {
        this.metricClick.emit({
            metricId,
            channelId: this.channelData.channelId,
            channelName: this.channelData.channelName,
            planDetailChannelId: this.channelData.planDetailChannelId,
            metricName,
            templateId: null,
            executionStartDate: this.datesData[0],
            executionEndDate: this.datesData[1],
        });
    }

    private prepareData() {

        let metrics = this.channelData.metrics;

        this.numbersData = metrics.filter(metric => Object.values(MainMetricTypes).find(name => metric.name == name));
        this.numbersData.forEach(nd => {
            nd.color = this.channelMetricsColorsService.getColor(this.actionType, nd.name);
        });

        this.createNumbersSparse(this.numbersData);

        this.tableData = metrics.filter(metric => Object.values(TableMetricTypes).find(name => metric.name == name));

        this.delivered = this.getMainMetric(MainMetricTypes.Delivered);
        this.opened = this.getMainMetric(MainMetricTypes.Opened);
        this.clicked = this.getMainMetric(MainMetricTypes.Clicked);
    }

    private getMainMetric(metricName: MainMetricTypes): IChannelMetricsElement {
        let metric = this.numbersData.find(el => el.name === metricName)
        if (!metric)
            return null;

        metric = JSON.parse(JSON.stringify(metric));
        metric.percent *= 100;

        return metric;
    }

    private createNumbersSparse(elements: IChannelMetricsElement[]) {
        this.numbersDataSparse = [];
        Object.values(MainMetricTypes).map(metricName => this.numbersDataSparse.push(elements.find(el => el.name == metricName)));
    }
}
