import { AllModules, ColDef, GridApi, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from "rxjs";
import { filter, first, switchMap, takeUntil, tap } from "rxjs/operators";
import { SearchItem, SearchListConfig } from '../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { WindowRefService } from '../../../../services/windowRef.service';
import { AttributeHelper } from "../../../settings/attributes/attributesList.helper";
import {
	ICustomerInfo,
	ISearchInputValue,
	ISearchProp,
	IdClickEvent
} from "../../customer360.models";
import { Customer360DataService } from '../../services/customer360.data.service';

const MinSearchStrLength = 1;

@Component({
	selector: 'customers-list',
	templateUrl: './customersList.component.html',
	styleUrls: ['./customersList.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CustomersListComponent implements OnInit, OnDestroy {

	@Input()
	set searchStr(options: ISearchInputValue) {
		if (options) {
			this.searchType = options?.SearchType
			this.searchStrControl.setValue(options?.SearchStr);
		}
	}

    @Output('onInit') onInitEvent = new EventEmitter<void>();
    @Output('onDataLoaded') onDataLoadedEvent = new EventEmitter<ICustomerInfo[]>();
    @Output('onSearchByChanges') onSearchByChangesEvent = new EventEmitter<void>();
	@Output('onCustomerSelected') onIdClickEvent = new EventEmitter<IdClickEvent>();

    private _cleanup$: Subject<void>;
    private gridApi: GridApi;
    private searchType: string;

    public propsToSearch: ISearchProp[] = [];
    public CIDAttr: string;
    public firstNameAttr: string;
    public lastNameAttr: string;
    public customers: ICustomerInfo[] = [];
    public gridOptions: GridOptions;
    public modules: Module[] = AllModules;
    public translateKeys = translateKeys;
	public pageNumber: number = 1;
    public loadStep: number = 100;
    public initialized: boolean = true;
    public loading: boolean = false;

    public orderBy: SearchItem[];
    public orderItems: SearchItem[] = [];
    public orderConfig: SearchListConfig = {
    	keyProperty: 'key',
    	valueProperty: 'value',
    	isMultiSelect: false,
    	itemNameTranslateKey: this.translate.instant(this.translateKeys.brandLabel),
    };

    searchStrControl = new FormControl('');
    searchByControl = new FormControl('');

    constructor(
		private readonly dataService: Customer360DataService,
        private readonly translate: TranslateService,
        @Inject(DOCUMENT) private readonly document: Document,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly windowService: WindowRefService,
		private readonly cd: ChangeDetectorRef
    ) {
    	this._cleanup$ = new Subject<void>();
    }

    ngOnInit(): void {
    	this.valueChangesSubscribe();

    	this.dataService.getPropsToSearch()
    		.pipe(first(), filter(x => !!x))
    		.subscribe((props: ISearchProp[]) => {
    			this.CIDAttr = props.find(p => p.value === 'Customer ID')?.key;
    			this.firstNameAttr = props.find(p => p.value === 'First Name')?.key;
    			this.lastNameAttr = props.find(p => p.value === 'Last Name')?.key;
    			this.orderItems = this.searchPropsToSearchItems(props);
    			this.orderBy = [this.orderItems[0]];
    			this.propsToSearch = this.updateSearchProps(props);
    			this.initGridConfig();
    			this.initialized = false;
    			this.onInitEvent.emit();
    			this.onOrderChanged(this.orderBy);
				this.searchStrControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    		});
    }

    ngOnDestroy(): void {
    	this._cleanup$.next();
    	this._cleanup$.complete();
    }

    public onOrderChanged(item: SearchItem[]) {
    	let columnDefs = this.getColumnDefs();

    	columnDefs.forEach((def) => {
    		def.sort = def.field === item[0].key
    			? 'asc'
    			: null;
    	});

    	this.gridOptions.api
    		? this.gridOptions.api?.setColumnDefs(columnDefs)
    		: this.gridOptions.columnDefs = columnDefs;
    }

	public onSortChanged(event) {
    	const columnState = this.gridOptions.columnApi.getColumnState();
    	if (columnState.length > 0) {
    		for (let i = 0; i < columnState.length; i++) {
				if(columnState[i].sort){
					this.orderBy = this.orderItems.filter(oi => oi.key === columnState[i].colId);
				}
    		}
    	}
    }

    private valueChangesSubscribe() {
    	this.searchStrControl.valueChanges.pipe(
    		takeUntil(this._cleanup$),
    		tap((searchStr: string) => {
    			this.loading = true;
    			if (searchStr?.length < MinSearchStrLength) {
    				this.customers = [];
    			}
    		}),
    		filter((searchStr: string) => searchStr?.trim().length >= MinSearchStrLength),
    		switchMap((searchStr: string) => this.dataService.getCustomersBySearchString({
    			searchStr: searchStr.trim(),
    			searchBy: this.searchByControl.value === 'Name'
    				? (this.firstNameAttr && this.lastNameAttr
    					? `${this.firstNameAttr},${this.lastNameAttr}`
    					: `${this.firstNameAttr || this.lastNameAttr}`)
    				: this.searchByControl.value,
    			orderBy: this.orderBy[0].key === 'Name'
    				? this.firstNameAttr
    				: this.orderBy[0].key,
    			pageNumber: this.pageNumber,
    			pageSize: this.loadStep,
    			searchType: this.searchType,
    		}))
    	).subscribe((resp) => {
    		if (resp.length === 1) {
    			const searchStr = encodeURI(this.searchStrControl.value);
				const customerId = resp[0][this.CIDAttr];
				this.onIdClickEvent.emit({
					CustomerId: customerId,
					SearchStr: this.searchStrControl.value,
					SearchType: this.searchType
				});
			} else {
				this.router.navigate(
					[],
					{
						relativeTo: this.route,
						queryParams: {
							customerId: null,
							viewTrendBy: null,
							selectedRangeIndex: null,
							startDate: null,
							endDate: null
						},
						queryParamsHandling: 'merge'
					});
			}
    		this.customers = this.setFullName(resp) || [];
    		this.updateTable([...this.customers]);
    		this.onDataLoadedEvent.emit([...this.customers]);
    		this.loading = false;
			this.cd.detectChanges();
    	});

		this.searchByControl.valueChanges
			.pipe(takeUntil(this._cleanup$))
			.subscribe(_ => {
				this.searchStrControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
				this.onSearchByChangesEvent.emit();
				this.cd.detectChanges();
			});
    }

    private updateTable(customers: ICustomerInfo[]) {
    	if (this.gridApi) {
    		this.gridApi.setRowData(customers);
    		this.gridApi.refreshClientSideRowModel();
    		this.gridApi.refreshHeader();
    		this.gridApi.refreshCells();
    	}
    }

    private initGridConfig() {
    	this.gridOptions = {
    		rowData: this.customers,
    		context: this,
    		columnDefs: this.getColumnDefs(),
    		headerHeight: 50,
    		rowHeight: 55,
    		alwaysShowHorizontalScroll: false,
    		suppressHorizontalScroll: false,
    		suppressRowTransform: true,
    		suppressCopyRowsToClipboard: false,
    		rowSelection: 'multiple',
    		enableCellTextSelection: true,
    		multiSortKey: 'ctrl',
    		getMainMenuItems: (params) => {
    			return params.defaultItems;
    		},
    		getRowNodeId: (data) => {
    			return data.id;
    		},
    		onGridReady: (params) => {
    			this.gridApi = params.api;
    			this.gridApi.setRowData([...this.customers]);
    			this.gridApi.refreshClientSideRowModel();
    		},
    		getRowHeight: (params) => {
    			return 40;
    		},
    		defaultColDef: {
    			sortable: true,
    			resizable: true
    		}
    	};
    }

    private getColumnDefs(): ColDef[] {
    	return this.propsToSearch
    		.filter((prop) => !prop.disabled)
    		.map((prop) => {
    			const colDef: ColDef = {
    				headerName: prop.value,
    				headerTooltip: prop.value,
    				field: prop.key,
    				sortable: true,
    				width: 1102 / this.propsToSearch.filter(x => !x.disabled).length,
    				minWidth: 1102 / this.propsToSearch.filter(x => !x.disabled).length,
    				enableCellChangeFlash: true,
    				cellStyle: () => {
    					return {'padding-right': '20px'}
    				},
					onCellClicked: (params) => {
						if (params.colDef.field === this.CIDAttr) {
							let customerId = params.data[params.colDef.field];
							this.onIdClickEvent.emit({
								CustomerId: customerId,
								SearchStr: this.searchStrControl.value,
								SearchType: this.searchType
							});
						}
					},
					cellRenderer: (params) => {
						let value = params.data[params.colDef.field];
						if (params.colDef.field !== this.CIDAttr) {
							return value;
						}

						return `<a [routerLink]="null">${value}</a>`
					},
    				comparator: AttributeHelper.stringComparator,
    				tooltipValueGetter: (params) => {
    					return AttributeHelper.decodeHTML(params.value);
    				},
    			};
    			return colDef;
    		});
    }

	private searchPropsToSearchItems(searchProps: ISearchProp[]): SearchItem[] {
		return this.updateSearchProps(searchProps)
			.filter((prop) => !prop.disabled)
			.map(x => ({key: x.key, value: x.value} as SearchItem));
	}

	private updateSearchProps(searchProps: ISearchProp[]): ISearchProp[] {
		const isFullNameDisabled = searchProps.filter(x => x.key.includes('Name'))
			.every(x => x.disabled);
		searchProps = searchProps.filter(x => !x.key.includes('Name'));
		searchProps.find(x => x.key === "Phone").value = "Phone";
		searchProps.find(x => x.key === "Email").value = "Email";
		searchProps.push({key: "Name", value: "Name", disabled: isFullNameDisabled });
		return searchProps;
	}

	private setFullName(customers: ICustomerInfo[]): ICustomerInfo[] {
		customers.forEach(x => x.Name = `${x['FirstName']} ${x['LastName']}`);
		return customers;
	}
}

const translateKeys = {
	title: 'features.customer360.body.customers_list.TITLE',
	titleSingleResult: 'features.customer360.body.customers_list.TITLE_SINGLE_RESULT',
	titleNoResults: 'features.customer360.body.customers_list.TITLE_NO_RESULTS',
	searchTermIn: 'features.customer360.body.customers_list.SEARCH_TERM_IN',
	brandLabel: 'features.user_settings.body.subscribers.BRAND_LABEL',
	noCustomersFoundTitle: 'features.customer360.body.state_messages.no_customers_found.TITLE',
	noCustomersFoundDescription: 'features.customer360.body.state_messages.no_customers_found.DESCRIPTION'
}
