import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild, ElementRef, Renderer2
, ViewEncapsulation } from "@angular/core";
import {SearchItem} from "../optiSearchListComponent/optiSearchList.component";
import {TranslateService} from "@ngx-translate/core";
import {SearchListUtilsService} from "../services/searchlistUtils.service";
import {OptiSearchListService} from "../optiSearchListComponent/optiSearchList.service";
import { isNullOrUndefined } from "util";

@Component({
    selector: 'osl-input',
    templateUrl: './oslInput.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslInput.component.scss']
})

export class OslInputComponent implements OnChanges {
    @Output() onFilterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() onFilterFocus: EventEmitter<void> = new EventEmitter<void>();
    @Output() onArrowDown: EventEmitter<void> = new EventEmitter<void>();

    @Input() selectedItems: SearchItem[];
    @Input() valueProperty: string;
    @Input() includeSearch: boolean;
    @Input() includeFooter: boolean;
    @Input() isMultiSelected: boolean;
    @Input() selectedTitleCount: number;
    @Input() closed: boolean;
    @Input() isDisabled: boolean;
    @Input() markAsInvalid: boolean;
    @Input() placeholderTranslateKey = 'components.opti_search_list.SELECT';
    @Input() listItemsLength: number;
    @Input() isExternalDropDownMode = false;
    @Input() isServerSideMode = false;

    @ViewChild('filterField', {static: true}) filterField: ElementRef;
    @ViewChild('buttonTooltip', {static: true}) buttonTooltip: ElementRef;
    @ViewChild('buttonTooltipCalculator', {static: true}) buttonTooltipCalculator: ElementRef;

    public title = "";
    public label = "";
    public searchState: SearchState = SearchState.Placeholder;
    public searchStateTypes = SearchState;
    public placeholder: string = '';
    public iconName: string;
    public iconClassList: string[];

    private filterText: string;

    public constructor(private renderer: Renderer2,
                       private translate: TranslateService,
                       private searchListUtilsService: SearchListUtilsService,
                       private optiSearchListService: OptiSearchListService) {

    }

    public isPlaceHolder () {
        return this.selectedItems.length === 0;
    }

    public filterChanged(event: Event): void {
        let text = (event as any).target.value;
        this.doSetFilter(text);
    }

    private doSetFilter(value: string){
        this.filterText = value;
        this.onFilterChanged.emit(value);
        this.setSearchState(this.closed, this.includeSearch, value);
    }

    public filterFocused(): void {
        if (this.isDisabled && !this.includeFooter) {
            return;
        }

        if (!this.closed) {
            this.focus();
            return;
        }

        this.onFilterFocus.emit();
        // we want to update state only after onFilterFocus actually handles the event, otherwise list get closed when clicking icon.
        if (!this.isDisabled && this.includeSearch){
            setTimeout(() => {
                this.setSearchState(this.closed, this.includeSearch);
                this.setPlaceholder(this.isDisabled, this.searchState, this.placeholderTranslateKey);
            }, 0);
        }
    }

    public onIconClicked() {
        if (this.searchState === SearchState.Searching) {
            this.clearFilter(); 
        }
    }

    private clearFilter() {
        this.doSetFilter('');
        this.clearInputValue();
    }

    private shouldShowTitle() {
        const tooltip = this.buttonTooltip.nativeElement;
        const tooltipCalculator = this.buttonTooltipCalculator.nativeElement;

        return (tooltipCalculator.offsetWidth >= tooltip.offsetWidth) ||
            (tooltipCalculator.offsetHeight >= (tooltip.offsetHeight + 3));
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.closed && !changes.closed.currentValue){
            this.filterField.nativeElement.focus();
        }

        if (changes.closed || changes.includeSearch){
            this.setSearchState(this.closed, this.includeSearch);
        }

        if (changes.closed && !isNullOrUndefined(changes.closed.previousValue) && changes.closed.currentValue){
            if (this.filterText) {
                this.clearFilter();
            }
        }

        this.setPlaceholder(this.isDisabled, this.searchState, this.placeholderTranslateKey);

        if (!changes.selectedItems || !changes.selectedItems.currentValue) {
            return;
        }
        const selectedItems = changes.selectedItems.currentValue;
        const searchItems = this.searchListUtilsService.filterOnlySearchItems(selectedItems);
        this.setLabel(searchItems);

        // we need to wait for label to be rendered as we depended on its size to determine if we should set tooltip or not.
        setTimeout(() => {
            this.setTooltip(searchItems);
        });
    }

    private setLabel(searchItems){
        if (searchItems.length === 0) {
            this.label = '';
        } else if (searchItems.length === 1) {
            this.label = searchItems[0][this.valueProperty];
        } else {
            this.label = this.getLabel(searchItems);
        }
    }

    private setTooltip(searchItems){
        if (searchItems.length === 0) {
            this.title = '';
        } else if (searchItems.length === 1) {
            this.title = this.shouldShowTitle() ? searchItems[0][this.valueProperty] : '';
        } else {
            this.title = this.getTitle(searchItems);
        }
    }


    private getLabel(searchItems: SearchItem[]) {
        if (this.isServerSideMode) {
            return this.translate.instant('components.opti_search_list.SELECTED_SUMMARY_SERVER', { selected: searchItems.length });
        }

        return this.translate.instant('components.opti_search_list.SELECTED_SUMMARY', {selected: searchItems.length, listItemsLength: this.listItemsLength })
    }

    private getTitle(searchItems: SearchItem[]) {
        const selectedTexts = searchItems.map(item => item[this.valueProperty]);
        const topSelectedText = selectedTexts.slice(0, this.selectedTitleCount);
        const remainingCount = selectedTexts.length - topSelectedText.length;
        const remainingText = this.translate.instant('components.opti_search_list.AND_MORE', {count: remainingCount});
        if (remainingCount > 0) {
            topSelectedText.push(remainingText);
        }
        return topSelectedText.join(',\n');
    }

    public onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 9 /*Tab*/ || event.keyCode === 27 /*Esc*/) {
            this.optiSearchListService.closeGridSubject.next();
            return;
        }
        
        if (this.closed && event.keyCode === 13 /*Enter*/) {
            this.filterFocused();
            event.stopPropagation();
            event.preventDefault();
        } else if (event.keyCode === 40) {
            this.onArrowDown.emit();
        }

        if (this.isReadonly(this.searchState)) {
            return false;
        }
    }

    private isReadonly(searchState: SearchState): boolean {
        return searchState === SearchState.Placeholder;
    }

    private setPlaceholder(isDisabled: boolean, searchState: SearchState, placeholderTranslateKey: string) {
        if (isDisabled) {
            this.placeholder = '';
            return;
        }

        if (searchState === SearchState.Focused) {
            this.placeholder = 'Quick find';
            return;
        }


        this.placeholder = placeholderTranslateKey ? this.translate.instant(placeholderTranslateKey) : '';
    }

    private setSearchState(isClosed: boolean, includeSearch: boolean, searchText?: string) {
        if (isClosed) {
            this.searchState = SearchState.Placeholder;
        } else if (!isClosed && !includeSearch) {
            this.searchState = SearchState.Placeholder;
        } else if (searchText && searchText.length > 0){
            this.searchState = SearchState.Searching;
        } else {
            this.searchState = SearchState.Focused;
        }

        this.setSearchStateIcon(this.searchState);
    }

    private setSearchStateIcon(searchState: SearchState) {
        switch (searchState) {
            case SearchState.Placeholder: 
                this.iconName = 'arrow_drop_down';
                this.iconClassList = [];
                break;
            case SearchState.Focused: 
                this.iconName = 'search';
                this.iconClassList = ['searchIcon'];
                break;
            case SearchState.Searching: 
                this.iconName = 'close';
                this.iconClassList = ['clearIcon', 'btn', 'btn-link'];
                break;
        }
    }

    public focus() {
        this.filterField.nativeElement.focus();
    }

    public dispatchTabEvent() {
        this.filterField.nativeElement.dispatchEvent(new KeyboardEvent('keydown', {bubbles: true, key: 'Tab', code: 'Tab',
            location: 0 }));
    }
    private clearInputValue() {
        this.renderer.setProperty(
            this.filterField.nativeElement,
            'value',
            ''
        );
    }
    
}

export enum SearchState {
    Placeholder,
    Focused,
    Searching
}