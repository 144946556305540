import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsTransitionalModule } from './components_transitional.module';

//import { RealtimeProfileDataManagerComponent} from "../features/settings/realTimeProfileDataManager/realTimeProfileDataManager.component";

function getGtmService(i) {
	return i.get('gtmDataLayerService');
}

@NgModule({
	imports: [
		CommonModule,
		ComponentsTransitionalModule
	],
	providers: [
	],
	declarations: [
	],
	exports: [
		ComponentsTransitionalModule
	],
	entryComponents: [
	]
})
export class ComponentsModule {
}
