import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IModalButtonConf, OptiLogicModalOptions, OptiLogicModalService } from "../components/optiLogicModal/optiLogicModal.service";
import { IGranularKPIFields } from "../features/customer360/customer360.models";
import { HttpService } from "./optimove-http/optimove-http.model";

@Injectable()
export class AddKPIsService {
    private baseUrl: string = '/Customer360';

    constructor(private readonly http: HttpService,
        private translate: TranslateService,
        private modalService: OptiLogicModalService) { }

    public getCustomerProfileGranularFields(): Observable<IGranularKPIFields[]> {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomerProfileGranularFields`)
            .pipe(catchError(error => of(error)))
            .pipe(
                map((res: DefaultAjaxResponse) => {
                    return res.Data as IGranularKPIFields[];
                })
            );
    }

    public addCampaignKpisField(request: IGranularKPIFields): Observable<string> {
        return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/AddCampaignKpisField`,
            {
                sourceFieldName: request.FieldName,
                tooltip: request.Tooltip,
                actionAlias: request.ActionAlias,
                avgAlias: request.AverageAlias,
                format: request.Format
            })
            .pipe(catchError(error => of(error)))
            .pipe(map((res: DefaultAjaxResponse) => res.Data));
    }

    public getMinDateForKpiUpdate(campaignKPI: string): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/GetMinDateForKpiUpdate?campaignKPI=${campaignKPI}`)
            .pipe(catchError(error => of(error)));
    }

    public saveRetroActiveCampaignKpiCalculationRequest(userName: string, kpi: string, date: string): Observable<any> {
        return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/saveRetroActiveCampaignKpiCalculationRequest`,
            { userName, kpi, date })
            .pipe(catchError(error => of(error)))
            .pipe(map((res: DefaultAjaxResponse) => res));
    }

    public showIsRecalculationNeededModal(date: string, kpiId: string) {
        return this.openModalLogicMessage(
            'Add Campaign KPI',
            '<p>Campaign KPI succesfully added!</p><p>Data available for retroactive calculation of this campaign KPI is from ' + date + '</p><p>Would you like to run a retroactive update for this campaign KPI?</p>',
            "sm",
            [
                {
                    class: "btn-primary",
                    label: this.translate.instant("general.OK"),
                    action: async () => {
                        await this.saveRetroActiveCampaignKpiCalculationRequest(localStorage.getItem('userId'), kpiId, date).toPromise();
                    },
                },
                {
                    class: "btn-primary",
                    label: this.translate.instant("general.CANCEL"),
                    action: () => { },
                },
            ]
        );
    }

    private openModalLogicMessage(
        title: string,
        message: string,
        size: string,
        buttons: IModalButtonConf[]) {
        return this.modalService.openOptiLogicModal(
            size,
            {
                title: title,
                html: message,
                buttons: buttons,
            },
            <OptiLogicModalOptions<any>>{
                ignoreBackdropClick: true,
                backdrop: 'static',
                keyboard: false,
                notNeedToCloseOnRouteChange: true
            }
        );
    }
}