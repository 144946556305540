import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { SsmService } from "../../../../services/ssm.service";
import { AttributeFamily } from "../attributesList.enums";
import { GranularAttribute, IAttributeService } from "../attributesList.model";
import { AttributeConfiguration, AttributeLog, BaseAttribute } from './../attributesList.model';

@Injectable()
export class AttributesManagerService implements IAttributeService {
    baseUrl: string = '/AttributesManager';
    public isAttributeEditorValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isDailyCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private profileAttributes: BaseAttribute[] = [];
    private attributeConfiguration: AttributeConfiguration;
    private ssm: any;

    constructor(private readonly httpClient: HttpService, ssmService: SsmService) {
        this.ssm = ssmService.getSSM();
        this.getDataUpdateStatus().subscribe(x => {
            this.isDailyCompleted.next(!x.isRunning);
        });
    }

    // ===== INTERFACE =====
    public getAttributes( fromClient?: boolean, families: AttributeFamily[] = ["ActivityHistory", "FormulaBased", "Conditional", "Regular", "PurchaseHistory"]): Observable<BaseAttribute[]> {
        return !fromClient
        ? this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/GetAttributes`, { types: families })
        .pipe(map((res: DefaultAjaxResponse) => {
            this.profileAttributes = res.Data;
            return res.Data;
        }))
        : of(this.profileAttributes)
    }

    public updateAttribute(attribute: BaseAttribute, family: AttributeFamily): Observable<DefaultAjaxResponse> {
        const body = { attributeJSON: JSON.stringify(attribute), type: family };
        return this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/UpdateAttribute`, body);
    }

    public deleteAttribute(realFieldName: string, family: AttributeFamily): Observable<DefaultAjaxResponse> {
        const body = { realFieldName, type: family };
        return this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/RemoveAttribute`, body);
    }
    
    public publishAll(families: AttributeFamily[] = ["ActivityHistory", "FormulaBased", "Conditional"]): Observable<DefaultAjaxResponse> {
        const body = { types: families };
        return this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/Publish`, body);
    }

    public saveAttribute(attribute: BaseAttribute, family: AttributeFamily): Observable<DefaultAjaxResponse> {
        const body = { attributeJSON: JSON.stringify(attribute), type: family };
        return this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/SaveAttribute`, body);
    }

    public getCountOfCreatedAttributes(): number {
        return this.profileAttributes.filter(x => ["calculated", "customer", "conditional"].includes(x.Type)).length;
    }

    public getLastActivity(realFieldName: string): Observable<AttributeLog> {
        const url = `/AttributesManager/GetLastActivity?realFieldName=${realFieldName}`;
        return this.httpClient.get<AttributeLog>(url);
    }

    // ===== PROFILE =====
    public addAttributeToProfile(attr: any): void {
        this.profileAttributes.push(attr);
    }

    public deleteAttributeFromProfile(attributeName: string): void {
        this.profileAttributes = this.profileAttributes.filter(attr => attr.Name != attributeName);
    }

    public searchInProfile(attrName: string) {
        return this.profileAttributes.find(x => x.Name === attrName);
    }

    public numberInProfileByDisplayName(attrName: string) {
        return this.profileAttributes.filter(x => x.DisplayName === attrName).length;
    }

    public async updateCustomerAttributes() {
        await this.ssm.getGenericAsync(this.ssm.Resx.CustomerAttributesEnums, true);
        await this.ssm.getGenericAsync(this.ssm.Resx.CustomerAttributes, true);
    }
    // ====================

    // ===== purchase =====

    public IsPurchaseHistoryMultiAttributeFlow() {
        return this.httpClient.get<DefaultAjaxResponse>(`${this.baseUrl}/IsPurchaseHistoryMultiAttributeFlow`, null);
    }

    // ====================
    // ===== customer =====
    public getGranularCustomerAttributes(): Observable<GranularAttribute[]> {
        return this.httpClient.get<DefaultAjaxResponse>(`../TargetGroups/GetCustomerGranularAttributesData`, {})
        .pipe(map((res: DefaultAjaxResponse) => {
            const response = res.Data.CustomerGranularAttributes;
            return Object.keys(response).map(key => response[key] as GranularAttribute);
        }));
    }
    
    public getLastPublishTime(): Observable<DefaultAjaxResponse> {
        const url = `${this.baseUrl}/GetLastPublishTime`;
        return this.httpClient.get<DefaultAjaxResponse>(url, {});
    }

    public getAttributeConfiguration():Observable<AttributeConfiguration> {
        const url = `${this.baseUrl}/GetAttributeConfiguration`;

        return this.attributeConfiguration !== undefined && Object.keys(this.attributeConfiguration).length > 0 ? 
            of(this.attributeConfiguration) : this.httpClient.get<DefaultAjaxResponse>(url, {})
                                                            .pipe(map((res: DefaultAjaxResponse) => {
                                                                this.attributeConfiguration = res.Data;
                                                                return this.attributeConfiguration;
                                                            }));
    }

    // ==== condition ====
    
    public getDataUpdateStatus(): Observable<any> {
        const url = '/Main/getDataUpdateStatus';
        return this.httpClient.get<DefaultAjaxResponse>(url, {});
    }

    // ===== list ====
    public getInternalFieldsList(): Observable<DefaultAjaxResponse> {
        const url = `${this.baseUrl}/GetInternalFields`;
        return this.httpClient.get<DefaultAjaxResponse>(url, {});
    }
}
