<div class="search-panel">
  <div class="back-button">
    <button class="btn-link header-action validate-btn" (click)="backClick()">
      <i class="material-icons error-material-icon md-18">arrow_back</i>
      <span>
        {{ "general.BACK" | translate }}
      </span>
    </button>
  </div>
  <div class="input-group input-search">
    <input
      class="translation-search-text form-control"
      [(ngModel)]="searchText"
      [placeholder]="'features.manage_templates.optimail.searchTranslationPanel.SEARCH_KEY' | translate"
      (ngModelChange)="onSearchTextChange($event)" />
    <span class="input-search-label material-icons search-text" (click)="clearSearch()">{{ searchText ? "close" : "search" }}</span>
  </div>
  <div class="tags-loading-container" *ngIf="isSearching">
    <div class="ag-grid-loader-container">
      <span class="click-loader ag-grid-loader-wrapper">
        <span class="loader ag-grid-loader"></span>
      </span>
    </div>
    <div class="loading-message">{{ "features.manage_templates.optimail.searchTranslationPanel.SEARCHING" | translate }}</div>
  </div>
  <div class="no-search-res" *ngIf="!isAnySearchResult() && !isSearching && searchText.length > 0">
    <p class="no-search-text">{{ "features.manage_templates.optimail.searchTranslationPanel.NO_STRING_KEYS" | translate }}</p>
  </div>
  <div class="initial-state" *ngIf="searchText.length === 0">
    <p class="search-existing">{{ "features.manage_templates.optimail.searchTranslationPanel.EXISTING_STRINGS" | translate }}</p>
    <p class="use-translated">{{ "features.manage_templates.optimail.searchTranslationPanel.TRANSLATED_STRINGS" | translate }}</p>
  </div>
  <div class="search-res" *ngIf="isAnySearchResult() && !isSearching && searchText.length > 0">
    <div *ngFor="let fileNode of this.searchResult | keyvalue">
      <div class="file-name-wrapper">
        <p [title]="fileNode.key" class="file-name">{{ fileNode.key }}</p>
      </div>
      <div *ngFor="let translation of fileNode.value">
        <div (click)="copyTanslation(translation)" class="match-file-wrapper">
          <p [title]="translation.text" class="match-file">{{ translation.key }}</p>
          <p *ngIf="clickedTanslation?.key === translation.key" class="copied">
            {{ "features.manage_templates.optimail.searchTranslationPanel.COPIED" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
