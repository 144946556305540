import { Customer360PurchaseHistoryService } from '../../services/customer360.purchaseHistory.service';
import { PurchaseHistoryRowData } from '../../customer360.models';
import { ColumnApi, GridApi, GridOptions } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ModalOptions } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";
import { ActivityAttribute, IAnalysisRange } from '../../customer360.models';
import { purchaseHistoryTranslateKeys } from '../../customer360.translate.key';
import { Customer360DataService } from '../../services/customer360.data.service';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { SelectAttributesModalComponent } from './selectAttributesModal/selectAttributesModal.component';
import { isUndefined } from 'angular';
import moment from 'moment';
@Component({
    selector: 'purchase-history',
    templateUrl: './purchaseHistory.component.html',
    styleUrls: ['./purchaseHistory.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {

    @Input() clientCustomerId: string;
    @Output() onDataLoaded = new EventEmitter<boolean>();

    public attributesToSelect: ActivityAttribute[] = [];
    public selectedAttributes: ActivityAttribute[] = [];
    public purchaseHistoryData: PurchaseHistoryRowData[] = [];

    public gridOptions: GridOptions | undefined;
    public gridApi: GridApi | undefined;
    public gridColumnApi: ColumnApi | undefined;

    public translateKeys = purchaseHistoryTranslateKeys;
    public rangeUrlParametersPrefix = "PH";
    public tableWidth: number = 1122;
    public selectedAnalysisRange: IAnalysisRange;
    public isTableLoading: boolean;
    public areNoAttributesSelected: boolean;

    private date = moment().format('YYYY-MM-DD');
    private siteName = sessionStorage['SiteName'].replace(/"/g, "");

    constructor(
        private dataService: Customer360DataService,
        private optiLogicModalService: OptiLogicModalService,
        private translate: TranslateService,
        private purchseHistoryService: Customer360PurchaseHistoryService
    ) { }

    async ngOnInit(): Promise<void> {
        this.isTableLoading = true;
        await this.GetAttributes();
        if (!this.areNoAttributesSelected) {
            await this.initGridOptions(this.selectedAttributes);
        }
        else {
            this.isTableLoading = false;
        }
    }

    public async onAnalysisRangeChanged(range: IAnalysisRange) {
        this.selectedAnalysisRange = range;
        this.isTableLoading = true;
        await this.loadPurchaseHistory();
    }

    private initGridOptions(selectedAttributes: ActivityAttribute[], preLoadFunc: Function = () => { }): Promise<void> {
        return new Promise((resolve, reject) => {
            this.gridOptions = <GridOptions>{
                columnDefs: this.purchseHistoryService.createColumnDefs(selectedAttributes, this.tableWidth),
                headerHeight: 30,
                rowData: [],
                rowHeight: 44,
                onGridReady: async (params) => {
                    const isFirstGridInit = !this.gridApi && !this.gridColumnApi;
                    this.gridApi = params.api;
                    this.gridColumnApi = params.columnApi;
                    if (isFirstGridInit || (!this.purchaseHistoryData || this.purchaseHistoryData.length === 0)) {
                        preLoadFunc();
                        await this.loadPurchaseHistory();
                    }
                    else {
                        this.gridApi.setRowData(this.purchaseHistoryData);
                    }
                    resolve();
                },
                overlayNoRowsTemplate: `<div class="text-center text-muted">
                    <h1>${this.translate.instant(this.translateKeys.EMPTY_TABLE_MESSAGE)}</h1>
                </div>`,
            }
        });
    }

    private loadPurchaseHistory(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.clientCustomerId && this.selectedAnalysisRange && this.selectedAttributes && this.gridApi) {
                this.dataService.GetPurchaseHistoryBetweenDates(this.clientCustomerId, this.selectedAnalysisRange)
                    .pipe(first()).subscribe(res => {
                        if (res.IsSuccess && res.Data?.length > 0) {
                            this.purchaseHistoryData = res.Data;
                            this.gridApi.setRowData(this.purchaseHistoryData);

                            resolve();
                            this.isTableLoading = false;
                        } else {
                            this.gridApi.setRowData([]);
                            resolve();
                            this.isTableLoading = false;
                        }
                    });
            }
            else if (this.gridApi) {
                this.gridApi.setRowData([]);
                resolve();
                this.isTableLoading = false;
            }
            else {
                this.isTableLoading = false;
                resolve();
            }
        });
    }

    private GetAttributes(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dataService.getPurchaseHistoryAttributes().pipe().subscribe(attributes => {
                if (attributes.IsSuccess) {
                    this.attributesToSelect = this.createActivityAttributes(attributes.Data);
                    this.selectedAttributes = this.getAttributesFromLocalStorage();
                }

                this.areNoAttributesSelected = this.selectedAttributes.length === 0;
                resolve();
            });
        })
    }

    public openSelectActivityAttributesModal() {
        this.optiLogicModalService.open(
            SelectAttributesModalComponent,
            'md',
            <ModalOptions<any>>{
                ignoreBackdropClick: false,
                initialState: {
                    attributesToSelect: this.attributesToSelect,
                    selectedAttributes: this.selectedAttributes,
                    onDone: (attributes: ActivityAttribute[]) => { this.onSelectAttributes(attributes) },
                }
            });

    }

    private async onSelectAttributes(attributes: ActivityAttribute[]) {
        if (attributes.length > 0 && this.areNoAttributesSelected) {
            this.areNoAttributesSelected = false;
            this.isTableLoading = true;

            await this.initGridOptions(attributes, () => {
                this.isTableLoading = true;
                this.refreshselectedAttributes(attributes);
                this.updateAttributesInLocalStorage();
            });
        }
        else {
            this.isTableLoading = true;
            this.refreshselectedAttributes(attributes);
            this.updateAttributesInLocalStorage();
            await this.loadPurchaseHistory();
        }
    }

    private refreshselectedAttributes(attributes: ActivityAttribute[]) {
        this.selectedAttributes = attributes;
        this.gridApi.setColumnDefs(this.purchseHistoryService.createColumnDefs(this.selectedAttributes, this.tableWidth));
        this.gridApi.sizeColumnsToFit();
    }

    private updateAttributesInLocalStorage() {
        let attributes: string[] = this.selectedAttributes.map(x => x.RealFieldName);
        this.dataService.setToLocalStorage("purchaseHistoty.selectedAttributes", JSON.stringify(attributes));
    }

    private getAttributesFromLocalStorage(): ActivityAttribute[] {
        const attributesJSON = this.dataService.getFromLocalStorage("purchaseHistoty.selectedAttributes");
        let selectedAttributes: ActivityAttribute[] = [];

        if (attributesJSON) {
            const attributes: string[] = JSON.parse(attributesJSON);
            selectedAttributes = this.attributesToSelect.filter(x => attributes.includes(x.RealFieldName));
        }

        return selectedAttributes;
    }

    //=== CSV export ===

    public get hasListData(): boolean {
        return this.purchaseHistoryData?.length > 0;
    }

    public get attributesListCsvFileName() {
        return `Optimove ${this.siteName} CustomerID ${this.clientCustomerId} Purchase History Details ${this.date}.csv`;
    }

    public get attributesListCsvFileHeaders(): string[] {
        if (this.purchaseHistoryData?.length > 0) {
            return Object.keys(this.purchaseHistoryData[0]);
        } else {
            return [];
        }
    }

    public attributesListCsvSerializerFunc = function (data: any) {
        let csvRowParts = [];
        for (let prop of Object.keys(data)) {
            let value = isUndefined(data[prop]) || data[prop] === null ? 'NA' : data[prop];

            if (prop === 'PurchaseDate') {
                value = moment(value).format('YYYY-MM-DD');
            }

            this.pushWithQuotes(csvRowParts, value);
        }
        return `${csvRowParts.join(',')}`;
    }.bind(this);

    public pushWithQuotes(array, value) {
        if (!value.toString().includes('"'))
            array.push('"' + value + '"');
        else
            array.push(value);
    };

    private createActivityAttributes(attrs: ActivityAttribute[]): ActivityAttribute[] {
        var attributes = [];
        attrs.forEach((attr, index) => {
            var attr: ActivityAttribute = {
                IsChecked: false,
                ActivityTypeId: 0,
                AttributeId: index + 1,
                AliasAttributeName: attr.AliasAttributeName,
                RealFieldName: attr.RealFieldName,
                ToolTipInfo: attr.ToolTipInfo,
                AttributeDataType: attr.AttributeDataType,
                AttributeFormat: attr.AttributeFormat,
                HasValuesList: false,
                AttributePriority: attr.AttributePriority,
                AttributeType: 0
            };
            attributes.push(attr);
        });
        return attributes;
    }
}