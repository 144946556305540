import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExchangeTokenResult } from '../models/exchangeTokenResult';
import { HttpService } from './optimove-http/optimove-http.model';
import { OptimoveHttpService } from './optimove-http/optimove-http.service';

@Injectable()
export class RedirectToAuthService {
	public static readonly authCodeKey = 'authCode';

	constructor(private readonly router: Router, private readonly cookieService: CookieService, private httpService: HttpService) {
		const optimoveHttpService = httpService as OptimoveHttpService;
		optimoveHttpService.onLogin$.subscribe(() => {
			this.login();
		});
		
		optimoveHttpService.onSessionExpired$.subscribe(() => {
			this.sessionExpired();
		});
	}

	sessionExpired() {
		const urlString = this.getRedirectUrl('ErrorPages/SessionExpired.html');
		window.location.assign(urlString);
	}

	login() {
		const urlString = this.getRedirectUrl('Auth/Login');
		window.location.assign(urlString);
	}

	logout() {
		window.localStorage.removeItem('refreshToken');
		window.localStorage.removeItem('accessToken');
		this.cookieService.delete('accessToken');
		window.sessionStorage.clear();		
    	sessionStorage.setItem('loggedOutOfPage', location.hash);
		this.cookieService.delete('MissionControlState');
		const urlString = this.getRedirectUrl('Auth/Logout', );
		window.location.assign(urlString);
	}

	private getRedirectUrl(pathname: string) {
		const url: URL = new URL(HttpService.baseApiUrlString);

		const fullParams = {};
		fullParams['clientServerHost'] = `${window.location.protocol}//${window.location.host}`;
		fullParams['redirectPage'] = window.location.hash.substring(1);
		const userName = window.localStorage.getItem('email');
		// (michael_v): in backend we revoke the refresh token on logout by email. Previously userName was resolved by accessToken,
		// but as we clear it now from local storage - it will not be passed as Authorization header, so we need to pass it explicitly
		fullParams['userName'] = userName

		url.pathname = pathname;
		url.search = new URLSearchParams(fullParams).toString();

		return url.toString();
	}

	public exchangeTokenAndInitLoginParameters(authCode: string): Observable<ExchangeTokenResult> {
		return this.httpService.post<AjaxResponse<ExchangeTokenResult>>('Auth/ExchangeToken', { authCode: authCode  }).pipe(
			map((res) => res.data),
			tap((exchangeTokenRes) => {
				localStorage.setItem('accessToken', exchangeTokenRes.accessToken);
				localStorage.setItem('refreshToken', exchangeTokenRes.refreshToken);
				sessionStorage.setItem('tenantId', exchangeTokenRes.tenantId);
				sessionStorage.setItem('email', exchangeTokenRes.email);
				sessionStorage.setItem('userId', exchangeTokenRes.email);
				sessionStorage.setItem('clientUniqueId', exchangeTokenRes.clientUniqueId);
			})
		)
	}

	public isLoggedIn(): Observable<boolean> {
		return this.httpService.get<AjaxResponse<boolean>>('Main/IsLoggedIn').pipe(
			map((res) => res.data)
		);
	}
}
