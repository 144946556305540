<opti-logic-modal [modalConf]="modalConf" class="template-modal">
    <div *ngIf="templateDetails && personalizedTemplate" 
        custom-header 
        class="header">
        <h2 class="template-title">
            {{ templateDetails.templateName }}
        </h2>
        <button *ngIf="showTemplateLink"
                data-qa-id="GoToTranslateBtn" 
                class="export-btn btn-link"
                (click)="goToTemplate()">
            <i class="material-icons">open_in_new</i>
            <span>
                {{ translateKeys.TEMPLATE_LINK | translate }}
            </span>
        </button>
    </div>
    <div class="template-container" 
        *ngIf="!personalizedTemplate" >
        <opti-loading 
            [show]="!personalizedTemplate" 
            [size]="'md'"
        ></opti-loading>
    </div>
    <div class="template-container" 
        *ngIf="personalizedTemplate">
        <div class="template" 
            [innerHtml]="personalizedTemplate | safe : 'html'">
        </div>
    </div>
</opti-logic-modal>
