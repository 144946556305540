<div class="columns-mapping-container">
    <div class="attributes">
        <opti-select 
            [(ngModel)]="selectedAttribute.selectedAttributeRealFieldName" 
            [placeholder]="selectAttributePlaceholder" 
            [items]="attributeItems"
            [disabled]="isSelectionDisabled"
            (ngModelChange)="onSelectedAttributeChanged($event)"
            class="attribute-selector">
        </opti-select>
    </div>
    <div class="file-content">
        <div class="file-header">
            <span class="elipsis-span header-title" title="{{attribute.columnHeader}}">{{attribute.columnHeader}}</span>
            <span *ngIf="selectedAttribute.isValid" class="material-icons text-success validation-icon">check_circle</span>
            <span *ngIf="selectedAttribute.isValid !== null && !selectedAttribute.isValid" class="material-icons text-issue validation-icon" title="{{warningTitle}}">report_problem</span>
        </div>
        <div class="file-rows">
            <div class="file-row" *ngFor = "let att of attribute.columnValues; index as i;">
                <span class="attribute_counter">{{i+1}}</span><span class="attribute_value elipsis-span" title="{{att}}">{{att}}</span>
            </div>
            <a #deleteButton class="btn btn-link btn-link-default delete-icon" 
                [ngClass]="{'hidden': !isDeleteAvailible}" 
                [attr.disabled]="isDeleteButtonDisabled">
                    <i [ngClass]="{'disabled': isDeleteButtonDisabled}" class="material-icons" (click)="cleanAttributeSelection($event)">delete</i>
            </a>
        </div>
    </div>
</div>