import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubMethodListItem } from '../moveTemplateModal/moveTemplateModal.models';
import { TemplateTreeItem } from '../../../models/templateResponse';
import { Dictionary } from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { SearchContextService } from '../../../services/searchContext.service';
import { ActivatedRoute } from '@angular/router';
import { SubMethodService } from '../../../services/subMethodService';
import { FolderService } from '../../../services/folder.service';
import { SearchListConfig } from '../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { OslValueType } from '../../../../../components/optiSearchList/models/oslValueType.enum';
import { SubMethod } from '../../../models/metadataResponse';
import { combineLatest } from 'rxjs';
import { TemplateContextService } from '../../../services/templateContext.service';

@Component({
	selector: 'move-lists-component',
	templateUrl: './moveListsComponent.component.html',
	styleUrls: ['./moveListsComponent.component.scss']
})
export class MoveListsComponentComponent implements OnInit {
	@Output() selectedFolderIdEmitter: EventEmitter<number> = new EventEmitter<number>();
	@Output() selectedSubMethodIdEmitter: EventEmitter<number> = new EventEmitter<number>();
	@Input() clickedFolderId: number;
	@Input() subMethodsDisabled: boolean;
	@Input() subMethodId: number;

	subMethods: SubMethodListItem[] = [];
	selectedSubMethodId: number;

	foldersList: TemplateTreeItem[] = [];
	foldersListPerSubMethod: Dictionary<TemplateTreeItem[]> = {};
	selectedFolderId: number | null;

	constructor(
		private translateService: TranslateService,
		private searchContextService: SearchContextService,
		private templateContextService: TemplateContextService,
		private route: ActivatedRoute,
		private subMethodService: SubMethodService,
		private folderService: FolderService
	) {}

	ngOnInit(): void {
		this.initFormData();
	}

	public subMethodChanged(value: number): void {
		this.selectedSubMethodIdEmitter.emit(value);
		this.selectedSubMethodId = value;
		this.selectedFolderId = null;
		this.selectedFolderIdEmitter.emit(null);
		this.refreshFoldersList();
	}

	public folderChanged(value: number): void {
		this.selectedFolderIdEmitter.emit(value);
		this.selectedFolderId = value;
	}

	public subMethodSearchConfig: SearchListConfig = {
		keyProperty: 'subMethodId',
		valueProperty: 'text',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	public folderSearchConfig: SearchListConfig = {
		keyProperty: 'id',
		valueProperty: 'text',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	private getSubMethodName(subMethod: SubMethod, channelId: number) {
		return this.translateService.instant(this.subMethodService.getSubMethodTypeText(subMethod.subMethodType, channelId));
	}

	private refreshFoldersList(): void {
		if (!this.selectedSubMethodId || !this.foldersListPerSubMethod[this.selectedSubMethodId]) return;

		this.foldersList = this.foldersListPerSubMethod[this.selectedSubMethodId];
		if (this.clickedFolderId) {
			this.selectedFolderId = this.clickedFolderId;
		} else {
			this.selectedFolderId = this.foldersList[0].id;
		}

		this.selectedFolderIdEmitter.emit(this.selectedFolderId);
	}

	private initFormData(): void {
		const brand$ = this.searchContextService.brand;
		const queryParams$ = this.route.queryParams;
		const channelId$ = this.templateContextService.channelId;

		combineLatest([brand$, queryParams$, channelId$]).subscribe(([brand, params, channelId]) => {
			const currentTypeId = this.subMethodId ?? params.typeId;
			if (!this.clickedFolderId && currentTypeId === params.typeId) {
				this.clickedFolderId = +params.folderId;
			}
			this.subMethods = brand.subMethods.map(
				(x) =>
					({
						text: this.getSubMethodName(x, +channelId),
						...x
					} as SubMethodListItem)
			);
			if (currentTypeId && this.subMethods.length > 0) {
				this.selectedSubMethodId =
					this.subMethods.find((x) => x.subMethodId == currentTypeId).subMethodId ?? this.subMethods[0].subMethodId;
				this.selectedSubMethodIdEmitter.emit(
					this.subMethods.find((x) => x.subMethodId == currentTypeId).subMethodId ?? this.subMethods[0].subMethodId
				);
			}

			this.foldersListPerSubMethod = this.subMethods.reduce((accumulator, current) => {
				const root = this.folderService.getRoot(current.subMethodId);
				let result = this.folderService.flatten([root]);
				accumulator[current.subMethodId] = result;
				return accumulator;
			}, {});

			this.refreshFoldersList();
		});
	}
}
