import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { FeatureFlag, FeatureFlagService } from '../../services/featureFlag.service';

@Injectable()
export class AudienceDiscoveryGuard implements CanActivate {

    constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

    canActivate(): Observable<boolean> {
        if (this.featureFlagService.isEnabled(FeatureFlag.AudienceDiscoveryPage)) {
            return of(true);
        } else {
            this.router.navigate(['**']);
            return of(false);
        }
    }

}
