<opti-logic-modal [modalConf]="modalConf">
    <div class="templates-breakdown">
        <div class="tb-action-channel">
            <channel-metrics-action-header [actionName]="actionName" [actionType]="actionType"></channel-metrics-action-header>
            <h5 class="left-offset">|</h5>
            <h5 class="left-offset">{{channelData.channelName}}</h5>
        </div>
        <table class="table table-bordered">
            <colgroup>
                <col class="column-template">
                <col class="column-metric" *ngFor="let metricName of allMetricsNames">
            </colgroup>
            <thead>
                <tr>
                    <th><h5>{{templateNameTranslationPath | translate}}</h5></th>
                    <ng-container *ngFor="let metricName of allMetricsNames">
                        <ng-template #popTemplate><div [innerHtml]="getMetricTooltip(metricName)"></div></ng-template>
                        <th><h5 [tooltip]="popTemplate">{{metricsTranslationPath + metricName | translate}}</h5></th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let templateData of channelData.templatesData">
                    <td class="template-name-with-preview">
                        <ng-container *ngTemplateOutlet="tdName;
                                      context: {
                                        imgSrc: templatePreviews[templateData.templateId],
                                        name: templateData.templateName
                                      }"></ng-container>
                    </td>
                    <td *ngFor="let metricName of allMetricsNames">
                        <div class="tb-values">
                            <div class="tb-percent" *ngIf="getMetricPercent(templateData, metricName); let p">
                                <span *ngIf="p < 0" class="emdash"></span>
                                <span *ngIf="p >= 0">
                                    {{ p!==specialValue ? (p | formatter:'0%':{shouldHandleSmallNumbers: true}) : (0 | formatter:'0%':{shouldHandleSmallNumbers: true}) }}
                                </span>
                            </div>
                            <div class="tb-number" *ngIf="getMetricValue(templateData, metricName); let v">
                                <p class="small no-margin" *ngIf="!mayNavigate(templateData, metricName)">
                                    {{ v!==specialValue ? (v | formatter:'0,') : (0 | formatter:'0,') }}
                                </p>
                                <span class="navigatable" *ngIf="mayNavigate(templateData, metricName)" (click)="onMetricClick(templateData, metricName)">
                                    {{ v!==specialValue ? (v | formatter:'0,') : (0 | formatter:'0,') }}
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</opti-logic-modal>

<ng-template #tdName let-imgSrc="imgSrc" let-name="name">
    <div class="template-name">
        <span class="template-name-name" [tooltip]="name">{{name}}</span>
    </div>
    <ng-template #tolTemplate>
        <div>
            <img [src]="imgSrc">
        </div>
    </ng-template>
    <div class="pull-right tb-preview" [tooltip]="imgSrc ? tolTemplate : null" placement="right">
        <i [attr.disabled]="!imgSrc ? 'disabled' : null" class="btn btn-link btn-link-default material-icons">remove_red_eye</i>
    </div>
</ng-template>
