<div class="wrapper" [ngClass]="{'disabled': isDisabled}" [tabindex]="0" (keydown)="onKeyDown($event)">
    <osl-item [showCheckbox]="true"
        [label]="showSelectedLabel"
        [isSelected]="isSelected"
        [disabled]="isDisabled"
        [isHeaderItem]="true"
        (onChange)="toggledIsSelected($event)"></osl-item>
    <span class="counter">
        {{ 'components.opti_search_list.OF' | translate:{'selected': selectedCount, 'total': allCount} }}
    </span>
</div>