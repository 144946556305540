import { Component, Input , ViewEncapsulation } from "@angular/core";
import { ActionType, CHANNEL_METRICS_TRANSLATION_ROOT } from "../../campaignAnalysis.models";

@Component({
    selector: 'channel-metrics-action-header',
    templateUrl: './channelMetricsActionHeader.component.html',
    styleUrls: ['./channelMetricsActionHeader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsActionHeaderComponent{
    @Input() actionName;
    @Input() actionType;

    actionTextKey: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'ACTION';

    getlabelActionOrderClass() {
        const actionTypeToIndex = {
            [ActionType.A]: 1,
            [ActionType.B]: 2,
            [ActionType.C]: 3,
            [ActionType.D]: 4,
            [ActionType.N]: 5
        }
        return `label-action-order-${actionTypeToIndex[this.actionType]}`;
    }
}