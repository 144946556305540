import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { OptiApiUser } from "../models/optiApiUser.model";
import { ApiRole } from "../models/apiRole.model";
import { AccessType } from "../models/accessType.model"
import { SearchListConfig } from "src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component";

@Component({
    selector: 'api-user-details',
    templateUrl: './apiUserDetails.component.html',
    styleUrls: ['./apiUserDetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApiUserDetails implements OnInit {
    public loading: boolean = true;
    public isSaving: boolean = false;
    public isPermanent: boolean = true;
    public emailFocus: boolean = true;
    public userNameFocus: boolean = true;
    public isWhenValuesHasOneOption: boolean;
    public rolesItems = [];
    public currAccessType = AccessType.Full;
    public AccessType = AccessType; 
    @Input() parentForm: FormGroup;
    @Input() userExistsMsg;
    public dropdownOslConfig: SearchListConfig = {
        keyProperty: "id",
        valueProperty: "name",
        isMultiSelect: false,
        itemNameTranslateKey: "name",
        placeholderTranslateKey: "Select"
    };
    public translateKeys = translateKeys;

    constructor() {
    }

    ngOnInit(): void {
        let role1: ApiRole = {
            id : 1,
            name: "ApiRole"
        }
        this.rolesItems.push(role1);
        this.isWhenValuesHasOneOption = this.isContainsOneItem(this.rolesItems);
    }

    private isContainsOneItem(itemList: any[]) {
        return itemList.length < 2;
    }

    public changeSelectedAccessType(accessType) : void {
        if (this.currAccessType !== accessType) {
            this.currAccessType = accessType;
        }
    }
    
    containsExistingUser(){
        return (this.userExistsMsg?.endsWith(this.parentForm.controls.userName.value) && (this.parentForm.controls.userName.value != "")) ? true : false;
    }

    buildNewApiUser() : OptiApiUser {
        let apiUser: OptiApiUser = {
                ApiRole: this.parentForm.controls.role.value[0].name,
                UserName: this.parentForm.controls.userName.value,
                NickName: this.parentForm.controls.userName.value,
                ContactEmail: this.parentForm.controls.email.value,
                LastLoginDate: '/Date(-62135596800000)/', // This user have never been logged in before
                UserId: null,
                Enabled: true
        }
        return apiUser;
    }

    get nameFormControl(): FormControl {
        return this.parentForm.get('userName') as FormControl;
    }

    get emailFormControl(): FormControl {
        return this.parentForm.get('email') as FormControl;
    }
}

const translateKeys = {
    userNamePlaceholder: 'features.user_settings.body.apiManagement.apiUserDetails.USER_NAME_PLACEHOLDER',
    userNameErrorMsg: 'features.user_settings.body.apiManagement.apiUserDetails.USER_NAME_ERROR_MSG',
    userNameCannotBeEmail: 'features.user_settings.body.apiManagement.apiUserDetails.USER_NAME_CANNOT_BE_EMAIL',
    userNameLimitLength: 'features.user_settings.body.apiManagement.apiUserDetails.USER_NAME_LIMIT_LENGTH',
    userNameNote: 'features.user_settings.body.apiManagement.apiUserDetails.USER_NAME_NOTE',
    contactEmailPlaceholder: 'features.user_settings.body.apiManagement.apiUserDetails.CONTACT_EMAIL_PLACEHOLDER',
    contactEmailErrorMsg: 'features.user_settings.body.apiManagement.apiUserDetails.CONTACT_EMAIL_ERROR_MSG',
    contactEmailNote: 'features.user_settings.body.apiManagement.apiUserDetails.CONTACT_EMAIL_NOTE',
    contactEmailValidTime: 'features.user_settings.body.apiManagement.apiUserDetails.CONTACT_EMAIL_VALID_TIME',

}