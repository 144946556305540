export interface TemplatesMetadata {
	channels: ChannelNode;
}
export interface EmailData {
	preDefinedEmailList: Root<EmailDataNode>;
	preDefinedReplyToEmailList: Root<EmailDataNode>;
}
export interface SuppressionList {
	preDefinedsuppressionGroupList: Root<SuppressionListNode>;
}

export interface Root<T> {
	[key: number]: T[];
}
export interface EmailDataNode {
	id: number;
	subMethodId: number;
	value: string;
}
export interface SuppressionListNode {
	id: number;
	value: string;
}
export interface ChannelNode {
	[key: number]: Channel;
}

export interface Channel {
	id: number;
	name: string;
	otherEditor: boolean;
	brands: Brand[];
}

export interface Brand {
	name: string;
	subMethods: SubMethod[];
}

export interface SubMethod {
	subMethodId: number;
	subMethodType: SubMethodType;
	executionMethodInnerID: string;
	defaultValues: { [id: string]: SubMethodDefaultValue };
}

export interface SubMethodDefaultValue {
	name: string;
	value: string;
}

export class SubMethodDefaultValueId {
	static readonly FROM_NAME = '2';
}

export enum SubMethodType {
	ScheduledCampaigns = 0,
	Transactional = 1,
	TriggeredCustomerCampaigns = 2,
	WebpagePopup = 3,
	Optipush = 4,
	TriggeredVisitorCampaigns = 5,
	ScheduledVisitorCampaigns = 6
}

export enum ExecutionChannel {
	Optipush = 29
}
