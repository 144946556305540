<div [@fadeIn] class="template-editor-master-panel">
  <div class="upper-master-panel-container">
    <div class="channel-title-container">
      <h1 [@fadeIn] class="channel-title">{{ selectedChannelTitle }}</h1>
    </div>
    <div class="brand-selection">
      <inline-select
        *ngIf="dropDownBrands && dropDownBrands.length > 1"
        [items]="dropDownBrands"
        [ngModel]="selectedBrand"
        (ngModelChange)="onSelectedBrandChanged($event)"
        [placeholder]="'general.CHANNELS' | translate"
        [required]="true"
        data-qa-id="brand-dropdown"></inline-select>
    </div>
    <div class="input-group input-search">
      <input
        class="template-search-text form-control"
        [(ngModel)]="searchText"
        [placeholder]="
          'features.manage_templates.header.labels.SEARCH'
            | translate
              : {
                  items:
                    (channelService.isWhatsApp(selectedChannel)
                      ? 'features.manage_templates.JOURNEY'
                      : 'features.manage_templates.TEMPLATE'
                    ) | translate
                }
        "
        (ngModelChange)="onSearchTextChange($event)" />
      <span class="input-search-label material-icons search-text" (click)="clearSearch()">{{ searchText ? "close" : "search" }}</span>
    </div>
  </div>
  <div id="drag-container" class="drag-container">
    <i class="material-icons" style="color: white">folder</i>
    <span class="drag-indication">1 Folder</span>
  </div>
  <div #masterPanel class="jstree-container" [ngClass]="{'allow-horizontal-scroll': isHorizontalScrollActive}">
    <tree-root
      #jsTree
      [nodes]="treeData"
      [options]="options"
      class="expand-tree"
      (initialized)="treeWasInitialized(jsTree)"
      data-qa-id="folder-tree">
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-full" [ngClass]="{'root-node-margin': node.data.parentId === -1 && node.data.id !== this.treeData[0].id}">
          <div *ngIf="node.data.parentId !== -1" class="drag-handle" [treeDrag]="node" [treeDragEnabled]="node.allowDrag()">
            <svg class="node-handle" xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
              <g id="Drag_Handler" data-name="Drag Handler" transform="translate(-32 -6)">
                <rect id="Rectangle_3234" data-name="Rectangle 3234" width="2.5" height="2.5" transform="translate(32 6)" fill="#cfd8dc" />
                <rect
                  id="Rectangle_3236"
                  data-name="Rectangle 3236"
                  width="2.5"
                  height="2.5"
                  transform="translate(32 10.5)"
                  fill="#cfd8dc" />
                <rect id="Rectangle_3238" data-name="Rectangle 3238" width="2.5" height="2.5" transform="translate(32 15)" fill="#cfd8dc" />
                <rect
                  id="Rectangle_3240"
                  data-name="Rectangle 3240"
                  width="2.5"
                  height="2.5"
                  transform="translate(32 19.5)"
                  fill="#cfd8dc" />
                <rect
                  id="Rectangle_3235"
                  data-name="Rectangle 3235"
                  width="2.5"
                  height="2.5"
                  transform="translate(37.5 6)"
                  fill="#cfd8dc" />
                <rect
                  id="Rectangle_3237"
                  data-name="Rectangle 3237"
                  width="2.5"
                  height="2.5"
                  transform="translate(37.5 10.5)"
                  fill="#cfd8dc" />
                <rect
                  id="Rectangle_3239"
                  data-name="Rectangle 3239"
                  width="2.5"
                  height="2.5"
                  transform="translate(37.5 15)"
                  fill="#cfd8dc" />
                <rect
                  id="Rectangle_3241"
                  data-name="Rectangle 3241"
                  width="2.5"
                  height="2.5"
                  transform="translate(37.5 19.5)"
                  fill="#cfd8dc" />
              </g>
            </svg>
          </div>
          <div
            (dragover)="options.actionMapping.mouse.dragOver(treeData, node, $event)"
            (drag)="options.actionMapping.mouse.drag(treeData, node, $event)"
            (dragend)="options.actionMapping.mouse.dragEnd(treeData, node, $event)"
            (dragleave)="options.actionMapping.mouse.dragLeave(treeData, node, $event)"
            data-id="{{ node.data.id }}"
            [ngClass]="{
              'hover-over-state': dragOverId === node.data.id && canDrop(this.folderService.findSubMethodByFolderId(node.data.id))
            }"
            (treeDrop)="node.onDrop($event)"
            [treeAllowDrop]="node.allowDrop"
            [allowDragoverStyling]="true"
            class="node-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused">
            <tree-node-expander class="node-expander" (click)="activateDisableHorizontalScroll()" [node]="node"></tree-node-expander>
            <div
              class="node-content-wrapper"
              [@fadeIn]
              (click)="node.mouseAction('click', $event)"
              (dblclick)="node.mouseAction('dblClick', $event)"
              (contextmenu)="node.mouseAction('contextMenu', $event)"
              [ngClass]="{'hover-over-state-node': dragOverId === node.data.id}">
              <div class="mainNode">
                <div class="title-container">
                  <i *ngIf="node.data.parentId || isAllTemplatesFolder(node.data, selectedChannel)" class="material-icons folder-icon">
                    folder
                  </i>
                  <span
                    class="name"
                    [@fadeIn]
                    [ngClass]="{'root-name': node.data.parentId === -1 && !isAllTemplatesFolder(node.data, selectedChannel)}"
                    title="{{ node.data.text }}"
                    [innerHtml]="
                      isAllTemplatesFolder(node.data, selectedChannel)
                        ? ('features.manage_templates.opti_tree.ALL_TEMPLATES'
                          | translate
                            : {
                                items:
                                  (channelService.isWhatsApp(selectedChannel)
                                    ? 'features.manage_templates.JOURNEYS'
                                    : 'features.manage_templates.TEMPLATES'
                                  ) | translate
                              })
                        : getFolderName(node.data, searchText)
                    "></span>
                </div>
                <div class="overflow-menu-container" (click)="$event.stopPropagation()">
                  <button #menuButton class="overflow-menu-btn" (click)="onTreeItemClick(menuButton, node)">
                    <i class="material-icons">more_vert</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </tree-root>
  </div>
  <div [style.visibility]="!showOverlay ? 'hidden' : 'visible'" #overlay class="overlay">
    <button
      *ngFor="let fmi of folderMenuItems"
      class="btn btn-link btn-link-default overflow-menu-item-btn ng-star-inserted overlay-button"
      (click)="fmi.callback()">
      <i class="material-icons overlay-icon">{{ fmi?.icon }}</i>
      <span class="overlay-text">{{ fmi?.text }}</span>
    </button>
  </div>
</div>
