import { Injectable } from "@angular/core";
import { SsmService } from "../../services/ssm.service";

@Injectable({
    providedIn: 'root'
})
export class TemplateImageProvider {

    private ssm: any;
    private campaignSettings: any;

    constructor(private ssmService: SsmService) {
        this.ssm = this.ssmService.getSSM();
        this.campaignSettings = this.ssm.GetGeneric(this.ssm.Resx.CampaignSettings);
    }

    checkImageExists(imageId, channelId, callback) {
        let host = document.location.origin;
        let templatesFolder = this.campaignSettings.templatesFolder;

        if (host.includes('localhost')) {
            host = 'https://tss-qa-suaposta.optimove.net';
            templatesFolder = 'Templates_TSS_QA/Optimove_TSS-QA-Suaposta';
        }

        const url = `${templatesFolder}/${channelId}/${imageId}.jpeg`;
        let img = new Image();
        img.onload = () => { callback(true, url); };
        img.onerror = () => { callback(false, null); };
        img.src = url;
    }
}
