import { Injectable } from '@angular/core';
import { TemplateDetails } from '../models/templateDetails';
import { TranslateService } from '@ngx-translate/core';
import { ManageTemplatesService } from './manageTemplates.service';
import { ErrorModalService } from './errorModal.service';
import { Observable, of, Subject } from 'rxjs';
import { FolderService } from './folder.service';
import { RelationType, TemplateTreeItem } from '../models/templateResponse';
import { ChannelService } from './channel.service';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import { mergeMap } from 'rxjs/operators';
import { FolderTemplatePreview } from '../models/folderTemplate';

export interface DuplicatedPayload {
	templateId: number;
	otherEditor: boolean;
	subMethodId: number;
	isBatchDuplication: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class TemplateDuplicateService {
	private $duplicating: Subject<boolean> = new Subject<boolean>();
	public duplicating: Observable<boolean> = this.$duplicating.asObservable();
	private $duplicated: Subject<DuplicatedPayload> = new Subject<DuplicatedPayload>();
	public duplicated: Observable<DuplicatedPayload> = this.$duplicated.asObservable();
	public duplicatedTemplateId: number;
	public duplicatedTemplateIds: number[];
	private lastDuplicatedId: number;

	constructor(
		private translate: TranslateService,
		private manageTemplatesService: ManageTemplatesService,
		private folderService: FolderService,
		private channelService: ChannelService,
		private errorModalService: ErrorModalService,
		private readonly http: HttpService
	) {}

	duplicate(
		templateId: number,
		channelId: number,
		subMethodId: number,
		tenantId: string,
		brandId: string,
		folderId: number,
		isBatch: boolean = false
	): void {
		this.$duplicating.next(true);
		if (this.channelService.isOptimobile(channelId)) {
			this.manageTemplatesService.getManageTemplatesUrl().subscribe((templatesUrl) => {
				const duplicateUrl = this.getMobileDuplicateUrl(templatesUrl.optimobileUrls, channelId);
				let formData: FormData = new FormData();
				formData.append('templateIds[]', templateId.toString());
				formData.append('tenantId', tenantId);
				formData.append('brandId', brandId);
				this.http
					.postWithoutFormattingURL(duplicateUrl, formData, { headers: { Authorization: `Bearer ${templatesUrl.token}` } })
					.pipe(
						mergeMap((duplicatedTemplate) => {
							this.duplicatedTemplateId = duplicatedTemplate[templateId];
							//in case of root folder, template shouldn`t be moved
							if (!folderId) {
								return of({ isSuccess: true });
							}
							let resultMove = this.manageTemplatesService.moveFolderOrTemplate({
								nodeId: this.duplicatedTemplateId,
								isFolder: false,
								parentId: folderId,
								subMethodId: subMethodId
							});
							return resultMove;
						}),
						mergeMap((moveRes) => {
							if (moveRes && moveRes.isSuccess) {
								this.$duplicating.next(false);
								return this.manageTemplatesService.getFolderTemplates([
									{
										templateID: this.duplicatedTemplateId,
										subMethodId: subMethodId,
										channelId: channelId,
										folderId: folderId
									}
								]);
							} else {
								this.showError();
								return of();
							}
						})
					)
					.subscribe(
						(folderTemplates: FolderTemplatePreview[]) => {
							if (folderTemplates && folderTemplates[0]) {
								const newTemplateTreeItem = this.convertTemplateToTreeItem(
									folderTemplates[0].templateName,
									folderTemplates[0].templateID,
									folderId,
									subMethodId
								);
								this.folderService.addTemplateToCache(folderId, subMethodId, newTemplateTreeItem);
								this.$duplicated.next({
									templateId: this.duplicatedTemplateId,
									otherEditor: false,
									subMethodId,
									isBatchDuplication: isBatch
								});
							} else {
								this.showError();
							}
						},
						() => this.showError()
					);
			});
		} else {
			this.manageTemplatesService.duplicateTemplate({ templateId, channelId }).subscribe((response) => {

				if (!response) {
					this.showError();
					return;
				}
				this.$duplicating.next(false);
				const duplicatedTemplate = response as unknown as TemplateDetails;
				this.lastDuplicatedId = duplicatedTemplate.templateId;
				const newTemplateTreeItem = this.convertTemplateDetailsToTreeItem(duplicatedTemplate);
				this.folderService.addTemplateToCache(duplicatedTemplate.folderId, duplicatedTemplate.subMethodId, newTemplateTreeItem);
				this.$duplicated.next({
					templateId: duplicatedTemplate.templateId,
					otherEditor: duplicatedTemplate.otherEditor,
					subMethodId: duplicatedTemplate.subMethodId,
					isBatchDuplication: isBatch
				});
			});
		}
	}

	public optiMobileBatchDuplicate(
		templateIds: number[],
		channelId: number,
		subMethodId: number,
		tenantId: string,
		brandId: string,
		folderId: number,
		isBatch: boolean = false
	) {
		this.$duplicating.next(true);
		this.manageTemplatesService.getManageTemplatesUrl().subscribe((templatesUrl) => {
			const duplicateUrl = this.getMobileDuplicateUrl(templatesUrl.optimobileUrls, channelId);
			let formData: FormData = new FormData();
			templateIds.forEach((templateId) => formData.append('templateIds[]', templateId.toString()));
			formData.append('tenantId', tenantId);
			formData.append('brandId', brandId);
			this.http
				.postWithoutFormattingURL(duplicateUrl, formData, { headers: { Authorization: `Bearer ${templatesUrl.token}` } })
				.pipe(
					mergeMap((duplicatedTemplates) => {
						let resultMove = this.manageTemplatesService.moveBatchOfTemplates({
							templateIds: Object.values(duplicatedTemplates),
							newFolderId: folderId,
							subMethodId: subMethodId
						});
						this.duplicatedTemplateIds = Object.values(duplicatedTemplates);
						return resultMove;
					}),
					mergeMap((moveRes) => {
						if (moveRes && moveRes.isSuccess) {
							this.$duplicating.next(false);
							const templatesRequest = this.duplicatedTemplateIds.map((id) => {
								return {
									templateID: id,
									subMethodId: subMethodId,
									channelId: channelId,
									folderId: folderId
								};
							});
							return this.manageTemplatesService.getFolderTemplates(templatesRequest);
						} else {
							this.showError();
							return of();
						}
					})
				)
				.subscribe(
					(folderTemplates: FolderTemplatePreview[]) => {
						if (folderTemplates) {
							folderTemplates.forEach((template) => {
								const newTemplateTreeItem = this.convertTemplateToTreeItem(
									template.templateName,
									template.templateID,
									folderId,
									subMethodId
								);
								this.folderService.addTemplateToCache(folderId, subMethodId, newTemplateTreeItem);
								this.$duplicated.next({
									templateId: template.templateID,
									otherEditor: false,
									subMethodId,
									isBatchDuplication: isBatch
								});
							});
						} else {
							this.showError();
						}
					},
					() => this.showError()
				);
		});
	}

	isLastDuplicatedId(id: number): boolean {
		return this.lastDuplicatedId === id;
	}

	private showError(): void {
		this.$duplicating.next(false);
		this.errorModalService.openErrorModal(
			this.translate.instant('features.manage_templates.optimail.templateFolderActions.DUPLICATE_TEMPLATES'),
			this.translate.instant('features.manage_templates.optimail.templateFolderActions.ACTION_DUPLICATE_TEMPLATE')
		);
	}

	private getMobileDuplicateUrl(optimobileUrls: string, channelId: number) {
		const refreshToken = localStorage.getItem('refreshToken');
		const urls = JSON.parse(optimobileUrls) as { [key: string]: string };
		let duplicateUrl = urls[channelId] ? urls[channelId] : urls['default'];
		duplicateUrl = duplicateUrl.replace('{channelId}', channelId?.toString());
		if (refreshToken) {
			duplicateUrl += `?refreshToken=${refreshToken}`;
		}

		return duplicateUrl;
	}

	private convertTemplateDetailsToTreeItem(template: TemplateDetails): TemplateTreeItem {
		return {
			id: template.templateId,
			parentId: template.folderId,
			rel: RelationType.leaf,
			text: template.templateName,
			displayText: null,
			isRootFolder: false,
			children: null,
			subMethodId: template.subMethodId,
			lastModifiedDate: template.lastModifiedDateUTC,
			isValid: template.isValid
		};
	}
	private convertTemplateToTreeItem(templateName, templateId, folderId, subMethodId): TemplateTreeItem {
		return {
			id: templateId,
			parentId: folderId,
			rel: RelationType.leaf,
			text: templateName,
			displayText: null,
			isRootFolder: false,
			children: null,
			subMethodId: subMethodId,
			isValid: true
		};
	}
}
