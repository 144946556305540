<opti-logic-modal [modalConf]="modalConf">
  <div class="language-modal-body">
    <div class="language-counter">
      <span class="language-count-sign">{{ selectedIds.length + 1 }} Languages Selected</span>
    </div>
    <div class="language-selection">
      <opti-select
        class="selection"
        [placeholder]="'features.manage_templates.optimail.translationLanguagesModal.SELECT_LANGUAGES' | translate"
        [items]="languages"
        [isMultiSelect]="true"
        [shouldShowSelectedValueTooltip]="false"
        [isSelectAllDisabledInMultiMode]="true"
        [(ngModel)]="selectedIds"
        (ngModelChange)="selectedIdsChanged($event)"></opti-select>
      <button (click)="selectRecent()" class="btn-link header-action select-recent">
        <span>{{ "features.manage_templates.optimail.translationLanguagesModal.SELECT_RECENT" | translate }}</span>
      </button>
      <button (click)="clearAllLanguages()" class="btn-link header-action">
        <span>{{ "features.manage_templates.optimail.translationLanguagesModal.CLEAR_ALL" | translate }}</span>
      </button>
    </div>
    <div class="language-input">
      <div class="language-textarea">
        <div class="language-pill">
          <i class="material-icons default-language">close</i>
          <div class="language-box">
            <p class="language-mark">{{ defaultLanguage.DisplayName }}</p>
          </div>
        </div>
        <div *ngFor="let language of selectedLanguages" class="language-pill">
          <i (click)="removeClicked(language)" class="material-icons other-lang">close</i>
          <div class="language-box">
            <p class="language-mark">{{ language.DisplayName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</opti-logic-modal>
