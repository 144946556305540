import { HttpErrorResponse } from '@angular/common/http';
import { TemplateDetails } from './templateDetails';

export type TemplateSaveUpdateResponse = TemplateSaveUpdateSuccessResponse | TemplateSaveUpdateErrorResponse | HttpErrorResponse;

export interface TemplateSaveUpdateRequest {
	allowInvalidTemplate: boolean;
	allowUsedTemplate: boolean;
	channelId: number;
	convertToFroala: boolean;
	templateJson: string;
}

export interface TemplateSaveUpdateSuccessResponse {
	isSuccess: boolean;
	data: {
		folderNewName: string;
		tId: number;
		templateData: TemplateDetails;
		lastModified: TemplateLastModified;
	};
}

export interface TemplateSaveUpdateErrorResponse {
	isSuccess: boolean;
	errorMsg: ErrorMessage | string;
	data: ValidationOutput;
}

export interface TemplateLastModified {
	date: string;
	time: string;
	username: string;
	externalData: string;
}

export const isTemplateSaveSuccessResponse = (b: TemplateSaveUpdateResponse): b is TemplateSaveUpdateSuccessResponse => {
	return (b as TemplateSaveUpdateSuccessResponse).data?.tId !== undefined;
};

export enum ErrorMessage {
	templateInUse = 'templateInUse',
	nameInUse = 'nameInUse',
	invalidTemplate = 'invalidTemplate'
}

export interface ValidationOutput {
	isValid: boolean;
	personalizationTagsValidation: any;
	conditionalLanguageValidation: any;
	emptyContentValidation: any;
	urlValidation: any;
	unsubscribeLinkValidation: any;
	contentTagValidation: any;
}
