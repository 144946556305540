import {Component, Input, OnChanges} from "@angular/core";

@Component({
    selector: 'ios-notification-channel',
    templateUrl: './iosNotificationChannel.component.html',
    styleUrls: ['./iosNotificationChannel.component.scss']
})

export class IosNotificationChannelsComponent implements  OnChanges{

    @Input() template: any;

    public channel: string;

    ngOnChanges(){
        this.initTemplate();
    }
    initTemplate() {
        if (!this.template) {
            return;
        }
        this.channel = this.template['iosChannel'];
    }

    channelChanged(channel: string) {
        this.channel = channel;

        if (this.template) {
            this.template['iosChannel'] = this.channel;
        }
    }

}
