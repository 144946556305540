import { Injectable } from "@angular/core";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../services/optimove-http/optimove-http.model";
import { WebhookConfiguration, WebhookEvent, WebhookSettings } from "./models/webhookConfiguration";

@Injectable()
export class WebhookConfigurationService {

    private readonly baseUrl = '/WebhookConfiguration';
    private configurationList: WebhookConfiguration[];
    private settings: WebhookSettings;
    private genericEventTypes: WebhookEvent[];

    constructor(private readonly httpClient: HttpService){ }

    public getWebhooksList() : Observable<WebhookConfiguration[]> {
        return this.httpClient.get<AjaxResponse<WebhookConfiguration[]>>(`${this.baseUrl}/GetOptigrationWebhooksList`, {}).pipe(
            map((res) => {
                if(this.configurationList){
                    let newConfigs = res.data.filter((webhook) =>  !this.configurationList.find(x=>x.webhookName == webhook.webhookName) );
                    newConfigs.forEach(x => { x.webhookData.isNew = true; });
                }
                this.configurationList = res.data;
                return this.configurationList;
            })
        )
    }

    // isNew is a boolean that indicates if we should add (isNew) or update (!isNew) (yes it's shitty, we know it)
    public addOrUpdateWebhook(webhookEntity: WebhookConfiguration, isNew: boolean) : Observable<any>{
        return this.httpClient.post<AjaxResponse<any>>(`${this.baseUrl}/AddOrUpdateOptigrationWebhookConfiguration`, { webhookEntity, isNew}).pipe(
            map((res) => {
                return res;
            })
        )
    }

    public deleteWebhook(webhookConfiguration: WebhookConfiguration) : Observable<boolean>{
        return this.httpClient.post<AjaxResponse<boolean>>(`${this.baseUrl}/DeleteOptigrationWebhookConfiguration`, webhookConfiguration).pipe(
            map((res) => {
                return res.isSuccess;
            })
        )
    }

    public validateWebhookURL(webhookURL: string, isInternal: boolean) : Observable<boolean>{
        return this.httpClient.post<AjaxResponse<boolean>>(`${this.baseUrl}/ValidateWebhookURL`, { webhookURL, isInternal }).pipe(
            map((res) => {
                return res.isSuccess;
            })
        )
    }

    public validateWebhookEventURL(webhookEventURL: string, isInternal: boolean) : Observable<boolean>{
        return this.httpClient.post<AjaxResponse<boolean>>(`${this.baseUrl}/ValidateWebhookEventURL`,{ webhookEventURL, isInternal }).pipe(
            map((res) => {
                return res.isSuccess;
            })
        )
    }

    public getSettings() : Observable<WebhookSettings> {
        if(this.settings){
            return of(this.settings);
        }

        return this.httpClient.get<AjaxResponse<WebhookSettings>>(`${this.baseUrl}/GetSettings`, {}).pipe(
            map((res) => {
                this.settings = res.data;
                return res.data;
            })
        )
    }
    public getOptigrationGenericEngagerEventTypes() : Observable<WebhookEvent[]> {
        if(this.genericEventTypes){
            return of(this.genericEventTypes);
        }

        return this.httpClient.get<AjaxResponse<WebhookEvent[]>>(`${this.baseUrl}/GetOptigrationGenericEngagerEventTypes`, {}).pipe(
            map((res) => {
                this.genericEventTypes = res.data;
                return res.data;
            })
        )
    }
}