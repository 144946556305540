<div class="test-formula-modal-container">
  <opti-loading [show]="config.isLoadingActive" [size]="'md'" ></opti-loading>
  <opti-logic-modal [modalConf]="modalConf"> 
  
    <div class="test-formula-content container">  
      <div class="row">
        <form [formGroup]="inputForm" (ngSubmit)="getCustomerData()" class="col-md-4 content-item">
          <span class="default-margin">
            {{translateKeys.testFormula.previewUsing | translate}}
          </span>
          <div class="radio-selection">
            <div class="radio-block">
            <label for="random-option" class="opti-radio">
              <input data-qa-id="RandomRadioButton" (change)="setRandomMode(true)" type="radio" id="random-option" name="preview-mode" tabindex="1" checked>
              <span>
                {{translateKeys.testFormula.randomCustomerData | translate}}
              </span> 
            </label>
            <button data-qa-id="refresh-btn" class="refresh-btn" [disabled]="!config.isRandomModeSelected">
              <i class="material-icons" >refresh</i>
            </button>       
            </div>
          <div class="radio-block">
            <label for="id-option" class="opti-radio">
              <input data-qa-id="CustomerIdRadioButton" (change)="setRandomMode(false)" type="radio" id="id-option" name="preview-mode" tabindex="2">
              <span>
                {{translateKeys.testFormula.idCustomerData | translate}}
              </span>
            </label>
          </div>
          </div>        
          <div class="input-data move-right">
            <input 
              data-qa-id="CustomerIdInput"
              class="input-cid move-right" 
              type="text"
              formControlName="customerId"
              [ngClass]="{'invalid-input': customerIdFormControl.touched && !config.isRandomModeSelected && !inputForm.valid || !config.isRequestSuccess}"          
              [placeholder]="translateKeys.testFormula.inputPlaceholder | translate">
            <span *ngIf="customerIdFormControl.touched && !config.isRandomModeSelected && !inputForm.valid || !config.isRequestSuccess" class="validation-msg invalid">
              {{translateKeys.errors.invalidId | translate}}
            </span>
          </div>
          <button data-qa-id="GeneratePreviewButton" class="btn btn-primary move-right" type="submit" [disabled]="config.isRandomModeSelected">
            {{translateKeys.testFormula.generatePrewiew | translate}}
          </button>  
        </form>

        <div class="vertical-line col-md-1">
          <div class="line"></div>
        </div>
        
        <div class="col-md-7 content-item">
          <opti-loading *ngIf="config.isLoadingActive"></opti-loading>
          <div  class="data-field">
            {{translateKeys.testFormula.customerId | translate}}
            <span data-qa-id="customer-id-lbl" class="customer-id" (click)="openCustomer360()">
              {{config.customerId}}
            </span>
          </div> 
          <div class="formula-result">
            {{title}}:
            <span data-qa-id="farmula-result-lbl" class="status">
              {{config.formulaResult}}
            </span>
          </div>
          <div data-qa-id="attribute-name-lbl" *ngFor="let attribute of attributes" class="data-field">
            {{attribute.DisplayName}}:
            <span data-qa-id="attribute-fieldname-lbl" class="status">
              {{getValue(attribute.FieldName)}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </opti-logic-modal>
</div>
