<opti-logic-modal [modalConf]="modalConf">
  <div class="edit-plain-text">
    <div class="button-layer">
      <div (click)="generateFromHtml()" class="col-md-4 generate-from-html">
        <i class="material-icons">open_in_browser</i>
        <p>{{ "features.manage_templates.components.plainText.DROPDOWN_GENERATE_FROM_HTML" | translate }}</p>
      </div>
      <div class="button-box">
        <div type="button" (click)="isLoadTags = true" class="col-md-4 generate-from-html">
          <i class="material-icons icon">portrait</i>
          <p>Personalization</p>
        </div>
      </div>
      <div *ngIf="isLoadTags">
        <personalization-tags-container [subMethodId]="subMethodType" (chosen)="onTagChosen($event)"></personalization-tags-container>
      </div>
    </div>
    <textarea #plainTextArea rows="12" class="form-control plaintext-area" id="edit-text-textarea" [(ngModel)]="editorText"></textarea>
  </div>
</opti-logic-modal>
