import { Injectable, Injector, NgZone } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, GuardsCheckEnd, NavigationEnd, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { NewNavbarService } from '../modules/navbar/services/newNavbar.service';
import { AlertsService } from "../services/alerts.service";

export enum RouterAdapterLoadingState {
    loading = 'loading',
    loaded = 'loaded'
};

@Injectable()
export class AngularRouterUIRouterAdapter {
    private routerAdapterLoadingSubject = new Subject<RouterAdapterLoadingState>();
    routerAdapterLoadingState = this.routerAdapterLoadingSubject.asObservable();

    private uiRouter: any;
    private unauthorizedService: any;
    private uiRouterGuardService: any;
    private uiRouterNavigationStates: any;

    constructor(private zone: NgZone, private angularRouter: Router, private activatedRoute: ActivatedRoute, private injector: Injector, private modalService: BsModalService) {
    }

    init(uiRouter: any, uiRouterGuardService: any, unauthorizedService: any, uiRouterNavigationStates: any) {
        if (this.uiRouter || this.uiRouterGuardService || this.uiRouterNavigationStates){
            console.error('attempt to init singletone more than once');
            return
        }
        this.uiRouter = uiRouter;
        this.uiRouterGuardService = uiRouterGuardService;
        this.unauthorizedService = unauthorizedService;
        this.uiRouterNavigationStates = uiRouterNavigationStates;
        this.uiRouter.setupAngularRouting(this);
        this.setupObservers();
    }

    public triggerAngularJsRoute(){
        this.uiRouter.triggerNavigation();
    }

    public getRouteChildSnapshot(inActivatedRoute: ActivatedRouteSnapshot): ActivatedRouteSnapshot{
        let activatedRoute = inActivatedRoute;
        while (activatedRoute.children.length > 0) {
            activatedRoute = activatedRoute.firstChild;
        }
        return activatedRoute;
    }

    private isAngularJsState(event: any): any {
        if (event.state && event.state.root){
            return this.getRouteChildSnapshot(event.state.root).data['isLegacy'];
        }
        return this.getRouteChildSnapshot(this.activatedRoute.snapshot).data['isLegacy']
    }

    private setupObservers(){
        this.observeAngularRoutes();
        this.observeAngularJsRoutes();
    }

    private observeAngularRoutes(){
        this.angularRouter.events.subscribe((event: any) =>  {
            // always remove pending modals
            if (event instanceof GuardsCheckEnd && event.shouldActivate) {
                this.clearModals();
            }
            // we allow angularjs to handle the dismissal and presentation of loader when needed
            if (event instanceof GuardsCheckEnd && event.shouldActivate && !this.isAngularJsState(event)) {
                this.routerAdapterLoadingSubject.next(RouterAdapterLoadingState.loading);
                this.clearOptiAlerts();
            } else if (event instanceof NavigationEnd && !this.isAngularJsState(event)) {
                this.routerAdapterLoadingSubject.next(RouterAdapterLoadingState.loaded);
            }
        });
    }

    private observeAngularJsRoutes(){
        // adding this displayedAngularJsUrl logic to handle the following edge case:
        // 1. user navigates to dashboard.aspx
        // 2. user navigated to manage-target-groups
        // 3. page finished loading dashboard.aspx
        // 4. page finished loading manage-target-groups
        // in this case the loader will be dimissed too soon and the user will see the dashboard page
        // instead of the manage-target-groups and only after a delay will he see manage-target-groups.
        let displayedAngularJsUrl = null;
        this.uiRouter.subscribe((event, url, fromStateHasCustomLoader, toStateHasCustomLoader) =>  {
            let hasCustomLoader = fromStateHasCustomLoader && toStateHasCustomLoader;
            let updateLoader = (state) => {
                // no need to show global loader if someone else is taking care of this
                if (!hasCustomLoader) {
                    this.routerAdapterLoadingSubject.next(state);
                }
            }

            if (event.name === this.uiRouterNavigationStates.navigationStart){
                displayedAngularJsUrl = url;
                updateLoader(RouterAdapterLoadingState.loading);
            }
            if (event.name == this.uiRouterNavigationStates.asyncContentLoaded && Boolean(url) && url?.includes(displayedAngularJsUrl)) {
                updateLoader(RouterAdapterLoadingState.loaded);
            }
        });
    }

    private clearModals(){
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
            this.modalService.hide(i);
        }
        this.uiRouter.closeOpenModals();
    }

    public normalize(path: string) : string {
        return path && path.replace('/#', '')
                   .replace('#', '');
    }

    public nonnormalize(path: string) : string {
        return '/#' + unescape(this.normalize(path));
    }

    public navigateByUrl(url: string, replaceUrl: boolean = false): Promise<boolean> {
        let normalizedUrl = this.normalize(url);
        return new Promise(async (resolve, reject) => {
            let navigationSucceeded = await this.angularRouter.navigateByUrl(normalizedUrl, { replaceUrl: Boolean(replaceUrl) });
            resolve(navigationSucceeded);
        })
    }

    public getDefaultUrl(): Promise<string> {
        const newNavbarService: NewNavbarService = this.injector.get(NewNavbarService);
        if (!newNavbarService) {
            return Promise.resolve(null);
        }
        return newNavbarService.getDefaultUrl().then((defaultUrl) => {
            return this.normalize(defaultUrl);
        });
    }

    public async isUnauthorized(url: string): Promise<boolean>{
        if (!this.unauthorizedService){
            return Promise.resolve(false);
        }
        var nonnormalizedUrl = this.nonnormalize(url);
        let isUnauthorizedUrl = await this.unauthorizedService.isUnauthorizedUrl(nonnormalizedUrl)
        return isUnauthorizedUrl;
    }

    public promptGuard(): Promise<boolean>{
        return this.uiRouterGuardService.promptGuards();
    }

    public isGuarded(){
        return this.uiRouterGuardService && this.uiRouterGuardService.isNavigationGuarded();
    }

    private clearOptiAlerts() {
        // The reason for using the injector to get ngJS service,
        // Because this service load before the ngJS service create.
        const alertsService = this.injector.get(AlertsService);
        if (alertsService) {
            alertsService.clearAlerts();
        }
    }
}

export class AngularRouterUIRouterAdapterStub {}