import {Component, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    selector: 'webhook-configuration-empty-state',
    templateUrl: './webhookConfigurationEmptyState.component.html',
    styleUrls: ['./webhookConfigurationEmptyState.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class WebhookConfigurationEmptyStateComponent{

    constructor(private router: Router) {
    }

    public addNewWebhook(){
        const url = this.router.serializeUrl(this.router.createUrlTree(['/user-settings/webhookConfiguration/addWebhookConfiguration']));
        window.open('/#' + url,'_self' );
    }
}