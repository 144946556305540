import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularJsSsmServiceInstance } from './optimove-ssm/optimove-ssm.model';
import { SsmService } from './ssm.service';

@Injectable()
export class MailService {

  ssm: AngularJsSsmServiceInstance;

  constructor(private readonly translateService: TranslateService,
    private readonly ssmService: SsmService) {
    this.ssm = this.ssmService.getSSM();
  }

  public contactSupport() {
    this.getMailParamsAsync().then(([siteName, ccMail]) => {
      window.open(`mailto:${this.translateService.instant('general.SUPPORT_EMAIL')}?subject=${siteName}&cc=${ccMail}&body=${this.translateService.instant('components.navbar.CONTACT_SUPPORT_BODY')}`, "_self");
    });
  }

  private getMailParamsAsync() {
    const promise = new Promise((resolve, reject) => {
      const siteNamePromise = this.ssm.getGenericAsync(this.ssm.Resx.SiteName);
      const csmConfigurationPromise = this.ssm.getGenericAsync(this.ssm.Resx.CsmConfiguration);

      Promise.all([siteNamePromise, csmConfigurationPromise])
        .then(([siteName, csmConfiguration]) => {
          resolve([siteName, csmConfiguration?.CsmMail ?? ' ']);
        })
        .catch((error) => reject(error));

    }) as Promise<[siteName: string, ccMail: string]>;

    return promise;
  }

}
