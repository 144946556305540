<div class="insert-link-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div>
      <span style="margin-bottom: 6px" class="name-field">
        {{ "features.manage_templates.optimail.contentTags.TEXT_TO_DISPLAY" | translate }}
      </span>
      <input class="form-control input-field" type="text" placeholder="text to display" [formControl]="textToDisplayControl" />
    </div>
    <div>
      <span class="name-field">{{ "features.manage_templates.optimail.contentTags.URL" | translate }}</span>
      <input
        [ngClass]="{'error-border': !urlControl.valid}"
        class="form-control input-field"
        type="text"
        placeholder="https://"
        [formControl]="urlControl" />
      <div style="margin-bottom: 6px" class="text-under-input">
        <p *ngIf="!urlControl.valid" class="inputs-validations error-msg">Enter valid url</p>
      </div>
    </div>
  </opti-logic-modal>
</div>
