import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { SearchItem,SearchListConfig } from '../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { AnalysisRange } from '../../customer360.enums';
import { analysisRangeTranslateKeys } from '../../customer360.translate.key';
import { IDateRangePicker } from '../../../../components/dateRangePicker/dateRangePicker.component';
import { Customer360FormatService } from '../../services/customer360.format.service';
import { IAnalysisRange } from '../../customer360.models';

@Component({
	selector: 'analysis-range-picker',
	templateUrl: './analysisRangePicker.component.html'
})
export class AnalysisRangePickerComponent implements OnChanges {

	@Input() disabled: boolean;
	@Input() rangeUrlParametersPrefix: string;
	@Output('analysisRangeChanged') dateRangeEvent = new EventEmitter<IAnalysisRange>();

	public translateKeys = analysisRangeTranslateKeys;
	public form: FormGroup;
	public analysisRangeConfig: SearchListConfig = {
		keyProperty: "key",
		valueProperty: "value",
		isMultiSelect: false,
		placeholderTranslateKey: this.translate.instant(this.translateKeys.ANALYSIS_RANGE),
		itemNameTranslateKey: this.translate.instant(this.translateKeys.ANALYSIS_RANGE),
		shouldIgnoreSorting: true,
	};
	public analysisRanges: SearchItem[] = [
		{key: AnalysisRange.Last7Days, value: AnalysisRange.Last7Days},
		{key: AnalysisRange.Last30Days, value: AnalysisRange.Last30Days},
		{key: AnalysisRange.Last90Days, value: AnalysisRange.Last90Days},
		{key: AnalysisRange.Custom, value: AnalysisRange.Custom},
	];
	public dateRangePickerConfig: IDateRangePicker;

	private subscriptions: Subscription[] = [];
	private startDate: string;
	private endDate: string;
	private selectedRangeIndex: number;

	constructor(
		private translate: TranslateService,
		private formatService: Customer360FormatService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.rangeUrlParametersPrefix && changes.rangeUrlParametersPrefix.firstChange) {
			this.initQueryParams();
		}
		if (!this.form) {
			const analysisRange = this.analysisRanges[this.selectedRangeIndex].key;
			const dateRange = analysisRange === AnalysisRange.Custom && this.startDate && this.endDate
				? [new Date(this.startDate + "T00:00:00"), new Date(this.endDate + "T00:00:00")]
				: null;
			this.createForm(dateRange);

			const isDisabled = !analysisRange || (changes.disabled && changes.disabled.currentValue);
			this.setupDateRangePickerConfig(dateRange, isDisabled);
			this.initSubscription();
			this.analysisRange.setValue([this.analysisRanges[this.selectedRangeIndex]]);
			if (dateRange) {
				this.dateRange.updateValueAndValidity({ onlySelf: false, emitEvent: true });
			}

			this.setDisabledState(isDisabled);
		} else {
			this.setDisabledState(changes.disabled.currentValue);
		}
	}

	public refreshDatePicker(analysisRange: SearchItem) {
		switch (analysisRange.key) {
			case AnalysisRange.Last7Days:
				this.setDateRangeTillToday(6);
				break;
			case AnalysisRange.Last30Days:
				this.setDateRangeTillToday(29);
				break;
			case AnalysisRange.Last90Days:
				this.setDateRangeTillToday(89);
				break;
			default:
				this.setupDateRangePickerConfig(this.dateRange.value, false);
		}
		
		this.applyQueryParams();
	}
	
	public onDateRangeChange(dateRange: Date[]) {
		this.applyQueryParams();
		
		//Only the 90 days range is allowed (89 + today)
		if (Math.ceil(this.formatService.dateRangeToDays(dateRange)) > 89) {
			dateRange[1] = this.formatService.getDateAfterXDays(dateRange[0], 89);
		}

		const datePipe = new DatePipe(this.translate.currentLang);
		const analysisRange: IAnalysisRange = {
			startDate: datePipe.transform(dateRange[0], "yyyy-MM-dd"),
			endDate: datePipe.transform(dateRange[1], "yyyy-MM-dd"),
		}

		this.dateRangeEvent.emit(analysisRange);
	}

	get analysisRange(): FormControl {
		return this.form.get("analysisRange") as FormControl;
	}

	get dateRange(): FormControl {
		return this.form.get("dateRange") as FormControl;
	}

	private initQueryParams() {
		this.startDate = this.route.snapshot.queryParamMap.get(`${this.rangeUrlParametersPrefix}startDate`);
		this.endDate = this.route.snapshot.queryParamMap.get(`${this.rangeUrlParametersPrefix}endDate`);
		const selectedRangeIndex = parseInt(this.route.snapshot.queryParamMap.get(`${this.rangeUrlParametersPrefix}selectedRangeIndex`));
		this.selectedRangeIndex = !isNaN(selectedRangeIndex) ? selectedRangeIndex : 1;
		if (this.analysisRanges[this.selectedRangeIndex].key === AnalysisRange.Custom && (!this.startDate || !this.endDate)) {
			this.startDate = null;
			this.endDate = null;
			this.selectedRangeIndex = 1;
		}
	}

	private setQueryParams(startDate: string, endDate: string, selectedRangeIndex: number) {
		setTimeout(() => {
			this.router.navigate([], {
				queryParams: { 
					[`${this.rangeUrlParametersPrefix}startDate`]: startDate, 
					[`${this.rangeUrlParametersPrefix}endDate`]: endDate, 
					[`${this.rangeUrlParametersPrefix}selectedRangeIndex`]: selectedRangeIndex,
				},
				queryParamsHandling: "merge",
				replaceUrl: true
			});
		});
	}

	private applyQueryParams() {
		const datePipe = new DatePipe(this.translate.currentLang);
		const dateRange = this.dateRange.value;

		const analysisRange = this.analysisRange.value[0].key;
		const startDate = analysisRange === AnalysisRange.Custom
			? datePipe.transform(dateRange[0], "YYYY-MM-dd")
			: null;
		const endDate = analysisRange === AnalysisRange.Custom
			? datePipe.transform(dateRange[1], "YYYY-MM-dd")
			: null;

		const selectedRangeIndex = this.analysisRanges.findIndex(item => item.key === analysisRange);
		this.setQueryParams(startDate, endDate, selectedRangeIndex);
	}

	private setDisabledState(isDisabled: boolean) {
		if (this.form) {
			if (isDisabled) {
				this.analysisRange.disable({emitEvent: false})
				this.setupDateRangePickerConfig(this.dateRange.value, true);
			}
			else {
				this.analysisRange.enable({emitEvent: false});
				switch (this.analysisRange.value[0].key) {
					case AnalysisRange.Last7Days:
					case AnalysisRange.Last30Days:
					case AnalysisRange.Last90Days:
						this.setupDateRangePickerConfig(this.dateRange.value, true);
						break;
					default:
						this.setupDateRangePickerConfig(this.dateRange.value, false);
				}
			}
		}
	}

	private setupDateRangePickerConfig(date: Date[], isDisabled: boolean) {
		this.dateRangePickerConfig = {
			date: date,
			placeholder: this.translate.instant(this.translateKeys.ANALYSIS_RANGE),
			placement: 'bottom right',
			isDisabled: isDisabled,
			maxDate: new Date(),
		};
	}
	
	private createForm(dateRange: Date[]) {
		this.form = new FormGroup({
			analysisRange: new FormControl('', [Validators.required]),
			dateRange: new FormControl(dateRange, [Validators.required]),
		});
	}

	private initSubscription() {
		const sub$1 = this.analysisRange.valueChanges.subscribe((range: SearchItem[]) => {this.refreshDatePicker(range[0])});
		const sub$2 = this.dateRange.valueChanges.subscribe((range: Date[]) => {this.onDateRangeChange(range)});
		this.subscriptions.push(sub$1);
		this.subscriptions.push(sub$2);
	}

	private setDateRangeTillToday(daysAgo: number) {
		const dateRange = [this.formatService.getDateXDaysAgo(daysAgo), this.formatService.getDateXDaysAgo(0)];
		this.dateRange.setValue(dateRange, {emitEvent: true});
		this.setupDateRangePickerConfig(this.dateRange.value, true);
	}
}
