import { Component,	Input,	Output,	EventEmitter } from '@angular/core';
import { IAnalysisRange } from '../../customer360.models';
import { impactHeaderTranslateKeys } from '../../customer360.translate.key';

@Component({
	selector: 'customer-impact-header',
	templateUrl: './customerImpactHeader.component.html'
})
export class CustomerImpactHeaderComponent {

	@Input() disabled: boolean;
	@Output() analysisRangeChanged = new EventEmitter<IAnalysisRange>();

	public translateKeys = impactHeaderTranslateKeys;
	public rangeUrlParametersPrefix = "";
}
