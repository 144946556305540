import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'opti-loading',
    templateUrl: './optiLoading.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./optiLoading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptiLoadingComponent{

    readonly defaultSize: string = 'md';

    @Input() show: boolean;
    @Input() size: string;
    @Input() title: string;
    @Input() text: string;
    @Input() isTextInnerHtml: boolean;

    constructor(){}
}
