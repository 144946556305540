<div class="panel-body">
    <analysis-range-picker
            [disabled]="isTableLoading"
            [rangeUrlParametersPrefix]="rangeUrlParametersPrefix"
            (analysisRangeChanged)="onAnalysisRangeChanged($event)">
        <h2>
            {{translateKeys.TITLE | translate }}
        </h2>
        <p class="small">
            {{translateKeys.DESCRIPTION | translate }}
        </p>
    </analysis-range-picker>
    <div class="row menu">
        <div class="col-md-9 tabs-menu">
        </div>
        <div class="col-md-3 text-right header-btns">
            <ul class="list-inline">
                <li>
                    <button (click)="openSelectActivityAttributesModal()"
                            class="btn-link"
                            [disabled]="isTableLoading">
                        <i class="material-icons">playlist_add_check</i>
                        <span>
                            {{ translateKeys.SELECT_ATTRIBUTE | translate }}
                        </span>
                    </button>
                </li>
                <li>
                    <button csv-exporter
                            [disabled]="!hasListData"
                            [fileName]="attributesListCsvFileName"
                            [headers]="attributesListCsvFileHeaders"
                            [exportedData]="purchaseHistoryData"
                            [objectCsvSerializerFunc]="attributesListCsvSerializerFunc"
                            class="btn-link">
                        <i class="material-icons">open_in_browser</i>
                        <span>
                            {{ translateKeys.EXPORT | translate }}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <hr class="menu-bottom-hr">
    <div class="row">
        <div class="col-md-12 ph-grid">
            <opti-loading 
                [show]="isTableLoading" 
                [size]="'sm'"
            ></opti-loading>
            <div class="empty-table-message" *ngIf="areNoAttributesSelected">
                <h1>{{ translateKeys.NO_ATTRIBUTE | translate}}</h1>
                <button (click)="openSelectActivityAttributesModal()" 
                    class="btn-link">
                    <span>
                        {{ translateKeys.SELECT_ATTRIBUTE | translate }}
                    </span>
                </button>
            </div>
            <ag-grid-angular *ngIf="gridOptions && !areNoAttributesSelected" 
                [ngStyle]="{'width': tableWidth}"
                class="ag-theme-balham ag-theme-white"
                [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>
</div>