import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AgGridModule } from "@ag-grid-community/angular";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OptiModalModule } from "./OptiModal.module";
import { CustomerAttributesComponent } from "../components/navBar/customerAttributesModal/customerAttributes.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../directives/directives.module";
import { PipesModule } from "../pipes/pipes.module";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { InputSearchModule } from "../components/inputSearch/InputSearch.module";
import { NavbarWrapperService } from "../services/navbarWrapper.service";
import { AttributesModule } from "../features/settings/attributes/attributesList.module";
import { TypeCellRendererComponent } from "../features/settings/attributes/components/typeCellRenderer/typeCellRenderer.component";
import { AttributesListService } from "../features/settings/attributes/services/attributesList.service";

@NgModule({
    imports: [
        AgGridModule.forRoot([TypeCellRendererComponent]),
        TypeaheadModule.forRoot(),
        OptiModalModule.forRoot(),
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectivesModule,
        PipesModule,
        InputSearchModule,
        AttributesModule,
        TooltipModule.forRoot(),
    ],
    providers: [
        // required legacy services for navbar integration
        NavbarWrapperService,
        AttributesListService,
        { provide: 'ssmService', useFactory: i => i.get('ssmService'), deps: ['$injector'] },
        { provide: 'navbarUtilityService', useFactory: i => i.get('navbarUtilityService'), deps: ['$injector'] },
        { provide: 'dailyStatusService', useFactory: i => i.get('dailyStatusService'), deps: ['$injector'] },
        { provide: 'optibotNotificationSystemService', useFactory: i => i.get('optibotNotificationSystemService'), deps: ['$injector'] },
        { provide: 'unauthorizedService', useFactory: i => i.get('unauthorizedService'), deps: ['$injector'] },
        { provide: 'legacyRedirectManager', useFactory: i => i.get('legacyRedirectManager'), deps: ['$injector'] },
        { provide: 'navbarDataService', useFactory: i => i.get('navbarDataService'), deps: ['$injector'] }
    ],
    declarations: [
        CustomerAttributesComponent
    ],
    exports: [
        TypeaheadModule,
        OptiModalModule,
        AgGridModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class NavbarModule {
}