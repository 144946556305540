import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TemplateContextService } from '../../services/templateContext.service';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { TemplateDetails } from '../../models/templateDetails';
import { finalize, skipWhile } from 'rxjs/operators';
import { UtmParamsComponent } from '../dialogs/utmParamsModal/utmParams.component';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { ExecutionChannel, SubMethodType } from '../../models/metadataResponse';
import { TemplateNavigationService } from '../../services/templateNavigation.service';
import { ValidatePersonalizationTagsComponent } from '../dialogs/validatePersonalizationTags/validatePersonalizationTags.component';
import { TemplateSaveUpdateService } from '../../services/templateSaveUpdate.service';
import { TranslateService } from '@ngx-translate/core';
import { SendTestModalComponent } from '../dialogs/sendTestModal/sendTestModal.component';
import { Subscription } from 'rxjs';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { FolderService } from '../../services/folder.service';
import { RoutingConsts } from '../../models/routing.consts';
import { SearchContextService } from '../../services/searchContext.service';
import { FeatureFlag, FeatureFlagService } from '../../../../services/featureFlag.service';

@Component({
	selector: 'editor-header',
	templateUrl: './editorHeader.component.html',
	styleUrls: ['./editorHeader.component.scss']
})
export class EditorHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() isFullscreen: boolean = false;
	@Output() templateSaved = new EventEmitter<number>();

	public isSuppressionPreferences: boolean = true;

	template: TemplateDetails = {
		templateName: '',
		subMethodId: 0
	} as TemplateDetails;

	public removeSuppressionGroupError() {}
	isSaving: boolean;
	templateLobbyQueryParams: TemplateQueryParams;
	templateLobbyUrl: string;
	private subscriptions = new Subscription();
	public isTemplateNameEditable: boolean = false;
	private webPage = '200';
	public isPopup = false;
	public templateIds: number[] = [];
	public indexCurrentTemplate: number;

	public nextTemplateName: string;
	public previousTemplateName: string;
	isTemplateDirty: boolean;

	@ViewChild('templateNameInput', { static: false }) templateNameNativeElement: ElementRef;
	@ViewChild('name', { static: false }) templateNameInput: NgModel;

	constructor(
		private templateContext: TemplateContextService,
		private modalService: OptiLogicModalService,
		private cd: ChangeDetectorRef,
		private manageTemplatesService: ManageTemplatesService,
		private templateNavigationService: TemplateNavigationService,
		private router: Router,
		private templateSaveUpdateService: TemplateSaveUpdateService,
		private bsModalRef: BsModalRef,
		public translateService: TranslateService,
		private folderService: FolderService,
		private searchContextService: SearchContextService,
		private featureFlagService: FeatureFlagService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.templateContext.queryTemplateParams.subscribe((params: TemplateQueryParams) => {
				this.templateLobbyQueryParams = params;
				this.isPopup = this.templateLobbyQueryParams.channelId === this.webPage;
				params['search'] = params.search ? params.search : '';
				const templateIdsSource = params.search
					? this.searchContextService.getSearchTemplateIds()
					: this.folderService.getTemplateIds(+params.folderId, +params.typeId, params.brandId, +params.channelId);
				this.subscriptions.add(
					templateIdsSource.subscribe((templateIds) => {
						this.templateIds = templateIds;

						if (this.templateLobbyQueryParams.templateId === '0') {
							if (this.template) {
								this.indexCurrentTemplate = this.templateIds.indexOf(this.template.templateId);
								this.cd.detectChanges();
							}
						} else {
							this.manageTemplatesService
								.getTemplateDetails({ templateId: this.templateLobbyQueryParams.templateId })
								.subscribe((response: TemplateDetails) => {
									if (!response?.templateId) {
										return;
									}
									this.template = response;
									this.indexCurrentTemplate = this.templateIds.indexOf(this.template.templateId);
									this.templateContext.updateTemplate(this.template);
									this.initSiblingTemplates(this.templateIds);
								});
						}
					})
				);
			})
		);

		this.subscriptions.add(
			this.templateContext.current.pipe(skipWhile((value) => !value)).subscribe((template) => {
				this.template = template;
				this.indexCurrentTemplate = this.templateIds.indexOf(this.template.templateId);
				this.cd.detectChanges();
			})
		);
		this.subscriptions.add(this.templateContext.isCurrentTemplateDirtyChange.subscribe((isDirty) => {
			return (this.isTemplateDirty = isDirty);
		}));
	}

	public initSiblingTemplates(templateIds: number[]) {
		const nextTemplateId = this.indexCurrentTemplate + 1 < templateIds.length ? `${templateIds[this.indexCurrentTemplate + 1]}` : null;
		const prevTemplateId = this.indexCurrentTemplate > 0 ? `${templateIds[this.indexCurrentTemplate - 1]}` : null;

		if (nextTemplateId) {
			this.manageTemplatesService
				.getTemplateDetails({ templateId: nextTemplateId })
				.subscribe((template) => (this.nextTemplateName = template?.templateName));
		}
		if (prevTemplateId) {
			this.manageTemplatesService
				.getTemplateDetails({ templateId: prevTemplateId })
				.subscribe((template) => (this.previousTemplateName = template?.templateName));
		}
	}

	ngAfterViewInit() {
		if (this.templateLobbyQueryParams.templateId === '0') {
			this.onEditMode();
			this.templateNameNativeElement.nativeElement.classList.remove('ng-invalid');
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
		this.subscriptions = null;
	}

	public navigateNext(): void {
		if (this.indexCurrentTemplate !== this.templateIds.length - 1 && this.template.templateId !== 0) {
			this.templateNavigationService.navigateToEditTemplate(
				+this.templateLobbyQueryParams.channelId,
				this.templateIds[this.indexCurrentTemplate + 1],
				+this.templateLobbyQueryParams.folderId,
				+this.templateLobbyQueryParams.typeId,
				null,
				this.templateLobbyQueryParams.search
			);
			this.cd.detectChanges();
		}
	}

	public navigatePrevious(): void {
		if (this.indexCurrentTemplate !== 0 && this.template.templateId !== 0) {
			this.templateNavigationService.navigateToEditTemplate(
				+this.templateLobbyQueryParams.channelId,
				this.templateIds[this.indexCurrentTemplate - 1],
				+this.templateLobbyQueryParams.folderId,
				+this.templateLobbyQueryParams.typeId,
				null,
				this.templateLobbyQueryParams.search
			);
			this.cd.detectChanges();
		}
	}

	public isNextBtnDisabled(): boolean {
		return this.indexCurrentTemplate === this.templateIds.length - 1 || this.isSaving || this.template.templateId === 0;
	}

	public isPrevBtnDisabled(): boolean {
		return this.indexCurrentTemplate === 0 || this.isSaving || this.template.templateId === 0 || this.templateIds.length === 0;
	}

	editUTMClick(): void {
		if (!this.template) {
			return;
		}

		this.modalService.open(UtmParamsComponent, 'md', <ModalOptions<any>>{
			title: 'UTM Params',
			ignoreBackdropClick: true,
			class: '',
			initialState: {}
		});
	}

	public checkIfUTMExists(): boolean {
		if (!this.template?.utm) {
			return false;
		}

		const parsedUtm = JSON.parse(this.template.utm);
		return (
			parsedUtm.utms.utm_source ||
			parsedUtm.utms.utm_medium ||
			parsedUtm.utms.utm_campaign ||
			parsedUtm.utms.utm_term ||
			parsedUtm.utms.utm_content ||
			parsedUtm.utms.utm_id
		);
	}

	public resetUtmParams(): void {
		const parsedUtm = JSON.parse(this.template.utm);
		parsedUtm.utms.utm_source = '';
		parsedUtm.utms.utm_medium = '';
		parsedUtm.utms.utm_campaign = '';
		parsedUtm.utms.utm_term = '';
		parsedUtm.utms.utm_content = '';
		parsedUtm.utms.utm_id = '';
		this.template.utm = JSON.stringify(parsedUtm);
	}

	openSendTest(): void {
		this.modalService.open(SendTestModalComponent, 'md', <ModalOptions<any>>{
			initialState: {
				subMethodId: this.template.subMethodId,
				template: this.template
			}
		});
	}

	public validateTemplate(): void {
		this.template = this.templateSaveUpdateService.deleteWhitespacesFromAtomicTags(this.template);
		this.manageTemplatesService
			.getValidationOutput(
				{
					subMethodId: this.template.subMethodId,
					channelId: this.template.executionMethodId,
					templateJson: JSON.stringify(this.template)
				},
				this.template.executionMethodId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL
			)
			.subscribe((res) => {
				this.template.isValid = res.isValid && this.template.fromEmail !== -1;
				this.templateContext.updateTemplate(this.template);
				if (res.isValid) {
					this.modalService.openModalMessage('sm', {
						message: this.translateService.instant(
							'features.manage_templates.components.validatePersonalizationTags.TEMPLATE_VALID'
						),
						buttons: [
							{
								class: 'btn-primary',
								label: this.translateService.instant('general.OK'),
								action: () => {
									this.bsModalRef.hide();
								}
							}
						]
					});
				} else {
					this.openValidationModal(res, this.template.otherEditor, this.template.isExecutedInvalid);
				}
			});
	}

	private openValidationModal(validationOutput, isOtherEditor, isExecutedInvalid): void {
		this.modalService.open(ValidatePersonalizationTagsComponent, 'lg', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				validationOutput: validationOutput,
				isOtherEditor: isOtherEditor,
				isExecutedInvalid: isExecutedInvalid
			}
		});
	}

	isSendTestEnabled(): boolean {
		if (this.template.executionMethodId === ExecutionChannel.Optipush) {
			return !!this.template.text || !!this.template.subject;
		}
		return !!this.template.subject && this.template.fromEmail !== -1 && !!this.template.fromName;
	}

	isRunCampaignEnabled(): boolean {
		return (this.template.isValid && this.template.templateId && this.IsValidSubMethodId() && !this.isTemplateDirty);
	}

	redirectToRunCampaign(type: string): void {
		const clientTemplateId = this.template.clientTemplateId;
		let additionalProperties;
		const template = clientTemplateId !== undefined && clientTemplateId !== null ? clientTemplateId : this.template.templateId;
		if (type === 'triggered') {
			additionalProperties = {
				templates: template
			};
		} else {
			additionalProperties = {
				templateGroupId: this.template.subMethodId,
				templateId: template,
				appIds: {}
			};
		}
		var channelProperties = {
			channelId: this.template.executionMethodId,
			...additionalProperties
		};

		this.templateNavigationService.navigateToCampaign(JSON.stringify(channelProperties), type);
	}

	IsValidSubMethodId(): boolean {
		const templateSubMethod = this.template.subMethodType;
		return (
			this.isPopup ||
			(templateSubMethod !== SubMethodType.Transactional &&
				templateSubMethod !== SubMethodType.TriggeredCustomerCampaigns &&
				templateSubMethod !== SubMethodType.TriggeredVisitorCampaigns)
		);
	}

	save(): void {
		if (!this.templateLobbyQueryParams.channelId) return;

		this.isSaving = true;
		this.templateSaveUpdateService.save(this.template, +this.templateLobbyQueryParams.channelId)
			.pipe(finalize(() => this.isSaving = false)).subscribe(
				(templateId: number) => {
					if (templateId) {
						this.manageTemplatesService
							.getTemplateDetails({ templateId: templateId.toString() })
							.subscribe((response: TemplateDetails) => {
								if (response && response.templateId) {
									this.templateContext.updateTemplate(response);
								}
								this.templateNavigationService.navigateToEditTemplate(
									+this.templateLobbyQueryParams.channelId,
									templateId,
									+this.templateLobbyQueryParams.folderId,
									+this.templateLobbyQueryParams.typeId,
									null,
									this.templateLobbyQueryParams.search
								);
								this.folderService.reloadFoldersCache(
									+this.templateLobbyQueryParams.channelId,
									this.templateLobbyQueryParams.brandId
								);
								this.cd.detectChanges();
							});
						if(this.featureFlagService.isEnabled(FeatureFlag.TemplatesOptimailMultiLanguageFlow)) {
							this.templateContext.refreshNumberOfLanguages([
								{
									templateId: templateId,
									subMethodId: +this.templateLobbyQueryParams.typeId
								}
							]);
						}
						this.templateSaved.emit(templateId);
					}
				},
				() => {
					this.cd.detectChanges();
				}
			);
	}

	cancel(): void {
		const path = this.router.url;
		if (path && this.templateLobbyQueryParams.channelId) {
			const pathToManageTemplates = path.substring(0, path.indexOf('templates'));
			this.templateLobbyUrl = `${pathToManageTemplates}templates/show/${this.templateLobbyQueryParams.channelId}`;
			this.templateLobbyQueryParams = {
				brandId: this.templateLobbyQueryParams.brandId,
				folderId: this.templateLobbyQueryParams.folderId,
				typeId: this.templateLobbyQueryParams.typeId,
				search: this.templateLobbyQueryParams.search,
				channelId: this.templateLobbyQueryParams.channelId
			};
		}
		this.router.navigate([this.templateLobbyUrl], {
			queryParams: this.templateLobbyQueryParams
		});
	}

	public onEditMode() {
		this.isTemplateNameEditable = !this.isTemplateNameEditable;
		setTimeout(() => {
			this.templateNameNativeElement.nativeElement.focus();
		});
	}

	public onTemplateNameBlur() {
		if (this.templateNameInput.valid) {
			this.isTemplateNameEditable = !this.isTemplateNameEditable;
		}
	}

	public onEnter(event: any) {
		if (event instanceof KeyboardEvent && event.key === 'Enter') {
			this.templateNameNativeElement.nativeElement.blur();
		}
	}
}
