import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from '@angular/core';
import { SecretService } from "../../services/secret.service";
import { S3SecretModel } from "../../models/S3SecretModel";
import { forkJoin } from "rxjs";
import { IListItem } from "@optimove/ui-sdk/common/models";
import { AwsRegion } from "../../models/awsRegion";
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastModel } from "src/app/features/missionControl/models/toast.model";
import { locationParameter } from 'src/app/models/locationParameter.enum';
import { IconType } from '@optimove/ui-sdk/common/models';
import { finalize } from "rxjs/operators";
import { GcsSecretModel } from '../../models/gcsSecretModel';

@Component({
  selector: "credentials-form",
  templateUrl: "./credentialsForm.component.html",
  styleUrls: ["./credentialsForm.component.scss"],
})
export class CredentialsFormComponent implements OnInit {
  toastParams: ToastModel = {
    isOpen: false,
    message: '',
    icon: 'info',
    showCloseIcon: true,
    location: locationParameter.Default,
    toastIconType: IconType.AngularMaterial
  };
  public pathItems = [
    "brand",
    "customerId",
    "campaignType",
    "campaignId/campaignSeriesId",
    "channel",
    "contact",
  ];

  public s3Secret: S3SecretModel = {} as S3SecretModel;
  public gcsSecretVal: GcsSecretModel = {} as GcsSecretModel;
  public isLoading: boolean = true;
  allRegions: IListItem[];
  selectedRegion: string;
  originalRegions: AwsRegion[];
  isSaving: boolean;
  isVerifying: boolean;
  public isGcs: boolean;
  public isAws: boolean = true;
  public readonly awsType = "s3";
  public readonly gcsType = "gcs";

  constructor(private secretService: SecretService, private modalService: OptiLogicModalService, private translate: TranslateService) {}

  ngOnInit() {
    this.secretService.getSecretPath().subscribe(secretPathConfig=>{
      if(secretPathConfig) {
        const pathString = secretPathConfig['Path'] === null || secretPathConfig['Path'] === undefined ? "" : secretPathConfig["Path"];
        if (secretPathConfig['StorageType'] === this.awsType) {
          this.initAwsForm(pathString);
        } else if (secretPathConfig['StorageType'] === this.gcsType) {
          this.initGcsForm(pathString);
        }
      } else {
        this.initAwsRegions();
      }
    })
  }

  public initAwsRegions(){
    this.secretService.getAwsRegions().subscribe(regions => {
      this.originalRegions = regions;
      this.allRegions = regions.map<IListItem>(t => {
        return { id: t.SystemName, name: `${t.DisplayName} [${t.SystemName}]` };
      });
      this.isLoading = false;
    });
    }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
    );
  }

  initAwsForm(pathString){
    forkJoin([
      this.secretService.getSecret(),
      this.secretService.getAwsRegions()
    ])
    .subscribe(([secret, regions]) => {
      this.originalRegions = regions;
      this.allRegions = regions.map<IListItem>(t => {
        return { id: t.SystemName, name: `${t.DisplayName} [${t.SystemName}]` };
      });
      this.initSecretFormData(secret);
      this.initS3SecretPath(pathString);
      this.isLoading = false;
    });
  }

  initGcsForm(pathString){
    this.isGcs = true;
    this.isAws = false;
    this.secretService.getGcsSecret().subscribe( gcsSecret=>{
      this.gcsSecretVal = gcsSecret;
      this.initS3SecretPath(pathString);
      this.isLoading = false;
    });
  }
  verifyCredentials(): void {
    this.s3Secret.Region = this.originalRegions.find(r => r.SystemName === this.selectedRegion)?.SystemName;
    this.isVerifying = true;
    this.isLoading = true;
    this.secretService.checkS3BucketCredentials(this.s3Secret).pipe(finalize(() => {
      this.isVerifying = false;
      this.isLoading = false;
    })).subscribe(result => {
      this.openSaveResultModal(result.IsValid);

    }, () => {
      this.openSaveResultModal(false);
    });
  }

  save(): void {
    this.modalService.openModalMessage('sm', {
      message: this.translate.instant('features.user_settings.body.message_archiving.credentials.CRED_SAVE_CONFIRMATION'),
      buttons: [
        {
          class: 'btn-primary',
          label: this.translate.instant('general.YES'),
          action: () => {
            this.isSaving = true;
            const pathString = this.pathItems.join('/');
            this.isLoading = true;

            if (this.isAws) {
              this.s3Secret.Region = this.originalRegions.find(r => r.SystemName === this.selectedRegion)?.SystemName;
              this.saveAwsTypeCreds(pathString);
            } else if(this.isGcs){
              this.saveGcsTypeCreds(pathString);
            }

          }
        },
        {
          class: 'btn-default',
          label: this.translate.instant('general.NO'),
          action: () => {}
        }
      ]
    });
  }

  public getType(){
    return this.isAws ? this.awsType : this.gcsType;
  }

  public saveAwsTypeCreds(pathString){
    this.secretService.saveCredentials(this.s3Secret).pipe(finalize(() => {
      this.isVerifying = false;
      this.isSaving = false;
      this.isLoading = false;
    })).subscribe((secretSaveRes) => {
      this.openSaveResultModal(secretSaveRes.IsValid);
      if(secretSaveRes.IsValid){
        this.saveCredsPath(pathString);
      }
    });
  }

  public saveGcsTypeCreds(pathString){
    this.secretService.saveGcsCredentials(this.gcsSecretVal).pipe(finalize(() => {
      this.isVerifying = false;
      this.isSaving = false;
      this.isLoading = false;
    })).subscribe((secretSaveRes) => {
      this.openSaveResultModal(secretSaveRes.IsValid);
      if(secretSaveRes.IsValid){
        this.saveCredsPath(pathString);
      }
    });
  }
  public saveCredsPath(pathString: string){
    this.secretService.saveCredentialPath(pathString, this.getType() ).pipe(finalize(() => {
      this.isSaving = false;
      this.isLoading = false;
    })).subscribe((isPathSaveSuccessful) => {
      if (isPathSaveSuccessful) {
        this.toastParams = {
          ...this.toastParams,
          isOpen: true,
          message: this.translate.instant('general.SUCCESS')
        };
      } else {
        this.toastParams = {
          ...this.toastParams,
          isOpen: true,
          message: this.translate.instant('general.FAILED')
        };
      }
    });
  }
  private openSaveResultModal(isValid: boolean): void {
    if (isValid) {
      this.toastParams = {
        ...this.toastParams,
        isOpen: true,
        message: this.translate.instant('general.SUCCESS')
      };
    } else {
      this.modalService.openModalMessage('sm', {
        message: this.translate.instant('features.user_settings.body.message_archiving.credentials.NOT_VALID'),
        buttons: [
          {
            class: 'btn-primary',
            label: this.translate.instant('general.OK'),
            action: () => {}
          }
        ]
      });
    }
  }

  private initSecretFormData(secret: S3SecretModel): void {
    this.s3Secret = secret;
    this.s3Secret.SecretKey = '';
    this.s3Secret.AccessKey = '';
    this.selectedRegion = this.originalRegions.find(r => r.SystemName === secret.Region)?.SystemName;
  }

  private initS3SecretPath(path: string): void {
    if (path.length === 0) return;
    this.pathItems = path.split('/');
    const index = this.pathItems.indexOf('campaignId');
    if (index > -1) {
      this.pathItems.splice(index, 2);
    }
    this.pathItems.splice(index, 0, 'campaignId/campaignSeriesId');
  }
   public awsClicked(){
    this.isGcs = false;
    this.isAws = true;
    const pathString = this.pathItems.join('/');
    this.initAwsForm(pathString);
   }
  public gcpClicked(){
    this.isAws = false;
    this.isGcs = true;
    const pathString = this.pathItems.join('/');
    this.initGcsForm(pathString);
  }

  verifyGcsCreds($event){
    this.isVerifying = true;
    this.isLoading = true;
    this.secretService.checkGcpBucketCredentials(this.gcsSecretVal).pipe(finalize(() => {
      this.isVerifying = false;
      this.isLoading = false;
    })).subscribe(result => {
      this.openSaveResultModal(result.IsValid);

    }, () => {
      this.openSaveResultModal(false);
    });
  }
}
