import { InternalFeatureFlags } from './../models/InternalFeatureFlags';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { RedirectToAuthService } from 'src/app/services/redirectToAuth.service';
import { SsmService } from 'src/app/services/ssm.service';
import { FeatureFlag, FeatureFlagService } from '../../../services/featureFlag.service';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import NavbarTreeData from "../models/navbarTreeData";
import { LogicalOperatorType, NavbarTreeNode, NavbarTreeNodeView, TreeNode, UserPermissions } from '../models/navbarTreeNode.model';
import { TenantDetails } from '../models/tenantDetails.model';
import { StorageService } from 'src/app/services/storage.service';
import { SessionStorageKeys } from 'src/app/features/missionControl/models/storage.model';
import { SsmServiceEndPoints } from '../../../services/optimove-ssm/optimove-ssm-resources.model';
import { InternalFeatureFlagService } from 'src/app/services/internalFeatureFlag.service';
import { Channel } from 'src/app/features/manageTemplates/models/metadataResponse';
import { ChannelIds } from 'src/app/models/channelIds.enum';

@Injectable({
  providedIn: 'root'
})

export class NewNavbarService {
  private mainControllerURL = '/Main';
  private ssm: any;
  private menuItems$ = new BehaviorSubject<NavbarTreeNodeView[]>([]);
  private internalFeatureFlags: InternalFeatureFlags = {
    RealtimeProfileDataManagement: null,
    RealtimeProfileDataManagementForUser: null
  }

  _unauthorizedService: any;
  private get unauthorizedService(): any {
    if (!this._unauthorizedService) {
      this._unauthorizedService = this.injector.get('unauthorizedService');
    }
    return this._unauthorizedService;
  }

  _legacyRedirectManagerService: any;
  private get legacyRedirectManagerService(): any {
    if (!this._legacyRedirectManagerService) {
      this._legacyRedirectManagerService = this.injector.get('legacyRedirectManager');
    }
    return this._legacyRedirectManagerService;
  }
  

  constructor(
    private readonly http: HttpService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly internalFeatureFlagService: InternalFeatureFlagService,
    private readonly ssmService: SsmService,
    private readonly injector: Injector,
    private readonly redirectToLoginService: RedirectToAuthService,
    private readonly storageService: StorageService,
  ) {
    this.ssm = this.ssmService.getSSM();
  }

  public setMenuItems(menuItems: NavbarTreeNodeView[]) {
    this.menuItems$.next(menuItems);
  }

  public getMenuItems(): NavbarTreeNodeView[] {
    return this.menuItems$.getValue();
  }

  public getVisibleMenuItems$(): Observable<NavbarTreeNodeView[]> {
    return this.menuItems$.pipe(
      map((menuItems) => this.getVisibleMenuItemsHelper(menuItems))
    )
  }

  public getVisibleMenuItemsHelper(menuItems: NavbarTreeNodeView[]): NavbarTreeNodeView[] {
    menuItems.forEach((navbarItem) => {
      if (navbarItem?.children != null && navbarItem.children.length != 0) {
        return this.getVisibleMenuItemsHelper(navbarItem?.children);
      }
    })
    return menuItems.filter(item => item.isVisible);
  }

  getNavbarTreeView(): Observable<NavbarTreeNodeView[]> {
        return forkJoin([
        this.getUserPermissions(),
          this.getDisabledRoutes(),
          this.getInternalFeatureFlags(),
          this.ssmService.GetGeneric<TenantDetails>(SsmServiceEndPoints.TenantDetails),
          this.ssmService.GetGeneric<boolean>(SsmServiceEndPoints.IsOptitrackEnabled),
          this.ssmService.GetGeneric<boolean>(SsmServiceEndPoints.IsRealtimeEnable),
          this.ssmService.GetGeneric<boolean>(SsmServiceEndPoints.IsOptimailEnabled),
          this.ssmService.GetGeneric(SsmServiceEndPoints.FeatureFlags),
      ]).pipe(
        map(([permissionsList, disabledRoutesList, internalFFs, tenantDetails, isOptitrackEnabled, isRealtimeEnable, isOptimailEnabled]) => {
          let navbarTreeView = this.transformToView(NavbarTreeData, permissionsList as UserPermissions[]);
          return this.changeNavbarTreeByAdditionalParameters(navbarTreeView, disabledRoutesList as string[], 
            tenantDetails as TenantDetails, isOptitrackEnabled as boolean, isRealtimeEnable as boolean, isOptimailEnabled as boolean, internalFFs as InternalFeatureFlags);
        })
      );
  }

  logout() {
    this.redirectToLoginService.logout();
  }

  private transformToView(tree: NavbarTreeNode[], permissionsList: UserPermissions[]): NavbarTreeNodeView[] {
    return tree?.map(node => ({
      ...node,
      children: this.transformToView(node?.children, permissionsList),
      isDisabled: !this.evaluatePermission(node?.permissions, permissionsList)
    } as NavbarTreeNodeView)) ?? [];
  }

  private getUserPermissions(): Observable<UserPermissions[]> {
    return this.http.get<UserPermissions[]>(`${this.mainControllerURL}/GetUserPermissions`, {}).pipe(
      map((res) => {
        this.storageService.setDataToSessionStorage(SessionStorageKeys.userPermissions, res);
        return res;
      }))
  }

  private getDisabledRoutes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.mainControllerURL}/GetDisabledRoutes`, {}).pipe(
      map((res) => {
        return res;
      }))
  }

  private getInternalFeatureFlags(): Observable<InternalFeatureFlags> {
    return forkJoin([
      this.internalFeatureFlagService.isEnabled(FeatureFlag.RealtimeProfileDataManagement),
      this.internalFeatureFlagService.isEnabled(FeatureFlag.RealtimeProfileDataManagementForUser),
    ]).pipe(
      map(([RealtimeProfileDataManagement, RealtimeProfileDataManagementForUser]) => {
        this.internalFeatureFlags.RealtimeProfileDataManagement = RealtimeProfileDataManagement;
        this.internalFeatureFlags.RealtimeProfileDataManagementForUser = RealtimeProfileDataManagementForUser;
        return this.internalFeatureFlags;
      })
    );
  }

  private isLeaf(node: TreeNode<number | LogicalOperatorType>): boolean {
    return node.left == null && node.right == null;
  }

  public getActiveItems(menuItems: NavbarTreeNodeView[], url: string): NavbarTreeNodeView[] {
    if (menuItems == null || menuItems.length === 0) {
      return [];
    }

    for (let j = 0; j < menuItems.length; j++) {
      const menuItem = menuItems[j];
      if (menuItem.children == null || menuItem.children.length === 0) {
        if (menuItem.routerLink != null) {
          if (menuItem.routerLink.toLocaleLowerCase() === url.toLocaleLowerCase()) {
            return [menuItem];
          }
        }
      }
    }

    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];
      if (menuItem.children == null || menuItem.children.length === 0) {
        if (menuItem.routerLink != null) {
          if (url.toLocaleLowerCase().includes(menuItem.routerLink.toLocaleLowerCase())) {
            return [menuItem];
          }
        }
      } else {
        const activeItemsForChild = this.getActiveItems(menuItem.children, url);
        if (activeItemsForChild != null && activeItemsForChild.length !== 0) {
          return [menuItem, ...activeItemsForChild];
        }
      }
    }

    return [];
  }

  private processExpression(booleanOperator: LogicalOperatorType, x: boolean, y: boolean) {
    if (booleanOperator === LogicalOperatorType.And) {
      return x && y;
    }
    if (booleanOperator === LogicalOperatorType.Or) {
      return x || y;
    }

    return true;
  }

  public evaluatePermission(currentNode: TreeNode<number | LogicalOperatorType>, permissionsList: UserPermissions[]): boolean {
    if (currentNode?.value == null) {
      return true;
    }

    if (this.isLeaf(currentNode)) {
      const permission = permissionsList.find(permissionItem => permissionItem.permissionId === currentNode.value);
      return permission?.isEnabled == true;
    }

    let x = this.evaluatePermission(currentNode.left, permissionsList);
    let y = this.evaluatePermission(currentNode.right, permissionsList);

    return this.processExpression(currentNode.value, x, y);
  }

  private changeNavbarTreeByAdditionalParameters(navbarTreeView: NavbarTreeNodeView[], disabledRoutesList: string[], tenantDetails: TenantDetails, isOptitrackEnabled: boolean, isRealtimeEnable: boolean, isOptimailEnabled: boolean, internalFFs: InternalFeatureFlags): NavbarTreeNodeView[] {

    navbarTreeView.forEach((navbarItem) => {

      if (disabledRoutesList?.length > 0) {
        this.changePageStateByDisabledRoute(navbarItem, disabledRoutesList);
      }

      if (navbarItem.name === "Visits Explorer" && !isOptitrackEnabled) {
        navbarItem.isDisabled = true;
      }

      if (navbarItem.name === "Realtime Triggers" && !isRealtimeEnable) {
        navbarItem.isDisabled = true;
      }

      if (navbarItem?.children != null) {
        navbarItem.children = this.changeNavbarTreeByAdditionalParameters(navbarItem?.children, disabledRoutesList, tenantDetails, isOptitrackEnabled, isRealtimeEnable, isOptimailEnabled, internalFFs);
      }

      if (navbarItem.name === "Dashboard" && this.featureFlagService.isEnabled(FeatureFlag.TheNewDashboard)) {
        navbarItem.routerLink = "/category/analysis_&_research/dashboard"
      }

    })

    return navbarTreeView.filter(item => {
     
      if (
        (item.name == "Cohorts Analysis" && !this.featureFlagService.isEnabled(FeatureFlag.CohortsAnalysisReport)) ||
        (item.name == "Business Performance Overview" && !this.featureFlagService.isEnabled(FeatureFlag.ShowBusinessPerformanceOverviewReport)) ||
        (item.name == "Repeat Rate Analysis" && !this.featureFlagService.isEnabled(FeatureFlag.ShowRepeatRateAnalysisReport)) ||
        (item.name == "Benchmark Report" && !this.featureFlagService.isEnabled(FeatureFlag.ShowGamingBenchmarkReport)) ||
        (item.name == "Realtime Customer Profile" && !isRealtimeEnable) ||
        (item.name == "Orchestration Overview" && !this.featureFlagService.isEnabled(FeatureFlag.showOrchestrationOverviewPermission) && !this.isOptimoveAdmin(tenantDetails)) ||
        (item.name == "Target Group Discovery" && !this.featureFlagService.isEnabled(FeatureFlag.AudienceDiscoveryPage)) ||
        (item.name == "Data Ingestion" && !this.featureFlagService.isEnabled(FeatureFlag.DataIngestionPage)) ||
        (item.name == "Real-Time Attributes" && !this.isRealTimeAttributesEnabled(tenantDetails, internalFFs)) ||
        (item.name == "Imported Customers" && !isOptimailEnabled) ||
        (item.name == "Promotions" && !this.featureFlagService.isEnabled(FeatureFlag.OptiPromo))
        ) {
        return false;
      }

      return true;
    });
  }
  
  private isRealTimeAttributesEnabled(tenantDetails: TenantDetails, internalFFs: InternalFeatureFlags): boolean {
    const OptimoveAdmin = this.isOptimoveAdmin(tenantDetails);
    const Admin = this.isAdmin(tenantDetails);
    return (internalFFs.RealtimeProfileDataManagement && OptimoveAdmin) 
      || (internalFFs.RealtimeProfileDataManagementForUser && Admin);
  }

  private isOptimoveAdmin(tenantDetails: TenantDetails) {
    return tenantDetails.userRole == "OptimoveAdmin";
  }

  private isAdmin(tenantDetails: TenantDetails) {
    return tenantDetails.userRole == "Admin";
  }

  private changePageStateByDisabledRoute(navbarItem: NavbarTreeNodeView, disabledRoutesList: string[]) {
    let isNavbarItemRouteInList = disabledRoutesList.find(disabledRoute => disabledRoute == navbarItem.routerLink)

    if (isNavbarItemRouteInList) {
      navbarItem.isDisabled = true;
    }
  }

  public initializeLegacyServices(navItems: NavbarTreeNodeView[]) {
    this.ssmService.GetGeneric<boolean>(SsmServiceEndPoints.IsSettingsAvailable).subscribe((isSettingsAvailable: boolean) => {
      this.setupUnauthorized(this.deepCopy(navItems), isSettingsAvailable);
      this.legacyRedirectManagerService.init();
    });
  }

  private deepCopy(items: any[]): any[] {
    return JSON.parse(JSON.stringify(items));
  }

  private setupUnauthorized(navItems: NavbarTreeNodeView[], isSettingsVisible: boolean) {
    let unauthorizedUrlsList = this.getUnauthorizedUrls(navItems);
    if (!isSettingsVisible) {
      unauthorizedUrlsList.push('/#/settings')
    }
    this.unauthorizedService.init(unauthorizedUrlsList);
  }

  public getUnauthorizedUrls(navbarTree: NavbarTreeNodeView[]): string[] {
    let unauthorizedUrls = [];

    for (let navbarItem of navbarTree) {

      if (navbarItem.routerLink != null && navbarItem.isDisabled) {
        unauthorizedUrls.push(this.nonnormalize(navbarItem.routerLink))
      }

      if (navbarItem.children && navbarItem.children.length != 0) {
        unauthorizedUrls = unauthorizedUrls.concat(this.getUnauthorizedUrls(navbarItem.children));
      }
    }
    return unauthorizedUrls;
  }

  public normalize(path: string): string {
    return path && path.replace('/#', '').replace('#', '');
  }

  public nonnormalize(path: string): string {
    return '/#' + unescape(this.normalize(path));
  }

  public getDefaultUrl(): Promise<string> {
    return this.ssmService.GetGeneric<string>(SsmServiceEndPoints.DefaultPage).toPromise().then((defaultPageName: string) => {
      if(!defaultPageName) return null;
      const defaultLink = this.getDefaultPageURL(defaultPageName, this.getMenuItems());
      return defaultLink ? defaultLink : '/category/Analysis_&_Research/Dashboard.aspx';
    });
  }

  getDefaultPageURL(defaultPageName: string, navbarTree: NavbarTreeNodeView[]) {
    if (navbarTree == null) {
      return null
    }

    for (let navbarItem of navbarTree) {
      if (navbarItem.routerLink != null && (!navbarItem.children || navbarItem.children.length === 0) && defaultPageName === navbarItem.name) {
        return navbarItem.routerLink
      }

      let defaultPageLink: string;

      defaultPageLink = this.getDefaultPageURL(defaultPageName, navbarItem.children);

      if (defaultPageLink) {
        return defaultPageLink
      }
    }
  }

  public sortChannels(channelA: Channel, channelB: Channel){
      if (channelA.id == ChannelIds.Optimail || channelB.id === ChannelIds.Optimail) {
        return 1;
      }
      if (channelA.name.toLocaleLowerCase().includes('opti') && channelB.name.toLocaleLowerCase().includes('opti')) {
        return channelA.name.localeCompare(channelB.name);
      }
      if (channelA.name.toLocaleLowerCase().includes('opti') && !channelB.name.toLocaleLowerCase().includes('opti')) {
        return -1;
      }
      if (!channelA.name.toLocaleLowerCase().includes('opti') && channelB.name.toLocaleLowerCase().includes('opti')) {
        return 1;
      }

      return channelA.name.localeCompare(channelB.name)
  }
}