export enum ChannelIds {
    Multi = -1,
    Silverpop = 4,
    SFMCEmail = 5,
    FacebookCustomAudience = 8,
    Emarsys = 9,
    Inwise = 11,
    TextLocal = 12,
    Appoxee = 14,
    Optimail = 15,
    Bronto = 16,
    GoogleDisplayNetwork = 17,
    CarmaEmail = 19,
    CarmaSMS = 20,
    Marketo = 21,
    GoogleCustomerMatch = 22,
    BrazePush = 23,
    AppboyPush = 23,
    Responsys = 24,
    CarmaAdHocSMS = 25,
    CarmaAdHocEmail = 26,
    Instagram = 27,
    ExactTargetMobilePush = 28,
    Optipush = 29,
    BrazeWebHook = 30,
    AppBoyWebhook = 30,
    AppBoyWebpush = 31,
    AppBoyEmail = 33,
    BrazeEmail = 33,
    Criteo = 34,
    Control = 100,
    RealtimeOnSiteMessaging = 200, //web popup
    RealtimeAPI = 201,
    RealtimePopup = 201,
    RealtimeSMS = 202,
    RealtimePush = 203,
    RealtimeEmail = 204,
    RealtimeWebPush = 205,
    RealtimeInAppInbox = 206,
    RealtimeInPlatform = 207,
    EDS = 208,
    OtherLevels = 401,
    XtremePush = 402,
    MarketoPush = 403,
    DynamicMessagin = 410,
    Wiraya = 411,
    ActiveTrail = 420,
    CampaignMonitor = 421,
    OptiMobileInApp = 427,
    M2C888 = 428,
    MMG = 440,
    Mobivate = 441,
    Cellent = 442,
    MarketoSMS = 443,
    XtremePushSMS = 444,
    ExactTargetSMS = 445,
    Sitecore = 446,
    RetargetingViaAPI = 454,
    OtherLevelsWebPush = 460,
    XtremePushWebPush = 461,
    ChatLeap = 470,
    OtherLevelsInAppInbox = 480,
    OptiMobilePush = 489,
    WebPushNotifications = 490,
    OptiText = 493,
    LobbyBanner = 501,
    CallCenter = 502,
    DirectMail = 503,
    Email = 504,
    SMS = 505,
    PushNotification = 506,
    InPlatform = 507,
    MyThings = 508,
    Other = 509,
    WebPush = 510,
    InAppInbox = 511,
    Retargeting = 512,
    PushNotificationIos = 513,
    PushNotificationAndroid = 514,
    ChatBot = 515   
}