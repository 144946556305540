import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModalOptions } from "ngx-bootstrap/modal";
import { IModalButtonConf, OptiLogicModalService } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { AddImportedCustomersModalComponent } from "../components/add-imported-customers-modal/add-imported-customers-modal.component";
import { ImportLeadsLoaderModalComponent } from "../components/import-customers-loader-modal/import-customers-loader-modal.component";
import {ImportResultModalComponent} from "../components/import-result-modal/import-result-modal.component"
import { ImportedCustomersErrors } from "../models/internal/imported-customers-errors.enum";

@Injectable()
export class ImportedCustomersModalService{
	constructor(
        private translate: TranslateService,
        private modalService: OptiLogicModalService) {}

	public openModalMappingModal(isInitialMapping = true){
		return this.modalService.open(
			AddImportedCustomersModalComponent,
			"lg", 
          <ModalOptions<any>>{
          	ignoreBackdropClick: true,
          	keyboard: true,
          	class: "add-imported-customers-modal",
          	initialState: {
          		isInitialMapping: isInitialMapping
          	}
          });
	}
  
	public openImportLoader(){
		return this.modalService.open(
			ImportLeadsLoaderModalComponent,
			"sm", 
            <ModalOptions<any>>{
            	ignoreBackdropClick: true,
            	keyboard: false,
            	class: "import-customers-loader-modal"
            });
	}
  
	public openImportResultModal(total: number, successfullyImported: number, alreadyExists: number, duplicates: number, invalidEmail: number){
		return this.modalService.open(
			ImportResultModalComponent,
			"sm", 
            <ModalOptions<any>>{
            	ignoreBackdropClick: true,
            	keyboard: true,
            	class: "import-result-modal",
            	initialState:{
            		total: total,
            		successfullyImported: successfullyImported,
            		alreadyExists: alreadyExists,
            		duplicates: duplicates,
            		invalidEmail: invalidEmail
            	}
            });
	}

	public onErrorInProcess(error?: ImportedCustomersErrors, parameters?: string) {
		switch (error) {
			case ImportedCustomersErrors.CSVFileSizeIsTooBig:
				this.showPopUpError("TITLE", "FAILED_TO_UPLOAD_FILE_SIZE");
				break;
			case ImportedCustomersErrors.CSVFileIncorectType:
				this.showPopUpError("TITLE", "FAILED_TO_UPLOAD_FILE_CSV");
				break;
			case ImportedCustomersErrors.FailedToImportCsv:
				this.showPopUpError("TITLE", "FAILED_TO_IMPORT_CSV", parameters)
				break;
			case ImportedCustomersErrors.FailedToGetEmailAttribute:
				this.showPopUpError("TITLE", "FAILED_TO_GET_EMAIL_ATTRIBUTE", parameters);
				break;
			default:
				break;
		}
	}

	private openModalLogicMessage(
		title: string,
		message: string,
		size: string,
		buttons: IModalButtonConf[]) {
		return this.modalService.openOptiLogicModal(
			size,
			{
				title: title,
				html: message,
				buttons: buttons,
			},
          <ModalOptions<any>>{
          	ignoreBackdropClick: true,
          	class: "import-customers-csv",
          }
		);
	}
    
	private showPopUpError(title: string, error: string, parameters?: string) {
		return this.openModalLogicMessage(
			this.translate.instant(
				`features.user_settings.body.importedCustomers.${title}`
			),
			this.translate.instant(
				`features.user_settings.body.importedCustomers.ERRORS.${error}`, { parameters: parameters}
			),
			"sm",
			[
				{
					class: "btn-primary",
					label: this.translate.instant("general.OK"),
					action: () => {},
				},
			]
		);
	}
      
}