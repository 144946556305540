<div class="chips-container" [ngClass]="mode">
    <div *ngFor="let item of itemsWithSelection;" class="chip">
        <input type="checkbox" *ngIf="!item.isDisabled" [checked]="item.isSelected" (keydown)="onKeyDown($event, item)" (change)="onSelectionChanged(item)" [disabled]="item.isDisabled || isDisabled">

        <div class="label-container" [ngClass]="{'disabled': item.isDisabled || isDisabled}">
            <div *ngIf="mode == ChipsMode.PRIMARY" class="checkmark" [ngClass]="{'visible': item.isSelected, 'disabled': item.isDisabled}">
                <i class="material-icons">check</i>
            </div>
            <div>{{ item.text }}</div>
        </div>
    </div>
</div>