import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoles } from '../models/userRole.enum';
import { HttpService } from './optimove-http/optimove-http.model';
import { SsmService } from './ssm.service';

@Injectable({ providedIn: 'root' })
export class CogwheelFeatureFlagService {
    readonly getFeaturesFlagsURL: string = "/Main/getExportButtonToAdminUserFeatureFlagStatus";
    constructor(private readonly httpClient: HttpService,
                private ssmService: SsmService) {
                    
    }

    public isExportButtonToAdminUserFeatureFlagEnabled() : Observable<boolean> {
        return this.httpClient.get<boolean>(this.getFeaturesFlagsURL);
    }

    /**
     * 
     * @returns true for 'has permission to export data' in case that user has admin permissions and config flag is 'on'
     */
    public checkIfHasExportPermissions(): Observable<boolean> {
        var ssm = this.ssmService.getSSM();
        var tenantDetails = ssm.GetGeneric(ssm.Resx.TenantDetails);
        
        var isAdmin = tenantDetails.userRole.toLocaleLowerCase() === UserRoles.admin 
                    || tenantDetails.userRole.toLocaleLowerCase() === UserRoles.optimoveadmin;
                    
        return isAdmin ? of(true) : this.isExportButtonToAdminUserFeatureFlagEnabled().pipe(map(response =>{
            return !response;
        }));
    }
}

export enum CogwheelFeatureFlag {
    isAlwaysExcludeForAdminsEnabled = "isAlwaysExcludeForAdminsEnabled",
    isEndUserEventManagementFeatureEnabled = "isEndUserEventManagementFeatureEnabled",
    isExportButtonToAdminUserFeatureEnabled = "isExportButtonToAdminUserFeatureEnabled",
    isPurchaseHistoryMultiAttributeFlowFeatureEnabled = "isPurchaseHistoryMultiAttributeFlowFeatureEnabled",
    isRealtimeProfileDataManagementFeatureEnabled = "isRealtimeProfileDataManagementFeatureEnabled",
    isRealtimeProfileDataManagementFeatureForEndUserEnabled = "isRealtimeProfileDataManagementFeatureForEndUserEnabled",
    isSuccessHubEnabled = "isSuccessHubEnabled",
}

