import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AndroidNotificationChannelsComponent } from "./components/androidNotificationChannels/androidNotificationChannels.component";
import { ComponentsModule } from "../../components/components.module";
import { AndroidNotificationChannelsService } from "./components/androidNotificationChannels/androidNotificationChannels.service";
import {SharedModule} from "../../shared.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {IosNotificationChannelsComponent} from "./components/iosNotificationChannel/iosNotificationChannel.component";

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        SharedModule,
        TooltipModule
    ],
    exports: [
        AndroidNotificationChannelsComponent,
        IosNotificationChannelsComponent
    ],
    declarations: [
        AndroidNotificationChannelsComponent,
        IosNotificationChannelsComponent
    ],
    providers: [
        AndroidNotificationChannelsService
    ]
})
export class OptiPushModule {}



