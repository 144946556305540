import { NgModule } from "@angular/core";
import { InputSearchComponent } from "./inputSearch.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectivesModule,
        PipesModule,
    ],
    providers: [
    ],
    declarations: [
        InputSearchComponent
    ],
    exports: [
        InputSearchComponent
    ]
})

export class InputSearchModule {
}