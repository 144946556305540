import {Injectable} from "@angular/core";


@Injectable()
export class SerializationService {

     serializeToCSV(headers: string[], data: any[], objectSerializerFunction: (data: any) => string): string {
        let csvString = '';
        if (!headers) {
            headers = [];
            for (let prop in data[0]) {
                headers.push(prop);
            }
        }
        csvString += headers.join(',') + "\n";

        let defaultObjectSerializerFunction = function (obj) {
            let propValuesArr = [];
            for (let prop in obj) {
                propValuesArr.push('"' + obj[prop] + '"');
            }
            return propValuesArr.join(',');
        }
        data.forEach(function (item) {
            let objString = objectSerializerFunction ? objectSerializerFunction(item) : defaultObjectSerializerFunction(item);
            if (objString)
                csvString += objString + "\n";
        });

        return csvString;
    }
}