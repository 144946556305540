import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { EventType } from '@optimove/ui-sdk/common/models';
import { AppBusService } from '@optimove/ui-sdk/common/services';
import { BehaviorSubject, ReplaySubject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OptiAccordionGroupComponent } from 'src/app/components/optiAccordionGroup/optiAccordionGroup.component';
import { NavbarWrapperService } from 'src/app/services/navbarWrapper.service';
import { SsmService } from 'src/app/services/ssm.service';
import { BiStudioService } from '../../../../features/biStudio/services/biStudio.service';
import {
  Channel,
  TemplatesMetadata
} from "../../../../features/manageTemplates/models/metadataResponse";
import { ManageTemplatesService } from "../../../../features/manageTemplates/services/manageTemplates.service";
import { NavbarIconType, NavbarTreeNodeView } from "../../models/navbarTreeNode.model";
import { NewNavbarService } from '../../services/newNavbar.service';

@Component({
  selector: 'sidenavbar',
  templateUrl: './sideNavbar.component.html',
  styleUrls: ['./sideNavbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavbarComponent implements OnInit, OnDestroy {
  @HostBinding("class.collapsed") isCollapsed: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private menuItemsWasInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('mainAccordionGroup') mainAccordionGroup: OptiAccordionGroupComponent;
  parentActiveMenuIndex: number = 0;
  activeItems: NavbarTreeNodeView[] = [];
  initiallyOpenedIndex: number;
  menuItems: NavbarTreeNodeView[] = [];
  channelNames: NavbarTreeNodeView[] = [];
  secondLevelAccordion: NavbarTreeNodeView = null;
  NavbarIconType = NavbarIconType;
  private readonly planItemName = "Plan";
  private readonly templatesItemName = "Templates";
  private _optibotNotificationsCount: number = 0;
  private ssm: any;
  private readonly webInboxChannelId = 495;
  private readonly whatsAppChannelId = 498;
  
  @Input() set optibotNotificationsCount(value: number) {
    this._optibotNotificationsCount = value;
    this.cd.detectChanges();
  }
  get optibotNotificationsCount() {
    return this._optibotNotificationsCount;
  }

  private appBusService : AppBusService = new AppBusService();

  constructor(
    private readonly router: Router,
    private readonly newNavbarService: NewNavbarService,
    private readonly cd: ChangeDetectorRef,
    private readonly manageTemplatesService: ManageTemplatesService,
    private readonly biStudioService: BiStudioService,
    private readonly title: Title,
    private readonly navbarWrapperService: NavbarWrapperService,
    private readonly ssmService: SsmService,) {
      this.ssm = this.ssmService.getSSM();
     }

  ngOnInit(): void {
    this.isCollapsed = JSON.parse(window.sessionStorage.getItem("isSidebarCollapse")) ?? this.isCollapsed;

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationCancel),
        filter((event: NavigationCancel) => event.reason === ''), // means deactivate
        takeUntil(this.destroyed$)
      ).subscribe(event => this.menuItemsWasInit$.next(true));

    const additionalNavbarMenuItems$ = forkJoin([
      this.biStudioService.getReportsWithPermissionsCheck(),
      this.manageTemplatesService.getMetadata().pipe(catchError(() => of({} as TemplatesMetadata)))
    ]);

    this.newNavbarService.getNavbarTreeView()
      .pipe(
        tap(menuItems => {
          this.newNavbarService.setMenuItems(menuItems);
        }),
        switchMap(() => additionalNavbarMenuItems$),
        takeUntil(this.destroyed$),
      ).subscribe(([biStudioReports, templatesMetadata]) => {
        let menuItems = this.newNavbarService.getMenuItems();
        menuItems = this.biStudioService.setBiStudioReportsToNavbar(menuItems, biStudioReports);
        menuItems = this.getChannels(templatesMetadata, menuItems);
        this.newNavbarService.setMenuItems(menuItems);
        this.newNavbarService.initializeLegacyServices(menuItems);
      });
      this.newNavbarService.getVisibleMenuItems$().subscribe((menuItems) => {
        this.menuItems = menuItems;
        this.menuItemsWasInit$.next(true);
        this.cd.detectChanges();
      })
    this.handleSyncActiveItems();
  }
  getChannels(templatesMetadata: TemplatesMetadata, menuItems: NavbarTreeNodeView[]): NavbarTreeNodeView[] {
    const templatesMenu = menuItems.find(i => i.name === this.planItemName).children.find(i => i.name === this.templatesItemName);

    if (!templatesMetadata || !templatesMetadata.channels || Object.keys(templatesMetadata.channels).length === 0) {
      const index = menuItems.find(i => i.name === this.planItemName).children.indexOf(templatesMenu);
      menuItems.find(i => i.name === this.planItemName).children.splice(index, 1);
      return menuItems;
    }
    const channels = Object.values(templatesMetadata.channels);
    const sortedChannels = channels.sort(this.newNavbarService.sortChannels);
    sortedChannels.forEach(x => {
      const link = this.makeChannelForLink(x);
      templatesMenu.children.push({
        isDisabled: false,
        name: x.name,
        routerLink: link[0],
        queryParams: link[1],
        isVisible: true,
        isBeta: x.id === this.webInboxChannelId || x.id === this.whatsAppChannelId
      } as NavbarTreeNodeView)
    });
    templatesMenu.isVisible = true;
    return menuItems;
  }
  
  makeChannelForLink = (channel: Channel): [string, Params?] => [`/category/One-to-One_Campaigns/templates/show/${channel.id}`, null];

  setCollapseState() {
    if (this.isCollapsed && this.secondLevelAccordion) {
      const prevSecondLevelAccordion = this.secondLevelAccordion;
      this.secondLevelAccordion = null;
      setTimeout(() => {
        this.secondLevelAccordion = prevSecondLevelAccordion;
        this.cd.detectChanges();
      }, 1000);
    }

    this.isCollapsed = !this.isCollapsed;
    window.sessionStorage.setItem("isSidebarCollapse", `${this.isCollapsed}`);

    this.appBusService.publish(EventType.sidebarStateChanged, null);
  }


  private handleSyncActiveItems() {
    const navigationStart$ = this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart)
    );

    const navigationEnd$ = this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    );

    combineLatest([navigationEnd$, this.menuItemsWasInit$])
      .pipe(
        filter(([event, menuItemsWasInit]: [NavigationEnd, boolean]) => menuItemsWasInit === true),
        map(([event, menuItemsWasInit]: [NavigationEnd, boolean]) => event),
      ).subscribe((event: NavigationEnd) => {
        this.activeItems = this.newNavbarService.getActiveItems(this.menuItems, event.url);
        this.setPageTitle(event.url);
        
        if (this.activeItems.length > 0) {
          if (this.activeItems.length > 1) {
            setTimeout(() => {
              this.secondLevelAccordion = this.activeItems[1] ?? null;
              this.cd.detectChanges();
            }, 500);
          }
          const indexToOpen = this.menuItems.findIndex(i => i === this.activeItems[0]);
          if (indexToOpen !== this.initiallyOpenedIndex) {
            this.initiallyOpenedIndex = indexToOpen;
            this.cd.detectChanges();
          }
        } else {
          this.secondLevelAccordion = null;
          this.initiallyOpenedIndex = null;
          this.activeItems = [];
          this.cd.detectChanges();
        }
      });

    navigationStart$.pipe(
    ).subscribe((event: NavigationStart) => {
      this.activeItems = this.newNavbarService.getActiveItems(this.menuItems, event.url);

      if (this.activeItems.length > 0) {
        if (this.activeItems.length > 1) {
          setTimeout(() => {
            this.secondLevelAccordion = this.activeItems[1] ?? null;
            this.cd.detectChanges();
          }, 500);
        }
        const indexToOpen = this.menuItems.findIndex(i => i === this.activeItems[0]);
        this.initiallyOpenedIndex = indexToOpen;

        if (this.mainAccordionGroup != null) {
          if (indexToOpen !== -1 && !this.mainAccordionGroup.accordionPanelsRef.get(indexToOpen).isOpen) {
            this.mainAccordionGroup.accordionPanelsRef.get(indexToOpen).toggleOpen();
          }
        }
      } else {
        this.secondLevelAccordion = null;
        this.initiallyOpenedIndex = null;
        this.activeItems = [];
      }
      this.cd.detectChanges();
    });

  }

  private setPageTitle(path: string) {
    let navItemName = "";
    navItemName = this.getNavItemNameFromNavbarTree(path, this.newNavbarService.getMenuItems());
    if(!navItemName){
      navItemName = 'Optimove';
    }

    this.title.setTitle(navItemName);
    sessionStorage["CurrentPage"] = navItemName;

    let tenantId = this.getCookie("TenantId");
    this.navbarWrapperService.initPageAnalytics(navItemName, tenantId); 
  }

  private getCookie(key: string): string {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }

  public getNavItemNameFromNavbarTree(path: string, navbarTree: NavbarTreeNodeView[]) {
    if(navbarTree == null){
        return null
    }

    for(let navbarItem of navbarTree){
        if(navbarItem.routerLink != null && (!navbarItem.children || navbarItem.children.length === 0) && path.toLocaleLowerCase().includes(navbarItem.routerLink.toLocaleLowerCase())){
            return navbarItem.name
        }

        let navbarItemName: string;

        navbarItemName = this.getNavItemNameFromNavbarTree(path, navbarItem.children);

        if(navbarItemName){
            return navbarItemName
        }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.menuItemsWasInit$.complete();
    this.menuItemsWasInit$.unsubscribe();
  }
}
