import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'settings-footer',
    templateUrl: './settingsFooter.component.html',
    styleUrls: ['./settingsFooter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SettingsFooterComponent{
    @Input() isSaving: boolean = false;
    @Input() disabled: boolean = false;

    @Output() saveClicked: EventEmitter<any> = new EventEmitter();
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter();

    public saveText: string;
    public savingText: string;

    constructor(private translateService: TranslateService) {
        this.saveText = this.translateService.instant('general.SAVE');
        this.savingText = this.translateService.instant('features.user_settings.body.loaders.SAVING');
    }

    public  onSaveClicked(){
        this.saveClicked.emit();
    }

    public onCancelClick(){
        this.cancelClicked.emit();
    }
}