<div class="validate-personalization-tags">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h2>
        {{ "features.manage_templates.components.validatePersonalizationTags.TITLE" | translate }}
      </h2>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <span class="text-muted">
            <div class="alert" [ngClass]="isExecutedInvalid ? 'alert-warning' : 'alert-danger'">
              <i class="material-icons warning-material-icon md-18" *ngIf="isExecutedInvalid">warning</i>
              <i class="material-icons md-18" *ngIf="!isExecutedInvalid">error</i>
              <span *ngIf="!isExecutedInvalid">
                <strong>
                  {{ "features.manage_templates.components.validatePersonalizationTags.GENERAL_ERROR_MESSAGE_PREFIX" | translate }}
                </strong>
                <span>
                  {{ "features.manage_templates.components.validatePersonalizationTags.GENERAL_ERROR_MESSAGE_SUFFIX" | translate }}
                </span>
              </span>
              <span *ngIf="isExecutedInvalid">
                {{
                  "features.manage_templates.components.validatePersonalizationTags.INVALID_TEMPLATE_ACTIVE_CAMPAIGN_ERROR_MESSAGE_SUFFIX"
                    | translate
                }}
              </span>
            </div>
          </span>
        </div>
        <div *ngIf="validationOutput.emptyContentValidation.error.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{ "features.manage_templates.components.validatePersonalizationTags.CONTENT_VALIDATION_SECTION_NAME" | translate }}
              </p>
            </div>
            <div class="col-md-9">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ "features.manage_templates.components.validatePersonalizationTags.ERROR" | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let error of validationOutput.emptyContentValidation.error">
                      <td>{{ error }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validationOutput.contentTagValidation.contentTagValidationErrors.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{ "features.manage_templates.components.validatePersonalizationTags.CONTENT_TAGS_VALIDATION_SECTION_NAME" | translate }}
              </p>
            </div>
            <div class="col-md-9">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-5">{{ "features.manage_templates.components.validatePersonalizationTags.TAG" | translate }}</th>
                      <th class="col-md-5">{{ "features.manage_templates.components.validatePersonalizationTags.ERROR" | translate }}</th>
                      <th class="col-md-2">
                        {{ "features.manage_templates.components.validatePersonalizationTags.LOCATION" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let error of validationOutput.contentTagValidation.contentTagValidationErrors">
                      <td class="word-break-all-cell">{{ error.element }}</td>
                      <td class="word-break-all-cell">{{ error.error }}</td>
                      <td class="word-break-all-cell">
                        {{ getLineNumberString(error.contentType, error.linenumber) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validationOutput.urlValidation.urlValidationErrors.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{ "features.manage_templates.components.validatePersonalizationTags.CONTENT_VALIDATION_SECTION_NAME" | translate }}
              </p>
            </div>
            <div class="col-md-9">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-10">{{ "features.manage_templates.components.validatePersonalizationTags.ERROR" | translate }}</th>
                      <th class="col-md-2">
                        {{ "features.manage_templates.components.validatePersonalizationTags.LOCATION" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let error of validationOutput.urlValidation.urlValidationErrors">
                      <td class="word-break-all-cell">{{ error.error }}</td>
                      <td class="word-break-all-cell">
                        {{ getLineNumberString(error.contentType, error.linenumber) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validationOutput.personalizationTagsValidation.personalizationTagsValidationErrors.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{
                  "features.manage_templates.components.validatePersonalizationTags.PERSONALIZATION_TAGS_VALIDATION_SECTION_NAME"
                    | translate
                }}
              </p>
            </div>
            <div class="col-md-9">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-5">{{ "features.manage_templates.components.validatePersonalizationTags.TAG" | translate }}</th>
                      <th class="col-md-5">{{ "features.manage_templates.components.validatePersonalizationTags.ERROR" | translate }}</th>
                      <th class="col-md-2">
                        {{ "features.manage_templates.components.validatePersonalizationTags.LOCATION" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let error of validationOutput.personalizationTagsValidation.personalizationTagsValidationErrors">
                      <td class="word-break-all-cell">{{ error.element }}</td>
                      <td class="word-break-all-cell">{{ error.error }}</td>
                      <td class="word-break-all-cell">
                        {{ getLineNumberString(error.contentType, error.linenumber) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validationOutput.conditionalLanguageValidation.conditionalLanguageValidationErrors.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{
                  "features.manage_templates.components.validatePersonalizationTags.CONDITIONAL_LANGUAGE_VALIDATION_SECTION_NAME"
                    | translate
                }}
              </p>
            </div>
            <div class="col-md-9">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-5">
                        {{ "features.manage_templates.components.validatePersonalizationTags.CONDITION" | translate }}
                      </th>
                      <th class="col-md-5">{{ "features.manage_templates.components.validatePersonalizationTags.ERROR" | translate }}</th>
                      <th class="col-md-2">
                        {{ "features.manage_templates.components.validatePersonalizationTags.LOCATION" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let error of validationOutput.conditionalLanguageValidation.conditionalLanguageValidationErrors">
                      <td class="word-break-all-cell">{{ error.element }}</td>
                      <td class="word-break-all-cell">{{ error.error }}</td>
                      <td class="word-break-all-cell">
                        {{ getLineNumberString(error.contentType, error.linenumber) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validationOutput.unsubscribeLinkValidation.error" class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                {{
                  "features.manage_templates.components.validatePersonalizationTags.UNSUBSCRIBE_LINK_VALIDATION_SECTION_NAME" | translate
                }}
              </p>
            </div>
            <div class="col-md-9">
              <p>
                {{ validationOutput.unsubscribeLinkValidation.error }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button (click)="close()" type="button" class="btn btn-primary pull-right">
            {{ "features.manage_templates.components.validatePersonalizationTags.OK" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
