export enum FieldType {
    CampaignType = 'campaignType',
    Priority = 'priority',
    StartDate = 'startDate',
    TargetGroup = 'targetGroup',
    Trigger = 'trigger',
    Actions = 'actions',
    Channel = 'channels',
    PromotionName = 'promotionName',
    PromotionCode = 'promotionCode',
    Templates = 'templates',
}

export enum SearchType {
    Contains = 'Contains',
    Equals = 'Equals',
}

export enum C360PageState {
    MockMessage = 'MockMessage',
    CustomerList = 'CustomerList',
    CustomerPage = 'CustomerPage',
}

export enum AnalysisRange {
    Last7Days = 'Last 7 days',
    Last30Days = 'Last 30 days',
    Last90Days = 'Last 90 days',
    Custom = 'Custom',
}