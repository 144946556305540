<div *ngIf="show"
     class="opti-loading-backdrop loader-{{size || defaultSize}}">
    <div class="opti-loading">
        <div class="opti-loading-inner">
            <div class="opti-loading-spinner"></div>
        </div>
    </div>
    <h3 *ngIf="title" class="opti-loading-title">{{title}}</h3>
    <ng-container *ngIf="!isTextInnerHtml else innerHtmlText">
        <span *ngIf="text" class="opti-loading-text">{{text}}</span>
    </ng-container>
    <ng-template #innerHtmlText>
        <span *ngIf="text" class="opti-loading-text" [innerHTML]="text"></span>
    </ng-template>
</div>
