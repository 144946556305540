import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";

@Injectable({
    providedIn: 'root',
})
export class AttributeValidatorService {
    
    constructor(private readonly httpClient: HttpService) {
    }

    validateEmptySpaces(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return control.value.trim() ? null : {"required": true};
        }
    }

    validateSpecialSymbols(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            var specials = /[^A-Za-z 0-9:,._-]/g;
            return specials.test(control.value) ? {"specialSymbols": true} : null;
        }
    }

    public canAttributeBeHidden(attributeFieldName: string): Observable<DefaultAjaxResponse> {
        const params = HttpService.toHttpParams({ fieldName: attributeFieldName });
        return this.httpClient.get<DefaultAjaxResponse>(`/AttributesManager/CanAttributeBeHidden`, params);
    }
}
