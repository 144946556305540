import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TemplatePreviewData } from '../../../models/channel/template/template-preview-data.model';

@Component({
	selector: 'templates-grid-view-modal',
	templateUrl: './templatesGridViewModal.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./templatesGridViewModal.component.scss']
})
export class TemplatesGridViewModalComponent implements OnInit {
    @Input() templates: TemplatePreviewData[];
    @Input() columnsCount = 2;
    @Input() toShowChannelName = true;
    @Input() datesData: [];
    @Output() showTemplatesCarouselModal: EventEmitter<number> = new EventEmitter();

    public channelName: string;
    public templatesRows: TemplatePreviewData[][];

    constructor() {
    }

    ngOnInit() {
    	this.channelName = this.templates[0].channelName;
    	this.templatesRows = this.convertToTemplatesRows(this.templates, this.columnsCount);
    }

    private convertToTemplatesRows(templates: TemplatePreviewData[], columnsCount: number): TemplatePreviewData[][] {
    	const rowsCount = Math.ceil(this.templates.length / columnsCount);
    	const templatesRows = new Array(rowsCount).fill(null).map(() => []);
    	templates.forEach((template, templateIndex) => {
    		const rowIndex = Math.floor(templateIndex / columnsCount);
    		templatesRows[rowIndex].push(template);
    	});

    	return templatesRows;
    }

    onShowTemplatesCarouselModal(templateIndex: number) {
    	this.showTemplatesCarouselModal.emit(templateIndex);
    }
}
