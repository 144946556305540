<div class="panel-body">
    <form [formGroup]="form" data-qa-id="apiKeyForm">
        <div class="form-row-with-notes">
            <label class="col1" for="userName" data-qa-id="nickNameLabel">{{ translateKeys.nickNameLabel | translate }}</label>
            <div class="col2">
                <input type="text" data-qa-id="apiKeyName" formControlName="Nickname" id="userName"
                    [placeholder]="translateKeys.nickNamePlaceholder | translate" class="form-control"
                    required>
            </div>
            <div class="col3"></div>
        </div>

        <div class="form-row">
            <div class="col1"></div>
            <div class="col4">
                <div *ngIf="nameFormControl.invalid && nameFormControl.touched && nameFormControl.errors?.required">
                    <sup class="small validationErr" data-qa-id="nickNameMustBeProvided"><b>{{ translateKeys.nickNameMustBe | translate }}</b></sup>
                </div>
                <div *ngIf="nameFormControl.invalid && nameFormControl.errors?.invalidNickname && nameFormControl.dirty">
                    <sup class="small validationErr" data-qa-id="errorUserName"><b>{{translateKeys.nickNameErrorMsg | translate }}</b></sup>
                </div>
                <div *ngIf="nameFormControl.invalid && nameFormControl.errors?.invalidAuth0Email && nameFormControl.dirty">
                    <sup class="small validationErr" data-qa-id="userNameCannotBeEmail"><b>{{translateKeys.nickNameCannotBeEmail | translate }}</b></sup>
                </div>
                <div *ngIf="nameFormControl.errors?.maxlength">
                    <sup class="small validationErr" data-qa-id="exccededUserNameLength"><b>{{translateKeys.nickNameLimitLength | translate }}</b></sup>
                </div>
                <div class="last-row" *ngIf="((nameFormControl.valid) || (!nameFormControl.touched && !nameFormControl.dirty))">
                    <sup class="note-form" data-qa-id="userNote">{{translateKeys.nickNameNote | translate }}</sup>
                </div>
            </div>
        </div>

        <div class="form-row-with-notes email">
            <label class="col1" for="email" data-qa-id="emailLabel">{{ translateKeys.contactEmailLabel | translate }}</label>
            <div class="col2">
                <input type="text" data-qa-id="apiUserEmail" formControlName="ContactEmail" id="email"
                    [placeholder]="translateKeys.contactEmailPlaceholder|translate" class="form-control"
                    required>
            </div>
            <div class="col3"></div>
        </div>

        <div class="form-row-with-notes">
            <div class="col1"></div>
            <div class="col4">
                <div *ngIf="emailFormControl.invalid && emailFormControl.touched && emailFormControl.errors?.required">
                    <sup class="small validationErr" data-qa-id="emailMustBeProvided"><b>{{ translateKeys.emailMustBe | translate }}</b></sup>
                </div>
                <div *ngIf="emailFormControl.invalid && emailFormControl.errors?.invalidEmail && emailFormControl.dirty">
                    <sup class="small validationErr" data-qa-id="errorEmail"><b>{{translateKeys.contactEmailErrorMsg | translate }}</b></sup>
                </div>
            </div>
        </div>

        <div class="form-row role">
            <label class="col1" data-qa-id="roleLabel">{{ translateKeys.accessTypeLabel | translate }}</label>
            <div class="col2">
                <opti-search-list required data-qa-id="apiKeyRole"
                    formControlName="RoleType"
                    [items]="apiRoles"
                    [config]="dropdownOslConfig"
                    (selectedItemsChange)="selectedRoleChanged($event)"
                    [isDefaultDisabled]="isWhenValuesHasOneOption">
                </opti-search-list>
            </div>
            <div class="col3"></div>
        </div>

        <ng-container [ngSwitch]="apiRole">
            <integrations formControlName="Claims" #integrations *ngSwitchCase="roles.Integrations"></integrations>
        </ng-container>
    </form>
</div>
