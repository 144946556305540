<div class="unsubscribe-container">
  <div id="one-click-unsubscribe-header" class="panel-heading">
    <h2>{{ "features.user_settings.body.oneClickUnsubscribe.TITLE" | translate }}</h2>
  </div>
  <div class="unsubscribe-body">
    <opti-loading [show]="isLoading" [size]="'md'"></opti-loading>
    <div *ngIf="!brands?.length">
      <p class="empty-indicator">{{ "features.user_settings.body.oneClickUnsubscribe.EMPTY" | translate }}</p>
    </div>
    <div class="brand-items">
      <div class="panel-body">
        <div *ngFor="let brand of brands" class="brands-item">
          <div class="brand-header">
            <i (click)="brand.isOpen = !brand.isOpen" class="material-icons expander-icon">{{ brand.isOpen ? "expand_more" : "chevron_right" }}</i>
            <p class="brand-name">{{ brand.name }}</p>
          </div>
          <div *ngIf="brand.isOpen" class="unsusbcribe-fields-container">
            <div class="unsusbcribe-fields">
              <div class="field-container">
                <p class="input-label">{{ "features.user_settings.body.oneClickUnsubscribe.MAILTO_UNSUBSCRIBE" | translate }}</p>
                <div class="input-field">
                  <input [(ngModel)]="brand.mailToUnsubscribe"  maxlength='88' class="form-control"  type="email" emailValidation #mailToEmail="ngModel" name="mailToEmail" id="mailToEmail"/>
                </div>
              </div>
              <div class="text-warning validation-error" *ngIf="mailToEmail.errors">
                {{ 'features.user_settings.body.oneClickUnsubscribe.EMAIL_ERROR' | translate }}
              </div>
              <div class="field-container">
                <p class="input-label">{{ "features.user_settings.body.oneClickUnsubscribe.HTTPS_UNSUBSCRIBE" | translate }}</p>
                <div class="input-field">
                  <input [(ngModel)]="brand.httpsUnsubscribe" class="form-control" urlValidation #httpsUnsubscribe="ngModel" name="httpsUnsubscribe" id="httpsUnsubscribe"/>
                </div>
              </div>
              <div class="field-container">
                <p class="input-label">{{ "features.user_settings.body.oneClickUnsubscribe.TEMPLATE_ID_LABEL" | translate }}</p>
                <div class="input-field">
                  <input name="checkboxTemplate" [(ngModel)]="brand.includeTemplateId" data-qa-id="checkbox-include-template-id" type="checkbox" />
                </div>
              </div>
              <div class="text-warning validation-error" *ngIf="httpsUnsubscribe.errors">
                {{ 'features.user_settings.body.oneClickUnsubscribe.HTTPS_ERROR' | translate }}
              </div>
              <div *ngIf="brand.httpsUnsubscribe" class="text-under-input">
                {{ "features.user_settings.body.oneClickUnsubscribe.HTTPS_HINT" | translate : { enteredUrl: brand.httpsUnsubscribe, variableParams: brand.includeTemplateId ? templateIdHintString : ''} }}
              </div>
            </div>
            <div class="one-click-action-buttons">
              <button class="btn btn-secondary" (click)="downloadSecretKey(brand)">{{ "features.user_settings.body.oneClickUnsubscribe.DOWNLOAD_SECRET_KEY" | translate}}</button>
              <button class="btn btn-secondary" (click)="regenerateSecretKey(brand)">{{ "features.user_settings.body.oneClickUnsubscribe.REGENERATE_SECRET_KEY" | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-primary save-button" [disabled]='isSaveDisabled()' (click)="save()">{{ "general.SAVE" | translate }}</button>
    </div>
  </div>
</div>
