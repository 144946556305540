<div class="opti-metric">
    <h4 class="opti-metric-title" *ngIf="!titleTooltip" title="{{titleText}}"
        class="min-height-multi-line-{{titleNumberOfLines}}">
        {{titleText}}
    </h4>
    <h4 *ngIf="titleTooltip" class="min-height-multi-line-{{titleNumberOfLines}}">
        <ng-template #popTemplate><div [innerHtml]="titleTooltip"></div></ng-template>
        <span *ngIf="!htmlTooltip" data-html="true" [tooltip]="titleTooltip" container="body">
            {{titleText}}
        </span>
        <span *ngIf="htmlTooltip" data-html="true" [tooltip]="popTemplate" container="body">
            {{titleText}}
        </span>
    </h4>

    <div *ngIf="valueNumberOfLines">
        <span class="opti-metric-value" *ngIf="!isMultiValue" title="{{metricValue}}" [ngClass]="metricDynamicClass()">
            {{metricValue}}
        </span>
        <span *ngIf="subLine && subLine.iconConfig" class="text-muted">
            <i class="material-icons sublineIcon"
               [tooltip]="subLine.iconConfig.tooltipValue"
               container="body">
                info_outline
            </i>
        </span>
        <span *ngIf="!isMultiValue && metricValueSubtext.length>0" [ngClass]="'p small' + metricDynamicClass()">
            {{metricValueSubtext}}
        </span>
        <div *ngIf="isMultiValue">
            <span class="multi-metric-display" [ngClass]="(i>0)? 'blockmetric':''"
                  *ngFor="let data of metricValue; let i = index;">
                <span class="multi-metric-display" [ngClass]="'h1 ' + metricDynamicClass(i)">
                    {{metricFormatted(i)}}
                </span>
                <span class="text-muted multi-metric-display" *ngIf="metricValueSubtext.length > 0" [ngClass]="multiValueClasses[i]">
                    {{metricValueSubtext[i]}}
                </span>
            </span>
        </div>
    </div>
    <div *ngIf="!valueNumberOfLines">
         <span *ngIf="!isMultiValue" title="{{valueNumberOfLines}}" [ngClass]="metricDynamicClass()">
            {{metricFormatted()}}
         </span>
        <span *ngIf="subLine && subLine.iconConfig" class="text-muted">
            <i class="material-icons sublineIcon"
               [tooltip]="subLine.iconConfig.tooltipValue"
               container="body">
                info_outline
            </i>
        </span>
        <span *ngIf="!isMultiValue && metricValueSubtext.length>0" [ngClass]="'p small' + metricDynamicClass()">
            {{metricValueSubtext}}
        </span>
        <div *ngIf="isMultiValue">
            <span *ngFor="let data of metricValue; let i = index;">
                <span class="multi-metric-display" [ngClass]="'h1 ' + metricDynamicClass(i)">
                {{metricFormatted(i)}}
                </span>
                <span class="multi-metric-display" *ngIf="metricValueSubtext.length>0">
                {{metricValueSubtext[i]}}
                </span>
            </span>
        </div>
    </div>
    <div *ngIf="subLine" class="{{subLine.lineClass + ' subLine'}}">
        <span [ngClass]="metricDynamicClassForSubValue(subLine.value,true)">
            <b>{{subLine.value | formatter:subLine.formatter:subLine.formatterOptions }}</b>
        </span>
        <span>{{subLine.text}}</span>
    </div>
</div>


