<div class="page-wrapper">
    <header>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 data-qa-id="CustomerPageTitle">
                        {{translateKeys.HEADER_TITLE | translate}}
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <p data-qa-id="CustomerPageDescription">
                        {{translateKeys.HEADER_DESCRIPTION | translate}}
                    </p>
                </div>
            </div>
            <div class="row">
                <search-input
                        (search)="onSearch($event)"
                ></search-input>
            </div>
        </div>
    </header>

    <section *ngIf="currentPageState === C360PageState.CustomerList">
        <div class="container">
            <div *ngIf="isLoading" class="loader-container">
                <opti-loading [show]="isLoading"></opti-loading>
            </div>
            <customers-list
                #customer360Child
                [ngStyle]="{'display': isLoading ? 'none' : ''}"
                [searchStr]="currentSearchValue"
                (onDataLoaded)="stopLoading()"
                (onCustomerSelected)="onCustomerSelected($event)"
                (onSearchByChanges)="mock()"
            ></customers-list>
        </div>
    </section>

    <section *ngIf="currentPageState === C360PageState.CustomerPage">
        <div class="container">
            <div *ngIf="isLoading" class="loader-container">
                <opti-loading [show]="isLoading"></opti-loading>
            </div>
            <customer-data-page
                #customer360Child
                [ngStyle]="{'display': isLoading ? 'none' : ''}"
                [customerId]="currentCustomerId"
                (onDataLoaded)="stopLoading()"
            ></customer-data-page>
        </div>
    </section>

    <section *ngIf="currentPageState === C360PageState.MockMessage" class="error-message">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center text-muted">
                    <h1 data-qa-id="CustomerPageMessageTitle">
                        {{translateKeys.MOCK_TITLE | translate}}
                    </h1>
                    <h3 data-qa-id="CustomerPageMessage">
                        {{translateKeys.MOCK_MESSAGE | translate}}
                    </h3>
                </div>
            </div>
        </div>
    </section>
</div>