<div class="details-panel-container">
  <div class="detail-panel-header">
    <detail-panel-header id="detailsPanel"></detail-panel-header>
  </div>
  <div class="details-panel-body">
    <div class="templates-count" *ngIf="templatesCount > 0">
      <templates-header
        (batchMoving)="openBatchMovingToast($event)"
        [numberTemplates]="templatesCount"
        [numberOfInvalid]="numberOfInvalid"
        [isSelected]="false"></templates-header>
    </div>

    <div class="grid-container">
      <ag-grid-angular
        [ngClass]="{'ag-grid-hidden': this.templatesCount < 1 && isGridReady}"
        (window:resize)="onResize($event)"
        id="agGridTemplates"
        class="ag-theme-clean ag-grid-templates"
        [modules]="modules"
        (cellMouseOver)="onCellMouseOver($event)"
        [components]="frameworkComponents"
        [gridOptions]="gridOptions"></ag-grid-angular>
    </div>

    <div class="no-templates-container">
      <div *ngIf="this.templatesCount < 1 && isGridReady" class="no-templates-placeholder">
        <img *ngIf="!searchText" src=".\assets\images\emptyStateManageTemplates.jpg" />
        <div class="empty-folder">
          <span *ngIf="!!searchText">{{ "features.manage_templates.components.details.NO_SEARCH_RESULTS" | translate }}</span>
          <span *ngIf="!searchText">
            {{
              ((isAnyTemplatesOrFolders
                ? "features.manage_templates.components.details.EMPTY_FOLDER"
                : "features.manage_templates.components.details.WELCOME"
              ) | translate) + (isAnyTemplatesOrFolders ? "" : channelName)
            }}
          </span>
        </div>
        <div class="empty-folder-description">
          <span *ngIf="!!searchText">{{ "features.manage_templates.components.details.TRY_ANOTHER" | translate }}</span>
          <button
            *ngIf="!searchText"
            (click)="newTemplate()"
            class="btn btn-primary save-button"
            id="manage-templates-details-new-template-button"
            type="button">
            {{
              "features.manage_templates.newTemplate.TITLE"
                | translate
                  : {
                      items:
                        (channelService.isWhatsApp(channelId) ? "features.manage_templates.JOURNEY" : "features.manage_templates.TEMPLATE")
                        | translate
                    }
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <toast
      [(isOpen)]="toastParams.isOpen"
      [icon]="toastParams.icon"
      [location]="toastParams.location"
      [message]="toastParams.message"
      [showCloseIcon]="toastParams.showCloseIcon"
      [toastIconType]="toastParams.toastIconType"
      [actionText]="actionText"
      [closeTimeout]="toastParams.closeTimeout"
      (actionBtnClicked)="onToastActionClick()"></toast>
  </div>
</div>
