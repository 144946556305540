<div class="creds-data">
    <div class="form-row">
      <div class="row-label">
        <label>{{ 'features.user_settings.body.message_archiving.credentials.BUCKET_NAME' | translate }}</label>
      </div>
      <div class="verify-row">
        <div class="row-input">
          <input name="bucketName" type="text" [(ngModel)]="this.gcsSecret.BucketName">
        </div>
        <button class="btn btn-default" (click)="verifyCredentials()" data-qa-id="verify-secret" [disabled]="!gcsSecret.BucketName || !gcsSecret.ServiceAccountJson">
          {{'features.user_settings.body.message_archiving.credentials.VERIFY' | translate }}
        </button>
      </div>
    </div>
    <div class="form-row">
      <div class="row-label">
        <label>{{ 'features.user_settings.body.message_archiving.credentials.JSON_CREDS' | translate }}</label>
      </div>
      <div class="row-input">
        <div class="browse-button">
          <button class="btn btn-secondary" (click)="uploadJson()">{{ 'features.user_settings.body.message_archiving.credentials.UPLOAD' | translate }}</button>
          <input  (change)="handleFileJsonInput($event)" type="file" id="file" accept=".json" class="upload-file-input" formControlName="file" #jsonFileInput>
        </div>
        <textarea class='json-text' name="jsonCreds" type="text" [(ngModel)]="this.gcsSecret.ServiceAccountJson"></textarea>
      </div>
    </div>
</div>