<div class="editor-header-container">
  <div class="template-name-row">
    <div class="header-title-panel">
      <ng-container>
        <div
          [isDisabled]="isNextBtnDisabled()"
          [tooltipHtml]="nextTemplate"
          [container]="'body'"
          [tooltipPlacement]="'bottom'"
          [containerClass]="'template-name-tooltip-container'">
          <div class="back-button-div" (click)="navigateNext()">
            <i
              class="material-icons btn-font-size back-button-icon"
              [ngClass]="{'disabled-link': isNextBtnDisabled()}"
              data-qa-id="visual-editor-next-template">
              keyboard_arrow_down
            </i>
          </div>
        </div>

        <div
          [isDisabled]="isPrevBtnDisabled()"
          [tooltipHtml]="previosTemplate"
          [container]="'body'"
          [tooltipPlacement]="'bottom'"
          [containerClass]="'template-name-tooltip-container'">
          <div (click)="navigatePrevious()" class="back-button-div">
            <i
              class="material-icons btn-font-size back-button-icon"
              [ngClass]="{'disabled-link': isPrevBtnDisabled()}"
              data-qa-id="visual-editor-previous-template">
              keyboard_arrow_up
            </i>
          </div>
        </div>
      </ng-container>
      <folder-popup-button></folder-popup-button>
      <div class="name-panel">
        <input
          id="name"
          templateNameValidator
          required
          [templateId]="template.templateId"
          [subMethodId]="template.subMethodId"
          (blur)="onTemplateNameBlur()"
          name="name"
          #templateNameInput
          #name="ngModel"
          [(ngModel)]="template.templateName"
          [ngClass]="{isEditable: isTemplateNameEditable}"
          (keydown.enter)="onEnter($event)"
          placeholder="{{ 'features.manage_templates.optimail.TEMPLATE_NAME_PLACEHOLDER' | translate }}" />
        <div *ngIf="isTemplateNameEditable === false" class="default-mode" (click)="onEditMode()">
          <span class="show-mode-template-name" title="{{ template.templateName }}" data-qa-id="visual-template-existing-name">
            {{ template.templateName }}
          </span>
          <span class="btn btn-link-default material-icons edit-mode-icon">mode_edit</span>
        </div>
        <span *ngIf="name.errors?.nameUnique" class="template-name-error-message invalid-text">
          {{ name.errors?.nameUnique | translate }}
        </span>
        <span *ngIf="name.dirty && template.templateName.length < 1" class="template-name-error-message invalid-text">
          {{ "features.manage_templates.messages.TEMPLATE_NAME_MUST_BE_PROVIDED" | translate }}
        </span>
      </div>
    </div>
    <div class="header-actions">
      <button
        *ngIf="!isPopup"
        [disabled]="!isRunCampaignEnabled()"
        (click)="redirectToRunCampaign('scheduled')"
        class="btn-link header-action run-campaign-btn"
        data-qa-id="visual-editor-run-campaign">
        {{ "features.manage_templates.editTemplate.RUN_CAMPAIGN" | translate }}
      </button>
      <button
        class="btn-link header-action validate-btn"
        [ngClass]="{'warning-button': template.isExecutedInvalid}"
        (click)="validateTemplate()"
        data-qa-id="visual-editor-validate">
        <i *ngIf="!template.isExecutedInvalid && !template.isValid" class="material-icons error-material-icon md-18">error</i>
        <span *ngIf="!template.isExecutedInvalid || template.isValid">
          {{ "features.manage_templates.editTemplate.VALIDATE" | translate }}
        </span>
        <i class="material-icons md-18" *ngIf="template.isExecutedInvalid">warning</i>
        <span *ngIf="template.isExecutedInvalid">{{ "features.manage_templates.editTemplate.INVALID" | translate }}</span>
      </button>
      <button
        *ngIf="isPopup"
        [disabled]="!isRunCampaignEnabled()"
        (click)="redirectToRunCampaign('triggered')"
        class="btn-link header-action run-campaign-btn">
        {{ "features.manage_templates.editTemplate.RUN_CAMPAIGN" | translate }}
      </button>
      <button
        *ngIf="!isPopup"
        [disabled]="!isSendTestEnabled()"
        (click)="openSendTest()"
        class="btn-link header-action send-test-btn"
        data-qa-id="visual-editor-send-test">
        {{ "features.manage_templates.editTemplate.SEND_TEST" | translate }}
      </button>
      <div class="save-cancel-buttons">
        <button
          [disabled]="isSaving"
          (click)="cancel()"
          class="btn btn-secondary cancel-button"
          type="button"
          data-qa-id="visual-editor-back">
          {{ "general.BACK" | translate }}
        </button>
        <button
          [disabled]="!template.templateName || isSaving"
          (click)="save()"
          class="btn btn-primary save-button"
          id="manage-templates-save-button"
          type="button">
          {{ "general.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template class="template-name-tooltip-container" #nextTemplate>
  <div>{{ nextTemplateName }}</div>
</ng-template>

<ng-template class="template-name-tooltip-container" #previosTemplate>
  <div>{{ previousTemplateName }}</div>
</ng-template>
