import { ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation, forwardRef } from "@angular/core";
import { ApiKeyManagementService } from "../../apiKeyManagement.service";
import { TranslateService } from "@ngx-translate/core";
import { SearchListConfig } from "src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AbstractNestedFormComponent } from "src/app/components/abstractNestedForm/abstractNestedForm.component";
import { Claims } from "../../models/optiApiKey.model";
import { OslValueType } from "src/app/components/optiSearchList/models/oslValueType.enum";

@Component({
    selector: 'integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Integrations),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => Integrations),
            multi: true
        }
    ]
})

export class Integrations extends AbstractNestedFormComponent<Claims> implements OnInit, OnDestroy {

    public translateKeys = translateKeys;
    public isWhenValuesHasOneOption: boolean;
    public legalChannels = [];
    public legalAttributes = [];
    public legalChannelsDropdownOslConfig: SearchListConfig = {
        keyProperty: "ID",
        valueProperty: "Name",
        isMultiSelect: true,
        itemNameTranslateKey: "Name",
        placeholderTranslateKey: this.translateService.instant(this.translateKeys.channelsPlaceHolder),
        isDropDownDirectionUp: false,
        valueType: OslValueType.Key
        
    };
    public legalAttributesDropdownOslConfig: SearchListConfig = {
        keyProperty: "RealFieldName",
        valueProperty: "AliasFieldName",
        isMultiSelect: true,
        itemNameTranslateKey: "AliasFieldName",
        placeholderTranslateKey: this.translateService.instant(this.translateKeys.permittedAttributesPlaceHolder),
        isDropDownDirectionUp: false,
        valueType: OslValueType.Key
    };

    constructor(private translateService: TranslateService,
        private apiKeyManagementService: ApiKeyManagementService,
        private fb: FormBuilder, 
        cd: ChangeDetectorRef) {
        super(cd);
        this.createForm();
    }

    ngOnInit(): void {
        this.apiKeyManagementService.getLegalChannelsAsync()
        .pipe(first()).subscribe(async (legalChannels) => {
            this.legalChannels = await legalChannels.Data;
            this.isWhenValuesHasOneOption = this.isContainsOneItem(this.legalChannels);
        
            this.apiKeyManagementService.getLegalAttributesAsync()
            .pipe(first()).subscribe(async (legalAttributes) => {
                this.legalAttributes = await legalAttributes.Data;
                this.isWhenValuesHasOneOption = this.isContainsOneItem(this.legalAttributes);
            });
        });
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    private createForm() {
        this.form = this.fb.group({
            LegalChannels: [null, Validators.required],
            LegalAttributes: [null, [Validators.required]],
            MaxCustomerAttributes: [10],
        });
    }

    private isContainsOneItem(itemList: any[]) {
        return itemList.length < 2;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}

const translateKeys = {
    channelsPlaceHolder: 'features.user_settings.body.apiKeyManagement.integrationsRole.CHANNELS_PLACEHOLDER',
    permittedAttributesPlaceHolder: 'features.user_settings.body.apiKeyManagement.integrationsRole.PERMITTED_ATTRIBUTES_PLACEHOLDER',
    channels: 'features.user_settings.body.apiKeyManagement.integrationsRole.CHANNELS',
    permittedAttributes: 'features.user_settings.body.apiKeyManagement.integrationsRole.PERMITTED_ATTRIBUTES'
}