import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
    QueryList,
    ViewChildren
} from "@angular/core";
import { IActionData } from "../../campaignAnalysis.models";
import {ChannelMetricsBarComponent} from "../channelMetricsBar/channelMetricsBar.component";

@Component({
    selector: 'channel-metrics-action',
    templateUrl: './channelMetricsAction.component.html',
    styleUrls: ['./channelMetricsAction.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsActionComponent {
    @Input() action: IActionData;
    @Input() datesData: [];
    @Input() planIdActionSerial: [];
    @Input() isScheduledCampaign: any;
    @Input() templateDialogClass: string;
    @Output() metricClick: EventEmitter<{ metricId, channelId, planDetailChannelId, metricName, templateId }> = new EventEmitter();

    @ViewChildren(ChannelMetricsBarComponent) channelMetricBars: QueryList<ChannelMetricsBarComponent>;

    onMetricClick(payload) {
        this.metricClick.emit(payload);
    }
}
