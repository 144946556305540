import { Injectable } from '@angular/core';
import { TemplateContextService } from './templateContext.service';
import { FeatureFlag, FeatureFlagService } from 'src/app/services/featureFlag.service';
import { RoutingConsts } from '../models/routing.consts';
import { first } from 'rxjs/operators';
import { ChannelService } from './channel.service';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { NewTemplateModalComponent } from '../nestedFoldersPanel/newTemplateModal/newTemplateModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { TemplateNavigationService } from './templateNavigation.service';

@Injectable({
	providedIn: 'root'
})
export class TemplateNewService {
	private isBeefreeEditorForPopupEnabled: boolean;

	constructor(
		private context: TemplateContextService,
		private channelService: ChannelService,
		private modalService: OptiLogicModalService,
		private templateNavigationService: TemplateNavigationService,
		private featureFlagService: FeatureFlagService) {
		this.isBeefreeEditorForPopupEnabled = this.featureFlagService.isEnabled(FeatureFlag.BeefreeEditorForPopup);
	}

	newTemplate(channelId: number): void {
		this.context.isChannelSupportOtherEditors.pipe(first()).subscribe((isChannelSupportOtherEditors) => {
			if ((channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL && !this.isBeefreeEditorForPopupEnabled) || this.channelService.isOptimobile(channelId) || !isChannelSupportOtherEditors) {
				this.newTemplateEditor('froala');
			} else if (channelId === RoutingConsts.OPTIMAIL_CHANNEL || channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL) {
				this.newTemplateModal();
			}
		});
	}

	private newTemplateModal(): void {
		this.modalService.open(NewTemplateModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				newTemplateSelectedEditor: (type) => this.newTemplateEditor(type)
			}
		});
	}

	private newTemplateEditor(type?: string): void {
		this.context.resetCurrentTemplate(type === 'beefree');
		this.templateNavigationService.navigateToNewTemplate(type).subscribe();
	}
}
