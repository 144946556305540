import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { GridApi } from "@ag-grid-community/all-modules";
import { AttributeTypeOptions } from "../../attributesList.model";
import { AttributeHelper } from "../../attributesList.helper";

@Component({
    selector: 'attr-type-cell',
    templateUrl: './typeCellRenderer.component.html',
    styleUrls: ['./typeCellRenderer.component.scss']
})
export class TypeCellRendererComponent implements ICellRendererAngularComp {
    private gridApi: GridApi;
    public options: AttributeTypeOptions;

    constructor() {}

    agInit(params: any): void {
        this.options = AttributeHelper.getFullAttributeType(params.data);
        this.gridApi = params.api;
    }

    refresh(): boolean {
        return true;
    }
}