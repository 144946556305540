import { FormulaService } from './formula.service';
import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { formulaOptions, MultipleConditionValue } from '../attributesList.model';

@Injectable({
    providedIn: 'root',
})
export class ConditionalValidatorService {
    
    constructor(private service: FormulaService) {
    }
    
    validate(conditionCount: () => number): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            if (control.value === null || control.value === '') { return null }

            let correctValues = ['1','2','3','4','5','6','7','8','9','10'];
            let value: string = control.value.toLowerCase();

            const correctSpaces = this.checkSpaces(value, correctValues);
            
            // prepearing to checkFormula()
            value = value.trim();
            while(value.indexOf('or') >= 0 || value.indexOf('and') >= 0) {
                value = value.replace('or', '-');
                value = value.replace('and', '+');
            }
            value = value.replace(/\s/g, '');

            // take numbers from formula
            let tmpStr = value;
            let splited: string[] = [];

            while(tmpStr.indexOf(')') >= 0 || tmpStr.indexOf('(') >= 0) {
                tmpStr = tmpStr.replace(')', '');
                tmpStr = tmpStr.replace('(', '');
            }
            tmpStr.split('-').forEach((x) => {
                x.split('+').forEach(y => splited.push(y));
            });

            // validation
            const containsCorrectNumbers = splited.every((x) => correctValues.indexOf(x) >= 0) &&
                                           Math.max(...splited.map(x => parseInt(x))) == conditionCount();
            const containsDuplicate = splited.length != new Set(splited).size;
            const correctNumbersCount = splited.length == conditionCount();
            
            if(!(correctSpaces && containsCorrectNumbers && !containsDuplicate && correctNumbersCount)) { return {'incorrect': true} }

            return this.service.checkFormula(value, {complexExpresion: true} as formulaOptions) ? null :  {'incorrect': true};
        }
    }

    private checkSpaces(formula: string, possibleValues: string[]): boolean {
        possibleValues.push('0');
        formula = formula.split(' ').join('x').split('and').join(possibleValues[0]).split('or').join(possibleValues[0]).split('10').join('0');

        const correctSpacesNearBrackets = this.isCorrectSpacesNearBrackets(formula, possibleValues);
        const correctSpacesNearValues = this.isCorrectSpacesNearValues(formula, possibleValues);

        return correctSpacesNearBrackets && correctSpacesNearValues;
    }

    private isCorrectSpacesNearBrackets(formulaCopy: string, possibleValues: string[]) {
        let oi = formulaCopy.indexOf('('); // opened bracket`s index

        while(oi !== -1) {
            if ((oi === 0 || formulaCopy[oi-1] === 'x') 
             && (formulaCopy[oi+1] === '(' || formulaCopy[oi+1] === 'x' || possibleValues.includes(formulaCopy[oi+1]))) {
                formulaCopy = `${formulaCopy.substring(0, oi)}${formulaCopy.substring(oi + 1)}`;
                oi = formulaCopy.indexOf('(');
            }
            else {
                return false;
            }
        } 

        let ci = formulaCopy.indexOf(')'); // closed bracket`s index

        while(ci !== -1) {
            if ((possibleValues.includes(formulaCopy[ci-1]) || formulaCopy[ci-1] === 'x') 
             && (ci === formulaCopy.length-1 || formulaCopy[ci+1] === ')' || formulaCopy[ci+1] === 'x')) {
                formulaCopy = `${formulaCopy.substring(0, ci)}${formulaCopy.substring(ci + 1)}`;
                ci = formulaCopy.indexOf(')');
            }
            else {
                return false;                
            }
        } 

        return true;
    }

    private isCorrectSpacesNearValues(formulaCopy: string, possibleValues: string[]) {
        let result = true;
        possibleValues.forEach((x) => {
            let i = formulaCopy.indexOf(x);
            while(i !== -1) {
                if ((i === 0 || formulaCopy[i-1] === '(' || formulaCopy[i-1] === 'x') 
                 && (i === formulaCopy.length - 1 || formulaCopy[i+1] === ')' || formulaCopy[i+1] === 'x')) {    // possible cases: '(1)', '(1x', '1x', 'x1x', 'x1', 'x1)'
                    (formulaCopy[i-1] === 'x')                                                                   // remove number and remove 'x' if it is possible
                        ? formulaCopy = `${formulaCopy.substring(0, i - 1)}${formulaCopy.substring(i + 1)}`
                        : (formulaCopy[i+1] === 'x')
                            ? formulaCopy = `${formulaCopy.substring(0, i)}${formulaCopy.substring(i + 2)}`
                            : formulaCopy = `${formulaCopy.substring(0, i)}${formulaCopy.substring(i + 1)}`; 
                    i = formulaCopy.indexOf(x);                                                                  // set next index of number
                }
                else {
                    result = false;
                    break;
                }
            }
        });
        return result;
    }

    public requiredSearchItem() {
        return (control: AbstractControl) : ValidationErrors | null => {
            if(control.value && control.value[0] && control.value[0].key && control.value[0].text) {
                return null
            }
            else {
                return {'requiredSearchItem': true};
            }
        }
    }

    public validFormArray() {
        return (control: AbstractControl) : ValidationErrors | null => {
            let result = null;
            ((control as FormArray).controls as FormControl[]).forEach((x) => {
                if((x.value as FormGroup).invalid) {
                    result = {'invalidForm': true};
                    x.setErrors({'invalidForm': true});
                }
            });
            return result;
        }
    }

    public validMultipleConditionValue() {
        return (control: AbstractControl) : ValidationErrors | null => {
            return control.value.IsValid ? null : {'invalidEditor': true};
        }
    }

    public validEmptyMultipleConditionValue() {
        return (control: AbstractControl) : ValidationErrors | null => {
            return control.value.EmptyRequiredFields ? {'emptyFields': true} : null;
        }
    }

    public notAllowedValues(getValues: Function) {
        return (control: AbstractControl) : ValidationErrors | null => {
            return getValues().indexOf(control.value) < 0 ? null : {'notAllowedValue': true};
        }
    }

    public validNumbersPicker() {
        return (control: AbstractControl) : ValidationErrors | null => {
            return !control.value || !control.value[0] || !control.value[1] || control.value[0] >= control.value[1] ? {'invalidNumbers': true} : null;
        }
    }

    public validDropdown() {
        return (control: AbstractControl) : ValidationErrors | null => {
            return !control.value || control.value.length === 0 ? {'isValueExist': true} : null;
        }
    }
    
    public isValueAlreadyExist(isValueAlreadyExist: Function) {
        return (control: AbstractControl) : ValidationErrors | null => {
            return isValueAlreadyExist() ? {'isValueAlreadyExist': true} : null;
        }
    }
}
