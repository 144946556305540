export function encodeHtmlEntities(str: string): string {
    let htmlElement = document.createElement('div');
    let content = document.createTextNode(str);
    htmlElement.appendChild(content);
    let encodedHtml = htmlElement.innerHTML;
    return encodedHtml;
}

export function encodeHtmlEntitiesIncludeDoubleSingleQuote(str: string): string {
    return  encodeHtmlEntities(str)
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#39;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/\+/g, "&#x2B;"); // Encode the plus sign in hexadecimal
}