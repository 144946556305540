import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter, OnDestroy , ViewEncapsulation } from '@angular/core';
import './conditionalLanguageContainer.component.scss'
import { Subscription } from "rxjs";
import { OptiLogicModalService } from "../optiLogicModal/optiLogicModal.service";
import { ConditionalLanguageModalComponent } from "./conditionalLanguageModal/conditionalLanguageModal.component";
import { ConditionalLanguageService } from "./conditionalLanguage.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { take } from 'rxjs/operators';


@Component({
    selector: 'conditional-language-container',
    templateUrl: './conditionalLanguageContainer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ConditionalLanguageContainerComponent implements OnChanges, OnInit, OnDestroy {
    private chosenSnippetSubscription: Subscription;
    private onHideSubscription: Subscription;
    modalRef: BsModalRef;

    @Input() snippets: any;
    @Output() chosen: EventEmitter<string> = new EventEmitter<string>();

    constructor(private modalService: OptiLogicModalService, private bsModalServiceCL: BsModalService, private conditionalLanguageService: ConditionalLanguageService) {
        this.onHideSubscription = this.bsModalServiceCL.onHide.subscribe((reason: string) => {
            const noSnippetPickedString = '';
            setTimeout(() => {
                this.chosen.emit(noSnippetPickedString);
            });
        });

        this.chosenSnippetSubscription = this.conditionalLanguageService.snippetPicked$.subscribe(val => {
            if (val) {
                this.chosen.emit(val);
                this.modalRef.hide();
            }
        });
    }

    public openModal(): void {
        this.modalRef = this.modalService.open(
            ConditionalLanguageModalComponent,
            'md',
            {}
        );
    }

    ngOnInit(): void {
        this.conditionalLanguageService.updateSnippetsObject(this.snippets);
        this.openModal();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.snippets && changes.snippets.currentValue) {
            this.snippets = changes.snippets.currentValue;
            this.conditionalLanguageService.updateSnippetsObject(this.snippets);
        }
    }

    ngOnDestroy(): void {
        this.chosenSnippetSubscription.unsubscribe();
        this.onHideSubscription.unsubscribe();
    }
}