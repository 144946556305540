import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import { IBrandDetails } from "../../models/brandDetails.model";
import SettingsSubscriberState from "../../models/settingsSubscriberState.model";
import Action from "../../models/action.model";
import { SettingsSubscribersService } from "../../settingsSubscribers.service";
import * as moment from 'moment';
import {
    SearchItem,
    SearchListConfig
} from "../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import {OptiLogicModalService, IModalButtonConf} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import {BsModalRef, ModalOptions} from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import {Observable} from "rxjs";
import {ExportModalComponent} from "../ExportModalComponent/exportModal.component";
import {AppConstant} from "../../../../../app.constatnt";
import {FeatureFlag, FeatureFlagService} from "../../../../../services/featureFlag.service";
import * as EmailValidator from 'email-validator';

const OPTIMAIL_SEND_TEST_RECIPIENTS_EMAILS_KEY: string = 'optimailSendTestRecipientsEmails';

@Component({
    selector: 'settings-subscribers-form',
    styleUrls: ['./settingsSubscribersForm.component.scss'],
    templateUrl: './settingsSubscribersForm.component.html'
})
export class SettingsSubscribersFormComponent implements OnInit {
    @Input() brands: IBrandDetails[];
    @Input() allEmails: string[];
    @ViewChild('csvFileInput', { static: false }) csvFileInput;
    public rootForm: FormGroup;
    public isInUpdateProcess: boolean;

    public brandControl: FormControl;
    public state: SettingsSubscriberState;
    public brandList:  SearchItem[];
    public brandConfig: SearchListConfig;
    public isLoading: boolean = false;
    public isFinished: boolean = false;
    public modalRef : BsModalRef<any>;
    public isPopUpOpen: boolean = false;
    public email: string;

    constructor(
        private settingsSubscribersService: SettingsSubscribersService,
        private optiLogicModalService: OptiLogicModalService,
        private featureFlagService: FeatureFlagService,
        private translate: TranslateService) { }

    ngOnInit(): void {

        this.getExistingRecipients();
        this.brandList = this.brandsToSearchItems(this.brands);
        this.isInUpdateProcess = false;
        this.state = {};
        this.brandConfig = {
            itemNameTranslateKey: this.translate.instant("features.user_settings.body.subscribers.BRAND_LABEL"),
            keyProperty: "id",
            valueProperty: "name",
            placeholderTranslateKey: this.translate.instant("features.user_settings.body.subscribers.SELECT_LABEL"),
            isMultiSelect: false
        };
        this.createForms();
        this.settingsSubscribersService.GetCustomerEmail().subscribe((response) => {
            this.email = response;
        })
    }

    private brandsToSearchItems(brandDetails: IBrandDetails[]): SearchItem[] {
        const brands = brandDetails
            .reduce((acc, brandDetail) => {
                if (typeof acc[brandDetail.brandName] === "undefined") {
                    acc[brandDetail.brandName] = [brandDetail.submethodId];
                } else {
                    acc[brandDetail.brandName].push(brandDetail.submethodId);
                }
                return acc;
            }, {});
        return Object.entries(brands).map((brand, index) => {
            // 0 -name 1- ids;
            return { id: index, name: brand[0], brandIds: brand[1]};
        });
    }

    createForms() {
        this.brandControl = new FormControl(null, [Validators.required]);
        let that = this;
        this.brandControl.valueChanges.subscribe( value => {
            if (value) {
                that.state.brandName = value[0].name;
                that.state.brandIds = value[0].brandIds;
            }
        });
        this.rootForm = new FormGroup(
            {
                brandId: this.brandControl,
                addOrRemove: new FormControl(true, [Validators.required]),
                file: new FormControl(null, [Validators.required]),
                email: new FormControl(null )
            }
        );
    }

    uploadCSV() {
        this.csvFileInput.nativeElement.value = "";
        this.csvFileInput.nativeElement.click();
    }

    finishLoading(){
        this.isLoading = false;
        this.isFinished = true;
    }

    handleFileCsvInput(event: Event) {
        this.isLoading = true;
        let files = (event.target as HTMLInputElement & EventTarget).files;
        this.state.file = files[0];
        const reader = new FileReader();
        var extension = files[0].name.split('.').pop().toLowerCase();  //file extension from input file
        var filesize = (files[0].size / (1024*1024)); //file size in MB
        if(extension != 'csv') { //if file is not csv
            this.finishLoading();
            this.onUploadNotCsv();
            this.reset();
        }
        else if(!this.featureFlagService.isEnabled(FeatureFlag.UseConsent) && filesize > 1) { // if file size is bigger the 1MB and isConsent is disabled
            this.finishLoading();
            this.onUpdateError('features.user_settings.body.subscribers.modal.ERROR_MSG_1MB')
            this.reset();
        }
        else if(filesize >= 50) { //if file size is bigger the 50MB
            this.finishLoading();
            this.onUpdateError('features.user_settings.body.subscribers.modal.ERROR_MSG_50MB')
            this.reset();
        }
        else {
            reader.onload = (function (event) {
                // filter all duplicated strings and also empty lines
                const emails: string[] = Array.from(new Set(event.target.result.replace(/['"]+/g, '').split(/\r\n|\n|,/).filter(this.validateEmail)));
                this.state.emailCount = emails.length;
                this.finishLoading();
            }.bind(this));
            reader.readAsText(this.state.file);
        }
    }

    validateEmail(email: string): boolean {
        return EmailValidator.validate(email.trim());
    }

    private makeModalMessage(): string {
        switch (this.getAction()) {
            case Action.ADD:
                return this.translate.instant('features.user_settings.body.subscribers.modal.ADD_ACTION', {emailCount: this.state.emailCount, brandName: this.state.brandName});
            case Action.REMOVE:
                return this.translate.instant('features.user_settings.body.subscribers.modal.REMOVE_ACTION', {emailCount: this.state.emailCount, brandName: this.state.brandName});
        }
    }

    onExportButtonPressed() {
        const modalRef = this.optiLogicModalService.open(
            ExportModalComponent,
            'lg',
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                class: 'export-unsubscribers-select-dates-modal-container',
            }
        );

        const onExportAction$: Observable<void> = modalRef.content.setExportDetails$;

        onExportAction$.subscribe((dates) => {
            this.getUnsubscribersCSV(dates);
        });
    }

    getUnsubscribersCSV(dates) {
        let from = null;
        let to = null;
        if (Array.isArray(dates) && dates.length) {
            from = moment(dates[0]).format(AppConstant.DATE_FORMATS.server);
            to = moment(dates[1]).format(AppConstant.DATE_FORMATS.server);
        }
        const parameterizeArray = (arr) => {
            arr = arr.map(encodeURIComponent)
            return arr
        }
        let formData = new FormData();
        if (from !== null) {
            formData.append("from", from);
        }
        if (to !== null) {
            formData.append("to", to);
        }
        var arr = parameterizeArray(this.state.brandIds);
        arr.forEach(submethod => {
            formData.append("submethodIds", submethod);
        });
        this.settingsSubscribersService.exportBrandUnsubscribers(formData).subscribe(response => {
            console.log(response);
        }, (err) => {
            console.log(err);
        });
    }

    onUpdateButtonPressed() {
        this.openModalMessage(
            this.translate.instant('features.user_settings.body.subscribers.modal.UPDATE_TITLE'),
            this.makeModalMessage(),
            [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('features.user_settings.body.subscribers.UPDATE'),
                    action: this.updateSubscription.bind(this)
                },
                {
                    class: 'btn-default',
                    label: this.translate.instant('general.CANCEL'),
                    action: () => {}
                }
            ]
        )
    }

    onUpdateSuccess() {
        return this.openModalLogicMessage(
            this.translate.instant('general.SUCCESS'),
            this.translate.instant('features.user_settings.body.subscribers.modal.SUCCESS_MSG'),
            'sm',
            [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('general.OK'),
                    action: () => {}
                }
            ]
        )
    }

    onUpdateError(error) {
        return this.openModalLogicMessage(
            this.translate.instant('general.FAILED'),
            this.translate.instant(error),
            'sm',
            [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('general.OK'),
                    action: () => {}
                }
            ]
        )
    }

    onUploadNotCsv() {
        return this.openModalLogicMessage(
            this.translate.instant('features.user_settings.body.subscribers.modal.WRONG_FORMAT_ERROR'),
            this.translate.instant('features.user_settings.body.subscribers.modal.WRONG_FORMAT'),
            'sm',
            [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('general.OK'),
                    action: () => {}
                }
            ]
        )
    }

    onUpdateProcess() {
        return this.openModalLogicMessage(
            this.translate.instant('features.user_settings.body.subscribers.modal.UPDATE_TITLE'),
            '<div class="modal-body-center" id="loading-message">' +
            '<span class="loader-big"></span><div> Updating list </div>' +
            '</div>',
            'sm',
            [
                {
                    isDisabled: () => {
                        return true;
                    },
                    class: 'btn-primary',
                    label: this.translate.instant('general.OK'),
                    action: () => {}
                }
            ]
        )
    }

    onUpdateProcessContinue() {
        return this.openModalLogicMessage(
            this.translate.instant('features.user_settings.body.subscribers.modal.UPDATE_TITLE'),
            '<div class="modal-body-center" id="loading-message">' +
            '<span class="loader-big"></span><br><div> This may take a while <br> We will send an email once complete </div>' +
            '</div>',
            'sm',
            [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('general.OK'),
                    action: () => {}
                }
            ]
        )
    }

    openModalLogicMessage(title: string, message: string, size: string, buttons: IModalButtonConf[]) {
        return this.optiLogicModalService.openOptiLogicModal(
            size,
            {
                title: title,
                html: message,
                buttons: buttons
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
            });
    }

    openModalMessage(title: string, message: string, buttons: IModalButtonConf[]) {
        this.optiLogicModalService.openModalMessage(
            'lg',
            {
                title: title,
                html: message,
                buttons: buttons
            },
            <ModalOptions<any>>{ignoreBackdropClick: false, keyboard: false}
        );
    }

    getAction(): Action {
        return (this.rootForm.get('addOrRemove').value) ? Action.ADD : Action.REMOVE;
    }

    updateSubscription() {
        this.modalRef = this.onUpdateProcess();
        this.isPopUpOpen = true;
        setTimeout(() => {
            if(this.isPopUpOpen){
                this.modalRef.hide();
                this.modalRef = this.onUpdateProcessContinue();
            }
        }, 5000)
        const validRecipientsEmails: string[] = this.getEmailsFromDelimitedString(this.rootForm.get('email').value).filter(this.validateEmail);
        this.addRecipientsEmailsToLocalStorage(validRecipientsEmails);
        this.state.emailsToReport = validRecipientsEmails;

        let formData = new FormData();
        formData.append("csvFile", this.state.file, this.state.file.name);
        this.state.emailsToReport.forEach(email => {
            formData.append("reportingMails", email);
        });
        this.state.brandIds.forEach(brandId => {
            formData.append("submethodIds", brandId.toString());
        });
        const functionName = (this.getAction() === Action.ADD) ? 'addUnsubscribes' : 'removeUnsubscribes';
        this.settingsSubscribersService[functionName](formData)
            .subscribe(response => {
                this.hideLoadMessage();
                if (response.IsSuccess) {
                    this.onUpdateSuccess()
                } else {
                    this.onUpdateError('features.user_settings.body.subscribers.modal.ERROR_MSG_BATCH_RUNNING');
                }
            }, (err) => {
                this.hideLoadMessage();
                this.onUpdateError('features.user_settings.body.subscribers.modal.ERROR_MSG');
            });
        this.reset();
    }

    hideLoadMessage(){
        this.modalRef.hide();
        this.isPopUpOpen = false;
    }

    getFileDetails() {
        if (this.state.file) {
            const emailString = this.state.emailCount > 1 ? this.translate.instant('general.EMAILS') : this.translate.instant('general.EMAIL');
            return `${this.state.file.name} / ${this.state.emailCount} ${emailString}`;
        }
        return '';
    }

    reset() {
        this.state.file = null;
        this.rootForm.reset({addOrRemove: true});
        this.isFinished = false;
    }

    getEmailsFromDelimitedString(commaDelimitedRecipientsEmails: string): string[] {
        if (!commaDelimitedRecipientsEmails) {
            return [];
        }
        return [...new Set<string>(commaDelimitedRecipientsEmails.split(",").map(email => email.trim()))];
    }

    addRecipientsEmailsToLocalStorage(newRecipientEmails: string[]) {
        const existingRecipientsEmails = JSON.parse(localStorage.getItem(OPTIMAIL_SEND_TEST_RECIPIENTS_EMAILS_KEY) ?? "[]");
        var allRecipientsEmails = new Set([...newRecipientEmails, ...existingRecipientsEmails]);
        localStorage.setItem(OPTIMAIL_SEND_TEST_RECIPIENTS_EMAILS_KEY, JSON.stringify(allRecipientsEmails));
        this.allEmails = Array.from(allRecipientsEmails);
    }

    getExistingRecipients() {
        var existingRecipientsEmails = JSON.parse(localStorage.getItem(OPTIMAIL_SEND_TEST_RECIPIENTS_EMAILS_KEY));
        this.allEmails = existingRecipientsEmails ? Array.from(existingRecipientsEmails) : [];
    }
}
