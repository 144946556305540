import {Component, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {IModalConf} from "../../optiLogicModal.service";
import {BsModalRef} from "ngx-bootstrap/modal";

/**
 * In order to use OptiModalComponent you should include it in your modal component template
 *
 * and you should pass your configuration as IModalConf like in the following html code:
 *
 *              <opti-logic-modal [modalConf]="modalConf">
 *                  <!-- Your html goes here -->
 *              </opti-logic-modal>
 *
 * for fully implementation example please see LogicModalExampleComponent.
 *
 * after modal component implementation you should pass it to OptiLogicModalService.open method
 *
 */
@Component({
    selector: 'opti-modal',
    templateUrl: './optiModal.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./optiModal.component.scss']
})
export class OptiModalComponent {


    constructor(private modalService: BsModalService) {
    }

    @Input() public modalConf: IModalConf;
    @ViewChild('template', {static: false}) template: TemplateRef<any>;

    public modalRef: BsModalRef;

}