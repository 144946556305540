<div class="opti-modal opti-modal-window">
    <div class="modal-header" *ngIf="modalConf.title">
        <div class="header-start">
            <h2 *ngIf="modalConf.title || modalConf.iconMaterial" class="modal-title">
                <i *ngIf="modalConf.iconMaterial" class="material-icons check-circle"
                   [ngClass]="modalConf.iconMaterial.type">
                    {{modalConf.iconMaterial.name}}</i>
                {{modalConf.title}}
            </h2>
            <div *ngIf="modalConf.subtitle" class="sub-title">
                <h2 class="seperator">|</h2>
                <h2 class="sub-title-text text-muted">{{ modalConf.subtitle }}</h2>
            </div>
        </div>
        <button *ngIf="modalConf.xButton" (click)="modalConf.xButton.action()" type="button"
                class="close-modal btn btn-link btn-link-default">
            <span class="material-icons" aria-hidden="true">close</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="!modalConf.html">
                <p class="opti-modal-message" *ngIf="modalConf.message">{{modalConf.message}}</p>
            </div>
            <div class="col-md-12" *ngIf="modalConf.html">
                <p class="opti-modal-message" [innerHTML]="modalConf.html"></p>
            </div>
        </div>
        <div class="row">
            <div class="modal-buttons">
                <div class="col-md-12">
                    <ul class="list-inline action-buttons">
                        <li *ngFor="let button of modalConf.buttons" class="col-md-2 opti-modal-button">
                            <button type="button" class="btn" (click)="button.action(modalRef)"
                                    [disabled]="!!button.isDisabled && button.isDisabled()" [ngClass]="button.class">
                                {{button.label}}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>