export enum UniquePromoErrors {
  NoErrors = 0,
  PromoNameNotUnique = 1,
  CSVFileSizeIsTooBig = 2,
  CSVFileIncorectType = 3,
  CSVNoRowsToImport = 4,
  FailedToCreatePromotion = 5,
  PromocodeNotValid = 6,
  GetPromotionFailed = 7,
  FailedToUpdatePromotion = 8,
  DeleteAllPromotionFailed = 9,
  FailedToUploadCSVFile = 10,
}
