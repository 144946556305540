<div class="move-tgs-to-stream-save-modal">
    <div class="overlay"></div>

    <div class="opti-modal opti-modal-window modal-content">
        <div class="modal-body" [ngClass]="{ 'sm': !isTgsHaveFutureCamapaigns, 'md': isTgsHaveFutureCamapaigns, 'saving': isSaving }">
            <div *ngIf="!isSaving" class="confirm-container">
                <div *ngIf="!isTgsHaveFutureCamapaigns">
                    <div class="confirm_desc" [innerHTML]="translateKeys.confirmDesc | translate"></div>
                    
                    <div>
                        {{ translateKeys.confirmQuestion | translate }}
                    </div>
                </div>
                
            
                <div *ngIf="isTgsHaveFutureCamapaigns">
                    <div class="confirm_desc" [innerHTML]="translateKeys.futureCampaignsConfirmDesc | translate"></div>
                
                    <div>
                        {{ translateKeys.futureCampaignsConfirmQuestion | translate }}
                    </div>
                </div>
            
                <div class="buttons-container">
                    <button class="btn btn-default" (click)="onCancel()">{{ translateKeys.buttons.dontMove | translate }}</button>
                    <button class="btn btn-primary" (click)="onSave()">{{ translateKeys.buttons.move | translate }}</button>
                </div>
            </div>
        
            <div *ngIf="isSaving" class="saving-loader-container">
                <div class="loader">
                    <opti-loading></opti-loading>
                </div>

                <div class="msg">
                    {{ translateKeys.loaderMsg | translate }}
                </div>
            </div>  
        </div>
    </div>
</div>
