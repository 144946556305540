import { Injectable, Inject } from '@angular/core';

@Injectable()
export class AlertsService {
    _alerts: any[];

    constructor(@Inject('$scope') private $rootScope: any, @Inject('$timeout') private $timeout: any) {}

    init() {
        this._alerts = this.$rootScope.vm.alerts = [];
        this.$rootScope.vm.closeAlert = this.closeAlert;
    }

    getAlerts() {
        return this.$rootScope.vm.alerts;
    }

    closeAlert(index: number) {
        this._alerts.splice(index, 1);
        this.$timeout();
    }

    addAlert(alert) {
        this._alerts.push(alert) - 1;
        this.$timeout();
        return () => {
            this._alerts = this._alerts.filter((a) => { a !== alert })
        };
    }

    clearAlerts() {
        this._alerts.length = 0;
        this.$rootScope.vm.alerts.length = 0;
        this.$timeout();
    }
}