<div class="input-container">
    <label for="from">
        {{currencySymbol}}
    </label>
    <input data-qa-id="condition-from-value-input"
        [ngClass]="{'invalid': !from.valid && isPickerTouched, 'currency-padding': currencySymbol}"
        type="number"
        [disabled]="disabled"
        [(ngModel)]="from.value"
        (blur)="isPickerTouched = true"
        (change)="onValueChanged()"
        placeholder="{{ translateKeys.from | translate}}"
        id="from">
</div>
-
<div class="input-container">
    <label for="to">
        {{currencySymbol}}
    </label>
    <input data-qa-id="condition-to-value-input"
        [ngClass]="{'invalid': !to.valid && isPickerTouched, 'currency-padding': currencySymbol}"
        type="number"
        [(ngModel)]="to.value"
        [disabled]="disabled"
        (blur)="isPickerTouched = true"
        (change)="onValueChanged()"
        placeholder="{{ translateKeys.to | translate}}"
        id="to">
</div>
