import { first } from 'rxjs/operators';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, ViewEncapsulation } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { TemplateModalComponent } from '../templateModal/templateModal.component';
import { CampaignGridService } from '../campaign-grid.service';

@Component({
    selector: 'campaign-grid-preview-column',
    templateUrl: 'campaignGridPreviewColumn.component.html',
    styleUrls: ['./campaignGridPreviewColumn.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CampaignGridPreviewColumnComponent implements ICellRendererAngularComp {

    public iconDisabled: boolean = true;
    private templateId: string;
    private groupType: number;
    private campaignExecutionDate: string;
    private clientCustomerId: string;

    constructor(private optiLogicModalService: OptiLogicModalService,
                private campaignGridService: CampaignGridService) { }

    agInit(params: any): void {
        this.templateId = params.value[0].TemplateId;
        this.groupType = params.value[0].GroupType;
        this.campaignExecutionDate = params.data.StartDate;
        this.clientCustomerId = params.data.ClientCustomerId;

        //GroupType: 1 is OptiMail
        if (this.groupType === 1 && !isNaN(Number(this.templateId))) {
            this.campaignGridService.doesTemplateExist(this.templateId).pipe(first()).subscribe(doesExist => {
                this.iconDisabled = !doesExist;
            });
        }
    }
    
    public openTamplateModal() {
        if (!this.iconDisabled) {
            this.optiLogicModalService.open(
                TemplateModalComponent,
                'lg',
                <ModalOptions<any>>{
                    ignoreBackdropClick: false,
                    initialState:{
                        clientCustomerId: this.clientCustomerId,
                        templateId: this.templateId, 
                        campaignExecutionDate: this.campaignExecutionDate,
                    }
                }
            );
        }
    }

    refresh(): boolean {
        return true;
    }
}
