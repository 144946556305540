import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { AttributeHelper } from "../../attributesList.helper";
import { AttributeTypeOptions } from "../../attributesList.model";

@Component({
    selector: 'attributes-count-tag',
    templateUrl: './attributeCountTag.component.html',
    styleUrls: ['./attributeCountTag.component.scss'],
})
export class AttributesCountTag implements OnInit {
    @Input() attributes: any[] = []
    @Input() config: AttributeCountTagConfig;

    public translateKeys = translateKeys;
    public attributesTypesInfo = {
        'DataBase' : {
            title: this.translate.instant(this.translateKeys.databaseAttributes),
            count: 0
        },
        'API' : {
            title: this.translate.instant(this.translateKeys.apiAttributes),
            count: 0
        },
        'RT' : {
            title: this.translate.instant(this.translateKeys.realtimeAttributes),
            count: 0
        },
        'Calculated' : {
            title: this.translate.instant(this.translateKeys.calculatedAttributes),
            count: 0
        },
        'WrongType' : {
            title: 'Without type',
            count: 0
        }
    };
    public attributesCountTag = {
        type: ColoredTagType.Type8,
        description: ""
    };

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.attributes.forEach(x => {
            let types: AttributeTypeOptions = AttributeHelper.getFullAttributeType(x);
            types.isAPI && !this.config.hideAPIAttrs ? this.attributesTypesInfo.API.count += 1 :
            types.isRT && !this.config.hideRTAttrs ? this.attributesTypesInfo.RT.count += 1 :
            types.isCalculated && !this.config.hideCalculatedAttrs ? this.attributesTypesInfo.Calculated.count += 1 :
            types.isBase && !this.config.hideDatabaseAttrs ? this.attributesTypesInfo.DataBase.count += 1 : 
            (() => { if(this.config.showAttrsWithoutType) this.attributesTypesInfo.WrongType.count += 1})();
        });
        this.attributesCountTag.description = this.translate.instant(this.translateKeys.popoverBtn);
    }
}

const translateKeys = {
    popoverBtn: 'features.user_settings.body.attributes.countPopover.BUTTON_TITLE',
    totalAttributes: 'features.user_settings.body.attributes.countPopover.TOTAL_ATTRIBUTES',
    databaseAttributes: 'features.user_settings.body.attributes.countPopover.DATABASE_ATTRIBUTES',
    apiAttributes: 'features.user_settings.body.attributes.countPopover.API_ATTRIBUTES',
    calculatedAttributes: 'features.user_settings.body.attributes.countPopover.CALCULATED_ATTRIBUTES',
    realtimeAttributes: 'features.user_settings.body.attributes.countPopover.REALTIME'
}

export interface AttributeCountTagConfig {
    hideDatabaseAttrs?: boolean;
    hideAPIAttrs?: boolean;
    hideCalculatedAttrs?: boolean;
    hideRTAttrs?: boolean;
    showAttrsWithoutType?: boolean;
}