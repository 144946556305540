import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'unsubscribe-tags-modal',
	templateUrl: './unsubscribeTagsModal.component.html',
	styleUrls: ['./unsubscribeTagsModal.component.scss']
})
export class UnsubscribeTagsModalComponent {
	initialValue = '';
	unsubscribeTags: [];
	tagSelected: (selected: number) => void;
	public groupBy: string = 'groupBy';
	
	constructor(public bsModalRef: BsModalRef) {}

	onSelectedChanged(value: number) {
		if (this.tagSelected) {
			this.tagSelected(value);
		}
		this.bsModalRef.hide();
	}
}
