import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'folder-delete',
	templateUrl: './folderDelete.component.html',
	styleUrls: ['./folderDelete.component.scss']
})
export class FolderDeleteComponent {
	@Input() templatesInUse: { templateName: string }[] = [];

	constructor(private bsModalRef: BsModalRef) {}

	close(): void {
		this.bsModalRef.hide();
	}
}
