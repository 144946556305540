import { isNullOrUndefined } from 'util';
import {
  OnInit,
  ContentChildren,
  QueryList,
  HostListener,
  ChangeDetectorRef,
  ViewChildren,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Component,
  AfterViewInit,
  AfterContentChecked
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  template: '',
})
export abstract class TabsManager implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChildren('tabHeader', { read: ElementRef }) tabHeaders: QueryList<ElementRef>;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  activeTab: TabComponent;
  private _focused = false;
  protected _activeTabIndex = 0;

  @Input() disabledTabsIndexes: number[] = [];

  @Input() set activeTabIndex(value: number) {
    const activeTabIndex = !isNullOrUndefined(value) ? value : 0;
    if (!isNullOrUndefined(this.tabs)) {
      this._setSelectedTab(activeTabIndex);
    }
  }

  @Output() tabSelected = new EventEmitter<number>();

  protected constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngAfterContentChecked() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this._setSelectedTab(this._activeTabIndex);
    }

    const tabs = this.tabs.toArray();
    this.disabledTabsIndexes.forEach((tabIdx) => {
      tabs[tabIdx].disabled = true;
    });
  }

  selectTab(index: number) {
    if (this.disabledTabsIndexes.indexOf(index) !== -1) {
      return;
    }
    this._setSelectedTab(index);
    this.tabSelected.emit(index);
  }

  _setSelectedTab(index: number) {
    const tab = this.tabs.toArray()[index];
    this.tabs.toArray().forEach(arrayTab => arrayTab.active = false);
    if (tab !== undefined) {
      tab.active = true;
      this.activeTab = tab;
      this._activeTabIndex = index;
    }
  }

  _getTabIndex(tab: TabComponent): number | null {
    return tab === this.activeTab ? 0 : -1;
  }

  _getTabContentId(i) {
    return `mat-tab-content-${i}`;
  }

  onFocus() {
    this._focused = true;
  }

  onFocusOut() {
    this._focused = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this._focused) {
      const tabs = this.tabs.toArray();
      const tabHeaders = this.tabHeaders.toArray();
      if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
        return;
      }

      let activeTabIndex = tabs.findIndex(t => t === this.activeTab);
      if (event.key === 'ArrowLeft') {
        activeTabIndex++;
      }
      if (event.key === 'ArrowRight') {
        activeTabIndex--;
      }
      if (activeTabIndex !== -1 && tabs[activeTabIndex]) {
        tabHeaders[activeTabIndex].nativeElement.focus();
        this.selectTab(activeTabIndex);
        this.cd.detectChanges();
      }
    }
  }
}
