import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiUserDetails } from '../ApiUserDetails/apiUserDetails.component';
import { OptiLogicModalService } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { ApiManagementService } from "../apiManagement.service";
import { Subscription } from "rxjs";
import { AccessType } from "../models/accessType.model";
import { emailValidator } from "../../../../directives/customValidation/emailValidation.directive";
import { userNameValidator } from "../../../../directives/customValidation/userNameValidation.directive";

@Component({
    selector: 'create-api-user',
    templateUrl: './createApiUser.component.html',
    styleUrls: ['./createApiUser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateApiUser implements OnInit, OnDestroy {

    @ViewChild('apiUserDetails', {static: false}) apiUserDetails: ApiUserDetails;
    private maxUserNameLength: number = 64;
    public loading: boolean = true;
    public isSaving: boolean = false;
    public userExistsMsg: string;
    public parentForm: FormGroup;
    private createApiUserSubscription?: Subscription;
    public accessTypes = [AccessType.Full, AccessType.Limited];
    public translateKeys = translateKeys;

    constructor(private translateService: TranslateService,
        private fb: FormBuilder,
        private router: Router,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private apiManagementService: ApiManagementService) {
    }

    ngOnInit(): void {
        this.parentForm = this.fb.group({
        accessType: [AccessType.Full],
        role: [null, Validators.required],
        userName: ['', [Validators.required, userNameValidator(), Validators.maxLength(this.maxUserNameLength)]],
        email: ['', [Validators.required, emailValidator()]]
        });
    }

    private hideSaveAndShowSuccessMsg(){
        this.isSaving = false;
        this.showValidationMessage(this.translateService.instant(translateKeys.successCreate),
            this.translateService.instant(translateKeys.newApiUserEmail));
    }

    private closeModal(title: string) {
        this.bsModalRef.hide();
        if (title == this.translateService.instant(translateKeys.successCreate)) {
            this.router.navigateByUrl('/user-settings/apiManagement');
        }
    }

    private showValidationMessage(title: string, message: string) {
        this.modalService.openModalMessage(
            'sm',
            {
                title: title,
                message: message,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant(translateKeys.ok),
                    action: () => this.closeModal(title),
                    dataQaId: 'createUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    private hideSaveAndShowErrorMsg(errMsg: string){
        this.isSaving = false;
        this.showValidationMessage(
            this.translateService.instant(translateKeys.failureCreate), errMsg);
    }

    onSubmit(): void {
        this.isSaving = true;
        let apiUser = this.apiUserDetails.buildNewApiUser();
        this.createApiUserSubscription = this.apiManagementService.createApiUser(apiUser).subscribe(async (response: AjaxResponse<string>) => {
            if(response.isSuccess){
                this.hideSaveAndShowSuccessMsg();
            } else {
                this.isSaving = false;
                (response.errorMsg) ? this.userExistsMsg = response.errorMsg 
                    : this.hideSaveAndShowErrorMsg(this.translateService.instant(translateKeys.generalError));
            }
        }, (_) => this.hideSaveAndShowErrorMsg(this.translateService.instant(translateKeys.generalError)));
        return;
    }

    ngOnDestroy() {
        this.createApiUserSubscription?.unsubscribe();
    }
}

const translateKeys = {
    successCreate: 'features.user_settings.body.manage_users.edit.operations.save.SUCCESS_CREATE',
    failureCreate: 'features.user_settings.body.manage_users.edit.operations.save.FAILURE_CREATE',
    newApiUserEmail: 'features.user_settings.body.manage_users.edit.account.body.NEW_API_USER_EMAIL',
    ok: 'general.OK',
    generalError: 'general.validation_error_messages.GENERIC_ERROR',
    back: 'general.BACK',
    title: 'features.user_settings.body.apiManagement.apiUserDetails.TITLE',
    cancel: 'features.user_settings.body.attributes.addAttribute.CANCEL',
    save: 'features.user_settings.body.attributes.addAttribute.SAVE',
    saving: 'features.user_settings.body.loaders.SAVING'
}