import { Component, OnInit, Input, OnDestroy , ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { IModalConf } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import {Subject, Subscription} from "rxjs";
import {IDateRangePicker} from "../../../../../components/dateRangePicker/dateRangePicker.component";
import * as moment from "moment";
import {AppConstant} from "../../../../../app.constatnt";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SettingsSubscribersService} from "../../settingsSubscribers.service";

@Component({
    templateUrl: 'exportModal.component.html',
    styleUrls: ['exportModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExportModalComponent implements OnInit, OnDestroy {

    public setExportDetails$: Subject<any> = new Subject();
    public dateRangePickerSettings: IDateRangePicker;
    public exportModalConfig: IModalConf;
    public message: string;
    private startDate: string;
    private endDate: string;
    public exportForm: FormGroup;
    public dataRangeClass = "disabledEvents";
    private subscription;
    public dateRangeControl: FormControl;
    public email: string;
    constructor(private translateService: TranslateService, private bsModalRef: BsModalRef, private settingsSubscribersService: SettingsSubscribersService) {
    }

    ngOnInit() {
        this.createForm();
        this.dateRangeControl = this.exportForm.get('dateRange') as FormControl;
        this.setModalConfig();
        this.subscription = this.exportForm.get('allTime').valueChanges.subscribe((isAllTime: any) => {
            if (isAllTime) {
                this.dataRangeClass = "disabledEvents";
                this.dateRangeControl.setValue(null);
            } else {
                this.dataRangeClass = "enabledEvents";
            }
        });
        const date = this.getMaxDate();
        this.dateRangePickerSettings = {
            date: [],
            maxDate: date
        };
        this.settingsSubscribersService.GetCustomerEmail().subscribe((response) => {
            this.email = response;
        });
    }

    getMaxDate():Date
    {
        return new Date();
    }
    createForm() {
        this.exportForm = new FormGroup(
            {
                allTime: new FormControl(true, [Validators.required]),
                dateRange: new FormControl(null)
            }
        );
    }

    public dateRangePickerDataChanged(e: Date[]): void {
        if(e && e.length) {
            this.startDate =  moment(e[0]).format(AppConstant.DATE_FORMATS.server);
            this.endDate =  moment(e[1]).format(AppConstant.DATE_FORMATS.server);
        }
    }

    public onExportAction() {
        if (this.exportForm.get('allTime').value) {
            this.setExportDetails$.next(null);
        } else {
            this.setExportDetails$.next(this.exportForm.get('dateRange').value);
        }
        this.closeModal();
    }

    private closeModal() {
        this.bsModalRef.hide();
    }

    private setModalConfig() {
        const okBtnLabel = this.translateService.instant("general.OK");

        this.exportModalConfig = {
            title: this.translateService.instant('features.user_settings.body.subscribers.EXPORT_LABEL'),
            buttons:
                [
                    {
                        class: 'btn-primary',
                        label: this.translateService.instant('general.EXPORT_TO_CSV'),
                        action: () => {
                            this.onExportAction();
                            this.bsModalRef.hide();
                        }
                    },
                    {
                        class: 'btn-default',
                        label: this.translateService.instant('general.CANCEL'),
                        action: () => {
                            this.bsModalRef.hide();
                        }
                    }
                ]
        };
    }

    ngOnDestroy(): void {
        this.setExportDetails$ && this.setExportDetails$.unsubscribe();
        this.subscription && this.subscription.unsubscribe();
    }

}
