<div class="preference-audit-card">
    <div class="preference-audit-card-header">
        <div class="audit-info-container">
            <img class="audit-icon" [src]="imageSrc" alt="">
            <div class="audit-info">
                <span class="audit-info-channel-name">{{channelName}}</span>
                <div class="audit-info-details">
                    <span *ngIf="contactDetails">{{contactDetails}}</span>
                    <div *ngIf="contactDetails" class="separator"></div>
                    <span *ngIf="isClientReachable()">
                        {{'features.customer360.preference_center_modal.REACHABLE' | translate}}
                    </span>
                    <span *ngIf="!isClientReachable()">
                        {{'features.customer360.preference_center_modal.NOT_REACHABLE' | translate}}
                    </span>
                </div>
            </div>
        </div>
        <div class="download-container">
            <button (click)="exportCSV(channelId)"
                    class="download-csv-button">
                <span class="download-icon material-icons">system_update_alt</span>
                <span class="download-text">{{'features.customer360.preference_center_modal.DOWNLOAD_CSV' | translate}}</span>
            </button>
            <colored-tag *ngIf="consents && consentActionDetails && consentActionDetails['optedIn'] === true" [coloredTagType]="coloredTagTypeIn">
                {{'features.customer360.preference_center_modal.OPT-IN' | translate}}
            </colored-tag>
            <colored-tag *ngIf="!consents || consentActionDetails && consentActionDetails['optedIn'] === false" [coloredTagType]="coloredTagTypeOut">
                {{'features.customer360.preference_center_modal.OPT-OUT' | translate}}
            </colored-tag>
        </div>
    </div>
    <div class="preference-audit-table">
        <div class="audit-table-header">
            <div class="audit-table-column topic-column">
                <span>{{'features.customer360.preference_center_modal.TOPIC' | translate}}</span>
            </div>
            <div class="audit-table-column interested-column">
                <span>{{'features.customer360.preference_center_modal.INTERESTED' | translate}}</span>
            </div>
            <div class="audit-table-column description-column">
                <span>{{'features.customer360.preference_center_modal.DESCRIPTION' | translate}}</span>
            </div>
        </div>
        <div *ngIf="areTopicsReady()" class="audits-rows-wrapper">
            <div class="audit" *ngFor="let audit of topicSubscriptions">
                <div class="topic-column">
                    <span class="topic-name">{{audit.name}}</span>
                </div>
                <div class="interested-column">
                    <span *ngIf="audit.isSubscribed" class="is-interested material-icons">check</span>
                </div>
                <div class="description-column">
                    <span class="topic-description">{{audit.description}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!areTopicsReady()" class="preference-history-message-wrapper">
            <span class="preference-history-message no-topics-message">{{'features.customer360.preference_center_modal.NO_CONFIGURED_TOPICS' | translate}}</span>
        </div>
    </div>
</div>
