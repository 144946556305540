
<div class="export-button">
    <multiple-options-button
        *ngIf="!showLoader"
        (itemClicked)="onButtonClick($event)" 
        [isLoading]="false"
        [loadingText]="'features.mission_control.export_to_csv_exporting_label' | translate"
        [inputObj]="multipleOptionsButtonObject" 
        [disabled]="disabled">
        <i class="material-icons">open_in_browser</i>
    </multiple-options-button>
    <span class="click-loader loader-wrapper pull-left" *ngIf="showLoader">
        <span class="loader pull-left" ></span>
        <p class="small loader-text">{{translateKeys.LOADING_TEXT | translate}}</p>
    </span>
</div>