import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive } from "@angular/core";
import {DateRangeInvalidStateMode} from "./dateRangeInvalidStateMode.enum";

export function validateDates(control: AbstractControl): ValidationErrors | null {
    const isValidDate = (d: any) => {
        return d instanceof Date && !isNaN(d.valueOf())
    };
    const d = control.value;
    const isValid = Array.isArray(d) && d.length === 2 && isValidDate(d[0]) && isValidDate(d[1]);
    const validationError = {};
    validationError[DateRangeInvalidStateMode.Invalid] = "true";

    return isValid ? null : validationError;
}

@Directive({
    selector: "[validDates]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidDatesValidatorDirective,
        multi: true
    }]
})

export class ValidDatesValidatorDirective implements Validator {
    validate(c: AbstractControl): { [key: string]: any; } {
        return validateDates(c);
    }
}