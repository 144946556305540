import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

export const patternURL = new RegExp('^(https?:\\/\\/)?' + // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
  '(\\#[-a-z\\d_]*)?$', 'i');

@Directive({
  selector: "[urlValidation]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UrlValidationDirective,
      multi: true,
    },
  ],
})
export class UrlValidationDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | any {
    const value = control.value;

    if (value) { 
       if(!patternURL.test(value))
          return {
            invalidUrl: true,
          };
    }
    return null;
  }
}
