import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import { DailyStatus, DataUpdateStatusEnum } from 'src/app/models/dailyServiceStatus.model';
import { FeatureFlag } from 'src/app/services/featureFlag.service';
import { WindowRefService } from 'src/app/services/windowRef.service';
import { ZendeskWidgetService } from 'src/app/services/zendeskWidget.service';
import { AboutOptimoveComponent } from '../../../../components/aboutModal/about.component';
import { CustomerAttributesComponent } from '../../../../components/navBar/customerAttributesModal/customerAttributes.component';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { FeatureFlagData } from '../../../../models/featureFlagData.model';
import { MailService } from '../../../../services/mail.service';
import { SsmServiceEndPoints } from '../../../../services/optimove-ssm/optimove-ssm-resources.model';
import { SsmService } from '../../../../services/ssm.service';
import { TenantDetails } from '../../models/tenantDetails.model';
import { NewNavbarService } from '../../services/newNavbar.service';

@Component({
  selector: 'topnavbar',
  templateUrl: './topNavbar.component.html',
  styleUrls: ['./topNavbar.component.scss'],
})
export class TopNavbarComponent implements OnInit, OnChanges {
  @Input() isDemoMode: boolean;
  @Input() dailyStatus: DailyStatus;
  @Input() isSettingsVisible: boolean;
  isSucceessHub: boolean = false;
  isUserMenuOpen: boolean = false;
  isDailyMenuOpen: boolean = false;
  updateStatus: string;
  userDetails: TenantDetails;
  userInitials: string = 'OU';
  userFullName: string = null;
  dataUpdateStatusEnum = DataUpdateStatusEnum;
  isOptiGenie = false;

  constructor(
    private readonly newNavbarService: NewNavbarService,
    private readonly modalService: OptiLogicModalService,
    private readonly ssmService: SsmService,
    private readonly cd: ChangeDetectorRef,
    private zendeskWidgetService: ZendeskWidgetService,
    private window: WindowRefService,
    public mailService: MailService
  ) {}
  ngOnInit(): void {
    this.ssmService
      .GetGeneric<TenantDetails>(SsmServiceEndPoints.TenantDetails)
      .subscribe((userDetails: TenantDetails) => {
        this.userDetails = new TenantDetails(
          userDetails.name,
          userDetails.lastName,
          userDetails.userName,
          userDetails.userRole,
          userDetails.tenantId,
          userDetails.userId
        );
        this.userInitials = this.userDetails.userInitials;
        this.userFullName = this.userDetails.fullName;
        this.cd.detectChanges();
      });

    this.ssmService
      .GetGeneric<FeatureFlagData>(SsmServiceEndPoints.SuccessHubFeatureFlag)
      .pipe(filter((ff) => typeof ff != 'undefined' && ff != null))
      .subscribe((SuccessHubData) => {
        this.isSucceessHub = SuccessHubData.IsEnabled;
        this.cd.detectChanges();
      });

    this.ssmService.GetGeneric(SsmServiceEndPoints.FeatureFlags).subscribe((data) => {
      this.isOptiGenie = data[FeatureFlag.OptiGenieAMA] !== undefined && data[FeatureFlag.OptiGenieAMA] === true;
      this.cd.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateStatus = this.getStatusText();
  }

  showAbout(): void {
    this.modalService.open(AboutOptimoveComponent, 'md', <ModalOptions<any>>{
      ignoreBackdropClick: false,
      keyboard: true,
    });
  }

  openCustomerAttributesModal() {
    this.modalService.open(CustomerAttributesComponent, 'lg', <ModalOptions<any>>{
      ignoreBackdropClick: true,
      class: 'customer-attributes-modal',
    });
  }

  getStatusText() {
    if (!this.dailyStatus) {
      return '';
    }
    var ret = 'components.navbarMenu.DAILY_STATUS.COMPLATE_ERR';
    if (this.dailyStatus.dataUpdateStatus === DataUpdateStatusEnum.Updated) {
      if (this.dailyStatus.analysisStatus === DataUpdateStatusEnum.Updating) {
        ret = 'components.navbarMenu.DAILY_STATUS.IN_PROGRESS';
      } else if (this.dailyStatus.campaignExecutionStatus === DataUpdateStatusEnum.Updated) {
        ret = 'components.navbarMenu.DAILY_STATUS.SUCCESS';
      }
    } else if (
      this.dailyStatus.campaignExecutionStatus !== DataUpdateStatusEnum.NotUpdated &&
      this.dailyStatus.analysisStatus === DataUpdateStatusEnum.Updating
    ) {
      ret = 'components.navbarMenu.DAILY_STATUS.SCHEDULED';
    }
    if (this.dailyStatus.isRunning && moment().diff(moment(this.dailyStatus.lastSuccessfulRunDate), 'days') < 2) {
      ret = 'components.navbarMenu.DAILY_STATUS.IN_PROGRESS';
    }
    if (this.isDemoMode) {
      ret = 'components.navbarMenu.DAILY_STATUS.SUCCESS';
    }
    return ret;
  }

  openOperationStatus() {
    window.open('https://status.optimove.net/', '_blank');
  }

  logout() {
    this.newNavbarService.logout();
  }

  toggleUserMenu() {
    this.isUserMenuOpen = !this.isUserMenuOpen;
    this.isDailyMenuOpen = false;
    this.cd.detectChanges();
  }

  toggleDailyStatusMenu() {
    this.isDailyMenuOpen = !this.isDailyMenuOpen;
    this.isUserMenuOpen = false;
    this.cd.detectChanges();
  }

  toggleAcademyIcon() {
    this.zendeskWidgetService.toggleZendeskWidget();
  }

  toggleOptiGenieIcon() {
    if (this.window.nativeWindow.OptiGenie && this.window.nativeWindow.OptiGenie.open)
      this.window.nativeWindow.OptiGenie.open();
  }
}
