<div class="api-management-container">
    <div class="panel-heading" data-qa-id="ApiManagementTitle">
        <h2> {{translateKeys.title | translate }} </h2>
        <a [routerLink]="['/user-settings/createApiUser']" class="btn btn-font-size pull-right btn-link events-stream-btn" 
        data-qa-id="addNewApi" [routerLinkActive]="['active']"  disabled="true">
            <i class="material-icons">add</i>
            <span><b>{{ translateKeys.addNewApiUser| translate }}</b></span>
        </a>
    </div>
    <opti-loading [show]="loading" [size]="'md'"></opti-loading>
    <div class="panel-body user-list">
        <form class="form-horizontal" autocomplete="off">
            <div class="form-group">
                <label class="col-md-2 control-label" for="APIEndPoint" data-qa-id="APIEndPoint">
                    {{translateKeys.apiEndPoint| translate }}
                </label>
                <div class="col-md-5" data-qa-id="ApiUrl">
                    <input type="text" id="APIEndPoint" class="form-control" [disabled]="true"
                        value={{apiEndPointVal}}>
                </div>
                <a (click)="goToAPIDocumentation()" class="btn btn-link api-document" data-qa-id="ApiDocumentation">
                    <span>
                        <i class="material-icons">launch</i>
                        <b>{{translateKeys.apiDocumentation| translate }}</b>
                    </span>
                </a>
            </div>
            <table class="table" *ngIf="apiUsersData.length>0" data-qa-id="ExistingApiUsersTable">
                <thead>
                    <tr>
                        <th (click)="sortUsers('UserName')" class="col-md-2">
                            {{translateKeys.colUserName| translate }}
                            <i *ngIf="sortBy('UserName', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('UserName', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('ContactEmail')" class="col-md-3">
                            {{translateKeys.colContactEmail| translate }}
                            <i *ngIf="sortBy('ContactEmail', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('ContactEmail', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('ApiRole')" class="col-md-2">
                            {{translateKeys.colRole| translate }}
                            <i *ngIf="sortBy('ApiRole', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('ApiRole', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('LastLoginDate')" class="col-md-2">
                            {{translateKeys.colLastLogin| translate }}
                            <i *ngIf="sortBy('LastLoginDate', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('LastLoginDate', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('Active')" class="col-md-2">
                            {{translateKeys.colActive| translate }}
                            <i *ngIf="sortBy('Active', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('Active', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of apiUsersData; index as i" data-qa-id="ApiUserData">
                        <td class="col-md-2" ellipsis-tooltip="1">{{user.UserName}}</td>
                        <td class="col-md-3" ellipsis-tooltip="1">{{user.ContactEmail}}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">{{user.ApiRole}}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">{{ (user.LastLoginDate !== null) ? (convertToDatestring(user.LastLoginDate, true)) : "-" }}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">
                            <switch *ngIf="userProcessingIndex !== i" data-qa-id="toggleActive" [buttonType]="switchButtonType" (ngModelChange)="updateIsActive([$event, user])"
                                [(ngModel)]="user.Enabled" [ngModelOptions]="{standalone: true}"></switch>
                            <span *ngIf="userProcessingIndex === i" class="click-loader loader-wrapper" data-qa-id="processingloader">
                                <span class="loader loader-processing"></span>
                                <span class="text-processing">{{ translateKeys.processing | translate}}</span>
                            </span>
                            <div class="pull-right">
                                <sup>                               
                                    <a class="btn btn-link btn-link-default" data-qa-id="deleteIcon" (click)="openUpdateUserModal(user)">
                                        <i tooltip-append-to-body="true" uib-tooltip="Delete user account"
                                            class="material-icons">delete</i>
                                    </a>
                                </sup>
                            </div>
                        </td>
                    </tr> 
                </tbody>
            </table>
            <div class="empty-state" *ngIf="apiUsersData.length===0" data-qa-id="emptyStateApiManagement">
                <div class="empty-state-content empty-state-api-management">
                    <div class="empty-state-text text-center">
                        <h1 class="text-muted empty-state-api-management-text">
                            {{translateKeys.noUserConfigured| translate }}
                        </h1>
                       <sup>
                            <a [routerLink]="['/user-settings/createApiUser']" class="btn btn-font-size btn-link" 
                            data-qa-id="addNewApi" [routerLinkActive]="['active']" disabled="true">
                                <i class="material-icons">add</i>
                                <span>{{ translateKeys.addNewUser| translate }}</span>
                            </a>
                       </sup>
                    </div>
                </div>
            </div> 
        </form>
    </div>
</div>