<div class="dialog-root">
  <div class="header-container">
    <p class="header-title">{{ "features.user_settings.body.preference_center.SDK_ID" | translate }}</p>
    <div class="close-button" (click)="close()">
      <i class="material-icons">close</i>
    </div>
  </div>
  <p class="input-label">
    {{ "features.user_settings.body.preference_center.ID" | translate }}
  </p>
  <div class="body-container">
    <p class="id-input">{{ sdkConfig }}</p>
    <div class="id-input-button" (click)="copy()">
      <i *ngIf="!justCopied" class="material-icons">content_copy</i>
      <p *ngIf="justCopied" class="copied-text">
        {{ "features.user_settings.body.preference_center.COPIED" | translate }}
      </p>
    </div>
  </div>
  <p class="input-label">
    {{ "features.user_settings.body.preference_center.BRAND_GROUP_ID" | translate }}
  </p>
  <div class="body-container">
    <p class="id-input">{{ brandGroupId }}</p>
    <div class="id-input-button-brand" (click)="copyBrandGroupId()">
      <i *ngIf="!justCopiedBrandGroup" class="material-icons">content_copy</i>
      <p *ngIf="justCopiedBrandGroup" class="copied-text">
        {{ "features.user_settings.body.preference_center.COPIED" | translate }}
      </p>
    </div>
  </div>
  <div class="separator"></div>
  <div class="footer-container">
    <button class="btn btn-primary ok-button" (click)="close()">{{ "general.OK" | translate }}</button>
  </div>
</div>
