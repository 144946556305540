import {
	ICustomerAttributeMapping,
	IFormatMetricOptions,
	ISelectableAttribute,
	PurchaseHistoryItem,
	PurchaseHistoryMultiAttributeFlowRowData,
	IHeaderMetric,
	ActivityAttribute
} from 'src/app/features/customer360/customer360.models';
import { Injectable } from "@angular/core";
import { FormatterPipe } from 'src/app/pipes/formatter.pipe';
import { Customer360DataService } from 'src/app/features/customer360/services/customer360.data.service';
import moment from 'moment';

@Injectable()
export class Customer360FormatService {

	constructor(private formatterPipe: FormatterPipe, private dataService: Customer360DataService) {}

	public getDateXDaysAgo(daysAgo: number): Date {
		const date = new Date;
		date.setDate(date.getDate() - daysAgo)
		return date;
	}

	public getDateAfterXDays(startDate: Date, days: number): Date {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		const endDate = new Date;
		endDate.setTime(startDate.getTime() + (millisecondsPerDay * days));
		return endDate;
	}

	public formatMetric(options: IFormatMetricOptions): IHeaderMetric {
		if (!options.formatSource && options.itemToformat.TitleText) {
			options.formatSource = this.dataService.getAttributeMetricFromStorage(options.itemToformat.TitleText);
		}

		if (!options.metricValue) {
			options.metricValue = options.itemToformat.MetricValue;
		}

		if (options.titleToUpperCase) {
			options.itemToformat.TitleText = options.formatSource 
				? options.formatSource.AliasName.toUpperCase() 
				: options.itemToformat.TitleText.toUpperCase();
		}

		if (options.formatSource) {
			switch (options.formatSource.DataType) {
				case "int":
				case "bigint":
				case "decimal":
					options.itemToformat.MetricValue = this.formatterPipe.transform(options.metricValue, options.formatSource.Format);
					break;
				case "date":
					if (options.metricValue ) {
						options.itemToformat.MetricValue = options.metricValue === "N/A" 
						? options.metricValue
						: moment(options.metricValue).format('YYYY-MM-DD')
					}
					else {
						options.itemToformat.MetricValue = "-";
					}
					break;
				default:
					options.itemToformat.MetricValue = options.metricValue;
			}
		}
		else {
			options.itemToformat.MetricValue = this.formatterPipe.transform(options.metricValue, options.itemToformat.MetricFormat);
		}

		return options.itemToformat;
	}

	public attributesToSelectable(attributes: ICustomerAttributeMapping[]): ISelectableAttribute[] {
		return attributes.map(x => {
				return {
					Alias: x.Alias,
					Name: x.Name,
					IsDefaultRun: x.IsDefaultRun,
					IsChecked: x.IsChecked,
					IsRealtime: x.IsFieldUpdatedByApi || x.Alias.includes("_RT"),
				}
			}
		);
	}

	public dateRangeToDays(range: Date[]): number {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		return (range[1].getTime() - range[0].getTime())/millisecondsPerDay;
	}

	public PurchaseHistoryItemToRowData(items: PurchaseHistoryItem[], selectedAttributes: {[key: string]: ActivityAttribute[]}): PurchaseHistoryMultiAttributeFlowRowData[] {
		let rowsData: PurchaseHistoryMultiAttributeFlowRowData[] = [];

		items.forEach(item => {
			let rowIndex = rowsData.findIndex(x => x.TransactionId === item.TransactionId && x.ActivityTypeId === item.ActivityTypeId);

			if (rowIndex === -1) {
				rowIndex = rowsData.length;
				rowsData.push({
					ActivityTypeId: item.ActivityTypeId,
					TransactionDate: item.TransactionDate,
					TransactionId: item.TransactionId,
				})
			}

			if (selectedAttributes[item.ActivityTypeId].some((y) => y.RealFieldName === item.RealFieldName)) {
				rowsData[rowIndex] = {
					[item.RealFieldName]: item.AttributeValue,
					...rowsData[rowIndex],
				};
			}
		})

		return rowsData;
	}

	public sortObjectProps(object: any, orderExample: any) {
		let orderedCurrentObj = {};

		for (const key in orderExample) {
			orderedCurrentObj[key] = object[key];
		}

		return orderedCurrentObj;
	}
}