import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import { TemplatePreviewData } from '../../../models/channel/template/template-preview-data.model';
import { TemplateAvailabilityService } from '../templateAvailability.service';

@Component({
	selector: 'templates-preview-carousel-modal',
	templateUrl: './templatesPreviewCarouselModal.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./templatesPreviewCarouselModal.component.scss'],
	providers: [
		TemplateAvailabilityService
	]
})
export class TemplatesPreviewCarouselModalComponent implements OnInit, OnChanges {
    @Input() templates: TemplatePreviewData[];
    @Input() displayTemplateIndex: number;

    @Output() showTemplatesGridViewModal: EventEmitter<any> = new EventEmitter();

    public templatesFullSizeUrl: SafeResourceUrl[];
    public currentTemplateIndex: number;
    public isLoading: boolean;
    public isTemplatesExists: boolean[];

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
    	if (event.code === 'ArrowLeft') {
    		this.showPreviousTemplate();
    	} else if (event.code === 'ArrowRight') {
    		this.showNextTemplate();
    	}
    }

    constructor(private sanitizer: DomSanitizer, private templateAvailabilityService: TemplateAvailabilityService) {}

    ngOnInit() {
    	this.isLoading = true;

    	if (isNullOrUndefined(this.currentTemplateIndex)) {
    		this.currentTemplateIndex = 0;
    	}

    	this.loadIsTemplatesHtmlExists(this.templates);
    }

    private loadIsTemplatesHtmlExists(templates: TemplatePreviewData[]) {
    	if (templates == null || templates.length === 0) {
    		this.isLoading = false;
    		return;
    	}

    	const templateIds = templates.map(template => template.templateId);
    	this.templateAvailabilityService.isTemplatesHtmlExists(templates[0].channelId.toString(), templateIds)
    		.subscribe(value => {
    			this.isLoading = false;
    			this.isTemplatesExists = templates.map(template => value[template.templateId]);
    		});
    }

    ngOnChanges(changes: SimpleChanges) {
    	if (changes.templates) {
    		this.templatesFullSizeUrl = this.templates.map(template => {
    			return this.getTemplateFullSizeUrl(template.url);
    		});
    	}

    	if (changes.displayTemplateIndex) {
    		this.currentTemplateIndex = this.displayTemplateIndex;
    	}
    }

    private getTemplateFullSizeUrl(templatePreviewUrl: string): SafeResourceUrl {
    	const templateFullSizeUrl = templatePreviewUrl.replace('.jpeg', '.html');
    	return this.sanitizer.bypassSecurityTrustResourceUrl(templateFullSizeUrl);
    }

    showNextTemplate() {
    	if (this.currentTemplateIndex < this.templates.length - 1) {
    		this.currentTemplateIndex++;
    	} else {
    		this.currentTemplateIndex = 0;
    	}
    }

    showPreviousTemplate() {
    	if (this.currentTemplateIndex > 0) {
    		this.currentTemplateIndex--;
    	} else {
    		this.currentTemplateIndex = this.templates.length - 1;
    	}
    }

    onShowTemplatesGridViewModal() {
    	this.showTemplatesGridViewModal.emit();
    }
}

