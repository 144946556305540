import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {AndroidNotificationChannelsService} from "./androidNotificationChannels.service";
import {NotificationChannel} from "../../models/notificationChannel";

@Component({
    selector: 'android-notification-channels',
    templateUrl: './androidNotificationChannels.component.html',
    styleUrls: ['./androidNotificationChannels.component.scss']
})
export class AndroidNotificationChannelsComponent implements  OnInit, OnChanges {

    @Input() template: any;

    public channelList: NotificationChannel[];
    public chosenChannel: NotificationChannel;
    public chosenChannelId: string;

    constructor(private notificationChannelsService: AndroidNotificationChannelsService) {
    }

    ngOnInit() {
        this.notificationChannelsService.getOptipushChannels().subscribe(result => {
            this.channelList = result.map(channel => {
                return {
                  os: 1,
                  channelId: channel.id,
                  name: channel.name
                };
            });
            this.initTemplate()
        });
    }

    ngOnChanges(){
        this.initTemplate();
    }

    initTemplate() {
        if (!this.template || !this.channelList) {
            return;
        }
        if (this.template['optipushChannel']) {
            this.chosenChannel = this.template['optipushChannel'];
            this.chosenChannelId = this.chosenChannel.channelId;
        } else {
            this.chosenChannel = this.channelList[0];
            this.chosenChannelId = this.chosenChannel.channelId;

            this.template['optipushChannel'] = this.chosenChannel;
        }
    }

    chosenChannelChanged(notificationChannel: NotificationChannel) {
        this.chosenChannel = notificationChannel;
        this.chosenChannelId = this.chosenChannel.channelId;

        if (this.template) {
            this.template['optipushChannel'] = this.chosenChannel;
        }
    }

}
