<div class="panel-heading">
    <h2>{{'features.user_settings.body.role_permissions.TITLE' | translate}}</h2>
</div>
<permissions-manager class="panel-body permissions-body"
                    [className]="'role-permissions-list'"    
                    [roleNames]="roleNames"
                    [editedRoleName]="editedRoleName"
                    [tenantRoles]="tenantRoles"
                    [restrictedPermissions]="restrictedPermissions"
                    [allPermissions]="allPermissions"
                    (roleName)="onRoleNameChange($event)"
                    (permissionsForm)="onPermissinsFormChange($event)">
</permissions-manager>
<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="col-md-6 pull-right text-right">
        <ul class="list-inline">
            <li *ngIf="!isInSavingProcess"> 
                <button [disabled]="isFormPristine" class="btn btn-default" (click)="cancelChanges()">
                    {{'general.CANCEL' | translate}}
                </button>
            </li>
            <li *ngIf="!isInSavingProcess">
                <button [disabled]="isFormPristine"  class="btn btn-default" (click)="saveRoleDefaultPermissions(true)">
                    Apply to all {{editedRoleName}} users
                </button>
            </li>
            <li *ngIf="!isInSavingProcess">
                <button [disabled]="isFormPristine" class="btn btn-primary" (click)="saveRoleDefaultPermissions(false)">
                    {{'features.user_settings.body.role_permissions.ROLE_ONLY' | translate}}
                </button>
            </li>
            <li *ngIf="isInSavingProcess">
                <span class="click-loader loader-wrapper">
                    <span class="loader"></span>
                    <span>{{'features.user_settings.body.loaders.UPDATING' | translate}}</span>
                </span>
            </li>
        </ul>
    </div>
</div>