import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FolderService } from '../../services/folder.service';
import { TemplateContextService } from '../../services/templateContext.service';
import { first, switchMap } from 'rxjs/operators';
import { RelationType, TemplateSearchResult, TemplateTreeItem } from '../../models/templateResponse';
import { TemplateNavigationService } from '../../services/templateNavigation.service';
import { RouterNavigationUrl } from '../../models/routerNavigationUrl';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { FolderTemplatePreview } from '../../models/folderTemplate';
import { SearchContextService } from '../../services/searchContext.service';
import { Subscription } from 'rxjs';

interface FolderTemplate extends FolderTemplatePreview {
	displayName: string;
}

@Component({
	selector: 'folder-popup-overlay',
	templateUrl: './folderPopupOverlay.component.html',
	styleUrls: ['./folderPopupOverlay.component.scss']
})
export class FolderPopupOverlayComponent implements OnInit, OnDestroy {
	@Input() navigateExternally: boolean;
	@Output() templateNavigate: EventEmitter<{
		channelId: number;
		id: number;
		folderId: number;
		typeId: number;
		otherEditor: boolean;
		search: string;
	}> = new EventEmitter();

	private subscriptions = new Subscription();

	folder: TemplateTreeItem = {} as any;
	lobbyUrl: RouterNavigationUrl = {} as any;
	searchText: string;
	private previousSearchText: string = '';
	queryParams: TemplateQueryParams;
	private skip: number = 0;
	private readonly take: number = 20;
	templates: FolderTemplate[] = [];
	isLoading: boolean;

	constructor(
		private folderService: FolderService,
		private context: TemplateContextService,
		private templateNavigationService: TemplateNavigationService,
		private templatesService: ManageTemplatesService,
		private cd: ChangeDetectorRef,
		private searchContext: SearchContextService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.context.queryTemplateParams
				.pipe(
					switchMap((params) => {
						this.queryParams = params;
						return this.folderService.getFolder(+params.folderId, +params.typeId, params.brandId, +params.channelId);
					})
				)
				.pipe(first())
				.subscribe((folder) => {
					if (folder) {
						this.folder = folder;
						this.loadMore();
					}
				})
		);
		this.subscriptions.add(this.templateNavigationService.createLobbyUrl().subscribe((lobbyUrl) => (this.lobbyUrl = lobbyUrl)));
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
		this.subscriptions = null;
	}

	onSearchTextChange(text: string): void {
		if (
			this.previousSearchText !== text &&
			(this.searchContext.isSearchTextValid(this.previousSearchText) || this.searchContext.isSearchTextValid(text))
		) {
			this.skip = 0;
			this.loadMore(this.previousSearchText != text);
		}
		this.previousSearchText = text;
	}

	clearSearch(): void {
		this.searchText = '';
		this.onSearchTextChange(this.searchText);
	}

	onScroll(event: any) {
		if (event.target.offsetHeight + event.target.scrollTop + 5 >= event.target.scrollHeight) {
			this.loadMore();
		}
	}

	getEditTemplateLink(template: FolderTemplatePreview): string {
		const result = this.templateNavigationService.getEditTemplateLink(
			+this.queryParams.channelId,
			template.templateID,
			template.folderId,
			template.subMethodId,
			template.otherEditor,
			this.queryParams.search
		);
		return result;
	}

	templateNavigateClick(template: FolderTemplatePreview): void {
		if (template.templateID === +this.queryParams.templateId) {
			return;
		}
		this.templateNavigate.emit({
			channelId: +this.queryParams.channelId,
			id: template.templateID,
			folderId: template.folderId,
			typeId: template.subMethodId,
			otherEditor: template.otherEditor,
			search: this.queryParams.search
		});
	}

	private loadMore(isCleanLoad = false): void {
		if (this.isLoading || !this.folder?.children) {
			return;
		}
		this.isLoading = true;
		let templateIds = this.folder.children
			.filter((c) => c.rel === RelationType.leaf)
			.filter((c) => {
				if (!this.searchContext.isSearchTextValid(this.searchText)) {
					return true;
				}
				return this.searchContext.isMatchSearchText(c.text, this.searchText);
			})
			.map((t) => t.id);
		templateIds = templateIds.slice(this.skip, this.skip + this.take);
		if (templateIds.length === 0) {
			this.isLoading = false;

			if (isCleanLoad) {
				this.templates = [];
				this.cd.detectChanges();
			}
			return;
		}
		const templates: TemplateSearchResult[] = templateIds.map((t) => {
			return {
				templateID: t,
				subMethodId: +this.queryParams.typeId,
				channelId: +this.queryParams.channelId,
				folderId: +this.queryParams.folderId
			};
		});
		this.templatesService.getFolderTemplates(templates).subscribe(
			(templatesResult: FolderTemplatePreview[]) => {
				this.skip += this.take;
				this.isLoading = false;
				if (templatesResult) {
					this.templates = (isCleanLoad ? [...templatesResult] : [...this.templates, ...templatesResult]) as FolderTemplate[];
					this.templates.forEach(
						(t) => (t.displayName = this.searchContext.highlightThisSearchText(t.templateName, this.searchText))
					);
					this.cd.detectChanges();
				}
			},
			() => (this.isLoading = false)
		);
	}
}
