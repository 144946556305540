import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-text',
  templateUrl: './loadingText.component.html',
  styleUrls: ['./loadingText.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingTextComponent implements OnInit {

  @Input() show: boolean;
  @Input() loadingText: string;
  @Input() loadingCssClass?: string;
  @Input() spinnerCssClass: string = '';
  @Input() textCssClass: string = '';
  @Input() loaderSize: 'sm' | 'lg' = 'sm';
  additionalClasses = ''
  constructor() { }

  ngOnInit(): void {
    this.additionalClasses = `${this.loaderSize}${this.loadingCssClass ? ` ${this.loadingCssClass}`: ''}`;
  }

}
