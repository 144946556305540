<div [formGroup]="form">
    <opti-logic-modal [modalConf]="modalConf">
        <div class="ph-inputs">
            <div class="ph-attr-input" *ngIf="form">
                <opti-search-list 
                    class=""
                    formControlName="defaultAttribute"
                    data-qa-id="AttributeDropDown"
                    [config]="attributeSearchListConfig"
                    [items]="defaultAttributeItem"
                ></opti-search-list>
                <button data-qa-id="DeleteCondition" 
                        class="condition-remove btn btn-link btn-link-default" 
                        type="button" 
                        [disabled]="true">
                    <i class="material-icons">delete_forever</i>
                </button>
            </div>
            <div class="ph-attr-input" 
                *ngFor="let attribute of attributes.controls; index as i;"
                formArrayName="attributes">
                <opti-search-list 
                    class=""
                    data-qa-id="AttributeDropDown"
                    formControlName="{{i}}"
                    [config]="attributeSearchListConfig"
                    [items]="attributesToSelect"
                ></opti-search-list>
                <button data-qa-id="DeleteCondition" 
                        class="condition-remove btn btn-link btn-link-default" 
                        type="button" 
                        (click)="onRemoveAttribute(i)" 
                        [disabled]="false">
                    <i class="material-icons">delete_forever</i>
                </button>
            </div>
            <div class="add-condition">
                <button 
                    data-qa-id="AddAttribute" 
                    class="add-template btn btn-link" 
                    type="button" 
                    (click)="onAddAttribute()">
                    {{ translateKeys.ADD_ATTRIBUTE | translate }}
                </button>
            </div>
        </div>
    </opti-logic-modal>
</div>
