<div class="unsubscribe-beefree-panel">
  <div>
    <h3 class="header">{{ "features.manage_templates.components.subscriptions.SUBSCRIPTION" | translate }}</h3>
    <div class="button-layer">
      <opti-select
        [items]="unsubscribeTags"
        [ngModel]="initialValue"
        [groupByPropertyKey]="groupBy"
        (ngModelChange)="onSelectedChanged($event)"
        [placeholder]="'features.manage_templates.components.subscriptions.UNSUBSCRIBE' | translate"></opti-select>
    </div>
  </div>
</div>
