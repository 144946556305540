import {Component, EventEmitter, Input, Output, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'input-search',
    templateUrl: './inputSearch.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./inputSearch.component.scss']
})
export class InputSearchComponent {

    @Input() term: string;
    @Input() placeholder = 'Search';
    @Output() termChange = new EventEmitter<string>();

    @ViewChild('inputSearch', {static: false}) inputSearch: ElementRef;

    constructor() {}

    public onTermChange(value: string) {
        this.term = value;
        this.termChange.emit(this.term);
    }

    public onClearTerm() {
        this.onTermChange('');
        this.inputSearch.nativeElement.focus();
    }
}