import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class CanDeactivateStateService {
  private state: any = {};

  public setCanDeactivateFunc(key: string, canDeactivateFn: () => boolean): void {
    this.state[key] = canDeactivateFn;
  }

  public canDeactivate(key: string): boolean {
    const canDeactivate: () => boolean = this.state[key];
    return canDeactivate ? canDeactivate() : true;
  }
}
