import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, Input } from "@angular/core";
import * as moment from 'moment';
import { DateRangeInvalidStateMode } from './dateRangeInvalidStateMode.enum';

export function dateRangeMinDateValidator(minDate: Date) {

    return (control: AbstractControl): ValidationErrors | null => {
        const val: string[] = control.value;
        const fromDate: string = val != null ? val[0] : null;
        const validationError = {};
        validationError[DateRangeInvalidStateMode.DateMustBeLaterThanMinDate] = "true";
        return minDate && moment(fromDate).isBefore(minDate, 'day') ? validationError : null;
    };
}

@Directive({
    selector: "[dateRangeMinDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: DateRangeMinDateValidatorDirective,
        multi: true
    }]
})
export class DateRangeMinDateValidatorDirective implements Validator {
    @Input() minDate: Date;

    validate(c: AbstractControl): ValidationErrors | null {
        const validate = dateRangeMinDateValidator(this.minDate);
        return validate(c);
    }
}