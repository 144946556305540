export enum LocalStorageKeys {
    lastSelectedViewId = "missionControlLastViewId",
    lastSessionDateRange = "LastSessionDateRange",
    missionControlLastUsedKPI = "MissionControlLastUsedKPI",
    unsavedViewState = "UnsavedViewState",
    isMiniDashOpened = "isMiniDashOpened",
    currentMiniDashboard = "currentMiniDashboard",
}

export enum SessionStorageKeys {
    isRealtimeEnable = "IsRealtimeEnable",
    timeZones = "CampaignBuilderTimeZones",
    isOptimailEnabled = "IsOptimailEnabled",
    userPermissions = "userPermissions"
}

export enum CookieStorageKeys {
    MissionControlState = "MissionControlState",
}