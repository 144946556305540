import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, Input } from "@angular/core";
import * as moment from 'moment';

export function minDateValidator(minDate: Date) {

    return (control: AbstractControl): ValidationErrors | null => {
        const d = control.value;
        return minDate && moment(d).isBefore(minDate, 'day') ? { minDate: true } : null;
    };
}

@Directive({
    selector: "[minDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MinDateValidatorDirective,
        multi: true
    }]
})
export class MinDateValidatorDirective implements Validator {
    @Input() minDate: Date;

    validate(c: AbstractControl): ValidationErrors | null {
        const validate = minDateValidator(this.minDate);
        return validate(c);
    }
}