import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IModalConf } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { Subscription } from 'rxjs';
import { CreateUpdateFolderResponse } from '../../models/createUpdateFolder';

@Component({
	selector: 'new-folder-modal',
	templateUrl: './newFolderModal.component.html',
	styleUrls: [
		'./newFolderModal.component.scss',
		'../../../../components/optiLogicModal/modals/successFailModal/successFailModal.component.scss'
	]
})
export class NewFolderModalComponent implements OnInit, OnDestroy {
	@Input() folderId: number;
	@Input() subMethodId: number;
	@Input() parentFolderId: number;
	@Input() folderCreated: (createdFolder: CreateUpdateFolderResponse) => void = () => {};
	public form: FormGroup;
	private checkFolderNameSubscription: Subscription;
	private createFolderSubscription: Subscription;
	modalConf: IModalConf;
	errorDisplay: boolean = false;
	errorDuplicate: boolean = false;

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private manageTemplatesService: ManageTemplatesService
	) {}

	ngOnInit() {
		this.setModalConf();
		this.form = new FormGroup({
			newFolderName: new FormControl({ value: null, disabled: false })
		});
	}

	ngOnDestroy() {
		if (this.checkFolderNameSubscription) {
			this.checkFolderNameSubscription.unsubscribe();
		}
		if (this.createFolderSubscription) {
			this.createFolderSubscription.unsubscribe();
		}
	}

	private createFolder() {
		const parentFolderId = this.folderId < 0 ? 0 : this.folderId;
		this.checkFolderNameSubscription = this.manageTemplatesService
			.checkFolderName(this.newFolderNameControl.value, this.subMethodId, this.parentFolderId)
			.subscribe((nameExists: boolean) => {
				if (!nameExists) {
					this.errorDisplay = false;
					this.createFolderSubscription = this.manageTemplatesService
						.saveAndUpdateFolder({
							folderNode: {
								parentId: parentFolderId,
								text: this.newFolderNameControl.value
							},
							subMethodId: this.subMethodId
						})
						.subscribe((response) => {
							if (response) {
								this.folderCreated(response);
								setTimeout(() => {
									this.bsModalRef.hide();
								}, 500);
							} else {
								this.errorDuplicate = true;
							}
						});
				} else {
					this.errorDuplicate = true;
				}
			});
	}

	public get newFolderNameControl() {
		return this.form.get('newFolderName');
	}

	setModalConf(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.newFolderModal.TITLE'),
			xButton: { action: () => this.bsModalRef.hide() },
			buttons: [
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.components.newFolderModal.OK_BTN'),
					action: () => {
						this.createFolder();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.newFolderModal.CANCEL_BTN'),
					action: () => {
						this.bsModalRef.hide();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
}
