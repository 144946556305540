<dev [formGroup]="form">
    <opti-logic-modal [modalConf]="modalConf">
        <opti-loading [show]="isLoading"></opti-loading>
        <p>{{ description }}</p>
        <div class="row">
            <opti-search-list data-qa-id="items-dropdown" formControlName="selectedItem"
                class="attribute-picker col-sm-6" [items]="granularKPIs" [config]="searchListConfig">
            </opti-search-list>
            <div class="col-sm-6">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <label>Tooltip:</label>
            </div>
            <div class="col-sm-6">
                <input class="form-control medium-box col-sm-6" type="text" formControlName="tooltip"/>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <label>Action alias:</label>
            </div>
            <div class="col-sm-6">
                <input class="form-control medium-box col-sm-6" type="text" formControlName="actionAlias" [placeholder]="translateKeys.ACTION_PHACEHOLDER | translate"/>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <label>Format:</label>
            </div>
            <div class="col-sm-6">
                <input class="form-control medium-box col-sm-6" type="text" formControlName="format"/>
            </div>
        </div>
    </opti-logic-modal>
</dev>