<div class="date-picker-wrapper">
    <input class="form-control theme-blue"
           bsDatepicker
           #dp="bsDatepicker"
           [bsConfig]="{ containerClass: 'theme-blue', dateInputFormat: 'DD MMM YYYY', showWeekNumbers: false, adaptivePosition: datePickerData?.adaptivePosition }"
           [ngClass]="disabled ? 'disabled-picker' : ''"
           [formControl]="inputControl"
           (input)="onInputChange($event)"
           (blur)="onblur()"
           [placement]="$any(datePickerData?.placement ? datePickerData.placement : 'bottom left')"
           [placeholder]="datePickerData?.placeholder ? datePickerData.placeholder : ''"
           [container]="datePickerData?.container"
           [minDate]="datePickerData?.minDate"
           [maxDate]="datePickerData?.maxDate">
    <i class="material-icons date-picker-icon" (click)="onIconClick()">today</i>

    <div *ngIf="showSelfValidationMessages && controlDirective && controlDirective.dirty && controlDirective.invalid" class="error-message text-danger small">
        <label *ngIf="controlDirective.errors && controlDirective.errors['invalidDate']" class="empty-field">
            {{'general.DATE_PICKER.invalidDate' | translate}}
        </label>
        <label *ngIf="controlDirective.errors && controlDirective.errors['minDate']" class="empty-field">
            {{'general.DATE_PICKER.outOfMinDate' | translate : { x : datePickerData.minDate | date : 'dd MMM yyyy' } }}
        </label>
        <label *ngIf="controlDirective.errors && controlDirective.errors['maxDate']" class="empty-field">
            {{'general.DATE_PICKER.outOfMaxDate' | translate : { x : datePickerData.maxDate | date : 'dd MMM yyyy' } }}
        </label>
    </div>
</div>
