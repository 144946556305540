import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActionData, CampaignSummaryRow, Metadata } from "./campaignKPIable.model";
import { ConvertOrderActionToLetter } from "../../pipes/convertOrderActionToLetter.pipe";
import { TranslateService } from "@ngx-translate/core";
import { AppConstant } from "../../app.constatnt";
import { FormatterPipe, IFormatterOptions } from "../../pipes/formatter.pipe";
import { avgKpiTooltips, CampaignKPITooltips, responseRateTooltips } from "./campaignKPITooltips";

@Component({
    selector: 'campaign-metrics-table',
    templateUrl: './campaignKPITable.component.html',
    styleUrls: ['./campaignKPITable.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CampaignKPITableComponent implements OnInit {

    public rows: CampaignSummaryRow[] = [];
    public data: Metadata;

    private _actionData: ActionData[];
    private _noResponse: boolean = false;
    private _notLowResponse: boolean = false;

    formatterOptions: IFormatterOptions;

    private readonly significantImgSrc = 'assets/images/gaussianGreen.svg';
    private readonly notSignificantImgSrc = 'assets/images/gaussianGrey.svg';

    @Input() set actionData(actions: ActionData[]) {
        if (actions) {
            actions.sort((a: ActionData, b: ActionData) => {
                if (a.id > b.id) {
                    return 1;
                }
                if (a.id < b.id) {
                    return -1;
                }
                return 0;
            });
            actions.sort((a: ActionData, b: ActionData) => {
                if (a.isControl) return 1;
                if (b.isControl) return -1;
                return 0;
            });
            this._actionData = actions;
            this.updateTable();
        }
    }

    @Input() set metadata(data: Metadata) {
        this.data = data;
        this.updateTable();
    }

    @Output() onGroupClicked = new EventEmitter();

    constructor(private translate: TranslateService,
        private orderActionToLetterConverter: ConvertOrderActionToLetter,
        private formatter: FormatterPipe) {
        this.formatterOptions = {
            shouldHandleSmallNumbers: true,
            shouldHandleBigNumbers: true,
            decimalPointPrecision: 1
        }
    }

    ngOnInit() {
    }

    private updateTable() {
        if (this.data && this._actionData) {
            this.setNoResponse();
            this.setNotLowResponse();
            this.setRowsDefenition();
            this.fillTable();
        }
    }

    private setRowsDefenition() {
        this.rows = [];
        let headerRow = {
            title: this.data.showHeaderText ? this.translate.instant("features.campaign_analysis.campaign_summary.table_header") : null,
            dataFormat: null,
            isHeaderRow: true,
            actionDataList: []
        };
        this.rows.push(headerRow);

        let groupSizeRow = {
            title: this.translate.instant("features.campaign_analysis.campaign_summary.GROUP_SIZE"),
            dataFormat: AppConstant.NUMBER_FORMATS.number,
            isHeaderRow: false,
            actionDataList: [],
            onClick: this.data.isScheduledCampaign ? (id) => {
                let action = this._actionData.find(a => a.id == id);
                this.onGroupClicked.emit({ clickable: true, planDetailId: id, action: action, isPromotion: false });
            } : null,
        }
        this.rows.push(groupSizeRow);

        let customerData = {
            type: this.data.isVisitor ? "Visitors" : "Customer",
            action: this.data.tookActionAlias
        }
        let respondentsRow = {
            title: this.translate.instant("features.campaign_analysis.campaign_summary.NUMBER_CUSTOMERS", customerData),
            dataFormat: AppConstant.NUMBER_FORMATS.number,
            isHeaderRow: false,
            onClick: this.data.showArrow ? (id) => {
                let action = this._actionData.find(a => a.id == id);
                this.onGroupClicked.emit({ clickable: true, planDetailId: id, action: action, isPromotion: false });
            } : null,
            actionDataList: []
        }
        this.rows.push(respondentsRow);

        let respondentsRateRow = {
            title: this.translate.instant("features.campaign_analysis.campaign_summary.PERCENT_CUSTOMERS", customerData),
            dataFormat: AppConstant.NUMBER_FORMATS.percentage,
            gaussianImg: this.data.isResponseRateSignificant ? this.significantImgSrc : this.notSignificantImgSrc,
            isHeaderRow: false,
            actionDataList: []
        }
        this.rows.push(respondentsRateRow);

        if (!this.data.isVisitor) {
            let avgKpi = {
                title: this.translate.instant("features.campaign_analysis.campaign_summary.AVG_KPI", { AliasName: this.data.aliasKpi }),
                dataFormat: this.data.kpiFormat,
                gaussianImg: this.data.isAvgSignificant ? this.significantImgSrc : this.notSignificantImgSrc,
                isHeaderRow: false,
                actionDataList: []
            }
            this.rows.push(avgKpi);

            if (this._actionData.some(a => a.promotion != undefined && a.promotion != null)) {
                let promotion = {
                    title: this.translate.instant("features.campaign_analysis.campaign_summary.BONUS_ALIAS", { PromotionAliasTookAction: this.data.promotionAliasTookAction }),
                    dataFormat: AppConstant.NUMBER_FORMATS.number,
                    isHeaderRow: false,
                    onClick: this.data.showArrow ? (id) => {
                        let action = this._actionData.find(a => a.id == id);
                        this.onGroupClicked.emit({
                            clickable: true,
                            planDetailId: id,
                            action: action,
                            isPromotion: true
                        });
                    } : null,
                    actionDataList: []
                }

                this.rows.push(promotion);
            }
        }
    }

    private fillTable() {
        this._actionData.forEach((action, index) => {
            let actionName = this.getActionName(index);
            let actionLabel = this.getActionLabel(index);
            this.rows[0].actionDataList.push({
                id: action.id,
                value: 0,
                actionName: actionName,
                actionLabel: actionLabel,
                isControl: action.isControl
            });
            this.rows[1].actionDataList.push({
                id: action.id,
                value: action.groupSize,
                actionName: actionName,
                actionLabel: actionLabel,
                isControl: action.isControl
            });
            this.rows[2].actionDataList.push({
                id: action.id,
                value: action.respondents,
                actionName: actionName,
                actionLabel: actionLabel,
                isControl: action.isControl
            });
            this.rows[3].actionDataList.push({
                id: action.id,
                value: action.responseRate,
                actionName: actionName,
                actionLabel: actionLabel,
                isControl: action.isControl
            });
            if (this.rows.length > 4) {
                this.rows[4].actionDataList.push({
                    id: action.id,
                    value: action.avgKpi,
                    actionName: actionName,
                    actionLabel: actionLabel,
                    isControl: action.isControl
                });
                if (this.rows.length > 5) {
                    this.rows[5].actionDataList.push({
                        id: action.id,
                        value: action.promotion,
                        actionName: actionName,
                        actionLabel: actionLabel,
                        isControl: action.isControl
                    });
                }
            }
        });

        this.setTooltips();
    }

    private setTooltips() {
        this.rows[4].tooltip = this.getTooltip(new avgKpiTooltips(this.translate, this.formatter, this.formatterOptions, this.data, this._actionData), false);
        this.rows[3].tooltip = this.getTooltip(new responseRateTooltips(this.translate, this.formatter, this.formatterOptions, this.data, this._actionData), true);
    }

    private getTooltip(tooltipObj: CampaignKPITooltips, allowLowRespondents: boolean): string {
        let headerRow = this.rows[0];
        let tooltip: string;
        if (!this._actionData) {
            return '';
        }
        if (this._noResponse) {
            tooltip = tooltipObj.noResponse();
        } else if (!this._notLowResponse && !allowLowRespondents) {
            tooltip = tooltipObj.lowRespondents();
        }else if(this._actionData.filter(a=>!a.isControl).length > 1){
            //#192707 - remove the tooltip of the bell for campaigns with more than one action + control
            return '';

        } else if (this.data.campaignType < 3) {
            let dif = tooltipObj.difference();
            if (dif != 0) {
                tooltip = tooltipObj.twoActionsDif(dif, headerRow);
            } else {
                tooltip = tooltipObj.twoActionsEqual(headerRow);
            }
        } else {            
            let isAllValuesEqual = tooltipObj.isAllActionsEqual();
            if (isAllValuesEqual) {
                tooltip = tooltipObj.allActionsEqual();
            } else {
                tooltip = tooltipObj.oneActionEffective(headerRow);
            }
        }
        return tooltip;
    }

    private setNotLowResponse() {
        this._notLowResponse = this._actionData.every((action) => {
            return action.respondents >= 5;
        });
    }

    private setNoResponse() {
        this._noResponse = this._actionData.every((action) => {
            return action.respondents === 0;
        });
    }

    private getActionLabel(index: number): string {
        if (this._actionData[index].isControl) {
            return null;
        }
        if (this.data.isTestControl) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.TEST');
        } else {
            return this.orderActionToLetterConverter.transform(index + 1);
        }
    }

    private getActionName(index: number) {
        if (this._actionData[index].isControl) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.CONTROL')
        }
        if (this.data.isTestControl) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.TEST')
        }
        return this._actionData[index].actionName;
    }
}