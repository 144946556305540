/* eslint-disable indent */
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ImportedCustomersService } from "../services/imported-customers.service";
import { ImportedCustomersErrors } from "../models/internal/imported-customers-errors.enum";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { SsmService } from "src/app/services/ssm.service";
import { ImportedSelectedAttribute } from "../models/internal/imported-selected-attribute.model";
import { IListItem } from "@optimove/ui-sdk/common/models";
import { MappingAttributes } from "../models/response/mapping-attributes.model";
import { SelectedAttribute } from "../models/request/selected-attributes-model";
import { ImportedCustomersModalService } from "../services/imported-customers-modal-service"
import { CanDeactivateStateService } from "src/app/routing/guard/canDeactivateState.service";
import { SwitchButtonType } from '../../../../components/switchButton/switchButtonType.model';
import { FormControl, FormGroup, Validators } from "@angular/forms";


@Component({
	selector: "imported-customers",
	templateUrl: "./imported-customers.component.html",
	styleUrls: ["./imported-customers.component.scss"]
})
export class ImportedCustomersComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('isConsentCheckbox') isConsentCheckbox: ElementRef;

  private readonly translationPrefix = "features.user_settings.body.importedCustomers";
  private readonly ssm: any;
  private modalRef: BsModalRef;
  private attributeItems: IListItem[];
  private attributesPreview: MappingAttributes[] = [];
  private importedSelectedAttributes: ImportedSelectedAttribute[] = [];
  private _isConsent: boolean;

  public csvFile: File;
  public showConfirmation: boolean;
  public translateKeys = translateKeys;

  public description: string;
  public validationLabel: string;
  public totalColumns: number;
  public mappedColumns: number;
  public columnsSummary: string;
  public SwitchButtonType = SwitchButtonType;
  public tgName: string;
  public allowCreateTg: boolean = false;
  public form: FormGroup;
  public tgInvalid: boolean = false;

  private get areChangesMade(): boolean { return !!this.mappedColumns }
  get isConsent(): boolean { return this._isConsent };
  get cancelDisabled(): boolean { return !this.areChangesMade };
  get saveDisabled(): boolean { return !this.areChangesMade || !this.isConsent || (this.allowCreateTg && this.form && !this.name.valid)}

  constructor(
    private translate: TranslateService,
    private ssmService: SsmService,
    private importedCustomersService: ImportedCustomersService,
    private importedCustomersModalService: ImportedCustomersModalService,
    private canDeactivateStateService: CanDeactivateStateService
  ){
  	this.ssm = this.ssmService.getSSM();
  }

  ngOnInit(): void {
	this.CreateForm();
  	this.description = this.translate.instant(`${this.translationPrefix}.UPLOAD_CSV_DESCRIPTION`);
  	this.validationLabel = this.translate.instant(`${this.translationPrefix}.UPLOAD_CSV_VALIDATION_LABEL`);
  	this.columnsSummary = this.translate.instant(`${this.translationPrefix}.MAPPED_COLUMNS`);
  	this.canDeactivateStateService.setCanDeactivateFunc(
  		"importedCustomers",
  		this.canDeactivate.bind(this)
  	);
	
  }
   
  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
  	return !this.areChangesMade;
  }
  
  public uploadCSV() {
  	this.fileInput.nativeElement.value = "";
  	this.fileInput.nativeElement.click();
  }

  public onFileInput(event: Event){
  	let files = (event.target as HTMLInputElement & EventTarget).files;
  	this.csvFile = files[0] as File;
  	if(this.checkFileExtension(this.csvFile) && this.checkFileSize(this.csvFile)){
  		this.openMappingModal(true);
  		this.prepareData();
  	}
  }

  public reMapAttributes(){
  	this.openMappingModal(false);
  	this.modalRef.content.loadData(this.attributesPreview, this.attributeItems, this.importedSelectedAttributes);
  }

  public toggleConsent(){
  	this._isConsent = !this._isConsent;
  }

  public clean(){
  	this.csvFile = null;
  	this.showConfirmation = false;
  	this.mappedColumns = undefined;
  	this.attributeItems = undefined;
  	this.attributesPreview = undefined;
  	this._isConsent = false;
  	this.allowCreateTg = false;
  }
  
  public save(){
  	this.modalRef = this.importedCustomersModalService.openImportLoader();

  	let selectedAttributes = this.importedSelectedAttributes
  		.filter(x => x.selectedAttributeRealFieldName != null)
  		.map(x => { return <SelectedAttribute>{selectedAttributeRealFieldName: x.selectedAttributeRealFieldName, mappingColumnId: x.mappingColumnId }});

  	this.importedCustomersService.importNewCustomers(selectedAttributes, this.csvFile, this.name.value).subscribe(res => {
  		this.modalRef.hide();

  		if(!res.isSuccess){
  			this.importedCustomersModalService.onErrorInProcess(ImportedCustomersErrors.FailedToImportCsv, res.errorMsg);
  			return;
  		}
  		this.clean();
  		let data = res.data;
  		this.modalRef = this.importedCustomersModalService.openImportResultModal(
  			data.total, 
  			data.successfullyImported, 
  			data.alreadyExists, 
  			data.duplicates, 
  			data.invalidEmail);
  	});
  }

  public switchAllowCreateTg()
  {
  	this.allowCreateTg = !this.allowCreateTg;
  }

  public isNameUnique() {
	this.importedCustomersService.CheckIfTgNameExists(this.name.value).subscribe((exists) => {
		this.name.setErrors(exists ? ["Target group name must be unique."] : [], {emitEvent: true});
		if (!exists) {
			this.name.updateValueAndValidity();
		}
	});
  }

  public CreateForm()
  {
  	this.form = new FormGroup(
  		{
  			name: new FormControl('', [Validators.required])
  		}
  	)

  }

  get name() { return this.form.get('name'); }

  private checkFileExtension(file: File): boolean{
  	var extension = file.name.split(".").pop().toLowerCase();
  	if (extension != "csv") {
  		this.importedCustomersModalService.onErrorInProcess(ImportedCustomersErrors.CSVFileIncorectType);
  		this.csvFile = null;
  		return false;
  	}
  	return true;
  }



  private checkFileSize(file: File): boolean{
  	var filesize = file.size / (1024 * 1024); //file size in MB
  	if (filesize >= 50) {
  		this.importedCustomersModalService.onErrorInProcess(ImportedCustomersErrors.CSVFileSizeIsTooBig);
  		this.csvFile = null;
  		return false;
  	}
  	return true;
  }

  private openMappingModal(isInitialMapping = true){
  	this._isConsent = false;
  	this.modalRef = this.importedCustomersModalService.openModalMappingModal(isInitialMapping);
  	this.modalRef.content.onClose.subscribe(result => {
  		this.showConfirmation = result.isConfirmed || result.isReopened;
  		this.importedSelectedAttributes = result.selectedAttributes;
  		if(result.isConfirmed){
  			this.totalColumns = result.totalColumns;
  			this.mappedColumns = result.mappedColumns;
  		}
  	});
  }

  private prepareData(){
  	forkJoin([
  		this.importedCustomersService.uploadCsvFile(this.csvFile),
  		this.importedCustomersService.getEmailAttributeName()
  	]).pipe(
  		map(([fileUploadResponse, emailAttributeName]) => {
  			return {
  				fileUploadResponse,
  				emailAttributeName
  			}
  		})
  	).subscribe((res) => {
  		if(!res.fileUploadResponse.isSuccess){
  			this.importedCustomersModalService.onErrorInProcess(ImportedCustomersErrors.FailedToImportCsv, res.fileUploadResponse.errorMsg);
  			this.modalRef.content.closeModal();
  			return;
  		}
  		else if(!res.emailAttributeName.isSuccess){
  			this.importedCustomersModalService.onErrorInProcess(ImportedCustomersErrors.FailedToGetEmailAttribute, res.emailAttributeName.errorMsg);
  			this.modalRef.content.closeModal();
  			return;
  		}

  		let attributes = this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes);
  		let emailRealFieldName = attributes[res.emailAttributeName.data].RealFieldName;
      
  		this.attributesPreview = res.fileUploadResponse.data;
  		
		// Virtual attributes cannot be mapped with existed values
  		this.attributeItems = Object.keys(attributes)
			.map(k => { 
				return {
					id: attributes[k].RealFieldName, 
					name: attributes[k].Alias
				} as IListItem
			})
			.filter(x => !x.id.toString().includes("VF_CA_FB_"));
      
  		let selectedValues = this.attributesPreview.map( (a,index) => { 
  			return { 
  				selectedAttributeRealFieldName: index === 0 ? emailRealFieldName : null, 
  				isValid: index === 0 ? true : null,
  				mappingColumnId: index === 0 ? 0 : null
  			} as ImportedSelectedAttribute } );
  		let selectedAttributes = selectedValues;

  		this.modalRef.content.loadData(this.attributesPreview, this.attributeItems, selectedAttributes);
  	});
  }
}



const translateKeys = {
	customerFound: 'features.user_settings.body.importedCustomers.CUSTOMER_COUNT',
	tgCreate: 'features.user_settings.body.importedCustomers.CREATE_TARGET_GROUP',
	tgPlacgeholder: 'features.user_settings.body.importedCustomers.CREATE_TARGET_GROUP_PLACEHOLDER',
	mapColumns: 'features.user_settings.body.importedCustomers.MAP_COLUMNS',
	mappedColumns: 'features.user_settings.body.importedCustomers.MAPPED_COLUMNS',
	saveLabel: 'features.user_settings.body.importedCustomers.SAVE_DESCRIPTION',
	consent: 'features.user_settings.body.importedCustomers.RECEIVE_LABEL',
	emptyTg:'features.user_settings.body.importedCustomers.ERRORS.TG_NAME_IS_EMPTY',
	tgNameMustBeUnique: 'features.user_settings.body.importedCustomers.ERRORS.TG_NAME_IS_UNIQUE'
  
}
function switchAllowCreateTg() {
	throw new Error("Function not implemented.");
}

