import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { forkJoin, interval, of, Subscription } from 'rxjs';
import { delay, delayWhen, filter, first } from 'rxjs/operators';
import { OptiLogicModalService } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { specialCharactersRegex } from '../../attributesList.constants';
import { AttributeStatus, PurchaseHistoryFamily } from "../../attributesList.enums";
import { AttributeHelper } from "../../attributesList.helper";
import { AttributeLog, CalculationSectionComponent, PurchaseHistoryAttribute } from "../../attributesList.model";
import { AttributesListService } from '../../services/attributesList.service';
import { AttributesManagerService } from '../../services/attributesManager.service';
import { AttributeValidatorService } from '../../services/attributeValidator.service';
import { PurchaseAttributesService } from '../../services/purchaseAttributes.service';

@Component({
    selector: 'purchase-attribute',
    templateUrl: './purchaseHistoryAttribute.component.html',
    styleUrls: ['./purchaseHistoryAttribute.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PurchaseHistoryAttributeComponent implements OnInit, OnDestroy {
    @ViewChild('attributeBody', {static: false}) attributeBodyComponent: CalculationSectionComponent;

    public form: FormGroup;
    public lastUpdate = "";
    public notUniqueAttribute = false;
    public isSaving = false;
    public isEditMode = false;
    public newPurchaseHistoryAttributesFF;
    public attributeStatus = {
        color: ColoredTagType.Type4,
        description: "Published"
    };
    public translateKeys = translateKeys;
    public attribute: PurchaseHistoryAttribute;
    public IsPersonalizationCheckBoxChecked: boolean;
    public isAttributeVisibilityChanged: boolean;
    public canAttributeBeHidden: boolean = false;

    private initialName;
    private initialAliasName;
    private subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private attrManagerService: AttributesManagerService,
        private router: Router,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private translate: TranslateService,
        private attributeValidatorService: AttributeValidatorService,
        private purchaseHistoryService: PurchaseAttributesService,
        private attributeListService: AttributesListService) { }

    async ngOnInit() {
        this.initFF();
        this.createForm();
        this.initAttributeValues();
        await this.purchaseHistoryService.GetProductsForOldPH().pipe(first()).toPromise();

        const subscription$1 = this.attributeBody.valueChanges.subscribe(x => this.updateAttributeBodyValidity());
        const subscription$2 = this.displayName.valueChanges.subscribe(x => { this.notUniqueAttribute = false; });
        this.subscriptions.push(subscription$1);
        this.subscriptions.push(subscription$2);
    }

    initChildFormSubscription(): void {
        const subscription$1 = interval(200).pipe(filter(_ => !!this.attributeBodyComponent), first()).subscribe(_ => {
            const subscription$2 = this.attributeBodyComponent.form.statusChanges.subscribe(x => {
                this.updateAttributeBodyValidity();
            });
            this.subscriptions.push(subscription$2);
        });
        this.subscriptions.push(subscription$1);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    saveAttributeClick() {
        this.isSaving = true;
        this.buildAttribute("NotPublished");
        this.attrManagerService.saveAttribute(this.attribute, "PurchaseHistory").pipe(first()).subscribe(res => {
            if (res.IsSuccess) {
                this.router.navigateByUrl("/user-settings/attributes/list");
            } else {
                this.notUniqueAttribute = true;
                this.showNotUniqueAttribute(this.attribute.DisplayName);
                this.attribute = initialAttribute;
                this.isSaving = false;
            }
        });
    }
    updateAttributeClick() {
        if (!this.attribute.IsPersonalization && this.IsPersonalizationCheckBoxChecked) {
            this.modalService.openModalMessage(
                'md',
                {
                    title: "Personalization tag successfully removed",
                    message: "Templates that already include this attribute will not be affected, and will continue to work as normal",
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: 'OK',
                            action: this.updateAttribute.bind(this)
                        },
                    ]
                },
                <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
            );
        } else {
            this.updateAttribute();
        }
    }

    updateAttribute() {
        this.isSaving = true;
        this.buildAttribute(this.attribute.PublishStatus);
        this.attribute.OldName = this.initialName;
        this.attribute.OldAliasName = this.initialAliasName;

        forkJoin(
            this.isAttributeVisibilityChanged
                ? {
                    updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "PurchaseHistory"),
                    changeAttributeVisibility: this.attributeListService.changeAttributeVisibility(this.attribute.FieldName, !this.attribute.IsHidden)
                }
                : {
                    updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "PurchaseHistory"),
                }
        ).pipe(first()).subscribe(async (res) => {
            if (res.updateAttribute.IsSuccess) {
                await this.attrManagerService.updateCustomerAttributes();
                this.router.navigate(['/user-settings/attributes/list']);
            } else {
                this.notUniqueAttribute = true;
                this.showNotUniqueAttribute(this.attribute.DisplayName);
                this.isSaving = false;
            }
        }, (err) => console.error(err));
    }

    //   // ==== Form Controls ==== \\
    get displayName(): FormControl {
        return this.form.get('displayName') as FormControl;
    }

    get attributeBody(): FormControl {
        return this.form.get('attributeBody') as FormControl;
    }

    get description(): FormControl {
        return this.form.get('description') as FormControl;
    }
    //   \\ ==== Form Controls ==== //


    //     // ==== Modals ==== \\
    public showNotUniqueAttribute(attributeName: string) {
        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant(translateKeys.errorRealFieldName, { attributeName: attributeName }),
                buttons: [{
                    class: 'btn-primary',
                    label: 'OK',
                    action: this.closeModal.bind(this)
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    public openConfirmModal() {
        if (this.form.dirty) {
            this.modalService.openModalMessage(
                'sm',
                {
                    message: this.translate.instant(this.translateKeys.confirmBackMessage),
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: this.translate.instant(this.translateKeys.discardChanges),
                            action: this.discardChanges.bind(this),
                        },
                        {
                            class: 'btn-default',
                            label: this.translate.instant(this.translateKeys.continueEditing),
                            action: this.closeModal.bind(this),
                        },
                    ]
                },
                <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
            );
        }
        else {
            this.discardChanges();
        }
    }

    private discardChanges() {
        this.router.navigateByUrl("/user-settings/attributes/list");
        this.bsModalRef.hide();
    }

    private closeModal() {
        this.bsModalRef.hide();
    }
    //     \\ ==== Modals ==== //


    //      // ==== private ==== \\
    private initFF(AfterAction: Function = () => { }) {
        this.purchaseHistoryService.GetNewPurchaseHistoryAttributesFF().pipe(first()).subscribe(featureFlag => {
            this.newPurchaseHistoryAttributesFF = featureFlag;
            AfterAction();
        })
    }

    private createForm() {
        this.form = this.formBuilder.group({
            displayName: ['', [Validators.required, Validators.maxLength(100), this.attributeValidatorService.validateEmptySpaces(), this.attributeValidatorService.validateSpecialSymbols()]],
            description: [],
            attributeBody: ['', [Validators.required]]
        });
    }

    private updateAttributeBodyValidity() {
        if (this.attributeBodyComponent) {
            this.attributeBody.setErrors(this.attributeBodyComponent.form.invalid ? { 'invalidAttributBody': true } : null, { emitEvent: false });
        }
    }

    private initAttributeValues() {
        const attribute = this.router.getCurrentNavigation().extras.state as PurchaseHistoryAttribute;
        if (attribute) {

            this.attributeValidatorService.canAttributeBeHidden(attribute.FieldName).pipe(first()).subscribe((res) => {
                this.canAttributeBeHidden = res.IsSuccess;
            });

            this.isEditMode = true;
            this.attribute = attribute;
            this.initialName = attribute.Name;
            this.initialAliasName = attribute.DisplayName;
            this.purchaseHistoryService.currentAttributeFamily = attribute.Family as PurchaseHistoryFamily;
            this.attributeStatus = {
                color: this.isPublished() ? ColoredTagType.Type4 : ColoredTagType.Type12,
                description: this.isPublished() ? "Published" : "Not Published"
            }

            this.displayName.setValue(this.attribute.DisplayName, { emitEvent: false });
            this.description.setValue(this.attribute.Description, { emitEvent: false });
            this.attributeBody.setValue(this.attribute, { emitEvent: false });
            if (this.isPublished()) this.attributeBody.disable();
            this.setLastActivityMsg();
        }
        else {
            this.attribute = initialAttribute;
            if (this.newPurchaseHistoryAttributesFF === null || this.newPurchaseHistoryAttributesFF === undefined) {
                this.initFF(() => {
                    this.initAttributeFamily(this.newPurchaseHistoryAttributesFF);
                });
            }
            else {
                this.initAttributeFamily(this.newPurchaseHistoryAttributesFF);
            }
        }
    }

    private initAttributeFamily(newPurchaseHistoryAttributesFF: boolean) {
        this.purchaseHistoryService.currentAttributeFamily = newPurchaseHistoryAttributesFF
            ? PurchaseHistoryFamily.New_PurchaseHistory
            : PurchaseHistoryFamily.OLD_PurchaseHistory;
    }

    public changePersonalization(isPeronalization: boolean) {
        this.IsPersonalizationCheckBoxChecked = !this.IsPersonalizationCheckBoxChecked;
        this.attribute.IsPersonalization = !this.attribute.IsPersonalization;
    }

    public changeAttributeVisibility() {
        this.isAttributeVisibilityChanged = !this.isAttributeVisibilityChanged;
    }

    private setLastActivityMsg() {
        this.attrManagerService.getLastActivity(this.attribute.Name)
            .pipe(first(), filter(x => !!x))
            .subscribe((res: AttributeLog) => {
                this.lastUpdate = `Last modified on ${AttributeHelper.getTimestamp(res.Time)} by ${res.User}`
            });
    }

    private buildAttribute(status: AttributeStatus) {
        this.attribute = this.attributeBodyComponent.buildAttributeBody(this.attribute);
        this.attribute.Type = "purchase";
        this.attribute.PublishStatus = status;
        this.attribute.DisplayName = this.displayName.value.trim();
        this.attribute.Name = this.displayName.value.replace(specialCharactersRegex, '')
        this.attribute.Description = this.description.value;
    }

    public isPublished(): boolean {
        return this.attribute.PublishStatus === "Published";
    }
    //      \\ ==== private ==== //
}

const initialAttribute = {
    Type: "purchase",
    PublishStatus: "NotPublished",
    IsHidden: false,
    IsPersonalization: false,
} as PurchaseHistoryAttribute;


const translateKeys = {
    displayName: 'features.user_settings.body.attributes.addCalculatedAttribute.DISPLAY_NAME',
    description: 'features.user_settings.body.attributes.addCalculatedAttribute.DESCRIPTION',
    placeholder: 'features.user_settings.body.attributes.purchaseAttributes.ENTER_NAME',
    title: 'features.user_settings.body.attributes.addCalculatedAttribute.TITLE',
    editTitle: 'features.user_settings.body.attributes.addCalculatedAttribute.EDIT_TITLE',
    errorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.NEW_ATTR_NAME',
    confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
    errorRealFieldName: 'features.user_settings.body.attributes.invalidErrors.REAL_FIELD_NAME',
    continueEditing: 'general.CONTINUE_EDITING',
    discardChanges: 'general.DISCARD_CHANGES',
    specialSymbolsErrorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.SPECIAL_SYMBOLS',
};