<div class="limited-height">
  <div class="panel-heading">
    <h2>{{ "features.user_settings.body.message_archiving.errorLog.TITLE" | translate }}</h2>
  </div>
  <div class="panel-body panel-body-errors">
    <opti-loading [show]="isLoading" [size]="'md'"></opti-loading>
    <div class="errors-list limited-height">
      <div class="columns-header">
        <div class="column column-error-date">
          <span class="name">{{ "features.user_settings.body.message_archiving.errorLog.HAPPENED_AT" | translate }}</span>
        </div>
        <div class="column column-error-text">
          <span class="name">{{ "features.user_settings.body.message_archiving.errorLog.ERROR" | translate }}</span>
        </div>
        <div class="column column-error-type">
          <span class="name">{{ "features.user_settings.body.message_archiving.errorLog.CODE" | translate }}</span>
        </div>
        <div class="column column-error-metadata">
          <span class="name">{{ "features.user_settings.body.message_archiving.errorLog.METADATA" | translate }}</span>
        </div>
      </div>
      <div *ngIf="!errorsModel?.Errors?.length && !isErrorsLoading" class="no-results">
        <span class="name">{{ "features.user_settings.body.message_archiving.errorLog.NO_RESULTS" | translate }}</span>
      </div>
      <div class="error-log-rows" (scroll)="onScroll($event)" *ngIf="errorsModel?.Errors?.length">
        <div class="error-log-row" *ngFor="let error of errorsModel?.Errors">
            <div class="column column-error-date">
              <span>{{ error.LastHappenedAt | date : "medium" }}</span>
            </div>
            <div class="column column-error-text">
              <span>{{ error.Error.Message }}</span>
            </div>
            <div class="column column-error-type">
              <span>{{ error.Error.Code }}</span>
            </div>
            <div class="column column-error-metadata">
              <pre class="error-metadata-field">{{ error.Metadata | json }}</pre>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
