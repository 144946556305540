import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { TemplatePreviewData } from '../../../models/channel/template/template-preview-data.model';
import { TemplatePreviewInitData } from '../../../models/channel/template/template-preview-init-data.model';
import { TemplatesPreviewInitData } from '../../../models/channel/template/templates-preview-init-data.model';
import { OptiLogicModalService } from '../../optiLogicModal/optiLogicModal.service';
import { TemplateAvailabilityService } from '../templateAvailability.service';
import { TemplateModalData } from '../templateModalContainer/models/templateModalData.model';
import { TemplateModalType } from '../templateModalContainer/models/templateModalType.enum';
import { TemplateModalContainerComponent } from '../templateModalContainer/templateModalContainer.component';

@Component({
	selector: 'templates-preview-carousel',
	templateUrl: './templatesPreviewCarousel.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./templatesPreviewCarousel.component.scss'],
	providers: [
		TemplateAvailabilityService
	]
})
export class TemplatesPreviewCarouselComponent implements OnChanges, OnDestroy {
    @Input() templatesPreviewInitData?: TemplatesPreviewInitData;
    @Input() templatesPreviewFullData?: TemplatePreviewData[];
    @Input() shouldOpenModal: boolean = true;

    @Output() displayTemplateChange = new EventEmitter<TemplatePreviewInitData>();
    @Output() showTemplatesCarouselModal: EventEmitter<number> = new EventEmitter();
    @Output() showTemplatesGridModal: EventEmitter<number> = new EventEmitter();
    
    public templatesPreviewData: TemplatePreviewData[];
    public isLoading: boolean;
    public showLoader: boolean;
    public currentTemplateIndex: number;
    public templatesLength: number;
    public showTemplatesPreview: boolean;
    private getTemplatesSubscription: Subscription;

    constructor(private templateAvailabilityService: TemplateAvailabilityService,
        private optiLogicModalService: OptiLogicModalService, private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
    	this.currentTemplateIndex = 0;
    	this.showTemplatesPreview = true;

    	if (changes.templatesPreviewInitData && !isNullOrUndefined(changes.templatesPreviewInitData.currentValue)) {
    		if (this.templatesPreviewInitData.showTemplatesPreview) {
    			this.setDataFromTemplatesInitData(this.templatesPreviewInitData.templatesFolder, this.templatesPreviewInitData.templates);
    		} else {
    			this.handleWithoutTemplatePreview(this.templatesPreviewInitData.templates);
    		}
    	} else if (changes.templatesPreviewFullData && !isNullOrUndefined(changes.templatesPreviewFullData.currentValue)){
    		this.setDataFromTemplatesFullData();
    	}

    	this.templatesLength = this.getTemplatesLength(this.templatesPreviewInitData, this.templatesPreviewData);
    }

    private setDataFromTemplatesInitData(templatesFolder: string, templatesInitData: TemplatePreviewInitData[]) {
    	this.isLoading = true;
    	this.templatesPreviewData = [];
        
    	this.getTemplatesSubscription = this.templateAvailabilityService.getTemplatesPreviewData(templatesFolder, templatesInitData).subscribe(templates => {
    		this.templatesPreviewData = templates;
    		this.onAllTemplatesDownloadComplete();
    	});

    	this.showLoader = false;
    	this.showDelayLoader();
    }

    private setDataFromTemplatesFullData() {
    	this.templatesPreviewData = this.templatesPreviewFullData;
    	this.onAllTemplatesDownloadComplete();
    }

    private showDelayLoader() {
    	setTimeout(() => {
    		if (this.isLoading){
    			this.showLoader = true;
    		}
    	}, 250);
    }

    private onAllTemplatesDownloadComplete() {
    	this.showLoader = false;
    	this.isLoading = false;
    	this.cdRef.detectChanges();
    }

    showPrevTemplate() {
    	if (this.currentTemplateIndex > 0) {
    		this.currentTemplateIndex--;
    	} else {
    		this.currentTemplateIndex = this.templatesLength - 1;
    	}
    	this.emitDisplayTemplateChange(this.currentTemplateIndex, this.templatesPreviewInitData, this.templatesPreviewData);
    }

    showNextTemplate() {
    	if (this.currentTemplateIndex < this.templatesLength - 1) {
    		this.currentTemplateIndex++;
    	} else {
    		this.currentTemplateIndex = 0;
    	}
    	this.emitDisplayTemplateChange(this.currentTemplateIndex, this.templatesPreviewInitData, this.templatesPreviewData);
    }

    private getTemplatesLength(templatesPreviewInitData: TemplatesPreviewInitData, templatesPreviewData: TemplatePreviewData[]): number {
    	if (templatesPreviewData && templatesPreviewData.length > 0) {
    		return templatesPreviewData.length;
    	}

    	return templatesPreviewInitData.templates.length;
    }

    private emitDisplayTemplateChange(templateIndex:number, templatesPreviewInitData: TemplatesPreviewInitData, templatesPreviewData: TemplatePreviewData[]) {
    	if (templatesPreviewData && templatesPreviewData.length > 0) {
    		this.displayTemplateChange.emit(templatesPreviewData[templateIndex]);
    		return;
    	}

    	this.displayTemplateChange.emit(templatesPreviewInitData[templateIndex]);
    }

    public onShowTemplatesPreviewCarouselModal() {
    	if (this.shouldOpenModal) {
    		this.openTemplatesPreviewModal({
    			type: TemplateModalType.Carousel,
    			templates: this.templatesPreviewData,
    			displayTemplateIndex: this.currentTemplateIndex
    		});
    	} else {
    		this.showTemplatesCarouselModal.emit(this.currentTemplateIndex);
    	}
    }

    public showTemplatesGridView() {
    	if (this.shouldOpenModal) {
    		this.openTemplatesPreviewModal({
    			type: TemplateModalType.Grid,
    			templates: this.templatesPreviewData
    		});
    	} else {
    		this.showTemplatesGridModal.emit();
    	}
    }

    private openTemplatesPreviewModal(templateModalData: TemplateModalData) {

    	this.optiLogicModalService.open(TemplateModalContainerComponent, 'lg', {
    		initialState: {
    			templateModalData: templateModalData
    		},
    		backdrop: true,
    		animated: false,
    		keyboard: true
    	});
    }

    private handleWithoutTemplatePreview(templatesPreviewInitData: TemplatePreviewInitData[]) {
    	this.showTemplatesPreview = false;
    	this.templatesPreviewData = this.templatesPreviewInitData.templates.map(template => {
    		return {...template, isExists: false, url: ''};
    	});
    	this.onAllTemplatesDownloadComplete();
    }

    ngOnDestroy() {
    	this.getTemplatesSubscription && this.getTemplatesSubscription.unsubscribe();
    }
}
