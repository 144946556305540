import {ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import { SwitchButtonType } from "./switchButtonType.model";

@Component({
    selector: 'switch',
    templateUrl: './switch.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SwitchComponent),
        }
    ]
})
export class SwitchComponent implements ControlValueAccessor {

    @Input() buttonType: SwitchButtonType = SwitchButtonType.Default;
    private onChangeEmitterFunc: any;
    private onTouchEmitterFunc: any;
    isChecked: boolean = false;
    disabled: boolean = false;

    @Input()
    set disabledInput(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    constructor(private cd: ChangeDetectorRef) { }

    public onChange() {
        this.isChecked = !this.isChecked;
        this.onChangeEmitterFunc && this.onChangeEmitterFunc(this.isChecked);
        this.onTouchEmitterFunc && this.onTouchEmitterFunc();
    }

    public onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 13) { // enter
            this.onChange();
            event.stopPropagation();
            event.preventDefault();
        }
    }

    setDisabledState(isDisabled: boolean)
    {
        this.disabled = isDisabled;
    }

    writeValue(isChecked: boolean) {
        this.isChecked = isChecked;
        this.cd.detectChanges();
    }

    registerOnChange(fn: any) {
        this.onChangeEmitterFunc = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchEmitterFunc = fn;
    }
}
