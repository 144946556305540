<div class="channel-metrics">
    <accordion>
        <accordion-group [(isOpen)]="isExpanded" [isDisabled]="true">
            <div class="row" accordion-heading>
                <div class="col-sm-9 cm-title-section">
                    <h2 class="cm-title">{{'features.campaign_analysis.channelMetrics.TITLE' | translate}}</h2>
                    <span *ngIf="showNoMetrics" class="metrics-not-available">
                        {{'features.campaign_analysis.channelMetrics.UNAVAILABLE' | translate}}
                    </span>
                    <span *ngIf="!showNoMetrics && _outliersIsOn" class="metrics-not-available">
                        {{'features.campaign_analysis.channelMetrics.OUTLIERS_ON' | translate}}
                    </span>
                </div>
            </div>
            <div class="accordion-body">
                <channel-metrics-body [isScheduledCampaign]="true" [actionsData]='actions' [planIdActionSerial]='planIdActionSerial' [datesData]='dates' [isLoading]='isLoading' (metricClick)="onMetricClick($event)"></channel-metrics-body>
            </div>
        </accordion-group>
    </accordion>
</div>

