import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'insert-link-modal',
	templateUrl: './insertLinkModal.component.html',
	styleUrls: ['./insertLinkModal.component.scss']
})
export class InsertLinkModalComponent implements OnInit {
	public reg = 'https:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';
	public urlControl = new FormControl('', [Validators.pattern(this.reg)]);
	public textToDisplayControl = new FormControl('');
	public modalConf;
	@Output() linkEvent: EventEmitter<string> = new EventEmitter<string>();
	constructor(public bsModalRef: BsModalRef, private translateService: TranslateService) {}

	ngOnInit(): void {
		this.initModalConfig();
	}

	private initModalConfig(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.optimail.contentTags.INSERT_LINK'),
			buttons: [
				{
					isDisabled: () => {
						!(this.urlControl.valid && this.urlControl.value.length > 0 && this.textToDisplayControl.value.length > 0);
					},
					isLoading: () => {},
					loadingText: null,
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.optimail.contentTags.INSERT_LINK'),
					action: () => {
						this.linkEvent.emit(`<a href="${this.urlControl.value}">${this.textToDisplayControl.value}</a>`);
						this.bsModalRef.hide();
					}
				},
				{
					isDisabled: () => false,
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.move_template_modal.CANCEL_BTN'),
					action: () => this.bsModalRef.hide()
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
}
