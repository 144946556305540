import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { ApiKeyManagementService } from './apiKeyManagement.service';
import * as moment from 'moment';
import { SwitchButtonType } from "src/app/components/switchButton/switchButtonType.model";
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { UpdateApiKeyPopupComponent } from "src/app/features/settings/apiKeyManagement/UpdateApiKey/updateApiKeyPopup.component";
import { FullOptiApiKey } from "./models/optiApiKey.model";
import { AjaxResponse, IconType } from '@optimove/ui-sdk/common/models';
import { TranslateService } from "@ngx-translate/core";
import { _ } from "@ag-grid-community/all-modules";
import { UtilsMethodsService } from "../../../services/utilsMethods.service";
import { WindowRefService } from "src/app/services/windowRef.service";
import { first } from "rxjs/operators";
import { ApiRole } from "./models/apiRole.model";
import { ToastModel } from "../../missionControl/models/toast.model";
import { locationParameter } from "src/app/models/locationParameter.enum";

@Component({
    selector: 'api-key-management',
    templateUrl: './apiKeyManagement.component.html',
    styleUrls: ['./apiKeyManagement.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApiKeyManagementComponent implements OnInit, OnDestroy {

    public loading: boolean = true;
    public apiEndPointVal: string;
    public apiKeysData: FullOptiApiKey[] = [];
    public apiRoles: ApiRole[];
    public translateKeys = translateKeys;
    public switchButtonType: SwitchButtonType = SwitchButtonType.Small;
    public userProcessingIndex: number = null;
    public toastParams: ToastModel;
    private sortStatus: {columnName: string, order: string};

    constructor(private windowRefService: WindowRefService,
                private apiKeyManagementService: ApiKeyManagementService,
                private modalService: OptiLogicModalService,
                private translateService: TranslateService,
                private bsModalRef: BsModalRef, 
                private utilsMethodsService: UtilsMethodsService,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.apiKeyManagementService.currentStatusApiActivated
            .pipe(first()).subscribe(showApiActivatedToast => {
                this.setToastParams(showApiActivatedToast);
                this.loadData();
                this.apiKeyManagementService.getApiEndPoint()
                    .pipe(first()).subscribe((response) => {
                        this.apiEndPointVal = response;
                    }, (err) => console.log(err));
            }, (err) => console.log(err)
        );
    }

    private setToastParams(showApiActivatedToast: boolean) {
        this.toastParams = {
            isOpen: showApiActivatedToast,
            message: this.translateService.instant(this.translateKeys.keyCreatedMsg),
            icon: "info",
            showCloseIcon: false,
            location: locationParameter.Default,
            toastIconType: IconType.AngularMaterial
        };
    }

    private isDatesValues(isDate: boolean, valueA: string, valueB: string) {
        if (isDate) {
            valueA = valueA.split('/').reverse().join('');
            valueB = valueB.split('/').reverse().join('');
        }
        return { valueA, valueB };
    }

    public sortBy(columnName, order): boolean {
        return this.sortStatus && this.sortStatus.columnName == columnName &&
            this.sortStatus.order == order;
    }

    public sortUsers(columnName: string) : void {
        this.updateSortStatus(columnName);
        this.apiKeysData.sort((user_1, user_2) => {
            var valueA: string = "";
            var valueB: string = "";
            var prop1 = user_1[columnName];
            var prop2 = user_2[columnName];
            ({ valueA , valueB } = (columnName == "LastUseDateTime" || columnName == "CreatedDateTime") 
                ? this.isDatesValues(true, prop1, prop2)
                : this.isDatesValues(false, prop1, prop2));
            var result = (this.sortStatus.order == 'asc') 
                ? this.utilsMethodsService.abSorting(valueA, valueB)
                : this.utilsMethodsService.abSorting(valueB, valueA);
            return result;
        });
    }

    private updateSortStatus(columnName: string): void {
        if (!this.sortStatus) {
            this.sortStatus = { columnName: columnName, order: 'asc' };
        } else {
            if (this.sortStatus.columnName != columnName) {
                this.sortStatus.order = 'asc';
            } else if (this.sortStatus.order == 'asc') {
                this.sortStatus.order = 'desc';
            } else {
                this.sortStatus.order = 'asc';
            }
            this.sortStatus.columnName = columnName;
        }
    }

    public goToAPIDocumentation(): void {
        this.windowRefService.nativeWindow.open('https://developer.optimove.com/', '_blank');
    }

    public convertToDatestring(inputDate: string): string {
        const dateStr = moment(inputDate).format('DD/MM/YYYY');
        if (dateStr.includes('/0001')){
            return '-';
        }
        return dateStr;
    }

    private loadData(): void {
        this.apiKeyManagementService.getRoleTypesAsync()
        .pipe(first()).subscribe(async (roleTypes) => {
            this.apiRoles = await roleTypes.Data;
            this.apiKeyManagementService.getApiKeysAsync()
            .pipe(first()).subscribe(async (existingApiKeys)  => {
                    this.apiKeysData = await existingApiKeys.Data;
                    this.loading = false;
                    this.cd.detectChanges();
                }, (err) => console.log(err));
            }, (err) => console.log(err));
    }

    private openFailedModal(user: FullOptiApiKey, title:string, errorMsg: string): void {
        this.modalService.openModalMessage(
            'sm',
            {
                title: title,
                html: errorMsg,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant('general.OK'),
                    action: () => this.bsModalRef.hide(),
                    dataQaId: 'errorUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
        user.IsActive = !user.IsActive;
        this.bsModalRef.hide();
        setTimeout (() => { this.userProcessingIndex = null; }, 300);
    }

    updateIsActive([event, user]: [boolean, FullOptiApiKey]) : void {
        this.userProcessingIndex = this.apiKeysData.indexOf(user, 0);
        user.IsActive = event;
        this.cd.detectChanges();

        this.apiKeyManagementService.updateApiKeyAsync(user)
            .pipe(first()).subscribe(async (response: AjaxResponse<string>) => {
                await (!response.isSuccess) ? this.failedToUpdateModal(user) : this.userProcessingIndex = null;
                this.cd.detectChanges();
            }, (_) => this.failedToUpdateModal(user));
      }

    private failedToUpdateModal(user: FullOptiApiKey) : void {
        (user.IsActive === false)
            ? this.openFailedModal(user, this.translateService.instant(this.translateKeys.titleDeactivate), this.translateService.instant(this.translateKeys.errorDeactivateUser))
            : this.openFailedModal(user, this.translateService.instant(this.translateKeys.titleActivate), this.translateService.instant(this.translateKeys.errorActivateUser));
    }

    private getRangeDate(user: FullOptiApiKey): boolean {
        var date1 = new Date().getTime();
        var date2 = new Date(this.convertToDatestring(user.LastUseDateTime)).getTime();
        var daysSinceLastLogin = ((date1 - date2) / (1000 * 60 * 60 * 24));
        // According to US: 154240 - If this user have been logged in in the past 32 days - 
        // this user will see the deactivate msg, otherwise, he will see the delete msg in the modal
        return daysSinceLastLogin <= 32;
    }

    openUpdateUserModal(user: FullOptiApiKey) : void {
        this.bsModalRef = this.modalService.open(
            UpdateApiKeyPopupComponent, 
            "md", 
            <ModalOptions<any>>{ 
                ignoreBackdropClick: true,
                initialState: {
                    recommendToBlock: this.getRangeDate(user),
                    apiKey: user,
                    lastUsed: (user.LastUseDateTime !== null) ? (this.convertToDatestring(user.LastUseDateTime)) : "-",
                    apiKeys: this.apiKeysData
                },
                class: 'update-api-key-popup'
        });

        this.bsModalRef.content.updateExistingApiKeysEvent.subscribe((res: FullOptiApiKey[]) => {
            this.apiKeysData = res;
            this.cd.detectChanges();
        });
    }

    findRole(userRole: number): string {
        let role = "-";
        this.apiRoles.find(element => {
            if (element.RoleType === userRole) {
                role = element.RoleName;
            }
        });
        return role;
    }

    ngOnDestroy(): void {
        this.apiKeyManagementService.passShowApiActivatedToast(false);
    }
}

const translateKeys = {
      title: 'features.user_settings.body.apiKeyManagement.TITLE',
      titleDeactivate: 'features.user_settings.body.apiKeyManagement.updateApiKey.TITLE_DEACTIVATE',
      titleActivate: 'features.user_settings.body.apiKeyManagement.updateApiKey.TITLE_ACTIVATE',
      apiEndPoint: 'features.user_settings.body.apiKeyManagement.END_POINT',
      apiDocumentation: 'features.user_settings.body.apiKeyManagement.API_DOCUMENTATION',
      colNickName: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_NICKNAME',
      colRole: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_ROLE',
      colContactEmail: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_CONTACT_EMAIL',
      colLastUsed: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_LAST_USED',
      colCreateDate: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_CRATE_DATE',
      colActive: 'features.user_settings.body.apiKeyManagement.TableColumns.COL_ACTIVE',
      addNewApiKey: 'features.user_settings.body.apiKeyManagement.ADD_NEW_API_KEY',
      noUserConfigured: 'features.user_settings.body.apiKeyManagement.emptyState.NO_USERS_CONFIGURED',
      processing: 'features.optibot.insight_card.PROCESSING',
      errorDeactivateUser: 'features.user_settings.body.apiKeyManagement.updateApiKey.ERROR_DEACTIVATE_USER',
      errorActivateUser: 'features.user_settings.body.apiKeyManagement.updateApiKey.ERROR_ACTIVATE_USER',
      keyCreatedMsg: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.API_KEY_CREATED'
}
