<div class="reccurence-form">
  <form [formGroup]="form" class="form-container">
    <div class="row form-group">
      <div class="col-md-12 label-value-container">
        <div class="label-column">
          {{ "features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.FREQUENCY_LABEL" | translate }}
        </div>
        <div class="frequency-and-time-input">
          <div class="label-value-inline frequency-input">
            <opti-search-list formControlName="frequency" [items]="frequencyOptions" [config]="frequencyConfig"></opti-search-list>
          </div>
          <div class="label-value-inline">
            <opti-search-list formControlName="timeUnit" [items]="timeUnitsOptions" [config]="timeUnitsConfig"></opti-search-list>
          </div>
        </div>
        <div *ngIf="isRecurrenceByWeek">
          <chips typeValidator [items]="weekdayOptions" [mode]="ChipsMode.SECONDARY" formControlName="weekdays" isRequired="true"></chips>
          <label *ngIf="showRedTextErrors && weekdaysControl.invalid" class="weekdays-error text-danger small">
            {{ "features.JB.vsb_stream_settings.red_errors.missing_selection" | translate }}
          </label>
        </div>
        <div *ngIf="isRecurrenceByMonth">
          <ng-container *ngIf="startDate != null">
            <chips-radio-group [items]="monthOptions" name="monthOptions" formControlName="monthSelection"></chips-radio-group>
          </ng-container>
          <div class="missing-start-date" *ngIf="startDate == null">
            <i class="material-icons text-warning">warning</i>
            <span class="missing-start-date-text">Choose a start date above to select a monthly recurrence type</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 label-value-container">
        <div class="label-column">
          {{ "features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.ENDING" | translate }}
        </div>
        <div>
          <div class="form-check radio radio-primary radio-group ending-never">
            <input class="form-check-input" type="radio" formControlName="endingType" id="exampleRadios1" [value]="endingType.Never" />
            <label class="form-check-label" for="exampleRadios1">
              {{ "features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.ENDING_TYPE_NEVER" | translate }}
            </label>
          </div>
          <div class="form-check radio radio-primary radio-group">
            <input class="form-check-input" type="radio" formControlName="endingType" id="exampleRadios2" [value]="endingType.After" />
            <label class="form-check-label" for="exampleRadios2">
              {{ "features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.ENDING_TYPE_AFTER" | translate }}
            </label>
            <div class="after-occurences-input">
              <input
                formControlName="endingAfterOccurrences"
                (keydown)="handlePositiveNumber($event)"
                class="form-control occurences-input"
                type="number"
                min="1"
                step="1" />
              <div class="occurences-text">occurences</div>
              <span
                *ngIf="
                  endingAfterOccurrencesControl?.dirty &&
                  endingAfterOccurrencesControl?.value !== null &&
                  endingAfterOccurrencesControl?.errors?.minNumberOfOccurrencesPast
                "
                class="ending-after-error text-danger small">
                {{ labelsTranslateKeys.numberOfOccurrencesPastError | translate }}
              </span>
              <span
                *ngIf="
                  showRedTextErrors &&
                  endingAfterOccurrencesControl?.value !== null &&
                  endingAfterOccurrencesControl?.errors?.min?.min > endingAfterOccurrencesControl?.errors?.min?.actual
                "
                class="ending-after-error text-danger small">
                {{ "features.JB.vsb_stream_settings.red_errors.enter_value_greater_than_0" | translate }}
              </span>
              <span
                *ngIf="showRedTextErrors && endingAfterOccurrencesControl?.errors?.required"
                class="ending-after-error text-danger small">
                {{ "features.JB.vsb_stream_settings.red_errors.missing_selection" | translate }}
              </span>
            </div>
          </div>
          <div class="form-check radio radio-primary radio-group">
            <input class="form-check-input" type="radio" formControlName="endingType" id="exampleRadios3" [value]="endingType.By" />
            <label class="form-check-label" for="exampleRadios3">
              {{ "features.scheduled_campaign_builder.STEPS.SCHEDULING.RECURRENCE.ENDING_TYPE_BY" | translate }}
            </label>
            <div class="ending-by">
              <div class="ending-by-input">
                <date-picker name="endingBy" formControlName="endingBy" [datePickerData]="datePickerData"></date-picker>
              </div>
              <ng-container
                *ngIf="
                  (showRedTextErrors === undefined || showRedTextErrors) &&
                  endingByControl?.dirty &&
                  endingByControl?.value !== null &&
                  endingByControl?.errors?.minDate
                ">
                <p *ngIf="isEditMode && endingByControl?.value < clientTime; else minEndDateError" class="endingby-error text-danger small">
                  {{ labelsTranslateKeys.endDateAlreadyPassedError | translate }}
                </p>

                <ng-template #minEndDateError>
                  <p class="endingby-error text-danger small">
                    {{ labelsTranslateKeys.minEndDateError | translate }}
                  </p>
                </ng-template>
              </ng-container>
              <p
                *ngIf="showRedTextErrors && endingByControl?.value === null && endingByControl?.errors?.invalidDate"
                class="endingby-error text-danger small">
                {{ "features.JB.vsb_stream_settings.red_errors.missing_selection" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
