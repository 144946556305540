import {Inject, Injectable} from "@angular/core";

@Injectable()
export class GTMDataLayerService {
    private tenantId: string;

    constructor(@Inject('gtmDataLayerService') private gtmDataLayerService: any){}

    addGTMEvent(sdkEvent: string, category: string, subCategory: {}){
        this.gtmDataLayerService.addTrackingEvent(sdkEvent, category, subCategory);
    }

    getTenantId(): string {
        if (!this.tenantId) {
            this.tenantId = this.gtmDataLayerService.getTenantId();
        }

        return this.tenantId;
    }

    initPageUserId(navItemName: string, tenantId: string){
        this.gtmDataLayerService.initPageUserId(navItemName, tenantId);
    }
}