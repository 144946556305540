<opti-logic-modal [modalConf]="modalConf">
    <div custom-header class="header">
        <div class="modal-header-col">
            <div class="modal-header-row">
                <div class="title"> {{ translateKeys.TITLE | translate }} </div>
                <div class="counter"></div>
                <attributes-count-tag *ngIf="attributes.length > 0" [attributes]="attributes" [config]="{}"></attributes-count-tag>
            </div>
            <div class="modal-header-row head-description"> {{ translateKeys.DESCRIPTION | translate }} </div>
        </div>
        <div class="modal-header-col bottom-items">
            <div class="find">
                <input-search placeholder="Quick find" (termChange)="onFilter($event)"></input-search> 
            </div>
            <div class="export">
                <button style="height: 26px;" class="btn btn-secondary" container="body" (click)="exportToCsv()">
                    <i class="material-icons">open_in_browser</i>
                    <span>{{'general.EXPORT_TO_CSV' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="customer-attributes-container">
        <ag-grid-angular class="customer-attributes-grid customer-attr-grid-settings ag-theme-white-basic .ag-theme-balham  " [rowData]="attributes" [modules]="modules" [gridOptions]="gridOptions">
        </ag-grid-angular>
    </div>
</opti-logic-modal>
