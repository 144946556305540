import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges , ViewEncapsulation } from "@angular/core";
import {ITreeDotButtons, ITreeNodeButtons, treeEventType} from "../jsTree.component";
import {ITreeMenuItem} from "../menuItem.model";

@Component ({
    selector: 'js-tree-dot-menu',
    templateUrl: './jsTreeDotMenu.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./jsTreeDotMenu.component.scss']
})
export class JsTreeDotMenuComponent implements OnChanges {
    @Input() treeButtons: ITreeNodeButtons;
    @Input() multiSelectedLength: number;
    @Input() isDisabled: boolean;
    @Output() itemClicked: EventEmitter<treeEventType> = new EventEmitter();

    public isMenuOpen = false;
    public menuItems: ITreeMenuItem[];

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (!this.isMenuOpen) return;
        if(!this.eRef.nativeElement.contains(event.target)) this.closeMenu();
    }

    constructor(private eRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.treeButtons) {
            this.menuItems = this.getMenuItems(this.treeButtons);
        }
    }

    onBtnMenuClicked() {
        this.isMenuOpen = true;
    }

    onMenuItemClicked(eventType: treeEventType) {
        this.itemClicked.emit(eventType);
        this.closeMenu();
    }

    private getMenuItems(treeButtons: ITreeDotButtons): ITreeMenuItem[]  {
        const menuItems: ITreeMenuItem[] = [];

        for (let item in treeButtons) {
            let event;
            switch(item) {
                case 'delete':
                    event = treeEventType.deleteNodes;
                    break;
                case 'folderWithSelection':
                    event = treeEventType.folderWithSelection;
                    break;

            }

            if (treeButtons[item].isHeaderMenuEnable && treeButtons[item].isEnable) {
                menuItems.push({
                    text: this.treeButtons[item].text,
                    eventType: event
                });
            }
        }

        return menuItems;
    }

    private closeMenu() {
        this.isMenuOpen = false;
    }
}