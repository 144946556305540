
<opti-logic-modal class="consent-modal" [modalConf]="modalConf" data-qa-id="ConsentModal">
    <div class="table-email" *ngIf="email!==''">
        <div class=email-container>
            <div class="email-identfier col-md-10">
                <div class="email-blue-icon-container">
                    <img src='assets/optimove/ui-sdk/images/channelGroupType/email.svg' [alt]="this.email" class="email-blue-icon">
                </div>
                <div class="email-and-channels">
                    <span data-qa-id="EmailTitle" class="email-title">{{'features.customer360.consent_modal.EMAIL' | translate}}</span>
                    <span data-qa-id="EmailValue" class="email-value">{{this.email}} | {{ 'features.customer360.consent_modal.OPTIMAIL' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="content-container">
            <div class="line-container">
                <div class="loader-container" >
                    <opti-loading [show]="isLoading" [size]="'md'" ></opti-loading>
                </div>
                <div class="boarder-under-email">
                    <hr>
                </div>
            </div>
            <div class="brands-cotainer">
                <div class="brands-inputs">
                    <div class="brands">
                        <div class="col-md-10 header" *ngFor="let audit of auditDataAllBrands">
                            <div class="brand-name brand-generic col-md-10" data-qa-id="BrandName">{{audit.BrandName}}</div>
                            <div class="brand-status col-md-0">
                                <colored-tag *ngIf="!audit.IsOptedIn" class="cell-container col-md-0" data-qa-id="ColoredTagType" [coloredTagType]="coloredTagType">
                                    Opted-Out
                                </colored-tag>
                                <colored-tag *ngIf="audit.IsOptedIn" class="cell-container col-md-0" data-qa-id="ColoredTagType" [coloredTagType]="coloredTagTypeIn">
                                    Opted-In
                                </colored-tag>
                            </div>
                            <div class="panel panel-default">
                                <div class="page-header">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h2>
                                                <span class="underline-small" data-qa-id="HistoryTitle">
                                                   {{'features.customer360.consent_modal.HISTORY' | translate}}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            <div *ngIf="audit.count===0">
                                    <div class="panel-body top-section-container no-his" data-qa-id="NoHistoryTitle">
                                        <span class="no-history"> {{'features.customer360.consent_modal.NO_HISTORY' | translate}}</span>
                                    </div>
                            </div>
                            <div *ngIf="audit.count!=0">
                                <div class = "panel-body top-section-container">
                                        <ag-grid-angular
                                                #consentModalGrid
                                                [ngClass]="{'my_class1': audit.count=== 1, 'my_class2' : audit.count=== 2,
                                                'my_class3' : audit.count=== 3, 'my_class4' : audit.count > 3  }"
                                                class="auditHistory ag-theme-balham ag-theme-white"
                                                [modules]="modules"
                                                [gridOptions]="gridOptionsArray[audit['GridNumber']]">
                                        </ag-grid-angular>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="email===''" class="viewConsentHistory">
        <h1 class="not_found_message" data-qa-id="NotFoundMessage" >{{'features.customer360.consent_modal.Not_Found' | translate}}</h1>
    </div>
</opti-logic-modal>