
import { ControlValueAccessor, Validator, AbstractControl, FormGroup, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormBuilder } from '@angular/forms';
import { Component, forwardRef, OnDestroy, Input, AfterViewInit, ChangeDetectorRef, OnInit, OnChanges, SimpleChanges , ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import {DisabledFieldValue, DisabledState} from './models/disabledState.model';
import { isNullOrUndefined } from 'util';
import { PredicateEmitChange } from './models/predicateEmitChange.model';

@Component({
    template: '',
})
export class AbstractNestedFormComponent<T> implements ControlValueAccessor, OnInit, OnChanges, Validator, OnDestroy {
    /**
     * Set form disabled state from parent
     *  
     * ```ts
     * this.disabledState = {
     *     a: {
     *         prop1: true,
     *         prop2: false
     *     },
     *     b: false
     * }
     * 
     * ```
     * ```html
     * <nested-form-a [disabledState]="disabledState?.a">
     * <nested-form-b [disabledState]="disabledState?.b">
     * <nested-form-c [disabledState]="disabledState?.c">
     * ```

    */
    @Input() disabledState?: DisabledState<T>;
    
    private _form: FormGroup;
    private emitChange: PredicateEmitChange<T>;
    private pendingInitFormValue: T;
    private hasPendingEmitValueChange = false;
    private isValueChangedFromParentComponent = false;

    public onTouched: Function;
    private valueChangeSubscription: Subscription;
    protected isFormDisabled: boolean;

    constructor(protected cd: ChangeDetectorRef) {

    }

    set form(formGroup: FormGroup) {
        this._form = formGroup;

        this.observeFormValueChange();

        if (!(this.pendingInitFormValue === null || this.pendingInitFormValue === undefined)) {
            this._form.setValue(this.pendingInitFormValue);
            this.pendingInitFormValue = null;
        }
    }

    get form(): FormGroup {
        return this._form;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledState && !changes.disabledState.firstChange && changes.disabledState.currentValue !== changes.disabledState.previousValue) {
            this.setDisabledStateFromConfiguration();
        }
    }

    ngOnInit() {
        this.setDisabledStateFromConfiguration();
    }

    private observeFormValueChange() {
        this.valueChangeSubscription = this.form.valueChanges.subscribe((formValue: any) => {
            if (isNullOrUndefined(this.emitChange)) {
                this.hasPendingEmitValueChange = true;
                return;
            }

            this.handleEmitValueChange(this.form);
        });
    }

    private handleEmitValueChange(form: FormGroup) {
        if (this.isValueChangedFromParentComponent) {
            this.isValueChangedFromParentComponent = false;
            return;
        }

        this.emitChange(form.getRawValue());
    }

    writeValue(val: any): void {
        if (isNullOrUndefined(val)) {
            return;
        }

        this.isValueChangedFromParentComponent = true;

        if (isNullOrUndefined(this.form)) {
            this.pendingInitFormValue = val;
            return;
        }
        
        this.form.setValue(val);
    }

    registerOnChange(fn: any): void {
        this.emitChange = fn;

        if (this.hasPendingEmitValueChange) {
            this.hasPendingEmitValueChange = false;
            this.handleEmitValueChange(this.form);
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        const disabledOptions = { onlySelf: true, emitEvent: false };
        this.isFormDisabled = isDisabled;
        isDisabled ? this.form.disable(disabledOptions) : this.form.enable(disabledOptions);
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.form.valid || this.form.disabled ? null : { invalidForm: { valid: false, message: "form fields are invalid" } };
    }

    setDisabledStateFromConfiguration() {
        if (this.disabledState) {
            Object.keys(this.disabledState).forEach(key => {
                const formControl = this.form.get(key);
                if (formControl) {
                    if (this.disabledState[key] === DisabledFieldValue) {
                        formControl.disable();
                    }
                    else {
                        formControl.enable();
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.valueChangeSubscription && this.valueChangeSubscription.unsubscribe();
    }
}