<div id="{{ folderTemplate.templateID }}" class="card-container" [ngClass]="isDragging ? 'dragging' : ''">
  <div [@fadeIn] class="card-container-with-bottom-line">
    <div (click)="$event.stopPropagation()" class="checkbox-container">
      <div *ngIf="isBatchActionsEnabled()" class="checkbox checkbox-primary batch-actions">
        <input
          name="checkboxTemplate"
          [(ngModel)]="isChecked"
          (change)="updateNumberOfSelected()"
          data-qa-id="checkbox-batch-actions"
          type="checkbox" />
        <label></label>
      </div>
    </div>
    <ng-container [ngSwitch]="previewType">
      <ng-container *ngSwitchCase="previewTypeEnum.Default">
        <div class="preview-image-container">
          <a [href]="getEditTemplateLink()">
            <img [src]="templatePreviews" />
          </a>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="previewTypeEnum.Optimobile">
        <div></div>
      </ng-container>
    </ng-container>
    <div class="template-data-container" [ngClass]="isOptimobile() ? 'template-data-container-optimobile' : 'aaaaaaaaaaaa'">
      <div class="template-title-container">
        <a
          [href]="getEditTemplateLink()"
          class="template-preview-title"
          [innerHtml]="getTemplateName(folderTemplate)"
          [title]="getTemplateName(folderTemplate)"
          containerClass="template-name-tooltip-container"
          data-qa-id="template-card-name"></a>
      </div>
      <div *ngIf="!isSubjectLineVisible()" [title]="folderTemplate.subjectLine" containerClass="template-subject-tooltip-container">
        <a [href]="getEditTemplateLink()" class="text-under-title" data-qa-id="template-card-subject">
          {{ folderTemplate.subjectLine }}
        </a>
      </div>
      <div *ngIf="searchText && searchText.length >= this.minSearchTextLength" class="path-section">
        <div>
          {{ getTemplatePath().root }}
        </div>
        <div class="child-path" containerClass="template-path-tooltip-container" [title]="getTemplatePath().fullPath">
          {{ getTemplatePath().childPath }}
        </div>
      </div>
      <div class="bottom-section">
        <div *ngIf="!folderTemplate.isValid && folderTemplate.channelId === 15" class="invalid-template">
          <i class="material-icons info-icon">info</i>
        </div>
        <div
          class="template-usage-container"
          [ngClass]="{'item-without-border': folderTemplate.lastModifiedDate === null && isNumberOfLanguagesVisible()}"
          data-qa-id="template-card-usage">
          <div *ngIf="isInUse">
            {{ "features.manage_templates.messages.TEMPLATE_IN_USE" | translate }}
          </div>
          <div *ngIf="!isInUse">
            {{ "features.manage_templates.messages.TEMPLATE_NOT_IN_USE" | translate }}
          </div>
        </div>
        <div
          class="last-modify-date-container"
          [ngClass]="{'item-without-border': !this.isNumberOfLanguagesVisible() || !numberOfLanguages}"
          *ngIf="folderTemplate.lastModifiedDate !== null"
          data-qa-id="template-card-last-modified">
          Last modified: {{ folderTemplate.lastModifiedDate | date : "fullDate" }}
        </div>
        <div class="number-of-languages" *ngIf="this.isNumberOfLanguagesVisible() && numberOfLanguages > 1">
          {{ numberOfLanguages }} {{ "features.manage_templates.messages.LANGUAGES" | translate }}
        </div>
        <div class="number-of-languages" *ngIf="this.isNumberOfLanguagesVisible() && numberOfLanguages === 1">
          {{ numberOfLanguages }} {{ "features.manage_templates.messages.LANGUAGE" | translate }}
        </div>
      </div>
    </div>
    <div class="menu-buttons">
      <div class="buttons-container" (click)="$event.stopPropagation()">
        <button
          *ngIf="!numberOfLanguages"
          class="btn btn-link btn-link-default overflow-menu-item-btn ng-star-inserted duplicate-button"
          title="Duplicate Template"
          data-qa-id="duplicate-template"
          (click)="duplicate()"
          [disabled]="isDuplicating">
          <i overflowMenuItemIcon class="material-icons">file_copy</i>
        </button>
        <div
          class="overflow-menu-container"
          [ngClass]="{'overflow-menu-container-without-disable': numberOfLanguages > 0}"
          data-qa-id="template-card-action-menu">
          <overflow-menu [position]="menuPosition" [items]="overflowMenuItems">
            <ng-template #overflowMenuItemTemplate let-overflowMenuItem>
              <overflow-menu-item [hasSubMenu]="overflowMenuItem.children?.length > 0">
                <i overflowMenuItemIcon class="material-icons">{{ overflowMenuItem.icon }}</i>
                <span overflowMenuItemText data-qa-id="template-card-action-button">{{ overflowMenuItem.text }}</span>
              </overflow-menu-item>
            </ng-template>
          </overflow-menu>
        </div>
      </div>
    </div>
  </div>
</div>
