import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { WindowRefService } from '../../../../services/windowRef.service';
import { BiStudioService } from '../../services/biStudio.service';

@Component({
  selector: 'bi-studio-landing-page',
  templateUrl: './biStudioLandingPage.component.html',
  styleUrls: ['./biStudioLandingPage.component.scss']
})
export class BiStudioLandingPageComponent implements OnInit {
  bistudioSendMail: boolean = true;

  constructor(
    private readonly biStudioService: BiStudioService,
    private readonly windowRefService: WindowRefService,
    private readonly modalService: OptiLogicModalService,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.bistudioSendMail = JSON.parse(window.sessionStorage.getItem("bistudioSendMail")) ?? this.bistudioSendMail;
  }

  sendMail() {
    this.biStudioService.sendMail()
      .subscribe(res => {
        if (!res.isSuccess) {
          this.failMessage();
        } else {
          this.bistudioSendMail = false;
          this.windowRefService.nativeWindow.sessionStorage.setItem("bistudioSendMail", `${this.bistudioSendMail}`);
        }
      })
  }

  private failMessage() {
    this.modalService.openSuccessFailModal(
      'sm',
      {
        isSuccess: false,
        title: this.translateService.instant('features.bistudio.mail_sender_error'),
        message: this.translateService.instant('features.bistudio.mail_sender_error_message'),
        primaryButtonText: this.translateService.instant('general.OK'),
      },
      <ModalOptions<any>>{
        ignoreBackdropClick: true,
      }
    );
  }
}