import { Customer360TemplateService } from './services/customer360.template.service';
import { Customer360PurchaseHistoryService } from './services/customer360.purchaseHistory.service';
import { AnalysisRangePickerComponent } from './components/analysisRangePicker/analysisRangePicker.component';
import { PurchaseHistoryMultiAttributeFlowComponent } from './components/purchaseHistory/purchaseHistoryMultiAttributeFlowComponent';
import { PurchaseHistoryComponent } from './components/purchaseHistory/purchaseHistoryComponent';
import { ComponentsModule } from './../../components/components.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule, CurrencyPipe} from "@angular/common";
import {OptiModalModule} from "../../modules/OptiModal.module";
import {ConsentModalComponent} from "./components/consentModal/consentModal.component";
import {CustomersListComponent} from "./components/customersList/customersList.component";
import {TranslateModule} from "@ngx-translate/core";
import {ConsentModalService} from "./components/consentModal/consent-modal.service";
import {SettingsSubscribersService} from "../settings/settingsSubscribers/settingsSubscribers.service";
import {AgGridModule} from "@ag-grid-community/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SetupGearButton } from "./components/setupGearButton/setupGearButton.component";
import { ChangeAttributeModal } from "./components/changeAttributeModal/changeAttributeModal.component";
import { Customers360ModalService } from "./services/customer360Modal.service";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AddKPIsModalComponent } from './components/add-kpis-modal/addKPIsModal.component';
import { CampaignGridComponent } from './components/campaign-grid/campaign-grid.component';
import { CampaignGridTriggerColumnComponent } from './components/campaign-grid/triggerColumn/campaignGridTriggerColumn.component';
import { CampaignGridChannelsColumnComponent } from './components/campaign-grid/channelsColumn/campaignGridChannelsColumn.component';
import { CampaignGridTemplateColumnComponent } from './components/campaign-grid/templateColumn/campaignGridTemplateColumn.component';
import { CampaignGridActionColumnComponent } from './components/campaign-grid/actionColumn/campaignGridActionColumn.component';
import { AttributesGridComponent } from './components/attributesGrid/attributesGrid.component'
import { CampaignGridPreviewColumnComponent } from './components/campaign-grid/previewColumn/campaignGridPreviewColumn.component';
import { CampaignGridPreviewColumnHoverComponent } from './components/campaign-grid/previewColumnHover/campaignGridPreviewColumnHover.component';
import { Customer360CellService } from './services/customer360Cell.service';
import { Customer360DataService } from './services/customer360.data.service';
import { Customer360FormatService } from './services/customer360.format.service';
import { Customer360PageComponent } from './components/customer360Page/customer360Page.component';
import { OptiSideBarModalService } from '../../services/optiSideBerModal.service';
import { OptiSideBarComponent } from './components/optiSideBar/optiSideBar.component';
import { SearchInputComponent } from './components/searchInput/searchInput.component';
import { CustomerDataPageComponent } from './components/customerDataPage/customerDataPage.component';
import { CustomerDataSummaryComponent } from './components/customerDataSummary/customerDataSummary.component';
import { CustomerImpactHeaderComponent } from './components/customerImpactHeader/customerImpactHeader.component';
import { ComponentsTransitionalModule } from 'src/app/components/components_transitional.module';
import { CustomerImpactMetricsComponent } from './components/customerImpactMetrics/customerImpactMetrics.component';
import { CustomerImpactDiagramsComponent } from './components/customerImpactDiagrams/customerImpactDiagrams.component';
import { CustomerImpactGraphComponent } from './components/customerImpactGraph/customerImpactGraph.component';
import { CustomerImpactCampaignsTableComponent } from './components/customerImpactCampaignsTable/customerImpactCampaignsTable.component';
import { CustomerSnapshotsTableComponent } from './components/customerSnapshotsTable/customerSnapshotsTable.component';
import { SelectAttributesModalComponent } from './components/purchaseHistory/selectAttributesModal/selectAttributesModal.component';
import { TemplateModalComponent } from './components/campaign-grid/templateModal/templateModal.component';
import { PipesModule } from './../../pipes/pipes.module';
import { PreferenceHistoryModalComponent } from './components/preferenceHistoryModal/preferenceHistoryModal.component';
import { PreferenceHistoryHeaderComponent } from './components/preferenceHistoryModal/preferenceHistoryHeader/preferenceHistoryHeader.component';
import { PreferenceHistoryMasterComponent } from "./components/preferenceHistoryModal/preferenceHistoryMaster/preferenceHistoryMaster.component";
import { PreferenceHistoryDetailsComponent } from "./components/preferenceHistoryModal/preferenceHistoryDetails/preferenceHistoryDetails.component";
import { PreferenceHistoryAuditCardComponent } from "./components/preferenceHistoryModal/preferenceHistoryAuditCard/preferenceHistoryAuditCard.component";
import { PreferenceHistoryService } from "./components/preferenceHistoryModal/services/preferenceHistory.service";


@NgModule({
    imports: [
        CommonModule,
        OptiModalModule.forRoot(),
        TranslateModule,
        ComponentsModule,
        FormsModule,
		ComponentsTransitionalModule,
        ReactiveFormsModule,
        PipesModule,
        PopoverModule,
        AgGridModule.withComponents([
            CampaignGridTriggerColumnComponent, 
            CampaignGridChannelsColumnComponent, 
            CampaignGridTemplateColumnComponent,
            CampaignGridActionColumnComponent,
            CampaignGridPreviewColumnComponent,
            CampaignGridPreviewColumnHoverComponent
        ]),
    ],
    exports: [],
    declarations: [
        PreferenceHistoryAuditCardComponent,
        PreferenceHistoryDetailsComponent,
        PreferenceHistoryMasterComponent,
        PreferenceHistoryHeaderComponent,
        PreferenceHistoryModalComponent,
        ConsentModalComponent,
        CustomersListComponent,
        SetupGearButton,
        ChangeAttributeModal,
        AddKPIsModalComponent,
        CampaignGridComponent,
        CampaignGridTriggerColumnComponent,
        CampaignGridChannelsColumnComponent,
        CampaignGridTemplateColumnComponent,
        CampaignGridActionColumnComponent,
        CampaignGridPreviewColumnComponent,
        CampaignGridPreviewColumnHoverComponent,
        AttributesGridComponent,
		Customer360PageComponent,
		OptiSideBarComponent,
		CustomerDataPageComponent,
		SearchInputComponent,
		CustomerDataSummaryComponent,
		CustomerImpactHeaderComponent,
		CustomerImpactMetricsComponent,
		CustomerImpactDiagramsComponent,
		CustomerImpactGraphComponent,
		CustomerImpactCampaignsTableComponent,
		CustomerSnapshotsTableComponent,
        AnalysisRangePickerComponent,
        PurchaseHistoryMultiAttributeFlowComponent,
        PurchaseHistoryComponent,
        SelectAttributesModalComponent,
        TemplateModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        PreferenceHistoryService,
        SettingsSubscribersService,
        ConsentModalService,
        Customers360ModalService,
        Customer360CellService,
        CurrencyPipe,
        Customer360DataService,
		Customer360FormatService,
		CurrencyPipe,
		OptiSideBarModalService,
        Customer360PurchaseHistoryService,
        Customer360TemplateService,
		{ provide: 'ssmService', useFactory: i => i.get('ssmService'), deps: ['$injector'] },
		{ provide: 'uiGridCellFactory', useFactory: i => i.get('uiGridCellFactory'), deps: ['$injector'] }
    ]
})
export class Customer360Module {

}
