<div class="select-attribute-modal-container">
    <opti-logic-modal [modalConf]="modalConf">
        <div class="sub-header">
            <span class="header-desc">
                <p>{{ translateKeys.description.first | translate }}</p>
                <a  href="https://academy.optimove.com/en/article/about-optimove-data-studio"
                    target="_blank"
                    rel="noopener">{{ translateKeys.description.link | translate }}</a>
                <p>{{ translateKeys.description.last | translate }}</p>
            </span>
        </div>
        <div class="block-panel">
            <div class="type-block" 
                data-qa-id="add-new-attribute-btn"
                *ngFor="let typeInfo of types;" 
                [ngClass]="{'gray-out': typeInfo.hidden}"
                (click)="moveToCreateAttribute(typeInfo.type)">
                <span class="title">
                    {{ translateKeys[typeInfo.title].title | translate }}
                </span>
                <i *ngIf="typeInfo.matIcon" class="material-icons material-icons-outlined">{{typeInfo.matIcon}}</i>
                <span *ngIf="typeInfo.oFondIcon" class="ofont-icon">{{typeInfo.oFondIcon}}</span>
                <span class="description">
                    {{ translateKeys[typeInfo.title].description | translate }}
                </span>
            </div>
        </div>
    </opti-logic-modal>
</div>
