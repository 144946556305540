import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportedCustomersComponent } from './components/imported-customers.component';
import { ComponentsModule } from "../../../components/components.module";
import { SettingsSharedModule } from '../settingsShared/settingsShared.module';
import { AddImportedCustomersModalComponent } from './components/add-imported-customers-modal/add-imported-customers-modal.component';
import { ColumnsMappingComponent } from './components/columns-mapping/columns-mapping.component';
import { ImportedCustomersService } from './services/imported-customers.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportLeadsLoaderModalComponent } from './components/import-customers-loader-modal/import-customers-loader-modal.component';
import { ImportResultModalComponent } from './components/import-result-modal/import-result-modal.component';
import { ImportedCustomersModalService } from './services/imported-customers-modal-service';
import { OptiModalModule } from '../../../modules/OptiModal.module';

@NgModule({
	declarations: [
		ImportedCustomersComponent,
		AddImportedCustomersModalComponent,
		ColumnsMappingComponent,
		ImportLeadsLoaderModalComponent,
		ImportResultModalComponent 
	],
	imports: [
		CommonModule,
		ComponentsModule,
		SettingsSharedModule,
		OptiModalModule.forRoot(),
		ReactiveFormsModule,
		FormsModule,
	],
	providers: [
		ImportedCustomersService,
		ImportedCustomersModalService
	]
})
export class ImportedCustomersModule { }
