<div *ngIf='isLoading' class = 'loading-wrapper'>
    <opti-loading [show]="isLoading" [size]="'md'" class='loading'></opti-loading>
</div>
<div *ngIf='!isLoading' class="preference-history-details">
    <div *ngIf='!isError'>
    <div class="selected-brand-name">
        <span class="brand-name">{{brandGroupName}}</span>
    </div>
    <div *ngIf="!isChannelBrandsConfigured" class="no-channels-configured-message">
        <span class="no-channels-message">{{'features.customer360.preference_center_modal.NO_CHANNELS_CONFIGURED' | translate}}</span>
    </div>
    <div class="audit-cards-container">
        <div class="audit-card" *ngFor="let channel of channels">
            <preference-audit-card  [preferences]="preferenceAudits[channel]"
                                    [topics]="topics"
                                    [reachability]="reachability[channel]"
                                    [consents]="consents[channel]"
                                    [channelId]="channel"
                                    [attributes]="attributes"
                                    (onExportClickEvent)="downloadFile($event)">
            </preference-audit-card>
        </div>
    </div>
    </div>
    <div *ngIf='isError'>
        <div class="not-loaded-wrap">
            <span class="not-loaded">{{'features.customer360.preference_center_modal.COULDNT_LOAD' | translate}}</span>
            <p class="contact-support">{{'features.customer360.preference_center_modal.CONTACT_SUPPORT' | translate}}</p>
        </div>
    </div>
</div>
