import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IModalConf, OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { BeefreeEditorService } from '../../../services/beefreeEditor.service';

@Component({
	selector: 'save-row-modal',
	templateUrl: './saveRowModal.component.html',
	styleUrls: [
		'./saveRowModal.component.scss',
		'../../../../../components/optiLogicModal/modals/successFailModal/successFailModal.component.scss'
	]
})
export class SaveRowModalComponent implements OnInit {
	@Input() brandId: string;
	@Input() brandAndType: string;
	@Input() tenantId: string;
	@Input() brandName: string;
	@Input() resolve;
	public form: FormGroup;
	modalConf: IModalConf;
	errorDuplicate: boolean = false;

	constructor(
		public bsModalRef: BsModalRef,
		private optiModalService: OptiLogicModalService,
		public translateService: TranslateService,
		public beeFreeService: BeefreeEditorService
	) {}

	ngOnInit() {
		this.setModalConf();
		this.form = new FormGroup({
			newRowName: new FormControl({ value: null, disabled: false }),
			isForAllBrands: new FormControl({ value: '1', disabled: false })
		});
	}

	public get newRowNameControl() {
		return this.form.get('newRowName');
	}
	public get isForAllBrands() {
		return this.form.get('isForAllBrands');
	}
	private createRowResolver() {
		let currentDate = new Date();
		let metadata = {
			name: this.newRowNameControl.value,
			id: this.tenantId + '_' + new Date().valueOf(),
			parentId: null,
			category: 'Custom Rows',
			dateCreated: currentDate,
			dateModified: currentDate,
			userId: '[~USER_ID~]',
			tags: [],
			tenantId: this.tenantId,
			brandId: this.brandId,
			brandName: this.brandName,
			saveForAllOrForOne: this.isForAllBrands.value
		};
		this.resolve(metadata);
		this.bsModalRef.hide();
	}
	private createDuplicateNameModal() {
		const modal = this.optiModalService.openModalMessage('sm', {
			message: this.translateService.instant('features.manage_templates.components.beefreeSavedRows.EXISTING_NAME_WARNING'),
			xButton: { action: () => this.bsModalRef.hide() },
			buttons: [
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-primary',
					label: this.translateService.instant('general.OVERWRITE'),
					action: () => {
						this.createRowResolver();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						modal.hide();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		});
	}
	private createRow() {
		this.beeFreeService
			.isBeefreeRowNameAlreadyUsed({
				name: this.newRowNameControl.value,
				brandId: this.brandId,
				saveForAllOrForOneFlag: this.isForAllBrands.value
			})
			.subscribe((res) => {
				if (res === 'OK') {
					this.createRowResolver();
				} else {
					this.createDuplicateNameModal();
				}
			});
	}

	public get newFolderNameControl() {
		return this.form.get('newFolderName');
	}

	setModalConf(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.beefreeSavedRows.TITLE'),
			xButton: { action: () => this.closeCancelModal() },
			buttons: [
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-primary',
					label: this.translateService.instant('general.SAVE'),
					action: () => {
						this.createRow();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						this.closeCancelModal();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
	closeCancelModal() {
		this.bsModalRef.hide();
		this.resolve(false);
	}
}
