import { Injectable } from '@angular/core';
import { Channel, SubMethod, SubMethodType, TemplatesMetadata } from '../models/metadataResponse';
import { RoutingConsts } from '../models/routing.consts';

@Injectable({
	providedIn: 'root'
})
export class SubMethodService {
	constructor() {}

	findSubmethodInnerId(metadata: TemplatesMetadata, subMethodId: number) {
		let subMethod = this.fetchSubMethod(metadata, subMethodId);
		return subMethodId ? subMethod.executionMethodInnerID : '';
	}

	fetchSubMethod(metadata: TemplatesMetadata, subMethodId: number) {
		const channelIds = Object.keys(metadata.channels);
		const channelArray = channelIds.map<Channel>((id) => metadata.channels[id]);
		const subMethod = channelArray.reduce((previousValue, currentValue) => {
			if (previousValue) {
				return previousValue;
			}
			return currentValue.brands.reduce((previousValue, currentValue) => {
				if (previousValue) {
					return previousValue;
				}
				return currentValue.subMethods.find((sm) => sm.subMethodId == subMethodId);
			}, null as SubMethod);
		}, null as SubMethod);
		return subMethod;
	}

	findSubmethodType(metadata: TemplatesMetadata, subMethodId: number): number {
		let subMethod = this.fetchSubMethod(metadata, subMethodId);
		return subMethodId ? subMethod.subMethodType : -1;
	}

	getSubMethodTypeText(subMethodType: SubMethodType, channelId: number): string {
		if (RoutingConsts.OPTIMOBILE_CHANNELS.includes(channelId)) {
			return channelId === RoutingConsts.OPTITEXT_WHATSAPP ? 'features.manage_templates.JOURNEYS' : 'features.manage_templates.TEMPLATES';
		}

		let text = '';
		switch (subMethodType) {
			case SubMethodType.TriggeredCustomerCampaigns:
				text = 'features.manage_templates.opti_tree.submethod_types.TRIGGERED';
				break;
			case SubMethodType.Transactional:
				text = 'features.manage_templates.opti_tree.submethod_types.TRANSACTIONAL';
				break;
			case SubMethodType.Optipush:
				text = 'features.manage_templates.opti_tree.submethod_types.OPTIPUSH';
				break;
			case SubMethodType.WebpagePopup:
				text = 'features.manage_templates.opti_tree.submethod_types.WEBPAGEPOPUP';
				break;
			default:
				text = 'features.manage_templates.opti_tree.submethod_types.SCHEDULED';
				break;
		}
		return text;
	}
}
