<div class="template-script-guard-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div>
      <p>{{ "features.manage_templates.components.templateScriptGuard.DISCLAIMER" | translate }}</p>
      <p>{{ "features.manage_templates.components.templateScriptGuard.APPROVAL_QUESTION" | translate }}</p>
    </div>
    <div class="recaptcha-container">
      <re-captcha (resolved)="reCaptchaResolved($event)" siteKey="{{ siteKey }}"></re-captcha>
    </div>
  </opti-logic-modal>
</div>
