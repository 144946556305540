<div class="opti-modal">
  <div class="opti-modal-window">
    <div class="panel panel-default">
      <div class="panel-body">
        <div *ngIf="templatesInUse.length === 1">
          <p>{{ "features.manage_templates.messages.FOLDER_IN_USE_ONE_TEMPLATE" | translate }}</p>
          <span>
            <b>{{ templatesInUse[0].templateName }}</b>
          </span>
        </div>
        <div *ngIf="templatesInUse.length > 1">
          <p>{{ "features.manage_templates.messages.FOLDER_IN_USE_MANY_TEMPLATE" | translate }}</p>
          <ul *ngFor="let template; in: templatesInUse.slice(0, 3)">
            <li>{{ template.templateName }}</li>
          </ul>
          <div *ngIf="templatesInUse.length > 3">
            <span>{{ "general.AND" | translate }}</span>
            <span>{{ templatesInUse.length - 3 }}</span>
            <span>{{ "general.MORE" | translate }}</span>
          </div>
        </div>
        <button type="button" (click)="close()" class="btn btn-primary pull-right" type="button">{{ "general.OK" | translate }}</button>
      </div>
    </div>
  </div>
</div>
