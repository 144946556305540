import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageTemplatesPageComponent } from './manageTemplatesPage/manageTemplatesPage.component';
import { loadRemoteModule } from 'src/app/utils/remoteModules/dynamic-federation';
import { OptimailEditorComponent } from './editors/optimailEditor/optimailEditor.component';
import { MasterDetailContainerComponent } from './masterDetailContainer/masterDetailContainer.component';
import { TemplateEditorGuard } from './editors/templateEditor.guard';
import { RoutingConsts } from './models/routing.consts';
import { OptimobileEditorComponent } from './editors/optimobileEditor/optimobileEditor.component';

const microFrontendOutletName = 'mfeditor';

const routes: Routes = [
	{
		path: '',
		component: ManageTemplatesPageComponent,
		children: [
			{
				path: '',
				component: MasterDetailContainerComponent,
				outlet: microFrontendOutletName
			}
		]
	},
	{
		path: `${RoutingConsts.LOBBY}/:id`,
		component: ManageTemplatesPageComponent,
		children: [
			{
				path: '',
				component: MasterDetailContainerComponent,
				outlet: microFrontendOutletName
			}
		]
	},
	{
		path: RoutingConsts.OPTIMAIL,
		component: OptimailEditorComponent,
		runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
		canDeactivate: [TemplateEditorGuard]
	},
	{
		path: RoutingConsts.WEB_PAGE_POPUP,
		component: OptimailEditorComponent,
		runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
		canDeactivate: [TemplateEditorGuard]
	},
	{
		path: RoutingConsts.OPTIMOBILE,
		component: OptimobileEditorComponent
	}
	/* examples below are kept for future microfrontends integration */
	/*  {
	path: "react",
	component: ManageTemplatesPageComponent,
	children: [
	  {
		path: "",
		component: WebComponentWrapper,
		data: {
		  remoteEntry: "http://localhost:8081/remoteEntry.js",
		  remoteName: "counter",
		  exposedModule: "./Counter",

		  elementName: "react-element",
		} as WebComponentWrapperOptions,
		outlet: microFrontendOutletName,
	  },
	],
  },
  {
	path: "optimail",
	component: ManageTemplatesPageComponent,
	children: [
	  {
		path: "",
		loadChildren: () =>
		  loadRemoteModule({
			remoteEntry: "http://localhost:4201/remoteEntry.js",
			remoteName: 'optimail',
			exposedModule: "BeefreeEditorModule",
		  }).then((m) => m["BeefreeEditorModule"]),
		outlet: microFrontendOutletName,
	  },
	],
  }, */
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: []
})
export class ManageTemplatesRoutingModule {}
