import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { TemplateContextService } from '../../services/templateContext.service';
import { SearchContextService } from '../../services/searchContext.service';

@Component({
	selector: 'folder-popup-button',
	templateUrl: './folderPopupButton.component.html',
	styleUrls: ['./folderPopupButton.component.scss']
})
export class FolderPopupButtonComponent implements OnInit {
	@ViewChild('folderOverlayButton') folderOverlayButtonElement: ElementRef<HTMLInputElement>;

	@Input() navigateExternally: boolean;
	@Output() navigateToTemplate: EventEmitter<{
		channelId: number;
		id: number;
		folderId: number;
		typeId: number;
		otherEditor: boolean;
		search: string;
	}> = new EventEmitter();

	showfolderOverlay: boolean;
	isSearchActive: boolean;

	constructor(private searchContext: SearchContextService, private context: TemplateContextService, private cd: ChangeDetectorRef) {}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.folderOverlayButtonElement && !this.folderOverlayButtonElement.nativeElement.contains(event.target)) {
			this.showfolderOverlay = false;
			this.cd.detectChanges();
		}
	}

	@HostListener('window:blur', ['$event'])
	onWindowBlur(event: any): void {
		this.showfolderOverlay = false;
		this.cd.detectChanges();
	}

	ngOnInit(): void {
		this.context.queryTemplateParams.pipe(first()).subscribe((params) => {
			this.isSearchActive = this.searchContext.isSearchTextValid(params.search);
		});
	}

	showHideOverlay(): void {
		this.showfolderOverlay = !this.showfolderOverlay;
		this.cd.detectChanges();
	}

	templateNavigate(params: {
		channelId: number;
		id: number;
		folderId: number;
		typeId: number;
		otherEditor: boolean;
		search: string;
	}): void {
		this.navigateToTemplate.emit(params);
		this.showHideOverlay();
	}
}
