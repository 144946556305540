import {Directive} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

export function nicknameValidator() : ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
        var patt = new RegExp('^[A-Za-z0-9_+-\.]+$');
        var pattNotEmail = new RegExp('^[a-z0-9_+-\.!#$\'\^\`~@]+@[a-z0-9.-]+\.[a-z]{2,4}$');
        if (!patt.test(control.value)) {
            return { 
                invalidNickname: true 
            };
        }
        if (pattNotEmail.test(control.value)) {
            return { 
                invalidAuth0Email: true 
            };
        }
        return null;
    };
  }


@Directive({
    selector: '[nicknameValidation]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: NicknameValidationDirective,
        multi: true
    }]
})
export class NicknameValidationDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any; } {
        return nicknameValidator();

    }

}