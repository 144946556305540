import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TemplateContextService } from '../../../services/templateContext.service';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { TemplateDetails } from '../../../models/templateDetails';
import { PersonalizationModalComponent } from '../../dialogs/personalizationModal/personalizationModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { UtmParamsComponent } from '../../dialogs/utmParamsModal/utmParams.component';
import { SearchItem, SearchListConfig } from '../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { first, skipWhile, takeUntil } from 'rxjs/operators';
import { OslValueType } from '../../../../../components/optiSearchList/models/oslValueType.enum';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { SubMethodService } from '../../../services/subMethodService';
import { SubMethodDefaultValue, SubMethodDefaultValueId } from '../../../models/metadataResponse';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { PreferenceCenterService } from 'src/app/features/settings/preferenceCenter/services/preferenceCenter.service';
import { Topic } from 'src/app/features/settings/preferenceCenter/models/topic.model';
import { TemplateTopic } from '../../../models/templateTopic';

@Component({
	selector: 'template-metadata',
	templateUrl: 'templateMetadataPanel.component.html',
	styleUrls: ['templateMetadataPanel.component.scss']
})
export class TemplateMetadataPanelComponent implements OnInit, OnChanges, OnDestroy {
	public template: TemplateDetails = {} as TemplateDetails;

	public dropdownsConfigBase: SearchListConfig = {
		keyProperty: 'id',
		valueProperty: 'value',
		isMultiSelect: false,
		itemNameTranslateKey: 'Item',
		valueType: OslValueType.Key,
		placeholderTranslateKey: this.translateService.instant('general.SELECT')
	};

	public dropdownsConfigOptional: SearchListConfig = {
		...this.dropdownsConfigBase,
		placeholderTranslateKey: this.translateService.instant('general.SELECT')
	};

	public fromEmailList: SearchItem[] = [];
	public replyToList: SearchItem[] = [];
	public preferenceGroupList: SearchItem[] = [];
	public isSuppressionPreferencesEnabled: boolean;
	private unsubscribe$ = new Subject<any>();
	private subMethodType: number;
	isTopicFieldEnabled: boolean;
	public allSelectableTopics: IListItem[] = [];
	public selectedTopics: string[] = [];
	private channelId: number;
	private brandId: string;
	private originalTemplateTopics: TemplateTopic[] = [];

	private subscriptions = new Subscription();

	@Input() froalaTemplate: any;

	constructor(
		private templateContext: TemplateContextService,
		private modalService: OptiLogicModalService,
		public translateService: TranslateService,
		public manageTemplatesService: ManageTemplatesService,
		public subMethodService: SubMethodService,
		private preferenceCenterService: PreferenceCenterService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.templateContext.current
				.pipe(
					skipWhile((value) => value === null),
					takeUntil(this.unsubscribe$)
				)
				.subscribe((template) => {
					if (this.froalaTemplate) {
						this.initDropdownsData(template);
						return;
					}

					this.template = template;
					this.initDropdownsData(this.template);

					if (this.template.subMethodType) {
						this.subMethodType = this.template.subMethodType;
						return;
					}

					this.subscriptions.add(
						this.manageTemplatesService
							.getMetadata()
							.pipe(first())
							.subscribe((metadata) => {
								this.subMethodType = this.subMethodService.findSubmethodType(metadata, this.template.subMethodId);
							})
					);
				})
		);
		this.subscriptions.add(this.templateContext.queryTemplateParams.subscribe(params => {
			if (this.channelId !== +params.channelId || this.brandId !== params.brandId){
				this.channelId = +params.channelId;
				this.brandId = params.brandId;
				this.preferenceCenterService.isPreferenceEnabled().subscribe(isEnabled => {
					this.isTopicFieldEnabled = isEnabled;
					if (!isEnabled) return;
					this.manageTemplatesService.getMetadata().subscribe(metadata => {
						const innerId = this.subMethodService.findSubmethodInnerId(metadata, +params.typeId);
						this.preferenceCenterService.getBrandGroups(+params.channelId, innerId).subscribe(brandGroups => {
							forkJoin(brandGroups.map(bg => this.preferenceCenterService.getTopics(bg.id))).subscribe(topics => {
								const allTopics = topics.reduce((previous: Topic[], current: Topic[]) => {
									current.forEach(t => previous.push(t));
									return previous;
								}, []);
								const allTopicsItems = allTopics.map<IListItem>(t => {
									return { id: t.id, name: t.name };
								});
								if (JSON.stringify(allTopicsItems) !== JSON.stringify(this.allSelectableTopics)) {
									this.allSelectableTopics = allTopicsItems;
								}
							});
						});
					});
				});
			}
		}));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.froalaTemplate !== undefined) {
			this.template = this.froalaTemplate;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.subscriptions?.unsubscribe();
	}

	openAdvancedSubjectModal(): void {
		this.modalService.open(PersonalizationModalComponent, 'md', <ModalOptions<any>>{
			initialState: {
				title: 'features.manage_templates.components.subject.TITLE',
				subMethodType: this.template.subMethodType ?? this.subMethodType,
				text: this.template.subject,
				save: (text: string) => (this.template.subject = text),
				showConditionalLanguage: true
			}
		});
	}

	openAdvancedPreviewTextModal(): void {
		this.modalService.open(PersonalizationModalComponent, 'md', <ModalOptions<any>>{
			initialState: {
				title: 'features.manage_templates.optimail.PREHEADER',
				subMethodType: this.template.subMethodType ?? this.subMethodType,
				text: this.template.preheader,
				save: (text: string) => (this.template.preheader = text),
				showConditionalLanguage: true
			}
		});
	}

	openAdvancedFromNameModal(): void {
		this.modalService.open(PersonalizationModalComponent, 'md', <ModalOptions<any>>{
			initialState: {
				title: 'features.manage_templates.components.fromName.TITLE',
				subMethodType: this.template.subMethodType ?? this.subMethodType,
				text: this.template.fromName,
				save: (text: string) => (this.template.fromName = text)
			}
		});
	}

	editUTMClick(): void {
		if (!this.template) {
			return;
		}

		this.modalService.open(UtmParamsComponent, 'md', <ModalOptions<any>>{
			title: 'UTM Params',
			ignoreBackdropClick: true,
			class: '',
			initialState: { froalaTemplate: this.froalaTemplate }
		});
	}

	updateIsDirtyChange(): void {
		this.templateContext.updateIsCurrentTemplateDirty();
	}

	selectedTopicsChanged($event) {
		this.selectedTopics = $event;
		
		this.template.templateTopics = this.selectedTopics.map(topicId => {
			const existingTopic = this.originalTemplateTopics?.find(t => t.topicId === topicId);
			return existingTopic ?? { topicId, templateUuid: this.template.uuid } as TemplateTopic;
		});
	}

	private initDropdownsData(template: TemplateDetails): void {
		this.manageTemplatesService.getEmailData().subscribe(emailData => {
			this.fromEmailList = this.getPreDefinedList(emailData.preDefinedEmailList[template.subMethodId]);
			this.replyToList = this.getPreDefinedList(emailData.preDefinedReplyToEmailList[template.subMethodId]);
			this.updateTemplateDefaultEmails(template, this.replyToList, this.fromEmailList);
		});

		this.manageTemplatesService.getMetadata().subscribe(metadata => {
			const subMethod = this.subMethodService.fetchSubMethod(metadata, template.subMethodId);
			if (subMethod) {
				const suppressionParam = Object.values(subMethod.defaultValues).find((x) => x.name === 'IsSuppressionPreferences');
				if (suppressionParam) {
					this.isSuppressionPreferencesEnabled = suppressionParam.value == '1';
				}
				this.updateTemplateDefaultName(template, subMethod.defaultValues);
			}
		});

		this.manageTemplatesService.getSuppressionGroups().subscribe((res) => {
			this.preferenceGroupList = this.getPreDefinedList(res.preDefinedsuppressionGroupList[template.subMethodId]);
		});

		if (template?.templateTopics) {
			this.originalTemplateTopics = template.templateTopics;
			this.selectedTopics = template.templateTopics.map(t => t.topicId);
		}
	}

	private updateTemplateDefaultName(template: TemplateDetails, defaultValues: { [id: string]: SubMethodDefaultValue }) {
		if (template.templateId !== 0) {
			return;
		}
		if (!template.fromName) {
			const defaultFromName = defaultValues[SubMethodDefaultValueId.FROM_NAME];
			if (defaultFromName) {
				template.fromName = defaultFromName.value;
			}
		}
	}

	private updateTemplateDefaultEmails(template: TemplateDetails, replyToList: SearchItem[], fromEmailList: SearchItem[]) {
		if (template.templateId !== 0) {
			return;
		}
		if (!template.replyTo && replyToList && replyToList.length > 0) {
			template.replyTo = replyToList[0].id;
		}

		if (!template.fromEmail && fromEmailList && fromEmailList.length > 0) {
			template.fromEmail = fromEmailList[0].id;
		}
	}

	private getPreDefinedList(rawList): SearchItem[] {
		if (rawList) {
			return rawList.map((value) => {
				return {
					id: value.id.toString(),
					value: value.value
				} as SearchItem;
			});
		} else {
			return [];
		}
	}
}
