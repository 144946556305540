import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { ErrorModalService } from '../../../services/errorModal.service';
import { FolderTemplatePreview } from '../../../models/folderTemplate';
import { TemplateKeysModel } from '../../../models/templateKeysModel';

export interface MoveTemplateInitialState {
	templates?: TemplateKeysModel[];
	template?: FolderTemplatePreview;
	callback: (destinationSubMethodId: number, destinationFolderId: number) => void;
	isInUse?: boolean;
	selectedSubMethodId?: number;
	selectedFolderId?: number | null;
	isTemplatesMoving?: (isMoving: boolean) => void;
	isBatchAction?: boolean;
}

@Component({
	selector: 'move-template-modal',
	templateUrl: './moveTemplateModal.component.html',
	styleUrls: ['./moveTemplateModal.component.scss']
})
export class MoveTemplateModalComponent implements OnInit, MoveTemplateInitialState {
	@Input() isInUse: boolean;
	template: FolderTemplatePreview;
	callback: (destinationSubMethodId: number, destinationFolderId: number) => void;
	isTemplatesMoving: (isMoving: boolean) => void;
	modalConf: IModalConf;
	selectedSubMethodId: number;
	selectedFolderId: number | null;
	isSavingTemplate: boolean = false;

	templates: FolderTemplatePreview[];
	public isBatchAction: boolean;
	public initialFolderId: number;

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private templateService: ManageTemplatesService,
		private errorModalService: ErrorModalService
	) {}

	ngOnInit(): void {
		this.initModalConfig();
		if (!this.isBatchAction) {
			this.selectedSubMethodId = this.template.subMethodId;
			this.selectedFolderId = this.template.folderId;
		}
		this.initialFolderId = this.selectedFolderId;
	}

	public setFolderId($event) {
		this.selectedFolderId = $event;
	}

	public setSubMethodId($event) {
		this.selectedSubMethodId = $event;
	}

	public canMove(): boolean {
		if (!this.isBatchAction) {
			return !!this.selectedSubMethodId && !!this.selectedFolderId && this.selectedFolderId != +this.template.folderId;
		}
		return !!this.selectedSubMethodId && !!this.selectedFolderId && this.selectedFolderId != this.initialFolderId;
	}

	private moveTemplate(): void {
		this.isSavingTemplate = true;
		this.templateService
			.moveFolderOrTemplate({
				nodeId: this.template.templateID,
				isFolder: false,
				parentId: this.selectedFolderId,
				subMethodId: this.selectedSubMethodId
			})
			.subscribe((result) => {
				this.isSavingTemplate = false;
				this.bsModalRef.hide();

				if (result.isSuccess) {
					this.callback(this.selectedSubMethodId, this.selectedFolderId);
				} else {
					this.errorModalService.openErrorModal(
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.MOVE_TEMPLATES'),
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_MOVE_TEMPLATE')
					);
				}
			});
	}

	private moveBatchOfTemplates(): void {
		this.isSavingTemplate = true;
		this.isTemplatesMoving(true);
		this.templateService
			.moveBatchOfTemplates({
				templateIds: this.templates.map((t) => t.templateID),
				newFolderId: this.selectedFolderId,
				subMethodId: this.selectedSubMethodId
			})
			.subscribe((response) => {
				this.isSavingTemplate = false;
				this.isTemplatesMoving(false);
				this.bsModalRef.hide();

				if (response.isSuccess) {
					this.callback(this.selectedSubMethodId, this.selectedFolderId);
				} else {
					this.errorModalService.openErrorModal(
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.MOVE_TEMPLATES'),
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_MOVE_TEMPLATE')
					);
				}
			});
	}

	private initModalConfig(): void {
		const title =
			this.templates && this.templates.length > 1
				? 'features.manage_templates.components.move_template_modal.TITLE_PLURAL'
				: 'features.manage_templates.components.move_template_modal.TITLE';
		this.modalConf = {
			title: this.translateService.instant(title),
			buttons: [
				{
					isDisabled: () => !this.canMove() || this.isSavingTemplate,
					isLoading: () => this.isSavingTemplate,
					loadingText: null,
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.components.move_template_modal.OK_BTN'),
					action: () => {
						if (!this.isBatchAction) {
							this.moveTemplate();
							this.bsModalRef.hide();
							return;
						}
						this.moveBatchOfTemplates();
						this.bsModalRef.hide();
					}
				},
				{
					isDisabled: () => false,
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.move_template_modal.CANCEL_BTN'),
					action: () => this.bsModalRef.hide()
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
}
