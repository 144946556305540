import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { IModalButtonConf, IModalConf, OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { ApiKeyManagementService } from "../apiKeyManagement.service";
import { FullOptiApiKey, OptiApiKey } from "../models/optiApiKey.model";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { first } from "rxjs/operators";

@Component({
    selector: 'update-api-key-popup',
    templateUrl: './updateApiKeyPopup.component.html',
    styleUrls: ['./updateApiKeyPopup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class UpdateApiKeyPopupComponent implements OnInit {

    @Input() recommendToBlock: boolean;
    @Input() apiKey: OptiApiKey;
    @Input() lastUsed: string;
    @Input() apiKeys: FullOptiApiKey[];
    @Output() updateExistingApiKeysEvent = new EventEmitter<OptiApiKey[]>();

    public modalConf: IModalConf;
    public translateKeys = translateKeys;
    public loading: boolean = false;

    constructor(private bsModalRef: BsModalRef, 
        private translateService: TranslateService,
        private apiKeyManagementService: ApiKeyManagementService,
        private modalService: OptiLogicModalService) {}

    ngOnInit() {
        this.setModalConf();
    }

    openFailedModal(title: string, errorMsg: string) {
        this.loading = false;
        this.bsModalRef.hide();
        this.modalService.openOptiLogicModal('sm', {
                title: title,
                html: errorMsg,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant('general.OK'),
                    action: () => this.bsModalRef.hide(),
                    dataQaId: 'errorUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    getButtonsConf(): IModalButtonConf[] {
        return [
            {
                isDisabled: () => { return !this.apiKey.IsActive; },
                class: 'btn-primary',
                label: this.translateService.instant(this.translateKeys.deactivateUserBtn),
                action: () => { return this.deactivateUser(); },
                dataQaId: 'deactivateUserBtn'
            },
            {
                isDisabled: () => { return false; },
                class: 'btn-default',
                label: this.translateService.instant(this.translateKeys.cancel),
                action: () => { this.bsModalRef.hide(); },
                dataQaId: 'cancelModalBtn'
            }
        ];
    }

    private deactivateUser() {
        this.loading = true;
        this.apiKey.IsActive = false;
        var title = this.translateService.instant(this.translateKeys.titleDeactivate);
        var errorMassage = this.translateService.instant(this.translateKeys.errorDeactivateUser);
        this.apiKeyManagementService.updateApiKeyAsync(this.apiKey)
            .pipe(first()).subscribe(async (response: AjaxResponse<string>) => {
                 await (!response.isSuccess) ? this.openFailedModal(title, errorMassage) : this.bsModalRef.hide();
            }, (_) => this.openFailedModal(title, errorMassage));
    }

    private deleteUser() {
        this.loading = true;
        var title = this.translateService.instant(this.translateKeys.title);
        var errorMassage = this.translateService.instant(this.translateKeys.errorDeleteUser);
        this.apiKeyManagementService.deleteApiKeyAsync(this.apiKey.Nickname)
            .pipe(first()).subscribe(async (response: AjaxResponse<string>) => {
                 await (!response.isSuccess)
                    ? this.openFailedModal(title, errorMassage) 
                    : this.successDeleteUser();
            }, (_) => this.openFailedModal(title, errorMassage));
    }

    private successDeleteUser() {
        // updating the existing api keys table in API Key Management page 
        const apiKeysUpdated = this.apiKeys.filter(apiKey => apiKey.Nickname !== this.apiKey.Nickname);        
        this.updateExistingApiKeysEvent.emit(apiKeysUpdated);
        this.loading = false;
        this.bsModalRef.hide();
    }

    setModalConf(): void {
        this.modalConf = {
            title: this.translateService.instant(this.translateKeys.title),
            buttons: this.getButtonsConf(),
            xButton: {
                action: () => { this.bsModalRef.hide(); }
            },
            linkConf: {
                isDisabled: () => { return false; },
                class: 'btn-delete',
                label: this.translateService.instant(this.translateKeys.deleteUserBtn),
                action: () => { return this.deleteUser(); },
                dataQaId: 'deleteUserBtn'
            },
            withoutMinHeight: true
        };
    }
}

const translateKeys = {
    title: 'features.user_settings.body.apiKeyManagement.updateApiKey.TITLE',
    titleDeactivate: 'features.user_settings.body.apiKeyManagement.updateApiKey.TITLE_DEACTIVATE',
    blockUserMsg: 'features.user_settings.body.apiKeyManagement.updateApiKey.BLOCK_USER_MSG',
    deactivateBlockUserMsg: 'features.user_settings.body.apiKeyManagement.updateApiKey.DEACTIVATE_BLOCK_USER_MSG',
    deleteUserMsg: 'features.user_settings.body.apiKeyManagement.updateApiKey.DELETE_USER_MSG',
    deleteUserBtn: 'features.user_settings.body.apiKeyManagement.updateApiKey.DELETE_USER_BTN',
    deactivateUserBtn: 'features.user_settings.body.apiKeyManagement.updateApiKey.DEACTIVATE_USER_BTN',
    cancel: 'features.user_settings.body.attributes.addAttribute.CANCEL',
    nickNameHeader: 'features.user_settings.body.apiKeyManagement.updateApiKey.NICKNAME',
    lastLoginHeader: 'features.user_settings.body.apiKeyManagement.updateApiKey.LAST_LOGIN',
    errorDeactivateUser: 'features.user_settings.body.apiKeyManagement.updateApiKey.ERROR_DEACTIVATE_USER',
    errorDeleteUser: 'features.user_settings.body.apiKeyManagement.updateApiKey.ERROR_DELETE_USER',
}
