<div class="metrics">
    <opti-metric
        *ngFor="let metric of metrics"
        data-qa-id="ImpactMetric"
        class="metric"
        [titleText]="metric.TitleText"
        [metricValue]="metric.MetricValue"
        [titleTooltip]="metric.TitleTooltip"
        [metricClass]="'h2'"
        [valueNumberOfLines]="1">
    </opti-metric>
</div>