<opti-logic-modal [modalConf]="modalConf">
  <div class="warning-block">
    <p class="warning-text">
      {{ translateService.instant("features.manage_templates.optimail.contentTags.UPDATE_WARNING") }}
    </p>
  </div>
  <div>
    {{ this.translateService.instant("features.manage_templates.optimail.contentTags.SURE_PROCEED") }}
  </div>
</opti-logic-modal>
