import { CanDeactivate, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AngularJsContainer } from "src/app/components/angularJsContainer/AngularJsContainer.component";
import { CanDeactivateStateService } from "./canDeactivateState.service";
import { TranslateService } from "@ngx-translate/core";

/*
  WARNING!!!
  Please, do not use it if you need a guard for regular routes that are not related to angularJS app routing.

  This is not a regular implementation of the guard for this purpose. 
  The main reason why we use a service here is because of integration with angularJS app.
  There is no way to read a component state because all components are downgraded to be used in 
  angularJS app and they are wrapped in AngularJsContainer which doesn't provide any information about the component state.
*/
export class LeavingAngularJsPageGuard {
  static forKey(routeKey: string, confirmationMsgTranslateKey: string) {
    @Injectable({ providedIn: "root" })
    class CanDeactivateRouteCheck implements CanDeactivate<AngularJsContainer> {
      constructor(
        private сanDeactivateStateService: CanDeactivateStateService,
        private translate: TranslateService
      ) {}

      canDeactivate(component: AngularJsContainer): boolean {
        let canDeactivate =
          this.сanDeactivateStateService.canDeactivate(routeKey);

        if (!canDeactivate) {
          const confirmMessage = this.translate.instant(confirmationMsgTranslateKey);
          const confirmResult = confirm(confirmMessage);
          if (confirmResult) {
            // clean canDeactivate state function
            this.сanDeactivateStateService.setCanDeactivateFunc(routeKey, null);
          }
          canDeactivate = confirmResult;
        }

        return canDeactivate;
      }
    }

    return CanDeactivateRouteCheck;
  }
}
