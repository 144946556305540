import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, Input } from "@angular/core";
import * as moment from 'moment';

export function maxDateValidator(maxDate: Date) {

    return (control: AbstractControl): ValidationErrors | null => {
        const d = control.value;
        return maxDate && moment(d).isAfter(maxDate, 'day') ? { maxDate: true } : null;
    };
}

@Directive({
    selector: "[maxDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MaxDateValidatorDirective,
        multi: true
    }]
})
export class MaxDateValidatorDirective implements Validator {
    @Input() maxDate: Date;

    validate(c: AbstractControl): { [key: string]: any; } {
        return maxDateValidator(this.maxDate);
    }
}