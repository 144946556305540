<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="settings-footer-btn-container">
        <div>
            <button class="btn btn-default" (click)="onCancelClick()" data-qa-id="cyAddNewWebhookCancelButtonLoader">
                {{'general.CANCEL' | translate }}
            </button>
        </div>
        <div class="save-settings-btn-container" data-qa-id="cysave-settings-btn-container">
            <loading-button
                    data-qa-id="cySaveButtonLoader"
                    [showLoader]="isSaving"
                    (onClick)="onSaveClicked()"
                    [isDisabled]="disabled"
                    [buttonCssClass]="'btn btn-primary settings-save-btn'"
                    [loadingCssClass]="'saving-loader'"
                    [loadingText]="savingText"
                    [buttonText]="saveText">
            </loading-button>
        </div>
    </div>
</div>