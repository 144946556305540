<div class="preference-history-header">
    <div class="customer-id-container">
        <span class="customer-id-title">{{'features.customer360.consent_modal.TITLE' | translate}}: {{clientCustomerId}}</span>

        <button class="close-button btn btn-link btn-link-default" (click)="close()">
            <span class="material-icons" aria-hidden="true">close</span>
        </button>
    </div>
    <div class="date-picker-container">
        <span class="date-picker-label">{{'features.customer360.preference_center_modal.DATE_PICKER_LABEL' | translate}}</span>
        <div class="date-picker-a">
            <date-picker
                    [(ngModel)]="date"
                    [maxDate]="maxDate"
                    (ngModelChange)="onDateChanged($event)">
            </date-picker>
        </div>
        <button class="date-picker-arrow-btn arrow-back" (click)="setOneDayBackward()">
            <span class="material-icons">arrow_back</span>
        </button>
        <button class="date-picker-arrow-btn arrow-forward" (click)="setOneDayForward()">
            <span class="material-icons">arrow_forward</span>
        </button>
        <button class="date-picker-today-bt" (click)="setDateNow()">
            <span>{{'features.customer360.preference_center_modal.TODAY' | translate}}</span>
        </button>
    </div>
</div>
