import { IGranularKPIFields } from './../../features/customer360/customer360.models';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IModalConf } from '../optiLogicModal/optiLogicModal.service';
import { SearchItem, SearchListConfig } from '../optiSearchList/optiSearchListComponent/optiSearchList.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'item-selector-modal',
  templateUrl: 'itemSelectorModal.component.html',
  styleUrls: ['./itemSelectorModal.component.scss']
})
export class ItemSelectorModal implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() granularKPIs: IGranularKPIFields[];
  @Input() searchListConfig: SearchListConfig;
  @Input() submitBtnText: string;
  @Input() onSubmit: (kpiFields: IGranularKPIFields) => void;
  @Input() onClose: () => void;

  public translateKeys = translateKeys;

  public form: FormGroup;
  public modalConf: IModalConf;
  public isLoading: boolean = false;

  private subs: Subscription[] = [];

  constructor(private bsModalRef: BsModalRef) { }

  async ngOnInit() {
    this.isLoading = true;

    this.createForm();
    this.format.disable();
    this.tooltip.disable();
    this.initFormSub();
    this.setModalConf();

    if (this.granularKPIs.length >= 1) {
      this.selectedItem.setValue([this.granularKPIs[0]]);
    }

    this.isLoading = false;
  }

  ngOnDestroy(): void {
      this.subs.forEach(x => x.unsubscribe());
  }

  get selectedItem(): FormControl {
    return this.form.get('selectedItem') as FormControl;
  }

  get tooltip(): FormControl {
    return this.form.get('tooltip') as FormControl;
  }

  get actionAlias(): FormControl {
    return this.form.get('actionAlias') as FormControl;
  }

  get format(): FormControl {
    return this.form.get('format') as FormControl;
  }

  private createForm() {
    this.form = new FormGroup({
      selectedItem: new FormControl('', Validators.required),
      tooltip: new FormControl('', Validators.required),
      actionAlias: new FormControl('', Validators.required),
      format: new FormControl('', Validators.required),
    });
  }

  private initFormSub() {
    this.subs = [
      this.selectedItem.valueChanges.subscribe((item: IGranularKPIFields[]) => {
        if (item[0]){
          this.tooltip.setValue(item[0].Tooltip);
          this.format.setValue(item[0].Format);
        }
      })
    ]
  }

  private setModalConf(): void {
    this.modalConf = {
        title: this.title,
        xButton: {action: () => this.closeModal()},
        buttons:[
          {
              isDisabled: () => this.form.invalid,
              class: 'btn btn-primary',
              label: this.submitBtnText,
              action: this.onSubmitBtnClick.bind(this),
          },
          {
              isDisabled: () => false,
              class: 'btn-default',
              label: 'Cancel',
              action: this.closeModal.bind(this),
          }
      ],
        withScale: false,
    };
  }

  private closeModal() {
    this.bsModalRef.hide()
    this.onClose();
  }

  private onSubmitBtnClick() {
    let kpiFields = this.selectedItem.value[0] as IGranularKPIFields;
    kpiFields.ActionAlias = this.actionAlias.value;
    this.onSubmit(kpiFields);
    this.closeModal();
  }
}

const translateKeys = {
  ACTION_PHACEHOLDER: 'features.customer360.body.customer_activity_trend.addKpisModal.ACTION_PLACEHOLDER',
}