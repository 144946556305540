<div class="filter-base">
    <label class="filter-label">
        <input
            type="checkbox"
            name="notPublished"
            [(ngModel)]="isNotPublished"
            (ngModelChange)="updateFilter()"
        />
        <colored-tag [coloredTagType]="colorItems['NotPublished']">Not Published</colored-tag>
    </label>
    <label class="filter-label">
        <input
            type="checkbox"
            name="publishing"
            [(ngModel)]="isPublishing"
            (ngModelChange)="updateFilter()"
        />
        <colored-tag [coloredTagType]="colorItems['Publishing']">Publishing</colored-tag>
      </label>
    <label class="filter-label">
        <input
            type="checkbox"
            name="published"
            [(ngModel)]="isPublished"
            (ngModelChange)="updateFilter()"
        />
        <colored-tag [coloredTagType]="colorItems['Published']">Published</colored-tag>
    </label>
  </div>