import { Directive, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, Subscriber } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ManageTemplatesService } from '../../services/manageTemplates.service';

@Directive({
	selector: '[templateNameValidator]',
	providers: [
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: TemplateNameValidatorDirective,
			multi: true
		}
	]
})
export class TemplateNameValidatorDirective implements AsyncValidator {
	@Input() templateId!: number;
	@Input() subMethodId!: number;

	private valueQueue = new BehaviorSubject<string>('');
	private validationResponse = new Subject<ValidationErrors | null>();

	constructor(private manageTemplatesService: ManageTemplatesService) {
		this.valueQueue.pipe(debounceTime(2000)).subscribe(value => {
			this.manageTemplatesService
				.isTemplateNameUnique({ templateName: value, templateId: this.templateId, subMethodId: this.subMethodId })
				.subscribe((response) => this.validationResponse.next(response ? null : { nameUnique: 'features.manage_templates.messages.TEMPLATE_NAME_MUST_BE_UNIQUE' }));
		});
	}

	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		if (!control.value) {
			return of(null);
		}

		return new Observable<ValidationErrors | null>((observer: Subscriber<ValidationErrors | null>) => {
			this.validationResponse.subscribe((res) => {
				observer.next(res);
				observer.complete();
			});
			this.valueQueue.next(control.value);
		});;
	}
}
