<opti-logic-modal [modalConf]="modalConf">
  <div class="utmModal">
    <div class="row">
      <h3 class="col-md-8">{{ translateService.instant("features.manage_templates.components.utmParams.GENERATED_URL_PLACEHOLDER") }}</h3>
    </div>
    <div class="row">
      <label for="input_utm_source" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_SOURCE") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_source"
            id="input_utm_source"
            (focus)="focused_source = true"
            (blur)="focused_source = false"
            data-qa-id="UTMSourceInput"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_SOURCE') }}"
            [(ngModel)]="utm.utms.utm_source" />
          <button type="button" (click)="isLoadSource = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadSource">
            <personalization-tags-container
              [subMethodId]="this.subMethodType"
              (chosen)="onTagChosenForUtmSource($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_source" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <label for="input_utm_medium" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_MEDIUM") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_medium"
            id="input_utm_medium"
            (focus)="focused_medium = true"
            (blur)="focused_medium = false"
            data-qa-id="UTMMediumInput"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_MEDIUM') }}"
            [(ngModel)]="utm.utms.utm_medium" />
          <button type="button" (click)="isLoadMedium = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadMedium">
            <personalization-tags-container
              [subMethodId]="subMethodType"
              (chosen)="onTagChosenForUtmMedium($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_medium" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <label for="input_utm_campaign" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_CAMPAIGN") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_campaign"
            id="input_utm_campaign"
            (focus)="focused_campaign = true"
            (blur)="focused_campaign = false"
            data-qa-id="UTMCampaignInput"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_CAMPAIGN') }}"
            [(ngModel)]="utm.utms.utm_campaign" />
          <button type="button" (click)="isLoadCampaign = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadCampaign">
            <personalization-tags-container
              [subMethodId]="subMethodType"
              (chosen)="onTagChosenForUtmCampaign($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_campaign" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <label for="input_utm_term" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_TERM") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_term"
            id="input_utm_term"
            data-qa-id="UTMTermInput"
            (focus)="focused_params = true"
            (blur)="focused_params = false"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_TERM') }}"
            [(ngModel)]="utm.utms.utm_term" />
          <button type="button" (click)="isLoadTerm = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadTerm">
            <personalization-tags-container
              [subMethodId]="subMethodType"
              (chosen)="onTagChosenForUtmTerm($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_params" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <label for="input_utm_content" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_CONTENT") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_content"
            id="input_utm_content"
            data-qa-id="UTMContentInput"
            (focus)="focused_content = true"
            (blur)="focused_content = false"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_CONTENT') }}"
            [(ngModel)]="utm.utms.utm_content" />
          <button type="button" (click)="isLoadContent = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadContent">
            <personalization-tags-container
              [subMethodId]="subMethodType"
              (chosen)="onTagChosenForUtmContent($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_content" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <label for="input_utm_id" class="col-md-3 control-label ng-scope">
        {{ translateService.instant("features.manage_templates.components.utmParams.UTM_ID") }}
      </label>
      <div class="col-md-9 inputStyle">
        <div class="utm-input-block">
          <input
            type="text"
            class="form-control ng-pristine ng-untouched ng-valid ng-not-empty utm_id"
            id="input_utm_id"
            data-qa-id="UTMIdInput"
            (focus)="focused_utm_id = true"
            (blur)="focused_utm_id = false"
            placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.TOOLTIP_UTM_ID') }}"
            [(ngModel)]="utm.utms.utm_id" />
          <button type="button" (click)="isLoadUtmId = true" class="tags-osl-drop-down">
            <i class="material-icons icon">portrait</i>
          </button>
          <div *ngIf="isLoadUtmId">
            <personalization-tags-container
              [subMethodId]="subMethodType"
              (chosen)="onTagChosenForUtmId($event)"></personalization-tags-container>
          </div>
        </div>
        <p *ngIf="focused_utm_id" class="warning-sign" data-qa-id="UTMWarningName">
          {{ translateService.instant("features.manage_templates.components.utmParams.WARNING_NAME") }}
        </p>
      </div>
    </div>
    <div class="row">
      <h3 class="col-md-12 ng-scope">{{ translateService.instant("features.manage_templates.components.utmParams.URL_TITLE") }}</h3>
    </div>
    <div class="row">
      <div class="col-md-10">
        <input
          type="text"
          class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
          name="sample_url"
          data-qa-id="UTMPlaceholderInput"
          placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.URL_PLACEHOLDER') }}"
          [(ngModel)]="sample_url" />
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-secondary btn-generate"
          (click)="generate()"
          (disabled)="(sample_url === undefined || sample_url === '')">
          {{ translateService.instant("features.manage_templates.components.utmParams.GENERATE") }}
        </button>
      </div>
    </div>
    <div class="row last-row">
      <div class="col-md-12">
        <textarea
          rows="4"
          class="form-control"
          placeholder="{{ translateService.instant('features.manage_templates.components.utmParams.GENERATED_URL_PLACEHOLDER') }}"
          [(ngModel)]="res_url"></textarea>
      </div>
    </div>
  </div>
</opti-logic-modal>
