<div class="item-content" #wrapperElement (click)="toggleSelect($event)" [ngClass]="{'disabled': disabled, 'group-child': isGroupChild }">
    <label #labelElement (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <input type="checkbox" *ngIf="showCheckbox" [checked]="isSelected" [disabled]="disabled">
        <div class="label-text">
            <div #textElement class="tooltip-multi-line-3" >
                {{label}}
            </div>            
        </div>
    </label>
    <span *ngIf="icon" class="material-icons">{{icon}}</span>
</div>