<div style="position:relative; padding: 0;" [ngClass]="{'external-drop-down-mode': config.isExternalDropDownMode}" tabindex="-1" class='opti-search-list'>
    <div #search>
        <osl-input [selectedItems]="transformedSelectedItems" [valueProperty]="config.valueProperty" [includeSearch]="includeSearch"
                   [isDisabled]="isDisabled || isDefaultDisabled" [includeFooter]="shouldIncludeFooter()" [selectedTitleCount]="presets.selectedTitleCount"
                   [closed]="!showGrid" [isMultiSelected]="config.isMultiSelect" (onFilterChanged)="onFilterChanged($event)"
                   (onFilterFocus)="onFilterFocus()" [markAsInvalid]="markAsInvalid" [placeholderTranslateKey]="config.placeholderTranslateKey"
                   [listItemsLength]="searchListLength" *ngIf="!config.isExternalDropDownMode || (config.isExternalDropDownMode && includeSearch)" [isExternalDropDownMode]="config.isExternalDropDownMode"
                   (onArrowDown)="onInputArrowDown()" [isServerSideMode]="isServerSideMode" #searchInput></osl-input>
    </div>

<div #list class="search-list lg" [hidden]="!showGrid" [ngClass]="{'drop-down-direction-up': config.isDropDownDirectionUp, 'multi-selection': config.isMultiSelect}">
    <ag-grid-angular [gridOptions]="gridOptions" [modules]="modules" class="searchlist ag-theme-searchlist" [overlayNoRowsTemplate]="noRowsTemplate" *ngIf="isInitialized"> </ag-grid-angular>

    <div class="loading-container" *ngIf="isLoading">
        <span class="loader"></span>
        <label>{{ 'components.opti_search_list.LOADING' | translate }}</label>
    </div>
</div>

    <osl-item [showCheckbox]="config.isMultiSelect" #calculationElement class="calculation-element" [maxRowsInCell]="presets.maxRowsInCell"></osl-item>
</div>