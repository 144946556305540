import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { WindowRefService } from "../../services/windowRef.service";
import { IModalConf } from "./optiLogicModal.service";
import { TranslateService } from '@ngx-translate/core';


/**
 * In order to use OptiLogicModalComponent you should include it in your modal component template
 *
 * and you should pass your configuration as IModalConf like in the following html code:
 *
 *              <opti-logic-modal [modalConf]="modalConf">
 *                  <!-- Your html goes here -->
 *              </opti-logic-modal>
 *
 * for fully implementation example please see LogicModalExampleComponent.
 *
 * after modal component implementation you should pass it to OptiLogicModalService.open method
 *
 */
@Component({
    selector: 'opti-logic-modal',
    templateUrl: './optiLogicModal.component.html',
    styleUrls: ['./optiLogicModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OptiLogicModalComponent implements AfterViewInit, OnInit {

    public hasScroll: boolean = false;
    public isScalable: boolean = false;
    private panelBodyElement: any;

    constructor(public modalRef: BsModalRef,
        private elementRef: ElementRef,
        private windowRef: WindowRefService,
        private translate: TranslateService,) {
    }


    @Input() public modalConf: IModalConf;
    @ViewChild('template', { static: false }) template: TemplateRef<any>;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkScrollDimentions();
    }

    ngOnInit() {
        this.getModalButtons();
        this.isScalable = Boolean(this.modalConf?.withScale);
    }

    private getModalButtons(){
        if(this.modalConf?.buttons && this.modalConf?.buttons.length){
            this.modalConf.buttons = this.modalConf.buttons.map(button => {
                return {
                    ...button,
                    label: this.translate.instant(button.label),
                    loadingText: button.loadingText ? this.translate.instant(button.loadingText) : this.translate.instant('general.LOADING')
                }
            })
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.panelBodyElement = this.elementRef.nativeElement.querySelector('.panel-body');
            this.checkScrollDimentions();
        }, 0);

    }

    checkScrollDimentions(): void {
        setTimeout(() => {
            if (!this.modalConf?.disableScroll) {
                this.hasScroll = this.panelBodyElement.scrollHeight > Math.ceil(this.windowRef.nativeWindow.innerHeight - 180);
            }
        });
    }

}

