import { Routes, Route } from '@angular/router';
import { AngularJsContainer } from '../components/angularJsContainer/AngularJsContainer.component';
import { FeatureFlag } from '../services/featureFlag.service';
import { AuthorizedService } from './authorizedService.service';
import { FeatureFlagGuard } from './guard/featureFlag.guard';
import { MarketingPlanDeprecationGuard } from './guard/marketingPlanDeprecation.guard';
import { LegacyGuardDeactived } from './legacyGuardDeactived.service';
import { RoleGuard } from './guard/role.guard';
import { LeavingAngularJsPageGuard } from './guard/leavingAngularJsPage.guard';
import { FeatureFlagRedirect } from './guard/featureFlagRedirect.guard';

const basicRoutes: Routes = [{
    path: '',
    children: [{
        path: 'settings',
    },
    {
        path: 'user-settings',
        children: [
            {
                path: 'manage-users',
                children: [{
                    path: 'list',
                    data: {initialChangeDetectionRequired: true},
                },
                {
                    path: 'create',
                },
                {
                    path: 'edit',
                },
                {
                    path: 'edit/:id',
                },
                {
                    path: '',
                    redirectTo: 'list',
                }
                ]
            },
            {
                path: 'my-account',
            },
            {
                path: 'role-permissions',
            },
            {
                path: 'sdk-event',
                children: [{
                    path: 'list'
                },
                {
                    path: 'create-event'
                },
                {
                    path: 'create-parameter'
                }, {
                    path: '',
                    redirectTo: 'list',
                }
                ]
            },
            {
                path: 'sdk-configuration',
                children: [{
                    path: 'list'
                },
                {
                    path: 'create'
                }, {
                    path: '',
                    redirectTo: 'list',
                }
                ]
            },
            {
                path: 'apiManagement',
                canActivate: [FeatureFlagGuard.forFlags([FeatureFlag.ManageAPI])//, DeprecationPageGuard.DeprecateforFlag(FeatureFlag.ManageAPI, "apiKeyManagement")
                            ],
            },
            {
                path: 'messageArchiving',
                children: [
                    {
                        path: 'credentialsForm',
                    },
                    {
                        path: 'errorsList'
                    }
                ]
            },
            {
                path: 'preferenceCenter',
                children: [
                    {
                        path: 'addTopic',
                    },
                    {
                        path: 'list',
                    },
                ]
            },
            {
                path: 'createApiUser',
                canActivate: [FeatureFlagGuard.forFlags([FeatureFlag.ManageAPI])//, DeprecationPageGuard.DeprecateforFlag(FeatureFlag.ManageAPI, "apiKeyManagement")
                            ],
            },
            {
                path: 'apiKeyManagement',
                canActivate: [FeatureFlagGuard.forFlags([FeatureFlag.openApiKeyManagement])],
            },
            {
                path: 'createApiKey',
                canActivate: [FeatureFlagGuard.forFlags([FeatureFlag.openApiKeyManagement])],
            },
            {
                path: 'gdpr',
            },
            {
                path: 'notifications',
            },
            {
                path: 'realTimeProfileDataManager',
                children: [
                    {
                        path: 'list',
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                    },
                    {
                        path: 'addAttribute',
                    },
                    {
                        path: 'editAttribute',
                    }
                ]
            },
            {
                path: 'attributes',
                children: [
                    {
                        path: 'list',
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                    },
                    {
                        path: 'calculatedAddAttribute',
                    },
                    {
                        path: 'calculatedEditAttribute',
                    },
                    {
                        path: 'baseEditAttribute',
                    },
                    {
                        path: 'customerAddAttribute',
                    },
                    {
                        path: 'customerEditAttribute',
                    },
                    {
                        path: 'conditionalEditAttribute',
                    },
                    {
                        path: 'purchaseAddAttribute',
                    },
                    {
                        path: 'purchaseEditAttribute',
                    },
                ]
            },
            {
				path: 'importedCustomers',
				canActivate: [RoleGuard.forRoles(['Admin', 'OptimoveAdmin'])],
				canDeactivate: [LeavingAngularJsPageGuard.forKey("importedCustomers", "features.user_settings.body.importedCustomers.LEAVE_PAGE_CONFIRMATION_MESSAGE")],      
			},
            {
                path: 'optimail',
                children: [{
                    path: 'preference-center',
                }, {
                    path: '',
                    redirectTo: 'preference-center',
                }, {
                    path: 'subscription',
                }, {
                    path: '',
                    redirectTo: 'subscription',
                }, {
                    path: 'doubleOptIn'
                }, {
                    path: '',
                    redirectTo: 'doubleOptIn',
                }, {
                    path: 'editBrand'
                }, {
                    path: '',
                    redirectTo: 'editBrand'
                }, {
                    path: 'oneClickUnsubscribe'
                }, {
                    path: '',
                    redirectTo: 'oneClickUnsubscribe'
                }]
            },
            {
                path: '',
                redirectTo: 'manage-users'
            },
            {
                path: 'webhookConfiguration',
                children: [
                    {
                        path: 'list',
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                    },
                    {
                        path: 'addWebhookConfiguration',
                    }
                ]
            },
            {
                path: 'uniquePromoCodes',
                children: [
                    {
                        path: 'list',
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                    },
                    {
                        path: 'addUniquePromoCode',
                    }
                ]
            }, 
            {
                path: 'optitext',
                children: [
                    {
                        path: 'smsConfig'
                    },
                    {
                       path: 'autoReplies'
                    },
                    {
                        path: 'errorLog'
                    },
                    {
                        path: 'im'
                    }
                ]
            },
            {
                path: 'optiweb',
                children: [
                    {
                        path: 'webInbox'
                    }
                ]
            },
            {
                path: 'optimobile',
                children: [
                    {
                        path: 'installs'
                    },
                    {
                        path: 'mobilePushConfig'
                    },
                    {
                        path: 'inAppMessaging'
                    },
                    {
                        path: 'webPushConfig'
                    },
                    {
                        path: 'webPushPrompts'
                    },
                    {
                        path: 'errorLog'
                    },
                    {
                        path: 'beacons'
                    },
                    {
                        path: 'geofences'
                    }
                ]
            },
            {
                path: 'ddl',
                children: [
                    {
                        path: 'deepLinks'
                    },
                    {
                        path: 'configuration'
                    }
                ]
            }
        ]
    },
    {
        path: 'category/Predictive_Model',
        children: [{
            path: 'customer-model',
        },
        {
            path: 'segmentation-explorer',
        },
        {
            path: 'Segmentation_Explorer.aspx',
        },
        {
            path: 'inter-stage-migrations',
        },
        {
            path: 'Inter Value-Segments', //ssrs
        },
        {
            path: 'Inter Layer' //ssrs
        }, {
            path: '',
            redirectTo: 'customer-model',
        }
        ]
    },
    {
        path: 'Analysis_%26_Research',
        redirectTo: 'Analysis_&_Research'
    },
    {
        path: 'category/Analysis_&_Research',
        children: [{
            path: 'dashboard',
        },
        {
            path: 'Dashboard.aspx',
            canActivate: [FeatureFlagRedirect.redirect((ffs) => ffs.isEnabled(FeatureFlag.TheNewDashboard), 'category/analysis_&_research/dashboard')],
        },
        {
            path: 'audience-discovery'
        },
        {
            path: 'campaign-analysis',
        },
        {
            path: 'LTV by Group',  //ssrs
        },
        {
            path: 'Cohorts',  //ssrs
        },
        {
            path: 'customer360',
        },
        {
            path: 'customer360/:id',
        },
        {
            path: 'customer360WithParams/:id',
        },
        {
            path: 'self-optimizing-campaign-analysis',
        },
        {
            path: 'funnel-explorer',
        }, {
            path: '',
            redirectTo: 'dashboard',
        },
        {
            path: 'visits-explorer',
        },
        {
            path: 'business-performance-overview',
        },
        {
            path: 'cohorts-analysis',
        },
        {
            path: 'deliverability-metrics',
        },
        ]
    },
    {
        path: 'category/One-to-One_Campaigns',
        children:[{
            canActivate: [MarketingPlanDeprecationGuard],
            path: 'marketing-plan',
        },
        {
            canActivate: [MarketingPlanDeprecationGuard],
            path: 'MarketingPlan.aspx',
        },
        {
            path: 'plan-explorer',
        },
        {
            path: 'Marketing_Plan_Explorer.aspx',
        },
        {
            path: 'manage-target-groups',
        },
        {
            path: 'Best Action For Target Group',  //ssrs
        },
        {
            path: 'Target Group Performance',  //ssrs
        },
        {
            path: 'orchestration-overview',
        },
        {
            path: 'manage-templates',
            children: [{
                path: 'optipush',
            },
            {
                path: 'bronto',
            },
            {
                path: 'optimail',
            },
            {
                path: 'campaignMonitor',
            },
            {
                path: 'realtimePopup',
            },
            {
                path: 'thirdParty',
            },
            {
                path: 'optiMobileInApp',
            },
            {
                path: 'kumulosMobilePush',
            },
            {
                path: 'webPushNotifications',
            },
            {
                path: 'optiTextSms',
            },
            {
                path: '',
                redirectTo: 'templates/optimail',
            }
            ]
        },
        {
            path: 'manage-templates-newedit',
            children: [{
                path: 'optipush',
            },
            {
                path: 'bronto',
            },
            {
                path: 'optimail',
            },
            {
                path: 'campaignMonitor',
            },
            {
                path: 'realtimePopup',
            },
            {
                path: 'thirdParty',
            },
            {
                path: 'optiMobileInApp',
            },
            {
                path: 'kumulosMobilePush',
            },
            {
                path: 'webPushNotifications',
            },
            {
                path: 'optiTextSms',
            },
            {
                path: '',
                redirectTo: 'templates/optimail',
            }
            ]
        },
        {
            path: 'realTime-triggers',
        },
        {
            path: 'RealTimeTriggers.aspx',
        }, {
            path: '',
            redirectTo: 'marketing-plan',
        }
        ]
    }
    ]
}]

function extendRoute(route: Route) {
	route = Object.assign(route, {
		data: {
			isLegacy: true,
			page: route.path ? route.path : route.redirectTo, // read explanation in angularjscontainer.component.ts for explanation
			...route?.data
		},
	});
	// bi studio can appear as a child of any category that includes children so we dynamically add them to the mix
	const biStudioRoutes: Route[] = [
		{
			path: 'biStudioLandingPage',
		},
		{
			path: 'biStudio',
		}
	];
	if (route.children) {
		let childrenWithBIStudio = route.children.concat(...biStudioRoutes);
		let childRouteObjects = childrenWithBIStudio.map((childRoute) => extendRoute(childRoute));
		route.children = childRouteObjects;
	}
	if (!route.redirectTo && !route.component) { // redirectTo and component cannot be used together
		route.component = AngularJsContainer
	}
	if (typeof route.path !== 'undefined') {
		route.pathMatch = 'prefix';
	}
	route.canDeactivate = [...(route?.canDeactivate ?? []), LegacyGuardDeactived];
	route.canActivateChild = [AuthorizedService];
	return route;
}

export const legacyAngularJsRoutes = basicRoutes.map((route) => extendRoute(route))
