export interface TemplatePreviewRequest {
	channelID: number;
	subMethodType: number;
	templateJson: string;
	idSelectionValue: number;
	customerId: string;
	containsNonVisitorTags: boolean;
	useHtmlForBeefree?: boolean;
}

export interface SendAndPreviewInfo {
	SubMethodTypesThatSupportVisitors: number[];
}

export enum AvailableIDSelectionValues {
	Unselected = -1,
	RandomCustomerId = 1,
	ExplicitId = 2,
	RandomVisitorId = 3
}

export interface AggregatedPersonalizationTag {
	PersonalizationTags: [];
	Id: number;
	UIPreffix: string;
	UIEnabled: boolean;
	Name: string;
}
