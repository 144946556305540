import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ContentTagModalComponent } from '../contentTagModal.component';
import { OptiLogicModalService } from '../../../../../../components/optiLogicModal/optiLogicModal.service';
import { ManageTemplatesService } from '../../../../services/manageTemplates.service';
import { ContentTag } from '../../../../models/contentTag';

@Component({
	selector: 'content-tag-container',
	templateUrl: 'contentTagModalContainer.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['contentTagModalContainer.component.scss']
})
export class ContentTagModalContainerComponent implements OnInit, OnDestroy {
	private onHideSubscription: Subscription;
	modalRef: BsModalRef;
	@Output() chosen: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private modalService: OptiLogicModalService,
		private bsModalService: BsModalService,
		public manageTemplatesService: ManageTemplatesService
	) {
		this.onHideSubscription = this.bsModalService.onHide.subscribe((reason: string) => {
			this.chosen.emit(reason);
		});
	}

	public openModal(): void {
		this.modalRef = this.modalService.open(ContentTagModalComponent, 'lg', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			keyboard: false,
			initialState: {
				onChosen: (tag: string) => {
					this.chosen.emit(tag);
					this.modalRef.hide();
				},
				onClose: () => {
					this.chosen.emit('');
					this.modalRef.hide();
				}
			}
		});
	}

	ngOnInit(): void {
		this.openModal();
	}

	ngOnDestroy(): void {
		this.onHideSubscription.unsubscribe();
	}
}
