<div class="copy-template-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <ng-container *ngIf="!isSuccess">
      <div class="row form-item mb-12">
        <label class="col-md-3 right-column-label">
          {{ "features.manage_templates.components.copy_template_modal.BRAND_LABEL" | translate }}
        </label>
        <div class="col-md-9">
          <opti-search-list
            class="brandsearchlist"
            [ngModel]="selectedOption.brand"
            [items]="brands"
            (ngModelChange)="brandsChannelChanged($event)"
            [config]="brandSearchConfig"></opti-search-list>
        </div>
      </div>
      <div class="row form-item mb-12">
        <label class="col-md-3 right-column-label">
          {{ "features.manage_templates.components.copy_template_modal.TEMPLATE_LABEL" | translate }}
        </label>
        <div class="col-md-9">
          <opti-search-list
            [items]="subMethods"
            (ngModelChange)="typeChanged($event)"
            [ngModel]="selectedOption.subMethodId"
            [disabled]="subMethodsDisabled"
            required="true"
            [config]="typeSearchConfig"></opti-search-list>
        </div>
      </div>
    </ng-container>
    <div *ngIf="isSuccess" class="success-fail-modal">
      <i class="top-icon success-icon material-icons">done</i>
      <h3>
        {{ "features.manage_templates.components.copy_template_modal.SUCCESS_MESSAGE" | translate }}
      </h3>
    </div>
  </opti-logic-modal>
</div>
