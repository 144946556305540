import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'convertOrderActionToLetter'})
export class ConvertOrderActionToLetter implements PipeTransform {
    readonly alphabeticInitNum: number = 65; 

    transform(order: number) {
        if(order === -1)
            return "Control";
        return String.fromCharCode(this.alphabeticInitNum + order - 1);
    }
}