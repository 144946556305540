<div class="purchase-attribute">
    <div class="panel-heading">
        <div class="header">
            <div class="back-button">
                <div class="btn btn-link" (click)="openConfirmModal()">
                    <i class="material-icons">arrow_back</i>
                    <span translate>{{'general.BACK' | translate}}</span>
                </div>
            </div>
            <div *ngIf="isEditMode" class="published-title">
                <h2 *ngIf="isEditMode"> {{translateKeys.editTitle | translate}}</h2>
                <colored-tag [coloredTagType]="attributeStatus.color" style="padding: 7px;">
                    {{ attributeStatus.description }}
                </colored-tag>
            </div>
            <div *ngIf="!isEditMode">
                <h2> {{ translateKeys.title | translate }} </h2>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <form [formGroup]="form" class="form-container">
            <div class="form-group">
                <div class="row attribute-head-inputs">
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="label-column col-sm-3">
                                {{translateKeys.displayName | translate}}
                            </div>
                            <div class="col-sm-9">
                                <input 
                                    [ngClass]="{'errorBorder': notUniqueAttribute}" 
                                    class="form-control value-column"
                                    type="text"
                                    [placeholder]="translateKeys.placeholder | translate"
                                    formControlName="displayName" translate />
                                <p *ngIf="notUniqueAttribute" class="inputs-validations error-msg">
                                    {{translateKeys.errorAliasFieldName | translate}}
                                </p>
                                <span data-qa-id="attribute-name-error-msg" *ngIf="displayName.errors?.specialSymbols"
                                    class="inputs-validations error-msg">
                                    {{translateKeys.specialSymbolsErrorAliasFieldName | translate }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="label-column col-sm-3">
                                {{translateKeys.description | translate}}
                            </div>
                            <div class="col-sm-9">
                                <textarea class="form-control value-column" type="text" formControlName="description"
                                    placeholder="(Optional)">
                                    </textarea>
                            </div>
                        </div>
                    </div>
                    <attribute-settings class="attribute-settings"
                        (chengePersonalizationEvent)="changePersonalization($event)"
                        (changeAttributeVisibilityEvent)="changeAttributeVisibility()"
                        [isPersonalization]="attribute.IsPersonalization" [showAttribute]="!attribute.IsHidden"
                        [disableShowHideCheckbox]="!canAttributeBeHidden" *ngIf="isPublished()">
                    </attribute-settings>
                </div>
            </div>
            <purchase-attribute-calculation-section
                *ngIf="attribute && ((isEditMode === true && [1,3,5,7].includes(attribute.Family)) || (isEditMode === false && newPurchaseHistoryAttributesFF === true))"
                #attributeBody formControlName="attributeBody" 
                (isFormCreated)="initChildFormSubscription()">
            </purchase-attribute-calculation-section>

            <old-purchase-attribute-calculation-section
                *ngIf="attribute && ((isEditMode === true && [0,2,4,6].includes(attribute.Family)) || (isEditMode === false && newPurchaseHistoryAttributesFF === false))"
                #attributeBody formControlName="attributeBody" 
                (isFormCreated)="initChildFormSubscription()">
            </old-purchase-attribute-calculation-section>

        </form>
    </div>
</div>
<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="last-modified-stamp">
        <label class="small">{{lastUpdate}}</label>
    </div>
    <div class="controls col-md-6 pull-right text-right">
        <ul class="list-inline">
            <li>
                <div class="btn btn-default" (click)="openConfirmModal()">
                    <span>Cancel</span>
                </div>
            </li>
            <li>
                <loading-button [showLoader]="isSaving"
                    (onClick)="attribute.Name ? updateAttributeClick() : saveAttributeClick()"
                    [isDisabled]="(!form.valid || !form.dirty) && !IsPersonalizationCheckBoxChecked && !isAttributeVisibilityChanged"
                    [buttonCssClass]="'btn btn-primary'" [loadingCssClass]="'saving-loader'"
                    [loadingText]="isEditMode ? 'Updating' : 'Saving'" [buttonText]="isEditMode ? 'Update' : 'Save'">
                </loading-button>
            </li>
        </ul>
    </div>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="isSaving"></div>