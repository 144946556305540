<div *ngIf="!showLoader">
    <button type="button"
            [ngClass]="buttonCssClass"
            [disabled]="isDisabled"
            (click)="onClick.emit()">
        {{buttonText}}
    </button>
</div>
<loading-text
        [loadingCssClass]="loadingCssClass"
        [show]="showLoader"
        [loadingText]="loadingText"
></loading-text>