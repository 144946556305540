import { SearchItem } from "../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
export const CHANNEL_METRICS_TRANSLATION_ROOT = 'features.campaign_analysis.channelMetrics.';

export enum MainMetricTypes {
    Delivered = 'Delivered',
    Opened = 'Opened',
    Clicked = 'Clicked'
}

export enum TableMetricTypes {
    Sent = 'Sent',
    Dropped = 'Dropped',
    Bounced = 'Bounced',
    Unsubscribed = 'Unsubscribed',
    SpamReport = 'SpamReport',
    Dismissed = 'Dismissed',
    Impressions = 'Impressions',
    Reach = 'Reach',
    Deferred = 'Deferred'
}

export enum ActionType {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    N = 'N' // control
}

export interface IColorTableEntry {
    actionType: string;
    type: string;
    color: string;
}

export interface IChannelMetricsElement {
    id: number;
    name: string;
    percent: number;
    showLink?: boolean;
    number?: number;
    color?: string;
    tooltip?: string;
    denominatorName?:string;
}

export interface ITemplateData {
    templateId: number;
    templateName: string;
    planDetailChannelId: number;
    setId: number;
    metrics: IChannelMetricsElement[];
    multiId: number,
    campaignTime: string,
    timeZone: string,
    shouldEngageByCustomerLocalTime: string
}

export interface IChannelData {
    channelId: number;
    channelName: string;
    planDetailChannelId: number;
    metrics: IChannelMetricsElement[];
    templatesData: ITemplateData[];
}

export interface IActionData {
    planDetailId: number;
    actionName: string;
    actionType: ActionType;
    channels: IChannelData[];
}

export interface AttributeToExport {
    attributeSearchItem: SearchItem, 
    isDefault: boolean
}
