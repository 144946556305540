import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules, Module } from '@ag-grid-enterprise/all-modules';
import { RealtimeProfileDataManageService } from './realtimeProfileDataManage.service';
import { BtnCellRendererComponent } from './components/btnsCellRenderer/btnsCellRenderer.component';
import { AddAttributeHeaderComponent } from './components/addAttributeHeader/addAttributeHeader.component';
import { TableAttribute } from './models/profileDetails.model';
import { ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import { Router } from '@angular/router';
import { StatusRTPCellRendererComponent } from './components/statusRTPCellRenderer/statusRTPCellRenderer.component';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AttributeConfiguration } from '../attributes/attributesList.model';
import moment from 'moment';
import { AttributesListService } from '../attributes/services/attributesList.service';

@Component({
  selector: 'realtime-profile-data-manage',
  templateUrl: './realtimeProfileDataManage.component.html',
  styleUrls: ['./realtimeProfileDataManage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RealtimeProfileDataManageComponent implements OnInit, OnDestroy {
  public fieldToColumnNameDic: { [fieldIdName: string]: string } = {
    tableDisplayName: 'Attribute Name',
    exists: 'Based on DB Att.',
    formula: 'Formula',
    eventsType: 'Event',
    eventsParameter: 'Event Parameter',
  };

  public loading = true;
  public inDeletion = false;
  public gridOptions: GridOptions;
  public modules: Module[] = AllCommunityModules;
  public disableAddAttr = false;
  public noAttributes = false;
  public updatedAttribute = null;
  public config: AttributeConfiguration;
  public allPublished = false;
  public isClickable = false;
  public isLockedDuringPublishing = false;
  public publishError = false;
  public publishErrorString = '';
  public translateKeys = translateKeys;
  public subscriptions: Subscription[] = [];
  private gridApi: GridApi;

  private dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;

  private timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  } as Intl.DateTimeFormatOptions;

  constructor(
    private service: RealtimeProfileDataManageService,
    private attributeService: AttributesListService,
    private modalService: OptiLogicModalService,
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private router: Router
  ) {
    this.updatedAttribute =
      this.router.getCurrentNavigation()?.extras?.state?.displayName;
  }

  ngOnInit() {
    (async () => {
      await this.refreshTable(true);
    })();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onLoadingError() {
    this.modalService.openModalMessage(
      'sm',
      {
        message: this.translate.instant(this.translateKeys.cantLoadAttributes),
        buttons: [
          {
            class: 'btn-primary',
            label: this.translate.instant(this.translateKeys.ok),
            action: this.closeModal.bind(this),
          },
        ],
      },
      <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
    );
  }

  showCantPublishAttributesModal() {
    this.modalService.openSuccessFailModal(
      'sm',
      {
        isSuccess: false,
        title: this.translate.instant('general.FAILED'),
        message: this.translate.instant(
          this.translateKeys.cantPublishAttributes
        ),
        primaryButtonText: this.translate.instant('general.OK'),
      },
      <ModalOptions<any>>{
        ignoreBackdropClick: true,
      }
    );
  }

  private async refreshTable(initialLoading?: boolean, isPublishing?: boolean) {
    try {
      await this.service.updateCustomerAttributes();
      if (this && !this.config) {
        this.config = await this.attributeService.getAttributeConfiguration().toPromise();
      }
      let attributes = await this.service
        .getAttributes()
        .pipe(first())
        .toPromise();

      if (isPublishing) {
        attributes = this.setPublishingStatus(attributes);
      }

      this.checkNoAttributes(attributes);
      this.checkMaxAttributesLimit(attributes);
      this.checkAllPublished(attributes);
      this.checkPublishError(attributes);

      if (initialLoading) {
        this.initGridConfig(attributes);
      } else {
        this.setGridData(attributes);
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.disableAddAttr = true;
      if (initialLoading) {
        this.initGridConfig([]);
      }
      this.onLoadingError();
      this.loading = false;
    }
  }

  private setGridData(profileDetailsArr: TableAttribute[]) {
    this.gridApi.setRowData(profileDetailsArr);
    this.gridApi.refreshClientSideRowModel();
    this.gridApi.refreshHeader();
    this.gridApi.refreshCells();
  }

  private setPublishingStatus(attrs: TableAttribute[]): TableAttribute[] {
    attrs.forEach((attr) => {
      if (attr.status === 'NotPublished') {
        attr.status = 'Publishing';
      }
    });

    return attrs;
  }

  private checkNoAttributes(attrs: TableAttribute[]) {
    this.noAttributes = attrs.length === 0;
  }

  private checkMaxAttributesLimit(attrs: TableAttribute[]) {
    this.disableAddAttr = attrs.length >= this.config.MaxCountOfRealtimeAttributes;
  }

  private checkAllPublished(attrs: TableAttribute[]) {
    this.allPublished = attrs.every((x) => x.status === 'Published');
    this.isClickable = attrs.some((x) => x.status === 'NotPublished');
    this.isLockedDuringPublishing = attrs.some(
      (x) => x.status === 'Publishing'
    );
  }

  private checkPublishError(attrs: TableAttribute[]) {
    const publishErrorTime = attrs.find(
      (x) => x.publishErrorTime !== undefined && x.publishErrorTime !== null
    )?.publishErrorTime;
    this.publishError =
      publishErrorTime !== undefined && publishErrorTime !== null;
    if (this.publishError) {
      this.setupMessageForPublishFail(publishErrorTime);
    }
  }

  private setupMessageForPublishFail(dateString: string) {
    if(moment(dateString).isValid()) {
      const date = moment(dateString).toDate();
      const offsetUTC = date.getTimezoneOffset() / 60;
      const hoursUTC = `${offsetUTC > 10
        ? offsetUTC
        : `0${offsetUTC > 0 ? offsetUTC : -1 * offsetUTC}`
        }:00`;
      const utcPart = `UTC${offsetUTC > 0 ? '-' : '+'}${hoursUTC}`;
      let time = new Intl.DateTimeFormat('en', this.timeOptions);
      let timestamp = `${date.toLocaleDateString(
        'en-UK',
        this.dateOptions
      )} at ${time.format(date)} ${utcPart}`;
      this.publishErrorString = this.translate.instant(
        translateKeys.publishFailed,
        { timestamp: timestamp }
      );
    } else {
      this.publishErrorString = this.translate.instant(
        translateKeys.publishFailed,
        { timestamp: '' }
      );
    }
  }

  private initGridConfig(profileDetailsArr: TableAttribute[]) {
    const columnDefs = this.getColumnDefs();
    this.gridOptions = {
      columnDefs: columnDefs,
      rowData: this.prepareData(profileDetailsArr),
      context: this,
      headerHeight: 50,
      rowHeight: 55,
      suppressRowTransform: true,
      suppressCellSelection: true,
      alwaysShowHorizontalScroll: true,
      animateRows: true,
      getRowNodeId: (data) => {
        return data.displayName;
      },
      onGridReady: (params) => {
        this.gridApi = params.api;
      },
      getRowHeight: (params) => {
        return params.data.events.length === 0
          ? 40
          : params.data.events.length * 40;
      },
      defaultColDef: {
        sortable: true,
      },
    };
  }

  private getColumnDefs(): ColDef[] {
    let colDef = Object.keys(this.fieldToColumnNameDic).map((fieldIdName) => {
      const colDef: ColDef = {
        headerName: this.fieldToColumnNameDic[fieldIdName],
        headerTooltip: this.fieldToColumnNameDic[fieldIdName],
        field: fieldIdName,
        sortable: true,
        initialWidth: this.setColWidth(fieldIdName),
        resizable: true,
        enableCellChangeFlash: true,
        cellStyle: () => {
          return { 'padding-right': '20px' };
        },
        cellRenderer: (params) => {
          return this.colCellRenderer(params);
        },
        comparator: (a, b) => {
          return typeof a === 'string'
            ? a.localeCompare(b)
            : a > b
              ? 1
              : a < b
                ? -1
                : 0;
        },
        tooltipValueGetter: (params) => {
          return this.decodeHTML(params.value);
        },
      };
      return colDef;
    });

    let upTo10 = this.translate.instant(this.translateKeys.upTo10Attributes);
    let title = this.translate.instant(this.translateKeys.addAttributeTitle);
    colDef.push({
      headerName: 'Status',
      field: 'status',
      width: 100,
      cellRendererFramework: StatusRTPCellRendererComponent,
    });
    colDef.push({
      headerComponentFramework: AddAttributeHeaderComponent,
      headerComponentParams: {
        disableAddAttr: this.disableAddAttr,
      },
      resizable: true,
      sortable: true,
      headerTooltip: this.disableAddAttr ? upTo10 : title,
      cellRendererFramework: BtnCellRendererComponent,
      initialWidth: 130,
    });

    return colDef;
  }

  private setColWidth(fieldIdName: string) {
    switch (fieldIdName) {
      case 'tableDisplayName':
        return 200;
      case 'eventsType':
      case 'formula':
        return 130;
      case 'eventsParameter':
      case 'exists':
        return 120;
      default:
        return 200;
    }
  }

  private colCellRenderer(params: any): string {
    let resHtml: string = '';
    if (params.colDef.field === 'eventsType') {
      for (let i: number = 0; i < params.value.length; i++) {
        if (params.value[i]) {
          resHtml += params.data.eventsType[i] + ' <br/> ';
        }
      }
      return resHtml;
    } else if (params.colDef.field === 'exists') {
      if (params.value) {
        resHtml += `<div><span class="material-icons icons">check</span></div>`;
      } else
        resHtml += `<div><span class="material-icons icons">close</span></div>`;
      return resHtml;
    } else if (params.colDef.field === 'eventsParameter') {
      if (!params.value) {
        return `<div><span class="material-icons no-parameter">remove</span></div>`;
      }

      return params.value;
    }

    return params.data[params.colDef.field];
  }

  private prepareData(attrArr: TableAttribute[]) {
    for (let index = 0; index < attrArr.length; index++) {
      const attribute = attrArr[index];
      const conditionNew =
        this.updatedAttribute &&
        this.updatedAttribute === attribute.tableDisplayName;
      if (conditionNew && attribute.tableDisplayName.length > 30) {
        attribute.tableDisplayName = `<div>${attribute.tableDisplayName.substr(
          0,
          30
        )}... <span class="new-tag">New!</span></div>`;
      } else if (conditionNew) {
        attribute.tableDisplayName = `<div>${attribute.tableDisplayName} <span class="new-tag">New!</span></div>`;
      }
    }
    return attrArr;
  }

  private decodeHTML(text) {
    let div = document.createElement('div');
    div.innerHTML = text;
    return div.innerText;
  }

  private closeModal() {
    this.bsModalRef.hide();
  }

  public async publishClick() {
    this.isClickable = false;
    await this.refreshTable(false, true);

    const attributes = await this.service.getAttributes().pipe(first()).toPromise();
    attributes.forEach(x => {
      if (x.status === 'NotPublished') {
        x.status = 'Publishing';
      }
    })

    let res = await this.service.publishAttributes().pipe(first()).toPromise();

    if (!res.IsSuccess) {
      this.showCantPublishAttributesModal();
    }

    await this.refreshTable();
  }

  openAttributeCreationSucceededModal() {
    this.modalService.openSuccessFailModal(
      'sm',
      {
        isSuccess: true,
        title: this.translate.instant(this.translateKeys.successModalTitle),
        message: this.translate.instant(this.translateKeys.successModalMsg),
        primaryButtonText: this.translate.instant(this.translateKeys.ok),
      },
      <ModalOptions<any>>{
        ignoreBackdropClick: true,
      }
    );
  }
}

const translateKeys = {
  successModalTitle: 'general.SUCCESS',
  ok: 'general.OK',
  successModalMsg:
    'components.realTimeProfile.ATTRIBUTE_CREATION_SUCCESS_MODAL_MESSAGE',
  allPublished:
    'features.user_settings.body.realtimeProfileDataManage.ALL_PUBLISHED',
  upTo10Attributes:
    'features.user_settings.body.realtimeProfileDataManage.UP_TO_10_ATTRIBUTES',
  cantLoadAttributes:
    'features.user_settings.body.realtimeProfileDataManage.invalidErrors.CANT_LOAD_ATTRIBUTES',
  addAttributeTitle:
    'features.user_settings.body.realtimeProfileDataManage.addAttribute.TITLE',
  addFirstAttribute:
    'features.user_settings.body.realtimeProfileDataManage.ADD_FIRST',
  noAttributes:
    'features.user_settings.body.realtimeProfileDataManage.NO_ATTRIBUTES',
  addAttributeDesc:
    'features.user_settings.body.realtimeProfileDataManage.ATTRIBUTES_ADDING_DESCRIPTION',
  publishFailed:
    'features.user_settings.body.realtimeProfileDataManage.PUBLISHED_FAILED',
  cantPublishAttributes:
    'features.user_settings.body.realtimeProfileDataManage.invalidErrors.CANT_PUBLISH_ATTRIBUTES',
};
