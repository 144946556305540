<div class="templates-html-carousel-modal templates-preview-carousel-modal">
    <div class="panel panel-default">
            <div class="panel-heading">
                <div>
                    <h2 ellipsis-title>{{templates[currentTemplateIndex].name}}</h2>

                    <div *ngIf="templates[currentTemplateIndex].time">
                        {{templates[currentTemplateIndex].time}} {{templates[currentTemplateIndex].timeZone}}
                    </div>

                    <div class="lastModifiedDate" *ngIf="templates[currentTemplateIndex].lastModifiedDate">
                        {{'components.templatePreview.LAST_MODIFIED' | translate}}
                        {{templates[currentTemplateIndex].lastModifiedDate}}
                        at
                        {{templates[currentTemplateIndex].lastModifiedTime}}
                        by
                        {{templates[currentTemplateIndex].lastModifiedBy}}
                    </div>
                </div>

                <div class="right-col" *ngIf="templates.length > 1">
                    <button (click)="onShowTemplatesGridViewModal()" class="btn-grid-view btn-link">{{'components.templatePreview.SHOW_ALL' | translate}}</button>
                    
                    <div class="templates-browse-state">
                        {{'components.templatePreview.MODAL_BROWSE_STATE' | translate: {currentTemplateIndex: currentTemplateIndex + 1,templatesLength: templates.length} }}
                    </div>
                </div>
            </div>

            <div class="actionsBar">
                <div class="clickMap">
                    <span class="clickMapLabel">{{'components.templatePreview.CLICK_MAP' | translate}}</span>
                    <div class="switchContainer">
                        <switch [buttonType]="1"
                                (ngModelChange)="ClickMapSwitch()"
                                [(ngModel)]="mapActivated">
                        </switch>
                    </div>

                </div>
                <div *ngIf="mapActivated && datesDataFormatted.length" class="dateRange">
                    <span>{{'components.templatePreview.DATE_RANGE' | translate}}&nbsp;</span>
                    <span class="dateRangeBold">{{datesDataFormatted[0]}} - {{datesDataFormatted[1]}}</span>
                    <i *ngIf="showModifiedInfo" tooltip="{{'components.templatePreview.DATES_TOOLTIP'| translate}}" class="material-icons ic-info btn-link-default modifiedInfo" translate>info_outline</i>
                </div>

                <div class="heatMapTabs">
                    <horizontal-tabs *ngIf="mapActivated" [activeTabIndex]="0" (tabSelected)="onTabSelected($event)">
                        <tab *ngFor="let tab of tabs" >
                            <tab-header>
                                {{tab.name}}
                            </tab-header>
                            <tab-body>
                            </tab-body>
                        </tab>
                    </horizontal-tabs>
                </div>
            </div>
            <div class="panel-body">
                <button *ngIf="templates.length > 1" class="btn-link template-browse-btn btn-browse-left" (click)="showPreviousTemplate()">
                    <i class="material-icons">chevron_left</i>
                </button>

                <iframe [attr.srcDoc]="templates[currentTemplateIndex].htmlToShow | safe : 'html'"></iframe>
                
                <div class="template-not-avialable-container" *ngIf="!templates[currentTemplateIndex].isExists">
                    <template-not-available [isLargeSize]="true"></template-not-available>
                </div>

                <button *ngIf="templates.length > 1" class="btn-link template-browse-btn btn-browse-right" (click)="showNextTemplate()">
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
    </div>
</div>
