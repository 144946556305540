import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {WebhookConfigurationService} from "../webhookConfiguration.service";
import { ColDef } from '@ag-grid-community/all-modules';
import {TranslateService} from "@ngx-translate/core";
import {
    AllCommunityModules,
    FirstDataRenderedEvent,
    GridApi,
    GridOptions,
    Module
} from "@ag-grid-enterprise/all-modules";
import {WebhookConfiguration, WebhookSettings} from "../models/webhookConfiguration"
import {WindowRefService} from "../../../../services/windowRef.service";
import {SettingsSharedService} from "../../settingsShared/settingsShared.service";
import {EditDeleteCellRendererComponent} from "../../settingsShared/editDeleteCellRenderer/editDeleteCellRenderer.component";
import {forkJoin, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {NewTagLabelCellRendererComponent} from "./newTagLabelCellRenderer/newTagLabelCellRenderer.component";
import {BsModalRef, ModalOptions} from "ngx-bootstrap/modal";
import {OptiLogicModalService} from "../../../../components/optiLogicModal/optiLogicModal.service";
import { Router } from "@angular/router";
import {FeatureFlagService} from "../../../../services/featureFlag.service";

@Component({
    selector: 'webhook-configuration',
    templateUrl: './webhookConfiguration.component.html',
    styleUrls: ['./webhookConfiguration.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WebhookConfigurationComponent implements OnInit, OnDestroy{

    public gridOptions: GridOptions;
    public modules: Module[] = AllCommunityModules;
    public rowData: WebhookConfiguration[];
    public emptyState: boolean = false;
    public loading: boolean = true;
    public settings: WebhookSettings;

    private gridApi: GridApi;
    private subscriptions: Subscription[] = []

    constructor(private webhookConfigurationService : WebhookConfigurationService,
                private translate: TranslateService,
                private windowRefService: WindowRefService,
                private settingsSharedService: SettingsSharedService,
                private optiLogicModalService: OptiLogicModalService,
                private bsModalRef: BsModalRef,
                private featureFlagService: FeatureFlagService,
                private router: Router) {
    }

    ngOnInit() {
        this.setupGridOptions();       
        forkJoin(
            this.webhookConfigurationService.getWebhooksList(),
            this.webhookConfigurationService.getSettings(),
            this.webhookConfigurationService.getOptigrationGenericEngagerEventTypes()
        ).pipe(
            map(([configurations, settings, genericEventTypes]) => {
                return {
                    configurations,
                    settings,
                    genericEventTypes 
                }
            })
        ).subscribe(res=> {
            this.updateData(res.configurations);
            this.settings = res.settings;
            this.settings.events = res.genericEventTypes;
        });

        let editSubscription = this.settingsSharedService.onEditSettingClick.subscribe(rowId => {
            this.addOrUpdateWebhook(rowId);
        });
        this.subscriptions.push(editSubscription);
        let deleteSubscription = this.settingsSharedService.onDeleteSettingClick.subscribe(rowId => {
            this.onDeleteConfigurationClick(rowId);
        });
        this.subscriptions.push(deleteSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }


    private setupGridOptions(){
        const columnDefs = this.getColumnDefinitions();
        this.gridOptions = {
            columnDefs: columnDefs,
            context: this,
            headerHeight: 50,
            rowHeight: 48,
            suppressRowTransform: true,
            suppressCellSelection: true,
            animateRows: true,
            onGridReady: (params) => {
                this.gridApi = params.api;
            },
            onFirstDataRendered: (params: FirstDataRenderedEvent) => {
                params.api.sizeColumnsToFit();
            },
            defaultColDef: {
                sortable: true
            }
        };
    }

    private getColumnDefinitions() : ColDef[] {
        let colDef:ColDef[] = [
            {
                field: 'webhookName',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.webhookName'),
                comparator: (valueA, valueB) => {
                    return this.stringComparator(valueA.cellValue, valueB.cellValue );
                },
                cellRendererFramework: NewTagLabelCellRendererComponent,
                valueGetter: (params) => {return { cellValue: params.data.webhookName, isNew: params.data.webhookData.isNew } }
            },
            {
                field: 'channelName',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.channelName'),
                sort: 'asc',
                comparator: this.stringComparator,
                valueGetter: (params) => { return this.settings.channels ? this.settings.channels.find(c=>c.id == params.data.webhookData.channelId)?.name : null }
            },
            {
                field: 'channelId',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.channelId'),
                valueGetter: (params) => { return params.data.webhookData.channelId },
            },
            {
                field: 'attributes',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.attributes'),
                valueGetter: (params) => {
                    let numOfSelectedAttributes = params.data.integrationAttributes ? params.data.integrationAttributes.length : 0;
                    
                    return numOfSelectedAttributes + ' ' + this.translate.instant('features.user_settings.body.webhookConfiguration.selected');
                },
                sortable: false
            },
            {
                field: 'actions',
                headerName: '',
                sortable: false,
                cellRendererFramework: EditDeleteCellRendererComponent,
                minWidth: 50,
                maxWidth: 50
            }
        ];
        return colDef;
    }

    public addOrUpdateWebhook(rowId?: string){
        let webhookEntity = null;
        if(rowId){
            webhookEntity = this.gridApi.getRowNode(rowId).data;
        }
        this.router.navigate([`/user-settings/webhookConfiguration/addWebhookConfiguration`], {
            state: webhookEntity
        });
    }

    private stringComparator = (valueA, valueB) => {
        if (!valueA) {
            return -1;
        }
        if (!valueB) {
            return 1;
        }
        return valueA.localeCompare(valueB);
    }

    private updateData(data){
        this.rowData = data;
        this.loading = false;
        this.emptyState = data.length == 0;
    }

    private onDeleteConfigurationClick(rowId: string){
        const modalConf = {
            message: this.translate.instant('features.user_settings.body.webhookConfiguration.deleteWebhookMessage'),
            buttons: [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('features.user_settings.body.webhookConfiguration.deleteWebhook'),
                    action: () => {
                        this.deleteConfiguration(rowId);
                    }
                },
                {
                    class: 'btn-default',
                    label: this.translate.instant('general.CANCEL'),
                    action: () => {
                        this.bsModalRef.hide();
                    }
                }
            ]
        };
        this.optiLogicModalService.openModalMessage(
            'sm',
            modalConf,
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

    private deleteConfiguration(rowId: string){
        let webhook = this.gridApi.getRowNode(rowId).data;
        this.webhookConfigurationService.deleteWebhook(webhook).subscribe(data => {
            this.webhookConfigurationService.getWebhooksList().subscribe(data =>{
                this.updateData(data);
            })
        });
    }
}