<div class="cell-flex" *ngFor="let val of dataTemplate">
    <div>
        <i #pop="bs-popover" class="btn btn-link btn-link-default material-icons previewIcon"
            [attr.disabled]="val.isImageError?'true':null" [popover]="popTemplate" placement="left" [outsideClick]="true"
            container="body" (mouseover)="pop.show()" (mouseout)="pop.hide()">
            remove_red_eye
        </i>
    </div>
    <ng-template #popTemplate class="popover">
        <div class="optiImgPreview">
            <div *ngIf="!val.showImage" class="opti-img-preview-loader-container">
                <span class="loader"></span>
            </div>
            <div *ngIf="val.showImage && val.imgUrl">
                <img src="{{val.imgUrl}}" />
            </div>
        </div>
    </ng-template>
</div>