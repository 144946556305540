export class RoutingConsts {
	public static readonly ROOT = 'category/One-to-One_Campaigns';
	public static readonly LOBBY = 'show';
	public static readonly OPTIMAIL = 'optimail';
	public static readonly OPTIMOBILE = 'optimobile';
	public static readonly WEB_PAGE_POPUP = 'realtimePopup';
	public static readonly OPTI_TEXT_SMS = 'optiTextSms';
	public static readonly WEB_PAGE_POPUP_CHANNEL = 200;
	public static readonly OPTIMOBILE_INAPP_MESSAGES_CHANNEL = 427;
	public static readonly OPTI_TEXT_SMS_CAHNNEL = 493;
	public static readonly OPTIMOBILE_INBOX = 495;
	public static readonly OPTITEXT_WHATSAPP = 498;
	public static readonly OPTIMAIL_CHANNEL = 15;
	public static readonly OPTIMOBILE_CHANNELS = [RoutingConsts.OPTIMOBILE_INAPP_MESSAGES_CHANNEL, 489, 490, RoutingConsts.OPTI_TEXT_SMS_CAHNNEL, RoutingConsts.OPTIMOBILE_INBOX, RoutingConsts.OPTITEXT_WHATSAPP];
	public static readonly MANAGE_TEMPLATES_PART = 'templates';
	public static readonly MANAGE_TEMPLATES_USING_OLD_EDITOR_PART = 'manage-templates-newedit';
}
