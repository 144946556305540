import { Observable } from 'rxjs';
import { AngularRouterUIRouterAdapter } from './angularRouterUIRouterAdapter.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from "@angular/router";
import {stat} from "fs";
import { AngularJsContainer } from '../components/angularJsContainer/AngularJsContainer.component';
import { TranslateService } from '@ngx-translate/core';
import { OptiLogicModalService } from '../components/optiLogicModal/optiLogicModal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';

// ***********************************************************************************
// **  Checks if can navigate to ng6 state from ng1 state or should prompt a guard  **
// ***********************************************************************************

// notice that there's a bug with angular that muddles up history when decatives prevents 
// navigation when going back or forwards - https://github.com/angular/angular/issues/14195 

@Injectable()
export class LegacyGuardDeactived implements CanDeactivate<AngularJsContainer> {
  constructor(private routingAdapter: AngularRouterUIRouterAdapter, 
              private translate: TranslateService,
              private modalService: OptiLogicModalService) {
                
              }

  canDeactivate(component: AngularJsContainer,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      if (!this.routingAdapter.isGuarded()) {
        return true;
      }
      if (route === state['parent']) { // allow navigating to parent route
        return true;
      }
      return this.routingAdapter.promptGuard();
  }
}