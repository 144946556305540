import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewNavbarService } from "./services/newNavbar.service";
import { SideNavbarComponent } from './components/sideNavbar/sideNavbar.component';
import { TopNavbarComponent } from './components/topNavbar/topNavbar.component';
import { ComponentsModule } from "../../components/components.module";
import { RouterModule } from '@angular/router';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    SideNavbarComponent,
    TopNavbarComponent
  ],
  exports: [
    SideNavbarComponent,
    TopNavbarComponent,
  ],
  providers: [
    NewNavbarService
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    PopoverModule
  ]
})
export class NewNavbarModule { }
