import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PreferenceHistoryService } from "../services/preferenceHistory.service";
import { PreferenceCenterService } from "../../../../settings/preferenceCenter/services/preferenceCenter.service";
import { ChannelBrand } from "../../../../settings/preferenceCenter/models/channelBrand.model";
import { ActionType } from "../models/ActionType";
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { Audit } from "../models/preferenceHistoryAuditModel";
import { filter, tap } from 'rxjs/operators';
import { Topic } from "../../../../settings/preferenceCenter/models/topic.model";
import { AttributesManagerService } from "src/app/features/settings/attributes/services/attributesManager.service";
import { BaseAttribute } from "src/app/features/settings/attributes/attributesList.model";

@Component({
    selector: 'preference-history-details',
    templateUrl: './preferenceHistoryDetails.component.html',
    styleUrls: ['./preferenceHistoryDetails.component.scss']
})

export class PreferenceHistoryDetailsComponent implements OnInit, OnDestroy {
    @Input() clientCustomerId: string;

    public isChannelBrandsConfigured: boolean = true;
    public brandGroupName: string = "";
    public preferenceAudits: { [channelId: number] : Audit; } = {};
    public reachability: { [channelId: number] : Audit; } = {};
    public consents: { [channelId: number] : Audit; } = {};
    public channels: number[] = [];
    public isLoading: boolean = true;
    public topics: Topic[] = [];
    attributes: BaseAttribute[];

    private brandGroupId: string = "";
    private selectedDate: Date;
    private subscriptions: Subscription = new Subscription();
    public isError: boolean;
    constructor(private preferenceHistoryService: PreferenceHistoryService,
                private preferenceCenterService: PreferenceCenterService) {}

    ngOnInit() {
        this.initAuditData();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public downloadFile(channelId: number) {
        this.preferenceCenterService.exportAuditsAsCSV(this.clientCustomerId, this.brandGroupId, channelId).subscribe(blob => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = `${this.brandGroupName}_${channelId}_${this.clientCustomerId}.csv`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }, error => {
            console.error('Download failed', error);
        });
    }

    private initAuditData() {
        const selectedBrandGroup = this.preferenceHistoryService.selectedBrandGroup;
        const selectedDate = this.preferenceHistoryService.selectedDate;

        this.subscriptions.add(combineLatest([selectedBrandGroup, selectedDate])
        .pipe(filter(x => x && x[0].id && x[1]!==undefined)).subscribe(([selectedBrandGroup, selectedDate]) => {
            if(this.brandGroupId === selectedBrandGroup.id && this.selectedDate.getTime() === selectedDate.getTime())
                return;
            this.isLoading = true;
            this.isChannelBrandsConfigured = true;
            this.selectedDate = selectedDate;

            if (this.brandGroupId !== selectedBrandGroup.id) {
                this.brandGroupId = selectedBrandGroup.id;
                this.brandGroupName = selectedBrandGroup.name;
                let channelsAll = selectedBrandGroup.channelBrands.map(channelBrand => channelBrand.channelId);
                this.channels = [...new Set(channelsAll)];
            }

            if (selectedBrandGroup.channelBrands === undefined || selectedBrandGroup.channelBrands.length === 0) {
                this.isChannelBrandsConfigured = false;
                this.isLoading = false;
                return;
            }


            const allObservables: Observable<any>[] = []; //preferences, reachability, consents, attributes, topics


            this.channels.forEach(channelId => {
                const preference = this.preferenceCenterService.getAudits(ActionType.Preference, this.clientCustomerId, this.brandGroupId, channelId, this.selectedDate);
                const reachability = this.preferenceCenterService.getAudits(ActionType.Reachability, this.clientCustomerId, this.brandGroupId, channelId, this.selectedDate);
                const consent = this.preferenceCenterService.getAudits(ActionType.Consent, this.clientCustomerId, this.brandGroupId, channelId, this.selectedDate);

                allObservables.push(
                  forkJoin([preference, reachability, consent]).pipe(
                    tap(([preference, reachability, consent]) => {
                        this.preferenceAudits[channelId] = preference.audits[0];
                        this.reachability[channelId] = reachability.audits[0];
                        this.consents[channelId] = consent.audits[0];
                    })
                  )
                );
            });

            allObservables.push(
              this.preferenceCenterService.getAttributes().pipe(
                tap(response => {
                    this.attributes = response?.length ? response : [];
                })
              )
            );

            allObservables.push(
              this.preferenceCenterService.getTopics(this.brandGroupId).pipe(
                tap(topics => {
                    this.topics = topics;
                })
              )
            );


            forkJoin(allObservables).subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: () => {
                    this.isError = true;
                    this.isLoading = false;
                }
            });
        })
        );
    }

}
