import { BaseAttribute, CalculatedAttribute, FormulaCalculationResult } from '../../attributesList.model';
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { first } from 'rxjs/operators';
import { FormulaService } from '../../services/formula.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { IModalConf } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AttributeDataType, AttributeDetails, AttributeFormat, FormulaType } from '../../attributesList.enums';
import { FormatterPipe, IFormatterOptions } from '../../../../../pipes/formatter.pipe';


@Component({
    selector: 'test-formula-modal',
    templateUrl: './testFormulaModal.component.html',
    styleUrls: ['./testFormulaModal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TestFormulaModalComponent implements OnInit {
    @Input() attributes: (BaseAttribute | CalculatedAttribute)[];
    @Input() details:  AttributeDetails[];
    @Input() formula: string;
    @Input() title: string;
    @Input() format: AttributeFormat;
    @Input() ignoreYear: boolean;
    
    public translateKeys = translateKeys;
    public modalConf: IModalConf;
    public inputForm: FormGroup;
    public fieldset: string;
    public attributeFormats: string[] = [];
    public config: TestFormulaModalConfig = {
        isRandomModeSelected: true,
        isRequestSuccess: true,
        isLoadingActive: true,
        customerId: "",
        fields: [],
        formulaResult: "",
        formulaType: "number",
        options: {
            shouldHandleSmallNumbers: true,
            shouldHandleBigNumbers: false,
            decimalPointPrecision: 3
        }
    };

    constructor(
        private bsModalRef: BsModalRef,
        private service: FormulaService,
        private formatter: FormatterPipe) {
        }

    ngOnInit() {
        this.setModalConf();
        this.formValidation();    
        this.fieldset = this.attributes.map(x => x.FieldName).toString(); 
        this.attributeFormats = this.attributes.map(x => x.Formatting);
        this.setFormulaType();
        this.getCustomerData();
        this.setRandomMode(true);
        
    }

    setModalConf(): void {
        this.modalConf = {
            title: translateKeys.testFormula.title,
            xButton: {action: () => this.close()},
            hideFooterBorder: true,
            hideFooterPanel: true,
            hideHeaderBorder: false,
            withScale: false,
        };
    }

    formValidation() {
        this.inputForm = new FormGroup({
            customerId: new FormControl('', [Validators.required]),
        });
    }

    setRandomMode(isRandomMode: boolean) {
        this.config.isRandomModeSelected = isRandomMode;
        if (isRandomMode) {
            this.customerIdFormControl.clearValidators();
            this.customerIdFormControl.updateValueAndValidity();
            this.inputForm.disable();
            this.inputForm.markAsUntouched();
        } else {
            this.customerIdFormControl.setValidators(Validators.required);
            this.customerIdFormControl.updateValueAndValidity();
            this.inputForm.enable();
        }
    }

    getCustomerData() {
        if (this.config.isRandomModeSelected) {
            this.getCustomerDataById();
        }
        else {
            if(this.inputForm.valid) {
                this.getCustomerDataById(this.inputForm.value['customerId']);
            }
        }
    }

    getCustomerDataById(customerId: string = "") {
        this.config.isRequestSuccess = true;
        this.config.isLoadingActive = true;
        const type = this.config.formulaType === "date" ? FormulaType.DateBase 
            : this.config.formulaType === "number" ? FormulaType.FormulaBase : FormulaType.NotSet;
        this.service.getCustomerData(this.formula, this.fieldset, type, this.format, this.attributeFormats, customerId, this.ignoreYear)
            .pipe(first())
            .subscribe((response: DefaultAjaxResponse) => {
                this.config.isLoadingActive = false;
                if(response.IsSuccess) {
                    const data = response.Data as FormulaCalculationResult;
                    this.config.fields = data.attributes;        
                    this.config.customerId = data.customerId;
                    this.config.formulaResult = this.parseResult(data.result, data.format);
                    if (this.config.formulaType === "date") {
                        for (const key in this.config.fields) {
                            const attr = this.config.fields[key];
                            if (this.config.fields[key]) {
                                this.config.fields[key] = new Date(parseInt(this.config.fields[key].replace(/\D/g,'')))
                                    .toLocaleDateString("zh-Hans-CN", { year: 'numeric', month: '2-digit', day: '2-digit' })
                                    .replace(/[/]/g, "-");
                            }
                        }
                    }
                }
                else {
                    this.config.customerId = "-";
                    this.config.isRequestSuccess = false;
                    this.config.formulaResult = "-";
                }
            });
    }

    getValue(fieldName: string): string {
        const value = this.config.fields[fieldName];
        const attribute = this.attributes.find(x => x.FieldName === fieldName);
        if (attribute?.AttributeBaseType === "date") {
            return value ? value : "-";
        }
        if (value == 0) return this.formatter.transform(value, attribute?.Formatting, this.config.options);
        return value ? this.formatter.transform(value, attribute?.Formatting, this.config.options) : "-";
    }

    parseResult(res: any, format: string) {
        if (res || res == 0) {
            switch (this.format) {
                case AttributeFormat.Number:
                    const value = Number.parseInt(res);
                    return value < 0 && this.config.formulaType === "date" 
                        ? `(${-1 * value})` : value;
                case AttributeFormat.Decimal:
                case AttributeFormat.Currency:
                case AttributeFormat.Percentage:
                    return this.formatter.transform(res, format, this.config.options);
                default:
                    return '-';
            }
        } else {
            return 0;
        }
    }

    openCustomer360() {
        if (this.config.customerId && this.config.customerId !== "-") {
            window.open(`/#/category/Analysis_&_Research/customer360?searchStr=${this.config.customerId}&searchType=Equals&customerId=${this.config.customerId}`);
        }
    }

    public closeClick() {
        this.close();
    }

    public close() {
        this.bsModalRef.hide();
    }

    private setFormulaType() {
        this.config.formulaType = this.details.some(x => x.Type === "date")
            ? "date" : "number";
    }

    get customerIdFormControl(): FormControl {
        return this.inputForm.get('customerId') as FormControl;
    }
}

interface TestFormulaModalConfig {
    isRandomModeSelected: boolean;
    isRequestSuccess: boolean;
    isLoadingActive: boolean;
    fields: { [key: string]: any };
    formulaResult: number | string;
    customerId: string;
    formulaType: AttributeDataType;
    options: IFormatterOptions;
}

const translateKeys = {
    testFormula: {
        title: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.TITLE",
        previewUsing: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.PREVIEW_USING",
        randomCustomerData: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.RANDOM_CUSTOMER_DATA",
        idCustomerData: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.CUSTOMER_DATA",
        inputPlaceholder: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.ENTER_CID",  
        generatePrewiew: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.GENERATE_PREVIEW",
        customerId: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.CUSTOMER_ID",
        
        },
    errors: {
        invalidId: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.INVALID_CID",
        invalidFormula: "features.user_settings.body.attributes.addCalculatedAttribute.testFormula.IVALID_FORMULA",
    }
}
