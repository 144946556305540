<div class="preference-history-modal">
    <div class="preference-history-header-container">
        <preference-history-header [clientCustomerId]="clientCustomerId"></preference-history-header>
    </div>
    <div class="preference-history-master-details-container">
        <div class="preference-history-master-container">
            <preference-history-master></preference-history-master>
        </div>
        <div class="preference-history-details-container">
            <preference-history-details [clientCustomerId]="clientCustomerId"></preference-history-details>
        </div>
    </div>
</div>
