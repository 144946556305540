<div class="template-metadata">
  <div class="template-metadata-title" data-qa-id="visual-editor-title">
    {{ "features.manage_templates.optimail.FORM.EMAIL_DETAILS" | translate }}
  </div>

  <div class="template-metadata-form">
    <div class="form-row">
      <div class="row-label">
        <label data-qa-id="visual-editor-subject-title">
          {{ "features.manage_templates.optimail.SUBJECT" | translate }}
        </label>
      </div>
      <div class="row-input">
        <textarea
          [(ngModel)]="template.subject"
          type="text"
          cdkTextareaAutosize
          (ngModelChange)="updateIsDirtyChange()"
          data-qa-id="visual-editor-subject-textarea"></textarea>
        <button
          (click)="openAdvancedSubjectModal()"
          title="{{ 'features.manage_templates.optimail.SUBJECT_TOOLTIP' | translate }}"
          data-qa-id="visual-editor-subject-advanced">
          <i data-qa-id="SubjectAdvancedInputButton" class="material-icons">playlist_add</i>
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="row-label">
        <label data-qa-id="visual-editor-preheader-title">
          {{ "features.manage_templates.optimail.PREHEADER" | translate }}
        </label>
      </div>
      <div class="row-input">
        <textarea
          placeholder="{{ 'features.manage_templates.optimail.FORM.OPTIONAL' | translate }}"
          [(ngModel)]="template.preheader"
          type="text"
          cdkTextareaAutosize
          (ngModelChange)="updateIsDirtyChange()"
          data-qa-id="visual-editor-preheader-textarea"></textarea>
        <button
          (click)="openAdvancedPreviewTextModal()"
          title="{{ 'features.manage_templates.optimail.PREHEADER_TOOLTIP' | translate }}"
          data-qa-id="visual-editor-preheader-advanced">
          <i data-qa-id="PreviewAdvancedInputButton" class="material-icons">playlist_add</i>
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="row-label">
        <label data-qa-id="visual-editor-from-email-title">
          {{ "features.manage_templates.optimail.FORM.EMAIL" | translate }}
        </label>
      </div>
      <div class="row-input" data-qa-id="visual-editor-from-email-block">
        <opti-search-list
          [ngClass]="{'one-element-list': fromEmailList.length === 1}"
          [(ngModel)]="template.fromEmail"
          [items]="fromEmailList"
          [config]="dropdownsConfigBase"></opti-search-list>
      </div>
    </div>

    <div class="form-row">
      <div class="row-label">
        <label data-qa-id="visual-editor-from-name-title">
          {{ "features.manage_templates.optimail.FORM.NAME" | translate }}
        </label>
      </div>
      <div class="row-input">
        <textarea
          placeholder="{{ 'features.manage_templates.optimail.FORM.FROM_NAME_PLACEHOLDER' | translate }}"
          [(ngModel)]="template.fromName"
          type="text"
          cdkTextareaAutosize
          (ngModelChange)="updateIsDirtyChange()"
          data-qa-id="visual-editor-from-name-textarea"></textarea>
        <button
          (click)="openAdvancedFromNameModal()"
          title="{{ 'features.manage_templates.optimail.FROM_NAME_TOOLTIP' | translate }}"
          data-qa-id="visual-editor-from-name-advanced">
          <i data-qa-id="FromNameAdvancedInputButton" class="material-icons">playlist_add</i>
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="row-label">
        <label data-qa-id="visual-editor-reply-to-title">
          {{ "features.manage_templates.optimail.FORM.REPLY_TO" | translate }}
        </label>
      </div>
      <div class="row-input" [ngClass]="{'one-element-list': replyToList.length === 1}" data-qa-id="visual-editor-reply-to-block">
        <opti-search-list
          [(ngModel)]="template.replyTo"
          (ngModelChange)="updateIsDirtyChange()"
          [items]="replyToList"
          [config]="dropdownsConfigBase"></opti-search-list>
      </div>
    </div>

    <div class="form-row" *ngIf="isSuppressionPreferencesEnabled">
      <div class="row-label">
        <label>
          {{ "features.manage_templates.optimail.FORM.SUPPRESSION_GROUP" | translate }}
        </label>
        <div class="preference-tooltip">
          <span
            class="preference-tooltip-icon material-icons"
            [placement]="'top'"
            [containerClass]="'tooltipContainer'"
            tooltip="{{ 'features.manage_templates.optimail.FORM.SUPPRESSION_GROUP_TOOLTIP' | translate }}">
            help_outline
          </span>
        </div>
      </div>
      <div class="row-input">
        <opti-search-list
          [(ngModel)]="template.suppressionGroup"
          (ngModelChange)="updateIsDirtyChange()"
          [items]="preferenceGroupList"
          [config]="dropdownsConfigOptional"></opti-search-list>
      </div>
    </div>
    <div class="form-row" *ngIf="isTopicFieldEnabled">
      <div class="row-label">
        <label>
          {{ "features.manage_templates.optimail.FORM.TOPICS" | translate }}
        </label>
        <div class="preference-tooltip">
          <span
            class="preference-tooltip-icon material-icons"
            [placement]="'top'"
            [containerClass]="'tooltipContainer'"
            tooltip="{{ 'features.manage_templates.optimail.FORM.TOPIC_TOOLTIP' | translate }}">
            help_outline
          </span>
        </div>
      </div>
      <div class="row-input">
        <opti-select
          class="selection"
          [placeholder]="'general.SELECT' | translate"
          [items]="allSelectableTopics"
          [isMultiSelect]="true"
          [shouldShowSelectedValueTooltip]="false"
          [isSelectAllDisabledInMultiMode]="true"
          [(ngModel)]="selectedTopics"
          (ngModelChange)="selectedTopicsChanged($event)"></opti-select>
      </div>
    </div>
    <div class="form-row utm-button">
      <button (click)="editUTMClick()" class="btn btn-link utm-button" data-qa-id="visual-editor-utm">
        <i class="material-icons">my_location</i>
        <span data-qa-id="UTMParametersButton">
          {{ "features.manage_templates.optimail.FORM.TRACKING_PARAMS_BTN" | translate }}
        </span>
      </button>
    </div>
    <last-modified-stamp
      class="last-modified"
      [id]="template.templateId"
      [lastModifiedData]="template.lastModified"
      [shouldShowIdAtBottom]="true"></last-modified-stamp>
  </div>
</div>
