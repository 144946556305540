import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerDataPageComponent } from 'src/app/features/customer360/components/customerDataPage/customerDataPage.component';
import { CustomersListComponent } from 'src/app/features/customer360/components/customersList/customersList.component';
import { C360PageState, SearchType } from 'src/app/features/customer360/customer360.enums';
import { IdClickEvent, ISearchInputValue } from 'src/app/features/customer360/customer360.models';
import { c360TranslateKeys } from 'src/app/features/customer360/customer360.translate.key';

@Component({
	selector: 'customer360-page',
	templateUrl: './customer360Page.component.html',
	styleUrls: ['./customer360Page.component.scss']
})
export class Customer360PageComponent implements OnInit {
	C360PageState = C360PageState;

  // CustomerDataPageComponent and CustomerListComponent have the own internal loader 
  // which is available after the components are initialized.
  // This variable (customer360Child) is used to show/not to show the common loader 
  @ViewChild('customer360Child') customer360Child: CustomerDataPageComponent | CustomersListComponent;

  public translateKeys = c360TranslateKeys;
  public isLoading: boolean = false;
  public currentPageState: C360PageState;
  public currentSearchValue: ISearchInputValue = null;
  public currentCustomerId: string = null;

  constructor(
	  private router: Router,
	  private route: ActivatedRoute,
  ) {
	  this.currentSearchValue = {
		  SearchStr: this.route.snapshot.queryParamMap.get('searchStr'),
		  SearchType: this.route.snapshot.queryParamMap.get('searchType') as SearchType
	  };
	  this.currentCustomerId = this.route.snapshot.queryParamMap.get('customerId');
	  this.subscribeOnRouteReset();
  }

  ngOnInit(): void {
  	this.setupInitPageState();
  }

  onSearch(searchValue: ISearchInputValue) {
  	if (this.customer360Child === undefined || this.customer360Child instanceof CustomerDataPageComponent) {
  		this.isLoading = true;
  	}
  	this.currentPageState = C360PageState.CustomerList;
  	this.currentSearchValue = searchValue;
  }

  onCustomerSelected(event: IdClickEvent) {
  	this.isLoading = true;
  	this.currentCustomerId = event.CustomerId;
  	this.currentPageState = C360PageState.CustomerPage;

  	const queryParams: Params = { customerId: this.currentCustomerId };
  	this.router.navigate(
  		[],
  		{
  			relativeTo: this.route,
  			queryParams: queryParams,
  			queryParamsHandling: 'merge'
  		});
  }

  stopLoading() {
  	this.isLoading = false;
  }

  mock() {
	//
	}

	private subscribeOnRouteReset() {
		this.router.events.subscribe(obj => {
			if (obj instanceof NavigationEnd && obj.url === '/category/Analysis_&_Research/customer360') {
				this.currentCustomerId = null;
				this.currentSearchValue = {
					SearchStr: null,
					SearchType: SearchType.Contains
				};
				this.setupInitPageState();
			}
		});
	}

  private setupInitPageState() {
  	const isCustomerIdExisted = this.currentCustomerId !== null && this.currentCustomerId !== undefined;
  	const isSearchValueExisted = this.currentSearchValue.SearchStr !== null && this.currentSearchValue.SearchStr !== undefined
			&& this.currentSearchValue.SearchType !== null && this.currentSearchValue.SearchType !== undefined;

  	if (isCustomerIdExisted) {
  		this.currentPageState = C360PageState.CustomerPage;
  	} else if (isSearchValueExisted) {
  		this.currentPageState = C360PageState.CustomerList;
  	} else {
  		this.currentPageState = C360PageState.MockMessage;
  	}
  }
}