export class ProfileDetailsModel {
    NodeKey: string;
    Name: string;
    Formula: string;
    Event: any[];
    Parameter: string;
    OptimoveFieldReferenceExist: boolean;
    Status: AttributeStatus;
}

export interface Attribute {
    aggregation: string;
    displayName: string;
    events: AttributeEvent[];
    export: boolean;
    format: string;
    import?: string;
    status: AttributeStatus;
    publishErrorTime?: string;
    isAlreadyPublished:boolean;
}

export interface TableAttribute extends Attribute {
    formula: string;
    eventsType: string[];
    eventsParameter: string;
    exists: boolean; 
    tableDisplayName?: string;
    basedOnFieldName?: string;
}

export interface RealTimeAttributeModel {
    Aggregation: string;
    FieldName: string;
    DataType: string;
    BasedOnAttribute: string;
    Format: Formats;
}

export interface AttributeEvent {
    type: string;
    value?: string;
    valueType?: string;
    conditionField?: string;
    conditionType?: string;
    conditionValue?: string;
}

export type AttributeStatus = "NotPublished" | "Published" | "Publishing";
export type AttributeOption = "Publish" | "Save";

export enum Formats {
    string,
    number,
    currency,
    date
}

export enum ParamTypes {
    text,
    numeric
}

export enum AttributeBasedOnTypes {
    combined,
    eventsOnly
}

export const FormulasDic = {
    COUNT: "Count",
    SUM: "Sum",
    LATEST_BY_OFFSET: "Latest Value"
};

export enum Formulas {
    count = 'COUNT',
    sum = 'SUM',
    latest = 'LATEST_BY_OFFSET'
}

export const Operators = {
    "text": [{
            "key": "extractEquals",
            "value": "Equals"
        },
        {
            "key": "extractNotEquals",
            "value": "Does not equal"
        },
        {
            "key": "extractIsEmpty",
            "value": "Is empty"
        },
        {
            "key": "extractIsNotEmpty",
            "value": "Is not empty"
        }
    ],
    "numeric": [{
            "key": "extractEquals",
            "value": "="
        },
        {
            "key": "extractNotEquals",
            "value": "<>"
        },
        {
            "key": "extractGreaterThan",
            "value": ">"
        }
    ]
};

export interface addAttributeFormData {
    existingAttributes: number;
    brandId: string;
    brandName: string;
    brandTypeName: string;
    open: boolean;
}

export const formatsMapping = {
    string: "VARCHAR(200)",
    number: "INT",
    currency: "DECIMAL(19,4)",
    date: "DATE"
};
