<form [formGroup]="form">
    <div class="condiiton">
        <div class="attribute-input">
            <opti-search-list
                data-qa-id="attribute"
                formControlName="attribute"
                (searchListHide)="selectedAttribute.markAsDirty()"
                [ngClass]="{'red-border-search-list': selectedAttribute.invalid && selectedAttribute.dirty}"
                [config]="attributesSearchListConfig"
                [items]="attributes"
                [disabled]="selectedAttribute.disabled">
            </opti-search-list>
        </div>

        <div class="operator-input">
            <opti-search-list
                data-qa-id="operator"
                formControlName="operator"
                (searchListHide)="selectedOperator.markAsDirty()"
                [ngClass]="{'red-border-search-list': selectedOperator.invalid && selectedOperator.dirty}"
                [config]="operatorsSearchListConfig"
                [items]="operators"
                [disabled]="selectedOperator.disabled">
            </opti-search-list>
        </div>

        <input 
            data-qa-id="value-input"
            formControlName="value"
            *ngIf="valueFormat==='disable'"
            class="value-input form-control"
            type="text"
            [disabled]="true"
            placeholder="{{ translateKeys.value | translate}}">

        <input 
            data-qa-id="value-input"
            formControlName="value"
            *ngIf="valueFormat==='text'"
            class="value-input form-control"
            type="text"
            [ngClass]="{'red-border': value.invalid && value.touched}"
            placeholder="{{ translateKeys.value | translate}}"
            [disabled]="value.disabled">

        <input 
            data-qa-id="value-input"
            formControlName="value"
            *ngIf="!isValueCurrency && valueFormat==='number'"
            class="value-input form-control"
            type="number"
            [ngClass]="{'red-border': value.invalid && value.touched}"
            [disabled]="value.disabled"
            placeholder="{{ translateKeys.value | translate}}"
            [disabled]="value.disabled">

        <div class="currency-value-input" *ngIf="isValueCurrency && valueFormat==='number'">
            <label for="numericInputReg">
                {{currencySymbol}}
            </label>
            <input 
                data-qa-id="value-input"
                formControlName="value"
                class="form-control"
                type="number"
                [ngClass]="{'red-border': value.invalid && value.touched}"
                [disabled]="value.disabled"
                placeholder="{{ translateKeys.value | translate}}"
                id="numericInputReg"
                [disabled]="value.disabled">
        </div>

        <opti-search-list
            data-qa-id="value-input"
            formControlName="value"
            *ngIf="valueFormat==='dropdown' && valueSearchListConfig.isMultiSelect"
            class="dropdown-value-input"
            (searchListHide)="value.markAsDirty()"
            [ngClass]="{'red-border-search-list': value.invalid && value.dirty}"
            [config]="valueSearchListConfig"
            [items]="possibleValues"
            [disabled]="value.disabled">
        </opti-search-list>

        <opti-search-list
            data-qa-id="value-input"
            formControlName="value"
            *ngIf="valueFormat==='dropdown' && !valueSearchListConfig.isMultiSelect"
            class="dropdown-value-input"
            (searchListHide)="value.markAsDirty()"
            [ngClass]="{'red-border-search-list': value.invalid && value.dirty}"
            [config]="valueSearchListConfig"
            [items]="possibleValues"
            [disabled]="value.disabled">
        </opti-search-list>

        <numbers-range-picker
            data-qa-id="value-input"
            class="numbers-picker"
            formControlName="value"
            *ngIf="valueFormat==='between numbers'"
            [currencySymbol]="currencySymbol"
            [disabled]="value.disabled"
            [isPickerTouched]="value.touched">
        </numbers-range-picker>
    </div>
</form>