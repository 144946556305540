import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { HttpService } from "../../services/optimove-http/optimove-http.model";

@Injectable()
export class ShowGroupSizeService {
    previouseTgId: number;
    tgDetails$: Observable<any>;

    constructor(private readonly http: HttpService) {
    }

    getTgSizeByTG(controllerUrl: string, tgId: number, iterationNumber?: number, isDaily?: boolean) : Observable<number> {
        return this.getTGDetails(controllerUrl, tgId).pipe(
            concatMap(targetGroup => {
                const url: string = `${controllerUrl}/GetNumberOfCustomers`;
                let body: any = {
                    tg: targetGroup
                };

                if(isDaily) body.isDaily = isDaily;
                if(iterationNumber) body.iterationNumber = iterationNumber;

                return this.http.post(url,body)
                    .pipe(
                        map((res: any) => (res.TargetGroupSize))
                    );
            })
        )
    }

    public getTGDetails(controllerUrl: string, tgId: number): Observable<any> {
        if (tgId != this.previouseTgId) {
            const url: string = `${controllerUrl}/GetTGDetails`;
            const params: any = {
                tgid: tgId
            };
            this.tgDetails$ = this.http.get(url, HttpService.toHttpParams(params)).pipe(
                map((res: any) => (res.Data)),
            );
        }
        this.previouseTgId = tgId;

        return this.tgDetails$;
    }
}