import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';

@Injectable({
	providedIn: 'root'
})
export class BeefreeEditorService {
	private baseUrl: string = '/ManageTemplates';

	private isBeefreeRowNameAlreadyUsedUrl: string = `${this.baseUrl}/IsBeefreeRowNameAlreadyUsed`;
	private saveBeeFreeSavedRowUrl: string = `${this.baseUrl}/SaveBeefreeSavedRow`;
	private editeRowUrl: string = `${this.baseUrl}/EditBeefreeSavedRow`;
	private deleteCustomRowURL: string = `${this.baseUrl}/RemoveBeefreeSavedRow`;

	constructor(private readonly http: HttpService) {}

	public isBeefreeRowNameAlreadyUsed(body: { name: string; brandId: string; saveForAllOrForOneFlag: string }): Observable<string> {
		return this.http.get<AjaxResponse<string>>(this.isBeefreeRowNameAlreadyUsedUrl,HttpService.toHttpParams(body)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}
	public saveBeefreeSavedRow(body: { rowObject: string }): Observable<string> {
		return this.http.post<AjaxResponse<string>>(this.saveBeeFreeSavedRowUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public EditeCustomRow(body: { rowObject: string; editedRow: string }): Observable<any> {
		return this.http
			.post(this.editeRowUrl, body)
			.pipe(catchError((error) => of(error)))
			.pipe(map((res) => res));
	}

	public DeleteCustomRow(customRow: string): Observable<any> {
		const rowObject = JSON.stringify(customRow);
		return this.http
			.post(this.deleteCustomRowURL, { rowObject })
			.pipe(catchError((error) => of(error)))
			.pipe(map((res) => res));
	}

	public transformExtraDataToServerFormat(extraDataJson) {
		let extraData = JSON.parse(JSON.stringify(extraDataJson));

		for (let i = 0; i < extraData.length; i++) {
			if (extraData[i]) {
				var deepLinkParamsObj = this.transformADeeplinkParamToServerFormat(extraData[i].deepLinkParams);
				if (deepLinkParamsObj && Object.keys(deepLinkParamsObj)?.length > 0) {
					extraData[i].deepLinkParams = deepLinkParamsObj;
				} else {
					delete extraData[i].deepLinkParams;
				}
			}
		}
		return JSON.stringify(extraData);
	}

	public transformADeeplinkParamToServerFormat(deeplinkParamsObjSrc) {
		let deepLinkParamsObjDest = {};

		if (deeplinkParamsObjSrc) {
			deeplinkParamsObjSrc.forEach(function (param) {
				if (param.paramKey && param.paramValue) {
					deepLinkParamsObjDest[param.paramKey] = param.paramValue;
				}
			});
		}

		return deepLinkParamsObjDest;
	}

	public transformExtraDataToClientFormat(extraDataString: string): any {
		let extraDataArr = JSON.parse(extraDataString);

		for (let i = 0; i < extraDataArr.length; i++) {
			let extraDataObj = extraDataArr[i];
			if (extraDataObj && extraDataObj.deepLinkParams && !Array.isArray(extraDataObj.deepLinkParams)) {
				var deepLinksParamsArr = [];
				Object.keys(extraDataObj.deepLinkParams).forEach(function (paramName) {
					var paramsObj = {} as any;
					paramsObj.keyTouched = true;
					paramsObj.valueTouched = true;

					paramsObj.paramKey = paramName;
					paramsObj.paramValue = extraDataObj.deepLinkParams[paramName];
					deepLinksParamsArr.push(paramsObj);
				});
				extraDataArr[i].deepLinkParams = deepLinksParamsArr;
			}
		}
		return extraDataArr;
	}

	private extractData<T>(response?: AjaxResponse<T>) {
		if (response?.isSuccess === false) {
			return response;
		}
		if (response?.data === null && response?.isSuccess) {
			return response;
		}
		if (response?.data !== undefined) {
			return response.data;
		}
		if ((response as any)?.Data !== undefined) {
			return (response as any).Data;
		}
		return response;
	}
}
