import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import { BeeFreeCustomFont, BeeFreePluginSetupData } from '../models/beefree';
import { ContentTag } from '../models/contentTag';
import { CreateUpdateFolderRequest, CreateUpdateFolderResponse } from '../models/createUpdateFolder';
import { EmailPreview } from '../models/emailPreview';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { EmailData, SuppressionList, TemplatesMetadata } from '../models/metadataResponse';
import { TemplateBeefreeObjectToTextRequest, TemplateHtmlToTextRequest } from '../models/templatePlainText';
import { AggregatedPersonalizationTag, SendAndPreviewInfo, TemplatePreviewRequest } from '../models/templatePreview';
import { TemplateSearchResult, TemplateTreeItem } from '../models/templateResponse';
import { TemplateSaveUpdateRequest, TemplateSaveUpdateResponse, ValidationOutput } from '../models/templateSaveUpdate';
import { TemplateTestEmailRequest } from '../models/templateTestEmail';
import { TemplateRequest } from '../models/templatesRequest';
import { UnsubscribeMultiLanguageTemplate } from '../models/unsubscribeMultiLanguageTemplate';
import { UpsertUtmParamsInAllHrefInTemplate } from '../models/upsertUtmParamsInAllHrefInTemplateResponse';
import { LocalStorageCacheService } from './localStorageCache.service';
import { LanguageTemplate } from '../models/languageTemplate';
import { NumberOfLanguagesPerTemplate, TemplateInUse } from '../models/templateInUse';
import { TemplateContentPart } from '../models/templateContentPart';
import { TemplateDetails } from '../models/templateDetails';
import { GenerateUtm, UpsertUtm } from '../models/utm';
import { TemplateAvailableLanguage } from '../models/templateAvailableLanguage';
import { TranslationSearchResult } from '../models/translationSearch';

@Injectable({
	providedIn: 'root'
})
export class ManageTemplatesService {
	private baseUrl: string = '/ManageTemplates';
	private baseOptimailUrl: string = '/Optimail';
	private baseMainUrl: string = '/Main';
	private readonly gRecaptchaHeader: string = 'g-recaptcha-required';

	private getMetadataUrl: string = `${this.baseUrl}/GetMetaData`;
	private getSuppressionGroupsUrl: string = `${this.baseUrl}/GetSuppressionGroups`;
	private getTemplatesUrl: string = `${this.baseUrl}/GetTemplates`;
	private getGetTemplateDetailsUrl: string = `${this.baseUrl}/GetTemplateDetails`;
	private generatetUtmParamsInHrefUrl: string = `${this.baseUrl}/GeneratetUtmParamsInHref`;
	private upsertUtmParamsInAllHrefInTemplateUrl: string = `${this.baseUrl}/UpsertUtmParamsInAllHrefInTemplate`;
	private getFolderTemplatesUrl: string = `${this.baseUrl}/GetFolderTemplates`;
	private findTemplatesUrl: string = `${this.baseUrl}/FindTemplates`;
	private isTemplateUnderUseUrl: string = `${this.baseOptimailUrl}/IsTemplateUnderUse`;
	private deleteTemplateUrl: string = `${this.baseUrl}/DeleteTemplate`;
	private UpdateIsDeletedForBatchUrl: string = `${this.baseUrl}/UpdateIsDeletedForBatch`;
	private deleteMasterIfNeededUrl: string = `${this.baseUrl}/DeleteMasterIfNeeded`;
	private getTargetGroupTableDataUrl: string = `${this.baseUrl}/TargetGroupTableData`;
	private checkPersonaliztionsInTemplateUrl: string = `${this.baseUrl}/CheckIfTemplateHasPersonalizationTags`;
	private duplicateTemplateUrl: string = `${this.baseUrl}/DuplicateTemplate`;
	private checkFolderNameUrl: string = `${this.baseUrl}/IsFolderNameAlreadyUsed`;
	private saveAndUpdateFolderUrl: string = `${this.baseUrl}/SaveAndUpdateFolder`;
	private isFolderNameAlreadyUsedUrl: string = `${this.baseUrl}/IsFolderNameAlreadyUsed`;
	private getUsedTemplatesUnderFolderUrl: string = `${this.baseUrl}/GetUsedTemplatesUnderFolder`;
	private deleteFolderUrl: string = `${this.baseOptimailUrl}/DeleteFolder`;
	private getTemplatesIsInUseUrl: string = `${this.baseUrl}/GetTemplatesIsInUse`;
	private getNumberOfLanguagesUrl: string = `${this.baseUrl}/GetNumberOfLanguages`;
	private getBeeFreePluginSetupDataUrl: string = `${this.baseUrl}/GetBeeFreePluginSetupData`;
	private isTemplateNameUniqueUrl: string = `${this.baseOptimailUrl}/IsTemplateNameUnique`;
	private saveAndUpdateTemplateUrl: string = `${this.baseUrl}/SaveAndUpdateTemplate`;
	private htmlToTextUrl: string = `${this.baseUrl}/HtmlToText`;
	private sendTestEmailUrl: string = `${this.baseUrl}/SendTestEmail`;
	private getPlainTextFromBeefreeObjectUrl: string = `${this.baseUrl}/GetPlainTextFromBeefreeObject`;
	private getEmailDataUrl: string = `${this.baseUrl}/GetEmailData`;
	private getEmailPreviewEnabledUrl: string = `${this.baseUrl}/GetEmailPreviewEnabled`;
	private getTargetGroupsUrl: string = `${this.baseMainUrl}/GetTargetGroups`;
	private getEmailPreviewUrl: string = `${this.baseUrl}/GetEmailPreview`;
	private previewEvaluationUrl: string = `${this.baseUrl}/PreviewEvaluation`;
	private getInfoForSendTestAndPreviewUrl: string = `${this.baseOptimailUrl}/GetInfoForSendTestAndPreview`;
	private getAggregatedPersonalizationTagsByBrandTypeUrl: string = `${this.baseOptimailUrl}/GetAggregatedPersonalizationTagsByBrandType`;
	private sendBeeFreeNotification: string = `${this.baseUrl}/SendBeeFreeCommentsNotifications`;
	private getValidationOutputUrl: string = `${this.baseUrl}/GetValidationOutput`;
	private updateTemplateFolder: string = `${this.baseUrl}/UpdateTemplateFolder`;
	private updateTemplatesFolderUrl = `${this.baseUrl}/UpdateTemplatesFolder`;
	private getManageTemplatesUrlUrl: string = `${this.baseUrl}/GetManageTemplatesUrl`;
	private getContentTagsUrl: string = `${this.baseOptimailUrl}/GetContentTags`;
	private getAggregatedUnsubscribeMultiLanguageTemplatesUrl: string = `${this.baseOptimailUrl}/GetAggregatedUnsubscribeMultiLanguageTemplates`;
	private createContentTagUrl: string = `${this.baseOptimailUrl}/CreateContentTag`;
	private updateContentTagUrl: string = `${this.baseOptimailUrl}/UpdateContentTag`;
	private getBeeFreeFontsUrl: string = `${this.baseUrl}/GetBeeFreeFonts`;
	private getTemplateLanguagesUrl: string = `${this.baseUrl}/GetTemplateLanguages`;
	private syncTranslationsUrl: string = `${this.baseUrl}/SyncTranslations`;
	private getContentPartsUrl: string = `${this.baseUrl}/GetContentParts`;
	private getExecutionTemplateUrl: string = `${this.baseUrl}/GetExecutionTemplate`;
	private getUpdateAvailableLanguagesUrl: string = `${this.baseUrl}/UpdateAvailableLanguages`;
	private searchTranslationsUrl: string = `${this.baseUrl}/SearchTranslations`;

	constructor(private readonly http: HttpService, private cache: LocalStorageCacheService) {}

	public getTemplateLanguage(): Observable<LanguageTemplate[]> {
		return this.cache.getOrCreate(this.getTemplateLanguagesUrl, this.http.get(this.getTemplateLanguagesUrl));
	}
	public getValidationOutput(
		body: { subMethodId: number; channelId: number; templateJson: string },
		isRecaptchaRequired: boolean
	): Observable<ValidationOutput> {
		const headers = {};
		headers[this.gRecaptchaHeader] = `${isRecaptchaRequired}`;
		return this.http
			.post<AjaxResponse<ValidationOutput>>(this.getValidationOutputUrl, body, {
				headers
			})
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getMetadata(): Observable<TemplatesMetadata> {
		return this.cache.getOrCreate(this.getMetadataUrl, this.http.get<AjaxResponse<TemplatesMetadata>>(this.getMetadataUrl)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getEmailData(): Observable<EmailData> {
		return this.cache.getOrCreate(this.getEmailDataUrl, this.http.get<AjaxResponse<EmailData>>(this.getEmailDataUrl)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getSuppressionGroups(): Observable<SuppressionList> {
		const timeout = 60 * 60 * 1000; // 1 hour
		return this.cache.getOrCreate(
			this.getSuppressionGroupsUrl,
			this.http.get<AjaxResponse<SuppressionList>>(this.getSuppressionGroupsUrl).pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			),
			timeout
		);
	}

	public getTargetGroupTableData(body: { targetGroup: number; channelID: number; templateJson: string }): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.getTargetGroupTableDataUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public moveFolderOrTemplate(body: { nodeId: number; parentId: number; isFolder: boolean; subMethodId: number }): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.updateTemplateFolder, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public moveBatchOfTemplates(body: { templateIds: number[]; newFolderId: number; subMethodId: number }): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.updateTemplatesFolderUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public checkIfPersonalizationInfoExits(body: { channelID: number; templateJson: string }): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.checkPersonaliztionsInTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getTemplates(body: TemplateRequest): Observable<TemplateTreeItem[]> {
		const { subMethodId, channelId } = body;
		return this.http
			.get<AjaxResponse<TemplateTreeItem[]>>(this.getTemplatesUrl, HttpService.toHttpParams({ subMethodId, channelId }))
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getTemplateDetails(body: { templateId: string }): Observable<TemplateDetails> {
		if (!body?.templateId) {
			return of({} as TemplateDetails);
		}
		return this.http.get<AjaxResponse<TemplateDetails>>(this.getGetTemplateDetailsUrl, HttpService.toHttpParams(body)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public findTemplates(body: {
		channelId: number;
		subMethodIds: number[];
		searchText: string;
		sortingType: number;
	}): Observable<TemplateSearchResult[]> {
		return this.http.post<AjaxResponse<TemplateSearchResult[]>>(this.findTemplatesUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getFolderTemplates(body: TemplateSearchResult[]): Observable<FolderTemplatePreview[]> {
		return this.http.post<AjaxResponse<FolderTemplatePreview[]>>(this.getFolderTemplatesUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public sendBeeFreeCommentsNotifications(body: { notificationsData; channelId; brandId; templateId; templateName }): Observable<number> {
		return this.http.post<AjaxResponse<any>>(this.sendBeeFreeNotification, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public isTemplateUnderUse(body: { templateId: number }): Observable<{ IsUnderUse: boolean }> {
		return this.http.get<AjaxResponse<{ IsUnderUse: boolean }>>(this.isTemplateUnderUseUrl, HttpService.toHttpParams(body)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public deleteTemplate(body: { templateId: number; channelId: number; subMethodId: number }): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.deleteTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public updateIsDeletedForBatch(body: {
		templateIdsWithSubMethod: { templateId: number; subMethodId: number }[];
		isDeleted: boolean;
	}): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.UpdateIsDeletedForBatchUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public deleteMasterIfNeeded(body: { folderId: number }): Observable<string> {
		return this.http.post<AjaxResponse<string>>(this.deleteMasterIfNeededUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public duplicateTemplate(body: { templateId: number; channelId: number }): Observable<TemplateDetails> {
		return this.http.post<AjaxResponse<TemplateDetails>>(this.duplicateTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public copyTemplate(body: {
		templateId: number;
		channelId: number;
		folderId: number;
		newSubMethodId: number;
	}): Observable<TemplateDetails> {
		return this.http.post<AjaxResponse<TemplateDetails>>(this.duplicateTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public isFolderNameAlreadyUsed(body: { proposedName: string; subMethodId: number; folderId: number }): Observable<boolean> {
		return this.http.get<AjaxResponse<boolean>>(this.isFolderNameAlreadyUsedUrl, HttpService.toHttpParams(body)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public saveAndUpdateFolder(body: CreateUpdateFolderRequest): Observable<CreateUpdateFolderResponse> {
		return this.http.post<AjaxResponse<CreateUpdateFolderResponse>>(this.saveAndUpdateFolderUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public generatetUtmParamsInHref(body: GenerateUtm): Observable<string> {
		return this.http.post<AjaxResponse<string>>(this.generatetUtmParamsInHrefUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public upsertUtmParamsInAllHrefInTemplate(body: UpsertUtm): Observable<UpsertUtmParamsInAllHrefInTemplate> {
		return this.http.post<AjaxResponse<UpsertUtmParamsInAllHrefInTemplate>>(this.upsertUtmParamsInAllHrefInTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}
	public getUsedTemplatesUnderFolder(body: { folderId: number }): Observable<{ templateName: string }[]> {
		return this.http
			.get<AjaxResponse<{ templateName: string }[]>>(this.getUsedTemplatesUnderFolderUrl, HttpService.toHttpParams(body))
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public deleteFolder(body: { id: number }): Observable<null> {
		return this.http.post<AjaxResponse<null>>(this.deleteFolderUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getTemplatesIsInUse(body: {
		templateIdsWithSubMethod: { templateId: number; subMethodId: number }[];
	}): Observable<TemplateInUse[]> {
		return this.http.post<AjaxResponse<TemplateInUse[]>>(this.getTemplatesIsInUseUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getNumberOfLanguages(body: {
		templateIdsWithSubMethod: { templateId: number; subMethodId: number }[];
	}): Observable<NumberOfLanguagesPerTemplate[]> {
		return this.http.post<AjaxResponse<NumberOfLanguagesPerTemplate[]>>(this.getNumberOfLanguagesUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}
	public getBeeFreePluginSetupData(editorType = 'Email'): Observable<BeeFreePluginSetupData> {
		return this.http
			.get<AjaxResponse<BeeFreePluginSetupData>>(this.getBeeFreePluginSetupDataUrl, HttpService.toHttpParams({ editorType }))
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public isTemplateNameUnique(body: { templateName: string; templateId: number; subMethodId: number }): Observable<boolean> {
		return this.http
			.get(this.isTemplateNameUniqueUrl, { ...HttpService.toHttpParams(body), responseType: 'text' })
			.pipe(
				catchError((error) => of(error)),
				map((res: string) => res?.toLowerCase() === 'true')
			);
	}

	public checkFolderName(proposedName: string, subMethodId: number, folderId: number) {
		return this.http
			.get<AjaxResponse<boolean>>(
				this.checkFolderNameUrl,
				HttpService.toHttpParams({
					proposedName: proposedName,
					subMethodId: subMethodId,
					folderId: folderId
				})
			)
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public convertHtmlToText(body: TemplateHtmlToTextRequest): Observable<string> {
		return this.http.post<AjaxResponse<string>>(this.htmlToTextUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getPlainTextFromBeefreeObject(body: TemplateBeefreeObjectToTextRequest): Observable<string> {
		return this.http.post<AjaxResponse<string>>(this.getPlainTextFromBeefreeObjectUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public sendTestEmail(body: TemplateTestEmailRequest): Observable<any> {
		return this.http.post<AjaxResponse<any>>(this.sendTestEmailUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public saveAndUpdateTemplate(body: TemplateSaveUpdateRequest, isRecaptchaRequired: boolean): Observable<TemplateSaveUpdateResponse> {
		const headers = {};
		headers[this.gRecaptchaHeader] = `${isRecaptchaRequired}`;
		return this.http
			.post<TemplateSaveUpdateResponse>(this.saveAndUpdateTemplateUrl, body, {
				headers
			})
			.pipe(catchError((error) => of(error)));
	}
	
	public saveAndUpdateTemplateUnsafe(body: TemplateSaveUpdateRequest, isRecaptchaRequired: boolean): Observable<TemplateSaveUpdateResponse> {
		const headers = {};
		headers[this.gRecaptchaHeader] = `${isRecaptchaRequired}`;
		return this.http
			.post<TemplateSaveUpdateResponse>(`${this.baseUrl}/SaveAndUpdateTemplateUnsafe`, body, {
				headers
			})
			.pipe(catchError((error) => of(error)));
	}

	public getEmailPreviewEnabled(): Observable<boolean> {
		return this.http.get<AjaxResponse<boolean>>(this.getEmailPreviewEnabledUrl).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getEmailPreview(body: { html: string; subject: string; fromName: string }): Observable<EmailPreview[]> {
		return this.http.post<AjaxResponse<EmailPreview[]>>(this.getEmailPreviewUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getTargetGroups(): Observable<{ id: number; value: string }[]> {
		return this.http
			.get<AjaxResponse<{ data: { id: number; value: string }[] }>>(
				this.getTargetGroupsUrl,
				HttpService.toHttpParams({ includeUnsegmentedVisitors: true })
			)
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res.data))
			);
	}

	public evaluateTemplate(body: TemplatePreviewRequest): Observable<{ html: string; subject: string }> {
		return this.http.post<AjaxResponse<{ html: string; subject: string }>>(this.previewEvaluationUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getInfoForSendTestAndPreview(channelId: string): Observable<SendAndPreviewInfo> {
		return this.http
			.get<AjaxResponse<SendAndPreviewInfo>>(this.getInfoForSendTestAndPreviewUrl, HttpService.toHttpParams({ channelId }))
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getAggregatedPersonalizationTagsByBrandType(subMethodType: number): Observable<AggregatedPersonalizationTag[]> {
		return this.http
			.get<AjaxResponse<AggregatedPersonalizationTag[]>>(
				this.getAggregatedPersonalizationTagsByBrandTypeUrl,
				HttpService.toHttpParams({ subMethodType })
			)
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getManageTemplatesUrl(): Observable<{ url: string; token: string; optimobileUrls: string }> {
		return this.http.get<AjaxResponse<{ url: string; token: string }>>(this.getManageTemplatesUrlUrl, {}).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getAggregatedUnsubscribeMultiLanguageTemplates(subMethodId: number): Observable<UnsubscribeMultiLanguageTemplate[]> {
		return this.http
			.get<AjaxResponse<UnsubscribeMultiLanguageTemplate[]>>(
				this.getAggregatedUnsubscribeMultiLanguageTemplatesUrl,
				HttpService.toHttpParams({ subMethodId })
			)
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getContentTags(names?: string[]): Observable<ContentTag[]> {
		return this.http.post<AjaxResponse<ContentTag[]>>(this.getContentTagsUrl, { names }).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public createContentTag(tag: ContentTag): Observable<ContentTag | AjaxResponse<string>> {
		tag.Content = encodeURIComponent(tag.Content);
		return this.http.post<AjaxResponse<ContentTag>>(this.createContentTagUrl, tag).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public updateContentTag(tag: ContentTag): Observable<ContentTag | AjaxResponse<string>> {
		tag.Content = encodeURIComponent(tag.Content);
		return this.http.post<AjaxResponse<ContentTag>>(this.updateContentTagUrl, tag).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public syncTranslations(subMethodId: number, templateId: number): Observable<TemplateContentPart[]> {
		return this.http.post<AjaxResponse<TemplateContentPart[]>>(this.syncTranslationsUrl, { subMethodId, templateId }).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	public getContentParts(subMethodId: number, templateId: number): Observable<TemplateContentPart[]> {
		return this.http
			.get<AjaxResponse<TemplateContentPart[]>>(this.getContentPartsUrl, HttpService.toHttpParams({ subMethodId, templateId }))
			.pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			);
	}

	public getExecutionTemplate(body: {
		channel: number;
		brand: string;
		templateId: number;
		languages: string[];
	}): Observable<TemplateDetails[]> {
		return this.http.post<AjaxResponse<TemplateDetails[]>>(this.getExecutionTemplateUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => (res?.Data ? JSON.parse(res.Data) : res))
		);
	}

	private extractData<T>(response?: AjaxResponse<T>) {
		if (response?.isSuccess === false) {
			return response;
		}
		if (response?.data === null && response?.isSuccess) {
			return response;
		}
		if (response?.data !== undefined) {
			return response.data;
		}
		if ((response as any)?.Data !== undefined) {
			return (response as any).Data;
		}
		return response;
	}

	public getBeeFreeFonts(): Observable<BeeFreeCustomFont[]> {
		return this.cache.getOrCreate(
			this.getBeeFreeFontsUrl,
			this.http.get<AjaxResponse<BeeFreeCustomFont[]>>(this.getBeeFreeFontsUrl).pipe(
				catchError((error) => of(error)),
				map((res) => this.extractData(res))
			)
		);
	}

	updateAvailableLanguages(body: TemplateAvailableLanguage[]) {
		return this.http.post<AjaxResponse<TemplateDetails[]>>(this.getUpdateAvailableLanguagesUrl, body).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	searchTranslations(searchQuery: string): Observable<TranslationSearchResult> {
		return this.http.get<TranslationSearchResult>(this.searchTranslationsUrl, HttpService.toHttpParams({ searchQuery })).pipe(
			catchError(() => of({ strings: [] }))
		);
	}

	getCustomerLanguage(args: { subMethodType: number, customerId: string, clientCustomerId: string }): Observable<string> {
		return this.http.get<AjaxResponse<string>>(`${this.baseUrl}/GetCustomerLanguage`, HttpService.toHttpParams(args)).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}

	getRandomCustomerId(): Observable<{ customerId: string, clientCustomerId: string }> {
		return this.http.get<AjaxResponse<{ customerId: string, clientCustomerId: string }>>(`${this.baseUrl}/GetRandomCustomerId`).pipe(
			catchError((error) => of(error)),
			map((res) => this.extractData(res))
		);
	}
}
