<div class="new-folder-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div *ngIf="errorDuplicate" class="duplicate-folder-name">
      {{ "features.manage_templates.components.newFolderModal.DUPLICATE_FOLDER_NAME" | translate }}
    </div>
    <form [formGroup]="form" class="form-container">
      <div class="new-folder-form-code">
        <input
          class="form-control"
          type="text"
          formControlName="newFolderName"
          [placeholder]="'features.manage_templates.components.newFolderModal.PLACEHOLDER' | translate" />
      </div>
    </form>
  </opti-logic-modal>
</div>
