import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Channel } from '../interfaces';

@Component({
    selector: 'campaign-grid-template-column',
    templateUrl: 'campaignGridTemplateColumn.component.html',
    styleUrls: ['./campaignGridTemplateColumn.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CampaignGridTemplateColumnComponent implements ICellRendererAngularComp {
    public dataTemplate: Channel[] = [];
    public propName: string = 'TemplateName';
    ssm: any;

    constructor(@Inject('ssmService') private ssmService: any) {
        this.ssm = this.ssmService.getSSM();
    }


    agInit(params: any): void {
        this.dataTemplate = params.data?.Channels?.map(x => {
            return {
                Id: x.Id,
                Name: x.Name,
                TemplateId: x.TemplateId,
                TemplateName: x.TemplateName
            }
        }) || [];
        this.propName = params.propName;
    }

    refresh(): boolean {
        return true;
    }
}
