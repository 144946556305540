import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { IModalConf } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { SearchItem } from "src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { OptiSideBarComponent } from "src/app/features/customer360/components/optiSideBar/optiSideBar.component";
import { ICustomerAttribute, ISelectableAttribute } from "src/app/features/customer360/customer360.models";

@Injectable()
export class Customers360ModalService {
    constructor(private modalService: BsModalService) {}

    public getAttributesSearchItems(customerAttributes: { [key: string]: ICustomerAttribute }): SearchItem[] {
        const attributesSearchItems = Object.values(customerAttributes)
            .filter((attr: ICustomerAttribute) => !attr.IsHidden)
            .map((attr: ICustomerAttribute) => {
                return {
                    FieldName: attr.Name,
                    AliasName: attr.Alias
                };
            });

        // Add Customer ID as attribute
        attributesSearchItems.push({ FieldName: "CLIENT_CUSTOMER_ID", AliasName: "CUSTOMER ID" });

        return attributesSearchItems;
    }

    openSideBar(config: IModalConf, attributes: ISelectableAttribute[], preDoneAction: Function): BsModalRef {
        const modalConfig = {
            animated: true,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog-right side-bar-right',
            initialState: {
                config: config,
                attributes: attributes,
                onDone: (attributesKeys: string[]) => {
                    preDoneAction(attributesKeys)
                }
            }
        }

        return this.modalService.show(OptiSideBarComponent, modalConfig);
    }
}