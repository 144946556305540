<div class="card-loader">
  <ul [@fadeIn] class="o-vertical-spacing o-vertical-spacing--l loading-cards-list">
    <li class="blog-post o-media" *ngFor="let index of items">
      <div class="o-media__figure">
        <span class="skeleton-box" style="width: 110px; height: 90px"></span>
      </div>
      <div class="o-media__body">
        <div class="o-vertical-spacing">
          <h3 class="blog-post__headline">
            <span class="skeleton-box" style="width: 55%"></span>
          </h3>
          <p>
            <span class="skeleton-box" style="width: 55%"></span>
          </p>
          <div class="blog-post__meta">
            <span class="skeleton-box" style="width: 40%"></span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
