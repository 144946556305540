import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { FeatureFlagService, FeatureFlag } from '../../services/featureFlag.service';

export class DeprecationPageGuard {

    static DeprecateforFlag(ff: FeatureFlag, destPath: string) { 
        @Injectable({ providedIn: 'root' })
        class DeprecationCheck implements CanActivate { 
            constructor(private featureFlagService: FeatureFlagService, private router: Router) {

            }
        
            canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
                if (this.featureFlagService.isEnabled(ff)) {
                    return of(true);
                } else {
                    this.router.navigate([destPath]);
                    return of(false);
                }
            }
        }
        return DeprecationCheck;
    }
}