import { Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import { Router } from "@angular/router";

@Component({
    selector:'edit-btn-cell-render',
    templateUrl: './editBtnCellRender.component.html',
    styleUrls: ['./editBtnCellRender.component.scss']
})

export class EditBtnCellRenderComponent implements  ICellRendererAngularComp{
    private params: any

    constructor(private router: Router ) {}

    agInit(params:any) {
        this.params = params;
    }

    routeToEditBrand(): void{
        this.router.navigate(['/user-settings/optimail/editBrand'], {state: this.params.data})
    }

    refresh(params: any): boolean {
        return true;
    }

}