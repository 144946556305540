import { Injectable } from '@angular/core';
import { RoutingConsts } from '../models/routing.consts';
import { Channel, TemplatesMetadata } from '../models/metadataResponse';
import { Observable, Subject, Subscriber } from 'rxjs';
import { LocalStorageCacheService } from './localStorageCache.service';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';

@Injectable({
	providedIn: 'root'
})
export class ChannelService {
	private $current: Subject<Channel> = new Subject<Channel>();
	public current: Observable<Channel> = this.$current.asObservable();

	private baseUrl: string = '/ManageTemplates';
	private getMetadataUrl: string = `${this.baseUrl}/GetMetaData`;

	constructor(private cache: LocalStorageCacheService, private httpService: HttpService) {}

	isOptimobile(channelId: number): boolean {
		return RoutingConsts.OPTIMOBILE_CHANNELS.includes(channelId);
	}

	isOptimail(channelId: number): boolean {
		return RoutingConsts.OPTIMAIL_CHANNEL === channelId;
	}

	isWebPopup(channelId: number): boolean {
		return channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL;
	}

	isOptimobileInapp(channelId: number): boolean {
		return channelId === RoutingConsts.OPTIMOBILE_INAPP_MESSAGES_CHANNEL;
	}

	isOptiText(chanelId: number): boolean {
		return chanelId === RoutingConsts.OPTI_TEXT_SMS_CAHNNEL;
	}

	isWhatsApp(channelId: number): boolean {
		return channelId === RoutingConsts.OPTITEXT_WHATSAPP;
	}

	setCurrent(channel: Channel): void {
		this.$current.next(channel);
	}

	getChannelNameById(channelId: number): Observable<string> {
		return new Observable<string>((observer: Subscriber<string>) => {
			this.cache
				.getOrCreate(this.getMetadataUrl, this.httpService.get<AjaxResponse<TemplatesMetadata>>(this.getMetadataUrl))
				.pipe(map((res) => res.data))
				.subscribe((metadata) => {
					const channelIds = Object.keys(metadata.channels);
					const channels = channelIds.map((id) => metadata.channels[id]);
					const filteredChannel = channels.find((channel) => channel.id == channelId);
					filteredChannel !== undefined ? observer.next(filteredChannel.name) : observer.next('');
					observer.complete();
				});
		});
	}
}
