import { Component, OnInit } from "@angular/core";
import { PreferenceCenterService } from "../../../../settings/preferenceCenter/services/preferenceCenter.service";
import { BrandGroup } from "../../../../settings/preferenceCenter/models/brandGroup.model";
import { PreferenceHistoryService } from "../services/preferenceHistory.service";

@Component({
    selector: 'preference-history-master',
    templateUrl: './preferenceHistoryMaster.component.html',
    styleUrls: ['./preferenceHistoryMaster.component.scss']
})

export class PreferenceHistoryMasterComponent implements OnInit {
    public brandGroups: BrandGroup[] = [];
    public selectedBrandGroup: BrandGroup;

    constructor(private preferenceHistoryService: PreferenceHistoryService,
                private preferenceCenterService: PreferenceCenterService) {}

    ngOnInit() {
        this.initBrandGroups();
    }

    public updateSelectedBrand(brandGroup){
        if (this.selectedBrandGroup.id !== brandGroup.id) {
            this.selectedBrandGroup.isOpen = false;
            this.selectedBrandGroup = brandGroup
            this.selectedBrandGroup.isOpen = true;
            this.preferenceHistoryService.updateSelectedBrandGroup(this.selectedBrandGroup)
        }
    }

    private initBrandGroups() {
        this.preferenceCenterService.getBrandGroups().subscribe((res) => {
            if (res?.length) {
                this.brandGroups = res;
                this.brandGroups[0].isOpen = true;
                this.selectedBrandGroup = this.brandGroups[0];
                this.preferenceHistoryService.updateSelectedBrandGroup(this.selectedBrandGroup);
            }
        })
    }
}
