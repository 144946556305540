import {Injectable} from "@angular/core";
import {TreeNode} from "@circlon/angular-tree-component";


@Injectable()
export class JsTreeDataHelperService {

    private flatTree: any;

    decodeEntities(str: string): string {
        let element: HTMLElement = document.createElement('div');
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }
        return str;
    }

    setFlatTree(tree: any): void {
        this.flatTree = {};
        this.updateFlatTreeItems(tree);
    }

    private updateFlatTreeItems(items: any): void {
        for (let i: number = 0; i < items.length; i++) {
            this.flatTree[items[i].text] = true;
            if(items[i].children && items[i].children.length) {
                this.updateFlatTreeItems(items[i].children);
            }
        }
    }

    public getDuplicateNodeName(node: any): string {
        const text = node.data.text;
        for (let i: number = 1; i < 501; i++) {
            const nameToCheck: string = `${text}(${i})`;
            if(this.flatTree[nameToCheck] === undefined) {
                return nameToCheck;
            }
        }
    }

    public encodeEntities(str: string): string {
        let htmlEntities = [
            {regex:/&/g,entity:'&amp;'},
            {regex:/>/g,entity:'&gt;'},
            {regex:/</g,entity:'&lt;'},
            {regex:/"/g,entity:'&quot;'}
        ];

        for(let v in htmlEntities){
            str = str.replace(htmlEntities[v].regex, htmlEntities[v].entity);
        }
        return str;
    }

    public decodeTreeNodeText(treeNodeText: string): string {
        let decodeDomElement = document.getElementById('decodeElement');
        if (!decodeDomElement) {
            decodeDomElement = document.createElement('div');
            decodeDomElement.id = 'decodeElement';
            decodeDomElement.innerHTML = treeNodeText; 
        }

        return decodeDomElement.innerText;
    }

    public getRandomId(): number {
        return Math.floor(Math.random() * (999999999 - 9999 + 1)) + 9999;
    }

    public setDragOverBg(node:TreeNode, event: any, isAdd?: boolean): void {
        const isFolder = !node.isLeaf;
        const hasParent = node.realParent ? true : false;
        const isCollapsed = node.isCollapsed;
        let nodeLevel = this.findNodeLevel(event.toElement);
        let parentNodeLevel: HTMLElement | false;


        if(nodeLevel && isFolder && !isCollapsed) {
            if(isAdd) {
                nodeLevel.classList.add('is-level-dragging-over');
            } else {
                nodeLevel.classList.remove('is-level-dragging-over');
            }
        }

        if(nodeLevel && !isFolder && hasParent) {
            parentNodeLevel = this.findNodeLevel(nodeLevel);

            if(isAdd) {
                parentNodeLevel && parentNodeLevel.classList.add('is-level-dragging-over');
            } else {
                parentNodeLevel && parentNodeLevel.classList.remove('is-level-dragging-over');
            }
        }
    }

    public findNodeLevel(el: HTMLElement): HTMLElement | false {
        if(!el) return false;
        let current: HTMLElement = el;
        for (let i: number = 0; i < 8; i++) {
            current = current.parentElement;
            if(current.className.includes('tree-node-level')) {
                return current;
            }
        }
        return false;
    }

    public sortAtoZ(arr: any[]): any[] {
        return arr.sort(function(a, b) {
            const textA = String(a.text).toLowerCase();
            const textB = String(b.text).toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }
}