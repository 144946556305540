import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpService } from "../../../services/optimove-http/optimove-http.model";


@Injectable()
export class OptiSearchListService {
    public closeGridSubject: Subject<any>;
    public allRowsChanged$: Subject<boolean>;
    constructor(private readonly http: HttpService) {
        this.closeGridSubject = new Subject();
        this.allRowsChanged$ = new Subject();
    }

    createRequest(url, params) {
        return this.http.post(url, { ...params }).toPromise();
    }
}