import { BrowserModule } from "@angular/platform-browser";
import { AddUniquePromoCodeComponent } from "./components/addUniquePromoCode/addUniquePromoCode.component";
import { NgModule } from "@angular/core";
import { ComponentsTransitionalModule } from "../../../components/components_transitional.module";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../../shared.module";
import { AgGridModule } from "@ag-grid-community/angular";
import { UniquePromoCodesComponent } from "./components/uniquePromoCodes.component";
import { InputSearchModule } from "../../../components/inputSearch/InputSearch.module";
import { StatusCellRendererComponent } from "./components/statusCellRenderer/statusCellRenderer.component";
import {UniquePromoCodesService} from './services/uniquePromoCodes.service';
import { ImportCsvLoaderModal } from './components/importCsvLoaderModal/importCsvLoaderModal.component';
import { OptiModalModule } from "../../../modules/OptiModal.module";
import {PipesModule} from "../../../pipes/pipes.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { ColoredTagModule } from '@optimove/ui-sdk/components/colored-tag'

@NgModule({
  imports: [
    BrowserModule,
    ColoredTagModule,
    ComponentsTransitionalModule,
    CommonModule,
    AgGridModule.withComponents([StatusCellRendererComponent]),
    SharedModule,
    InputSearchModule,
    OptiModalModule.forRoot(),
    PipesModule,
    TooltipModule,
  ],
  declarations: [
    UniquePromoCodesComponent,
    StatusCellRendererComponent,
    AddUniquePromoCodeComponent,
    ImportCsvLoaderModal,
  ],
  providers: [UniquePromoCodesService],
  entryComponents: [StatusCellRendererComponent],
})
export class UniquePromoCodesModule {}
