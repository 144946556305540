export enum DataUpdateStatusEnum  {
    Updated = 0,
    Updating = 1,
    NotUpdated = 2
};

export interface DailyStatus {
    dataUpdateStatus?: DataUpdateStatusEnum,
    campaignExecutionStatus?: DataUpdateStatusEnum,
    analysisStatus?: DataUpdateStatusEnum,
    isRunning: boolean,
    lastSuccessfulRunDate: Date,
    lastSuccessfulRun: string,
    dailyScheduleRunTime: string
}

export interface DailyServiceStatus {
    isRunning: boolean,
    lastSuccessfulRun: string;
    taskId: number;
    taskState: string;
    currentStage: ServiceCurrentStage;
}

export interface ServiceCurrentStage {
    lastUpdate: string;
    executionStage: ServiceExecutionStage;
    campaignExecutionStatus: number;
    dataUpdateStatus: number;
    analysisStatus: number;
}

export enum ServiceExecutionStage {
    PreCampaignExecution = 1,
    CampaignExecution = 2,
    BetweenCampaignExecutionToReExecution = 3,
    ReExecution = 4,
    PostReExecution = 5,
    NotAvailable = 6
}