import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { SsmService } from '../../services/ssm.service';

@Injectable()
export class ImportedCustomersGuard implements CanActivate {
    private ssmService: any;
    
    constructor(private router: Router, private ssm: SsmService) {
        this.ssmService = ssm.getSSM();
    }

    canActivate(): Observable<boolean> {
        const isOptimailEnabled = this.ssmService.GetGeneric(this.ssmService.Resx.IsOptimailEnabled);
        if (isOptimailEnabled) {
            return of(true);
        } else {
            this.router.navigate(['**']);
            return of(false);
        }
    }
}
