import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs/internal/Observable";
import { AttributeDetails, AttributeDataType, AttributeOption, AttributeStatus, AttributeType, TimeframeScenarioCode,TimeframePeriod, ConditionValueFormat, AttributeFormat, AttributeFamily, PurchaseHistoryFamily, PurchaseHistoryFunctionName } from "./attributesList.enums";

export interface IAttributeService {
    baseUrl: string;
    saveAttribute(attribute: BaseAttribute, family: AttributeFamily): Observable<DefaultAjaxResponse>;
    updateAttribute(attribute: BaseAttribute, family: AttributeFamily): Observable<DefaultAjaxResponse>;
    deleteAttribute(realFieldName: string, family: AttributeFamily): Observable<DefaultAjaxResponse>;
    getAttributes(): Observable<any[]>;
    addAttributeToProfile(attr: any, option?: AttributeOption, obj?: RealtimeAttribute): void | any;
    deleteAttributeFromProfile(attributeName: string): void;
    searchInProfile(attrName: string): any;
    publishAll(): Observable<DefaultAjaxResponse>;
    updateCustomerAttributes(): void;
    getCountOfCreatedAttributes(): number;
}

export interface FormulaCalculationResult {
    result: number;
    format: string;
    attributes: { [key: string]: any };
    customerId: string;
}

export interface AttributeConfiguration {
    NumberOfAttributesAllowed: number;
    NumberOfShowsPerAttribute: number;
    MaxCountOfCreatedAttributes: number;
    MaxCountOfPurchaseHistoryAttributes: number;
    MaxCountOfRealtimeAttributes: number;
}

export interface AttributeTypeInfo {
    type: AttributeType;
    title: string;
    hidden?: boolean;
    matIcon?: string;
    oFondIcon?: string;
}

export interface BaseAttribute {
    Name?: string;
    DisplayName?: string;
    Description: string;
    PublishStatus: AttributeStatus;
    Type: AttributeType;
    FieldName: string;
    AttributeBaseType?: AttributeDataType;
    IsHidden?: boolean;
    Formatting?: string;
    IsPersonalization: boolean;
    OldName?: string;
    OldAliasName?: string;
    Priority?: number;
}

export interface CalculatedAttribute extends BaseAttribute {
    Function: string;
    Activity?: GranularAttribute;
    Timeframe: string;
    Family: string;
}

export interface CalculationSectionComponent {
    form: FormGroup;
    buildAttributeBody: Function;
    isFormCreated: EventEmitter<boolean>;
}

export interface PurchaseHistoryAttribute extends BaseAttribute {
    ActivityTypeId: number;
    // Conditions is an empty array if the function name is "First" or "Last"
    Conditions: PurchaseHistoryCondition[];
    TimeframeId: number;
    TimeframePeriod: number;
    ResultAttribute: string;
    FunctionName: PurchaseHistoryFunctionName;
    Family: PurchaseHistoryFamily;
    FieldFormat: string,
    // TransactionAttributeID is a result attribute Id
    TransactionAttributeID: number;
    // AttributeType is a type of the result attribute inside the PurchaseHistoryAttribute with "First" or "Last" as a function name
    AttributeType?: number; 
    // MetricTransactionAttributeID is a metric attribute Id for Most Spent scenario
    MetricTransactionAttributeID?: number;
}

export interface OldPurchaseHistoryAttribute extends BaseAttribute {
    Conditions: PurchaseHistoryCondition[];
    TimeframeId: number;
    FunctionName: PurchaseHistoryFunctionName;
    TimeframePeriod: number;
    ResultAttribute: string;
    Family: PurchaseHistoryFamily;
    FieldFormat: string;
}

export interface OldPurchaseHistoryProduct {
    DataType: string;
    FieldFormat: string;
    HasEnum: boolean;
    Name: string;
    Priorirty: number;
    RealFieldName: string;
    ToolTipInfo: string;
}

export interface FormulaBasedAttribute extends BaseAttribute {
    Family: string;
    Formula: string;
    AttributeDetails: AttributeDetails[];
    Format: AttributeFormat;
    SourceFieldName: string;
    IgnoreYear: boolean;
}

export interface ConditionalAttribute extends BaseAttribute{
    ComplexExpression: string;
    Conditions: Condition[];
    Format?: AttributeFormat;
    Values: string[];
}

export interface PurchaseHistoryCondition {
    AttributeFieldName: string;
    AttributeType?: number;
    AttributeID?: number;
    HasValuesList?: boolean;
    OperatorId: string;
    OperatorExpression: string;
    Value: string;
    FormatType: ConditionValueFormat;
}

export interface PurchaseHistoryFlowItem {
    aliasAttributeName: string;
    attributeDataType: string;
    attributeFormat: string;
    attributeId: number;
    attributeType: number;
    attributeValues: any[];
    hasValuesList: boolean;
    realFieldName: string;
    toolTipInfo: string;
}

export interface MultipleConditionValue {
    ComplexExpression: string;
    Conditions: Condition[];
    Value: string;
    IsValid?: boolean;
    EmptyRequiredFields?: boolean;
}

export interface MultyExpressionsConditionalAttribute extends BaseAttribute{
    ExpressionsArray: ComplexExpressionsElement[];
    ElseValue: string;
    Format: AttributeFormat;
}

export interface Condition {
    Position: number;
    AttributeFieldName: string;
    OperatorId: string;
    OperatorValue: string;
    Value: any;
    FormatType: ConditionValueFormat;
} 

export interface ComplexExpressionsElement {
    Priority: number;
    ShowComplexExpression: boolean;
    ComplexExpression: string;
    Conditions: Condition[];
    Value: string;
}

export interface ConditionConfig {
    attributeName: string;
    conditions: Condition[];
    complexExpression: string;
    showComplexExpression: boolean;
    values: string[];
    isDisabled: boolean;
    isAddMode: boolean;
    format: AttributeFormat;
}

export interface MultipleConditionEditorConfig {
    priority: number;
    showComplexExpression: boolean;
    isDisabled: boolean;
    isAddMode: boolean;
}

export interface ConditionalEditorArrayConfig {
    attributeName: string;
    attributeBody: MultyExpressionsConditionalAttribute,
    isDisabled: boolean;
    isAddMode: boolean;
}

export interface AttributeLog {
    RealFieldName: string;
    User: string;
    Action: string;
    Time: string;
    Message: string;
}

export interface formulaOptions {
    complexExpresion: boolean
} 

export interface TableBaseAttribute extends BaseAttribute {
    tableDisplayName?: string;
}

export interface TableCalculatedAttribute extends CalculatedAttribute {
    tableDisplayName?: string;
}

export interface TableFormulaBasedAttribute extends FormulaBasedAttribute {
    tableDisplayName?: string;
}

export interface TableConditionalAttribute extends ConditionalAttribute {
    tableDisplayName?: string;
}

export interface TableRealtimeAttribute extends RealtimeAttribute {
    tableDisplayName?: string;
}

export interface RealtimeAttribute extends BaseAttribute { }

/*export interface RealtimeAttribute {
    aggregation: string;
    DisplayName: string;
    events: AttributeEvent[];
    export: boolean;
    format: string;
    import?: string;
    status: AttributeStatus;
    publishTime?: string;
    isAlreadyPublished: boolean;
    Type: AttributeType;
    PublishStatus: AttributeStatus;
}*/

export interface TableRealtimeAttribute extends RealtimeAttribute {
    formula: string;
    eventsType: string[];
    eventsParameter: string;
    exists: boolean; 
    tableDisplayName?: string;
}

export interface AttributeEvent {
    type: string;
    value?: string;
    valueType?: string;
    conditionField?: string;
    conditionType?: string;
    conditionValue?: string;
}

export interface GranularAttribute {
    AliasName: string;
    FIeldTypeString: string;
    FieldFormat: null;
    FieldName?: string;
    FieldRealName: string;
    FieldType: number;
    Format: string;
    HasEnum: boolean;
    IsPriority: boolean;
    TableName: string | null;
    TookActionAlias: string | null;
    ToolTip: string | null;
    Value: any;
}

export interface AttributeTypeOptions {
    isAPI: boolean;
    isRT: boolean;
    isCalculated: boolean;
    isBase: boolean;
}

export class Timeframe {
    code: number;
    period: number;
    interval: number;
    specialName?: string;
    prefix?: string;

    constructor(scenario: TimeframeScenarioCode, period: number, interval: number, prefix?: string, specialName?: string) {
        this.code = TimeframeCode.find(x => x.key === scenario).value;
        this.interval = interval;
        this.period = period;
        
        if (specialName) {
            this.specialName = specialName;
        }
        if (prefix) {
            this.prefix = prefix;
        }
    }
    /**
     * Convert Timeframe object to user-friendly text variant
     */
    public toString(withUnderscore: boolean = false, withPrefix?: string): string {
        const code = timeframeCodeStrings.find(x => x.key === this.code);
        const period = timeframePeriodStrings.find(x => x.key === this.period);
        const lastCode = TimeframeCode.find(t => t.key === "Last").value;
        const isMultiple = this.code === lastCode && this.interval > 1;
        const intervalString = isMultiple ? period.key === TimeframePeriod.Day 
            ? ` ${this.interval}` : ` ${numbersString[this.interval]}` : "";

        // Priority of prefix (low to high): code.value => this.prefix => withPrefix 
        const prefixValue = withPrefix ? withPrefix : this.prefix ? this.prefix : code.value;

        var stringVariant = this.specialName 
            ? this.specialName
            : `${prefixValue}${intervalString} ${period.value}${isMultiple ? "s" : ""}`;
        
        return withUnderscore ? stringVariant.replace(/\s/g, "") : stringVariant;
    }
    
    public equals(frame: string): boolean {
        const arr = frame.split(",").map(x => Number.parseInt(x));
        if (Number.isNaN(arr[1])) {
            arr[1] = null;
        }

        return this.code === arr[0]
            && this.interval === arr[1]
            && this.period === arr[2];
    }

    public toSendForm(): string {
        return `${this.code},${this.interval !== null ? this.interval : ''},${this.period}`;
    }
}

const TimeframeCode = [
    { key: "Last", value: 1 },
    { key: "This", value: 2 },
    { key: "Last Calendar", value: 2 }
];

const timeframeCodeStrings = [
    { key: TimeframeCode.find(t => t.key === "Last").value, value: "Last" },
    { key: TimeframeCode.find(t => t.key === "This").value, value: "This" }
];

const timeframePeriodStrings = [
    { key: TimeframePeriod["Day"], value: "Day" },
    { key: TimeframePeriod["Week"], value: "Week" },
    { key: TimeframePeriod["Month"], value: "Month" },
    { key: TimeframePeriod["Quarter"], value: "Quarter" },
    { key: TimeframePeriod["Year"], value: "Year" },
];

const numbersString = ["Zero", "One", "Two", "Three", "Four", 
    "Five", "Six", "Seven", "Eight", "Nine", "Ten"];

export const defaultTimeframes = [
    { key: 1, value: "During the previous", groupId: "0", order: 0 },
    { key: 2, value: "Exactly", groupId: "0", order: 1 },
    { key: 30, value: "This week (to date)", groupId: "1", order: 2 },
    { key: 31, value: "This month (to date)", groupId: "1", order: 3 },
    { key: 32, value: "This quarter (to date)", groupId: "1", order: 4 },
    { key: 33, value: "This year (to date)", groupId: "1", order: 5 },
    { key: 34, value: "Last calendar week", groupId: "2", order: 6 },
    { key: 35, value: "Last calendar month", groupId: "2", order: 7 },
    { key: 36, value: "Last calendar quarter", groupId: "2", order: 8 },
    { key: 37, value: "Last calendar year", groupId: "2", order: 9 }
];

export const functionList = [
    {
      key: 'sum',
      value: 'Sum',
      isNumeric: true
    },
    {
      key: 'first',
      value: 'First',
      isNumeric: false
    },
    {
      key: 'last',
      value: 'Last',
      isNumeric: false
    },
    {
      key: 'mostFrequent',
      value: 'Most Frequent'
    },
    {
      key: 'mostSpent',
      value: 'Most Spent'
    }
  ];


