import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Tag } from '../../../models/tag.model';
import { IModalButtonConf, IModalConf, OptiLogicModalService } from '../../optiLogicModal/optiLogicModal.service';
import { NotesAndTagIds } from '../model/notesAndTags.model';


@Component({
	selector: 'notes-and-tags-modal',
	templateUrl: 'notesAndTagsModal.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./notesAndTagsModal.component.scss']
})

export class NotesAndTagsButtonModalComponent implements OnInit, OnDestroy {

    @Input() tagsLimit: number;
    @Input() textValue: string;
    @Input() selectedTags: number[];
    @Input() allTags: Tag[];
    @Input() isEditMode: boolean;
    @Input() streamTestName: string;
    @Input() curNewTags: Tag[];
    public saveNotesAndTags$: Subject<NotesAndTagIds> = new Subject<NotesAndTagIds>();
    public saveNewTags$: Subject<Tag[]> = new Subject<Tag[]>();
    private _newTags: Tag[] = [];
    selectedTagsBackup: any[];
    isTagsListEmpty = true;
    disableAddingTag = false;
    modalConf: IModalConf;
    form: FormGroup;

    constructor(public bsModalRef: BsModalRef, private modalService: OptiLogicModalService) {}

    ngOnInit(): void {
    	this.selectedTagsBackup = [...this.selectedTags];
    	const notesAndTags: NotesAndTagIds = {
    		note: this.textValue,
    		tags: this.selectedTags
    	};

    	this.form = new FormGroup({
    		notesAndTags: new FormControl(notesAndTags)
    	});
    	this.setModalConf();
    }

    // From here its the modal configuration.
    onSave(): void {
    	const tagsAndNotesData: NotesAndTagIds = this.notesAndTagsControl.value;
    	this.saveNewTags$.next(this.newTags);
    	this.saveNotesAndTags$.next(tagsAndNotesData);
    	this.bsModalRef.hide();
    }

    onCancel(): void {
    	this.selectedTags = [...this.selectedTagsBackup];
    	this.bsModalRef.hide();
    }

    setModalConf(): void {
    	this.modalConf = {
    		title: 'Tags',
    		buttons: this.getButtonsConf(),
    		hideFooterBorder: true,
    		hideHeaderBorder: true,
    		hideHeaderPanel: true
    	};
    }

    getButtonsConf(): IModalButtonConf[] {
    	return [
    		{
    			class: 'btn-primary',
    			label: 'Save',
    			action: this.onSave.bind(this)
    		},
    		{
    			isDisabled: () => {
    				return false;
    			},
    			class: 'btn-default',
    			label: 'Cancel',
    			action: this.onCancel.bind(this)
    		}
    	];
    }

    get notesAndTagsControl() : FormControl {
    	return this.form.get('notesAndTags') as FormControl;
    }
    get newTags() : Tag[] {
    	return this._newTags;
    }
    addNewTagEvent(iListItem: IListItem) {
    	if (this.newTags.length > 0) {
    		let minId = Math.min(...this.newTags.map(x => x.id));
    		iListItem.id = minId - 1;
    	}
    	this.newTags.push({ id:iListItem.id, name:iListItem.name, isReadOnly: false} as Tag);
    }

    ngOnDestroy(): void {
    	this.saveNewTags$.complete();
    	this.saveNotesAndTags$.complete();
    	this.saveNewTags$ && this.saveNewTags$.unsubscribe();
    	this.saveNotesAndTags$ && this.saveNotesAndTags$.unsubscribe();
    }

}
