import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { FeatureFlagService } from '../../services/featureFlag.service';

export class FeatureFlagRedirect {

    static redirect(condition: (ffs: FeatureFlagService) => boolean, destPath: string) { 
        @Injectable({ providedIn: 'root' })
        class redirectCheck implements CanActivate { 
            constructor(private featureFlagService: FeatureFlagService, private router: Router) {

            }
        
            canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
                if(condition(this.featureFlagService)){
                    this.router.navigate([destPath]);
                    return of(false);
                }
                return of(true);
            }
        }
        return redirectCheck;
    }
}