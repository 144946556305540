import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from "@angular/core";
import { SearchItem } from "../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { Operator } from "../../../scheduledCampaignBuilder/models/wizardData.model";
import { conditionalOperators } from "../attributesList.constants";
import { AttributeDataTypeConditional, ConditionValueFormat } from "../attributesList.enums";
import {
    BaseAttribute,
    ComplexExpressionsElement,
    Condition,
    ConditionalAttribute,
    ConditionalEditorArrayConfig,
    MultipleConditionEditorConfig,
    MultipleConditionValue,
    MultyExpressionsConditionalAttribute
} from "../attributesList.model";
import moment from 'moment';
import { AttributesManagerService } from './attributesManager.service';
import { filter, first } from 'rxjs/operators';
import { ConditionalValidatorService } from './conditionalValidator.service';
import { SsmService } from '../../../../services/ssm.service';

@Injectable()
export class ConditionalAttributeService {

    public attributes: BaseAttribute[] = [];
    public attributesSearchItems: SearchItem[] = [];
    public possibleValuesForAttributes;

    constructor(private attrManagerService: AttributesManagerService,
                private condValidationService: ConditionalValidatorService,
                private SSMService: SsmService) {}

    // ================================ \\
    // =========| GET region |========= \\

    public getAttributesSearchItems(): SearchItem[] {
        if(this.attributes.length === 0) {
            this.attrManagerService.getAttributes(true)
            .pipe(first(), filter(x => !!x))
            .subscribe((res: BaseAttribute[]) => {
                this.attributes = (res.filter((x) => { 
                    if( !x.Name.startsWith('IF_CA_CC_') && !x.Name.startsWith('VF_CA_') && !!x ) { 
                        return x; 
                    } 
                }));
                this.attributesSearchItems = (this.convertAttributesToSearchItems(this.attributes));

                let SSM = this.SSMService.getSSM();
                this.possibleValuesForAttributes = SSM.GetGeneric(SSM.Resx.CustomerAttributesEnums);
            });
        }
        return this.attributesSearchItems;
    }

    public getOperator(attrType: AttributeDataTypeConditional, name: string = null, id: number = null, expression: string = null): Operator {
        let operators = this.getOperators(attrType);
        return operators.find((operator: Operator) => 
            (name && operator.Name === name) ||
            (id && operator.Id === id) ||
            (expression && operator.Expression === expression)
        );
    }

    public getOperators(attrType: AttributeDataTypeConditional): Operator[] {
        return conditionalOperators.find((x) => x.type === attrType).operators;
    }
    
    public getDefaultComplexExpression(lastPosition: number, condition: Condition[]): string {
        let complexExpression = '';
        condition.forEach((x, i, arr) => {
            complexExpression = `${complexExpression}@${lastPosition + i + 1}${i !== arr.length - 1 ? ' and ' : ''}`;
        })
        return complexExpression;
    }
        
    public createConditionForm(position: number): FormGroup {
        return new FormGroup({
          attributeFieldName: new FormControl([{key: "", text: ""}], this.condValidationService.requiredSearchItem()),
          operator: new FormControl([{key: "", text: ""}], this.condValidationService.requiredSearchItem()),
          value: new FormControl('', [Validators.required]),
          formatType: new FormControl(''),
          position: new FormControl(position),
          attributeDataType: new FormControl("number")
        });
    }

    public getAttributeDataType(attributeFieldName: string): AttributeDataTypeConditional {
        this.getAttributesSearchItems();
        if(this.possibleValuesForAttributes[attributeFieldName]) 
        {
          return "multiValues";
        }
        else {
          const attribute = this.attributes.find((x) => {
            if(x.FieldName === attributeFieldName) { return x }
          });
          return attribute.AttributeBaseType as AttributeDataTypeConditional;
        }
    }

    public getPossibleValuesForAttribute(attributeFieldName: string): SearchItem[] {
        return this.possibleValuesForAttributes[attributeFieldName]
                ? this.possibleValuesForAttributes[attributeFieldName].map((y) => {
                    return { key: y, text: y } as SearchItem;
                  })
                : [];
    }

    // ================================ \\
    // =======| CONVERT region |======= \\

    public convertAttributesToSearchItems(attributes: any[]): SearchItem[] {
        return attributes.filter(x => x.PublishStatus === "Published").map((item: BaseAttribute) =>  ({key: item.FieldName, text: item.DisplayName})); 
    }

    public convertOperatorsToSearchItems(operators: Operator[]): SearchItem[] {
        return operators.map((operator: Operator) => ({key: operator.Id, text: operator.Expression}));
    }
    
    public formatComplexExpression(expression: string, maxConditionAmount: number): string {
        for (let i = maxConditionAmount; i >= 1; i--) {
            expression = expression.replace(`${i}`, `@{${i}}`);
        }
        return expression;
    }

    public expressionToDisplayFormat(expression: string): string {
        return expression.split(/[]+|[{]|[}]|[@]/).join('');
    }

    public ConditionalAttributeToMultyExpressions(attribute: ConditionalAttribute): MultyExpressionsConditionalAttribute {
        let result: MultyExpressionsConditionalAttribute = {
            ExpressionsArray: [{
                Priority: 1,
                ShowComplexExpression: attribute.ComplexExpression !== '',
                ComplexExpression: attribute.ComplexExpression === '' ? this.getDefaultComplexExpression(0, attribute.Conditions) : attribute.ComplexExpression,
                Conditions: attribute.Conditions,    //Must be changed if you need to provide more than 1 complex expression
                Value: attribute.Values[0]
            } as ComplexExpressionsElement],
            ElseValue: attribute.Values[attribute.Values.length - 1],
            Name: attribute.Name,
            DisplayName: attribute.DisplayName,
            Description: attribute.Description,
            PublishStatus: attribute.PublishStatus,
            Type: attribute.Type,
            FieldName: attribute.FieldName,
            AttributeBaseType: attribute.AttributeBaseType,
            IsHidden: attribute.IsHidden,
            Formatting: attribute.Formatting,
            IsPersonalization: attribute.IsPersonalization,
            Format: attribute.Format,
            OldName: attribute.OldName,
        };
        return result;
    }

    public MultyExpressionsToConditionalAttribute(multyExpressions: MultyExpressionsConditionalAttribute): ConditionalAttribute {
        let result: ConditionalAttribute = {
            ComplexExpression: multyExpressions.ExpressionsArray[0].ComplexExpression.toLowerCase().replace(' ', '') === this.getDefaultComplexExpression(0, multyExpressions.ExpressionsArray[0].Conditions) 
                                ? ''
                                : multyExpressions.ExpressionsArray[0].ComplexExpression,
            Conditions: multyExpressions.ExpressionsArray[0].Conditions,
            Values: [multyExpressions.ExpressionsArray[0].Value, multyExpressions.ElseValue],
            Name: multyExpressions.Name,
            DisplayName: multyExpressions.DisplayName,
            Description: multyExpressions.Description,
            PublishStatus: multyExpressions.PublishStatus,
            Type: multyExpressions.Type,
            FieldName: multyExpressions.FieldName,
            AttributeBaseType: multyExpressions.AttributeBaseType,
            IsHidden: multyExpressions.IsHidden,
            Formatting: multyExpressions.Formatting,
            IsPersonalization: multyExpressions.IsPersonalization,
            Format: multyExpressions.Format,
            OldName: multyExpressions.OldName,
        }
        return result;
    }

    public buildCondition(form: FormGroup): Condition {
        const getValueControl = () => form.get('value') as FormControl;
        const getValueFormatControl = () => form.get('formatType') as FormControl;
        const getAttributeDataType = () => form.get('attributeDataType') as FormControl;
        const currentOperator = this.getOperator(getAttributeDataType().value, null, form.get('operator').value[0]?.key)
        let condition: Condition = {
            Position: 0,
            AttributeFieldName: '',
            OperatorId: '',
            OperatorValue: '',
            Value: '',
            FormatType: 'number'
        }
        
        condition.AttributeFieldName = form.get('attributeFieldName').value[0].key;
        condition.OperatorId = currentOperator ? currentOperator.Id.toString() : '';
        condition.OperatorValue = currentOperator ? currentOperator.Expression : '';
        condition.FormatType = getValueFormatControl().value;
        condition.Position = form.get('position').value;
    
        if((getValueControl().value 
            || getValueControl().value === 0 
            || getValueFormatControl().value as ConditionValueFormat === "disable") 
            && !(getValueFormatControl().value as ConditionValueFormat === "between numbers" 
                && getValueControl().value.indexOf("") >= 0)) {
    
            switch (getValueFormatControl().value as ConditionValueFormat) {
                case "dropdown":
                    console.log(getValueControl().value.map(element => element.key))
                    condition.Value = getAttributeDataType().value as AttributeDataTypeConditional === "multiValues"
                                      && getValueControl().value.length > 0
                                        ? getValueControl().value.length > 1
                                            ? (getValueControl().value.map(element => element.key) as string[]).toString()
                                            : [getValueControl().value[0].key].toString()
                                        : [].toString();
                    break;
                case "date":
                    condition.Value = (moment(getValueControl().value)).format('YYYY-MM-DD');
                    break;
                case "between dates":
                    condition.Value = (getValueControl().value.map(x => moment(x).format('YYYY-MM-DD'))).toString();
                    break;
                case "disable":
                    break;
                default: 
                    condition.Value = getValueControl().value.toString();
            }
        }
        else {
            getValueControl().setValue(null);
        }

        return condition
    }

    public conditionToForm(condition: Condition): FormGroup {
        let resultForm = this.createConditionForm(condition.Position);
        
        const getAttributeControl = () => resultForm.get('attributeFieldName') as FormControl;
        const getOperatorControl = () => resultForm.get('operator') as FormControl;
        const getValueControl = () => resultForm.get('value') as FormControl;
        const getFormatTypeControl = () => resultForm.get('formatType') as FormControl;
        const getAttributeDataType = () => resultForm.get('attributeDataType') as FormControl;

        //ATTRIBUTE
        if(condition.AttributeFieldName) {
            let currentAttribute: SearchItem = [{
                key: condition.AttributeFieldName, 
                text: '',
            }];
    
            this.attributesSearchItems.forEach(x => {
                if(x.key === condition.AttributeFieldName) { 
                    currentAttribute[0].text = x.text; 
                    getAttributeControl().setValue(currentAttribute);
                    return;
                }
            });
    
            getAttributeDataType().setValue(this.getAttributeDataType(condition.AttributeFieldName));    
        }
        
        // OPERATOR
        if(condition.OperatorId) {
            let operator = this.getOperator(getAttributeDataType().value, null, parseInt(condition.OperatorId));
            getOperatorControl().setValue([{key: operator.Id, text: operator.Expression}] as SearchItem);
        }

        // VALUE
        if(condition.Value) {
            let currentValue = null;

            switch (condition.FormatType) {
                case "date":
                    currentValue = new Date(condition.Value);
                    break;
                case "between dates":
                    const splitedDates = condition.Value.split(",");
                    currentValue = [new Date(splitedDates[0]), new Date(splitedDates[1])];
                    break;
                case "dropdown":
                    if (!this.possibleValuesForAttributes)
                    {
                        let SSM = this.SSMService.getSSM();
                        this.possibleValuesForAttributes = SSM.GetGeneric(SSM.Resx.CustomerAttributesEnums);
                    }
                    currentValue = [];
                    (this.possibleValuesForAttributes[condition.AttributeFieldName] as string[]).forEach(posValue => {
                        if ((condition.Value as string).includes(posValue)) {
                            currentValue.push({ key: posValue, text: posValue });
                        }
                    });
                    break;
                default:
                    currentValue = condition.Value.split(",");
            }
    
            getValueControl().setValue(currentValue);
        }

        //FORMATTYPE
        getFormatTypeControl().setValue(condition.FormatType);

        return resultForm;
    }

    public splitToMultipleConditionEditorConfigs(config: ConditionalEditorArrayConfig): MultipleConditionEditorConfig[] {
        return config.attributeBody.ExpressionsArray.map((x) => {
            return {
                priority: x.Priority,
                showComplexExpression: x.ShowComplexExpression,
                isDisabled: config.isDisabled,
                isAddMode: config.isAddMode,
            };
        }) as MultipleConditionEditorConfig[];
    }

    public splitToMultipleConditionValue(config: ConditionalEditorArrayConfig): MultipleConditionValue[] {
        return config.attributeBody.ExpressionsArray.map((x) => {
            const startPosition = Math.min(...x.Conditions.map(z => z.Position));
            return {
                ComplexExpression: x.ShowComplexExpression 
                    ? this.parseComplexExpression(startPosition, x.ComplexExpression, 'setup')
                    : '',
                Conditions: x.Conditions,
                Value: x.Value,
                IsValid: true
            };
        }) as MultipleConditionValue[];
    }

    public parseComplexExpression(startPosition: number, complexExpression: string, type: 'setup' | 'build') {
        startPosition = type === 'setup' ? -1 * startPosition + 1 : startPosition;
        let data = complexExpression
            .toLowerCase()
            .split(/[ ]+|[{]|[}]|[and]|[or]|[)]|[(]|[@]/)
            .filter(x => !!x)
            .map(x => {
                let numValue = Number.parseInt(x);
                return {
                    before: numValue,
                    after: numValue + startPosition
                };
            });

        data.sort((a, b) => b.before - a.before)
            .forEach((value) => {
                complexExpression = 
                    type === 'setup'
                    ? complexExpression.replace(`${value.before}`, `{${value.after}}`)
                    : complexExpression.replace(`{${value.before}}`, `${value.after}`);
            });
        return complexExpression;
    }

    public pushAfter(array: any[], index: number, newElement: any): any[] {
        return [...array.slice(0, index + 1), newElement, ...array.slice(index + 1)];
    }
}