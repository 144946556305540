import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { EditorType } from '../../models/editorType';
import { TemplateDetails } from '../../models/templateDetails';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { TemplateContextService } from '../../services/templateContext.service';
import { TranslateService } from '@ngx-translate/core';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { SubMethodService } from '../../services/subMethodService';
import { TemplatesMetadata } from '../../models/metadataResponse';
import { isTemplateSaveSuccessResponse } from '../../models/templateSaveUpdate';
import { TemplatePreviewModalComponent } from '../dialogs/templatePreviewModal/templatePreviewModal.component';
import { EmailPreview } from '../../models/emailPreview';
import { EditPlainTextModalComponent } from '../dialogs/editPlainTextModal/editPlainTextModal.component';
import { EditPlainTextModalCloseResult } from '../dialogs/editPlainTextModal/editPlainTextModal.models';
import { first, map, take } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { TemplateNavigationService } from '../../services/templateNavigation.service';
import { RoutingConsts } from '../../models/routing.consts';
import { PanelType } from '../../models/panelType';
import { FeatureFlag, FeatureFlagService } from '../../../../services/featureFlag.service';
import { LanguageStatus } from '../../models/languageStatus';
import { ChannelService } from '../../services/channel.service';

@Component({
	selector: 'editor-body',
	templateUrl: './editorBody.component.html',
	styleUrls: ['./editorBody.component.scss']
})
export class EditorBodyComponent implements OnInit, OnDestroy {
	templateType: EditorType;
	EditorType = EditorType;
	template: TemplateDetails;
	templateLobbyQueryParams: TemplateQueryParams;
	modal: BsModalRef;
	templateMetadata: TemplatesMetadata;
	isFullScreen: boolean;
	isPreviewInitialized: boolean;
	isPreviewEnabled: boolean;
	emailPreviewClients: EmailPreview[];
	public panelType: PanelType;
	private subscriptions = new Subscription();
	public isPopup = false;
	public isTranslationFeatureAvailable: boolean;
	@Output() fullScreenActivate = new EventEmitter<boolean>();
	isTranslationWarningVisible: boolean;

	constructor(
		private templateContext: TemplateContextService,
		private cd: ChangeDetectorRef,
		private modalService: OptiLogicModalService,
		public translateService: TranslateService,
		public manageTemplatesService: ManageTemplatesService,
		private subMethodService: SubMethodService,
		private templateNavigationService: TemplateNavigationService,
		private featureFlagsService: FeatureFlagService,
		private channelService: ChannelService
	) {}

	ngOnInit(): void {
		this.isTranslationFeatureAvailable = this.featureFlagsService.isEnabled(FeatureFlag.TemplatesOptimailMultiLanguageFlow);

		this.subscriptions.add(
			this.templateContext.queryTemplateParams.subscribe((params: TemplateQueryParams) => {
				this.isPopup = this.channelService.isWebPopup(+params?.channelId);
				this.templateLobbyQueryParams = { ...params };
			})
		);
		this.subscriptions.add(
			this.templateContext.current.subscribe((t) => {
				this.template = t;
				if (!t && this.templateLobbyQueryParams.visualeditor) this.templateContext.resetCurrentTemplate(true);

				this.isTranslationWarningVisible = !!this.template?.languages?.find((l) => l.status === LanguageStatus.ContentChanged);
				if (t) {
					this.templateType = this.templateContext.templateType(t);
				} else {
					this.templateType = this.templateLobbyQueryParams.visualeditor ? EditorType.beefree : EditorType.froala;
				}
				this.cd.detectChanges();
			})
		);
		this.panelType = PanelType.metadata;
		this.subscriptions.add(
			this.templateContext.contentActivated.subscribe(() => {
				if (this.panelType !== PanelType.palette) {
					this.panelType = PanelType.palette;
					this.cd.detectChanges();
				}
			})
		);
	}
	public get panelTypeAccess(): typeof PanelType {
		return PanelType;
	}
	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
		this.subscriptions = null;
	}

	convertJsonToHtml() {
		this.modal = this.modalService.openModalMessage('md', {
			message:
				this.translateService.instant('features.manage_templates.optimail.BeefreeConvertModal.message') +
				this.translateService.instant('features.manage_templates.optimail.BeefreeConvertModal.message2'),
			xButton: {
				action: () => this.modal.hide()
			},
			buttons: [
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.optimail.BeefreeConvertModal.okButton'),
					action: () => {
						this.onConvertJsonToHtmlConfirmation();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.optimail.BeefreeConvertModal.cancelButton'),
					action: () => {
						this.modal.hide();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		});
	}

	public onConvertJsonToHtmlConfirmation(): void {
		this.manageTemplatesService.getMetadata().subscribe((res) => {
			this.templateMetadata = res;
			const updatedTemplate = {
				templateJson: JSON.stringify({
					...this.template,
					subMethodId: this.template.subMethodId,
					subMethodType: this.subMethodService.findSubmethodType(this.templateMetadata, this.template.subMethodId),
					folderId: this.templateLobbyQueryParams.folderId
				}),
				allowUsedTemplate: true,
				channelId: +this.templateLobbyQueryParams.channelId,
				allowInvalidTemplate: true,
				convertToFroala: true
			};
			this.manageTemplatesService
				.saveAndUpdateTemplate(updatedTemplate, +this.templateLobbyQueryParams.channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL)
				.subscribe((resp) => {
					this.modal.hide();

					if (isTemplateSaveSuccessResponse(resp)) {
						this.templateContext.updateTemplate(resp.data.templateData);
						this.templateNavigationService.navigateToEditTemplate(
							+this.templateLobbyQueryParams.channelId,
							this.template.templateId,
							+this.templateLobbyQueryParams.folderId,
							this.template.subMethodId,
							false,
							this.templateLobbyQueryParams.search
						);
					}
				});
		});
	}

	public expandBeeFree() {
		this.isFullScreen = !this.isFullScreen;
		this.fullScreenActivate.emit(this.isFullScreen);
	}

	openPreview(): void {
		if (this.isPreviewInitialized) {
			this.openPreviewModal();
		} else {
			this.manageTemplatesService.getEmailPreviewEnabled().subscribe((isPreviewEnabled) => {
				this.isPreviewEnabled = isPreviewEnabled;
				this.isPreviewInitialized = true;
				this.openPreviewModal();
			});
		}
	}

	editPlainTextClick(): void {
		if (!this.template) return;

		this.templateContext.channelId.pipe(take(1)).subscribe((channelId) => {
			let plainText$;
			if (this.template.otherEditor) {
				plainText$ = this.manageTemplatesService.getPlainTextFromBeefreeObject({
					channelId: +channelId,
					templateJson: JSON.stringify(this.template)
				});
			} else {
				plainText$ = this.manageTemplatesService.convertHtmlToText({
					html: this.template.html
				});
			}
			plainText$.subscribe((text) => {
				let subMethodType$: Observable<number>;
				if (this.template.subMethodType) subMethodType$ = of(this.template.subMethodType);
				else if (this.templateMetadata)
					subMethodType$ = of(this.subMethodService.findSubmethodType(this.templateMetadata, this.template.subMethodId));
				else
					subMethodType$ = this.manageTemplatesService.getMetadata().pipe(
						map((res) => {
							this.templateMetadata = res;
							return this.subMethodService.findSubmethodType(this.templateMetadata, this.template.subMethodId);
						})
					);

				subMethodType$.pipe(first()).subscribe((subMethodType) => {
					const modalRef = this.modalService.open(EditPlainTextModalComponent, 'lg', <ModalOptions<any>>{
						ignoreBackdropClick: true,
						class: '',
						initialState: {
							subMethodType: subMethodType,
							templateText: this.template.text,
							generatedText: text
						}
					});
					modalRef.content.closeAction.pipe(take(1)).subscribe((closeResult: EditPlainTextModalCloseResult) => {
						if (!closeResult.text.length) return;
						this.template.text = closeResult.text;
						this.template.autoGeneratePlainText = closeResult.isGenerated;
						this.templateContext.updateTemplate(this.template);
					});
				});
			});
		});
	}

	private openPreviewModal(): void {
		if (this.template) {
			this.modalService.open(TemplatePreviewModalComponent, 'lg', <ModalOptions<any>>{
				initialState: {
					previewEnabled: this.isPreviewEnabled,
					template: this.template
				}
			});
		}
	}
}
