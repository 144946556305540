import { LogicalOperatorType, NavbarIconType, NavbarTreeNode, PermissionsName } from "./navbarTreeNode.model";

export default [
    {
        name: "Dashboard",
        routerLink: "/category/Analysis_&_Research/Dashboard.aspx",
        icon: {
            iconName: "equalizer",
            iconType: NavbarIconType.Fill
        },
        permissions: { value: PermissionsName.Dashboard },
        isLandingPage: true,
        isVisible: true
    },
    {
        name: "Mission Control",
        routerLink: "/category/one-to-one_campaigns/mission-control",
        icon: {
            iconName: "icon-monitor-dashboard",
            iconType: NavbarIconType.OptiFont
        },
        permissions: { value: PermissionsName.MissionControl },
        isLandingPage: true,
        isVisible: true
    },
    {
        name: "Explore",
        icon: {
            iconName: "person_search",
            iconType: NavbarIconType.Fill
        },
        isVisible: true,
        children: [
            {
                name: "Customer Explorer",
                routerLink: "/category/analysis_&_research/customer-explorer",
                permissions: { value: PermissionsName.CustomerExplorer },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Target Group Discovery",
                routerLink: "/category/analysis_&_research/audience-discovery",
                permissions: { value: PermissionsName.AudienceDiscovery },
                isLandingPage: true,
                isVisible: true,
                isNew: true
            },
            {
                name: "Customer Model",
                routerLink: "/category/Predictive_Model/customer-model",
                permissions: { value: PermissionsName.CustomerModel },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Migration Explorer",
                routerLink: "/category/Predictive_Model/newBiStudio/inter-stage-migrations",
                permissions: { value: PermissionsName.InterStageMigrations },
                isVisible: true
            },
            {
                name: "Segmentation Explorer",
                routerLink: "/category/Predictive_Model/Segmentation_Explorer.aspx",
                permissions: { value: PermissionsName.SegmentationExplorer },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Funnel Explorer",
                routerLink: "/category/Analysis_&_Research/funnel-explorer",
                permissions: { value: PermissionsName.FunnelExplorer },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Visits Explorer",
                routerLink: "/category/Analysis_&_Research/newBiStudio/visits-explorer",
                permissions: { value: PermissionsName.VisitsExplorer },
                isVisible: true
            },
            {
                name: "Realtime Customer Profile",
                routerLink: "/category/analysis_&_research/realtime-customer-profile",
                permissions: { value: PermissionsName.RealtimeCustomerProfile },
                isVisible: true
            },
        ]
    },
    {
        name: "Plan",
        icon: {
            iconName: "gps_fixed",
            iconType: NavbarIconType.Fill
        },
        isVisible: true,
        children: [
            {
                name: "Target Groups",
                routerLink: "/category/One-to-One_Campaigns/manage-target-groups",
                permissions: { value: PermissionsName.TargetGroups },
                isVisible: true
            },
            {
                name: "Actions",
                routerLink: "/category/one-to-one_campaigns/manage-actions",
                permissions: { value: PermissionsName.ManageActions },
                isVisible: true
            },
            {
                name: "Templates",
                permissions: { value: PermissionsName.ManageTemplates },
                isVisible: false
            },
            {
                name: "Promotions",
                routerLink: "/category/opti-promo", 
                permissions: { value: PermissionsName.Promotions },
                isLandingPage: true,
                isVisible: true,
                isNew: true
            },
            {
                name: "Streams",
                routerLink: "/category/one-to-one_campaigns/manage-streams",
                permissions: { value: PermissionsName.ManageStreams },
                isVisible: true
            },
            {
                name: "Realtime Triggers",
                routerLink: "/category/One-to-One_Campaigns/RealTimeTriggers.aspx",
                permissions: { value: PermissionsName.ManageRealTimeTriggers },
                isVisible: true
            },
        ]
    },
    {
        name: "Execute",
        icon: {
            iconName: "play_circle_outline",
            iconType: NavbarIconType.Fill
        },
        isVisible: true,
        children: [
            {
                name: "Campaign Builder",
                routerLink: "/category/one-to-one_campaigns/campaign-builder",
                permissions: {
                    value: LogicalOperatorType.Or,
                    left: { value: PermissionsName.ScheduledCampaignBuilder },
                    right: { value: PermissionsName.TriggeredCampaignBuilder },
                },
                isVisible: true
            },
            {
                name: "Stream Builder",
                routerLink: "/category/one-to-one_campaigns/streams-builder/visual-streams-builder",
                permissions: { value: PermissionsName.VisualStreamBuilder },
                isVisible: true
            },
        ]
    },
    {
        name: "Analyze",
        icon: {
            iconName: "track_changes",
            iconType: NavbarIconType.Fill
        },
        isVisible: true,
        children: [
            {
                name: "Customer 360",
                routerLink: "/category/Analysis_&_Research/customer360",
                permissions: { value: PermissionsName.Customer360 },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Marketing Plan Explorer",
                routerLink: "/category/One-to-One_Campaigns/Marketing_Plan_Explorer.aspx",
                permissions: { value: PermissionsName.MarketingPlanExplorer },
                isLandingPage: true,
                isVisible: true
            },
            {
                name: "Orchestration Overview",
                routerLink: "/category/One-to-One_Campaigns/newBiStudio/orchestration-overview",
                permissions: { value: PermissionsName.OrchestrationOverview },
                isVisible: true
            },
            {
                name: "Business Performance Overview",
                routerLink: "/category/Analysis_&_Research/newBiStudio/business-performance-overview",
                permissions: { value: PermissionsName.BusinessPerformanceOverview },
                isVisible: true
            },
            {
                name: "Repeat Rate Analysis",
                routerLink: "/category/Analysis_&_Research/newBiStudio/repeat-rate-analysis",
                permissions: { value: PermissionsName.RepeatRateAnalysis },
                isVisible: true,
                isNew: true
            },
            {
                name: "Benchmark Report",
                routerLink: "/category/Analysis_&_Research/newBiStudio/benchmark-report",
                permissions: { value: PermissionsName.BenchmarkReport },
                isVisible: true,
                isNew: true
            },
            {
                name: "Cohorts Analysis",
                routerLink: "/category/Analysis_&_Research/newBiStudio/cohorts-analysis",
                permissions: { value: PermissionsName.CohortsAnalysis },
                isVisible: true
            },
            {
                name: "Deliverability Metrics",
                routerLink: "/category/Analysis_&_Research/newBiStudio/deliverability-metrics",
                isVisible: true
            },
            {
                name: "BI Studio",
                routerLink: "/category/Analysis_&_Research/newBiStudio",
                permissions: { value: PermissionsName.BiStudio },
                children: [],
                isVisible: true
            }
        ]
    },
    {
        name: "Data Studio",
        icon: {
            iconName: "icon-data-studio",
            iconType: NavbarIconType.OptiFont
        },
        isVisible: true,
        children: [
            {
                name: "Attributes",
                routerLink: "/category/data_studio/attributes-list",
                permissions: { value: PermissionsName.Attributes },
                isVisible: true
            },
            {
                name: "Real-Time Attributes",
                routerLink: "/category/data__studio/realtime-attributes",
                permissions: { value: PermissionsName.RealtimeAttributes },
                isVisible: true
            },
            {
                name: "Imported Customers",
                routerLink: "/category/data__studio/imported-customers",
                permissions: { value: PermissionsName.ImportedCustomers },
                isVisible: true
            },
            {
                name: "Data Ingestion",
                routerLink: "/category/data___studio/data-ingestion",
                permissions: { value: PermissionsName.Attributes /*temporary development */ },
                isVisible: true,
                isNew: true,
            }
        ]
    },
    {
        name: "Optibot",
        routerLink: "/optibot",
        isOptibot: true,
        icon: {
            iconName: "lightbulb",
            iconType: NavbarIconType.Outlined
        },
        permissions: { value: PermissionsName.Optibot },
        isLandingPage: true,
        isVisible: true
    },
    {
        name: "Hidden Items",
        isVisible: false,
        children: [
            {
                name: "Campaign Analysis",
                routerLink: "/category/Analysis_&_Research/campaign-analysis",
                permissions: { value: PermissionsName.CampaignAnalysisPage },
                isVisible: false
            },
            {
                name: "Self-Optimized Campaign Analysis",
                routerLink: "/category/Analysis_&_Research/self-optimizing-campaign-analysis",
                permissions: { value: PermissionsName.SelfOptimizedCampaignAnalysisPage },
                isVisible: false
            },
            {
                name: "Streams Analysis",
                routerLink: "/category/One-to-One_Campaigns/streams-analysis",
                permissions: { value: PermissionsName.StreamsAnalysis },
                isVisible: false
            },
            {
                name: "Streams Analysis",
                routerLink: "/streams/analysis",
                permissions: { value: PermissionsName.StreamsAnalysis },
                isVisible: false
            },
            {
                name: "Scheduled Campaign Builder",
                routerLink: "/category/one-to-one_campaigns/campaign-builder/scheduled",
                permissions: { value: PermissionsName.ScheduledCampaignBuilder },
                isVisible: false
            },
            {
                name: "Triggered Campaign Builder",
                routerLink: "/category/one-to-one_campaigns/campaign-builder/triggered",
                permissions: { value: PermissionsName.TriggeredCampaignBuilder },
                isVisible: false
            }
        ]
    }
] as NavbarTreeNode[];