<div class="save-row-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <form [formGroup]="form" class="panel-body" autocomplete="off">
      <div class="form-horizontal">
        <div class="form-group saveCustomRowNameSection">
          <label for="customRowName" class="col-sm-3 control-label">
            {{ "features.manage_templates.components.beefreeSavedRows.ROW_NAME" | translate }}
          </label>
          <div class="col-sm-9">
            <input formControlName="newRowName" type="text" id="customRowName" class="form-control" autocomplete="on" />
          </div>
        </div>

        <div class="form-group saveForAllOrForOneSection">
          <div class="col-md-12 label-value-container">
            <div class="radio radio-primary radio-exclusion exclude-radio-container">
              <input formControlName="isForAllBrands" value="1" class="form-control" type="radio" name="isForAllBrands" id="rdoExclude" />
              <label for="rdoExclude">{{ "features.manage_templates.components.beefreeSavedRows.SAVE_FOR_ALL_BRANDS" | translate }}</label>
            </div>
            <div class="radio radio-primary radio-exclusion">
              <input formControlName="isForAllBrands" value="0" class="form-control" type="radio" name="isForAllBrands" id="rdoInclude" />
              <label for="rdoInclude">
                {{
                  translateService
                    .instant("features.manage_templates.components.beefreeSavedRows.SAVE_ONLY_FOR_BRAND_NEW")
                    .concat(brandAndType)
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </opti-logic-modal>
</div>
