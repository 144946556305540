import { Component, HostListener, Input , ViewEncapsulation } from "@angular/core";
import { TemplateModalData } from "./models/templateModalData.model";
import { TemplateModalType } from "./models/templateModalType.enum";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: 'template-modal-container',
    templateUrl: './templateModalContainer.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./templateModalContainer.component.scss']
})
export class TemplateModalContainerComponent {
    @Input() templateModalData: TemplateModalData;
    @Input() datesData: [];

    public templateModalTypes = TemplateModalType;

    constructor(private bsModalRef: BsModalRef) {
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    onShowTemplatesGridViewModal() {
        this.templateModalData.type = TemplateModalType.Grid;
    }

    onShowTemplatesCarouselModal(templateIndex: number) {
        this.templateModalData.type = TemplateModalType.Carousel;
        this.templateModalData.displayTemplateIndex = templateIndex;
    }
}
