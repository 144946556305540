import { Params } from "@angular/router";

export interface NavbarTreeNode {
    name: string;
    routerLink?: string;
    isNew?: boolean;
    isOptibot?: boolean;
    children?: NavbarTreeNode[];
    featureFlags?: string;
    icon?: NavbarIcon;
    permissions?: TreeNode<number | LogicalOperatorType>;
    isLandingPage?: boolean;
    isVisible: boolean;
}

export interface NavbarIcon {
    iconName: string;
    iconType: NavbarIconType
}

export enum NavbarIconType {
    Fill = 0,
    Outlined = 1,
    OptiFont = 2
}

export interface NavbarTreeNodeView {
    name: string;
    isDisabled: boolean;
    routerLink?: string;
    queryParams?: Params;
    isNew?: boolean;
    isBeta?: boolean;
    isOptibot?: boolean;
    children?: NavbarTreeNodeView[];
    icon?: NavbarIcon;
    isVisible: boolean
}

export interface UserPermissions {
    permissionName: string;
    permissionId: number;
    isEnabled: boolean;
}

export interface TreeNode<T> {
    value: T;
    left?: TreeNode<T>;
    right?: TreeNode<T>;
}

export enum LogicalOperatorType {
    And, 
    Or
}

export enum PermissionsName {
    CustomerModel = 8,
    SegmentationExplorer = 9,
    CohortsAnalysis = 14,
    FunnelExplorer = 16,
    TargetGroups = 23,
    ManageActions = 24,
    ScheduledCampaignBuilder = 28,
    ManageTemplates = 38,
    CustomerExplorer = 41,
    ManageRealTimeTriggers = 42,
    MarketingPlanExplorer = 43,
    Optibot = 44,
    InterStageMigrations = 46,
    Customer360 = 47,
    Dashboard = 50,
    BiStudio = 55,
    ManageStreams = 56,
    TriggeredCampaignBuilder = 59,
    RealtimeCustomerProfile = 61,
    MissionControl = 62,
    VisitsExplorer = 64,
    VisualStreamBuilder = 65,
    OrchestrationOverview = 66,
    BusinessPerformanceOverview = 67,
    CampaignAnalysisPage = 39,
    SelfOptimizedCampaignAnalysisPage = 49,
    StreamsAnalysis = 58,
    AudienceDiscovery = 68, //It's TG value, need to change
    RepeatRateAnalysis = 70,
    Attributes = 71,
    ImportedCustomers = 72,
    RealtimeAttributes = 73,
    DataIngestion = 74,
    Promotions = 75,
    BenchmarkReport = 76
}