import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IDateRangePicker } from 'src/app/components/dateRangePicker/dateRangePicker.component';
import { SearchListConfig } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { IDatePicker } from 'src/app/components/datePicker/datePicker.component';
import {
  ConditionalValidatorService
} from 'src/app/features/settings/attributes/services/conditionalValidator.service';
import { Operator } from 'src/app/features/scheduledCampaignBuilder/models/wizardData.model';
import { SearchItem } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import {
  AttributeDataTypeConditional,
  ConditionValueFormat
} from 'src/app/features/settings/attributes/attributesList.enums';
import { ConditionalAttributeService } from 'src/app/features/settings/attributes/services/condition.service';

@Component({
  selector: 'if-else-condition',
  templateUrl: './ifElseCondition.component.html',
  styleUrls: ['./ifElseCondition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IfElseConditionComponent,
      multi: true
    }]
})
export class IfElseConditionComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() attributesSearchItems: any[];
  @Input() Position: number;
  @Input() isAddMode: boolean;
  @Input() disabled: boolean;
  @Output() changeEmitter: EventEmitter<FormGroup> = new EventEmitter();

  public translateKeys = translateKeys;
  public conditionForm: FormGroup;
  public config = {
    possibleValues: [],
    operators: [],
  };
  
  private subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private conditionService: ConditionalAttributeService,
              private validatorService: ConditionalValidatorService) { }

 
  ngOnInit(): void {
    this.conditionForm = this.conditionService.createConditionForm(this.Position);
    this.dateRangePickerConfig.isDisabled = this.disabled;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => { x.unsubscribe() });
  }

  onAttributeChange(item: SearchItem[]) {
    let attributeFieldName = item[0].key as string;

    this.attributeDataType.setValue(this.conditionService.getAttributeDataType(attributeFieldName));
    this.config.possibleValues = this.conditionService.getPossibleValuesForAttribute(attributeFieldName);

    this.setOperators(this.conditionService.getOperators(this.attributeDataType.value));
    this.calculateValueFormat(this.attributeDataType.value, this.operator.value[0] ? this.operator.value[0].text : null, true);

    this.changeEmitter.emit(this.conditionForm);
  }

  onOperatorChange(operatorItem: SearchItem[], emitEvent: boolean = true) {
    this.calculateValueFormat(this.attributeDataType.value, operatorItem[0] ? operatorItem[0].text : null, emitEvent);
    
    this.changeEmitter.emit(this.conditionForm);
  }

  onValueChange(value: any = null) {
    if((this.attributeDataType.value as AttributeDataTypeConditional) === "date") {
      this.value.setValue(value);
    }
    this.changeEmitter.emit(this.conditionForm);
  }

  setFirstInvalidInputAsTouched() { 
    if (this.attribute.invalid) {
      this.attribute.markAsDirty();
    }
    else if (this.operator.invalid) {
      this.operator.markAsDirty();
    }
    else if (this.value.invalid) {
      this.value.markAsTouched();
    }
  }

// ================================ \\
// ===| Input`s configs region |=== \\
  
  public datePickerConfig: IDatePicker = {
    placeholder: this.translate.instant(this.translateKeys.value),
    placement: 'bottom',
  }
  public dateRangePickerConfig: IDateRangePicker = {
    date: null,
    placeholder: this.translate.instant(this.translateKeys.value),
    placement: 'bottom'
  }
  public attributeConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.attribute),
    keyProperty: "key",
    valueProperty: "text",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.attribute),
    isMultiSelect: false
  };
  public operatorConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.condition),
    keyProperty: "key",
    valueProperty: "text",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.condition),
    isMultiSelect: false
  };
  public valueConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.value),
    keyProperty: "key",
    valueProperty: "text",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.value),
    isMultiSelect: false
  };

// ================================ \\
// =====| FormControl region |===== \\

  get attribute(): FormControl {
    return this.conditionForm.get('attributeFieldName') as FormControl;
  }

  get operator(): FormControl {
    return this.conditionForm.get('operator') as FormControl;
  }

  get value(): FormControl {
    return this.conditionForm.get('value') as FormControl;
  }

  get valueFormat(): FormControl {
    return this.conditionForm.get('formatType') as FormControl;
  }

  get position(): FormControl {
    return this.conditionForm.get('position') as FormControl;
  }

  get attributeDataType(): FormControl {
    return this.conditionForm.get('attributeDataType') as FormControl;
  }

// ================================ \\
// ====| ValueAccessor region |==== \\

  writeValue(form: FormGroup): void {
    this.conditionForm = form ? form : this.conditionService.createConditionForm(this.Position);
    this.config.possibleValues = this.conditionService.getPossibleValuesForAttribute(this.attribute.value[0].key);

    this.valueConfig.isMultiSelect = this.attributeDataType.value as AttributeDataTypeConditional === "multiValues" 
                                     && ["One of","Not one of"].includes(this.operator.value[0].text);
    this.setOperators(this.conditionService.getOperators(this.attributeDataType.value));
    this.calculateValueFormat(this.attributeDataType.value, this.operator.value[0] ? this.operator.value[0].text : null, true);
    this.setValueValidators(this.valueFormat.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.attribute.disable({emitEvent: false});
      this.operator.disable({emitEvent: false});
      this.value.disable({emitEvent: false});
    }
    else {
      this.attribute.enable({emitEvent: false})
      this.operator.enable({emitEvent: false});
      this.value.enable({emitEvent: false});
    }
  }

  onChange: any = () => {};
  
  onTouch: any = () => {};

// ================================ \\
// =======| private region |======= \\

  private setValueFormat(format: ConditionValueFormat, emitEvent: boolean = true) {
    if(this.valueFormat.value as ConditionValueFormat != format) {
      this.valueFormat.setValue(format, {emitEvent: emitEvent});
      this.value.setValue('', {emitEvent: emitEvent});
      this.setValueValidators(format);
      this.value.updateValueAndValidity();
    }
  }

  private setOperators(operators: Operator[]) {
    let operatorsSearchItems: SearchItem[] = this.conditionService.convertOperatorsToSearchItems(operators);
    if(!this.config.operators.every((x, i) => operatorsSearchItems[i].key === x.key && operatorsSearchItems[i].text === x.text)){
      this.operator.setValue('');
    }
    this.config.operators = operatorsSearchItems;
  }
  
  private setValueValidators(format: ConditionValueFormat) {
    let validators = format === 'disable' 
    ? [] 
    : format === 'between numbers' 
      ? [this.validatorService.validNumbersPicker(), Validators.required]
      : format === 'dropdown'
        ? [this.validatorService.validDropdown(), Validators.required]
        : Validators.required;

    this.value.setValidators(validators);
  }

  private calculateValueFormat(attrType: AttributeDataTypeConditional, operatorText: string, emitEvent: boolean = true) {

    switch (attrType) {
      case "number":
        if(operatorText && operatorText === "Between") {
            this.setValueFormat("between numbers", emitEvent);
          break;
        }
        this.setValueFormat("number", emitEvent);
        break;

      case "string":
        switch (operatorText) {
          case "Is null":
          case "Is not null":
            this.setValueFormat("disable", emitEvent);
            break;
          default:
            this.setValueFormat("text", emitEvent);
            break;
        }
        break;

      case "date":
        if(!operatorText) {
          this.setValueFormat("date", emitEvent);
          break;
        }
        switch (operatorText) {
          case "After":
          case "Before":
          case "Does not equal":
          case "Equals":
            this.setValueFormat("date", emitEvent);
            break;
          case "Between":
            this.setValueFormat("between dates", emitEvent);
            break;
          case "This week":
          case "Today":
          case "Tomorrow":
          case "Yesterday":
          case "Is null":
          case "Is not null":
            this.setValueFormat("disable", emitEvent);
            break;
        }
        break;

      case "multiValues":
        if(!operatorText) {
          this.setValueFormat("text", emitEvent);
          break;
        }
        switch (operatorText) {
          case "Begins with":
          case "Ends with":
          case "Contains":
          case "Does not contain":
            this.setValueFormat("text", emitEvent);
            break;
          case "Equals":
          case "Does not equal":
            if(this.valueConfig.isMultiSelect) { this.value.setValue('') }
            this.valueConfig.isMultiSelect = false;
            this.setValueFormat("dropdown", emitEvent);
            break;
          case "One of":
          case "Not one of":
            if(!this.valueConfig.isMultiSelect) { this.value.setValue('') }
            this.valueConfig.isMultiSelect = true;
            this.setValueFormat("dropdown", emitEvent);
            break;
        }
        break;

      default:
        this.setValueFormat("disable", emitEvent);
    }
  }
}

const translateKeys = {
  attribute: 'features.user_settings.body.attributes.addConditionAttribute.ATTRIBUTE',
  condition: 'features.user_settings.body.attributes.addConditionAttribute.CONDITION',
  value: 'features.user_settings.body.attributes.addConditionAttribute.VALUE',
}