import { Injectable} from '@angular/core';

@Injectable()
export class UtilsMethodsService {
    public isPositiveIntegerKeydown(key: string, inputValue: number) {
        if (key === '.' ||
            key === 'e' ||
            key === '-' ||
            key === 'Control') {
            return false;
        }
        if (key === 'ArrowDown' && inputValue === 0) {
            return false;
        }
    }

    public abSorting(a: string, b: string): number {
        const aLowerCase = a.toLowerCase();
        const bLowerCase = b.toLowerCase();

        if (aLowerCase < bLowerCase) {
            return -1;
        } else if (aLowerCase > bLowerCase) {
            return 1;
        }
        return 0;
    }
}