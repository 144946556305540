import { Component } from '@angular/core';
import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/all-modules';
import { fadeIn } from './loadingTemplateCart.animation';

@Component({
	selector: 'loading-template-cart',
	templateUrl: 'loadingTemplateCart.component.html',
	styleUrls: ['loadingTemplateCart.component.scss'],
	animations: [fadeIn]
})
export class LoadingTemplateCartComponent implements ILoadingCellRendererAngularComp {
	public items = new Array(6);

	agInit(params: ILoadingCellRendererParams): void {}
}
