import { Component, OnInit } from '@angular/core';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { LanguageTemplate } from '../../../models/languageTemplate';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateAvailableLanguage } from '../../../models/templateAvailableLanguage';

enum FallbackOption {
	NoFallback,
	FallbackLanguage
}

interface LanguageItem extends IListItem {
	code: string;
}

export interface SelectFallbackLanguageInitialState {
	templateLanguages: TemplateAvailableLanguage[];
	languageDefinitions: LanguageTemplate[];
	onSave: (id: string | number | null) => void;
}

@Component({
	selector: 'select-fallback-language',
	templateUrl: './selectFallbackLanguage.component.html',
	styleUrls: ['./selectFallbackLanguage.component.scss']
})
export class SelectFallbackLanguageComponent implements OnInit, SelectFallbackLanguageInitialState {
	FallbackOption = FallbackOption;

	selectedOption: FallbackOption = FallbackOption.NoFallback;
	listOfLanguages: LanguageItem[] = [];
	templateLanguages: TemplateAvailableLanguage[] = [];
	languageDefinitions: LanguageTemplate[];
	fallbackLanguageId: number;
	onSave: (id: string | number | null) => void = (id: string | number | null) => {};

	constructor(private bsModalRef: BsModalRef) {}

	ngOnInit(): void {
		this.listOfLanguages = this.templateLanguages?.map((templateLanguage) => {
			const languageDefinition = this.languageDefinitions?.find((l) => l.Id === templateLanguage.languageId);
			return {
				id: templateLanguage.languageId,
				name: languageDefinition?.DisplayName,
				code: languageDefinition?.Code
			};
		});
		this.fallbackLanguageId =  this.templateLanguages?.find((l) => l.isFallback)?.languageId;
		if(this.fallbackLanguageId) {
			this.selectedOption = FallbackOption.FallbackLanguage;
		}
		if(!this.fallbackLanguageId && this.templateLanguages?.length) {
			this.fallbackLanguageId = this.templateLanguages[0].languageId;
		}
	}

	close(): void {
		this.bsModalRef.hide();
	}

	save(): void {
		const templateLanguage = this.templateLanguages.find(l => l.languageId === this.fallbackLanguageId);
		if (templateLanguage.id) {
			this.onSave(this.selectedOption === FallbackOption.NoFallback ? null : templateLanguage.id);
		} else {
			templateLanguage.isFallback = this.selectedOption === FallbackOption.FallbackLanguage;
		}
		this.bsModalRef.hide();
	}
}
