import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import {
	ActivityAttribute,
	IAnalysisRange,
	IAttributeMetric,
	IAttributesMappingData, IC360DataRequest, ICustomer360DataServiceConfiguration, ICustomerAttribute,
	ICustomerAttributeMapping,
	ICustomerInfo,
	ISearchOptions,
	ISearchProp
} from 'src/app/features/customer360/customer360.models';
import { SsmService } from "src/app/services/ssm.service";
import { Observable, of } from "rxjs";
import { catchError, map, first, share } from "rxjs/operators";
import { HttpService } from '../../../services/optimove-http/optimove-http.model';

@Injectable()
export class Customer360DataService {
    private baseUrl: string = '/Customer360';
		private configurationCampaignsDetails: ICustomer360DataServiceConfiguration = {
			request: null,
			campaigns: null
		};
		private configurationCampaignsKPI: ICustomer360DataServiceConfiguration = {
			request: null,
			campaigns: null
		}

	constructor(private readonly http: HttpService, private SSMService: SsmService) { }

    public getClientId(clientCustomerId: string) {
    	const params = HttpService.toHttpParams({clientCustomerId});
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomerId`, params);
    }

    public getCustomerHeaderDetails(clientCustomerId: string) {
    	const params = HttpService.toHttpParams({customerId: clientCustomerId});
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomerHeaderDetails`, params);
    }

    public setFirstAttribute(attributeFieldName: string, clientCustomerId: string) {
    	const body = {
    		customerProfileFieldName: attributeFieldName,
    		customerId: clientCustomerId
    	};
    	return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/SetFirstAttribute`, body);
    }

	public getCustomerAttributes(): Observable<{ [key: string]: ICustomerAttribute }> {
		return this.http.get<{ [key: string]: ICustomerAttribute }>(`${this.baseUrl}/GetCustomerAttributes`, {});
	}

    public getAttributeMetricFromStorage(aliasName: string): IAttributeMetric {
    	const SSM = this.SSMService.getSSM();
    	const storedAttributes = SSM.GetGeneric(SSM.Resx.CustomerAttributes);
    	const attributeFieldName = Object.getOwnPropertyNames(storedAttributes)
    		.find(x => storedAttributes[x].Alias.toUpperCase() === aliasName.toUpperCase());
        
    	return attributeFieldName 
    		? {
    			FieldName: attributeFieldName,
    			AliasName: storedAttributes[attributeFieldName].Alias,
    			Format: storedAttributes[attributeFieldName].Format,
    			DataType: storedAttributes[attributeFieldName].DataType,
    		}
    		: undefined;
    }

    public getCampaignsDetailsPerCustomerBetweenDates(customerId: string, startDate: string, endDate: string): Observable<DefaultAjaxResponse> {
    	const params = {
    		customerId,
    		startDate,
    		endDate
    	} ;

		if (!this.canUseClientData(params, this.configurationCampaignsDetails)) {
			this.configurationCampaignsDetails.request = params as IC360DataRequest;
			this.configurationCampaignsDetails.campaigns = this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCampaignsDetailsPerCustomerBetweenDates`, HttpService.toHttpParams(params)).pipe(share());	
		}
		return this.configurationCampaignsDetails.campaigns
    }

    public getCampaignKPIsDataByCustomerBetweenDates(customerId: string, startDate: string, endDate: string): Observable<DefaultAjaxResponse> {
    	const params = {
    		customerId,
    		startDate,
    		endDate
    	} ;

		if (!this.canUseClientData(params, this.configurationCampaignsKPI)) {
			this.configurationCampaignsKPI.request = params as IC360DataRequest;
			this.configurationCampaignsKPI.campaigns = this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCampaignKPIsDataByCustomerBetweenDates`, HttpService.toHttpParams(params)).pipe(share());
		}
		return this.configurationCampaignsKPI.campaigns;
    }

	private canUseClientData(request: IC360DataRequest, configuration: ICustomer360DataServiceConfiguration): boolean {
		return configuration.request 
			&& configuration.request.customerId === request.customerId
			&& configuration.request.startDate === request.startDate
			&& configuration.request.endDate === request.endDate;
	}

    public getCustomersBySearchString(obj: ISearchOptions): Observable<ICustomerInfo[]> {
    	return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/GetOptimoveCustomersBySearchString`, obj).pipe(catchError(error => of(error))).pipe(
    		map((res: DefaultAjaxResponse) => {
    			const customers = res.Data as ICustomerInfo[];
    			return customers.map((c, i) => { return {...c, id: `${i}`} as ICustomerInfo});
    		})
    	);
    }

    public getPropsToSearch(): Observable<ISearchProp[]> {
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomerSearchProps`)
    		.pipe(catchError(error => of(error))).pipe(
    			map((res: DefaultAjaxResponse) => {
    				return Object.keys(res.Data).map(x => {
    					return {key: x, value: res.Data[x] ?? x, disabled: !res.Data[x]}
    				});
    			})
    		);
    }

	public getProfileAttributesByCustomerBetweenDates(clientCustomerId: string, fieldSet: string, startDate: string, endDate: string): Observable<DefaultAjaxResponse> {
		const params = HttpService.toHttpParams({
			customerId: clientCustomerId,
			fieldSet,
			startDate,
			endDate,
		})
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetProfileAttributesByCustomerBetweenDates`, params);
    }

	//CustomerSnapshotsTableComponent
	public getAttributesMappingData(): Observable<IAttributesMappingData> {
		return new Observable<IAttributesMappingData>((observer) => {
			this.getDefaultAttributes().pipe(first()).subscribe(response => {
				const defaultCustomerAttributesList = response.Data.DefaultAttributes;
				
				const result: IAttributesMappingData = {
					checkedCustomerAttributesKeys: "",
					customerAttributesList: [],
				};

				const selectedAttrMapping = localStorage
					.getItem(localStorageKeys.selectedAttrMapping)
					?.replace(/"/g,'')
					?.split(',');

				const ssm = this.SSMService.getSSM();
				const customerAttributes = ssm.GetGeneric(ssm.Resx.CustomerAttributes);
				for (const key in customerAttributes) {
					const attr = customerAttributes[key.toUpperCase()];
					const attribute: ICustomerAttributeMapping = {
						Alias: attr?.Alias,
						Name: attr?.Name,
						RealFieldName: attr?.RealFieldName,
						Tooltip: attr?.Tooltip,
						Format: attr?.Format,
						MetricType: attr?.MetricType,
						IsDefaultRun: attr?.IsDefaultRun,
						EnforceFormatting: attr?.EnforceFormatting,
						IsHidden: attr?.IsHidden,
						IsChecked: attr?.IsDefaultRun,
						IsFieldUpdatedByApi: attr?.IsFieldUpdatedByApi,
						FieldType: attr?.FieldType,
						DataType: attr?.DataType
					}

					result.customerAttributesList.push(attribute);
				}

				for (const key in defaultCustomerAttributesList) {
					const attribute: ICustomerAttributeMapping = {
						Alias: defaultCustomerAttributesList[key].Alias,
						Name: defaultCustomerAttributesList[key].Name,
						RealFieldName: defaultCustomerAttributesList[key].RealFieldName,
						Tooltip: defaultCustomerAttributesList[key].Tooltip,
						Format: defaultCustomerAttributesList[key].Format,
						MetricType: defaultCustomerAttributesList[key].MetricType,
						IsDefaultRun: defaultCustomerAttributesList[key].IsDefaultRun,
						EnforceFormatting: defaultCustomerAttributesList[key].EnforceFormatting,
						IsHidden: defaultCustomerAttributesList[key].IsHidden,
						IsChecked: defaultCustomerAttributesList[key].IsDefaultRun,
						FieldType: defaultCustomerAttributesList[key].FieldType,
						DataType: defaultCustomerAttributesList[key].DataType,
						IsFieldUpdatedByApi: false
					}

					result.customerAttributesList.push(attribute);
				}

				result.customerAttributesList.forEach(attribute => {
					if (selectedAttrMapping) {
						attribute.IsChecked = selectedAttrMapping.includes(attribute.Name);
					} else {
						attribute.IsChecked = attribute.IsDefaultRun;
					}

					if (attribute.IsChecked) {
						result.checkedCustomerAttributesKeys += `${ attribute.Name },`;
					}
				});
	
				//Remove a comma at the end of the string if it exists
				if (result.checkedCustomerAttributesKeys !== '' && result.checkedCustomerAttributesKeys[result.checkedCustomerAttributesKeys.length - 1] === ',') {
					result.checkedCustomerAttributesKeys = result.checkedCustomerAttributesKeys.substring(0, result.checkedCustomerAttributesKeys.length - 1);
				}

				observer.next(result);
				observer.complete();
			});
		});
	}

	public setSelectedAttrMapping(attributesKeys: string[]) {
		localStorage.setItem(localStorageKeys.selectedAttrMapping, `"${attributesKeys.join(',')}"`);
	}

	public setToLocalStorage(localStorageKey: any, item: string) {
		localStorage.setItem(localStorageKey, item);
	}

	public getFromLocalStorage(localStorageKey: any): string {
		return localStorage.getItem(localStorageKey);
	}

	private getDefaultAttributes(): Observable<DefaultAjaxResponse> {
		return this.http.get<DefaultAjaxResponse>(`${ this.baseUrl }/GetDefaultAttributes`, {});
	}

	public getCampaignMeasuresMapping() {
		const ssm = this.SSMService.getSSM();
		return ssm.GetGeneric(ssm.Resx.MeasuresMapping);
	}

	public getDefaultKPI() {
		const ssm = this.SSMService.getSSM();
		return ssm.GetGeneric(ssm.Resx.DefaultKpiCampaigns);
	}

	public resetCampaignMeasuresMapping(): Observable<void> {
		return new Observable<void>((observer) => {
			const ssm = this.SSMService.getSSM();
			this.getAllCampaignMeasuresMapping().pipe(first()).subscribe(res => {
				ssm.SetGeneric(ssm.Resx.MeasuresMapping, res);
				observer.next();
				observer.complete();
			});
		});
	}

	private getAllCampaignMeasuresMapping(): Observable<any> {
    	return this.http.get<any>('/Main/GetAllCampaignMeasuresMapping');
    }

	public getActivityTypes(): Observable<DefaultAjaxResponse> {
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetActivityTypes`);
    } 

	public getActivityAttributes(): Observable<DefaultAjaxResponse> {
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetActivityAttributes`);
    } 

	public getPurchaseHistoryAttributes(): Observable<DefaultAjaxResponse> {
    	return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetPurchaseHistoryAttributes`);
    } 

	public GetNewPurchaseHistoryFF(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.isPurchaseHistoryMultiAttributeFlow().pipe(first()).subscribe((res) => {
				resolve(res.Data);
			});
		})
	}

	public isPurchaseHistoryMultiAttributeFlow() {
        return this.http.get<DefaultAjaxResponse>('/AttributesManager/IsPurchaseHistoryMultiAttributeFlow');
    }

	public GetPurchaseHistoryMultiAttributeFlowBetweenDates(customerId: string, dateRange: IAnalysisRange, attributes: ActivityAttribute[]) {
		const body = {
			customerId,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate,
			transactionAttributes: attributes.filter(x => x.AttributeType === 1).map(x => x.AttributeId),
			productAttributes: attributes.filter(x => x.AttributeType === 0).map(x => x.AttributeId)
		}
        return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/GetPurchaseHistoryMultiAttributeFlowBetweenDates`, body);
	}

	public GetPurchaseHistoryBetweenDates(customerId: string, dateRange: IAnalysisRange) {
		const body = {
			customerId,
			startDate: dateRange.startDate,
			endDate: dateRange.endDate
		}
        return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/GetPurchaseHistoryBetweenDates`, body);
	}
}

const localStorageKeys = {
	selectedAttrMapping: 'customerAttributes.selectedAttrMapping',
}
