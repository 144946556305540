import { Injectable, Inject } from '@angular/core';

@Injectable()
export class CookiesService {
    public setCookie(cookie: string) {
        document.cookie = cookie;
    }
    public getCookie(key) : string{
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }
}