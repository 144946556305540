import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentTag } from '../../models/contentTag';

@Directive({
	selector: '[uniqueContentTagName]',
	providers: [
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: ContentTagNameValidatorDirective,
			multi: true
		}
	]
})
export class ContentTagNameValidatorDirective {
	@Input() currentContentTag!: ContentTag;
	@Input() skipValidation!: boolean;
	@Input() contentTagList: ContentTag[];

	constructor() {}

	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		let nameValue = control.value.toLocaleLowerCase();
		if (nameValue.includes(' ')) {
			return of({ nameContainsSpaces: 'features.manage_templates.optimail.contentTags.CONTENT_TAG_SPACES' });
		}
		if (!nameValue || this.skipValidation) {
			return of(null);
		}
		this.contentTagList.forEach((contentTag) => (contentTag.Name = contentTag.Name.toLocaleLowerCase()));
		let response = this.contentTagList.filter((contentTag) => contentTag.Name === nameValue);
		return of(response && response.length > 0 ? { nameUnique: 'features.manage_templates.optimail.contentTags.NAME_IN_USE' } : null);
	}
}
