import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageStatus } from '../../../models/languageStatus';
import { ColoredTagType } from '@optimove/ui-sdk/components/colored-tag';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import {
	TranslationLanguagesComponent,
	TranslationLanguagesInitialState
} from '../../dialogs/translationLanguagesModal/translationLanguages.component';
import { LanguageTemplate } from '../../../models/languageTemplate';
import { TemplateContextService } from '../../../services/templateContext.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TemplateDetails } from '../../../models/templateDetails';
import { TemplateAvailableLanguage } from '../../../models/templateAvailableLanguage';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { TemplateContentPart } from '../../../models/templateContentPart';
import { DefaultLanguageModalComponent } from '../../dialogs/defaultLanguageModal/defaultLanguageModal.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { OverflowMenuItem } from 'src/app/components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { TranslateService } from '@ngx-translate/core';
import {
	SelectFallbackLanguageComponent,
	SelectFallbackLanguageInitialState
} from '../../dialogs/selectFallbackLanguage/selectFallbackLanguage.component';
import { distinctUntilChanged, switchMap, throttleTime } from 'rxjs/operators';

interface LanguageListItem extends TemplateAvailableLanguage {
	name: string;
}

@Component({
	selector: 'translation-panel',
	templateUrl: './translationPanel.component.html',
	styleUrls: ['./translationPanel.component.scss']
})
export class TranslationPanelComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	private refreshSyncDate = new Subject<boolean>();
	private templateChanged = new BehaviorSubject<number>(-1);
	private refreshSyncDateTimeout = 1000 * 60;

	template: TemplateDetails;
	public defaultLanguage: string;
	isSyncing: boolean;
	isMultiLanguage: boolean;
	isAllLanguagesNew: boolean;
	isAnyLanguageTranslated: boolean;
	languageStatusList: LanguageListItem[] = [];
	isTemplateDirty: boolean = true;
	isContentEmpty: boolean = true;
	lastUpdated: Date;
	languageDefinitions: LanguageTemplate[];

	isNoLanguagesContentChanged: boolean;
	isSyncDisabled: boolean;
	isAllLanguagesInProgressOrTranslated: boolean;

	isSaveHintVisible: boolean;
	isChangeSyncHintVisible: boolean;
	isNewSyncHintVisible: boolean;
	isSearchMode: boolean;

	menuPosition = [
		new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'top' }),
		new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'bottom' })
	];
	overflowMenuItems = [
		{
			callback: () => this.openFallbackLanguageDialog(),
			text: this.translate.instant('features.manage_templates.optimail.translationPanel.SET_DEFAULT_LANGUAGE')
		} as OverflowMenuItem
	];
	constructor(
		private modalService: OptiLogicModalService,
		private context: TemplateContextService,
		private manageTemplatesService: ManageTemplatesService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.context.current.subscribe((t) => {
				this.template = t;

				this.languageStatusList = [];
				this.isMultiLanguage = false;
				this.defaultLanguage = undefined;

				const templateLanguages = this.template?.languages;
				if (templateLanguages && templateLanguages.length > 0) {
					this.manageTemplatesService.getTemplateLanguage().subscribe((languages) => {
						this.languageDefinitions = languages;
						this.languageStatusList = templateLanguages.map((templateLanguage: TemplateAvailableLanguage) => {
							if (this.defaultLanguage === undefined) {
								this.defaultLanguage = languages.find(
									(lang) => lang.Id === templateLanguage.languageId && templateLanguage.isDefault === true
								).DisplayName;
							}
							const name = languages.find((l) => l.Id === templateLanguage.languageId).DisplayName;
							return { ...templateLanguage, name };
						});
					});
					this.isMultiLanguage = templateLanguages.length > 0;
					const isAnyLanguageNew = !!templateLanguages.find((l) => l.status === LanguageStatus.New && !l.isDefault);
					this.isAllLanguagesNew = !templateLanguages.find((l) => l.status !== LanguageStatus.New && !l.isDefault);
					const isAnyLanguageContentChanged = !!templateLanguages.find((l) => l.status === LanguageStatus.ContentChanged);
					this.isNoLanguagesContentChanged = !templateLanguages.find((l) => l.status === LanguageStatus.ContentChanged);
					this.isAnyLanguageTranslated = !!templateLanguages.find((l) => l.status !== LanguageStatus.Translated);
					this.isAllLanguagesInProgressOrTranslated = !templateLanguages.find(
						(l) => l.status !== LanguageStatus.InProgress && l.status !== LanguageStatus.Translated && !l.isDefault
					) && templateLanguages.length > 1;
					this.isTemplateDirty = this.context.isDirty();
					this.isSyncDisabled = this.isTemplateDirty || this.isContentEmpty || templateLanguages.length < 2;
					this.isSaveHintVisible = this.isTemplateDirty;
					this.isChangeSyncHintVisible = !this.isTemplateDirty && isAnyLanguageContentChanged;
					this.isNewSyncHintVisible =
						!this.isTemplateDirty && !isAnyLanguageContentChanged && isAnyLanguageNew && !this.isAllLanguagesNew;
						
					this.refreshSyncDate.next(true);
					this.templateChanged.next(t.templateId);
				}
				this.isContentEmpty = this.context.isCurrentTemplateEmpty();
			})
		);

		const refreshSyncDateOnTemplateChangeAndNotTooOften =
			this.refreshSyncDate.pipe(throttleTime(this.refreshSyncDateTimeout)).pipe(switchMap(() => this.templateChanged.pipe(distinctUntilChanged())));
		this.subscriptions.add(refreshSyncDateOnTemplateChangeAndNotTooOften.subscribe(() => this.updateSyncDate()));
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
		this.subscriptions = null;
	}

	public get languageStatusTypes(): typeof LanguageStatus {
		return LanguageStatus;
	}

	public get coloredTypeAccess(): typeof ColoredTagType {
		return ColoredTagType;
	}

	openLanguageTranslationModal() {
		const languagesPreSelected = this.languageStatusList?.map((templateLanguage) => {
			const languageDefinition = this.languageDefinitions.find((ld) => ld.Id === templateLanguage.languageId);
			return { ...languageDefinition, isDefault: templateLanguage.isDefault };
		});

		this.modalService.open(TranslationLanguagesComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			class: '',
			initialState: {
				languagesPreSelected,
				languagesSelected: (languages: LanguageTemplate[]) => this.onLanguagesSelected(languages)
			} as TranslationLanguagesInitialState
		});
	}

	private onLanguagesSelected(languages: LanguageTemplate[]): void {
		const availableLanguages = languages.map((l) => {
			const templateLanguage = this.template.languages?.find((templateLanguage) => templateLanguage.languageId === l.Id);
			return (
				templateLanguage ?? {
					id: 0,
					isDefault: l.Name === this.defaultLanguage,
					isFallback: false,
					templateId: this.template.templateId,
					subMethodId: this.template.subMethodId,
					languageId: l.Id,
					status: LanguageStatus.New
				}
			);
		});
		this.context.updateLanguages(availableLanguages);
	}

	public openDefaultLanguageModal(): void {
		if (this.defaultLanguage === undefined)
			this.modalService.open(DefaultLanguageModalComponent, 'sm', <ModalOptions<any>>{
				initialState: {
					selectedDefaultLanguage: (defaultLanguage: LanguageTemplate) => this.onDefaultLanguageSelected(defaultLanguage)
				}
			});
	}

	public onDefaultLanguageSelected(language: LanguageTemplate): void {
		this.defaultLanguage = language.Name;
		let templateLanguages: TemplateAvailableLanguage[] = [];
		templateLanguages.push({
			id: 0,
			languageId: language.Id,
			isDefault: true,
			isFallback: false,
			templateId: this.template.templateId,
			subMethodId: this.template.subMethodId,
			status: LanguageStatus.New
		});
		this.context.updateLanguages(templateLanguages);
	}

	updateSyncDate(): void {
		if (!this.template) {
			return;
		}
		this.manageTemplatesService
			.getContentParts(this.template.subMethodId, this.template.templateId)
			.subscribe((parts) => this.syncLastUpdated(parts));
	}

	syncTranslations(): void {
		this.isSyncing = true;
		this.manageTemplatesService.syncTranslations(this.template.subMethodId, this.template.templateId).subscribe((parts) => {
			this.isSyncing = false;
			this.syncLastUpdated(parts);
			this.context.updateTemplateFromBackEnd(this.template.templateId);
		});
	}

	private syncLastUpdated(contentParts: TemplateContentPart[]): void {
		if (!contentParts?.sort || contentParts.length === 0)
			return;
		contentParts.sort((a, b) => this.parseDate(b.UpdatedAt).getTime() - this.parseDate(a.UpdatedAt).getTime() );
		this.lastUpdated = this.parseDate(contentParts[0].UpdatedAt);
	}

	private parseDate(input: string): Date {
		if (input && typeof input === 'string') {
			const d = /\/Date\((\d*)\)\//.exec(input);
			return d ? new Date(+d[1]) : new Date(input);
		}
		return new Date();
	}

	private openFallbackLanguageDialog(): void {
		this.modalService.open(SelectFallbackLanguageComponent, 'md', <ModalOptions<any>>{
			initialState: {
				templateLanguages: this.template?.languages,
				languageDefinitions: this.languageDefinitions,
				onSave: (id) => this.setFallbackLanguage(id)
			} as SelectFallbackLanguageInitialState
		});
	}

	private setFallbackLanguage(id): void {
		if (!this.template?.languages) {
			return;
		}
		let changedLanguage: TemplateAvailableLanguage = null;
		if (id) {
			changedLanguage = this.template.languages.find((l) => l.id === id);
			if (changedLanguage) changedLanguage.isFallback = true;
		} else {
			changedLanguage = this.template.languages.find((l) => l.isFallback);
			if (changedLanguage) changedLanguage.isFallback = false;
		}
		if (changedLanguage) {
			const languagesToUpdate = [changedLanguage];
			const oldFallback = this.template.languages.find((l) => l.isFallback && l.id !== changedLanguage.id);
			if (oldFallback) {
				oldFallback.isFallback = false;
				languagesToUpdate.push(oldFallback);
			}
			this.manageTemplatesService.updateAvailableLanguages(languagesToUpdate).subscribe();
		}
	}
}
