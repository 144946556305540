import {ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import {GridApi, RowNode} from "@ag-grid-enterprise/all-modules";
import {TranslateService} from "@ngx-translate/core";
import {SearchListUtilsService} from "../services/searchlistUtils.service";
import {OslCellType} from "../models/osl-cell-type.enum";
import {OptiSearchListService} from "../optiSearchListComponent/optiSearchList.service";
import {ISearchListGroup} from "../optiSearchListComponent/optiSearchList.component";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";

@Component({
    selector: 'osl-cell',
    templateUrl: './oslCell.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslCell.component.scss']
})

export class OslCellComponent implements ICellRendererAngularComp {
    private api: GridApi;

    public node: RowNode;
    public params: any;
    public isMultiSelect: boolean = false;
    public isAllSelected: boolean;
    public label: string;
    public type: OslCellType;
    public availableTypes = OslCellType;
    public maxRowsInCell: number;
    public isDisabled: boolean;
    public isGroupView: boolean;
    public singleRowHeight: number;
    public icon?: string;

    constructor(private translate: TranslateService,
                private searchListUtilsService: SearchListUtilsService,
                private optiSearchListService: OptiSearchListService,
                private cdRef: ChangeDetectorRef) {

    }

    agInit(params: any): void {
        this.params = params;
        this.api = params.api;
        this.node = params.node;
        this.isMultiSelect = Boolean(params.isMultiSelect);
        this.maxRowsInCell = params.maxRowsInCell;
        this.isDisabled = params.disabledProperty ? params.data[params.disabledProperty] : false;
        this.type = typeof params.data.type !== 'undefined' ? params.data.type : OslCellType.Item;
        this.label = this.getLabel(params);
        this.isGroupView = params.isGroupView;
        this.singleRowHeight = params.singleRowHeight;
        this.icon = params.data[params.iconProperty];

        this.updateAggregatedAction(false);
    }

    toggleSelectAll(shouldSelect: boolean): void {
        if (this.isDisabled){
            return;
        }
        if (shouldSelect) {
            this.api.selectAllFiltered();
        } else {
            this.api.deselectAllFiltered();
        }
        this.isAllSelected = shouldSelect;
        this.optiSearchListService.allRowsChanged$.next(this.isAllSelected);
    }

    toggleSelect(shouldSelect: boolean): void {
        if (this.isDisabled){
            return;
        }

        // On single selection and the selected node allready selected - close grid
        if (!this.isMultiSelect && this.node.isSelected()){
            this.optiSearchListService.closeGridSubject.next();
            return;
        }

        this.node.setSelected(shouldSelect);
        this.cdRef.detectChanges();
    }

    private getLabel(params: any): string {
        if (this.type === OslCellType.Group) {
            const groupData = params.data as ISearchListGroup;
            return groupData.groupText;
        } else if (this.type === OslCellType.Item) {
            return params.data[params.valueProperty];
        } else {
            return '';
        }
    }

    private updateAggregatedAction(isRefresh: boolean){
        if (this.type !== OslCellType.AggregatedAction){
            return;
        }
        // @ts-ignore
        const rootNode = this.api.getModel().rootNode;
        const allRows = this.searchListUtilsService.filterOnlySearchNodes(rootNode.allLeafChildren);
        const filteredRows = this.searchListUtilsService.filterOnlySearchNodes(rootNode.childrenAfterFilter);
        const selectedFilteredRows = filteredRows.filter((row) => {
            return row.selected;
        });
        this.label = this.getAllItemLabel(allRows.length !== filteredRows.length);
        this.isAllSelected = selectedFilteredRows.length === filteredRows.length;
        if (isRefresh){
            this.toggleSelect(this.isAllSelected);
        }
    }

    private getAllItemLabel(isSearching: boolean): string {
        return isSearching ? this.translate.instant('components.opti_search_list.ALL_SEARCH')
            : this.translate.instant('components.opti_search_list.ALL');
    }

    refresh(): boolean {
        this.updateAggregatedAction(true);
        return true;
    }
}