import {
        Component,
        ElementRef,
        EventEmitter,
        HostListener,
        Input,
        OnChanges,
        Output,
        SimpleChanges
, ViewEncapsulation } from "@angular/core";
import {ITreeNodeButtons, treeEventType} from "../jsTree.component";
import {ITreeMenuItem} from "../menuItem.model";

@Component ({
        selector: 'js-tree-menu',
        templateUrl: './jsTreeMenu.component.html',
	encapsulation: ViewEncapsulation.None,
        styleUrls: ['./jsTreeMenu.component.scss']
})
export class JsTreeMenuComponent implements OnChanges {
        @Input() treeButtons: ITreeNodeButtons;
        @Output() itemClicked: EventEmitter<treeEventType> = new EventEmitter();

        public isMenuOpen = false;
        public menuItems: ITreeMenuItem[];

        @HostListener('document:click', ['$event'])
        clickout(event) {
                if (!this.isMenuOpen) {
                        return;
                }

                if(!this.eRef.nativeElement.contains(event.target)) {
                        this.closeMenu();
                }
        }

        constructor(private eRef: ElementRef) {
        }

        ngOnChanges(changes: SimpleChanges): void {
                if (changes.treeButtons) {
                        this.menuItems = this.getMenuItems(this.treeButtons);
                }
        }

        onBtnMenuClicked() {
                this.isMenuOpen = true;
        }

        onMenuItemClicked(eventType: treeEventType) {
               this.itemClicked.emit(eventType);
               this.closeMenu();
        }

        private getMenuItems(treeButtons: ITreeNodeButtons): ITreeMenuItem[]  {
                const menuItems: ITreeMenuItem[] = [];

                if (treeButtons.newNode.isHeaderMenuEnable && treeButtons.newNode.isEnable) {
                        menuItems.push({
                                text: this.treeButtons.newNode.text,
                                eventType: treeEventType.addNode
                        });
                }

                if (treeButtons.newFolder.isHeaderMenuEnable && treeButtons.newFolder.isEnable) {
                        menuItems.push({
                                text: this.treeButtons.newFolder.text,
                                eventType: treeEventType.addFolder
                        });
                }

                return menuItems;
        }

        private closeMenu() {
                this.isMenuOpen = false;
        }
}