<div class="realtimeProfileDataManage-addAttribute">
    <opti-loading [show]="loading" [size]="'md'" ></opti-loading>
    <div class="panel-heading">
        <div class="back-button">
            <div class="btn btn-link" (click)="openConfirmModal()">
                <i class="material-icons">arrow_back</i>
                <span translate>{{'general.BACK' | translate}}</span>
            </div>
        </div>
        <h2 *ngIf="editMode"> {{'features.user_settings.body.realtimeProfileDataManage.addAttribute.EDIT_TITLE' | translate}}</h2>
        <h2 *ngIf="!editMode"> {{'features.user_settings.body.realtimeProfileDataManage.addAttribute.TITLE' | translate}}</h2>
    </div>
    <div class="panel-body">
        <form [formGroup]="addAttributeForm" class="form-container">
            <div class="based-on-section">
                <div class="row attributeBasedOn">
                    <div class="label-column col-md-2">
                        {{translateKeys.attributeBasedOn.label | translate}}
                    </div>
                    <div class="radio radio-inline radio-primary attributeBasedOnCombined">
                        <input type="radio"
                               [attr.disabled] = "editMode ? 'disabled' : null"
                               [value]="attributeBasedOnTypes.combined"
                               formControlName="attributeBasedOn"
                               id="combined">
                        <label for="combined">{{translateKeys.attributeBasedOn.radioLabel1 | translate}}</label>
                    </div>
                    <div *ngIf="attributeBasedOn.value === attributeBasedOnTypes.combined" [ngClass]="{'disabled': editMode}" class="col-md-4 existingAttributesValue">
                        <opti-search-list formControlName="existingAttributes"
                                          [items]="existingAttributes"
                                          [config]="existingAttributesOslConfig"
                                          (selectedItemsChange)="onExistingAttributeSelect($event)">
                        </opti-search-list>
                        <div *ngIf="newAttrDbFieldName" class="newAttrDbIndication">
                            <span class="newAttrDbFieldNameDesc">{{newAttrDbFieldNameDesc}}</span>
                            <span class="newAttrDbFieldName">{{newAttrDbFieldName}}</span>
                            <i class="material-icons flashOn">flash_on</i>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="radio radio-inline radio-primary realtimeEventsOnly">
                        <input type="radio"
                               [attr.disabled] = "editMode ? 'disabled' : null"
                               [value]="attributeBasedOnTypes.eventsOnly"
                               formControlName="attributeBasedOn"
                               id="realtimeEventsOnly">
                        <label for="realtimeEventsOnly">{{translateKeys.attributeBasedOn.radioLabel2 | translate}}</label>
                    </div>
                    <div class="newProfileAttrValues"
                         *ngIf="attributeBasedOn.value === attributeBasedOnTypes.eventsOnly">
                        <div>
                            <input class="form-control content-input newProfileAttrName"
                                   [attr.disabled] = "editMode ? 'disabled' : null" 
                                   type="text" 
                                   formControlName="newAttrName" 
                                   placeholder="Type new attribute name">
                            <p *ngIf="newAttrFormControl.errors?.newAttrNameInvalid"
                               class="inputs-validations error-msg">Attribute name can't
                                contain {{newAttrFormControl.errors.newAttrNameInvalid}}.</p>
                            <p *ngIf="newAttrFormControl.errors?.invalidStartWithNumbers"
                                class="inputs-validations error-msg">Attribute name can't
                                 start with numbers.</p>
                        </div>
                        <div [ngClass]="{'disabled': editMode}" class="formatsDropdown">
                            <opti-search-list formControlName="formats"
                                              [items]="formats"
                                              [config]="formatsOslConfig"
                                              (selectedItemsChange)="onFormatItemChanged($event)">
                            </opti-search-list>
                        </div>
                    </div>
                </div>
            </div>

            <div class="calculate-section">
                <div class="row form-group">
                    <div class="label-column  col-md-2">
                        {{translateKeys.calculate.label | translate}}
                    </div>
                    <div class="value-column formulasDropdown">
                        <opti-search-list formControlName="formulas"
                                          [items]="formulas"
                                          [config]="formulasOslConfig"
                                          (selectedItemsChange)="onFormulaItemChanged($event)">
                        </opti-search-list>
                    </div>
                    <div class="label-column linker">{{translateKeys.calculate.of | translate}}</div>
                    <div class="value-column eventsDropdown">
                        <opti-search-list formControlName="events"
                                          [items]="events"
                                          [config]="eventsOslConfig">
                        </opti-search-list>
                    </div>
                    <div class="contextEventParamDiv"
                         *ngIf="this.formulasFormControl.value[0].key === formulasEnum.sum">
                        <div class="label-column linker">{{translateKeys.calculate.by | translate}}</div>
                        <div class="value-column eventParamsDropdown">
                            <opti-search-list formControlName="eventParamContext"
                                              [items]="eventParams"
                                              [config]="eventParamsOslConfig">
                            </opti-search-list>
                        </div>
                    </div>
                </div>

                <div class="row form-group optionalCondition">
                    <div class="label-column">
                        {{translateKeys.calculate.when | translate}}
                    </div>
                    <div class="addConditionParamButton" *ngIf="showAddParameterButton">
                        <button class="btn btn-secondary" type="button" id="addParameterBtn"
                                (click)="toggleAddParameterArea()">
                            <i class="material-icons">add</i>
                        </button>
                        <label class="addParameterLabel"
                               for="addParameterBtn">{{translateKeys.calculate.addParamOptional | translate}}</label>
                    </div>
                    <div *ngIf="!showAddParameterButton" class="row conditionParamWrapper">
                        <div class="value-column eventParamsCondition">
                            <opti-search-list formControlName="eventParamsCondition"
                                              [items]="eventParams"
                                              [config]="eventParamsOslConfig"
                                              (selectedItemsChange)="onEventParamChanged($event)">
                            </opti-search-list>
                        </div>
                        <div class="value-column operatorsDropdown">
                            <opti-search-list formControlName="operators"
                                              [items]="operatorsSearchItems"
                                              [config]="operatorsOslConfig"
                                              (selectedItemsChange)="onOperatorChanged($event)">
                            </opti-search-list>
                        </div>
                        <div class="value-column conditionParamValue" *ngIf="!isSpecialOperatorChosen">
                            <input *ngIf="!isDefinedEventParamValue" type="text" class="form-control content-input" 
                                formControlName="eventParamRelatingValue" placeholder="Value">
                            <opti-search-list *ngIf="isDefinedEventParamValue" formControlName="eventParamRelatingValue"
                                              [items]="eventParamsRelatingValues"
                                              [config]="eventParamsOslConfig">
                            </opti-search-list>
                        </div>
                        <a class="btn btn-link btn-link-default delete-button"
                           (click)="toggleAddParameterArea(); cleanOptionalData();" style="margin-left: 10px;">
                            <i class="material-icons">delete_forever</i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="latest-value-section" style="margin-top: 40px;"
                 *ngIf="this.formulasFormControl.value[0].key === formulasEnum.latest">
                <div class="row form-group">
                    <div class="label-column  col-md-2">
                        {{translateKeys.returnLatestValue.label | translate}}
                    </div>
                    <div style="display: flex; width: 311px">
                        <input type="text" (change)="onLatestValueConstantChanged($event)"
                               class="form-control content-input"
                               formControlName="constValueOREventParam" 
                               placeholder="Type or select value"
                               required>
                        <div class="value-column latestValueEventParams">
                            <opti-search-list-drop-down
                                    [button]="{}"
                                    [searchList]="latestValueConfig"
                                    (select)="onLatestValueEventsParams($event)"
                                    [align]="optiSearchListDropDownAlignHorizontal.Right" 
                                    required>
                            </opti-search-list-drop-down>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="controls col-md-6 pull-right text-right">
        <ul class="list-inline">
            <li>
                <div class="btn btn-default" (click)="openConfirmModal()">
                    <span>Cancel</span>
                </div>
            </li>
            <li>
                <loading-button
                        [showLoader]="isSaving"
                        (onClick)="editMode ? updateExistingAttribute() : saveAttributeClicked()"
                        [isDisabled]="!addAttributeForm.valid || !addAttributeForm.dirty"
                        [buttonCssClass]="'btn btn-primary'"
                        [loadingCssClass]="'saving-loader'"
                        [loadingText]="'Saving'"
                        [buttonText]="'Save'">
                </loading-button>
            </li>
        </ul>
    </div>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="isSaving"></div>
