import { Timeframe } from './../../attributesList.model';
import { Subscription, forkJoin } from 'rxjs';
import { AttributesManagerService } from './../../services/attributesManager.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SearchItem, SearchListConfig } from "../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { OptiLogicModalService } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { functions } from "../../attributesList.constants";
import { CalculatedAttribute as Attribute, GranularAttribute, AttributeLog } from "../../../attributes/attributesList.model";
import { OptiSearchListDropDownAlignHorizontal } from "../../../../../components/optiSearchListDropDown/models/optiSearchListDropDownAlignHorizontal.enum";
import { Router } from "@angular/router";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { filter, first } from "rxjs/operators";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { AttributeStatus, TimeframePeriod, TimeframeScenarioCode } from "../../attributesList.enums";
import { AttributeHelper } from "../../attributesList.helper";
import { AttributeValidatorService } from '../../services/attributeValidator.service';
import angular from 'angular';
import { AttributesListService } from '../../services/attributesList.service';


@Component({
	selector: 'calculated-attribute',
	templateUrl: './calculatedAttribute.component.html',
	styleUrls: ['./calculatedAttribute.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class CalculatedAttributeComponent implements OnInit, OnDestroy {

    public addAttributeForm: FormGroup;
    public activities: SearchItem[];
    public functions: SearchItem[];
    public timeframes: SearchItem[];
    private granularAttributes: GranularAttribute[];
    public lastUpdate = "";
    public isSaving = false;
    public editMode = "create";
    public IsPersonalizationCheckBoxChecked: boolean;
    public isAttributeVisibilityChanged: boolean;
    public displayNameError = false;
    public attributeStatus = {
    	color: ColoredTagType.Type4,
    	description: "Published"
    };
    public optiSearchListDropDownAlignHorizontal = OptiSearchListDropDownAlignHorizontal;
    public translateKeys = translateKeys;
    public attribute: Attribute;
    public dropdownOslConfig: SearchListConfig = {
    	keyProperty: "key",
    	valueProperty: "value",
    	isMultiSelect: false,
    	itemNameTranslateKey: translateKeys.select,
    };
    public showTimeframeIntervalInput: boolean = false;
    public canAttributeBeHidden: boolean = false;
    private subscriptions: Subscription[] = [];
    private defaultFamily = "Activity History";

    constructor(private formBuilder: FormBuilder,
                private attrManagerService: AttributesManagerService,
                private router: Router,
                private modalService: OptiLogicModalService,
                private bsModalRef: BsModalRef,
                private translate: TranslateService,
                private attributeValidatorService: AttributeValidatorService,
                private attributeListService: AttributesListService) {
    	this.attribute = {
    		Family: this.defaultFamily
    	} as Attribute;
    }

    ngOnInit() {
    	this.createForm();
    	this.setDropdownData();
    	this.checkExistedAttribute();
    	this.initFormSubscriptions();
    }

    ngOnDestroy(): void {
    	this.subscriptions.forEach(s => s.unsubscribe());
    }

    private checkExistedAttribute() {
    	const attribute = this.router.getCurrentNavigation().extras.state as Attribute;
    	if (attribute) {
    		this.attributeValidatorService.canAttributeBeHidden(attribute.FieldName).pipe(first()).subscribe((res) => {
    			this.canAttributeBeHidden = res.IsSuccess;
    		});

    		this.editMode = attribute.OldName ? "editTemp" : "edit";
    		if (this.editMode === 'edit') {
    			this.timeframeIntervalFormControl.disable();
    		}

    		this.attributeStatus = {
    			color: attribute.OldName ? ColoredTagType.Type12 : ColoredTagType.Type4,
    			description: attribute.OldName ? "Not Published" : "Published"
    		}
            
    		this.attribute = attribute;
    		this.attrManagerService.getLastActivity(this.attribute.Name)
    			.pipe(first(), filter(x => !!x))
    			.subscribe((res: AttributeLog) => {
    				this.lastUpdate = `Last modified on ${AttributeHelper.getTimestamp(res.Time)} by ${res.User}`
    			});
    	}
    }

    private getPrefix = (src: string, str: TimeframeScenarioCode) => src.includes(str) ? str : null;

    private setupExistedAttribute() {
    	this.displayNameFormControl.setValue(this.attribute.DisplayName);
    	this.descriptionFormControl.setValue(this.attribute.Description);
    	this.functionFormControl.setValue(functions.filter(x => x.value === this.attribute.Function));
    	const timeframes = AttributeHelper.getTimeframesDic(this.attribute.PublishStatus === 'Published');  
    	this.timeframes = timeframes.map(frame => {
    		return {
    			key: frame.toSendForm(),
    			value: frame.toString()
    		} as SearchItem
    	});
    	let timeframeValue = timeframes.find(x => x.equals(this.attribute.Timeframe));
    	//If timeframe is not found, this timeframe should has not an interval
    	if (!timeframeValue) {
    		let timeframeArr = this.attribute.Timeframe.split(',').map(x => Number.parseInt(x));
    		const timeframeInterval = timeframeArr[1];
    		timeframeArr[1] = null;
    		timeframeValue = timeframes.find(x => x.equals(timeframeArr.toString()));
    		this.showTimeframeIntervalInput = true;
    		this.timeframeIntervalFormControl.setValidators([Validators.required]);
    		this.timeframeIntervalFormControl.setValue(timeframeInterval);
    	}

    	this.timeframeFormControl.setValue([{
    		key: timeframeValue.toSendForm(),
    		value: timeframeValue.toString(false, this.getPrefix(this.attribute.Name, "Last Calendar"))
    	}]);
    	const activity = this.granularAttributes.find(x => x.FieldName == this.attribute.Activity.FieldName.toUpperCase());
    	this.activityFormControl.setValue([{
    		key: activity?.FieldName,
    		value: activity?.AliasName
    	} as SearchItem]);
    }

    private createForm() {
    	this.addAttributeForm = this.formBuilder.group({
    		displayName: ['', [Validators.required, Validators.maxLength(100), this.attributeValidatorService.validateEmptySpaces(), this.attributeValidatorService.validateSpecialSymbols()]],
    		description: [],
    		function: ['', Validators.required],
    		activity: ['', Validators.required],
    		timeframe: ['', Validators.required],
    		timeframeInterval: [''],
    	});
    }

    private initFormSubscriptions() {
    	const subscription$1 = this.displayNameFormControl.valueChanges.subscribe(() => {
    		this.displayNameError = false;
    	});
    	const subscription$2 = this.timeframeFormControl.valueChanges.subscribe((timeframeSearchItem) => {
    		const timeframes = AttributeHelper.getTimeframesDic(this.attribute.PublishStatus === 'Published');
    		const timeframeValue = timeframes.find(x => x.equals(timeframeSearchItem[0]?.key));
    		this.showTimeframeIntervalInput = timeframeValue.interval === null;
    		this.timeframeIntervalFormControl.setValidators(this.showTimeframeIntervalInput ? [Validators.required] : []);
    	});
    	this.subscriptions.push(subscription$1);
    	this.subscriptions.push(subscription$2);
    }

    public isInputEventIsRegularNumber(event) {
    	// Between 1-9 validation 
    	if (!(event.charCode >= 48 && event.charCode <= 57)) {
    		event.preventDefault();
    	}
    }

    public saveAttributeClick() {
    	this.setupAttribute("NotPublished");
    	const result = this.attrManagerService.saveAttribute(this.attribute, "ActivityHistory")
    		.pipe(first(), filter(x => !!x))
    		.subscribe(async (result: DefaultAjaxResponse) => {
    			if (result.IsSuccess) {
    				this.attrManagerService.addAttributeToProfile(this.attribute);
    				this.router.navigate(['/user-settings/attributes/list']);
    			} else {
    				this.updateDisplayError();
    				this.attribute.Name = null;
    			}
    		}, (err) => console.error(err));
    }


    public updateAttributeClick() {
    	if(!this.attribute.IsPersonalization && this.IsPersonalizationCheckBoxChecked) {
    		this.modalService.openModalMessage(
    			'md',
    			{
    				title: "Personalization tag successfully removed",
    				message: "Templates that already include this attribute will not be affected, and will continue to work as normal",
    				buttons: [
    					{
    						class: 'btn-primary',
    						label: 'OK',
    						action: this.updateAttribute.bind(this)
    					},
    				]
    			},
                <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
    		);
    	} else {
    		this.updateAttribute();
    	}
    }

    public isPublished(): boolean {
    	return this.attribute.PublishStatus === "Published";
    }

    private updateAttribute()
    {
    	this.setupAttribute(this.attribute.PublishStatus);

    	forkJoin(
    		this.isAttributeVisibilityChanged 
    			? {
    				updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "ActivityHistory"),
    				changeAttributeVisibility: this.attributeListService.changeAttributeVisibility(this.attribute.FieldName, !this.attribute.IsHidden)
    			} 
    			: {
    				updateAttribute: this.attrManagerService.updateAttribute(this.attribute, "ActivityHistory"),
    			}
    	).pipe(first()).subscribe(async (res) => {
    		if (res.updateAttribute.IsSuccess) {
    			await this.attrManagerService.updateCustomerAttributes();
    			this.router.navigate(['/user-settings/attributes/list']);
    		} else {
    			this.updateDisplayError();
    		}
    	}, (err) => console.error(err));
    }

    private updateDisplayError() {
    	if (this.attrManagerService.numberInProfileByDisplayName(this.attribute.DisplayName) >= 1) {
    		this.displayNameError = true;
    	} else {
    		let attr = this.attrManagerService.searchInProfile(this.attribute.Name);
    		if (attr) {
    			this.showNotUniqueAttribute(attr?.DisplayName);
    		}
    	}
    }

    public changePersonalization(isPeronalization: boolean){
    	this.IsPersonalizationCheckBoxChecked = !this.IsPersonalizationCheckBoxChecked;
    	this.attribute.IsPersonalization = !isPeronalization;
    }

    public changeAttributeVisibility() {
    	this.isAttributeVisibilityChanged = !this.isAttributeVisibilityChanged;
    }

    private setupAttribute(status: AttributeStatus) {
    	this.displayNameError = false;
    	this.attribute.Type = "calculated";
    	this.attribute.AttributeBaseType = "number";
    	this.attribute.PublishStatus = status;
    	this.attribute.DisplayName = this.displayNameFormControl.value.trim();
    	this.attribute.Description = this.descriptionFormControl.value;
    	this.attribute.Function = this.functionFormControl.value[0]?.value;
    	this.attribute.Activity = this.granularAttributes
    		.find(x => x.FieldName === this.activityFormControl.value[0].key);
    	const timeframes = AttributeHelper.getTimeframesDic(status === "Published");
    	const selectedTimeframe = angular.copy(timeframes
    		.find(x => x.equals(this.timeframeFormControl.value[0]?.key)));

    	if (!selectedTimeframe.interval && this.showTimeframeIntervalInput) {
    		selectedTimeframe.interval = this.timeframeIntervalFormControl.value;
    	}

    	this.attribute.Timeframe = selectedTimeframe.toSendForm();
        
    	if (status === "Published") {
    		this.attribute.OldName = this.attribute.Name;
    	}
    	else {
    		this.attribute.Name = this.buildAttributeName(selectedTimeframe);
    	}
    }

    private buildAttributeName(selectedTimeframe: Timeframe): string {
    	const activity = this.attribute.Activity.FieldRealName.replace(/\s/g,'');
    	let timeframe = selectedTimeframe.toString(true).replace("(todate)", '');
    	if ([1,2].includes(selectedTimeframe.code)) {
    		timeframe = `${timeframe}_${selectedTimeframe.interval}`;
    	}
    	return `IF_CA_AH_${this.attribute.Function}_${activity}_${timeframe}`;
    }

    private setDropdownData() {
    	this.functions = functions.map(func => {
    		return {
    			key: func.key.toString(),
    			value: func.value
    		} as SearchItem
    	});
    	const timeframes = AttributeHelper.getTimeframesDic(false);
    	this.timeframes = timeframes.map(frame => {
    		return {
    			key: frame.toSendForm(),
    			value: frame.toString()
    		} as SearchItem
    	});
    	this.attrManagerService.getGranularCustomerAttributes()
    		.pipe(first()).subscribe((res: GranularAttribute[]) => {
    			this.granularAttributes = res;
    			this.activities = this.granularAttributes.map(attr => {
    				return {
    					key: attr.FieldName,
    					value: attr.AliasName
    				} as SearchItem
    			});
    			if (this.editMode !== "create") {
    				this.setupExistedAttribute();
    			}
    		});
    }

    showNotUniqueAttribute(attributeName: string) {
    	this.modalService.openModalMessage(
    		'sm',
    		{
    			message: this.translate.instant(translateKeys.errorRealFieldName, { attributeName: attributeName }),
    			buttons: [{
    				class: 'btn-primary',
    				label: 'OK',
    				action: this.closeModal.bind(this)
    			}]
    		},
            <ModalOptions<any>>{
            	ignoreBackdropClick: true,
            	keyboard: false
            }
    	);
    }
    
    public openConfirmModal(){
    	if(this.addAttributeForm.dirty) {
    		this.modalService.openModalMessage(
    			'sm',
    			{
    				message: this.translate.instant(this.translateKeys.confirmBackMessage),
    				buttons: [
    					{
    						class: 'btn-primary',
    						label: this.translate.instant(this.translateKeys.discardChanges),
    						action: this.discardChanges.bind(this),
    					},
    					{
    						class: 'btn-default',
    						label: this.translate.instant(this.translateKeys.continueEditing),
    						action: this.closeModal.bind(this),
    					},
    				]
    			},
            <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
    		);
    	}
    	else {
    		this.discardChanges();
    	}
    }

    private discardChanges() {
    	this.router.navigateByUrl("/user-settings/attributes/list");
    	this.bsModalRef.hide();
    }

    private closeModal() {
    	this.bsModalRef.hide();
    }

    get displayNameFormControl(): FormControl {
    	return this.addAttributeForm.get('displayName') as FormControl;
    }

    get descriptionFormControl(): FormControl {
    	return this.addAttributeForm.get('description') as FormControl;
    }

    get functionFormControl(): FormControl {
    	return this.addAttributeForm.get('function') as FormControl;
    }

    get activityFormControl(): FormControl {
    	return this.addAttributeForm.get('activity') as FormControl;
    }

    get timeframeFormControl(): FormControl {
    	return this.addAttributeForm.get('timeframe') as FormControl;
    }

    get timeframeIntervalFormControl(): FormControl {
    	return this.addAttributeForm.get('timeframeInterval') as FormControl;
    }
}

type EditMode = 'create' | 'editTemp' | 'edit';


const translateKeys = {
	cancel: 'features.user_settings.body.attributes.addCalculatedAttribute.CANCEL',
	save: 'features.user_settings.body.attributes.addCalculatedAttribute.SAVE',
	select: 'features.user_settings.body.attributes.addCalculatedAttribute.SELECT',
	displayName: 'features.user_settings.body.attributes.addCalculatedAttribute.DISPLAY_NAME',
	description: 'features.user_settings.body.attributes.addCalculatedAttribute.DESCRIPTION',
	attributeCalculation: 'features.user_settings.body.attributes.addCalculatedAttribute.ATTRIBUTE_CALCULATION',
	function: 'features.user_settings.body.attributes.addCalculatedAttribute.FUNCTION',
	activity: 'features.user_settings.body.attributes.addCalculatedAttribute.ACTIVITY',
	timeframe: 'features.user_settings.body.attributes.addCalculatedAttribute.TIMEFRAME',
	title: 'features.user_settings.body.attributes.addCalculatedAttribute.TITLE',
	editTitle: 'features.user_settings.body.attributes.addCalculatedAttribute.EDIT_TITLE',
	days: 'features.user_settings.body.attributes.addCalculatedAttribute.DAYS',
	daysAgo: 'features.user_settings.body.attributes.addCalculatedAttribute.DAYS_AGO',
	errorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.NEW_ATTR_NAME',
	errorRealFieldName: 'features.user_settings.body.attributes.invalidErrors.REAL_FIELD_NAME',
	confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
	continueEditing: 'general.CONTINUE_EDITING',
	discardChanges: 'general.DISCARD_CHANGES',
	specialSymbolsErrorAliasFieldName: 'features.user_settings.body.attributes.invalidErrors.SPECIAL_SYMBOLS',
	placeholder: 'features.user_settings.body.attributes.purchaseAttributes.ENTER_NAME',
};
