import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {BrandsService} from "./brandsService";
import {Module, AllModules, GridOptions, GridApi, ColDef} from '@ag-grid-enterprise/all-modules';
import {EditBtnCellRenderComponent} from "../EdtiBtnCellRender/editBtnCellRender.component";
import {IsDOICellRenderComponent} from "../IsDOICellRender/isDOICellRender.component";
import {BrandModel} from "../../brandModel";

@Component({
    selector:'brands-list',
    templateUrl:'./brandsList.component.html',
    styleUrls:['./brandsList.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BrandsListComponent implements OnInit{
    public loading: boolean = true;
    public brands: BrandModel[];
    public preparedBrands: BrandModel[]
    public gridOptions: GridOptions;
    public modules: Module[] = AllModules;
    public translateKeys = translateKeys;
    private gridApi:GridApi;
    constructor(private service: BrandsService) { }

    ngOnInit(): void {
       this.LoadBrands();
    }

    public LoadBrands(){
        this.service.getTransactionalBrands()
            .subscribe(response => {
                this.loading = false;
                this.preparedBrands = this.service.prepareBrands(response);
                this.createGrid(this.preparedBrands);
            }, (err) => {
                console.log(err);
            });
    }

    public createGrid(brands){
        this.gridOptions = {
            context: this,
            columnDefs: this.CreateColumnDefs(),
            rowData: brands,
            headerHeight: 44,
            rowHeight: 44,
            suppressRowTransform: true,
            suppressCellSelection: true,
            onGridReady(params) {
                this.gridApi = params.api;
            }
        }
    }

    public OnGridReady(params){
        this.gridApi = params.api;
        this.gridApi.setRowData(this.preparedBrands);
    }

    public CreateColumnDefs():ColDef[]{
        return [
            {
                headerName: 'Brand',
                headerClass:'brand-header-cell',
                field: 'BrandName',
                initialWidth: 643,
                cellClass:'brand-cell',
                suppressMenu: true,
            },
            {
                headerName: 'Double Opt-in',
                headerClass:'check-header-cell',
                cellRendererFramework: IsDOICellRenderComponent,
                initialWidth: 150,
                cellClass: 'check-cell',
                suppressMenu: true,
            },
            {
                headerClass:'edit-header-cell',
                cellRendererFramework: EditBtnCellRenderComponent,
                filter: false,
                cellClass: 'edit-cell',
                suppressMenu: true,
                initialWidth: 143
            }
        ];
    }
}
const translateKeys = {
    title: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.TITLE',
    brand: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.Brand',
    isDOi: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.Double_Opt-in',
}