import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { Audit, PreferenceActionDetails, TopicSubscription } from "../models/preferenceHistoryAuditModel";
import { Topic } from "../../../../settings/preferenceCenter/models/topic.model";
import { ChannelService } from "../../../../manageTemplates/services/channel.service";
import { BaseAttribute } from "src/app/features/settings/attributes/attributesList.model";

@Component({
    selector: 'preference-audit-card',
    templateUrl: './preferenceHistoryAuditCard.component.html',
    styleUrls: ['./preferenceHistoryAuditCard.component.scss']
})

export class PreferenceHistoryAuditCardComponent implements OnInit, OnChanges {
    @Input() channelId: number;
    @Input() topics: Topic[] = [];
    @Input() preferences: Audit;
    @Input() reachability: Audit;
    @Input() consents: Audit;
    @Input() attributes: BaseAttribute[];

    @Output() onExportClickEvent = new EventEmitter<number>();

    public contactDetails: string = "";
    public channelName = "";
    public coloredTagTypeIn: ColoredTagType = ColoredTagType.Type4;
    public coloredTagTypeOut: ColoredTagType = ColoredTagType.Type5;
    public preferencesActionDetails: PreferenceActionDetails[] = [];
    public topicSubscriptions: TopicSubscription[] = [];
    public reachabilityActionDetails: {};
    public consentActionDetails: {};
    public imageSrc: string;
    constructor(private channelService: ChannelService) {}

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.preferencesActionDetails = [];
        this.imageSrc = this.getChannelIconImageUrl(this.channelId);

        if (changes['channelId'] && changes['channelId'].isFirstChange())
            this.setChannelName(this.channelId);

        let auditsPerTopicId: { [key: string]: PreferenceActionDetails } = {};

        if (this.preferences) {
            this.preferencesActionDetails = JSON.parse(this.preferences.actionDetails);
            auditsPerTopicId = this.preferencesActionDetails.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});

        }

        this.topicSubscriptions = this.topics.map(item => {
            const correspondingItem = auditsPerTopicId[item.id] || {};

            return {
                name: item.name,
                description: item.description,
                isSubscribed: correspondingItem.hasOwnProperty('subscribed') ? correspondingItem['subscribed'] : true
            } as TopicSubscription;
        });

        this.reachabilityActionDetails = this.reachability === undefined ? undefined : JSON.parse(this.reachability.actionDetails);
        this.contactDetails = this.reachability === undefined || !this.isContactVisible() ? "" : (this.reachability.displayContactId ?? this.reachability.contactId);
        this.consentActionDetails = this.consents === undefined ? undefined : JSON.parse(this.consents.actionDetails);
    }

    public exportCSV(value: number) {
        this.onExportClickEvent.emit(value);
    }

    private setChannelName(channelId: number) {
        this.channelService.getChannelNameById(channelId).subscribe((channelName) => {
            this.channelName = channelName;
        })
    }

    public isClientReachable(){
        return (this.reachability && this.reachabilityActionDetails && this.reachabilityActionDetails['reachable']) || !this.reachability || !this.reachabilityActionDetails;
    }
    
    private isContactVisible(): boolean {
        let attributeName = '';
        if (this.channelService.isOptimail(this.channelId)){
            attributeName = 'Email';
        } else if (this.channelService.isOptimobile(this.channelId)) {
            attributeName = 'MOBILE_NUMBER';
        } else {
            return true;
        }

        const attribute = this.attributes.find(a => a.Name === attributeName);
        return attribute && !attribute.IsHidden;
    }

    public getChannelIconImageUrl(channelId: number): string {
        switch (channelId) {
            case 8:
                return 'assets/optimove/ui-sdk/images/channelGroupType/facebook.svg';
            case 489:
                return 'assets/optimove/ui-sdk/images/channelGroupType/push.svg';
            case 29:
                return 'assets/optimove/ui-sdk/images/channelGroupType/push.svg';
            case 493:
                return 'assets/optimove/ui-sdk/images/channelGroupType/message-text.svg';
            case 427:
                return 'assets/optimove/ui-sdk/images/channelGroupType/inApp.svg';
            case 490:
                return 'assets/optimove/ui-sdk/images/channelGroupType/webPush.svg';
            case 15:
                return 'assets/optimove/ui-sdk/images/channelGroupType/email.svg';
            case 22:
                return 'assets/optimove/ui-sdk/images/channelGroupType/google.svg';
            case 415:
                return 'assets/optimove/ui-sdk/images/channelGroupType/snapchat.svg';
            case 426:
                return 'assets/optimove/ui-sdk/images/channelGroupType/tiktok.svg';
            case 502:
                return 'assets/optimove/ui-sdk/images/channelGroupType/cellCenter.svg';
            case 503:
                return 'assets/optimove/ui-sdk/images/channelGroupType/directMail.svg';
            default:
                return 'assets/optimove/ui-sdk/images/channelGroupType/email.svg';
        }
    }

    public areTopicsReady(){
        return this.topicSubscriptions && this.topicSubscriptions.length > 0;
    }
}
