export class TranslateKeys {
    private entityPrefix = 'features.manageTargetGroups.modal_messages.MOVE_TG_TO_STREAM_SAVE_MODAL';
    confirmDesc = `${this.entityPrefix}.CONFIRM_DESC`;
    confirmQuestion = `${this.entityPrefix}.CONFIRM_QUESTION`;
    futureCampaignsConfirmDesc = `${this.entityPrefix}.FUTURE_CAMPAIGNS_CONFIRM_DESC`;
    futureCampaignsConfirmQuestion = `${this.entityPrefix}.FUTURE_CAMPAIGNS_CONFIRM_QUESTION`;
    buttons = {
        move: `${this.entityPrefix}.BUTTONS.MOVE_TO_STREAMS`,
        dontMove: `${this.entityPrefix}.BUTTONS.DONT_MOVE`,
    };
    loaderMsg = `${this.entityPrefix}.LOADER_MSG`;
}