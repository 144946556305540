<div class="js-tree-dot-menu">
    <i (click)="onBtnMenuClicked()" class="material-icons dot-button" [ngClass]="{'menu-open': isMenuOpen, 'disabled': isDisabled}">more_vert</i>
    <div class="tree-menu external-btn-menu" *ngIf="isMenuOpen">
        <ul>
            <li class="disabled">
                <h4>
                    <ng-container *ngIf="multiSelectedLength; then selected; else nonSelected"></ng-container>
                    <ng-template #selected>{{multiSelectedLength}} {{'features.manageActions.opti_tree.opti_tree_nodes.SELECTED' | translate}}</ng-template>
                    <ng-template #nonSelected>{{'features.manageActions.opti_tree.opti_tree_nodes.NO_SELECTION' | translate}}</ng-template>
                </h4>
            </li>
            <li *ngFor="let menuItem of menuItems" [ngClass]="{'disabled': multiSelectedLength === 0}" (click)="onMenuItemClicked(menuItem.eventType)">
                <span>{{ menuItem.text }}</span>
            </li>
        </ul>
    </div>
</div>