import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SettingsSubscribersComponent } from "./settingsSubscribers/settingsSubscribers.component";
import { ComponentsModule } from "../../components/components.module";
import { SettingsSubscribersService } from "./settingsSubscribers/settingsSubscribers.service";
import { SettingsSubscribersFormComponent } from "./settingsSubscribers/components/settingsSubscribersForm/settingsSubscribersForm.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { RealtimeProfileDataManageModule } from "./realtimeProfileDataManage/realtimeProfileDataManage.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ExportModalComponent } from "./settingsSubscribers/components/ExportModalComponent/exportModal.component";
import { OptiModalModule } from "../../modules/OptiModal.module";
import { WebhookConfigurationModule } from "./webhookConfiguration/webhookConfiguration.module";
import { UniquePromoCodesModule } from "./uniquePromoCodes/uniquePromoCodes.module";
import { AttributesModule } from "./attributes/attributesList.module";
import { DoubleOptedInModule } from "./DoubleOptedIn/doubleOptedIn.module";
import { ApiManagementModule } from "./apiManagement/apiManagement.module";
import { ApiKeyManagementModule } from "./apiKeyManagement/apiKeyManagement.module";
import { RolePermissionsComponent } from "./rolePermissions/rolePermissions.component";
import { ImportedCustomersModule } from "./importedCustomers/imported-customers.module";
import { PermissionsManagerComponent } from "./rolePermissions/components/permissionsManager.component";
import { PreferenceCenterModule } from './preferenceCenter/preferenceCenter.module';
import { MessageArchivingModule } from "./messageArchiving/messageArchiving.module";
import { OneClickUnsubscribeModule } from "./oneClickUnsubscribe/oneClickUnsubscribe.module";

@NgModule({
	imports: [
		ComponentsModule,
		CommonModule,
		AccordionModule,
		FormsModule,
		ReactiveFormsModule,
		TypeaheadModule.forRoot(),
		RealtimeProfileDataManageModule,
		AttributesModule,
		OptiModalModule.forRoot(),
		AgGridModule.withComponents([]),
		WebhookConfigurationModule,
		UniquePromoCodesModule,
		DoubleOptedInModule,
		ApiManagementModule,
		ImportedCustomersModule,
		ApiKeyManagementModule,
		PreferenceCenterModule,
		MessageArchivingModule,
		OneClickUnsubscribeModule
	],
	exports: [
	],
	declarations: [
		SettingsSubscribersComponent,
		SettingsSubscribersFormComponent,
		ExportModalComponent,
		RolePermissionsComponent,
		PermissionsManagerComponent
	],
	providers: [
		SettingsSubscribersService,
	],
   
})

export class SettingsModule { }


