<div class="attributes-list">
    <div class="panel-heading">
        <h2>
            {{ translateKeys.title | translate }}
            <attributes-count-tag *ngIf="attributes.length > 0" [attributes]="attributes" [config]="attrCountTagConfig"></attributes-count-tag>
        </h2>
        <div class="right-items">
            <input-search
                [placeholder]="'features.user_settings.body.attributes.SEARCH_PLACEHOLDER' | translate" 
                (termChange)="onTextFilterChanged($event)"
                class="search">
            </input-search>
            <a  [ngClass]="{'disabled': config.disableAddAttr}" 
                (click)="openPopup()"
                class="btn btn-font-size btn-link pull-right tab-item" routerLinkActive="active" data-qa-id="addnewattribute">
                <i class="material-icons">add </i>
                <span>{{ translateKeys.addFirst | translate }}</span>
            </a>
        </div>
    </div>
    <div class="panel-body" style="height: 100%;" *ngIf="!config.noAttributes">
        <p *ngIf="config.publishError" class="error-msg" translate>
            {{ config.publishErrorString | translate }}
        </p>
        <ag-grid-angular *ngIf="gridOptions" 
                         [gridOptions]="gridOptions"
                         [modules]="modules"
                         (sortChanged)="onSortChanged($event)"
                         [ngClass]="{'with-error': config.publishError, 'without-error': !config.publishError}"
                         [ngStyle]="{'padding-bottom': config.publishError ? 65 + 'px' : 15 + 'px'}"
                         class="ag-theme-alpine">
        </ag-grid-angular>
    </div>
    <div class="panel-body" style="height: 100%;" *ngIf="config.noAttributes">
        <div class="empty-state-wrapper">
            <div class="no-attributes">{{ translateKeys.noAttributes | translate }}</div>
            <span>
                <a (click)="openPopup()"
                    class="create-first btn btn-font-size btn-link tab-item" routerLinkActive="active" data-qa-id="addnewattribute">
                    <i class="material-icons">add </i>
                    <span>{{ translateKeys.addFirst | translate }}</span>
                </a>
            </span>
        </div>
    </div>
    <div class="panel-footer panel-footer-bottom-stick footer-btns" *ngIf="!config.noAttributes">
        <div class="controls col-md-6 pull-right text-right">
            <ul class="list-inline">
                <li *ngIf="config.allPublished">
                    <i class="material-icons check-icon">done</i>
                </li>
                <li *ngIf="config.allPublished">
                    <p>
                        {{translateKeys.allPublished | translate}}
                    </p>
                </li>
                <li>
                    <loading-button
                            [showLoader]="config.loading"
                            (onClick)="publishClick()"
                            [isDisabled]="!config.isClickable"
                            [buttonCssClass]="'btn btn-primary'"
                            [loadingCssClass]="'saving-loader'"
                            [loadingText]="'Publishing'"
                            [buttonText]="'Publish Attributes'">
                    </loading-button>
                </li>
            </ul>
        </div>
    </div>
    <opti-loading [show]="config.loading" [size]="'md'" ></opti-loading>
</div>


