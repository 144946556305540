import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";
import { AjaxResponse } from "@optimove/ui-sdk/common/models/src/ajaxResponse.model";
import { map } from "rxjs/operators";
import { S3SecretModel } from "../models/S3SecretModel";
import { AwsRegion } from "../models/awsRegion";
import { SecretValidationResult } from "../models/secretValidationResult";
import { MessageArchivingErrorLogModel } from "../models/errorLogModel";
import { GcsSecretModel } from '../models/gcsSecretModel';

@Injectable()
export class SecretService {
    private readonly baseUrl: string = '/UserSettings';
    private readonly getS3BucketSecretUrl = 'GetS3BucketSecret';
    private readonly getGcsBucketSecretUrl = 'GetGcsBucketSecret';
    private readonly getS3SecretPathUrl = 'GetS3SecretPath';
    private readonly getErrorLogs= '/GetMessageArchivingErrorLogs';
    private readonly saveCredentialPathUrl = '/SaveCredentialPath';
    private readonly downloadSecretUrl = '/GetOneClickSecret';

    constructor(private readonly http: HttpService) {}

    public getOneClickSecret(channelId: number, brandId: string, isRegenerate?: boolean): Observable<any>{
        return this.http.get<AjaxResponse<string>>(`${this.baseUrl}${this.downloadSecretUrl}`,
            HttpService.toHttpParams({channelId, brandId, isRegenerate}))
            .pipe(map(res => {
                return res.data;
            }))
    }

    public downloadTxtFile(data: string, fileName: string) {
        const blob = new Blob([data], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
    }

    public saveCredentialPath(pathString: string, storageType: string): Observable<any>{
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}${this.saveCredentialPathUrl}`,
          {path: pathString, storageType: storageType})
            .pipe(map(res => {
                return res.isSuccess;
            }))
    }

    public getMessageArchivingErrorLogs(page: number, take: number): Observable<MessageArchivingErrorLogModel>{
        return this.http.get<AjaxResponse<string>>(`${this.baseUrl}/${this.getErrorLogs}`, HttpService.toHttpParams({ page, take }))
            .pipe(map(res => {
                return JSON.parse(res.data)
            }));
    }

    public getSecret(): Observable<S3SecretModel>
    {
        return this.http.get<AjaxResponse<string>>(`${this.baseUrl}/${this.getS3BucketSecretUrl}`)
            .pipe(map(res => {
                return JSON.parse(res.data)
            }));
    }

    public getGcsSecret(): Observable<GcsSecretModel>
    {
        return this.http.get<AjaxResponse<string>>(`${this.baseUrl}/${this.getGcsBucketSecretUrl}`)
        .pipe(map(res => {
            return JSON.parse(res.data)
        }));
    }

    public getSecretPath(): Observable<string>
    {
        return this.http.get<AjaxResponse<string>>(`${this.baseUrl}/${this.getS3SecretPathUrl}`)
            .pipe(map(res => {
                return JSON.parse(res.data)
            }));
    }

    public getAwsRegions(): Observable<AwsRegion[]>
    {
        return this.http.get<AwsRegion[]>(`${this.baseUrl}/GetAwsRegions`);
    }

    public checkS3BucketCredentials(body: S3SecretModel): Observable<SecretValidationResult>
    {
        return this.http.post<SecretValidationResult>(`${this.baseUrl}/CheckS3BucketCredentials`, body)
            .pipe(map(res => res ));
    }

    public saveCredentials(body: S3SecretModel): Observable<SecretValidationResult>
    {
        return this.http.post<SecretValidationResult>(`${this.baseUrl}/SaveCredentials`, body)
            .pipe(map(res => res ));
    }
    public saveGcsCredentials(body: GcsSecretModel): Observable<SecretValidationResult>
    {
        return this.http.post<SecretValidationResult>(`${this.baseUrl}/SaveGcsCredentials`, body)
        .pipe(map(res => res ));
    }

    public checkGcpBucketCredentials(body: GcsSecretModel): Observable<SecretValidationResult>
    {
        return this.http.post<SecretValidationResult>(`${this.baseUrl}/CheckGcpBucketCredentials`, body)
        .pipe(map(res => res ));
    }
}
