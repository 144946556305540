import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from '../../../components/components.module';
import { Integrations } from '../apiKeyManagement/Roles/Integrations/integrations.component';
import { OptiModalModule } from '../../../modules/OptiModal.module';
import { SharedModule } from '../../../shared.module';
import { ApiKeyDetails } from "../apiKeyManagement/ApiKeyDetails/apiKeyDetails.component";
import { CreateApiKey } from '../apiKeyManagement/CreateApiKey/createApiKey.component';
import { ApiKeyActivatedComponent } from "./ApiKeyActivated/apiKeyActivated.component";
import { UpdateApiKeyPopupComponent } from "./UpdateApiKey/updateApiKeyPopup.component";
import { ApiKeyManagementComponent } from './apiKeyManagement.component';
import { ApiKeyManagementService } from './apiKeyManagement.service';

@NgModule({
  declarations: [
    ApiKeyManagementComponent,
    UpdateApiKeyPopupComponent,
    CreateApiKey,
    ApiKeyDetails,
    ApiKeyActivatedComponent,
    Integrations
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    OptiModalModule
  ],
  providers: [ApiKeyManagementService],
  entryComponents: [
    ApiKeyManagementComponent,
    UpdateApiKeyPopupComponent,
    CreateApiKey,
    ApiKeyDetails,
    ApiKeyActivatedComponent,
    Integrations
  ]
})

export class ApiKeyManagementModule { }