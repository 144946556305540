import { Injectable, Inject } from "@angular/core";
import { IColorTableEntry, MainMetricTypes, ActionType } from "./campaignAnalysis.models";
import { ColorsService } from "@optimove/ui-sdk/common/services";

@Injectable()
export class ChannelMetricsColorsService {

    constructor(private colorsService: ColorsService) {
    }

    private colorsTable: IColorTableEntry[] = [
        { actionType: ActionType.A, type: MainMetricTypes.Delivered, color: this.colorsService.getColorConstants('brand-pink-minus3') },
        { actionType: ActionType.A, type: MainMetricTypes.Opened, color: this.colorsService.getColorConstants('brand-pink') },
        { actionType: ActionType.A, type: MainMetricTypes.Clicked, color: this.colorsService.getColorConstants('brand-pink-plus2') },
        { actionType: ActionType.B, type: MainMetricTypes.Delivered, color: this.colorsService.getColorConstants('brand-yellow-minus2') },
        { actionType: ActionType.B, type: MainMetricTypes.Opened, color: this.colorsService.getColorConstants('brand-yellow') },
        { actionType: ActionType.B, type: MainMetricTypes.Clicked, color: this.colorsService.getColorConstants('brand-yellow-plus1') },
        { actionType: ActionType.C, type: MainMetricTypes.Delivered, color: this.colorsService.getColorConstants('brand-orange-minus3') },
        { actionType: ActionType.C, type: MainMetricTypes.Opened, color: this.colorsService.getColorConstants('brand-orange') },
        { actionType: ActionType.C, type: MainMetricTypes.Clicked, color: this.colorsService.getColorConstants('brand-orange-plus2') },
        { actionType: ActionType.D, type: MainMetricTypes.Delivered, color: this.colorsService.getColorConstants('brand-jade-plus1') },
        { actionType: ActionType.D, type: MainMetricTypes.Opened, color: this.colorsService.getColorConstants('brand-jade-plus2') },
        { actionType: ActionType.D, type: MainMetricTypes.Clicked, color: this.colorsService.getColorConstants('brand-jade-plus3') },
    ];

    getColor(actionType: ActionType, name: string): string {
        const found = this.colorsTable.find(entry =>
            entry.actionType === actionType &&
            entry.type === name);

        let color = found ? found.color : 'black';

        return color;
    }
}

