import { Injectable } from '@angular/core';
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { TemplateDetails } from 'src/app/features/manageTemplates/models/templateDetails';
import { AvailableIDSelectionValues } from 'src/app/features/manageTemplates/models/templatePreview';
import { Customer360DataService } from '../../services/customer360.data.service';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { IPermission } from '../../customer360.models';
import { CampaignResponse, CampaignsData } from './interfaces';

@Injectable({
	providedIn: 'root'
})
export class CampaignGridService {
	private templatesInfo: { templateId: string, doesExist: boolean }[] = [];

	constructor(private readonly http: HttpService, private dataService: Customer360DataService) { }

	getCampaignsDetailsPerCustomerBetweenDates = (customerId: string, startDate: string, endDate: string): Observable<CampaignsData> => {
		startDate = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
		endDate = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
		return this.dataService.getCampaignsDetailsPerCustomerBetweenDates(customerId, startDate, endDate)
			.pipe(catchError(error => of(error))).pipe(
				map((res: CampaignResponse) => {
					return res.Data as CampaignsData;
				})
			);
	}

	public getTemplateById(templateId: string) {
		const params = HttpService.toHttpParams({ templateId });
		return this.http.get<AjaxResponse<TemplateDetails>>('/Customer360/GetTemplateDetails', params);
	}

	public getUserPermission(permissionName: string) {
		const body = HttpService.toHttpParams({ permissionName });
		return this.http.get<IPermission>('/Main/GetUserPermission', body);
	}

	public doesTemplateExist(templateId: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const templateInfo = this.templatesInfo.find(x => x.templateId === templateId);
			if (!templateInfo) {
				this.doesTemplateExistPost(templateId).pipe(first()).subscribe(res => {
					const templateInfo = this.templatesInfo.find(x => x.templateId === templateId);
					if (!templateInfo || templateInfo.doesExist !== res.data) {
						this.templatesInfo.push({templateId, doesExist: res.data});
						observer.next(res.data);
					}
					else {
						observer.next(templateInfo.doesExist);
					}
					observer.complete();
				});
			}
			else {
				observer.next(templateInfo.doesExist);
				observer.complete();
			}
		});
	}
	
	private doesTemplateExistPost(templateId: string) {
		return this.http.get<AjaxResponse<boolean>>('/Customer360/DoesTemplateExist', HttpService.toHttpParams({templateId}));
	}

	public getPersonalizedTemplate(clientCustomerId: string, campaignExecutionDate: string, channelID: number, subMethodType: number, templateJson: string, idSelectionValue: AvailableIDSelectionValues, containsNonVisitorTags: boolean) {
		
		let body = {
			clientCustomerId,
			campaignExecutionDate,
			channelID, 
			subMethodType,
			templateJson: this.stringToBase64(templateJson),
			idSelectionValue,
			containsNonVisitorTags,
		}
		return this.http.post<DefaultAjaxResponse>('/Customer360/GetPersonalizedTemplate', body);
	}

	private stringToBase64(input: string): string {
		const encoder = new TextEncoder();
		const data = encoder.encode(input);
		let binary = '';
		for (let i = 0; i < data.length; i++) {
			binary += String.fromCharCode(data[i]);
		}
		return btoa(binary);
	}
}