import { Component, OnInit } from '@angular/core';
import { IModalConf } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { TemplateContextService } from '../../services/templateContext.service';

@Component({
	selector: 'new-template-modal',
	templateUrl: './newTemplateModal.component.html',
	styleUrls: [
		'./newTemplateModal.component.scss',
		'../../../../components/optiLogicModal/modals/successFailModal/successFailModal.component.scss'
	]
})
export class NewTemplateModalComponent implements OnInit {
	public newTemplateSelectedEditor: (editor) => any;
	modalConf: IModalConf;

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private templateContextService: TemplateContextService
	) {}

	ngOnInit() {
		this.setModalConf();
	}

	setModalConf(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.newTemplate.MODAL_TITLE'),
			xButton: { action: () => this.bsModalRef.hide() },
			hideFooterBorder: true,
			hideHeaderBorder: true,
			hideFooterPanel: true
		};
	}

	selectEditor(type) {
		this.newTemplateSelectedEditor(type);
		this.templateContextService.updateTemplateType(type === 'beefree');
		this.bsModalRef.hide();
	}
}
