<div [ngSwitch]="type" class="type-selection">
  <osl-item *ngSwitchCase="availableTypes.AggregatedAction" [showCheckbox]="true" [maxRowsInCell]="maxRowsInCell"
            [isSelected]="isAllSelected" [label]="label" (onChange)="toggleSelectAll($event)" [disabled]="isDisabled"></osl-item>
  <osl-item *ngSwitchCase="availableTypes.Item" [showCheckbox]="isMultiSelect" [maxRowsInCell]="maxRowsInCell"
            [isSelected]="node.isSelected()" [label]="label" (onChange)="toggleSelect($event)" [disabled]="isDisabled"
            [isGroupChild]="isGroupView" [singleRowHeight]="singleRowHeight" [itemHeight]="node.rowHeight" [icon]="icon"></osl-item>
  <osl-group *ngSwitchCase="availableTypes.Group" [label]="label"></osl-group>
  <osl-footer *ngSwitchCase="availableTypes.FooterAction" [config]="params.data" [disabled]="isDisabled"></osl-footer>
</div>

