import { Injectable } from '@angular/core';
import { AjaxResponse } from "@optimove/ui-sdk/common/models";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NavbarTreeNodeView } from '../../../modules/navbar/models/navbarTreeNode.model';
import { HttpService } from '../../../services/optimove-http/optimove-http.model';
import { WindowRefService } from '../../../services/windowRef.service';
import { BiEmbedConfig, BiReport, BiReportEmbed } from "../models/biStudio.model";

@Injectable({
  providedIn: 'root'
})
export class BiStudioService {
  BI_STUDIO_NAVBAR_BASE_URL = "/category/Analysis_&_Research/newBiStudio/";
  private readonly API_URL = "/BIStudio";
  public currentBiStudioPage$ = new BehaviorSubject<BiReportEmbed>(null);

  constructor(
    private readonly http: HttpService,
    private readonly windowRefService: WindowRefService,
  ) { }

  getReports(type: number=0): Observable<AjaxResponse<BiReport[]>> {
    const getReportsCache = window.sessionStorage.getItem(`biStudioCacheReports_${type}`);
    if (!getReportsCache) {
      return this.http.get<AjaxResponse<BiReport[]>>(`${this.API_URL}/GetReports`, HttpService.toHttpParams({type})).
        pipe(
          tap(res => {
            this.windowRefService.nativeWindow.sessionStorage.setItem(`biStudioCacheReports_${type}`, `${JSON.stringify(res)}`);
          }),
          catchError(() => {
            this.windowRefService.nativeWindow.sessionStorage.removeItem(`biStudioCacheReports_${type}`);
            return of({
              data: []
            } as AjaxResponse<BiReport[]>);
          })
        );
    } else {
      return of(JSON.parse(getReportsCache));
    }
  }

  getReportToken(report: BiReport, type: number): Observable<AjaxResponse<BiEmbedConfig>> {
    return this.http.post<AjaxResponse<BiEmbedConfig>>(`${this.API_URL}/GetReportTokenAsync`, { report, type });
  }

  isTenantHasCredential(type: number): Observable<boolean> {
    const getTenantCredentialResponseCache = window.sessionStorage.getItem(`biStudioTenantCredentialResponseCache`);
    if (!getTenantCredentialResponseCache) {
      return this.http.get<AjaxResponse<boolean>>(`${this.API_URL}/GetIsTenantHasCredential`, HttpService.toHttpParams({type})).pipe(
        switchMap(res => {
          this.windowRefService.nativeWindow.sessionStorage.setItem(`biStudioTenantCredentialResponseCache`, `${JSON.stringify(res.data)}`)
          return of(res.data);
        }),
        catchError(e => {
          return of(false);
        })
      );
    } else {
      return of(JSON.parse(getTenantCredentialResponseCache));
    }
  }

  sendMail(): Observable<AjaxResponse<null>> {
    return this.http.post<AjaxResponse<null>>(`${this.API_URL}/SendMail`, null);
  }

  getReportTypeByUrl(url: string) {
    url = url.toLowerCase();

    let reportType: number = 0;
    if (url.indexOf("bistudiolandingpage") > -1) {
      reportType = 0;
    } else if (url.indexOf("inter-stage-migrations") > -1) {
      reportType = 1;
    } else if (url.indexOf("visits-explorer") > -1) {
      reportType = 2;
    } else if (url.indexOf("orchestration-overview") > -1) {
      reportType = 3;
    } else if (url.indexOf("business-performance-overview") > -1) {
      reportType = 4;
    } else if (url.indexOf("cohorts-analysis") > -1) {
      reportType = 5;
    } else if (url.indexOf("deliverability-metrics") > -1) {
      reportType = 6;
    } else if (url.indexOf("repeat-rate-analysis") > -1) {
      reportType = 7;
    } else if (url.indexOf("benchmark-report") > -1) {
      reportType = 8;
    }

    return reportType;
  }

  getReportsWithPermissionsCheck(type: number = 0): Observable<BiReport[]> {
    return this.isTenantHasCredential(type).pipe(
      switchMap(hasCredential => {
        if (hasCredential === false) return of([]);
        return this.getReports(type)
          .pipe(map(res => res.data));
      })
    );
  }

  setBiStudioReportsToNavbar(navbarTreeView: NavbarTreeNodeView[], biStudioReports: BiReport[]) {
    const navbarTreeViewClone = [...navbarTreeView];
    const analyzeMenuItem = navbarTreeViewClone.find(m => m.name === "Analyze");
    const biStudioMenuItem = analyzeMenuItem?.children?.find(am => am.name === "BI Studio");

    if (!biStudioMenuItem) return [];
    if (biStudioReports == null || biStudioReports?.length === 0) {
      biStudioMenuItem.routerLink = `${this.BI_STUDIO_NAVBAR_BASE_URL}biStudioLandingPage`;
      return navbarTreeViewClone;
    }

    biStudioMenuItem.children = [];
    biStudioReports.forEach(report => biStudioMenuItem.children.push({
      name: report.name,
      isDisabled: biStudioMenuItem.isDisabled,
      routerLink: `${this.BI_STUDIO_NAVBAR_BASE_URL}${report.name.trim().replace(/ /g, '-')}`,
      isVisible: true
    }));

    if (biStudioMenuItem.children.length === 0) {
      biStudioMenuItem.routerLink = `${this.BI_STUDIO_NAVBAR_BASE_URL}biStudioLandingPage`;
    }

    return navbarTreeViewClone;
  }

  getReportNameByType(type: number) {
    switch (type) {
      case 1:
        return "Migration Explorer";
      case 2:
        return "Visits Explorer";
      case 3:
        return "Orchestration Overview";
      case 4:
        return "Business Performance Overview";
      case 5:
        return "Cohorts Analysis";
      case 6:
        return "Deliverability Metrics";
      case 7: 
        return "Repeate Rate Analysis";
      case 8: 
        return "Benchmark Report";
    }
  }
}

export class BiStudioServiceStub {
  getReports(type: number): Observable<AjaxResponse<BiReport[]>> {
    return of({
      data: [],
      isSuccess: true,
      errorCode: null,
      errorMsg: null
    } as AjaxResponse<BiReport[]>);
  }

  getReportsWithPermissionsCheck(type: number = 0): Observable<BiReport[]> {
    return of([]);
  }

  getReportToken(report: BiReport, type: number): Observable<AjaxResponse<BiEmbedConfig>> {
    return of({
      data: {},
      isSuccess: true,
      errorCode: null,
      errorMsg: null
    } as AjaxResponse<BiEmbedConfig>);
  }

  isTenantHasCredential(type: number): Observable<boolean> {
    return of(true);
  }

  sendMail(): Observable<AjaxResponse<null>> {
    return of({
      data: null,
      isSuccess: true,
      errorCode: null,
      errorMsg: null
    } as AjaxResponse<null>);
  }
}