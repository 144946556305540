<div class="channel-metrics-data-bar">
    <div class="row cms-container">
        <div class="col-md-2">
            <channel-metrics-rings [delivered]="delivered" [opened]="opened" [clicked]="clicked"></channel-metrics-rings>
        </div>
        <div class="col-md-5 cmns">
            <div class="col-sm-4" *ngFor="let nd of numbersDataSparse">
                <channel-metrics-number [channelId]="channelData.channelId" [isScheduledCampaign]='isScheduledCampaign' [planDetailChannelId]="channelData.planDetailChannelId" [planIdActionSerial]='planIdActionSerial' [datesData]="datesData" [templatesData]="channelData.templatesData" *ngIf="!!nd"
                                        [metric]="nd" 
                                        (metricClick)="onMetricClick($event)">
                </channel-metrics-number>
            </div>
        </div>
        <div class="col-md-4 col-sm-10 col-xs-6">
            <channel-metrics-table [metrics]="tableData" [channelId]="channelData.channelId" (metricClick)="onMetricClick($event)"></channel-metrics-table>
        </div>
    </div>
</div>
