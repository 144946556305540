<opti-logic-modal [modalConf]="modalConfig" data-qa-id="cy_opti_logic_modal">
    <div class="result-container">
        <span class="summary" [innerHTML]="summary"></span>

        <div class="details">
            <div class="details-row"> 
                <span> {{ "features.user_settings.body.importedCustomers.IMPORT_RESULT.SUCCESSFULLY_IMPORTED" | translate }} </span>
                <span class="details-number">{{ successfullyImported | number }}</span>
            </div>
            <div class="details-row"> 
                <span> {{ "features.user_settings.body.importedCustomers.IMPORT_RESULT.ALREADY_EXIST" | translate }} </span>
                <span class="details-number">{{ alreadyExists | number }}</span>
            </div>
            <div class="details-row"> 
                <span> {{ "features.user_settings.body.importedCustomers.IMPORT_RESULT.DUPLICATES" | translate }} </span>
                <span class="details-number">{{ duplicates | number }}</span>
            </div>
            <div class="details-row"> 
                <span> {{ "features.user_settings.body.importedCustomers.IMPORT_RESULT.INVALID_EMAILS" | translate }} </span>
                <span class="details-number">{{ invalidEmail | number }}</span>
            </div>
        </div>
    </div> 
</opti-logic-modal>