import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive, Input } from "@angular/core";
import * as moment from 'moment';
import { DateRangeInvalidStateMode } from './dateRangeInvalidStateMode.enum';

export function dateRangeMaxDateValidator(maxDate: Date) {

    return (control: AbstractControl): ValidationErrors | null => {
        const val: string[] = control.value;
        const toDate: string = val != null ? val[1] : null;
        const validationError = {};
        validationError[DateRangeInvalidStateMode.DateMustBeBeforeMaxDate] = "true";
        return maxDate && moment(toDate).isAfter(maxDate, 'day') ? validationError : null;
    };
}

@Directive({
    selector: "[dateRangeMaxDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: DateRangeMaxDateValidatorDirective,
        multi: true
    }]
})
export class DateRangeMaxDateValidatorDirective implements Validator {
    @Input() maxDate: Date;

    validate(c: AbstractControl): { [key: string]: any; } {
        return dateRangeMaxDateValidator(this.maxDate);
    }
}