import { IGranularKPIFields } from './../../customer360.models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { SearchItem, SearchListConfig } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { AddKPIsService } from 'src/app/services/addKPIs.service';
import { ItemSelectorModal } from 'src/app/components/itemSelectorModal/itemSelectorModal.component';

@Component({
  selector: 'add-kpis-modal',
  templateUrl: './addKPIsModal.component.html',
})
export class AddKPIsModalComponent implements OnInit {

  @Input() set openModal(open: boolean) { if (open) this.openItemSelectorModal() }
  @Output("onClose") onCloseEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output("onSubmit") onSubmitEmitter: EventEmitter<string> = new EventEmitter<string>();

  private searchListConfig: SearchListConfig = {
    isMultiSelect: false,
    keyProperty: "FieldName",
    valueProperty: "AverageAlias",
    itemNameTranslateKey: this.translate.instant(translateKeys.MODAL_DROPDOWN_PLACEHOLDER),
    placeholderTranslateKey: this.translate.instant(translateKeys.MODAL_DROPDOWN_PLACEHOLDER),
  }

  constructor(private modalService: OptiLogicModalService,
    private translate: TranslateService,
    private kpiService: AddKPIsService) { }

  ngOnInit(): void { }

  public openItemSelectorModal() {
    this.kpiService.getCustomerProfileGranularFields()
      .pipe(first())
      .subscribe(items => {
        this.modalService.open(
          ItemSelectorModal,
          'md',
          <ModalOptions<any>>{
            ignoreBackdropClick: true,
            initialState: {
              title: this.translate.instant(translateKeys.MODAL_TITLE),
              description: this.translate.instant(translateKeys.MODAL_DESCRIPTION),
              searchListConfig: this.searchListConfig,
              granularKPIs: items,
              submitBtnText: this.translate.instant(translateKeys.MODAL_SUBMIT_BTN),
              onSubmit: (kpiFields: IGranularKPIFields, tooltip?: string, actionAlias?: string, avgAlias?: string, format?: string) =>
                this.onSubmit(kpiFields),
              onClose: () => this.onCloseEmitter.emit()
            }
          }
        );
      });
  }

  private async onSubmit(kpiFields: IGranularKPIFields) {
    if (kpiFields) {
      const kpiId = await this.kpiService.addCampaignKpisField(kpiFields).toPromise();
      const minDateForKpiUpdate = await this.kpiService.getMinDateForKpiUpdate(kpiId).toPromise();
      if (minDateForKpiUpdate) {
        //show dialog
        const modalRef = this.kpiService.showIsRecalculationNeededModal(minDateForKpiUpdate, kpiId);
      }

      this.onSubmitEmitter.emit(kpiId.toString());
    }
  }
}

const translateKeys = {
  MODAL_TITLE: "features.customer360.body.customer_activity_trend.addKpisModal.TITLE",
  MODAL_DESCRIPTION: "features.customer360.body.customer_activity_trend.addKpisModal.DESCRIPTION",
  MODAL_SUBMIT_BTN: "features.customer360.body.customer_activity_trend.addKpisModal.SUBMIT_BTN",
  MODAL_DROPDOWN_PLACEHOLDER: "features.customer360.body.customer_activity_trend.addKpisModal.DROPDOWN_PLACEHOLDER"
}