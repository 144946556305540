import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../../components/components.module";
import {BrandsListComponent} from "./Components/BrandsList/brandsList.component";
import {AgGridModule } from "@ag-grid-community/angular";
import {EditBtnCellRenderComponent} from "./Components/EdtiBtnCellRender/editBtnCellRender.component";
import {IsDOICellRenderComponent} from "./Components/IsDOICellRender/isDOICellRender.component";
import {BrandsService} from "./Components/BrandsList/brandsService";
import {EditBrandComponent} from "./Components/EditBrand/editBrand.component";
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {SharedModule} from "../../../shared.module";

@NgModule({
    imports:[
        ComponentsModule,
        CommonModule,
        AgGridModule.withComponents([
            EditBtnCellRenderComponent,
            IsDOICellRenderComponent
        ]),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    declarations:[
        EditBtnCellRenderComponent,
        IsDOICellRenderComponent,
        BrandsListComponent,
        EditBrandComponent,
    ],
    providers:[BrandsService],
    entryComponents:[
        BrandsListComponent,
        EditBtnCellRenderComponent,
        IsDOICellRenderComponent,
        EditBrandComponent,
    ]

})
export class DoubleOptedInModule{}