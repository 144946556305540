import { Component, Input, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { ISelectableAttribute } from '../../customer360.models';
import { sideBarTranslateKeys } from '../../customer360.translate.key';

@Component({
  selector: 'opti-side-bar',
  templateUrl: './optiSideBar.component.html',
  styleUrls: ['./optiSideBar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OptiSideBarComponent implements OnInit {

  public translateKeys = sideBarTranslateKeys;

  @Input() config: IModalConf;
  @Input() attributes: ISelectableAttribute[];
  @Output() onDone: Function;

  public defaultAttributes: ISelectableAttribute[] = [];
  public allAttributes: ISelectableAttribute[] = [];

  public selectedAttributesLimit: number = 30;
  public selectedAttributesCount: number = 0;
  public searchString: string = "";

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.config.buttons[1].action = () => { this.onDone(this.getSelectedAttributesNames()) }
    this.config.buttons[1].isDisabled = () => this.selectedAttributesCount === 0;

    this.attributes.forEach(x => {
      if (x.IsChecked) {
        this.selectedAttributesCount += 1;
      }
      x.IsDefaultRun 
      ? this.defaultAttributes.push(x)
      : this.allAttributes.push(x);
    });
  }

  onCheckBoxClick(attributeIndex: number, isDefaultAttribute: boolean = false) {
    if (isDefaultAttribute) {
      this.defaultAttributes[attributeIndex].IsChecked = !this.defaultAttributes[attributeIndex].IsChecked;
      this.defaultAttributes[attributeIndex].IsChecked
      ? this.selectedAttributesCount += 1
      : this.selectedAttributesCount -= 1;
    }
    else {
      this.allAttributes[attributeIndex].IsChecked = !this.allAttributes[attributeIndex].IsChecked;
      this.allAttributes[attributeIndex].IsChecked
      ? this.selectedAttributesCount += 1
      : this.selectedAttributesCount -= 1;
    }
  }

  clearSelection() {
    this.defaultAttributes.forEach(x => x.IsChecked = false);
    this.allAttributes.forEach(x => x.IsChecked = false);
    this.selectedAttributesCount = 0;
  }

  clearSearchStr() {
    this.searchString = "";
    this.search();
  }

  search() {
    const searchString = this.searchString.toLowerCase();
    this.defaultAttributes.forEach(x => x.Hide = (this.searchString !== "" && !x.Alias.toLowerCase().includes(searchString)));
    this.allAttributes.forEach(x => x.Hide = (this.searchString !== "" && !x.Alias.toLowerCase().includes(searchString)));
  }

  private getSelectedAttributesNames(): string[] {
    const result: string[] = [];
    this.defaultAttributes.forEach(x => {if(x.IsChecked) result.push(x.Name)});
    this.allAttributes.forEach(x => {if(x.IsChecked) result.push(x.Name)});
    return result;
  }
}
