
<form class="form-inline form" *ngIf="true" [formGroup]="form">
    <div class="section" style="padding-bottom: 18px">
        <div class="condition-headers">
        </div>
        <div class="conditions" cdkDropList (cdkDropListDropped)="onDrop($event)" formArrayName="conditions">
            <div class="condition" *ngFor="let condition of conditions.controls index as i;" cdkDrag>
                <div class="start-container">
                    <div class="condition-handle" cdkDragHandle>
                        <i class="material-icons">drag_handle</i>
                    </div>
                    <div class="condition-number">
                        {{ i + 1 }}
                    </div>
                </div>
                <div class="condition-inputs">
                    <if-else-condition 
                    [formControlName]="i"
                    #condition
                    name="condition"
                    [isAddMode]="config.isAddMode"
                    [disabled]="config.isDisabled"
                    [Position]="i + 1"
                    [attributesSearchItems]="attributesSearchItems"
                    (changeEmitter)="onConditionChange($event, i)">
                    </if-else-condition>
                </div>
                <button data-qa-id="DeleteCondition" class="condition-remove btn btn-link btn-link-default" type="button" (click)="onRemoveCondition(i+1)" [disabled]="conditions.length <= 1 || config.isDisabled">
                    <i class="material-icons">delete_forever</i>
                </button>
            </div>
        </div>
        <div class="add-condition">
            <button data-qa-id="AddCondition" class="add-template btn btn-link" type="button" (click)="onAddCondition()" [disabled]="isAddButtonDisabled || config.isDisabled">
                {{ translateKeys.addCondition | translate }}
            </button>
        </div>
        <div class="checkbox checkbox-primary">
            <input [disabled]="conditions.controls.length == 1 || config.isDisabled" data-qa-id="ComplexSelectionCheckBox" 
                    class="checkbox-input" 
                    [defaultChecked]="isComplexSelection"  
                    [(ngModel)]="isComplexSelection"
                    [ngModelOptions]="{standalone: true}"
                    type="checkbox" 
                    (change)="onCheckBoxChange()">
            <label class="complex-selection-label">
                {{ translateKeys.complexSelection | translate }}
            </label>
            <textarea data-qa-id="ComplexSelectionTextArea"
                        formControlName="complexSelection"
                        *ngIf="isComplexSelection" 
                        type="text" 
                        class="text-input text-area"
                        (change)="onComplesSelectionChange()"
                        [ngClass]="{'red-border': complexSelection.invalid}"
                        placeholder="{{translateKeys.optional | translate}}">
            </textarea>
        </div>
        <span *ngIf="complexSelection.invalid && isComplexSelection" class="error-msg">
            {{translateKeys.complexSelectionErrMsg | translate}}
        </span>
        <div class="value-input">
            <label data-qa-id="customers-meet-conditions-lbl">
                {{ translateKeys.customersWhoMeetConditions | translate }}
            </label>
            <input data-qa-id="MeetValueInput" 
                    formControlName="expectedValue"
                    type="text" 
                    (change)="onValueChange()"
                    class="text-input"
                    [ngClass]="{'red-border': expectedValue.invalid && expectedValue.touched}"
                    placeholder="{{translateKeys.value | translate}}" >

        </div>
    </div>
    <div class="section">
        <div class="value-input">
            <label data-qa-id="customers-dont-meet-conditions-lbl">
                {{ translateKeys.customersWhoDontMeetConditions | translate }}
            </label>
            <input data-qa-id="DontMeetValueInput" 
                    formControlName="unexpectedValue"
                    type="text" 
                    (change)="onValueChange()"
                    class="text-input"
                    [ngClass]="{'red-border': unexpectedValue.invalid && unexpectedValue.touched}"
                    placeholder="{{translateKeys.value | translate}}" >
        </div>
    </div>
</form>

