<div class="templates-grid-view">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h2>{{'components.templatePreview.TEMPLATES_LIST_HEADER' | translate: {templatesLength: this.templates.length} }}</h2>
            
            <div *ngIf="toShowChannelName">{{channelName}}</div>
        </div>

        <div class="panel-body">
            <cdk-virtual-scroll-viewport itemSize="98" class="virtual-scroll-viewport">
                <div class="templates-row" *cdkVirtualFor="let templatesRow of templatesRows; let rowIndex = index">
                    <div class="template-item" *ngFor="let template of templatesRow; let columnIndex = index">
                        <templates-preview-carousel 
                            [templatesPreviewFullData]="[template]"
                            [shouldOpenModal]="false"
                            (showTemplatesCarouselModal)="onShowTemplatesCarouselModal(rowIndex * columnsCount + columnIndex)">
                        </templates-preview-carousel>
        
                        <div class="template-details">
                            <div class="template-index">{{rowIndex * columnsCount + columnIndex + 1}}</div>
                            <span class="template-name" ellipsis-title [lines]="2">{{template.name}}</span>
                            <div class="template-time">{{template.time}} {{template.timeZone}}</div>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>
