<form [formGroup]="form" class="form-group">
    <div class="subtitle row calc-row">
        <h3 class="col-sm-12">
            {{translateKeys.attributeCalculation | translate}}
        </h3>
    </div>

    <div class="culc-section-bg">

        <div class="row calc-row">
            <div class="col-sm-2">
                {{translateKeys.function | translate}}
            </div>
            <div class="col-sm-7">
                <opti-search-list class="value-input" data-qa-id="attribute-result" formControlName="func"
                    [items]="functionList" [config]="funcSearchListConfig">
                </opti-search-list>
            </div>
        </div>
        <ng-container *ngIf="isTextFunction">
            <div class="row calc-row" *ngIf="activityTypes.length > 1">
                <div class="col-sm-2">
                    {{translateKeys.activityType | translate}}
                </div>
                <div class="col-sm-7">
                    <opti-search-list data-qa-id="activity-type" formControlName="activityType" [items]="activityTypes"
                        [config]="activityTypesSearchListConfig">
                    </opti-search-list>
                </div>
            </div>
            <div class="row calc-row">
                <div class="col-sm-2">
                    {{translateKeys.attribute | translate}}
                </div>
                <div class="col-sm-7">
                    <opti-search-list class="value-input" data-qa-id="attribute-result" formControlName="resultAttribute"
                        [items]="textAttributes" [config]="resultAttributeSearchListConfig">
                    </opti-search-list>
                </div>
            </div>
            <div class="row calc-row" *ngIf="isWithMetric">
                <div class="col-sm-2">
                    {{translateKeys.in | translate}}
                </div>
                <div class="col-sm-7">
                    <opti-search-list class="value-input" data-qa-id="attribute-result-metric" formControlName="metricAttribute"
                        [items]="numericAttributes" [config]="resultAttributeSearchListConfig">
                    </opti-search-list>
                </div>
            </div>
            <div class="row calc-row">
                <div class="col-sm-2">
                    {{translateKeys.timeframe | translate}}
                </div>
                <div [ngClass]="{'col-sm-5': needShowTimeframePeriod, 'col-sm-7': !needShowTimeframePeriod }">
                    <opti-search-list data-qa-id="timeframe" formControlName="timeframe" [items]="timeframes"
                        [config]="timeframeSearchListConfig">
                    </opti-search-list>
                </div>
                <div *ngIf="needShowTimeframePeriod" class="timeframe-period-column col-sm-2">
                    <input data-qa-id="timeframe-period" formControlName="timeframePeriod" class="value-input" type="number"
                        [min]="1" [max]="730" [required]
                        [ngClass]="{'red-border': timeframePeriod.invalid && timeframePeriod.touched}"
                        (keypress)="isInputEventIsRegularNumber($event)" />
    
                    <div *ngIf="timeframe.value[0].key === 1" class="label-days">
                        {{translateKeys.days | translate}}
                    </div>
                    <div *ngIf="timeframe.value[0].key === 2" class="label-days">
                        {{translateKeys.daysAgo | translate}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isNumericFunction">
            <div class="row calc-row">
                <div class="col-sm-2">
                    {{translateKeys.of | translate}}
                </div>
                <ng-container *ngIf="hasActivityTypes">
                    <div class="col-sm-5">
                        <opti-search-list data-qa-id="activity-type" formControlName="activityType" [items]="activityTypes"
                            [config]="activityTypesSearchListConfig">
                        </opti-search-list>
                    </div>
                </ng-container>
                <div [ngClass]="{'col-sm-2': hasActivityTypes, 'col-sm-7': !hasActivityTypes }">
                    <opti-search-list class="value-input" data-qa-id="attribute-result" formControlName="resultAttribute"
                        [items]="numericAttributes" [config]="resultAttributeSearchListConfig">
                    </opti-search-list>
                </div>
            </div>
            <div class="row calc-row">
                <div class="col-sm-2">
                    {{translateKeys.where | translate}}
                </div>
    
                <div class="col-sm-7">
                    <div formArrayName="conditions" class="conditions">
                        <div *ngFor="let condition of conditions.controls index as i" class="condition">
                            <span *ngIf="i > 0">
                                And
                            </span>
                            <div>
                                <purchase-attribute-condition #condition [attributes]="textAttributes" [formControlName]="i">
                                </purchase-attribute-condition>
                                <button *ngIf="maxConditionsCount > 1" data-qa-id="delete-condition"
                                    class="btn btn-link btn-link-default" type="button"
                                    (click)="onRemoveAttributeCondition(i)"
                                    [disabled]="conditions.length <= 1 || condition.disabled">
                                    <i class="material-icons">delete_forever</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row add-attr" *ngIf="maxConditionsCount > 1">
                <div class="col-sm-2"></div>
                <button data-qa-id="AddCondition" class="add-template btn btn-link" type="button"
                    (click)="addAttributeCondition()" [disabled]="isAddButtonDisabled">
                    {{ translateKeys.add | translate }}
                </button>
            </div>
            <div class="row calc-row">
                <div class="col-sm-2">
                    {{translateKeys.timeframe | translate}}
                </div>
                <div [ngClass]="{'col-sm-5': needShowTimeframePeriod, 'col-sm-7': !needShowTimeframePeriod }">
                    <opti-search-list data-qa-id="timeframe" formControlName="timeframe" [items]="timeframes"
                        [config]="timeframeSearchListConfig">
                    </opti-search-list>
                </div>
                <div *ngIf="needShowTimeframePeriod" class="timeframe-period-column"
                    [ngClass]="{'col-sm-2': timeframe.value[0].key === 1, 'col-sm-3': timeframe.value[0].key === 2}">
                    <input data-qa-id="timeframe-period" formControlName="timeframePeriod" class="value-input" type="number"
                        [min]="1" [max]="730" [required]
                        [ngClass]="{'red-border': timeframePeriod.invalid && timeframePeriod.touched}"
                        (keypress)="isInputEventIsRegularNumber($event)" />
    
                    <div *ngIf="timeframe.value[0].key === 1" class="label-days">
                        {{translateKeys.days | translate}}
                    </div>
                    <div *ngIf="timeframe.value[0].key === 2" class="label-days">
                        {{translateKeys.daysAgo | translate}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

</form>