import {Directive} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

export function cannotStartWithNumbersValidator() : ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
        var patt = new RegExp('^([^0-9][a-zA-Z0-9_@]*)*$');
        if (!patt.test(control.value)) {
            return { 
                invalidStartWithNumbers: true 
            };
        }
        return null;
    };
  }


@Directive({
    selector: '[cannotStartWithNumbersValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: CannotStartWithNumbersValidationDirective,
        multi: true
    }]
})
export class CannotStartWithNumbersValidationDirective implements Validator {

    validate(c: AbstractControl): { [key: string]: any; } {
        return cannotStartWithNumbersValidator();

    }

}