import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { SsmService } from '../../../../services/ssm.service';
import './templateScriptGuardModal.component.scss';
import { TemplateScriptGuardModalService } from './templateScriptGuardModal.service';
import { SsmServiceEndPoints } from '../../../../services/optimove-ssm/optimove-ssm-resources.model';

@Component({
	selector: 'template-script-guard-modal',
	templateUrl: './templateScriptGuardModal.component.html'
})
export class TemplateScriptGuardModalComponent implements OnInit {
	public siteKey: string = '';
	modalConf: IModalConf;
	isButtonDisabled: boolean = true;
	captchaResponse: string = '';
	ssm: any;

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private templateScriptGuardModalService: TemplateScriptGuardModalService,
		private ssmService: SsmService
	) {}

	ngOnInit(): void {
		this.ssm = this.ssmService.getSSM();
		this.ssmService.GetGeneric<string>(SsmServiceEndPoints.ReCaptchaPublicKey).subscribe((siteKey: string) => {
			this.siteKey = siteKey;
		});
		this.setModalConf();
	}

	setModalConf(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.templateScriptGuard.TITLE'),
			buttons: [
				{
					isDisabled: () => {
						return this.isButtonDisabled;
					},
					class: 'btn-primary',
					label: this.translateService.instant('general.YES'),
					action: () => {
						this.bsModalRef.hide();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('general.NO'),
					action: () => {
						this.templateScriptGuardModalService.setRecaptchaToken(null).subscribe((data: boolean) => {
							this.bsModalRef.hide();
						});
					}
				}
			],
			hideHeaderBorder: true,
			hideFooterBorder: true
		};
	}

	reCaptchaResolved(captchaResponse: string): void {
		this.templateScriptGuardModalService.setRecaptchaToken(captchaResponse).subscribe((data: boolean) => {
			if (data) {
				this.captchaResponse = captchaResponse;
				this.isButtonDisabled = false;
			}
		});
	}
}
