import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ControllerActionScheme, HttpMethod, HttpOptions, HttpService } from './optimove-http.model';

@Injectable({
	providedIn: 'root'
})
export class OptimoveHttpService extends HttpService {
	accessToken$: Subject<string>;
	isRefreshingToken: boolean;
	_onLogin$ = new Subject<void>();
	_onSessionExpired$ = new Subject<void>();

	constructor(protected readonly http: HttpClient) {
		super(http);
		(window.top as any).baseApiUrlString = this.baseApiUrlString;
		(window.top as any).getCustomHeaders = HttpService.getCustomHeaders;
		(window.top as any).httpService = this; // For very old AngularJs pages
	}

	get<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.GET, apiURL, null, HttpService.getOptions(options));
	}

	post<T>(url: string | ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.POST, apiURL, body, HttpService.getOptions(options, body));
	}

	put<T>(url: string | ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.PUT, apiURL, body, HttpService.getOptions(options, body));
	}

	delete<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.DELETE, apiURL, null, HttpService.getOptions(options));
	}

	patch<T>(url: string | ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.PATCH, apiURL, body, HttpService.getOptions(options, body));
	}

	head<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		const apiURL = HttpService.getRequestScheme(url);

		return this.sendRequest<T>(HttpMethod.HEAD, apiURL, null, HttpService.getOptions(options));
	}

	postWithoutFormattingURL<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
		return this.sendRequest<T>(HttpMethod.POST, url, body, options);
	}

	/**
	 *
	 * @param method a string representing the HTTP method to use.
	 * @param url     The endpoint URL (string)
	 * @param body the body of the request.
	 * @param options The HTTP options to send with the request.
	 * @return An `Observable` of the response, with the response body as an `T`.
	 */
	private sendRequest<T>(method: string, url: string, body: any | null, options?: HttpOptions): Observable<T> {
		const cancelPendingRequests$ = this.cancelPendingRequests$.asObservable();

		return this.http.request<T>(method, url, { ...options, body } as any).pipe(
			catchError((error: HttpErrorResponse) => {
				switch (error.status) {
					case HttpStatusCode.Unauthorized:
						if (localStorage.getItem('refreshToken') === null || localStorage.getItem('accessToken') === null) {
							this._onSessionExpired$.next();
							return throwError(error);
						}

						const getNewAccessToken$ = this.getNewAccessToken();

						return getNewAccessToken$.pipe(
							switchMap(() => this.sendRequest<T>(method, url, body, HttpService.getOptions(options))),
							catchError((refreshError) => {
								return throwError(refreshError); // Re-throw the error to propagate it further
							})
						);
					default:
						break;
				}
				// Return an observable with a user-facing error message.
				return throwError(error);
			}),
			takeUntil(cancelPendingRequests$),
			map((response) => response as any as T)
		);
	}

	get onLogin$(): Observable<void> {
		return this._onLogin$.asObservable();
	}

	get onSessionExpired$(): Observable<void> {
		return this._onSessionExpired$.asObservable();
	}

	/**
	 * @returns Observable which will request a new access token and store it for next usage
	 */
	private getNewAccessToken(): Observable<string> {
		if (this.isRefreshingToken) {
			return this.accessToken$.asObservable();
		}

		this.isRefreshingToken = true;
		this.accessToken$ = new Subject<string>();
		return this.post<string>('/Auth/Refresh', { refresh: localStorage.getItem('refreshToken') }, HttpService.getOptions()).pipe(
			catchError((error) => {
				this._onLogin$.next();
				this.accessToken$.error(error);
				this.accessToken$.complete();
				return throwError(error);
			}),
			tap((accessToken: string) => {
				this.isRefreshingToken = false;
				this.accessToken$.next(accessToken);
				this.accessToken$.complete();
				localStorage.setItem('accessToken', accessToken);
			})
		);
	}
}
