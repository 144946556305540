import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { TemplateContextService } from '../services/templateContext.service';
import { OptimailEditorComponent } from './optimailEditor/optimailEditor.component';

@Injectable()
export class TemplateEditorGuard implements CanDeactivate<OptimailEditorComponent> {
	constructor(
		private templateContext: TemplateContextService,
		private modalService: OptiLogicModalService,
		private translate: TranslateService
	) {}

	canDeactivate(
		component: OptimailEditorComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.templateContext.isDirty()) return true;
		return new Observable((observer: Subscriber<boolean>) => {
			const modal = this.modalService.openModalMessage('sm', {
				message: this.translate.instant('features.manage_templates.messages.CHANGES_NOT_SAVED_MESSAGE'),
				buttons: [
					{
						isDisabled: () => {
							return false;
						},
						class: 'btn-primary',
						label: this.translate.instant('general.DISCARD_CHANGES'),
						action: () => {
							observer.next(true);
							observer.complete();
							modal.hide();
							this.templateContext.resetCurrentTemplate(false);
						}
					},
					{
						isDisabled: () => {
							return false;
						},
						class: 'btn-default',
						label: this.translate.instant('general.CONTINUE_EDITING'),
						action: () => {
							observer.next(false);
							observer.complete();
							modal.hide();
						}
					}
				],
				hideHeaderBorder: false,
				hideFooterBorder: false
			});
		});
	}
}
