import { Injectable } from '@angular/core';
import { SortingTypes } from '../models/sortingTypes';
import { BehaviorSubject } from 'rxjs';
import { TemplateTreeItem } from '../models/templateResponse';

@Injectable({
	providedIn: 'root'
})
export class TemplatesSortingService {
	private readonly sortingTypesOnSessionStorage = 'templatesSortingPerChannel';

	private $sortingType: BehaviorSubject<number> = new BehaviorSubject<number>(SortingTypes.ASC_BY_NAME);
	public sortingType = this.$sortingType.asObservable();

	constructor() {}

	public updateSortingTypePerChannel(channelId: number, sortingType: number) {
		let templatesSorting: { [key: number]: number } = {};

		templatesSorting =
			sessionStorage.getItem(this.sortingTypesOnSessionStorage) === null
				? templatesSorting
				: JSON.parse(sessionStorage.getItem(this.sortingTypesOnSessionStorage));

		templatesSorting[channelId] = sortingType;
		sessionStorage.setItem(this.sortingTypesOnSessionStorage, JSON.stringify(templatesSorting));
		if (this.$sortingType.value !== sortingType) {
			this.$sortingType.next(sortingType);
		}
	}

	public getSortingType(channelId: number): number {
		const templatesSorting = JSON.parse(sessionStorage.getItem(this.sortingTypesOnSessionStorage));

		if (!templatesSorting || !templatesSorting.hasOwnProperty(channelId)) {
			return null;
		}
		return templatesSorting[channelId];
	}

	public getSortedTemplatesIds(templates: TemplateTreeItem[], sortingType: number): number[] {
		switch (sortingType) {
			case SortingTypes.ASC_BY_NAME:
				return TemplatesSortingService.sortTemplatesByNameAsc(templates);
			case SortingTypes.DESC_BY_NAME:
				return TemplatesSortingService.sortTemplatesByNameDesc(templates);
			case SortingTypes.NEWEST:
				return TemplatesSortingService.sortTemplatesByNewest(templates);
			case SortingTypes.OLDEST:
				return TemplatesSortingService.sortTemplatesByOldest(templates);
		}
		return;
	}

	private static sortTemplatesByNameDesc(templates: TemplateTreeItem[]): number[] {
		return templates
			.sort((t1, t2) => (t1.text.toLocaleLowerCase() > t2.text.toLocaleLowerCase() ? -1 : 1))
			.map((template) => template.id);
	}

	private static sortTemplatesByNameAsc(templates: TemplateTreeItem[]): number[] {
		return templates
			.sort((t1, t2) => (t1.text.toLocaleLowerCase() < t2.text.toLocaleLowerCase() ? -1 : 1))
			.map((template) => template.id);
	}

	private static sortTemplatesByOldest(templates: TemplateTreeItem[]): number[] {
		return templates
			.sort((t1, t2) => {
				return <any>new Date(t1.lastModifiedDate) - <any>new Date(t2.lastModifiedDate);
			})
			.map((template) => template.id);
	}

	private static sortTemplatesByNewest(templates: TemplateTreeItem[]): number[] {
		return templates
			.sort((t1, t2) => {
				return <any>new Date(t2.lastModifiedDate) - <any>new Date(t1.lastModifiedDate);
			})
			.map((template) => template.id);
	}
}
