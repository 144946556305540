<div class="personalization-tags-modal">
    <div class="opti-modal-window opti-logic-modal">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h2>
                    Personalization Tags
                </h2>
                <div class="input-group input-search">
                    <input autocomplete="on" class="form-control" [(ngModel)]="searchFilter" data-qa-id="search-tag" id="filter" (keyup.enter)="onSearchChange(this.searchFilter)"
                           #filter
                           placeholder="Quick find personalization tag" />
                    <span class="input-search-label material-icons search-text" *ngIf="this.searchFilter.length==0">search</span>
                    <span class="input-search-label material-icons delete-text" *ngIf="this.searchFilter.length>0"
                          (click)="this.onSearchChange('')" >close</span>
                </div>
                 <button type="button" class="close-modal btn btn-link btn-link-default" (click)="modalRef.hide()">
                    <span class="material-icons" aria-hidden="true">close</span>
                </button>
            </div>
            <opti-loading [show]="!this.isGridReady" [size]="'md'"></opti-loading>
            <div class="panel-body">
                <div class="ag-grid-container">

                    <div class="personalization-tag-categories-container">
                        <ag-grid-angular style="height: 100%; width: 186px" class="ag-theme-clean personalization-categories"
                                         [modules]="modules"
                                         [components]="frameworkComponents"
                                         [gridOptions]="categoriesGridOptions" *ngIf="this.isGridReady">
                        </ag-grid-angular>

                        <div *ngIf="isBeeFree && isUseContentTagsEnabled()" class="open-content-tags-btn" (click)="openContentTagModal()">
                            <span class="content-tag-btn-text">
                                Content Tags
                            </span>
                            <span class="ofont-icon"></span>
                        </div>
                    </div>

                    <div class="personaliztion-tags-container">
                        <div *ngIf="this.datasource.length === 0"  class="overlay-no-rows">
                            <span>No personalization tags</span>
                        </div>
                        <ag-grid-angular style="height: 100%; width: 414px" class="ag-theme-clean personalization-tag"
                                         [modules]="modules"
                                         [components]="frameworkComponents"
                                         [gridOptions]="tagsGridOptions" (selectionChanged)="onSelectionChanged($event)"
                                         (modelUpdated)="onModelUpdated($event)" *ngIf="this.isGridReady && this.datasource.length > 0">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
