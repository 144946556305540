import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
, ViewEncapsulation } from "@angular/core";
import { TreeNode } from "@circlon/angular-tree-component";
import { ITreeEvent, treeEventType } from "../jsTree.component";
import {ITreeMenuItem} from "../menuItem.model";

@Component({
    selector: 'js-tree-more-actions-menu',
    templateUrl: './jsTreeActionsMenu.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../jsTree.component.scss', './jsTreeActionsMenu.component.scss']
})
export class JsTreeActionsMenuComponent implements OnChanges {
    
    @Input() items: ITreeMenuItem[];
    @Input() leftClickXPosition: number;
    @Input() leftClickYPosition: number;
    @Input() isTreeScrolling: boolean;
    @Input() node: TreeNode;
    @Output() itemClicked: EventEmitter<ITreeEvent> = new EventEmitter();

    public isMenuOpen = false;

    constructor(private eRef: ElementRef) {
        this.isMenuOpen = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.isMenuOpen) {
            return;
        }

        this.closeMenu();
    }

    
    @HostListener('document:contextmenu', ['$event'])
    contextClick(event) {
        if (this.isMenuOpen) {
            this.closeMenu();
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.isMenuOpen) {
            return;
        }

        if(!this.getMenuContainer().contains(event.target) && !event.target.classList.contains("dot-more-button")) {
            this.closeMenu();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            if (this.items && this.items.length > 0) {
                this.showMenu(this.leftClickXPosition, this.leftClickYPosition);
            } else {
                this.closeMenu();
            }
        }

        if (changes.isTreeScrolling &&
            changes.isTreeScrolling.currentValue &&
            !changes.isTreeScrolling.previousValue) {
            this.closeMenu();
        }
    }

    private showMenu(leftClickXPosition: number, leftClickYPosition: number) {
        const menuNativeElement = this.getMenuContainer();

        this.isMenuOpen = true;

        const itemHeight = 25;
        const topBorder = 1;
        const bottomBorder = 1;
        const menuHeight = this.items.length * itemHeight + topBorder + bottomBorder;

        const menuYPosition = leftClickYPosition + 10;
        if (this.isMenuInScreen(document.body.clientHeight, menuHeight, menuYPosition)) {
            menuNativeElement.style.top = `${menuYPosition}px`;
        } else {
            const menuUpYPosition = leftClickYPosition - menuHeight - 10;
            menuNativeElement.style.top = `${menuUpYPosition}px`;
        }
        menuNativeElement.style.left = `${leftClickXPosition}px`;
    }

    private getMenuContainer(): HTMLElement {
        return this.eRef.nativeElement.firstChild;
    }


    public onMenuItemClicked(eventType: treeEventType) {
        this.itemClicked.emit({
            eventName: eventType,
            node: this.node,
        });
        this.closeMenu();
    }

    private closeMenu() {
        this.isMenuOpen = false;
    }

    private isMenuInScreen(screenHeight, menuHeight, menuYPosition) {
        if (screenHeight > menuHeight + menuYPosition) {
            return true;
        } else {
            return false;
        }
    }
}