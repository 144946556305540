import { AttributesListExportService } from './services/attributesListExport.service';
import { ConditionalAttributeService } from './services/condition.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '../../../components/components.module';
import { CommonModule } from '@angular/common';
import { AgGridModule } from "@ag-grid-community/angular";
import { AttributesListComponent } from "./attributesList.component";
import { AttributesListService } from "./services/attributesList.service";
import { AttributesManagerService } from './services/attributesManager.service';
import { AddAttributePopupComponent } from "./components/addAttributePopup/addAttributePopup.component";
import { SelectAttributePopupComponent } from "./components/selectAttributePopup/selectAttributePopup.component";
import { TestFormulaModalComponent } from "./components/testFormulaModal/testFormulaModal.component";
import { CalculatedAttributeComponent } from "./components/calculatedAttribute/calculatedAttribute.component";
import { CustomerAttributeComponent } from "./components/customerAttribute/customerAttribute.component";
import { BtnCellRendererComponent } from "./components/btnCellRenderer/btnCellRenderer.component";
import { StatusCellRendererComponent } from "./components/statusCellRenderer/statusCellRenderer.component";
import { TypeCellRendererComponent } from "./components/typeCellRenderer/typeCellRenderer.component";
import { EditBaseAttributeComponent } from "./components/editBaseAttribute/editBaseAttribute.component";
import { AttributeCalculatorComponent } from "./components/attributeCalculator/attributeCalculator.component";
import { SharedModule } from '../../../shared.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OptiModalModule } from '../../../modules/OptiModal.module';
import { StatusFilterComponent } from './filters/statusFilter/statusFilter.component';
import { TypeFilterComponent } from './filters/typeFilter/typeFilter.component';
import { InputSearchModule } from '../../../components/inputSearch/InputSearch.module';
import { FormulaService } from './services/formula.service';
import { FormulaValidatorService } from './services/formulaValidator.service';
import { ConditionalValidatorService } from './services/conditionalValidator.service';
import { ConditionalEditorComponent } from './components/conditionalEditor/conditionalEditor.component';
import { UseAsPersonalizationTagComponent} from "./components/attributeSettings/attributeSettings.component";
import { IfElseConditionComponent } from './components/conditionalEditor/ifElseCondition/ifElseCondition.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomerAttrEditorRef } from '../../../features/settings/attributes/directives/customerAttributeEditorRef.directive';
import { NumbersRangePickerComponent } from './components/conditionalEditor/numbersRangePicker/numbersRangePicker.component'
import { PopoverModule } from 'ngx-bootstrap/popover';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { AttributesCountTag } from './components/AttributesCountTag/attributeCountTag.component';
import { ConditionalEditorArrayComponent } from './components/conditionalEditorArray/conditionalEditorArray.component';
import { MultipleConditionEditorComponent } from './components/conditionalEditorArray/multipleConditionEditor/multipleConditionEditor.component';
import { PurchaseHistoryAttributeComponent } from './components/purchaseHistoryAttribute/purchaseHistoryAttribute.component';
import { PurchaseAttributesService } from './services/purchaseAttributes.service';
import { PurchaseCalculationSectionComponent } from './components/purchaseHistoryAttribute/purchaseAttributeCalculationSection/purchaseHistoryAttributeCalculationSection.component';
import { OldPurchaseCalculationSectionComponent } from './components/purchaseHistoryAttribute/oldPurchaseAttributeCalculationSection/oldPurchaseHistoryAttributeCalculationSection.component';
import { PurchaseAttributeConditionComponent } from './components/purchaseHistoryAttribute/purchaseAttributeCondition/purchaseHistoryAttributeCondition.component'

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        AgGridModule.withComponents([
            BtnCellRendererComponent,
            StatusCellRendererComponent,
            TypeCellRendererComponent,
            StatusFilterComponent,
            TypeFilterComponent
        ]),
        OptiModalModule.forRoot(),
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        InputSearchModule,
        DragDropModule,
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
    ],
    declarations: [
        CustomerAttrEditorRef,
        AttributesListComponent,
        BtnCellRendererComponent,
        StatusCellRendererComponent,
        TypeCellRendererComponent,
        AddAttributePopupComponent,
        EditBaseAttributeComponent,
        CalculatedAttributeComponent,
        CustomerAttributeComponent,
        StatusFilterComponent,
        TypeFilterComponent,
        AttributeCalculatorComponent,
        SelectAttributePopupComponent,
        TestFormulaModalComponent,
        ConditionalEditorComponent,
        IfElseConditionComponent,
        NumbersRangePickerComponent,
        AttributesCountTag,
        UseAsPersonalizationTagComponent,
        ConditionalEditorArrayComponent,
        MultipleConditionEditorComponent,
        PurchaseHistoryAttributeComponent,
        PurchaseCalculationSectionComponent,
        OldPurchaseCalculationSectionComponent,
        PurchaseAttributeConditionComponent,
        
    ],
    providers: [
        AttributesManagerService,
        AttributesListService,
        FormulaService,
        FormulaValidatorService,
        ConditionalValidatorService,
        ConditionalAttributeService,
        PurchaseAttributesService,
        AttributesListExportService
    ],
    entryComponents: [
        AttributesListComponent,
        BtnCellRendererComponent,
        StatusCellRendererComponent,
        TypeCellRendererComponent,
        AddAttributePopupComponent,
        EditBaseAttributeComponent,
        CalculatedAttributeComponent,
        CustomerAttributeComponent,
        AttributeCalculatorComponent,
        SelectAttributePopupComponent,
        TestFormulaModalComponent,
        UseAsPersonalizationTagComponent,
        PurchaseHistoryAttributeComponent
    ],
    exports: [
        NumbersRangePickerComponent,
        AttributesCountTag,
    ]
})
export class AttributesModule { }
