import {
	Component,
	Input,
	Output,
	OnInit,
	OnChanges,
	SimpleChanges,
	EventEmitter
} from '@angular/core';
import { forkJoin } from 'rxjs';
import * as Highcharts from 'highcharts';
import { IAnalysisRange } from '../../customer360.models';
import { Customer360DataService } from '../../services/customer360.data.service';
import { CampaignsDetailsPerCustomer } from "../../../../models/customer360/campaignDetailsPerCustomer.model";
import { CampaignKPIsData } from "../../../../models/customer360/campaignKPIsData.model";
import { ColorsService } from '@optimove/ui-sdk/common/services';
import { FormatterPipe } from "../../../../pipes/formatter.pipe";
@Component({
	selector: 'customer-impact-diagrams',
	templateUrl: './customerImpactDiagrams.component.html',
	styleUrls: ['./customerImpactDiagrams.component.scss']
})
export class CustomerImpactDiagramsComponent implements OnInit, OnChanges {

    public Highcharts: typeof Highcharts = Highcharts;

    @Input() clientCustomerId: string;
    @Input() dateRange: IAnalysisRange

    @Output('onDataLoaded') onDataLoadedEvent = new EventEmitter<void>();

    public channelDistributionChartConfiguration: Highcharts.Options;
	public actionsDistributionChartConfiguration: Highcharts.Options;

    public dataMessage: string;
    public dateRangeChanged: boolean;

    public campaignsDetailsPerCustomer: CampaignsDetailsPerCustomer;
	public campaignsKPIsData: CampaignKPIsData;

	public translateKeys = translateKeys;

	constructor(
        private dataService: Customer360DataService,
        private colorsService: ColorsService,
		private formatter: FormatterPipe
	) { }

	ngOnChanges(changes: SimpleChanges): void {
    	if (this.clientCustomerId && this.dateRange?.startDate && this.dateRange?.endDate) {
    		this.loadData();
    	}
	}

	ngOnInit(): void { }

	private loadData() {
    	const promises = {
    		campaignsDetails: this.dataService.getCampaignsDetailsPerCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate),
    		campaignKPIsData: this.dataService.getCampaignKPIsDataByCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate)
    	};

		forkJoin(promises)
			.subscribe((response: { campaignsDetails, campaignKPIsData }) => {
				if (response) {
					this.handleCampaignsDetailsResponse(response.campaignsDetails);
					this.handleCampaignKPIsData(response.campaignKPIsData);
				} else {
					this.dataMessage = 'no_campaigns';
				}
				this.onDataLoadedEvent.emit();
			});
	}

	private handleCampaignsDetailsResponse(res) {
		this.campaignsDetailsPerCustomer = res.Data.CampaignsDetailsPerCustomer;
	}

	private handleCampaignKPIsData(res) {
		if (res.Data && res.Data.CampaignsKPIsData) {
			this.campaignsKPIsData = res.Data.CampaignsKPIsData;
			if (this.campaignsKPIsData.NumOfCampaigns > 0) {
				this.setChannelDistributionChartConfiguration();
				this.setActionDistributionConfiguration();
				this.dataMessage = null;
			} else {
				this.dataMessage = 'no_campaigns';
			}
		}
		this.dateRangeChanged = false;
	}

	private setChannelDistributionChartConfiguration() {
		const formatter = this.formatter;
    	this.channelDistributionChartConfiguration = {
    		chart: {
    			renderTo: 'container',
    			type: 'pie'
    		},
    		credits: {
    			enabled: false
    		},
    		exporting: {
    			enabled: false
    		},
    		legend: {
    			itemMarginTop: 5,
    			itemMarginBottom: 5,
    			itemStyle: {
    				width: 100,
    				fontFamily: 'Roboto',
    				fontSize: '12px',
    				fontWeight: '400',
    				cursor: 'auto'
    			},
    			itemHoverStyle: {
    				cursor: 'auto'
    			},
    			symbolPadding: 10,
    			symbolHeight: 10,
    			symbolWidth: 10,
    			squareSymbol: true,
    			backgroundColor: '#fafafa',
    			align: 'right',
    			verticalAlign: 'bottom',
    			layout: 'vertical'
    		},
    		title: {
    			text: ''
    		},
    		yAxis: {
    			title: {
    				text: 'Total percent market share'
    			}
    		},
    		plotOptions: {
    			series: {
    				point: {
    					events: {
    						legendItemClick: function () {
    							return false; // <== returning false will cancel the default action
    						}
    					}
    				}
    			},
    			pie: {
    				shadow: false,
    				borderWidth: 1,
    				borderColor: null
    			}
    		},
    		tooltip: {
    			borderWidth: 0,
				formatter: function () {
					const text = formatter.transform(this.percentage / 100, '0%', { shouldHandleSmallNumbers: true });
					return '<b>' + this.point.name + '</b><br/>' + text + ' [' + (this.y) + ']';
				}
    		},
    		series: [{
    			type: null,
    			name: 'Browsers',
    			data: this.getChannelDistributionArray(),
    			colors: this.buildChannelDistributionColorsPalette(),
    			size: '80%',
    			innerSize: '70%',
    			showInLegend: true,
    			dataLabels: {
    				enabled: false
    			}
    		}]
    	};
	}

	private getChannelDistributionArray() {
		const channelDistributionArray = [];
		const channelsDic = this.campaignsDetailsPerCustomer.ChannelsDic;
		if (this.campaignsDetailsPerCustomer && channelsDic) {
			for (let key in channelsDic) {
				if (channelsDic.hasOwnProperty(key)) {
					channelDistributionArray.push([key, channelsDic[key]]);
				}
			}
		}
		return channelDistributionArray;
	}

	private buildChannelDistributionColorsPalette() {
		const colorPalette = [];
		for (let i = 1; i < 9; i++) {
			colorPalette.push(this.colorsService.getColorConstants('brand-action-order-' + i));
		}

		return colorPalette;
	}

	private setActionDistributionConfiguration() {
		const actionDistributionObject = this.getActionDistributionObject();
		const categories = actionDistributionObject.categories;
		const data = actionDistributionObject.data;
		const maxValue = actionDistributionObject.maxValue + 0.1;

		const formatter = this.formatter;
		this.actionsDistributionChartConfiguration = {
			tooltip: {
				useHTML: true,
				padding: 0,
				borderWidth: 0,
				backgroundColor: 'white',
				formatter: function () {
					const text = formatter.transform(this.y, '0%', { shouldHandleSmallNumbers: true });
					return '</br>&ensp;<b>' + this.x + '</b>&ensp;<br/>&ensp;' + text + '&ensp;<br/><div>&ensp;</div>';
				}
			},
			chart: {
				type: 'bar',
				marginLeft: 100
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			title: {
				text: ''
			},
			xAxis: {
				categories: categories,
				labels: {
					formatter: function () {
						const text = this.value.toString();
						const formatted = text.length > 12 ? ' ' + text.substring(0, 12) + '...' : ' ' + text;

						return '<div class="js-ellipse" title="' + text + '" style="width:85px; overflow:hidden">' + formatted + '</div>';
					},
					style: {
						width: 150,
						left: '5px'
					},
					useHTML: true
				}
			},
			yAxis: {
				labels: {
					formatter: function () {
						return this.value * 100 + '%';
					},
					useHTML: true
				},
				min: 0,
				max: maxValue > 1 ? 1 : maxValue,
				title: {
					text: null
				}
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					//stacking: 'normal',
					color: this.colorsService.getColorConstants('brand-sky'),
					states: {
						hover: {
							color: this.colorsService.getColorConstants('brand-sky-plus1')
						}
					}
				}
			},
			series: [{
				type: null,
				data: data
			}]
		};
	}

	private getActionDistributionObject() {
		const channelDistribution = {
			categories: [],
			data: [],
			maxValue: 0
		};
		if (this.campaignsDetailsPerCustomer && this.campaignsDetailsPerCustomer.ActionsDic) {
			for (let key in this.campaignsDetailsPerCustomer.ActionsDic) {
				if (this.campaignsDetailsPerCustomer.ActionsDic.hasOwnProperty(key)) {
					const value = this.campaignsDetailsPerCustomer.ActionsDic[key];
					channelDistribution.categories.push(key);
					channelDistribution.data.push(value);
					if (value > channelDistribution.maxValue) {
						channelDistribution.maxValue = value;
					}
				}
			}
		}
		return channelDistribution;
	}
}

const translateKeys = {
	channelDistributionChartHeader: 'features.customer360.body.customer_activity_trend.trend.CHANNEL_DISTRIBUTION',
	actionsDistributionChartHeader: 'features.customer360.body.customer_activity_trend.trend.ACTIONS_DISTRIBUTION',
	noCampaignsFoundTitle: 'features.customer360.body.data_messages.no_campaigns.TITLE',
	noCampaignsFoundDescription: 'features.customer360.body.data_messages.no_campaigns.DESCRIPTION'
}