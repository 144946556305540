<ng-template #popTemplate class="popover">
    <div style="margin-top: 4px;" class="popover-row">
        <span>{{ translateKeys.totalAttributes | translate }}</span>
        <span style="font-weight: 700;">{{attributes.length}}</span>
    </div>
    <hr>
    <div *ngFor="let attrType of attributesTypesInfo | keyvalue">
        <div *ngIf="attrType.value.count > 0" class="popover-row">
            <span>{{attrType.value.title}}</span>
            <span style="font-weight: 700;">{{attrType.value.count}}</span>
        </div>
    </div>
</ng-template>
<colored-tag 
    class="attribute-count" 
    [popover]="popTemplate" 
    [outsideClick]="true"
    placement="bottom left"
    [coloredTagType]="attributesCountTag.type">
        {{attributes.length}}
        {{attributesCountTag.description}}
        <div class="arrow-down"></div>
</colored-tag>