import { Injectable } from "@angular/core";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";
import { IUploadCsvFileResponse } from "../models/iUploadCsvFileResponse.model";
import { ICreateNewUniquePromoCodeResults } from "../models/icreateNewUniquePromoCodeResults.modal";
import { IUniquePromoCode } from "../models/iuniquePromoCode.model";
import { IUniquePromoCodesLobby } from "../models/iuniquePromoCodesLobby.model";

@Injectable()
export class UniquePromoCodesService {
    
    private readonly baseUrl = '/UniquePromoCodes';
    
    constructor(private readonly httpClient: HttpService){ }

    public getUniquePromoCodesList() : Observable<IUniquePromoCodesLobby[]> {
        return this.httpClient.get<AjaxResponse<IUniquePromoCodesLobby[]>>(`${this.baseUrl}/GetAllUniquePromoCodes`, {}).pipe(
            map((res) => 
            {
                return res.data;
            })
        )
    }

    public getUniquePromoCodeByName(promotionName) : Observable<IUniquePromoCode> {
        const params = HttpService.toHttpParams({ promotionName: promotionName });
        return this.httpClient.get<AjaxResponse<IUniquePromoCode>>(`${this.baseUrl}/GetPromotionByName`, params).pipe(
            map((res) => 
            {
                return res.data;
            })
        )
    }

    public getUniquePromoCodeById(promotionId): Observable<IUniquePromoCode> {
        const params = HttpService.toHttpParams({ promotionId: promotionId });
        return this.httpClient.get<AjaxResponse<IUniquePromoCode>>(`${this.baseUrl}/GetPromotionById`, params).pipe(
            map((res) => {
                return res.data;
            })
        )
    }

    public AddUniquePromoCode(createNewUniquePromoCode: IUniquePromoCode) : Observable<ICreateNewUniquePromoCodeResults>{
        return this.httpClient.post<AjaxResponse<ICreateNewUniquePromoCodeResults>>(`${this.baseUrl}/CreateNewPromotion`, createNewUniquePromoCode).pipe(
            map((res) => {
                return res.data;
            })
        )
    }

    public UpdateUniquePromoCode(updatedUniquePromoCode: IUniquePromoCode) : Observable<ICreateNewUniquePromoCodeResults>{
        return this.httpClient.post<AjaxResponse<ICreateNewUniquePromoCodeResults>>(`${this.baseUrl}/UpdatePromotion`, updatedUniquePromoCode).pipe(
            map((res) => {
                return res.data;
            })
        )
    }

    public uploadCsvFile(formData: FormData): Observable<IUploadCsvFileResponse> {

        return this.httpClient.post<AjaxResponse<IUploadCsvFileResponse>>(`${this.baseUrl}/UploadCsvFile`, formData, {
            headers: {}
        }).pipe(
            map((res) => {
                return res;
            })
        )
    }

    public getUniquePromoCodeByNameMock(promotionName: string) : IUniquePromoCode{
        var mockResult = {
            promotionName: promotionName,
            defaultPromotionCode: "default",
            email: "email",
            threshold: 3,
            status: "Available",
            remaining: 5000,
            total: 10000,
            used: 5000,
        } as IUniquePromoCode;
        return mockResult;
    }
}
