<div class="optimail-editor-container" [ngClass]="{'optimail-editor-expanded': isFullScreen}">
  <editor-header class="editor-header" [isFullscreen]="isFullScreen" (templateSaved)="onTemplateSaved($event)"></editor-header>
  <editor-body (fullScreenActivate)="isFullScreen = $event" class="editor-body"></editor-body>
  <div>
    <toast
      [(isOpen)]="toastParams.isOpen"
      [icon]="toastParams.icon"
      [location]="toastParams.location"
      [message]="toastParams.message"
      [showCloseIcon]="toastParams.showCloseIcon"
      [toastIconType]="toastParams.toastIconType"></toast>
  </div>
</div>
