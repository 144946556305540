
<form class="form-inline form " *ngIf="form" [formGroup]="form">
    <div class="bordered-container">
        <div class="condition-header-container value-input">
            <label class="if-else-text">
                <strong *ngIf="config?.priority > 1">{{translateKeys.elseLbl | translate}} </strong>
                <strong>{{translateKeys.ifLbl | translate}} </strong> 
                {{translateKeys.conditionsMet | translate}} 
            </label> 
            <input data-qa-id="MeetValueInput" 
                        formControlName="value"
                        type="text" 
                        (change)="onValueChange()"
                        class="text-input"
                        [ngClass]="{'red-border': value.invalid && value.touched}"
                        placeholder="{{translateKeys.value | translate}}" >
            <span class="multiple-options-menu-container"  >
                <button #toggleButton [disabled]="config.isDisabled" type="button" class="btn btn-primary menu-button" 
                    [ngClass]="{'menu-open': isMenuOpen}" (click)="onMenuButtonClicked()">
                    <i #toggleButtonIcon class="material-icons" >more_vert</i>
                </button>
                <div class="menu" *ngIf="isMenuOpen" #menu >
                    <ul>
                        <li data-qa-id="duplicateConditionBtn" tabindex="0" (click)="duplicateEditor()" [ngClass]="{'disabled': !isDuplicateEnable}">
                            <i class="material-icons">content_copy</i>
                            <span>Duplicate condition</span>
                        </li>
                        <li data-qa-id="deleteConditionBtn" tabindex="0" (click)="delete.emit()" >
                            <i class="material-icons">delete_forever</i>
                            <span>Delete condition</span>
                        </li>
                    </ul>
                </div>
            </span>
        </div> 
        <div class="section" style="padding-bottom: 18px">
            <div class="condition-headers">
            </div>
            <div class="conditions" cdkDropList (cdkDropListDropped)="onDrop($event)" formArrayName="conditions">
                <div class="condition" *ngFor="let condition of conditions.controls index as i;" cdkDrag>
                    <div class="start-container">
                        <div class="condition-handle" cdkDragHandle>
                            <i class="material-icons">drag_handle</i>
                        </div>
                        <div class="condition-number">
                            {{ i + 1 }}    
                        </div>
                    </div>
                    <div class="condition-inputs">
                        <if-else-condition 
                        [formControlName]="i"
                        #condition
                        name="condition"
                        [isAddMode]="isAddMode"
                        [disabled]="config.isDisabled"
                        [Position]="i + 1"
                        [attributesSearchItems]="attributesSearchItems"
                        (changeEmitter)="onConditionChange($event, i)">
                        </if-else-condition>
                    </div>
                    <button data-qa-id="DeleteCondition" class="condition-remove btn btn-link btn-link-default" type="button" (click)="onRemoveCondition(i+1)" [disabled]="conditions.length <= 1 || config.isDisabled">
                        <i class="material-icons">delete_forever</i>
                    </button>
                </div>
            </div>
            <div class="add-condition">
                <button data-qa-id="AddCondition" class="add-template btn btn-link" type="button" (click)="onAddCondition()" [disabled]="isAddButtonDisabled || config.isDisabled">
                    {{ translateKeys.addCondition | translate }}
                </button>
            </div>
            <div class="checkbox checkbox-primary">
                <input [disabled]="conditions.controls.length == 1 || config.isDisabled" data-qa-id="ComplexSelectionCheckBox" 
                        class="checkbox-input" 
                        [defaultChecked]="isComplexSelection"  
                        [(ngModel)]="isComplexSelection"
                        [ngModelOptions]="{standalone: true}"
                        type="checkbox" 
                        (change)="onCheckBoxChange()">
                <label class="complex-selection-label">
                    {{ translateKeys.complexSelection | translate }}
                </label>
                <textarea data-qa-id="ComplexSelectionTextArea"
                            formControlName="complexSelection"
                            *ngIf="isComplexSelection" 
                            type="text" 
                            class="text-input text-area"
                            (change)="onComplesSelectionChange()"
                            [ngClass]="{'red-border': complexSelection.invalid}"
                            placeholder="{{translateKeys.optional | translate}}">
                </textarea>
            </div>
            <span *ngIf="complexSelection.invalid && isComplexSelection" class="error-msg">
                {{translateKeys.complexSelectionErrMsg | translate}}
            </span>
        </div>
    </div>
</form>

