import { AgGridModule } from '@ag-grid-community/angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import {
	CampaignStatusModule,
	CampaignStatusRectComponent,
	CampaignStatusService,
	CampaignStatusTagComponent
} from '@optimove/ui-sdk/components/campaign-status-tag';
import { ChannelIconService, ChannelWidgetBarModule } from '@optimove/ui-sdk/components/channel-widget-bar';
import { ColoredTagModule } from '@optimove/ui-sdk/components/colored-tag';
import { HighChartsModule } from '@optimove/ui-sdk/components/high-charts';
import { OptiSelectComponent, OptiSelectModule } from '@optimove/ui-sdk/components/opti-select';
import { AngularSplitModule } from 'angular-split';
import { NouisliderModule } from 'ng2-nouislider';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DirectivesModule } from '../directives/directives.module';
import { MoveTgsToStreamSaveModalComponent } from '../features/manageTargetGroups/components/moveTgsToStreamSaveModal/moveTgsToStreamSaveModal.component';
import { PriorityListComponent } from '../features/manageTargetGroups/components/priorityList/priorityList.component';
import { PriorityListItemComponent } from '../features/manageTargetGroups/components/priorityList/priorityListItem.component';
import { OptiModalModule } from '../modules/OptiModal.module';
import { PipesModule } from '../pipes/pipes.module';
import { CogwheelFeatureFlagService } from '../services/cogwheelFeatureFlag.service';
import { FeatureFlagService } from '../services/featureFlag.service';
import { PendoEventsService } from '../services/pendoEvents.service';
import { SsmService } from '../services/ssm.service';
import { AboutOptimoveComponent } from './aboutModal/about.component';
import { AcademyLinksComponent } from './academyLinks/academyLinks.component';
import { AccordionWizardHeaderComponent } from './accordionWizardHeader/accordionWizardHeader.component';
import { AlertComponent } from './alert/alert.component';
import { TemplateChannelSummaryComponent } from './campaign-summary/template-channel-summary/template-channel-summary.component';
import { TemplateSummaryComponent } from './campaign-summary/template-summary/template-summary.component';
import { CampaignAnalysisTableComponent } from './campaignAnalysisTable/campaign-analysis-table.component';
import { CampaignKPITableComponent } from './campaignKPITable/campaignKPITable.component';
import { ChannelGroupTypeComponent } from './channelGroupType/channelGroupType.component';
import { CheckboxToggleButtonComponent } from './checkboxToggleButton/checkboxToggleButton.component';
import { ChipsComponent } from './chips/chips.component';
import { ChipsRadioGroupComponent } from './chipsRadioGroup/chipsRadioGroup.component';
import { ClickLoaderComponent } from './clickLoader/clickLoader.component';
import { ConditionalLanguageService } from './conditionalLanguageContainer/conditionalLanguage.service';
import { ConditionalLanguageContainerComponent } from './conditionalLanguageContainer/conditionalLanguageContainer.component';
import { ConditionalLanguageModalComponent } from './conditionalLanguageContainer/conditionalLanguageModal/conditionalLanguageModal.component';
import { DatePickerComponent } from './datePicker/datePicker.component';
import { MaxDateValidatorDirective } from './datePicker/validators/maxDateValidator.directive';
import { MinDateValidatorDirective } from './datePicker/validators/minDateValidator.directive';
import { ValidDateValidatorDirective } from './datePicker/validators/validDateValidator.directive';
import { DateRangePickerComponent } from './dateRangePicker/dateRangePicker.component';
import { DateRangeMaxDateValidatorDirective } from './dateRangePicker/validators/maxDateValidator.directive';
import { DateRangeMinDateValidatorDirective } from './dateRangePicker/validators/minDateValidator.directive';
import { ValidDatesValidatorDirective } from './dateRangePicker/validators/validateDatesValidators.diretive';
import { ValidateFirstDateSmallerThanSecondValidator } from './dateRangePicker/validators/validateFirstDateSmallerThanSecondValidator.directive';
import { DateRangePickerInlineComponent } from './dateRangePickerInline/dateRangePickerInline.component';
import { DetailsCustomHeaderComponent } from './detailsCustomHeader/detailsCustomHeader.component';
import { EmptyStateComponent } from './emptyState/emptyState.component';
import { InlineSelectComponent } from './inlineSelect/inlineSelect.component';
import { InputEditComponent, LengthFieldValidatorDirective, SpacesFieldValidatorDirective } from './inputEdit/inputEdit.component';
import { ItemSelectorModal } from './itemSelectorModal/itemSelectorModal.component';
import { JsTreeComponent } from './jsTree/jsTree.component';
import { JsTreeDataHelperService } from './jsTree/jsTree.dataHelper.service';
import { JsTreeActionsMenuComponent } from './jsTree/jsTreeActionsMenu/jsTreeActionsMenu.component';
import { JsTreeContextMenuComponent } from './jsTree/jsTreeContextMenu/jsTreeContextMenu.component';
import { JsTreeDotMenuComponent } from './jsTree/jsTreeDotMenu/jsTreeDotMenu.component';
import { JsTreeMenuComponent } from './jsTree/jsTreeMenu/jsTreeMenu.component';
import { LabelComponent } from './label/label.component';
import { LastModifiedStampComponent } from './lastModifiedStamp/lastModifiedStamp.component';
import { LoadingButtonComponent } from './loadingButton/loadingButton.component';
import { LoadingIconButtonComponent } from './loadingIconButton/loadingIconButton.component';
import { LoadingTextComponent } from './loadingText/loadingText.component';
import { AreaSplineChartComponent } from './miniDashboardWidgets/widgets/areaSplineChart/areaSplineChart.component';
import { ChannelWidgetComponent } from './miniDashboardWidgets/widgets/channelWidget/channelWidget.component';
import { ChannelWidgetPieComponent } from './miniDashboardWidgets/widgets/channelWidget/components/channelWidgetPie/channelWidgetPie.component';
import { CoverageWidgetComponent } from './miniDashboardWidgets/widgets/coverageWidget/coverageWidget.component';
import {
	DirectIncreaseWidgetComponent
} from './miniDashboardWidgets/widgets/directIncreaseWidget/directIncreaseWidget.component';
import { FunnelWidgetComponent } from './miniDashboardWidgets/widgets/funnelWidget/funnelWidget.component';
import { IncreaseWidgetComponent } from './miniDashboardWidgets/widgets/increaseWidget/increaseWidget.component';
import { NumberOfCustomersWidgetComponent } from './miniDashboardWidgets/widgets/numberOfCustomersWidget/numberOfCustomersWidget.component';
import { OpenAndClickRateWidgetComponent } from './miniDashboardWidgets/widgets/openAndClickRateWidget/openAndClickRateWidget.component';
import { OtherChannelMetricsWidgetComponent } from './miniDashboardWidgets/widgets/otherChannelMetricsWidget/otherChannelMetricsWidget.component';
import { PieChartComponent } from './miniDashboardWidgets/widgets/pieChart/pieChart.component';
import { ResponseRateWidgetComponent } from './miniDashboardWidgets/widgets/responseRateWidget/responseRateWidget.component';
import { SplineChartComponent } from './miniDashboardWidgets/widgets/splineWidget/splineChart.component';
import { WidgetErrorStateComponent } from './miniDashboardWidgets/widgets/widgetErrorState/widgetErrorState.component';
import { MultipleOptionsButtonComponent } from './multipleOptionsButton/multipleOptionsButton.component';
import { NotesAndTagsFormComponent } from './notes-and-tags-form/notesAndTagsForm.component';
import { AddingTagComponent } from './notesAndTagsButton/modal/addingTagComponent/addingTag.component';
import { HashtagComponent } from './notesAndTagsButton/modal/hashtagComponent/hashtag.component';
import { NotesAndTagsButtonModalComponent } from './notesAndTagsButton/modal/notesAndTagsModal.component';
import { NotesAndTagsButtonComponent } from './notesAndTagsButton/notesAndTagsButton.component';
import { OptiAccordionGroupModule } from './optiAccordionGroup/optiAccordionGroup.module';
import { OptiCardComponent } from './optiCard/optiCard.component';
import { OptiLoadingComponent } from './optiLoading/optiLoading.component';
import { OptiMetricComponent } from './optiMetric/optiMetric.component';
import { OptiPopoverComponent } from './optiPopover/optiPopover.component';
import { OptiSearchListComponent } from './optiSearchList/optiSearchListComponent/optiSearchList.component';
import { OslCellComponent } from './optiSearchList/oslCellComponent/oslCell.component';
import { OslFooterComponent } from './optiSearchList/oslFooterComponent/oslFooter.component';
import { OslGroupComponent } from './optiSearchList/oslGroupComponent/oslGroup.component';
import { OslHeaderComponent } from './optiSearchList/oslHeaderComponent/oslHeader.component';
import { OslInputComponent } from './optiSearchList/oslInputComponent/oslInput.component';
import { OslItemComponent } from './optiSearchList/oslItemComponent/oslItem.component';
import { RobotoFontSizesService } from './optiSearchList/services/robotoFontSizes.service';
import { RowCalculationService } from './optiSearchList/services/rowCalculation.service';
import { SearchListUtilsService } from './optiSearchList/services/searchlistUtils.service';
import { OptiSearchListDropDownComponent } from './optiSearchListDropDown/optiSearchListDropDown.component';
import { OptiSearchListTreeViewComponent } from './optiSearchListTreeView/optiSearchListTreeView.component';
import { OptiSelectFlexComponent } from './optiSelectFlex/optiSelectFlex.component';
import { OptiSelectTreeViewComponent } from './optiSelectTreeView/optiSelectTreeView.component';
import { OverflowMenuComponent } from './overflowMenu/overflowMenu.component';
import { OverflowMenuItemComponent } from './overflowMenu/overflowMenuItem/overflowMenuItem.component';
import { PanelWithAccordionSidebar } from './panelWithAccordionSidebar/panelWithAccordionSidebar.component';
import { PercentageInputComponent } from './percentageInput/percentageInput.component';
import { ValidPercentageValidatorDirective } from './percentageInput/validators/validPercentageValidator.directive';
import { PersonalizationTagsService } from './personalizationTagsContainer/personalizationTags.service';
import { PersonalizationTagsContainerComponent } from './personalizationTagsContainer/personalizationTagsContainer.component';
import { PersonalizationTagsModalComponent } from './personalizationTagsContainer/personalizationTagsModal/personalizationTagsModal.component';
import { RangeInputComponent } from './rangeInput/rangeInput.component';
import { RecurFormComponent } from './recurForm/recurForm.component';
import { RecurrenceFormComponent } from './recurrenceForm/recurrenceForm.component';
import { RowTableComponent } from './rowTable/rowTable.component';
import { SelectBtnComponent } from './selectBtn/selectBtn.component';
import { ShowGroupSizeComponent } from './showGroupSize/showGroupSize.component';
import { ShowGroupSizeService } from './showGroupSize/showGroupSize.service';
import { SideBarComponent } from './sideBar/sideBar.component';
import { SliderComponent } from './slider/slider.component';
import { SliderItemInputComponent } from './sliderMultiValues/sliderItemInput/sliderItemInput.component';
import { SliderMultiValuesComponent } from './sliderMultiValues/sliderMultiValues.component';
import { StickyTableComponent } from './stickyTable/stickyTable.component';
import { StreamsListItemComponent } from './streamsListItem/StreamsListItem.component';
import { SwitchComponent } from './switchButton/switch.component';
import { HorizontalTabsComponent } from './tabs/horizontalTabs/horizontalTabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TabBodyComponent } from './tabs/tabBody/tabBody.component';
import { TabHeaderComponent } from './tabs/tabHeader/tabHeader.component';
import { VerticalTabsComponent } from './tabs/verticalTabs/verticalTabs.component';
import { TagifyComponent } from './tagify/tagify.component';
import { TagifyService } from './tagify/tagify.service';
import { MinMaxTagsValidatorDirective } from './tagify/validators/min-max-tags-validator.directive';
import { TemplateModalContainerComponent } from './templatePreviewComponents/templateModalContainer/templateModalContainer.component';
import { TemplateNotAvailableComponent } from './templatePreviewComponents/templateNotAvailable/templateNotAvailable.component';
import { PopoverImageComponent } from './templatePreviewComponents/templatePreviewPopover/popoverImage/popoverImage.component';
import { TemplatePreviewPopoverComponent } from './templatePreviewComponents/templatePreviewPopover/templatePreviewPopover.component';
import { TemplatesGridViewModalComponent } from './templatePreviewComponents/templatesGridViewModal/templatesGridViewModal.component';
import { TemplatesPreviewCarouselComponent } from './templatePreviewComponents/templatesPreviewCarousel/templatesPreviewCarousel.component';
import { TemplatesPreviewCarouselModalComponent } from './templatePreviewComponents/templatesPreviewCarouselModal/templatesPreviewCarouselModal.component';
import { TimePickerFormComponent } from './timePicker/timePickerForm.component';
import { TimeRangeComponent } from './timeRange/timeRange.component';
import { MinTimeRangeValidatorDirective } from './timeRange/validators/minTimeRangeValidator.directive';
import { TimeRangeRequiredValidatorDirective } from './timeRange/validators/timeRangeRequiredValidator.directive';
import { ToastComponent } from './toast/toast.component';
import { ToggleButtonComponent } from './toggleButtons/toggleButtons.component';
import { TreeViewComponent } from './treeViewModal/treeView/treeView.component';
import { TreeViewModalComponent } from './treeViewModal/treeViewModal.component';
import { editTargetGroupConditionsWrapper } from './upgrade/editTargetGroupConditionsWrapper.component';
import { HorizontalGaugeComponent } from './upgrade/horizontalGauge.component';
import { optiCsvExporterComponent } from './upgrade/optiCsvExporter.component';
import { OptiSearchListBackupComponent } from './upgrade/optiSearchListBackup.component';
import { OptiUiGridComponent } from './upgrade/optiUigrid.component';

function getGtmService(i) {
	return i.get('gtmDataLayerService');
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		TranslateModule,
		DirectivesModule,
		PipesModule,
		PopoverModule,
		TooltipModule.forRoot(),
		ButtonsModule.forRoot(),
		BsDatepickerModule,
		AgGridModule.withComponents([
			OptiSearchListComponent,
			OslHeaderComponent,
			OslCellComponent
		]),
		OptiModalModule.forRoot(),
		TreeModule,
		TypeaheadModule.forRoot(),
		NouisliderModule,
		AngularSplitModule,
		ScrollingModule,
		OverlayModule,
		OptiSelectModule,
		ColoredTagModule,
		HighChartsModule,
		ChannelWidgetBarModule,
		CampaignStatusModule
	],
	providers: [
		{
			provide: 'gtmDataLayerService',
			useFactory: getGtmService,
			deps: ['$injector']
		},
		{
			provide: '$scope',
			useFactory: i => i.get('$rootScope'),
			deps: ['$injector']
		},
		{
			provide: '$rootScope',
			useFactory: i => i.get('$rootScope'),
			deps: ['$injector']
		},
		{
			provide: 'manageTargetGroupsDataService',
			useFactory: i => i.get('manageTargetGroupsDataService'),
			deps: ['$injector']
		},
		{
			provide: 'executionChannelsPriorityService',
			useFactory: i => i.get('executionChannelsPriority'),
			deps: ['$injector']
		},
		{
			provide: 'signalrService',
			useFactory: i => i.get('signalrService'),
			deps: ['$injector']
		},
		{
			provide: 'optiModalService',
			useFactory: i => i.get('optiModalService'),
			deps: ['$injector']
		},
		{
			provide: 'optibotNotificationSystemService',
			useFactory: i => i.get('optibotNotificationSystemService'),
			deps: ['$injector']
		},
		{
			provide: 'alertsService',
			useFactory: i => i.get('alertsService'),
			deps: ['$injector']
		},
		SsmService,
		ShowGroupSizeService,
		RobotoFontSizesService,
		RowCalculationService,
		SearchListUtilsService,
		JsTreeDataHelperService,
		PersonalizationTagsService,
		ConditionalLanguageService,
		PendoEventsService,
		BsModalRef,
		RecurrenceFormComponent,
		FeatureFlagService,
		DatePipe,
		CogwheelFeatureFlagService,
		TagifyService,
		ChannelIconService,
		CampaignStatusService
	],
	declarations: [
		OptiSearchListComponent,
		OptiSearchListBackupComponent,
		HorizontalTabsComponent,
		VerticalTabsComponent,
		EmptyStateComponent,
		SideBarComponent,
		LoadingButtonComponent,
		LoadingIconButtonComponent,
		HorizontalGaugeComponent,
		AcademyLinksComponent,
		InputEditComponent,
		SelectBtnComponent,
		AccordionWizardHeaderComponent,
		ClickLoaderComponent,
		DateRangePickerComponent,
		DateRangePickerInlineComponent,
		DatePickerComponent,
		ValidDateValidatorDirective,
		ValidDatesValidatorDirective,
		ValidateFirstDateSmallerThanSecondValidator,
		MinDateValidatorDirective,
		MaxDateValidatorDirective,
		DateRangeMinDateValidatorDirective,
		DateRangeMaxDateValidatorDirective,
		optiCsvExporterComponent,
		StickyTableComponent,
		RowTableComponent,
		StreamsListItemComponent,
		OptiUiGridComponent,
		OptiLoadingComponent,
		OptiMetricComponent,
		SideBarComponent,
		OslHeaderComponent,
		OslItemComponent,
		OslCellComponent,
		OslInputComponent,
		OslFooterComponent,
		SliderMultiValuesComponent,
		SliderItemInputComponent,
		ShowGroupSizeComponent,
		RecurFormComponent,
		LoadingButtonComponent,
		JsTreeComponent,
		PriorityListComponent,
		PriorityListItemComponent,
		PanelWithAccordionSidebar,
		LastModifiedStampComponent,
		SpacesFieldValidatorDirective,
		LengthFieldValidatorDirective,
		ChipsComponent,
		NotesAndTagsButtonComponent,
		NotesAndTagsButtonModalComponent,
		HashtagComponent,
		AddingTagComponent,
		CampaignAnalysisTableComponent,
		AboutOptimoveComponent,
		SliderComponent,
		SwitchComponent,
		PersonalizationTagsContainerComponent,
		PersonalizationTagsModalComponent,
		ConditionalLanguageContainerComponent,
		ConditionalLanguageModalComponent,
		JsTreeMenuComponent,
		JsTreeDotMenuComponent,
		JsTreeContextMenuComponent,
		JsTreeActionsMenuComponent,
		OslGroupComponent,
		OptiSearchListDropDownComponent,
		OptiSearchListTreeViewComponent,
		OptiSelectTreeViewComponent,
		TreeViewModalComponent,
		ChipsRadioGroupComponent,
		TemplateNotAvailableComponent,
		TemplatesPreviewCarouselComponent,
		TemplatesGridViewModalComponent,
		TemplatesPreviewCarouselModalComponent,
		TemplateModalContainerComponent,
		TemplatePreviewPopoverComponent,
		PopoverImageComponent,
		RecurrenceFormComponent,
		MultipleOptionsButtonComponent,
		AlertComponent,
		TimePickerFormComponent,
		MoveTgsToStreamSaveModalComponent,
		OverflowMenuComponent,
		OverflowMenuItemComponent,
		ChannelGroupTypeComponent,
		CampaignKPITableComponent,
		RangeInputComponent,
		PercentageInputComponent,
		ValidPercentageValidatorDirective,
		TimeRangeComponent,
		MinTimeRangeValidatorDirective,
		TimeRangeRequiredValidatorDirective,
		editTargetGroupConditionsWrapper,
		TimeRangeRequiredValidatorDirective,
		TabHeaderComponent,
		TabBodyComponent,
		TabComponent,
		TreeViewComponent,
		InlineSelectComponent,
		OptiSelectFlexComponent,
		CheckboxToggleButtonComponent,
		ToastComponent,
		TagifyComponent,
		MinMaxTagsValidatorDirective,
		LoadingTextComponent,
		LabelComponent,
		OptiPopoverComponent,
		ItemSelectorModal,
		IncreaseWidgetComponent,
		NotesAndTagsFormComponent,
		ChannelWidgetComponent,
		ChannelWidgetPieComponent,
		ResponseRateWidgetComponent,
		CoverageWidgetComponent,
		NumberOfCustomersWidgetComponent,
		OptiCardComponent,
		WidgetErrorStateComponent,
		SplineChartComponent,
		FunnelWidgetComponent,
		OtherChannelMetricsWidgetComponent,
		AreaSplineChartComponent,
		OpenAndClickRateWidgetComponent,
		ToggleButtonComponent,
		DirectIncreaseWidgetComponent,
		PieChartComponent,
		DetailsCustomHeaderComponent,
		TemplateChannelSummaryComponent,
		TemplateSummaryComponent
	],
    exports: [
        OptiPopoverComponent,
        OptiSearchListBackupComponent,
        OptiSearchListComponent,
        HorizontalGaugeComponent,
        AcademyLinksComponent,
        InputEditComponent,
        DirectivesModule,
        SelectBtnComponent,
        AccordionWizardHeaderComponent,
        TranslateModule,
        DateRangePickerComponent,
        DateRangePickerInlineComponent,
        DatePickerComponent,
        optiCsvExporterComponent,
        StickyTableComponent,
        RowTableComponent,
        StreamsListItemComponent,
        OptiUiGridComponent,
        OptiLoadingComponent,
        ClickLoaderComponent,
        EmptyStateComponent,
        OptiMetricComponent,
        SideBarComponent,
        RecurFormComponent,
        ShowGroupSizeComponent,
        SliderMultiValuesComponent,
        LoadingButtonComponent,
        LoadingIconButtonComponent,
        JsTreeComponent,
        JsTreeDotMenuComponent,
        JsTreeActionsMenuComponent,
        PriorityListComponent,
        PriorityListItemComponent,
        PanelWithAccordionSidebar,
        LastModifiedStampComponent,
        SpacesFieldValidatorDirective,
        LengthFieldValidatorDirective,
        ChipsComponent,
        NotesAndTagsButtonComponent,
        NotesAndTagsButtonModalComponent,
        HashtagComponent,
        AddingTagComponent,
        CampaignAnalysisTableComponent,
        AboutOptimoveComponent,
        SliderComponent,
        SwitchComponent,
        PersonalizationTagsContainerComponent,
        PersonalizationTagsModalComponent,
        ConditionalLanguageContainerComponent,
        ConditionalLanguageModalComponent,
        OptiSearchListDropDownComponent,
        OptiSearchListTreeViewComponent,
        OptiSelectTreeViewComponent,
        ChipsRadioGroupComponent,
        TemplateNotAvailableComponent,
        TemplatesPreviewCarouselComponent,
        TemplateModalContainerComponent,
        TemplatePreviewPopoverComponent,
        PopoverImageComponent,
        TreeViewModalComponent,
        RecurrenceFormComponent,
        MultipleOptionsButtonComponent,
        AlertComponent,
        TimePickerFormComponent,
        MoveTgsToStreamSaveModalComponent,
        OverflowMenuComponent,
        OverflowMenuItemComponent,
        ChannelGroupTypeComponent,
        CampaignKPITableComponent,
        RangeInputComponent,
        PercentageInputComponent,
        ValidPercentageValidatorDirective,
        TimeRangeComponent,
        MinTimeRangeValidatorDirective,
        TimeRangeRequiredValidatorDirective,
        editTargetGroupConditionsWrapper,
        TimeRangeRequiredValidatorDirective,
        TemplatesGridViewModalComponent,
        HorizontalTabsComponent,
        VerticalTabsComponent,
        TabHeaderComponent,
        TabBodyComponent,
        TabComponent,
        TreeViewComponent,
        InlineSelectComponent,
        OptiSelectFlexComponent,
        CheckboxToggleButtonComponent,
        ToastComponent,
        TagifyComponent,
        LoadingTextComponent,
        OptiAccordionGroupModule,
        LabelComponent,
        ItemSelectorModal,
        IncreaseWidgetComponent,
        ResponseRateWidgetComponent,
        ChannelWidgetComponent,
        NotesAndTagsFormComponent,
        CoverageWidgetComponent,
        NumberOfCustomersWidgetComponent,
        OptiCardComponent,
        WidgetErrorStateComponent,
        OptiSelectComponent,
        ColoredTagModule,
        SplineChartComponent,
        HighChartsModule,
        FunnelWidgetComponent,
        OtherChannelMetricsWidgetComponent,
        AreaSplineChartComponent,
        OpenAndClickRateWidgetComponent,
        ToggleButtonComponent,
        DirectIncreaseWidgetComponent,
        PieChartComponent,
        DetailsCustomHeaderComponent,
        TemplateChannelSummaryComponent,
        TemplateSummaryComponent,
        CampaignStatusTagComponent,
        CampaignStatusRectComponent,
        MaxDateValidatorDirective
    ],
	entryComponents: [
		ClickLoaderComponent,
		SelectBtnComponent,
		NotesAndTagsButtonComponent,
		NotesAndTagsButtonModalComponent,
		HashtagComponent,
		AddingTagComponent,
		AboutOptimoveComponent,
		DateRangePickerComponent,
		DateRangePickerInlineComponent,
		DatePickerComponent,
		SliderComponent,
		SwitchComponent,
		PersonalizationTagsContainerComponent,
		PersonalizationTagsModalComponent,
		ConditionalLanguageContainerComponent,
		ConditionalLanguageModalComponent,
		JsTreeComponent,
		OptiSearchListDropDownComponent,
		PanelWithAccordionSidebar,
		PriorityListComponent,
		PriorityListItemComponent,
		MultipleOptionsButtonComponent,
		AlertComponent,
		TreeViewModalComponent,
		MoveTgsToStreamSaveModalComponent,
		TemplateModalContainerComponent,
		TemplatesGridViewModalComponent,
		PopoverImageComponent,
		HorizontalTabsComponent,
		VerticalTabsComponent,
		TabHeaderComponent,
		TabBodyComponent,
		TabComponent,
		TreeViewComponent,
		CheckboxToggleButtonComponent,
		TemplateChannelSummaryComponent,
		TemplateSummaryComponent
	]
})
export class ComponentsTransitionalModule {
}
