import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChannelService } from '../services/channel.service';
import { OverflowMenuItem } from '../../../components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { TemplateContextService } from '../services/templateContext.service';
import { SortingTypes } from '../models/sortingTypes';
import { TemplatesSortingService } from '../services/templatesSorting.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { TemplateQueryParams } from '../models/templateQueryParams';
import { TemplateSelectionService } from '../services/templateSelection.service';
import { ManageTemplatesService } from '../services/manageTemplates.service';
import { TemplateDeleteService } from '../services/templateDelete.service';
import { TemplateBatchInUseModalComponent } from './modals/templateBatchInUseModal/templateBatchInUseModal.component';
import { TenantInformationService } from '../services/tenantInformation.service';
import { SubMethodService } from '../services/subMethodService';
import { TemplateDuplicateService } from '../services/templateDuplicate.service';
import { NumberOfLanguagesPerTemplate } from '../models/templateInUse';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import {
	MoveTemplateInitialState,
	MoveTemplateModalComponent
} from '../folderTemplatePreviewCard/modals/moveTemplateModal/moveTemplateModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { FolderService } from '../services/folder.service';
import { TemplatesBatchMove } from '../models/templateMove';
import { TemplateInUse } from '../models/templateInUse';
import { CopyToTemplateModalComponent } from '../folderTemplatePreviewCard/modals/copyToTemplateModalComponent/copyToTemplateModal.component';
import { SearchContextService } from '../services/searchContext.service';
import { TemplateKeysModel } from '../models/templateKeysModel';
import { TemplateSearchResult } from '../models/templateResponse';
import { FeatureFlag, FeatureFlagService } from '../../../services/featureFlag.service';
@Component({
	selector: 'templates-header',
	templateUrl: 'templatesHeader.component.html',
	styleUrls: ['templatesHeader.component.scss']
})

export class TemplatesHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() isSelected: boolean;
	@Input() numberTemplates: number;
	@Input() numberOfInvalid: number;
	@Output() batchMoving = new EventEmitter();
	public menuPosition: ConnectionPositionPair[];
	public sortingTitle: string;
	public sortingType: number = SortingTypes.ASC_BY_NAME;
	public channelId: number;
	public templatesSelected: TemplateKeysModel[] = [];
	public queryParams: TemplateQueryParams;
	public subMethodId: number;
	public folderId: number;
	public menuItems: OverflowMenuItem[] = [
		{
			addSeparatorBelow: false,
			callback: this.emitSortingType.bind(this)(SortingTypes.ASC_BY_NAME),
			children: [],
			disabled: false,
			text: this.translate.instant('features.manage_templates.components.details.Name_(A-Z)')
		},
		{
			addSeparatorBelow: false,
			callback: this.emitSortingType.bind(this)(SortingTypes.DESC_BY_NAME),
			children: [],
			disabled: false,
			text: this.translate.instant('features.manage_templates.components.details.Name_(Z-A)')
		},
		{
			addSeparatorBelow: false,
			callback: this.emitSortingType.bind(this)(SortingTypes.NEWEST),
			children: [],
			disabled: false,
			text: this.translate.instant('features.manage_templates.components.details.Date_Modified_(Newest)')
		},
		{
			addSeparatorBelow: false,
			callback: this.emitSortingType.bind(this)(SortingTypes.OLDEST),
			children: [],
			disabled: false,
			text: this.translate.instant('features.manage_templates.components.details.Date_Modified_(Oldest)')
		}
	];
	containsMultiLangTemplates: boolean;
	numberOfLanguagesPerTemplate: NumberOfLanguagesPerTemplate[];
	isMultipleSubMethodsInSelection: boolean;
	isSearchValid: boolean;
	public isSelectAllChecked: boolean = false;
	public isSelectAllIndeterminateState: boolean = false;
	private readonly maxBatchLengthForDuplication: number =15;
	private selectAllCheckbox: HTMLInputElement;
	private searchCache: TemplateSearchResult[] = [];
	private componentSubscriptions: Subscription = new Subscription();
	private templatesInUse: TemplateInUse[] = [];
	public isDuplicating: boolean;
	constructor(
		public channelService: ChannelService,
		private translate: TranslateService,
		private modalService: OptiLogicModalService,
		private manageTemplatesService: ManageTemplatesService,
		private folderService: FolderService,
		private templateDeleteService: TemplateDeleteService,
		private templateContextService: TemplateContextService,
		private templatesSortingService: TemplatesSortingService,
		private templateSelectionService: TemplateSelectionService,
		private searchContextService: SearchContextService,
		private tenantInformationService: TenantInformationService,
		private subMethodService: SubMethodService,
		private templateDuplicateService: TemplateDuplicateService,
		private featureFlagService: FeatureFlagService
	) {}

	ngOnInit(): void {
		this.templateSelectionService.removeAll();
		this.componentSubscriptions.add(
			this.templateContextService.queryTemplateParams.subscribe((queryParams) => {
				if((this.subMethodId !== +queryParams.typeId || this.folderId !== +queryParams.folderId) &&
					(this.isSelectAllChecked || this.isSelectAllIndeterminateState)){
					this.selectAllCheckbox.indeterminate = false;
					this.isSelectAllIndeterminateState = false;
					this.selectAllCheckbox.checked = false;
					this.templateSelectionService.updateSelectAllState(false);
				}

				this.queryParams = queryParams;
				this.subMethodId = +queryParams.typeId;
				this.folderId = +queryParams.folderId;
			})
		);
		this.templateContextService.isTemplatesInUse.subscribe((templatesInUse) => {
			this.templatesInUse = templatesInUse;
		});

		this.menuPosition = this.getMenuPosition();
		this.componentSubscriptions.add(
			this.templateContextService.queryTemplateParams
				.pipe(filter((params) => this.channelId !== +params.channelId))
				.subscribe((params) => {
					this.channelId = +params.channelId;

					let sortingType = this.templatesSortingService.getSortingType(this.channelId);
					if (sortingType === null) {
						this.templatesSortingService.updateSortingTypePerChannel(this.channelId, SortingTypes.ASC_BY_NAME);
						sortingType = SortingTypes.ASC_BY_NAME;
					}
					this.menuItems[sortingType].callback();
					this.sortingTitle = this.menuItems[sortingType].text;
				})
		);
		this.componentSubscriptions.add(
			this.templateContextService.numberOfLanguages.subscribe((value) => {
				if (value) {
					this.numberOfLanguagesPerTemplate = Object.values(value);
				}
			})
		);
		this.componentSubscriptions.add(
			this.searchContextService.searchText.subscribe(
				(text) => (this.isSearchValid = this.searchContextService.isSearchTextValid(text))
			)
		);
		this.componentSubscriptions.add(
			this.searchContextService.searchTemplates.subscribe((searchCache) => {
				this.searchCache = searchCache;
			})
		);
	}

	ngAfterViewInit() {
		const templatesSelectedSubscription = this.templateSelectionService.templatesSelected;
		const isAllSelectedSubscription = this.templateSelectionService.isSelectAllChecked;
		this.selectAllCheckbox = document.getElementById('select-all-checkbox') as HTMLInputElement;

		const selectionSubscription = combineLatest([templatesSelectedSubscription, isAllSelectedSubscription.pipe(distinctUntilChanged())]).subscribe(
			([templatesSelected, isSelectAll]) => {
				this.templatesSelected = templatesSelected;

				this.isMultipleSubMethodsInSelection =
					this.templatesSelected.map((t) => t.subMethodId).filter((value, index, array) => array.indexOf(value) === index)
						.length > 1;

				if (this.numberOfLanguagesPerTemplate)
					this.containsMultiLangTemplates =
						Object.values(this.numberOfLanguagesPerTemplate)
							.filter(
								(template) =>
									this.templatesSelected.map((t) => t.templateID).includes(template.templateId) &&
									template.subMethodId === +this.queryParams.typeId
							)
							.filter((templateWithMultiLang) => templateWithMultiLang.numberOfLanguages > 1).length > 0;

				if (templatesSelected.length > 0  && templatesSelected.length !== this.numberTemplates) {
					this.selectAllCheckbox.indeterminate = true;
					this.isSelectAllIndeterminateState = true;
					this.selectAllCheckbox.checked = false;
				} else if (templatesSelected.length > 0 && templatesSelected.length === this.numberTemplates && !isSelectAll){
					this.isSelectAllIndeterminateState = false;
					this.selectAllCheckbox.indeterminate = false;
					this.selectAllCheckbox.checked = true;
					this.isSelectAllChecked = true;
					this.templateSelectionService.updateSelectAllState(true);
				} else if (templatesSelected.length === 0 && this.selectAllCheckbox.indeterminate) {
					this.selectAllCheckbox.indeterminate = false;
					this.isSelectAllIndeterminateState = false;
					this.selectAllCheckbox.checked = false;
					this.templateSelectionService.updateSelectAllState(false);
				}
			}
		);

		this.componentSubscriptions.add(selectionSubscription);
	}

	ngOnDestroy() {
		this.componentSubscriptions.unsubscribe();
	}

	public onSelectAllChecked() {
		if (this.isSelectAllChecked) {
			if (!this.selectAllCheckbox.indeterminate) {
				this.selectAllCheckbox.indeterminate = false;
				this.isSelectAllIndeterminateState = false;
				this.templateSelectionService.removeAll();
			}
			if (!this.isSearchValid) {
				this.folderService
					.getFolderTemplates(this.folderId, this.subMethodId, this.queryParams.brandId, this.channelId)
					.subscribe((templates) => {
						const selectedAll = templates.map((t) => {
							return {
								templateID: t.id,
								subMethodId: t.subMethodId,
								folderId: t.parentId,
								channelId: this.channelId
							} as TemplateKeysModel;
						});
						this.templateSelectionService.selectAll(selectedAll);
					});
			} else {
				this.templateSelectionService.selectAll(this.searchCache);
			}
		} else {
			this.searchContextService.searchText.subscribe((text) => {
				if (!this.searchContextService.isSearchTextValid(text) && this.searchCache.length > 0) {
					this.isSelectAllChecked = false;
					this.searchContextService.updateSearchTemplateCache([]);
					this.templateSelectionService.updateSelectAllState(false);
				}
			});
			this.templateSelectionService.removeAll();
			this.templateSelectionService.updateSelectAllState(false);
		}
	}

	public duplicateBatch() {
		if(this.containsMultiLangTemplates || this.isDuplicating)
			return;
		this.isDuplicating = true;
		const tenantInfo = this.tenantInformationService.getTenantInformation();
		const tenantId = tenantInfo.tenantId;

		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			let executionMethodInnerId = this.subMethodService.findSubmethodInnerId(metadata, +this.queryParams.typeId);

			if (this.channelService.isOptimobile(+this.queryParams.channelId)) {
				const templateIds = this.templatesSelected.map((template) => template.templateID);
				const optimobileDuplicateBatchAction = (): any => {
					return this.templateDuplicateService.optiMobileBatchDuplicate(
						templateIds,
						this.channelId,
						this.subMethodId,
						tenantId,
						executionMethodInnerId,
						this.folderId,
						true
					);
				};

				if (this.templatesSelected.length > this.maxBatchLengthForDuplication){
					this.openTooManyTemplatesMessage(
						this.translate.instant('features.manage_templates.messages.TOO_MANY_TEMPLATES_FOR_DUPLICATE'),
						this.translate.instant('features.manage_templates.messages.DUPLICATE_ANYWAY'), optimobileDuplicateBatchAction);
					this.clearSelectAllState();
				} else{
					optimobileDuplicateBatchAction();
					this.clearSelectAllState();
				}
				this.isDuplicating = false;
			}

			const optimailDuplicateBatchAction = (): any => {
				return this.templatesSelected.map((template) => {
					this.templateDuplicateService.duplicate(
						template.templateID,
						template.channelId,
						template.subMethodId,
						tenantId,
						executionMethodInnerId,
						template.folderId,
						true
					);
				});
			};

			if (this.templatesSelected.length > this.maxBatchLengthForDuplication){
				this.openTooManyTemplatesMessage(
					this.translate.instant('features.manage_templates.messages.TOO_MANY_TEMPLATES_FOR_DUPLICATE'),
					this.translate.instant('features.manage_templates.messages.DUPLICATE_ANYWAY'), optimailDuplicateBatchAction);
				this.clearSelectAllState();
			} else {
				optimailDuplicateBatchAction();
				this.clearSelectAllState();
			}
			this.isDuplicating = false;
		});
	}

	copyToBatch() {
		const copyBatchAction = (): any => {
			this.modalService.open(CopyToTemplateModalComponent, 'md', <ModalOptions<any>>{
				ignoreBackdropClick: true,
				initialState: {
					templatesBatch: this.templatesSelected
				}
			});
		};
		
		if (this.templatesSelected.length > this.maxBatchLengthForDuplication){
			this.openTooManyTemplatesMessage(
				this.translate.instant('features.manage_templates.messages.TOO_MANY_TEMPLATES_FOR_COPY'),
				this.translate.instant('features.manage_templates.messages.COPY_ANYWAY'), copyBatchAction);
			this.clearSelectAllState();
		} else{
			copyBatchAction();
			this.clearSelectAllState();
		}
	}

	public emitSortingType(type: number) {
		return () => {
			this.templatesSortingService.updateSortingTypePerChannel(this.channelId, type);
		};
	}

	public getMenuPosition(): ConnectionPositionPair[] {
		return [
			new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
			new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'bottom' })
		];
	}
	public setChosenSortingTitle(title: string): void {
		this.sortingTitle = title;
	}
	public isBatchActionsEnabled() {
		return this.isBatchActionsAvailable() || this.channelService.isOptimobile(+this.queryParams.channelId);
	}

	public onBatchActionsMoveClicked(): void {
		this.modalService.open(MoveTemplateModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				selectedFolderId: +this.queryParams.folderId,
				selectedSubMethodId: +this.queryParams.typeId,
				templates: this.templatesSelected,
				callback: this.onTemplatesBatchMoved.bind(this),
				isTemplatesMoving: () => {
					this.batchMoving.emit();
				},
				isBatchAction: true
			} as MoveTemplateInitialState
		});
	}

	onTemplatesBatchMoved(selectedSubMethod: number, selectedFolderId: number): void {
		const templatesBatchToMove: TemplatesBatchMove = {
			templates: this.templatesSelected,
			destinationFolderId: selectedFolderId,
			destinationSubMethodId: selectedSubMethod
		};
		this.isSelectAllChecked = false;
		this.folderService.templatesBatchMovedEvent(templatesBatchToMove);

	}

	public batchDelete() {
		this.modalService.openModalMessage('sm', {
			message: this.translate.instant('features.manage_templates.messages.BATCH_DELETE_TEMPLATES'),
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('features.manage_templates.messages.BATCH_DELETE_TEMPLATE_BUTTON'),
					action: this.deleteTemplates.bind(this)
				},
				{
					class: 'btn-default',
					label: this.translate.instant('general.CANCEL'),
					action: () => {}
				}
			]
		});
	}

	deleteTemplates(): void {
		let someTemplateIsUsed =
			this.templatesSelected.filter(
				(t) =>
					Object.values(this.templatesInUse).filter(
						(u) => u.templateId === t.templateID && u.subMethodId === t.subMethodId && u.inUse
					).length > 0
			).length > 0;

		if (someTemplateIsUsed) {
			this.modalService.open(TemplateBatchInUseModalComponent, 'sm', {
				ignoreBackdropClick: true,
				class: 'template-in-use-modal',
				initialState: {
					title: this.translate.instant('features.manage_templates.messages.TEMPLATE_BATCH_CANNOT_DELETE_TITLE'),
					message: this.translate.instant('features.manage_templates.messages.TEMPLATE_BATCH_CANNOT_DELETE'),
					description: this.translate.instant('features.manage_templates.messages.TEMPLATE_CANNOT_DELETE')
				}
			});
		} else this.templateDeleteService.updateIsDeleted(this.templatesSelected, true);
	}
	isBatchActionsAvailable() {
		return this.channelService.isOptimail(+this.queryParams.channelId) || this.channelService.isWebPopup(+this.queryParams.channelId);
	}

	private openTooManyTemplatesMessage(message: string, buttonText: string, action: () => {}){

		this.modalService.openModalMessage('sm', {
			message: message,
			buttons: [
				{
					class: 'btn-primary',
					label: buttonText,
					action: action
				},
				{
					class: 'btn-default',
					label: this.translate.instant('general.CANCEL'),
					action: () => {}
				}
			]
		});
	}

	private clearSelectAllState():void{
		if(this.isSelectAllChecked){
			if (this.isSelectAllChecked){
				this.isSelectAllChecked = false;
				this.templateSelectionService.removeAll();
				this.templateSelectionService.updateSelectAllState(false);
			}
		}
	}
}
