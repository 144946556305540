import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { Component, ElementRef, HostListener, OnDestroy, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { first } from 'rxjs/operators';
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { AttributeType } from "src/app/features/settings/attributes/attributesList.enums";
import { AttributesListService } from "src/app/features/settings/attributes/services/attributesList.service";

@Component({
	selector: 'btn-cell-renderer',
	templateUrl: './btnCellRenderer.component.html',
	styleUrls: ['./btnCellRenderer.component.scss'],
})
export class BtnCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    @ViewChild('toggleButton') toggleButton: ElementRef;
    @ViewChild('menu') menu: ElementRef;
    private params: any;
    private attributeType: AttributeType;
    public disabled: boolean;
    public disabledDelete: boolean;
    public translateKeys = translateKeys;
    public showAttributeVisibilityBtn = true;
    public isMenuOpen = false;
    public isAttributeHidden = false;
    private readonly isMenuOpenListener: () => void;

    constructor(
        private service: AttributesListService,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private translate: TranslateService,
        private router: Router,
        private eRef: ElementRef,
        private renderer: Renderer2) {
    	this.isMenuOpenListener = this.renderer.listen('window', 'click', (e:Event) => {
    		if(e.target !== this.toggleButton.nativeElement 
                && e.target !== this.menu?.nativeElement){
    			this.isMenuOpen=false;
    		}
    	});
    }

    agInit(params: any): void {
    	this.params = params;
    	this.attributeType = this.params.data.Type;
    	this.disabled = this.params.data.PublishStatus === "Publishing";
    	this.disabledDelete = this.service.internalFields.includes(this.params.data.Name) 
            && !["conditional", "calculated", "purchase"].includes(this.attributeType);
    	this.showAttributeVisibilityBtn = !["Publishing", "NotPublished"].includes(this.params.data.PublishStatus);
    	this.isAttributeHidden = this.params.data.IsHidden;
    }

    @HostListener('document:click', ['$event'])
    clickOut(event) {
    	if (!this.isMenuOpen) {
    		return;
    	}
    	if (!this.eRef.nativeElement.contains(event.target)) {
    		this.isMenuOpen = false;
    	}
    }

    onMenuButtonClicked() {
    	this.isMenuOpen = !this.isMenuOpen;
    }

    changeAttributeVisibility(isHide: boolean) {
    	if (this.isAttributeHidden !== null) {
    		this.isAttributeHidden = null;
    		console.log(this.params.data.FieldName);
    		this.service.changeAttributeVisibility(this.params.data.FieldName, isHide).pipe(first()).subscribe((res) => {
    			if(res.IsSuccess) {
    				this.isAttributeHidden = isHide;
    				this.params.data.IsHidden = this.isAttributeHidden;
    				this.params.api.redrawRows({rowNodes : [this.params.node]});
    			}
    			else {
    				this.isAttributeHidden = !isHide;
    				this.showValidationMessage(this.parseErrorMessage(res.ErrorMsg, res.Data, false));
    			}                
    		});
    	}
    }

    moveToEditAttribute(): void {
    	this.router.navigate([`/user-settings/attributes/${this.attributeType}EditAttribute`], {
    		state: this.params.data
    	});
    }

    areYouSure() {
    	this.modalService.openModalMessage(
    		'sm',
    		{
    			message: this.translate.instant(this.translateKeys.deleteAttribute),
    			buttons: [
    				{
    					class: 'btn-primary',
    					label: 'Delete Attribute',
    					action: this.deleteAndRefresh.bind(this)
    				},
    				{
    					class: 'btn-default',
    					label: 'Cancel',
    					action: this.closeModal.bind(this)
    				}
    			]
    		},
            <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
    	);
    }

    showValidationMessage(message: string, size: string = 'sm') {
    	this.modalService.openModalMessage(
    		size,
    		{
    			html: message,
    			buttons: [{
    				class: 'btn-primary',
    				label: 'OK',
    				action: this.closeModal.bind(this)
    			}]
    		},
            <ModalOptions<any>>{
            	ignoreBackdropClick: true,
            	keyboard: false
            }
    	);
    }

    async deleteAndRefresh() {
    	this.params.context.loading = true;
    	const isNotPublished = this.params.data.OldName ? true : false;
    	this.service.deleteAttribute(this.attributeType, this.params.data.Name)
    		.pipe(first())
    		.subscribe(async (res: DefaultAjaxResponse) => {
    			this.params.context.loading = false;
    			if (res.IsSuccess) {
    				this.service.deleteAttributeFromProfile(this.params.data.Name);
    				this.params.context.attributes = this.params.context.attributes.filter(x => x.Name !== this.params.data.Name);
    				this.runChecks();
    				this.params.context.setGridData(this.params.context.attributes);
    			} else {
    				this.showValidationMessage(this.parseErrorMessage(res.ErrorMsg,res.Data, true));
    			}
    			await this.params.context.service.updateCustomerAttributes();
    		}, (err) => console.error(err));
    }

    runChecks() {
    	this.params.context.checkNoAttributes();
    	this.params.context.checkLimitForAttributes();
    	this.params.context.checkPublishedStatus();
    	this.params.context.checkPublishError();
    }

    parseErrorMessage(errorMsg: string, errorData: any, isDelete: boolean) : string {
    	switch (errorMsg) {
    		case "BasedOnError":
    			return this.translate.instant(isDelete ? translateKeys.deleteErrorRealtime : translateKeys.hideErrorRealtime);
    		case "PersonalizationTagError":
    			return this.translate.instant(isDelete ? translateKeys.deleteErrorPersonalizationTag : translateKeys.hideErrorPersonalizationTag);
    		case "DefaultError":
    			return this.translate.instant(isDelete ? translateKeys.deleteErrorDefault : translateKeys.hideErrorDefault);
    		case "Customer360Error":
    			return this.translate.instant(isDelete ? translateKeys.deleteErrorCustomer360 : translateKeys.hideErrorCustomer360)
    		case "StreamTestError": {
				const title = this.translate.instant(isDelete ? translateKeys.deleteErrorUsedInStream : translateKeys.hideErrorDefault); 
				const tgs = errorData.targetGroups.filter((x, i) => i < 5).map((x) => `<li>${x}</li>`).reduce((a,b) => `${a}${b}`);
				return `${title}<br/><br/><ul>${tgs}${errorData.targetGroups.length > 5 ? `<li>and ${errorData.targetGroups.length - 5} more...</li>` : ''}</ul>`;
			}	
			default: {
			if (errorMsg.includes("TagretGroupError")) {
				//const data = errorMsg.split(":")[1].split("|||");
				const title = this.translate.instant(isDelete ? translateKeys.deleteErrorTgUsed : translateKeys.hideErrorTgUsed, { usedTg: errorData.targetGroups.length }); 
				const tgs = errorData.targetGroups.filter((x, i) => i < 5).map((x) => `<li>${x}</li>`).reduce((a,b) => `${a}${b}`);
				return `${title}:<br/><br/><ul>${tgs}${errorData.targetGroups.length > 5 ? `<li>and ${errorData.targetGroups.length - 5} more...</li>` : ''}</ul>`;
			} else {
				return this.translate.instant(isDelete ? translateKeys.deleteErrorDefault : translateKeys.hideErrorDefault);
			}
    		}
    	}
    }

    optimoveFieldDeletionError() {
    	this.modalService.openModalMessage(
    		'sm',
    		{
    			message: this.translate.instant(this.translateKeys.deleteErrorDefault),
    			buttons: [{
    				class: 'btn-primary',
    				label: this.translate.instant("general.OK"),
    				action: this.closeModal.bind(this)
    			}]
    		},
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
    	);
    }

    refresh(params: any): boolean {
    	return true;
    }

    private closeModal() {
    	this.bsModalRef.hide();
    }

    ngOnDestroy() {
    	if (this.isMenuOpenListener) {
    		this.isMenuOpenListener();
    	}
    }
}

const translateKeys = {
	deleteAttribute: 'features.user_settings.body.attributes.DELETE_ATTRIBUTE',
	delete: 'features.user_settings.body.attributes.DELETE',
	edit: 'features.user_settings.body.attributes.EDIT',
	deleteErrorDefault: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_DEFAULT_MESSAGE',
	deleteErrorRealtime: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_BY_REALTIME_ATTRIBUTE',
	deleteErrorTgUsed: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_BY_TARGET_GROUPS',
	deleteErrorUsedInStream: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_BY_STREAMS',
	deleteErrorPersonalizationTag: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_BY_PERSONALIZATION_TAG',
	deleteErrorCustomer360: 'features.user_settings.body.attributes.invalidErrors.DELETE_ATTRIBUTE_BY_CUSTOMER360',
	hideErrorDefault: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_DEFAULT_MESSAGE',
	hideErrorRealtime: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_BY_REALTIME_ATTRIBUTE',
	hideErrorTgUsed: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_BY_TARGET_GROUPS',
	hideErrorPersonalizationTag: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_BY_PERSONALIZATION_TAG',
	hideErrorPriority: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_BY_PRIORITY',
	hideErrorCustomer360: 'features.user_settings.body.attributes.invalidErrors.HIDE_ATTRIBUTE_BY_CUSTOMER360',
	showTooltip: 'features.user_settings.body.attributes.SHOW_TOOLTIP',
	hideTooltip: 'features.user_settings.body.attributes.HIDE_TOOLTIP'
}
