<span class="tree-menu-container" *ngIf="menuItems && menuItems.length > 0">
    <button type="button" class="btn btn-main" [ngClass]="{'menu-open': isMenuOpen}" (click)="onBtnMenuClicked()">
        <i class="material-icons">add</i>
    </button>

    <div class="tree-menu external-btn-menu" *ngIf="isMenuOpen">
        <ul>
            <li *ngFor="let menuItem of menuItems" (click)="onMenuItemClicked(menuItem.eventType)">
                <span>{{ menuItem.text | translate }}</span>
            </li>
        </ul>
    </div>
</span>