import { Component, Input, OnInit, ContentChildren, QueryList, AfterContentInit, AfterViewInit, ViewChild, ViewContainerRef, ChangeDetectorRef , ViewEncapsulation } from '@angular/core';
import {TabsManager} from "../tabs-manager";

@Component({
  selector: 'horizontal-tabs',
  templateUrl: './horizontalTabs.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./horizontalTabs.component.scss']
})
export class HorizontalTabsComponent extends TabsManager {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
