import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { first } from "rxjs/operators";
import { isUndefined } from 'angular';
import * as moment from "moment";
import { customerSnapshotsTranslateKeys, sideBarTranslateKeys } from 'src/app/features/customer360/customer360.translate.key';
import { Customer360DataService } from 'src/app/features/customer360/services/customer360.data.service';
import { IAnalysisRange, ICustomerAttributeMapping } from 'src/app/features/customer360/customer360.models';
import { Customer360FormatService } from 'src/app/features/customer360/services/customer360.format.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Customers360ModalService } from 'src/app/features/customer360/services/customer360Modal.service';

@Component({
	selector: 'customer-snapshots-table',
	templateUrl: './customerSnapshotsTable.component.html',
	styleUrls: ['./customerSnapshotsTable.component.scss']
})
export class CustomerSnapshotsTableComponent implements OnChanges {

	@Input() dateRange: IAnalysisRange;
	@Input() clientCustomerId: string;
	@Output() onDataLoaded = new EventEmitter<boolean>();

	public translateKeys = customerSnapshotsTranslateKeys;
	public checkedCustomerAttributesKeys: string = "";
	public customerAttributesList: ICustomerAttributeMapping[] = [];
	public attributesListData: any[] = [];
	private date = moment().format('YYYY-MM-DD');
	private siteName = sessionStorage['SiteName'].replace(/"/g, "");

	public get hasListData(): boolean {
        return this.attributesListData?.length > 0;
    }

	public get attributesListCsvFileHeaders(): string[] {
		if (this.attributesListData?.length > 0) {
			const list = Object.keys(this.attributesListData[0]);
			let headerAttributes = this.customerAttributesList.filter(x => list.includes(x.Name));
			let headersCSV = list.map(x => headerAttributes.find(y => y.Name === x)?.Alias.replace(/,/g, '')).filter(x => x);
			headersCSV.unshift('Period');
			return headersCSV;
		} else {
			return [];
		}
    }

	public get attributesListCsvFileName() {
		return `Optimove ${this.siteName} CustomerID ${this.clientCustomerId} Campaign List Details ${this.date}.csv`;
	}

	public get attributesData(): any[] {
		return this.attributesListData;
	}

	constructor(private dataService: Customer360DataService,
				private modalService: Customers360ModalService,
				private formatService: Customer360FormatService,
				private translate: TranslateService,
				private bsModalRef: BsModalRef) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.clientCustomerId?.currentValue) {
			this.refreshAttributesData();
		}
		if (changes.dateRange?.currentValue) {
			this.onDataLoaded.emit(true);
		}
	}

	public openSelectAttributeSideBar() {
		const attributes = this.formatService.attributesToSelectable(this.customerAttributesList);
		this.bsModalRef = this.modalService.openSideBar({
				title: this.translate.instant(sideBarTranslateKeys.TITLE),
				buttons: [{
					label: this.translate.instant("general.CANCEL"),
					action: () => this.closeSideBar(),
					class: "btn-default",
					isDisabled: () => false,
					dataQaId: "side-bar-cancel",
				},
				{
					label: this.translate.instant("general.DONE"), 
					action: () => {},
					class: "btn-primary",
					isDisabled: () => false,
					dataQaId: "side-bar-apply",
				}],
			}, 
			attributes, 
			(keys: string[]) => {this.refreshAttributesKeys(keys)});
	}

	public onExportReady(data: any[]) {
		this.attributesListData = data.sort((a, b) => b.Period.localeCompare(a.Period));
	}

	public attributesListCsvSerializerFunc = function (data: any) {
		let csvRowParts = [];
		for (let prop of Object.keys(data)) {
			let value = isUndefined(data[prop]) || data[prop] === null ? 'NA': data[prop];
			
			if (value === 'NA') {
				let attribute = this.customerAttributesList.find(x => x.Name === prop);
				if (attribute?.FieldType === 'Numeric' || ['ChurnRate','ReactivationRate','ConversionRate'].includes(prop)) {
					value = 0;
				}
			}
			
			if (prop === 'Period') {
				value = moment(value).format('YYYY-MM-DD');
			}
			
			this.pushWithQuotes(csvRowParts, value);
		}
		return `${csvRowParts.join(',')}`;
	}.bind(this);

	public pushWithQuotes(array, value) {
		if (!value.toString().includes('"'))
			array.push('"' + value + '"');
		else
			array.push(value);
	};

	private refreshAttributesData() {
		this.dataService.getAttributesMappingData().pipe(first()).subscribe(response => {
			this.checkedCustomerAttributesKeys = response.checkedCustomerAttributesKeys;
			this.customerAttributesList = response.customerAttributesList;
		});
	}

	private closeSideBar() {
		this.bsModalRef.hide();
	}

	private refreshAttributesKeys(attributesKeys: string[]) {
		if (this.checkedCustomerAttributesKeys !== attributesKeys.toString()) {
			this.onDataLoaded.emit(false);
			this.checkedCustomerAttributesKeys = attributesKeys.toString();
			this.customerAttributesList.forEach(x => x.IsChecked = attributesKeys.includes(x.Name));
			this.dataService.setSelectedAttrMapping(attributesKeys);
		}
		this.closeSideBar();
	}
}
