import {
    Component,
    Input,
    HostListener,
    ViewChild,
    ElementRef,
    Output,
    Renderer2,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    AfterContentInit,
    Optional,
    Self, 
    OnDestroy,
    ViewEncapsulation, 
    OnInit} from "@angular/core";

import { OslCellComponent } from "../oslCellComponent/oslCell.component";
import { OslHeaderComponent } from "../oslHeaderComponent/oslHeader.component";
import { OslItemComponent } from "../oslItemComponent/oslItem.component";
import {noop, Subscription, forkJoin} from 'rxjs';
import * as deepEqual from "deep-equal";
import {AbstractControl, ControlValueAccessor, NgControl, FormControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {SearchListUtilsService} from "../services/searchlistUtils.service";
import {OslCellType} from "../models/osl-cell-type.enum";
import {BaseAgGrid} from "../../agGrid/base-ag-grid.interface";
import {OptiSearchListService} from "./optiSearchList.service";
import { IHashTable } from "@optimove/ui-sdk/common/models";
import {OslInputComponent} from "../oslInputComponent/oslInput.component";
import { OslValueType } from "../models/oslValueType.enum";
import { isNullOrUndefined } from "util";
import { take } from "rxjs/operators";
import { AllCommunityModules, Module, IServerSideDatasource, IServerSideGetRowsParams, ServerSideRowModelModule } from '@ag-grid-enterprise/all-modules'
import { GridOptions, ColDef, SuppressKeyboardEventParams, SelectionChangedEvent, RowNode } from "@ag-grid-enterprise/all-modules";

@Component({
    selector: 'opti-search-list',
    templateUrl: './optiSearchList.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./optiSearchList.component.scss'],
    providers: [
        OptiSearchListService,
    ]
})
export class OptiSearchListComponent implements AfterContentInit, OnChanges, ControlValueAccessor, BaseAgGrid, OnInit, OnDestroy {
    private defaults: SearchListConfigPresets = {
        includeSearchThreshold: 1,
        includeShowSelectedThreshold: 8,
        selectedTitleCount: 5,
        rowHeight: 28,
        minRowCount: 2,
        maxRowCount: 9,
        headerHeight: 45,
        footerHeight: 20,
        footerPadding: 10,
        maxRowsInCell: 3,
        groupRowHeight: 28,
    };

    @Input() items: any;
    @Input() selectedItems: SearchItem[];
    @Input() presets: SearchListConfigPresets = this.defaults;
    @Input() config: SearchListConfig;
    @Input() isServerSideMode: boolean = false;
    @Input() serverSideConfig: ServerSideConfig;
    @Input() isLoading: boolean;
    @Input() isDefaultDisabled: boolean;
    @Input() isSearchEnabled: boolean = true;
    @Output() selectedItemsChange = new EventEmitter<any>();
    @Output() searchListHide = new EventEmitter();

    @ViewChild('search', {static: true}) search: ElementRef;
    @ViewChild('list', {static: true}) list: ElementRef;
    @ViewChild('calculationElement', {static: true}) calculationElement: OslItemComponent;
    @ViewChild('searchInput', {static: false}) searchInput: OslInputComponent;

    public filteredText: string = '';
    public gridOptions: GridOptions = null;
    public includeSearch: boolean = false;
    public isInitialized: boolean = false;
    public transformedSelectedItems: SearchItem[];
    public markAsInvalid: boolean;
    public noRowsTemplate: string;
    public searchListLength: number;
    public modules: Module[] = [ ...AllCommunityModules, ServerSideRowModelModule ];

    private transformedItems: SearchItem[];
    private gridApi: any;
    private gridColumnApi: any;
    private propagateChange:any = () => {};
    private pendingGridActions: (() => void)[] = [];
    private isShowingScrollBar = false;
    private isShowingOnlySelected: boolean;
    private isNoFilterRowsOverlayVisibile = false;
    private isGroupView = false;
    private pendingSelectedItems: SearchItem[];
    private isRowCalcSuccessfully = false;
    private _showGrid = false; 
    private isFirstLoad = true;
    private latestRowNumberFromItems = 0;
    private isServerSideFilter = false;
    private removedServerSideFilter = false;
    private serverSideState: eServerSideModelStates = eServerSideModelStates.FIRST_LOAD;
    private numberOfServerSideItems: number = 0;
    private statusChangeSubscription: Subscription;

    get showGrid(): boolean {
        return this._showGrid;
    }
    set showGrid(shouldShow: boolean) {
        if (!this.gridApi) {
            return;
        }

        if (shouldShow && this.isDisabled) {
            shouldShow = false;
            return;
        }

        const shouldUpdate = this._showGrid !== shouldShow && !shouldShow && this.hasPendingChanges(this.transformedSelectedItems, this.selectedItems);
        this._showGrid = shouldShow;

        if (shouldShow){
            if (!this.isRowCalcSuccessfully) {
                this.gridApi.resetRowHeights();
            }

            this.resizeIfNeeded();

            if (this.onTouched) {
                this.onTouched();
            }

            const columnDef = this.gridApi.getColumnDef("content");
            columnDef.headerComponentParams && columnDef.headerComponentParams.api.onGridShow && columnDef.headerComponentParams.api.onGridShow();

            this.setListScrollToTop();

            this.gridApi.clearFocusedCell();

            this.handleNoFilterRowsOverlayVisibility();
            this.updateColumnWidth(true);
        }

        if (shouldUpdate) {
            this.doSelectItems(this.transformedSelectedItems, true);
        }
        else if(!shouldShow){
            this.searchListHide.emit();
        }

        this.cdRef.detectChanges();
    }
    public isDisabled: boolean;

    private _isControlDisabled: boolean = false;
    private get isControlDisabled(): boolean {
        return this._isControlDisabled;
    }
    private set isControlDisabled(value: boolean) {
        this._isControlDisabled = value;
        this.isDisabled = this.isControlDisabled || this.isDefaultValueDisabled;
    }

    private _isDefaultValueDisabled: boolean = false;
    private get isDefaultValueDisabled(): boolean {
        return this._isDefaultValueDisabled;
    }
    private set isDefaultValueDisabled(value: boolean) {
        this._isDefaultValueDisabled = value;
        this.isDisabled = this.isControlDisabled || this.isDefaultValueDisabled;
    }

    private resizeIfNeeded(){
        if (!this.gridApi) {
            return;
        }

        const suggestedWidth = this.getDropdownWidth(this.isShowingScrollBar);
        const currentWith = this.gridApi.getColumnDef("content").width;
        if (suggestedWidth!== currentWith) {
            this.updateColumnWidth(true);
        }
    }

    private hasPendingChanges(transformedSelectedItems: SearchItem[], selectedItems: SearchItem[]): boolean {
        if (!transformedSelectedItems && !selectedItems) {
            return false;
        }
        selectedItems = selectedItems ? selectedItems : [];
        transformedSelectedItems = transformedSelectedItems ? transformedSelectedItems : [];
        if (transformedSelectedItems.length !== selectedItems.length) {
            return true
        }
        return this.intersect(transformedSelectedItems, selectedItems).length !== selectedItems.length;
    }

    private onTouched: any;
    private closeGridSubscription: Subscription;
    @HostListener('document:click', ['$event']) clickout(event) {
        if(!this.showGrid) return;
        const allowedElements: HTMLElement[] = [this.list.nativeElement, this.search.nativeElement];
        const allowedTouch: boolean = allowedElements.some((elem) => elem.contains(event.target));
        !allowedTouch && this.closeGrid();
    }

    private closeGrid(){
        if (this.gridApi.isAnyFilterPresent()) {
            this.gridApi.setQuickFilter('');
        }
        this.isShowingScrollBar = this.shouldShowScrollBar();
        this.showGrid = false;
    }

    constructor(private el: ElementRef,
                private renderer: Renderer2,
                private translate: TranslateService,
                private cdRef:ChangeDetectorRef,
                private searchListUtilsService: SearchListUtilsService,
                @Optional() @Self() private control: NgControl,
                private optiSearchListService: OptiSearchListService) {
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    ngOnInit() {
        if (this.control) {
            this.setMarkAsInvalidValue(this.control.invalid, this.config.showValidationMark);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.statusChangeSubscription) {
            this.observeStatusChanges();
        }
        
        if (!this.isInitialized) {
            return;
        }

        if (changes.selectedItems &&
            ((!changes.selectedItems.currentValue || !changes.selectedItems.previousValue))) {
            this.selectedItems = this.convertInputToSearchItems(changes.selectedItems.currentValue);
            this.transformedSelectedItems = this.getAvailableSelectedItems(this.selectedItems);
            this.setSelectedItemsInGrid(this.transformedSelectedItems);
            this.handleSingleValue(this.transformedSelectedItems);
        }

        if(changes.items && changes.items.currentValue) {
            this.dropDownConf();
        }

        if (this.gridApi && this.gridApi.headerHeight !== this.getHeaderHeight()){
            this.setHeaderVisiblity();
        }
    }

    private async setSelectedItemsFromServer(selectedItems) {
        let itemsToUse = selectedItems;
        if (!Array.isArray(selectedItems)) {
            itemsToUse = [selectedItems];
        }

        const { selectedItemsRequestUrl, additionalParameters } = this.serverSideConfig;
        const itemsResponse = <any>await this.optiSearchListService
                                     .createRequest(selectedItemsRequestUrl, { selectedItems: itemsToUse, ...additionalParameters });
        const { isSuccess, data } = itemsResponse;
        const { items } = data;

        if (isSuccess) {
            this.addItemsFromServerSide(items);
            return items;
        }
    }

    private dropDownConf() {
        // note: the order of the functions invocations is important here
        this.transformItems();
        this.setListHeight();
        this.handleSelectedItemsAfterListItemsChanged();
        this.updateColumnWidth();
        this.handleNoFilterRowsOverlayVisibility();
        this.handleSingleValue(this.transformedSelectedItems);
    }

    private setHeaderVisiblity() {
        this.gridApi.setHeaderHeight(this.getHeaderHeight());
        // number of elements in header might change
        const col = this.gridApi.getColumnDef("content");
        col.headerComponentParams = this.createHeaderDefs(this.shouldShowHeader());
        this.gridApi.refreshHeader();
    }

    private doSelectItems(items: SearchItem[], dispatchOnChangeEvent: boolean){
        this.transformedSelectedItems = items;
        if (dispatchOnChangeEvent) {
            this.selectedItems = items;
            const outputValue = this.convertSelectedItemsToOutput(this.selectedItems);
            this.propagateChange(outputValue);
            this.selectedItemsChange.emit(outputValue);
        }
    }

    private shouldShowScrollBar(): boolean {
        if (!this.filteredText || !this.gridApi || this.isServerSideMode){
            return this.isListItemsExceedMaxHeight(this.transformedItems, null, false);
        } else {
            const rootNode = this.gridApi.getModel().rootNode;
            const filteredRows = rootNode.childrenAfterFilter;
            
            return this.isListItemsExceedMaxHeight(null, filteredRows, false);
        }
    }

    private getDropdownWidth(isShowingScrollBar: boolean): number {
        const scrollbarWidth = 17; // https://www.textfixer.com/tutorials/browser-scrollbar-width.php
        const scrollbarModifier = isShowingScrollBar ? scrollbarWidth : 0;
        const compStyles = window.getComputedStyle(this.search.nativeElement);
        const width = parseFloat(compStyles["width"]);

        return width - scrollbarModifier;
    }

    ngAfterContentInit(): void {
        this.isGroupView = this.config && this.config.groupKeyProperty ? true : false;
        this.transformItems();
        this.initGridView();
        this.isInitialized = true;
        this.observeCloseGrid();
    }

    private completePendingGridActions(): void {
        let invocation = null;
        do {
            invocation = this.pendingGridActions.shift();
            invocation ? invocation() : noop();
        } while (invocation)
    }

    private initGridView(): void {
        this.isShowingOnlySelected = false;
        let multiSelectOptions = this.createOptionsForMultiSelect();
        let gridOptions = <GridOptions>{
            context: this,
            columnDefs: this.createColumnDefs(),
            defaultColDef: {
                lockPinned: true //for no pinning
            },
            rowDragManaged: false, //dragging rows
            rowMultiSelectWithClick: this.config.isMultiSelect,
            onSelectionChanged: this.onSelectionChanged.bind(this),
            suppressDragLeaveHidesColumns: true,
            suppressRowClickSelection: true,
            suppressRowTransform: true,
            suppressHorizontalScroll: true,
            scrollbarWidth: 0,
            
            onGridReady: (params) => {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi;
                this.setData();
                this.setListHeight();
                this.completePendingGridActions();
                this.handleSingleValue(this.transformedSelectedItems || []);
                if (this.config.isExternalDropDownMode) {
                    this.initSearchListOnExternalDropDownMode();
                }
            },
            doesExternalFilterPass: (node) => {
                return node.isSelected() || node.data.type === OslCellType.AggregatedAction;
            },
            isExternalFilterPresent: () => {
                return Boolean(this.isShowingOnlySelected);
            },
            getRowHeight: (node) => {
                const containerWidth = this.getDropdownWidth(this.isShowingScrollBar);
                return this.getHeightForItem(node.data, containerWidth);
            },
            onFilterChanged: this.updateAggregatedAndHeaderRow.bind(this),
            getRowNodeId: (data) => {
                const dataIdType = this.getDataIdType();
                return data[dataIdType] ? data[dataIdType] : 0;
            }
        };
        
        if (this.isServerSideMode) {
            gridOptions.rowModelType = 'serverSide';
            gridOptions.cacheOverflowSize = 1;
            gridOptions.serverSideDatasource = this.createDataSource();
            gridOptions.pagination = false;
        }

        this.gridOptions = Object.assign(gridOptions, multiSelectOptions);
    }

    private createDataSource(): IServerSideDatasource {
        return {
            getRows: async (params: IServerSideGetRowsParams) => {
                const { request, successCallback, failCallback } = params;
                const { requestUrl, additionalParameters } = this.serverSideConfig;
                const { startRow, endRow } = request;                
                const filter = this.isServerSideFilter ? this.filteredText : '';
                const selectedItemsData = this.selectedItems ? this.selectedItems : [];

                if (this.isShowingOnlySelected) {
                    const selectedItemWithFilter = this.getSelectedItemsWithFilter(this.transformedSelectedItems);
                    this.numberOfServerSideItems = selectedItemWithFilter.length;
                    successCallback(selectedItemWithFilter, selectedItemWithFilter.length);
                    return;
                }

                const nextData = <any>await this.optiSearchListService
                                                .createRequest(requestUrl, { startRow, endRow, filter, ...additionalParameters });
                const pendingData = this.pendingSelectedItems ? 
                                                        await this.setSelectedItemsFromServer(this.pendingSelectedItems) : [];
                
                const { isSuccess, data } = nextData;

                if (isSuccess) {
                    const { nextData, totalDataCount } = data;
                    const allAvaialbleValues = this.getAvailableValues(selectedItemsData, nextData, pendingData);
                    this.numberOfServerSideItems = allAvaialbleValues.length;
                    successCallback(allAvaialbleValues, allAvaialbleValues.length);
                    this.afterRecievedNextData(endRow, totalDataCount, allAvaialbleValues);
                } else {
                    failCallback();
                }
            }
        }
    }

    private getSelectedItemsWithFilter(selectedItemsData = []) {
        if (!this.isServerSideFilter) {
            return selectedItemsData;
        }
        
        const { valueProperty } = this.config;
        return selectedItemsData.filter(item => {
            const value = item[valueProperty];
            return value.toLowerCase().includes(this.filteredText.toLowerCase());
        });
    }

    private getAvailableValues(selectedItemsData = [], nextData = [], pendingData = []) {
        const valuesToUse = [ ...nextData ];
        this.addSelectedItems(nextData, selectedItemsData, valuesToUse);
        this.removeExistsDataFromPendingItems(nextData, selectedItemsData, pendingData);
        return [...pendingData, ...valuesToUse];
    }

    private addSelectedItems(nextData, selectedItemsData, valuesToUse) {
        selectedItemsData.forEach(item => {
            const dataIdType = this.getDataIdType();
            const selectedItemId = item[dataIdType];
            const isInAvailableValues = nextData.some(value => {
                const valueId = value[dataIdType];
                return valueId === selectedItemId;
            });

            if (!isInAvailableValues) {
                valuesToUse.unshift(item);
            }
        });
    }

    private removeExistsDataFromPendingItems(nextData, selectedItemsData, pendingData) {
        const pendingDataToUse = [...pendingData];
        pendingDataToUse.forEach((pendingDataItem) => {
            const dataIdType = this.getDataIdType();
            const pendingDataItemValueId = pendingDataItem[dataIdType];
            const isInSelectedItems = selectedItemsData.some(item => {
                const valueId = item[dataIdType];
                return valueId === pendingDataItemValueId;
            });
            const isInNextData = nextData.some(item => {
                const valueId = item[dataIdType];
                return valueId === pendingDataItemValueId;
            });

            if (isInSelectedItems || isInNextData ) {
                const dataToIds = pendingData.map(item => { return item[dataIdType] });
                const indexToRemove = dataToIds.indexOf(pendingDataItemValueId);
                pendingData.splice(indexToRemove, 1);
            }
        });
    }

    private afterRecievedNextData(endRow, totalDataCount, allAvaialbleValues) {
        this.setServerSideState(endRow, totalDataCount);
        this.addItemsFromServerSide(allAvaialbleValues);
        this.afterNewServerDataArrived();
        this.resolvePendingItems();
    }

    private addItemsFromServerSide(nextData) {
        const { keyProperty } = this.config;
        nextData.forEach(value => {
            const valueId = value[keyProperty];
            const haveValue = this.items.some(item => item[keyProperty] === valueId);

            if (!haveValue) {
                this.items.push(value);
            }
        });
    }

    private setServerSideState(endRow: number, maxRowNumber: number) {
        if (endRow < maxRowNumber) {
            this.serverSideState = eServerSideModelStates.PARTLY_LOADED;
        } else if (endRow >= maxRowNumber) {
            this.serverSideState = eServerSideModelStates.FULLY_LOADED;
        } else {
            this.serverSideState = eServerSideModelStates.FIRST_LOAD;
        }
    }

    private afterNewServerDataArrived() {
        this.transformedSelectedItems = this.getAvailableSelectedItems(this.transformedSelectedItems);
        if (!this.isServerSideFilter && !this.removedServerSideFilter) {
            this.removedServerSideFilter = false;
            this.dropDownConf();
            this.setHeaderVisiblity();
        }
    }

    private resolvePendingItems() {
        if (this.pendingSelectedItems) {
            this.writeValue(this.pendingSelectedItems);
            this.pendingSelectedItems = null;
        }
    }

    private getHeightForItem(item, containerWidth){
        if (item.type === OslCellType.FooterAction) {
            return this.calcFooterElementHeight();
        }

        if (item.type === OslCellType.Group) {
            return this.presets.groupRowHeight;
        }

        if (isNaN(containerWidth)) {
            return this.defaults.rowHeight;
        }

        if (this.gridApi && !this.isRowCalcSuccessfully) {
            this.isRowCalcSuccessfully = true;
        }


        let text = item[this.config.valueProperty];
        return this.calculationElement.getHeightForText(text, containerWidth, this.isGroupView);
    }

    private calcFooterElementHeight(): number {
        let footerElementsLength = this.getFooterElements().length;
        let footerElementHeight = this.presets.footerHeight + this.presets.footerPadding * 2 / footerElementsLength;
        return footerElementHeight;
    }

    private getMinHeight(){
        return this.presets.rowHeight * this.presets.minRowCount;
    }

    private getMaxHeight(){
        return this.presets.rowHeight * this.presets.maxRowCount;
    }

    private getHeaderHeight(): number {
        if (!this.shouldShowHeader()) {
            return 0;
        }

        let headerHeight = this.presets.headerHeight;
        if (this.config.isExternalDropDownMode) {
            headerHeight += 30; // Padding in .external-drop-down-mode > .input-container
        }
        return headerHeight;
    }

    private setListHeight(){
        let height;
        const isIncludeFooter = this.shouldIncludeFooter();
        if (this.isNoFilterRowsOverlayVisibile) {
            if (!isIncludeFooter) {
                height = this.getMinHeight();
            } else {
                height = 80;
            }
        } else {
            height = this.calcListItemsDisplayHeight(this.transformedItems, false);
        }
        height += this.getHeaderHeight();
        if (isIncludeFooter) {
            const footerHeight = this.calcFooterElementHeight() * this.config.footerConfig.length;
            height += footerHeight;
        }
        this.list.nativeElement.style.height = `${height}px`;
    }

    private getFooterElements(): SearchListFooterConfig[] {
        if (!this.config.footerConfig || !this.gridApi){
            return [];
        }
        return this.config.footerConfig.filter(config => { return config.textProvider && config.textProvider() });
    }

    ngDoCheck(): void {
        // footer info is passed as an internal object of config, so we don't get notified about it changes in ngOnChanges
        if (!this.gridApi){
            return;
        }
        let footerRowsData = this.getFooterElements();
        if (this.gridApi.pinnedRowModel.pinnedBottomRows.length !== footerRowsData.length){
            this.setFooter(footerRowsData);
        }
    }
    

    public onFilterChanged(text): void {
        if (!this.gridApi) {
            return;
        }
        
        this.filteredText = text;

        if (this.isServerSideMode) {
            this.setListScrollToTop();
            this.validateServerSideFilter(text);
            return;
        } else {
            this.gridApi.setQuickFilter(text);
        }
        
        this.handleNoFilterRowsOverlayVisibility();
        this.updateColumnWidth();
    }

    private validateServerSideFilter(text: string) {
        if (text !== '') {
            this.refreshCahacheWithFilter();
        } else {
            this.isServerSideFilter = false;
            this.removedServerSideFilter = true;
            this.gridApi.purgeServerSideCache();
        }
    }

    private refreshCahacheWithFilter() {
            this.isServerSideFilter = true;
            this.gridApi.purgeServerSideCache();
    }

    public onFilterFocus(): void {
        this.showGrid = true;
    }

    public onSelectionChanged(event: SelectionChangedEvent) {
        this.updateAggregatedAndHeaderRow();
        const selectedNodes = event.api.getSelectedNodes();
        const selectedItems = selectedNodes.map((node) => {
            return <SearchItem>node.data
        });
        const oldTransformSelectedItems = this.transformedSelectedItems ? [ ...this.transformedSelectedItems ] : [];
        this.transformedSelectedItems = this.searchListUtilsService.filterOnlySearchItems(selectedItems);
        const isTransformItemDifference = this.validateTransformItemDifference(oldTransformSelectedItems, this.transformedSelectedItems);
        if (!this.config.isMultiSelect && selectedNodes && selectedNodes.length && oldTransformSelectedItems && isTransformItemDifference) {
            this.filteredText = null;
            this.showGrid = false;
        }

        // Notify selection changed to header
        if (this.shouldShowHeader()) {
            const col = this.gridApi.getColumnDef("content");
            col.headerComponentParams && col.headerComponentParams.api.onSelectionChanged && col.headerComponentParams.api.onSelectionChanged();
        }
    }

    private validateTransformItemDifference(oldTransformSelectedItems = [], updatedTransformedItems = []): boolean {
        if (oldTransformSelectedItems.length !== updatedTransformedItems.length) {
            return true;
        }
        
        const isTransformSelectedItemsEqual = oldTransformSelectedItems.every(item => {
            return updatedTransformedItems.every(updatedItems => {
                const dataIdType = this.getDataIdType();
                return item[dataIdType] === updatedItems[dataIdType];
            });
        });

        return !isTransformSelectedItemsEqual;
    }

    private transformItems(): void {
        if (!this.items || this.items.length === 0) {
            this.transformedItems = [];
            this.setNoRowsTemplate(true);
            this.searchListLength = 0;
            this.setGridItems(this.transformedItems);
            return;
        }
        if (!this.isGroupView) { 
            this.transformedItems = this.sortItems();
        } else {
            this.transformedItems = this.getGroupsItemsList(this.items, this.config.groupKeyProperty, this.config.groupValueProperty, this.config.valueProperty, !this.config.shouldIgnoreSorting);
        }
        this.setupAggregatedAction();
        this.isShowingScrollBar = this.shouldShowScrollBar();
        this.includeSearch = this.isSearchEnabled ? this.transformedItems.length > this.presets.includeSearchThreshold : false;
        this.setNoRowsTemplate(false);
        this.searchListLength = this.searchListUtilsService.filterOnlySearchItems(this.transformedItems).length;
        
        this.setGridItems(this.transformedItems);
    }

    private setGridItems(transformedItems: SearchItem[]) {
        if (this.gridApi) {
            this.gridApi.setRowData(transformedItems);
        }
    }

    private sortItems(): SearchItem[]{
        if (this.config.shouldIgnoreSorting) {
            return this.items;
        }

        let sortItems: SearchItem[] = this.items.sort(this.sort.bind(this));
        return sortItems;
    }

    private sort(a,b) {
        const sortProperty = this.config.sortProperty ? this.config.sortProperty : this.config.valueProperty;
        let av,bv;
        if (this.config.isNumberSorting)
        {
            av = parseInt(a[sortProperty]);
            bv = parseInt(b[sortProperty]);
        }
        else
        {
            av = (!isNullOrUndefined(a[sortProperty]) ? a[sortProperty] : '').toLowerCase();
            bv = (!isNullOrUndefined(b[sortProperty]) ? b[sortProperty] : '').toLowerCase();
        }
        if (a.type === OslCellType.AggregatedAction) {return -1}
        if (b.type === OslCellType.AggregatedAction) {return 1}
        if(av < bv) { return -1; }
        if(av > bv) { return 1; }
        return 0;
    }

    private getGroupsItemsList(items: any[], groupKeyProperty: string, groupValueProperty: string, itemValueProperty: string, shouldSort: boolean): SearchItem[] {
        const groups = this.getGroups(items, groupKeyProperty, groupValueProperty, shouldSort);

        // Init each group items
        const groupsItemsDictionary = groups.reduce((groupsItems, group) => {
            groupsItems[group.groupKey] = {
                groupText: group.groupText,
                items: []
            };

            return groupsItems;
        }, {});

        // Attach each item to a group
        items.forEach(item => {
            const itemGroupKey = item[groupKeyProperty].toString();
            const group = groupsItemsDictionary[itemGroupKey];
            group.items.push(item);

            const groupText = group.groupText;
            item.filterTextInGroupView = `${item[itemValueProperty]} ${groupText}`;
        });

        // Concat a list from group item & group's items
        let resultItems = [];
        groups.forEach(group => {
            const groupItems = groupsItemsDictionary[group.groupKey].items;
            group.filterTextInGroupView = group.groupText + ' ' + groupItems.map(groupItem => groupItem[itemValueProperty]).join(' ');

            resultItems.push(group);

            if (shouldSort) {
                const sortedGroupItems = groupItems.sort(this.sort.bind(this));
                resultItems = resultItems.concat(sortedGroupItems);
            } else {
                resultItems = resultItems.concat(groupItems);
            }
        });

        return resultItems;
    }

    private getGroups(items: any[], groupKeyProperty: string, groupValueProperty: string, shouldSort: boolean): ISearchListGroup[] {
        // Get groups "dictionary" from list items
        const groups = items.reduce((groups: IHashTable<string>, item: any) => {
            const groupKeyValue = item[groupKeyProperty];
            if (groups[groupKeyValue] === undefined) {
                groups[groupKeyValue] = item[groupValueProperty];
            }
            return groups;
        }, {});

        const groupsArray: ISearchListGroup[] = [];
        Object.entries(groups).forEach(([key, value]) => {
            const group: ISearchListGroup = { groupKey: key, groupText: value.toString(), type: OslCellType.Group };
        
            if (shouldSort) {
                let itemIndex = groupsArray.findIndex(sortGroupItem => sortGroupItem.groupText > group.groupText);
                itemIndex = itemIndex < 0 ? groupsArray.length : itemIndex;
                groupsArray.splice(itemIndex, 0, group);
            } else {
                groupsArray.push(group);
            }
        });

        return groupsArray;
    }

    private setupAggregatedAction(){
        let aggregatedActionIndex = this.transformedItems.findIndex(item => item.type === OslCellType.AggregatedAction);
        const shouldShowAggregatedAction = this.shouldShowAggregatedAction();
        if (shouldShowAggregatedAction && aggregatedActionIndex === -1) {
            // Add aggregatedAction
            const aggregatedAction = {
                type: OslCellType.AggregatedAction,
            };
            aggregatedAction[this.config.valueProperty] = this.translate.instant('components.opti_search_list.ALL');
            this.transformedItems.splice(0, 0, aggregatedAction);
        } else if (!shouldShowAggregatedAction && aggregatedActionIndex > -1) {
            // Remove aggregatedAction
            this.transformedItems.splice(aggregatedActionIndex, 1);
        }
    }

    private setData(): void {
        this.setGridItems(this.transformedItems);
        this.setSelectedItemsInGrid(this.transformedSelectedItems ? this.transformedSelectedItems : this.selectedItems);
        this.setFooter(this.config.footerConfig);
    }

    private setFooter(footerConfig: SearchListFooterConfig[]){
        if (!footerConfig) {
            return;
        }
        let footerRowsData: SearchItem & SearchListFooterConfig[] = [];
        for (let footerItemConfig of footerConfig){
            let footerRowData: SearchItem & SearchListFooterConfig = {
                type: OslCellType.FooterAction
            };
            footerRowData[this.config.valueProperty] = 'placeholder'; // need to conform to protocol
            footerRowData = Object.assign(footerRowData, footerItemConfig);
            footerRowsData.push(footerRowData);
        }
        this.gridApi.setPinnedBottomRowData(footerRowsData);
    }

    private setSelectedItemsInGrid(selectedItems: SearchItem[]): SearchItem[]{
        if (!this.gridApi){ // might be called as part on onChanges before initialization.
            return;         // in this case skip it. it will be called as part of initialization.
        }

        if (!Array.isArray(selectedItems)){
            return;
        }
        
        const selectedNodes = this.gridApi.getSelectedNodes()
        selectedNodes.forEach(node => {
            const { data } = node;
            const dataIdType = this.getDataIdType();
            const valueId = data[dataIdType];
            const isNodeInSelectedItems = selectedItems.some(item => {
                const selectedId = item[dataIdType];
                return valueId === selectedId;
            });
            
            if (!isNodeInSelectedItems) {
                node.setSelected(false, false, true);
            }
        });

        const selectedObjectIndexPairs = this.getSelectedObjectIndexPairs(selectedItems,false);
        selectedItems.forEach(item => {
            const dataIdType = this.getDataIdType();
            const itemId = item[dataIdType];
            const node = this.gridApi.getRowNode(itemId);
            if (node) {
                node.setSelected(true, false, true);
            }
    });

        // an item that doesn't exist in list was selected, in that case update parent that only the available items are selected
        if (selectedObjectIndexPairs.length !== selectedItems.length) {
            const selectedObjects = selectedObjectIndexPairs.map(pair => pair.object);
            selectedItems = this.intersect(selectedObjects, this.transformedItems);
        }

        this.updateAggregatedAndHeaderRow();

        return selectedItems;
    }

    private getDataIdType() {
        return this.config.keyProperty ? this.config.keyProperty : this.config.valueProperty; 
    }

    private intersect(items1: SearchItem[], items2: SearchItem[]):SearchItem[] {
        return items1.filter((selectedItem) => {
            return items2.some(i => i[this.config.keyProperty] === selectedItem[this.config.keyProperty])
        });
    }


    // need to update selected items so that they reference same object as transformed items
    private refreshSelectedItems() {
        const selectedAvailableItems = this.getAvailableSelectedItems(this.transformedSelectedItems);

        if (this.transformedItems.length === 1 && selectedAvailableItems.length <= 1){
            this.handleSingleValue(selectedAvailableItems);
            this.setSelectedItemsInGrid(selectedAvailableItems);
        } else {
            const dispatchOnChangeEvent = this.hasPendingChanges(this.transformedSelectedItems, selectedAvailableItems);
            this.doSelectItems(selectedAvailableItems, dispatchOnChangeEvent);
            this.setSelectedItemsInGrid(selectedAvailableItems);
        }
    }

    private getAvailableSelectedItems(newSelectedItems: SearchItem[]): SearchItem[] {
        const selectedObjectIndexPairs = this.getSelectedObjectIndexPairs(newSelectedItems,true);
        const selectedItems = selectedObjectIndexPairs.map(pair => pair.object);
        return this.intersect(selectedItems, this.transformedItems);
    }

    private getSelectedObjectIndexPairs(selectedItems: SearchItem[], deepCompare: boolean): ObjectIdxPair[] {
        if (!selectedItems || !this.transformedItems){
            return [];
        }

        let projection = (a) => {
            const key = this.config.keyProperty;
            return key ? a[key] : a;
        };

        let comparator = (a, b) => {
            const pa = projection(a);
            const pb = projection(b);
            return ( deepCompare ? deepEqual(pa, pb) : pa === pb);
        };

        let selectedObjectIdxPair: ObjectIdxPair[] = [];
        for (let i = 0; i < selectedItems.length; i++) {
            const itemIndex = this.transformedItems.findIndex(item => comparator(selectedItems[i], item));
            if (itemIndex > -1) {
                const pair = {
                    object: this.transformedItems[itemIndex],
                    idx: itemIndex
                };
                selectedObjectIdxPair.push(pair);
            }
        }
        return selectedObjectIdxPair;
    }

    private createOptionsForMultiSelect(): GridOptions {
        let options = <GridOptions>{
            rowSelection: this.config.isMultiSelect ? 'multiple' : 'single',
            headerHeight: this.getHeaderHeight(),
        };
        const multiSelectOptions = { frameworkComponents: {agColumnHeader: OslHeaderComponent} };
        options = Object.assign(options, multiSelectOptions);
        return options;
    }

    private shouldShowHeader() : boolean {
        return this.config.isMultiSelect && this.transformedItems.length > this.presets.includeShowSelectedThreshold;
    }

    private shouldShowAggregatedAction(): boolean {
        return this.config.isMultiSelect && this.transformedItems.length > 1 && (this.config.showSelectAllButton || this.config.showSelectAllButton === undefined);
    }

    private createHeaderDefs(shouldRender: boolean): any {
        const headerColDef = {
            isMultiSelect: this.config.isMultiSelect,
            allCount: this.isServerSideMode ? this.numberOfServerSideItems : this.transformedItems.length,
            shouldRender: shouldRender,
            hasAggregatedButton: this.shouldShowAggregatedAction(),
            isSmallHeader: this.config.isSmallHeader,
            api: {
                toggleShowSelected: (showSelected) => {
                    this.isShowingOnlySelected = showSelected;
                    this.gridApi.onFilterChanged();
                },
                showSelectedItemsClicked: () => {
                    this.updateColumnWidth(false);
                },
                onKeyDown: this.onHeaderKeyDown.bind(this),
            }
        }

        if (this.gridApi) {
            const col = this.gridApi.getColumnDef("content");
            if (col?.headerComponentParams?.api) {
                const existsOslHeaderComponentApiEvents = col.headerComponentParams.api;

                headerColDef.api = {
                    ...headerColDef.api,
                    ...existsOslHeaderComponentApiEvents
                };
            }
        }
        
        return headerColDef;
    }

    private createColumnDefs(): [ColDef] {
        const defaults: ColDef = {
            initialWidth: this.getDropdownWidth(this.isShowingScrollBar)
        };
        let column = {
            headerName: 'content',
            field: 'content',
            colId: 'content',
            cellRendererFramework: OslCellComponent,
            getQuickFilterText: this.quickFilterText.bind(this),
            cellClass: 'cell-wrap-text',
            cellRendererParams: {
                isMultiSelect: this.config.isMultiSelect,
                valueProperty: this.config.valueProperty,
                disabledProperty: this.config.disabledProperty,
                maxRowsInCell: this.presets.maxRowsInCell,
                isGroupView: this.isGroupView,
                singleRowHeight: this.presets.rowHeight,
                iconProperty: this.config.iconProperty
            },
            suppressKeyboardEvent: this.onCellKeyboardEvent.bind(this)
        };

        if (this.config.isMultiSelect) {
            const multiSelectHeader = {
                headerComponentParams: this.createHeaderDefs(this.shouldShowHeader())
            };
            column = Object.assign(column, multiSelectHeader);
        }
        return [Object.assign(defaults, column)];
    }

    private updateAggregatedAndHeaderRow(): void {
        if (!this.config.isMultiSelect){
            return;
        }
        const model = this.gridApi.getModel();
        const aggregatedActionNode = model.getRow(0);
        if (aggregatedActionNode) {
            this.gridApi.refreshCells({
                rowNodes: [aggregatedActionNode],
                force: true
            });
        }

        if (this.shouldShowHeader()) {
            const col = this.gridApi.getColumnDef("content");
            col.headerComponentParams && col.headerComponentParams.api.onSelectionChanged && col.headerComponentParams.api.onSelectionChanged();
        }
    }

    private quickFilterText(params: any): string {
        // a somewhat ugly hack to make sure aggregated row returns when searching
        if (params.node.data.type === OslCellType.AggregatedAction) {
            return this.filteredText;
        }

        return this.isGroupView ? params.data.filterTextInGroupView : params.data[this.config.valueProperty];
    }

    private updateColumnWidth(force: boolean = false){
        if (this.gridApi) {
            const wasShowingScrollbar = this.isShowingScrollBar;
            const isShowingScrollBar = this.shouldShowScrollBar();
            if (force || wasShowingScrollbar !== isShowingScrollBar) {
                this.isShowingScrollBar = isShowingScrollBar;
                const width = this.getDropdownWidth(this.isShowingScrollBar);
                const column = this.gridColumnApi.getColumn('content');
                this.gridColumnApi.setColumnWidth(column, width);
            }
            return;
        }
        this.pendingGridActions.push(() => {
            this.gridApi.setColumnDefs(this.createColumnDefs());
            this.gridApi.setHeaderHeight(this.getHeaderHeight());
        });
    }


    // <editor-fold desc="ControlValueAccessor implementation">
    writeValue(value) {
        const updateItems = () => {
            const searchItemValue = this.convertInputToSearchItems(value);
            const selectedItems =  this.getAvailableSelectedItems(searchItemValue) || [];

            if (searchItemValue.length !== selectedItems.length) {
                this.pendingSelectedItems = value;

                if (this.isServerSideMode) {
                    this.setSelectedItemsFromServer(value);
                }
                return;
            } else if (this.isServerSideMode && this.serverSideState === eServerSideModelStates.FIRST_LOAD) {
                this.pendingSelectedItems = value;
                return;
            }

            this.selectedItems = selectedItems;
            this.doSelectItems(selectedItems, false);
            this.setSelectedItemsInGrid(selectedItems);
            this.handleSingleValue(selectedItems);
        };

        if (this.gridApi) {
            updateItems();
        } else {
            this.pendingGridActions.push(updateItems);
        }
    }

    private isListItemsExceedMaxHeight(transformedItems: SearchItem[], filterItems: RowNode[], isShowingScrollBar: boolean): boolean {
        const listMaxHeight = this.getMaxHeight();
        let accumulateHeight = 0;
        if (filterItems) {
            for (let filterItem of filterItems) {
                const itemHeight = filterItem.rowHeight;
                accumulateHeight += itemHeight;
                if (accumulateHeight > listMaxHeight) {
                    return true;
                }
            }
        } else {
            const containerWidth = this.getDropdownWidth(isShowingScrollBar);

            for (let transformItem of transformedItems) {
                const itemHeight = this.getHeightForItem(transformItem, containerWidth);
                accumulateHeight += itemHeight;
                if (accumulateHeight > listMaxHeight) {
                    return true;
                }
            }
        }

        return false;
    }

    private calcListItemsDisplayHeight(visibileItems: SearchItem[], isShowingScrollBar: boolean): number {
        const containerWidth = this.getDropdownWidth(isShowingScrollBar);
        const listMaxHeight = this.getMaxHeight();
        const listMinHeight = this.getMinHeight();
        
        let accumulateVisibileHeight = 1;
        for (let visibileItem of visibileItems) {
            const itemHeight = this.getHeightForItem(visibileItem, containerWidth);
            accumulateVisibileHeight += itemHeight;
            if (accumulateVisibileHeight > listMaxHeight) {
                break;
            }
        }

        return this.searchListUtilsService.forceInRange(accumulateVisibileHeight, listMinHeight, listMaxHeight);
    }

    // Its an heavy method, that calcs the height for each item. consider if to use it.
    private getItemsHeight(containerWidth){
        return this.transformedItems.reduce((sum, item) => {
            return sum + this.getHeightForItem(item, containerWidth);
        }, 0);
    }

    private handleSelectedItemsAfterListItemsChanged() {
        if (this.pendingSelectedItems && (this.pendingSelectedItems.length || !Array.isArray(this.pendingSelectedItems))) {
            this.writeValue(this.pendingSelectedItems);
            this.pendingSelectedItems = null;
        } else {
            this.refreshSelectedItems();
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.isControlDisabled = isDisabled;
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }
    // </editor-fold>

    getGridOption(): GridOptions {
        return this.gridOptions;
    }

    shouldIncludeFooter(): boolean {
        return Boolean(this.config.footerConfig);
    }

    // account for this use-case - https://mobius.visualstudio.com/Backstage/_workitems/edit/21786
    private handleSingleValue(selectedItems: SearchItem[]){
        if (!this.isRequired() || this.isLoading) {
            return;
        }

        selectedItems = !isNullOrUndefined(selectedItems) ? selectedItems : []; 
        const keepDefaultSelection = this.isDefaultValueDisabled && this.transformedItems.length === 1 && selectedItems.length === 1;
        if (keepDefaultSelection){
            return;
        }
        if (this.transformedItems.length !== 1){
            this.isDefaultValueDisabled = false;
            return;
        }
        this.isDefaultValueDisabled = true;

        if (selectedItems.length === 0) {
            setTimeout(() => {
                const currentSelectedItems = this.transformedSelectedItems || [];
                if (currentSelectedItems.length === 0 && this.transformedItems.length === 1) {
                    this.doSelectItems(this.transformedItems, true);
                    this.setSelectedItemsInGrid(this.transformedItems);
                }
            });
        }
    }

    private observeStatusChanges() {
        if (!this.control) {
            return;
        }

        this.statusChangeSubscription = this.control.statusChanges.subscribe(() => {
            this.setMarkAsInvalidValue(this.control.invalid, this.config.showValidationMark);
        })
    }

    private setMarkAsInvalidValue(isInvalid: boolean, showValidationMarkConfig: boolean) {
        this.markAsInvalid = isInvalid && showValidationMarkConfig;
    }

    // Handle no filter rows overlay visibility.
    // ag-grid handles the empty list overlay, but doesn't handles the no filter results.
    private handleNoFilterRowsOverlayVisibility() {
        if (!this.gridApi) {
            return;
        }

        setTimeout(() => {
            const prevIsNoFilterRowsOverlayVisibile = this.isNoFilterRowsOverlayVisibile;
            const isNoFilterRowsOverlayVisibile = this.gridApi.getDisplayedRowCount() === 0;

            if (prevIsNoFilterRowsOverlayVisibile !== isNoFilterRowsOverlayVisibile) {
                if (isNoFilterRowsOverlayVisibile) {
                    this.setNoRowsTemplate(false);
                    this.gridApi.showNoRowsOverlay();
                } else {
                    this.gridApi.hideOverlay();
                }

                this.isNoFilterRowsOverlayVisibile = isNoFilterRowsOverlayVisibile;
                this.setListHeight();
            }
        });
    }

    private setNoRowsTemplate(isEmptyListTemplate: boolean) {
        const listItemDesc = this.translate.instant(this.config.itemNameTranslateKey);
        
        // There is an init case, that the translation isn't init yet.
        if (listItemDesc === this.config.itemNameTranslateKey) {
            this.translate.get(this.config.itemNameTranslateKey)
            .pipe(take(1))
            .subscribe(listItemDesc => {
                this.bindNoRowsTemplate(listItemDesc, isEmptyListTemplate);
            });
        } else {
            this.bindNoRowsTemplate(listItemDesc, isEmptyListTemplate);
        }
    }

    private bindNoRowsTemplate(listItemDesc: string, isEmptyListTemplate: boolean) {
        let message: string;

        if (isEmptyListTemplate) {
            const listItemDescFormatted = listItemDesc.toLowerCase();
            message = this.translate.instant('components.opti_search_list.NO_ITEMS', {listItemDesc: listItemDescFormatted});
        } else {
            message = this.translate.instant('components.opti_search_list.NO_SEARCH_RESULTS');
        }

        let messageClass: string;
        if (this.shouldShowHeader()) {
            messageClass = 'no-rows-with-header';
        } else if (this.shouldIncludeFooter()) {
            messageClass = 'no-rows-with-footer';
        }

        this.noRowsTemplate = `<h5 class="${messageClass}">${message}</h5>`;
    }

    private isRequired() {
        if (this.control && this.control.control && this.control.control.validator) {
            const validator = this.control.control.validator({}as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }

        return false;
    }

    private onCellKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
        params.event.stopPropagation();
        params.event.preventDefault();
        let suppressAgGridKeyboardEvent = false;

        if (params.data && params.data.type === OslCellType.Group) {
            return false;
        }

        if (params.event.keyCode === 13 /*Enter*/ || params.event.keyCode === 32 /*Space*/) {
            // On spacebar clicked, ag-grid allready handle the selection on multiSelect search list item
            if (params.event.keyCode === 32 && this.config.isMultiSelect && (params.data.type === OslCellType.Item || params.data.type === undefined)) {
                return false;
            }

            if (params.data.type === OslCellType.FooterAction) {
                this.dispatchFooterClickEvent(params.event.target as HTMLElement);
                return false;
            }

            this.dispatchItemClickEvent(params.event.target as HTMLElement);

            if (!this.config.isMultiSelect) {
                this.searchInput.focus();
            }

            suppressAgGridKeyboardEvent = true;
        } else if (params.event.keyCode === 9 /*Tab*/) {
            this.handleTabClick();

            suppressAgGridKeyboardEvent = true;
        } else if (params.event.keyCode === 27 /*Esc*/) {
            this.showGrid = false;
            suppressAgGridKeyboardEvent = true;
        } else if (params.node.childIndex === 0 && params.event.keyCode === 38 /*ArrowUp*/ && this.includeSearch) {
            if (this.shouldShowHeader() && this.isHeaderEnabled()) {
                this.focusOnHeader();
            } else {
                this.searchInput.focus();
            }

            this.gridApi.clearFocusedCell();
            suppressAgGridKeyboardEvent = true;
        }

        return suppressAgGridKeyboardEvent;
    }

    private handleTabClick() {
        this.showGrid = false;

        this.searchInput.focus();
        this.searchInput.dispatchTabEvent();
    }

    private dispatchItemClickEvent(gridRow: HTMLElement){
        const oslItemNativeElement = gridRow.querySelector('osl-item');
        const oslItemContainer = oslItemNativeElement.querySelector('.item-content');
        oslItemContainer.dispatchEvent(new Event('click'));
    }

    private dispatchFooterClickEvent(gridFooterRow: HTMLElement){
        const footerButton = gridFooterRow.querySelector('button');
        footerButton.dispatchEvent(new Event('click'));
    }

    private setListScrollToTop(){
        if (!this.list) {
            return;
        }

        const listNativeElement = this.list.nativeElement as HTMLElement;
        const listViewPort = listNativeElement.querySelector('.ag-body-viewport');
        if (!listViewPort) {
            return;
        }

        if (this.isServerSideMode) {
            listViewPort.scrollTop = 0;
            return;
        }

        setTimeout(() => {
            listViewPort.scrollTop = 0;
        }, 0);
    }

    public onInputArrowDown() {
        if (this.shouldShowHeader() && this.isHeaderEnabled()) {
            this.focusOnHeader();
        } else {
            this.scrollTopAndFocus();
        }
    }

    public onHeaderKeyDown(keyCode: number) {
        if (keyCode === 40 /*ArrowDown*/) {
            this.scrollTopAndFocus();
        } else if (keyCode === 38 /*ArrowUp*/ && this.includeSearch) {
            this.searchInput.focus();
        } else if (keyCode === 9 /*Tab*/) {
            this.handleTabClick();
        } else if (keyCode === 27 /*Esc*/) {
            this.showGrid = false;
        } 
    }

    private isHeaderEnabled(): boolean {
        const columnDef = this.gridApi.getColumnDef("content");
        return columnDef.headerComponentParams && columnDef.headerComponentParams.api.isDisabled && !columnDef.headerComponentParams.api.isDisabled();
    }

    private focusOnHeader() {
        const columnDef = this.gridApi.getColumnDef("content");
        columnDef.headerComponentParams && columnDef.headerComponentParams.api.focus && columnDef.headerComponentParams.api.focus();
    }

    private scrollTopAndFocus() {
        this.setListScrollToTop();
        setTimeout(() => {
            this.focusOnFirstItem();
            }, 100);
    }

    private focusOnFirstItem() {
        if (this.isNoFilterRowsOverlayVisibile) {
            return;
        }

        this.gridApi.setFocusedCell(0, 'content', null);
    }

    private observeCloseGrid() {
        this.closeGridSubscription =  this.optiSearchListService.closeGridSubject
            .asObservable()
            .subscribe(() => this.closeGrid());
    }

    private initSearchListOnExternalDropDownMode() {
        this.showGrid = true;

        setTimeout(() => {
            if (!this.includeSearch) {
                (this.el.nativeElement as HTMLElement).focus();
                this.focusOnFirstItem();
            } else {
                const searchInputWrapper: HTMLElement = (this.search.nativeElement as HTMLElement).querySelector('.search-wrapper');
                searchInputWrapper.dispatchEvent(new MouseEvent('click'));
            }
        });
    }

    private convertInputToSearchItems(inputValue: any): SearchItem[] {
        if (this.config.valueType === undefined) {
            return inputValue || [];
        }

        if (isNullOrUndefined(inputValue)) {
            return [];
        }
        
        if (this.config.isMultiSelect) {
            return this.convertInputValueToSearchItems(inputValue, this.config.valueType, this.config.keyProperty);
        } else {
            const searchListItem = this.convertInputValueToSearchItem(inputValue, this.config.valueType, this.config.keyProperty);
            return [searchListItem];
        }
    }

    private convertInputValueToSearchItem(inputValue: any, valueType: OslValueType, keyProperty: string): SearchItem {
        if (valueType === OslValueType.Object) {
            return inputValue;
        }

        return {[keyProperty]: inputValue};
    }

    private convertInputValueToSearchItems(inputValue: any[], valueType: OslValueType, keyProperty: string): SearchItem[] {
        return inputValue.map(value => 
            this.convertInputValueToSearchItem(value, valueType, keyProperty)
        );
    }

    private convertSelectedItemsToOutput(selectedItems: SearchItem[]): any {
        if (this.config.valueType === undefined) {
            return selectedItems;
        }

        if (isNullOrUndefined(selectedItems) || selectedItems.length === 0) {
            return null;
        }

        if (this.config.isMultiSelect) {
            return this.convertSelectedItemsToOutputValue(selectedItems, this.config.valueType, this.config.keyProperty);
        } else {
            return this.convertSelectedItemToOutputValue(selectedItems[0], this.config.valueType, this.config.keyProperty);  
        }
    }

    private convertSelectedItemToOutputValue(selectedItem: SearchItem, valueType: OslValueType, keyProperty: string): any {
        if (valueType === OslValueType.Object) {
            return selectedItem;
        }

        return selectedItem[keyProperty];
    }

    private convertSelectedItemsToOutputValue(selectedItems: SearchItem[], valueType: OslValueType, keyProperty: string): any[] {
        return selectedItems.map(selectedItem => 
            this.convertSelectedItemToOutputValue(selectedItem, valueType, keyProperty)
        );
    }

    ngOnDestroy(): void {
        this.closeGridSubscription && this.closeGridSubscription.unsubscribe();
        this.statusChangeSubscription && this.statusChangeSubscription.unsubscribe();
    }
}

export interface SearchItem {
    type?: OslCellType,
    [key: string]: any,
    filterTextInGroupView?: string,
}

export interface SearchListConfigPresets {
    includeSearchThreshold: number,
    includeShowSelectedThreshold: number
    selectedTitleCount: number,
    rowHeight: number,
    minRowCount: number,
    maxRowCount: number,
    maxRowsInCell: number,
    headerHeight: number,
    footerHeight: number;
    footerPadding: number,
    groupRowHeight: number;
}

export interface SearchListConfig {
    valueType?: OslValueType,
    valueProperty: string,
    keyProperty?: string,
    sortProperty?: string,
    disabledProperty?: string,
    shouldIgnoreSorting?: boolean,
    isMultiSelect: boolean,
    presets?: SearchListConfigPresets,
    footerConfig?: SearchListFooterConfig[],
    placeholderTranslateKey?: string,
    isDropDownDirectionUp?: boolean,
    itemNameTranslateKey: string,
    showSelectAllButton?: boolean,
    groupKeyProperty?: string,
    groupValueProperty?: string,
    isExternalDropDownMode?: boolean,
    isNumberSorting?: boolean,
    isSmallHeader?: boolean,
    showValidationMark?: boolean,
    iconProperty?:string
}

export interface SearchListFooterConfig {
    textProvider?: () => (string),
    icon?: string,
    action?: () => void
}

export interface ObjectIdxPair {
    object: any,
    idx: number
}

export interface ISearchListGroup extends  SearchItem {
    groupKey: string,
    groupText: string,
}

export interface ServerSideConfig {
    requestUrl: string,
    selectedItemsRequestUrl: string
    additionalParameters: any
}

export enum eServerSideModelStates {
    FIRST_LOAD,
    PARTLY_LOADED,
    FULLY_LOADED
}
