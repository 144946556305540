<ul class="opti-connected-list list-group">
    <li *ngFor="let summaryRow of rows; let i = index" class='list-group-item connected-table-row'
        [ngClass]="data.showArrow && i == 2? ' arrow-box':''">
        <div class="col col-title">
            <span ellipsis-title="1" [ngClass]="summaryRow.isHeaderRow ? 'response-brakedown': ''">{{ summaryRow.title }}</span>
            <div *ngIf="summaryRow.gaussianImg" class="list-icon-wrapper">
                <img src="{{summaryRow.gaussianImg}}" tooltip="{{summaryRow.tooltip}}" container="body" class="list-icon">
            </div>
        </div>
        <div *ngFor="let action of summaryRow.actionDataList; let actionIndex = index" class="col col-action">
            <span *ngIf="summaryRow.isHeaderRow" [ngClass]="data.isTestControl || action.isControl ? 'h4' : ''" class="action-header">
                <span *ngIf="!data.isTestControl && !action.isControl" class="label-action-order" [ngClass]="'label-action-order-' + (actionIndex+1)">{{ action.actionLabel}} </span>
                <span *ngIf="action.actionName" class="action-name" ellipsis-title="1">{{action.actionName}}</span>
            </span>
            <span *ngIf="!summaryRow.isHeaderRow" class="text-nowrap" (click)="summaryRow.onClick(action.id)"
                  [ngClass]="summaryRow.onClick && action.value ? 'customers-link': ''"
                  title="{{ summaryRow.onClick && action.value ? ('features.mission_control.view_in_customer_explorer' | translate) : '' }}">
                {{ action.value | formatter: summaryRow.dataFormat: formatterOptions}}
            </span>
        </div>
    </li>
</ul>