import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {IModalButtonConf, IModalConf, OptiLogicModalService} from "../../optiLogicModal.service";
@Component({
    selector: 'generic-logic-modal',
    templateUrl: './genericLogicModal.html',
    encapsulation: ViewEncapsulation.None
})
export class GenericLogicModalComponent implements OnInit, OnChanges {
    @Input() modalConf: IModalConf;
    private saveAction: Function = () => null;
    private cancelAction: Function = () => null;
    private xButtonAction: Function = () => null;
    constructor(public bsModalRef: BsModalRef) {
    }
    ngOnChanges(changes: SimpleChanges): void {
    }
    ngOnInit(): void {
        this.buildModalConf();
    }
    onSave(): void {
        this.saveAction();
        this.bsModalRef.hide();
    }
    onCancel(xButton: boolean): void {
        if (xButton) {
            this.xButtonAction();
        } else {
            this.cancelAction();
        }
        this.bsModalRef.hide();
    }
    buildModalConf(): void {
        if (this.modalConf.buttons) {
            if (this.modalConf.buttons[0]) {
                this.saveAction = this.modalConf.buttons[0].action;
                this.modalConf.buttons[0].action = this.onSave.bind(this);
            }
            if (this.modalConf.buttons[1]) {
                this.cancelAction = this.modalConf.buttons[1].action;
                this.modalConf.buttons[1].action = this.onCancel.bind(this);
            }
        }
        if (this.modalConf.xButton) {
            this.xButtonAction = this.modalConf.xButton.action;
            this.modalConf.xButton.action = this.onCancel.bind(this);
        }
    }
}