import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FeatureFlag, FeatureFlagService } from "src/app/services/featureFlag.service";

@Component({
    selector:'attribute-settings',
    templateUrl:'attributeSettings.component.html',
    styleUrls:['attributeSettings.component.scss'],
})

export class UseAsPersonalizationTagComponent{
    public isPersonalizationTag: boolean;
    public isPersonalizationCheckBoxChecked: boolean;
    public translateKeys = translateKeys;
    public isPossibleToHide = false;
    @Input() isPersonalization: boolean;
    @Input() showAttribute: boolean;
    @Input() disableShowHideCheckbox: boolean;
    @Output() chengePersonalizationEvent = new EventEmitter<boolean>();
    @Output() changeAttributeVisibilityEvent = new EventEmitter<boolean>();

    constructor(private featureFlagService: FeatureFlagService) {
        this.isPersonalizationTag = this.featureFlagService.isEnabled(FeatureFlag.isPersonalisationTagAttribute);
    }

    changePersonalization(isPersonalization: boolean){
        this.chengePersonalizationEvent.emit(isPersonalization)
    }

    changeAttributeVisibility() {
       this.showAttribute = !this.showAttribute;
       this.changeAttributeVisibilityEvent.emit(this.showAttribute);
    }

    changePersonalizationCheckBox(){
        this.isPersonalizationCheckBoxChecked = !this.isPersonalizationCheckBoxChecked;
        this.changePersonalization(this.isPersonalization);
        
        if(this.isPersonalizationCheckBoxChecked && !this.showAttribute) {
            this.changeAttributeVisibility();
        }
    }
}
const translateKeys = {
    personalizationTag: 'features.user_settings.body.attributes.addAttribute.PERSONALIZATION',
    isHidden: 'features.user_settings.body.attributes.addAttribute.IS_HIDDEN',
    settings: 'features.user_settings.body.attributes.addAttribute.SETTINGS',
    tooltip: 'features.user_settings.body.attributes.addAttribute.DISPLAY_TOOLTIP'
}