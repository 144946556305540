import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SsmService } from 'src/app/services/ssm.service';
import { UtilsMethodsService } from '../../services/utilsMethods.service';
import { AbstractNestedFormComponent } from '../abstractNestedForm/abstractNestedForm.component';
import { DisabledFieldValue } from '../abstractNestedForm/models/disabledState.model';
import { IChipItem } from '../chips/models/chipItem.model';
import { ChipsMode } from '../chips/models/chipsMode.model';
import { ChipRadioItem } from '../chipsRadioGroup/models/chipRadioItem.model';
import { IDatePicker } from '../datePicker/datePicker.component';
import { OslValueType } from '../optiSearchList/models/oslValueType.enum';
import { SearchListConfig } from '../optiSearchList/optiSearchListComponent/optiSearchList.component';
import { LabelTranslateKeys } from './labelTraslateKeys';
import { DefaultRecurrenceFormData } from './models/defaultRecurrenceFormData.model';
import { EndingType } from './models/endingType.model';
import { MonthSelection } from './models/monethSelection.model';
import { RecurrenceFormData } from './models/recurrenceFormData.model';
import { RecurrenceType } from './models/recurrenceType.model';
import { RecurrenceFormService } from './services/recurrenceForm.service';

@Component({
    selector: 'recurrence-form',
    templateUrl: 'recurrenceForm.component.html',
    styleUrls: ['./recurrenceForm.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecurrenceFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RecurrenceFormComponent),
            multi: true
        }
    ]
})
export class RecurrenceFormComponent extends AbstractNestedFormComponent<RecurrenceFormData> implements OnChanges, OnDestroy {
    @Input() startDate: Date;
    @Input() minEndByDate: Date;
    @Input() isEndingSectionDisabled = false;
    @Input() defaultValues: RecurrenceFormData
    @Input() isMonthlyDisbaled = false;
    @Input() minNumberOfOccurrencesPastValidatorFn: ValidatorFn;
    @Input() clientTime: Date;
    @Input() isEditMode: boolean;
    @Input() datePickerPlacement: 'top' | 'bottom' | 'left' | 'right' | 'top left' |  'top right' | 'bottom left' | 'bottom right' | 'top left'
    @Input() datePickerContainer: string = null;
    @Input() showRedTextErrors: boolean;

    timeUnitsConfig: SearchListConfig;
    frequencyConfig: SearchListConfig;
    weekdayOptions: IChipItem[];
    monthOptions: ChipRadioItem[] = [];
    endingType = EndingType;
    labelsTranslateKeys = new LabelTranslateKeys();
    timeUnitsOptions = [];
    RecurrenceType = RecurrenceType;
    frequencyOptions = [];
    datePickerData: IDatePicker = {};
    ChipsMode = ChipsMode;
    endingTypeChangeSubscription: Subscription;
    timeUnitChangeSubscription: Subscription;
    formDataChangeSubscription: Subscription;
    multiFrequencySubscription: Subscription;
    isRecurrenceByWeek = false;
    isRecurrenceByMonth = false;
    private ssm: any;

    private readonly DAYS_PER_WEEK = 7;

    constructor(private formBuilder: FormBuilder, private traslate: TranslateService, private recurrenceService: RecurrenceFormService, private utilsMethodsService: UtilsMethodsService ,cd: ChangeDetectorRef, private ssmService: SsmService) {
        super(cd);
    }

    ngOnInit() {
        this.ssm = this.ssmService.getSSM();
        this.timeUnitsOptions = [
            { key: RecurrenceType.Day, text: this.traslate.instant(this.labelsTranslateKeys.day) },
            { key: RecurrenceType.Week, text: this.traslate.instant(this.labelsTranslateKeys.week) },
        ];
        if (!this.isMonthlyDisbaled) {
            this.timeUnitsOptions.push({ key: RecurrenceType.Month, text: this.traslate.instant(this.labelsTranslateKeys.month) })
        }
        
        if (!this.datePickerData.minDate) {
            this.datePickerData.minDate = this.getClientTime().toDate();
        }

        this.datePickerData.container = this.datePickerContainer;
        this.datePickerData.placement = this.datePickerPlacement;
        this.datePickerData.adaptivePosition = true;

        const selectionConfig = {
            keyProperty: "key",
            valueProperty: "text",
            isMultiSelect: false,
            valueType: OslValueType.Key
        };

        this.timeUnitsConfig = {
            ...selectionConfig,
            itemNameTranslateKey: 'features.scheduled_campaign_builder.STEPS.TARGET_GROUP.TARGET_GROUP_LABEL',
            shouldIgnoreSorting: true
        };

        for (let i = 1; i <= 10; i++) {
            this.frequencyOptions.push({ key: i, text: i.toString() })
        }

        this.frequencyConfig = {
            ...selectionConfig,
            placeholderTranslateKey: 'features.scheduled_campaign_builder.STEPS.TARGET_GROUP.TARGET_GROUP_PLACEHOLDER',
            itemNameTranslateKey: 'features.scheduled_campaign_builder.STEPS.TARGET_GROUP.TARGET_GROUP_LABEL',
            shouldIgnoreSorting: true
        };

        this.weekdayOptions = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((dayStr, index) => {
            const weekDayOption: IChipItem = {
                id: (index + 1) % this.DAYS_PER_WEEK,
                text: dayStr,
                isDisabled: this.isFormDisabled,
                isRadioButton: false,
            };

            return weekDayOption;
        });

        this.createForm();
        this.handleTimeUnitChange();
        this.handleEndingTypeChange();
        this.observeMultiFrequencyChanged();
        super.ngOnInit();
    }

    ngAfterViewInit() {
        if (!this.isEndingSectionDisabled) {
            this.endingTypeControl.enable({ emitEvent: false });
        }
    }

    public handlePositiveNumber(event: KeyboardEvent) {
        const inputValue = Number((event.target as HTMLInputElement).value);
        return this.utilsMethodsService.isPositiveIntegerKeydown(event.key, inputValue);
    }

    public getClientTime() {
		return moment(this.ssm.GetGeneric(this.ssm.Resx.ClientTime), 'YYYYMMDDHHmm');
	}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minEndByDate) {
            this.datePickerData = {
                ...this.datePickerData,
                minDate: this.minEndByDate
            };

            if (this.form && this.endingByControl) {
                // Why setTimeout? Should update datePicker component with minDate, and after that check validation.
                setTimeout(() => {
                    this.endingByControl.updateValueAndValidity({ emitEvent: false });
                })
            }
        }

        if (changes.startDate != null && changes.startDate.currentValue != changes.startDate.previousValue) {
            this.setMonthOptionsByStartDate(this.startDate);
            if (this.form && this.endingByControl) {
                this.endingByControl.updateValueAndValidity({ emitEvent: false });
            }
        }

        super.ngOnChanges(changes);
    }

    private setMonthOptionsByStartDate(startDate: Date) {
        const momentDate = moment(startDate);
        const dayOfMonth = momentDate.date();
        const month = momentDate.month();
        const weekNumber = this.recurrenceService.getWeekNumberByDate(startDate);
        const weekNumberName = this.traslate.instant('general.NUMBER_NAME_' + weekNumber);
        const weekday = momentDate.format('dddd');
        let monthOptions = [
            {
                name: this.traslate.instant(this.labelsTranslateKeys.sectionPrefix + 'MONTH_OPTION_1', { dayOfMonth }),
                value: MonthSelection.OnDateInMonth
            }
        ];
        if (weekNumber !== 5) {
            const dayInWeekOption = {
                name: this.traslate.instant(this.labelsTranslateKeys.sectionPrefix + 'MONTH_OPTION_2', { weekNumberName, weekday }),
                value: MonthSelection.OnDayInWeek
            };
            monthOptions.push(dayInWeekOption);
        }
        else {
            if (this.form && this.monthSelectionControl && this.monthSelectionControl.value === MonthSelection.OnDayInWeek) {
                this.monthSelectionControl.setValue(MonthSelection.OnDayInLastWeek, { emitEvent: false });
            }
        }

        const nextWeekMomentDate = momentDate.clone().add(1, "weeks");
        const isLastDayInMonth = nextWeekMomentDate.month() !== month;
        if (isLastDayInMonth) {
            const dayInLastWeekOption = {
                name: this.traslate.instant(this.labelsTranslateKeys.sectionPrefix + 'MONTH_OPTION_3', { weekday }),
                value: MonthSelection.OnDayInLastWeek
            }
            monthOptions.push(dayInLastWeekOption);
        }
        else {
            if (this.form && this.monthSelectionControl && this.monthSelectionControl.value === MonthSelection.OnDayInLastWeek) {
                this.monthSelectionControl.setValue(MonthSelection.OnDayInWeek, { emitEvent: false });
            }
        }

        this.monthOptions = monthOptions;
    }

    private createForm() {
        const defaultValues = this.defaultValues ? this.defaultValues : new DefaultRecurrenceFormData();
        
        this.form = this.formBuilder.group({
            frequency: [defaultValues.frequency, [Validators.required]],
            timeUnit: [defaultValues.timeUnit, [Validators.required]],
            endingType: [{ value: defaultValues.endingType, disabled: this.isEndingSectionDisabled }, [Validators.required]],
            endingAfterOccurrences: [{ value: defaultValues.endingAfterOccurrences, disabled: true }, [Validators.required, Validators.min(1)]],
            endingBy: [{ value: defaultValues.endingBy, disabled: true }, [Validators.required]],
            weekdays: [{ value: null, disabled: true }, [Validators.required]],
            monthSelection: [{ value: null, disabled: true }, [Validators.required]],
        });

        if (this.minNumberOfOccurrencesPastValidatorFn) {
            this.endingAfterOccurrencesControl.addValidators(this.minNumberOfOccurrencesPastValidatorFn);
        }
    }

    private observeMultiFrequencyChanged() {
        const isMultiple$: Observable<boolean> = this.frequencyControl.valueChanges.pipe(
            map(val => val > 1),
            distinctUntilChanged()
        );

        this.multiFrequencySubscription = isMultiple$.subscribe(isMultiple => {
            if (!isMultiple) {
                this.timeUnitsOptions = [
                    { key: RecurrenceType.Day, text: this.traslate.instant(this.labelsTranslateKeys.day) },
                    { key: RecurrenceType.Week, text: this.traslate.instant(this.labelsTranslateKeys.week) },
                ];
                if (!this.isMonthlyDisbaled) {
                    this.timeUnitsOptions.push({ key: RecurrenceType.Month, text: this.traslate.instant(this.labelsTranslateKeys.month) })
                }
            }
            else {
                this.timeUnitsOptions = [
                    { key: RecurrenceType.Day, text: this.traslate.instant(this.labelsTranslateKeys.days) },
                    { key: RecurrenceType.Week, text: this.traslate.instant(this.labelsTranslateKeys.weeks) },
                ];
                if (!this.isMonthlyDisbaled) {
                    this.timeUnitsOptions.push({ key: RecurrenceType.Month, text: this.traslate.instant(this.labelsTranslateKeys.months) })
                }
            }
        });
    }

    private handleEndingTypeChange() {
        this.endingTypeChangeSubscription = this.endingTypeControl.valueChanges.subscribe((value: EndingType) => {
            if (value === EndingType.After && !this.isEndingSectionDisabled) {
                this.endingAfterOccurrencesControl.enable({ emitEvent: false });
            }
            else {
                this.endingAfterOccurrencesControl.disable({ emitEvent: false });
            }

            if (value === EndingType.By && !this.isEndingSectionDisabled) {
                this.endingByControl.enable({ emitEvent: false });
            }
            else {
                this.endingByControl.disable({ emitEvent: false });
            }
        });
    }
    
    private handleTimeUnitChange() {
        this.timeUnitChangeSubscription = this.timeUnitControl.valueChanges.subscribe((option) => {
            const value: RecurrenceType = option;
            if (value === RecurrenceType.Week) {
                const defaultValue = this.startDate ? [moment(this.startDate).weekday()] : [];
                this.weekdaysControl.setValue(defaultValue, { emitEvent: false });

                this.isFormDisabled || this.disabledState?.weekdays === DisabledFieldValue ? this.weekdaysControl.disable({ emitEvent: false }) : this.weekdaysControl.enable({ emitEvent: false });

                this.isRecurrenceByWeek = true;
            } else {
                this.weekdaysControl.setValue(null, { emitEvent: false });
                this.weekdaysControl.disable({ emitEvent: false });
                this.isRecurrenceByWeek = false;
            }

            if (value === RecurrenceType.Month) {
                const defaultValue = MonthSelection.OnDateInMonth;
                this.monthSelectionControl.setValue(defaultValue, { emitEvent: false });

                this.isFormDisabled ? this.monthSelectionControl.disable({ emitEvent: false }) : this.monthSelectionControl.enable({ emitEvent: false });

                this.isRecurrenceByMonth = true;
            }
            else {
                this.monthSelectionControl.setValue(null, { emitEvent: false });
                this.monthSelectionControl.disable({ emitEvent: false });
                this.isRecurrenceByMonth = false;
            }
        });
    }

    get frequencyControl(): FormControl {
        return this.form.get('frequency') as FormControl;
    }

    get timeUnitControl(): FormControl {
        return this.form.get('timeUnit') as FormControl;
    }

    get endingTypeControl(): FormControl {
        return this.form.get('endingType') as FormControl;
    }

    get endingAfterOccurrencesControl(): FormControl {
        return this.form.get('endingAfterOccurrences') as FormControl;
    }

    get endingByControl(): FormControl {
        return this.form.get('endingBy') as FormControl;
    }

    get weekdaysControl(): FormControl {
        return this.form.get('weekdays') as FormControl;
    }

    get monthSelectionControl(): FormControl {
        return this.form.get('monthSelection') as FormControl;
    }

    ngOnDestroy() {
        this.endingTypeChangeSubscription && this.endingTypeChangeSubscription.unsubscribe();
        this.formDataChangeSubscription && this.formDataChangeSubscription.unsubscribe();
        this.timeUnitChangeSubscription.unsubscribe();
        this.multiFrequencySubscription.unsubscribe();
        super.ngOnDestroy();
    }

}