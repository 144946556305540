<div [formGroup]="form">
    <opti-logic-modal *ngIf="modalConf" [modalConf]="modalConf">
        <p>{{ config.translateKeys.description | translate }}</p>
        <div class="row">
            <opti-search-list data-qa-id="attribute-dropdown"
                              formControlName="selectedAttribute"
                              class="attribute-picker col-sm-6"
                              [items]="attributesSearchItems"
                              [config]="attributeSearchListConfig">
            </opti-search-list>
            <div class="col-sm-6">
            </div>
        </div>
    </opti-logic-modal>
</div>