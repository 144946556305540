import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../../../services/optimove-http/optimove-http.model";
import { BrandModel } from "../../brandModel";


@Injectable()
export class BrandsService {
    private readonly baseUrl: string = '/UserSettings';
    private brands:  BrandModel[];

    constructor(private readonly http: HttpService) { }

    public getTransactionalBrands(): Observable<BrandModel[]> {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetTransactionalOptimailBrandsAsync`)
            .pipe(map(res => {
                this.brands = res.Data.data;
                return res.Data.data;
            }));
    }

    public prepareBrands(brands){
        let result = [];
        brands.forEach((brand)=>{
            result.push ({
                BrandId: brand.BrandId,
                BrandName: brand.BrandName,
                DoiEnabled: JSON.parse(brand.DoiEnabled),
                DoiLink: brand.DoiLink,
            });
        })
        return result;
    }

    public checkBrandUsingDoubleOptIn(brandId: number):boolean{
        let preparedBrands = this.prepareBrands(this.brands);
        let isDOIEnabled: boolean;
        preparedBrands.forEach(brand => {
            if(brand.BrandId == brandId){
                isDOIEnabled = brand.DoiEnabled
            }
        })
        return isDOIEnabled;
    }
    public updateBrandProperty(brand: BrandModel): Observable<DefaultAjaxResponse>
    {
        const body ={brandJSON: JSON.stringify(brand)};
        return this.http.post<DefaultAjaxResponse>(`${this.baseUrl}/UpdateBrandProperty`, body);
    }
}
