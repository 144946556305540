import {
  FormatterPipe,
  IFormatterOptions,
} from "./../../../pipes/formatter.pipe";
import { TranslateService } from "@ngx-translate/core";
import { ActivityAttribute } from "./../../../features/customer360/customer360.models";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { purchaseHistoryTranslateKeys } from "./../../../features/customer360/customer360.translate.key";
import { Injectable } from "@angular/core";
import * as moment from "moment/moment";

@Injectable()
export class Customer360PurchaseHistoryService {
  public translateKeys = purchaseHistoryTranslateKeys;
  private formatterOptions: IFormatterOptions = {
    shouldHandleSmallNumbers: true,
    shouldHandleBigNumbers: true,
    decimalPointPrecision: 1,
    handleSpecialCustomersValue: true,
  };

  constructor(
    private translate: TranslateService,
    private formatter: FormatterPipe
  ) {}

  public createColumnMultiAttributeFlowDefs(
    selectedAttributes: ActivityAttribute[],
    tableWidth: number
  ): ColDef[] {
    let colDefs: ColDef[] = [];

    colDefs.push(
      ...(<ColDef[]>[
        {
          headerName: this.translate.instant(
            this.translateKeys.TRANSACTION_DATE
          ),
          field: "TransactionDate",
          resizable: true,
          sortable: true,
          sort: "asc",
          cellRenderer: (data) => {
            return moment(data.value).format("YYYY-MM-DD");
          },
        }
      ])
    );

    let generatedcolDefs = this.generateColDefs(selectedAttributes);
    generatedcolDefs.forEach(colDef => {
      colDefs.push(colDef);
    });

    colDefs.forEach((def) => {
      def.minWidth = tableWidth / colDefs.length;
    });

    return colDefs;
  };

  public createColumnDefs(
    selectedAttributes: ActivityAttribute[],
    tableWidth: number
  ): ColDef[] {
    let colDefs: ColDef[] = [];

    colDefs.push(
      ...(<ColDef[]>[
        {
          headerName: this.translate.instant(
            this.translateKeys.PURCHASE_DATE
          ),
          field: "PurchaseDate",
          resizable: true,
          sortable: true,
          sort: "asc",
          cellRenderer: (data) => {
            return moment(data.value).format("YYYY-MM-DD");
          },
        }
      ])
    );

    let generatedcolDefs = this.generateColDefs(selectedAttributes);
    generatedcolDefs.forEach(colDef => {
      colDefs.push(colDef);
    });

    colDefs.forEach((def) => {
      def.minWidth = tableWidth / colDefs.length;
    });

    return colDefs;
  };

  private generateColDefs(selectedAttributes: ActivityAttribute[]): ColDef[]{
    let colDefs: ColDef[] = [];
    selectedAttributes.forEach((attr) => {
      colDefs.push(<ColDef>{
        headerName: attr.AliasAttributeName,
        field: attr.RealFieldName,
        resizable: true,
        sortable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA == valueB) return 0;

          const numA = Number.parseFloat(valueA);
          const numB = Number.parseFloat(valueB);
          if (numA && numB) {
            return numA > numB ? 1 : -1
          }

          if (!valueA) {
            return -1;
          }
          if (!valueB) {
            return 1;
          }
          return valueA.localeCompare(valueB);
        },
        cellRenderer: (data) => {
          return ["decimal", "int", "number"].includes(
            attr.AttributeDataType.toLowerCase()
          )
            ? `${this.formatter.transform(
              !data.value ? 0 : data.value,
              attr.AttributeFormat,
            )}`
            : `${data.value === undefined ? '' : data.value}`;
        },
      });
    });

    return colDefs;
  }
}
