import {Component, OnInit, OnDestroy, OnChanges, Input , ViewEncapsulation } from "@angular/core";
import { SsmService } from "../../services/ssm.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import {IModalConf} from "../optiLogicModal/optiLogicModal.service";

@Component({
    selector: 'about-optimove-component',
    templateUrl: './about.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./about.component.scss']
})

export class AboutOptimoveComponent {

    optimoveVersion : string;
    modalConf: IModalConf;

    constructor(private ssm: SsmService,public bsModalRef: BsModalRef){    }

    ngOnInit(): void {
        this.getVersion();
        this.setModalConf();

    }

    setModalConf(): void {
        this.modalConf = {
            hideFooterBorder: true,
            hideHeaderBorder: true,
            hideHeaderPanel: true,
            hideFooterPanel: true
        };
    }

    getVersion() : void {
        const SSM = this.ssm.getSSM();
        this.optimoveVersion = SSM.GetGeneric(SSM.Resx.Version);
    }

    onCancel(): void {
        this.bsModalRef.hide();
    }
}