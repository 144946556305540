
export class BrandWithAudit {
    BrandName: string;
    IsOptedIn: boolean;
    count: number;
    constructor(BrandName, IsOptedIn, count) {
        this.BrandName = BrandName;
        this.IsOptedIn = IsOptedIn;
        this.count = count;
    }
}