<div class="content-tags-modal">
  <div class="opti-modal-window opti-logic-modal">
    <div class="panel panel-default">
      <div class="left-container">
        <div class="content-tags-title">
          <h2>
            {{ "features.manage_templates.optimail.contentTags.CONTENT_TAGS" | translate }}
          </h2>
        </div>
        <div class="content-tags-list">
          <div class="content-ag-grid" [ngClass]="{'content-tags-loading': isContentTagsLoading}">
            <ag-grid-angular class="ag-theme-clean content-tag-list" [gridOptions]="gridOptions1" [modules]="modules"></ag-grid-angular>
          </div>
          <div class="tags-loading-container" *ngIf="isContentTagsLoading">
            <div class="ag-grid-loader-container">
              <span class="click-loader ag-grid-loader-wrapper">
                <span class="loader ag-grid-loader"></span>
              </span>
            </div>
            <div class="loading-message">Loading</div>
          </div>
        </div>
        <div class="add-user-outer-height pull-left btn btn-link add-user-btn create-new-button">
          <span (click)="createNewClicked()">{{ "features.manage_templates.optimail.contentTags.CREATE_NEW_TAG" | translate }}</span>
        </div>
      </div>
      <div class="empty-border"></div>
      <div *ngIf="!isContentTagsLoading" class="right-container">
        <div>
          <div class="content-tags-editor">
            <div class="content-tags-fields">
              <div *ngIf="!this.isEditModeContentTag && this.contentTags && this.contentTags.length > 0">
                <div class="edit-name-container">
                  <div>
                    <span class="non-edit-mode-name">{{ name }}</span>
                  </div>
                  <div *ngIf="isAdmin" class="btn btn-secondary cancel-button ng-binding edit-button" (click)="editContent()">
                    <i class="material-icons">edit</i>
                    <span>{{ "features.manage_templates.optimail.contentTags.EDIT_CONTENT" | translate }}</span>
                  </div>
                  <div *ngIf="!!chosenRow" class="btn btn-primary action-button ng-binding last-in-row-button" (click)="selectTag()">
                    <span>{{ "features.manage_templates.optimail.contentTags.INSERT_TAG" | translate }}</span>
                  </div>
                </div>
                <div class="non-edit-container">
                  <div [innerHTML]="innerHtml" class="text-input non-edit-mode-content"></div>
                </div>
              </div>
              <div class="edit-mode" *ngIf="this.isEditModeContentTag">
                <div
                  [ngClass]="{
                    'name-container-no-margin':
                      contenttagname.errors?.nameUnique ||
                      contenttagname.errors?.nameContainsSpaces ||
                      (contenttagname.dirty && name.length < 1) ||
                      name.length > 40
                  }"
                  class="name-contianer"
                  class="name-contianer">
                  <input
                    #contenttagname="ngModel"
                    required
                    uniqueContentTagName
                    [contentTagList]="contentTags"
                    [currentContentTag]="chosenRow"
                    minlength="1"
                    [skipValidation]="!isCreateNew"
                    [readOnly]="!isCreateNew"
                    [disabled]="!isCreateNew"
                    class="form-control input-field name-input"
                    type="text"
                    placeholder="{{ 'features.manage_templates.optimail.contentTags.CONTENT_TAG_NAME' | translate }}"
                    [(ngModel)]="name"
                    [ngClass]="{'ng-invalid': name.length > 40}" />
                  <span *ngIf="contenttagname.errors?.nameUnique" class="contenttag-name-error-message">
                    {{ contenttagname.errors?.nameUnique | translate }}
                  </span>
                  <span *ngIf="contenttagname.errors?.nameContainsSpaces" class="contenttag-name-error-message">
                    {{ contenttagname.errors?.nameContainsSpaces | translate }}
                  </span>
                  <span *ngIf="contenttagname.dirty && name.length < 1" class="contenttag-name-error-message">
                    {{ "features.manage_templates.optimail.contentTags.NAME_REQUIRED" | translate }}
                  </span>
                  <span *ngIf="name.length > 40" class="contenttag-name-error-message">
                    {{ "features.manage_templates.optimail.contentTags.NAME_TOO_LONG" | translate }}
                  </span>
                </div>
                <div class="content-input-header">
                  <div class="personalization-button-container">
                    <button class="personalization-button" (click)="insertLinkClick()">
                      <i class="icon-link-variant button-icon"></i>
                      <span class="button-text">Insert link</span>
                    </button>
                  </div>
                  <div class="personalization-button-container">
                    <button class="personalization-button" (click)="personalizationTagsClick()">
                      <i class="material-icons button-icon">portrait</i>
                      <span class="button-text">{{ "features.manage_templates.optimail.contentTags.PERSONALIZATION" | translate }}</span>
                    </button>
                  </div>
                  <div class="personalization-button-container">
                    <button
                      #unsubscribeoverlay
                      class="personalization-button"
                      (click)="showSubscriptionOverlay = !showSubscriptionOverlay"
                      cdkOverlayOrigin
                      #trigger="cdkOverlayOrigin">
                      <i class="material-icons button-icon">block</i>
                      <span class="button-text">{{ "features.manage_templates.components.subscriptions.SUBSCRIPTION" | translate }}</span>
                    </button>
                    <ng-template
                      cdkConnectedOverlay
                      [cdkConnectedOverlayOrigin]="trigger"
                      [cdkConnectedOverlayOpen]="showSubscriptionOverlay">
                      <div class="unsubscribe-overlay">
                        <div *ngFor="let item of unsubscribeTags">
                          <div>
                            <span class="overlay-name">
                              {{ item.Name }}
                            </span>
                            <div *ngFor="let utag of item.SuppressionTemplates">
                              <a class="overlay-value" (click)="unsubscribeTagSelected(utag)">{{ utag.name }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="{'error-content': content.length > 100000}"
                  class="form-control input-field name-input textarea-wrapper-container">
                  <div class="textarea-wrapper">
                    <textarea
                      id="textarea-content"
                      *ngIf="this.isEditModeContentTag"
                      class="text-input-edit"
                      type="text"
                      [(ngModel)]="content"></textarea>
                  </div>
                </div>
                <span class="user-hint">
                  Use
                  <span class="user-hint-br">&lt;BR&gt;</span>
                  to start a new line
                </span>
                <span class="remaning" [ngClass]="{'error-content-text': content.length > 100000}">
                  {{ numberFormatWithComma() }} /100,000
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.contentTags && this.contentTags.length === 0 && !isCreateNew">
          <div class="content-tags-editor">
            <div class="no-content-tags">
              <h1>{{ "features.manage_templates.optimail.contentTags.NO_CONTENT_TAGS" | translate }}</h1>
              <h2>{{ "features.manage_templates.optimail.contentTags.NO_TAGS_MESSAGE" | translate }}</h2>
            </div>
          </div>
        </div>
        <button type="button" class="close-modal btn btn-link btn-link-default" (click)="onCloseClick()">
          <span class="material-icons" aria-hidden="true">close</span>
        </button>
        <div *ngIf="this.isEditModeContentTag" class="controls col-md-6 pull-right text-right footer-buttons">
          <ul class="list-inline">
            <li>
              <div class="btn btn-default">
                <span (click)="cancelClicked()">{{ "general.CANCEL" | translate }}</span>
              </div>
            </li>
            <li>
              <loading-button
                [showLoader]="false"
                (onClick)="saveTagClicked()"
                [isDisabled]="isSaveButtonDisabled()"
                [buttonCssClass]="'btn btn-primary'"
                [loadingCssClass]="'saving-loader'"
                [loadingText]="'Saving'"
                [buttonText]="'Save tag'"></loading-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="loader-container" *ngIf="isContentTagsLoading">
        <span class="click-loader loader-wrapper">
          <span class="loader preview-loader"></span>
        </span>
      </div>
    </div>
  </div>
  <div>
    <toast
      [(isOpen)]="toastParams.isOpen"
      [icon]="toastParams.icon"
      [location]="toastParams.location"
      [message]="toastParams.message"
      [showCloseIcon]="toastParams.showCloseIcon"
      [toastIconType]="toastParams.toastIconType"></toast>
  </div>
</div>
