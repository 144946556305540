<div class="add-Unique-promo-code-container" data-qa-id="cy_add-new-app-page">
  <div class="panel-heading">
    <div class="add-Unique-promo-code-header">
      <div class="edit-wrapper">
        <div class="heading-col1 back-button">
          <a
            data-qa-id="cy_add_unique_promo_back_button"
            class="btn btn-link"
            href="/#/user-settings/uniquePromoCodes"
          >
            <i class="material-icons">arrow_back</i>
            <span>{{ "general.BACK" | translate }}</span>
          </a>
        </div>
        <div
          class="heading-col2 edit-title"
          data-qa-id="cy_promotion_edit_add_title"
        >
          <h2
            placement="bottom"
            [tooltip]="tooltipDescription"
            [isDisabled]="!editMode"
          >
            {{ promotionTitle }}
          </h2>
          <ng-template #tooltipDescription>
            <div [innerHtml]="promotionTitle"></div>
          </ng-template>
        </div>
        <div
          class="heading-col2 edit-status"
          *ngIf="editMode"
          data-qa-id="cy_promotion_status"
        >
          <colored-tag [coloredTagType]="coloredTagType" *ngIf="coloredTagType">{{
            editPromotionStatus
          }}</colored-tag>
        </div>
      </div>
      <div
        class="pull-right text-right available-codes-wrapper"
        *ngIf="editMode"
      >
        <div
          class="available-codes-text"
          data-qa-id="cy_promotion_available_codes_title"
        >
          <p>
            <small>{{
              "features.user_settings.body.uniquePromoCodes.addUniquePromoCode.REMAINING_CODES"
                | translate
            }}</small>
          </p>
        </div>
        <div
          class="available-codes-number"
          data-qa-id="cy_promotion_available_codes_count"
        >
          <h3 class="available-codes-number">{{ availableCodes }}</h3>
        </div>
      </div>
    </div>
  </div>


    <div class="panel-body">
         <form [formGroup]="addUniquePromoCodeForm" class="add-unique-promo-code-form">
            <div *ngIf="!editMode" class="add-unique-promo-row add-unique-promo-row-margin-med">
                <div class="add-unique-promo-label">
                    {{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.PROMOTION_NAME' | translate}}
                </div>
                <div class="add-unique-promo-input-with-validation" data-qa-id="cy_addUniquePromoNameWithValidation">
                    <input
                    id="promotionName"
                    formControlName="promotionName"
                    (change)="onPromotionNameChanged()"
                    [placeholder]="'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ENTER_UNIQUE_NAME'|translate" class="form-control"/>
                    <div *ngIf="promotionName.invalid && promotionName.touched && promotionName.errors.required" class="validation-error" data-qa-id="cy_ValidationErrorEmptyPromotionName">{{'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.validationErrors.PROMOTION_NAME_REQUIRED' | translate}}</div>
                </div>
            </div>
            <div class="add-unique-promo-row">
                <div class="add-unique-promo-label">
                    {{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.DEFAULT_PROMO_CODE' | translate}}
                </div>
                <div class="add-unique-promo-value" data-qa-id="cy_addPromotionCodeValue">
                    <input
                    formControlName="defaultPromoCode"
                    [placeholder]="'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.OPTIONAL' |translate" class="form-control"/>
                </div>
            </div>
            <div class="add-unique-promo-row add-unique-promo-row-margin">
                <p class="caption">
                    <small>{{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.DEFAULT_PROMO_CODE_CAPTION' | translate }}</small>
                </p>
            </div>
            <div class="add-unique-promo-row hidden"> <!-- hidden in US 163700 -->
                <div class="add-unique-promo-label">
                    {{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.NOTIFICATION' | translate}}
                </div>
                <div class="switchContainer" data-qa-id = "cyAddNotificationSwitch">
                    <switch [buttonType]="1"
                     formControlName="isNotification" (ngModelChange)="onIsNotificationChange($event)">
                    </switch>
                </div>
            </div>
            <div class="add-unique-promo-row add-unique-promo-row-margin hidden"> <!-- hidden in US 163700 -->
                <p class="caption no-margin-bottom no-margin-top">
                    <small>{{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.NOTIFICATION_CAPTION' | translate }}</small>
                </p>
            </div>
            <div class="notification-settings-container add-unique-promo-row-margin" *ngIf="isNotificationControl.value">
                <div class="add-unique-promo-row">
                    <div class="notification-settengs-label">
                        {{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.EMAIL_ADDRESS' | translate}}
                    </div>
                    <div class="add-unique-promo-input-with-validation" data-qa-id="cy_AddPromotionEmail">
                        <input
                        formControlName="email"
                        [placeholder]="'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.EMAIL_FOR_NOTIFICATION' |translate" class="form-control"/>
                    </div>
                    <div class="notification-email-errors">
                        <div *ngIf="email.invalid && email.touched && email.errors.required" class="validation-error" data-qa-id="cy_ValidationEmptyEmail">{{'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.validationErrors.EMAIL_REQUIRED' | translate}}</div>
                        <div *ngIf="email.errors?.['notValidEmail']" class="validation-error" data-qa-id="cy_ValidationErrorInvalidEmail">{{'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.validationErrors.VALID_EMAIL' | translate}}</div>
                        <div *ngIf="email.errors?.['multipleAddressesError']" class="validation-error" >{{'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.validationErrors.MULTIPLE_ADDRESSES_ERROR' | translate}}</div>
                    </div>
                </div>
                <div class="add-unique-promo-row add-unique-promo-row-margin">
                    <p class="caption no-margin-bottom">
                        <small>{{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.EMAIL_ADDRESS_CAPTION' | translate }}</small>
                    </p>
                </div>
                <div class="add-unique-promo-row add-unique-promo-row-margin add-unique-promo-row-margin-big">
                    <div class="notification-settengs-label">
                        {{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.THRESHOLD' | translate}}
                    </div>
                    <div class="add-unique-promo-number-input" data-qa-id="cy_AddPromotionThreshold">
                        <input only-digits type="number" min="0" class="form-control" formControlName="threshold"  (blur)="OnLostThresholdFocus()">
                    </div>
                </div>
            </div>
            <div class="add-unique-promo-row load-csv-file-header">
                <div class="heading-col2">
                    <h3 class="no-margin-top no-margin-bottom"> {{'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.IMPORT_UNIQUE_CODES' | translate }} </h3>
                </div>
            </div>
            <div class="add-unique-promo-row">
                <p class="caption">
                    <small data-qa-id="cy_CSV_CAPTION">{{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.CSV_CAPTION' | translate }}</small>
                </p>
            </div>
            <div class="add-unique-promo-row" *ngIf="!this.fileSelected">
                <div class="load-csv-file-row">
                    <button class="btn btn-secondary" data-qa-id="cyChooseFile" (click)="uploadCSV()">{{ 'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.CHOOSE_FILE' | translate }}</button>
                    <input type="file" id="file" accept=".csv" class="upload-file-input" formControlName="file"
                           (change)="handleFileCsvInput($event)" #csvFileInput>
                </div>
            </div>
            <div class="finish-load-csv" *ngIf="this.fileSelected">
                <p class="file-name">
                    {{ this.csvFile.name }}
                    <a class="btn btn-link btn-link-default delete-button" (click)="reset()" id="cyDeleteButton">
                        <i class="material-icons" >delete_forever</i>
                    </a>
                </p>
            </div>
        </form> 
    </div>
    <div class="panel-footer panel-footer-bottom-stick footer-btns">
        <div class="controls col-md-6 pull-right text-right">
            <ul class="list-inline">
                <li>
                    <a class="btn btn-default" data-qa-id="cy_CancelButton" href="/#/user-settings/uniquePromoCodes">
                        <span>Cancel</span>
                    </a>
                </li>
                <li>
                     <loading-button [showLoader]="isSaving" (onClick)="onSubmit()"
                        [isDisabled]="!this.addUniquePromoCodeForm.valid || !hasChange" [buttonCssClass]="'btn btn-primary'" data-qa-id="cy_CreatePromotionButton"
                        [loadingCssClass]="'saving-loader'" [loadingText]="'features.user_settings.body.uniquePromoCodes.addUniquePromoCode.SAVING'|translate"
                        [buttonText]=saveOrCreateBtn>
                    </loading-button> 
                </li>
            </ul>
        </div>
        <div *ngIf="editMode" class="controls pull-left text-left hidden"> <!-- hidden in US 163700 -->
            <ul class="list-inline">
                <li>
                    <a class="btn btn-default btn-secondary" data-qa-id="cy_DeleteButton" href="/#/user-settings/uniquePromoCodes">
                        <span>{{ 'general.DELETE'| translate }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <opti-loading [show]="loading" [size]="'md'" ></opti-loading>
</div>