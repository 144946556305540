<div class="channel-metrics-action">
    <channel-metrics-action-header [actionName]="action.actionName"
                                   [actionType]="action.actionType">
    </channel-metrics-action-header>
    <div *ngFor="let channel of action.channels; let i = index">
        <div [style.height.px]="i > 0 ? '24' : '14'"></div>
        <channel-metrics-bar [channelData]="channel"
                             [isScheduledCampaign]='isScheduledCampaign'
                             [actionType]="action.actionType"
                             [actionName]="action.actionName"
                             [datesData]="datesData"
                             [planIdActionSerial]='planIdActionSerial'
                             [templateDialogClass]="templateDialogClass"
                             (metricClick)="onMetricClick($event)">
        </channel-metrics-bar>
    </div>
    <div class="empty-channel" *ngIf="!action.channels?.length">
        <span class="empty-channel-text">
            {{'features.campaign_analysis.channelMetrics.UNAVAILABLE'  | translate}}
        </span>
    </div>
</div>
