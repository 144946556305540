
<div class="create-api-key-container" data-qa-id="createApiKey">
    <div class="panel-heading">
        <div class="row">
            <div class="col-md-1 back-button back-btn">
                <a data-qa-id="backBtn" class="btn btn-link" [routerLink]="['/user-settings/apiKeyManagement']"
                [routerLinkActive]="['active']">
                    <i class="material-icons">arrow_back</i>
                    <span>{{ translateKeys.back | translate }}</span>
                </a>
            </div>
            <div class="col-md-11" data-qa-id="createApiKeyTitle">
                <h2> {{ translateKeys.title| translate }} </h2>
            </div>
        </div>
    </div>

    <form [formGroup]="form" data-qa-id="parentFrom">
        <api-key-details [form]="form" #apiKeyDetails [apiKeyExistsMsg]="apiKeyExistsMsg"></api-key-details>
        <div class="panel-footer panel-footer-bottom-stick footer-btns">
            <div class="controls pull-right text-right">
                <ul class="list-inline">
                    <li>
                        <button type="button" class="btn btn-default ng-binding" data-qa-id="cancelBtn" 
                        (click)="showLeavingModal(this.leavingMsg)">
                            <span>{{ translateKeys.cancel| translate}}</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-qa-id="activateKeyBtn" (click)="onSubmit()" 
                        class="btn btn-default btn-primary ng-binding" [disabled]="(!this.form.valid) ? true : null">
                            <span>{{ translateKeys.activateKey| translate}}</span>
                        </button>  
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
<toast [(isOpen)]="toastParams.isOpen"
    [icon]="toastParams.icon"
    [location]="toastParams.location"
    [message]="toastParams.message"
    [showCloseIcon]="toastParams.showCloseIcon"
    [toastIconType]="toastParams.toastIconType">
</toast>

