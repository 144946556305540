import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from '../../../components/components.module';
import { OptiModalModule } from '../../../modules/OptiModal.module';
import { SharedModule } from '../../../shared.module';
import { ApiUserDetails } from "../apiManagement/ApiUserDetails/apiUserDetails.component";
import { CreateApiUser } from '../apiManagement/CreateApiUser/createApiUser.component';
import { UpdateApiUserPopupComponent } from "./UpdateApiUser/updateApiUserPopup.component";
import { ApiManagementComponent } from './apiManagement.component';
import { ApiManagementService } from './apiManagement.service';

@NgModule({
  declarations: [
    ApiManagementComponent,
    UpdateApiUserPopupComponent,
    CreateApiUser,
    ApiUserDetails
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    OptiModalModule
  ],
  providers: [ApiManagementService],
  entryComponents: [
    ApiManagementComponent,
    UpdateApiUserPopupComponent,
    CreateApiUser,
    ApiUserDetails
  ]
})

export class ApiManagementModule { }