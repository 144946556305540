import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularRouterUIRouterAdapter } from './angularRouterUIRouterAdapter.service';

@Injectable()
export class AuthorizedService implements CanActivate {
	constructor(
		private readonly routingAdapter: AngularRouterUIRouterAdapter,
		private readonly router: Router,
		private readonly ngZone: NgZone
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.checkIfUnauthorized(state);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.canActivate(route, state);
	}

	private checkIfUnauthorized(state: RouterStateSnapshot): Promise<boolean> {
		const path = state.url.toString();
		return this.routingAdapter.isUnauthorized(path).then((isUnauthorized: boolean) => {
			if (isUnauthorized) {
				this.ngZone.run(() => this.router.navigate(['unauthorized'], { replaceUrl: true }));
				return false;
			}
			return this.ngZone.run(() => true);
		});
	}
}
