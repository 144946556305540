import { Component, OnInit, OnDestroy, SimpleChanges, Input, OnChanges, ViewEncapsulation, isDevMode } from "@angular/core";
import {Router, NavigationEnd} from "@angular/router";
import { Subscription } from "rxjs";
import { FeatureFlag, FeatureFlagService } from "src/app/services/featureFlag.service";

@Component({
    selector: 'iframe-container',
    templateUrl: './iframeContainer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class IframeContainerComponent implements OnInit, OnDestroy, OnChanges {
    
    @Input() url: string;
    @Input() search: string;
    @Input() absoluteurl: boolean;

    pageUrl: string = '';

    private events: string[] = ['mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll'];
    private navigated$: Subscription;
    private methodsMap: Dictionary<() => (void)> = {};

    constructor(
        private router: Router,
        private featureFlagsService: FeatureFlagService
        ) {
    }
    
    ngOnInit(): void {
        this.update();
        this.navigated$ = this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.viewContentLoaded();
            }
        });
    }

    ngOnDestroy(){
        let iframe = document.getElementById('legacyIframe') as HTMLIFrameElement
        this.navigated$ && this.navigated$.unsubscribe();
        for (var i = 0; i < this.events.length; i++) {
            try {
                var curr = this.events[i];
                iframe.contentWindow.removeEventListener(curr, this.methodsMap[curr]);
                delete this.methodsMap[curr];
            }
            catch (_ex) {
                console.warn('faild to remove event listener');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.url || this.search) {
            this.update();
        }
    }
    
    private update(){
        let url = this.url ? this.url : 'Dashboard.aspx';
        let urlNoQuery = url.indexOf('?') !== -1 ? url.split('?')[0] : url;
        let actualUrl = this.getPageUrl(urlNoQuery);
        let actualSearch = this.search ? this.search : url.split('?').slice(1).join('?');
        actualSearch = this.trim(actualSearch, '?');
        if (actualSearch){
            this.pageUrl = `${actualUrl}?${actualSearch}`;
        } else {
            this.pageUrl = actualUrl;
        }
    }

    private getPageUrl(pageName: string): string {
        if (pageName.toLowerCase().indexOf('aspx') === -1 || pageName.toLowerCase().indexOf('main.aspx') !== -1) {
            return this.absoluteurl ? pageName : '/' + pageName;
        }
        let pageNameNoSuffix = pageName.split('.aspx')[0];
        let pageNameNoSuffixOrPrefix = this.trim(pageNameNoSuffix, "/");
        return `iframes/${pageNameNoSuffixOrPrefix}.html`;
    }

    private trim(str: string, char: string): string {
        if (!str){
            return str;
        }
        while (str.startsWith(char)) {
            str = str.slice(1);
        }
        while (str.endsWith(char)){
            str = str.slice(0, -1);
        }
        return str;
    }

    private viewContentLoaded() {
        let iframe = document.getElementById('legacyIframe') as HTMLIFrameElement
        iframe.onload = () => {
            for (var i = 0; i < this.events.length; i++) {
                try {
                    var curr = this.events[i];
                    iframe.contentWindow.addEventListener(curr, this.invokeParentEvent(curr));
                }
                catch (_ex) {
                    console.log('faild to add event listener');
                }
            }
        }
    }

    private invokeParentEvent(curr) {
        if (!this.methodsMap[curr]) {
            this.methodsMap[curr] = () => {
                curr = curr === 'mousemove' ? 'mousewheel' : curr;
                let event = new Event(curr);
                let htmlElement = parent.document.getElementsByTagName('html')[0];
                htmlElement.dispatchEvent(event);
            }    
        }
        return this.methodsMap[curr];
    }
}

interface Dictionary<T> {
    [Key: string]: T;
}
