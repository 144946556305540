import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PermissionFormModel, PermissionsConfigFormModel, RoleRepmissionInfo, TenantRolesInfo, UserPermission } from './rolePermissions.model';
import { RolePermissionsService } from './services/rolePermissions.service';
import { IModalButtonConf, IModalConf, OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service'
import { ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsManagerComponent } from './components/permissionsManager.component';

@Component({
  selector: 'role-permissions',
  templateUrl: './rolePermissions.component.html',
  styleUrls: ['./rolePermissions.component.scss']
})

export class RolePermissionsComponent implements OnInit {
  @Input() roleNames: string[];
  @Input() editedRoleName: string;
  @Input() tenantRoles: TenantRolesInfo;
  @Input() restrictedPermissions: Record<number, number>;
  @Input() allPermissions: UserPermission[];
  @Output() updateAllUsersWithNewPermissionsData = new EventEmitter<any>();
  @ViewChild(PermissionsManagerComponent, {static: false}) newPermissionManager: PermissionsManagerComponent;

  public isInSavingProcess: boolean = false;
  public isFormPristine: boolean = true;
  public newPermissionsData: PermissionFormModel[];
  public landingPage: string;
   
  constructor(
    private rolePermissionsService: RolePermissionsService,
    private modalService: OptiLogicModalService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    
  }

  public onRoleNameChange(roleName: string){
    this.editedRoleName = roleName;
    this.isFormPristine = true;
  }

  public onPermissinsFormChange(permissionsConfig: PermissionsConfigFormModel){
    this.landingPage = permissionsConfig.landingPage;
    this.newPermissionsData = permissionsConfig.permissions;
    this.isFormPristine = false;
  }

  public saveRoleDefaultPermissions(withUsers) {
    if (withUsers) {
        let roleName = this.editedRoleName;
        const modalConf = this.getModalConf(roleName, withUsers);
        this.modalService.openModalMessage(
          'sm',
          modalConf,
          <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
        );
    } else {
        this.performSaveRoleDefaultPermissions(withUsers);
    }
  }

  public cancelChanges(){
    this.newPermissionManager.onCancelClick();
    this.isFormPristine = true;
  }

  private performSaveRoleDefaultPermissions(withUsers) {
    this.isInSavingProcess = true;
    const newPermissions = this.getNewPermissionsList(this.newPermissionsData);
    this.rolePermissionsService.updateTenantRoles(this.editedRoleName, this.landingPage, newPermissions, withUsers).subscribe(res => {
      if(res.IsSuccess){
        this.isInSavingProcess = false;

        this.tenantRoles[this.editedRoleName].LandingPage = this.landingPage;
        this.tenantRoles[this.editedRoleName].Permissions = newPermissions;
        this.openSuccessModal(withUsers, this.editedRoleName, newPermissions, this.landingPage);
        this.isFormPristine = true;
      }else {
        this.isInSavingProcess = false;
        
        this.openErrorModal();
      }
    })     
  }

  private getNewPermissionsList(permissionsList: PermissionFormModel[]) {
    let finalPermissions = {}
    permissionsList.forEach(permission => finalPermissions[permission.permissionId] = permission.isSelected ? 2 : 0);
    return finalPermissions;
  }

  getModalConf(roleName: string, withUsers: boolean): IModalConf {
    return {
      title: this.translate.instant('features.user_settings.body.manage_users.edit.modals.UPDATE_MODAL_TITLE'),
      message: this.translate.instant('features.user_settings.body.manage_users.edit.modals.UPDATE_MODAL_MESSAGE', {roleName: roleName}),
      buttons: this.getVisualButtonsConf(roleName, withUsers)
    };
  }

  getVisualButtonsConf(roleName: string, withUsers: boolean): IModalButtonConf[] {
    return [
      {
        class: 'btn-primary',
        label: this.translate.instant('features.user_settings.body.manage_users.edit.modals.UPDATE_MODAL_PRIMARY_BUTTON', {roleName: this.editedRoleName}),
        action: this.performSaveRoleDefaultPermissions.bind(this, withUsers)
      },
      {
        class: 'btn-default',
        label: this.translate.instant('general.CANCEL'),
        action: () => {
        }
      }
    ];
  }

  private openSuccessModal(withUsers: boolean, roleName: string, newPermissions: any, landingPage: string) {
    this.modalService.openModalMessage(
      'sm',
      {
        message: this.translate.instant('features.user_settings.body.manage_users.edit.modals.SUCCESS_MODAL_MESSAGE'),
        buttons: [
          {
            class: 'btn-primary',
            label: this.translate.instant("general.OK"),
            action: () => {
              if (withUsers) {
                this.updateAllUsersWithNewPermissionsData.emit({roleName: roleName, newPermissions: newPermissions, landingPage: landingPage});
              }
            }
          }
        ]
      }
    );
  }

  private openErrorModal() {
    this.modalService.openModalMessage(
      'sm',
      {
        message: this.translate.instant('features.user_settings.body.manage_users.edit.modals.ERROR_MODAL_MESSAGE'),
        buttons: [
          {
            class: 'btn-primary',
            label: this.translate.instant("general.OK"),
            action: () => {
            }
          }
        ]
      }
    );
  }
}