import { AllCommunityModules, GridApi, GridOptions, Module } from "@ag-grid-enterprise/all-modules";
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as moment from 'moment';
import { BsModalRef } from "ngx-bootstrap/modal";
import { exhaustMap, first } from 'rxjs/operators';
import { AttributeHelper } from "../../../features/settings/attributes/attributesList.helper";
import { TableBaseAttribute, TableCalculatedAttribute, TableConditionalAttribute, TableFormulaBasedAttribute } from "../../../features/settings/attributes/attributesList.model";
import { TypeCellRendererComponent } from "../../../features/settings/attributes/components/typeCellRenderer/typeCellRenderer.component";
import { AttributesListService } from "../../../features/settings/attributes/services/attributesList.service";
import { SsmService } from "../../../services/ssm.service";
import { IModalConf } from "../../optiLogicModal/optiLogicModal.service";

@Component({
    selector: 'customer-attributes',
    templateUrl: './customerAttributes.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./customerAttributes.component.scss']
})
export class CustomerAttributesComponent implements OnInit {
    modalConf: IModalConf;
    gridOptions: GridOptions;
    gridApi: GridApi;
    modules: Module[] = AllCommunityModules;

    private isAdmin: boolean;

    public attributes = [];
    public translateKeys = translateKeys;

    constructor(
        private attrService: AttributesListService,
        private bsModalRef: BsModalRef,
        private ssmService: SsmService,
        private readonly cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.setModalConf();
        this.setGrid();
        this.getUserRole();
        this.attrService.getAttributesTags()
        .pipe(exhaustMap(() => this.attrService.getInternalFieldsList()))
        .subscribe(() => {
            this.loadAttributes();
            this.cd.detectChanges();
        });
    }

    private setModalConf() {
        this.modalConf = {
            xButton: {
                action: () => { this.bsModalRef.hide(); }
            },
            hideFooterBorder: true,
            hideFooterPanel: true,
            hideHeaderBorder: false,
            withoutMinHeight: false,
        };
    }

    private getUserRole() {
        let sessionStorageManager = this.ssmService.getSSM();
        this.isAdmin = sessionStorageManager.GetGeneric(sessionStorageManager.Resx.TenantDetails)?.userRole?.includes("Admin");
    }

    private setGrid() {
        this.gridOptions = <GridOptions>{
            defaultColDef: {
                sortable: true,
                resizable: true
            },
            columnDefs: [
                { 
                    headerName: 'Real Field Name', 
                    field: 'Name', 
                    hide: true, 
                    pinned: 'top'
                },
                { 
                    headerName: 'Attribute Name', 
                    field: 'DisplayName', 
                    initialSort: 'asc', 
                    comparator: AttributeHelper.stringComparator, 
                    pinned: 'top' 
                },
                { 
                    headerName: 'Description', 
                    field: 'Description', 
                    comparator: AttributeHelper.stringComparator, 
                    pinned: 'top' 
                },
                { 
                    headerName: 'Type',
                    comparator: AttributeHelper.typeComparator, 
                    cellRendererFramework: TypeCellRendererComponent, 
                    initialWidth: 75, 
                    pinned: 'top' 
                }
            ],
            context: this,
            headerHeight: 50,
            rowHeight: 35,
            animateRows: false,
            rowDragManaged: false,
            rowSelection: 'single',
            suppressDragLeaveHidesColumns: true,
            suppressCellSelection: true,
            suppressHorizontalScroll: true,
            suppressVerticalScroll: true,
            colResizeDefault: 'shift',
            onGridReady: (params) => {
                this.gridApi = params.api;
                this.gridApi.sizeColumnsToFit();
            }
        };
    }

    public onFilter(filterText: string) {
        this.gridApi.setQuickFilter(filterText);
    }

    public exportToCsv() {
        const fileName = this.getCsvFileName();
        this.gridApi.exportDataAsCsv({ fileName: fileName,  allColumns: true});
    }

    private getCsvFileName() {
        const ssm = this.ssmService.getSSM();
        var siteName = ssm.GetGeneric(ssm.Resx.SiteName).replace(/"/g, "");
        var date = moment().format('YYYY-MM-DD');
        return `${siteName} Customer Attributes ${date}.csv`;
    }

    private loadAttributes() {
        this.attrService.getAttributes().pipe(first())
        .subscribe((res: (TableBaseAttribute | TableCalculatedAttribute | TableFormulaBasedAttribute | TableConditionalAttribute)[]) => {
            this.attributes = res.filter(x => (this.isAdmin ? true : !x.IsHidden) || x.Type === "realtime");
        });
    }
}

const translateKeys = {
    TITLE: "components.glossary.header.TITLE",
    DESCRIPTION: "components.glossary.header.DESCRIPTION",
}