import { Injectable } from '@angular/core';
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from 'rxjs';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { RoleRepmissionInfo, TenantRolesInfo, UserPermission } from '../rolePermissions.model';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionsService {

  private userSettingsControllerURL = '/UserSettings';
  private userPermissions: UserPermission[];

  constructor(
    private readonly http: HttpService
  ) { }

  public createPermissionsObj(entityPermissions: TenantRolesInfo, restrictedPermissions: Record<number, number>, permisionsList: UserPermission[]): RoleRepmissionInfo[] {
    return permisionsList.map(permissionItem => ({
      id: permissionItem.permissionId,
      permissionName: permissionItem.permissionName,
      selected: this.isSelected(entityPermissions[permissionItem.permissionId]),
      restricted: restrictedPermissions && this.isRestricted(restrictedPermissions[permissionItem.permissionId])
    } as RoleRepmissionInfo));  
  }

  private isSelected(item) {
    if (item && Number.isInteger(item) && item > 0) {
        return true;
    } else {
        return false;
    }
  }

  private isRestricted(item) {
    if (Number.isInteger(item) && item <= 0) {
        return true;
    } else {
        return false;
    }
  }

  public updateTenantRoles(roleName, landingPage, newPermissions, withUsers): Observable<DefaultAjaxResponse> {
    const url = `${this.userSettingsControllerURL}/UpdateTenantRoles`;
    let roles = {};
    roles[roleName] = { LandingPage: landingPage, Permissions: newPermissions };
    let updateRequest = { roles: roles, updateRoleUsers: withUsers };
    return this.http.post<DefaultAjaxResponse>(url, updateRequest);
  }
}
