import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { ValidationErrorModelComponent } from '../editors/dialogs/validationErrorModel/validationErrorModel.component';
import { RoutingConsts } from '../models/routing.consts';
import { TemplateDetails } from '../models/templateDetails';
import {
	ErrorMessage,
	isTemplateSaveSuccessResponse,
	TemplateSaveUpdateErrorResponse,
	TemplateSaveUpdateRequest,
	ValidationOutput
} from '../models/templateSaveUpdate';
import { TemplateScriptGuardModalComponent } from '../TemplateScriptGuardContainer/TemplateScriptGuardModal/templateScriptGuardModal.component';
import { TemplateScriptGuardModalService } from '../TemplateScriptGuardContainer/TemplateScriptGuardModal/templateScriptGuardModal.service';
import { ManageTemplatesService } from './manageTemplates.service';

@Injectable({
	providedIn: 'root'
})
export class TemplateSaveUpdateService {
	private readonly OPEN_TAG_BRACKET = '[%';
	private readonly CLOSE_TAG_BRACKET = '%]';
	private readonly INNER_TAG_DELIM = ':';
	private readonly STRING_LITERAL_ENCLOSING_CHAR = '\'';
	private readonly STRING_LITERAL_ENCLOSING_CHAR_ENCODED = '&apos;';
	private readonly DATETIME_TAG_LITERALS = ['CURRENT_DATE', 'TOMORROW_DATE', 'CURRENT_TIME'];
	private readonly DATEIME_FUNC_PATTERN = /ADD_DAYS\(-{0,1}\d+\)$/;
	private commentsNotifications;

	private $isMakingRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isMakingRequest: Observable<boolean> = this.$isMakingRequest.asObservable();

	public get beeFreeNewCommentsNotifications() {
		return this.commentsNotifications;
	}
	public set beeFreeNewCommentsNotifications(value) {
		this.commentsNotifications = value;
	}

	private readonly MALICIOUS_SCRIPT_STATUS_CODE_CONFIRM = 445;
	private readonly MALICIOUS_SCRIPT_STATUS_CODE_FORBIDDEN = 444;
	private readonly SUCCESS_CODE = 200;


	constructor(
		private manageTemplatesService: ManageTemplatesService,
		private modalService: OptiLogicModalService,
		private translate: TranslateService,
		private templateScriptGuardModalService: TemplateScriptGuardModalService
	) {}

	save(template: TemplateDetails, channelId: number): Observable<number> {
		const templateJson = JSON.stringify(this.deleteWhitespacesFromAtomicTags(template));
		const request = {
			allowInvalidTemplate: false,
			allowUsedTemplate: false,
			channelId,
			convertToFroala: false,
			templateJson
		} as TemplateSaveUpdateRequest;

		return new Observable<number>((observer: Subscriber<number>) => this.saveAndPromptTemplate(template, request, observer));
	}

	deleteWhitespacesFromAtomicTags(template: TemplateDetails): TemplateDetails {
		if (template) {
			template.subject = this.deleteWhitespacesFromAtomicTagsFromStr(template.subject);
			template.preheader = this.deleteWhitespacesFromAtomicTagsFromStr(template.preheader);
			template.text = this.deleteWhitespacesFromAtomicTagsFromStr(template.text);
			template.html = this.deleteWhitespacesFromAtomicTagsFromStr(template.html);
			template.fromName = this.deleteWhitespacesFromAtomicTagsFromStr(template.fromName);
			template.extraData = this.deleteWhitespacesFromAtomicTagsFromStr(template.extraData);
			template.templateName = template.templateName?.replace(/\s+/g, ' ').trim();
		}
		return template;
	}

	private deleteWhitespacesFromAtomicTagsFromStr(str: string): string {
		if (!str) {
			return str;
		}
		var strToReturn = '';
		var strLeft = str;
		var closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		while (closeBracketIndex >= 0) {
			var openBracketIndex = strLeft.substring(0, closeBracketIndex).lastIndexOf(this.OPEN_TAG_BRACKET);
			if (openBracketIndex >= 0) {
				strToReturn += strLeft.substring(0, openBracketIndex);
				var curAtomicTag = strLeft.substring(openBracketIndex, closeBracketIndex + this.CLOSE_TAG_BRACKET.length);
				if (!curAtomicTag.startsWith('[%EXTERNAL_TRANSLATION:') && /\s/.test(curAtomicTag)) {
					var stringLiteralOpenIndex = curAtomicTag.indexOf(this.STRING_LITERAL_ENCLOSING_CHAR);
					var stringLiteralCloseIndex =
						stringLiteralOpenIndex >= 0 && stringLiteralOpenIndex < curAtomicTag.length - 1
							? curAtomicTag.substring(stringLiteralOpenIndex + 1).indexOf(this.STRING_LITERAL_ENCLOSING_CHAR) +
							  stringLiteralOpenIndex +
							  1
							: -1;
					if (stringLiteralOpenIndex === -1 && stringLiteralCloseIndex === -1) {
						stringLiteralOpenIndex = curAtomicTag.indexOf(this.STRING_LITERAL_ENCLOSING_CHAR_ENCODED);
						stringLiteralCloseIndex =
							stringLiteralOpenIndex >= 0 && stringLiteralOpenIndex < curAtomicTag.length - 1
								? curAtomicTag.substring(stringLiteralOpenIndex + 1).indexOf(this.STRING_LITERAL_ENCLOSING_CHAR_ENCODED) +
								  stringLiteralOpenIndex +
								  6
								: -1;
					}

					var lastIndexOfColon = curAtomicTag.lastIndexOf(this.INNER_TAG_DELIM);

					if (this.containsStringLiteral(stringLiteralOpenIndex, stringLiteralCloseIndex)) {
						var prefix = curAtomicTag.substring(0, stringLiteralOpenIndex);
						var stringLiteral = curAtomicTag.substring(
							stringLiteralOpenIndex,
							Math.min(stringLiteralCloseIndex + 1, curAtomicTag.length - 1)
						);
						var suffix = curAtomicTag.substring(Math.min(stringLiteralCloseIndex + 1, curAtomicTag.length - 1));
						curAtomicTag = prefix.replace(/\s/g, '') + stringLiteral + suffix.replace(/\s/g, '');
					} else if (this.tryGetDatetimeFormat(curAtomicTag, lastIndexOfColon)) {
						curAtomicTag = `${curAtomicTag.substring(0, lastIndexOfColon).replace(/\s/g, '')}:${this.extractDatetimeFormat(
							curAtomicTag,
							lastIndexOfColon
						).trim()}${this.CLOSE_TAG_BRACKET}`;
					} else {
						curAtomicTag = curAtomicTag.replace(/\s/g, '');
					}
				}
				strToReturn += curAtomicTag;
			} else {
				strToReturn += strLeft.substring(0, closeBracketIndex + this.CLOSE_TAG_BRACKET.length);
			}
			strLeft = strLeft.substring(closeBracketIndex + this.CLOSE_TAG_BRACKET.length);
			closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		}
		strToReturn += strLeft;
		return strToReturn;
	}

	private containsStringLiteral(stringLiteralOpenIndex, stringLiteralCloseIndex) {
		return stringLiteralOpenIndex >= 0 && stringLiteralCloseIndex > stringLiteralOpenIndex;
	}

	private extractDatetimeFormat(curAtomicTag, lastIndexOfColon) {
		return curAtomicTag.substring(lastIndexOfColon + this.INNER_TAG_DELIM.length, curAtomicTag.lastIndexOf(this.CLOSE_TAG_BRACKET));
	}

	private tryGetDatetimeFormat(curAtomicTag, lastIndexOfColon): boolean {
		const atomicTagPrefix = curAtomicTag.substring(0, lastIndexOfColon).trim();
		return this.DATETIME_TAG_LITERALS.some((p) => atomicTagPrefix.endsWith(p)) || this.DATEIME_FUNC_PATTERN.test(atomicTagPrefix);
	}

	private saveAndPromptTemplate(template: TemplateDetails, request: TemplateSaveUpdateRequest, observer: Subscriber<number>, isUnsafe: boolean = false): void {
		this.$isMakingRequest.next(true);
		const save = isUnsafe ?
			this.manageTemplatesService.saveAndUpdateTemplateUnsafe(request, request.channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL) :
			this.manageTemplatesService.saveAndUpdateTemplate(request, request.channelId === RoutingConsts.WEB_PAGE_POPUP_CHANNEL);
		save.subscribe((response) => {
			this.$isMakingRequest.next(false);
			if (isTemplateSaveSuccessResponse(response)) {
				template.isValid = true;
				template.lastModified = response.data.lastModified;
				template.templateId = response.data.tId;
				this.sendBeeFreeCommentsNotifications(template);
				observer.next(template.templateId);
				observer.complete();
			} else {
				template.isValid = false;

				if ((response as HttpErrorResponse)?.status === this.MALICIOUS_SCRIPT_STATUS_CODE_CONFIRM) {
					this.showMaliciousConfirmation(template, request, observer);
					//	this.handleRecaptchaConfirmation(template, request, observer);
				} else if ((response as HttpErrorResponse)?.status === this.MALICIOUS_SCRIPT_STATUS_CODE_FORBIDDEN) {
					this.showMaliciousScriptDetected();
					observer.error();
					observer.complete();
				} else if ((response as HttpErrorResponse)?.status) {
					this.showFailedSaveModal(this.translate.instant('features.manage_templates.messages.FAILED_SAVE'));
					observer.error();
					observer.complete();
				} else {
					const res = response as TemplateSaveUpdateErrorResponse;
					switch (res.errorMsg) {
						case ErrorMessage.nameInUse:
							this.showFailedSaveModal(this.translate.instant('features.manage_templates.messages.UNIQUE_TEMPLATE_NAME'));
							observer.error();
							observer.complete();
							break;
						case ErrorMessage.invalidTemplate:
							this.showInvalidTemplateModal(template, request, res.data, observer);
							break;
						default:
							this.showFailedSaveModal(this.translate.instant('features.manage_templates.messages.FAILED_SAVE'));
							observer.error();
							observer.complete();
							break;
					}
				}
			}
		});
	}

	private handleRecaptchaConfirmation(template: TemplateDetails, request: TemplateSaveUpdateRequest, observer: Subscriber<number>) {
		this.templateScriptGuardModalService.isRecaptchaValid
			.pipe(skip(1))
			.pipe(first())
			.subscribe((isValid) => {
				if (isValid) {
					this.saveAndPromptTemplate(template, request, observer);
				}
			});
		this.showMaliciousScriptModal();
	}

	private sendBeeFreeCommentsNotifications(template: TemplateDetails) {
		if (this.beeFreeNewCommentsNotifications !== undefined && Object.keys(this.beeFreeNewCommentsNotifications).length) {
			this.manageTemplatesService
				.sendBeeFreeCommentsNotifications({
					notificationsData: this.beeFreeNewCommentsNotifications,
					channelId: template.executionMethodId,
					brandId: template.subMethodId,
					templateId: template.clientTemplateId,
					templateName: template.templateName
				})
				.subscribe((res) => {
					// reset notification data
					this.beeFreeNewCommentsNotifications = {};
				});
		}
	}

	private showInvalidTemplateModal(
		template: TemplateDetails,
		request: TemplateSaveUpdateRequest,
		validationOutput: ValidationOutput,
		observer: Subscriber<number>
	) {
		this.modalService.open(ValidationErrorModelComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				saveCallback: () => {
					this.saveAndPromptTemplate(template, { ...request, allowInvalidTemplate: true }, observer);
				},
				cancelCallback: () => {
					observer.error();
					observer.complete();
				},
				validationOutput,
				isOtherEditor: template.otherEditor
			}
		});
	}

	private showFailedSaveModal( message: string) {
		this.modalService.openModalMessage('sm', {
			message: message,
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('general.OK'),
					action: () => {}
				}
			]
		});
	}

	private showMaliciousScriptDetected() {
		this.modalService.openModalMessage('sm', {
			message: this.translate.instant('features.manage_templates.components.templateScriptGuard.MALICIOUS_DETECTED'),
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('general.OK'),
					action: () => {}
				}
			]
		});
	}

	private showMaliciousScriptModal(): void {
		this.modalService.open(TemplateScriptGuardModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {}
		});
	}

	private showMaliciousConfirmation(template: TemplateDetails, request: TemplateSaveUpdateRequest, observer: Subscriber<number>) {
		const message = this.translate.instant('features.manage_templates.components.templateScriptGuard.DISCLAIMER') + ' ' + this.translate.instant('features.manage_templates.components.templateScriptGuard.APPROVAL_QUESTION');
		this.modalService.openModalMessage('sm', {
			message,
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('general.OK'),
					action: () => {
						this.saveAndPromptTemplate(template, request, observer, true);
					}
				},
				{
					class: 'btn-primary',
					label: this.translate.instant('general.CANCEL'),
					action: () => {
						observer.error();
						observer.complete();
					}
				}
			]
		});
	}
}
