import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { Channel } from '../interfaces';

@Component({
    selector: 'campaign-grid-channels-column',
    templateUrl: 'campaignGridChannelsColumn.component.html',
    styleUrls: ['./campaignGridChannelsColumn.component.scss']
})
export class CampaignGridChannelsColumnComponent implements ICellRendererAngularComp {
    public data: Channel[] = [];
    public propName: string = 'Name';
    public trigger: string;

    constructor() { }

    agInit(params: any): void {
        this.data = params.data?.Channels || [];
        this.propName = params.propName;
        this.trigger = params.data?.Trigger;
    }

    refresh(): boolean {
        return true;
    }
}
