<ng-container *ngIf="ngBootstrapStatus === ngBootstrapStatusAvailable.complete">
    <main class="opti-wrapper">
        <aside>
            <sidenavbar [optibotNotificationsCount]="optibotNotificationsCount"></sidenavbar>
        </aside>
        <section class="opti-page-content">
            <topnavbar [isSettingsVisible]="navbarState.isSettingsVisible" [dailyStatus]="dailyStatus" [isDemoMode]="navbarState.isDemoMode"></topnavbar>
            <div class="page-container">
                <angular-loader [show]="isLoading" class="page-loader"></angular-loader>
                <span [ngClass]="{'loader-is-showing': isLoading}">
                    <router-outlet name="primary"></router-outlet>
                </span>
            </div>
        </section>
    </main>
</ng-container>