import { Component, Input, OnInit } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'templateInUseModal',
	templateUrl: './templateInUseModal.component.html',
	styleUrls: ['./templateInUseModal.component.scss']
})
export class TemplateInUseModalComponent implements OnInit {
	@Input() description: string;
	@Input() title: string;
	modalConf: IModalConf;

	constructor(private translateService: TranslateService, private bsModalRef: BsModalRef) {}

	ngOnInit(): void {
		this.initModalConfig();
	}

	private initModalConfig(): void {
		this.modalConf = {
			title: this.title,
			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('general.OK'),
					action: () => this.bsModalRef.hide()
				}
			],
			hideHeaderBorder: true,
			hideFooterBorder: true,
			noBodyPadding: true
		};
	}
}
