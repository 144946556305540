<div class="sendTestMail-modal panel">
  <div class="panel-heading">
    <h2>{{ "features.manage_templates.components.sendTestMail.TITLE" | translate }}</h2>
  </div>
  <div class="warning-block" *ngIf="isMultiLanguage">
    <div class="warning-container">
      <p class="warning-message" *ngIf="isTemplateDirty">
        {{ "features.manage_templates.components.sendTestMail.UNSAVED_WARNING" | translate }}
      </p>
      <p class="warning-message" *ngIf="!isTemplateDirty && selectedOption === availableIdSelectionValues.ExplicitId">
        {{ "features.manage_templates.components.sendTestMail.UNTRANSLATED_WARNING" | translate }}
      </p>
    </div>
  </div>
  <form class="panel-body" autocomplete="off">
    <div class="form-horizontal">
      <form novalidate name="send-test-form" autocomplete="off">
        <div *ngIf="showSendTestError && !isSendingData">
          <p class="alert alert-danger">
            <span class="send-test-error">
              {{ "features.manage_templates.components.sendTestMail.ERROR_VALIDATION_BOLD" | translate }}
            </span>
            <span>
              {{ "features.manage_templates.components.sendTestMail.ERROR_VALIDATION" | translate }}
            </span>
          </p>
        </div>
        <div>
          <h3>Send a test email to:</h3>
        </div>
        <div>
          <input
            type="text"
            id="sendTestTxt"
            name="sendTestTxtName"
            class="form-control"
            [(ngModel)]="emails"
            (ngModelChange)="emails$.next($event)"
            [typeahead]="existingRecipientEmails"
            [typeaheadOptionsLimit]="10"
            [typeaheadMinLength]="0"
            [typeaheadMultipleSearch]="true"
            typeaheadMultipleSearchDelimiters=","
            #emailsInput />
        </div>
        <div>
          <div>
            <p class="text-muted">
              {{ "features.manage_templates.components.sendTestMail.EMAILTXT_DESCRIPTION" | translate }}
            </p>
          </div>
        </div>
        <div class="personalization-wrapper">
          <div class="">
            <p>{{ "features.manage_templates.components.sendTestMail.DROPDOWN_TITLE" | translate }}</p>
          </div>
        </div>
        <div>
          <div class="radio radio-primary">
            <input
              id="randomData"
              name="selectedOptionRadios"
              class="form-control"
              type="radio"
              (ngModelChange)="onCustomerIdChanged()"
              [(ngModel)]="selectedOption"
              [value]="availableIdSelectionValues.RandomCustomerId"
              [attr.disabled]="subMethodType === 1 ? true : null" />
            <label for="randomData">
              {{ "features.manage_templates.components.sendTestMail.DROPDOWN_OPTION1" | translate }}
            </label>
          </div>
          <div class="radio radio-primary">
            <input
              id="visitorRandomData"
              name="selectedOptionRadios"
              class="form-control"
              type="radio"
              (ngModelChange)="onCustomerIdChanged()"
              [(ngModel)]="selectedOption"
              [value]="availableIdSelectionValues.RandomVisitorId"
              [attr.disabled]="subMethodType === 1 || !shouldDisplayVisitorOption ? true : null" />
            <label for="visitorRandomData">
              {{ "features.manage_templates.components.sendTestMail.RANDOM_VISITOR_DATA" | translate }}
            </label>
          </div>
          <div class="radio radio-primary">
            <input
              id="customerID"
              name="selectedOptionRadios"
              class="form-control"
              type="radio"
              [(ngModel)]="selectedOption"
              [value]="availableIdSelectionValues.ExplicitId"
              [attr.disabled]="subMethodType === 1 ? true : null" />
            <label for="customerID">
              <span *ngIf="shouldDisplayVisitorOption">
                {{ "features.manage_templates.components.sendTestMail.DROPDOWN_OPTION_CUSTOMER_VISITOR" | translate }}
              </span>
              <span *ngIf="!shouldDisplayVisitorOption">
                {{ "features.manage_templates.components.sendTestMail.DROPDOWN_OPTION2" | translate }}
              </span>
            </label>
            <div class="form-group SendTestCustomerWrapper">
              <div class="col-md-5">
                <input
                  type="text"
                  id="SendTestCustomerID"
                  class="form-control"
                  name="SendTestCustomerID"
                  autocomplete="on"
                  placeholder="{{ 'features.manage_templates.components.sendTestMail.PLACEHOLDER_CUSTOMER' | translate }}"
                  [disabled]="selectedOption !== availableIdSelectionValues.ExplicitId"
                  [(ngModel)]="customerId"
                  (ngModelChange)="onRecipientIdChange()" />
                <p *ngIf="shouldShowInvalidCustomerIdError" class="text-danger small inline">
                  {{ "features.manage_templates.components.sendTestMail.ERROR_CUSTOMER" | translate }} {{ customerId
                  }}{{ "features.manage_templates.components.sendTestMail.ERROR_NOT_FOUND" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </form>
  <div class="panel-footer">
    <div class="row">
      <div class="col-md-8">
        <div class="content checkbox checkbox-primary sl_checkboxRow send-test-checkbox">
          <input class="" type="checkbox" [(ngModel)]="isShouldAddPrefix" />
          <label>
            <span>{{ "features.manage_templates.components.sendTestMail.FOOTER" | translate }}</span>
          </label>
        </div>
      </div>
      <div class="col-md-4 pull-right text-right">
        <ul class="list-inline">
          <li>
            <button type="button" class="btn btn-default cancel-btn" (click)="close()" *ngIf="!isSendingData">
              {{ "features.manage_templates.components.sendTestMail.CANCEL" | translate }}
            </button>
          </li>
          <li>
            <click-loader
              loadingText="{{ 'features.manage_templates.components.sendTestMail.SENDING' | translate }}"
              [isResolved]="!isSendingData"
              [isDisabled]="isLoaderDisabled">
              <button
                type="button"
                class="btn btn-primary add-btn"
                [disabled]="!emails || emails.length === 0 || (isTemplateDirty && isMultiLanguage)"
                (click)="send()">
                <span>{{ "features.manage_templates.components.sendTestMail.SEND" | translate }}</span>
              </button>
            </click-loader>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
