import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
	selector: '[elementClickTracker]'
})
export class ElementClickTrackerDirective implements OnInit {
	private isMouseOver: boolean;

	@Input() debug: boolean;
	@Output() elementClick = new EventEmitter<ElementRef>();

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnInit(): void {
		this.renderer.listen(window, 'blur', () => {
			if (this.isMouseOver) {
				this.elementClick.emit(this.el);
			}
		});
	}

	@HostListener('mouseover')
	private onElementMouseOver() {
		this.isMouseOver = true;
	}

	@HostListener('mouseout')
	private onElementMouseOut() {
		this.isMouseOver = false;
	}
}
