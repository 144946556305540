<div class="path-builder" cdkDropList cdkDropListOrientation="horizontal"
     [cdkDropListSortPredicate]="sortPredicateForDisabledItem(pathItems)"
     [cdkDropListData]="pathItems" (cdkDropListDropped)="drop($event)">
  <div class="row-label">
    <label>{{ 'features.user_settings.body.message_archiving.credentials.PATH_BUILDER' | translate }}</label>
  </div>
  <div class="path-elements">
    <div class="path-element" *ngFor="let item of pathItems; let i = index; last as isLast" cdkDrag [cdkDragDisabled]="isLast">
      <span *ngIf="i < pathItems.length - 1">{{ item }}/</span>
      <span *ngIf="isLast">{{ item }}</span>
    </div>
  </div>
</div>