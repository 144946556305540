import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive } from "@angular/core";
import {DateRangeInvalidStateMode} from "./dateRangeInvalidStateMode.enum";

export function validateFirstDateSmallerThanSecondValidator(control: AbstractControl): ValidationErrors | null {
    const d = control.value;
    const isValid = Array.isArray(d) && d.length === 2 && d[0] <= d[1];
    const validationError = {};
    validationError[DateRangeInvalidStateMode.EndDateMustBeBigger] = "true";
    return isValid ? null : validationError;
}

@Directive({
    selector: "[validFirstDateSmallThanSecond]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidateFirstDateSmallerThanSecondValidator,
        multi: true
    }]
})

export class ValidateFirstDateSmallerThanSecondValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any; } {
        return validateFirstDateSmallerThanSecondValidator(c);
    }
}