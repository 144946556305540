<div style="display: none" id="drag-container-ag-grid" class="drag-container">
  <i class="material-icons" style="color: white">insert_drive_file</i>
  <span class="drag-indication" id="drag-container-ag-grid-indication">
    <!-- text here is set in code because otherwise it doesn't show latest changes -->
  </span>
</div>
<div
  id="{{ folderTemplate.templateID }}-drag"
  class="drag-handle"
  data-qa-id="drag"
  draggable="true"
  (dragstart)="dragstart($event)"
  (dragend)="dragEnd($event)">
  <!--  if we paste svg as img then drag doesn`t work-->
  <svg class="node-handle" xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
    <g id="Drag_Handler" data-name="Drag Handler" transform="translate(-32 -6)">
      <rect id="Rectangle_3234" data-name="Rectangle 3234" width="2.5" height="2.5" transform="translate(32 6)" fill="#cfd8dc" />
      <rect id="Rectangle_3236" data-name="Rectangle 3236" width="2.5" height="2.5" transform="translate(32 10.5)" fill="#cfd8dc" />
      <rect id="Rectangle_3238" data-name="Rectangle 3238" width="2.5" height="2.5" transform="translate(32 15)" fill="#cfd8dc" />
      <rect id="Rectangle_3240" data-name="Rectangle 3240" width="2.5" height="2.5" transform="translate(32 19.5)" fill="#cfd8dc" />
      <rect id="Rectangle_3235" data-name="Rectangle 3235" width="2.5" height="2.5" transform="translate(37.5 6)" fill="#cfd8dc" />
      <rect id="Rectangle_3237" data-name="Rectangle 3237" width="2.5" height="2.5" transform="translate(37.5 10.5)" fill="#cfd8dc" />
      <rect id="Rectangle_3239" data-name="Rectangle 3239" width="2.5" height="2.5" transform="translate(37.5 15)" fill="#cfd8dc" />
      <rect id="Rectangle_3241" data-name="Rectangle 3241" width="2.5" height="2.5" transform="translate(37.5 19.5)" fill="#cfd8dc" />
    </g>
  </svg>
</div>
