import  {Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { GridApi } from "@ag-grid-community/all-modules";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { AttributeStatus } from "../../attributesList.enums"; 

@Component({
    selector: 'attr-status-cell',
    templateUrl: './statusCellRenderer.component.html'
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
    public value: string;
    public coloredTagType: ColoredTagType;
    private gridApi: GridApi;

    constructor() {}

    agInit(params: any): void {
        const status = params.data.PublishStatus as AttributeStatus;
        this.value = status;
        
        switch(status) {
            case "NotPublished": 
            case "FailedToPublish":
                this.coloredTagType = ColoredTagType.Type12;
                this.value = "Not Published";
                break;
            case "Published": 
                this.coloredTagType = ColoredTagType.Type4;
                break;
            case "Publishing": 
                this.coloredTagType = ColoredTagType.Type3;
                break;
        }
        this.gridApi = params.api;
    }

    refresh(): boolean {
        return true;
    }
}