import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class SettingsSharedService{
    readonly onEditSettingClick: Subject<string> = new Subject<string>();
    readonly onDeleteSettingClick: Subject<string> = new Subject<string>();

    editSettingClick(rowId: string) {
        this.onEditSettingClick.next(rowId);
    }

    deleteSettingClick(rowId: string) {
        this.onDeleteSettingClick.next(rowId);
    }
}