import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TemplateTreeItem } from '../models/templateResponse';
import {TemplateKeysModel} from '../models/templateKeysModel';

@Injectable({
	providedIn: 'root'
})
export class TemplatesDragDropService {
	private $dragTemplates: BehaviorSubject<TemplateKeysModel[]> = new BehaviorSubject<TemplateKeysModel[]>([]);
	public dragTemplates: Observable<TemplateKeysModel[]> = this.$dragTemplates.asObservable();
	private $dragFolders: BehaviorSubject<TemplateTreeItem[]> = new BehaviorSubject<TemplateTreeItem[]>([]);
	public dragFolders: Observable<TemplateTreeItem[]> = this.$dragFolders.asObservable();

	constructor() {}

	updateDragTemplates(value: TemplateKeysModel[]): void {
		if (value) {
			this.$dragTemplates.next(value);
		}
	}

	updateDragFolders(value: TemplateTreeItem[]): void {
		if (value) {
			this.$dragFolders.next(value);
		}
	}

	isTemplateDraggable(templateId: number, selectedTemplates: TemplateKeysModel[]): boolean {
		if (!selectedTemplates || !selectedTemplates.length) {
			return true;
		}

		if (!selectedTemplates.find((t) => t.templateID === templateId)) {
			return false;
		}

		const submethods = selectedTemplates.map((t) => t.subMethodId).filter((value, index, array) => array.indexOf(value) === index);
		const isMultipleSubmethodsInSelection = submethods.length > 1;
		return !isMultipleSubmethodsInSelection;
	}

	canDrop(destinationSubMethodId: number): boolean {
		const canDropTemplates = !this.$dragTemplates.value.find((t) => t.subMethodId !== destinationSubMethodId);
		const canDropFolders = !this.$dragFolders.value.find((t) => t.subMethodId !== destinationSubMethodId);
		return canDropTemplates && canDropFolders;
	}
}
