import { Component, OnDestroy } from "@angular/core";
import {
    IModalButtonConf,
    IModalConf,
    OptiLogicModalService
} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ModalOptions, BsModalRef } from "ngx-bootstrap/modal";
import { SsmService } from "../../../../../services/ssm.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AttributeOption, AttributeStatus } from "../../models/profileDetails.model";
import { first } from "rxjs/operators";

@Component({
    selector: 'btn-cell-renderer',
    templateUrl: `./btnsCellRenderer.component.html`
})
export class BtnCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    private params: any;
    public disabled: boolean;
    private ssm: any;

    constructor(private modalService: OptiLogicModalService,
                private bsModalRef: BsModalRef,
                private ssmService: SsmService,
                private translate: TranslateService,
                private router: Router) {
        this.ssm = ssmService.getSSM();
    }

    agInit(params: any): void {
        this.params = params;
        this.disabled = this.params.data.status === "Publishing" || params.context.isLockedDuringPublishing;
    }

    moveToEditAttribute(): void {
        this.router.navigate(['/user-settings/realTimeProfileDataManager/addAttribute'], { 
            state: this.params.data
        });
    }

    areYouSure() {
        const modalConf = this.getModalConf();
        this.modalService.openModalMessage(
            'sm',
            modalConf,
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

    optimoveFieldDeletionError() {
        this.modalService.openModalMessage(
            'sm',
            {
                message: this.translate.instant('features.user_settings.body.realtimeProfileDataManage.invalidErrors.CANT_DELETE_ATTRIBUTE'),
                buttons: [{
                    class: 'btn-primary',
                    label: this.translate.instant("general.OK"),
                    action: this.closeModal.bind(this)
                }]
            },
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

     async deleteAndRefresh() {
        try {
            this.params.context.loading = true;
            const option = this.params.data.status === "Published" ? "Publish" : "Save";

            let fullAttrObj = Object.values(this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes))
                .find(attrObj => attrObj['RealFieldName'].toLocaleLowerCase() === this.params.data.displayName.toLocaleLowerCase());

            if (fullAttrObj) {
                const result = await this.params.context.attributeService.deleteAttribute("realtime", fullAttrObj['RealFieldName']).pipe(first()).toPromise();
                if (!result.IsSuccess) {
                    this.optimoveFieldDeletionError();
                    throw new Error(result.ErrorMsg);
                }
            }

            let updatedProfile = this.params.context.service.deleteAttribute(this.params.data.displayName, option);
            const res = await this.params.context.service.updateSubscription(updatedProfile).pipe(first()).toPromise();
            if (res.IsSuccess) {
                // check if attribute has property for back compatibility
                // only for published attributes need update ksql
                if(!this.params.data.hasOwnProperty("isAlreadyPublished") || this.params.data.isAlreadyPublished === true){
                    await this.params.context.service.updateKsql().pipe(first()).toPromise();
                }
                await this.params.context.service.updateCustomerAttributes();
                const attributes = this.params.context.service.attributes;
                this.params.context.checkNoAttributes(attributes);
                this.params.context.checkMaxAttributesLimit(attributes);
                this.params.context.checkAllPublished(attributes);
                this.params.context.checkPublishError(attributes);
                this.params.context.setGridData(attributes);
                this.params.context.loading = false;
                return;
            }
            throw new Error();
        } catch(e) {
            this.params.context.loading = false;
        }
    }

    refresh(params: any): boolean {
        return true;
    }

    getModalConf(): IModalConf {
        return {
            message: `Are you sure you want to delete this attribute?`,
            buttons: this.getButtonsConf()
        };
    }

    getButtonsConf(): IModalButtonConf[] {
        return [
            {
                class: 'btn-primary',
                label: 'Delete',
                action: this.deleteAndRefresh.bind(this)
            },
            {
                class: 'btn-default',
                label: 'Cancel',
                action: this.closeModal.bind(this)
            }
        ];
    }

    private closeModal() {
        this.bsModalRef.hide();
    }

    ngOnDestroy() {}
}
