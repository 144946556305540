<ng-container [formGroup]="form">
  <div class="calculation-area">
    <div class="row">
      <div class="col-sm-12 pl-0 pr-0">
        <div class="panel ">
          <div class="panel-heading pl_10_px" *ngIf="config.isPublished === false; else elseIsPublished">
            <span>
              {{ translateKeys.headerNotPublishedDesc | translate }}
            </span>
            <a style="padding-left: 10px;cursor: pointer;" href="https://academy.optimove.com/en/article/setting-up-calculated-customer-attributes-based-on-existing-attributes"
               target="_blank" rel="noopener">{{ translateKeys.learnMore | translate }}
            </a>
          </div>
          <ng-template #elseIsPublished>
            <div class="panel-heading pl_10_px" >
              <span> {{ translateKeys.headerPublishedDesc | translate }} </span>
            </div>
          </ng-template>
          <div class="panel-body">
            <div class="row calculation-sections">
              <div class="formula-area col-md-8 pl_10_px" [ngClass]="{'invalid': equationEditorFormControl.invalid && equationEditorFormControl.dirty && !isDateSelectedFirst}">
                <tagify name="equationEditor" tabindex="-1"
                        formControlName="equationEditor"
                        [readonly]="config.isDisabled"
                        [disabled]="config.isDisabled"
                        [settings]="settings">
                </tagify>
                <span *ngIf="equationEditorFormControl.invalid && equationEditorFormControl.dirty && !isDateSelectedFirst" 
                       class="invalid-msg">
                  {{ translateKeys.invalidFormula | translate }}
                </span>
                <div class="footer" *ngIf="this.config.formulaType == FormulaType.DateBase">
                  <div class="checkbox checkbox-primary">
                    <input id="ignoreYear"
                           type="checkbox"
                           formControlName="ignoreYear"
                           [attr.disabled]="config.isDisabled ? true : null"
                           (change)="changeEmitter.emit(true)">
                    <label for="ignoreYear">{{ translateKeys.ignoreYear | translate }}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4 calculator pl-0 pr-0">
                <div class="row" style="margin-bottom: 0;">
                  <div class="col-md-7 pl-0 pr-0 calc-buttons ">
                    <button *ngFor="let item of config.items" class="btn btn-secondary calc-btn " [disabled]="item.disabled === true || config.isDisabled === true"
                      [ngClass]="{ span: item.span === 1, 'double-span': item.span === 2 }" (click)="item.action()">
                      {{ item.displayName }}
                    </button>
                  </div>
                  <div class=" col-md-5 pl-0 pr-0 calc-actions">
                    <button class="btn btn-secondary calc-btn action-btn" *ngFor="let actionItem of config.actionItems"
                      [disabled]="actionItem.disabled === true || config.isDisabled === true" [ngClass]="{ space: actionItem.hidden }"
                      (click)="actionItem.action()">
                      {{ actionItem.displayName }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-sm-12 pl-0 format-row">
    </div>

  </div> 
</ng-container>
