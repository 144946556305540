import { IHeaderAngularComp } from '@ag-grid-community/angular';
import {Component, ElementRef , ViewEncapsulation } from "@angular/core";
import {SearchListUtilsService} from "../services/searchlistUtils.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'osl-header',
    templateUrl: './oslHeader.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslHeader.component.scss'],
})

export class OslHeaderComponent implements IHeaderAngularComp  {
    private params: any;
    private api: any;
    public allCount: number;
    public selectedCount: number;
    public isSelected: boolean = false;
    public hasAggregatedButton = false;
    public isDisabled: boolean;
    public showSelectedLabel: string;

    constructor(private searchListUtilsService: SearchListUtilsService, private eRef: ElementRef, private translateService: TranslateService) {
    }

    agInit(params: any): void  {
        this.params = params;
        this.api = params.api;
        const col = this.api.getColumnDef("content");
        if (col.headerComponentParams) {
            col.headerComponentParams.api.onSelectionChanged = this.onSelectionChanged.bind(this);
            col.headerComponentParams.api.onGridShow = this.onGridShow.bind(this);
            col.headerComponentParams.api.focus = this.focus.bind(this);
            col.headerComponentParams.api.isDisabled = this.getIsDisabled.bind(this);
        }
        this.hasAggregatedButton = this.params.hasAggregatedButton;
        this.showSelectedLabel = this.getShowSelectedLabel(params.isSmallHeader);
        this.setAllCount(params);
        this.onSelectionChanged();
        this.initIsSelected();
    }

    private getShowSelectedLabel(isSmallHeader: boolean): string {
        if (isSmallHeader) {
            return this.translateService.instant('components.opti_search_list.SHOW_SELECTED_SMALL_HEADER');
        } else {
            return this.translateService.instant('components.opti_search_list.SHOW_SELECTED');
        }
    }

    toggledIsSelected (isChecked: boolean){
        this.isSelected = isChecked;
        this.api.toggleShowSelected(isChecked);

        const col = this.api.getColumnDef("content");
        if (col.headerComponentParams) {
            col.headerComponentParams.api.showSelectedItemsClicked();
        }
    }

    onSelectionChanged() {
        this.selectedCount = this.getSelectedCount();

        this.setShowSelectedEnabled(this.selectedCount);

        if (this.isSelected && this.selectedCount === 0) {
            this.toggledIsSelected(false);
        }
    }

    onGridShow() {
        // When grid opened && show selected items -> enforce to show only selected items.
        if (this.isSelected) {
            this.toggledIsSelected(true);
        }
    }

    getSelectedCount(): number {
        const selectedNodes = this.api.getSelectedNodes();
        const selectedSearchItems = this.searchListUtilsService.filterOnlySearchNodes(selectedNodes);
        const shouldDecreaseCount = selectedNodes.length !== selectedSearchItems.length;
        const selectedItemsCount = selectedNodes.length - (shouldDecreaseCount ? 1 : 0);
        return selectedItemsCount;
    }

    setShowSelectedEnabled(selectedItemsCount: number) {
        if (selectedItemsCount === 0) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
    }

    initIsSelected() {
        if (!this.params.shouldRender) {
            this.isSelected = false;
            return;
        }
        const rootNode = this.api.getModel().rootNode;

        if (!rootNode) {
            return;
        }

        if (!rootNode.childrenAfterFilter) {
            return;
        }

        const filteredRows = this.searchListUtilsService.filterOnlySearchNodes(rootNode.childrenAfterFilter);
        const haveUnselectedSearchItem = filteredRows.some((row) => !row.selected);
        if (haveUnselectedSearchItem || filteredRows.length === 0) {
            this.isSelected = false;
        } else {
            this.isSelected = true;
        }
    }

    getIsDisabled(): boolean {
        return this.isDisabled;
    }

    focus() {
        const wrapper = this.eRef.nativeElement.querySelector('.wrapper');
        wrapper.focus();
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 13 /*Enter*/ || event.keyCode === 32 /*Space*/) { 
            this.toggledIsSelected(!this.isSelected);
        }

        const col = this.api.getColumnDef("content");
        if (col.headerComponentParams) {
            col.headerComponentParams.api.onKeyDown(event.keyCode);
        }
    }

    setAllCount(params: any) {
        this.allCount = params.allCount - (params.hasAggregatedButton ? 1 : 0);
    }

    refresh(params: any): boolean {
        this.setAllCount(params);
        return true;
    }
}