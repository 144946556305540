<div class="preference-history-master">
    <div class="brands-container">
        <div class="brand" *ngFor="let brand of brandGroups"
             [ngClass]="{ 'selected-brand': brand.isOpen}"
             (click)="updateSelectedBrand(brand)">
            <span class="brand-name">{{brand.name}}</span>
            <span *ngIf="brand.isOpen" class="selected-brand-arrow material-icons" aria-hidden="true">chevron_right</span>
        </div>
    </div>
</div>
