import { Directive, ElementRef, Injector , ViewEncapsulation } from "@angular/core";

import { UpgradeComponent } from "@angular/upgrade/static";

// allow $scope to be provided to ng1
export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => injector.get('$rootScope').$new(),
};

@Directive({
    providers: [ ScopeProvider ],
    selector: 'ng2-ui-view',
  })
  

export class UpgradedUiViewComponent extends UpgradeComponent {
    constructor(ref: ElementRef, inj: Injector) {
        super('uiViewWrapper', ref, inj);
    }
}