<div class="inline-select">
    <button class="input-box" (click)="onInputClick()">
        <span>{{ selectedItems[0]?.name }}</span>
        <i class="material-icons">arrow_drop_down</i>
    </button>
    <ng-select [items]="items"
            [markFirst]="false"
            [trackByFn]="trackBy"
            [virtualScroll]="true"
            (open)="onOpen()"
            (close)="onClose()"
            bindLabel="name"
            bindValue="id"
            [groupBy]="groupByProperty"
            [disabled]="disabled"
            [ngModel]="selected"
            [clearable]="false"
            (ngModelChange)="onModelChange($event)"
            (blur)="onBlur()">

             <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span class="ng-option-content" [title]="item.name">{{ item.name }}</span>
            </ng-template>
    </ng-select>
</div>
