import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { TemplateContextService } from '../../services/templateContext.service';
import { ToastModel } from 'src/app/features/missionControl/models/toast.model';
import { locationParameter } from 'src/app/models/locationParameter.enum';
import { IconType } from '@optimove/ui-sdk/common/models';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalService } from '../../services/errorModal.service';

@Component({
	selector: 'optimail-editor',
	templateUrl: './optimailEditor.component.html',
	styleUrls: ['./optimailEditor.component.scss']
})
export class OptimailEditorComponent implements OnInit {
	public toastParams: ToastModel = {
		isOpen: false,
		message: '',
		icon: 'info',
		showCloseIcon: true,
		location: locationParameter.Default,
		toastIconType: IconType.AngularMaterial
	};
	templateLobbyUrl: string;
	templateLobbyQueryParams: TemplateQueryParams;
	isFullScreen: boolean;
	constructor(
		private router: Router,
		private templateContextService: TemplateContextService,
		private translate: TranslateService,
		private errorModalService: ErrorModalService
	) {}

	ngOnInit(): void {
		this.templateContextService.queryTemplateParams.subscribe((params: TemplateQueryParams) => {
			const path = this.router.url;
			if (path) {
				const pathToManageTemplates = path.substring(0, path.indexOf('templates'));
				this.templateLobbyQueryParams = { ...params };
				this.templateLobbyUrl = `${pathToManageTemplates}templates/show/${params.channelId}`;
			}
		});
	}

	onTemplateSaved(templateId: number): void {
		if (!templateId) {
			this.errorModalService.openErrorModal(
				this.translate.instant('features.manage_templates.optimail.templateFolderActions.SAVE_TEMPLATE'),
				this.translate.instant('features.manage_templates.optimail.templateFolderActions.ACTION_SAVE_TEMPLATE')
			);
			return;
		}
		this.toastParams = {
			...this.toastParams,
			isOpen: true,
			message: this.translate.instant('features.manage_templates.toast_notifications.TEMPLATE_SAVE_SUCCESS')
		};
	}
}
