import {ActionData, CampaignSummaryRow, Metadata} from "./campaignKPIable.model";
import {AppConstant} from "../../app.constatnt";
import {TranslateService} from "@ngx-translate/core";
import {FormatterPipe, IFormatterOptions} from "../../pipes/formatter.pipe";

export abstract class CampaignKPITooltips {

    constructor(protected translate: TranslateService,
                protected formatter: FormatterPipe,
                protected formatterOptions: IFormatterOptions,
                protected data: Metadata,
                protected actionData: ActionData[]) {
    }

    public difference() {
        if (!this.actionData || this.actionData.length < 2 || this.getValueToCompare(this.actionData[1]) == 0) {
            return 0;
        }

        return (this.getValueToCompare(this.actionData[0]) - this.getValueToCompare(this.actionData[1])) / this.getValueToCompare(this.actionData[1]);
    }

    public abstract noResponse(): string;

    public abstract lowRespondents(): string;

    public abstract twoActionsDif(dif: number, headerRow: CampaignSummaryRow): string;

    public abstract twoActionsEqual(headerRow: CampaignSummaryRow): string;

    public abstract allActionsEqual(): string;

    public abstract oneActionEffective(headerRow: CampaignSummaryRow): string;

    public abstract isAllActionsEqual(): boolean;

    protected abstract getValueToCompare(action: ActionData): number;

    protected getActionName(actionLabel, actionId): string {
        if (this.actionData.find(a => a.id == actionId).isControl) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.CONTROL_GROUP')
        }
        if (this.data.isTestControl) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.TEST_GROUP');
        }
        return this.translate.instant('features.campaign_analysis.campaign_summary.ACTION') + actionLabel;
    }

    protected getWinningActionObj(headerRow: CampaignSummaryRow, isSignificant: boolean) {
        var max = 0;
        var currentNum;
        var winningAction = this.actionData[0];

        this.actionData.forEach((action) => {
            currentNum = this.getValueToCompare(action);
            if (max < currentNum) {
                winningAction = action;
                max = currentNum;
            }
        });

        let action = headerRow.actionDataList.find(a => a.id == winningAction.id);
        return {
            action: this.getActionName(action.actionLabel, action.id),
            alias: this.data.aliasKpi,
            took_action: this.data.tookActionAlias,
            planDetailId: winningAction.id,
            significant: isSignificant ? '' : this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.NOT')
        };
    }

    protected isControlAction(actionId: number): boolean {
        return this.actionData.find(a => a.id == actionId).isControl;
    }
}

export class avgKpiTooltips extends CampaignKPITooltips {

    public noResponse(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.NO_RESPONDERS', {
            took_action: this.data.tookActionAlias
        });
    }

    public lowRespondents(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.LOW_RESPONDENTS')
    }

    public twoActionsDif(dif: number, headerRow: CampaignSummaryRow): string {
        if (this.data.isAvgSignificant) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.AB_SIGNIFICANT', this.getParamsForTwoActionsDif(dif, headerRow));
        } else {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.AB_NOT_SIGNIFICANT', this.getParamsForTwoActionsDif(dif, headerRow));
        }
    }

    public twoActionsEqual(headerRow: CampaignSummaryRow): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.AB_EQUAL', {
            alias: this.data.aliasKpi,
            actionA: this.getActionName(headerRow.actionDataList[0].actionLabel, headerRow.actionDataList[0].id),
            actionB: this.getActionName(headerRow.actionDataList[1].actionLabel, headerRow.actionDataList[1].id)
        });
    }

    public allActionsEqual(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.ABN_EQUAL', {
            alias: this.data.aliasKpi
        });
    }

    public oneActionEffective(headerRow: CampaignSummaryRow): string {
        let params = this.getWinningActionObj(headerRow, this.data.isAvgSignificant);
        if (this.isControlAction(params.planDetailId)) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.ABN_SIGNIFICANT_CONTROL', params);
        } else {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.avg.ABN_SIGNIFICANT', params);
        }
    }

    public isAllActionsEqual(): boolean {
        return this.actionData.filter((a, i) => this.actionData[0].avgKpi == a.avgKpi).length == this.actionData.length;
    }

    protected getValueToCompare(action: ActionData): number {
        return action.avgKpi;
    }

    private getParamsForTwoActionsDif(dif: number, headerRow: CampaignSummaryRow) {
        let change = dif > 0 ? this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.HIGHER') :
            this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.LOWER');
        return {
            alias: this.data.aliasKpi,
            actionA: this.getActionName(headerRow.actionDataList[0].actionLabel, headerRow.actionDataList[0].id),
            actionB: this.getActionName(headerRow.actionDataList[1].actionLabel, headerRow.actionDataList[1].id),
            difference: this.formatter.transform(Math.abs(dif), AppConstant.NUMBER_FORMATS.percentage, this.formatterOptions),
            change: change,
            tookAction: this.data.tookActionAlias
        };
    }
}

export class responseRateTooltips extends CampaignKPITooltips {

    public noResponse(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.NO_RESPONDERS', {
            took_action: this.data.tookActionAlias
        });
    }

    public lowRespondents(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.NO_RESPONDERS', {
            took_action: this.data.tookActionAlias
        });
    }

    public twoActionsDif(dif: number, headerRow: CampaignSummaryRow): string {
        let params = this.getParamsForTwoActionsDif(dif, headerRow);
        if (this.data.isResponseRateSignificant) {
            return isFinite(dif) ? this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.AB_SIGNIFICANT', params) :
                this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.AB_SIGNIFICANT_NAN', params);
        } else {
            return isFinite(dif) ? this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.AB_NOT_SIGNIFICANT', params) :
                this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.AB_NOT_SIGNIFICANT_NAN', params);
        }
    }

    public twoActionsEqual(headerRow: CampaignSummaryRow): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.AB_EQUAL', {
            took_action: this.data.tookActionAlias,
            actionA: this.getActionName(headerRow.actionDataList[0].actionLabel, headerRow.actionDataList[0].id),
            actionB: this.getActionName(headerRow.actionDataList[1].actionLabel, headerRow.actionDataList[1].id)
        });
    }

    public allActionsEqual(): string {
        return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.ABN_EQUAL', {
            took_action: this.data.tookActionAlias
        });
    }

    public oneActionEffective(headerRow: CampaignSummaryRow): string {
        let params = this.getWinningActionObj(headerRow, this.data.isResponseRateSignificant);
        if (this.isControlAction(params.planDetailId)) {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.ABN_SIGNIFICANT_CONTROL', params);
        } else {
            return this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.ABN_SIGNIFICANT', params);
        }
    }

    public isAllActionsEqual(): boolean {
        return this.actionData.filter((a, i) => this.actionData[0].responseRate == a.responseRate).length == this.actionData.length;
    }

    protected getValueToCompare(action: ActionData): number {
        return action.responseRate;
    }

    private getParamsForTwoActionsDif(dif: number, headerRow: CampaignSummaryRow) {
        let change = dif > 0 ? this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.HIGHER') :
            this.translate.instant('features.campaign_analysis.campaign_summary.tooltip.percent.LOWER');

        return {
            actionA: this.getActionName(headerRow.actionDataList[0].actionLabel, headerRow.actionDataList[0].id),
            actionB: this.getActionName(headerRow.actionDataList[1].actionLabel, headerRow.actionDataList[1].id),
            difference: this.formatter.transform(Math.abs(dif), AppConstant.NUMBER_FORMATS.percentage, this.formatterOptions),
            change: change,
            took_action: this.data.tookActionAlias
        };
    }
}