import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { TemplateKeysModel } from '../models/templateKeysModel';

@Injectable({
	providedIn: 'root'
})
export class TemplateSelectionService {
	private $templatesSelected: BehaviorSubject<TemplateKeysModel[]> = new BehaviorSubject<TemplateKeysModel[]>([]);
	public templatesSelected: Observable<TemplateKeysModel[]> = this.$templatesSelected.asObservable();
	private $isSelectAllChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isSelectAllChecked: Observable<boolean> = this.$isSelectAllChecked.asObservable();

	isSelected(templateToCheck: TemplateKeysModel): boolean {
		let templates = this.$templatesSelected.getValue();
		return !!templates.find((template) => template.templateID === templateToCheck.templateID && template.subMethodId === templateToCheck.subMethodId);
	}
	addTemplateSelected(templateSelected: TemplateKeysModel) {
		let templates = this.$templatesSelected.getValue();
		templates.push(templateSelected);
		this.$templatesSelected.next(templates);
	}
	removeTemplateSelected(templateUnselected: TemplateKeysModel) {
		let templates = this.$templatesSelected.getValue();
		const index = templates.findIndex(
			(template) => template.templateID === templateUnselected.templateID && template.subMethodId === templateUnselected.subMethodId
		);
		if (index > -1) templates.splice(index, 1);
		this.$templatesSelected.next(templates);
	}
	removeAll() {
		this.$templatesSelected.next([]);
	}

	selectAll(selectedTemplates: TemplateKeysModel[]) {
		let selection = this.$templatesSelected.getValue();
		selection.push(...selectedTemplates);
		this.$isSelectAllChecked.next(true);
		this.$templatesSelected.next(selection);
	}

	updateSelectAllState(state: boolean) {
		this.$isSelectAllChecked.next(state);
	}
}
