<opti-logic-modal [modalConf]="config">
    <div class="side-bar-body">
        <div class="body-header">
            <p class="small">
                {{ translateKeys.SELECTED_ATTRIBUTES_LABEL | translate : { x: selectedAttributesCount, y: selectedAttributesLimit } }}
            </p>
            <div class="search-bar">
                <div class="search-input">
                    <input 
                        data-qa-id="SideBarSearch"
                        class="form-control"
                        type="text" 
                        [placeholder]="translateKeys.SEARCH | translate"
                        (ngModelChange)="search()"
                        [(ngModel)]="searchString">
                    <span *ngIf="searchString === ''" data-qa-id="SideBarSearchIcon" class="input-search-label material-icons">search</span>
                    <span *ngIf="searchString !== ''" data-qa-id="SideBarXIcon" class="input-search-label clear-icon material-icons" (click)="clearSearchStr()">close</span>
                </div>
                <button data-qa-id="SideBarClearAll" 
                        class="btn-link-padding btn-link" 
                        (click)="clearSelection()">
                    <span class="strong">{{ translateKeys.CLEAR_ALL | translate }}</span>
                </button>
            </div>
        </div>
        <div class="attribute-list">
            <div>
                <h3 class="attributes-type-title">{{ translateKeys.DEFAULT_ATTRIBUTE | translate }}</h3>
                <div *ngFor="let attribute of defaultAttributes; let i = index">
                    <div *ngIf="!attribute.Hide" class="checkbox checkbox-primary">
                        <input 
                            type="checkbox"
                            data-qa-id="AttributeCheckBox" 
                            (change)="onCheckBoxClick(i, true)"
                            [checked]="attribute.IsChecked"
                            id="defaultCheckBox{{i}}"
                            [disabled]="selectedAttributesCount >= selectedAttributesLimit && !attribute.IsChecked">
                        <label for="defaultCheckBox{{i}}"> {{ attribute.Alias }} </label> 
                        <i *ngIf="attribute.IsRealtime" 
                            data-qa-id="SideBarFlashIcon"
                            title="{{ translateKeys.REAL_TIME_TOOLTIP | translate }}" 
                            class="material-icons realtime-icon">
                            flash_on
                        </i>
                    </div>
                </div>
            </div>
            <div>
                <h3 class="attributes-type-title">{{ translateKeys.ALL_ATTRIBUTES | translate }}</h3>
                <div *ngFor="let attribute of allAttributes; let i = index">
                    <div *ngIf="!attribute.Hide" class="checkbox checkbox-primary">
                        <input 
                            type="checkbox"
                            data-qa-id="attributeCheckBox" 
                            (change)="onCheckBoxClick(i)"
                            [checked]="attribute.IsChecked"
                            id="checkBox{{i}}"
                            [disabled]="selectedAttributesCount >= selectedAttributesLimit && !attribute.IsChecked">
                        <label for="checkBox{{i}}"> {{ attribute.Alias }} </label> 
                        <i *ngIf="attribute.IsRealtime" 
                            data-qa-id="SideBarFlashIcon"
                            title="{{ translateKeys.REAL_TIME_TOOLTIP | translate }}" 
                            class="material-icons realtime-icon">
                            flash_on
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</opti-logic-modal>
