import { Injectable } from '@angular/core';
import { Subject } from "rxjs";


@Injectable()
export class ConditionalLanguageService {
    private snippetsObjectSub = new Subject<any>();
    public snippetsObject$ = this.snippetsObjectSub.asObservable();

    private snippetPicked = new Subject<any>();
    public snippetPicked$ = this.snippetPicked.asObservable();

    public updateChosenSnippet(snippet: string): void {
        this.snippetPicked.next(snippet);
    }

    public updateSnippetsObject(snippets: any) {
        setTimeout(() => {
            this.snippetsObjectSub.next(snippets);
        });
    }
}