import {Pipe, PipeTransform} from "@angular/core";

declare var numeral: any;

@Pipe({name: 'formatter'})
export class FormatterPipe implements PipeTransform {

    readonly precisionPattern: string = "0\\.?0*";
    readonly percentageSymbol: string = "%";
    readonly bitcoinSymbol: string = "Ƀ";

    transform(value: any, format: string, options?: IFormatterOptions, enforceFormatting?: boolean): any {
        // empty value
        if (this.isInvalid(value)) {
            if(options && options.allowBlankValue){
                return value;
            }
            return "—";
        }
        if (this.isInvalid(format))
            return value;

        let newFormat = this.checkAndGetPercentageFormat(value, format, options);
        let formatted = this.originalFormat(value, newFormat); // calls the main (original) functionallity of the formatter (in charge of currency etc..)

        if (options && options.shouldHandleBigNumbers) {
            // if the number is big, ignore the fraction part
            let wholeNumberPartFormatted = formatted.split('.')[0];
            let wholeNumberWithoutFormat = wholeNumberPartFormatted.replace(/\D/g, '');
            let isBig = this.isBigNumber(wholeNumberWithoutFormat, 1);
            if (isBig)
                formatted = wholeNumberPartFormatted;

            // convert to 'big number' format (add k/m/etc)
            let numberPart = formatted.replace(/\D/g, '');
            let bigNumber = this.formatBigNumber(numberPart.replace(/,/g, ""), options.decimalPointPrecision);
            let bigNumberWithCommas = this.addCommas(bigNumber);
            let numberPartWithCommas = this.addCommas(numberPart);

            let val = formatted.replace(numberPartWithCommas, bigNumberWithCommas);
            return enforceFormatting ? val : this.finalFormat(val);
        }
        else {

            formatted = enforceFormatting ? formatted : this.finalFormat(formatted);

            if (!isNaN(parseInt(formatted))){
                return this.addCommas(formatted);
            } else {
                return formatted;
            }
        }
    }

    checkAndGetPercentageFormat(value: any, format: string, options: IFormatterOptions): string {
        let newFormat: string = '';
        let isPercentage: boolean = format.includes(this.percentageSymbol);
        if (options && options.shouldHandleSmallNumbers) {
            let percentageMatch = format.match(this.precisionPattern);
            if (percentageMatch) {
                var relevantPercision = this.getRelevantPercision(value, percentageMatch[0], isPercentage, options);
                if (relevantPercision !== percentageMatch[0])
                    newFormat = format.split(percentageMatch[0]).join(relevantPercision);

            }
        }
        if (value == 1 && isPercentage) {
            newFormat = "0%";
        }
        return newFormat || format;
    }

    getRelevantPercision(value: any, format: string, isPercentage: boolean, options: IFormatterOptions = null) {
        var calculateFormat = isPercentage ? this.handlePercentage(value, options) : this.handleCurrency(value, options);
        // if (calculateFormat.length > format.length) {
        //     format = calculateFormat;
        // }
        return calculateFormat;
    }

    isInvalid(value): boolean {
        return value === null || value === '' || value === undefined  || value === '-';
    }

    handlePercentage(value: any, options: IFormatterOptions = null) {
        var calculateFormat : string;
        var valueAsStr = value.toString();

        switch (true) {
            case (value < 0.001):
                calculateFormat = "0.000";
                break;
            case (value < 0.01):
                calculateFormat = "0.00";
                break;
            case (value < 0.1 && options && options.handleSpecialCustomersValue):
                calculateFormat = "0.00";
                break;
            case (value < 0.1):
                calculateFormat = "0.0";
                break;
            default:
                calculateFormat = "0";
                break;
        }
        calculateFormat = this.handleMaxFormatLengthPrecision(calculateFormat, options)
        return calculateFormat;
    }

    handleCurrency(value: any, options: IFormatterOptions = null) {
        value = (value < 0) ? value * -1 : value;
        var calculateFormat;

        switch (true) {
            case (value < 0.01):
                calculateFormat = "0.000";
                break;
            case (value < 0.1):
                calculateFormat = "0.00";
                break;
            case (value < 1 && options && options.handleSpecialCustomersValue):
                calculateFormat = "0.00";
                break;
            case (value < 10):
                calculateFormat = "0.0";
                break;
            default:
                calculateFormat = "0";
                break;
        }
        calculateFormat = this.handleMaxFormatLengthPrecision(calculateFormat, options);
        return calculateFormat;
    }

    handleMaxFormatLengthPrecision(calculateFormat: string, options: IFormatterOptions = null, ): string{
        if (options?.maxFormatLengthPrecision && calculateFormat.length > options.maxFormatLengthPrecision + 2) {
            calculateFormat = calculateFormat.slice(0, options.maxFormatLengthPrecision + 2);
        }
        return calculateFormat
    }

    originalFormat(value: any, format: string): string {
        if (this.isEmptyOrSpaces(value)) {
            return "-";
        }
        numeral && numeral.language(this.languagueSelector(format));

        if (!format)
            return value;
        var number = numeral ? numeral(value) : value;
        var newFormat = format;
        if (format.indexOf(";") !== -1)
            newFormat = this.currencySignReplace(format.split(";")[1]);

        return number.format(newFormat);
    }

    isEmptyOrSpaces(str: string): boolean {
        return typeof (str) == 'undefined' || str === null || str.toString().match(/^ *$/) !== null;
    }

    languagueSelector(format: string): string {
        var ltvFormat = format;// $.parseJSON(sessionStorage["ModelFotmats"]).LtvFormat.split(';')[0];

        if (ltvFormat.indexOf("R$") != -1) {
            return 'pt-br';
        }
        else if (ltvFormat.indexOf("B$") != -1) {
            return 'bsd';
        }
        else if (ltvFormat.indexOf("BZ$") != -1) {
            return 'bzd';
        }
        else if (ltvFormat.indexOf("BD$") != -1) {
            return 'bmd';
        }
        else if (ltvFormat.indexOf("$MN") != -1) {
            return 'cup';
        }
        else if (ltvFormat.indexOf("RD$") != -1) {
            return 'dop';
        }
        else if (ltvFormat.indexOf("EC$") != -1) {
            return 'xcd';
        }
        else if (ltvFormat.indexOf("C$") != -1) {
            return 'cad';
        }
        else if (ltvFormat.indexOf("FJ$") != -1) {
            return 'fjd';
        }
        else if (ltvFormat.indexOf("GY$") != -1) {
            return 'gyd';
        }
        else if (ltvFormat.indexOf("HK$") != -1) {
            return 'hkd';
        }
        else if (ltvFormat.indexOf("N$") != -1) {
            return 'nad';
        }
        else if (ltvFormat.indexOf("C$") != -1) {
            return 'nio';
        }
        else if (ltvFormat.indexOf("WS$") != -1) {
            return 'wst';
        }
        else if (ltvFormat.indexOf("S$") != -1) {
            return 'sgd';
        }
        else if (ltvFormat.indexOf("SI$") != -1) {
            return 'sbd';
        }
        else if (ltvFormat.indexOf("NT$") != -1) {
            return 'twd';
        }
        else if (ltvFormat.indexOf("T$") != -1) {
            return 'top';
        }
        if (ltvFormat.indexOf("$") != -1) {
            return 'en';
        }
        else if (ltvFormat.indexOf("£") != -1) {
            return 'en-gb';
        }
        else if (ltvFormat.indexOf("€") != -1) {
            return 'de';
        }
        else if (ltvFormat.indexOf("¥") != -1) {
            return 'ja';
        }
        else if (ltvFormat.toLowerCase().indexOf("aed") != -1) {
            return 'aed';
        }
        else if (ltvFormat.toLowerCase().indexOf("rub") != -1) {
            return 'ru-UA';
        }
        else if (ltvFormat.toLowerCase().indexOf("₹") != -1) {
            return 'inr';
        }
        else if (ltvFormat.toLowerCase().indexOf("try") != -1) {
            return 'tr';
        }
        else if (ltvFormat.toLowerCase().indexOf("kr") != -1) {
            return 'sv-se';
        }
        else if (ltvFormat.toLowerCase().indexOf("gel") != -1) {
            return 'ka-ge';
        }
        else if (ltvFormat.toLowerCase().indexOf("zar") != -1) {
            return 'za-za';
        }
        else if (ltvFormat.toLowerCase().indexOf("pln") != -1) {
            return 'pl-pl';
        }
        else if (ltvFormat.toLowerCase().indexOf("₪") != -1) {
            return 'he';
        }
        else if (ltvFormat.toLowerCase().indexOf("₦") != -1) {
            return 'ngn';
        }
        else if (ltvFormat.indexOf("Ƀ") != -1) {
            return 'btc';
        }
        else if (ltvFormat.toLowerCase().indexOf("kn") != -1) {
            return 'hrk';
        }
        else if (ltvFormat.toLowerCase().indexOf("rp") != -1) {
            return 'idr';
        }
        else if (ltvFormat.toLowerCase().indexOf("lei") != -1) {
            return 'lei';
        }
        else if (ltvFormat.toLowerCase().indexOf("kč") != -1) {
            return 'cs-cz';
        }
        else if (ltvFormat.toLowerCase().indexOf("rm") != -1) {
            return 'ms-my';
        }
        else if (ltvFormat.toLowerCase().indexOf("֏") != -1) {
            return 'amd';
        } else if (ltvFormat.toLowerCase().indexOf("₴") != -1) {
            return 'uah';
        } else if (ltvFormat.toLowerCase().indexOf("лв") != -1) {
            return 'bgn';
        } else if (ltvFormat.toLowerCase().indexOf("tk") != -1) {
            return 'bdt';
        } else if (ltvFormat.toLowerCase().indexOf("bbd") != -1) {
            return 'bbd';
        } else if (ltvFormat.toLowerCase().indexOf("br") != -1) {
            return 'byr';
        } else if (ltvFormat.toLowerCase().indexOf("nu") != -1) {
            return 'btn';
        } else if (ltvFormat.toLowerCase().indexOf("bs") != -1) {
            return 'bob';
        } else if (ltvFormat.toLowerCase().indexOf("fbu") != -1) {
            return 'bif';
        } else if (ltvFormat.toLowerCase().indexOf("khr") != -1) {
            return 'khr';
        } else if (ltvFormat.toLowerCase().indexOf("esc") != -1) {
            return 'cve';
        } else if (ltvFormat.toLowerCase().indexOf("bceao") != -1) {
            return 'hof';
        } else if (ltvFormat.toLowerCase().indexOf("beac") != -1) {
            return 'haf';
        } else if (ltvFormat.toLowerCase().indexOf("kmf") != -1) {
            return 'kmf';
        } else if (ltvFormat.toLowerCase().indexOf("₡") != -1) {
            return 'crc';
        } else if (ltvFormat.toLowerCase().indexOf("fdj") != -1) {
            return 'fdj';
        } else if (ltvFormat.toLowerCase().indexOf("eek") != -1) {
            return 'eek';
        } else if (ltvFormat.toLowerCase().indexOf("fg") != -1) {
            return 'gnf';
        } else if (ltvFormat.toLowerCase().indexOf("ghs") != -1) {
            return 'ghs';
        } else if (ltvFormat.toLowerCase().indexOf("ft") != -1) {
            return 'huf';
        } else if (ltvFormat.toLowerCase().indexOf("﷼") != -1) {
            return 'irr';
        } else if (ltvFormat.toLowerCase().indexOf("jod") != -1) {
            return 'jod';
        } else if (ltvFormat.toLowerCase().indexOf("kzt") != -1) {
            return 'kzt';
        } else if (ltvFormat.toLowerCase().indexOf("ksh") != -1) {
            return 'kes';
        } else if (ltvFormat.toLowerCase().indexOf("₩") != -1) {
            return 'krw';
        } else if (ltvFormat.toLowerCase().indexOf("kgs") != -1) {
            return 'kgs';
        } else if (ltvFormat.toLowerCase().indexOf("mkd") != -1) {
            return 'mkd';
        } else if (ltvFormat.toLowerCase().indexOf("mk") != -1) {
            return 'mwk';
        } else if (ltvFormat.toLowerCase().indexOf("rf") != -1) {
            return 'mvr';
        } else if (ltvFormat.toLowerCase().indexOf("um") != -1) {
            return 'mro';
        } else if (ltvFormat.toLowerCase().indexOf("₨") != -1) {
            return 'mur';
        } else if (ltvFormat.toLowerCase().indexOf("mdl") != -1) {
            return 'mdl';
        } else if (ltvFormat.toLowerCase().indexOf("₮") != -1) {
            return 'mnt';
        } else if (ltvFormat.toLowerCase().indexOf("₨") != -1) {
            return 'npr';
        } else if (ltvFormat.toLowerCase().indexOf("naƒ") != -1) {
            return 'ang';
        } else if (ltvFormat.toLowerCase().indexOf("₩") != -1) {
            return 'kpw';
        } else if (ltvFormat.toLowerCase().indexOf("rs") != -1) {
            return 'pkr';
        } else if (ltvFormat.toLowerCase().indexOf("₲") != -1) {
            return 'pyg';
        } else if (ltvFormat.toLowerCase().indexOf("s/") != -1) {
            return 'pen';
        } else if (ltvFormat.toLowerCase().indexOf("₱") != -1) {
            return 'php';
        } else if (ltvFormat.toLowerCase().indexOf("zł") != -1) {
            return 'pln';
        } else if (ltvFormat.toLowerCase().indexOf("руб") != -1) {
            return 'rub';
        } else if (ltvFormat.toLowerCase().indexOf("rf") != -1) {
            return 'rwf';
        } else if (ltvFormat.toLowerCase().indexOf("chf") != -1) {
            return 'chf';
        } else if (ltvFormat.toLowerCase().indexOf("db") != -1) {
            return 'std';
        } else if (ltvFormat.toLowerCase().indexOf("sr") != -1) {
            return 'scr';
        } else if (ltvFormat.toLowerCase().indexOf("le") != -1) {
            return 'sll';
        } else if (ltvFormat.toLowerCase().indexOf("sk") != -1) {
            return 'skk';
        } else if (ltvFormat.toLowerCase().indexOf("r") != -1) {
            return 'zar';
        } else if (ltvFormat.toLowerCase().indexOf("ரூ") != -1) {
            return 'lkr';
        } else if (ltvFormat.toLowerCase().indexOf("sdg") != -1) {
            return 'sdg';
        } else if (ltvFormat.toLowerCase().indexOf("szl") != -1) {
            return 'szl';
        } else if (ltvFormat.toLowerCase().indexOf("syp") != -1) {
            return 'syp';
        } else if (ltvFormat.toLowerCase().indexOf("฿") != -1) {
            return 'thb';
        } else if (ltvFormat.toLowerCase().indexOf("ytl") != -1) {
            return 'try';
        } else if (ltvFormat.toLowerCase().indexOf("x") != -1) {
            return 'tzs';
        } else if (ltvFormat.toLowerCase().indexOf("ttd") != -1) {
            return 'ttd';
        } else if (ltvFormat.toLowerCase().indexOf("ush") != -1) {
            return 'ugx';
        } else if (ltvFormat.toLowerCase().indexOf("uyu") != -1) {
            return 'uyu';
        } else if (ltvFormat.toLowerCase().indexOf("uzs") != -1) {
            return 'uzs';
        } else if (ltvFormat.toLowerCase().indexOf("vt") != -1) {
            return 'vuv';
        } else if (ltvFormat.toLowerCase().indexOf("₫") != -1) {
            return 'vnd';
        } else if (ltvFormat.toLowerCase().indexOf("vef") != -1) {
            return 'vef';
        } else if (ltvFormat.toLowerCase().indexOf("yer") != -1) {
            return 'yer';
        } else if (ltvFormat.toLowerCase().indexOf("zmk") != -1) {
            return 'zmk';
        } else if (ltvFormat.toLowerCase().indexOf("s") != -1) {
            return 'awg';
        } else if (ltvFormat.toLowerCase().indexOf("l") != -1) {
            return 'all';
        } else if (ltvFormat.toLowerCase().indexOf("p") != -1) {
            return 'bwp';
        } else if (ltvFormat.toLowerCase().indexOf("d") != -1) {
            return 'gmd';
        } else if (ltvFormat.toLowerCase().indexOf("q") != -1) {
            return 'gtq';
        } else if (ltvFormat.toLowerCase().indexOf("g") != -1) {
            return 'htg';
        } else if (ltvFormat.toLowerCase().indexOf("l") != -1) {
            return 'hnl';
        } else if (ltvFormat.toLowerCase().indexOf("k") != -1) {
            return 'mmk';
        } else if (ltvFormat.toLowerCase().indexOf("f") != -1) {
            return 'xpf';
        } else if (ltvFormat.toLowerCase().indexOf("b") != -1) {
            return 'pab';
        } else if (ltvFormat.toLowerCase().indexOf("k") != -1) {
            return 'pgk';
        } else if (ltvFormat.toLowerCase().indexOf("l") != -1) {
            return 'ron';
        }
        else { return 'en'; }
    }

    currencySignReplace(format: string) {
        return format.replace("£", "$")
            .replace("€", "$")
            .replace("¥", "$")
            .replace("R$", "$")
            .replace("RUB", "$")
            .replace("₹", "$")
            .replace("TRY", "$")
            .replace("kr", "$")
            .replace("GEL", "$")
            .replace("ZAR", "$")
            .replace("PLN", "$")
            .replace("₪", "$")
            .replace("₦", "$")
            .replace("Ƀ", "$")
            .replace("kn", "$")
            .replace("Rp", "$")
            .replace("lei", "$")
            .replace("Kč", "$")
            .replace("RM", "$")
            .replace("֏", "$")
            .replace("₴", "$")
            .replace("COP", "$")
            .replace("лв", "$")
            .replace("B$", "$")
            .replace("Tk", "$")
            .replace("BBD", "$")
            .replace("Br", "$")
            .replace("BZ$", "$")
            .replace("BD$", "$")
            .replace("Nu", "$")
            .replace("Bs", "$")
            .replace("FBu", "$")
            .replace("KHR", "$")
            .replace("Esc", "$")
            .replace("BCEAO", "$")
            .replace("BEAC", "$")
            .replace("KMF", "$")
            .replace("₡", "$")
            .replace("$MN", "$")
            .replace("Fdj", "$")
            .replace("EEK", "$")
            .replace("FG", "$")
            .replace("GY$", "$")
            .replace("GHS", "$")
            .replace("HK$", "$")
            .replace("Ft", "$")
            .replace("Rs", "$")
            .replace("﷼", "$")
            .replace("JOD", "$")
            .replace("KZT", "$")
            .replace("KSh", "$")
            .replace("₩", "$")
            .replace("KGS", "$")
            .replace("MKD", "$")
            .replace("UM", "$")
            .replace("₨", "$")
            .replace("MDL", "$")
            .replace("₮", "$")
            .replace("N$", "$")
            .replace("₨", "$")
            .replace("NAƒ", "$")
            .replace("₩", "$")
            .replace("₲", "$")
            .replace("S/", "$")
            .replace("₱", "$")
            .replace("zł", "$")
            .replace("L", "$")
            .replace("руб", "$")
            .replace("RF", "$")
            .replace("CHF", "$")
            .replace("WS$", "$")
            .replace("Db", "$")
            .replace("SR", "$")
            .replace("Le", "$")
            .replace("S$", "$")
            .replace("Sk", "$")
            .replace("SI$", "$")
            .replace("ரூ", "$")
            .replace("SDG", "$")
            .replace("SZL", "$")
            .replace("SYP", "$")
            .replace("฿", "$")
            .replace("YTL", "$")
            .replace("NT$", "$")
            .replace("T$", "$")
            .replace("TTD", "$")
            .replace("USh", "$")
            .replace("UYU", "$")
            .replace("UZS", "$")
            .replace("Vt", "$")
            .replace("₫", "$")
            .replace("VEF", "$")
            .replace("YER", "$")
            .replace("ZMK", "$")
            .replace("RD$", "$")
            .replace("EC$", "$")
            .replace("C$", "$")
            .replace("FJ$", "$")
            .replace("MK", "$")
            .replace("Rf", "$")
            .replace("L", "$")
            .replace("s", "$")
            .replace("G", "$")
            .replace("D", "$")
            .replace("Q", "$")
            .replace("R", "$")
            .replace("x", "$")
            .replace("B", "$")
            .replace("K", "$")
            .replace("F", "$")
            .replace("P", "$");
    };

    isBigNumber(num, precision): boolean {
        if (num === this.formatBigNumber(num, precision))
            return false;
        return true;
    }

    formatBigNumber(num, decimalPointPrecision) {
        let million = 1000000;
        let tenK = 10000;

        if (num < tenK) {
            return num;
        }
        else {
            let addedLetter;
            let divider;
            if (num >= tenK && num < million) {
                addedLetter = 'K';
                divider = 1000;
            }
            else {
                addedLetter = 'M';
                divider = million;
            }
            let res = this.roundFigure(num / divider, decimalPointPrecision);
            let resParts = res.split('.');
            return resParts[1] == '0' ? resParts[0] + addedLetter : res + addedLetter;

        }
    }

    /**
     * Converts num to a decimal string (if it isn't one already) and then rounds it
     * to at most dp decimal places.
     *
     * For explanation of why you'd want to perform rounding operations on a String
     * rather than a Number, see http://stackoverflow.com/a/38676273/1709587
     *
     * @param {(number|string)} num
     * @param {number} dp
     * @return {string}
     */
    roundFigure (num, dp: number = 2) {
        num = num.toString();
        if (num.indexOf('e+') != -1) {
            // Can't round numbers this large because their string representation
            // contains an exponent, like 9.99e+37
            throw new Error("num too large");
        }
        if (num.indexOf('.') == -1) {
            // Nothing to do
            return num;
        }
        if (dp <= 0) {
            return num;
        }

        var parts = num.split('.'),
            beforePoint = parts[0],
            afterPoint = parts[1],
            shouldRoundUp = afterPoint[dp] >= 5,
            decimalIndex = num.indexOf('.'),
            finalNumber,
            ignoreDecimal = false;

        afterPoint = afterPoint.slice(0, dp);
        finalNumber = beforePoint + afterPoint;

        if (shouldRoundUp) {
            // handle logic like 999.99999
            let i;
            for (i = finalNumber.length - 1; i >= 0 && this.getDigitAtIdx(finalNumber, i) === 0; i--) { }
            if (i < 0) {
                ignoreDecimal = true;
                finalNumber = Math.pow(10, finalNumber.length - dp).toString();
            } else {
                let newDigit = this.getDigitAtIdx(finalNumber, i);
                finalNumber =  this.replaceStringAt(finalNumber, newDigit, i, i+1).substr(0, i+1); // remove accumlated digits
                if(beforePoint.length > finalNumber.length) {
                    finalNumber = finalNumber + '0'.repeat(beforePoint.length - finalNumber.length);
                }
            }
        }

        if (!ignoreDecimal) {
            finalNumber = this.replaceStringAt(finalNumber, '.', decimalIndex);  //reinsert decimal number
        }

        // Remove trailing decimal point from numbers rounded to integers
        return finalNumber.replace(/\.+$/, '');
    }

    getDigitAtIdx(numStr, i){
        return (parseInt(numStr[i]) + 1) % 10;
    }


    replaceStringAt(text, str, startIdx, endIdx = undefined){
        endIdx = typeof endIdx !== 'undefined' ? endIdx : startIdx;
        return text.slice(0, startIdx) + str +  text.slice(endIdx);
    }

    addCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    finalFormat(formattedValue) {
        if (typeof formattedValue === 'string' && formattedValue.indexOf('.') != -1) {
            let precisionPattern = "[0-9]+\\.[0-9]*";
            let percisionMatch = formattedValue.match(precisionPattern);
            let counter = 0;
            for (let i = percisionMatch[0].length - 1; i > 0; i--) {
                if (percisionMatch[0][i] === ".") {
                    counter++;
                    break;
                }
                if (percisionMatch[0][i] !== "0")
                    break;
                counter++;
            }
            let val = percisionMatch[0].substring(0, percisionMatch[0].length - counter);
            return  formattedValue.split(percisionMatch[0]).join(val);
        }
        return formattedValue;
    }

}

export interface IFormatterOptions {
    shouldHandleSmallNumbers?: boolean,
    shouldHandleBigNumbers?: boolean,
    decimalPointPrecision?: number,
    maxFormatLengthPrecision?: number,
    allowBlankValue?:boolean,
    handleSpecialCustomersValue?: boolean
}