import { MicrofrontendOptions } from "./microfrontend.model";

export const Microfrontends: { [key: string]: MicrofrontendOptions } = {
  dashboard: {
    exposedModule: "./Dashboard",
    remoteName: "optimoveDashboard",
    elementName: "optimove-dashboard"
  },
  optiPromo:{
    exposedModule: "./optiPromo",
    remoteName: "optiPromo",
    elementName: "opti-promo"
  },
  predictionTree: {
    exposedModule: "./PredictionTree",
    remoteName: "predictionTreeServiceUi",
    elementName: "audience-discovery"
  },
  importedCustomers: {
    exposedModule: "./importedCustomers",
    remoteName: "importedCustomersUi",
    elementName: "imported-customers"
  },
  realtimeAttributes: {
    exposedModule: "./realtimeAttributes",
    remoteName: "realtimeAttributesUi",
    elementName: "realtime-attributes"
  },
  dataStudio: {
    exposedModule: "DataStudioModule",
    remoteName: "dataStudio"
  },
  dataIngestion: {
    exposedModule: "DataIngestionMainModule",
    remoteName: "dataIngestion"
  }
};

export const MicrofrontendModules: { [key: string]: MicrofrontendOptions } = {
  dataStudio: {
    exposedModule: "DataStudioModule",
    remoteName: "dataStudio"
  },
  dataIngestion: {
    exposedModule: "DataIngestionMainModule",
    remoteName: "dataIngestion"
  }
};
