import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
    AllCommunityModules,
    AllEnterpriseModules,
    ColDef,
    ColumnApi,
    GridApi,
    GridOptions,
    Module
} from "@ag-grid-enterprise/all-modules";
import * as moment from "moment";
import { CampaignGridTriggerColumnComponent } from "src/app/features/customer360/components/campaign-grid/triggerColumn/campaignGridTriggerColumn.component";
import { FormatterPipe, IFormatterOptions } from "src/app/pipes/formatter.pipe";
import { Customer360DataService } from "src/app/features/customer360/services/customer360.data.service";
import { ICustomerAttributeMapping } from "src/app/features/customer360/customer360.models";

@Component({
    selector: 'attributes-grid',
    templateUrl: './attributesGrid.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./attributesGrid.component.scss']
})
export class AttributesGridComponent implements OnInit, OnChanges {

    @Input() clientCustomerId: string;
    @Input() customerAttributes: string;
    @Input() customerAttributeMappingList: ICustomerAttributeMapping[];
    @Output() onDataLoaded: EventEmitter<void> = new EventEmitter<void>();
    @Output() onExportReady: EventEmitter<any> = new EventEmitter<any>();

    public isTableLoading: boolean;
    public frameworkComponents;
    public gridOptions: GridOptions | undefined;
    public gridApi: GridApi | undefined;
    public gridColumnApi: ColumnApi | undefined;
    public modules: Module[] = [...AllCommunityModules, ...AllEnterpriseModules];

    private formatterOptions: IFormatterOptions = {
        shouldHandleSmallNumbers: false,
        shouldHandleBigNumbers: true,
        decimalPointPrecision: 1,
        handleSpecialCustomersValue: true
    };

    public data: any[];

    constructor(
        private dataService: Customer360DataService,
        private translateService: TranslateService,
        private formatter: FormatterPipe,
        private readonly cd: ChangeDetectorRef
    ) {
        this.frameworkComponents = {
            campaignGridTriggerColumnComponent: CampaignGridTriggerColumnComponent
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.customerAttributes && this.customerAttributeMappingList) {
            this.initGridOptions();
            if (this.gridApi) {
                this.isTableLoading = true;
                this.dataService.getProfileAttributesByCustomerBetweenDates(this.clientCustomerId, this.customerAttributes, null, null)
                    .subscribe(response => {
                        if (!response) {
                            console.log(response);
                        } else {
                            this.data = response.Data.ProfileAttributesByCustomerBetweenDates || [];
                            this.onExportReady.emit(response.Data.ProfileAttributesByCustomerBetweenDates || []);
                            this.gridApi.setRowData(this.data);
                            this.gridApi.refreshHeader();
                            this.gridApi.refreshCells();
                            this.gridApi.setColumnDefs(this.createColumnDefs());
                            this.onDataLoaded.emit();
                        }
                        this.cd.detectChanges();

                        this.isTableLoading = false;
                    });
            }
        }
    }

    ngOnInit() {
    }

    initGridOptions() {
        this.gridOptions = <GridOptions>{
            context: this,
            columnDefs: this.createColumnDefs(),
            defaultColDef: this.createDefaultColDef(),
            headerHeight: 30,
            rowHeight: 44,
            rowBuffer: 0,
            wrapText: true,
            suppressHorizontalScroll: false,
            debug: false,
            rowModelType: "clientSide",
            loadingCellRendererParams: {
                loadingMessage: 'Loading...',
            },
            serverSideStoreType: "partial",
            blockLoadDebounceMillis: 1000,
            cacheBlockSize: 100,
            cacheOverflowSize: 20,
            enableCellTextSelection: true,
            maxConcurrentDatasourceRequests: 4,
            infiniteInitialRowCount: 100,
            suppressDragLeaveHidesColumns: true,
            suppressCellSelection: true,
            valueCache: true,
            rowClass: 'manage-streams-row',
            onGridSizeChanged: this.onGridSizeChanged,
            overlayNoRowsTemplate: `<div class="text-center text-muted">
                <h1>${this.translateService.instant('features.communication_stream.manage.NO_SEARCH_RESULTS')}</h1>
            </div>`,
            onFilterChanged: this.handleNoResultsOverlay.bind(this),
            onGridReady: (params) => {
                this.gridApi = params.api;
                this.gridColumnApi = params.columnApi
                this.isTableLoading = true;
                this.dataService.getProfileAttributesByCustomerBetweenDates(this.clientCustomerId, this.customerAttributes, null, null)
                    .subscribe(response => {
                        if (response.Data.ProfileAttributesByCustomerBetweenDates) {
                            this.data = response.Data.ProfileAttributesByCustomerBetweenDates || [];
                            this.onExportReady.emit(response.Data.ProfileAttributesByCustomerBetweenDates || []);
                            this.gridApi?.setRowData(this.data);
                            this.onDataLoaded.emit();
                            this.cd.detectChanges();
                        }

                        this.isTableLoading = false;
                    });
            }
        };
    }

    private createDefaultColDef(): ColDef {
        return {
            sortable: true,
            resizable: true,
            wrapText: true,
            lockPinned: false,
            minWidth: 100
        };
    }

    private createColumnDefs(): ColDef[] {
        let periods: ColDef = {
            headerName: this.translateService.instant('features.customer360.body.explore_customer.COLUMNS_TITLES.SNAPSHOT_DATE'),
            field: "Period",
            resizable: true,
            sortable: true,
            sort: 'desc',
            cellRenderer: (data) => {
                return moment(data.value).format('YYYY-MM-DD');
            }
        };

        if (!this.customerAttributes || !this.customerAttributeMappingList)
            return [periods];

        let columns: ColDef[] = this.customerAttributes.split(',')
            .map(field => {
                const attributeMapping = this.customerAttributeMappingList.find(x => x.Name.toLowerCase() === field.toLowerCase());
                return {
                    headerName: attributeMapping?.Alias,
                    headerComponent: attributeMapping?.IsFieldUpdatedByApi || attributeMapping?.Alias.includes('_RT')
                        ? 'campaignGridTriggerColumnComponent'
                        : null,
                    field: field,
                    resizable: true,
                    sortable: true,
                    cellStyle: function (params) {
                        if (attributeMapping.MetricType === 1) {
                            return { 
                                textAlign: 'right', 
                                textOverflow: 'ellipsis', 
                                whiteSpace: 'nowrap' 
                            };
                        }
                        return { textOverflow: 'ellipsis', whiteSpace: 'nowrap' };
                    },
                    cellRenderer: (data) => {
                        if (attributeMapping.MetricType === 3) {
                            return data.value ? moment(data.value).format('YYYY-MM-DD') : '—';
                        } else {
                            if (attributeMapping.Format != '0') {
                                return this.formatter.transform(!data.value ? 0 : data.value, attributeMapping.Format, this.formatterOptions, attributeMapping.EnforceFormatting);
                            } else {
                                return !data.value ? '—' : data.value;
                            }
                        }

                    },
                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                        if (valueA == valueB) return 0;
                        return (valueA > valueB) ? 1 : -1;
                    },
                }
            });

        columns.unshift(periods);

        return columns;
    }

    public handleNoResultsOverlay() {
        if (this.gridApi?.getDisplayedRowCount() === 0) {
            this.gridApi.showNoRowsOverlay();
        } else {
            this.gridApi?.hideOverlay();
        }
    }

    private scrollToTop() {
        this.gridApi?.ensureIndexVisible(0, 'top');
    }

    private onGridSizeChanged(params: any) {
        //params.api.sizeColumnsToFit();
    }
}