import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { forkJoin, Observable } from "rxjs";
import { first } from 'rxjs/operators';
import { OslCellType } from "../../../../components/optiSearchList/models/osl-cell-type.enum";
import { SearchItem } from "../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";
import { SsmService } from "../../../../services/ssm.service";
import { AttributeToExport } from "../../campaignAnalysis.models";

@Injectable({ providedIn: 'root' })
export class AttributeExportService {

    private baseUrl: string = '/CampaignAnalysis';
    private attributesSearchItems: SearchItem[] = [];
    private SSM;

    constructor(private readonly httpService: HttpService, private SSMService: SsmService) {}

    public getAttributesSearchItems(): SearchItem[] {
        if (this.attributesSearchItems.length === 0) {
            this.SSM = this.SSMService.getSSM();
            let attributes = this.SSM.GetGeneric(this.SSM.Resx.CustomerAttributes);

            for (let attributeName in attributes) {
                if (!attributes[attributeName].IsHidden) {
                    this.attributesSearchItems.push({
                        Name: attributeName, 
                        Alias: attributes[attributeName].Alias,
                        type: OslCellType.Item
                    } as SearchItem);
                }
            }
        }
        return this.attributesSearchItems;
    }

    public getAttributesListToExport(isVisitorCampaign: boolean): Observable<AttributeToExport[]> {
        let observer = new Observable<AttributeToExport[]>((observer) => {
            let attributesSearchList = this.getAttributesSearchItems();
            let attributesToExport: AttributeToExport[] = [];
    
            forkJoin({
                customAttributes: this.getCustomAttributesListToExport(),
                defaultAttributes: 
                    isVisitorCampaign 
                    ? this.GetDefaultVisitorAttributesToExport() 
                    : this.GetDefaultCustomerAttributesToExport()
            }).pipe(first()).subscribe((res) => {
                for (let attributeName of res.customAttributes.Data) {
                    if (attributeName) {
                        let attributeSearchItem = attributesSearchList.find(x => x.Name === attributeName);
                        attributesToExport.push({attributeSearchItem: attributeSearchItem, isDefault: false} as AttributeToExport);
                    }
                }
                for (let attributeAlias of res.defaultAttributes.Data) {
                    if (attributeAlias) {
                        let attributeSearchItem = {Alias: attributeAlias, Name: attributeAlias};
                        attributesToExport.push({attributeSearchItem: attributeSearchItem, isDefault: true} as AttributeToExport);
                    }
                }
                observer.next(attributesToExport);
                observer.complete();
            });
        });
        return observer;
    }
    
    public setAttributesListToExport(attributesNames: string[]): Observable<DefaultAjaxResponse> {
        const body = {attributeFieldNames: attributesNames};
        return this.httpService.post<DefaultAjaxResponse>(`${this.baseUrl}/SetAttributesListToExport`, body);
    }

    public getCustomAttributesListToExport(): Observable<DefaultAjaxResponse> {
        return this.httpService.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomAttributesListToExport`, null);
    }

    public GetDefaultVisitorAttributesToExport(): Observable<DefaultAjaxResponse> {
        return this.httpService.get<DefaultAjaxResponse>(`${this.baseUrl}/GetDefaultVisitorAttributesToExport`, null);
    }

    public GetDefaultCustomerAttributesToExport(): Observable<DefaultAjaxResponse> {
        return this.httpService.get<DefaultAjaxResponse>(`${this.baseUrl}/GetDefaultCustomerAttributesToExport`, null);
    }
}