import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { PreferenceCenterService } from '../../services/preferenceCenter.service';
import { Router } from '@angular/router';
import { BrandGroup } from '../../models/brandGroup.model';
import { ErrorModalService } from '../../../../manageTemplates/services/errorModal.service';
import { TranslateService } from '@ngx-translate/core';
import { Topic } from '../../models/topic.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';

@Component({
  selector:'add-update-topic',
  templateUrl:'./addUpdateTopic.component.html',
  styleUrls:['./addUpdateTopic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddUpdateTopicComponent implements OnInit{
  public isSaving: boolean;
  public brandGroup: BrandGroup;
  public topic: Topic = {
    id: null,
    name: undefined,
    description: undefined,
    parentId: null,
    order: 0,
    brandGroupId: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null
  };
  public isEditMode: boolean;
  constructor(private service: PreferenceCenterService,
              private router: Router,
              private errorModal: ErrorModalService,
              private translateService: TranslateService,
              private clipboard: Clipboard,
              private modalService: OptiLogicModalService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    let currentState = this.router.getCurrentNavigation().extras.state;
    if ('channelBrands' in currentState) {
      this.brandGroup = currentState as BrandGroup;
      this.topic.brandGroupId = this.brandGroup.id;
    }
    else {
      this.topic = currentState as Topic;
      this.isEditMode = true;
    }
    }

  public navigateToPreferenceCenter() {
    this.router.navigate(['/user-settings/preferenceCenter/list'])
  }

  public addUpdateTopic() {
    this.isSaving = true;
    if (!this.isEditMode) {
     this.addTopicRequest()
    }
    else {
      this.updateTopicRequest();
    }
    }

  public addTopicRequest(){
    this.service.addTopic(this.topic).subscribe(
      topic => {
        this.isSaving = false;
        if (topic) {
          this.navigateToPreferenceCenter();
          //timeout is needed for topicsList component to be initialized. In other case toast us not showing up
          setTimeout(() => {
            this.service.topicCreatedEvent(topic);
          },2000);
        } else {
          this.showErrorSaveTopic();
        }
      }, err => {
        this.isSaving = false;
        this.showErrorSaveTopic();
      }
    );
  }

  public showErrorSaveTopic(){
    this.errorModal.openErrorModal(
      this.translateService.instant('features.user_settings.body.preference_center.FAILED_SAVE'),
      this.translateService.instant('features.user_settings.body.preference_center.SAVE_TOPIC_ACTION')
    );
    }

  public showErrorDeleteTopic(){
    this.errorModal.openErrorModal(
      this.translateService.instant('features.user_settings.body.preference_center.FAILED_DELETE'),
      this.translateService.instant('features.user_settings.body.preference_center.DELETE_TOPIC_ACTION')
    );
    }

  public updateTopicRequest(){
    this.service.updateTopic(this.topic).subscribe(
      topic => {
        this.isSaving = false;
        if (topic) {
          this.navigateToPreferenceCenter();
          setTimeout(() => {
            this.service.topicUpdatedEvent(topic);
          },2000);
        } else {
          this.showErrorSaveTopic();
        }
      },err => {
        this.isSaving = false;
        this.showErrorSaveTopic();
      }
    );
    }
  
  public copyId(){
    this.clipboard.copy(this.topic.id);
    }

  public deleteTopic(){
    this.modalService.openModalMessage('sm', {
      message: this.translate.instant('features.user_settings.body.preference_center.TOPIC_DELETE_CONFIRM'),
      buttons: [
        {
          class: 'btn-primary',
          label: this.translate.instant('general.DELETE'),
          action: this.deleteTopicAction.bind(this)
        },
        {
          class: 'btn-default',
          label: this.translate.instant('general.CANCEL'),
          action: () => {}
        }
      ]
    });
    }

  public deleteTopicAction(){
    this.service.deleteTopic(this.topic.id).subscribe(topic => {
        if (topic) {
          this.navigateToPreferenceCenter();
          setTimeout(() => {
            this.service.topicDeletedEvent(topic);
          },2000);
        } else {
          this.showErrorDeleteTopic();
        }
      },err => {
        this.showErrorDeleteTopic();
      }
    );
  }
}
