<div class="templates-preview-carousel-modal">
    <div class="panel panel-default" *ngIf="!isLoading">
            <div class="panel-heading">
                <div>
                    <h2 ellipsis-title>{{templates[currentTemplateIndex].name}}</h2>

                    <div *ngIf="templates[currentTemplateIndex].time">
                        {{templates[currentTemplateIndex].time}} {{templates[currentTemplateIndex].timeZone}}
                    </div>
                </div>

                <div class="right-col" *ngIf="templates.length > 1">
                    <button (click)="onShowTemplatesGridViewModal()" class="btn-grid-view btn-link">{{'components.templatePreview.SHOW_ALL' | translate}}</button>
                    
                    <div class="templates-browse-state">
                        {{'components.templatePreview.MODAL_BROWSE_STATE' | translate: {currentTemplateIndex: currentTemplateIndex + 1,templatesLength: templates.length} }}
                    </div>
                </div>
            </div>

            <div class="panel-body">
                <button *ngIf="templates.length > 1" class="btn-link template-browse-btn btn-browse-left" (click)="showPreviousTemplate()">
                    <i class="material-icons">chevron_left</i>
                </button>
                
                <iframe *ngIf="isTemplatesExists[currentTemplateIndex]" [src]="templatesFullSizeUrl[currentTemplateIndex]"></iframe>
                
                <div class="template-not-avialable-container" *ngIf="!isTemplatesExists[currentTemplateIndex]">
                    <template-not-available [isLargeSize]="true"></template-not-available>
                </div>

                <button *ngIf="templates.length > 1" class="btn-link template-browse-btn btn-browse-right" (click)="showNextTemplate()">
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
    </div>

    <opti-loading *ngIf="isLoading" [show]="true" [size]="'md'"></opti-loading>
</div>
