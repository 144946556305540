import { Component, OnInit, Input, ViewContainerRef, ViewChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input() title: string;
  @Input() active = false;
  @Input() disabled = false;

  @ViewChild('headerTpl', {read: TemplateRef}) headerTemplateRef;
  @ViewChild('bodyTpl', {read: TemplateRef}) bodyTemplateRef;

  constructor(private _viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
  }

}
