import * as moment from 'moment';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class DateService {
    public diffBetweenDates(a:Date,b:Date): number
    {
        const aDate = new Date(moment(a).format('YYYY-MM-DD'));
        const bDate = new Date(moment(b).format('YYYY-MM-DD'));
        const utc1 = Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate());
        const utc2 = Date.UTC(bDate.getFullYear(), bDate.getMonth(), bDate.getDate());
        return utc1 - utc2;
    }

    public formatToHours(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const hoursFormatted = hours.toString().padStart(2, '0');
        const leftMinutes = minutes % 60;
        const minutesFormatted = leftMinutes.toString().padStart(2, '0');
        return `${hoursFormatted}:${minutesFormatted}`;
    }

    public convertTimeTo12HoursClock(time24HoursClock: string): string {
        const timeData = time24HoursClock.split(':');
        const date = moment(); 
        date.hours(Number(timeData[0]));
        date.minutes(Number(timeData[1]));

        return date.format('hh:mm A');
    }

    public formatUtcOffSetToHours(minutes: number): string {
        const absMinutes = Math.abs(minutes);
        const formattedHours = this.formatToHours(absMinutes);
        
        let signedHours;
        if (minutes === 0) {
            signedHours = formattedHours;
        } else if (minutes < 0) {
            signedHours = `+${formattedHours}`;
        } else {
            signedHours = `-${formattedHours}`;
        }

        return `(UTC ${signedHours})`;
    }

    public convertTimeTo24HoursClock(time12HoursClock: string): string {
        const dateWithoutTime = moment().format('DD/MM/YYYY'); 
        const dateWithTime = moment(`${dateWithoutTime} ${time12HoursClock}`, 'DD/MM/YYYY hh:mm A');
        
        return dateWithTime.format('HH:mm');
    }
}