<div class="templates-previews-header" data-qa-id="templates-previews-header">
  <div class="selection-container">
    <div class="checkbox checkbox-primary select-all-container">
      <input id="select-all-checkbox" type="checkbox" name="selectAll" [(ngModel)]="isSelectAllChecked" (change)="onSelectAllChecked()" />
      <label class="select-all-label" [ngClass]="{'indeterminate-state': this.isSelectAllIndeterminateState}"></label>
    </div>
    <div class="number-of-templates" *ngIf="templatesSelected.length === 0 || !isBatchActionsEnabled()" data-qa-id="number-of-templates">
      <p *ngIf="numberTemplates > 1">{{
        "features.manage_templates.messages.TEMPLATES"
          | translate
            : {
                numberTemplates: numberTemplates,
                items:
                  (channelService.isWhatsApp(channelId) ? "features.manage_templates.JOURNEYS" : "features.manage_templates.TEMPLATES")
                  | translate
              }
      }}</p>
      <p *ngIf="numberTemplates === 1">{{
        "features.manage_templates.messages.TEMPLATE"
          | translate
            : {
                numberTemplates: numberTemplates,
                items:
                  (channelService.isWhatsApp(channelId) ? "features.manage_templates.JOURNEY" : "features.manage_templates.TEMPLATE")
                  | translate
              }
      }}</p>
    </div>
    <div *ngIf="numberOfInvalid > 0" class="item-with-border" data-qa-id="number-of-invalid-templates"></div>
    <div *ngIf="numberOfInvalid > 0 && templatesSelected.length === 0" class="number-of-invalid">
      <i class="invalid-templates-icon material-icons info-icon">info</i>
      <p class="invalid-templates-number-message">
        {{ numberOfInvalid }} {{ "features.manage_templates.components.details.INVALID_TEMPLATES" | translate }}
      </p>
    </div>
    <div
      class="number-of-templates"
      *ngIf="templatesSelected.length > 0 && isBatchActionsEnabled()"
      data-qa-id="selected-number-of-templates">
      <p class="selected" *ngIf="!isSearchValid">
        {{ "features.manage_templates.messages.SELECTED" | translate : {templatesSelected: templatesSelected.length} }}
      </p>
      <p class="selected" *ngIf="isSearchValid">
        {{
          "features.manage_templates.messages.FOUND_SELECTED"
            | translate : {numberTemplates: numberTemplates, templatesSelected: templatesSelected.length}
        }}
      </p></div
    >
  </div>

  <div *ngIf="templatesSelected.length > 0 && isBatchActionsEnabled()" class="content-main">
    <div class="actions">
      <div
        [title]="containsMultiLangTemplates ? ('features.manage_templates.components.details.DUPLICATION_DISABLED' | translate) : ''"
        class="batch-action-container"
        *ngIf="isBatchActionsAvailable()">
        <button
          type="button"
          class="btn btn-link btn-link-default icon-batch"
          data-qa-id="batch-action-duplicate"
          [disabled]="containsMultiLangTemplates || isDuplicating"
          (click)="duplicateBatch()">
          <i class="material-icons copy-icon">file_copy</i>
          <p
            class="batch-menu-text"
            [ngClass]="{
              'disabled-text': containsMultiLangTemplates
            }">
            {{ "general.DUPLICATE" | translate }}
          </p>
        </button>
      </div>
      <div
        class="batch-action-container"
        [title]="isMultipleSubMethodsInSelection ? ('features.manage_templates.components.details.MOVE_DISABLED' | translate) : ''">
        <button
          type="button"
          class="btn btn-link btn-link-default icon-batch"
          data-qa-id="batch-action-move"
          [disabled]="isMultipleSubMethodsInSelection"
          (click)="onBatchActionsMoveClicked()">
          <i class="material-icons copy-icon">send</i>
          <p class="batch-menu-text" [ngClass]="{'disabled-text': isMultipleSubMethodsInSelection}">
            {{ "general.MOVE" | translate }}
          </p>
        </button>
      </div>
      <div
        [title]="
          containsMultiLangTemplates || isMultipleSubMethodsInSelection
            ? ('features.manage_templates.components.details.COPYTO_DISABLED' | translate)
            : ''
        "
        *ngIf="isBatchActionsAvailable()"
        class="batch-action-container">
        <button
          type="button"
          class="btn btn-link btn-link-default icon-batch"
          data-qa-id="batch-action-copy"
          [disabled]="containsMultiLangTemplates || isMultipleSubMethodsInSelection"
          (click)="copyToBatch()">
          <i class="material-icons copy-icon">drive_file_move</i>
          <p class="batch-menu-text" [ngClass]="{'disabled-text': containsMultiLangTemplates || isMultipleSubMethodsInSelection}">
            {{ "general.COPY" | translate }}
          </p>
        </button>
      </div>
      <div class="batch-action-container">
        <button
          type="button"
          (click)="batchDelete()"
          class="btn btn-link btn-link-default icon-batch-delete"
          data-qa-id="batch-action-delete">
          <i class="material-icons copy-icon">delete_forever</i>
          <p class="batch-menu-text-delete">{{ "general.DELETE" | translate }}</p>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="templatesSelected.length === 0 || !isBatchActionsEnabled()" class="template-sorting-container">
    <overflow-menu
      data-qa-id="template-sorting"
      [isArrowButtonVisible]="true"
      [overflowMenuButtonText]="sortingTitle !== undefined ? sortingTitle : menuItems[0].text"
      [position]="menuPosition"
      [items]="menuItems">
      <ng-template #overflowMenuItemTemplate let-overflowMenuItem>
        <overflow-menu-item (click)="setChosenSortingTitle(overflowMenuItem.text)" [hasSubMenu]="overflowMenuItem.children?.length > 0">
          <span overflowMenuItemText>{{ overflowMenuItem.text }}</span>
        </overflow-menu-item>
      </ng-template>
    </overflow-menu>
  </div>
</div>
