import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { SearchItem } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { Operator } from "src/app/features/scheduledCampaignBuilder/models/wizardData.model";
import { SsmService } from 'src/app/services/ssm.service';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { OldPurchaseHistoryAttributes, conditionalOperators } from "../attributesList.constants";
import { AttributeDataTypePurchase } from "../attributesList.enums";
import { OldPurchaseHistoryProduct, PurchaseHistoryFlowItem } from "../attributesList.model";
import { PurchaseHistoryFamily } from './../attributesList.enums';

@Injectable()
export class PurchaseAttributesService {

    private _currentAttributeFamily: PurchaseHistoryFamily;
    private _baseUrl: string = '/TargetGroups';
    private _PHFlowData;
    private _productsOldPH: OldPurchaseHistoryProduct[];
    private _productsOldPHValues;
    private _currencySymbol;

    constructor(private readonly httpClient: HttpService, private SSMService: SsmService) {}

    public set currentAttributeFamily(family: PurchaseHistoryFamily) {
        if ([
            PurchaseHistoryFamily.New_PurchaseHistory,
            PurchaseHistoryFamily.New_PurchaseHistoryFirstLast,
            PurchaseHistoryFamily.OLD_PurchaseHistory,
            PurchaseHistoryFamily.Old_PurchaseHistoryFirstLast
        ].includes(family)) {
            this._currentAttributeFamily = family;
        }
    }

    public get currencySymbol(): string {
        return this._currencySymbol ? this._currencySymbol : this.getCurrencySymbol();
    }

    public GetNewPurchaseHistoryAttributesFF(): Observable<boolean> {
        return new Observable((observer) => {
            this.isPurchaseHistoryMultiAttributeFlow().pipe(first()).subscribe(res => {
                observer.next(res.Data);
                observer.complete();
            });
        });
    }

    public GetPHFlowData(): Observable<any> {
        return new Observable((observer) => {
            if (this._PHFlowData) {
                observer.next(this._PHFlowData)
                observer.complete();
            }
            else {
                this.getPurchaseHistoryMultiAttributeFlowData().pipe(first()).subscribe(res => {
                    if (res.isSuccess) {
                        this._PHFlowData = res.data;
                    }
                    observer.next(this._PHFlowData);
                    observer.complete();
                });
            }
        });
    }

    public GetAttributeDataType(attributeRealFieldName: string): Observable<AttributeDataTypePurchase> {
        return new Observable((observer) => {
            if ([
                    PurchaseHistoryFamily.New_PurchaseHistory, 
                    PurchaseHistoryFamily.New_PurchaseHistoryFirstLast
                ].includes(this._currentAttributeFamily)) {
                this.getPurchaseHistoryFlowItem(attributeRealFieldName).pipe(first()).subscribe(flowItem => {
                    switch (flowItem.attributeDataType.toLowerCase()) {
                        case "text":
                        case "string":
                            observer.next("string");
                            break;
                        case "decimal":
                        case "int":
                        case "number":
                            observer.next("number");
                            break;
                    }
                    observer.complete();
                });
            } else {
                let attribute;
                attribute = OldPurchaseHistoryAttributes.find(x => x.realFieldName === attributeRealFieldName);
                
                if (attribute) {
                    observer.next(attribute.dataType);
                    observer.complete();
                }
                else {
                    this.GetProductsForOldPH().pipe(first()).subscribe(productsOldPH => {
                        attribute = productsOldPH.find(x => x.RealFieldName === attributeRealFieldName);
                        switch (attribute.DataType.toLowerCase()) {
                            case "text":
                            case "string":
                                observer.next("string");
                                break;
                            case "decimal":
                            case "int":
                            case "number":
                                observer.next("number");
                                break;
                        }
                        observer.complete();
                    });
                }
            }
            
        });
    }

    public GetPossibleAttributeValues(attributeRealFieldName: string): Observable<SearchItem[]> {
        return new Observable((observer) => {
            if (this._currentAttributeFamily === PurchaseHistoryFamily.New_PurchaseHistory) {
                this.getPurchaseHistoryFlowItem(attributeRealFieldName).pipe(first()).subscribe(flowItem => {
                    this.getProductHistoryMultiAttributeFlowAttributeValues(flowItem.attributeType, flowItem.attributeId)
                    .pipe(first()).subscribe(res => {
                        const values: SearchItem[] = res.data.nextData.map(x => { return {key: x.attributeValueId, value: x.attributeValue} });
                        observer.next(values);
                        observer.complete();
                    });
                });
            }
            else {
                const attribute = OldPurchaseHistoryAttributes.find(x => x.realFieldName === attributeRealFieldName);
                if (attribute) {
                    observer.next([]);
                    observer.complete();
                }
                else {
                    this.getOldPhProduct(attributeRealFieldName).pipe(first()).subscribe(product => {
                        if (this._productsOldPHValues) {
                            observer.next(product.HasEnum ? this._productsOldPHValues[attributeRealFieldName] : []);
                            observer.complete();
                        }
                        else {
                            this.getProductAllFieldValues().pipe(first()).subscribe(OldPHValues => {
                                for (const key in OldPHValues.Data) {
                                    OldPHValues.Data[key] = OldPHValues.Data[key].map(x => { return { key: x.toString().replace(','), value: x } as SearchItem});
                                }
                                this._productsOldPHValues = OldPHValues.Data;
                                observer.next(product.HasEnum ? this._productsOldPHValues[attributeRealFieldName] : []);
                                observer.complete();
                            });
                        }
                    });
                }
            }
        });
    }

    public getActivityTypes(PHFlowData): SearchItem[] {
        let activityTypes: SearchItem[] = [];

        for (const obj in PHFlowData) {
            if (PHFlowData[obj].attributes.find(x => ['decimal', 'number', 'int'].includes(x.attributeDataType.toLowerCase()))) {
                activityTypes.push({
                    key: PHFlowData[obj].productTypeId, 
                    value: PHFlowData[obj].productTypeName
                } as SearchItem);
            }
        }

        return activityTypes;
    }

    public GetOperators(attribtueDataType: AttributeDataTypePurchase): Operator[] {
        return conditionalOperators.find((x) => x.type === attribtueDataType).operators
        .filter(x => !['Is null', 'Is not null'].includes(x.Name));
    }

    public isAttributeValueCurrency(attributeRealFieldName: string): Observable<boolean> {
        return new Observable((observer) => {
            if (this._currentAttributeFamily === PurchaseHistoryFamily.New_PurchaseHistory) {
                this.getPurchaseHistoryFlowItem(attributeRealFieldName).pipe(first()).subscribe(item => {
                    observer.next(item.attributeFormat.includes(this.currencySymbol));
                    observer.complete();
                });
            }
            else {
                const attribute = OldPurchaseHistoryAttributes.find(x => x.realFieldName === attributeRealFieldName);
                if (attribute) {
                    observer.next(attribute.isCurrency);
                    observer.complete();
                }
                else {
                    this.getOldPhProduct(attributeRealFieldName).pipe(first()).subscribe(item => {
                        observer.next(item.FieldFormat.includes(this.currencySymbol));
                        observer.complete();
                    });
                }
            }
        });
    }

    public GetProductsForOldPH(): Observable<OldPurchaseHistoryProduct[]> {
        return new Observable((observer) => {
            if (this._productsOldPH) {
                observer.next(this._productsOldPH);
                observer.complete();
            }
            else {
                this.getPurchaseHistoryData().pipe(first()).subscribe(OldPHData => {
                    let products = [];
                    const productsObj = OldPHData.Data.PurchaseHistory.Products;

                    for (const key in productsObj) {
                        products.push(productsObj[key]);
                    }

                    this._productsOldPH = products;
                    observer.next(this._productsOldPH);
                    observer.complete();
                });

                this.getProductAllFieldValues().pipe(first()).subscribe(OldPHValues => {
                    for (const key in OldPHValues.Data) {
                        OldPHValues.Data[key] = OldPHValues.Data[key].map(x => { return { key: x.toString().replace(','), value: x } as SearchItem});
                    }
                    this._productsOldPHValues = OldPHValues.Data;
                });
            }
        });
    }

    private getPurchaseHistoryFlowItem(attributeRealFieldName: string): Observable<PurchaseHistoryFlowItem> {
        return new Observable((observer) => {
            this.GetPHFlowData().pipe(first()).subscribe(PHFlowData => {
                let flowItems: PurchaseHistoryFlowItem[] = [];

                for (const activityTypeId in PHFlowData as any) {
                    flowItems = flowItems.concat(PHFlowData[activityTypeId].attributes as PurchaseHistoryFlowItem[]);
                }

                const flowItem: PurchaseHistoryFlowItem = flowItems.find(x => x.realFieldName.toLowerCase() === attributeRealFieldName.toLowerCase());
                observer.next(flowItem);
                observer.complete();
            });
        });
    }

    private getOldPhProduct(productRealFieldName: string): Observable<OldPurchaseHistoryProduct> {
        return new Observable((observer) => {
            this.GetProductsForOldPH().pipe(first()).subscribe(products => {
                const product: OldPurchaseHistoryProduct = products.find(x => x.RealFieldName === productRealFieldName);
                observer.next(product);
                observer.complete();
            });
        });
    }

    private getCurrencySymbol(): string {
        var SSM = this.SSMService.getSSM();
        var defaultKpiModel = SSM.GetGeneric(SSM.Resx.DefaultKpiModel);
        var modelFormats = {
            LtvFormat: defaultKpiModel.ltvFormat,
            IncomeFormat: defaultKpiModel.incomeFormat
        };
        return modelFormats.LtvFormat.indexOf('%') > -1 ? '' : modelFormats.LtvFormat.substring(0, modelFormats.LtvFormat.indexOf('#'));
    }
    
    private isPurchaseHistoryMultiAttributeFlow() {
        return this.httpClient.get<DefaultAjaxResponse>('/AttributesManager/IsPurchaseHistoryMultiAttributeFlow', null);
    }
    
    private getPurchaseHistoryMultiAttributeFlowData() {
        return this.httpClient.get<AjaxResponse<any>>(`${this._baseUrl}/GetPurchaseHistoryMultiAttributeFlowData`, null);
    }

    private getProductHistoryMultiAttributeFlowAttributeValues(attributeType, attributeId) {
        const params = HttpService.toHttpParams({attributeType, attributeId});
        return this.httpClient.get<AjaxResponse<any>>(`${this._baseUrl}/GetProductHistoryMultiAttributeFlowAttributeValues`, params);
    }

    private getPurchaseHistoryData() {
        return this.httpClient.get<DefaultAjaxResponse>(`${this._baseUrl}/GetPurchaseHistoryData`, null);
    }

    public getProductAllFieldValues() {
        return this.httpClient.get<DefaultAjaxResponse>(`${this._baseUrl}/GetProductAllFieldValues`, null);
    }

    public isCalculationBodyPristine(form: FormGroup, exceptControl: FormControl){
        let isPristine = true;
    
        for (const control in form.controls) {
          if (!(form.controls[control].pristine || form.controls[control].disabled || form.controls[control] === exceptControl)) {
            isPristine = false;
          }
        }
    
        return isPristine;
    }
}
