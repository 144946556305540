<div class="panel-body api-user-details-body">
    <form [formGroup]="parentForm" data-qa-id="apiUserForm">
        <div class="form-row form-group">
            <label class="col1" data-qa-id="accessTypeLabel">Access Type</label>
            <div class="col2">
                <div class="btn-group btn-group-toggle" data-qa-id="apiUserAccessType">
                    <label class="btn btn-default" (click)="changeSelectedAccessType(AccessType.Full)"  data-qa-id="fullLabel"
                    [ngClass]="{ active: currAccessType === AccessType.Full, disabled: true }" for="rdoFull">
                        <input type="radio" formControlName="accessType" name="accessType" id="rdoFull" [value]="AccessType.Full">
                        <span>Full</span>
                    </label>
                    <label class="btn btn-default" (click)="changeSelectedAccessType(AccessType.Limited)" data-qa-id="limitedLabel"
                    [ngClass]="{ active: currAccessType === AccessType.Limited, disabled: true}" for="rdoLimited">
                        <input type="radio" formControlName="accessType" name="accessType" id="rdoLimited" [value]="AccessType.Limited">
                        <span>Limited</span>
                    </label>
                </div>
            </div>
            <div class="col3"></div>
        </div>
        <div class="form-row form-group">
            <label class="col1" data-qa-id="roleLabel">Role</label>
            <div class="col2">
                <opti-search-list required data-qa-id="apiUserRole"
                    formControlName="role"
                    [items]="rolesItems"
                    [config]="dropdownOslConfig"
                    [isDefaultDisabled]="isWhenValuesHasOneOption">
                </opti-search-list>
            </div>
            <div class="col3"></div>
        </div>
        <div class="form-row-with-notes form-group">
            <label class="col1" for="userName" data-qa-id="nameLabel">Username</label>
            <div class="col2">
                <input type="text" data-qa-id="apiUserName" formControlName="userName" id="userName"
                    [placeholder]="translateKeys.userNamePlaceholder|translate" class="form-control"
                    required>
            </div>
            <div class="col3"></div>
        </div>
        <div class="form-row form-group">
            <div class="col1"></div>
            <div class="col4">
                <div *ngIf="nameFormControl.invalid && nameFormControl.errors?.invalidUserName && nameFormControl.dirty">
                    <p class="small validationErr" data-qa-id="errorUserName">{{translateKeys.userNameErrorMsg | translate }}</p>
                </div>
                <div *ngIf="nameFormControl.invalid && nameFormControl.errors?.invalidAuth0Email && nameFormControl.dirty">
                    <p class="small validationErr" data-qa-id="userNameCannotBeEmail">{{translateKeys.userNameCannotBeEmail | translate }}</p>
                </div>
                <div *ngIf="containsExistingUser()  && nameFormControl.dirty">
                    <p class="small validationErr" data-qa-id="userExistsAlready">{{userExistsMsg}}</p>
                </div>
                <div *ngIf="nameFormControl.errors?.maxlength">
                    <p class="small validationErr" data-qa-id="exccededUserNameLength">{{translateKeys.userNameLimitLength | translate }}</p>
                </div>
                <div class="last-row" *ngIf="(nameFormControl.valid && !containsExistingUser()) || !nameFormControl.dirty">
                    <sup class="note-form" data-qa-id="userNote">{{translateKeys.userNameNote | translate }}</sup>
                </div>
            </div>
        </div>
        <div class="form-row-with-notes form-group">
            <label class="col1" for="email" data-qa-id="emailLabel">Contact Email</label>
            <div class="col2">
                <input type="text" data-qa-id="apiUserEmail" formControlName="email" id="email"
                    [placeholder]="translateKeys.contactEmailPlaceholder|translate" class="form-control"
                    required>
            </div>
            <div class="col3"></div>
        </div>
        <div class="form-row form-group">
            <div class="col1"></div>
            <div class="col4">
                <div *ngIf="emailFormControl.invalid && emailFormControl.errors?.invalidEmail && emailFormControl.dirty">
                    <p class="small validationErr" data-qa-id="errorEmail">{{translateKeys.contactEmailErrorMsg | translate }}</p>
                </div>
                <div class="last-row" *ngIf="emailFormControl.valid || !emailFormControl.dirty">
                    <sup class="note-form" data-qa-id="emailNote">{{translateKeys.contactEmailNote | translate }}</sup>
                </div>
                <div class="last-row" *ngIf="emailFormControl.valid || !emailFormControl.dirty">
                    <sup class="note-form" data-qa-id="emailValidTime">{{translateKeys.contactEmailValidTime | translate}}</sup>
                </div>
            </div>
        </div>
    </form>
</div>
