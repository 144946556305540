<div class="preview-template-instant">
  <div class="container-fluid preview-list">
    <div class="container-fluid preview-list" [hidden]="selectedClient">
      <div class="email-client col-md-3" *ngFor="let client of clients" (click)="selectedClient = client">
        <div class="panel panel-default">
          <div class="panel-body">
            <img
              (error)="client.isFailed = true"
              *ngIf="client?.isLoaded"
              [ngClass]="{loaded: client.isLoaded}"
              [src]="client.thumbnailUrl | safe : 'url'" />
            <div *ngIf="client?.isFailed" [ngClass]="{failed: client?.isFailed}" class="not-available">
              {{ "features.manage_templates.components.optimailPreview.clients.UNAVAILABLE" | translate }}
            </div>
            <span *ngIf="!client?.isFailed && !client?.isLoaded" class="click-loader loader-wrapper">
              <span class="loader preview-loader"></span>
            </span>
          </div>
          <div class="panel-footer">
            <span class="clientName" ellipsis-tooltip="1">{{ client?.clientName }}</span>
            <button class="btn-link btn-link-default pull-right">
              <i class="material-icons">zoom_out_map</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="preview-single" [hidden]="!selectedClient">
    <div class="title">
      <button (click)="back()" class="btn-link pull-left">
        <i class="material-icons">chevron_left</i>
        {{ "features.manage_templates.components.optimailPreview.clients.BACK" | translate }}
      </button>
      <h2>{{ selectedClient?.clientName }}</h2>
      <img [src]="selectedClient?.fullUrl" />
    </div>
  </div>
</div>
