export interface EmailPreview {
	fullUrl: string;
	thumbnailUrl: string;
	clientName: string;
	clientIdentifier: string;
}

export interface ErrorResponse {
	data: string;
	isSuccess: boolean;
}

export const isErrorResponse = (b: ErrorResponse): b is ErrorResponse => {
	return b.isSuccess !== undefined && !b.isSuccess;
};
