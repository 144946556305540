import { Injectable } from '@angular/core';
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { auditDataPerBrand } from "../../../settings/settingsSubscribers/models/auditDataPerBrand";
import { IBrandDetails } from "../../../settings/settingsSubscribers/models/brandDetails.model";
import { BrandWithAudit } from "../../../settings/settingsSubscribers/models/brandWithAuditData";


@Injectable()
export class ConsentModalService {
    private baseUrl: string = '/Customer360';
    constructor(private readonly http: HttpService) {}

    public optedOutBrandListByCustomerId(customerId:number) {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetConsentAttributeValueByCustomerId`, HttpService.toHttpParams({customerId: customerId, realfieldName:`OptiMailUnsubscribed`}))
            .pipe(catchError(error => of(error))).pipe(
                map((res: DefaultAjaxResponse) => {
                    return res.Data;
                })
            );
    }

    public getUserEmail(customerId:number):Observable<string>{

        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetCustomerEmail`,HttpService.toHttpParams({customerId: customerId}))
            .pipe(catchError(error => of(error))).pipe(
                map((res: DefaultAjaxResponse) => {
                    return res.Data.Email ?? '';
                })
            );
    }
    public getBrands(): Observable<IBrandDetails[]> {
        return this.http.get<AjaxResponse<IBrandDetails[]>>(`/UserSettings/GetBrandsConsentHistoryPage`)
            .pipe(catchError(error => of(error))).pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    public getAuditAllBrands(identifier: string): Observable<BrandWithAudit[]>
    {
        const params = {
            identifier:identifier
        };
        return this.http.get<any[]>(`${this.baseUrl}/GetConsentHistoryAllBrands`, HttpService.toHttpParams(params))
            .pipe(catchError(error => of(error))).pipe(map((res) => {
                return res.Data;
            }));
    }

    public getAudit(identifier: string, brand: string , offset: number, count: number): Observable<auditDataPerBrand>
    {
        const params = {
            identifier:identifier,
            brand:brand,
            offset:offset,
            count:count
        };
        return this.http.get<any[]>(`${this.baseUrl}/GetConsentHistoryByName`, HttpService.toHttpParams(params))
            .pipe(catchError(error => of(error))).pipe(map((res) => {
            return res.Data;
        }));
    }

}
