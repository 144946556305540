<div class="opti-modal-window opti-logic-modal">
  <div class="panel panel-default">
    <div *ngIf="!modalConf.hideHeaderPanel" class="panel-heading" [ngClass]="{'no-border': modalConf.hideHeaderBorder}">
      <div *ngIf="modalConf.title" class="row">
        <div class="col-md-12 title-container">
          <i *ngIf="modalConf?.materialIconBeforeTitle" class="material-icons">
            {{ modalConf.materialIconBeforeTitle }}
          </i>
          <h2 class="none-hypens opti-logic-modal-title" data-qa-id="cy_modal-title" translate>
            {{ modalConf.title }}
          </h2>
          <div *ngIf="modalConf.subtitle" class="sub-title">
            <h2 class="seperator">|</h2>
            <h2 class="sub-title-text text-muted">{{ modalConf.subtitle }}</h2>
          </div>
        </div>
      </div>
      <div *ngIf="modalConf.description" class="row">
        <div class="col-md-12">
          <p class="small status">
            {{ modalConf.description }}
          </p>
        </div>
      </div>
      <ng-content select="[custom-header]"></ng-content>
      <button
        *ngIf="modalConf.xButton"
        (click)="modalConf.xButton.action()"
        type="button"
        [class]="modalConf.xButton?.class"
        class="close-modal btn btn-link btn-link-default">
        <span *ngIf="modalConf.xButton.label">{{ modalConf.xButton.label }}</span>
        <span *ngIf="!modalConf.xButton.label" class="material-icons" aria-hidden="true">close</span>
      </button>
    </div>

    <div
      class="panel-body"
      [ngClass]="{
        scrollable: hasScroll,
        scalable: isScalable,
        'no-padding': modalConf.noBodyPadding,
        'with-min-height': !modalConf?.withoutMinHeight
      }">
      <ng-content></ng-content>
    </div>

    <div *ngIf="!modalConf.hideFooterPanel" class="panel-footer" [ngClass]="{'no-border': modalConf.hideFooterBorder}">
      <div class="row">
        <div class="col-md-4">
          <ng-content class="custom-start-footer" select="[custom-start-footer]"></ng-content>
          <button
            *ngIf="modalConf.linkConf"
            [disabled]="!!modalConf.linkConf && !!modalConf.linkConf.isDisabled && modalConf.linkConf.isDisabled()"
            (click)="modalConf.linkConf.action()"
            class="btn btn-link"
            [ngClass]="modalConf.linkConf.class"
            [attr.data-qa-id]="modalConf.linkConf.dataQaId">
            <i *ngIf="modalConf.linkConf.materialIcon" class="material-icons" aria-hidden="true">
              {{ modalConf.linkConf.materialIcon }}
            </i>
            <span translate>{{ modalConf.linkConf.label }}</span>
          </button>
        </div>
        <div class="col-md-8">
          <ul class="list-inline action-buttons">
            <li *ngFor="let button of modalConf.buttons" class="col-md-2 opti-modal-button">
              <button
                type="button"
                class="btn"
                (click)="button.action(modalRef)"
                [disabled]="!!button.isDisabled && button.isDisabled()"
                [ngClass]="button.isLoading && button.isLoading() ? [button.class, 'is-loading'] : button.class"
                [attr.data-qa-id]="button.dataQaId">
                <ng-container *ngIf="button.isLoading && button.isLoading(); else noLoader">
                  <span class="loader"></span>
                  <span>{{ button.loadingText }}</span>
                </ng-container>
                <ng-template #noLoader>
                  {{ button.label }}
                  <span *ngIf="button.materialIcon" class="material-icons" aria-hidden="true">{{ button.materialIcon }}</span>
                </ng-template>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
