import { Component, OnInit } from "@angular/core";
import { MessageArchivingErrorLogModel } from "../../models/errorLogModel";
import { SecretService } from "../../services/secret.service";

@Component({
  selector: "error-log-list",
  templateUrl: "errorLogList.component.html",
  styleUrls: ["errorLogList.component.scss"],
})
export class ErrorLogListComponent implements OnInit {
  private readonly PageSize = 20;

  private pageNumber = 1;
  private reachedEnd = false;
  isErrorsLoading = false;
  isLoading: boolean = true;
  errorsModel: MessageArchivingErrorLogModel;

  constructor(private secretService: SecretService) {}

  ngOnInit() {
    this.loadMore();
  }

  onScroll(event: any) {
      if (event.target.offsetHeight + event.target.scrollTop + 5 >= event.target.scrollHeight) {
          this.loadMore();
      }
  }

  private loadMore(): void {
    if (this.reachedEnd || this.isErrorsLoading) return;

    this.isErrorsLoading = true;
    this.secretService.getMessageArchivingErrorLogs(this.pageNumber++, this.PageSize).subscribe((res) => {
        this.reachedEnd = res.Errors.length < this.PageSize;
        this.errorsModel = { ...res, Errors: (this.errorsModel?.Errors ?? []).concat(res.Errors)};
        this.isLoading = false;
        this.isErrorsLoading = false;
      }, () => this.isErrorsLoading = false);
  }
}
