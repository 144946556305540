import {Component, SimpleChanges, Input, OnInit, OnChanges, ViewEncapsulation } from "@angular/core";
import {FormatterPipe} from "../../pipes/formatter.pipe";

@Component({
    selector: 'opti-metric',
    templateUrl: './optiMetric.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./optiMetric.component.scss']
})
export class OptiMetricComponent implements OnChanges {

    public metricClassCalculated = '';
    public isMultiValue;
    public multiValueClasses = [];

    //title handle
    @Input() titleText: string;
    @Input() titleTooltip: string = '';
    @Input() titleNumberOfLines: number = 1;

    @Input() valueNumberOfLines: number = 2;
    @Input() metricValue;
    @Input() defaultNullUndefinedValue;
    @Input() htmlTooltip;
    @Input() metricClass = '';
    @Input() metricFormat = this.getDefaultFormat();
    @Input() metricFormatterOptions;
    @Input() isBindOnce: boolean = false;
    @Input() metricTexts;
    @Input() isMetricColored: boolean = false;
    @Input() subLine;
    @Input() metricValueSubtext = [];
    @Input() isNotSignificant;
    @Input() enforceFormatting;

    constructor(private formatter: FormatterPipe){}

    ngOnChanges(changes: SimpleChanges) {
        if(changes.metricClass && changes.metricClass.currentValue) {
            this.metricClassCalculated = this.handleMetricClasses(this.metricClass);
        }
        if(changes.metricValue && changes.metricValue.currentValue) {
            this.isMultiValue = Array.isArray(changes.metricValue.currentValue);
        }
    }

    public metricDynamicClass(index?: number) {
        let res = this.metricClassCalculated + ' ';
        if (this.isMetricColored) {
            let value = this.getNumberFormatted(this.metricValue, '0,').replace(/,/g, '');
            res += this.getDynamicClass(value);
        } else if (res.match('h[1-6]|p')) {
                return res + ' tooltip-multi-line';
        } else {
            return res;
        }
        if (this.isMultiValue) {
            res = this.getDynamicClass(this.getNumberFormatted(this.metricValue[index], '0,').replace(/,/g, ''));
        }
        return res;
    }

    public metricFormatted(i?) {
        let value = (this.isMultiValue ? this.metricValue[i] : this.metricValue);
        if (this.isDefinedAndNotNull(value)) {
            let format = (Array.isArray(this.metricFormat) ? this.metricFormat[i] : this.metricFormat);
            return this.getNumberFormatted(value, format, i);
        } else if (this.defaultNullUndefinedValue) {
            return this.defaultNullUndefinedValue;
        }

        return 'N/A';
    }

    public metricDynamicClassForSubValue(curr, isSubvalue) {
        let res = isSubvalue ? '' : this.metricClassCalculated + ' ';
        let requiredValue = curr || this.metricValue;
        if (this.isMetricColored) {
            let value = this.getNumberFormatted(requiredValue, '0,').replace(/,/g, '');
            res += this.getDynamicClass(value);
        }
        return res;
    }



    private handleMetricClasses(metricClass) {
        let headerClass = 'h1 ';
        if (!metricClass) {
            return headerClass;
        }

        if (!metricClass.match('h[1-6]|p')) {
            return metricClass + ' ' + headerClass;
        }

        return metricClass;
    }

    private isDefinedAndNotNull(val) {
        return val && val !== null;
    }

    private getNumberFormatted(value, format, index?) {
        let formatterOptions = (Array.isArray(this.metricFormatterOptions) ? this.metricFormatterOptions[index] : this.metricFormatterOptions);
        return this.valueFormatter(value, format, formatterOptions, this.enforceFormatting);
    }

    private valueFormatter(val: number, format: string, options: any, enforce: boolean): string {
        if(enforce) {
            return this.formatter.transform(val, format, options);
        }
        return '';
    }

    private getDefaultFormat() {
        let res = '0';
        if (sessionStorage.DefaultKpi) {
            res = this.fromJson(sessionStorage.DefaultKpi).format;
        }

        return res;
    }

    private getDynamicClass(value) {
        if (this.isNotSignificant) {
            return 'text-muted silver-m3';
        }
        let res : string= "";
        if (value !== '-') {
            if (value > 0) {
                res += 'text-success';
            }
            else if (value < 0) {
                res += 'text-danger';
            }
            else if (value == 0) {
                res += 'text-muted silver-m3';
            }
        }
        // if N/A so color in gray
        if (this.isDefinedAndNotNull(value) && isNaN(value)) {
            res += 'text-muted silver-m3';
        }
        return res;
    }

    private fromJson(json) {
        return this.isString(json) ? JSON.parse(json) : json;
    }

    private isString(val) {
        return typeof val === 'string';
    }
}