import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ComponentsModule } from "../../components/components.module";
import { OptiSearchListService } from "../../components/optiSearchList/optiSearchListComponent/optiSearchList.service";
import { OptiModalModule } from "../../modules/OptiModal.module";
import { PipesModule } from "../../pipes/pipes.module";
import { TemplatesService } from "./campaignTemplates.service";
import { ChannelMetricsColorsService } from "./channelMetricsColors.service";
import { AttributeToExportModal } from "./components/attributesExportBtn/attribureToExpotModal/attributeToExportModal.component";
import { AttributeExportService } from "./components/attributesExportBtn/attributeExport.service";
import { AttributesExportBtnComponent } from "./components/attributesExportBtn/attributesExportBtn.component";
import { ChannelMetricsComponent } from "./components/channelMetrics/channelMetrics.component";
import { ChannelMetricsActionComponent } from "./components/channelMetricsAction/channelMetricsAction.component";
import { ChannelMetricsActionHeaderComponent } from "./components/channelMetricsActionHeader/channelMetricsActionHeader.component";
import { ChannelMetricsBarComponent } from "./components/channelMetricsBar/channelMetricsBar.component";
import { ChannelMetricsBodyComponent } from "./components/channelMetricsBody/channelMetricsBody.component";
import { ChannelMetricsDataBarComponent } from "./components/channelMetricsDataBar/channelMetricsDataBar.component";
import { ChannelMetricsNumberComponent } from "./components/channelMetricsNumber/channelMetricsNumber.component";
import { ChannelMetricsRingsComponent } from "./components/channelMetricsRings/channelMetricsRings.component";
import { ChannelMetricsTableComponent } from "./components/channelMetricsTable/channelMetricsTable.component";
import { TemplateHTMLModalContainerComponent } from "./components/templateHTMLModalContainer/templateHTMLModalContainer.component";
import { TemplatesBreakdownComponent } from "./components/templatesBreakdown/templatesBreakdown.component";
import { TemplatesHtmlCarouselModalComponent } from "./components/templatesHtmlCarouselModal/templatesHtmlCarouselModal.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        AccordionModule.forRoot(),
        TooltipModule,
        PipesModule,
        OptiModalModule.forRoot()
    ],
    declarations: [
        ChannelMetricsComponent,
        ChannelMetricsActionComponent,
        ChannelMetricsActionHeaderComponent,
        ChannelMetricsNumberComponent,
        ChannelMetricsTableComponent,
        ChannelMetricsRingsComponent,
        ChannelMetricsDataBarComponent,
        ChannelMetricsBarComponent,
        TemplatesBreakdownComponent,
        ChannelMetricsBodyComponent,
        TemplateHTMLModalContainerComponent,
        TemplatesHtmlCarouselModalComponent,
        AttributesExportBtnComponent,
        AttributeToExportModal,
    ],
    providers: [
        ChannelMetricsColorsService,
        TemplatesService,
        AttributeExportService,
        OptiSearchListService,
    ],
    exports: [
        ChannelMetricsBodyComponent
    ]
})
export class CampaignAnalysisModule {
}
