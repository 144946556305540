<opti-logic-modal [modalConf]="modalConf">
    <form [formGroup]="form" class="notes-and-tags-form">
        <notes-and-tags-form
            class="notesAndTags"
            formControlName="notesAndTags"
            [allTags]="allTags"
            [tagsLimit]="tagsLimit"
            [streamTestName]="streamTestName"
            [curNewTags]="curNewTags"
            (onAddingNewTag)="addNewTagEvent($event)"
            >
        </notes-and-tags-form>
    </form>
</opti-logic-modal>
