<div id="main" class="horizontal-tabs-container rd-flex flex-column">
  <ul id="tabs-list" role="tablist" class="d-flex">
    <li *ngFor="let tab of tabs; let i = index"
        class="tab-title" #tabHeader
        role="tab"
        (focusin)="onFocus()"
        (focusout)="onFocusOut()"
        (click)="selectTab(i)"
        [class.active]="tab.active"
        [class.disabled]="tab.disabled"
        [attr.tabIndex]="_getTabIndex(tab)"
        [attr.aria-controls]="_getTabContentId(i)"
        [attr.aria-selected]="tab === activeTab">
      <ng-container [ngTemplateOutlet]="tab.headerTemplateRef"></ng-container>
    </li>
  </ul>

  <section role="tabpanel">
    <ng-container [ngTemplateOutlet]="activeTab.bodyTemplateRef"></ng-container>
  </section>
</div>
