<div class="preview-optimail-browser-template">
  <div class="filter">
    <form novalidate class="form-horizontal filter-form" name="previewForm" autocomplete="off" *ngIf="isOnCustomerData">
      <div>
        <div *ngIf="isMultiLanguageEnabled" class="language-select-container">
          <opti-select
            class="language-select"
            [class.language-select-closed]="!isLanguageMenuOpen"
            (onOpenChange)="isLanguageMenuOpen = !isLanguageMenuOpen"
            [disabled]="!isLanguageMenuEnabled"
            [items]="languages"
            [(ngModel)]="selectedLanguage"
            (ngModelChange)="loadSelectedLanguage()"
            [placeholder]="'features.manage_templates.components.optimailPreview.QUICK_FIND' | translate"
            name="languagesSelect"></opti-select>
          <button
            *ngIf="!isLanguageMenuOpen"
            (click)="switchPreviousLanguage()"
            class="btn-link arrow-button"
            [class.button-disabled]="!isPreviousEnabled(selectedLanguage)"
            [disabled]="!isPreviousEnabled(selectedLanguage)">
            <i class="material-icons arrow-icon">arrow_back</i>
          </button>
          <button
            *ngIf="!isLanguageMenuOpen"
            (click)="switchNextLanguage()"
            class="btn-link arrow-button next-button"
            [class.button-disabled]="!isNextEnabled(selectedLanguage)"
            [disabled]="!isNextEnabled(selectedLanguage)">
            <div class="icon-container">
              <i class="material-icons arrow-icon">arrow_back</i>
            </div>
          </button>
        </div>
        <div class="dirty-warning-container">
          <span *ngIf="isTemplateDirty && isMultiLanguageEnabled" class="dirty-warning">
            {{ "features.manage_templates.components.optimailPreview.DIRTY_TEMPLATE_WARNING" | translate }}
          </span>
        </div>
        <div class="dirty-warning-container warning-bottom">
          <span *ngIf="isICUincorrect && isMultiLanguageEnabled" class="dirty-warning">
            {{ "features.manage_templates.components.optimailPreview.ICU_INCORRECT" | translate }}
          </span>
        </div>
        <div class="filter-row">
          <div class="radio radio-primary">
            <input
              type="radio"
              [(ngModel)]="selectedLivePersonType"
              [value]="availableIDSelectionValues.RandomCustomerId"
              id="previewOptimail.filter.randomData"
              name="selectedLivePersonTypeRadios"
              (change)="customerNotExistsServerValidator = false; evaluateInCustomerLanguage()" />
            <label for="previewOptimail.filter.randomData">
              {{ "features.manage_templates.components.sendTestMail.DROPDOWN_OPTION1" | translate }}
            </label>
            <button
              (click)="evaluateInCustomerLanguage()"
              [disabled]="refreshing || selectedLivePersonType !== availableIDSelectionValues.RandomCustomerId"
              class="btn-link pull-right">
              <i class="material-icons">refresh</i>
            </button>
          </div>
        </div>
        <div class="filter-row">
          <div class="radio radio-primary">
            <input
              type="radio"
              [(ngModel)]="selectedLivePersonType"
              id="previewOptimail.filter.randomVisitorData"
              [value]="availableIDSelectionValues.RandomVisitorId"
              name="selectedLivePersonTypeRadios"
              [attr.disabled]="!shouldDisplayVisitorMenu"
              (change)="customerNotExistsServerValidator = false; evaluateInCustomerLanguage()" />
            <label for="previewOptimail.filter.randomVisitorData">
              {{ "features.manage_templates.components.sendTestMail.RANDOM_VISITOR_DATA" | translate }}
            </label>
            <button
              (click)="evaluateInCustomerLanguage()"
              [disabled]="refreshing || selectedLivePersonType !== availableIDSelectionValues.RandomVisitorId || !shouldDisplayVisitorMenu"
              class="btn-link pull-right">
              <i class="material-icons">refresh</i>
            </button>
          </div>
        </div>

        <div class="filter-row">
          <div class="radio radio-primary">
            <input
              type="radio"
              [(ngModel)]="selectedLivePersonType"
              id="previewOptimail.filter.customerId"
              [value]="availableIDSelectionValues.ExplicitId"
              name="selectedLivePersonTypeRadios"
              [disabled]="!allowCustomerId"
              (change)="customerId ? evaluateInCustomerLanguage(customerId) : {}" />
            <label for="previewOptimail.filter.customerId">
              {{
                (shouldDisplayVisitorMenu
                  ? "features.manage_templates.components.optimailPreview.browser.ID_CUSTOMER_AND_VISITOR"
                  : "features.manage_templates.components.optimailPreview.browser.ID_CUSTOMER_ONLY"
                ) | translate
              }}
            </label>
            <input
              class="form-control row-control"
              [(ngModel)]="customerId"
              type="text"
              placeholder="{{ 'features.manage_templates.components.sendTestMail.PLACEHOLDER_CUSTOMER' | translate }}"
              (change)="customerIdEdited = true"
              autocomplete="on"
              id="customerID"
              name="customerID"
              [disabled]="selectedLivePersonType !== availableIDSelectionValues.ExplicitId" />

            <div class="clearfix"></div>
            <div [hidden]="!customerNotExistsServerValidator" class="validator text-danger small inline">
              <p class="small">
                {{
                  "features.manage_templates.components.optimailPreview.browser.ERROR_CUSTOMER" | translate : {customerId: wrongCustomerId}
                }}
              </p>
            </div>
            <button
              type="submit"
              class="btn row-control btn-primary pull-right"
              (click)="evaluateInCustomerLanguage(customerId)"
              [disabled]="refreshing || customerId === null || customerId?.length < 1 || !customerIdEdited">
              {{ "features.manage_templates.components.optimailPreview.browser.SHOW" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="filter-form" name="previewForm" *ngIf="!isOnCustomerData">
      <div class="target-group-container">
        <div class="target-group-list">
          <form novalidate class="form-horizontal">
            <span [ngClass]="{'email-error': template.fromEmail === -1}">
              <opti-select
                [ngModel]="targetGroup"
                [disabled]="isTargetGroupsDisabled"
                (ngModelChange)="targetGroup = $event"
                [items]="targetGroups"
                [placeholder]="'features.manage_templates.components.optimailPreview.SELECT' | translate"
                [itemName]="'features.manage_templates.components.optimailPreview.TARGET_GROUP' | translate"
                name="targetGroupSelect"></opti-select>
            </span>
          </form>
        </div>
        <div class="grid-container">
          <smart-preview-table
            (disableTargetGroupsListEmitter)="isTargetGroupsDisabled = $event"
            (previewEvaluationEmitter)="evaluateWithParam($event)"
            [targetGroupId]="targetGroup"
            [templateJson]="templateJson"></smart-preview-table>
        </div>
      </div>
    </div>
  </div>

  <div class="content" [hidden]="customerNotExistsServerValidator || !body" *ngIf="body">
    <h3 class="subject">
      <span class="not-important">{{ "features.manage_templates.components.optimailPreview.browser.SUBJECT" | translate }}</span>
      <div [innerHTML]="subjectHtml"></div>
    </h3>
    <iframe class="body" [attr.srcDoc]="body | safe : 'html'"></iframe>
  </div>
  <div class="loader-container" *ngIf="!customerNotExistsServerValidator && !body">
    <span class="click-loader loader-wrapper">
      <span class="loader preview-loader"></span>
    </span>
  </div>
</div>
