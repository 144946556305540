import { AllCommunityModules, AllEnterpriseModules, ColDef, ColumnApi, GridApi, GridOptions, Module } from "@ag-grid-enterprise/all-modules";
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { CampaignGridService } from "./campaign-grid.service";
import { CampaignGridChannelsColumnComponent } from "./channelsColumn/campaignGridChannelsColumn.component";
import { CampaignList } from "./interfaces";
import { CampaignGridTemplateColumnComponent } from "./templateColumn/campaignGridTemplateColumn.component";
import { CampaignGridTriggerColumnComponent } from "./triggerColumn/campaignGridTriggerColumn.component";
import { CampaignGridActionColumnComponent } from "./actionColumn/campaignGridActionColumn.component";
import { CampaignGridPreviewColumnComponent } from "./previewColumn/campaignGridPreviewColumn.component";
import { Customer360CellService } from "../../services/customer360Cell.service";
import { CampaignGridPreviewColumnHoverComponent } from "./previewColumnHover/campaignGridPreviewColumnHover.component";
export interface GridItem {
	StartDate: string,
	TargetGroup: string,
	IsRecurring: boolean,
	Trigger: string,
	Action: string,
	Channels: any[],
	Priority: any,
	PromotionCode: string,
	PromotionName: string,
	ClientCustomerId: string,
}

@Component({
	selector: 'campaign-grid',
	templateUrl: './campaign-grid.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./campaign-grid.component.scss']
})
export class CampaignGridComponent implements OnInit, OnChanges {

	@Input() customerId: string;
	@Input() clientCustomerId: string;
	@Input() startDate: string;
	@Input() endDate: string;
	@Input() filter: 'all' | 'scheduled' | 'triggered';

	public data: GridItem[];
	public sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true,
					suppressSideButtons: true,
					suppressColumnFilter: true,
					suppressColumnSelectAll: true,
					suppressColumnExpandAll: true
				}
			}
		],
		defaultToolPanel: ''
	};

	public frameworkComponents: any;
	public gridOptions: GridOptions | undefined;
	public gridApi: GridApi | undefined;
	public gridColumnApi: ColumnApi | undefined;
	public modules: Module[] = [...AllCommunityModules, ...AllEnterpriseModules];

	constructor(
		private campaignGridService: CampaignGridService,
		private translateService: TranslateService,
		private customer360CellService: Customer360CellService
	) {
		this.frameworkComponents = {
			campaignGridTriggerColumnComponent: CampaignGridTriggerColumnComponent,
			campaignGridChannelsColumnComponent: CampaignGridChannelsColumnComponent,
			campaignGridTemplateColumnComponent: CampaignGridTemplateColumnComponent,
			campaignGridActionColumnComponent : CampaignGridActionColumnComponent,
			campaignGridPreviewColumnComponent: CampaignGridPreviewColumnComponent,
			campaignGridPreviewColumnHoverComponent: CampaignGridPreviewColumnHoverComponent,
		};
	}

	ngOnInit() {
		this.gridOptions = <GridOptions>{
			context: this,
			columnDefs: this.getColumnDefs(),
			defaultColDef: this.getDefaultColDef(),
			headerHeight: 30,
			
			getRowHeight: (params) => {
				return 40 + params.data.Channels.length * 30;
			},
			rowBuffer: 0,
            wrapText: true,
			resizable: true,
			debug: false,
			rowModelType: "clientSide",
			loadingCellRendererParams: {
				loadingMessage: 'Loading...',
			},
			serverSideStoreType: "partial",
			blockLoadDebounceMillis: 1000,
			cacheBlockSize: 100,
			cacheOverflowSize: 20,
			maxConcurrentDatasourceRequests: 4,
			enableCellTextSelection: true,
			suppressCopyRowsToClipboard: true,
			processCellForClipboard: this.processCellForClipboard,
			infiniteInitialRowCount: 100,
			/// INFINITE SCROLL SSRM
			suppressDragLeaveHidesColumns: true,
			suppressCellSelection: true,
			valueCache: true,
			onGridSizeChanged: this.onGridSizeChanged,
			onSelectionChanged: this.onGridSizeChanged,
			overlayNoRowsTemplate: `<div class="text-center text-muted">
                <h1>${this.translateService.instant('features.customer360.body.data_messages.no_campaigns.TITLE')}</h1>
            </div>`,
			onFilterChanged: this.handleNoResultsOverlay.bind(this),
			onGridReady: (params) => {
				this.gridApi = params.api;
				this.gridColumnApi = params.columnApi;
				this.campaignGridService.getCampaignsDetailsPerCustomerBetweenDates(this.customerId!, this.startDate!, this.endDate!)
					.subscribe(response => {
						if (response?.CampaignsDetailsPerCustomer) {
							this.data = this.mapCampaignListData(response.CampaignsDetailsPerCustomer.CampaignList || []);
							this.gridApi?.setRowData(this.data);
						}
					});
			}
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.filter && !changes.filter.firstChange) {
			const columnDefs = this.gridApi?.getColumnDefs()!;
			const triggerColumn: ColDef = columnDefs?.find((x: ColDef) => x.field === 'Trigger')!;
			triggerColumn.hide = false;
			switch (changes.filter.currentValue) {
				case 'scheduled':
					this.gridApi?.setRowData(this.data.filter(x => !x.Trigger || x.Trigger === '-'));
					triggerColumn.hide = true;
					break;
				case 'triggered':
					this.gridApi?.setRowData(this.data.filter(x => x.Trigger && x.Trigger !== '-'));
					break;
				case 'all':
					this.gridApi?.setRowData(this.data);
				default:
					break;
			}
			this.gridApi?.setColumnDefs(columnDefs);
			this.gridApi?.sizeColumnsToFit();
		}
		if ((changes?.startDate || changes?.endDate) && this.customerId) {
			this.campaignGridService.getCampaignsDetailsPerCustomerBetweenDates(this.customerId!, this.startDate!, this.endDate!)
				.subscribe(response => {
					if (response?.CampaignsDetailsPerCustomer) {
						this.data = this.mapCampaignListData(response.CampaignsDetailsPerCustomer.CampaignList || []);
						this.gridApi?.setRowData(this.data);
						this.gridApi?.refreshHeader();
						this.gridApi?.refreshCells();
					}
				});
		}
	}

	processCellForClipboard = (params: any): string => {
		return this.customer360CellService.process(params);
	}

	handleNoResultsOverlay() {
		if (this.gridApi?.getDisplayedRowCount() === 0) {
			this.gridApi.showNoRowsOverlay();
		} else {
			this.gridApi?.hideOverlay();
		}
	}

	private mapCampaignListData(list: CampaignList[]): GridItem[] {
		if (list && list.length) {
			return list.map(item => {
				if (item.Channels.length > 1) {
					item.Priority = 'multi';
				}
				return {
					StartDate: moment(item.CampaignDate).format('YYYY-MM-DD'),
					TargetGroup: item.TargetGroupName,
					IsRecurring: item.IsRecurring,
					Trigger: item.Trigger,
					Action: item.Action,
					Channels: item.Channels,
					Priority: item.Priority,
					PromotionCode: item.PromotionCode,
					PromotionName: item.PromotionName,
					ClientCustomerId: this.clientCustomerId,
				};
			});
		}
		return [];
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.START_DATE'),
				field: 'StartDate',
				initialWidth: 125,
				minWidth: 100,
				sortable: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.TARGET_GROUP'),
				field: 'TargetGroup',
				minWidth: 100,
				sortable: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.TRIGGER'),
				headerComponent: 'campaignGridTriggerColumnComponent',
				field: 'Trigger',
				filter: true,
				minWidth: 100,
				sortable: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.ACTION'),
				cellRenderer: 'campaignGridActionColumnComponent',
				field: 'Action',
				filter: true,
				minWidth: 100,
				sortable: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.CHANNELS'),
				cellRenderer: 'campaignGridChannelsColumnComponent',
				cellRendererParams: {
					propName: "Name"
				},
				field: 'Channels',
				sortable: true,
				minWidth: 100,
				initialWidth: 130
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.PROMOTION_CODE'),
				field: 'PromotionCode',
				sortable: true,
				minWidth: 100,
				initialHide: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.PROMOTION_NAME'),
				field: 'PromotionName',
				sortable: true
			},
			{
				headerName: this.translateService.instant('features.customer360.body.campaign_list.COLUMNS_TITLES.TEMPLATES'),
				cellRenderer: 'campaignGridTemplateColumnComponent',
				cellRendererParams: {
					propName: "TemplateName"
				},
				field: 'Channels',
				minWidth: 100,
				sortable: true
			},
			{
				headerName: '',
				cellRenderer: 'campaignGridPreviewColumnComponent',
				field: 'Channels',
				sortable: false,
				resizable: false,
				suppressMovable: true,
				width: 80
			}
		];
	}

	private getDefaultColDef(): ColDef {
		return {
			sortable: true,
			resizable: true,
            wrapText: true,
			lockPinned: false
		};
	}

	private onGridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
	}
}
