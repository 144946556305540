<div class="customers-list">
    <div class="panel-heading">
        <div class="upper-title">
            <h2 data-qa-id="showing-customers-title">
                <span *ngIf="customers.length > 0">{{ translateKeys.title | translate: {count: customers.length} }}</span>
                <span *ngIf="customers.length === 1">{{ translateKeys.titleSingleResult | translate }}</span>
                <span *ngIf="customers.length === 0">{{ translateKeys.titleNoResults | translate }}</span>
            </h2>
        </div>
        <div class="sub-menu">
            <div class="left-items">
                <div class="radio-button-group">
<!--                    <span>{{ translateKeys.searchTermIn | translate }}</span>-->
                    <span>Search term in:</span>
                    <div class="radio radio-primary radio-exclusion exclude-radio-container">
                        <input class="radio" type="radio" [formControl]="searchByControl" value="">
                        <label class="radio-inline">All</label>
                    </div>
                    <div *ngFor="let searchProp of propsToSearch index as i;"
                         class="radio radio-primary radio-exclusion exclude-radio-container">
                        <input class="radio" type="radio"
                            [formControl]="searchByControl"
                            [value]="searchProp.key"
                            [attr.disabled]="searchProp.disabled === false ? null : true"
                            data-qa-id="search-radio">
                        <label class="radio-inline" [ngClass]="{'disabled': searchProp.disabled}">
                            {{searchProp.value}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="right-items" *ngIf="orderItems.length > 0">
                <span class="mt-12">Sort results by</span>
                <opti-search-list
                        [(ngModel)]="orderBy"
                        class="order-by-control"
                        data-qa-id="sort-dropdown"
                        [items]="orderItems"
                        [config]="orderConfig"
                        (selectedItemsChange)="onOrderChanged($event)">
                </opti-search-list>
            </div>
        </div>
    </div>
    <div class="panel-body" style="height: 100%;">
        <opti-loading [show]="loading"></opti-loading>
        <div *ngIf="customers.length === 0" class="mt-60 text-center text-muted">
            <h1 translate>
                {{ translateKeys.noCustomersFoundTitle }}
            </h1>
            <h3 translate>
                {{ translateKeys.noCustomersFoundDescription }}
            </h3>
        </div>
        <ag-grid-angular *ngIf="customers.length > 0 && !initialized"
                         style="width: 100%; height: 100%"
                         [gridOptions]="gridOptions"
                         [modules]="modules"
                         (sortChanged)="onSortChanged($event)"
                         class="ag-theme-alpine"
                         data-qa-id="customer-table">
        </ag-grid-angular>
    </div>
</div>
