import { Component, Input, OnInit } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { FolderService } from '../../../services/folder.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Brand, SubMethod } from '../../../models/metadataResponse';
import { TemplateContextService } from '../../../services/templateContext.service';
import { SearchContextService } from '../../../services/searchContext.service';
import { combineLatest, forkJoin } from 'rxjs';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { SearchListConfig } from '../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { OslValueType } from '../../../../../components/optiSearchList/models/oslValueType.enum';
import { SubMethodService } from '../../../services/subMethodService';
import { TemplateTreeItem } from '../../../models/templateResponse';
import { Dictionary } from 'highcharts';
import { first, map } from 'rxjs/operators';
import { BrandService } from '../../../services/brand.service';
import { RelationType } from '../../../models/templateResponse';
import { ErrorModalService } from '../../../services/errorModal.service';
import { FolderTemplatePreview } from '../../../models/folderTemplate';
import { TemplatesBatchMove } from '../../../models/templateMove';

@Component({
	selector: 'copy-to-modal',
	templateUrl: 'copyToTemplateModal.component.html',
	styleUrls: ['copyToTemplateModal.component.scss']
})
export class CopyToTemplateModalComponent implements OnInit {
	modalConf: IModalConf;
	currentBrandId: string;
	public isCopyingTemplate: boolean = false;

	template: FolderTemplatePreview;
	@Input() callback;
	@Input() templatesBatch: FolderTemplatePreview[];

	public brands: Brand[] = [];
	public selectedBrandId: string;

	public subMethodsOfSelectedBrand: SubMethodModel[] = [];
	public subMethodsPerBrand: Dictionary<SubMethod[]> = {};
	public selectedSubMethodId: number;

	public foldersOfSelectedSubMethod: TemplateTreeItem[] = [];
	public foldersPerSubMethod: Dictionary<TemplateTreeItem[]> = {};
	public selectedFolderId: number;
	public channelId: number;
	public submethodId: number;
	public folderId: number;
	public isCopyToEnabled = true;
	constructor(
		private manageTemplatesService: ManageTemplatesService,
		private folderService: FolderService,
		private translateService: TranslateService,
		private bsModalRef: BsModalRef,
		private templateContextService: TemplateContextService,
		private searchContextService: SearchContextService,
		private subMethodService: SubMethodService,
		private brandService: BrandService,
		private errorModalService: ErrorModalService
	) {}

	ngOnInit() {
		this.channelId = this.templatesBatch ? +this.templatesBatch[0].channelId : this.template.channelId;
		this.submethodId = this.templatesBatch ? +this.templatesBatch[0].subMethodId : this.template.subMethodId;
		this.folderId = this.templatesBatch ? +this.templatesBatch[0].folderId : this.template.folderId;
		this.brandService.updateBrandsByChannelId(this.channelId).subscribe();
		this.templateContextService.queryTemplateParams.pipe(first()).subscribe((params) => (this.currentBrandId = params.brandId));
		this.createModal();
		this.initDropdownsData();
	}

	public brandsListConfig: SearchListConfig = {
		keyProperty: 'name',
		valueProperty: 'name',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	public subMethodsListConfig: SearchListConfig = {
		keyProperty: 'subMethodId',
		valueProperty: 'name',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	public foldersListConfig: SearchListConfig = {
		keyProperty: 'id',
		valueProperty: 'text',
		isMultiSelect: false,
		valueType: OslValueType.Key,
		itemNameTranslateKey: 'Items'
	};

	private initDropdownsData(): void {
		const brands = this.brandService.brandsByChannelId;
		const brand = this.searchContextService.brand;
		const queryTemplateParams = this.templateContextService.queryTemplateParams;

		combineLatest([brands, brand, queryTemplateParams]).subscribe(([brands, brand, params]) => {
			this.brands = brands;
			this.selectedBrandId = brand.name;

			this.subMethodsPerBrand = this.brands.reduce((acc, current) => {
				acc[current.name] = current.subMethods;
				return acc;
			}, {});
			if (!this.subMethodsPerBrand[this.selectedBrandId]) {
				return;
			}
			this.subMethodsOfSelectedBrand = this.subMethodsPerBrand[this.selectedBrandId].map(
				(sub) =>
					({
						name: this.translateService.instant(this.subMethodService.getSubMethodTypeText(sub.subMethodType, this.channelId)),
						subMethodId: sub.subMethodId,
						subMethodType: sub.subMethodType,
						executionMethodInnerID: sub.executionMethodInnerID
					} as SubMethodModel)
			);
			const selectedSubMethod =
				this.subMethodsOfSelectedBrand.find((item) => item.subMethodId == +params.typeId) ?? this.subMethodsOfSelectedBrand[0];
			this.selectedSubMethodId = selectedSubMethod.subMethodId;

			this.foldersPerSubMethod = this.subMethodsOfSelectedBrand.reduce((accumulator, current) => {
				const root = this.folderService.getRoot(current.subMethodId);
				const result = this.folderService.flatten([root]);
				accumulator[current.subMethodId] = result;
				return accumulator;
			}, {});

			this.foldersOfSelectedSubMethod = this.foldersPerSubMethod[this.selectedSubMethodId];
			const selectedFolder = this.foldersOfSelectedSubMethod.find((folder) => folder.id == +params.folderId);
			this.selectedFolderId = selectedFolder.id;
		});
	}

	public onBrandChange(value: string): void {
		this.isCopyToEnabled = false;
		this.selectedBrandId = value;
		this.refreshSubMethods(value);
	}

	public refreshSubMethods(newBrandId: string): void {
		this.subMethodsOfSelectedBrand = this.subMethodsPerBrand[newBrandId].map(
			(sub) =>
				({
					name: this.translateService.instant(this.subMethodService.getSubMethodTypeText(sub.subMethodType, this.channelId)),
					subMethodId: sub.subMethodId,
					subMethodType: sub.subMethodType,
					executionMethodInnerID: sub.executionMethodInnerID
				} as SubMethodModel)
		);

		newBrandId === this.currentBrandId
			? (this.selectedSubMethodId = this.submethodId)
			: (this.selectedSubMethodId = this.subMethodsOfSelectedBrand[0].subMethodId);

		this.loadNewFolders(this.subMethodsOfSelectedBrand);
	}

	public onSubMethodChange(value: number): void {
		if (value === null) return;

		if (value === this.submethodId) {
			this.selectedSubMethodId = this.submethodId;
			this.foldersOfSelectedSubMethod = this.foldersPerSubMethod[this.selectedSubMethodId];
			this.selectedFolderId = this.folderId;
			return;
		}

		this.selectedSubMethodId = value;
		this.foldersOfSelectedSubMethod = this.foldersPerSubMethod[this.selectedSubMethodId];
		this.selectedFolderId = this.foldersOfSelectedSubMethod.find((folder) => folder.isRootFolder === true).id;
	}

	public onFolderChange(value: number) {
		if (value === null) return;

		value === this.folderId ? (this.selectedFolderId = this.folderId) : (this.selectedFolderId = value);
	}

	private loadNewFolders(subMethods: SubMethodModel[]) {
		forkJoin(
			subMethods.map((subMethod) =>
				this.manageTemplatesService
					.getTemplates({
						channelId: this.channelId,
						subMethodId: subMethod.subMethodId
					})
					.pipe(
						map((templateTreeItems) => {
							return {
								templateTreeItems: templateTreeItems,
								subMethod: subMethod
							};
						})
					)
			)
		).subscribe((results) => {
			this.foldersPerSubMethod = {};

			results.forEach((result) => {
				this.foldersPerSubMethod[result.subMethod.subMethodId] = [];
				if (result.templateTreeItems.length > 0) {
					result.templateTreeItems[0].text = this.translateService.instant(
						this.subMethodService.getSubMethodTypeText(result.subMethod.subMethodType, this.channelId)
					);
				}
				result.templateTreeItems.forEach((item) => {
					this.foldersPerSubMethod[result.subMethod.subMethodId].push(...this.folderService.flatten([item]));
					this.foldersPerSubMethod[result.subMethod.subMethodId].forEach(i => i.text = this.decodeHTML(i.text));
				});
			});

			this.foldersOfSelectedSubMethod = this.foldersPerSubMethod[this.selectedSubMethodId];

			if (this.selectedBrandId === this.currentBrandId) {
				this.selectedFolderId = this.folderId;
			} else {
				this.selectedFolderId = this.foldersOfSelectedSubMethod.length > 0 ? this.foldersOfSelectedSubMethod[0].id : null;
			}
			if(this.selectedFolderId)
				this.isCopyToEnabled = true;
		});
	}

	private copyTemplate(): void {
		this.isCopyingTemplate = true;
		const shouldFromNameBeCopied = this.template.subMethodId === this.selectedSubMethodId;
		this.manageTemplatesService
			.copyTemplate({
				templateId: this.template.templateID,
				channelId: this.template.channelId,
				folderId: this.selectedFolderId,
				newSubMethodId: shouldFromNameBeCopied ? null : this.selectedSubMethodId
			})
			.subscribe((res) => {
				this.bsModalRef.hide();
				if (!res) {
					this.errorModalService.openErrorModal(
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.COPY_TEMPLATES'),
						this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_COPY_TEMPLATE')
					);
					return;
				}
				this.isCopyingTemplate = false;
				this.folderService.addTemplateToCache(this.selectedFolderId, this.selectedSubMethodId, {
					id: res.templateId,
					parentId: this.selectedFolderId,
					rel: RelationType.leaf,
					text: res.templateName,
					displayText: res.templateName,
					isRootFolder: false,
					subMethodId: this.selectedSubMethodId,
					children: [],
					isValid: res.isValid
				});
				this.callback();
			});
	}
	private copyTemplatesBatch(): void {
		this.isCopyingTemplate = true;
		let errorsDuringCopy = false;
		const promises = this.templatesBatch.map((t) => {
			const shouldFromNameBeCopied = t.subMethodId === this.selectedSubMethodId;
			 return this.manageTemplatesService
				.copyTemplate({
					templateId: t.templateID,
					channelId: t.channelId,
					folderId: this.selectedFolderId,
					newSubMethodId: shouldFromNameBeCopied ? null : this.selectedSubMethodId
				})
				.toPromise();
		}
		);

		Promise.all(promises).then((results) => {
			results.forEach((res) => {
				if (!res) {
					errorsDuringCopy = true;
					return;
				}
				this.folderService.addTemplateToCache(this.selectedFolderId, this.selectedSubMethodId, {
					id: res.templateId,
					parentId: this.selectedFolderId,
					rel: RelationType.leaf,
					text: res.templateName,
					displayText: res.templateName,
					isRootFolder: false,
					subMethodId: this.selectedSubMethodId,
					children: [],
					isValid: res.isValid
				});
			});
			if (errorsDuringCopy) {
				this.errorModalService.openErrorModal(
					this.translateService.instant('features.manage_templates.optimail.templateFolderActions.COPY_TEMPLATES'),
					this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_COPY_TEMPLATE')
				);
			}
			this.bsModalRef.hide();
			this.isCopyingTemplate = false;
			this.onTemplatesBatchCopied();
		});
	}
	onTemplatesBatchCopied(): void {
		const templatesBatchToMove: TemplatesBatchMove = {
			templates: this.templatesBatch,
			destinationFolderId: this.selectedFolderId,
			destinationSubMethodId: this.selectedSubMethodId
		};
		this.folderService.templatesBatchCopiedEvent(templatesBatchToMove);
	}
	public isCopyDisabled(): boolean {
		return (
			(this.selectedBrandId === this.currentBrandId &&
			this.selectedSubMethodId === this.submethodId &&
			this.selectedFolderId === this.folderId &&
			!!this.selectedFolderId) ||
			!this.isCopyToEnabled
		);
	}

	public createModal(): void {
		const title =
			this.templatesBatch && this.templatesBatch.length > 1
				? 'features.manage_templates.components.copy_template_modal.TITLE_PLURAL'
				: 'features.manage_templates.components.copy_template_modal.TITLE';
		this.modalConf = {
			title: this.translateService.instant(title),
			description: this.translateService.instant('features.manage_templates.components.copy_template_modal.SUBTITLE'),
			buttons: [
				{
					isDisabled: () => this.isCopyDisabled(),
					loadingText: null,
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.components.copy_template_modal.OK_BTN'),
					action: () => (this.templatesBatch ? this.copyTemplatesBatch() : this.copyTemplate())
				},
				{
					isDisabled: () => false,
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.move_template_modal.CANCEL_BTN'),
					action: () => this.bsModalRef.hide()
				}
			],
			xButton: {
				action: () => this.bsModalRef.hide()
			},
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}

	private decodeHTML(text) {
		const div = document.createElement('div');
		div.innerHTML = text;
		return div.innerText;
	}
}
interface SubMethodModel extends SubMethod {
	name: string;
}
