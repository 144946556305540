// @DEPRECATED

import { SearchItem } from './../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { AttributesManagerService } from './../../services/attributesManager.service';
import { ConditionalValidatorService } from './../../services/conditionalValidator.service';
import { Subscription } from 'rxjs';
import { IAttributeEditor } from '../../interfaces/editor.interface';
import { AfterViewInit, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, first, map } from 'rxjs/operators';
import { BaseAttribute, Condition, ConditionalAttribute, ConditionConfig, MultyExpressionsConditionalAttribute } from '../../attributesList.model';
import { SsmService } from '../../../../../services/ssm.service';
import { ConditionalAttributeService } from '../../services/condition.service';
import { ConditionalAttributesMaxLength as maxLength } from '../../attributesList.constants';

@Component({
  selector: 'conditions-editor',
  templateUrl: './conditionalEditor.component.html',
  styleUrls: ['./conditionalEditor.component.scss']
})
export class ConditionalEditorComponent implements OnInit, OnDestroy, AfterViewInit, IAttributeEditor {
  @Input() config: ConditionConfig;
  @Output() changeEmitter: EventEmitter<boolean> = new EventEmitter();
  
  public translateKeys = translateKeys;
  public form: FormGroup;
  public attributesSearchItems: SearchItem[];
  public isAddButtonDisabled = false;
  public isComplexSelection = false;
  public isValuesChanged = false;
  private maxConditionAmount = 10;

  constructor(private attrManagerService: AttributesManagerService,
    private validationService: ConditionalValidatorService,
    private conditionService: ConditionalAttributeService) { }

  ngOnInit(): void {
    this.attributesSearchItems = this.conditionService.getAttributesSearchItems();
    this.config.complexExpression = this.conditionService.expressionToDisplayFormat(this.config.complexExpression);
    this.createForm();
    if(!this.config.isAddMode) {
      this.setValues();
    }
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
  }

  ngAfterViewInit(): void {
    if(this.config.isDisabled) {
      this.conditions.disable();
      this.expectedValue.disable();
      this.unexpectedValue.disable();
      this.complexSelection.disable();
    }
    
    if (!this.config.showComplexExpression) {
      this.isComplexSelection = false;
      this.onCheckBoxChange(true);
    }
  }

  ngOnDestroy() {
  }

  public buildAttribute(): MultyExpressionsConditionalAttribute {
    let attribute = {
      ComplexExpression: this.isComplexSelection ? 
        this.conditionService.formatComplexExpression(this.complexSelection.value, this.maxConditionAmount) : '',
      Conditions: this.conditions.value.map(form => this.conditionService.buildCondition(form)),
      Values: [ this.expectedValue.value, this.unexpectedValue.value ] 
    };
    return this.conditionService.ConditionalAttributeToMultyExpressions(attribute as ConditionalAttribute);
  }

  public onDrop(event: CdkDragDrop<string[]>) {
    let movedItem = this.conditions.controls[event.previousIndex];

    let resArray = this.conditions.controls.slice(0, event.previousIndex)
      .concat(this.conditions.controls.slice(event.previousIndex + 1));
    
    this.conditions.controls = resArray.slice(0, event.currentIndex)
      .concat(movedItem, resArray.slice(event.currentIndex));

    this.emitChanges();
  }

  public onAddCondition() {
    let newCondition = new FormControl(this.conditionService.createConditionForm(this.conditions.length + 1));
    this.conditions.push(newCondition);
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
    this.complexSelection.updateValueAndValidity();
    this.emitChanges();
  }

  public onConditionChange(form: FormGroup, index: number){
    this.conditions.controls[index].setValue(form);
    this.conditions.controls[index].setErrors(form.valid ? null : {'incorrect': true}, {emitEvent: false})
    this.complexSelection.updateValueAndValidity();
    this.emitChanges();
  }

  public onRemoveCondition(position: number) {
    this.conditions.removeAt(position - 1);
    this.conditions.controls = this.conditions.controls.map((x, i) => {
      x.value.controls['position'].setValue(i + 1);
      return x
    });
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
    if(this.conditions.length == 1) {
      this.isComplexSelection = false;
      this.onCheckBoxChange();
    }
    this.complexSelection.updateValueAndValidity();
    this.emitChanges();
  }

  public onCheckBoxChange(skipEmit: boolean = false) {
    this.complexSelection.setValidators(this.isComplexSelection 
      ? [this.validationService.validate(() => this.conditions.length)]
      : []);

    this.complexSelection.updateValueAndValidity();
    if(!skipEmit) this.emitChanges();
  }

  public onComplesSelectionChange() {
    this.emitChanges();
  }

  public onValueChange() {
    if(this.expectedValue.value === this.unexpectedValue.value) {
      this.expectedValue.setErrors({"incorrect": true});
      this.unexpectedValue.setErrors({"incorrect": true});
    }
    else if(this.expectedValue.value !== this.unexpectedValue.value) {
      this.expectedValue.setErrors(this.expectedValue.value && this.expectedValue.value.trim() ? null : {'required': true});
      this.unexpectedValue.setErrors(this.unexpectedValue.value && this.unexpectedValue.value.trim() ? null : {'required': true});
    }
    this.emitChanges();
  }

// ================================ \\
// =====| FormControl region |===== \\

  get conditions(): FormArray {
    return this.form.get('conditions') as FormArray;
  }

  get complexSelection(): FormControl {
    return this.form.get('complexSelection')as FormControl;
  }

  get expectedValue(): FormControl {
    return this.form.get('expectedValue')as FormControl;
  }

  get unexpectedValue(): FormControl {
    return this.form.get('unexpectedValue')as FormControl;
  }

// ================================ \\
// =======| private region |======= \\

  private createForm() {
    const conditions: FormControl[] = this.config.conditions.length > 0
      ? this.config.conditions.map(c => new FormControl(this.conditionService.conditionToForm(c))) 
      : [new FormControl(this.conditionService.createConditionForm(1))];

    this.form = new FormGroup({
      conditions: new FormArray(conditions),
      complexSelection: new FormControl(''),
      expectedValue: new FormControl('', [Validators.required, Validators.maxLength(maxLength)]),
      unexpectedValue: new FormControl('', [Validators.required, Validators.maxLength(maxLength)]),
    });
  }

  private setValues() {
    this.expectedValue.setValue(this.config.values[0]);
    this.unexpectedValue.setValue(this.config.values[1]);
    if(this.config.complexExpression && this.config.showComplexExpression) {
      this.isComplexSelection = true;
      this.onCheckBoxChange(true);
      this.complexSelection.setValue(this.config.complexExpression);
    }
  }

  private emitChanges() {
    this.changeEmitter.emit(true);
    this.attrManagerService.isAttributeEditorValid.next(this.config.isDisabled ? true : this.form.valid);
  }
}

const translateKeys = {
  value: 'features.user_settings.body.attributes.addConditionAttribute.VALUE',
  optional: 'features.user_settings.body.attributes.addConditionAttribute.OPTIONAL',
  addCondition: 'features.user_settings.body.attributes.addConditionAttribute.ADD_CONDITION',
  complexSelection: 'features.user_settings.body.attributes.addConditionAttribute.COMPLEX_SELECTION',
  customersWhoMeetConditions: 'features.user_settings.body.attributes.addConditionAttribute.CUSTOMERS_WHO_MEET_CONDITIONS',
  customersWhoDontMeetConditions: 'features.user_settings.body.attributes.addConditionAttribute.CUSTOMERS_WHO_DONT_MEET_CONDITIONS',
  complexSelectionErrMsg: 'components.complexExpression.EXPRESSION_ERROR_MSG',
};