import {Directive, ElementRef, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({
    selector: '[only-digits]'
})
export class OnlyDigitsDirective{

    @Output() ngModelChange: EventEmitter<number> = new EventEmitter(false);

    private regexStr: string = '^[0-9]*$';

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        console.log(event);
        if (["Delete", "Backspace", "Tab", "Escape", "Enter"].indexOf(event.code) !== -1 ||
            // Allow: Ctrl+A
            (event.code == "KeyA" && event.ctrlKey === true) ||
            // Allow: Ctrl+C
            (event.code == "KeyC" && event.ctrlKey === true) ||
            // Allow: Ctrl+V
            (event.code == "KeyV" && event.ctrlKey === true) ||
            // Allow: Ctrl+X
            (event.code == "KeyX" && event.ctrlKey === true) ||
            // Allow: home, end, left, right
            (event.code == "Home" || event.code == "End" || event.code == "ArrowLeft" || event.code == "ArrowRight")) {
            // let it happen, don't do anything
            return;
        }
        let regEx =  new RegExp(this.regexStr);
        if(regEx.test(event.key))
            return;
        else
            event.preventDefault();
    }

    @HostListener('change')
    onValueChanged() {
        let value = this.el.nativeElement.value;
        if(value){
            value = value.replace(/\D/g, '');
            value = Number(value);
        }
        this.ngModelChange.emit(value);
    }
}