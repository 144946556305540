import { Component, Input, OnInit , ViewEncapsulation } from "@angular/core";
import { SearchListFooterConfig} from "../optiSearchListComponent/optiSearchList.component";

@Component({
    selector: 'osl-footer',
    templateUrl: './oslFooter.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslFooter.component.scss']
})

export class OslFooterComponent implements OnInit {
    @Input() config: SearchListFooterConfig;
    @Input() disabled: boolean;

    ngOnInit(): void {
    }
}