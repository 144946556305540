import {Injectable} from "@angular/core";

@Injectable()

export class OptitrackConfigurationService {
    constructor() {
    }

    public getOptitrackConfiguration(): any {
        return {
            manageStreams: {
                listGTM: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Manage Streams',
                        optitrackSdkEvent: 'CHANGED_DROPDOWN_VALUE',
                        optitrackSubcategory: {subCategory: 'communication streams list changed'}
                    }]
                },
                createNewTestGTM: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Manage Streams',
                        optitrackSdkEvent: 'CLICKED_LINK',
                        optitrackSubcategory: {
                            metric_type: 'Create Stream',
                            link_to: 'Stream Builder'
                        }
                    }]
                },
                dateRangePicker: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Manage Streams',
                        optitrackSdkEvent: 'DATE_RANGE_CHANGED',
                        optitrackSubcategory: {subCategory: 'date picker changed'}
                    }]
                },
                goToMarketingPlanGTM: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Manage Streams',
                        optitrackSdkEvent: 'CLICKED_LINK',
                        optitrackSubcategory: {
                            link_to: 'Marketing Plan',
                            metric_type: 'View in marketing plan'
                        }
                    }]
                },
                goToAnalysisGTM: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Manage Streams',
                        optitrackSdkEvent: 'CLICKED_LINK',
                        optitrackSubcategory: {
                            link_to: 'Stream Analysis',
                            metric_type: 'Analysis'
                        }
                    }]
                }
            },
            streamsBuilder: {
                nonMigrationRadioButtonGTM: {
                    event: 'click',
                    config: [{
                        optitrackSdkEvent: 'CLICKED_BUTTON',
                        optitrackCategory: 'Streams Builder',
                        optitrackSubcategory: {
                            Subcategory: 'non migration objective radio button selected',
                            button_name: 'Non Migration Objective Selected'
                        }
                    }]
                },
                migrationCellGTM: {
                    event: 'click',
                    config: [{
                        optitrackSdkEvent: 'CLICKED_BUTTON',
                        optitrackCategory: 'Streams Builder',
                        optitrackSubcategory: {
                            Subcategory: 'migration cell chosen',
                            button_name: 'Migration Cell'
                        }
                    }]
                },
                setManuallyGTM: {
                    event: 'click',
                    config: [{
                        optitrackSdkEvent: 'CLICKED_BUTTON',
                        optitrackCategory: 'Streams Builder',
                        optitrackSubcategory: {
                            Subcategory: 'set manually clicked',
                            button_name: 'Set Manually'
                        }
                    }]
                }
            },
            streamsAnalysis: {
                filterChangedGTM: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Streams Analysis',
                        optitrackSdkEvent: 'TOP_FILTER_CHANGED',
                        optitrackSubcategory: {subCategory: 'streams analysis filter changed'}
                    }]
                },
                dateRangePickerGTM: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Streams Analysis',
                        optitrackSdkEvent: 'DATE_RANGE_CHANGED',
                        optitrackSubcategory: {subCategory: 'date picker changed'}
                    }]
                },
                toggleSwitchGTM: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Streams Analysis',
                        optitrackSdkEvent: 'SWITCH_VIEW',
                        optitrackSubcategory: {subCategory: 'date picker changed'}
                    }]
                },
                viewMetricTrendGTM: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Streams Analysis',
                        optitrackSdkEvent: 'SECTION_EXPANDED',
                        optitrackSubcategory: {
                            subCategory: 'view metric trend',
                            section: 'View Trend'
                        }
                    }]
                },
                exportToCSVGTM: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Streams Analysis',
                        optitrackSdkEvent: 'EXPORT_TO_CSV',
                        optitrackSubcategory: {
                            subCategory: 'export to csv'
                        }
                    }]
                }
            },
            optibot: {
                implementActionOptibot: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Optibot',
                        optitrackSdkEvent: 'clicked_on_action',
                        optitrackSubcategory: {
                            subCategory: 'Implement Action'
                        }
                    }]
                },
                starredCardClicked: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Optibot',
                        optitrackSdkEvent: 'starred_card',
                        optitrackSubcategory: {
                            subCategory: 'Starred Card Clicked'
                        }
                    }]
                },
                switchToArchiveOptibot: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Optibot',
                        optitrackSdkEvent: 'switch_to_archive',
                        optitrackSubcategory: {
                            subCategory: 'Switch to Archive view'
                        }
                    }]
                },
                clickedOptibotLink: {
                    event: 'click',
                    config: [
                        {
                            optitrackCategory: 'Optibot',
                            optitrackSubcategory: {
                                subcategory: 'Open Optibot Link'
                            },
                            optitrackSdkEvent: 'clicked_link_optibot'
                        }
                    ]
                },
            },
            customerExplorer: {
                tgPanelExpanded: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'section_expanded',
                        optitrackSubcategory: {
                            subCategory: 'TG Panel was expanded',
                            section: 'TG Panel'
                        }
                    }]
                },
                top100Expanded: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'section_expanded',
                        optitrackSubcategory: {
                            subCategory: 'Top 100 customers was expanded',
                            section: 'Top 100 Customers'
                        }
                    }]
                },
                addNewTG: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'create_group',
                        optitrackSubcategory: {
                            subCategory: 'Click on Add new group from dropdown'
                        }
                    }]
                },
                selectGroup: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'load_group',
                        optitrackSubcategory: {
                            subCategory: 'Select group from dropdown'
                        }
                    }]
                },
                changeSnapshotDate: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'changed_dropdown_value',
                        optitrackSubcategory: {
                            subCategory: 'Snapshotdate changed'
                        }
                    }]
                },
                attributesSidebar: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'opened_customer_attributes',
                        optitrackSubcategory: {
                            subCategory: 'Select attributes sidebar'
                        }
                    }]
                },
                changeSortBy: {
                    event: 'change',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'changed_sort_by',
                        optitrackSubcategory: {
                            subCategory: 'Change top 100 sorting'
                        }
                    }]
                },
                exportToCSV: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'export_to_csv',
                        optitrackSubcategory: {
                            subCategory: 'Export to CSV'
                        }
                    }]
                },
                clickOnTab: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSubcategory: {
                            subCategory: 'Click on tab'
                        }
                    }]
                },
                clickOnGenerate: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'clicked_button',
                        optitrackSubcategory: {
                            subCategory: 'Generate'
                        }
                    }]
                },
                clickOnCohortGenerate: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'clicked_button',
                        optitrackSubcategory: {
                            subCategory: 'Generate'
                        }
                    }]
                },
                showMetrics: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'clicked_button',
                        optitrackSubcategory: {
                            subCategory: 'Key Characteristics',
                            button_name: 'Show Attribute Metrics'
                        }
                    }]
                },
                showBreakdown: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Customer Explorer',
                        optitrackSdkEvent: 'clicked_button',
                        optitrackSubcategory: {
                            subCategory: 'Key Characteristics',
                            button_name: 'Show Attribute Breakdown'
                        }
                    }]
                }
            },
            navbarMenu:{
                navbarMoreMenu: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Navigation',
                        optitrackSdkEvent: 'OPENED_MENU',
                        optitrackSubcategory: {
                            subCategory: 'Navigation',
                            button_name: 'Open side menu'
                        }
                    }]
                },
                navbarOpenGlossary: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Navigation',
                        optitrackSdkEvent: 'OPENED_CUSTOMER_ATTRIBUTES',
                        optitrackSubcategory: {
                            subCategory: 'Navigation',
                            button_name: 'Glossary'
                        }
                    }]
                },
                navbarOpenAbout: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Navigation',
                        optitrackSdkEvent: 'OPENED_ABOUT',
                        optitrackSubcategory: {
                            subCategory: 'Navigation',
                            button_name: 'Open About'
                        }
                    }]
                },
                navigationLogoutClicked: {
                    event: 'click',
                    config: [{
                        optitrackCategory: 'Navigation',
                        optitrackSdkEvent: 'LOGOUT',
                        optitrackSubcategory: {
                            subCategory: 'Navigation',
                            button_name: 'More'
                        }
                    }]
                }
            }
        }
    }
}