import { NgBootstrapper } from './app/routing/ngBootstrapper.service';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { enableProdMode } from "@angular/core";
import { LicenseManager } from "@ag-grid-enterprise/all-modules";

import "./polyfills"

if (process.env.ENV === 'prod') {
    enableProdMode();
}
// set ag-grid license
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-046609 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Mobius Solutions LTD )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 August 2024 )____[v2]_MTcyMzc2MjgwMDAwMA==1ec670a887a406287267c5bb312a60e1");


platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    const ngBootstrapper = new NgBootstrapper();
    ngBootstrapper.bootstrap(upgrade);

    const router = platformRef.injector.get(Router);
    // setTimeout is necessary because upgrade.bootstrap is async.
    setTimeout(() => {
        router.initialNavigation();
    }, 0);
}).catch(err => console.log(err));