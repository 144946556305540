<div>
  <div class="panel-heading">
    <h2 class="title-text">
      {{ title }}
    </h2>
  </div>
  <div class="folder-name">
    <input
      class="form-control"
      [(ngModel)]="newName"
      [placeholder]="'features.manage_templates.components.newFolderModal.PLACEHOLDER' | translate"
      [required]="true"
      [maxlength]="850"
      #folderNameInput
      autofocus />
  </div>
  <div class="panel-footer">
    <div></div>
    <div>
      <button (click)="onCancel()" type="button" class="btn btn-default dialog-button">
        {{ "general.CANCEL" | translate }}
      </button>
      <button (click)="onSave()" type="button" class="btn btn-primary dialog-button">
        {{ "general.UPDATE" | translate }}
      </button>
    </div>
  </div>
</div>
