import { NgModule } from '@angular/core';
import { ComponentsModule } from '../../../components/components.module';
import { CommonModule } from '@angular/common';
import { AgGridModule } from "@ag-grid-community/angular";
import { RealtimeProfileDataManageComponent } from "./realtimeProfileDataManage.component";
import { RealtimeProfileDataManageService } from "./realtimeProfileDataManage.service";
import { BtnCellRendererComponent } from "./components/btnsCellRenderer/btnsCellRenderer.component";
import { StatusRTPCellRendererComponent } from "./components/statusRTPCellRenderer/statusRTPCellRenderer.component";
import { RealtimeProfileAddAttributeComponent } from "./components/addAttribute/realtimeProfileAddAttribute.component";
import { AddAttributeHeaderComponent } from "./components/addAttributeHeader/addAttributeHeader.component";
import { SharedModule } from '../../../shared.module';
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        AgGridModule.withComponents([BtnCellRendererComponent, AddAttributeHeaderComponent, StatusRTPCellRendererComponent]),
        SharedModule,
        ReactiveFormsModule,
    ],
    declarations: [
        RealtimeProfileDataManageComponent,
        BtnCellRendererComponent,
        StatusRTPCellRendererComponent,
        RealtimeProfileAddAttributeComponent,
        AddAttributeHeaderComponent
    ],
    providers: [
        RealtimeProfileDataManageService,
    ]
})
export class RealtimeProfileDataManageModule { }
