import {Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {SliderValueType} from "./sliderValueType.enum";
import {debounceTime} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";

@Component({
    selector: 'slider',
    templateUrl: './slider.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true
        }
    ],
})
export class SliderComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() min: number;
    @Input() max: number;
    @Input() sliderSteps: number;
    @Input() valueType: SliderValueType;
    @Input() shouldDebounceChangeEvent: boolean = true;

    sliderValue: number = 0;
    sliderValueType = SliderValueType;
    isDisabled: boolean;

    private onSliderDraged$: Subject<number> = new Subject();
    private onSliderDragedSubscription: Subscription;
    private controlValueChangedEmitter: any;
    private controlIsTouchedEmitter: any;

    ngOnInit(): void {
        this.observeSliderDrag();
    }

    writeValue(value: number): void {
        this.sliderValue = value || 0;
    }

    onSliderDraged(value: number) {
        if (this.shouldDebounceChangeEvent) {
            this.onSliderDraged$.next(value);
        } else {
            this.emitValueChanged(value);
        }
    }

    registerOnChange(fn: any): void {
        this.controlValueChangedEmitter = fn;
    }

    registerOnTouched(fn: any): void {
        this.controlIsTouchedEmitter = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private observeSliderDrag() {
        // On slider item drag - Handle value change after X mili sec.
        // The reason for debounce value change: When slider button draged, on change event fired allot of times.
        this.onSliderDragedSubscription = this.onSliderDraged$
            .pipe(debounceTime(250))
            .subscribe((value: number) => {
                this.emitValueChanged(value);
            });
    }

    private emitValueChanged(value: number) {
        this.sliderValue = value;

        this.controlValueChangedEmitter(this.sliderValue);
        this.controlIsTouchedEmitter();
    }

    ngOnDestroy(): void {
        this.onSliderDragedSubscription && this.onSliderDragedSubscription.unsubscribe();
    }
}