<div class="panel-body permissions-body user-permissions" [ngClass]="className">
    <div class="permissions-manager">
        <form [formGroup]="form">
            <div class="form-inline">
                <div class="form-group full-width">
                    <div class="permissions-manager-top-container">
                        <div *ngIf="isRoleDefaultEdit()" class="btn-group">
                            <ng-container class="button-container" *ngFor="let roleName of roleNames">
                                <button *ngIf="roleName !== 'Admin'" 
                                    class="btn" 
                                    [ngClass]="editedRoleName == roleName ? 'btn-primary' : 'btn-default'"
                                    (click)="changeEditedRole(roleName)">{{roleName}}
                                </button>
                            </ng-container>
                        </div>
                        <div>
                            <label>
                                {{'features.user_settings.body.manage_users.edit.permissions.LANDING_PAGE' | translate}}
                            </label>
                            <opti-select class="opti-select" [items]="landingPagesList" formControlName="landingPage">
                            </opti-select>                       
                        </div>
                    </div>
                    <span class="pull-right vertical-align-webkit">
                        <!-- && userRoleName && userRoleName.indexOf('Admin') < 0" -->
                        <a *ngIf="!isRoleDefaultEdit()" class="btn btn-link" (click)="setUserToRoleDefaultPermissions()">
                            {{'features.user_settings.body.manage_users.edit.permissions.RESET_TO_DEFAULT_ROLE_PERMISSIONS' | translate}}
                        </a>
                    </span>
                </div>
            </div>
            <div class="permissions-description">
                <span *ngIf="isRoleDefaultEdit()">
                    {{'features.user_settings.body.manage_users.edit.permissions.MAIN_DESCRIPTION' | translate:{'role': editedRoleName} }}
                </span>
                <span *ngIf="!isRoleDefaultEdit()">
                    {{'features.user_settings.body.manage_users.edit.permissions.DESCRIPTION' | translate}}
                </span>
            </div>
            <div class="title">{{'features.user_settings.body.manage_users.edit.permissions.TITLE' | translate}}</div>
            <div formArrayName="permissions">
                <div *ngFor="let permissionItem of permissionsArray.controls; let permissionItemIndex = index; let last = last" [ngClass]="{'last-child': last}" >
                    <div class="permissions-container" [formGroupName]="permissionItemIndex">
                        <span>{{permissionItem.value.permissionName}}</span>
                        <switch [buttonType]="SwitchButtonType.Small" formControlName="isSelected"></switch>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>