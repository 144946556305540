<div class="copy-to-modal" data-qa-id="copy-to-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div class="dropdowns-container">
      <div class="dropdowns-item" data-qa-id="copy-template-to-brand">
        <label>{{ "features.manage_templates.components.copy_template_modal.BRAND_LABEL" | translate }}</label>
        <div class="item-list">
          <opti-search-list
            [items]="brands"
            (ngModelChange)="onBrandChange($event)"
            [ngModel]="selectedBrandId"
            [config]="brandsListConfig"
            required="true"></opti-search-list>
        </div>
      </div>
      <div class="dropdowns-item" data-qa-id="copy-template-to-submethod">
        <label>{{ "features.manage_templates.components.copy_template_modal.TEMPLATE_LABEL" | translate }}</label>
        <div class="item-list">
          <opti-search-list
            [items]="subMethodsOfSelectedBrand"
            [ngModel]="selectedSubMethodId"
            (ngModelChange)="onSubMethodChange($event)"
            [config]="subMethodsListConfig"
            required="true"></opti-search-list>
        </div>
      </div>
      <div class="dropdowns-item" data-qa-id="copy-template-to-folder">
        <label>{{ "features.manage_templates.components.copy_template_modal.FOLDER_LABEL" | translate }}</label>
        <div class="item-list">
          <opti-search-list
            [items]="foldersOfSelectedSubMethod"
            [ngModel]="selectedFolderId"
            [config]="foldersListConfig"
            (ngModelChange)="onFolderChange($event)"
            required="true"></opti-search-list>
        </div>
      </div>
    </div>
  </opti-logic-modal>
  <opti-loading [show]="isCopyingTemplate"></opti-loading>
</div>
