import { Component, OnInit } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SearchListConfig } from '../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { OslValueType } from '../../../../../components/optiSearchList/models/oslValueType.enum';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { LanguageTemplate } from '../../../models/languageTemplate';

interface LanguageItem extends IListItem {
	code: string;
}

@Component({
	selector: 'default-language-modal',
	templateUrl: './defaultLanguageModal.component.html',
	styleUrls: ['./defaultLanguageModal.component.scss']
})
export class DefaultLanguageModalComponent implements OnInit {
	public selectedDefaultLanguage = (language: LanguageTemplate) => {};

	public languages: LanguageTemplate[];
	public defaultLanguage: LanguageTemplate;
	public listOfLanguages: LanguageItem[] = [];
	public selectedLanguageId: number;
	public modalConf: IModalConf;

	constructor(
		private translateService: TranslateService,
		private bsModalRef: BsModalRef,
		private manageTemplatesService: ManageTemplatesService
	) {}

	ngOnInit(): void {
		this.initModalConfig();
		this.initDropdownData();
	}

	public onLanguageSelected(languageId: number): void {
		this.defaultLanguage = this.languages.find((lang) => lang.Id === languageId);
	}

	public initModalConfig(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.optimail.defaultLanguageModal.SET_SOURCE_LANGUAGE'),
			description: this.translateService.instant(
				'features.manage_templates.optimail.defaultLanguageModal.SOURCE_LANGUAGE_MODAL_SUBTITLE'
			),
			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.optimail.defaultLanguageModal.SET_SOURCE'),
					action: () => {
						this.selectedDefaultLanguage(this.defaultLanguage);
						this.bsModalRef.hide();
					}
				},
				{
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => this.bsModalRef.hide()
				}
			],
			xButton: {
				action: () => this.bsModalRef.hide()
			},
			hideHeaderBorder: true
		};
	}

	public initDropdownData(): void {
		this.manageTemplatesService.getTemplateLanguage().subscribe((languages) => {
			this.languages = languages;
			this.listOfLanguages = languages?.map((lang) => {
				return {
					id: lang.Id,
					name: lang.DisplayName,
					code: lang.Code
				};
			});
		});
	}
}
