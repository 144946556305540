<div class="editor-body-container">
  <div class="bee-free-content-panel">
    <div class="body-buttons">
      <button
        *ngIf="templateType === EditorType.beefree"
        class="body-button fullscreen-button"
        [ngClass]="{'fr-active': isFullScreen}"
        (click)="expandBeeFree()"
        data-qa-id="visual-editor-full-screen">
        <span class="icon-arrow-collapse" *ngIf="isFullScreen"></span>
        <span class="icon-arrow-expand" *ngIf="!isFullScreen"></span>
      </button>
      <button *ngIf="!isPopup && template" (click)="openPreview()" class="body-button" data-qa-id="visual-editor-preview">
        <i class="material-icons button-icon">remove_red_eye</i>
        <span class="small">{{ "features.manage_templates.components.optimailPreview.PREVIEW" | translate }}</span>
      </button>
      <button
        *ngIf="templateType === EditorType.beefree && template?.templateId && !template?.languages?.length"
        type="button"
        (click)="convertJsonToHtml()"
        class="body-button"
        [disabled]="template?.templateId === null">
        <span class="icon material-icons button-icon">code</span>
        <span>{{ "features.manage_templates.optimail.BeefreeConvertModal.okButton" | translate }}</span>
      </button>
      <button *ngIf="!isPopup" (click)="editPlainTextClick()" class="body-button" data-qa-id="visual-editor-edit-plain-text">
        <i class="material-icons button-icon">receipt</i>
        <span data-qa-id="EditPlainText">{{ "features.manage_templates.optimail.FORM.PLAIN_TEXT_BTN" | translate }}</span>
      </button>
    </div>
    <div class="bee-free-container" *ngIf="templateType === EditorType.beefree">
      <beefree-container *ngIf="!isPopup" [isFullscreen]="isFullScreen"></beefree-container>
      <beefreePopup-container *ngIf="isPopup" [isFullscreen]="isFullScreen"></beefreePopup-container>
    </div>
  </div>
  <div *ngIf="!isPopup" class="bee-free-settings-panel">
    <div
      [ngClass]="{
        'template-metadata-container': panelType === panelTypeAccess.metadata,
        'template-metadata-not-visible': panelType !== panelTypeAccess.metadata
      }">
      <template-metadata></template-metadata>
    </div>
    <div
      [ngClass]="{
        'translations-container': panelType === panelTypeAccess.translation,
        'template-metadata-not-visible': panelType !== panelTypeAccess.translation
      }">
      <translation-panel></translation-panel>
    </div>
    <div class="settings-buttons">
      <button
        class="settings-btn"
        [ngClass]="{isButtonActive: panelType === panelTypeAccess.metadata}"
        (click)="panelType = panelTypeAccess.metadata"
        data-qa-id="visual-editor-metadata">
        <span class="icon material-icons tune-icon">tune</span>
      </button>
      <button
        class="settings-btn"
        [ngClass]="{isButtonActive: panelType === panelTypeAccess.palette}"
        (click)="panelType = panelTypeAccess.palette"
        data-qa-id="visual-editor-palette">
        <span class="icon material-icons palette-icon">palette</span>
      </button>
      <button
        *ngIf="isTranslationFeatureAvailable"
        class="settings-btn translations-button"
        [ngClass]="{isButtonActive: panelType === panelTypeAccess.translation}"
        (click)="panelType = panelTypeAccess.translation"
        data-qa-id="visual-template-translate">
        <span class="icon material-icons palette-icon">translate</span>
        <div *ngIf="isTranslationWarningVisible" class="attention-indicator"></div>
      </button>
    </div>
  </div>
</div>
