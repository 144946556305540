<div class="credentials" [ngClass]="{'isLoading': isLoading}">
    <opti-loading [show]="isLoading" [size]="'md'"></opti-loading>
    <div class="panel-heading">
        <h2>{{ 'features.user_settings.body.message_archiving.credentials.TITLE' | translate }}</h2>
    </div>
    <div class="panel-body">
        <div class="radio-button-group">
            <div class="radio radio-primary radio-exclusion exclude-radio-container">
                <input (click)='awsClicked()' class="radio" type="radio" value="" [checked]='isAws'>
                <label (click)='awsClicked()' class="radio-inline type-label">{{ 'features.user_settings.body.message_archiving.credentials.AWS_TYPE' | translate }}</label>
            </div>
            <div class="radio radio-primary radio-exclusion exclude-radio-container">
                <input (click)='gcpClicked()' class="radio" type="radio" value="" [checked]='isGcs'>
                <label (click)='gcpClicked()'  class="radio-inline type-label">{{ 'features.user_settings.body.message_archiving.credentials.GCS_TYPE' | translate }}</label>
            </div>
        </div>
        <div class="creds-data" *ngIf='isAws'>
            <form>
                <div class="form-row">
                    <div class="row-label">
                        <label>{{ 'features.user_settings.body.message_archiving.credentials.BUCKET_NAME' | translate }}</label>
                    </div>
                    <div class="verify-row">
                        <div class="row-input">
                            <input name="bucketName" type="text" [(ngModel)]="s3Secret.BucketName">
                        </div>
                        <button class="btn btn-default" (click)="verifyCredentials()" data-qa-id="verify-secret" [disabled]="isVerifying || !s3Secret.BucketName || !s3Secret.AccessKey || !s3Secret.SecretKey || !selectedRegion">
                            {{'features.user_settings.body.message_archiving.credentials.VERIFY' | translate }}
                        </button>
                    </div>
                </div>
                <div class="form-row">
                    <div class="row-label">
                        <label>{{ 'features.user_settings.body.message_archiving.credentials.AWS_REGION' | translate }}</label>
                    </div>
                    <div class="row-input">
                        <opti-select name="region"
                                [placeholder]="'features.user_settings.body.message_archiving.credentials.AWS_REGION' | translate"
                                [items]="allRegions"
                                [(ngModel)]="selectedRegion"></opti-select>
                    </div>
                </div>
                <div class="form-row" *ngIf="false">
                    <div class="row-label">
                        <label>{{ 'features.user_settings.body.message_archiving.credentials.AWS_ENDPOINT' | translate }}</label>
                    </div>
                    <div class="row-input">
                        <input name="Endpoint" type="text" [(ngModel)]="s3Secret.Endpoint">
                    </div>
                </div>
                <div class="form-row">
                    <div class="row-label">
                        <label>{{ 'features.user_settings.body.message_archiving.credentials.AWS_ACCESS_KEY' | translate }}</label>
                    </div>
                    <div class="row-input">
                        <input name="AccessKey" type="password" [(ngModel)]="s3Secret.AccessKey">
                    </div>
                </div>
                <div class="form-row">
                    <div class="row-label">
                        <label>{{ 'features.user_settings.body.message_archiving.credentials.AWS_SECRET_KEY' | translate }}</label>
                    </div>
                    <div class="row-input">
                        <input name="SecretKey" type="password" [(ngModel)]="s3Secret.SecretKey">
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf='isGcs'>
            <gcs-credentials-form [(gcsSecret)]='gcsSecretVal' (verificationCredentials)='verifyGcsCreds($event)'></gcs-credentials-form>

        </div>
       <path-builder [pathItems]='pathItems' ></path-builder>
    </div>
    <div class="panel-footer footer-btns">
        <button class="btn btn-primary" (click)="save()" data-qa-id="save-secret" [disabled]="isVerifying || isSaving">
            {{'general.SAVE_CHANGES' | translate }}
        </button>
    </div>
    <div>
        <toast
          [(isOpen)]="toastParams.isOpen"
          [icon]="toastParams.icon"
          [location]="toastParams.location"
          [message]="toastParams.message"
          [showCloseIcon]="toastParams.showCloseIcon"
          [toastIconType]="toastParams.toastIconType"></toast>
      </div>
</div>
