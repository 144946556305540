import { SearchItem } from '../../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { ConditionalValidatorService } from '../../../services/conditionalValidator.service';
import { ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Condition, MultipleConditionEditorConfig, MultipleConditionValue } from '../../../attributesList.model';
import { ConditionalAttributeService } from '../../../services/condition.service';
import { IfElseConditionComponent } from '../../conditionalEditor/ifElseCondition/ifElseCondition.component';
import { ConditionalAttributesMaxLength as maxLength } from './../../../attributesList.constants';

@Component({
  selector: 'multiple-condition-editor',
  templateUrl: './multipleConditionEditor.component.html',
  styleUrls: ['./multipleConditionEditor.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultipleConditionEditorComponent),
    multi: true
  }]
})
export class MultipleConditionEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChildren(IfElseConditionComponent) allConditions: QueryList<IfElseConditionComponent>;

  @ViewChild('toggleButtonIcon') toggleButtonIcon: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  @Input() config: MultipleConditionEditorConfig;
  @Input() isDuplicateEnable: boolean;
  @Input() set isValueAlreadyExist(exist: boolean) {
    this._isValueAlreadyExist = exist
    if(this.form) { 
      if(exist) this.value.markAsTouched();
      this.value.updateValueAndValidity();
      this.onChange(this.buildConditions());
    }
  };
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  public translateKeys = translateKeys;
  public form: FormGroup;
  public attributesSearchItems: SearchItem[];
  public isAddButtonDisabled = false;
  public isComplexSelection = false;
  public isAddMode = false;
  public isMenuOpen = false;
  private _isValueAlreadyExist = false;
  private maxConditionAmount = 10;
  private isMenuOpenListener: () => void;

  constructor(private validationService: ConditionalValidatorService,
    private conditionService: ConditionalAttributeService, private renderer: Renderer2) {
      this.isMenuOpenListener = this.renderer.listen('window', 'click', (e:Event) => {
        if(e.target !== this.toggleButton.nativeElement 
        && e.target !== this.menu?.nativeElement 
        && e.target !== this.toggleButtonIcon.nativeElement){
          this.isMenuOpen=false;
        }
      });
  }

  ngOnInit(): void {
    this.attributesSearchItems = this.conditionService.getAttributesSearchItems();
  }

  ngOnDestroy() {
    if (this.isMenuOpenListener) {
      this.isMenuOpenListener();
    }
  }

  public onMenuButtonClicked() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public onDrop(event: CdkDragDrop<string[]>) {
    let movedItem = this.conditions.controls[event.previousIndex];

    let resArray = this.conditions.controls.slice(0, event.previousIndex)
      .concat(this.conditions.controls.slice(event.previousIndex + 1));
    
    this.conditions.controls = resArray.slice(0, event.currentIndex)
      .concat(movedItem, resArray.slice(event.currentIndex));

      this.onChange(this.buildConditions());
  }

  public onAddCondition() {
    let newCondition = new FormControl(this.conditionService.createConditionForm(this.conditions.length + 1));
    this.conditions.push(newCondition);
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
    this.complexSelection.updateValueAndValidity();
    this.onChange(this.buildConditions());
  }

  public onConditionChange(form: FormGroup, index: number){
    this.conditions.controls[index].setValue(form);
    this.complexSelection.updateValueAndValidity();
    this.onChange(this.buildConditions());
  }

  public onRemoveCondition(position: number) {
    this.conditions.removeAt(position - 1);
    this.conditions.controls = this.conditions.controls.map((x, i) => {
      x.value.controls['position'].setValue(i + 1);
      return x
    });
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
    if(this.conditions.length == 1) {
      this.isComplexSelection = false;
      this.onCheckBoxChange();
    }
    this.complexSelection.updateValueAndValidity();
    this.onChange(this.buildConditions());
  }

  public onCheckBoxChange(skipEmit: boolean = false) {
    this.complexSelection.setValidators(this.isComplexSelection 
      ? [this.validationService.validate(() => this.conditions.length)]
      : []);

    this.complexSelection.updateValueAndValidity();
    if(!skipEmit) this.onChange(this.buildConditions());
  }

  public onComplesSelectionChange() {
    this.onChange(this.buildConditions());
  }

  public onValueChange() {
    this.onChange(this.buildConditions());
    this.valueChanged.emit(this.value.value);
  }

  public duplicateEditor() {
    this.duplicate.emit();
    this.isMenuOpen = false;
  }

  public setFirstInvalidInputAsTouched() {
    if (this.value.invalid) {
      this.value.markAsTouched();
      return;
    } 

    let firstInvalidConditionIndex = -1;
    this.conditions.controls.find((x, i) => {
      if(x.invalid) {
        firstInvalidConditionIndex = i;
        return true;
      }
      return false;
    });

    if (firstInvalidConditionIndex >= 0) {
      this.allConditions.forEach((x, i) => {
        if (i === firstInvalidConditionIndex) {
          x.setFirstInvalidInputAsTouched();
        }
      });
      return;
    }

    if (this.complexSelection.invalid) {
      this.complexSelection.markAsTouched();
    }
  }

// ================================ \\
// ====| ValueAccessor region |==== \\

  writeValue(value: MultipleConditionValue): void {
    this.createForm(value.Conditions);

    this.value.setValue(value.Value);
    if(this._isValueAlreadyExist) this.value.markAsTouched();

    if(value.ComplexExpression && this.config.showComplexExpression) {
      this.isComplexSelection = true;
      this.onCheckBoxChange(true);
      this.complexSelection.setValue(this.conditionService.expressionToDisplayFormat(value.ComplexExpression));
    }
    if(this.config.isDisabled) {
      this.conditions.disable();
      this.value.disable();
      this.complexSelection.disable();
    }
    if (!this.config.showComplexExpression) {
      this.isComplexSelection = false;
      this.onCheckBoxChange(true);
    }
    
    this.isAddButtonDisabled = this.conditions.length >= this.maxConditionAmount;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onChange = (fn: MultipleConditionValue) => {}

  registerOnTouched(fn: any): void { }

// ================================ \\
// =====| FormControl region |===== \\

  get conditions(): FormArray {
    return this.form.get('conditions') as FormArray;
  }

  get complexSelection(): FormControl {
    return this.form.get('complexSelection')as FormControl;
  }

  get value(): FormControl {
    return this.form.get('value')as FormControl;
  }

// ================================ \\
// =======| private region |======= \\

  private createForm(conditions: Condition[]) {
    const conditionsControls: FormControl[] = conditions.length > 0
      ? conditions.map(c => new FormControl(this.conditionService.conditionToForm(c))) 
      : [new FormControl(this.conditionService.createConditionForm(1))];

    this.form = new FormGroup({
      conditions: new FormArray(conditionsControls, [this.validationService.validFormArray()]),
      complexSelection: new FormControl(''),
      value: new FormControl('', [Validators.required, Validators.maxLength(maxLength), this.validationService.isValueAlreadyExist(() => this._isValueAlreadyExist)]),
    });
  }
  
  private buildConditions(): MultipleConditionValue {
    return {
      ComplexExpression: this.isComplexSelection ? 
        this.conditionService.formatComplexExpression(this.complexSelection.value, this.maxConditionAmount) : '',
      Conditions: this.conditions.value.map(form => form ? this.conditionService.buildCondition(form) : null),
      Value: this.value.value,
      IsValid: this.form.valid,
      EmptyRequiredFields: !this.conditions.valid || !this.complexSelection.valid || !this.value.value,
    };
  }
}

const translateKeys = {
  value: 'features.user_settings.body.attributes.addConditionAttribute.VALUE',
  optional: 'features.user_settings.body.attributes.addConditionAttribute.OPTIONAL',
  addCondition: 'features.user_settings.body.attributes.addConditionAttribute.ADD_CONDITION',
  complexSelection: 'features.user_settings.body.attributes.addConditionAttribute.COMPLEX_SELECTION',
  customersWhoMeetConditions: 'features.user_settings.body.attributes.addConditionAttribute.CUSTOMERS_WHO_MEET_CONDITIONS',
  customersWhoDontMeetConditions: 'features.user_settings.body.attributes.addConditionAttribute.CUSTOMERS_WHO_DONT_MEET_CONDITIONS',
  complexSelectionErrMsg: 'components.complexExpression.EXPRESSION_ERROR_MSG',
  ifLbl: 'features.user_settings.body.attributes.multipleConditionAttribute.IF',
  elseLbl: 'features.user_settings.body.attributes.multipleConditionAttribute.ELSE',
  conditionsMet: 'features.user_settings.body.attributes.multipleConditionAttribute.FOLLOWING_CONDITIONS',
};