<div class="choose-attribute-modal-container">
  <opti-logic-modal [modalConf]="modalConf">
    <form style="margin-bottom: 12px;">
      <div class="customer-attributes-container">
        <div class="grid-toolbar">
          <div class="search-input-conatiner">
            <input-search
              data-qa-id="input-search"
              (termChange)="onFilter($event)"
              [placeholder]="translateKeys.selectAttribute.inputPlaceholder | translate" ></input-search>
          </div>
        </div>
      </div>
    </form >

        <div class="list-group" style="margin-left: -24px; margin-right: -24px;" >
          <button *ngFor="let attribute of attributes" 
            data-qa-id="list-group-attribute"
            [ngClass]="{'selected':selectedAttribute && attribute.DisplayName === selectedAttribute.DisplayName}"
            (click)="onSelect($event)" 
            class="list-group-item list-group-item-action"
            style="padding: 7px 25px;border:none;">
            {{ attribute.DisplayName }}
          </button>
        </div>
  </opti-logic-modal>
</div>
