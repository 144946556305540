import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import { TemplateScriptGuardModalComponent } from './TemplateScriptGuardModal/templateScriptGuardModal.component';
import { TemplateScriptGuardModalService } from './TemplateScriptGuardModal/templateScriptGuardModal.service';
import './templateScriptGuardContainer.component.scss';

@Component({
	selector: 'template-script-guard-container',
	templateUrl: './templateScriptGuardContainer.component.html'
})
export class TemplateScriptGuardContainerComponent implements OnInit, OnDestroy {
	private onHideSubscription: Subscription;

	@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

	private isRecaptchaValid: boolean;

	constructor(
		private modalService: OptiLogicModalService,
		private bsModalServiceCL: BsModalService,
		private templateScriptGuardModalService: TemplateScriptGuardModalService
	) {
		this.onHideSubscription = this.bsModalServiceCL.onHide.subscribe((reason: string) => {
			this.closed.emit(this.isRecaptchaValid);
		});

		this.templateScriptGuardModalService.isRecaptchaValid.subscribe((isRecaptchaValid: boolean) => {
			this.isRecaptchaValid = isRecaptchaValid;
		});
	}

	ngOnInit(): void {
		this.openModal();
	}

	public openModal(): void {
		const modalRef = this.modalService.open(TemplateScriptGuardModalComponent, 'md', {});
	}
	ngOnDestroy(): void {
		this.onHideSubscription.unsubscribe();
	}
}
