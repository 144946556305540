import { ChangeDetectorRef, Component, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation, forwardRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiKeyDetails } from '../ApiKeyDetails/apiKeyDetails.component';
import { OptiLogicModalService } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { AjaxResponse, IconType } from '@optimove/ui-sdk/common/models';
import { ApiKeyManagementService } from "../apiKeyManagement.service";
import { emailValidator } from "../../../../directives/customValidation/emailValidation.directive";
import { nicknameValidator } from "../../../../directives/customValidation/nicknameValidation.directive";
import { first } from 'rxjs/operators';
import { ApiKeyActivatedComponent } from "../ApiKeyActivated/apiKeyActivated.component";
import { AccessType } from "../../apiManagement/models/accessType.model";
import { DefaultClaims } from "../../apiKeyManagement/models/defaultClaims.model";
import { ToastModel } from "src/app/features/missionControl/models/toast.model";
import { locationParameter } from "src/app/models/locationParameter.enum";
import { Subscription } from "rxjs";
import { OptiApiKey } from "../models/optiApiKey.model";
import { AbstractNestedFormComponent } from "src/app/components/abstractNestedForm/abstractNestedForm.component";
import { Integrations } from "../Roles/Integrations/integrations.component";

@Component({
    selector: 'create-api-key',
    templateUrl: './createApiKey.component.html',
    styleUrls: ['./createApiKey.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CreateApiKey),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CreateApiKey),
            multi: true
        }
    ]
})
export class CreateApiKey extends AbstractNestedFormComponent<OptiApiKey> implements OnInit, OnDestroy {

    @ViewChild('apiKeyDetails', {static: false}) apiKeyDetails: ApiKeyDetails;
    @ViewChild('integrations', {static: false}) integrations: Integrations;

    public apiKeyExistsMsg: string;
    public showApiCopiedToast: boolean = false;
    public toastParams: ToastModel;
    public translateKeys = translateKeys;
    public leavingMsg = this.translateService.instant(translateKeys.leavingMsg);
    private maxUserNameLength: number = 64;
    private redirectUrl: string = '/user-settings/apiKeyManagement';
    private keyCopyToastSub: Subscription = new Subscription();

    constructor(private translateService: TranslateService,
        private fb: FormBuilder,
        private router: Router,
        private modalService: OptiLogicModalService,
        private bsModalRef: BsModalRef,
        private apiKeyManagementService: ApiKeyManagementService,
        cd: ChangeDetectorRef) {
            super(cd);
    }

    ngOnInit(): void {
        this.setToastParams();
        this.apiKeyManagementService.passShowApiActivatedToast(false);

        const defaultClaims = new DefaultClaims();
        this.form = this.fb.group({
            IsActive: [true],
            RoleType: [null, Validators.required],
            Nickname: ['', [Validators.required, nicknameValidator(), Validators.maxLength(this.maxUserNameLength)]],
            ContactEmail: ['', [Validators.required, emailValidator()]],
            Claims: new FormControl({ value: defaultClaims, disabled: false })
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    private setToastParams() {
        this.toastParams = {
            isOpen: false,
            message: '',
            icon: "info",
            showCloseIcon: false,
            location: locationParameter.Default,
            toastIconType: IconType.AngularMaterial
        };
    }

    private closeModal() {
        this.bsModalRef.hide();
        this.router.navigateByUrl(this.redirectUrl);
    }

    showLeavingModal(message: string) {
        this.modalService.openModalMessage(
            'sm',
            {
                message: message,
                buttons: [{
                    class: 'btn-primary',
                    label: 'Leave Screen',
                    action: () => this.closeModal(),
                    dataQaId: 'createUserOKBtn'
                },
                {
                    class: 'btn-default',
                    label: 'Continue Editing',
                    action: () => this.bsModalRef.hide(),
                    dataQaId: 'cancelModalBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }
    
    private closeModalAndRedirect(isGeneralError: boolean){
        this.bsModalRef.hide();
        if (isGeneralError) {
            this.router.navigateByUrl(this.redirectUrl);
        }
    }

    private ShowErrorMsg(title: string, message: string, isGeneralError: boolean){
        this.modalService.openModalMessage(
            'sm',
            {
                title: title,
                message: message,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant(translateKeys.ok),
                    action: () => this.closeModalAndRedirect(isGeneralError),
                    dataQaId: 'createUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    openApiKeyActivatedModal(apiKey: string) : void {
        this.bsModalRef = this.modalService.open(
            ApiKeyActivatedComponent, 
            "md", 
            <ModalOptions<any>>{ 
                ignoreBackdropClick: true,
                initialState: {
                    apiKey: apiKey,
                    redirectUrl: this.redirectUrl
                },
                class: 'api-key-activated'
        });

        this.keyCopyToastSub = this.bsModalRef.content.onToast.pipe()
        .subscribe((params: ToastModel) => {
            if (params != null) {
                this.toastParams = params;
                this.apiKeyManagementService.emitToastOpened(params);
            }
        }, (err) => console.log(err));
    }

    onSubmit(): void {
        let apiKey = this.apiKeyDetails.buildNewApiKey();   
        this.apiKeyManagementService.createApiKeyAsync(apiKey)
            .pipe(first()).subscribe(async (response: AjaxResponse<string>) => {
                if (response.isSuccess) {
                    this.openApiKeyActivatedModal(response.data);
                } else {
                    (response.errorMsg) ? this.ShowErrorMsg(this.translateService.instant(translateKeys.failureCreate),response.errorMsg, false)
                        : this.ShowErrorMsg(this.translateService.instant(translateKeys.failureCreate), this.translateService.instant(translateKeys.generalError), true);
                }
            }, (_) => this.ShowErrorMsg(this.translateService.instant(translateKeys.failureCreate), this.translateService.instant(translateKeys.generalError), true));
    }

    ngOnDestroy(): void {
        this.keyCopyToastSub ?? this.keyCopyToastSub.unsubscribe();
    }
}

const translateKeys = {
    failureCreate: 'features.user_settings.body.apiKeyManagement.createApiKey.FAILURE_CREATE',
    newApiUserEmail: 'features.user_settings.body.manage_users.edit.account.body.NEW_API_USER_EMAIL',
    ok: 'general.OK',
    generalError: 'general.validation_error_messages.GENERIC_ERROR',
    back: 'general.BACK',
    title: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.TITLE',
    cancel: 'features.user_settings.body.attributes.addAttribute.CANCEL',
    activateKey: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.ACTIVATE_KEY',
    leavingMsg: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.LEAVING_MSG'
}