import  {Component } from "@angular/core";
import {BrandsService} from "../BrandsList/brandsService";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import {ICellRendererParams} from "@ag-grid-community/all-modules";
import {BrandModel} from "../../brandModel";

@Component({
    selector:'is-DOI-cell',
    templateUrl:'./isDOICellRender.component.html',
    styleUrls:['./isDOICellRender.component.scss']
})

export class IsDOICellRenderComponent implements ICellRendererAngularComp {
    public isDOIEnabled: boolean;

    constructor(private  brandService: BrandsService) {}

    agInit(params: ICellRendererParams): void {
        this.isDOIEnabled = this.brandService.checkBrandUsingDoubleOptIn(params.data.BrandId);
    }

    refresh(): boolean {
        return true;
    }
}