import {Component, Output, Input, EventEmitter, OnChanges, SimpleChanges, HostListener, OnInit, ViewEncapsulation } from "@angular/core";
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import { TemplateDetailsPreviewData } from "../templateHTMLModalContainer/models/templateModalData.model";
import { isNullOrUndefined } from "util";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';

@Component({
    selector: 'templates-html-carousel-modal',
    templateUrl: './templatesHtmlCarouselModal.component.html',
	styleUrls: ['../../../../components/templatePreviewComponents/templatesPreviewCarouselModal/templatesPreviewCarouselModal.component.scss', './templatesHtmlCarouselModal.component.scss']
})
export class TemplatesHtmlCarouselModalComponent implements OnInit, OnChanges {
    @Input() templates: TemplateDetailsPreviewData[];
    @Input() displayTemplateIndex: number;
    @Input() datesData: string[];

    @Output() showTemplatesGridViewModal: EventEmitter<any> = new EventEmitter();

    public currentTemplateIndex: number;
    public mapActivated : boolean;
    public showModifiedInfo : boolean;
    public selectedTabIdx: number;
    public tabs: any[];
    public datesDataFormatted: string[];
    private selectedTabIndexToMetricKey = ["UniqueRateValue", "UniqueValue", "TotalValue"];
    private metricPercentageToColor = [[20, "major"], [5, "high"], [1, "medium"], [0, "low"]];

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if (event.code === "ArrowLeft") {
            this.showPreviousTemplate();
        } else if (event.code === "ArrowRight") {
            this.showNextTemplate();
        }
    }

    constructor(private sanitizer: DomSanitizer, private translate: TranslateService) {}

    ngOnInit() {
        this.selectedTabIdx = 0;
        if (isNullOrUndefined(this.currentTemplateIndex)) {
            this.currentTemplateIndex = 0;
        }
        this.mapActivated = true;
        this.showMap();
        this.tabs = [this.translate.instant('components.templatePreview.CLICK_RATE'),
            this.translate.instant('components.templatePreview.UNIQUE_CLICKS'),
            this.translate.instant('components.templatePreview.TOTAL_CLICKS')].map((tabName, index) => {
            return {
                id: index,
                name: tabName
            }
        });

        // this.datesData[0] - start date, this.datesData[2] - end time like in analysis time change
        if (this.datesData[0] && this.datesData[2]) {
            const dateFrom = new Date(this.datesData[0]);
            const dateTo = new Date(this.datesData[2]);
            this.datesDataFormatted = [moment(dateFrom).format("D MMM YYYY"), moment(dateTo).format("D MMM YYYY")];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.displayTemplateIndex) {
            this.currentTemplateIndex = this.displayTemplateIndex;
        }
    }

    showNextTemplate() {
        if (this.currentTemplateIndex < this.templates.length - 1) {
            this.currentTemplateIndex++;
        } else {
            this.currentTemplateIndex = 0;
        }
        this.showMap();
    }

    showPreviousTemplate() {
        if (this.currentTemplateIndex > 0) {
            this.currentTemplateIndex--;
        } else {
            this.currentTemplateIndex = this.templates.length - 1;
        }
        this.showMap();
    }

    onShowTemplatesGridViewModal() {
        this.showTemplatesGridViewModal.emit();
    }

    public onTabSelected(selectedTabIdx: number) {
        this.selectedTabIdx = selectedTabIdx;
        this.showMap();
    }

    public ClickMapSwitch() {
        // on click map on action
        if(!this.mapActivated) {
            this.showMap();
        } else {
            // reset to original html with no metrics
            this.templates[this.currentTemplateIndex].htmlToShow = this.templates[this.currentTemplateIndex].html;
        }
    }

    private checkIfToShowModifiedInfo() {
        if (this.templates[this.currentTemplateIndex].lastModifiedDate && this.datesData[0]) {
            const dateFrom = new Date(this.datesData[0]);
            // reset hours/mins/sec/msecs
            dateFrom.setHours(0,0,0,0);
            const lastModified = new Date(this.templates[this.currentTemplateIndex].lastModifiedDate);
            lastModified.setHours(0,0,0,0);
            if (lastModified.getTime() > dateFrom.getTime()) {
                return true;
            }
        }
        return false;
    }

    private showMap() {
        this.showModifiedInfo = this.checkIfToShowModifiedInfo();

        var selectedMetric = this.selectedTabIndexToMetricKey[this.selectedTabIdx];
        if (typeof this.templates[this.currentTemplateIndex].metrics[selectedMetric] == "undefined") {
            // default view without metrics - html content
            this.templates[this.currentTemplateIndex].htmlToShow = this.templates[this.currentTemplateIndex].html;
            return;
        }
        // @ts-ignore
        this.templates[this.currentTemplateIndex].htmlToShow =  this.embedMetricIntoHTMLTemplate(
            this.templates[this.currentTemplateIndex].html,
            this.templates[this.currentTemplateIndex].plainTextLinkOffset,
            this.templates[this.currentTemplateIndex].metrics[selectedMetric],
            selectedMetric);
    }

    private embedMetricIntoHTMLTemplate(templateHTML,plainTextLinkOffset, templateMetric, selectedMetricName="") {
        // suport also <area> links...????
        // find all links with name offsets are injected by OptiContent to collect click metrics
        // <a....  name="click_offset_<d>> .... </a>"
        const linkReg = /<a[\s]+([^>]+)>*.click_offset_(\d)+/g;
        const linkPositionRegex = "click_offset_(\\d+)";

        let isFirstMatch = true;
        const metricUnit = (selectedMetricName == "UniqueRateValue") ? "%" : "";
        let  replacer = (match) => {
            const firstClass = isFirstMatch ? 'firstHeatMapMetric' : '';
            isFirstMatch = false;

            const linkPositionMatch = match.match(linkPositionRegex);
            const linkPosition = linkPositionMatch ? linkPositionMatch[1] : null;

            // p2 is for parenthesized capture group
            if (linkPosition && typeof templateMetric[linkPosition] !== 'undefined') {
                // format from: 0.156 -> 15.6%
                const ratePercentageValue = (parseFloat(templateMetric[linkPosition])*100).toFixed(1);
                const className = (selectedMetricName == "UniqueRateValue") ? this.getClassNameByPercentage(ratePercentageValue) : this.getClassNameByValue(templateMetric[linkPosition],templateMetric);
                const metricValue = (selectedMetricName == "UniqueRateValue") ? ratePercentageValue : templateMetric[linkPosition];
                return match.replace("<a", `<a data-heatmark="${metricValue}${metricUnit}" class="heatMark ${className}HeatMark ${firstClass}" `);
            }
            return match.replace("<a", `<a data-heatmark="0${metricUnit}" class="heatMark lowHeatMark ${firstClass}" `);
        }
        templateHTML = templateHTML.replace(linkReg, replacer);

        // support template with no <head> tag
        if (templateHTML.match(/<head[^>]*>[\s\S]*<\/head>/gi) == null) {
            templateHTML = "<head>" + this.getFrameCss() + templateHTML + "</head>";
        } else {
            templateHTML = templateHTML.replace("</head>", "</head>" + this.getFrameCss());
        }

        return templateHTML;
    }

    private getClassNameByValue(value, metrics) {
        var total = metrics.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        var percentage = Math.round((100 * value) / total);
        return this.getClassNameByPercentage(percentage);
    }

    private getClassNameByPercentage(percentage) {
        for (var index = 0; index < this.metricPercentageToColor.length; index ++) {
            if(percentage >= this.metricPercentageToColor[index][0]) {
                return this.metricPercentageToColor[index][1];
            }
        }
        // default
        return "low";
    }

    private getFrameCss() {
        // added a top body space avoid the hide of metric value of the top links by upper bar
        return `
<style>
@import url(https://fonts.googleapis.com/css?family=Roboto:400);
body {
    margin-top: 13px !important;
}

a.heatMark {
  background: #FFFFFF;
  outline: 2px solid #000000;
  position: relative;
  display: inline-block;
  margin-top: 25px;
}
a.heatMark::before {
  content: " " attr(data-heatmark) " ";
  position: absolute;
  right: -2px;
  top: -20px;
  padding: 2px 4px;
  text-align: right;
  font: bold 12px Roboto;
  color: #000000;
  background: #FFFFFF;
  white-space: nowrap;
}

a.firstHeatMapMetric {
    margin-top: 25px;
}

a.majorHeatMark,
a.highHeatMark,
a.mediumHeatMark,
a.lowHeatMark {
    outline: 2px solid !important;
}

a.majorHeatMark {
  outline-color: #FC002B !important;
  background-color: rgba(252, 0, 43, 0.2);
}

a.majorHeatMark::before {
  color: #FFFFFF;
  background: #FC002B;
}

a.highHeatMark {
  outline-color: #FF9661 !important;
  background-color: rgba(255, 150, 97, 0.2);
  
}

a.highHeatMark::before {
  color: #FFFFFF;
  background: #FF9661;
}

a.mediumHeatMark {
  outline-color: #FFD400 !important;
  background-color: rgba(255, 212, 0, 0.2);
}

a.mediumHeatMark::before {
  color: #263238;
  background: #FFD400;
}

a.lowHeatMark {
  outline-color: #757575 !important;
  background-color: rgba(117, 117, 117, 0.2);
}

a.lowHeatMark::before {
  color: #FFFFFF;
  background: #757575;
}
</style>`;

    }
}

