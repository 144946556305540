import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { ApiManagementService } from './apiManagement.service';
import { Subscription } from "rxjs";
import * as moment from 'moment';
import { SwitchButtonType } from "src/app/components/switchButton/switchButtonType.model";
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { UpdateApiUserPopupComponent } from "./UpdateApiUser/updateApiUserPopup.component";
import { OptiApiUser } from "./models/optiApiUser.model";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { TranslateService } from "@ngx-translate/core";
import { _ } from "@ag-grid-community/all-modules";
import { UtilsMethodsService } from "../../../services/utilsMethods.service";
import { WindowRefService } from "src/app/services/windowRef.service";
import { first } from "rxjs/operators";


@Component({
    selector: 'api-management',
    templateUrl: './apiManagement.component.html',
    styleUrls: ['./apiManagement.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApiManagementComponent implements OnInit, OnDestroy {

    public loading: boolean = true;
    public apiEndPointVal: string;
    public apiUsersData: OptiApiUser[] = [];
    private sortStatus;
    private subscriptionsArr: Subscription[] = [];
    public translateKeys = translateKeys;
    public switchButtonType: SwitchButtonType = SwitchButtonType.Small;
    public userProcessingIndex: number = null;

    constructor(private windowRefService: WindowRefService,
                private apiManagementService: ApiManagementService,
                private modalService: OptiLogicModalService,
                private translateService: TranslateService,
                private bsModalRef: BsModalRef, 
                private utilsMethodsService: UtilsMethodsService,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.loadData();
        this.subscriptionsArr.push(this.apiManagementService.getApiEndPoint().subscribe((response) => {
            this.apiEndPointVal = response;
        }));
    }

    public getRowNodeId(node) {
        return node.ID;
    }

    private isDatesValues(isDate: boolean, valueA: string, valueB: string) {
        if (isDate) {
            valueA = valueA.split('/').reverse().join('');
            valueB = valueB.split('/').reverse().join('');
        }
        return { valueA, valueB };
    }

    public sortBy(columnName, order): boolean {
        return this.sortStatus && this.sortStatus.columnName == columnName &&
            this.sortStatus.order == order;
    }

    public sortUsers(columnName) : void {
        this.updateSortStatus(columnName);
        this.apiUsersData.sort((user_1, user_2) => {
            var valueA: string = "";
            var valueB: string = "";
            var prop1 = user_1[columnName];
            var prop2 = user_2[columnName];
            ({ valueA , valueB } = (columnName == "LastLoginDate") 
                ? this.isDatesValues(true, prop1, prop2)
                : this.isDatesValues(false, prop1, prop2));
            var result = (this.sortStatus.order == 'asc') 
                ? this.utilsMethodsService.abSorting(valueA, valueB)
                : this.utilsMethodsService.abSorting(valueB, valueA);
            return result;
        });
    }

    private updateSortStatus(columnName): void {
        if (!this.sortStatus) {
            this.sortStatus = { columnName: columnName, order: 'asc' };
        } else {
            if (this.sortStatus.columnName != columnName) {
                this.sortStatus.order = 'asc';
            } else if (this.sortStatus.order == 'asc') {
                this.sortStatus.order = 'desc';
            } else {
                this.sortStatus.order = 'asc';
            }
            this.sortStatus.columnName = columnName;
        }
    }

    public goToAPIDocumentation(): void {
        this.windowRefService.nativeWindow.open('https://developer.optimove.com/', '_blank');
    }

    public convertToDatestring(inputDate, isTable): string {
        if (inputDate === '/Date(-62135596800000)/') {
            return '-';
        }
        return (isTable) 
            ? moment(inputDate).format('YYYY-MM-DD') 
            : moment(inputDate).format('DD/MM/YYYY hh:mm:ss'); // format date for the update modal
    }

    private loadData(): void {
        this.subscriptionsArr.push(this.apiManagementService.getApiUsers()
        .subscribe(existingApiUsers  => {
            this.apiUsersData = existingApiUsers.Data;
            this.loading = false;
            this.cd.detectChanges();
        } , (err) => console.log(err)));
    }

    private openFailedModal(user: OptiApiUser, title:string, errorMsg: string): void {
        this.modalService.openModalMessage(
            'sm',
            {
                title: title,
                message: errorMsg,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant('general.OK'),
                    action: () => this.bsModalRef.hide(),
                    dataQaId: 'errorUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
        user.Enabled = !user.Enabled;
        this.bsModalRef.hide();
        setTimeout (() => { this.userProcessingIndex = null; }, 300);
    }

    updateIsActive([event, user]: [boolean, OptiApiUser]) : void {
        this.userProcessingIndex = this.apiUsersData.indexOf(user, 0);
        user.Enabled = event;
        this.cd.detectChanges();

        this.apiManagementService.updateApiUser(user)
                .pipe( first() )
                .subscribe((response: AjaxResponse<string>) => {
                    (!response.isSuccess) ? this.failedToUpdateModal(user) : this.userProcessingIndex = null;

                    this.cd.detectChanges();
                }, (_) => this.failedToUpdateModal(user));
      }

    private failedToUpdateModal(user: OptiApiUser) : void {
        (user.Enabled === false)
            ? this.openFailedModal(user, this.translateService.instant(this.translateKeys.titleDeactivate), this.translateService.instant(this.translateKeys.errorDeactivateUser))
            : this.openFailedModal(user, this.translateService.instant(this.translateKeys.titleActivate), this.translateService.instant(this.translateKeys.errorActivateUser));
    }

    private getRangeDate(user: OptiApiUser): boolean {
        var date1 = new Date().getTime();
        var date2 = new Date(this.convertToDatestring(user.LastLoginDate, true)).getTime();
        var daysSinceLastLogin = ((date1 - date2) / (1000 * 60 * 60 * 24));
        // According to US: 154240 - If this user have been logged in in the past 32 days - 
        // this user will see the deactivate msg, otherwise, he will see the delete msg in the modal
        return daysSinceLastLogin <= 32;
    }

    openUpdateUserModal(user: OptiApiUser) : void {
        this.bsModalRef = this.modalService.open(
            UpdateApiUserPopupComponent, 
            "md", 
            <ModalOptions<any>>{ 
                ignoreBackdropClick: true,
                initialState: {
                    recommendToBlock: this.getRangeDate(user),
                    apiUser: user,
                    lastLogin: (user.LastLoginDate !== null) ? (this.convertToDatestring(user.LastLoginDate, false)) : "-"
                },
                class: 'update-api-user-popup'
        });

        this.bsModalRef.content.updateExistingApiUsersEvent.subscribe((res: OptiApiUser[]) => {
            this.apiUsersData = res;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        this.subscriptionsArr.forEach(sub => sub.unsubscribe());
    }
}

const translateKeys = {
      title: 'features.user_settings.body.apiManagement.TITLE',
      titleDeactivate: 'features.user_settings.body.apiManagement.updateApiUser.TITLE_DEACTIVATE',
      titleActivate: 'features.user_settings.body.apiManagement.updateApiUser.TITLE_ACTIVATE',
      apiEndPoint: 'features.user_settings.body.apiManagement.END_POINT',
      apiDocumentation: 'features.user_settings.body.apiManagement.API_DOCUMENTATION',
      colUserName: 'features.user_settings.body.apiManagement.TableColumns.COL_USERNAME',
      colContactEmail: 'features.user_settings.body.apiManagement.TableColumns.COL_CONTACT_EMAIL',
      colRole: 'features.user_settings.body.apiManagement.TableColumns.COL_ROLE',
      colLastLogin: 'features.user_settings.body.apiManagement.TableColumns.COL_LAST_LOGIN',
      colActive: 'features.user_settings.body.apiManagement.TableColumns.COL_ACTIVE',
      addNewApiUser: 'features.user_settings.body.apiManagement.ADD_NEW_API_USER',
      noUserConfigured: 'features.user_settings.body.apiManagement.emptyState.NO_USERS_CONFIGURED',
      addNewUser: 'features.user_settings.body.apiManagement.ADD_NEW_USER',
      processing: 'features.optibot.insight_card.PROCESSING',
      errorDeactivateUser: 'features.user_settings.body.apiManagement.updateApiUser.ERROR_DEACTIVATE_USER',
      errorActivateUser: 'features.user_settings.body.apiManagement.updateApiUser.ERROR_ACTIVATE_USER',
}
