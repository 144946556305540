<div class="toast-card" class="{{location}}" [ngClass]="{'with-close-icon': showCloseIcon, 'open': isOpen}">
	<div class="start" [ngSwitch]="toastIconType">
		<i class="material-icons toast-icon" *ngSwitchCase="ToastIconTypeEnum.AngularMaterial">{{icon}}</i>
		<img class="toast-icon" *ngSwitchCase="ToastIconTypeEnum.OptimoveIcon" [src]="icon">
		<span class="text">{{message}}</span>
	</div>
	<div class="end" *ngIf="actionText || showCloseIcon">
		<button  *ngIf="actionText" (click)="onActionBtnClicked()" class="btn btn-link btn-toast-action">{{actionText}}</button>
		<button *ngIf="showCloseIcon" (click)="onCloseBtnClicked()" class="btn btn-link btn-link-default btn-toast-close">
			<i class="material-icons toast-close-icon">close</i>
		</button>
	</div>
</div>