import { Component, Input, Output, OnInit, ChangeDetectorRef, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  SearchItem,
  SearchListConfig
} from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { IModalConf } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { IAttributeMetric, ICustomerAttribute } from "src/app/features/customer360/customer360.models"
import { Customers360ModalService } from 'src/app/features/customer360/services/customer360Modal.service';
import { Customer360DataService } from "src/app/features/customer360/services/customer360.data.service";

@Component({
  selector: 'change-attribute-modal',
  templateUrl: './changeAttributeModal.component.html',
  styleUrls: ['./changeAttributeModal.component.scss']
})
export class ChangeAttributeModal implements OnInit {
  @Input() defaultAttributeAliasName: string;
  @Output() onChangeAttribute: any;
  
  public form: FormGroup;
  public modalConf: IModalConf;
  public translateKeys = translateKeys;
  public attributesSearchItems: SearchItem[];
  public customerAttributes: { [key: string]: ICustomerAttribute };
  public attributeSearchListConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.attribute),
    keyProperty: "FieldName",
    valueProperty: "AliasName",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.attribute),
    isMultiSelect: false
  };
  public config = {
    translateKeys: translateKeys,
};

  constructor(private bsModalRef: BsModalRef, 
              private c360ModalService: Customers360ModalService,
              private dataService: Customer360DataService,
              private translate: TranslateService,
              private readonly cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.createForm();
    this.dataService.getCustomerAttributes().subscribe((customerAttributes: { [key: string]: ICustomerAttribute }) => {
      this.customerAttributes = customerAttributes;
      this.attributesSearchItems = this.c360ModalService.getAttributesSearchItems(customerAttributes);
      if (this.defaultAttributeAliasName) {
        let defaultAttribute = this.attributesSearchItems.find(x => x.AliasName.toLowerCase() === this.defaultAttributeAliasName.toLowerCase());
        if (defaultAttribute) {
          this.selectedAttribute.setValue([ defaultAttribute ]);
        }
      }
      this.setModalConf();
      this.cd.detectChanges();
    });
  }

  get selectedAttribute(): FormControl {
    return this.form.get('selectedAttribute') as FormControl;
  }

// =======| private region |======= \\

  private createForm() {
    this.form = new FormGroup({
      selectedAttribute: new FormControl('', Validators.required),
    });
  }

  private setModalConf(): void {
    this.modalConf = {
        title: "Change Attribute",
        xButton: {action: () => this.bsModalRef.hide()},
        buttons:[
          {
              isDisabled: () => this.selectedAttribute.value && this.selectedAttribute.value[0].AliasName.toLowerCase() === this.defaultAttributeAliasName.toLowerCase(),
              class: 'btn btn-primary',
              label: 'Change Attribute',
              action: this.onChangeAttributeBtnClick.bind(this),
          },
          {
              isDisabled: () => false,
              class: 'btn-default',
              label: 'Cancel',
              action: this.closeModal.bind(this),
          }
      ],
        withScale: false,
    };
  }

  private closeModal() {
    this.bsModalRef.hide()
  }

  private onChangeAttributeBtnClick() {
    let attribute = this.customerAttributes[this.selectedAttribute.value[0].FieldName];
    
    let attributeMetric: IAttributeMetric = {
      FieldName: this.selectedAttribute.value[0].FieldName,
      AliasName: this.selectedAttribute.value[0].AliasName,
      Format: attribute && attribute.Format ? attribute.Format : '0',
      DataType: attribute && attribute.DataType ? attribute.DataType : 'string',
    };

    this.onChangeAttribute(attributeMetric);
    this.closeModal();
  }
}

const translateKeys = {
  attribute: 'features.user_settings.body.attributes.addConditionAttribute.ATTRIBUTE',
  condition: 'features.user_settings.body.attributes.addConditionAttribute.CONDITION',
  value: 'features.user_settings.body.attributes.addConditionAttribute.VALUE',
  description: 'features.customer360.body.change_attribute_modal.DESCRIPTION'
}