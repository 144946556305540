import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { OptiApiKey } from "../models/optiApiKey.model";
import { SearchListConfig } from "src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { Integrations } from "../Roles/Integrations/integrations.component";
import { ApiKeyManagementService } from "../apiKeyManagement.service";
import { first } from "rxjs/operators";
import { Roles } from "../models/roles.model";
import { OslValueType } from "src/app/components/optiSearchList/models/oslValueType.enum";
import { DefaultClaims } from "../models/defaultClaims.model";
import { FeatureFlag, FeatureFlagService } from "src/app/services/featureFlag.service";

@Component({
    selector: 'api-key-details',
    templateUrl: './apiKeyDetails.component.html',
    styleUrls: ['./apiKeyDetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApiKeyDetails implements OnInit {

    @ViewChild('integrations', {static: false}) integrations: Integrations;

    public isWhenValuesHasOneOption: boolean;
    public apiRoles = [];
    public translateKeys = translateKeys;
    public apiRole: Roles;
    public roles = Roles;

    @Input() form: FormGroup;
    @Input() apiKeyExistsMsg: string;
    public dropdownOslConfig: SearchListConfig = {
        keyProperty: "RoleType",
        valueProperty: "RoleName",
        isMultiSelect: false,
        itemNameTranslateKey: "RoleName",
        valueType: OslValueType.Key
    };
    
    constructor(private apiKeyManagementService: ApiKeyManagementService,
        private featureFlagService: FeatureFlagService) {
    }

    ngOnInit(): void {
        this.apiKeyManagementService.getRoleTypesAsync()
            .pipe(first()).subscribe(async (roleTypes) => {
            this.apiRoles = await roleTypes.Data;
            this.roleFormControl.setValue(this.apiRoles[0].RoleType, 
                { emitEvent: true, emitModelToViewChange: true });
            this.isWhenValuesHasOneOption = this.isContainsOneItem(this.apiRoles);
        });
    }

    private isContainsOneItem(itemList: any[]) {
        return itemList.length < 2;
    }

    public selectedRoleChanged(currRole: number) {
        switch (currRole) {
            case Roles.Admin:
            case Roles.Transactional:
                this.form.controls.Claims.disable();
                this.form.controls.Claims.setValue(new DefaultClaims());
                this.form.controls.Claims.enable();
                break;
            case Roles.Integrations:
                this.form.controls.Claims.enable();
                break;
        }
        this.apiRole = currRole;
    }

    buildNewApiKey() : OptiApiKey {
        return this.form.value;
    }

    get nameFormControl(): FormControl {
        return this.form.get('Nickname') as FormControl;
    }

    get emailFormControl(): FormControl {
        return this.form.get('ContactEmail') as FormControl;
    }

    get roleFormControl(): FormControl {
        return this.form.get('RoleType') as FormControl;
    }
}

const translateKeys = {
    fullLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.FULL_LABEL',
    limitedLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.LIMITED_LABEL',
    nickNameLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_LABEL',
    contactEmailLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_LABEL',
    accessTypeLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.ACCESS_TYPE_LABEL',
    roleLabel: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.ROLE_LABEL',
    nickNamePlaceholder: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_PLACEHOLDER',
    nickNameErrorMsg: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_ERROR_MSG',
    nickNameCannotBeEmail: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_CANNOT_BE_EMAIL',
    nickNameLimitLength: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_LIMIT_LENGTH',
    nickNameNote: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_ERROR_MSG',
    nickNameMustBe: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.NICKNAME_MUST_BE',
    contactEmailPlaceholder: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_PLACEHOLDER',
    contactEmailErrorMsg: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_ERROR_MSG',
    contactEmailNote: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_NOTE',
    contactEmailValidTime: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_VALID_TIME',
    emailMustBe: 'features.user_settings.body.apiKeyManagement.apiKeyDetails.CONTACT_EMAIL_MUST_BE'
}