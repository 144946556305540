import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { BeefreeEditorService } from '../../../services/beefreeEditor.service';

@Component({
	selector: 'edit-row-modal',
	templateUrl: './editRowModal.component.html',
	styleUrls: ['./editRowModal.component.scss']
})
export class EditRowModalComponent implements OnInit {
	private readonly saveForAllOrForOne: string = '1';
	public customRowTitle = new FormControl('');
	public customRowOldTitle: string;

	@Input() rowData;
	@Input() resolve;

	constructor(
		private bsModalRef: BsModalRef,
		private beeFreeService: BeefreeEditorService,
		public translateService: TranslateService,
		private optiModalService: OptiLogicModalService
	) {}

	ngOnInit(): void {
		this.customRowOldTitle = this.rowData.name;
	}

	public editeRow(newRowTitle: string) {
		this.beeFreeService
			.isBeefreeRowNameAlreadyUsed({
				name: newRowTitle,
				brandId: this.rowData.metadata.brandId.toString(),
				saveForAllOrForOneFlag: this.rowData.metadata.saveForAllOrForOne.toString()
			})
			.subscribe((res) => {
				if (res !== 'OK') {
					this.createOverWriteModal();
				} else {
					this.bsModalRef.hide();
					this.saveEditedRow(newRowTitle);
				}
			});
	}

	public saveEditedRow(newRowTitle: string) {
		let newRowObj = this.rowData;
		newRowObj.name = newRowTitle;
		newRowObj.metadata.name = newRowTitle;
		this.beeFreeService
			.EditeCustomRow({ rowObject: this.customRowOldTitle, editedRow: JSON.stringify(newRowObj) })
			.toPromise()
			.then((res) => {
				if (res.isSuccess) {
					this.resolve(true);
				}
			});
	}

	public onCancel(): void {
		this.bsModalRef.hide();
	}

	public createOverWriteModal() {
		this.optiModalService.openModalMessage('sm', {
			message: this.translateService.instant('features.manage_templates.components.beefreeSavedRows.EXISTING_NAME_WARNING'),
			xButton: { action: () => this.bsModalRef.hide() },
			buttons: [
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-primary',
					label: this.translateService.instant('general.OVERWRITE'),
					action: () => {
						this.saveEditedRow(this.customRowTitle.value);
						this.bsModalRef.hide();
					}
				},
				{
					isDisabled: () => {
						return false;
					},
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						this.bsModalRef.hide();
					}
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		});
	}
}
