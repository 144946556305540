import {ModuleWithProviders, NgModule} from "@angular/core";
import {ModalModule} from "ngx-bootstrap/modal";
import {OptiLogicModalService} from "../components/optiLogicModal/optiLogicModal.service";
import { OptiModalComponent } from "../components/optiLogicModal/modals/optiModal/optiModal.component";
import { CommonModule } from "@angular/common";
import { OptiLogicModalComponent } from "../components/optiLogicModal/optiLogicModal.component";
import {GenericModalComponent} from '../components/optiLogicModal/modals/genericModal/genericModal.component';
import {SuccessFailModalComponent} from '../components/optiLogicModal/modals/successFailModal/successFailModal.component';
import {SharedModule} from '../shared.module';
import { IframeModalComponent } from "../components/optiLogicModal/modals/iframeModal/iframeModal.component";
import { PipesModule } from "../pipes/pipes.module";
import { IframeContainerComponent } from "../components/iframeContainer/iframeContainer.component";
import {GenericLogicModalComponent} from "../components/optiLogicModal/modals/genericLogicModal/genericLogicModal";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ModalModule.forRoot(),
        PipesModule,
    ],
    providers: [
        OptiLogicModalService,
    ],
    declarations: [
        OptiModalComponent,
        OptiLogicModalComponent,
        GenericModalComponent,
        GenericLogicModalComponent,
        SuccessFailModalComponent,
        IframeModalComponent,
        IframeContainerComponent
    ],
    exports: [
        OptiModalComponent,
        OptiLogicModalComponent,
        GenericModalComponent,
        GenericLogicModalComponent,
        SuccessFailModalComponent,
        IframeModalComponent,
        IframeContainerComponent
    ]
})

export class OptiModalModule {
    static forRoot() : ModuleWithProviders<OptiModalModule> {
        return({
            ngModule: OptiModalModule,
            providers: [
                OptiLogicModalService
            ]
        });     
    }
}