import { Component, Input, OnInit, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalConf } from './../../../../../components/optiLogicModal/optiLogicModal.service';
import { CampaignGridService } from '../campaign-grid.service';
import { first } from "rxjs/operators";
import { Router } from '@angular/router';
import { TemplateQueryParams } from './../../../../../features/manageTemplates/models/templateQueryParams';
import { AvailableIDSelectionValues } from 'src/app/features/manageTemplates/models/templatePreview';
import { Customer360TemplateService } from '../../../services/customer360.template.service';
import { TemplateDetails } from 'src/app/features/manageTemplates/models/templateDetails';
import { Buffer } from 'buffer';

@Component({
  selector: 'template-modal',
  templateUrl: './templateModal.component.html',
  styleUrls: ['./templateModal.component.scss']
})
export class TemplateModalComponent implements OnInit {
  @Input() templateId: string;
  @Input() clientCustomerId: string;
  @Input() campaignExecutionDate: string;

  public translateKeys = translateKeys;
  public modalConf: IModalConf;
  public showTemplateLink: boolean = false;
  public templateDetails: TemplateDetails;
  public personalizedTemplate: string;
  private templateParams: TemplateQueryParams = {};

  constructor(
    private bsModalRef: BsModalRef, 
    private campaignGridService: CampaignGridService,
    private router: Router,
    private templateService: Customer360TemplateService
  ) {
    const templatesUseNewEditoFF = true;
    this.campaignGridService.getUserPermission('Manage Templates').pipe(first()).subscribe(permission => {
      this.showTemplateLink = permission.isEnabled && !templatesUseNewEditoFF;
    });
  }

  async ngOnInit() {
    this.setModalConf();
    await this.getTemplate();
  }

  private setModalConf(): void {
    this.modalConf = {
        xButton: {action: () => this.bsModalRef.hide()},
        withScale: false,
        hideFooterPanel: true,
        disableScroll: true,
    };
  }

  private getTemplate(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.templateId) {
        this.campaignGridService.getTemplateById(this.templateId).pipe(first()).subscribe(async res => {
          this.templateDetails = res.data;
          this.templateParams.templateId = this.templateId;
          this.templateParams.channelId = `${this.templateDetails.executionMethodId}`;

          let subMethodType = this.templateDetails.subMethodId;
          let templateJson = JSON.stringify(this.templateDetails);
          let idSelectionValue = AvailableIDSelectionValues.ExplicitId;
          let containsNonVisitorTags = await this.templateService.doesTemplateContainNonVisitorTags(this.templateDetails);

          this.campaignGridService.getPersonalizedTemplate(this.clientCustomerId, this.campaignExecutionDate, this.templateDetails.executionMethodId, subMethodType, templateJson, idSelectionValue, containsNonVisitorTags)
            .pipe(first()).subscribe(res => {
              this.personalizedTemplate = Buffer.from(res.Data, 'base64').toString();
              resolve();
            });
        });
      } 
      else {
        resolve();
      }
    });
  }

  public goToTemplate() {
    this.router.navigate([`/category/One-to-One_Campaigns/manage-templates/optimail`], {
      queryParams: this.templateParams,
    });
  }
}

const translateKeys = {
  TEMPLATE_LINK: "features.customer360.body.campaign_list.GO_TO_TEMPLATE",
}