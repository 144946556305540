import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output, Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator, Validators
} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {AppConstant} from "../../app.constatnt";
import * as moment from 'moment';
import {
    BsDaterangepickerConfig,
    BsDaterangepickerDirective, BsDaterangepickerInputDirective
} from "ngx-bootstrap/datepicker";
import {QuickSelectionDateMode} from "./quickSelectionDateMode.enum";
import {DateRangeInvalidStateMode} from "./validators/dateRangeInvalidStateMode.enum";
import {Subscription} from "rxjs";
import {validateDates} from "./validators/validateDatesValidators.diretive";
import {validateFirstDateSmallerThanSecondValidator} from "./validators/validateFirstDateSmallerThanSecondValidator.directive";
import { dateRangeMinDateValidator } from './validators/minDateValidator.directive';
import { dateRangeMaxDateValidator } from './validators/maxDateValidator.directive';

@Component({
    selector: 'date-range-picker',
    templateUrl: './dateRangePicker.component.html',
    styleUrls: ['./dateRangePicker.component.scss', '../datePicker/datePicker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerComponent),
        multi: true
    },{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateRangePickerComponent),
        multi: true
    }]
})

export class DateRangePickerComponent implements ControlValueAccessor, OnInit, OnChanges, Validator {
    private onTouchedCallback: () => void;
    private onChangeCallback: (_: any) => void;
    private valueChangeSubscription: Subscription;

    @ViewChild('dateRangePicker', {static: false}) dateRangePicker;
    @ViewChild('drp', {static: false}) dateRangePickerPopup: BsDaterangepickerDirective;

    @Input() shouldShowCustomTimeRange: boolean;
    @Input() hideValidation? = false;
    @Input() isEndDateOnlyMode = false;
    @Input() readonly = null;
    @Input() icon: string = 'today';
    @Input() className = null;
    @Input() dateRangePickerData?: IDateRangePicker;
    @Input() isValidationDisabled = false;
    @Output() dateRangePickerDataChange?: EventEmitter<Date[]> = new EventEmitter<Date[]>();
    @Output() dateRangeSelectionMode?: EventEmitter<QuickSelectionDateMode> = new EventEmitter<QuickSelectionDateMode>();

    public formattedDate: string | null = null;
    public inputControl: FormControl;

    public isQuickSelectEnabled = false;
    public quickSelectPrevButtonTitle = "";
    public quickSelectNextButtonTitle = "";
    public selectionDateMode: QuickSelectionDateMode;
    public invalidDateStateModes = DateRangeInvalidStateMode;
    public manualValidationState: ValidationErrors | null;
    public firstErrorKey: DateRangeInvalidStateMode;

    private inputText: string = "";
    private backupNgxValidate: any;

    public bsConfig: Partial<BsDaterangepickerConfig> = {
        containerClass: 'theme-blue',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: false,
        adaptivePosition: false
    };

    public get dates(): Date[] {
        return this.inputControl.value;
    }

    private setupInputControl(){
        if (this.inputControl){
            return;
        }
        this.inputControl = new FormControl(this.dateRangePickerData?.date, []);
    }

    private get quickSelectionTypeString(): {QuickSelectionDateMode: string} {
        let dict = {};
        dict[QuickSelectionDateMode.Week] = "week";
        dict[QuickSelectionDateMode.Month] = "month";
        dict[QuickSelectionDateMode.Custom] = "custom";
        return dict as {QuickSelectionDateMode: string};
    }

    private getDaysOfTheWeek(date: Date){
        // thanks - https://stackoverflow.com/a/43751748
        let currentWeekDay = date.getDay();
        let lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;

        let daysOfTheWeek = [];
        let firstDate = new Date(new Date(date).setDate(date.getDate() - lessDays));

        for (let i = 0; i < 7; i++) {
            let newDate = new Date(new Date(firstDate).setDate(firstDate.getDate() + i));
            daysOfTheWeek.push(newDate);
        }
        return daysOfTheWeek;
    }

    private getWeekRange(offset: number, fromDate: Date = new Date()): Date[]{
        const offsetDate = new Date(fromDate.getTime());
        offsetDate.setDate(offsetDate.getDate() + offset);
        offsetDate.setHours(0, 0, 0, 0);
        let weekDays = this.getDaysOfTheWeek(offsetDate);
        return [weekDays[0], weekDays[weekDays.length-1]];
    }

    private getMonthRange(offset: number, date: Date = new Date()): Date[]{
        // stupid inconsistency in js requires adding an offset of 1 to month when checking for number of days in month
        let daysInMonth = new Date(date.getFullYear(), date.getMonth() + offset + 1, 0).getDate();
        let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() + offset, 1);
        let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + offset, daysInMonth);
        return [firstDayOfMonth, lastDayOfMonth];
    }

    private setRangeQuickSelect() {
        let weekRange =
                [
                    {
                        value: this.getWeekRange(0),
                        label: this.translate.instant('general.DATE_RANGE_PICKER.this') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.week')
                    },
                    {
                        value: this.getWeekRange(+7),
                        label: this.translate.instant('general.DATE_RANGE_PICKER.next') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.week')
                    },
                    {
                        value: this.getWeekRange(-7),
                        label: this.translate.instant('general.DATE_RANGE_PICKER.last') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.week')
                    },
                ];

            let monthRange = [
                {
                    value: this.getMonthRange(0),
                    label: this.translate.instant('general.DATE_RANGE_PICKER.this') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.month')
                },
                {
                    value: this.getMonthRange(+1),
                    label: this.translate.instant('general.DATE_RANGE_PICKER.next') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.month')
                },
                {
                    value: this.getMonthRange(-1),
                    label: this.translate.instant('general.DATE_RANGE_PICKER.last') + ' ' + this.translate.instant('general.DATE_RANGE_PICKER.month')
                },
            ];
            let allCustomRanges = [...weekRange, ...monthRange];
            this.bsConfig.ranges = allCustomRanges;
    }

    constructor(private translate: TranslateService,
                private changeDetectorRef: ChangeDetectorRef,
                private renderer: Renderer2) {

    }

    opened(){
        setTimeout(() => {
            //@ts-ignore
            let positionService = this.dateRangePickerPopup._datepickerRef.instance._positionService;
            this.fixDateRangePickerPositionService(this.dateRangePickerPopup, positionService);
        });
    }


    // we've got our own logic for dateRangePicker validation
    overrideDefaultPickerValidation(): any {
        let backupValidate = BsDaterangepickerInputDirective.prototype.validate;
        BsDaterangepickerInputDirective.prototype.validate = function(c: AbstractControl): ValidationErrors | null {
            this.writeValue(c.value);
            return null;
        };
        return backupValidate;
    }

    markAsTouched() {
        this.inputControl.markAsTouched();
    }

    // You might be asking yourself WTF, right? well this solves the following bug: https://mobius.visualstudio.com/Backstage/_workitems/edit/60279
    // It's caused in pages that include both datePickers and tooltips. there's a bug in ngx that shares the options for
    // the positioning service between the two components and overrides the component's default options with one another's.
    // This way we make sure datePicker isn't rendering based on features it doesn't support. We also make sure tooltip
    // will still enjoy the positioning feature as normal.
    fixDateRangePickerPositionService(dateRangePickerPopup: any, positionService: any) {
        positionService.positionElements.forEach = (func) => {
            let iterator = positionService.positionElements.values();
            let value = iterator.next().value;
            while (value){
                let backupOptions = Object.assign({}, positionService.options);
                let options = positionService.options;
                if (value.target === dateRangePickerPopup._elementRef && options && options.modifiers){
                    delete options.modifiers.preventOverflow;
                }
                if (value.target === dateRangePickerPopup._elementRef && !this.bsConfig.adaptivePosition && options && options.modifiers) {
                    delete options.modifiers.flip;
                }
                positionService.setOptions(options);
                func(value);
                positionService.setOptions(backupOptions);
                value = iterator.next().value;
            }
        };
    }

    private setControlValue(dates: Date[]){
        this.inputControl.setValue(dates, { emitEvent: false });
        this.selectionDateMode = this.getQuickSelectionType();
        this.updateQuickSelectionButtons();
    }

    writeValue(dates: Date[]): void {
        this.setupInputControl();

        if (dates == null) {
            dates = [];
        }
        // initial setup
        this.setControlValue(dates);
        this.dateRangePickerData = { ...this.dateRangePickerData, date: dates};
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateRangePickerData?.currentValue !== changes.dateRangePickerData?.previousValue) {
            this.mergeDefaults();
        }
    }

    onblur() {
        this.onTouchedCallback();
    }

    onInputChange(event: Event): void {
        let inputText = (event as any).target?.value;
        this.inputText = inputText;
    }

    onValueChange(dates: Date[] | null): Date[] | null {
        if (this.inputText){
            dates = this.parseDates(this.inputText);
        }

        if (dates && dates.length === 2) {
            let firstDate = dates[0];
            let lastDate = dates[1];
            const minDate = this.dateRangePickerData?.minDate;
            const maxDate = this.dateRangePickerData?.maxDate;

            if (minDate && moment(firstDate).isBefore(minDate, 'day')) {
                firstDate = new Date(minDate.getTime());
                firstDate.setHours(0, 0, 0, 0);
                this.inputControl.setValidators = null;

            }
            if (maxDate && moment(lastDate).isAfter(maxDate, 'day')) {
                lastDate = new Date(maxDate.getTime());
                lastDate.setHours(0, 0, 0, 0);
            }
            if (firstDate !== dates[0] && lastDate !== dates[1]) {
                this.setControlValue([firstDate, lastDate]);
            }
        }


        let dummyControl = new FormControl();
        let valueToValidate = dates ? dates : this.inputText;
        dummyControl.setValue(valueToValidate);
        this.manualValidationState = this.validate(dummyControl);
        if (this.manualValidationState !== null) {
            const manualValidationKeys = Object.keys(this.manualValidationState);
            this.firstErrorKey = manualValidationKeys[0] as DateRangeInvalidStateMode;
        }
        else {
            this.firstErrorKey = null;
        }

        if(dates === null) {
            dates = [];
        }

        if (!this.manualValidationState) {
            this.setControlValue(dates);
            this.dateRangePickerDataChange.emit(dates);
        } else {
            this.isQuickSelectEnabled = false;
        }
        this.onChangeCallback && this.onChangeCallback(dates);

        setTimeout(() => {
            this.formattedDateView();
            this.inputText = null;
        }, 0);

        return dates;
    }

    back(): void {
        this.quickSelect(-1);
    }

    forward(): void {
        this.quickSelect(1);
    }

    private quickSelect(direction: number){
        let type = this.getQuickSelectionType();
        let offset = this.getQuickRangeOffset(type, direction);
        let rangeSelector = this.getQuickRangePickerForMode(type);
        let newDate = rangeSelector(offset, this.inputControl.value[0]);
        if (newDate){
            this.setControlValue(newDate);
            this.changeDetectorRef.detectChanges();
            this.dateRangePickerDataChange.emit(newDate);
        }
    }

    private getQuickRangeOffset(mode: QuickSelectionDateMode, direction: number): number {
        switch (mode) {
            case QuickSelectionDateMode.Custom:
                return 0;
            case QuickSelectionDateMode.Week:
                return direction * 7;
            case QuickSelectionDateMode.Month:
                return direction;
        }
    }

    private getQuickRangePickerForMode(mode: QuickSelectionDateMode): any {
        switch (mode) {
            case QuickSelectionDateMode.Custom:
                return (() => {}); // noop
            case QuickSelectionDateMode.Week:
                return this.getWeekRange.bind(this);
            case QuickSelectionDateMode.Month:
                return this.getMonthRange.bind(this);
        }
    }

    private getQuickSelectTitleForDirection(direction: number): string {
        let type = this.getQuickSelectionType();
        if (type === QuickSelectionDateMode.Custom) {
            return "";
        }
        let directionString = direction === -1 ? "previous" : "following";
        let typeString = this.quickSelectionTypeString[type];
        let translateDirectionKey = `general.DATE_RANGE_PICKER.${directionString}`;
        let translateTypeKey = `general.DATE_RANGE_PICKER.${typeString}`;
        let translatedWord = this.translate.instant(translateDirectionKey) + ' ' + this.translate.instant(translateTypeKey);
        return translatedWord;
    }

    public getQuickSelectionType(): QuickSelectionDateMode {
        let date = this.inputControl.value;
        if (!date || !Array.isArray(date) || date.length != 2) {
            return QuickSelectionDateMode.Custom;
        }
        let firstDate = date[0];
        let lastDate = date[1];

        let weekRange = this.getWeekRange(0, firstDate);
        let monthRange = this.getMonthRange(0, firstDate);

        if (this.compareOnlyDate(weekRange[0], firstDate) && this.compareOnlyDate(weekRange[1], lastDate)) {
            return QuickSelectionDateMode.Week;
        }
        if (this.compareOnlyDate(monthRange[0], firstDate) && this.compareOnlyDate(monthRange[1], lastDate)) {
            return QuickSelectionDateMode.Month;
        }
        return QuickSelectionDateMode.Custom;
    }

    private compareOnlyDate(date1: Date, date2: Date): boolean {
        return date1.toDateString() == date2.toDateString();
    }

    private mergeDefaults(): void {
        const defaults: any = {
            date:[],
            placement: 'bottom left',
            placeholder: 'Select date range',
            container: 'div.page-container',
            isDisabled: false,
            minDate: null,
            maxDate: null
        };

        this.dateRangePickerData = Object.assign({}, defaults, this.dateRangePickerData);
        this.writeValue(this.dateRangePickerData.date);

        setTimeout(() => {
            this.formattedDateView();
        }, 0);
    }

    updateQuickSelectionButtons() {
        if (this.shouldShowCustomTimeRange) {
            this.isQuickSelectEnabled = this.getQuickSelectionType() !== QuickSelectionDateMode.Custom;
            this.quickSelectPrevButtonTitle = this.getQuickSelectTitleForDirection(-1);
            this.quickSelectNextButtonTitle = this.getQuickSelectTitleForDirection(1);
        }
    }

    private formattedDateView(): void {
        let dates = this.inputControl.value;
        if (this.inputText) {
            this.formattedDate = this.inputText;
        } else if(dates && dates.length) {
            let startDate: string  = moment(dates[0]).format(AppConstant.DATE_FORMATS.display);
            let endDate: string  = moment(dates[1]).format(AppConstant.DATE_FORMATS.display);
            this.formattedDate = startDate + ' - ' + endDate;
        }
    }

    private parseDates(str: string): Date[] | null {
        let theDates: string[] = str.split('-');

        if (theDates.length > 2) { //case xxx-xx-xx - xxx-xx-xx
            theDates = str.split(' - ');
        }

        if (theDates.length === 2) { //case date1 - date2
            let startDate: Date = moment.utc(theDates[0]).toDate();
            let endDate: Date = moment.utc(theDates[1]).toDate();
            return [startDate, endDate];
        }
        return null;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    ngOnInit(): void {
        if (this.shouldShowCustomTimeRange) {
            this.setRangeQuickSelect();
        }
        this.setupInputControl();
        this.dateRangeSelectionMode.emit(this.getQuickSelectionType());
        this.valueChangeSubscription = this.inputControl.valueChanges.subscribe(this.onValueChange.bind(this));
        this.backupNgxValidate = this.overrideDefaultPickerValidation();
    }

    ngOnDestroy(): void {
        this.valueChangeSubscription && this.valueChangeSubscription.unsubscribe();
        BsDaterangepickerInputDirective.prototype.validate = this.backupNgxValidate;
    }

    validate(c: FormControl): ValidationErrors | null {
        if(this.isValidationDisabled) {
            return null;
        }
        const emptyFieldValidation = {};
        emptyFieldValidation[DateRangeInvalidStateMode.EmptyField] = "true";

        let result = { ...validateDates(c), ...validateFirstDateSmallerThanSecondValidator(c) };
        
        if (this.dateRangePickerData.minDate && !this.isEndDateOnlyMode) {
            const validateMinDate = dateRangeMinDateValidator(this.dateRangePickerData.minDate);
            result = { ...result,  ...validateMinDate(c) };
        }

        if (this.dateRangePickerData.maxDate) {
            const validateMaxDate = dateRangeMaxDateValidator(this.dateRangePickerData.maxDate);
            result = { ...result,  ...validateMaxDate(c) };
        }

        if (Validators.required(c)) {
            result =  {... result, ...emptyFieldValidation};
        }

        return Object.keys(result).length === 0 ? null : result;
    }

    public get placement(): 'top' | 'bottom' | 'left' | 'right' | 'top left' |  'top right' | 'bottom left' |  'bottom right' {
        return this.dateRangePickerData?.placement || 'bottom left';
    }
}

export interface IDateRangePicker {
    date: Date[] | null,
    minDate?: Date,
    maxDate?: Date,
    placeholder?: string,
    isDisabled?: boolean,
    placement?: 'top' | 'bottom' | 'left' | 'right' | 'top left' |  'top right' | 'bottom left' |  'bottom right',
    container?: string
}
