<div class="move-template-modal" data-qa-id="move-template-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <move-lists-component
      (selectedFolderIdEmitter)="setFolderId($event)"
      (selectedSubMethodIdEmitter)="setSubMethodId($event)"
      [subMethodsDisabled]="true"
      [subMethodId]="selectedSubMethodId"
      [clickedFolderId]="selectedFolderId"></move-lists-component>
  </opti-logic-modal>
</div>
