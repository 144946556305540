import { AllCommunityModules, AllEnterpriseModules, CellClickedEvent, GridApi, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AjaxResponse, IconType } from '@optimove/ui-sdk/common/models';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { ToastModel } from 'src/app/features/missionControl/models/toast.model';
import { locationParameter } from 'src/app/models/locationParameter.enum';
import { IInputEditParams } from '../../../../../components/inputEdit/inputEdit.component';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { PersonalizationTagsService } from '../../../../../components/personalizationTagsContainer/personalizationTags.service';
import { PersonalizationTagsModalComponent } from '../../../../../components/personalizationTagsContainer/personalizationTagsModal/personalizationTagsModal.component';
import { ContentTag, ContentTagValidationErrors } from '../../../models/contentTag';
import { TemplateDetails } from '../../../models/templateDetails';
import { TemplateQueryParams } from '../../../models/templateQueryParams';
import { SuppressionTemplate, UnsubscribeMultiLanguageTemplate } from '../../../models/unsubscribeMultiLanguageTemplate';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { SubMethodService } from '../../../services/subMethodService';
import { TemplateContextService } from '../../../services/templateContext.service';
import { InsertLinkModalComponent } from '../insertLinkModal/insertLinkModal.component';
import { UpdateContentTagModalComponent } from '../updateContentTagModal/updateContentTagModal.component';

@Component({
	selector: 'content-tag-modal',
	templateUrl: './contentTagModal.component.html',
	styleUrls: ['./contentTagModal.component.scss']
})
export class ContentTagModalComponent implements OnInit {
	@ViewChild('unsubscribeoverlay') unsubscribeOverlayElement: ElementRef<HTMLInputElement>;
	@ViewChild('contenttagname')
	contenttagnameRef: any;

	public name = '';
	public content = '';
	public template: TemplateDetails;
	public inputEditParams: IInputEditParams = {
		inputType: 'text',
		isEditMode: true,
		requiredErrMsg: '',
		minLengthErrorMsg: '',
		maxLengthErrorMsg: ''
	};
	public toastParams: ToastModel = {
		isOpen: false,
		message: '',
		icon: '',
		showCloseIcon: true,
		location: locationParameter.Default,
		toastIconType: IconType.AngularMaterial
	};
	public isShowingForm = false;
	public modules: Module[] = [...AllCommunityModules, ...AllEnterpriseModules];
	private gridApi1: GridApi;
	public isEditModeContentTag: boolean;
	public isContentTagsLoading: boolean;
	public isCreateNew: boolean;
	public isTagOnSave: boolean;
	public chosenRow: ContentTag;
	public contentTags: ContentTag[];
	public queryParams: TemplateQueryParams;
	public subMethodId: number;
	public isAdmin: boolean;
	public modalRef;
	unsubscribeTags: UnsubscribeMultiLanguageTemplate[] = [];
	showSubscriptionOverlay: boolean;

	onChosen: (tag: string) => void;
	onClose: () => void;
	public innerHtml;
	public readonly contentPrefix: string = 'CONTENT:';

	constructor(
		public bsModalRef: BsModalRef,
		private translate: TranslateService,
		private manageTemplatesService: ManageTemplatesService,
		private modalService: OptiLogicModalService,
		private templateContextService: TemplateContextService,
		private subMethodService: SubMethodService,
		private personalizationTagsService: PersonalizationTagsService
	) {
		this.gridOptions1.getRowStyle = function (params) {
			if (this.chosenRow && this.chosenRow.Name === params.data.Name) {
				return {
					'background-color': '#FAFAFA',
					'pointer-events': 'none',
					width: '291px',
					'padding-left': '25px',
					display: 'flex',
					'align-items': 'center'
				};
			}
			return null;
		}.bind(this);
	}
	isSaveButtonDisabled() {
		return (
			!(this.content.length > 0 && this.name.length > 0 && this.content.length < 100000 && this.name.length < 40) ||
			(this.isCreateNew && this.contenttagnameRef.errors?.nameUnique) ||
			this.isTagOnSave
		);
	}
	ngOnInit(): void {
		const tenantDetails = JSON.parse(sessionStorage.getItem('tenantDetails'));
		this.isAdmin = tenantDetails?.userRole?.includes('Admin');
		this.templateContextService.queryTemplateParams.subscribe((params) => {
			if (params.folderId) {
				this.subMethodId = +params.typeId;
			}
			this.manageTemplatesService
				.getAggregatedUnsubscribeMultiLanguageTemplates(this.subMethodId)
				.subscribe((unsubscribeTemplates) => (this.unsubscribeTags = unsubscribeTemplates));
		});
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.unsubscribeOverlayElement && !this.unsubscribeOverlayElement.nativeElement.contains(event.target)) {
			this.showSubscriptionOverlay = false;
		}
	}

	public getColumnDefs() {
		return [
			{
				headerName: 'Content tags',
				field: 'Name',
				initialHide: false,
				sortable: true,
				cellStyle: {
					font: 'normal normal normal 12px/16px Roboto',
					'letter-spacing': '0px',
					color: '#37474F',
					opacity: '1',
					'margin-left': '24px',
					'margin-top': '16px',
					width: '248px'
				}
			}
		];
	}

	public showTagContent() {
		let doc = document.createElement('textarea');
		doc.innerText = this.content;
		doc.innerHTML = this.content;
		this.innerHtml = (doc as HTMLTextAreaElement).value;
	}

	public setContentTag(contentTag: ContentTag) {
		this.chosenRow = contentTag;
		if (this.chosenRow) {
			this.name = this.chosenRow.Name;
			this.content = this.chosenRow.Content;
		}
		this.gridApi1.redrawRows();
		this.showTagContent();
	}

	public gridOptions1 = <GridOptions>{
		columnDefs: this.getColumnDefs(),
		onCellClicked: (event: CellClickedEvent) => {
			if (!this.isEditModeContentTag) {
				this.setContentTag(event.data);
			} else {
				this.showCancelWarning(event.data);
			}
		},
		rowStyle: {
			width: '291px',
			'padding-left': '25px',
			display: 'flex',
			'align-items': 'center'
		},
		loadingCellRenderer: '<opti-loading [show]="true" [size]="\'md\'"></opti-loading>',
		overlayNoRowsTemplate: '<span></span>',
		headerHeight: 0,
		rowHeight: 32,
		animateRows: false,
		domLayout: 'autoHeight',
		rowSelection: 'single',
		suppressHorizontalScroll: true,
		onGridReady: (params) => {
			this.gridApi1 = params.api;
			this.createContentTagsSource();
		},
		getRowNodeId(data) {
			return data.id;
		}
	};

	public createContentTagsSource(): any {
		this.isContentTagsLoading = true;
		this.manageTemplatesService.getContentTags().subscribe((contentTags) => {
			if (contentTags) {
				this.contentTags = contentTags;
				this.contentTags.sort((a, b) => a.Name.localeCompare(b.Name));
				this.gridApi1.setRowData(this.contentTags);
				this.setChosenTag();
			} else {
				this.onCloseClick();
				this.openErrorFetchModal();
			}
			this.isContentTagsLoading = false;
		});
	}

	public openErrorFetchModal() {
		this.showErrorModal(
			'features.manage_templates.optimail.contentTags.NO_CONTENT_TAGS_FOUND',
			'features.manage_templates.optimail.contentTags.NOT_FOUND_ERROR'
		);
	}
	public setChosenTag() {
		if (!this.chosenRow) {
			this.chosenRow = this.contentTags[0];
		}
		if (this.chosenRow) {
			this.name = this.chosenRow.Name;
			this.content = this.chosenRow.Content;
		}
		this.gridApi1.redrawRows();
		this.showTagContent();
	}

	public cancelClicked() {
		this.showCancelWarning(null);
	}

	public showEditWarning() {
		this.modalService.open(UpdateContentTagModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: false,
			keyboard: true,
			initialState: {
				onSave: this.updateAction.bind(this),
				onCancel: () => this.isTagOnSave = false
			},
			class: 'stop-stream-running-modal'
		});
	}

	public showCloseWarning() {
		let modalRef = this.modalService.openModalMessage(
			'sm',
			{
				message: this.translate.instant('features.manage_templates.optimail.contentTags.CHANGES_NOT_SAVED'),
				buttons: [
					{
						isDisabled: () => false,
						isLoading: () => {},
						loadingText: null,
						class: 'btn-primary',
						label: this.translate.instant('features.manage_templates.optimail.contentTags.CONTINUE_EDITING'),
						action: () => {
							modalRef.hide();
						}
					},
					{
						isDisabled: () => false,
						class: 'btn-default',
						label: this.translate.instant('general.CANCEL'),
						action: () => {
							modalRef.hide();
							this.onClose();
						}
					}
				]
			},
			<ModalOptions<any>>{
				ignoreBackdropClick: true
			}
		);
	}
	public showCancelWarning(chosenRow: ContentTag | null) {
		let modalRef = this.modalService.openModalMessage(
			'sm',
			{
				message: this.translate.instant('features.manage_templates.optimail.contentTags.CHANGES_NOT_SAVED'),
				buttons: [
					{
						isDisabled: () => false,
						isLoading: () => {},
						loadingText: null,
						class: 'btn-primary',
						label: this.translate.instant('features.manage_templates.optimail.contentTags.CONTINUE_EDITING'),
						action: () => {
							modalRef.hide();
						}
					},
					{
						isDisabled: () => false,
						class: 'btn-default',
						label: this.translate.instant('general.DISCARD_CHANGES'),
						action: () => {
							modalRef.hide();
							if (!chosenRow) {
								this.setChosenTag();
							} else {
								this.setContentTag(chosenRow);
							}
							this.isEditModeContentTag = false;
							this.isCreateNew = false;
						}
					}
				]
			},
			<ModalOptions<any>>{
				ignoreBackdropClick: true
			}
		);
	}

	public editContent() {
		this.isEditModeContentTag = true;
	}

	public createNewClicked() {
		this.isCreateNew = true;
		this.isEditModeContentTag = true;
		this.name = '';
		this.content = '';
	}

	public personalizationTagsClick() {
		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			this.modalRef = this.modalService.open(PersonalizationTagsModalComponent, 'md', <ModalOptions<any>>{
				initialState: {
					submethodType: this.subMethodService.findSubmethodType(metadata, this.subMethodId),
					onTagSelected: (tag: string) => {
						const currentLocation = (document.getElementById('textarea-content') as HTMLTextAreaElement).selectionStart;
						this.content = [this.content.slice(0, currentLocation), tag, this.content.slice(currentLocation)].join('');
						this.modalRef?.hide();
					}
				}
			});
		});
	}

	public insertLinkClick() {
		const linkInserted = new EventEmitter<string>();
		let modalRef;
		linkInserted.pipe(first()).subscribe((link: string) => {
			const currentLocation = (document.getElementById('textarea-content') as HTMLTextAreaElement).selectionStart;
			this.content = [this.content.slice(0, currentLocation), link, this.content.slice(currentLocation)].join('');
			modalRef?.hide();
		});
		modalRef = this.modalService.open(InsertLinkModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: { linkEvent: linkInserted }
		});
	}

	public saveTagClicked() {
		this.isTagOnSave = true;
		if (this.isCreateNew) {
			this.createNewAction();
		} else this.showEditWarning();
	}

	private updateAction() {
		let tag = this.createTagObject(this.chosenRow.Id);
		this.manageTemplatesService.updateContentTag(tag).subscribe((response) => {
			this.isTagOnSave = false;
			const errorResponse = (response as AjaxResponse<string>);
			if (errorResponse?.isSuccess === false) {
				const validationErrors = this.getValidationErrors(errorResponse);
				if (validationErrors)
					this.showSaveValidationErrorsModal(validationErrors);
				else
					this.showErrorModal(
						'features.manage_templates.optimail.contentTags.SAVE_CONTENT_TAG',
						'features.manage_templates.optimail.contentTags.ERROR_SAVE_EXISTING'
					);
				return;
			}
			
			this.isTagOnSave = false;
			this.chosenRow.Content = this.content;
			this.isEditModeContentTag = false;
			this.createContentTagsSource();
			this.showToast('features.manage_templates.optimail.contentTags.CHANGES_SAVED');
		});
	}

	public numberFormatWithComma() {
		return this.content.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	private createNewAction() {
		let tag = this.createTagObject(null);
		this.manageTemplatesService.createContentTag(tag).subscribe((response) => {
			this.isTagOnSave = false;

			const errorResponse = (response as AjaxResponse<string>);
			if (errorResponse?.isSuccess === false) {
				const validationErrors = this.getValidationErrors(errorResponse);
				if (validationErrors)
					this.showSaveValidationErrorsModal(validationErrors);
				else
					this.showErrorModal(
						'features.manage_templates.optimail.contentTags.NEW_CONTENT_TAG',
						'features.manage_templates.optimail.contentTags.ERROR_SAVE_NEW'
					);
				return;
			}

			const contentTag = response as ContentTag;
			if (!this.chosenRow && contentTag) {
				this.chosenRow = tag;
			}
			if (contentTag) {
				this.chosenRow.Content = this.content;
				this.chosenRow.Name = this.name;
				this.chosenRow.Id = (contentTag as any).id;
				this.isEditModeContentTag = false;
				this.createContentTagsSource();
				this.isCreateNew = false;
				this.showToast('features.manage_templates.optimail.contentTags.TAG_SAVED');
			}
		});
	}

	private getValidationErrors(response: AjaxResponse<string>): string[] {
		const validationResponse = response.data ? JSON.parse(response?.data) as ContentTagValidationErrors : null;
		return validationResponse?.errors;
	}

	private createTagObject(id: string): ContentTag {
		return {
			Name: this.name,
			Content: this.content,
			SupportedChannels: [15],
			TenantId: null,
			Id: id,
			CreatedAt: '',
			CreatedBy: '',
			UpdatedAt: '',
			UpdatedBy: '',
			IsDeleted: false
		};
	}

	selectTag() {
		if (this.chosenRow) {
			this.onChosen(`[%${this.contentPrefix}${this.chosenRow.Name}%]`);
		}
	}

	public onCloseClick() {
		if (!this.isEditModeContentTag) {
			this.onClose();
		} else {
			this.showCloseWarning();
		}
	}

	unsubscribeTagSelected(selectedTag: SuppressionTemplate): void {
		this.showSubscriptionOverlay = false;
		var temporalDivElement = document.createElement('div');
		temporalDivElement.innerHTML = selectedTag.value;
		const lbl = temporalDivElement.textContent;
		let hrefLink = lbl;
		const hrefLinks = selectedTag.value.match('hrefs*=s*["\'\'](.*?)["\']');
		if (hrefLinks != undefined) {
			hrefLink = hrefLinks[1];
		}

		const currentLocation = (document.getElementById('textarea-content') as HTMLTextAreaElement).selectionStart;
		const link = `<a href="${hrefLink}">${lbl}</a>`;
		this.content = [this.content.slice(0, currentLocation), link, this.content.slice(currentLocation)].join('');
	}

	private showErrorModal(title: string, message: string): void {
		this.modalService.openModalMessage(
			'sm',
			{
				title: this.translate.instant(title),
				message: this.translate.instant(message),
				buttons: [
					{
						class: 'btn-primary',
						label: this.translate.instant('general.OK'),
						action: () => {}
					}
				]
			},
			<ModalOptions<any>>{
				ignoreBackdropClick: true
			}
		);
	}

	private showSaveValidationErrorsModal(errors: string[]): void {
		const html = `Could not save content tag due to the following errors: <br/><br/>${errors.join('<br/>')}`;
		this.modalService.openModalMessage(
			'md',
			{
				title: this.translate.instant('features.manage_templates.optimail.contentTags.SAVE_CONTENT_TAG'),
				html: html,
				buttons: [
					{
						class: 'btn-primary',
						label: this.translate.instant('general.OK'),
						action: () => {}
					}
				]
			},
			<ModalOptions<any>>{
				ignoreBackdropClick: true
			}
		);
	}

	private showToast(message: string): void {
		this.toastParams = {
			...this.toastParams,
			isOpen: true,
			message: this.translate.instant(message)
		};
	}
}
