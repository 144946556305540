import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';

@Component({
	selector: 'import-customers-loader-modal',
	templateUrl: './import-customers-loader-modal.component.html',
	styleUrls: ['./import-customers-loader-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImportLeadsLoaderModalComponent implements OnInit {
  private readonly translationPrefix = "features.user_settings.body.importedCustomers";
  public modalConfig: IModalConf;
  public loadingText: string;
  public isLoading: boolean = true;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  	this.setModalConfig();
  	this.loadingText = this.translate.instant(`${this.translationPrefix}.IMPORT_LOADER_TEXT`);
  }

  private setModalConfig() {
  	this.modalConfig = {
  		hideFooterBorder: false,
  		noBodyPadding: true,
  		title: this.translate.instant(`${this.translationPrefix}.TITLE`),
  		buttons:[
  			{
  				class: "btn-primary",
  				isDisabled: () => true,
  				label: this.translate.instant("general.OK"),
  				action: () => {},
  			},
  		]
  	};
  }

}
