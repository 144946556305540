import {Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'last-modified-stamp',
    templateUrl: './lastModifiedStamp.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./lastModifiedStamp.component.scss']
})
export class LastModifiedStampComponent {

    @Input() id;
    @Input() lastModifiedData: any;
    @Input() isBreakRow: boolean;
    @Input() shouldShowIdAtBottom: boolean;
}