import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiStudioComponent } from './biStudio.component';
import { BiStudioRoutingModule } from "./biStudio.route";
import { BiStudioLandingPageComponent } from './components/biStudioLandingPage/biStudioLandingPage.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BiStudioComponent,
    BiStudioLandingPageComponent
  ],
  exports: [
    BiStudioComponent
  ],
  imports: [
    CommonModule,
    BiStudioRoutingModule,
    TranslateModule
  ],
  providers: [
  ],
})
export class BiStudioModule { }
