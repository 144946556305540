import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { debounceTime, map } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { TranslationSearchResult, TranslationSearchString } from '../../../models/translationSearch';
import { TemplateContextService } from '../../../services/templateContext.service';
import { TemplateDetails } from '../../../models/templateDetails';
import { ChannelService } from '../../../services/channel.service';
import { TemplateQueryParams } from '../../../models/templateQueryParams';

@Component({
	selector: 'search-panel',
	templateUrl: './searchTranslationsPanel.component.html',
	styleUrls: ['./searchTranslationsPanel.component.scss']
})
export class SearchTranslationsPanelComponent implements OnInit, OnDestroy {
	@Output() backButtonClick = new EventEmitter<boolean>();
	subscriptions = new Subscription();
	searchTextChanged = new Subject<string>();
	public searchText: string = '';
	public isSearching: boolean;
	public searchResult: { [fileName: string]: TranslationSearchString[] } = {};
	public clickedTanslation: TranslationSearchString;
	private template: TemplateDetails;
	selectedChannel: number;

	constructor(private clipboard: Clipboard, private templateService: ManageTemplatesService, private context: TemplateContextService, public channelService: ChannelService) {}

	ngOnInit(): void {
		const mapResult = (result: TranslationSearchResult): { [fileName: string]: TranslationSearchString[] } =>
			result.strings.reduce((prev: { [fileName: string]: TranslationSearchString[] }, curr) => {
				if (!prev[curr.fileName]) prev[curr.fileName] = [];
				prev[curr.fileName].push(curr);
				return prev;
			}, {});

		this.subscriptions.add(
			this.searchTextChanged.pipe(debounceTime(200)).subscribe((searchText) => {
				this.isSearching = true;
				this.templateService
					.searchTranslations(searchText)
					.pipe(map(mapResult))
					.subscribe((result) => (this.searchResult = result))
					.add(() => (this.isSearching = false));
			})
		);
		this.subscriptions.add(this.context.current.subscribe((t) => (this.template = t)));
		this.subscriptions.add(this.context.queryTemplateParams.subscribe((params: TemplateQueryParams) => this.selectedChannel = +params.channelId));
	}

	backClick() {
		this.backButtonClick.emit(true);
	}
	onSearchTextChange($event) {
		this.searchTextChanged.next($event);
	}
	clearSearch() {
		this.searchText = '';
		this.clickedTanslation = null;
	}
	isAnySearchResult() {
		return Object.keys(this.searchResult).length > 0;
	}
	copyTanslation(translation: TranslationSearchString) {
		this.clipboard.copy(`[%EXTERNAL_TRANSLATION:${translation.key}%]`);
		this.clickedTanslation = translation;
		if (this.template) {
			const externalTranslations = this.template.externalTranslations ?? (this.template.externalTranslations = []);
			if (!externalTranslations.find((t) => t.key === translation.key)) externalTranslations.push(translation);
		}
		setTimeout(() => {
			this.clickedTanslation = null;
		}, 2000);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.searchTextChanged.complete();
	}
}
