import { Injectable } from '@angular/core';
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { catchError, first, map } from 'rxjs/operators';
import { HttpService } from './../services/optimove-http/optimove-http.model';
import { Observable, of } from 'rxjs';
import { FeatureFlag } from './featureFlag.service';

@Injectable({
  providedIn: 'root'
})
export class InternalFeatureFlagService {

  constructor(private httpService: HttpService) { }

  public isEnabled(flagName: FeatureFlag): Observable<boolean> {
    return new Observable((observer) => {
      this.httpService.get<DefaultAjaxResponse>({ controller: 'UserSettings', action: 'GetFeatureFlagData' })
        .pipe(
          first(),
          catchError(err => of(false))
        ).subscribe((res: DefaultAjaxResponse) => {
          const isEnabled = res.Data.find(ff => ff.FeatureName === flagName).IsEnabled;
          observer.next(isEnabled);
          observer.complete();
      });
    });
  }
}
