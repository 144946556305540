import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { first } from "rxjs/operators";
import { SearchItem, SearchListConfig } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { Operator } from 'src/app/features/scheduledCampaignBuilder/models/wizardData.model';
import { AttributeDataTypePurchase, ConditionValueFormat, PurchaseHistoryFamily } from '../../../attributesList.enums';
import { PurchaseHistoryCondition } from '../../../attributesList.model';
import { ConditionalValidatorService } from '../../../services/conditionalValidator.service';
import { PurchaseAttributesService } from '../../../services/purchaseAttributes.service';

@Component({
  selector: 'purchase-attribute-condition',
  templateUrl: './purchaseHistoryAttributeCondition.component.html',
  styleUrls: ['./purchaseHistoryAttributeCondition.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PurchaseAttributeConditionComponent,
      multi: true
    }]
})
export class PurchaseAttributeConditionComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input('attributes') set attributesInput(attributes: SearchItem[]) {
    this.attributes = attributes;
    if (this.form) this.cleanCondition();
      if (this.currentCondition !== undefined && this.attributes.some(x => x.key === this.currentCondition.AttributeFieldName)) {
          this.writeValue(this.currentCondition);
          this.currentCondition = undefined;
      }
  };

  public translateKeys = translateKeys;
  public form: FormGroup;
  public attributesSearchListConfig: SearchListConfig;
  public operatorsSearchListConfig: SearchListConfig;
  public valueSearchListConfig: SearchListConfig;
  public valueFormat: ConditionValueFormat;
  public isValueCurrency: boolean;
  public currencySymbol: string = '';
  private currentCondition: PurchaseHistoryCondition;

  public attributes: SearchItem[] = [];
  public operators: SearchItem[] = [];
  public possibleValues: SearchItem[] = [];

  private subscriptions: Subscription[] = [];
  private attributeDataType: AttributeDataTypePurchase;
  private isValueInputForceDisabled: boolean = false;

  constructor(private purchaseAttributeService: PurchaseAttributesService,
    private conditionValidator: ConditionalValidatorService) { }

  ngOnInit(): void {
    this.initSearchListsConfigs();
    this.form = this.createForm();
    this.initFormSubscriptions();
    this.setValueFormat("text", false);
  }

  private shouldUseTransactionalDecimal() {
    const attribute = this.selectedAttribute.value[0];
    return (attribute.attributeType === 1 && ['decimal', 'int', 'number'].includes(this.selectedAttribute.value[0].dataType))
      || (attribute.attributeType === 1 && attribute.hasValuesList && ['text', 'string'].includes(attribute.dataType))
  }

  buildCondition(family: PurchaseHistoryFamily): PurchaseHistoryCondition {
    const value = this.valueFormat === "between numbers"
      ? (this.value.value as Array<number>).toString()
      : this.valueFormat === "dropdown"
        ? this.value.value.map(x => x.key).toString()
        : this.value.value;

    const operatorId = this.selectedOperator.value[0].key;
    const attribute = this.selectedAttribute.value[0];

    const attributeType = family === PurchaseHistoryFamily.New_PurchaseHistory
      ? this.shouldUseTransactionalDecimal()
        ? 0
        : attribute.attributeType === 1 && ['text', 'string'].includes(attribute.dataType)
          ? 1
          : attribute.attributeType === 0
            ? 2
            : null
      : 0;


    return {
      AttributeFieldName: attribute.key,
      OperatorId: operatorId,
      OperatorExpression: this.selectedOperator.value[0].value,
      Value: value,
      FormatType: this.valueFormat,
      AttributeID: family === PurchaseHistoryFamily.New_PurchaseHistory ? attribute.attributeID : 0,
      AttributeType: attributeType,
      HasValuesList: attribute.hasValuesList
    } as PurchaseHistoryCondition;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  ngAfterViewChecked(){
    this.setDisabledState(this.isValueInputForceDisabled);
  }
  // ====| ValueAccessor region |==== \\

  writeValue(condition: PurchaseHistoryCondition): void {
    this.currentCondition = condition;
    if (condition) {
      this.form = this.createForm();
      this.setDisabledState(this.isValueInputForceDisabled);
      this.initFormSubscriptions();

      const attributeSearchItem = this.attributes.find(x => x.key === condition.AttributeFieldName);

      if (attributeSearchItem) {
        this.selectedAttribute.setValue(attributeSearchItem ? [attributeSearchItem] : '', { emitEvent: false });

        this.purchaseAttributeService.GetAttributeDataType(condition.AttributeFieldName)
          .pipe(first()).subscribe(attributeDataType => {
            this.attributeDataType = attributeDataType;
            this.setOperators(this.purchaseAttributeService.GetOperators(attributeDataType), false);

            const selectedOperator: SearchItem = { key: condition.OperatorId, value: condition.OperatorExpression };
            this.selectedOperator.setValue([selectedOperator], { emitEvent: false });
            this.onOperatorChanged(selectedOperator, false);

            this.setValueFormat(condition.FormatType, false);
            if (condition.FormatType === "dropdown") {
              this.purchaseAttributeService.GetPossibleAttributeValues(condition.AttributeFieldName).pipe(first()).subscribe(possibleValues => {
                const searchItemsKeys = condition.Value.split(',');
                const value = possibleValues.filter(x => searchItemsKeys.includes(x.key?.toString()));
                this.value.setValue(value, { emitEvent: false });
              });
            }
            else {
              const value = condition.FormatType === "between numbers"
                ? condition.Value.split(',')
                : condition.Value;
              this.value.setValue(value, { emitEvent: false });
            }

            if (!this.isValueInputForceDisabled) {
                this.selectedOperator.enable({ emitEvent: false });
            }
          });
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectedAttribute.disable({ emitEvent: false });
      this.selectedOperator.disable({ emitEvent: false });
      this.value.disable({ emitEvent: false });
      this.isValueInputForceDisabled = true;
    }
    else {
      this.selectedAttribute.enable({ emitEvent: false });
      this.selectedOperator.enable({ emitEvent: false });
      this.value.enable({ emitEvent: false });
      this.isValueInputForceDisabled = false;
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn }

  onChange = (fn: any) => { }

  registerOnTouched(fn: any): void { this.onTouched = fn }

  onTouched = (fn: any) => { }

  // ====| FormControls region |==== \\

  get selectedAttribute(): FormControl {
    return this.form.get('attribute') as FormControl;
  }

  get selectedOperator(): FormControl {
    return this.form.get('operator') as FormControl;
  }

  get value(): FormControl {
    return this.form.get('value') as FormControl;
  }

  // ====| private region |==== \\

  private createForm(): FormGroup {
    return new FormGroup({
      attribute: new FormControl('', [Validators.required]),
      operator: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
    });
  }

  private initFormSubscriptions() {
    
    const subscription$4 = this.selectedAttribute.statusChanges.subscribe(attribute => {  });
    const subscription$5 = this.selectedOperator.statusChanges.subscribe(operator => {  });
    const subscription$6 = this.value.statusChanges.subscribe(v => {  });

    const subscription$1 = this.selectedAttribute.valueChanges.subscribe(attribute => { if (attribute) this.onAttributeChanged(attribute[0]) });
    const subscription$2 = this.selectedOperator.valueChanges.subscribe(operator => { if (operator) this.onOperatorChanged(operator[0]) });
    const subscription$3 = this.form.valueChanges.subscribe(formValue => { this.onChange(formValue) });
    this.subscriptions.push(subscription$1);
    this.subscriptions.push(subscription$2);
    this.subscriptions.push(subscription$3);
  }

  private onAttributeChanged(attribute: SearchItem) {
    this.purchaseAttributeService.GetAttributeDataType(attribute.key)
      .pipe(first()).subscribe(attributeDataType => {
        this.attributeDataType = attributeDataType;
        this.selectedOperator.enable({ emitEvent: false });
        this.setOperators(this.purchaseAttributeService.GetOperators(this.attributeDataType));
      });
  }

  private onOperatorChanged(operator: SearchItem, emitEvent: boolean = true) {
    this.calculateValueFormat(this.selectedAttribute.value[0], operator, this.attributeDataType, emitEvent);
  }

  private setOperators(operators: Operator[], emitEvent: boolean = true) {
    let operatorsSearchItems: SearchItem[] = operators.map(x => { return { key: x.Id, value: x.Expression } as SearchItem });
    const areOperatorsValuesChanged =
      this.operators.length !== operatorsSearchItems.length ||
      operatorsSearchItems.some((x, i) => {
        return this.operators[i].key !== x.key || this.operators[i].value !== x.value
      });

    if (areOperatorsValuesChanged) {
      this.selectedOperator.setValue('', { emitEvent: false });
      this.selectedOperator.markAsPristine();
      this.operators = operatorsSearchItems;
      this.setValueFormat("text", false);
      this.value.setValue('', { emitEvent: emitEvent });
    }
    else {
      if (this.selectedAttribute.value && this.selectedOperator.value) {
        this.calculateValueFormat(this.selectedAttribute.value[0], this.selectedOperator.value[0], this.attributeDataType);
      }
    }
  }

  private cleanCondition() {
    this.selectedAttribute.setValue('', { emitEvent: false });
    this.selectedOperator.setValue('', { emitEvent: false });
    this.value.setValue('', { emitEvent: false });

    this.setValueFormat("text", true);
  }

  private calculateValueFormat(attribute: SearchItem, operator: SearchItem, attributeDataType: AttributeDataTypePurchase, emitEvent: boolean = true) {
    const operatorExpression = operator.value;
    const attributeRealFieldName = attribute.key;

    forkJoin({
      possibleValues: this.purchaseAttributeService.GetPossibleAttributeValues(attributeRealFieldName),
      isValueCurrency: this.purchaseAttributeService.isAttributeValueCurrency(attributeRealFieldName),
    }).pipe(first()).subscribe((res) => {
      const arePossibleValuesChanged = this.possibleValues.length !== res.possibleValues.length ||
        res.possibleValues.some((x, i) => {
          return this.possibleValues[i].key !== x.key || this.possibleValues[i].value !== x.value
        });

      if (arePossibleValuesChanged) this.possibleValues = res.possibleValues;

      this.isValueCurrency = res.isValueCurrency;
      this.currencySymbol = this.isValueCurrency ? this.purchaseAttributeService.currencySymbol : '';
      const doesAttributeHaveValues: boolean = this.possibleValues.length > 0;

      switch (attributeDataType) {
        case "number":
          if (operatorExpression === "Between") {
            this.setValueFormat("between numbers", emitEvent);
          }
          else {
            this.setValueFormat("number", emitEvent);
          }
          break;
        case "string":
          switch (operatorExpression) {
            case "Not one of":
            case "One of":
              if (arePossibleValuesChanged && this.valueFormat !== "disable") {
                this.value.setValue('', { emitEvent });
              }
              if (!this.valueSearchListConfig.isMultiSelect) {
                if (this.valueFormat !== "disable") this.value.setValue('', { emitEvent });
                this.valueSearchListConfig.isMultiSelect = true;
              }
              this.setValueFormat(doesAttributeHaveValues ? "dropdown" : "text", emitEvent);
              break;
            case "Does not equal":
            case "Equals":
              if (arePossibleValuesChanged && this.valueFormat !== "disable") {
                this.value.setValue('', { emitEvent });
              }
              if (this.valueSearchListConfig.isMultiSelect) {
                if (this.valueFormat !== "disable") this.value.setValue('', { emitEvent });
                this.valueSearchListConfig.isMultiSelect = false;
              }
              this.setValueFormat(doesAttributeHaveValues ? "dropdown" : "text", emitEvent);
              break;
            default:
              this.setValueFormat("text", emitEvent);
              break;
          }
      }
    });
  }

  private setValueFormat(format: ConditionValueFormat, emitEvent: boolean = true) {
    if (this.valueFormat !== format) {
      if (this.valueFormat !== "disable") this.value.setValue('', { emitEvent });
      this.value.markAsUntouched();
      this.value.markAsPristine();
      this.valueFormat = format;
    }

    if (this.value.disabled && !this.isValueInputForceDisabled && format !== "disable") {
      this.value.enable({ emitEvent });
    }

    if (format === "between numbers") {
      this.value.setValidators([Validators.required, this.conditionValidator.validNumbersPicker()]);
    }
    else {
      this.value.setValidators([Validators.required]);
    }
  }

  private initSearchListsConfigs() {
    let defaultSearchListConfig = {
      keyProperty: "key",
      valueProperty: "value",
      isMultiSelect: false,
    };
    this.attributesSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.attributes,
      placeholderTranslateKey: this.translateKeys.attributes,
    }
    this.operatorsSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.operator,
      placeholderTranslateKey: this.translateKeys.operator,
    }
    this.valueSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.value,
      placeholderTranslateKey: this.translateKeys.value,
    }
  }
}

const translateKeys = {
  attributes: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTES',
  value: 'features.user_settings.body.attributes.purchaseAttributes.VALUE',
  operator: 'features.user_settings.body.attributes.purchaseAttributes.OPERATOR'
};
