import { Component, OnInit, forwardRef, Input , ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChipRadioItem } from './models/chipRadioItem.model';

@Component({
    selector: 'chips-radio-group',
    templateUrl: 'chipsRadioGroup.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./chipsRadioGroup.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipsRadioGroupComponent),
            multi: true
        }
    ]
})

export class ChipsRadioGroupComponent implements OnInit, ControlValueAccessor {
    @Input() items: Array<ChipRadioItem>;
    @Input() name: string;
    
    public isDisabled: boolean;

    constructor() { }

    ngOnInit() { }

    private innerValue: string | number | boolean;
    get value(): string | number | boolean {
        return this.innerValue;
    }

    set value(v: string | number | boolean) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.change(v);
        }
    }

    onChange: Function;
    onTouched: Function;

    writeValue(value: string | number | boolean) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    change(value: string | number | boolean) {
        this.innerValue = value;
        this.onChange(value);
        this.onTouched(value);
    }
    
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}