import { AgFilterComponent } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { AttributeStatus } from "../../attributesList.enums";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";

@Component({
  selector: "status-filter",
  templateUrl: "./statusFilter.component.html"
})
export class StatusFilterComponent implements AgFilterComponent {
  colorItems: { [key: string] : ColoredTagType } = {
    "NotPublished" : ColoredTagType.Type12,
    "Publishing" : ColoredTagType.Type3,
    "Published" : ColoredTagType.Type4
  };

  params: IFilterParams;
  isNotPublished: boolean = true;
  isPublishing: boolean = true;
  isPublished: boolean = true;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const status = params.data.PublishStatus as AttributeStatus;
    return (["NotPublished", "FailedToPublish"].includes(status) && this.isNotPublished)
          || (status === "Publishing" && this.isPublishing)
          || (status === "Published" && this.isPublished);
  }

  getModel() {}

  setModel(model: any) {}

  updateFilter() {
    this.params.filterChangedCallback();
  }
}