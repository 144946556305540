import { Component, Input, Output, EventEmitter, OnDestroy , ViewEncapsulation } from "@angular/core";
import { IChannelData, ActionType, CHANNEL_METRICS_TRANSLATION_ROOT } from "../../campaignAnalysis.models";
import { OptiLogicModalService } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { TemplatesBreakdownComponent } from "../templatesBreakdown/templatesBreakdown.component";
import {BsModalRef, ModalOptions} from "ngx-bootstrap/modal";

@Component({
    selector: 'channel-metrics-bar',
    templateUrl: './channelMetricsBar.component.html',
    styleUrls: ['./channelMetricsBar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsBarComponent {
    @Input() channelData: IChannelData;
    @Input() actionType: ActionType;
    @Input() actionName: string;
    @Input() datesData: [];
    @Input() planIdActionSerial: [];
    @Input() isScheduledCampaign: any;
    @Input() templateDialogClass: string;
    @Output() metricClick: EventEmitter<{ metricId, channelId, planDetailChannelId, metricName, templateId }> = new EventEmitter();

    public tmpBreakdownBsModalRef : BsModalRef | null;

    templatesBreakdownPath = CHANNEL_METRICS_TRANSLATION_ROOT + 'TEMPLATES_BREAKDOWN';
    unavailableMetricsPath = CHANNEL_METRICS_TRANSLATION_ROOT + 'UNAVAILABLE';

    constructor(private modalService: OptiLogicModalService) {

    }

    onTemplatesBreakdown() {
        this.tmpBreakdownBsModalRef = this.modalService.open(
            TemplatesBreakdownComponent,
            'xl',
            <ModalOptions<any>>({
                ignoreBackdropClick: true,
                class: this.templateDialogClass,
                initialState: {
                    channelData: this.channelData,
                    actionType: this.actionType,
                    actionName: this.actionName,
                    metricClickCallback: (payload) => this.onMetricClick(payload)
                }
            }));
    }

    onMetricClick(payload) {
        this.metricClick.emit(payload);
    }
}
