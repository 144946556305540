<div class="panel-body">
    <customer-impact-graph
            [clientCustomerId]="clientCustomerId"
            [dateRange]="dateRange"
    ></customer-impact-graph>
    <div class="row list-menu ">
        <div class="trend-label col-md-9 filter-menu ">
            <button class="filter-menu-item" (click)="filterCampaignsList('all')"
                [ngClass]="{'disabled-menu-item': campaignFilter !== 'all'}" data-qa-id="AllCampaignsTabBtn">
                <h3>
                    {{translationKeys.ALL_CAMPAIGNS | translate}}
                </h3>
                <hr class="tab-indicator" *ngIf="campaignFilter === 'all'" />
            </button>
            <button class="filter-menu-item" (click)="filterCampaignsList('scheduled')"
                [ngClass]="{'disabled-menu-item': campaignFilter !== 'scheduled'}"
                data-qa-id="ScheduledCampaignsTabBtn">
                <h3>
                    {{translationKeys.SCHEDULED_CAMPAIGNS | translate}}
                </h3>
                <hr class="tab-indicator" *ngIf="campaignFilter === 'scheduled'" />
            </button>
            <button class="filter-menu-item" (click)="filterCampaignsList('triggered')"
                [ngClass]="{'disabled-menu-item': campaignFilter !== 'triggered'}"
                data-qa-id="TriggeredCampaignsTabBtn">
                <h3>
                    {{translationKeys.TRIGGERED_CAMPAIGNS | translate}}
                </h3>
                <hr class="tab-indicator" *ngIf="campaignFilter === 'triggered'" />
            </button>
        </div>
        <div class="pt-15 trend-label col-md-3">
            <button data-qa-id="CsvExportButton" 
                [disabled]="!hasListData" csv-exporter [fileName]="campaignListCsvFileName"
                [headers]="campaignListCsvFileHeaders" [exportedData]="campaignListDataFiltered"
                [objectCsvSerializerFunc]="campaignListCsvSerializerFunc" class="export-btn btn-link">
                <i class="material-icons">open_in_browser</i>
                <span>
                    {{exportBtnText | translate}}
                </span>
            </button>
        </div>
    </div>
    <div class="row data-table">
        <div class="col-md-12">
            <campaign-grid [customerId]="clientCustomerId" [clientCustomerId]="customerId" [startDate]="dateRange?.startDate"
                [endDate]="dateRange?.endDate" [filter]="campaignFilter"></campaign-grid>
        </div>
    </div>
</div>