<form [formGroup]="form" class="form-group">
    <div class="subtitle row">
        <div class="col-sm-9">
            <h3 class="label-column">
                {{translateKeys.attributeCalculation | translate}}
            </h3>
        </div>
    </div>

    <div class="row calc-row">
        <div class="col-sm-2">
            {{translateKeys.function | translate}}
        </div>
        <div class="col-sm-7">
            <opti-search-list class="value-input" data-qa-id="attribute-result" formControlName="func"
                [items]="functionList" [config]="funcSearchListConfig">
            </opti-search-list>
        </div>
    </div>

    <ng-container *ngIf="isNumericFunction">
        <div class="row">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.of | translate}}
                </div>
            </div>
            <div class="col-sm-7">
                <opti-search-list class="value-input" data-qa-id="attribute-result"
                    formControlName="resultAttribute" [items]="numericAttributes"
                    [config]="resultAttributeSearchListConfig">
                </opti-search-list>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.where | translate}}
                </div>
            </div>
            <div class="col-sm-7">
                <div formArrayName="conditions" class="attribute-conditions">
                    <div *ngFor="let condition of conditions.controls index as i" class="condition">
                        <span *ngIf="i > 0">
                            And
                        </span>
                        <div>
                            <purchase-attribute-condition #condition
                                [attributes]="i === 0 ? firstConditionAttributes : []" [formControlName]="i">
                            </purchase-attribute-condition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.timeframe | translate}}
                </div>
            </div>
            <div [ngClass]="{'col-sm-5': needShowTimeframePeriod, 'col-sm-7': !needShowTimeframePeriod }">
                <opti-search-list data-qa-id="timeframe" formControlName="timeframe" [items]="timeframes"
                    [config]="timeframeSearchListConfig">
                </opti-search-list>
            </div>
            <div *ngIf="needShowTimeframePeriod" class="timeframe-period-column col-sm-2">
                <input data-qa-id="timeframe-period" formControlName="timeframePeriod" class="value-input" type="number"
                    [min]="1" [max]="730" [required]
                    [ngClass]="{'red-border': timeframePeriod.invalid && timeframePeriod.touched}"
                    (keypress)="isInputEventIsRegularNumber($event)" />
                <div class="label-days">
                    {{translateKeys.days | translate}}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isTextFunction">
        <div class="row">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.where | translate}}
                </div>
            </div>
            <div class="col-sm-7">
                <opti-search-list class="value-input" data-qa-id="attribute-result"
                    formControlName="resultAttribute" [items]="textAttributes"
                    [config]="resultAttributeSearchListConfig">
                </opti-search-list>
            </div>
        </div>
        <div class="row" *ngIf="isWithMetric">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.in | translate}}
                </div>
            </div>
            <div class="col-sm-7">
                <opti-search-list [isDefaultDisabled]="true" class="value-input" data-qa-id="attribute-result-metric"
                    formControlName="metricAttribute" [items]="metricAttributes"
                    [config]="resultAttributeSearchListConfig">
                </opti-search-list>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2">
                <div class="label-column">
                    {{translateKeys.timeframe | translate}}
                </div>
            </div>
            <div [ngClass]="{'col-sm-5': needShowTimeframePeriod, 'col-sm-7': !needShowTimeframePeriod }">
                <opti-search-list data-qa-id="timeframe" formControlName="timeframe" [items]="timeframes"
                    [config]="timeframeSearchListConfig">
                </opti-search-list>
            </div>
            <div *ngIf="needShowTimeframePeriod" class="timeframe-period-column"
                [ngClass]="{'col-sm-2': timeframe.value[0].key === 1, 'col-sm-3': timeframe.value[0].key === 2}">
                <input data-qa-id="timeframe-period" formControlName="timeframePeriod" class="value-input" type="number"
                    [min]="1" [max]="730" [required]
                    [ngClass]="{'red-border': timeframePeriod.invalid && timeframePeriod.touched}"
                    (keypress)="isInputEventIsRegularNumber($event)" />
                <div class="label-days">
                    {{translateKeys.days | translate}}
                </div>
            </div>
        </div>
    </ng-container>

</form>