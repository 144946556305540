import { AttributeFormat, AttributeDetails, FormulaType } from "../../attributesList.enums";
import { BaseAttribute, CalculatedAttribute, Condition, FormulaBasedAttribute, MultyExpressionsConditionalAttribute } from "../../attributesList.model";

export interface CalculatorConfig {
    attributeName: string;
    items: CalculatorItem[];
    actionItems: CalculatorItem[];
    numberOfAttributesAllowed:number;
    numberOfShowsPerAttribute:number;
    isAddMode:boolean;
    formulaType: FormulaType;
    formula: string;
    params: string;
    isPublished: boolean;
    isDisabled: boolean;
    ignoreYear: boolean;
    attributes: (BaseAttribute | CalculatedAttribute)[];
}

export interface FormulaMetadata {
    params: string[];
    details: AttributeDetails[],
    formula: string;
}

export interface CalculatorItem {
    orderUI: number;
    value: string;
    span?: number;
    action?: () => void;
    disabled?: boolean;
    hidden?: boolean;
    displayName?: string;
}


export const calculatorDatasource: CalculatorItem[] = [
    { value: "7", orderUI: 0, span: 1 },
    { value: "8", orderUI: 1, span: 1 },
    { value: "9", orderUI: 2, span: 1 },
    { value: "/", orderUI: 3, span: 1 },
    { value: "4", orderUI: 4, span: 1 },
    { value: "5", orderUI: 5, span: 1 },
    { value: "6", orderUI: 6, span: 1 },
    { value: "*", orderUI: 7, span: 1, displayName: "x" },
    { value: "1", orderUI: 8, span: 1 },
    { value: "2", orderUI: 9, span: 1 },
    { value: "3", orderUI: 10, span: 1 },
    { value: "-", orderUI: 11, span: 1 },
    { value: "0", orderUI: 12, span: 2 },
    { value: ".", orderUI: 13, span: 1 },
    { value: "+", orderUI: 14, span: 1 },
    { value: "Reset", orderUI: 15, span: 2 },
    { value: "(", orderUI: 16, span: 1 },
    { value: ")", orderUI: 17, span: 1 },
];

export const CalculatorActionItems: CalculatorItem[] = [
    { value: "+Add Attribute", orderUI: 0 },
    { value: "empty space", orderUI: -1 },
    { value: "Days since", orderUI: 1 },
    { value: "Days until", orderUI: 2 },
    { value: "Days between", orderUI: 3 }
];