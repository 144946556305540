import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TemplateContextService } from '../../services/templateContext.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { OverflowMenuItem } from '../../../../components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { TranslateService } from '@ngx-translate/core';
import { FolderService } from '../../services/folder.service';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { ChannelService } from '../../services/channel.service';
import { TemplateNewService } from '../../services/templateNew.service';
import { RoutingConsts } from '../../models/routing.consts';

@Component({
	selector: 'detail-panel-header',
	templateUrl: './detailsPanelHeader.component.html',
	styleUrls: ['./detailsPanelHeader.component.scss']
})
export class DetailsPanelHeaderComponent implements OnInit, OnDestroy {
	private optimailChannelId: number = RoutingConsts.OPTIMAIL_CHANNEL;
	private webPage: number = RoutingConsts.WEB_PAGE_POPUP_CHANNEL;
	public shouldShowCreateNew: boolean = false;
	public newButtonMenuPosition = this.getOverflowMenuPosition();
	public newButtonMenuItems: OverflowMenuItem[];
	public searchText?: string;
	public subMethodId;
	private currentFolderId: number;
	private channelId: number;
	public folderTitle: string;
	public parentFolderId: number;
	private unsubscribe$ = new Subject<any>();
	public isBeefreeEditorForPopupEnabled = false;

	constructor(
		private context: TemplateContextService,
		private translateService: TranslateService,
		private folderEditService: FolderService,
		private channelService: ChannelService,
		private changeDetector: ChangeDetectorRef,
		private templateNewService: TemplateNewService
	) {
	}

	ngOnInit(): void {
		const folderList = this.folderEditService.foldersList;
		const params = this.context.queryTemplateParams;

		combineLatest([folderList, params])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([folderList, params]) => {
				this.searchText = params.search;
				this.subMethodId = +params.typeId;
				this.currentFolderId = +params.folderId;
				this.channelId = +params.channelId;
				this.shouldShowCreateNew =
					params.channelId === `${this.optimailChannelId}` ||
					params.channelId === `${this.webPage}` ||
					this.channelService.isOptimobile(+params.channelId);
				this.newButtonMenuItems = this.getNewButtonMenuItems();
				if (!isNaN(this.currentFolderId)) {
					const currentFolder = folderList.find((folder) => folder.id === this.currentFolderId);

					if (currentFolder) {
						this.folderTitle = currentFolder.text;
						this.parentFolderId = currentFolder.parentId;
					}
				}
				this.changeDetector.detectChanges();
			});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	private getOverflowMenuPosition(): ConnectionPositionPair[] {
		return [
			new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
			new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' })
		];
	}

	private getNewButtonMenuItems(): OverflowMenuItem[] {
		return [
			{
				addSeparatorBelow: false,
				callback: () => {
					this.templateNewService.newTemplate(this.channelId);

					return {};
				},
				disabled: false,
				text: this.translateService.instant('features.manage_templates.newTemplate.TITLE', {items: this.translateService.instant(this.channelService.isWhatsApp(this.channelId)
					? 'features.manage_templates.JOURNEY'
					: 'features.manage_templates.TEMPLATE'
				)})
			},
			this.folderEditService.getNewFolderMenuItem(() => {
				return {
					folderId: this.currentFolderId,
					subMethodId: this.subMethodId,
					parentFolderId: this.parentFolderId
				};
			})
		];
	}

	getTitle(searchText: string, folderTitle: string): string {
		return searchText && searchText.length > 2
			? this.translateService.instant('features.manage_templates.components.details.SHOWING_RESULTS') + ' "' + searchText + '"'
			: folderTitle;
	}
}
