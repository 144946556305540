import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Customer360DataService } from './../../services/customer360.data.service';
import { IAnalysisRange } from './../../customer360.models';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'customer-data-page',
  templateUrl: './customerDataPage.component.html',
  styleUrls: ['./customerDataPage.component.scss'],
})
export class CustomerDataPageComponent implements OnInit {
  @Input() customerId: string;
  @Output('onDataLoaded') onDataLoadedEvent = new EventEmitter<void>();

  public currentAnalysisRange: IAnalysisRange;
  public currentClientId: string;
  public isCustomerImpactReady: boolean = false;
  public isSnapshotTableReady: boolean = false;
  public cogwheelNewPurchaseHistoryFF: boolean = null;
  //This form is used to know if the child components data has been loaded
  public loadingForm: FormGroup;
  private formSubs: Subscription[] = [];

  constructor(private dataService: Customer360DataService, private cd: ChangeDetectorRef) {}

  async ngOnInit() {
    this.dataService
      .isPurchaseHistoryMultiAttributeFlow()
      .pipe(first())
      .subscribe((res) => {
        this.cogwheelNewPurchaseHistoryFF = res.Data;
      });
    this.createForm();
    this.initFormSubscription();
    const res = await this.dataService.getClientId(this.customerId).pipe(first()).toPromise();
    this.currentClientId = res.Data.clientId;
    this.cd.detectChanges();
  }

  public onAnalysisRangeChanged(range: IAnalysisRange) {
    this.impactMetricsLoaded.setValue(false);
    this.impactCampaignsTableLoaded.setValue(false);
    this.impactDiagramsLoaded.setValue(false);
    this.snapshotTableLoaded.setValue(false);
    this.currentAnalysisRange = range;
  }

  public onImpactMetricsLoaded() {
    this.impactMetricsLoaded.setValue(true);
  }

  public onImpactCampaignsTableLoaded() {
    this.impactCampaignsTableLoaded.setValue(true);
  }

  public onImpactDiagramsLoaded() {
    this.impactDiagramsLoaded.setValue(true);
  }

  public onSnapshotTableLoaded() {
    this.snapshotTableLoaded.setValue(true);
  }

  get customerImpactLoaded(): FormGroup {
    return this.loadingForm.get('customerImpact') as FormGroup;
  }

  get impactMetricsLoaded(): FormControl {
    return this.customerImpactLoaded.get('customerImpactMetrics') as FormControl;
  }

  get impactCampaignsTableLoaded(): FormControl {
    return this.customerImpactLoaded.get('customerImpactCampaignsTable') as FormControl;
  }

  get impactDiagramsLoaded(): FormControl {
    return this.customerImpactLoaded.get('customerImpactDiagrams') as FormControl;
  }

  get snapshotTableLoaded(): FormControl {
    return this.loadingForm.get('customerSnapshotTable') as FormControl;
  }

  private createForm() {
    this.loadingForm = new FormGroup({
      customerImpact: new FormGroup({
        customerImpactMetrics: new FormControl(false, [Validators.required]),
        customerImpactCampaignsTable: new FormControl(false, [Validators.required]),
        customerImpactDiagrams: new FormControl(false, [Validators.required]),
      }),
      customerSnapshotTable: new FormControl(false, [Validators.required]),
    });
  }

  private initFormSubscription() {
    const sub$1 = this.customerImpactLoaded.valueChanges.subscribe((x) => {
      this.isCustomerImpactReady =
        x.customerImpactMetrics && x.customerImpactCampaignsTable && x.customerImpactDiagrams;
    });

    const sub$2 = this.snapshotTableLoaded.valueChanges.subscribe((x) => {
      this.isSnapshotTableReady = x;
    });

    this.formSubs.push(sub$1);
    this.formSubs.push(sub$2);
  }
}
