<div class="brands-list">
    <div class="panel-heading">
        <h2>{{ translateKeys.title | translate }}</h2>
    </div>
    <div class="panel-body">
        <ag-grid-angular *ngIf="gridOptions" class="brand-grid ag-theme-alpine"
                          [gridOptions]="gridOptions"
                          [modules]="modules">
        </ag-grid-angular>
    </div>
</div>