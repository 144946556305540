<div class="default-language-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <div class="item-list">
      <opti-select
        [items]="listOfLanguages"
        [ngModel]="selectedLanguageId"
        (ngModelChange)="onLanguageSelected($event)"
        [placeholder]="'features.manage_templates.optimail.defaultLanguageModal.SELECT_LANGUAGE' | translate"></opti-select>
    </div>
  </opti-logic-modal>
</div>
