import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'click-loader',
    templateUrl: './clickLoader.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./clickLoader.component.scss']
})

export class ClickLoaderComponent implements OnInit, OnChanges {

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.initParams();
    }

    @Input() isResolved: boolean;
    @Input() isDisabledOnResolved: boolean;
    @Input() loadingText: string;
    @Input() isShowLoader: boolean;
	@Input() model: number;
	@Input() isDisabled: boolean;

    private _clicked: boolean;
    private _allowRestore: boolean;
    private _isDisabledOnResolved:  boolean;
    private _isResolved: boolean;

    public showLoaderHtml: boolean;



    ngOnChanges(changes: SimpleChanges) {
        if (changes.isShowLoader) {
            this.onShowLoaderChange(changes.isShowLoader.currentValue);
        }
        if (changes.isResolved) {
            this.onIsResolveChange(changes.isResolved.currentValue, changes.isResolved.previousValue);
        }
		if (changes.model && !changes.model.firstChange){
			if (changes.model.currentValue != changes.model.previousValue){
				this._allowRestore = true;
			}
		}
    }

    replaceView(): void {
        if ((!this._isResolved || this._isDisabledOnResolved === false) &&
            (this.isShowLoader === undefined || this.isShowLoader === true )) {
                this.showLoaderHtml = true;
        }
    }

    initParams(): void{
        this._clicked = false;
        this._allowRestore = true;
        this.showLoaderHtml = false;
        this.loadingText  = this.loadingText !== undefined ? this.loadingText :  this.translate.instant('components.click_loader.loading');
    }

    onIsResolveChange(newVal, oldVal) {
        if ((!this._allowRestore && this._clicked) && (newVal === false && oldVal === true)) {
            this.showLoaderHtml = true;
            return;
        }
        
        if (newVal === false || oldVal === true || newVal === undefined) {
            this._allowRestore = true;
            return;
        }

        this._allowRestore = false;
        this.showLoaderHtml = false;
    }
    
    onShowLoaderChange(newVal) {
        if (this.isShowLoader === undefined) {
            return;
        }
        if (newVal && this._clicked) {
            this.replaceView();
            this._clicked = false;
        }
    }

    onClickHandler() {
		if (!this._allowRestore || this.isDisabled){
			return;
		}
        this._clicked = true;
        this.replaceView();
        this._clicked = false;
    }

	showLoader(){
		return this.showLoaderHtml && !this.isDisabled;
	}
}