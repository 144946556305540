import { ColDef } from '@ag-grid-enterprise/all-modules';
import { AttributeStatus, AttributeType, PurchaseHistoryFamily, PurchaseHistoryFunctionName, TimeframePeriod } from './attributesList.enums';
import { AttributeTypeInfo, AttributeTypeOptions, BaseAttribute, TableBaseAttribute, TableCalculatedAttribute, Timeframe } from "./attributesList.model";
import { BtnCellRendererComponent } from "./components/btnCellRenderer/btnCellRenderer.component";
import { StatusCellRendererComponent } from "./components/statusCellRenderer/statusCellRenderer.component";
import { TypeCellRendererComponent } from "./components/typeCellRenderer/typeCellRenderer.component";
import { AttributesTitles } from './models/attributesTitles.model';


export class AttributeHelper {
    public static attributesTypes: AttributesTitles;
	public static columnsToExport: string[] = [];
    private static disableNodesIds: string[] = [];

    public static getColumnDefs(): ColDef[] {
    	let colDef = Object.keys(this.fieldToColumnNameDic)
    		.map((fieldIdName) => {
    			const colDef: ColDef = {
    				headerName: this.fieldToColumnNameDic[fieldIdName],
    				headerTooltip: this.fieldToColumnNameDic[fieldIdName],
    				field: fieldIdName,
    				sortable: true,
    				filter: 'text',
    				menuTabs: ['generalMenuTab', 'filterMenuTab'],
    				width: AttributeHelper.setColWidth(fieldIdName),
    				minWidth: 190,
    				enableCellChangeFlash: true,
    				cellStyle: (params) => {return {
    					'padding-right': '20px',
    					'opacity': `${params.data.IsHidden ? '0.4' : ''}`
    				}},
    				cellRenderer: (params) => {
    					return AttributeHelper.colCellRenderer(params);
    				},
    				comparator: AttributeHelper.stringComparator,
    				tooltipValueGetter: (params) => {
    					return AttributeHelper.decodeHTML(params.value);
    				},
    			};
    			return colDef;
    		});

    	colDef.push({
    		headerName: "Type",
    		initialWidth: 135,
    		minWidth: 120,
    		sortable: true,
    		field: "Type",
    		filter: 'typeFilter',
    		comparator: AttributeHelper.typeComparator,
    		menuTabs: ['generalMenuTab', 'filterMenuTab'], 
    		cellRendererFramework: TypeCellRendererComponent,
    		cellStyle: (params) => params.data.IsHidden ? {'opacity': '0.4'} : {},
    	});
        
    	colDef.push({
    		headerName: "Status",
    		initialWidth: 135,
    		minWidth: 115,
    		sortable: true,
    		field: "PublishStatus",
    		filter: 'statusFilter',
    		comparator: AttributeHelper.statusComparator,
    		menuTabs: ['generalMenuTab', 'filterMenuTab'], 
    		cellRendererFramework: StatusCellRendererComponent,
    		cellStyle: (params) => params.data.IsHidden ? {'opacity': '0.4'} : {},
    	});
    	colDef.push({
    		headerName: "Actions",
    		cellRendererFramework: BtnCellRendererComponent,
    		filter: false,
    		sortable: false,
    		initialWidth: 110,
    		minWidth: 50,
    		cellStyle: {overflow: "visible"},
    	});

		AttributeHelper.columnsToExport = colDef.map(x => x.field);

    	return colDef;
    }

    public static stringComparator = (a, b) => {
    	return typeof a === 'string' 
    		? a.localeCompare(b) 
    		: (a > b ? 1 : (a < b ? -1 : 0));
    }

	public static getAttributeFamily(func: string, isOldPh: boolean): PurchaseHistoryFamily {
		switch (func) {
		  case 'first':
		  case 'last':
			return isOldPh ? PurchaseHistoryFamily.Old_PurchaseHistoryFirstLast 
				: PurchaseHistoryFamily.New_PurchaseHistoryFirstLast;
		  case 'mostFrequent':
			return isOldPh ? PurchaseHistoryFamily.Old_PurchaseHistoryMostFrequent
				: PurchaseHistoryFamily.New_PurchaseHistoryMostFrequent;
		  case 'mostSpent':
			return isOldPh ? PurchaseHistoryFamily.Old_PurchaseHistoryMostSpent
				: PurchaseHistoryFamily.New_PurchaseHistoryMostSpent;
		  case 'sum':
		  default:
			return isOldPh ? PurchaseHistoryFamily.OLD_PurchaseHistory
				: PurchaseHistoryFamily.New_PurchaseHistory;
		}
	  }
	
	  public static getFunctionType(func: string): PurchaseHistoryFunctionName {
		switch (func) {
		  case 'first':
			return 'First';
		  case 'last':
			return 'Last';
		  case 'mostFrequent':
			return 'MostFrequent';
		  case 'mostSpent':
			return 'MostSpent';
		  case 'sum':
		  default:
			return 'Sum';
		}
	  }

    public static statusComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    	const statusA = AttributeHelper.convertAttributeStatusToNumber(nodeA.data.PublishStatus);
    	const statusB = AttributeHelper.convertAttributeStatusToNumber(nodeB.data.PublishStatus);

    	return statusA === statusB ? 0 : statusA > statusB ? 1 : -1;
    }

    public static typeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    	const typeA = AttributeHelper.convertAttributeTypeToNumber(
    		AttributeHelper.getFullAttributeType(nodeA.data));
    	const typeB = AttributeHelper.convertAttributeTypeToNumber(
    		AttributeHelper.getFullAttributeType(nodeB.data));

    	return typeA === typeB ? 0 : typeA < typeB ? 1 : -1;
    }

    private static convertAttributeStatusToNumber(status: AttributeStatus) {
    	switch (status) {
    		case "FailedToPublish":
    		case "NotPublished":
    			return 1;
    		case "Publishing":
    			return 2;
    		default:
    			return 3;
    	}
    }

    private static convertAttributeTypeToNumber(type: AttributeTypeOptions): number {
    	let priorityPoints = 0;
    	priorityPoints = type.isAPI ? priorityPoints + 20 : priorityPoints;
    	priorityPoints = type.isCalculated ? priorityPoints + 10 : priorityPoints;
    	priorityPoints = type.isRT ? priorityPoints + 5 : priorityPoints;
    	priorityPoints = type.isBase ? priorityPoints + 1 : priorityPoints;
    	return priorityPoints;
    }

    public static getFullAttributeType(attribute: BaseAttribute): AttributeTypeOptions {
    	const titles = AttributeHelper.attributesTypes;
    	const isAPI = titles.api.includes(attribute.DisplayName) || titles.api.includes(attribute.Name);
    	const isRT = titles.realtime.includes(attribute.Name) || titles.realtime.includes(attribute.FieldName) || attribute.DisplayName.includes("_RT");
    	const isCalculated = titles.calculated.some(elem => elem.toLowerCase() === attribute.Name.toLowerCase()) 
            || ["conditional", "customer", "calculated", "purchase"].includes(attribute.Type as AttributeType);
    	const isBase = ["realtime", "base"].includes(attribute.Type) 
            && !isAPI && !isCalculated;
    	return {
    		isAPI: isAPI,
    		isRT: isRT,
    		isCalculated: isCalculated,
    		isBase: isBase
    	};
    }

    public static decodeHTML(html: string) {
    	let div = document.createElement('div');
    	div.innerHTML = html;
    	return div.innerText;
    }

    public static setColWidth(fieldName: string) {
    	switch (fieldName) {
    		case "DisplayName":
    			return 270;
    		case "Description":
    			return 270;
    		default:
    			return 200;
    	}
    }

    public static setupTypeInfo(): AttributeTypeInfo[] {
    	const types: AttributeTypeInfo[] = [];
    	types.push({
    		title: "customerAttributes",
    		type: "customer",
    		matIcon: "calculate",
    	});
    	types.push({
    		title: "activityHistory",
    		type: "calculated",
    		oFondIcon: ""
    	});
    	types.push({
    		title: "purchaseHistory",
    		type: "purchase",
    		oFondIcon: ""
    	});
    	return types;
    }

    public static colCellRenderer(params: any): string {
		// This replacing prevents js code injections
    	return params.data?.[params.colDef.field]?.replace('<', '&lt;').replace('>', '&gt;');
    }

    public static prepareData(attrArr: (TableCalculatedAttribute | TableBaseAttribute)[]) {
    	const priorityStatus = {
    		FailedToPublish: 3,
    		NotPublished: 3,
    		Publishing: 2,
    		Published: 1
    	};

    	// initial sort: status >> name
    	return attrArr.sort((a, b) => {
    		return priorityStatus[a.PublishStatus] < priorityStatus[b.PublishStatus] ? 1 : 
    			priorityStatus[a.PublishStatus] === priorityStatus[b.PublishStatus] 
    				? a.DisplayName >= b.DisplayName 
    					? 1 : -1 : -1;
    	} );
    }

    public static getTimestamp(dateString: string) {
    	const date = new Date(Number.parseInt(dateString.match(/\d+/).shift()));
    	const offsetUTC = date.getTimezoneOffset() / 60;
    	const hoursUTC = `${offsetUTC > 10 ? offsetUTC : `0${offsetUTC > 0 ? offsetUTC : -1 * offsetUTC}`}:00`; 
    	const utcPart = `UTC${offsetUTC > 0 ? '-' : '+'}${hoursUTC}`;
    	let time = new Intl.DateTimeFormat("en" , AttributeHelper.timeOptions);
    	return `${date.toLocaleDateString("en-UK", AttributeHelper.dateOptions)} at ${time.format(date)} ${utcPart}`;
    }

    public static getTimeframesDic(withOldTimeframes: boolean) {
    	let timeframes: Timeframe[] = [
    		new Timeframe("Last", TimeframePeriod.Day, null, null, "During the previous"),
    		new Timeframe("This", TimeframePeriod.Day, null, null, "Exactly"),

    		new Timeframe("This", TimeframePeriod.Week, 0, null, "This week (to date)"),
    		new Timeframe("This", TimeframePeriod.Month, 0, null, "This month (to date)"),
    		new Timeframe("This", TimeframePeriod.Quarter, 0, null, "This quarter (to date)"),
    		new Timeframe("This", TimeframePeriod.Year, 0, null, "This year (to date)"),

    		new Timeframe("Last Calendar", TimeframePeriod.Week, 1, "Last Calendar"),
    		new Timeframe("Last Calendar", TimeframePeriod.Month, 1, "Last Calendar"),
    		new Timeframe("Last Calendar", TimeframePeriod.Quarter, 1, "Last Calendar"),
    		new Timeframe("Last Calendar", TimeframePeriod.Year, 1, "Last Calendar"),
    	]; 
    	if (withOldTimeframes) {
    		const oldTimeframes: Timeframe[] = [
    			new Timeframe("Last", TimeframePeriod.Day, 1, null, "Yesterday"),
    			new Timeframe("Last", TimeframePeriod.Day, 7),
    			new Timeframe("Last", TimeframePeriod.Day, 21),
    			new Timeframe("Last", TimeframePeriod.Week, 2),
    			new Timeframe("Last", TimeframePeriod.Day, 30, null, "Last Month"),
    			new Timeframe("Last", TimeframePeriod.Month, 3),
    			new Timeframe("Last", TimeframePeriod.Month, 6),
    			new Timeframe("Last", TimeframePeriod.Day, 365, null, "Last Year"),
    			new Timeframe("Last", TimeframePeriod.Day, 730, null, "Last Two Years"),
    		];
    		timeframes = timeframes.concat(oldTimeframes);
    	}
    	return timeframes;
    }

    private static fieldToColumnNameDic: { [fieldName: string] : string; } = {
    	'DisplayName': 'Attribute Name',
    	'Description': 'Description'
    };

    private static dateOptions = { 
    	year: 'numeric', 
    	month: 'short', 
    	day: 'numeric'
    } as Intl.DateTimeFormatOptions;

    private static timeOptions = {
    	hour: '2-digit', 
    	minute: '2-digit', 
    	hour12: true 
    } as Intl.DateTimeFormatOptions;

    private static isNodeDisable(nodeId: string) {
    	return AttributeHelper.disableNodesIds.includes(nodeId);
    }
}