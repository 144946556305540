import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'master-detail-container',
	templateUrl: './masterDetailContainer.component.html',
	styleUrls: ['./masterDetailContainer.component.scss']
})
export class MasterDetailContainerComponent {
	constructor() {}
}
