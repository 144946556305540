import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface CacheItem<T> {
	created: Date;
	value: T;
	timeout: number;
}

@Injectable({
	providedIn: 'root'
})
export class LocalStorageCacheService {
	private readonly defaultTimeout = 60 * 1000;

	getOrCreate<T>(id: string, factory: Observable<T>, timeout?: number): Observable<T> {
		if (!timeout) {
			timeout = this.defaultTimeout;
		}

		return of(this.get<T>(id)).pipe(
			switchMap((content) => {
				if (content === null) {
					return factory.pipe(
						tap((value) => {
							const cacheItem = {
								created: new Date(),
								value,
								timeout
							} as CacheItem<T>;
							localStorage.setItem(id, JSON.stringify(cacheItem));
						})
					);
				} else {
					return of(content);
				}
			})
		);
	}

	get<T>(id: string): T | null {
		const content = localStorage.getItem(id);
		if (content === null) {
			return null;
		}

		const cacheItem: CacheItem<T> = JSON.parse(content);
		if (<any>new Date() - <any>new Date(cacheItem.created) < cacheItem.timeout) {
			return cacheItem.value;
		}
		return null;
	}
}
