import {Component, Input, EventEmitter, Output, ViewEncapsulation, QueryList, ViewChildren} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { IActionData } from "../../campaignAnalysis.models";
import {ChannelMetricsActionComponent} from "../channelMetricsAction/channelMetricsAction.component";

@Component({
    selector: 'channel-metrics-body',
    templateUrl: './channelMetricsBody.component.html',
    styleUrls: ['./channelMetricsBody.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChannelMetricsBodyComponent {

    public actions: IActionData[];

    @Input() isLoading: boolean;
    @Input() templateDialogClass: string;
    @Input() datesData: any;
    @Input() isScheduledCampaign: any;
    @Input() planIdActionSerial: any;
    @Input() set actionsData(value: IActionData[]) {
        this.actions = value
    }
    @Output() metricClick: EventEmitter<{ metricId, channelId, planDetailChannelId, metricName, templateId, dates, multiId }> = new EventEmitter();

    @ViewChildren(ChannelMetricsActionComponent) channelMetricsActions: QueryList<ChannelMetricsActionComponent>;

    onMetricClick(payload) {
        this.metricClick.emit(payload);
    }

}
