import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {

    /**
    *
    * @param objArr Array of json objects
    * @param exportedFilename File name including extension
    * @param fileDelimiter exported file delimiter
    * @returns Observable<boolean>
    */
    public createExcelFileFromObjectArray(objArr: any[], exportedFilename: string): Observable<boolean> {

        return new Observable<boolean>(observer => {
            const { fileDelimiter, fileType } = this.getFileMetadata(exportedFilename);

            if (!fileDelimiter || !fileType) {
                observer.error('Unsupported format');
                observer.complete();
                return;
            }

            const headers = this.setHeaders(objArr)

            if (headers) {
                objArr.unshift(headers);
            }

            const jsonObject = JSON.stringify(objArr);

            const fileLinesString = this.convertToFileLinesString(jsonObject, fileDelimiter);

            this.downloadFile(fileLinesString, fileType, exportedFilename);
            observer.next(true);
            observer.complete();
        });

    }
    /**
     *
     * @param fileData File string data
     * @param exportedFilename File name including extension
     * @returns Observable<boolean>
     */
    public createFileFromStringData(fileData: string, exportedFilename: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const { fileDelimiter, fileType } = this.getFileMetadata(exportedFilename);

            if (!fileDelimiter || !fileType) {
                observer.error('Unsupported format');
                observer.complete();
                return;
            }
            this.downloadFile(fileData, fileType, exportedFilename);
            observer.next(true);
            observer.complete();
        });

    }

    private getFileMetadata(filename: string): { fileDelimiter: string, fileType: string } {
        const re: RegExp = /(?:\.([^.]+))?$/;
        const ext = re.exec(filename)[1];
        switch (ext.toLowerCase()) {
            case 'csv':
                return {
                    fileDelimiter: ',',
                    fileType: 'text/csv;charset=utf-8;',
                }
            case 'xls':
                return {
                    fileDelimiter: '\t',
                    fileType: 'application/vnd.ms-excel;charset=utf-8;',
                }
            default:
                return {
                    fileDelimiter: null,
                    fileType: null,
                };
        }
    }

    private downloadFile(fileData: string, fileType: string, exportedFilename: string) {
        const blob = new Blob([fileData], { type: fileType });

        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    private setHeaders(objArr: any[]) {
        const row = objArr[0];

        let header: any = {};

        for (const property in row) {
            header[property] = property;
        }

        return header;
    }

    private convertToFileLinesString(objArr: any[] | string, delemiter: string = ',') {
        var array = typeof objArr != 'object' ? JSON.parse(objArr) : objArr;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += delemiter

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

    public createAndDownloadCsv(csvContent: string, csvFileName: string) {
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent));
        a.setAttribute('download', csvFileName);
        window.document.body.appendChild(a);
        a.click();
    }
}
