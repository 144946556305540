<div class="panel-body">
    <div class="row">
        <div class="col-md-6">
            <h2>
                {{ translateKeys.TITLE | translate }}
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <p class="small">
                {{ translateKeys.DESCRIPTION | translate }}
            </p>
        </div>
        <div class="col-md-4 text-right header-btns">
            <ul class="list-inline">
                <li>
                    <button data-qa-id="SelectAttributesButton" 
                            (click)="openSelectAttributeSideBar()" 
                            class="btn-link">
                        <i class="material-icons">playlist_add_check</i>
                        <span>
                            {{ translateKeys.SELECT_ATTRIBUTE | translate }}
                        </span>
                    </button>
                </li>
                <li>
                    <button data-qa-id="ExportSnapshotsTabteButton" 
                    [disabled]="!hasListData" csv-exporter [fileName]="attributesListCsvFileName"
                    [headers]="attributesListCsvFileHeaders" [exportedData]="attributesData"
                    [objectCsvSerializerFunc]="attributesListCsvSerializerFunc"
                            class="btn-link">
                        <i class="material-icons">open_in_browser</i>
                        <span>
                            {{ translateKeys.EXPORT | translate }}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 fs-block">
            <attributes-grid
                data-qa-id="SnapshotsTabte"
                [clientCustomerId]="clientCustomerId"
                [customerAttributes]="checkedCustomerAttributesKeys"
                [customerAttributeMappingList]="customerAttributesList"
                (onDataLoaded)="onDataLoaded.emit(true)"
                (onExportReady)="onExportReady($event)">
            </attributes-grid>
        </div>
    </div>
</div>

