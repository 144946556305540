import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NavbarTreeNode } from '../../../../modules/navbar/models/navbarTreeNode.model';
import NavbarTreeData from '../../../../modules/navbar/models/navbarTreeData'
import { PermissionFormModel, PermissionsConfigFormModel, RoleRepmissionInfo, TenantRolesInfo, UserPermission } from '../rolePermissions.model'
import { IListItem } from "@optimove/ui-sdk/common/models";
import { RolePermissionsService } from '../services/rolePermissions.service';
import { SwitchButtonType } from '../../../../components/switchButton/switchButtonType.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'permissions-manager',
	templateUrl: './permissionsManager.component.html',
	styleUrls: ['./permissionsManager.component.scss']
})
export class PermissionsManagerComponent implements OnInit, OnDestroy {
  @Input() roleNames: string[];
  @Input() editedRoleName: string;
  @Input() landingPage: string = null;
  @Input() tenantRoles: TenantRolesInfo;
  @Input() restrictedPermissions: Record<number, number>;
  @Input() allPermissions: UserPermission[];
  @Input() editedUserPermissions: Record<number, number>;
  @Output() roleName = new EventEmitter<string>();
  @Output() permissionsForm = new EventEmitter<PermissionsConfigFormModel>();
  @Input() className: string | string[] | Set<string> | { [klass: string]:string};

  public form: FormGroup;
  private pageId = 1;
  public landingPagesList: IListItem[] = [];
  public SwitchButtonType = SwitchButtonType;
  public rolePermissions: RoleRepmissionInfo[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly rolePermissionsService: RolePermissionsService
  ) { }

  ngOnInit(): void {
  	this.isRoleDefaultEdit();
  	this.getLandingPagesList(NavbarTreeData);
  	this.rolePermissions = this.rolePermissionsService.createPermissionsObj(this.editedUserPermissions ? this.editedUserPermissions : this.tenantRoles[this.editedRoleName].Permissions, this.restrictedPermissions, this.allPermissions);
  	this.createForm();
  	this.setFormValue();
  	this.observePermissinsForm();
  }

  private getLandingPagesList(navbarTreeData: NavbarTreeNode[]) {
  	navbarTreeData.map(navbarItem => {
  		if(navbarItem.isLandingPage){
  			let landingPage: IListItem = {
  				id: this.pageId,
  				name: navbarItem.name
  			}
  			this.landingPagesList.push(landingPage); 
  			this.pageId++
  		}

  		if(navbarItem?.children){
  			this.getLandingPagesList(navbarItem.children);
  		}
  	})
  }

  public getRoleLandingPage() {
  	const roleLandingPageName = this.landingPage ?? this.tenantRoles[this.editedRoleName].LandingPage;
  	const roleLandingPageItem = this.landingPagesList.find(pageItem => pageItem.name === roleLandingPageName);
  	if(roleLandingPageItem){
  		return roleLandingPageItem.id
  	} else {
  		const defaultRoleLandingPage = this.landingPagesList.find(pageItem => pageItem.name === 'Dashboard');
  		return defaultRoleLandingPage.id
  	}
  }

  public getRoleLandingPageName() {
  	const roleLandingPageItem = this.landingPagesList.find(pageItem => pageItem.id === this.landingPageControl.value);
  	return roleLandingPageItem.name
  }

  public changeEditedRole(roleName) {
  	if(this.editedRoleName != roleName) {
  		this.editedRoleName = roleName;
  		this.rolePermissions = this.rolePermissionsService.createPermissionsObj(this.tenantRoles[roleName].Permissions, this.restrictedPermissions, this.allPermissions);
  		this.setFormValue();
  		this.observePermissinsForm();
  		this.form.markAsPristine();
  		this.roleName.emit(this.editedRoleName);
  	}
  }

  public isRoleDefaultEdit() {
  	return this.roleNames;
  }

  public createForm() {
  	const permissionControls = this.rolePermissions.map(permissionItem => new FormGroup({
  		permissionName: new FormControl({value: permissionItem.permissionName, disabled: permissionItem.restricted}),
  		permissionId: new FormControl(permissionItem.id),
  		isSelected: new FormControl({value: permissionItem.selected, disabled: permissionItem.restricted})
  	}));

  	this.form = new FormGroup({
  		landingPage: new FormControl(),
  		permissions: new FormArray(permissionControls)
  	});

  }

  private setFormValue() {
  	const permissionControl = this.rolePermissions.map(permissionItem => {
  		return {
  			permissionName: permissionItem.permissionName,
  			permissionId: permissionItem.id,
  			isSelected: permissionItem.selected
  		} as PermissionFormModel
  	});

  	this.landingPageControl.setValue(this.getRoleLandingPage());
  	this.permissionsArray.setValue(permissionControl);
  }

  get permissionsArray(): FormArray {
  	return this.form.get('permissions') as FormArray;
  }

  get landingPageControl(): FormControl {
  	return this.form.get('landingPage') as FormControl;
  }

  public observePermissinsForm(){
  	this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
  		this.permissionsForm.emit(this.getPermissionConfigFormValue());
  	})
  } 

  private getPermissionConfigFormValue() :PermissionsConfigFormModel{
  	return {
  		landingPage: this.getRoleLandingPageName(),
  		permissions: this.permissionsArray.value
  	} as PermissionsConfigFormModel
  }

  public setUserToRoleDefaultPermissions() {
  	this.rolePermissions = this.rolePermissionsService.createPermissionsObj(this.tenantRoles[this.editedRoleName].Permissions, this.restrictedPermissions, this.allPermissions);
  	this.setFormValue();
  	this.observePermissinsForm();
  }

  public onCancelClick() {
  	this.setFormValue();
  	this.observePermissinsForm();
  	this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  	this.destroyed$.next(true);
  	this.destroyed$.complete();
  }
}