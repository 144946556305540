import {Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'osl-group',
    templateUrl: './oslGroup.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslGroup.component.scss']
})
export class OslGroupComponent {
    @Input() label: string;
}