import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsmService } from "./ssm.service";

@Injectable()
export class PendoEventsService {
    readonly API_URL: string = 'https://app.pendo.io/data/track';
    private ssm;
    private tenantDetails;
    private siteName;

    constructor(private readonly httpClient: HttpClient, private readonly ssmService: SsmService) {
        this.ssm = this.ssmService.getSSM();
        this.tenantDetails = this.ssm.GetGeneric(this.ssm.Resx.TenantDetails);
        this.siteName = this.ssm.GetGeneric(this.ssm.Resx.SiteName);
        this.siteName = this.siteName.replace(/"/g, "");
    }

    addPendoEvent(eventName: string) {
        if(this.tenantDetails && this.siteName && this.siteName.indexOf('Optimove_') !== -1) {
            this.siteName = this.siteName.split('Optimove_')[1];
        }
        const body = {
            type : "track",
            event : eventName,
            visitorId : this.tenantDetails.userId,
            accountId : this.tenantDetails.tenantId,
            timestamp : new Date().getTime(),
            context: {}
        }


        return this.httpClient.post(this.API_URL, body);
    }
}