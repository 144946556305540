<div class="channel-metrics-number">
    <div class="cmn-title">
        <div [style.background]="metric.color" class="cmn-title-color"></div>
        <ng-template #popTemplate><div [innerHtml]="tooltip"></div></ng-template>
        <span [tooltip]="popTemplate">
            {{metricsTranslatePath + metric.name | translate}}
        </span>
    </div>
    <div class="cmn-big">
        <span *ngIf="metric.percent < 0" class="emdash"></span>
        <span *ngIf="metric.percent >= 0">
            {{ metric.percent | formatter:metric.percent > 1 ? '0%' : '0.00%' :{ shouldHandleSmallNumbers: false, shouldHandleBigNumbers: true } }}
        </span>
    </div>
    <div class="cmn-small">
        <p class="small" *ngIf="!isNavigatable(metric)">{{metric.number | formatter:'0,'}}</p>
        <span class="navigatable" *ngIf="isNavigatable(metric)" (click)="onMetricClick(metric)">{{metric.number | formatter:'0,'}}</span>

        <button type="button" class="tag-container btn btn-secondary clickMapButton" (click)="onMapClick(metric)" *ngIf="channelId == 15 && metric.id == 3" [disabled]="!isTemplateMetricsExists">
            {{'components.templatePreview.CLICK_MAP' | translate}}
        </button>

    </div>
</div>
