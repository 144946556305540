import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ElementRef, ViewEncapsulation, ChangeDetectorRef, Optional, Self } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    NG_VALIDATORS,
    Validator,
    FormControl,
    ValidationErrors,
    AbstractControl,
    NgControl
} from "@angular/forms";
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService, BsDatepickerDirective, BsDatepickerInputDirective } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { validateDate } from './validators/validDateValidator.directive';
import { Subscription } from 'rxjs';
import { minDateValidator } from './validators/minDateValidator.directive';
import { maxDateValidator } from './validators/maxDateValidator.directive';

@Component({
    selector: 'date-picker',
    templateUrl: './datePicker.component.html',
    providers: [
        DatePipe
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./datePicker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

    @ViewChild('dp', { static: false }) bsDatepicker: BsDatepickerDirective;

    @Input() datePickerData: IDatePicker;
    @Input() showSelfValidationMessages = false;
    //@Input('required') isRequired = false;
    @Output() datePickerDataChange = new EventEmitter();

    public disabled: boolean;
    public inputControl: FormControl;

    private onChangeEmitterFunc: any;
    private onTouchEmitterFunc: any;
    private valueChangeSubscription: Subscription;
    private inputText: string;
    private backupNgxValidate: (c: AbstractControl) => ValidationErrors;

    constructor(private localeService: BsLocaleService, private cd: ChangeDetectorRef, @Optional() @Self() public controlDirective: NgControl) {
        if (this.controlDirective) {
            this.controlDirective.valueAccessor = this;
        }
        
        // Set week start to be monday
        defineLocale(enGbLocale.abbr, enGbLocale);
        this.localeService.use(enGbLocale.abbr);
    }

    ngOnInit(): void {
        if (this.controlDirective) {
            this.controlDirective.control.setValidators([this.validate.bind(this)]);
            this.controlDirective.control.updateValueAndValidity({ emitEvent: false });
        }

        if (!this.inputControl) {
            this.inputControl = new FormControl(null);
        }
        this.valueChangeSubscription = this.inputControl.valueChanges.subscribe(this.onValueChange.bind(this));
        //this.inputControl.setValidators([this.validate.bind(this)]);
        this.backupNgxValidate = this.overrideDefaultPickerValidation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.datePickerData && changes.datePickerData.previousValue != null && this.datePickerData.minDate !== changes.datePickerData.previousValue.minDate) {
            this.onChangeEmitterFunc(this.inputControl.value);
        }
    }

    onValueChange(date: Date): void {
        if (this.inputText && this.inputText !== 'Invalid date') {
            let dateFromText: Date  = this.parseStringToDate(this.inputText);
            if (dateFromText !== null) {
                date = dateFromText;
                this.inputControl.setValue(date, { emitEvent: false });
            }
            this.inputText = null;
        }

        this.datePickerDataChange.emit(date);
        this.onChangeEmitterFunc && this.onChangeEmitterFunc(date);
        this.onTouchEmitterFunc && this.onTouchEmitterFunc();
    }

    // we've got our own logic for datePicker validation
    overrideDefaultPickerValidation(): (c: AbstractControl) => ValidationErrors {
        let backupValidate = BsDatepickerInputDirective.prototype.validate;
        BsDatepickerInputDirective.prototype.validate = function(c: AbstractControl): ValidationErrors | null {
            this.writeValue(c.value);
            return null;
        };
        return backupValidate;
    }

    onInputChange(event: Event): void {
        let inputText = (event as any).target?.value;
        this.inputText = inputText;
    }

    onblur() {
        this.onTouchEmitterFunc();
    }

    writeValue(date: Date): void {
        if (!this.inputControl) {
            this.inputControl = new FormControl(null);
        }
        this.inputControl.setValue(date, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChangeEmitterFunc = fn;
    }

    parseStringToDate(str: string) {
        let res: Date = null;
        const dateFormats = ['MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY', 'MM-DD-YY'];
        for (let i = 0; i < dateFormats.length && res === null; i++) {
            const format = dateFormats[i];
            const strict = true;
            const momentDate = moment(str, format, strict);
            momentDate.set({hour:0,minute:0,second:0,millisecond:0});
            if (momentDate.isValid()) {
                res = momentDate.toDate();
            }
        }

        return res;
    }

    registerOnTouched(fn: any): void {
        this.onTouchEmitterFunc = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.inputControl.disable({ emitEvent: false });
        }
        else {
            this.inputControl.enable({ emitEvent: false });
        }
        this.cd.detectChanges();
    }

    validate(c: FormControl): ValidationErrors | null {
        let controlValidationRes: ValidationErrors = null;

        const validationRes = validateDate(c);
        if (validationRes) {
            controlValidationRes = { ...controlValidationRes, ...validationRes }
        }

        if (this.datePickerData && this.datePickerData.minDate) {
            const validateMinDate = minDateValidator(this.datePickerData.minDate);
            controlValidationRes = { ...controlValidationRes,  ...validateMinDate(c) };
        }

        if (this.datePickerData && this.datePickerData.maxDate) {
            const validateMaxDate = maxDateValidator(this.datePickerData.maxDate);
            controlValidationRes = { ...controlValidationRes,  ...validateMaxDate(c) };
        }

        //if (this.isRequired) {
        //    const requiredValidator = new RequiredValidator();
        //    controlValidationRes = {...controlValidationRes, ...requiredValidator.validate(c)};
        //}

        return controlValidationRes;
    }

    onIconClick() {
        if (this.disabled) {
            return;
        }

        this.bsDatepicker.show();
    }

    ngOnDestroy(): void {
        this.valueChangeSubscription && this.valueChangeSubscription.unsubscribe();
        BsDatepickerInputDirective.prototype.validate = this.backupNgxValidate;
    }
}

export interface IDatePicker {
    minDate?: Date,
    maxDate?: Date,
    placeholder?: string,
    placement?: 'top' | 'bottom' | 'left' | 'right' | 'top left' |  'top right' | 'bottom left' |  'bottom right',
    container?: string;
    adaptivePosition?: boolean;
}
