<div class="unique-promo-container">

    <div class="panel-heading">
        <h2 data-qa-id="uniquePromoCodesTitle"> {{'features.user_settings.body.uniquePromoCodes.TITLE' | translate }} </h2>
        <a class="btn btn-font-size pull-right btn-link add-new-promo-code-btn" data-qa-id="uniqueCodesAddNewPromo" href="/#/user-settings/uniquePromoCodes/addUniquePromoCode">
            <i class="material-icons">add</i>
            <span>{{ 'features.user_settings.body.uniquePromoCodes.ADD_NEW_PROMOTION' | translate }}</span>
        </a>
    </div>

    <div class="panel-body">
        <input-search [term]="filterText"
            [placeholder]="'features.user_settings.body.uniquePromoCodes.FIND_BY_PROMOTION_NAME' | translate"
            (termChange)="onTextFilterChanged($event)" class="search">
        </input-search>
        <div class="unique-promo-codes-grid-container">
            <ag-grid-angular *ngIf="gridOptions && !emptyState" [gridOptions]="gridOptions" [modules]="modules"
                [rowData]="rowData" class="ag-theme-balham ag-theme-white" data-qa-id="uniquePromocodeGrid">
            </ag-grid-angular>
            <div class="skeleton-table" *ngIf="!isLoaded">
                <!--[@fadeInOutAnimation]-->
                <div class="skeleton-row" *ngFor="let item of [].constructor(4)">
                    <div class="skeleton-column skeleton-chevron-column">
                        <span class='skeleton-line'></span>
                    </div>
                    <div class="skeleton-column">
                        <span class='skeleton-line'></span>
                        <span class='skeleton-line skeleton-second-line'></span>
                    </div>
                    <div class="skeleton-column">
                        <span class='skeleton-line'></span>
                        <span class='skeleton-line skeleton-second-line'></span>
                    </div>
                    <div class="skeleton-column" *ngFor="let item of [].constructor(3)">
                        <span class='skeleton-line'></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <opti-loading [show]="loading" [size]="'md'" ></opti-loading>
</div>