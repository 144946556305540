import { Component, Input, OnInit } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { TemplateQueryParams } from '../../../models/templateQueryParams';
import { TemplateContextService } from '../../../services/templateContext.service';
import { ErrorModalService } from '../../../services/errorModal.service';
import { FolderService } from '../../../services/folder.service';

@Component({
	selector: 'move-folder-modal',
	templateUrl: './moveFolderModal.component.html',
	styleUrls: ['./moveFolderModal.component.scss']
})
export class MoveFolderModalComponent implements OnInit {
	@Input() channelId: number;
	@Input() folderId: number;
	@Input() parentFolderId: number;
	@Input() subMethodId: number;
	@Input() folderMoved: (id: number) => void = () => {};
	@Input() isChildNode: (sourceNodeId: number, targetNodeId: number, submethod: number) => boolean;
	callback: (deleteResponse: number) => void;
	modalConf: IModalConf;
	selectedSubMethodId: number;
	selectedFolderId: number | null;
	isSavingTemplate: boolean = false;
	queryParams: TemplateQueryParams;

	constructor(
		public bsModalRef: BsModalRef,
		private translateService: TranslateService,
		private templateContextService: TemplateContextService,
		private templateService: ManageTemplatesService,
		private errorModalService: ErrorModalService
	) {}

	public setFolderId($event) {
		this.selectedFolderId = $event;
	}

	public setSubMethodId($event) {
		this.selectedSubMethodId = $event;
	}

	ngOnInit(): void {
		this.initModalConfig();
		this.templateContextService.queryTemplateParams.subscribe((queryParams) => {
			this.queryParams = queryParams;
			this.selectedFolderId = +this.queryParams.folderId;
			this.selectedSubMethodId = this.subMethodId ?? +this.queryParams.typeId;
		});
	}

	public canMove(): boolean {
		return (
			!!this.selectedSubMethodId &&
			!!this.selectedFolderId &&
			this.selectedFolderId !== this.folderId &&
			this.selectedFolderId !== this.parentFolderId
		);
	}

	private moveFolder(): void {
		this.isSavingTemplate = true;
		if (!this.isChildNode(this.folderId, this.selectedFolderId, this.selectedSubMethodId)) {
			this.templateService
				.moveFolderOrTemplate({
					nodeId: this.folderId,
					isFolder: true,
					parentId: this.selectedFolderId,
					subMethodId: this.selectedSubMethodId
				})
				.subscribe((result) => {
					if (result.isSuccess) {
						this.folderMoved(this.folderId);
						this.isSavingTemplate = false;
						this.callback(this.folderId);
					} else {
						this.errorModalService.openErrorModal(
							this.translateService.instant('features.manage_templates.optimail.templateFolderActions.MOVE_FOLDER'),
							this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_MOVE_FOLDER')
						);
					}
				});
		} else {
			this.errorModalService.openErrorModal(
				this.translateService.instant('features.manage_templates.optimail.templateFolderActions.MOVE_FOLDER'),
				this.translateService.instant('features.manage_templates.optimail.templateFolderActions.ACTION_MOVE_FOLDER')
			);
		}
		this.bsModalRef.hide();
	}
	private initModalConfig(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.move_folder_modal.TITLE'),
			description: this.translateService.instant('features.manage_templates.components.move_folder_modal.DESCRIPTION'),
			buttons: [
				{
					isDisabled: () => !this.canMove() || this.isSavingTemplate,
					isLoading: () => this.isSavingTemplate,
					loadingText: null,
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.components.move_folder_modal.OK_BTN'),
					action: () => this.moveFolder()
				},
				{
					isDisabled: () => false,
					class: 'btn-default',
					label: this.translateService.instant('features.manage_templates.components.move_folder_modal.CANCEL_BTN'),
					action: () => this.bsModalRef.hide()
				}
			],
			hideHeaderBorder: false,
			hideFooterBorder: false
		};
	}
}
