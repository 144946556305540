<div class="save-row-modal">
  <div class="panel-heading">
    <h2>{{ "features.manage_templates.components.beefreeEditRows.TITLE" | translate }}</h2>
  </div>

  <div class="panel-body">
    <div class="form-horizontal">
      <label class="col-sm-3 control-label">{{ "features.manage_templates.components.beefreeSavedRows.ROW_NAME" | translate }}</label>
      <div class="col-sm-9">
        <input class="form-control" type="text" id="customRowName" [(ngModel)]="rowData.name" [formControl]="customRowTitle" />
      </div>
    </div>
  </div>

  <div class="panel-footer">
    <div class="buttons pull-rights text-right">
      <button (click)="onCancel()" type="button" class="btn btn-default cancel-btn">
        {{ "general.CANCEL" | translate }}
      </button>
      <button
        (click)="editeRow(customRowTitle.value)"
        [disabled]="customRowTitle.value.length === 0 || customRowTitle.value === customRowOldTitle"
        type="button"
        class="btn btn-primary add-btn">
        {{ "features.manage_templates.components.beefreeEditRows.EDIT" | translate }}
      </button>
    </div>
  </div>
</div>
