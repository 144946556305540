<form *ngIf="form" [formGroup]="form">
    <div formArrayName="multipleConditionEditors">
        <div *ngFor="let editor of editors.controls; let i = index" style="height: max-content;">
            <div [ngStyle]="{'margin-bottom': i === editors.controls.length - 1 ? '20px' : '40px'}">
                <multiple-condition-editor
                    id="condition-editor-{{i}}"
                    [formControlName]="i"
                    [config]="editorsConfigs[i]"
                    [isDuplicateEnable]="editors.length < maxEditorsCount"
                    [isValueAlreadyExist]="editorsValues[i].alreadyExist"
                    (duplicate)="duplicateEditor(i)"
                    (delete)="deleteEditor(i)"
                    (valueChanged)="onEditorValueChanged(i, $event)" >
                </multiple-condition-editor>
            </div>
        </div>
    </div>
    <button data-qa-id="addElseIfBtn"
            class="btn btn-secondary add-if-else-btn action-btn" 
            type="button" (click)="addEditor()" 
            [disabled]="editors.length >= maxEditorsCount || config.isDisabled">
        {{translateKeys.add | translate}}
    </button>
    <div class="value-input">
        <label>
            <strong>{{translateKeys.else | translate}} </strong> 
            {{translateKeys.elseVal | translate}} 
        </label> 
        <input data-qa-id="elseValue" 
                id="elseValue"
                formControlName="elseValue"
                type="text"
                class="text-input"
                [ngClass]="{'red-border': elseValue.invalid}"
                placeholder="{{translateKeys.value | translate}}"
                [disabled]="config.isDisabled">
    </div> 
</form>

