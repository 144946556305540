import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from "@angular/forms";
import { Directive } from "@angular/core";

export function validateDate(control: AbstractControl): ValidationErrors | null {
    const d = control.value;
    const isValid = d instanceof Date && !isNaN(d.valueOf());
    return isValid ? null : { invalidDate: true };
}

@Directive({
    selector: "[validDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidDateValidatorDirective,
        multi: true
    }]
})
export class ValidDateValidatorDirective implements Validator {
    validate(c: AbstractControl): { [key: string]: any; } {
        return validateDate(c);
    }
}