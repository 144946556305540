import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, PRIMARY_OUTLET } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { TemplateDetails } from '../models/templateDetails';
import { EditorType } from '../models/editorType';
import { ManageTemplatesService } from './manageTemplates.service';
import { TemplateQueryParams } from '../models/templateQueryParams';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { SubMethodService } from './subMethodService';
import { RoutingConsts } from '../models/routing.consts';
import { TemplateAvailableLanguage } from '../models/templateAvailableLanguage';
import { NumberOfLanguagesPerTemplate, TemplateInUse } from '../models/templateInUse';
import { ChannelService } from './channel.service';

@Injectable({
	providedIn: 'root'
})
export class TemplateContextService {
	private originalTemplate: TemplateDetails;
	private $current: BehaviorSubject<TemplateDetails> = new BehaviorSubject<TemplateDetails>(null);
	public current: Observable<TemplateDetails> = this.$current.asObservable();

	private $queryTemplateParamsInternal: BehaviorSubject<TemplateQueryParams> = new BehaviorSubject<TemplateQueryParams>({});
	private $queryTemplateParams: BehaviorSubject<TemplateQueryParams> = new BehaviorSubject<TemplateQueryParams>({});
	public queryTemplateParams: Observable<TemplateQueryParams> = this.$queryTemplateParams.asObservable();

	private $channelId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
	public channelId: Observable<string> = this.$channelId.asObservable();
	private $isAnyTemplatesOrFolders: Subject<boolean> = new Subject<boolean>();
	public isAnyTemplatesOrFolders: Observable<boolean> = this.$isAnyTemplatesOrFolders.asObservable();
	private $isTemplatesInUse: BehaviorSubject<TemplateInUse[]> = new BehaviorSubject<TemplateInUse[]>(null);
	public isTemplatesInUse: Observable<TemplateInUse[]> = this.$isTemplatesInUse.asObservable();

	private $numberOfLanguages: BehaviorSubject<NumberOfLanguagesPerTemplate[]> = new BehaviorSubject<NumberOfLanguagesPerTemplate[]>(null);
	public numberOfLanguages: Observable<NumberOfLanguagesPerTemplate[]> = this.$numberOfLanguages.asObservable();
	private $isChannelSupportOtherEditors: Subject<boolean> = new BehaviorSubject<boolean>(true);
	public isChannelSupportOtherEditors: Observable<boolean> = this.$isChannelSupportOtherEditors.asObservable();
	private $isCurrentTemplateDirtyChange: Subject<boolean> = new BehaviorSubject<boolean>(true);
	public isCurrentTemplateDirtyChange: Observable<boolean> = this.$isCurrentTemplateDirtyChange
		.asObservable()
		.pipe(distinctUntilChanged());

	private $contentActivated: Subject<boolean> = new Subject<boolean>();
	public contentActivated: Observable<boolean> = this.$contentActivated.asObservable();

	constructor(
		private readonly manageTemplatesService: ManageTemplatesService,
		private route: ActivatedRoute,
		private router: Router,
		private subMethodService: SubMethodService,
		private channelService: ChannelService
	) {
		combineLatest([
			this.$queryTemplateParamsInternal.pipe(distinctUntilChanged()),
			this.channelId.pipe(distinctUntilChanged())
		]).subscribe((results) => {
			const params = { ...results[0] };
			if (results[1] && Number.isInteger(+results[1])) {
				params.channelId = results[1];
			}
			this.$queryTemplateParams.next(params);
		});

		this.router.events.subscribe((events) => {
			if (events instanceof NavigationEnd) {
				const urlTree = this.router.parseUrl(events.url);
				const segmentGroup = urlTree.root.children[PRIMARY_OUTLET];
				const indexOfShow = segmentGroup.segments.findIndex((s) => s.path === 'show');
				if (
					!segmentGroup.segments.find(
						(s) =>
							s.path === RoutingConsts.MANAGE_TEMPLATES_USING_OLD_EDITOR_PART ||
							s.path === RoutingConsts.MANAGE_TEMPLATES_PART
					)
				) {
					this.$channelId.next('');
					return;
				}

				if (indexOfShow === -1 || segmentGroup.segments.length < indexOfShow + 1) {
					return;
				}

				this.$channelId.next(segmentGroup.segments[indexOfShow + 1].path);
			}
		});

		this.route.queryParams
			.pipe(
				filter((params: TemplateQueryParams) => {
					const currentParams = this.$queryTemplateParamsInternal.value;
					return !currentParams.typeId || JSON.stringify(params) !== JSON.stringify(currentParams);
				})
			)
			.subscribe((params: TemplateQueryParams) => {
				const internalParams = {
					...params,
					visualeditor: params.visualeditor ? JSON.parse((params.visualeditor as unknown as string).toLowerCase()) : 0
				};
				this.$queryTemplateParamsInternal.next(internalParams);
				const templateId = params.templateId;
				const channelId = params.channelId;
				if (+templateId && !channelService.isOptimobile(+channelId)) {
					this.updateTemplateFromBackEnd(+templateId);
				}
				if (+params.channelId) {
					this.$channelId.next(params.channelId);
				}
			});
		this.current.subscribe(() => this.updateIsCurrentTemplateDirty());
	}

	templateType(template: TemplateDetails): EditorType {
		if (template) {
			return template.otherEditor ? EditorType.beefree : EditorType.froala;
		}
		return EditorType.none;
	}

	updateTemplate(template: TemplateDetails): void {
		if (!template) return;
		this.originalTemplate = JSON.parse(JSON.stringify(template));
		this.$current.next(template);
	}

	getOriginalTemplate(): TemplateDetails {
		return this.originalTemplate;
	}

	updateTemplateFromBackEnd(templateId: number) {
		this.manageTemplatesService.getTemplateDetails({ templateId: templateId.toString() }).subscribe((response: TemplateDetails) => {
			if (response && response.templateId) {
				this.updateTemplate(response);
			}
		});
	}

	updateTemplateType(otherEditor: boolean) {
		const template = this.$current.getValue();
		if (template) {
			template.otherEditor = otherEditor;
		}
		this.$current.next(template);
	}

	updateTemplateExtraData(extraData: any) {
		const template = this.$current.getValue();
		if (template.extraData === extraData) return;
		template.extraData = extraData;
		this.$current.next(template);
	}

	updateLanguages(newValue: TemplateAvailableLanguage[]) {
		const template = this.$current.getValue();
		template.languages = newValue;
		this.$current.next(template);
	}

	updateIsAnyTemplatesOrFolders(isAnyTemplatesOrFolders: boolean) {
		this.$isAnyTemplatesOrFolders.next(isAnyTemplatesOrFolders);
	}

	refreshTemplatesInUse(templateIdsWithSubMethod: { templateId: number; subMethodId: number }[]): void {
		this.manageTemplatesService.getTemplatesIsInUse({ templateIdsWithSubMethod: templateIdsWithSubMethod }).subscribe((resp) => {
			if (!resp) {
				return;
			}

			this.$isTemplatesInUse.next({
				...this.$isTemplatesInUse.value,
				...resp
			});
		});
	}

	refreshNumberOfLanguages(templateIdsPerSubMethod: { templateId: number; subMethodId: number }[]): void {
		this.manageTemplatesService.getNumberOfLanguages({ templateIdsWithSubMethod: templateIdsPerSubMethod }).subscribe((res) => {
			if (res && res.length) {
				this.$numberOfLanguages.next({
					...this.$numberOfLanguages.value,
					...res
				});
			}
		});
	}

	isDirty(): boolean {
		const currentTemplate = this.$current.value;
		if (!currentTemplate || !this.originalTemplate) {
			return false;
		}

		const result = currentTemplate.otherEditor ?
			this.isCurrentMetadataDirty() || this.isTemplateExtraDataDirty() :
			this.isCurrentTemplateDirty();
		return result;
	}

	isCurrentTemplateEmpty(): boolean {
		const currentTemplate = this.$current.value;
		if (!currentTemplate) {
			return true;
		}

		if (!currentTemplate.otherEditor) {
			return !!currentTemplate.html;
		}

		if (currentTemplate.extraData) {
			var extraData = JSON.parse(currentTemplate.extraData);
			if (extraData[1]?.page?.rows[0]?.columns[0]?.modules?.length > 0) {
				return false;
			}
		}
		return true;
	}

	resetCurrentTemplate(isBeefree: boolean): void {
		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			this.updateTemplate({
				extraData: '[null,{"page":{"title":"","description":"","rows":[],"body":{"container":{"style":{"background-color":"#FFFFFF"}}}, "template":{}}, "comments":{}}]',
				autoGeneratePlainText: true,
				templateName: '',
				otherEditor: isBeefree,
				templateId: 0,
				isValid: true,
				subMethodId: +this.$queryTemplateParams.value.typeId,
				executionMethodId: +this.$channelId.value,
				folderId: +this.$queryTemplateParams.value.folderId,
				languages: [],
				html: '<!DOCTYPE html><html><head><title></title></head><body></body></html>',
				subMethodType: this.subMethodService.findSubmethodType(metadata, +this.$queryTemplateParams.value.typeId)
			} as TemplateDetails);
		});
	}

	updateIsChannelSupportOtherEditors(otherEditor: boolean) {
		this.$isChannelSupportOtherEditors.next(otherEditor);
	}

	updateIsCurrentTemplateDirty(): void {
		this.$isCurrentTemplateDirtyChange.next(this.isCurrentTemplateDirty());
	}

	updateContentActivated(): void {
		this.$contentActivated.next(true);
	}

	private isCurrentTemplateDirty(): boolean {
		const currentTemplate = this.$current.value;
		if (!currentTemplate || !this.originalTemplate) {
			return false;
		}

		const tmpExtraDataArr = currentTemplate.extraData;
		const extraDataArr = this.originalTemplate.extraData;
		const tmpHtml = currentTemplate.html;
		const html = this.originalTemplate.html;

		const isValid = this.originalTemplate.isValid;
		this.originalTemplate.isValid = currentTemplate.isValid;

		const currentTemplateCopy = JSON.parse(JSON.stringify(this.originalTemplate));
		const tmpCurrentTemplateCopy = JSON.parse(JSON.stringify(currentTemplate));

		if (typeof currentTemplateCopy.html !== 'undefined' && currentTemplateCopy.html !== null) {
			currentTemplateCopy.html = currentTemplateCopy.html.replace(/[\t\r\n]+/g, '');
		}
		if (typeof tmpCurrentTemplateCopy.html !== 'undefined' && tmpCurrentTemplateCopy.html !== null) {
			tmpCurrentTemplateCopy.html = tmpCurrentTemplateCopy.html.replace(/[\t\r\n]+/g, '');
		}
		const isFieldsChanged = JSON.stringify(currentTemplateCopy) !== JSON.stringify(tmpCurrentTemplateCopy);
		
		currentTemplate.extraData = tmpExtraDataArr;
		this.originalTemplate.extraData = extraDataArr;
		currentTemplate.html = tmpHtml;
		this.originalTemplate.html = html;
		this.originalTemplate.isValid = isValid;

		return isFieldsChanged;
	}

	private isCurrentMetadataDirty(): boolean {
		const currentTemplate = this.$current.value;
		if (!currentTemplate || !this.originalTemplate) {
			return false;
		}

		const originalTemplateCopy = JSON.parse(JSON.stringify(this.originalTemplate));
		const currentTemplateCopy = JSON.parse(JSON.stringify(currentTemplate));

		currentTemplateCopy.html = '';
		originalTemplateCopy.html = '';
		currentTemplateCopy.extraData = '[]';
		originalTemplateCopy.extraData = '[]';

		const isDirty = JSON.stringify(currentTemplateCopy) !== JSON.stringify(originalTemplateCopy);
		return isDirty;
	}
	
	private isTemplateExtraDataDirty(): boolean {
		const currentContentProperties = [];
		const originalContentProperties = [];
		const currentTemplate = this.$current.value;
		if (!currentTemplate?.extraData || !this.getOriginalTemplate()?.extraData) {
			return false;
		}
		const currentExtraData = JSON.parse(currentTemplate.extraData)[1];
		const originalExtraData = JSON.parse(this.getOriginalTemplate().extraData)[1];
		this.findContentValues(currentExtraData, currentContentProperties);
		this.findContentValues(originalExtraData, originalContentProperties);

		const isDirty = JSON.stringify(currentContentProperties) !== JSON.stringify(originalContentProperties);
		return isDirty;
	}

	private findContentValues(obj, results: string[]) {
		if (!obj) {
			return;
		}
		for (let property in obj) {
			if (['html', 'text', 'label', 'src'].find(p => p === property)){
				results.push(JSON.stringify(obj[property]));
			}
			if (typeof obj[property] === 'object') {
				this.findContentValues(obj[property], results);
			}
		}
	}
}
