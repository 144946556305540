import { Injectable } from "@angular/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { Tagify } from '../../../../components/tagify/tagify.component';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { AttributeFormat, FormulaType } from '../attributesList.enums';
import { BaseAttribute, CalculatedAttribute, formulaOptions } from '../attributesList.model';


@Injectable()
export class FormulaService {

    baseUrl = "/AttributesManager";
    numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    operators = '+-*/';

    constructor(private readonly httpClient: HttpService) {}

    public getCustomerData(
        formula: string, 
        fieldset: string, 
        type: FormulaType, 
        format: AttributeFormat,
        attributeFormats: string[],
        clientCustomerId: string,
        ignoreYear): Observable<DefaultAjaxResponse> {
        return this.httpClient.post<DefaultAjaxResponse>(`${this.baseUrl}/GetCalculationResultForCustomer`, {formula, fieldset, type, format, attributeFormats, clientCustomerId, ignoreYear});
    }
    
    public checkFormula(formula: string, options: formulaOptions = {complexExpresion: false}) {
        let leftLimit = formula.lastIndexOf('(');
        let rightLimit = leftLimit + formula.substring(leftLimit).indexOf(')');
        while (leftLimit !== -1 && rightLimit !== -1) {
            if (leftLimit > rightLimit /*|| (leftLimit > 0 && !this.operators.includes(formula.charAt(leftLimit - 1)))*/) {
                return false;
            }
            const part = formula.substring(leftLimit + 1, rightLimit);
            if (this.checkInBrackets(part, options)) {
                formula = formula.replace(`(${part})`, ' 1 ');
                leftLimit = formula.lastIndexOf('(');
                rightLimit = leftLimit + formula.substring(leftLimit).indexOf(')');
            } else {
                return false;
            }
        }
        return this.checkInBrackets(formula, options);
    }

    private checkInBrackets(formula: string, options: formulaOptions): boolean {
        if(options.complexExpresion && formula.includes('-') && formula.includes('+')) {
            return false;
        }

        if (formula.includes("(") || 
            formula.includes(")") ||
            formula.includes(").") || (
                formula.includes('.') && (
                    formula[0] === '.' || 
                    formula[formula.length - 1] === '.'))) {
            return false;
        }

        let variables = formula.split(/[/]|[*]|[+]|[-]|[ ]/ig).filter(x => !!x);
        let actions = formula.split('').filter(x => this.operators.includes(x));

        const invalidFormulaCondition = variables.length - actions.length !== 1;
        const wrongPointUsage = formula.split('').some((x, i) => {
            return x === '.' 
                && ![formula.length - 1, 0].includes(i) 
                && (!this.numbers.includes(formula[i - 1]) || !this.numbers.includes(formula[i + 1]));
        });
        const doublePointInVariable = variables.some((x) => {
            return x.indexOf('.') !== x.lastIndexOf('.')
        });
        const minesBeforeFirstVariable = options.complexExpresion 
            ? false 
            : actions.length > 0 
            && formula.trimStart().substring(0,1) === '-'
            && actions[0] === '-' 
            && variables.length === actions.length;

        const divisionDiff = minesBeforeFirstVariable ? 0 : 1;
        const divisionByZero = actions.includes('/') && variables.length >= 2 
            ? actions.some((action, index) => {
                return action === '/' && variables[index + divisionDiff] && 
                    !variables[index + divisionDiff].split('').some((symbol: string) => {
                            return !['0', '.'].includes(symbol);
                        });
                    }) : false;

        let restoredFormula = "";
        let positionConditions = false;
        if (variables.length > 0 && actions.length > 0) {
            for (let index = 0; index < variables.length - 1; index++) {
                restoredFormula += minesBeforeFirstVariable 
                    ? `${actions[index]}${variables[index]}`
                    : `${variables[index]}${actions[index]}`;
            }
            restoredFormula += minesBeforeFirstVariable 
                ? `${actions[actions.length - 1]}${variables[variables.length - 1]}`
                : variables[variables.length - 1];

            positionConditions = restoredFormula !== formula.replace(/\s/g, '');
        }
        
        return (!invalidFormulaCondition || minesBeforeFirstVariable) 
                && !wrongPointUsage 
                && !doublePointInVariable
                && !divisionByZero
                && !positionConditions;
    }

    public getValidationFormula(tagify: Tagify, attributes: (BaseAttribute | CalculatedAttribute)[], formulaType: () => FormulaType) {
        let formula = "";
        const tags = tagify ? tagify.getTagElms() : [];
        if (formulaType() === FormulaType.FormulaBase) {
            tags.map(x => {
                const attribute = attributes.find(z => z.DisplayName === x.title);
                formula = attribute ? `${formula} ${attribute.FieldName} ` : `${formula}${x.title}`;
            });
        } else {
            let isDaysUntil = false;
            tags.map(x => {
                const attribute = attributes.find(z => z.DisplayName === x.title);
                if (attribute) {
                    formula = `${formula}${attribute.FieldName}`;
                } else if (x.title.includes("and")) {
                    formula = `${formula}-`;
                } else if (x.title.includes("Days since")) {
                    formula = `today-${formula}`;
                } else if (x.title.includes("Days until")) {
                    isDaysUntil = true;
                }
            });
            if (isDaysUntil) {
                formula = `${formula}-today`;
            }
        }
        return formula;
    }
}
