// Actions sent from the embedded editor to the host (iframe -> Angular)
export enum OptimobileEditorAction {
	// User pressed 'back' or 'cancel' in the template editor
	NavigateBack,
	// User pressed up/down on the quick nav buttons
	NavigateTemplateList
}

export type OptimobileEditorMessage =
	| { type: OptimobileEditorAction.NavigateBack }
	| {
			type: OptimobileEditorAction.NavigateTemplateList;
			data: { direction: 'prev' | 'next' };
	  };

// Commands sent from the editor host (Angular -> iframe)
enum OptimobileEditorHostCommand {
	LoadTemplate
}

type OptimobileEditorHostMessage = {
	type: OptimobileEditorHostCommand.LoadTemplate;
	data: {
		templateId: number;
		folderId: number;
		actions: {
			canNavigatePrev: boolean;
			canNavigateNext: boolean;
		};
	};
};
