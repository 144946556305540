import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Campaign as CampaignModel } from '../../../../models/customer360/campaignDetailsPerCustomer.model';
import { customerImpactCampaignsTableTranslateKeys } from '../../customer360.translate.key';
import { Customer360DataService } from '../../services/customer360.data.service';
import { IAnalysisRange } from '../../customer360.models';

@Component({
	selector: 'customer-impact-campaigns-table',
	templateUrl: './customerImpactCampaignsTable.component.html',
	styleUrls: ['./customerImpactCampaignsTable.component.scss']
})
export class CustomerImpactCampaignsTableComponent implements OnInit, OnChanges {
	public campaignFilter: 'all' | 'scheduled' | 'triggered';
	public campaignListData: any[] = [];
	public campaignListDataFiltered: any[] = [];
	public get hasListData(): boolean {
        return this.campaignListDataFiltered?.length > 0;
    }
	public translationKeys = customerImpactCampaignsTableTranslateKeys;
	public exportBtnText: string = this.translationKeys.EXPORT_ALL_CAMPAIGNS;
	public campaignListViewConfig: any;
	public campaignListCsvFileHeaders = ['Start Date', 'Target Group', 'Trigger', 'Action', 'Channel', 'Promotion Code', 'Promotion Name', 'Template'];
	public campaignListCsvFileName: any;

	@Input() clientCustomerId: string;
	@Input() customerId: string;
	@Input() dateRange: IAnalysisRange;

	@Output('onDataLoaded') onDataLoadedEvent = new EventEmitter<void>();

	constructor(private customer360DataService: Customer360DataService,
		public translate: TranslateService,
		@Inject('uiGridCellFactory') public uiGridCellFactory: any) {
	}

	async ngOnInit() {
		await this.loadData();
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (this.clientCustomerId && this.dateRange?.startDate && this.dateRange?.endDate) {
			this.generateCsvFileNames();
			await this.loadData();
		}
	}

	async loadData(): Promise<void> {
		if (this.dateRange) {
			let response = await this.customer360DataService.getCampaignsDetailsPerCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate)
				.toPromise();
			this.campaignListData = response?.Data?.CampaignsDetailsPerCustomer?.CampaignList || [];
			this.campaignListDataFiltered = this.campaignListData;
			this.campaignFilter = "all";
			this.onDataLoadedEvent.emit();
		}
	}

	public filterCampaignsList = (type) => {
		this.campaignFilter = type;
		this.campaignListDataFiltered = this.campaignListData;
		switch (this.campaignFilter) {
			case 'scheduled':
				this.campaignListDataFiltered = this.campaignListData.filter(x => !x.Trigger || x.Trigger === '-');
				this.exportBtnText = this.translationKeys.EXPORT_SCHEDULED_CAMPAIGNS;
				break;
			case 'triggered':
				this.campaignListDataFiltered = this.campaignListData.filter(x => x.Trigger && x.Trigger !== '-');
				this.exportBtnText = this.translationKeys.EXPORT_TRIGGERED_CAMPAIGNS;
				break;
			case 'all':
			default:
				this.exportBtnText = this.translationKeys.EXPORT_ALL_CAMPAIGNS;
				break;
		}
	}

	private generateCsvFileNames() {
		var siteName = sessionStorage['SiteName'].replace(/"/g, ""); // eslint-disable-line dot-notation
		var clientCustomerId = this.clientCustomerId;
		var date = moment().format('YYYY-MM-DD');
		this.campaignListCsvFileName = `Optimove ${siteName} CustomerID ${clientCustomerId} Campaign List Details ${date}.csv`;
	}

	public campaignListCsvSerializerFunc = function (campaign: CampaignModel) {
		let res = '';
		for (let [idx, channel] of campaign.Channels.entries()) {
			const csvRowParts = [];

			this.pushWithQuotes(csvRowParts, campaign.CampaignDate ? moment(campaign.CampaignDate).format('YYYY-MM-DD') : 'NA');

			//TARGET GROUP
			this.pushWithQuotes(csvRowParts, campaign.TargetGroupName ? campaign.TargetGroupName : 'NA');

			//TRIGGER
			this.pushWithQuotes(csvRowParts, campaign.Trigger ? campaign.Trigger : 'NA');

			//ACTION
			this.pushWithQuotes(csvRowParts, campaign.Action ? campaign.Action : 'NA');

			//CHANNEL
			this.pushWithQuotes(csvRowParts, channel.Name ? channel.Name : 'NA');

			//PROMOTION CODE
			this.pushWithQuotes(csvRowParts, campaign.PromotionCode ? campaign.PromotionCode : 'NA');

			//PROMOTION NAME
			this.pushWithQuotes(csvRowParts, campaign.PromotionName ? campaign.PromotionName : 'NA');

			//TEMPLATE
			this.pushWithQuotes(csvRowParts, channel.TemplateName ? channel.TemplateName : 'NA');

			res += csvRowParts.join(',');
			if (idx != campaign.Channels.length - 1) {
				res += '\n';
			}
		}
		return res;
	}.bind(this);

	public pushWithQuotes(array, value) {
		if (!value.toString().includes('"'))
			array.push('"' + value + '"');
		else
			array.push(value);
	};

}

