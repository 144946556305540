<span class="osl-drop-down-container">
    <div>
        <button type="button" (click)="toggleSearchList()" [disabled] = "!searchList" [ngClass]="dynamicClasses">
            <i *ngIf="button.iconName" class="material-icons icon">{{button.iconName}}</i>
            <span *ngIf="button.label">{{button.label}}</span>
            <i class="material-icons dropdown-btn-caret">arrow_drop_down</i>
        </button>
    </div>

    <div class="float-search-list" *ngIf="isSearchListVisible && searchList"
         [ngClass]="{'align-right': align === alignHorizontalTypes.Right, 'align-up': vAlign === alignVerticalTypes.Up}">
        <opti-search-list
                [config]="searchList.config"
                [items]="searchList.items"
                [selectedItems]="searchList.selectedItems"
                [isSearchEnabled]="isSearchEnabled"
                (selectedItemsChange)="onSelectionChange($event)"
                (searchListHide)="onSelectedListHide()"
        ></opti-search-list>
    </div>
</span>