import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  Routes,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { newRoutes } from '../../routing/app.routing';
import { SsmService } from '../../services/ssm.service';
import { loadRemoteModule } from './dynamic-federation';
import { RemoteModules } from './constants/remote-modules';

@Injectable()
export class MicrofrontendRouteService {
  constructor(private ssmService: SsmService, private router: Router) {
    this.onInit();
  }

  onInit() {
    const ssm = this.ssmService.getSSM();
    const urls = ssm.GetGeneric(ssm.Resx.MicrofrontendModules);
    const dynamicRoutes: Routes = [];
    for (let module of RemoteModules.MODULES_COLLECTION) {
      const remoteOptions = {
        ...module.options,
        remoteEntry: `${urls[module.name]}remoteEntry.js?v=${Date.now()}`,
      };

      dynamicRoutes.push({
        path: module.path,
        loadChildren: () => loadRemoteModule({ ...remoteOptions }).then((m) => m[remoteOptions.exposedModule]),
      });
    }

    const tempRoutes = [...newRoutes];
    tempRoutes[0].children = [...dynamicRoutes, ...tempRoutes[0].children];
    this.router.resetConfig(tempRoutes);
  }
}
@Injectable()
export class DynamicActivator implements CanActivateChild {
  private isInitialActivation: boolean = true;
  public constructor(private srv: MicrofrontendRouteService, private router: Router) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.isInitialActivation) {
      this.router.navigateByUrl(state.url);
      this.isInitialActivation = false;
    }
    return true;
  }
}
