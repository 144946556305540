import { IChannelMetricsElement } from "./campaignAnalysis.models";
import { ChannelMetricsUtilitiesService } from "./channelMetricsUtilities.service";
import { Component } from '@angular/core';

@Component({
    template: '',
})
export class Navigatable {

    constructor(private utils: ChannelMetricsUtilitiesService) {
    }

    isNavigatable(metric: IChannelMetricsElement): boolean {
        return this.utils.isNavigatable(metric);
    }
}