<div class="col-md-4 filter">
    <form [formGroup]="form">
        <h4 data-qa-id="C360SearchTitle">
            {{translateKeys.SEARCH_TITLE | translate}}
        </h4>
        <ul class="list-inline search-bar">
            <li class="search-type">
                <opti-search-list 
                    data-qa-id="SearchTypeDropDown"
                    formControlName="searchType"
                    [config]="searchTypeConfig"
                    [items]="searchTypes"
                ></opti-search-list>
            </li>
            <li class="col-md-8 search-input-li">
                <input 
                    data-qa-id="CustomerIdInputTextBox"
                    formControlName="searchStr"
                    class="form-control search-input-with-options"
                    type="text"
                    placeholder="{{ translateKeys.SEARCH_INPUT_PLACEHOLDER | translate }}"
                />
            </li>
            <li>
                <button
                    data-qa-id="SearchButton"
                    (click)="onSearchClick()"
                    class="btn btn-primary">
                    {{translateKeys.SEARCH | translate}}
                </button>
            </li>
        </ul>
    </form>
</div>
