import { Injectable } from "@angular/core";
import { AjaxResponse, DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpService } from "../../../services/optimove-http/optimove-http.model";
import { ToastModel } from "../../missionControl/models/toast.model";
import { OptiApiKey } from "./models/optiApiKey.model";

@Injectable({providedIn: 'root'})
export class ApiKeyManagementService {
    private baseUrl: string = '/ApiKeys';
    private toastOpened$ = new Subject<ToastModel>();
    private showApiActivatedToast = new BehaviorSubject<boolean>(false);
    currentStatusApiActivated = this.showApiActivatedToast.asObservable();

    constructor(private readonly http: HttpService) {
    }

    passShowApiActivatedToast(showApiActivatedToast: boolean) {
        this.showApiActivatedToast.next(showApiActivatedToast);
    }

    public emitToastOpened(toastParams: ToastModel) {
        return this.toastOpened$.next(toastParams);
    }

    public getApiEndPoint(): Observable<string> {
        return this.http.get(`${this.baseUrl}/GetApiEndPoint`, {responseType: 'text'});
    }

    public getRoleTypesAsync(): Observable<DefaultAjaxResponse> {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetRoleTypesAsync`);
    }

    public getApiKeysAsync(): Observable<DefaultAjaxResponse> {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetApiKeysAsync`);
    }

    public createApiKeyAsync(optiApiKey: OptiApiKey): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/CreateApiKeyAsync`,  optiApiKey );
    }

    public updateApiKeyAsync(optiApiKey: OptiApiKey): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/UpdateApiKeyAsync`,  optiApiKey );
    }

    public deleteApiKeyAsync(nickname: string): Observable<AjaxResponse<string>> {
        return this.http.post<AjaxResponse<string>>(`${this.baseUrl}/DeleteApiKeyAsync`,   {nickname});
    }

    public getLegalChannelsAsync() {
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetLegalChannelsAsync`);
    }

    public getLegalAttributesAsync(){
        return this.http.get<DefaultAjaxResponse>(`${this.baseUrl}/GetLegalAttributesAsync`);
    }
}