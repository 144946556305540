import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation,} from "@angular/core";
import {WebhookChannel, WebhookConfiguration, WebhookEvent} from '../../models/webhookConfiguration';
import {SearchListConfig, OptiSearchListComponent} from "../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import {WebhookConfigurationService} from "../../webhookConfiguration.service";
import {SsmService} from "../../../../../services/ssm.service";
import {Router} from "@angular/router";
import {AbstractControl, NgModel} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FormatterPipe} from "../../../../../pipes/formatter.pipe";
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { AddGenericEventWebhookModal } from "../addGenericEventWebhookModal/addGenericEventWebhookModal.component";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { AllCommunityModules, ColDef, FirstDataRenderedEvent, GridApi, GridOptions, Module } from "@ag-grid-community/all-modules";
import { EditDeleteCellRendererComponent } from "../../../settingsShared/editDeleteCellRenderer/editDeleteCellRenderer.component";
import { SettingsSharedService } from "../../../settingsShared/settingsShared.service";
import {FeatureFlagService} from "../../../../../services/featureFlag.service";
import {SwitchButtonType} from "../../../../../components/switchButton/switchButtonType.model";

@Component({
    selector: 'add-webhook-configuration',
    templateUrl: './addWebhookConfiguration.component.html',
    styleUrls: ['./addWebhookConfiguration.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddWebhookConfigurationComponent implements OnInit, OnDestroy {

    public channelList: WebhookChannel[];
    public genericWebhookEvents: WebhookEvent[];
    public webhookConfiguration: WebhookConfiguration = new WebhookConfiguration();
    public customerAttributes: any;
    public selectedChannels: WebhookChannel[] = [];
    public selectedCustomerAttributes: any[]  = [];
    public noneSelectionObject = {RealFieldName: "(None)"};
    public webhookIdentifierList: any[]  = [];
    public webhookIdentifier: any[] = [];
    public isSaving: boolean = false;
    public editMode: boolean = false;
    public isOptimoveAdmin: boolean = false;
    public urlValidated: boolean = false;
    public urlValid: boolean = false;
    public isValidating: boolean = false;
    public batchSizeMinMaxErrorMsg: string;
    public eventsGridOptions: GridOptions;
    public eventsGridModule: Module[] = AllCommunityModules;
    private gridApi: GridApi;
    public hasEventsRegistered: boolean = false;
    public webhookEventsGridChanged: boolean = false;
    private subscriptions: Subscription[] = []
    public readonly SwitchButtonType = SwitchButtonType;

    public channelsConfig: SearchListConfig = {
        keyProperty: "id",
        valueProperty: "name",
        isMultiSelect: false,
        itemNameTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.channels',
        isDropDownDirectionUp: false,
        placeholderTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.channelNamePlaceholder'
    };

    public attributesConfig: SearchListConfig = {
        keyProperty: "Name",
        valueProperty: "RealFieldName",
        isMultiSelect: true,
        itemNameTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.attributes',
        isDropDownDirectionUp: false,
        placeholderTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.attributesPlaceholder'
    };

    public identifierConfig: SearchListConfig = {
        keyProperty: "Name",
        valueProperty: "RealFieldName",
        isMultiSelect: false,
        itemNameTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.identifier',
        isDropDownDirectionUp: false,
        placeholderTranslateKey: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.attributesPlaceholder'
    };

    private ssm: any;
    private saveAfterGetAttributes: boolean = false;
    private maxBatchSize: number;
    private minBatchSize: number;
    private minBatchSizeStr: string;
    private maxBatchSizeStr: string;
    private destroyOrCancelled$: Subject<boolean> = new Subject<boolean>();
    public getRowIdByEventIdFunc = (webhookEventEntity) => webhookEventEntity.genericEngagerEventTypeID;

    @ViewChild('attributes', {static: true}) attributesList: NgModel;
    @ViewChild('identifier', {static: true}) identifierList: NgModel;
    @ViewChild('batchSize', {static: true}) batchSize: NgModel;
    @ViewChild('webhookName', {static: true}) webhookName: NgModel;
    @ViewChild('webhookUrl', {static: true}) webhookUrl: NgModel;
    @ViewChild('channelName', {static: true}) channelName: NgModel;
    
    constructor(private webhookConfigurationService : WebhookConfigurationService,
                private ssmService: SsmService,
                private translate: TranslateService,
                private router: Router,
                private modalService: OptiLogicModalService,
                private settingsSharedService: SettingsSharedService,
                private formatter: FormatterPipe,
                private featureFlagService: FeatureFlagService,
                private genericEventModalRef: BsModalRef) {
                    this.ssm = this.ssmService.getSSM();
                }
                
                ngOnInit(){
                    this.setupEventsGridOptions();
                    this.batchSize.control.setValidators((control: AbstractControl) => {
                        if(control && control.value) {
                            let value = Number.isFinite(control.value) ? control.value : Number(control.value.replace(/\D/g, ''));
                            if (value > this.maxBatchSize) {
                                return {
                                    max: false
                                };
                            }
                            if (value < this.minBatchSize) {
                                return {
                                    min: false
                                };
                            }
                        }
                        return null;
                    });
                    let attributes = this.ssm.GetGeneric(this.ssm.Resx.CustomerAttributes);
                    this.customerAttributes = Object.keys(attributes).map(k => attributes[k]).filter(attr => {
                        let realFieldName = attr.RealFieldName.toLowerCase();
                        return !["customerid", "customer_id","contact_detail"].includes(realFieldName);
                    });


                    let tenantDetails = this.ssm.GetGeneric(this.ssm.Resx.TenantDetails);
                    this.isOptimoveAdmin = tenantDetails.userRole == 'OptimoveAdmin';
                    if(this.isOptimoveAdmin){
                        this.webhookConfiguration.webhookData.additionalData.internalConfiguration = true;//for OptimoveAdmin it is true by default
                    }
                    this.webhookConfigurationService.getOptigrationGenericEngagerEventTypes().subscribe((data) => {                        
                        this.genericWebhookEvents = this.DeepCopyArrayOfObjects(data);                        
                    });
                    this.webhookConfigurationService.getSettings().subscribe((data) => {
                        this.channelList = data.channels;
                        this.maxBatchSize = data.batchSizeMaxLimit;
                        this.minBatchSize = data.batchSizeMinLimit;                           
                        this.minBatchSizeStr = this.formatter.transform(this.minBatchSize, '0,', {shouldHandleBigNumbers: true});
                        this.maxBatchSizeStr = this.formatter.transform(this.maxBatchSize, '0,', {shouldHandleBigNumbers: true});                                               
                        this.setWebhookData();
                        this.batchSizeMinMaxErrorMsg = this.translate.instant(translateKeys.batchSizeErrorMsg, { batchSizeMin: this.minBatchSizeStr, batchSizeMax: this.maxBatchSizeStr });
                    });

                    this.ConfigureGridButtons();
                }
                
    DeepCopyArrayOfObjects(objectsArray: any[]): any[] {
        return objectsArray.map(obj => Object.assign({}, obj));
    }

    ConfigureGridButtons() {
        let deleteSubscription = this.settingsSharedService.onDeleteSettingClick.subscribe(rowId => {
            this.onDeleteWebhookEventClick(rowId);
        });
        this.subscriptions.push(deleteSubscription);
        let editSubscription = this.settingsSharedService.onEditSettingClick.subscribe(rowId => {
            this.onEditWebhookEventClick(rowId);
        });
        this.subscriptions.push(editSubscription);
    }

    backToList(){
        const url = this.router.serializeUrl(this.router.createUrlTree(['/user-settings/webhookConfiguration']));
        window.open('/#' + url,'_self' );
    }

    OnWebhookEventsGridChanged(params) {
        params.context.webhookEventsGridChanged = true;
        this.gridApi.onFilterChanged();
    }

    saveConfiguration(){
        let attributesComponent = this.attributesList.valueAccessor as OptiSearchListComponent;
        if(attributesComponent.showGrid){
            this.saveAfterGetAttributes = true;
            return;
        }
        this.save();
    }

    onSelectedAttributesChanged(currentSelected: any[]){
        if(this.saveAfterGetAttributes) {
            this.save();
            this.saveAfterGetAttributes = false;
        }
        this.clearIdentifierIfAttributeNotSelected(currentSelected);
        this.webhookIdentifierList = [this.noneSelectionObject, ...this.selectedCustomerAttributes];
    }

    onIdentifierSelected(currentSelected: any) {
        if(currentSelected[0].RealFieldName === '(None)') {
            this.webhookIdentifier = [];
        }
    }

    clearIdentifierIfAttributeNotSelected(currentSelected: any[]) {
        if(!currentSelected.includes(this.webhookIdentifier[0])){
            this.webhookIdentifier = [];
        }
    }

    validateURL() {
        this.urlValidated = true;
        this.isValidating = true;
        this.webhookConfigurationService.validateWebhookURL(this.webhookConfiguration.webhookData.webhookUrl, this.webhookConfiguration.webhookData.additionalData.internalConfiguration)
            .pipe(takeUntil(this.destroyOrCancelled$))
            .subscribe(isValid => {
                this.urlValid = isValid;
                this.isValidating = false;
                this.webhookUrl.control.setErrors(!isValid ? {'urlInvalid': true} : null);
            });
    }

    onWebhookURLChanged(){
        this.urlValidated = false;
        this.urlValid = false;
    }

    onWebhookNameChanged(){
        this.webhookConfiguration.webhookName = this.webhookConfiguration.webhookName.trim();
    }

    checkHasEventsRegistered(){
        if(this.genericWebhookEvents){
            this.hasEventsRegistered = this.genericWebhookEvents.filter((event) => this.FilterEventsWithRegisteredUrl(event)).length > 0;
        }
    }

    private onEditWebhookEventClick(rowId: string) {
        const genericEventToEdit = this.gridApi.getRowNode(rowId).data as WebhookEvent;
        this.openGenericWebhookEventModal(genericEventToEdit, rowId);
    }

    private onDeleteWebhookEventClick(rowId: string){
        const modalConf = {
            message: this.translate.instant('features.user_settings.body.webhookConfiguration.deleteWebhookMessage'),
            buttons: [
                {
                    class: 'btn-primary',
                    label: this.translate.instant('features.user_settings.body.webhookConfiguration.deleteWebhook'),
                    action: () => {
                        this.deleteWebhookEventConfiguration(rowId);
                        this.webhookEventsGridChanged = true;
                    },
                    dataQaId: "cyDeleteEventDeleteButton"
                },
                {
                    class: 'btn-default',
                    label: this.translate.instant('general.CANCEL'),
                    action: () => {
                        this.genericEventModalRef.hide();
                    },
                    dataQaId: "cyDeleteEventCancelButton"
                }
            ]
        };
        this.modalService.openModalMessage(
            'sm',
            modalConf,
            <ModalOptions<any>>{ignoreBackdropClick: true, keyboard: false}
        );
    }

    private deleteWebhookEventConfiguration(rowId: string){
        this.gridApi.getRowNode(rowId).setDataValue('webhookUrl', '');
        this.gridApi.onFilterChanged();
    }

    private save(){
        if(!this.urlValidated){
            this.webhookUrl.control.setErrors({'notValidated': true});
            return;
        }
        this.isSaving = true;
        this.webhookConfiguration.webhookData.webhookName = this.webhookConfiguration.webhookName;
        this.webhookConfiguration.webhookData.channelId = this.selectedChannels[0].id;
        this.webhookConfiguration.webhookData.additionalData.selectedCustomerAttributes = this.selectedCustomerAttributes.map(x => x.Name);
        this.webhookConfiguration.integrationAttributes = this.selectedCustomerAttributes.map(x => x.RealFieldName);
        if(this.webhookIdentifier[0] != undefined){
            this.webhookConfiguration.webhookData.additionalData.identifier = this.webhookIdentifier[0].Name;
            this.webhookConfiguration.integrationIdentifier = this.webhookIdentifier[0].RealFieldName;
        }
        this.webhookConfiguration.genericEngagerWebhookEvents = this.genericWebhookEvents.filter((webhookEvent) => this.FilterEventsWithRegisteredUrl(webhookEvent));
        this.webhookConfigurationService.addOrUpdateWebhook(this.webhookConfiguration, !this.editMode).subscribe((res) => {
            if(!res.isSuccess){
                if(res.errorMsg.includes("WebhookName exists")) {
                    this.webhookName.control.setErrors({'isExists': true});
                }
                if(res.errorMsg.includes("WebhookURL exists")) {
                    this.channelName.control.setErrors({'isExistsForChannel': true});
                }
                if(res.errorMsg.includes("WebhookName is empty")) {
                    this.webhookName.control.setErrors({'required' : true });
                }
                if(!res.errorMsg.includes("WebhookName exists") && !res.errorMsg.includes("WebhookURL exists") && !res.errorMsg.includes("WebhookName is empty")) {
                    this.modalService.openModalMessage(
                        'sm',
                        {
                            message: this.translate.instant('features.user_settings.body.webhookConfiguration.addWebhookConfiguration.unableToSaveWebhook'),
                            buttons: [{
                                class: 'btn-primary',
                                label: this.translate.instant('general.OK'),
                                action: () => {}
                            }]
                        },
                        <ModalOptions<any>>{
                            ignoreBackdropClick: true,
                            keyboard: false
                        }
                    );
                }
                this.isSaving = false;
            }else {
                this.isSaving = false;
                this.backToList();
            }
        });
    }

    private setWebhookData() {
        const webhook = this.router.getCurrentNavigation()?.extras.state;

        if (webhook) {
            this.editMode = true;
            this.urlValidated = true;
            this.urlValid = true;
            this.webhookConfiguration.integrationId = webhook.integrationId;
            this.webhookConfiguration.webhookName = webhook.webhookName;
            this.webhookConfiguration.webhookData.webhookUrl = webhook.webhookData.webhookUrl;
            this.webhookConfiguration.webhookData.additionalData.batchSize = webhook.webhookData.additionalData.batchSize;
            this.webhookConfiguration.webhookData.additionalData.internalConfiguration = webhook.webhookData.additionalData.internalConfiguration;
            this.webhookConfiguration.webhookData.additionalData.applyFormatting = webhook.webhookData.additionalData.applyFormatting;

            if (webhook.webhookData.channelId) {
                this.selectedChannels.push(this.channelList.find(c => c.id == webhook.webhookData.channelId));
            }
            
            this.selectedCustomerAttributes = this.customerAttributes.filter(attr => webhook.integrationAttributes.includes(attr.RealFieldName));
            this.webhookIdentifierList = [this.noneSelectionObject, ...this.selectedCustomerAttributes];

            let identifier = webhook.integrationIdentifier
            if (identifier && identifier != "null" && identifier != "undefined") {
                this.webhookIdentifier.push(this.selectedCustomerAttributes.find(a => a.RealFieldName == identifier));
            }
            this.genericWebhookEvents = this.GetWebhookEventsList(webhook.genericEngagerWebhookEvents as WebhookEvent[]);
        }
    }

    private GetWebhookEventsList(givenWebhookEvents: WebhookEvent[]): WebhookEvent[] {
        let mergedList: WebhookEvent[] = [];
        mergedList = this.genericWebhookEvents.map((settingsEvent) => {
            let registeredWebhooks = givenWebhookEvents.find((registeredEvent) => settingsEvent.genericEngagerEventTypeID === registeredEvent.genericEngagerEventTypeID);
            return {...settingsEvent, ...registeredWebhooks};
        });
        return mergedList;
    }

    selectedChannelChanged(){
        this.webhookUrl.control.setErrors({'isExistsForChannel': null});
        this.webhookUrl.control.updateValueAndValidity();
    }

    ngOnDestroy(){
        this.destroyOrCancelled$.next(true);
        this.destroyOrCancelled$.complete();
        this.genericWebhookEvents = [];
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngDoCheck() {
        this.checkHasEventsRegistered();
    }

    areAllEventsHaveRegisteredWebhooks() {
        return this.genericWebhookEvents?.findIndex(webhook => webhook.webhookUrl === '' || webhook.webhookUrl === undefined ) === -1;
    }

    private setupEventsGridOptions(){
        const columnDefs = this.getEventsGridColumnsDefinitions();
        this.eventsGridOptions = {
            columnDefs: columnDefs,
            context: this,
            headerHeight: 50,
            tooltipShowDelay: 500,
            rowHeight: 48,
            isExternalFilterPresent: () => true,
            doesExternalFilterPass: (row) => this.FilterEventsWithRegisteredUrl(row.data),
            suppressRowTransform: true,
            suppressCellSelection: true,
            animateRows: true,
            onGridReady: (params) => {
                this.gridApi = params.api;
            },
            onFirstDataRendered: (params: FirstDataRenderedEvent) => {
                params.api.sizeColumnsToFit();
            },
            defaultColDef: {
                sortable: true
            },
            onCellValueChanged: (params) => this.OnWebhookEventsGridChanged(params)
        };
    }

    private FilterEventsWithRegisteredUrl(event: WebhookEvent): boolean {
        return event.webhookUrl !== "" && event.webhookUrl !== undefined;
    }

    private getEventsGridColumnsDefinitions() : ColDef[] {
        let colDef:ColDef[] = [
            {
                field: 'event',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.addWebhookConfiguration.event'),
                valueGetter: (params) => { return params.data.genericEngagerEventName },
                minWidth: 275,
                maxWidth: 275
            },
            {
                field: 'webhookUrl',
                headerName: this.translate.instant('features.user_settings.body.webhookConfiguration.webhookURL'),
                valueGetter: (params) => { return params.data.webhookUrl },
                tooltipField: 'webhookUrl'
            },
            {
                field: 'actions',
                headerName: '',
                sortable: false,
                cellRendererFramework: EditDeleteCellRendererComponent,
                minWidth: 50,
                maxWidth: 50
            }
        ];
        return colDef;
    }

    addOrUpdateGenericWebhookEvent(modalOutput: WebhookEvent, rowId?: string) {
        if(this.onEditMode(rowId)) {
            const rowNode = this.gridApi.getRowNode(rowId);
            rowNode.setDataValue("webhookUrl", modalOutput.webhookUrl);
        } else {
            this.genericWebhookEvents
                .find((webhookEvent) => webhookEvent.genericEngagerEventTypeID === modalOutput.genericEngagerEventTypeID)
                .webhookUrl = modalOutput.webhookUrl;
            this.webhookEventsGridChanged = true;
            this.gridApi?.onFilterChanged();
        }
    }
    
    private onEditMode(rowId: string) {
        return rowId != null;
    }

    openGenericWebhookEventModal(genericEventToEdit?: WebhookEvent, rowId? : string) {
        this.genericEventModalRef = this.modalService.open(AddGenericEventWebhookModal, "md", <
        ModalOptions<any>
      >{
        ignoreBackdropClick: true,
        keyboard: true,
        class: "add-generic-event-webhook-modal",
        initialState: {
            mainWebhookUrl: this.urlValid ? this.webhookConfiguration.webhookData.webhookUrl : null,
            internalConfiguration: this.webhookConfiguration.webhookData.additionalData.internalConfiguration,
            genericEventListInput: this.genericWebhookEvents,
            genericEventToEdit: genericEventToEdit
          },
      });
      this.genericEventModalRef.content.onSave.pipe(take(1)).subscribe((modalOutput: WebhookEvent) => {
        this.addOrUpdateGenericWebhookEvent(modalOutput, rowId);
    });
    }

    onInternalConfigurationChange(internalConfiguration: boolean) {
        this.webhookConfiguration.webhookData.additionalData.internalConfiguration = internalConfiguration;
        this.urlValidated = false;
        this.urlValid = false;
    }

    onApplyFormattingChange(applyFormatting: boolean) {
        this.webhookConfiguration.webhookData.additionalData.applyFormatting = applyFormatting;
    }

}

const translateKeys = {
    batchSizeErrorMsg: 'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.batchSizeMinMax'
}