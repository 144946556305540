import { Injectable } from "@angular/core";
import { AttributeHelper } from '../attributesList.helper';
import { AttributeTypeOptions, BaseAttribute } from '../attributesList.model';

@Injectable()
export class AttributesListExportService {
  constructor() {}

  customProcessCell(params) {
    if (params.column.colDef.headerName === "Type") {
      const attribute: BaseAttribute = params.node.data;
      const options: AttributeTypeOptions = AttributeHelper.getFullAttributeType(attribute);

      let type = options.isCalculated 
        ? "Calculated" 
        : options.isAPI
          ? "API"
          : options.isBase
           ? "Database"
           : "";

      if (options.isRT) {
        type += " Realtime";
      }

      return type;
    }

    return params.value;
  }
}