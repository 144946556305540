import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { DOCUMENT } from '@angular/common';
import { DataTransferConsts } from '../models/dataTransferConsts';
import { Subscription } from 'rxjs';
import { TemplateSelectionService } from '../services/templateSelection.service';
import { TemplatesDragDropService } from '../services/templatesDragDrop.service';
import { TemplateKeysModel } from '../models/templateKeysModel';

@Component({
	selector: 'drag-grab',
	templateUrl: './drag.component.html',
	styleUrls: ['./drag.component.scss']
})
export class DragComponent implements ICellRendererAngularComp, OnInit, OnDestroy {
	readonly tempTreeItemClass = 'temp-tree-item';

	public folderTemplate: FolderTemplatePreview;
	private componentSubscriptions: Subscription;
	private selectedTemplates: TemplateKeysModel[];

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private templateSelectionService: TemplateSelectionService,
		private templatesDragDropService: TemplatesDragDropService
	) {}

	ngOnInit(): void {
		this.componentSubscriptions = this.templateSelectionService.templatesSelected.subscribe((value) => {
			this.selectedTemplates = value;
		});
	}

	ngOnDestroy(): void {
		this.componentSubscriptions?.unsubscribe();
	}

	dragstart(event) {
		const dragTemplates = this.isCurrentTemplatePartOfSelection() ? this.selectedTemplates : [this.folderTemplate];
		this.templatesDragDropService.updateDragTemplates(dragTemplates);

		const crt = document.getElementById('drag-container-ag-grid');

		const numberOfDraggedTemplates = this.isCurrentTemplatePartOfSelection() ? this.selectedTemplates.length : 1;
		const indication = this.document.getElementById('drag-container-ag-grid-indication');
		indication.innerHTML = `${numberOfDraggedTemplates} ${numberOfDraggedTemplates > 1 ? ' Templates' : ' Template'}`;

		crt.classList.add(this.tempTreeItemClass);
		document.body.appendChild(crt);
		event.dataTransfer.setDragImage(crt, 0, 0);
		event.dataTransfer.setData(DataTransferConsts.template, JSON.stringify(dragTemplates));
	}

	agInit(params: ICellRendererParams): void {
		this.folderTemplate = params.data;
	}

	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	dragEnd($event) {
		this.templatesDragDropService.updateDragTemplates([]);
	}

	private isCurrentTemplatePartOfSelection(): boolean {
		return !!(this.selectedTemplates && this.selectedTemplates.find((t) => t.templateID === this.folderTemplate.templateID));
	}
}
