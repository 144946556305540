<div class="row">
    <div class="col-md-8">
        <ng-content></ng-content>
    </div>
    <div class="col-md-4">
        <h4 class="analysis-range">
            {{translateKeys.ANALYSIS_RANGE | translate }}
        </h4>
        <form [formGroup]="form">
            <opti-search-list 
                class="col-md-5"
                style="padding-left: 0"
                data-qa-id="AnalysisRange"
                formControlName="analysisRange"
                [config]="analysisRangeConfig"
                [items]="analysisRanges"
            ></opti-search-list>
            <date-range-picker 
                class="col-md-7"
                style="padding: 0"
                data-qa-id="AnalysisRangeDatePicker"
                formControlName="dateRange"
                [dateRangePickerData]="dateRangePickerConfig"
            ></date-range-picker>
        </form>
    </div>
</div>
