<div class="edit-brand">
    <div class="panel-heading">
        <div class="header">
        <div class="back-button">
            <div class="btn btn-link" (click)="openConfirmModal()">
                <i class="material-icons">arrow_back</i>
                <span translate>{{'general.BACK' | translate}}</span>
            </div>
        </div>
        <h2 style="margin-left: 15px;">{{ translateKeys.title | translate }}</h2>
        </div>
    </div>
    <div class="panel-body">
        <form [formGroup]="editDOIForm"  class="form">
            <div class="form-element">
                <div class="label-column">
                    {{ translateKeys.switchLabel | translate }}
                </div>
                <div class="switch">
                    <switch on-change="switchFilters()" [buttonType]="switchButtonType" formControlName="isDOI"></switch>
                </div>
            </div>
            <div class="form-element">
                <div class="label-column">
                    {{ translateKeys.urlLabel | translate }}
                </div>
                <div class="input-wrap">
                    <input [ngClass]="{'errorBorder': !editDOIForm.valid}" type="text" placeholder="{{translateKeys.placeholder | translate}}"
                           formControlName="link"
                           class="form-control value-column"/>
                </div>
            </div>
            <div class="text-under-input">
                <p  *ngIf="!editDOIForm.valid"
                    class="inputs-validations error-msg">
                    {{translateKeys.validation_error | translate}}
                </p>
                {{translateKeys.text_under_input | translate}}
            </div>
        </form>
    </div>
    <div class="panel-footer panel-footer-bottom-stick footer-btns">
        <div class="controls col-md-6 pull-right text-right">
            <ul class="list-inline">
                <li>
                    <div class="btn btn-default" data-qa-id="save-button" (click)="cancelClick()">
                        <span>Cancel</span>
                    </div>
                </li>
                <li>
                    <div data-qa-id="save-button">
                        <loading-button
                                [showLoader]="isSaving"
                                (onClick)="updateBrandDOI()"
                                [buttonCssClass]="'btn btn-primary'"
                                [isDisabled] = "!editDOIForm.valid || !editDOIForm.dirty || (this.isDOIFormControl.value && this.linkFormControl.value === '')"
                                [loadingCssClass]="'saving-loader'"
                                [loadingText]="'Updating'"
                                [buttonText]="'Update'">
                        </loading-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="isSaving"></div>
