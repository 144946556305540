import { Injectable } from "@angular/core";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../services/optimove-http/optimove-http.model";
import { IBrandDetails } from "./models/brandDetails.model";

@Injectable()
export class SettingsSubscribersService {
    private baseUrl: string = '/UserSettings';

    constructor(private readonly http: HttpService) { }

    public getBrands(): Observable<IBrandDetails[]> {
        // check error
        return this.http.get<AjaxResponse<IBrandDetails[]>>(`${this.baseUrl}/GetBrands`)
            .pipe(
                map((res) => {
                    return res.data;
                })
            );
    }

    public addUnsubscribes(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/AddUnsubscribesFromSettingsPage`, formData);
    }

    public exportBrandUnsubscribers(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/ExportUnsubscribesAsCsv`, formData);
    }

    public removeUnsubscribes(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/RemoveUnsubscribesFromSettingsPage`, formData);
    }

    public GetCustomerEmail(): Observable<string> {
        return this.http.get(`${this.baseUrl}/GetCustomerEmail`, {responseType: 'text'});
    }
}
