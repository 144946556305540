<div class="popup-overlay-root" (click)="$event.stopPropagation()">
  <div class="folder-name-container">
    <div class="material-icons folder-icon">folder</div>
    <span class="folder-name">{{ folder.text }}</span>
  </div>
  <div class="separator"></div>
  <div>
    <div class="input-group input-search template-search-container">
      <input
        class="template-search-text form-control"
        [(ngModel)]="searchText"
        [placeholder]="'features.manage_templates.components.folderPopup.TEMPLATES_IN_THIS_FOLDER' | translate"
        (ngModelChange)="onSearchTextChange($event)" />
      <span class="input-search-label material-icons search-text" (click)="clearSearch()">{{ searchText ? "close" : "search" }}</span>
    </div>
  </div>
  <div class="template-list" (scroll)="onScroll($event)">
    <div *ngIf="templates.length === 0" class="empty-container">
      <span class="empty-state">
        {{ "features.manage_templates.components.details.EMPTY_FOLDER" | translate }}
      </span>
    </div>
    <ul *ngIf="templates.length > 0" class="list-inline">
      <li *ngFor="let item of templates">
        <a
          *ngIf="navigateExternally"
          class="template-item-container"
          [ngClass]="{'current-template': +queryParams.templateId === item.templateID}"
          (click)="templateNavigateClick(item)">
          <div class="material-icons template-icon">insert_drive_file</div>
          <span class="template-text" [innerHtml]="item.displayName" [title]="item.displayName"></span>
        </a>
        <div *ngIf="!navigateExternally && +queryParams.templateId === item.templateID" class="template-item-container current-template">
          <div class="material-icons template-icon">insert_drive_file</div>
          <span class="template-text" [innerHtml]="item.displayName" [title]="item.displayName"></span>
        </div>
        <a
          *ngIf="!navigateExternally && +queryParams.templateId !== item.templateID"
          class="template-item-container"
          [href]="getEditTemplateLink(item)"
          (click)="templateNavigateClick(item)">
          <div class="material-icons template-icon">insert_drive_file</div>
          <span class="template-text" [innerHtml]="item.displayName" [title]="item.displayName"></span>
        </a>
      </li>
    </ul>
  </div>
  <div class="separator"></div>
  <div class="show-all-link-container">
    <a [routerLink]="lobbyUrl.routerLink" [queryParams]="lobbyUrl.queryParams" target="_blank">
      <div>
        <span>{{ "features.manage_templates.components.folderPopup.SHOW_ALL_FOLDERS" | translate }}</span>
        <div class="material-icons show-all-icon">launch</div>
      </div>
    </a>
  </div>
</div>
