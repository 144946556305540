import { Injectable } from "@angular/core";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LifecycleFilter } from "../features/missionControl/models/attributeFilter.model";
import { HttpService } from "./optimove-http/optimove-http.model";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private missionControlEndPoint = "/MissionControl";

    constructor(private readonly http: HttpService,
        private cookieService: CookieService) {

    }

    private getDataByKey(storageKey: StorageKey): Observable<any | null> {
        switch (storageKey) {
            case StorageKey.LifecycleStages:
                return this.getLifecycleStages()
            case StorageKey.LifecycleFilterItems:
                return this.getLifecycleFilterItems()
            default:
                return of(null);
        }
    }

    public getData<T>(storageKey: StorageKey): Observable<T> {
        return this.getDataByKey(storageKey)
            .pipe(
                map(data => {
                    return data as T;
                })
            );
    }

    private getLifecycleStages(): Observable<LifecycleFilter[]> {
        if (sessionStorage.getItem(StorageKey.LifecycleStages)) {
            return of(JSON.parse(sessionStorage.getItem(StorageKey.LifecycleStages)));
        } else {
            return this.http.get<AjaxResponse<LifecycleFilter[]>>(`${this.missionControlEndPoint}/GetLifecycles`)
                .pipe(
                    map((res) => {
                        return res.data;
                    }),
                    tap((data: LifecycleFilter[]) => {
                        sessionStorage.setItem(StorageKey.LifecycleStages, JSON.stringify(data));
                    })
                );
        }
    }

    private getLifecycleFilterItems(): Observable<LifecycleFilter[]> {
        if (sessionStorage.getItem(StorageKey.LifecycleFilterItems)) {
            return of(JSON.parse(sessionStorage.getItem(StorageKey.LifecycleFilterItems)));
        } else {
            return this.getLifecycleStages()
                .pipe(
                    map(data => {
                        return data.filter(item => {
                            return item.id !== '100';
                        });
                    }),
                    tap(data => {
                        sessionStorage.setItem(StorageKey.LifecycleFilterItems, JSON.stringify(data));
                    })
                );
        }
    }

    public getDataFromLocalStorage<T>(key: string): T {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    public getDataFromSessionStorage<T>(key: string): T {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    public getDataFromCookies<T>(key: string): T{
        const item = this.cookieService.get(key)
        return item ? JSON.parse(item) : null;
    }

    public setDataToLocalStorage<T>(key: string, valueObj: T): void {
        let valueStr = JSON.stringify(valueObj);
        localStorage.setItem(key, valueStr);
    }

    public setDataToSessionStorage(key: string, valueObj: any): void {
        let valueStr = JSON.stringify(valueObj);
        sessionStorage.setItem(key, valueStr);
    }

    public setDataToCookies<T>(key: string, data: T) {
        this.cookieService.set(key, JSON.stringify(data));
    }

    public isDataInStorage<T>(itemParsed: T): boolean {
        return itemParsed !== null && itemParsed !== undefined
    }
}

export enum StorageKey {
    LifecycleStages = 'LifecycleStages',
    LifecycleFilterItems = 'LifecycleFilterItems'
}
