<div class="last-modified-stamp">
    <label class="small id-label" *ngIf="id && !shouldShowIdAtBottom">
        ID:
        {{id}}
    </label>
    <label class="small" *ngIf="lastModifiedData">
        Last modified on
        {{lastModifiedData.Date || lastModifiedData.date}}
        <br *ngIf="isBreakRow" />
        at
        {{lastModifiedData.Time || lastModifiedData.time}}
        by
        {{lastModifiedData.Username || lastModifiedData.username}}
        <span *ngIf="shouldShowIdAtBottom">
            | ID: {{id}}
        </span>
    </label>
</div>