import { Title } from "@angular/platform-browser";
import {
    downgradeComponent,
    downgradeInjectable,
    setAngularJSGlobal,
    UpgradeModule,
} from "@angular/upgrade/static";
import { ColoredTagComponent } from "@optimove/ui-sdk/components/colored-tag";
import { CampaignStatusTagComponent } from '@optimove/ui-sdk/components/campaign-status-tag'
import { OptiSelectComponent } from "@optimove/ui-sdk/components/opti-select";
import { AlertComponent } from "../components/alert/alert.component";
import { CampaignKPITableComponent } from "../components/campaignKPITable/campaignKPITable.component";
import { ClickLoaderComponent } from "../components/clickLoader/clickLoader.component";
import { ConditionalLanguageContainerComponent } from "../components/conditionalLanguageContainer/conditionalLanguageContainer.component";
import { DatePickerComponent } from "../components/datePicker/datePicker.component";
import { DateRangePickerComponent } from "../components/dateRangePicker/dateRangePicker.component";
import { IframeContainerComponent } from "../components/iframeContainer/iframeContainer.component";
import { JsTreeComponent } from "../components/jsTree/jsTree.component";
import { TreeStreamLevel } from "../components/jsTree/models/treeStreamLevel.enum";
import { MultipleOptionsButtonComponent } from "../components/multipleOptionsButton/multipleOptionsButton.component";
import { NotesAndTagsButtonComponent } from "../components/notesAndTagsButton/notesAndTagsButton.component";
import { OptiLoadingComponent } from "../components/optiLoading/optiLoading.component";
import { OptiSearchListComponent } from "../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { OptiSearchListDropDownComponent } from "../components/optiSearchListDropDown/optiSearchListDropDown.component";
import { PanelWithAccordionSidebar } from "../components/panelWithAccordionSidebar/panelWithAccordionSidebar.component";
import { PersonalizationTagsContainerComponent } from "../components/personalizationTagsContainer/personalizationTagsContainer.component";
import { SliderComponent } from "../components/slider/slider.component";
import { SwitchComponent } from "../components/switchButton/switch.component";
import { SwitchButtonType } from "../components/switchButton/switchButtonType.model";
import { HorizontalTabsComponent } from "../components/tabs/horizontalTabs/horizontalTabs.component";
import { TabComponent } from "../components/tabs/tab/tab.component";
import { TabBodyComponent } from "../components/tabs/tabBody/tabBody.component";
import { TabHeaderComponent } from "../components/tabs/tabHeader/tabHeader.component";
import { AttributesExportBtnComponent } from "../features/campaignAnalysis/components/attributesExportBtn/attributesExportBtn.component";
import { ChannelMetricsComponent } from "../features/campaignAnalysis/components/channelMetrics/channelMetrics.component";
import { AddKPIsModalComponent } from "../features/customer360/components/add-kpis-modal/addKPIsModal.component";
import { AttributesGridComponent } from "../features/customer360/components/attributesGrid/attributesGrid.component";
import { CampaignGridComponent } from "../features/customer360/components/campaign-grid/campaign-grid.component";
import { Customer360PageComponent } from "../features/customer360/components/customer360Page/customer360Page.component";
import { CustomersListComponent } from "../features/customer360/components/customersList/customersList.component";
import { SetupGearButton } from "../features/customer360/components/setupGearButton/setupGearButton.component";
import { MoveTgsToStreamSaveModalComponent } from "../features/manageTargetGroups/components/moveTgsToStreamSaveModal/moveTgsToStreamSaveModal.component";
import { PriorityListComponent } from "../features/manageTargetGroups/components/priorityList/priorityList.component";
import { CopyTemplateContainerComponent } from "../features/manageTemplates/copyTemplateContainer/copyTemplateContainer.component";
import { ContentTagModalContainerComponent } from "../features/manageTemplates/editors/dialogs/contentTagModal/container/contentTagModalContainer.component";
import { TemplateNavigationService } from "../features/manageTemplates/services/templateNavigation.service";
import { SmartPreviewTableComponent } from "../features/manageTemplates/smartPreviewTable/smartPreviewTable.component";
import { TemplateScriptGuardContainerComponent } from "../features/manageTemplates/TemplateScriptGuardContainer/templateScriptGuardContainer.component";
import { OptibotNotificationService } from "../features/optibot/services/optibotNotification.service";
import { AndroidNotificationChannelsComponent } from "../features/optiPush/components/androidNotificationChannels/androidNotificationChannels.component";
import { IosNotificationChannelsComponent } from "../features/optiPush/components/iosNotificationChannel/iosNotificationChannel.component";
import { ApiKeyManagementComponent } from "../features/settings/apiKeyManagement/apiKeyManagement.component";
import { CreateApiKey } from "../features/settings/apiKeyManagement/CreateApiKey/createApiKey.component";
import { ApiManagementComponent } from "../features/settings/apiManagement/apiManagement.component";
import { CreateApiUser } from "../features/settings/apiManagement/CreateApiUser/createApiUser.component";
import { AttributesListComponent } from "../features/settings/attributes/attributesList.component";
import { CalculatedAttributeComponent } from "../features/settings/attributes/components/calculatedAttribute/calculatedAttribute.component";
import { CustomerAttributeComponent } from "../features/settings/attributes/components/customerAttribute/customerAttribute.component";
import { EditBaseAttributeComponent } from "../features/settings/attributes/components/editBaseAttribute/editBaseAttribute.component";
import { PurchaseHistoryAttributeComponent } from "../features/settings/attributes/components/purchaseHistoryAttribute/purchaseHistoryAttribute.component";
import { BrandsListComponent } from "../features/settings/DoubleOptedIn/Components/BrandsList/brandsList.component";
import { EditBrandComponent } from "../features/settings/DoubleOptedIn/Components/EditBrand/editBrand.component";
import { ImportedCustomersComponent } from "../features/settings/importedCustomers/components/imported-customers.component";
import { RealtimeProfileAddAttributeComponent } from "../features/settings/realtimeProfileDataManage/components/addAttribute/realtimeProfileAddAttribute.component";
import { RealtimeProfileDataManageComponent } from "../features/settings/realtimeProfileDataManage/realtimeProfileDataManage.component";
import { PermissionsManagerComponent } from "../features/settings/rolePermissions/components/permissionsManager.component";
import { RolePermissionsComponent } from "../features/settings/rolePermissions/rolePermissions.component";
import { SettingsSubscribersComponent } from "../features/settings/settingsSubscribers/settingsSubscribers.component";
import { AddUniquePromoCodeComponent } from "../features/settings/uniquePromoCodes/components/addUniquePromoCode/addUniquePromoCode.component";
import { UniquePromoCodesComponent } from "../features/settings/uniquePromoCodes/components/uniquePromoCodes.component";
import { AddWebhookConfigurationComponent } from "../features/settings/webhookConfiguration/components/addWebhookConfiguration/addWebhookConfiguration.component";
import { WebhookConfigurationComponent } from "../features/settings/webhookConfiguration/components/webhookConfiguration.component";
import {
    StreamTestStatus,
    TestType,
} from "../features/streams/models/streamsTestInfo.model";
import { AlertsService } from "../services/alerts.service";
import { CogwheelFeatureFlagService } from "../services/cogwheelFeatureFlag.service";
import {
    FeatureFlag,
    FeatureFlagService,
} from "../services/featureFlag.service";
import { FullPageLoaderService } from "../services/fullPageLoader.service";
import { HttpService } from "../services/optimove-http/optimove-http.model";
import { SignalrService } from "../services/signalr.service";
import { FormatterPipe } from "./../pipes/formatter.pipe";
import { AngularRouterUIRouterAdapter } from "./angularRouterUIRouterAdapter.service";
import { FolderPopupButtonComponent } from "../features/manageTemplates/folderPopup/folderPopupButton/folderPopupButton.component";
import { ColorsService } from '@optimove/ui-sdk/common/services';
import { TemplateMetadataPanelComponent } from '../features/manageTemplates/editors/panels/templateMetadataPanel/templateMetadataPanel.component';
import { TopicsListComponent } from '../features/settings/preferenceCenter/components/topicsList/topicsList.component';
import { AddUpdateTopicComponent } from '../features/settings/preferenceCenter/components/addUpdateTopic/addUpdateTopic.component';
import {
    CredentialsFormComponent
} from "../features/settings/messageArchiving/components/credentialsForm/credentialsForm.component";
import {
    ErrorLogListComponent
} from "../features/settings/messageArchiving/components/errorLogList/errorLogList.component";
import { OneClickUnsubscribeComponent } from "../features/settings/oneClickUnsubscribe/components/oneClickUnsubscribe.component";

export class NgBootstrapper {
    private moduleName = "optiApp";

    public bootstrap(upgrade: UpgradeModule) {
        // using window.angular instead of importing it in order not to create two references to same angular library
        // since angularjs app is already importing angular we can rely it on being on the window object.
        setAngularJSGlobal(window["angular"]);
        window["angular"]
            .module(this.moduleName)
            .factory(
                "httpService",
                downgradeInjectable(HttpService)
            )
            .directive("errorLogList",
                downgradeComponent({
                    component: ErrorLogListComponent,
                }))
            .directive("credentialsForm",
                downgradeComponent({
                    component: CredentialsFormComponent,

                }))
            .directive("oneClickUnsubscribe",
                downgradeComponent({
                    component: OneClickUnsubscribeComponent,
                }))
            .directive(
                "templateMetadata",
                downgradeComponent({
                    component: TemplateMetadataPanelComponent,
                    inputs: ["froalaTemplate"],
                })
            )
            .directive(
                "smartPreviewTable",
                downgradeComponent({
                    component: SmartPreviewTableComponent,
                    inputs: ["targetGroupId"],
                    outputs: ["disableTargetGroupsListEmitter"],
                })
            )
            .directive(
                "clickLoader",
                downgradeComponent({
                    component: ClickLoaderComponent,
                    inputs: [
                        "isResolved",
                        "disabledOnResolved",
                        "loadingText",
                        "showLoader",
                    ],
                })
            )
            .directive(
                "notesAndTagsButton",
                downgradeComponent({
                    component: NotesAndTagsButtonComponent,
                    inputs: ["tagsLimit", "isEditMode", "allTags", "ngModel", "disabled"],
                    outputs: ["saveNewTagsAndNotesEmitter"],
                })
            )
            .directive(
                "personalizationTagsContainer",
                downgradeComponent({
                    component: PersonalizationTagsContainerComponent,
                    inputs: ["subMethodId", "isBeeFree"],
                    outputs: ["chosen"],
                })
            )
            .directive(
                "contentTagModalContainer",
                downgradeComponent({
                    component: ContentTagModalContainerComponent,
                    outputs: ["chosen"],
                })
            )
            .directive(
                "folderPopupButton",
                downgradeComponent({
                    component: FolderPopupButtonComponent,
                    inputs: ["navigateExternally"],
                    outputs: ["navigateToTemplate"],
                })
            )
            .directive(
                "conditionalLanguageContainer",
                downgradeComponent({
                    component: ConditionalLanguageContainerComponent,
                    inputs: ["conditionalLanguageSnippets"],
                    outputs: ["chosen"],
                })
            )
            .directive(
                "templateScriptGuardContainer",
                downgradeComponent({
                    component: TemplateScriptGuardContainerComponent,
                    outputs: ["closed"],
                })
            )
            .directive(
                "dateRangePicker",
                downgradeComponent({
                    component: DateRangePickerComponent,
                    inputs: ["dateRangePickerData"],
                    outputs: ["dateRangePickerDataChange"],
                })
            )
            .directive(
                "datePicker",
                downgradeComponent({
                    component: DatePickerComponent,
                    inputs: ["datePickerData", "ngModel"],
                    outputs: ["datePickerDataChange"],
                })
            )
            .directive(
                "slider",
                downgradeComponent({
                    component: SliderComponent,
                    inputs: [
                        "min",
                        "max",
                        "sliderSteps",
                        "valueType",
                        "shouldDebounceChangeEvent",
                    ],
                })
            )
            .directive(
                "setupGearButton",
                downgradeComponent({
                    component: SetupGearButton,
                    inputs: ["defaultAttributeAliasName"],
                    outputs: ["onChangeAttribute"],
                })
            )
            .directive(
                "campaignGrid",
                downgradeComponent({
                    component: CampaignGridComponent,
                    inputs: ["customerId", "startDate", "endDate", "filter"],
                })
            )
            .directive(
                "attributesGrid",
                downgradeComponent({
                    component: AttributesGridComponent,
                    inputs: [
                        "clientCustomerId",
                        "customerAttributes",
                        "customerAttributeMappingList",
                    ],
                    outputs: ["onDataLoaded", "onExportReady"],
                })
            )
            .directive(
                "jsTree",
                downgradeComponent({
                    component: JsTreeComponent,
                    inputs: ["nodes", "treeActions", "rootNodeId"],
                    outputs: ["treeEvents", "nodeSelected"],
                })
            )
            .directive(
                "iframeContainer",
                downgradeComponent({
                    component: IframeContainerComponent,
                    inputs: ["url", "search"],
                    outputs: [],
                })
            )
            .directive(
                "panelWithAccordionSidebarUpgradedNg",
                downgradeComponent({
                    component: PanelWithAccordionSidebar,
                    inputs: [
                        "leftSidebarIsOpen",
                        "rightSidebarIsOpen",
                        "leftSizeMaxWidth",
                        "is-resize-disabled",
                        "leftSidebarHidden",
                    ],
                    outputs: ["toggleSideBar", "closeRightSideBar"],
                })
            )
            .directive(
                "optiSearchListDropDown",
                downgradeComponent({
                    component: OptiSearchListDropDownComponent,
                    inputs: ["button", "searchList", "align", "vAlign"],
                    outputs: ["select"],
                })
            )
            .directive(
                "horizontalTabs",
                downgradeComponent({
                    component: HorizontalTabsComponent,
                })
            )
            .directive(
                "tab",
                downgradeComponent({
                    component: TabComponent,
                    inputs: ["title", "active", "disabled"],
                })
            )
            .directive(
                "tabBody",
                downgradeComponent({
                    component: TabBodyComponent,
                })
            )
            .directive(
                "tabHeader",
                downgradeComponent({
                    component: TabHeaderComponent,
                })
            )
            .directive(
                "optiLoading",
                downgradeComponent({
                    component: OptiLoadingComponent,
                    inputs: ["show", "size", "title", "text"],
                })
            )
            .directive(
                "tgPriorityList",
                downgradeComponent({
                    component: PriorityListComponent,
                    inputs: ["maxTgIndex", "filteredTg", "firstSelectedTg", "search"],
                    outputs: ["firstSelectedTgEmitter"],
                })
            )
            .directive(
                "channelMetrics",
                downgradeComponent({
                    component: ChannelMetricsComponent,
                    inputs: [
                        "metricsData",
                        "campaign",
                        "seriesOn",
                        "recurranceStartDate",
                    ],
                    outputs: ["datesRangeChanged"],
                })
            )
            .directive(
                "multipleOptionsButton",
                downgradeComponent({
                    component: MultipleOptionsButtonComponent,
                    inputs: ["inputObj", "disabled"],
                    outputs: ["itemClicked"],
                })
            )
            .directive(
                "alert",
                downgradeComponent({
                    component: AlertComponent,
                    inputs: ["message", "type", "show"],
                    outputs: ["showChange"],
                })
            )
            .directive(
                "brandsList",
                downgradeComponent({
                    component: BrandsListComponent,
                })
            )
            .directive(
                "editBrand",
                downgradeComponent({
                    component: EditBrandComponent,
                })
            )
            .directive(
                "topicsList",
                downgradeComponent({
                    component: TopicsListComponent,
                })
            )
            .directive(
                "addUpdateTopic",
                downgradeComponent({
                    component: AddUpdateTopicComponent,
                })
            )
            .directive(
                "settingsSubscribers",
                downgradeComponent({
                    component: SettingsSubscribersComponent,
                })
            )
            .directive(
                "attributesList",
                downgradeComponent({
                    component: AttributesListComponent,
                })
            )
            .directive(
                "editBaseAttribute",
                downgradeComponent({
                    component: EditBaseAttributeComponent,
                })
            )
            .directive(
                "calculatedAttribute",
                downgradeComponent({
                    component: CalculatedAttributeComponent,
                })
            )
            .directive(
                "customerAttribute",
                downgradeComponent({
                    component: CustomerAttributeComponent,
                })
            )
            .directive(
                "purchaseAttribute",
                downgradeComponent({
                    component: PurchaseHistoryAttributeComponent,
                })
            )
            .directive(
                "customersList",
                downgradeComponent({
                    component: CustomersListComponent,
                    inputs: ["searchStr"],
                    outputs: [
                        "onInit",
                        "onDataLoaded",
                        "onSearchByChanges",
                        "onCustomerSelected",
                    ],
                })
            )
            .directive(
                "realtimeProfileDataManage",
                downgradeComponent({
                    component: RealtimeProfileDataManageComponent,
                })
            )
            .directive(
                "realtimeProfileAddAttribute",
                downgradeComponent({
                    component: RealtimeProfileAddAttributeComponent,
                })
            )
            .directive(
                "moveTgsToStreamSaveModal",
                downgradeComponent({
                    component: MoveTgsToStreamSaveModalComponent,
                    inputs: [
                        "isTgsHaveFutureCamapaigns",
                        "moveTgsToStreamConfirmed",
                        "moveTgsToStreamRejected",
                    ],
                })
            )
            .directive(
                "androidNotificationChannels",
                downgradeComponent({
                    component: AndroidNotificationChannelsComponent,
                    inputs: ["apps", "template"],
                    outputs: ["onChannelSelect"],
                })
            )
            .directive(
                "iosNotificationChannel",
                downgradeComponent({
                    component: IosNotificationChannelsComponent,
                    inputs: ["template"],
                })
            )
            .directive(
                "switch",
                downgradeComponent({
                    component: SwitchComponent,
                    inputs: ["buttonType"],
                })
            )
            .directive(
                "optiSearchListDowngrade",
                downgradeComponent({
                    component: OptiSearchListComponent,
                    inputs: [
                        "items",
                        "selectedItems",
                        "presets",
                        "config",
                        "markAsInvalid",
                        "isServerSideMode",
                        "serverSideConfig",
                    ],
                    outputs: ["selectedItemsChange"],
                })
            )
            .directive(
                "campaignMetricsTable",
                downgradeComponent({
                    component: CampaignKPITableComponent,
                    inputs: ["actionData", "metadata"],
                    outputs: ["onGroupClicked"],
                })
            )
            .directive(
                "coloredTag",
                downgradeComponent({
                    component: ColoredTagComponent,
                    inputs: ["coloredTagType"],
                })
            )
            .directive(
                "campaignStatusTag",
                downgradeComponent({
                    component: CampaignStatusTagComponent,
                    inputs: ["coloredTagType", "isShow", "statusName"],
                })
            )
            .directive(
                "copyTemplateContainer",
                downgradeComponent({
                    component: CopyTemplateContainerComponent,
                    outputs: ["closed"],
                })
            )
            .directive(
                "webhookConfiguration",
                downgradeComponent({
                    component: WebhookConfigurationComponent,
                })
            )
            .directive(
                "uniquePromoCodes",
                downgradeComponent({
                    component: UniquePromoCodesComponent,
                })
            )
            .directive(
                "addUniquePromoCode",
                downgradeComponent({
                    component: AddUniquePromoCodeComponent,
                })
            )
            .directive(
                "addWebhookConfiguration",
                downgradeComponent({
                    component: AddWebhookConfigurationComponent,
                })
            )
            .directive(
                "apiManagement",
                downgradeComponent({
                    component: ApiManagementComponent,
                })
            )
            .directive(
                "importedCustomers",
                downgradeComponent({
                    component: ImportedCustomersComponent,
                })
            )
            .directive(
                "createApiUser",
                downgradeComponent({
                    component: CreateApiUser,
                })
            )
            .directive(
                "apiKeyManagement",
                downgradeComponent({
                    component: ApiKeyManagementComponent,
                })
            )
            .directive(
                "createApiKey",
                downgradeComponent({
                    component: CreateApiKey,
                })
            )
            .directive(
                "rolePermissions",
                downgradeComponent({
                    component: RolePermissionsComponent,
                    inputs: [
                        "roleNames",
                        "landingPage",
                        "editedRoleName",
                        "restrictedPermissions",
                        "tenantRoles",
                        "allPermissions",
                    ],
                    outputs: ["updateAllUsersWithNewPermissionsData"],
                })
            )
            .directive(
                "permissionsManager",
                downgradeComponent({
                    component: PermissionsManagerComponent,
                    inputs: [
                        "landingPage",
                        "tenantRoles",
                        "editedRoleName",
                        "restrictedPermissions",
                        "allPermissions",
                        "editedUserPermissions",
                    ],
                    outputs: ["permissionsForm"],
                })
            )
            .directive(
                "attributesExportBtn",
                downgradeComponent({
                    component: AttributesExportBtnComponent,
                    inputs: ["disabled, isVisitorCampaign, isExportResolved"],
                    outputs: ["export"],
                })
            )
            .directive(
                "addKpisModal",
                downgradeComponent({
                    component: AddKPIsModalComponent,
                    inputs: ["openModal"],
                    outputs: ["onClose", "onSubmit"],
                })
            )
            .directive(
                "optiSelect",
                downgradeComponent({
                    component: OptiSelectComponent,
                    inputs: [
                        "items",
                        "placeholder",
                        "isMultiSelect",
                        "itemName",
                        "compareFunction",
                        "isLoading",
                        "icon",
                        "isDisabled",
                    ],
                    outputs: ["onSelect"],
                })
            )
            .directive(
                "customer360Page",
                downgradeComponent({
                    component: Customer360PageComponent,
                    inputs: [],
                    outputs: [],
                })
            )
            .factory(
                "angularRouterUIRouterAdapter",
                downgradeInjectable(AngularRouterUIRouterAdapter)
            )
            .factory(
                "optibotNotificationSystemService",
                downgradeInjectable(OptibotNotificationService)
            )
            .factory("titleService", downgradeInjectable(Title)) // TODO: after gtm removal remove this service
            .factory("signalrService", downgradeInjectable(SignalrService))
            .factory("alertsService", downgradeInjectable(AlertsService))
            .factory(
                "fullPageLoaderService",
                downgradeInjectable(FullPageLoaderService)
            )
            .factory("featureFlagService", downgradeInjectable(FeatureFlagService))
            .factory(
                "cogwheelFeatureFlagService",
                downgradeInjectable(CogwheelFeatureFlagService)
            )
            .factory("colorsService", downgradeInjectable(ColorsService))
            .factory("formatterPipe", downgradeInjectable(FormatterPipe))
            .factory("templateNavigationService", downgradeInjectable(TemplateNavigationService))
            .constant("streamTestStatus", StreamTestStatus)
            .constant("streamTestType", TestType)
            .constant("treeStreamLevel", TreeStreamLevel)
            .constant("switchButtonType", SwitchButtonType)
            .constant("featureFlag", FeatureFlag);

        upgrade.bootstrap(document.documentElement, [this.moduleName]);
        console.log("hybrid app bootstrapped");
    }
}
