import { Component, Input, OnInit } from '@angular/core';
import { IModalConf } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { SubMethodService } from '../../../services/subMethodService';

@Component({
	selector: 'update-content-tag',
	templateUrl: './updateContentTagModal.component.html',
	styleUrls: ['./updateContentTagModal.component.scss']
})
export class UpdateContentTagModalComponent implements OnInit {
	@Input() onSave: () => void;
	@Input() onCancel: () => void;
	public modalConf: IModalConf;

	constructor(public bsModalRef: BsModalRef, public translateService: TranslateService, public subMethodService: SubMethodService) {}

	ngOnInit(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.optimail.contentTags.UPDATE_CONTENT_TAG'),
			hideHeaderPanel: false,
			xButton: { action: () => {
				this.onCancel();
				this.closeModal();
			} },
			hideFooterPanel: false,
			hideFooterBorder: false,

			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.optimail.contentTags.SAVE_CHANGES'),
					action: () => {
						this.onSave();
						this.bsModalRef.hide();
					}
				},
				{
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						this.onCancel();
						this.bsModalRef.hide();
					}
				}
			]
		};
	}
	private closeModal() {
		this.bsModalRef.hide();
	}
}
