import { AttributeExportService } from './../attributeExport.service';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SearchItem, SearchListConfig } from '../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'attribute-to-export-modal',
  templateUrl: 'attributeToExportModal.component.html',
  styleUrls: ['./attributeToExportModal.component.scss'],
})
export class AttributeToExportModal implements OnInit {
  @Input() isVisitorCampaign: boolean;
  public export: () => void;

  public SSM;
  public modalConf: IModalConf;
  public translateKeys = translateKeys;

  public isAddAttributeMode: boolean = false;
  public isAddAttributeBtnDisable: boolean = true;
  public defaultAttributesToExport: SearchItem[] = [];
  public attributesToExport: SearchItem[] = [];
  public selectedAttributes: SearchItem[] = [];
  public attributesSearchList: SearchItem[] = [];
  private initialAttributesToExport: SearchItem[] = [];
  private maxAttributeToExport = 20;

  public attributeSearchListConfig: SearchListConfig = {
    itemNameTranslateKey: this.translate.instant(this.translateKeys.DROPDOWN_PLASEHOLDER),
    keyProperty: "Name",
    valueProperty: "Alias",
    placeholderTranslateKey: this.translate.instant(this.translateKeys.DROPDOWN_PLASEHOLDER),
    isMultiSelect: false
  };

  constructor(private attributeExportService: AttributeExportService,
              private bsModalRef: BsModalRef,
              private translate: TranslateService,) { }

  ngOnInit(): void {
    this.setModalConf();
    this.attributesSearchList = this.attributeExportService.getAttributesSearchItems();
    this.attributeExportService.getAttributesListToExport(this.isVisitorCampaign).pipe(first()).subscribe(res => {
      this.defaultAttributesToExport = res.filter(x => x.isDefault && !!x.attributeSearchItem).map(x => x.attributeSearchItem);
      this.initialAttributesToExport = res.filter(x => !x.isDefault && !!x.attributeSearchItem).map(x => x.attributeSearchItem);
      this.attributesToExport = [...this.initialAttributesToExport];
      this.removeDefaultAttributesFromSearchList();
      this.removeAttributesFromSearchList(this.initialAttributesToExport);
      this.isAddAttributeBtnDisable = this.isAttributeCountMax();
    });
  }

  public addAttributesToExport(attributes: SearchItem[]) {
    this.attributesToExport.push(...attributes);
    this.removeAttributesFromSearchList(attributes);
    this.setSelectedAttribute([]);
    this.isAddAttributeBtnDisable = this.isAttributeCountMax();
    this.isAddAttributeMode = false;
  }

  public removeAttributeFromExport(attribute: SearchItem) {
    this.attributesToExport = this.attributesToExport.filter(x => x !== attribute);
    this.attributesSearchList.push(attribute);
    this.isAddAttributeBtnDisable = this.isAttributeCountMax();
  }

  public setSelectedAttribute(attributes: SearchItem[]) {
    this.selectedAttributes = attributes;
  }

  public saveAndExport() {
    this.export();
    this.setAttributesListToExport();
  }
  
  public isSaveDisabled(): boolean {
    return this.attributesToExport.every(x => this.initialAttributesToExport.includes(x)) 
           && this.attributesToExport.length === this.initialAttributesToExport.length;
  }

  private setModalConf(): void {
    this.modalConf = {
        title: this.translate.instant(this.translateKeys.ATTRIBUTE_EXPORT_MODAL_TITLE),
        xButton: {action: () => this.bsModalRef.hide()},
        buttons:[
          {
              isDisabled: () => this.isSaveDisabled(),
              class: 'btn btn-primary',
              label: this.translate.instant(this.translateKeys.SAVE_BTN),
              action: this.setAttributesListToExport.bind(this),
          },
          {
              isDisabled: () => false,
              class: 'btn-default',
              label: this.translate.instant(this.translateKeys.CANCEL_BTN),
              action: this.closeModal.bind(this),
          }
      ],
        withScale: false,
    };
  }

  private closeModal() {
    this.bsModalRef.hide()
  }

  private setAttributesListToExport() {
    let selectedAttributesNames: string[] = this.attributesToExport.map(x => x.Name);
    this.attributeExportService.setAttributesListToExport(selectedAttributesNames)
    .pipe(first(), filter(x => !!x)).subscribe(res => {
      if (res.IsSuccess) {
        this.closeModal();
      }
    });
  }

  private removeDefaultAttributesFromSearchList() {
    this.defaultAttributesToExport.forEach(defaultAttribute => {
      this.attributesSearchList = this.attributesSearchList.filter(x => x.Alias !== defaultAttribute.Alias);
    });
  }

  private removeAttributesFromSearchList(attributes: SearchItem[]) {
    this.attributesSearchList = this.attributesSearchList.filter(x => !attributes.includes(x));
  }

  private isAttributeCountMax(): boolean {
    return this.attributesToExport.length >= this.maxAttributeToExport
  }
}

const translateKeys = {
  ATTRIBUTE_EXPORT_MODAL_TITLE: "features.campaign_analysis.summary_section.attributeExport.ATTRIBUTE_EXPORT_MODAL_TITLE",
  DROPDOWN_PLASEHOLDER: "features.campaign_analysis.summary_section.attributeExport.DROPDOWN_PLASEHOLDER",
  ADD_ATTRIBUTE_BTN: "features.campaign_analysis.summary_section.attributeExport.ADD_ATTRIBUTE_BTN",
  ADD_BTN: "features.campaign_analysis.summary_section.attributeExport.ADD_BTN",
  SAVE_BTN: "features.campaign_analysis.summary_section.attributeExport.SAVE_BTN",
  CANCEL_BTN: "features.campaign_analysis.summary_section.attributeExport.CANCEL_BTN",
  SAVE_EXPORT_BTN: "features.campaign_analysis.summary_section.attributeExport.SAVE_EXPORT_BTN"
}