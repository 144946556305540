import  {Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { GridApi } from "@ag-grid-community/all-modules";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { AttributeStatus } from "../../models/iuniquePromoCodesLobby.model"; 


@Component({
    selector: 'attr-status-cell',
    templateUrl: './statusCellRenderer.component.html'
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
    public value: string;
    public coloredTagType: ColoredTagType;
    private gridApi: GridApi;

    constructor() {
    }

    agInit(params: any): void {
        let status = params.data.status as AttributeStatus;
        status = status == null ? "Empty" : status;
        this.value = status;
        
        switch(status) {
            case "Empty":
                this.coloredTagType = ColoredTagType.Type1; 
                break;
            case "Available": 
                this.coloredTagType = ColoredTagType.Type4;
                break;
        }
        this.gridApi = params.api;
    }

    refresh(): boolean {
        return true;
    }
}