import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy, OnInit,
    Output, Renderer2,
    ViewChild,
    ViewEncapsulation } from "@angular/core";

import {RowCalculationService} from "../services/rowCalculation.service";
import { isNullOrUndefined } from "util";
import { OptiSearchListService } from '../optiSearchListComponent/optiSearchList.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'osl-item',
    templateUrl: './oslItem.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./oslItem.component.scss']
})

export class OslItemComponent implements OnInit, OnDestroy {

    @Input() maxRowsInCell: number;

    @Input() disabled: boolean;
    @Input() label: string;
    @Input() showCheckbox: boolean = false;
    @Input() isSelected: boolean = false;
    @Input() isGroupChild: boolean = false;
    @Input() singleRowHeight: number;
    @Input() itemHeight: number;
    @Input() isHeaderItem: boolean;
    @Input() icon?: string = null;
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('textElement', {static: true}) textElement: ElementRef;
    @ViewChild('wrapperElement', {static: true}) wrapperElement: ElementRef;
    @ViewChild('labelElement', {static: true}) labelElement: ElementRef;

    private lineHeight: number;
    private verticalPadding: number;
    private horizontalPadding: number;
    private inputOffset: number;
    public maxRowsInTooltip: string;
    public itemLinesCssClass: string;
    private subscriptions: Subscription;

    constructor(private renderer: Renderer2, private rowCalculationService: RowCalculationService, private cdRef: ChangeDetectorRef,
        private optiSearchListService: OptiSearchListService){
            this.subscriptions = new Subscription();
        }

    ngOnInit() {
        if (!isNullOrUndefined(this.itemHeight)) {
            this.setMultiLineCssClass(this.itemHeight, this.singleRowHeight);   
        }

        this.observeSelectAllRowsChanged();   
    }

    private setMultiLineCssClass(itemHeight: number, singleRowHeight: number) {
        let itemRowsLength = Math.ceil(itemHeight / (singleRowHeight + 2));
        if (itemRowsLength > 3) {
            itemRowsLength = 3;
        }
        this.itemLinesCssClass = `tooltip-multi-line-${itemRowsLength}`;
    }

    private getLineHeight(): number {
        if (!this.lineHeight) {
            const compStyles = window.getComputedStyle(this.textElement.nativeElement);
            this.lineHeight = parseFloat(compStyles['line-height']);
            if (isNaN(this.lineHeight)) {
                this.lineHeight = 18;
            }
        }
        return this.lineHeight;
    }

    private getInputOffset(): number {
        if (!this.verticalPadding) {
            this.inputOffset = this.textElement.nativeElement.offsetLeft;
        }
        return this.inputOffset;
    }

    private getVerticalPadding(): number {
        if (!this.verticalPadding) {
            const compStyles = window.getComputedStyle(this.wrapperElement.nativeElement);
            this.verticalPadding = parseFloat(compStyles['padding-top']) + parseFloat(compStyles['padding-bottom']);
        }
        return this.verticalPadding;
    }

    private getHorizontalPadding(isGroupView: boolean): number {
        if (!this.horizontalPadding) {
            const compStyles = window.getComputedStyle(this.wrapperElement.nativeElement);
            this.horizontalPadding = parseFloat(compStyles['padding-left']) + parseFloat(compStyles['padding-right']);
            // Dont use this.isGroupChild in this method, this.isGroupChild not init yet
            if (isGroupView) {
                this.horizontalPadding += 10;
            }
        }
        return this.horizontalPadding;
    }


    setWidth(width: number): void {
        this.renderer.setStyle(
            this.wrapperElement.nativeElement,
            'width',
            `${width}px`
        );
    }

    toggleSelect(event: any): void {
        if (this.disabled) {
            return;
        }

        this.onChange.emit(!this.isSelected);
        event.stopPropagation();
        event.preventDefault();
    }

    mouseout(event: any): void {
        this.renderer.removeAttribute(this.textElement.nativeElement,'title');
    }

    mouseover(event: any): void {
        if (this.shouldShowTooltip(this.textElement.nativeElement)) {
            this.renderer.setAttribute(
                this.textElement.nativeElement,
                'title',
                this.label
            );
        }
    }

    private shouldShowTooltip(elem) {
        return (elem.scrollWidth > elem.offsetWidth) ||
            (elem.scrollHeight > (elem.offsetHeight + 3));
    }

    getHeightForText(text: string, containerWidth: number, isGroupView: boolean) : number {
        const concreteWidth = containerWidth - this.textElement.nativeElement.offsetLeft;
        const maxRows = this.maxRowsInCell || 3; // fallback to fix tests mostly
        return this.rowCalculationService.getHeightForText(text, concreteWidth, this.getHorizontalPadding(isGroupView),
            this.getVerticalPadding(), this.getLineHeight(), maxRows, 2, 2);
    }

    private observeSelectAllRowsChanged() {
        const subscription = this.optiSearchListService.allRowsChanged$.subscribe(isSelected => {
            if (this.isHeaderItem) {
                return;
            }
            this.isSelected = isSelected;
            this.cdRef.detectChanges();
        });
        this.subscriptions.add(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}