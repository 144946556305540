import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AjaxResponse } from "@optimove/ui-sdk/common/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";
import { SelectedAttribute } from "../models/request/selected-attributes-model";
import { ImportNewCustomersResult } from "../models/response/import-new-customers-result.model";
import { MappingAttributes } from "../models/response/mapping-attributes.model";

@Injectable()
export class ImportedCustomersService{
    private readonly baseUrl = '/ImportedCustomers';
    
    constructor(private readonly httpClient: HttpService){ }

    public uploadCsvFile(file: File): Observable<AjaxResponse<MappingAttributes[]>> {
    	let formData = new FormData();
    	formData.append("csvFile", file, file.name);

    	let headers = new HttpHeaders();
    	headers.append('Content-Type', 'multipart/form-data');
    	headers.append('Accept', 'application/json');

    	return this.httpClient.post<AjaxResponse<MappingAttributes[]>>(`${this.baseUrl}/UploadCsvFile`, formData, { headers }).pipe(
    		map((res) => {
    			return res;
    		})
    	)
    }

    public getEmailAttributeName(): Observable<AjaxResponse<string>> {
    	let url = `${this.baseUrl}/GetEmailAttributeName`;
    	return this.httpClient.get<AjaxResponse<string>>(url, {}).pipe(
    		map((res) => {
    			return res;
    		})
    	);
    }

    public CheckIfTgNameExists(tgName: string): Observable<boolean> {
    	let url = `/TargetGroups/CheckIfTgNameExists?tgName=${tgName}`;
    	return this.httpClient.get<AjaxResponse<boolean>>(url, {}).pipe(
    		map((res) => {
    			return res.data;
    		})
    	);
    }

    public importNewCustomers(selectedAttributes: SelectedAttribute[], file: File, tgName: string): Observable<AjaxResponse<ImportNewCustomersResult>>{
    	
    	let formData = new FormData();
    	let csvFile = new File([file], file.name, {
    		type: file.type,
    		lastModified: file.lastModified,
    	});
    	formData.append("file", csvFile, csvFile.name);

    	let headers = new HttpHeaders();
    	headers.append('Content-Type', 'multipart/form-data');
    	headers.append('Accept', 'application/json');
    	headers.append('File', 'CSV Imported Customers');

    	let url = `${this.baseUrl}/ImportNewCustomers?tgName=${tgName}&attributes=${JSON.stringify(selectedAttributes)}`;
    	return this.httpClient.post<AjaxResponse<ImportNewCustomersResult>>(url, formData, { headers }).pipe(
    		map((res) => {
    			return res;
    		})
    	);
    }
}