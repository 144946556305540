import {Component, ViewEncapsulation} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
    selector: 'newChevronCellRenderer',
    templateUrl: 'newTagLabelCellRenderer.component.html',
    styleUrls: ['./newTagLabelCellRenderer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewTagLabelCellRendererComponent  implements ICellRendererAngularComp {

    public cellValue: any;
    public isNew: boolean;

    agInit(params: any): void {
        this.cellValue = params.value.cellValue;
        this.isNew = params.value.isNew;
    }

    refresh(params: any): boolean {
        return false;
    }

}