import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IIframeModalConf } from '../../optiLogicModal.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'iframe-modal',
    templateUrl: './iframeModal.component.html',
    styleUrls: ['./iframeModal.component.scss'],
})
export class IframeModalComponent implements OnInit {
    @Input() modalConf: IIframeModalConf;
    public onClose$: Subject<void>;
    public onComplete$: Subject<void>;

    constructor(private bsModalRef: BsModalRef) { }

    ngOnInit() { 
        this.onClose$ = new Subject();
        this.onComplete$ = new Subject();
    }

    close(): void {
        this.onClose$.next();
    }
}