import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TreeItem } from 'src/app/components/jsTree/models/treeItem.model';
import { RelationType, RootTemplateTreeItem, TemplateTreeItem } from '../models/templateResponse';
import { TemplateQueryParams } from '../models/templateQueryParams';
import { ManageTemplatesService } from '../services/manageTemplates.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Brand, Channel } from '../models/metadataResponse';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { TemplateContextService } from '../services/templateContext.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SearchContextService } from '../services/searchContext.service';
import { KEYS, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { fadeIn } from './masterPanel.animation';
import { OverflowMenuItem } from 'src/app/components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { FolderService } from '../services/folder.service';
import { RoutingConsts } from '../models/routing.consts';
import { ChannelService } from '../services/channel.service';
import { DOCUMENT } from '@angular/common';
import { DataTransferConsts } from '../models/dataTransferConsts';
import { TemplateSelectionService } from '../services/templateSelection.service';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { TemplatesDragDropService } from '../services/templatesDragDrop.service';

interface DragOverPayload {
	target: any;
	canDrop: boolean;
	folderId: number;
}

@Component({
	selector: 'master-panel',
	templateUrl: './masterPanel.component.html',
	styleUrls: ['./masterPanel.component.scss'],
	animations: [fadeIn]
})
export class MasterPanelComponent implements OnInit, OnDestroy {
	@ViewChild('overlay', { static: false }) overlay: ElementRef;
	@ViewChild('masterPanel', { static: false }) masterPanel: ElementRef;
	public dragOverId: number;
	readonly nodeTransparentClass = 'node-transparent';
	readonly dragContainerClass = 'drag-container';
	readonly tempTreeItemClass = 'temp-tree-item';
	readonly searchLengthMinimum: number = 2;

	private $highlightDragOver: Subject<DragOverPayload> = new Subject<DragOverPayload>();
	public highlightDragOver: Observable<DragOverPayload> = this.$highlightDragOver
		.asObservable()
		.pipe(distinctUntilChanged(undefined, (p) => p.folderId));

	public options = {
		allowDrag: true,
		allowDrop: true,
		displayField: 'text',
		allowDragoverStyling: true,
		dropSlotHeight: 0,
		nodeHeight: () => 32,
		actionMapping: {
			mouse: {
				click: (tree, node, $event) => {
					this.onNodeClick(tree, node, $event);
				},
				dblClick: (tree, node, $event) => {
					this.onNodeClick(tree, node, $event);
				},
				drop: (tree, node: TreeNode, $event) => {
					const folder = node.data as TemplateTreeItem;
					folder.subMethodId = this.folderService.findSubMethodByFolderId(folder.id);
					if ($event?.dataTransfer?.getData(DataTransferConsts.template)) {
						this.dropTemplate($event, folder);
					} else {
						this.dropFolder($event, folder);
					}
					this.templatesDragDropService.updateDragFolders([]);
					this.templatesDragDropService.updateDragTemplates([]);
				},
				dragStart: (tree, node, $event) => {
					this.setDraggedStyle(node, $event);
					node.data.subMethodId = this.folderService.findSubMethodByFolderId(node.data.id);
					$event?.dataTransfer?.setData(DataTransferConsts.folder, JSON.stringify(node.data));
					this.templatesDragDropService.updateDragFolders([node.data]);
					return true;
				},
				dragEnter: (tree, node, $event) => {},
				dragOver: (tree, node, $event) => {
					this.dragOverId = node.data.id;
					const target = this.getCurrentTarget($event);
					const nodeSubMethodId = this.folderService.findSubMethodByFolderId(node.data.id);
					this.$highlightDragOver.next({
						target,
						canDrop: this.templatesDragDropService.canDrop(nodeSubMethodId),
						folderId: node.data.id
					});
					this.cdRef.detectChanges();
				},
				drag: (tree, node, $event) => {},
				dragLeave: (tree, node, $event) => {
					this.resetStyles($event, this.getCurrentTarget($event));
				},
				dragEnd: (tree, node, $event) => {
					this.removeTransparencyByClassName();
					this.dragOverId = null;
					this.cdRef.detectChanges();
				}
			},
			keys: {
				[KEYS.ENTER]: (tree, node, $event) => {
					this.onNodeClick(tree, node, $event);
				}
			}
		}
	};

	private removeTransparencyByClassName() {
		const elNode = document.getElementsByClassName(this.nodeTransparentClass);
		elNode[0].classList.remove(this.nodeTransparentClass);
	}
	private removeTransparency(nodeId) {
		const elNode = document.getElementById(nodeId);
		elNode.classList.remove('node-transparent');
	}
	private resetStyles($event, target) {
		target.style.backgroundColor = '';
		$event.dataTransfer.dropEffect = 'copy';
		this.dragOverId = null;
		this.cdRef.detectChanges();
	}
	private setDraggedStyle(node: TreeNode, e: any): void {
		const elNode = document.getElementById(node.data.id);
		elNode.classList.add(this.nodeTransparentClass);
		const hoverIcon = document.getElementById(this.dragContainerClass);
		hoverIcon.classList.add(this.tempTreeItemClass);
		document.body.appendChild(hoverIcon);
		e.dataTransfer.setDragImage(hoverIcon, 0, 0);
	}
	public dropTemplate($event, folder) {
		const draggedTemplates = JSON.parse($event.dataTransfer.getData(DataTransferConsts.template)) as FolderTemplatePreview[];
		const moveTemplateRequests = draggedTemplates
			.filter((t) => t.subMethodId === folder.subMethodId && t.folderId !== folder.id)
			.map((t) =>
				this.manageTemplatesService.moveFolderOrTemplate({
					nodeId: t.templateID,
					parentId: folder.id,
					isFolder: false,
					subMethodId: folder.subMethodId
				})
			);
		forkJoin(moveTemplateRequests).subscribe((results) => {
			if (results && results.length && results.find((r) => r.isSuccess)) {
				if (results.length > 1) {
					this.folderService.templatesBatchMovedEvent({
						templates: draggedTemplates,
						destinationFolderId: folder.id,
						destinationSubMethodId: folder.subMethodId
					});
				} else {
					this.folderService.templateMovedEvent({
						templateId: draggedTemplates[0].templateID,
						sourceSubMethodId: draggedTemplates[0].subMethodId,
						sourceFolderId: draggedTemplates[0].folderId,
						destinationFolderId: folder.id,
						destinationSubMethodId: folder.subMethodId
					});
				}
			}
		});
		this.resetStyles($event, this.getCurrentTarget($event));
	}
	public dropFolder($event, folder) {
		const draggedFolder = JSON.parse($event.dataTransfer.getData(DataTransferConsts.folder)) as TemplateTreeItem;
		this.removeTransparency(draggedFolder.id);
		if (
			draggedFolder.subMethodId === folder.subMethodId &&
			!this.folderService.isChildNode(draggedFolder.id, folder.id, folder.subMethodId) &&
			draggedFolder.parentId !== folder.id
		) {
			this.manageTemplatesService
				.moveFolderOrTemplate({
					nodeId: draggedFolder.id,
					parentId: folder.id,
					isFolder: true,
					subMethodId: folder.subMethodId
				})
				.subscribe((result) => {
					if (result.isSuccess) {
						this.updateTreeDataWithFolderRemoved(draggedFolder.id);
						this.folderService.folderMovedEvent(draggedFolder.id);
					}
				});
		}
		let target = this.getCurrentTarget($event);
		this.resetStyles($event, target);
	}
	public getCurrentTarget(e) {
		if (e.srcElement) {
			return e.srcElement;
		} else if (e.currentTarget) {
			return e.currentTarget;
		} else if (e.toElement) {
			return e.toElement;
		} else {
			return null;
		}
	}
	public isHorizontalScrollActive: boolean = false;
	public templateTreeItems: TemplateTreeItem[];
	public treeData: TreeItem[] = [];
	private initFolderId: number;

	public channels: Channel[];
	public selectedChannel: number;

	public brands: Brand[];
	public dropDownBrands: IListItem[];
	public selectedBrand: string;
	public isBrandsLoading: boolean = true;

	public selectedChannelTitle: string;
	public searchText: string = '';
	private treeModel: TreeModel;
	private subscriptions: Subscription;
	private brandSubmethodsSubscription: Subscription;

	folderMenuItems: OverflowMenuItem[] = this.getFolderMenuItems();
	clickedFolder: TreeItem;
	currentQueryParams: TemplateQueryParams;

	showOverlay: boolean;

	constructor(
		private manageTemplatesService: ManageTemplatesService,
		private route: ActivatedRoute,
		private router: Router,
		private templateContextService: TemplateContextService,
		private searchContextService: SearchContextService,
		private cdRef: ChangeDetectorRef,
		public folderService: FolderService,
		public channelService: ChannelService,
		@Inject(DOCUMENT) private document: Document,
		private templateSelectionService: TemplateSelectionService,
		private templatesDragDropService: TemplatesDragDropService
	) {}

	@HostListener('document:click', ['$event'])
	documentClick(event: MouseEvent) {
		this.showOverlay = false;
		this.cdRef.detectChanges();
	}

	ngOnInit(): void {
		this.subscriptions = this.templateContextService.queryTemplateParams.subscribe((params: TemplateQueryParams) => {
			this.currentQueryParams = params;
			if (params.search) this.setSearchTextWithoutNavigation(params.search);

			if (!params.channelId) {
				this.selectedChannel = 0;
				this.selectedBrand = '';
				return;
			}

			this.initFolderId = +params.folderId;
			this.selectCurrentFolderInTree();

			if (this.selectedChannel !== +params.channelId || this.selectedBrand !== params.brandId) {
				this.selectedChannel = +params.channelId;
				this.selectedBrand = params.brandId;
				if (this.selectedChannel) {
					if (this.channels) {
						this.onSelectedChannelChanged(this.selectedChannel);
						this.loadTree();
					} else {
						this.loadChannels();
					}
				} else {
					this.loadChannels();
				}
			}
		});
		this.subscriptions.add(
			this.folderService.folderCreated.subscribe((createdFolder) => {
				if (createdFolder) {
					this.initFolderId = createdFolder.folderId;
					this.loadTree();
					this.navigateParams({
						...this.currentQueryParams,
						folderId: this.initFolderId.toString(),
						typeId: createdFolder.subMethodId.toString()
					});
				}
			})
		);
		this.subscriptions.add(
			this.folderService.folderNameUpdated.subscribe((folderUpdate) => {
				if (folderUpdate?.folderId && this.treeData) {
					const findFolder = (treeItems: TreeItem[]): TreeItem | null => {
						if (!treeItems) return;
						const foundFolder = treeItems.find((t) => t.id === folderUpdate?.folderId);
						if (foundFolder) return foundFolder;
						const res = treeItems.reduce((previous, current) => {
							if (previous) return previous;
							return findFolder(current.children);
						}, null);
						return res;
					};
					let folder = findFolder(this.treeData);
					let folderFromCache;
					if (folder) {
						folder.text = folderUpdate.folderName;
						this.folderService.updateFolderTree(this.treeData);
						folderFromCache = this.folderService.getFolderFromCache(folder.id, +this.currentQueryParams.typeId);
					}
					if (folderFromCache) {
						folderFromCache.text = folderUpdate.folderName;
					}
				}
			})
		);

		this.subscriptions.add(
			this.folderService.folderDeleted.subscribe((folderId) => {
				this.updateTreeDataWithFolderRemoved(folderId);
			})
		);

		this.subscriptions.add(
			this.searchContextService.searchableTemplatesChanged.subscribe(() => {
				if (!this.searchContextService.isSearchTextValid(this.searchText)) {
					return;
				}

				const fullCache = this.folderService.getFullCache();
				const isAnyTemplatesOrFolders = !!fullCache.find((r) => r.templateTreeItems && r.templateTreeItems.children?.length > 0);
				this.templateContextService.updateIsAnyTemplatesOrFolders(isAnyTemplatesOrFolders);
				this.templateTreeItems = fullCache.map((result) => result.templateTreeItems);
				this.templateTreeItems.sort((t0, t1) => t0.text.localeCompare(t1.text));
				this.treeData = this.filterOnlyFolders(this.templateTreeItems) as unknown as TreeItem[];
				this.setSearchTextWithoutNavigation(this.searchText);
				this.selectCurrentFolderInTree();
				this.cdRef.detectChanges();
			})
		);

		this.subscriptions.add(
			this.highlightDragOver.subscribe((payload) => {
				payload.target.style.backgroundColor = payload.canDrop ? '#C2EBFF' : '';
			})
		);
	}
	updateTreeDataWithFolderRemoved(folderId) {
		if (this.treeData) {
			const filteredFolders = this.treeData.filter((f) => f.id !== folderId);
			if (filteredFolders.length < this.treeData.length) {
				this.treeData = this.treeData.filter((f) => f.id !== folderId);
			} else {
				this.loadTree(true);
			}
		}
	}
	ngOnDestroy() {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}

	loadChannels(): void {
		this.manageTemplatesService.getMetadata().subscribe((data) => {
			const channelIds = Object.keys(data.channels);
			this.channels = channelIds.map((id) => data.channels[id]);
			if (!this.selectedChannel || !this.brands) {
				this.onSelectedChannelChanged(this.selectedChannel ? this.selectedChannel : (this.channels[0].id as number));
			}
			this.loadTree();
		});
		this.manageTemplatesService.getSuppressionGroups().subscribe(() => {}); // this is for caching - editor load faster
		this.manageTemplatesService.getEmailData().subscribe(() => {});
	}

	loadTree(checkForChanges = false): void {
		if (this.brands && this.selectedBrand) {
			const brand = this.brands.find((b) => b.name === this.selectedBrand);

			if (!brand || !brand.subMethods) return;

			this.folderService.resetFolderTreeCache();
			this.brandSubmethodsSubscription?.unsubscribe();
			this.brandSubmethodsSubscription = forkJoin(
				brand.subMethods.map((subMethod) =>
					this.manageTemplatesService
						.getTemplates({
							channelId: this.selectedChannel,
							subMethodId: subMethod.subMethodId
						})
						.pipe(
							map((templateTreeItems) => {
								return {
									templateTreeItems,
									subMethod
								};
							})
						)
				)
			).subscribe((results) => {
				results.forEach((result) => {
					result.templateTreeItems =
						result.templateTreeItems['data'] !== null || result.templateTreeItems['data'] !== undefined
							? result.templateTreeItems
							: [];
				});
				const isAnyTemplatesOrFolders = !!results.find((r) => r.templateTreeItems && r.templateTreeItems.length > 0);
				this.templateContextService.updateIsAnyTemplatesOrFolders(isAnyTemplatesOrFolders);
				this.templateTreeItems = results
					.map((result) => this.folderService.normalizeAndCacheFolderTree(result.templateTreeItems, result.subMethod))
					.filter((treeItem) => treeItem && treeItem.isRootFolder);
				this.templateTreeItems.sort((t0, t1) => t0.text.localeCompare(t1.text));
				this.treeData = this.filterOnlyFolders(this.templateTreeItems) as unknown as TreeItem[];
				if (this.searchText) {
					this.setSearchTextWithoutNavigation(this.searchText);
				}
				this.cdRef.detectChanges();
				this.selectCurrentFolderInTree();
				if (!checkForChanges) {
					this.folderService.updateFolderTree(this.treeData);
					this.activateDisableHorizontalScroll();
					this.searchContextService.updateBrand(brand);
				}

				const initFolder = this.treeModel?.getNodeById(this.initFolderId);
				if (!initFolder && this.templateTreeItems.length) {
					const firstRoot = this.templateTreeItems[0] as RootTemplateTreeItem;
					this.navigate(
						this.selectedBrand,
						this.searchText,
						firstRoot.id,
						firstRoot.subMethodId,
						firstRoot.executionMethodInnerID
					);
				}
			});
		}
	}

	filterOnlyFolders(source: TemplateTreeItem[]): TemplateTreeItem[] {
		if (!source) {
			return [];
		}

		return source.reduce<TemplateTreeItem[]>((previousValue: TemplateTreeItem[], currentValue: TemplateTreeItem) => {
			if (currentValue.rel === RelationType.folder) {
				previousValue.push({
					...currentValue,
					children: this.filterOnlyFolders(currentValue.children)
				});
			}
			return previousValue;
		}, []);
	}

	public onSelectedChannelChanged(value: number): void {
		if (!value) {
			return;
		}
		this.selectedChannel = value;

		if (!this.channels) {
			return;
		}
		const channel = this.channels.find((a) => a.id === this.selectedChannel);

		if (!channel) {
			return;
		}

		this.channelService.setCurrent(channel);
		this.templateContextService.updateIsChannelSupportOtherEditors(channel.otherEditor);
		this.selectedChannelTitle = channel.name;
		this.brands = channel.brands;
		this.dropDownBrands = this.brands.map((brand) => {
			return {
				id: brand.name,
				name: brand.name
			} as IListItem;
		});
		if (this.dropDownBrands && this.dropDownBrands.length && !this.dropDownBrands.find((b) => b.name === this.selectedBrand)) {
			this.onSelectedBrandChanged(this.dropDownBrands[0].name);
		}
		this.isBrandsLoading = false;
		this.templateSelectionService.removeAll();
	}

	public onSelectedBrandChanged(value: string): void {
		if (this.selectedBrand !== value) {
			this.navigateParams({ brandId: value, folderId: undefined });
		}
	}

	folderOverflowClick(folder: any): void {
		this.clickedFolder = folder.data;
	}

	getFolderName(folder: TreeItem, searchText: any) {
		return this.searchContextService.highlightSearchText(folder.text);
	}
	public onSearchTextChange(value: string) {
		this.setSearchText(value);
		if (value.length > this.searchLengthMinimum) {
			this.templateSelectionService.removeAll();
		}
	}

	public clearSearch() {
		this.setSearchText('');
	}

	treeWasInitialized(jsTree: TreeComponent): void {
		this.treeModel = jsTree.treeModel;
		if (this.initFolderId || this.initFolderId === 0) {
			this.selectCurrentFolderInTree();
		}
	}

	onTreeItemClick(origin, folder: any) {
		this.clickedFolder = folder.data;
		this.folderMenuItems = this.getFolderMenuItems(this.clickedFolder);

		const originRect = origin.getBoundingClientRect();
		const masterPanelRect = this.masterPanel.nativeElement.getBoundingClientRect();

		this.overlay.nativeElement.style.left = `${originRect.left - masterPanelRect.left - 10}px`;
		const isAtBottom = masterPanelRect.height - originRect.top < originRect.height;
		const offset = isAtBottom ? -this.overlay.nativeElement.offsetHeight + 10 : -originRect.height;
		this.overlay.nativeElement.style.top = `${originRect.top - masterPanelRect.top + offset + 5}px`;
		this.showOverlay = true;
		this.cdRef.detectChanges();
	}

	isAllTemplatesFolder(folder: TreeItem, channelId: number): boolean {
		if (channelId === RoutingConsts.OPTIMAIL_CHANNEL || folder.parentId !== -1) {
			return false;
		}

		return this.treeData.length === 1;
	}

	private onNodeClick(tree, node, $event) {
		TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
		if (node.isCollapsed) {
			TREE_ACTIONS.EXPAND(tree, node, $event);
			this.activateDisableHorizontalScroll();
		} else {
			TREE_ACTIONS.COLLAPSE(tree, node, $event);
			this.activateDisableHorizontalScroll();
		}
		const templateTreeItem = (node as TreeNode).data as TemplateTreeItem;
		if (templateTreeItem) {
			const root = this.findRoot(templateTreeItem) as RootTemplateTreeItem;
			this.navigate(this.selectedBrand, this.searchText, templateTreeItem.id, root.subMethodId, root.executionMethodInnerID);
			this.templateSelectionService.removeAll();
		}
	}

	private setSearchTextWithoutNavigation(newText: string): void {
		this.searchText = newText;
		const filterSearchText = this.searchContextService.isSearchTextValid(newText) ? newText : '';
		this.searchContextService.updateSearchText(filterSearchText);

		const searchFilteredItems = this.filterBySearchText(this.templateTreeItems, filterSearchText);
		this.treeData = this.filterOnlyFolders(searchFilteredItems) as unknown as TreeItem[];
		this.folderService.updateFolderTree(this.treeData);
		this.activateDisableHorizontalScroll();
	}

	private setSearchText(newText: string): void {
		const navigationSearchText = newText && newText.length < 3 ? '' : newText;
		this.setSearchTextWithoutNavigation(newText);
		const queryParams: NavigationExtras = {
			queryParams: { search: navigationSearchText && navigationSearchText !== '' ? navigationSearchText : null },
			queryParamsHandling: 'merge'
		};
		this.router.navigate([], queryParams);
	}

	private navigate(brandId: string, search: string, folderId?: number, subMethodId?: number, executionMethodInnerID?: number) {
		const queryParams: TemplateQueryParams = {
			brandId
		};
		if (folderId !== undefined) {
			queryParams.folderId = folderId.toString();
		}
		if (folderId === -1) {
			queryParams.folderId = undefined;
		}
		if (subMethodId !== undefined) {
			queryParams.typeId = subMethodId.toString();
		}
		queryParams.search = search;
		this.setSearchTextWithoutNavigation(search);
		this.navigateParams(queryParams);
	}

	private navigateParams(queryParams: TemplateQueryParams) {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			queryParamsHandling: 'merge'
		});
	}

	private findRoot(child: TemplateTreeItem): TemplateTreeItem | null {
		if (!child) return;

		const containsChild = (parent: TemplateTreeItem): boolean => {
			if (parent.id === child.id) return true;
			return parent.children && parent.children.find((t) => t.id === child.id || containsChild(t)) != undefined;
		};
		return this.templateTreeItems.find((t) => containsChild(t));
	}

	private selectCurrentFolderInTree() {
		const node = this.treeModel?.getNodeById(this.initFolderId);
		node?.setActiveAndVisible();
		node?.expand();
	}

	private getFolderMenuItems(folder?: TreeItem): OverflowMenuItem[] {
		const clickedSubMethod = this.findRoot(this.clickedFolder as any as TemplateTreeItem)?.subMethodId;

		const result = [
			this.folderService.getNewFolderMenuItem(() => ({
				folderId: this.clickedFolder.id,
				parentFolderId: this.clickedFolder.parentId,
				subMethodId: clickedSubMethod
			}))
		];
		if (folder?.parentId <= 0) {
			return result;
		}

		result.push(
			this.folderService.getRenameMenuItem(() => ({
				folderId: this.clickedFolder.id,
				currentName: this.clickedFolder.text,
				subMethodId: clickedSubMethod
			}))
		);
		result.push(
			this.folderService.getMoveMenuItem(() => {
				return {
					parentId: this.clickedFolder.parentId,
					clickedFolderId: this.clickedFolder.id,
					selectedChannelId: this.selectedChannel,
					subMethodId: clickedSubMethod
				};
			})
		);
		result.push(this.folderService.getDeleteMenuItem(() => this.clickedFolder.id));
		return result;
	}

	private filterBySearchText(source: TemplateTreeItem[], searchText: string): TemplateTreeItem[] {
		if (!source) {
			return [];
		}

		return source.reduce<TemplateTreeItem[]>((previousValue: TemplateTreeItem[], currentValue: TemplateTreeItem) => {
			const currentIsAMatch = this.searchContextService.isMatch(currentValue.text);
			const isAnyLeafChildAMatch = currentValue.children?.find(
				(c) => c.rel === RelationType.leaf && this.searchContextService.isMatch(c.text)
			);
			const allChildrenAreNoMatchLeafs =
				!currentValue.children?.length ||
				(!isAnyLeafChildAMatch && !currentValue.children.find((c) => c.rel === RelationType.folder));

			if (!currentIsAMatch && allChildrenAreNoMatchLeafs) {
				if (currentValue.parentId <= 0) {
					previousValue.push({ ...currentValue, children: [] });
				}
				return previousValue;
			}

			const childMatches = this.filterBySearchText(
				currentValue.children.filter((c) => c.rel === RelationType.folder),
				searchText
			);
			const currentMatch = {
				...currentValue,
				children: [...childMatches, ...currentValue.children.filter((c) => c.rel === RelationType.leaf)]
			};

			if (currentIsAMatch || childMatches.length || isAnyLeafChildAMatch) {
				previousValue.push(currentMatch);
			} else if (currentValue.parentId <= 0) {
				previousValue.push({ ...currentValue, children: [] });
			}

			return previousValue;
		}, []);
	}

	public activateDisableHorizontalScroll(): void {
		const element = document.getElementsByClassName('tree-node-level-13 tree-node tree-node-expanded');
		if (element.length === 0) {
			this.isHorizontalScrollActive = false;
			return;
		}
		this.isHorizontalScrollActive = true;
	}

	canDrop(subMethodId: number): boolean {
		return this.templatesDragDropService.canDrop(subMethodId);
	}
}
