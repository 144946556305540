<span class="multiple-options-button-container" *ngIf="inputObj?.mainItem !== null">

    <button type="button" class="btn btn-primary main-button" [disabled]="disabled || isLoading"
        (click)="onItemClicked(inputObj.mainItem)">
        <i *ngIf="!isLoading && inputObj.mainItem.iconName" class="material-icons">{{inputObj.mainItem.iconName}}</i>
        <span *ngIf="isLoading" class="loader"></span>
        {{ isLoading ? loadingText : inputObj.mainItem.label }}
    </button>

    <button type="button" class="btn btn-primary menu-button" *ngIf="inputObj.menuItems.length > 0"
        [ngClass]="{'menu-open': isMenuOpen}" (click)="onMenuButtonClicked()"  [disabled]="disabled || isLoading">
        <i class="material-icons">more_vert</i>
    </button>

    <div class="menu" *ngIf="isMenuOpen && inputObj.menuItems.length > 0">
        <ul>
            <li *ngFor="let menuItem of inputObj.menuItems" [ngClass]="{'disabled': menuItem.disabled}"
                (click)="onItemClicked(menuItem)" tabindex="0">
                <i *ngIf="menuItem.iconName" class="material-icons">{{menuItem.iconName}}</i>
                <span>{{ menuItem.label }}</span>
            </li>
        </ul>
    </div>

</span>