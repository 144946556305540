import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BiStudioComponent } from "./biStudio.component";
import { BiStudioLandingPageComponent } from './components/biStudioLandingPage/biStudioLandingPage.component';

const routes: Routes = [
    {
        path: 'biStudioLandingPage',
        component: BiStudioLandingPageComponent,
    },
    {
        path: ':reportName',
        component: BiStudioComponent,
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BiStudioRoutingModule { }