import { AfterContentInit, AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal/public_api';
import { Observable, Subscription } from 'rxjs';
import { first } from "rxjs/operators";
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { OslCellType } from 'src/app/components/optiSearchList/models/osl-cell-type.enum';
import { FeatureFlag, FeatureFlagService } from 'src/app/services/featureFlag.service';
import { SearchItem, SearchListConfig } from '../../../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { OldPurchaseHistoryAttributes } from '../../../attributesList.constants';
import { PurchaseHistoryFamily, PurchaseHistoryFunctionName } from '../../../attributesList.enums';
import { AttributeHelper } from '../../../attributesList.helper';
import { BaseAttribute, CalculationSectionComponent, defaultTimeframes, functionList, OldPurchaseHistoryAttribute, OldPurchaseHistoryProduct } from '../../../attributesList.model';
import { ConditionalValidatorService } from '../../../services/conditionalValidator.service';
import { PurchaseAttributesService } from '../../../services/purchaseAttributes.service';
import { PurchaseAttributeConditionComponent } from '../purchaseAttributeCondition/purchaseHistoryAttributeCondition.component';

@Component({
  selector: 'old-purchase-attribute-calculation-section',
  templateUrl: './oldPurchaseHistoryAttributeCalculationSection.component.html',
  styleUrls: ['./oldPurchaseHistoryAttributeCalculationSection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OldPurchaseCalculationSectionComponent,
      multi: true
    }]
})
export class OldPurchaseCalculationSectionComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor, CalculationSectionComponent {
  @ViewChildren('condition') conditionComponents: QueryList<PurchaseAttributeConditionComponent>;
  @Output() isFormCreated: EventEmitter<boolean> = new EventEmitter();

  public timeframes: SearchItem[] = [];
  public firstConditionAttributes: SearchItem[] = [];
  public secondConditionAttributes: SearchItem[] = [];
  public numericAttributes: SearchItem[] = [];
  public metricAttributes: SearchItem[] = [];
  public textAttributes: SearchItem[] = [];
  public functionList: SearchItem[] = [];

  public translateKeys = translateKeys;
  public form: FormGroup;
  private purchaseHistoryProducts: OldPurchaseHistoryProduct[] = [];
  public timeframeSearchListConfig: SearchListConfig;
  public resultAttributeSearchListConfig: SearchListConfig;
  public funcSearchListConfig: SearchListConfig;

  private currentFunction: SearchItem;
  private isAttributePublished: boolean = false;

  public get hasFuncValue(): boolean {
    return this.currentFunction && this.currentFunction.key;
  };

  public get isWithMetric(): boolean {
    return this.currentFunction ? this.currentFunction.key === 'mostSpent' : false;
  }

  public get isTextFunction(): boolean {
    return ['first','last','mostFrequent','mostSpent'].includes(this.currentFunction?.key);
  };
  
  public get isNumericFunction(): boolean {
    return this.currentFunction ? this.currentFunction.key == 'sum' : false;
  };

  public get needShowTimeframePeriod(): boolean {
    return this.timeframe?.value && (this.timeframe.value[0].key === 1 || this.timeframe.value[0].key === 2);
  };

  private subscriptions: Subscription[] = [];
  private isFormDisabledByDefault: boolean = false;

  constructor(private conditionValidator: ConditionalValidatorService,
    private purchseHistoryService: PurchaseAttributesService,
    private modalService: OptiLogicModalService,
    private translate: TranslateService,
    private featureFlagService: FeatureFlagService) { }

  async ngOnInit(): Promise<void> {
    this.initSearchListsConfigs();
    if (!this.form) {
      this.form = this.createForm();
    }
    this.initFormSubscriptions();
    await this.initSearchListsData();
  }

  ngAfterViewInit(): void {
    const subscription$1 = this.conditionComponents.changes.subscribe(conditionComponents => {
      this.validateCondition(true);
    });
    this.subscriptions.push(subscription$1);
  }

  ngAfterContentInit() {
    this.isFormCreated.emit(true);
  }

  buildAttributeBody(initialAttribute: BaseAttribute): OldPurchaseHistoryAttribute {
    return {
      Conditions: this.isTextFunction 
        ? [] 
        : this.conditionComponents.map(x => x.buildCondition(PurchaseHistoryFamily.OLD_PurchaseHistory)),
      TimeframeId: this.timeframe.value[0].key,
      AttributeBaseType: this.isTextFunction ? "string" : "number",
      TimeframePeriod: this.timeframePeriod.value,
      ResultAttribute: this.resultAttribute.value[0].key,
      Family: AttributeHelper.getAttributeFamily(this.currentFunction.key, true),
      FieldFormat: this.resultAttribute.value[0].fieldFormat,
      Formatting: this.resultAttribute.value[0].fieldFormat,
      FieldName: initialAttribute.FieldName,
      FunctionName: AttributeHelper.getFunctionType(this.currentFunction.key),
      Description: initialAttribute.Description,
      PublishStatus: initialAttribute.PublishStatus,
      Type: initialAttribute.Type,
      IsHidden: initialAttribute.IsHidden,
      IsPersonalization: initialAttribute.IsPersonalization,
    } as OldPurchaseHistoryAttribute;
  }

  isInputEventIsRegularNumber(event) {
    // Between 1-9 validation 
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  // ====| ValueAccessor region |==== \\

  async writeValue(attribute: OldPurchaseHistoryAttribute): Promise<void> {
    if (attribute) {
      if (this.purchaseHistoryProducts.length === 0) {
        await this.initSearchListsData();
      }
      this.initAttributeData(attribute);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.conditions.disable({ emitEvent: false });
      this.timeframe.disable({ emitEvent: false });
      this.timeframePeriod.disable({ emitEvent: false });
      this.resultAttribute.disable({ emitEvent: false });
      this.metricAttribute.disable({ emitEvent: false });
      this.func.disable({ emitEvent: false });
    }
    else {
      this.conditions.enable({ emitEvent: false });
      this.timeframe.enable({ emitEvent: false });
      this.timeframePeriod.enable({ emitEvent: false });
      this.resultAttribute.enable({ emitEvent: false });
      this.metricAttribute.enable({ emitEvent: false });
      this.func.enable({ emitEvent: false });
    }
    this.isFormDisabledByDefault = isDisabled;
  }

  registerOnChange(fn: any): void { this.onChange = fn }

  onChange = (fn: any) => { }

  registerOnTouched(fn: any): void { this.onTouched = fn }

  onTouched = (fn: any) => { }

  // ====| FormControls region |==== \\

  get conditions(): FormArray {
    return this.form.get('conditions') as FormArray;
  }

  get timeframe(): FormControl {
    return this.form.get('timeframe') as FormControl;
  }

  get timeframePeriod(): FormControl {
    return this.form.get('timeframePeriod') as FormControl;
  }

  get resultAttribute(): FormControl {
    return this.form.get('resultAttribute') as FormControl;
  }

  get metricAttribute(): FormControl {
    return this.form.get('metricAttribute') as FormControl;
  }


  get func(): FormControl {
    return this.form?.get('func') as FormControl;
  }

  // ====| private region |==== \\

  private createForm(): FormGroup {
    return new FormGroup({
      func: new FormControl('', [Validators.required]),
      conditions: new FormArray([new FormControl('', [Validators.required])], [Validators.required, this.conditionValidator.validFormArray()]),
      timeframe: new FormControl('', [Validators.required]),
      timeframePeriod: new FormControl('', [Validators.required]),
      resultAttribute: new FormControl('', [Validators.required]),
      metricAttribute: new FormControl('', [])
    });
  }

  private initFormSubscriptions() {
    this.subscriptions.push(
      this.func.valueChanges.subscribe(this.onFuncChanged.bind(this)),
      this.conditions.valueChanges.subscribe(this.onConditionsChanged.bind(this)),
      this.timeframe.valueChanges.subscribe(this.onTimeframesChanged.bind(this)),
      this.form.valueChanges.subscribe(formValue => { this.onChange(formValue) }),
      this.resultAttribute.valueChanges.subscribe(_ => {
        this.updateForm(this.func.value[0]?.key)
      })
    );
  }


  private async initSearchListsData() {
    
      this.functionList = this.featureFlagService.isEnabled(FeatureFlag.PurchaseHistoryAttributesNewScenario)
          ? [...functionList] : functionList.filter(x => !['first','last','mostFrequent','mostSpent'].includes(x.key));
      this.timeframes = defaultTimeframes.map(x => {
        return {
          key: x.key,
          value: x.value,
          groupId: x.groupId,
          order: x.order,
          type: OslCellType.Item
        } as SearchItem;
      });

      if (!this.func.value) {
        this.func.setValue([this.functionList[0]], {emitEvent: false});
        this.onFuncChangedApproved([this.functionList[0]]);
      }

      this.purchaseHistoryProducts = await this.purchseHistoryService.GetProductsForOldPH().pipe(first()).toPromise();

      const newProducts = this.purchaseHistoryProducts.map(x => { return { key: x.RealFieldName, value: x.Name } as SearchItem });

      const areProductsChanged = this.firstConditionAttributes.length !== newProducts.length
        || !newProducts.every((x) => this.firstConditionAttributes.find(y => y.key === x.key));

      if (areProductsChanged) {
        this.firstConditionAttributes = newProducts;
      }

      this.numericAttributes = [];
      this.numericAttributes.push(...this.purchaseHistoryProducts.filter(x => ['decimal', 'int', 'number'].includes(x.DataType.toLowerCase()))
        .map(x => { return { key: x.RealFieldName, value: x.Name, fieldFormat: x.FieldFormat } as SearchItem }));
      this.numericAttributes.push(...OldPurchaseHistoryAttributes.filter(x => x.dataType === 'number')
        .map(x => { return { key: x.realFieldName, value: x.aliasName, fieldFormat: x.fieldFormat } as SearchItem }));
      this.textAttributes.push(...this.purchaseHistoryProducts.filter(x => !['decimal', 'int', 'number'].includes(x.DataType.toLowerCase()))
      .map(x => { return { key: x.RealFieldName, value: x.Name, fieldFormat: x.FieldFormat } as SearchItem }));
      this.textAttributes.push(...OldPurchaseHistoryAttributes.filter(x => x.dataType === 'string')
        .map(x => { return { key: x.realFieldName, value: x.aliasName, fieldFormat: x.fieldFormat } as SearchItem }));

      this.metricAttributes = this.numericAttributes.filter(x => x.key === "Amount");
      this.metricAttribute.setValue([this.metricAttributes.find(x => x.key === "Amount")], { emitEvent: false });
      this.metricAttribute.disable();
      
      this.updateResultAttributeSource();
  }

  private updateResultAttributeSource() {
    switch (this.func.value[0].key) {
      case 'first':
      case 'last':
      case 'mostFrequent':
      case 'mostSpent': {
        if (this.textAttributes.length > 1) {
          if (!this.textAttributes.includes(this.resultAttribute.value[0])) {
            this.resultAttribute.setValue('', { emitEvent: false });
          }
          this.resultAttribute.enable({ emitEvent: false });
        }
        else if (this.textAttributes.length === 1) {
          this.resultAttribute.setValue(this.textAttributes[0], { emitEvent: false });
          this.resultAttribute.enable({ emitEvent: false });
        }
        else {
          this.resultAttribute.setValue('', { emitEvent: false });
          this.resultAttribute.disable({ emitEvent: false });
        }
        break;
      }
      case 'sum': 
      default: {
        if (this.numericAttributes.length > 1) {
          if (!this.numericAttributes.includes(this.resultAttribute.value[0])) {
            this.resultAttribute.setValue('', { emitEvent: false });
          }
          this.resultAttribute.enable({ emitEvent: false });
        }
        else if (this.numericAttributes.length === 1) {
          this.resultAttribute.setValue(this.numericAttributes[0], { emitEvent: false });
          this.resultAttribute.enable({ emitEvent: false });
        }
        else {
          this.resultAttribute.setValue('', { emitEvent: false });
          this.resultAttribute.disable({ emitEvent: false });
        }
        break;
      }
    }
  }

  private initAttributeData(attribute: OldPurchaseHistoryAttribute) {
    if (!this.form) {
      this.form = this.createForm();
      this.initFormSubscriptions();
    }
    this.setDisabledState(this.isFormDisabledByDefault);

    const func = [this.functionList.find(x => x.key.toLowerCase() === attribute.FunctionName.toLowerCase())];
    this.func.setValue(func, {emitEvent: false});
    this.onFuncChangedApproved(func, this.isNewFuncType(func));

    const timeframe = this.timeframes.find(x => x.key === attribute.TimeframeId);
    this.timeframe.setValue([timeframe], { emitEvent: false });
    this.onTimeframesChanged([timeframe], false);

    if ([1, 2].includes(timeframe.key)) {
      this.timeframePeriod.setValue(attribute.TimeframePeriod, { emitEvent: false });
    }

    attribute.Conditions.forEach((x, i) => {
      this.conditions.controls[i].setValue(attribute.Conditions[i], { emitEvent: false });
    });

    switch (this.func.value[0].key) {
      case 'first':
      case 'last':
      case 'mostFrequent':
      case 'mostSpent': {
        this.resultAttribute.setValue([this.textAttributes.find(x => x.key === attribute.ResultAttribute)], { emitEvent: false });
        this.conditions.disable({ emitEvent: false });
        break;
      }
      case 'sum': 
      default: {
        this.resultAttribute.setValue([this.numericAttributes.find(x => x.key === attribute.ResultAttribute)], { emitEvent: false });
        break;
      }
    }

    this.isAttributePublished = attribute.PublishStatus === "Published";
    
    if (this.isAttributePublished) {
      this.form.disable();
    } else {
      this.form.updateValueAndValidity();
    }
  }

  private onTimeframesChanged(timeframes: SearchItem[], emitEvent: boolean = true) {
    if ([1, 2].includes(timeframes[0].key)) {
      this.timeframePeriod.setValidators([Validators.required]);
    } else {
      this.timeframePeriod.setValue(1, { emitEvent });
      this.timeframePeriod.setValidators([]);
    }
    this.timeframePeriod.updateValueAndValidity({ emitEvent });
    this.updateForm(this.func.value[0]?.key);
  }

  private onConditionsChanged(conditions) {
    if (conditions.length > 0 && this.conditionComponents) {
      this.validateCondition(false);
    }
  }

  private onFuncChanged(funcs: SearchItem[], emitEvent: boolean = false) {
    const isCalcBodyPristine = () => { return this.purchseHistoryService.isCalculationBodyPristine(this.form, this.func) };
    const isNewFunctionSelected = this.isNewFuncType(funcs[0]);
    if (funcs && isNewFunctionSelected && !isCalcBodyPristine()) {
      this.openConfirmModal(() => {
        this.discardFuncChanging([this.currentFunction]);
      }, () => {
        this.onFuncChangedApproved(funcs, isNewFunctionSelected);
      });
    }
    else if (funcs) {
      this.onFuncChangedApproved(funcs, isNewFunctionSelected);
    }
  }

  private onFuncChangedApproved(funcs: SearchItem[], isNewFunction: boolean = false) {
    const func = funcs && funcs.length ? funcs[0] : null;
    if (!func) {
      this.conditions.disable({ emitEvent: false });
      this.timeframe.disable({ emitEvent: false });
      this.timeframePeriod.disable({ emitEvent: false });
      this.resultAttribute.disable({ emitEvent: false });
      this.metricAttribute.disable({ emitEvent: false });
    }
    else {
      this.currentFunction = func;
      this.timeframe.enable({ emitEvent: false });
      this.timeframePeriod.enable({ emitEvent: false });
      this.resultAttribute.enable({ emitEvent: false });
      this.metricAttribute.enable({ emitEvent: false });
      if (isNewFunction) {
        this.updateForm(func.key);
        this.updateResultAttributeSource();
      }
    };
  }

  private discardFuncChanging(prevFuncValue: SearchItem[]) {
    this.func.setValue(prevFuncValue, {emitEvent: false});
  }

  private isNewFuncType(nextFunc: SearchItem) {
    // return true if the type of the current function is not the same as the type of the next function   
    return (this.isTextFunction && nextFunc.key === "sum") 
        || (!this.isWithMetric && nextFunc.key === "mostSpent")
        || ((this.isNumericFunction || this.isWithMetric) && ['first','last','mostFrequent'].includes(nextFunc.key));
  }

  private updateForm(func) {
    switch (func) {
      case 'first':
      case 'last':
      case 'mostFrequent':
        this.conditions.disable({ emitEvent: false });
        this.metricAttribute.disable({ emitEvent: false });
        break;
      case 'mostSpent':
        this.metricAttribute.enable({ emitEvent: false });
        this.conditions.disable({ emitEvent: false });
        break;
      case 'sum':
      default:
        if (this.isAttributePublished) {
          this.conditions.disable({ emitEvent: false });
        }
        else {
          this.conditions.enable({ emitEvent: false });
        }
        this.metricAttribute.disable({ emitEvent: false });
        break;
    }
  }

  private validateCondition(emitEvent: boolean) {
    this.conditions.controls.forEach((x, i) => {
      let invalidCondition = false;
      let areSomeControlsDisabled = false;
      const conditionComponent = this.conditionComponents.get(i);

      if (conditionComponent) {
        invalidCondition = conditionComponent.form.invalid;

        for (const control in conditionComponent.form.controls) {
          areSomeControlsDisabled = conditionComponent.form.controls[control].disabled;
          if (areSomeControlsDisabled) break;
        }
      }
      else {
        invalidCondition = true;
      }

      x.setErrors(invalidCondition || areSomeControlsDisabled ? { 'invalidCondition': true } : null, { emitEvent })
    });
  }

  private initSearchListsConfigs() {
    let defaultSearchListConfig = {
      keyProperty: "key",
      valueProperty: "value",
      isMultiSelect: false,
    };

    this.timeframeSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.timeframe,
      placeholderTranslateKey: this.translateKeys.timeframe,
    }

    this.resultAttributeSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.select,
      placeholderTranslateKey: this.translateKeys.select,
    }

    this.funcSearchListConfig = {
      ...defaultSearchListConfig,
      itemNameTranslateKey: this.translateKeys.select,
      placeholderTranslateKey: this.translateKeys.select,
    }
  }

  private openConfirmModal(confirmAction: () => void, discardAction: () => void) {
    if (this.form.dirty) {
      this.modalService.openModalMessage(
        'sm',
        {
          message: this.translate.instant(this.translateKeys.confirmBackMessage),
          buttons: [
            {
              class: 'btn-primary',
              label: this.translate.instant(this.translateKeys.continueEditing),
              action: confirmAction.bind(this),
            },
            {
              class: 'btn-default',
              label: this.translate.instant(this.translateKeys.discardChanges),
              action: discardAction.bind(this),
            },
          ]
        },
        <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
      );
    }
    else {
      confirmAction();
    }
  }
}

const translateKeys = {
  attributeCalculation: 'features.user_settings.body.attributes.addCalculatedAttribute.ATTRIBUTE_CALCULATION',
  activityType: 'features.user_settings.body.attributes.purchaseAttributes.ACTIVITY_TYPE',
  timeframe: 'features.user_settings.body.attributes.purchaseAttributes.TIMEFRAME',
  of: 'features.user_settings.body.attributes.purchaseAttributes.OF',
  days: 'features.user_settings.body.attributes.purchaseAttributes.DAYS',
  attributes: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTES',
  attribute: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTE',
  add: 'features.user_settings.body.attributes.purchaseAttributes.ADD',
  attributeResultLable: 'features.user_settings.body.attributes.purchaseAttributes.ATTRIBUTE_RESULT_LABLE',
  select: 'features.user_settings.body.attributes.purchaseAttributes.SELECT',
  confirmBackMessage: 'features.user_settings.body.attributes.confirmModal.MESSAGE',
  continueEditing: 'general.CONTINUE_EDITING',
  discardChanges: 'general.DISCARD_CHANGES',
  function: 'features.user_settings.body.attributes.purchaseAttributes.FUNCTION',
  where: 'features.user_settings.body.attributes.purchaseAttributes.WHERE',
  in: 'features.user_settings.body.attributes.purchaseAttributes.IN',
  on: 'features.user_settings.body.attributes.purchaseAttributes.ON'
};
