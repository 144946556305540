import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IListItem } from "@optimove/ui-sdk/common/models";
import { MappingAttributes } from '../../models/response/mapping-attributes.model';
import { ImportedSelectedAttribute } from '../../models/internal/imported-selected-attribute.model';

@Component({
	selector: 'columns-mapping',
	templateUrl: './columns-mapping.component.html',
	styleUrls: ['./columns-mapping.component.scss']
})
export class ColumnsMappingComponent implements OnInit {
  private readonly translationPrefix = "features.user_settings.body.importedCustomers.COLUMNS_MAPPING";
  @Input() attributeItems: IListItem[] = [];
  @Input() selectedAttribute: ImportedSelectedAttribute;
  @Input() attribute: MappingAttributes;
  @Input() isSelectionDisabled: boolean;
  public isDeleteButtonDisabled?: boolean = null;
  public isDeleteAvailible: boolean = false;
  public selectAttributePlaceholder: string;
  public warningTitle: string;
    
  @Output() selectedAttributeChange = new EventEmitter<ImportedSelectedAttribute>();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  	this.isDeleteButtonDisabled = this.isSelectionDisabled ? true : null;
  	this.isDeleteAvailible = this.selectedAttribute.selectedAttributeRealFieldName != null;
  	this.selectAttributePlaceholder = this.translate.instant(`${this.translationPrefix}.SELECT_ATTRIBUTE`);
  	this.warningTitle = this.translate.instant(`${this.translationPrefix}.DUPLICATION_COLUMN_MAPPING`);
  }

  onSelectedAttributeChanged(value:string){
  	this.isDeleteAvailible = true;
  	this.changeSelectionOfAttribute(value);
  }

  cleanAttributeSelection(e: any){
  	this.changeSelectionOfAttribute(null);
  	this.isDeleteAvailible = false;
  }

  changeSelectionOfAttribute(value?: string){
  	if(!value){
  		this.selectedAttribute.isValid = null;
  	}
  	this.selectedAttribute.selectedAttributeRealFieldName = value;
  	this.selectedAttribute.mappingColumnId = this.attribute.columnId;
  	this.selectedAttributeChange.emit(this.selectedAttribute);
  }
}
