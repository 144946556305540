import { Injectable } from "@angular/core";
import { MainMetricTypes, TableMetricTypes } from "./campaignAnalysis.models";

@Injectable({
    providedIn: 'root'
})
export class SpecialChannelsService {
    config = {
        8: { // Facebook
            filter: [
                MainMetricTypes.Clicked,
                TableMetricTypes.Reach,
                TableMetricTypes.Impressions
            ]
        },
        12: { // Text Local
            filter: [
                MainMetricTypes.Delivered,
                TableMetricTypes.Sent,
                TableMetricTypes.Bounced
            ]
        },
        29: { // Optipush
            filter: [
                MainMetricTypes.Delivered,
                MainMetricTypes.Opened,
                TableMetricTypes.Sent,
                TableMetricTypes.Bounced
            ]
        },
        434: { // Klaviyo
            filter: [
                MainMetricTypes.Delivered,
                MainMetricTypes.Opened,
                TableMetricTypes.Sent,
                MainMetricTypes.Clicked,
                TableMetricTypes.Unsubscribed,
            ]
        },
        441: { // Mobivate
            filter: [
                MainMetricTypes.Delivered,
                TableMetricTypes.Sent,
                MainMetricTypes.Clicked
            ]
        },
        503: { // Direct Mail
            filter: [
                TableMetricTypes.Sent,
            ]
        },
        496: { // Blue Infinity
            filter: [
                MainMetricTypes.Delivered,
                TableMetricTypes.Sent,
                TableMetricTypes.Unsubscribed,
            ]
        },
        507: { // In-platform
            filter: [
                MainMetricTypes.Delivered,
                MainMetricTypes.Opened,
                TableMetricTypes.Sent,
                MainMetricTypes.Clicked,
            ]
        },
        510: { // Web push
            filter: [
                MainMetricTypes.Delivered,
                MainMetricTypes.Clicked,
                TableMetricTypes.Sent,
                MainMetricTypes.Opened,
            ]
        },
    }

    getSpecialChannels(): number[] {
        return Object.keys(this.config).map(key => +key);
    }

    isChannelSpecial(id: number): boolean {
        return this.getSpecialChannels().includes(id);
    }

    getMetricsFilter(id: number): string[] {
        const value = this.config[id];
        if (!value)
            return null;
        return [...value.filter];
    }
}