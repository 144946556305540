<div class="tg-priority-list" style="height: 63vh;">
    <ag-grid-angular
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [modules]="modules"
            (rowDragEnd)="onRowDragEnd($event)"
            (rowDragEnter)="onRowDragEnter($event)"
            (rowClicked)="onRowClicked($event)"
            (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>