import { AllModules, GridApi, GridOptions, Module } from '@ag-grid-enterprise/all-modules';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { ModalOptions } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { OptiLogicModalService } from "../../../components/optiLogicModal/optiLogicModal.service";
import { attributePrefixesToHide } from "./attributesList.constants";
import { AttributeHelper } from "./attributesList.helper";
import { AttributeConfiguration, TableBaseAttribute, TableCalculatedAttribute, TableConditionalAttribute, TableFormulaBasedAttribute } from "./attributesList.model";
import { AttributeCountTagConfig } from "./components/AttributesCountTag/attributeCountTag.component";
import { AddAttributePopupComponent } from "./components/addAttributePopup/addAttributePopup.component";
import { StatusFilterComponent } from "./filters/statusFilter/statusFilter.component";
import { TypeFilterComponent } from "./filters/typeFilter/typeFilter.component";
import { AttributesListService } from "./services/attributesList.service";
import { exhaustMap } from 'rxjs/operators';

@Component({
    selector: 'attributes-list',
    templateUrl: './attributesList.component.html',
    styleUrls: ['./attributesList.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AttributesListComponent implements OnInit , OnDestroy {
    public gridOptions: GridOptions;
    public translateKeys = translateKeys;
    public attributes: (TableBaseAttribute | TableCalculatedAttribute | TableFormulaBasedAttribute)[] = [];
    public modules: Module[] = AllModules;
    public attrCountTagConfig: AttributeCountTagConfig = {
        hideRTAttrs: true,
    };

    public config = {
        loading: true,
        disableAddAttr: false,
        noAttributes: false,
        allPublished: false,
        isClickable: false,
        publishError: false,
        publishErrorString: ""
    };

    private maxCountOfCreatedAttributes = 60;
    private gridApi: GridApi;
    subscriptions: Subscription[] = [];
    constructor(
        public attributesListService: AttributesListService,
        private service: AttributesListService,
        private modalService: OptiLogicModalService,
        private translate: TranslateService,
        private router: Router,
        private readonly cd: ChangeDetectorRef
        ) {}

    ngOnInit() {
        
        this.attributesListService.getAttributesTags()
        .pipe(exhaustMap(() => this.attributesListService.getInternalFieldsList()))
        .subscribe(() => {
            this.loadAttributes();
            this.cd.detectChanges();
        });

        var subscription1$ = this.attributesListService.getAttributeConfiguration()
                                .subscribe((config: AttributeConfiguration) => {
                                        this.maxCountOfCreatedAttributes = config.MaxCountOfCreatedAttributes;
                                        this.cd.detectChanges();
                                });
        this.subscriptions.push(subscription1$);
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    public moveToCreateAttribute() {
        this.router.navigate([`/user-settings/attributes/calculatedAddAttribute`]);
    }

    public loadAttributes(onlyUpdate: boolean = false) {
        this.attributesListService.getAttributes()
        .subscribe((res: (TableBaseAttribute | TableCalculatedAttribute | TableFormulaBasedAttribute | TableConditionalAttribute)[]) => {
            this.attributes = res.filter(this.attributeFilter);
            this.checkNoAttributes();
            this.checkPublishedStatus();
            this.checkLimitForAttributes();
            this.checkPublishError();
            
            if (onlyUpdate) {
                this.setGridData(this.attributes);
            } else {
                this.initGridConfig(this.attributes);
            }

            this.config.loading = false;
            this.cd.detectChanges();
        }, (err) => {
            console.error(err);
        });
    }
    
    public async publishClick() {
        this.updateToPublishing();
        this.attributesListService.publishAll()
        .subscribe(async (res: DefaultAjaxResponse) => {
            if (res.IsSuccess) {
                this.config.loading = true;
                this.loadAttributes(true);
            }
            await this.attributesListService.updateCustomerAttributes();
            this.cd.detectChanges();
        }, (err) => {
            console.error(err);
        });
    }

    public openPopup() {
        this.modalService.open(
            AddAttributePopupComponent, 
            "lg", 
            <ModalOptions<any>>{ 
                ignoreBackdropClick: false,
                keyboard: true
        });
    }

    public onTextFilterChanged(filterText: string){
        this.gridOptions.rowData = this.attributes.filter(x => x.DisplayName?.toLowerCase().includes(filterText?.toLowerCase()));
        this.gridOptions.api.setRowData(this.gridOptions.rowData);
    }

    private updateToPublishing() {
        this.attributes.forEach(x => { 
            x.PublishStatus = ["NotPublished", "FailedToPublish"].includes(x.PublishStatus) 
                ? "Publishing" : x.PublishStatus;
        });
        this.setGridData(this.attributes);
        this.checkPublishedStatus();
    }

    private initGridConfig(attrArr: (TableCalculatedAttribute | TableBaseAttribute)[]) {
        const columnDefs = AttributeHelper.getColumnDefs();
        const rowData = AttributeHelper.prepareData(attrArr);
        this.gridOptions = {
            columnDefs: columnDefs,
            rowData: rowData,
            context: this,
            getRowHeight: this.getLastRowHeight.bind(this),
            headerHeight: 50,
            rowHeight: 55,
            suppressRowTransform: true,
            suppressCellSelection: true,
            multiSortKey: 'ctrl',
            frameworkComponents: {
                statusFilter: StatusFilterComponent,
                typeFilter: TypeFilterComponent
            },
            getMainMenuItems: (params) => {
                return params.defaultItems;
            },
            getRowNodeId: (data) => {
                return data.Name;
            },
            onGridReady: (params) => {
                this.gridApi = params.api;
            },
            getContextMenuItems: this.service.getContextMenuItems.bind(this.service),
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true,
                filterParams: {
                    buttons: ['reset', 'apply'],
                    debounceMs: 200
                }
            }
        };
    }

    private getLastRowHeight(params: any): number {
        if (params.node && params.node.rowIndex === this.attributes.length - 1) {
            return 80;
        }
        return 40;
    }

    public onSortChanged(event: any) {
        if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.resetRowHeights();
        }
    }
    

    private setGridData = (attributes: (TableCalculatedAttribute | TableBaseAttribute)[]) => {
        this.gridApi.setRowData(AttributeHelper.prepareData([...attributes]));
        this.gridApi.refreshClientSideRowModel();
        this.gridApi.refreshHeader();
        this.gridApi.refreshCells();
    }

    //#region Checks

    private checkLimitForAttributes = () => {
        this.config.disableAddAttr = this.attributesListService.getCountOfCreatedAttributes() >= this.maxCountOfCreatedAttributes;
    }

    private checkPublishError = () => {
        this.config.publishError = this.attributes.findIndex(x => x.PublishStatus === "FailedToPublish") !== -1;
        if (this.config.publishError) {
            this.attributesListService.getLastPublishTime().subscribe((res) => {
                if (res.IsSuccess) {
                    this.config.publishErrorString = this.translate.instant(
                        translateKeys.publishFailed, 
                        { timestamp: AttributeHelper.getTimestamp(res.Data) }
                    );
                    this.cd.detectChanges();
                }
            });
        }
    }

    private checkPublishedStatus = () => {
        this.config.allPublished = this.attributes.every(x => x.PublishStatus === "Published");
        this.config.isClickable = this.attributes.some(x => ["NotPublished", "FailedToPublish"]
            .includes(x.PublishStatus));
    }

    private checkNoAttributes = () => {
        this.config.noAttributes = this.attributes.length === 0;
    }

    private attributeFilter(attribute: TableCalculatedAttribute | TableBaseAttribute | TableFormulaBasedAttribute | TableConditionalAttribute): boolean {
        return !(["realtime", "base"].includes(attribute.Type) && attributePrefixesToHide.some(x => attribute.Name.includes(x)));
    }

    //#endregion
}

const translateKeys = {
    upTo15: 'features.user_settings.body.attributes.UP_TO_15_ATTRIBUTES',
    title: 'features.user_settings.body.attributes.TITLE',
    noAttributes: 'features.user_settings.body.attributes.NO_ATTRIBUTES',
    addFirst: 'features.user_settings.body.attributes.ADD_FIRST',
    allPublished: 'features.user_settings.body.attributes.ALL_PUBLISHED',
    publishFailed: 'features.user_settings.body.attributes.PUBLISHED_FAILED',
    popoverBtn: 'features.user_settings.body.attributes.countPopover.BUTTON_TITLE',
    totalAttributes: 'features.user_settings.body.attributes.countPopover.TOTAL_ATTRIBUTES',
    databaseAttributes: 'features.user_settings.body.attributes.countPopover.DATABASE_ATTRIBUTES',
    apiAttributes: 'features.user_settings.body.attributes.countPopover.API_ATTRIBUTES',
    calculatedAttributes: 'features.user_settings.body.attributes.countPopover.CALCULATED_ATTRIBUTES'
}