<div class="search-wrapper" (click)="filterFocused()" [ngClass]="{'disabled': isDisabled, 'is-external-drop-down-mode': isExternalDropDownMode}">
    <div class="searchListButtonContainer">
        <div class="searchListButton">
            <input class="title form-control searchListInput searchListTitlePlaceHolder" [ngClass]="{'invalid': markAsInvalid}"
                    [value]="closed || !includeSearch ? label : ''" [placeholder]="placeholder"
                    (input)="filterChanged($event)" (keydown)="onKeyDown($event)" tabindex="0"
                    [disabled]="isDisabled" #filterField [title]="title">
        </div>

        <!-- The following is a solution for displaying tooltip correctly -->
        <span #buttonTooltip  class="searchListButtonTooltip tooltip-multi-line-1" tabindex="-1">
            <span #buttonTooltipCalculator class="titleSizeCalculator"> {{ label }} </span>
        </span>
    </div>

    <div class="searchIcon">
        <i [ngSwitch]="searchState" class="material-icons" [ngClass]="iconClassList" (click)="onIconClicked()">
            {{iconName}}
        </i>
    </div>

</div>