import { first } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Customer360DataService } from '../../services/customer360.data.service';
import { IAttributeMetric, IFormatMetricOptions, IHeaderMetric } from '../../customer360.models';
import { Customer360FormatService } from '../../services/customer360.format.service';
import { FeatureFlag, FeatureFlagService } from '../../../../services/featureFlag.service';
import { dataSummaryTranslateKeys } from '../../customer360.translate.key';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { ConsentModalComponent } from '../consentModal/consentModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { PreferenceHistoryModalComponent } from "../preferenceHistoryModal/preferenceHistoryModal.component";

@Component({
	selector: 'customer-data-summary',
	templateUrl: './customerDataSummary.component.html',
	styleUrls: ['./customerDataSummary.component.scss']
})
export class CustomerDataSummaryComponent implements OnChanges, OnInit {

	@Input() customerId: string;
	@Input() clientCustomerId: string;
	@Output() onDataLoaded = new EventEmitter<void>();

	public headerAttributeMetric: IHeaderMetric;
	public metrics: IHeaderMetric[] = [];
	public isHeaderAttributeLoading: boolean = false;

	public useConsentFF: boolean;
	public translateKeys = dataSummaryTranslateKeys;
	public isPrefCenterEnabled: boolean = false;

	constructor(private dataService: Customer360DataService, private formatService: Customer360FormatService, private featureFlagService: FeatureFlagService, private optiLogicModalService: OptiLogicModalService) { 
		this.useConsentFF = this.featureFlagService.isEnabled(FeatureFlag.UseConsent);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.clientCustomerId && changes.clientCustomerId.currentValue) {
			this.dataService.getCustomerHeaderDetails(this.clientCustomerId).pipe(first()).subscribe((res) => {
				this.headerAttributeMetric = res.Data.CustomerHeaders[0];
				this.setupHeaderAttribute();
				this.metrics = (res.Data.CustomerHeaders as Array<IHeaderMetric>).slice(1);
				this.formatMetrics();
				this.onDataLoaded.emit();
			});
		}
	}

	public onFirstAttributeChanged(attributeMetric: IAttributeMetric) {
		this.isHeaderAttributeLoading = true;
		this.dataService.setFirstAttribute(attributeMetric.FieldName, this.clientCustomerId).pipe(first()).subscribe((res) => {
			if (res.IsSuccess) {
				const formatOptions: IFormatMetricOptions = {
					itemToformat: this.headerAttributeMetric,
					formatSource: attributeMetric,
					metricValue: res.Data,
					titleToUpperCase: true,
				}
				this.headerAttributeMetric = this.formatService.formatMetric(formatOptions);
			}
			this.isHeaderAttributeLoading = false;
		});
	}

	public openOldConsentModal(){
		this.optiLogicModalService.open(
			ConsentModalComponent, 'lg',
			<ModalOptions<any>>{
				ignoreBackdropClick: true,
				initialState: {
					//parameters are swiched because of the inner mistake in the ConsentModalComponent
					customerId: this.clientCustomerId,
					clientCustomerId: this.customerId,
				}
			}
		);
	}
	public openPreferenceHistoryModal(){
		this.optiLogicModalService.open(
			PreferenceHistoryModalComponent, 'xl',
			<ModalOptions<any>>{
				ignoreBackdropClick: false,

				initialState: {
					clientCustomerId: this.customerId,
				}
			}
		);
	}


	private setupHeaderAttribute() {
		this.headerAttributeMetric = this.formatService.formatMetric({itemToformat: this.headerAttributeMetric, titleToUpperCase: true});
	}

	private formatMetrics() {
		for (let i = 0; i < this.metrics.length; i++) {
			this.metrics[i] = this.formatService.formatMetric({ itemToformat: this.metrics[i], titleToUpperCase: false });
		}
	}

	ngOnInit(): void {
		this.isPrefCenterEnabled = this.featureFlagService.isEnabled(FeatureFlag.UseNewPreferenceCenterHistoryModal);
	}
}
