import { Injectable } from "@angular/core";
import { HttpService } from "./optimove-http/optimove-http.model";
import { WindowRefService } from "./windowRef.service";
import { SignalrService48 } from "./signalr48.service";
import { SignalrService6 } from "./signalr6.service";

@Injectable()
export class SignalrService {
  private signalR48: SignalrService48;
  private signalR6: SignalrService6;

  constructor(private readonly windowService: WindowRefService, private readonly http: HttpService) {
    this.signalR6 = new SignalrService6(windowService, http);
    this.signalR48 = new SignalrService48(windowService);
  }

  async init(): Promise<void> {
    Promise.all([this.signalR6.init(), this.signalR48.init()]);
    this.windowService.nativeWindow.signalrService = this;
  }

  on(
    hubName: string,
    methodName: string,
    newMethod: (...args: any[]) => any
  ): void {
    this.signalR6.getConnection(hubName)?.on(methodName, newMethod);
    this.signalR48.getConnection(hubName)?.on(methodName, newMethod);
  }

  off(
    hubName: string,
    methodName: string,
    method: (...args: any[]) => void
  ): void {
    this.signalR6.getConnection(hubName)?.off(methodName, method);
    this.signalR48.getConnection(hubName)?.off(methodName, method);
  }

  private _getCookie(key) {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  }

  isCurrentUser(uniqueUserIdentifier) {
    return uniqueUserIdentifier === this._getCookie("ClientUniqueId");
  }
}
