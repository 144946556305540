import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ManageTemplatesService } from './manageTemplates.service';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TemplateQueryParams } from '../models/templateQueryParams';
import { RoutingConsts } from '../models/routing.consts';
import { TemplateContextService } from './templateContext.service';
import { ChannelService } from './channel.service';
import { HttpParams } from '@angular/common/http';
import { RouterNavigationUrl } from '../models/routerNavigationUrl';

@Injectable({
	providedIn: 'root'
})
export class TemplateNavigationService {
	constructor(
		private router: Router,
		private manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private channelService: ChannelService
	) {}

	navigateToEditTemplate(
		channelId: number,
		templateId: number,
		folderId: number,
		typeId: number,
		otherEditor: boolean,
		search?: string
	): void {
		const queryParams = {
			channelId,
			templateId,
			folderId,
			onlyedit: true,
			search,
			typeId
		};
		this.navigate(queryParams, otherEditor);
	}

	getEditTemplateLink(
		channelId: number,
		templateId: number,
		folderId: number,
		typeId: number,
		otherEditor: boolean,
		search?: string
	): string {
		const queryParamsForRoute = {
			...this.router.parseUrl(this.router.url).queryParams,
			channelId,
			templateId,
			folderId,
			onlyedit: true,
			typeId
		};
		if (search) {
			(queryParamsForRoute as any).search = search;
		}
		const url = this.getNavigationUrl(otherEditor, channelId);
		const queryString = new HttpParams({ fromObject: queryParamsForRoute }).toString();

		return `/#/${url}?${queryString}`;
	}

	navigateToNewTemplate(editor?: string): Observable<void> {
		return this.templateContextService.queryTemplateParams.pipe(first()).pipe(
			map((existingQueryParams) => {
				const queryParams = {
					channelId: existingQueryParams.channelId,
					brandId: existingQueryParams.brandId,
					folderId: existingQueryParams.folderId,
					typeId: existingQueryParams.typeId,
					onlyedit: true,
					visualeditor: editor === 'beefree'
				} as TemplateQueryParams;

				if (queryParams.visualeditor) {
					queryParams.templateId = '0';
				}

				if (existingQueryParams.search) {
					queryParams.search = existingQueryParams.search;
				}

				this.navigateWithNewParams(queryParams);
			})
		);
	}

	navigateToCampaign(channelProperties: string, type: string): void {
		this.router.navigate([`${RoutingConsts.ROOT}/campaign-builder/${type}`], {
			queryParams: {
				channelProperties
			}
		});
	}

	navigate(queryParams, otherEditor?: boolean) {
		const queryParamsForRoute = {
			...this.router.parseUrl(this.router.url).queryParams,
			...queryParams
		};

		this.navigateWithNewParams(queryParamsForRoute, otherEditor);
	}

	createLobbyUrl(): Observable<RouterNavigationUrl> {
		const path = this.router.url;
		if (!path) {
			return;
		}
		return this.templateContextService.queryTemplateParams.pipe(first()).pipe(
			map((queryParams) => {
				const channelId = queryParams?.channelId;
				if (!channelId) {
					return;
				}

				const result: RouterNavigationUrl = {
					routerLink: `/${RoutingConsts.ROOT}/${RoutingConsts.MANAGE_TEMPLATES_PART}/${RoutingConsts.LOBBY}/${channelId}`,
					queryParams: {
						brandId: queryParams.brandId,
						folderId: queryParams.folderId,
						typeId: queryParams.typeId,
						search: queryParams.search
					}
				};
				return result;
			})
		);
	}

	private navigateWithNewParams(queryParams: TemplateQueryParams, otherEditor?: boolean): void {
		let isOtherEdtior$: Observable<boolean>;
		const isOtherEditor = otherEditor !== null && otherEditor !== undefined ? otherEditor : queryParams.visualeditor;

		// we may still have no information about otherEditor value when navigation is triggered from template editor via arrow buttons
		if ((isOtherEditor === null || isOtherEditor === undefined) && +queryParams.templateId) {
			isOtherEdtior$ = this.manageTemplatesService
				.getTemplateDetails({ templateId: queryParams.templateId })
				.pipe(map((resp: any) => resp?.otherEditor));
		} else {
			isOtherEdtior$ = of(isOtherEditor);
		}

		isOtherEdtior$.subscribe((isBeefree: any) => {
			this.router.navigate([this.getNavigationUrl(isBeefree, +queryParams.channelId)], {
				queryParams
			});
		});
	}

	private getNavigationUrl(isBeefree: boolean, channelId: number): string {
		const templatesPathPart =
			isBeefree || this.channelService.isOptimobile(channelId)
				? RoutingConsts.MANAGE_TEMPLATES_PART
				: RoutingConsts.MANAGE_TEMPLATES_USING_OLD_EDITOR_PART;

		return `${RoutingConsts.ROOT}/${templatesPathPart}/${this.getEditTemplateSuffix(channelId)}`;
	}

	private getEditTemplateSuffix(channelId: number): string {
		if (this.channelService.isOptimobile(channelId)) {
			return RoutingConsts.OPTIMOBILE;
		}
		if (this.channelService.isWebPopup(channelId)) {
			return RoutingConsts.WEB_PAGE_POPUP;
		}
		if (this.channelService.isOptiText(channelId)) return RoutingConsts.OPTI_TEXT_SMS;

		return RoutingConsts.OPTIMAIL;
	}
}
