import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneClickUnsubscribeComponent } from './components/oneClickUnsubscribe.component';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsTransitionalModule } from "../../../components/components_transitional.module";
import { SharedModule } from "../../../shared.module";
import { OptiModalModule } from "../../../modules/OptiModal.module";
import { PipesModule } from "../../../pipes/pipes.module";
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports:[
      BrowserModule,
      ComponentsTransitionalModule,
      CommonModule,
      SharedModule,
      OptiModalModule.forRoot(),
      PipesModule,
      DragDropModule
      ],
  exports:[],
  declarations:[OneClickUnsubscribeComponent],
  providers:[]
})
export class OneClickUnsubscribeModule { }
