<div class="smart-preview-container">
  <div class="buttons-container">
    <arrow-scroll
      style=""
      *ngIf="gridOptions"
      (indexAgGrid)="scrollToColumnIndex($event)"
      [numOfColumns]="gridOptions.columnDefs.length - 1"></arrow-scroll>
  </div>
  <div class="loader-container" *ngIf="isTableLoading">
    <span class="click-loader loader-wrapper">
      <span class="loader preview-loader"></span>
    </span>
  </div>
  <ag-grid-angular
    *ngIf="gridOptions && isTargetGroupHasCustomers && isTemplateHasPersonalizationTags && !isTableLoading"
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    #agGrid
    [gridOptions]="gridOptions"
    [modules]="modules"
    (gridReady)="OnGridReady($event)"
    (cellClicked)="onCidClick($event)"></ag-grid-angular>
  <div class="error-message" *ngIf="!isTargetGroupHasCustomers && !isTableLoading">
    <span>{{ "features.manage_templates.components.optimailPreview.TARGET_GROUP_IS_EMPTY" | translate }}</span>
  </div>

  <div class="error-message" *ngIf="!isTemplateHasPersonalizationTags && !isTableLoading">
    <span>{{ "features.manage_templates.components.optimailPreview.TEMPLATE_WITHOUT_PERSONALIZATIONS" | translate }}</span>
  </div>
</div>
