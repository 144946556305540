<div class="conditional-language-modal">
    <div class="opti-modal-window opti-logic-modal">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h2>
                    Conditional Language
                </h2>
                <button type="button" class="close-modal btn btn-link btn-link-default" (click)="bsModalRef.hide()">
                    <span class="material-icons" aria-hidden="true">close</span>
                </button>
            </div>
            <div class="panel-body" [ngClass]="{'scrollable': hasScroll}">
                <!-- <div class="input-group input-search">
                    <input autocomplete="on" class="form-control" [ngModel]="searchFilter$ | async" id="filter" #filter
                        (keyup)="onSearchChange(filter.value)" placeholder="Quick find personalization tag" />
                    <span class="input-search-label material-icons search-text" *ngIf="!filter.value">search</span>
                    <span class="input-search-label material-icons delete-text" *ngIf="filter.value"
                        (click)="this.onSearchChange('')">close</span>
                </div> -->
                <div class="ag-grid-container">
                    <opti-loading [show]="!this.isGridReady" [size]="'md'"></opti-loading>
                    <ag-grid-angular style="width: 100%; height:100%;" class="ag-theme-clean"
                        [gridOptions]="gridOptions" (selectionChanged)="onSelectionChanged($event)"
                        (modelUpdated)="onModelUpdated($event)" [modules]="modules" *ngIf="this.isGridReady">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>