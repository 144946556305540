<div class="panel-with-accordion-sidebar panel" [ngClass]="{'height-l': leftSidebarHidden}">
    <as-split unit="pixel" [disabled]="!leftSidebarIsOpen || isResizeDisabled" [useTransition]="true">
        <as-split-area *ngIf="!leftSidebarHidden" [size]="leftSidebarIsOpen || (leftSidebarIsOpen && isResizeDisabled) ? 390 : 20" [minSize]="390" [maxSize]="leftSizeMaxWidth" [order]="0">
            <div class="left-side" [ngClass]="{'hide-content': !leftSidebarIsOpen}">
                <ng-content select="[left-side-content]" class="left-side-content"></ng-content>

                <button class="left-side-toggle-button" [ngClass]="{'collapsed': !leftSidebarIsOpen}" (click)="toggleLeftSidebar()">
                    <i class="material-icons" *ngIf="leftSidebarIsOpen">chevron_left</i>
                    <i class="material-icons" *ngIf="!leftSidebarIsOpen">chevron_right</i>
                </button>
                <!--<button class="left-side-toggle-button" [ngClass]="{'collapsed': !leftSidebarIsOpen}" (click)="toggleLeftSidebar()" [disabled]="rightSidebarIsOpen">
                    <i class="material-icons" *ngIf="leftSidebarIsOpen">chevron_left</i>
                    <i class="material-icons" *ngIf="!leftSidebarIsOpen">chevron_right</i>
                </button>-->
            </div>
        </as-split-area>

        <as-split-area [size]="$any('*')" [order]="1">
            <div class="middle-side">
                <div class="middle-side-container">
                    <ng-content select="[middle-side-content]"></ng-content>
                </div>
            </div>
        </as-split-area>

        <as-split-area [size]="390" [lockSize]="true" [visible]="rightSidebarIsOpen" [order]="2">
            <div class="right-side">
                <ng-content select="[right-side-content]"></ng-content>
            </div>
        </as-split-area>
    </as-split>
</div>