import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { PreferenceHistoryService } from "../services/preferenceHistory.service";

@Component({
    selector: 'preference-history-header',
    templateUrl: './preferenceHistoryHeader.component.html',
    styleUrls: ['./preferenceHistoryHeader.component.scss']
})

export class PreferenceHistoryHeaderComponent implements OnInit {
    @Input() clientCustomerId: string;
    public date: Date = new Date();
    public maxDate: Date = new Date();


    constructor(private preferenceHistoryService: PreferenceHistoryService,
                private translateService: TranslateService,
                private bsModalRef: BsModalRef) {}

    ngOnInit() {
        this.setDateNow();
    }

    onDateChanged(event: Date) {
        this.preferenceHistoryService.updateSelectedDate(event);
    }

    close(){
        this.bsModalRef.hide();
    }

    setOneDayForward() {
        const newDate = new Date(this.date);
        newDate.setDate(newDate.getDate() + 1);

        setTimeout(() => {
            this.date = newDate;
            this.preferenceHistoryService.updateSelectedDate(this.date);
        }, 0);
    }

    setOneDayBackward () {
        const newDate = new Date(this.date);
        newDate.setDate(newDate.getDate() - 1);

        setTimeout(() => {
            this.date = newDate;
            this.preferenceHistoryService.updateSelectedDate(this.date);
        }, 0);
    }

    setDateNow() {
        setTimeout(() => {
            this.date = new Date();
            this.preferenceHistoryService.updateSelectedDate(this.date);
        }, 0);
    }
}
