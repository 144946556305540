import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from './optimove-http.model';
import { OptimoveHttpService } from './optimove-http.service';
import { RedirectToAuthService } from '../redirectToAuth.service';

@NgModule({
	imports: [HttpClientModule],
	providers: [
		RedirectToAuthService,
		{
			provide: HttpService,
			useClass: OptimoveHttpService
		}
	]
})
export class HttpServiceModule {
	constructor() {
	}

	static forRoot(): ModuleWithProviders<HttpServiceModule> {
		return {
			ngModule: HttpServiceModule,
			providers: [
				{
					provide: HttpService,
					useClass: OptimoveHttpService
				}
			]
		};
	}
}
