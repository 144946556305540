import { Injectable } from "@angular/core";
import { IActionData, IChannelData, IChannelMetricsElement, ITemplateData, MainMetricTypes, TableMetricTypes, ActionType } from "./campaignAnalysis.models";
import { SpecialChannelsService } from "./specialChannels.service";
import {ConvertOrderActionToLetter} from "../../pipes/convertOrderActionToLetter.pipe";

@Injectable({
    providedIn: 'root'
})
export class ChannelMetricsTransformationService {

    metricsOrderArray = [
        TableMetricTypes.Sent,
        MainMetricTypes.Delivered,
        MainMetricTypes.Opened,
        MainMetricTypes.Clicked,
        TableMetricTypes.Dropped,
        TableMetricTypes.Bounced,
        TableMetricTypes.Unsubscribed,
        TableMetricTypes.SpamReport,
        TableMetricTypes.Dismissed,
        TableMetricTypes.Impressions,
        TableMetricTypes.Reach,
        TableMetricTypes.Deferred
    ]

    metricsOrder = null;


    constructor(private specialChannelsService: SpecialChannelsService,
                private actionTransformer: ConvertOrderActionToLetter) {
        this.metricsOrder = {};
        for (let i = 0; i < this.metricsOrderArray.length; i++) {
            this.metricsOrder[this.metricsOrderArray[i]] = i;
        }
    }

    transform(actions: IActionData[], groupSizes: any, denominators: any ) : IActionData[]{

        for(let action of actions){
            let type = this.actionTransformer.transform(actions.map(a => a.planDetailId).indexOf(action.planDetailId) + 1);
            action.actionType = ActionType[type];
            for(let channel of action.channels){
                this.updateChannelMetricsFromTemplatesMetrics(channel, groupSizes[action.planDetailId], denominators);
            }
        };

        return actions;
    }

    updateChannelMetricsFromTemplatesMetrics(channelData: IChannelData, groupSize: number, denominators: any) {

        const templatesData = channelData.templatesData;

        if (!templatesData)
            return;

        this.filterMetrics(channelData);

        this.handleMetricNamesAndTooltips(templatesData, denominators);

        if (templatesData.length == 1) {
            channelData.metrics = JSON.parse(JSON.stringify(templatesData[0].metrics));
            this.sortMetrics(channelData.metrics);
        }
        else { // multi template case

            let allMetricNames: string[] = this.getAllMetricsNames(templatesData);
            const allMetricsIds: {} = this.getMetricsIds(templatesData);
            const allMetricsValues: {} = this.calculateMetricsValues(allMetricNames, templatesData);
            const allMetricsPercents: {} = this.calculateMetricsPercents(allMetricsValues, channelData.channelId, groupSize, denominators);
            const allMetricsShowLinks: {} = this.calculateMetricsShowLinks(templatesData);

            channelData.metrics = [];

            allMetricNames.forEach(metricName => {

                let metric: IChannelMetricsElement = {
                    id: allMetricsIds[metricName],
                    name: metricName,
                    number: allMetricsValues[metricName],
                    percent: allMetricsPercents[metricName],
                    showLink: allMetricsShowLinks[metricName],
                    denominatorName: denominators[this.metricNameToServerMetricName(metricName)]
                };

                channelData.metrics.push(metric);
            });
        }
    }

    getAllMetricsNames(templatesData: ITemplateData[]): string[] {

        let allMetricNames: string[] = [];

        templatesData.forEach(templateData => {
            const metricsNames = templateData.metrics.map(metric => this.serverMetricNameToMetricName(metric.name));
            allMetricNames = Array.from(new Set([...allMetricNames, ...metricsNames]));
        });

        allMetricNames.sort((a: string, b: string) => {
            return this.metricsOrder[a] - this.metricsOrder[b];
        });

        return allMetricNames;
    }

    getMetricsIds(templatesData: ITemplateData[]): {} {

        let all = {};

        all = templatesData.reduce((prevAll, templateData: ITemplateData) => {
            prevAll = templateData.metrics.reduce((acc: {}, metric: IChannelMetricsElement) => {
                return { ...acc, ...{ [metric.name]: metric.id } }
            }, prevAll);
            return prevAll;
        }, all);

        return all;
    }

    private calculateMetricsValues(allMetricNames: string[], templatesData: ITemplateData[]): {} {

        let allMetricsValues: {} = {};

        allMetricNames.forEach(metricName => {

            const metricTotalValue = templatesData.reduce((total: number, templateData: ITemplateData) => {
                const metric = templateData.metrics.find(metric => metric.name == metricName);
                return total + (metric ? metric.number : 0);
            }, 0);

            allMetricsValues[metricName] = metricTotalValue;
        });

        return allMetricsValues;
    }

    private calculateMetricsPercents(allMetricsValues: any, channelId: number, groupSize: number, denominators: any): {} {

        const allMetricsPercents: {} = {};

        Object.keys(allMetricsValues).forEach(metricName => {

            let denominatorName = denominators[this.metricNameToServerMetricName(metricName).toLowerCase()];
            if ([TableMetricTypes.Reach, TableMetricTypes.Impressions].includes(<TableMetricTypes>metricName)) {
                denominatorName = null;
            }
            if (metricName == MainMetricTypes.Clicked) {
                if (channelId == 8 /* Facebook */)
                    denominatorName = TableMetricTypes.Impressions;
                else if (channelId == 510 /* Web push */)
                    denominatorName = MainMetricTypes.Delivered;
                else if (channelId == 441 /* Web Mobivate */)
                    denominatorName = MainMetricTypes.Delivered;
            }

            let percent;
            if (denominatorName) {
                const numerator = allMetricsValues[metricName];
                const denominator = denominatorName == "Group Size" ? groupSize : allMetricsValues[denominatorName];
                if (!denominator) {
                    if (numerator === 0)
                        percent = 0
                    else
                        percent = -1;
                } else {
                    percent = numerator / denominator;
                }
            } else {
                percent = -1;
            }

            allMetricsPercents[metricName] = percent;
        });

        return allMetricsPercents;
    }

    private calculateMetricsShowLinks(templatesData: ITemplateData[]): {} {

        let result = {};
        if(templatesData.length === 0)
            return result;

        for (let templateData of templatesData) {
            for (let i = 0; i < templateData.metrics.length; i++) {

                const metric: IChannelMetricsElement = templateData.metrics[i];
                const metricName = metric.name;
                result[metricName] = result[metricName] || metric.showLink;
            }
        }

        return result;
    }

    private serverMetricNameToMetricName(metricName: any): string {
        if (metricName === 'Unsubscribes')
            return TableMetricTypes.Unsubscribed;
        return metricName;
    }

    private metricNameToServerMetricName(metricName: any){
        if (metricName === TableMetricTypes.Unsubscribed)
            return 'Unsubscribes';
        return metricName;
    }

    private handleMetricNamesAndTooltips(templatesData: ITemplateData[], denominators: any){
        templatesData.forEach(template => {
            template.metrics.forEach(metric => {
                metric.name = this.serverMetricNameToMetricName((metric.name));
                metric.tooltip = metric.name + ' / ' + denominators[this.metricNameToServerMetricName(metric.name).toLowerCase()];
            })
        });
    }

    private filterMetrics(channelData: IChannelData) {

        let metricsFilter: string[] = null;
        if (this.specialChannelsService.isChannelSpecial(channelData.channelId)) {
            metricsFilter = this.specialChannelsService.getMetricsFilter(channelData.channelId);
        }

        if(metricsFilter != null) {
            channelData.templatesData.forEach(template => {
                let allMetrics = template.metrics.filter(m => metricsFilter.indexOf(this.serverMetricNameToMetricName(m.name)) < 0);
                allMetrics.forEach(metric => {
                    let index = template.metrics.indexOf(metric);
                    if (index > -1) {
                        template.metrics.splice(index, 1);
                    }
                });
            });
        }
    }

    private sortMetrics(metrics: IChannelMetricsElement[]){
        metrics.sort((a: IChannelMetricsElement, b: IChannelMetricsElement) => {
            return this.metricsOrder[a.name] - this.metricsOrder[b.name];
        });
    }


}
