import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FolderTemplatePreview } from '../models/folderTemplate';
import { TemplateCardParams } from '../models/templateCardParams';
import { SsmService } from '../../../services/ssm.service';
import { ICampaignSettings } from '../../scheduledCampaignBuilder/models/campaignSettings.model';
import { OverflowMenuItem } from '../../../components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { ManageTemplatesService } from '../services/manageTemplates.service';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { fadeIn } from './templatePreview.animation';
import { TemplateContextService } from '../services/templateContext.service';
import { filter } from 'rxjs/operators';
import { MoveTemplateInitialState, MoveTemplateModalComponent } from './modals/moveTemplateModal/moveTemplateModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal/modal-options.class';
import { TemplateInUseModalComponent } from './modals/templateInUseModal/templateInUseModal.component';
import { CopyToTemplateModalComponent } from './modals/copyToTemplateModalComponent/copyToTemplateModal.component';
import { SearchContextService } from '../services/searchContext.service';
import { FolderService } from '../services/folder.service';
import { ChannelService } from '../services/channel.service';
import { Subscription } from 'rxjs';
import { ErrorModalService } from '../services/errorModal.service';
import { TemplateDuplicateService } from '../services/templateDuplicate.service';
import { TenantInformationService } from '../services/tenantInformation.service';
import { SubMethodService } from '../services/subMethodService';
import { FeatureFlag, FeatureFlagService } from '../../../services/featureFlag.service';
import { TemplateSelectionService } from '../services/templateSelection.service';
import { ChannelIds } from '../../../models/channelIds.enum';
import { TemplatesDragDropService } from '../services/templatesDragDrop.service';
import { TemplateKeysModel } from '../models/templateKeysModel';

enum PreviewType {
	Default,
	Optimobile
}

@Component({
	selector: 'template-preview',
	templateUrl: 'templatePreviewCard.component.html',
	styleUrls: ['templatePreviewCard.component.scss'],
	animations: [fadeIn]
})
export class TemplatePreviewCardComponent implements OnInit, ICellRendererAngularComp, OnDestroy, AfterContentInit {
	public ssm: any;
	public campaignSettings: ICampaignSettings;
	public templatePreviews: any;
	public overflowMenuItems: OverflowMenuItem[];
	public folderTemplate: FolderTemplatePreview;
	public menuPosition: ConnectionPositionPair[];
	public gridCellParams: TemplateCardParams;
	public isInUse: boolean;
	public numberOfLanguages: number;
	public isTemplateMultiLanguage: boolean;
	public searchText: string;
	public readonly minSearchTextLength: number = 2;
	previewType: PreviewType;
	previewTypeEnum = PreviewType;
	public isChecked: boolean;
	isDragging: boolean;
	public isDuplicating: boolean;
	private subscriptions: Subscription = new Subscription();
	private isSelectAll: boolean = false;

	constructor(
		private modalService: OptiLogicModalService,
		private ssmService: SsmService,
		private translate: TranslateService,
		private manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private searchContextService: SearchContextService,
		private folderService: FolderService,
		private channelService: ChannelService,
		private errorModalService: ErrorModalService,
		private cd: ChangeDetectorRef,
		private templateDuplicateService: TemplateDuplicateService,
		private featureFlagsService: FeatureFlagService,
		private templateSelectionService: TemplateSelectionService,
		private tenantInformationService: TenantInformationService,
		private subMethodService: SubMethodService,
		private templatesDragDropService: TemplatesDragDropService
	) {
		this.ssm = ssmService.getSSM();
		this.campaignSettings = this.ssm.GetGeneric(this.ssm.Resx.CampaignSettings);
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.templateSelectionService.isSelectAllChecked.subscribe((isSelectAll) => {
				if (!isSelectAll){
					this.isChecked = this.templateSelectionService.isSelected(this.folderTemplate);
				} else {
					this.isChecked = isSelectAll;
				}
				this.isSelectAll = isSelectAll;
			})
		);

		this.isChecked = this.templateSelectionService.isSelected(this.folderTemplate);
		this.isTemplateMultiLanguage = this.featureFlagsService.isEnabled(FeatureFlag.TemplatesOptimailMultiLanguageFlow);
		this.subscriptions.add(
			this.templateContextService.isTemplatesInUse
				.pipe(
					filter(
						(tiu) =>
							this.folderTemplate &&
							tiu &&
							Object.values(tiu).filter(
								(template) =>
									template.templateId === this.folderTemplate.templateID &&
									template.subMethodId === this.folderTemplate.subMethodId
							).length > 0
					)
				)
				.subscribe((value) => {
					if (value) {
						this.isInUse = Object.values(value).filter(
							(template) =>
								template.templateId === this.folderTemplate.templateID &&
								template.subMethodId === this.folderTemplate.subMethodId
						)[0].inUse;
					}
				})
		);
		this.subscriptions.add(
			this.templateContextService.numberOfLanguages
				.pipe(
					filter(
						(tiu) =>
							this.folderTemplate &&
							tiu &&
							Object.values(tiu).filter(
								(template) =>
									template.templateId === this.folderTemplate.templateID &&
									template.subMethodId === this.folderTemplate.subMethodId
							).length > 0
					)
				)
				.subscribe((value) => {
					if (value) {
						this.numberOfLanguages = Object.values(value).filter(
							(template) =>
								template.templateId === this.folderTemplate.templateID &&
								template.subMethodId === this.folderTemplate.subMethodId
						)[0].numberOfLanguages;
						this.overflowMenuItems = this.getMenuConfig();
					}
				})
		);

		this.subscriptions.add(
			this.searchContextService.searchText.subscribe((searchText) => {
				this.searchText = searchText;
				this.cd.detectChanges();
			})
		);
		this.subscriptions.add(
			this.templatesDragDropService.dragTemplates.subscribe((templates) => {
				this.isDragging = !!(templates && templates.find((t) => t.templateID === this.folderTemplate?.templateID));
			})
		);

		this.overflowMenuItems = this.getMenuConfig();
		this.menuPosition = this.getMenuPosition();
	}

	ngAfterContentInit(): void {
		this.templatePreviews = './assets/images/noPreview.jpg';

		if (this.folderTemplate.isPreviewExist === true) {
			this.getImagePreview(this.folderTemplate.channelId, this.folderTemplate.templateID, (url) => {
				this.templatePreviews = url ? url : this.templatePreviews;
			});
		} else if (this.templateDuplicateService.isLastDuplicatedId(this.folderTemplate.templateID)) {
			setTimeout(() => {
				this.getImagePreview(this.folderTemplate.channelId, this.folderTemplate.templateID, (url) => {
					this.templatePreviews = url ? url : this.templatePreviews;
				});
			}, 1000);
		}
	}
	updateNumberOfSelected() {
		const template = {
			templateID: this.folderTemplate.templateID,
			subMethodId: this.folderTemplate.subMethodId,
			channelId: this.folderTemplate.channelId,
			folderId: this.folderTemplate.folderId
		} as TemplateKeysModel;

		if (this.isChecked) this.templateSelectionService.addTemplateSelected(template);
		else {
			this.templateSelectionService.removeTemplateSelected(template);
			if(this.isSelectAll) {
				this.templateSelectionService.updateSelectAllState(false);
				this.isChecked = this.templateSelectionService.isSelected(this.folderTemplate);
			}
		}
	}

	public isBatchActionsEnabled() {
		return this.folderTemplate.channelId === ChannelIds.Optimail ||
				this.folderTemplate.channelId === ChannelIds.RealtimeOnSiteMessaging ||
				this.channelService.isOptimobile(+this.folderTemplate.channelId);
	}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getEditTemplateLink() {
		return this.gridCellParams.getEditTemplateLink(this.folderTemplate);
	}

	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	agInit(params: ICellRendererParams): void {
		this.gridCellParams = params as unknown as TemplateCardParams;
		this.folderTemplate = this.gridCellParams.value;
		this.isChecked = this.templateSelectionService.isSelected(this.folderTemplate);
		this.previewType = this.channelService.isOptimobile(this.folderTemplate.channelId) ? PreviewType.Optimobile : PreviewType.Default;
	}

	public getImagePreview(channelId, templateId, callBack) {
		let templatesFolder = this.campaignSettings.templatesFolder;
		const url = `${templatesFolder}/${channelId}/${templateId}.jpeg`;
		let img = new Image();
		img.onload = () => {
			callBack(url);
		};
		img.onerror = () => {
			callBack(null);
		};
		img.src = url;
	}

	public getMenuConfig(): OverflowMenuItem[] {
		const menuItems: OverflowMenuItem[] = [];
		menuItems.push({
			addSeparatorBelow: false,
			callback: this.move.bind(this),
			children: [],
			disabled: false,
			icon: 'send',
			text: this.translate.instant('general.MOVE')
		});
		if (!this.channelService.isOptimobile(this.folderTemplate.channelId) && !this.numberOfLanguages) {
			menuItems.push({
				addSeparatorBelow: false,
				callback: this.copyTo.bind(this),
				children: [],
				disabled: false,
				icon: 'drive_file_move',
				text: this.translate.instant('features.manage_templates.components.copy_template_modal.COPY_TO')
			});
		}
		menuItems.push({
			addSeparatorBelow: false,
			callback: this.delete.bind(this),
			children: [],
			disabled: false,
			icon: 'delete_forever',
			text: this.translate.instant('general.DELETE')
		});

		return menuItems;
	}

	public getMenuPosition(): ConnectionPositionPair[] {
		return [
			new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'top' }),
			new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'bottom' })
		];
	}

	move(): void {
		this.modalService.open(MoveTemplateModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				template: this.folderTemplate,
				callback: this.onTemplateMoved.bind(this),
				isInUse: this.isInUse
			} as MoveTemplateInitialState
		});
	}

	copyTo() {
		this.modalService.open(CopyToTemplateModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				template: this.folderTemplate,
				callback: () => this.gridCellParams.onTemplateCopied()
			}
		});
	}

	delete(): void {
		this.modalService.openModalMessage('sm', {
			message: this.translate.instant('features.manage_templates.messages.DELETE_TEMPLATE'),
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('features.manage_templates.messages.DELETE_TEMPLATE_BUTTON'),
					action: this.deleteTemplate.bind(this)
				},
				{
					class: 'btn-default',
					label: this.translate.instant('general.CANCEL'),
					action: () => {}
				}
			]
		});
	}

	deleteTemplate(): void {
		if (this.isInUse) {
			this.modalService.open(TemplateInUseModalComponent, 'md', {
				ignoreBackdropClick: true,
				class: 'template-in-use-modal',
				initialState: {
					title: this.translate.instant('features.manage_templates.messages.TEMPLATE_CANNOT_DELETE_TITLE'),
					description: this.translate.instant('features.manage_templates.messages.TEMPLATE_CANNOT_DELETE')
				}
			});
		} else {
			this.manageTemplatesService
				.deleteTemplate({
					templateId: this.folderTemplate.templateID,
					channelId: this.folderTemplate.channelId,
					subMethodId: this.folderTemplate.subMethodId
				})
				.subscribe((response) => {
					if (response?.errorCode) {
						this.errorModalService.openErrorModal(
							this.translate.instant('features.manage_templates.optimail.templateFolderActions.DELETE_TEMPLATES'),
							this.translate.instant('features.manage_templates.optimail.templateFolderActions.ACTION_DELETE_TEMPLATE')
						);
						return;
					}
					this.gridCellParams.onTemplateDeleted(this.folderTemplate);
					this.manageTemplatesService
						.deleteMasterIfNeeded({ folderId: this.folderTemplate.folderId })
						.subscribe((folderName) => {});
				});
		}
	}

	getTemplateName(template: FolderTemplatePreview) {
		return this.searchContextService.highlightSearchText(template.templateName);
	}

	getTemplatePath(): { fullPath: string; root: string; childPath?: string } {
		const path = this.folderService.getPath(this.folderTemplate.templateID, this.folderTemplate.subMethodId);
		if (path.length < 2) {
			return { fullPath: path.join('/'), root: path[0] };
		}
		return { fullPath: path.join('/'), root: path[0] + '/', childPath: path.splice(1).join('/ ') };
	}

	edit(): void {
		this.gridCellParams.onEditTemplate(this.folderTemplate);
	}

	onTemplateMoved(selectedSubMethod: number, selectedFolderId: number): void {
		this.gridCellParams.onTemplateMoved(
			this.folderTemplate.templateID,
			this.folderTemplate.subMethodId,
			this.folderTemplate.folderId,
			selectedSubMethod,
			selectedFolderId
		);
	}

	isSubjectLineVisible(): boolean {
		return (
			this.channelService.isWebPopup(this.folderTemplate.channelId) ||
			this.channelService.isOptimobileInapp(this.folderTemplate.channelId)
		);
	}
	isNumberOfLanguagesVisible(): boolean {
		return (
			(this.channelService.isOptimobile(this.folderTemplate.channelId) ||
				this.channelService.isOptimail(this.folderTemplate.channelId)) &&
			this.isTemplateMultiLanguage
		);
	}

	isOptimobile(): boolean {
		return this.channelService.isOptimobile(this.folderTemplate.channelId);
	}

	duplicate(): void {
		const tenantInfo = this.tenantInformationService.getTenantInformation();
		let tenantId = tenantInfo.tenantId;
		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			let executionMethodInnerId = this.subMethodService.findSubmethodInnerId(metadata, +this.folderTemplate.subMethodId);
			this.templateDuplicateService.duplicate(
				this.folderTemplate.templateID,
				this.folderTemplate.channelId,
				this.folderTemplate.subMethodId,
				tenantId,
				executionMethodInnerId,
				this.folderTemplate.folderId
			);
		});
		this.isDuplicating = true;
	}
}
