<div class="date-range-picker-wrapper" >
    <div class="default-mode-container" *ngIf="!isEndDateOnlyMode">
        <button tabindex="-1" class="btn-link" (click)="drp.show()"
                [disabled]="dateRangePickerData?.isDisabled ? true : null"
                [ngClass]="{ 'room-for-buttons' : shouldShowCustomTimeRange }"
                class="{{className}}">
            <i class="material-icons">{{icon}}</i>
        </button>

        <input class="form-control theme-blue picker grouped-item"
               [attr.readonly]="readonly"
               [bsConfig]="bsConfig"
               (click)="opened()"
               [ngClass]="{ 'disabled-picker' : dateRangePickerData?.isDisabled, 'room-for-buttons' : shouldShowCustomTimeRange}"
               class="{{className}}"
               bsDaterangepicker
               [placeholder]="dateRangePickerData.placeholder"
               (input)="onInputChange($event)"
               value="{{formattedDate}}"
               [formControl]="inputControl"
               [placement]="$any(placement)"
               [container]="dateRangePickerData?.container"
               [minDate]="dateRangePickerData?.minDate"
               [maxDate]="dateRangePickerData?.maxDate"
               #drp="bsDaterangepicker"
               #dateRangePicker>

        <button *ngIf="shouldShowCustomTimeRange"
                type="button"
                class="btn btn-secondary grouped-item"
                [title]="quickSelectPrevButtonTitle"
                (click)="back()" [disabled]="isQuickSelectEnabled ? null: true">
            <i class="material-icons tags-plus">arrow_back</i>
        </button>

        <button *ngIf="shouldShowCustomTimeRange"
                type="button"
                class="btn btn-secondary grouped-item"
                [title]="quickSelectNextButtonTitle"
                (click)="forward()" [disabled]="isQuickSelectEnabled ? null: true">
            <i class="material-icons tags-plus">arrow_forward</i>
        </button>
    </div>
    <div *ngIf="isEndDateOnlyMode" class="end-date-only-container">
        <date-picker [datePickerData]="{}"
                     [disabled]="true"
                     [ngModel]="dates[0]">
                     
        </date-picker>
        <div class="date-seperator">—</div>
        <date-picker
                [datePickerData]="{
                    minDate: dateRangePickerData?.minDate,
                    maxDate: dateRangePickerData?.maxDate,
                    placeholder: 'general.SELECT_DATE' | translate,
                    placement: dateRangePickerData?.placement,
                    container: dateRangePickerData?.container
                }"
                [ngModel]="dates[1]"
                (ngModelChange)="onValueChange([dates[0],$event])">
        </date-picker>
    </div>

    <div *ngIf="!!manualValidationState && hideValidation != true" class="error-message text-danger small">
        <ng-container [ngSwitch]="firstErrorKey">
            <label *ngSwitchCase="invalidDateStateModes.EmptyField" class="empty-field">
                {{'general.DATE_RANGE_PICKER.dateNotEmpty' | translate}}
            </label>
            <label *ngSwitchCase="invalidDateStateModes.Invalid" class="invalid-date">
                {{'general.DATE_RANGE_PICKER.invalidDate' | translate}}
            </label>
            <label *ngSwitchCase="invalidDateStateModes.EndDateMustBeBigger" class="end-must-be-larger">
                {{'general.DATE_RANGE_PICKER.endDateMustBeBigger' | translate}}
            </label>
            <label *ngSwitchCase="invalidDateStateModes.DateMustBeBeforeMaxDate">
                {{'general.DATE_RANGE_PICKER.outOfMaxDate' | translate : { x : dateRangePickerData.maxDate | date : 'dd MMM yyyy' } }}
            </label>
            <label *ngSwitchCase="invalidDateStateModes.DateMustBeLaterThanMinDate">
                {{'general.DATE_RANGE_PICKER.outOfMinDate' | translate : { x : dateRangePickerData.minDate | date : 'dd MMM yyyy' } }}
            </label>
        </ng-container>
    </div>
</div>
