import { AbstractControl, ValidationErrors, NG_VALIDATORS, ValidatorFn } from "@angular/forms";
import { Directive, Input } from "@angular/core";


/**
 * Validator for checking min or max occurences in control value for specified tags.
 * @param tagsNumber Number of tags, that will be allowed to contain in control
 * @param tag Tag name, which will be checked in control value
 * @param type Contains type of check. Possible values: 'min' | 'max'
 * @param controlName Optional. Used for child AbstractControl
 * @returns validation result
 */
export function minMaxTagsValidator(tagsNumber: number, tag: string, type: MinMaxType, controlName: string) : ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
      const workControl = controlName ? control.get(controlName) : control;

      if (!workControl.value) {
          return null;
      }

      var tags = workControl.value.filter((x) => {
        return x.tag === tag;
      });

      return (tags.length >= tagsNumber && type === 'min') || 
             (tags.length <= tagsNumber && type === 'max') 
                ? null : { minMaxTags: true };
  };
}

@Directive({
  selector: '[minMaxTags]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MinMaxTagsValidatorDirective,
    multi: true
  }]
})
export class MinMaxTagsValidatorDirective {

  @Input() tagsNumber: number;
  @Input() tag: string;
  @Input() controlName: string;
  @Input() type: MinMaxType;
  
  validate(c: AbstractControl): { [key: string]: any; } {
      return minMaxTagsValidator(this.tagsNumber, this.tag, this.type, this.controlName);
  }
}

type MinMaxType = 'min' | 'max';
