import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { PreferenceCenterService } from '../../services/preferenceCenter.service';
import { BrandGroup } from '../../models/brandGroup.model';
import { switchMap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Topic } from '../../models/topic.model';
import { ToastModel } from '../../../../missionControl/models/toast.model';
import { locationParameter } from '../../../../../models/locationParameter.enum';
import { IconType } from '@optimove/ui-sdk/common/models';
import { TranslateService } from '@ngx-translate/core';
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { ModalOptions } from "ngx-bootstrap/modal";
import { SdkIdDialogComponent } from "../sdkIdDialog/sdkIdDialog.component";

@Component({
  selector:'topics-list',
  templateUrl:'./topicsList.component.html',
  styleUrls:['./topicsList.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopicsListComponent implements OnInit {
  private readonly defaultVersion = '1';

  public isLoading: boolean = true;
  public preparedBrands: BrandGroup[] = [];
  public toastParams: ToastModel;
  public subscribtions: Subscription;
  constructor(private service: PreferenceCenterService,
              private router: Router,
              private translateService: TranslateService,
              private modalService: OptiLogicModalService) { }

  ngOnInit(): void {
    this.loadBrands();
    this.initToastParams();
  }
  private initToastParams(): void {
    this.toastParams = {
      isOpen: false,
      message: '',
      icon: '',
      showCloseIcon: false,
      location: locationParameter.Default,
      toastIconType: IconType.AngularMaterial
    };
    this.subscribtions = this.service.topicCreated.subscribe((_) => {
      this.openToastMessage(this.translateService.instant('features.user_settings.body.preference_center.TOPIC_CREATED'));
    });
    this.subscribtions.add(this.service.topicUpdated.subscribe((_) => {
      this.openToastMessage(this.translateService.instant('features.user_settings.body.preference_center.TOPIC_UPDATED'));
    }));
    this.subscribtions.add(this.service.topicDeleted.subscribe((_) => {
      this.openToastMessage(this.translateService.instant('features.user_settings.body.preference_center.TOPIC_DELETED'));
    }));
  }

  openToastMessage(message: string) {
      this.toastParams = {
        isOpen: true,
        message: message,
        icon: 'info',
        showCloseIcon: true,
        location: locationParameter.Default,
        toastIconType: IconType.AngularMaterial,
        closeTimeout: 3000
      };
      //to trigger toast, we need to make click
      document.getElementById('topicsListHeader').click();
  }

  public loadBrands(){
    this.service.getBrandGroups().pipe(
      switchMap(response => {
        this.preparedBrands = response;
        if (this.preparedBrands[0])
          this.preparedBrands[0].isOpen = true;
        else
          this.isLoading = false;

        const topicObservables = this.preparedBrands.map(brand =>
          this.service.getTopics(brand.id)
        );

        return forkJoin(topicObservables);
      })
    ).subscribe(
      topicsArray => {
        topicsArray.forEach((topics, index) => {
          this.preparedBrands[index].topics = topics;
        });
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }
  updateIsOpen(brandGroup){
    brandGroup.isOpen = !brandGroup.isOpen;
  }

  createNewTopic(brandGroup: BrandGroup = null) {
    this.subscribtions.unsubscribe();
    if (brandGroup) {
      this.router.navigate(['/user-settings/preferenceCenter/addTopic'], { state: brandGroup })
      return;
    }
  }
  
  editTopic(topic: Topic) {
    this.router.navigate(['/user-settings/preferenceCenter/addTopic'], { state: topic })
    this.subscribtions.unsubscribe();
  }

  showSdkId(brandGroup: BrandGroup = null): void {
    const brandGroupId = brandGroup.id;
    const tenantDetails = JSON.parse(sessionStorage.getItem("tenantDetails"));

    this.service.getVersion().subscribe(versionResponse => {
      let version = this.defaultVersion;
      if (versionResponse && versionResponse.toString().includes('.')) {
        version = versionResponse.toString().split('.')[0];
      }

      const sdkConfigObject = [
        version,
        this.getEnv(),
        tenantDetails.tenantId,
        brandGroupId
      ];
      const sdkConfig = btoa(JSON.stringify(sdkConfigObject));
      this.modalService.open(SdkIdDialogComponent, 'md', <ModalOptions<{ sdkConfig: string, brandGroupId: string }>> { initialState: { sdkConfig, brandGroupId } });
    });
  }

  private getEnv(): string {
    const preferenceCenterUrl = this.service.getPreferenceCenterUrl();
    if (!preferenceCenterUrl) {
      return 'dev';
    }
    if (preferenceCenterUrl.includes('-eu')) {
      return 'eu';
    } else if (preferenceCenterUrl.includes('-us')) {
      return 'us';
    } else {
      return 'dev';
    }
  }
}
