<opti-logic-modal [modalConf]="exportModalConfig">
    <div class="export-unsubscribers-modal">
        <form [formGroup]="exportForm">
            <div class="row form-group">
                <div class="col-md-12 label-value-container">
                        <label class="warning"> {{ 'features.user_settings.body.subscribers.modal.WARNING' | translate :{email:this.email} }} </label>
                    <div>
                        <label class="warning-limit"> {{ 'features.user_settings.body.subscribers.modal.LIMIT_WARNING' | translate }} </label>
                    </div>
                    <div class="radio radio-primary radio-exclusion exclude-radio-container allTimeRadioButtonSelect">
                        <input type="radio" formControlName="allTime" name="allTime" id="radAlltime" [value]="true">
                        <label for="radAlltime">{{ 'features.user_settings.body.subscribers.modal.ALL_TIME' | translate}}</label>
                    </div>
                    <div class="radio radio-primary radio-exclusion allTimeRadioButtonSelect">
                        <input type="radio" formControlName="allTime" name="allTime" id="radSelectedDates" [value]="false">
                        <label for="radSelectedDates">{{ 'features.user_settings.body.subscribers.modal.SELECTED_DATES' | translate}}</label>
                    </div>
                    <div [ngClass]="dataRangeClass">
                        <date-range-picker-inline formControlName="dateRange"  [dateRangePickerData]="dateRangePickerSettings" > </date-range-picker-inline>
                    </div>

                </div>
            </div>
        </form>
    </div>
</opti-logic-modal>
