import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditPlainTextModalCloseResult } from './editPlainTextModal.models';
import { TemplateContextService } from '../../../services/templateContext.service';
import { TemplateQueryParams } from '../../../models/templateQueryParams';
import { TemplateDetails } from '../../../models/templateDetails';
import { first } from 'rxjs/operators';

@Component({
	selector: 'edit-plain-text-modal',
	templateUrl: './editPlainTextModal.component.html',
	styleUrls: ['./editPlainTextModal.component.scss']
})
export class EditPlainTextModalComponent implements OnInit {
	@ViewChild('plainTextArea') textArea: ElementRef;

	@Input() public subMethodType: number;
	@Input() public templateText: string;
	@Input() public generatedText: string;

	@Output() public closeAction: EventEmitter<EditPlainTextModalCloseResult> = new EventEmitter<EditPlainTextModalCloseResult>();

	public editorText: string = '';
	public modalConf: IModalConf;
	public isLoadTags: boolean = false;
	template: TemplateDetails;
	templateQueryParams: TemplateQueryParams;

	constructor(
		private translateService: TranslateService,
		public bsModalRef: BsModalRef,
		private templateContext: TemplateContextService
	) {}

	ngOnInit(): void {
		this.initModalConfig();
		this.templateContext.queryTemplateParams.pipe(first()).subscribe((params: TemplateQueryParams) => {
			this.templateQueryParams = { ...params };
		});
		this.templateContext.current.pipe(first()).subscribe((t) => {
			if (!t) {
				this.templateContext.resetCurrentTemplate(this.templateQueryParams.visualeditor);
				return;
			}
			if (!this.template) {
				this.template = t;
			}
			if (this.template.autoGeneratePlainText || this.template.autoGeneratePlainText === null) this.editorText = this.generatedText;
			else this.editorText = this.template.text;
		});
	}

	generateFromHtml(): void {
		this.editorText = this.generatedText;
	}

	public onTagChosen($event): void {
		this.isLoadTags = false;

		if (!$event) return;

		let cursor = (this.textArea.nativeElement as HTMLTextAreaElement).selectionStart;
		this.editorText = this.editorText.slice(0, cursor) + $event + this.editorText.slice(cursor);
	}

	private save(): void {
		this.emitAndClose(this.editorText, this.editorText === this.generatedText);
	}

	private hide(): void {
		this.emitAndClose('', null);
	}

	emitAndClose(text: string, isGenerated: boolean): void {
		this.closeAction.emit({
			text: text,
			isGenerated: isGenerated
		});
		this.bsModalRef.hide();
	}

	private initModalConfig(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.components.plainText.TITLE'),
			hideHeaderPanel: false,
			hideFooterPanel: false,
			hideFooterBorder: true,
			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('general.SAVE'),
					isDisabled: () => this.templateText === this.editorText || this.editorText === '',
					action: this.save.bind(this)
				},
				{
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: this.hide.bind(this)
				}
			]
		};
	}
}
