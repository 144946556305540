<div class="run-campaign-modal">
    <div class="modal-header">
        <button type="button" class="close " data-dismiss="alert" (click)="close()">
            <i class="material-icons">{{ "general.CLOSE" | translate }}</i>
        </button>
        <h2 class="modal-title">{{"features.optibot.insight_card.modal_message.RUN_CAMPAIGN_TITLE" | translate}}</h2>
    </div>
    <iframe-container [url]="modalConf.iframeSrc" class="run-campaign-modal-frame"></iframe-container>
</div>

