import {Injectable} from "@angular/core";
import {OslItemComponent} from "../oslItemComponent/oslItem.component";

const robotoFontSizes = {
  "0": 6.75,
  "1": 6.75,
  "2": 6.75,
  "3": 6.75,
  "4": 6.75,
  "5": 6.75,
  "6": 6.75,
  "7": 6.75,
  "8": 6.75,
  "9": 6.75,
  "a": 6.531300000000002,
  "b": 6.734400000000001,
  "c": 6.281300000000002,
  "d": 6.781300000000002,
  "e": 6.359400000000001,
  "f": 4.171900000000001,
  "g": 6.734400000000001,
  "h": 6.609400000000001,
  "i": 2.921900000000001,
  "j": 2.875,
  "k": 6.093800000000002,
  "l": 2.921900000000001,
  "m": 10.531300000000002,
  "n": 6.625,
  "o": 6.843800000000002,
  "p": 6.734400000000001,
  "q": 6.828099999999999,
  "r": 4.0625,
  "s": 6.1875,
  "t": 3.921900000000001,
  "u": 6.625,
  "v": 5.8125,
  "w": 9.031300000000002,
  "x": 5.953099999999999,
  "y": 5.6875,
  "z": 5.953099999999999,
  "A": 7.828099999999999,
  "B": 7.484400000000001,
  "C": 7.8125,
  "D": 7.875,
  "E": 6.828099999999999,
  "F": 6.640599999999999,
  "G": 8.1875,
  "H": 8.5625,
  "I": 3.265599999999999,
  "J": 6.625,
  "K": 7.531300000000002,
  "L": 6.468800000000002,
  "M": 10.4844,
  "N": 8.5625,
  "O": 8.25,
  "P": 7.578099999999999,
  "Q": 8.25,
  "R": 7.390599999999999,
  "S": 7.125,
  "T": 7.171900000000001,
  "U": 7.781300000000002,
  "V": 7.640599999999999,
  "W": 10.656300000000002,
  "X": 7.531300000000002,
  "Y": 7.218800000000002,
  "Z": 7.1875,
  "`": 3.7188000000000017,
  "~": 8.1719,
  "!": 3.0938000000000017,
  "@": 10.781300000000002,
  "#": 7.390599999999999,
  "$": 6.75,
  "%": 8.7969,
  "^": 5.015599999999999,
  "&": 7.468800000000002,
  "*": 5.171900000000001,
  "(": 4.109400000000001,
  ")": 4.171900000000001,
  "-": 3.3125,
  "_": 5.421900000000001,
  "=": 6.593800000000002,
  "+": 6.8125,
  "[": 3.1875,
  "]": 3.1875,
  "\\": 4.921900000000001,
  "{": 4.0625,
  "}": 4.0625,
  "|": 2.9375,
  ";": 2.546900000000001,
  "'": 2.0938000000000017,
  ":": 2.9063000000000017,
  "\"": 3.8438000000000017,
  ",": 2.359400000000001,
  ".": 3.171900000000001,
  "/": 4.953099999999999,
  "<": 6.109400000000001,
  ">": 6.281300000000002,
  "?": 5.671900000000001,
  " ": 2.97
};

@Injectable()
export class RobotoFontSizesService {
  getWidth(char: string): number {
    return robotoFontSizes[char];
  }

  // helper method to extract the dictionary above
  exportFontSizeForElement(calculationElement: OslItemComponent){
    const digits = '0123456789'.split('');
    const lowercase = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const specialChars = '`~!@#$%^&*()-_=+[]\\{}|;\':",./<>? '.split('');
    // @ts-ignore
    const allChars = [digits, lowercase, uppercase, specialChars].flat();
    const textElement = calculationElement.textElement;
    const emptyWidth = this.calcCharWidth(textElement, '');
    let charWidthMap = {};
    for (let i = 0; i < allChars.length; i++) {
      const char = allChars[i];
      const charWidth = this.calcCharWidth(textElement, char) - emptyWidth;
      charWidthMap[char] = charWidth;
    }
  }

  private calcCharWidth(textElement, char: string): number {
    return this.calcDimension(textElement, char, 'width');
  }

  private calcDimension(textElement,char: string, propertyName: string): number{
    const prevText = textElement.nativeElement.innerText;
    textElement.nativeElement.innerText = char;
    const properties = window.getComputedStyle(textElement.nativeElement);
    const retVal = parseFloat(properties[propertyName]);
    textElement.nativeElement.innerText = prevText;
    return retVal;
  }
}
