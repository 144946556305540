import { NotesAndTags } from '../../../components/notesAndTagsButton/model/notesAndTags.model';
import { Recurrence } from '../../../components/recurrenceForm/models/recurrenceServer.model';
import { TargetGroupVisualStreamBuilder } from '../streamBuilder/modules/visualStreamBuilder/models/server/targetGroupVisualStreamBuilder.model';

export interface StreamTestInfo {
	id: number;
	name: string;
	folderId: number;
	startDate: string;
	endDate: string;
	treatments: Treatment[];
	status: StreamTestStatus;
	isMigrationBased: boolean;
	associatedTargetGroups: number[];
	currentWinnerStreamId: number | null;
	isWinner: boolean;
	testType: TestType;
	lastModified: string;
	modifierName: string;
	dropStreamData: DropStreamData | null;
	droppedTreatments?: DropStreamData[];
	hasScheduledCampaignsInRange: boolean;
	hasTriggeredCampaignsInRange: boolean;
	testMaxDuration: number;
	recurrencePattern: string;
	exitTgProperties?: TargetGroupVisualStreamBuilder | null;
	notesAndTags?: NotesAndTags;
	recurrence?: Recurrence;
	isSelfOptimizing: boolean;
}

export interface StreamBatchInfo {
	streamTests: StreamTestInfo[];
	tabsTotalAmount: ManageStreamTabsScrollAmount;
	selectedTabAmount: number;
}

export enum ManageStreamSortByColumnType {
	status = 1,
	testType = 2,
	lastModified = 3,
	name = 4,
	dateRange = 5,
	isWinner = 6
}

export interface ManageStreamTabsScrollAmount {
	draft?: number;
	scheduled?: number;
	running?: number;
	completed?: number;
	all?: number;
}

export interface ManageStreamTab {
	id: number;
	headerName: string;
	amount: number;
}

export interface Treatment {
	id: number;
	name: string;
	isolationVolume: number;
	targetGroups: StreamTargetGroup[];
	conditions: StreamCondition[];
	isControl: boolean;
}

export enum StreamTestStatus {
	Draft = 1,
	Scheduled,
	Running,
	Completed
}

export enum ManageStreamTabType {
	All = 0,
	Draft = 1,
	Scheduled = 2,
	Running = 3,
	Completed = 4
}

export enum LifecycleType {
	AllCustomers = 0,
	NonDepositor = 1,
	New = 2,
	Active = 3,
	Churn = 4,
	Reactivated = 5,
	Dormant = 6
}

export enum TestType {
	MANUAL,
	VISUAL
}

export enum TreatmentType {
	A,
	B,
	C,
	D,
	Control
}

export interface MigrationBasedStreamTest extends StreamTestInfo {
	fromLifecycleStage: number;
	toLifecycleStage: number;
	preTestMigrationRate: number;
	objectiveMigrationRate: number;
	isNegativeTrend: boolean;
	createdFromCard: number;
}

export interface StreamTest_NonMigration extends StreamTestInfo {
	selectedGainKPI: number;
	kpiAlias: string;
	targetGroupId: number;
	targetGroupName: string;
}

export interface StreamTargetGroup {
	groupCategory: number;
	parentFolderId: number;
	targetGroupName: string;
	targetGroupSerial: number;
}

export interface DropStreamData {
	droppedStreamId: number;
	dropTime: string;
	timeOfAction: string;
	modifierName: string;
}

export interface StreamCondition {
	position: number;
	type: number;
	paramsJson: string;
}
