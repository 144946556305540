<opti-logic-modal [modalConf]="modalConf">

    <button (click)="onCancel()" type="button" class="close-modal btn btn-link btn-link-default about-x-button">
        <span class="material-icons" aria-hidden="true">close</span>
    </button>

    <div class="row about-row-logo about-vertical-center about-row-pad">
        <div class="col-sm-8">
            <object class="optimove-logo-object" data="assets/images/optimoveLogo.svg" type="image/svg+xml"
                alt=""></object>
        </div>
        <div class="col-sm-4  about-right-padding">
            <span class="pull-right">
                <h3>{{'components.aboutOptimove.VERSION'|translate:{optimoveVersion:optimoveVersion} }}</h3>
            </span>
        </div>
    </div>

    <div class="row about-row-support about-row-pad">
        <div class="col-sm-12 about-col-support">
            <p>Support :</p>
            <a href="mailto:{{'components.aboutOptimove.SUPPORT'|translate}}" class="btn-link">{{'components.aboutOptimove.SUPPORT'|translate}}</a>
        </div>
    </div>

    <div class="row about-row-paragraph about-row-pad">
        <div class="col-sm-12">
            <p>
                {{'components.aboutOptimove.ABOUT'|translate}}
            </p>
        </div>
    </div>

</opti-logic-modal>