<div class="api-key-management-container">
    <div class="panel-heading" data-qa-id="ApiKeyManagementTitle">
        <h2> {{translateKeys.title | translate }} </h2>
    </div>
    <opti-loading [show]="loading" [size]="'md'"></opti-loading>
    <div class="panel-body user-list">
        <form class="form-horizontal" autocomplete="off">
            <div class="form-group">
                <label class="col-md-2 control-label" for="APIEndPoint" data-qa-id="APIEndPoint">
                    {{translateKeys.apiEndPoint| translate }}
                </label>
                <div class="col-md-5" data-qa-id="ApiUrl">
                    <input type="text" id="APIEndPoint" class="form-control" [disabled]="true"
                        value={{apiEndPointVal}}>
                </div>
                <a (click)="goToAPIDocumentation()" class="btn btn-link api-document" data-qa-id="ApiDocumentation">
                    <span>
                        <i class="material-icons">launch</i>
                        <b>{{translateKeys.apiDocumentation| translate }}</b>
                    </span>
                </a>
            </div>
            <table class="table" *ngIf="apiKeysData.length>0" data-qa-id="ExistingApiKeyUsersTable">
                <thead>
                    <tr>
                        <th (click)="sortUsers('Nickname')" class="col-md-3">
                            {{translateKeys.colNickName | translate }}
                            <i *ngIf="sortBy('Nickname', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('Nickname', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('ContactEmail')" class="col-md-3">
                            {{translateKeys.colContactEmail| translate }}
                            <i *ngIf="sortBy('ContactEmail', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('ContactEmail', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('RoleType')" class="col-md-2">
                            {{translateKeys.colRole | translate }}
                            <i *ngIf="sortBy('RoleType', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('RoleType', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('LastUseDateTime')" class="col-md-2">
                            {{translateKeys.colLastUsed | translate }}
                            <i *ngIf="sortBy('LastUseDateTime', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('LastUseDateTime', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('CreatedDateTime')" class="col-md-2">
                            {{translateKeys.colCreateDate | translate }}
                            <i *ngIf="sortBy('CreatedDateTime', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('CreatedDateTime', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th (click)="sortUsers('IsActive')" class="col-md-1">
                            {{translateKeys.colActive | translate }}
                            <i *ngIf="sortBy('IsActive', 'asc')" class="material-icons btn-link">arrow_drop_down</i>
                            <i *ngIf="sortBy('IsActive', 'desc')" class="material-icons btn-link">arrow_drop_up</i>
                        </th>
                        <th class="col-md-1">
                            <a [routerLink]="['/user-settings/createApiKey']" class="btn btn-font-size btn-link pull-right tab-item" 
                            data-qa-id="addNewApiKey" [routerLinkActive]="['active']">
                            <span><b>+ {{ translateKeys.addNewApiKey| translate }}</b></span>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of apiKeysData; index as i" data-qa-id="ApiKeyUserData">
                        <td class="col-md-3" ellipsis-tooltip="1">{{ user.Nickname }}</td>
                        <td class="col-md-3" ellipsis-tooltip="1">{{ user.ContactEmail }}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">{{ findRole(user.RoleType) }}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">{{ (user.LastUseDateTime !== null) ? (convertToDatestring(user.LastUseDateTime)) : "-" }}</td>
                        <td class="col-md-2" ellipsis-tooltip="1">{{ (user.CreatedDateTime !== null) ? (convertToDatestring(user.CreatedDateTime)) : "-" }}</td>
                        <td class="col-md-1" ellipsis-tooltip="1">
                            <switch *ngIf="userProcessingIndex !== i" data-qa-id="toggleActive" [buttonType]="switchButtonType" (ngModelChange)="updateIsActive([$event, user])"
                                [(ngModel)]="user.IsActive" [ngModelOptions]="{standalone: true}"></switch>
                            <span *ngIf="userProcessingIndex === i" class="click-loader loader-wrapper" data-qa-id="processingloader">
                                <span class="loader loader-processing"></span>
                                <span class="text-processing">{{ translateKeys.processing | translate}}</span>
                            </span>
                        </td>
                        <td class="col-md-1" ellipsis-tooltip="1">
                            <div class="pull-right">
                                <sup>                               
                                    <a class="btn btn-link btn-link-default" data-qa-id="deleteIcon" (click)="openUpdateUserModal(user)">
                                        <i tooltip-append-to-body="true" uib-tooltip="Delete api key account"
                                            class="material-icons">delete</i>
                                    </a>
                                </sup>
                            </div>
                        </td>
                    </tr> 
                </tbody>
            </table>
            <div class="empty-state" *ngIf="apiKeysData.length===0" data-qa-id="emptyStateApiKeyManagement">
                <div class="empty-state-content empty-state-api-key-management">
                    <div class="empty-state-text text-center">
                        <h1 class="text-muted empty-state-api-key-management-text">
                            {{translateKeys.noUserConfigured| translate }}
                        </h1>
                       <sup>
                            <a [routerLink]="['/user-settings/createApiKey']" class="btn btn-font-size btn-link" 
                            data-qa-id="addNewApiKey" [routerLinkActive]="['active']">
                                <i class="material-icons">add</i>
                                <span>{{ translateKeys.addNewApiKey| translate }}</span>
                            </a>
                       </sup>
                    </div>
                </div>
            </div> 
        </form>
    </div>
</div>
<toast [(isOpen)]="toastParams.isOpen"
    [icon]="toastParams.icon"
    [location]="toastParams.location"
    [message]="toastParams.message"
    [showCloseIcon]="toastParams.showCloseIcon"
    [toastIconType]="toastParams.toastIconType">
</toast>