import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OptimoveEnvironment } from '../../../environments/environment.model';

/**
 * @enum HttpMethod
 * @description Represents the HTTP methods supported by the Optimove API.
 */
export enum HttpMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
	HEAD = 'HEAD'
}

/**
 * @interface HttpOptions
 * @description Represents the HTTP options supported by the Optimove API.
 * @param body - The body of the request.
 * @param headers - The headers of the request.
 * @param context - The context of the request.
 * @param observe - The observe of the request.
 * @param params - The params of the request.
 * @param reportProgress - The reportProgress of the request.
 * @param responseType - The responseType of the request.
 * @param withCredentials - The withCredentials of the request.
 * @example
 * ```javascript
 * const options: HttpOptions = {
 * 	body: { name: 'John Doe' },
 * 	headers: { 'Content-Type': 'application/json' },
 * 	context: null,
 * 	observe: 'body',
 * 	params: { id: 1 },
 * 	reportProgress: false,
 * 	responseType: 'json',
 * 	withCredentials: false
 * };
 * ```
 */
export interface HttpOptions {
	body?: any | null;
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	context?: HttpContext;
	observe?: 'body' | 'events' | 'response';
	params?:
		| HttpParams
		| {
				[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		  };
	reportProgress?: boolean;
	responseType?: 'arraybuffer' | 'json' | 'blob' | 'text';
	withCredentials?: boolean;
}

/**
 * @interface ControllerActionScheme
 * @description Represents the controller and action scheme for the Optimove API.
 * @param controller - The name of the controller, without 'Controller'. example: 'Main'
 * @param action - The name of the method within the controller. example 'GetFeatureFlag'
 * @example
 * ```javascript
 * const controllerActionScheme: ControllerActionScheme = {
 * 	controller: 'Main',
 * 	action: 'GetFeatureFlag'
 * };
 * ```
 */
export interface ControllerActionScheme {
	controller: string;
	action: string;
}

/**
 * @class HttpService
 * @implements IHttpService
 * @description Represents the HTTP service for the Optimove API.
 * @description This class is used to send HTTP requests to the Optimove API.
 * @description This class is used by the Optimove Angular SDK, and should not be used directly.
 * @param http - The Angular HttpClient service.
 */

export abstract class HttpService {
	/**
	 * @constructor
	 * @param http - The Angular HttpClient service.
	 * @description Constructs the HttpService class.
	 * @description This class is used to send HTTP requests to the Optimove API.
	 * @description This class is used by the Optimove Angular SDK, and should not be used directly.
	 */
	constructor(protected readonly http: HttpClient) {}

	/**
	 * @property environment
	 * @static environment
	 * @type {OptimoveEnvironment}
	 * @description Represents the environment configuration for Optimove.
	 * @description This property is set by the HttpServiceModule, and should not be set manually.
	 */
	public static get environment(): OptimoveEnvironment{
		return environment;
	}

	/**
	 * @method toHttpParams
	 * @static toHttpParams
	 * @returns The params object for the HttpParams constructor.
	 */
	static toHttpParams(keyValueData: { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> }): {
		params: HttpParams;
	} {
		const params: HttpParams = Object.entries(keyValueData)
			.filter(([key, value]) => value !== undefined && value !== null)
			.reduce((httpParams, [key, value]) => httpParams.set(key, value.toString()), new HttpParams());

		return { params };
	}

	/**
	 * @method getOptions
	 * @static getOptions
	 * @param url a string representing the URL to send the request to.
	 * @returns The relevant url for the request.
	 */
	static getRequestScheme(url: string | ControllerActionScheme) {
		let apiURL: string;
		if (typeof url === 'object') {
			apiURL = `${HttpService.baseApiUrlString}/${url.controller}/${url.action}`;
		} else {
			apiURL = `${HttpService.baseApiUrlString}/${url}`;
		}
		apiURL = HttpService.removeDoubleSlashes(apiURL);

		return apiURL;
	}

	/**
	 * @method getOptions
	 * @static getOptions
	 * @param externalOptions a HttpOptions object to merge with the default options.
	 * @returns The options object for the HttpClient request.
	 */
	static getOptions(externalOptions?: HttpOptions, body?: any) {
		const requestHeaders: { [key: string]: string | string[] } = this.getCustomHeaders();

		if (externalOptions !== undefined && externalOptions !== null && externalOptions.headers) {
			const externalHeaders =
				externalOptions.headers instanceof HttpHeaders ? externalOptions.headers.keys() : Object.keys(externalOptions.headers);
			externalHeaders.forEach((headerKey: string) => {
				if (headerKey !== 'Authorization') {
					requestHeaders[headerKey] = externalOptions.headers[headerKey];
				}
			});
		}

		const options = { headers: requestHeaders };

		for (const property in externalOptions) {
			if (property !== 'headers') {
				options[property] = externalOptions[property];
			}
		}

		if (body && body instanceof FormData) {
			delete options.headers['Content-Type'];
		}

		return options;
	}

	/**
	 * @method baseApiUrlString
	 * @static baseApiUrlString
	 * @returns The value of the current base API URL string.
	 */
	public static get baseApiUrlString(): string {
		let apiBaseUrl = '';
		if (HttpService.environment.isMonolith === true) {
			apiBaseUrl = `${window.location.protocol}//${window.location.host}`;
		} else {
			if (HttpService.environment.apiServer != null && HttpService.environment.apiServer.length != 0) {
				apiBaseUrl = HttpService.environment.apiServer;
			} else {
				const url = window.location.hostname;
				const urlArr = url.split('.');
				const pt1 = urlArr[0];
				urlArr.splice(0, 1);
				const pt2 = urlArr.toString().replace(',', '.');
				apiBaseUrl = `${window.location.protocol}//${pt1}-api.${pt2}`;
				apiBaseUrl = HttpService.removeDoubleSlashes(apiBaseUrl);
			}
		}

		sessionStorage.setItem('apiBaseUrl', apiBaseUrl); // For cypress to use the apiBaseUrl for direct requests to the API
		return apiBaseUrl;
	}

	/**
	 * @method removeDoubleSlashes
	 * @description Removes double slashes from the given URL.
	 * @param url - The URL to remove double slashes from.
	 * @returns The URL without double slashes
	 * @example
	 * ```javascript
	 * HttpService.removeDoubleSlashes('http://www.optimove.com//api//v1//'); // 'http://www.optimove.com/api/v1/'
	 * ```
	 */
	static removeDoubleSlashes(url: string) {
		if (url.startsWith('http') === false) return url;

		// Split the URL into protocol, domain, and path
		var parts = url.split('://');
		var protocol = parts[0];
		var remainingUrl = parts[1];

		// Remove double slashes from the path portion
		var cleanedUrl = remainingUrl.replace(/\/{2,}/g, '/');

		// Reconstruct the URL with the cleaned path
		return protocol + '://' + cleanedUrl;
	}

	static getCustomHeaders(): { [key: string]: string | string[] } {
		const nullableCustomHeaders = {
			access_token: localStorage.getItem('accessToken'),
			access_token_id: localStorage.getItem('refreshToken'),
			tenant_id: sessionStorage.getItem('tenantId'),
			client_unique_id: sessionStorage.getItem('clientUniqueId'),
			asp_session_id: sessionStorage.getItem('aspSessionId'),
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			'Content-Type': 'application/json'
		};

		const customHeaders = Object.entries(nullableCustomHeaders).reduce(
			(item, [key, value]) => (value ? ((item[key] = value), item) : item),
			{}
		);
		return customHeaders;
	}

	/**
	 * @method baseApiUrlString
	 * @description Exposing the value of the static method with the same name
	 * @returns The value of the current base API URL string.
	 */
	readonly baseApiUrlString = HttpService.baseApiUrlString;

	/**
	 * @property cancelPendingRequests$
	 * @type {Subject<void>}
	 * @description Represents the cancel pending requests subject.
	 * @description This property is used to cancel pending requests.
	 */
	protected readonly cancelPendingRequests$ = new Subject<void>();

	/**
	 * @method toHttpParams
	 * @description Exposing the value of the static method with the same name
	 * @returns The params object for the HttpParams constructor.
	 */
	toHttpParams(keyValueData: { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> }) {
		return HttpService.toHttpParams(keyValueData);
	}

	/**
	 * @method cancelPendingRequests
	 * @description Cancels all pending requests.
	 * @example
	 * ```javascript
	 * cancelPendingRequests();
	 * ```
	 */
	cancelPendingRequests(): void {
		this.cancelPendingRequests$.next();
	}

	/**
	 *
	 * @method get<T>
	 * Constructs a `GET` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * get('path/to/endpoint', options).subscribe(data => {});
	 * ```
	 */
	get<T>(url: string, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method get<T>
	 * Constructs a `GET` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * get('path/to/endpoint').subscribe(data => {});
	 * get({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * get({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	get<T>(url: ControllerActionScheme, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method get<T>
	 * Constructs a `GET` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * get({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * get({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	get<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}

	/**
	 *
	 * @method post<T>
	 * Constructs a `POST` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * post('path/to/endpoint', options).subscribe(data => {});
	 * ```
	 */
	post<T>(url: string, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method post<T>
	 * Constructs a `POST` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * post('path/to/endpoint', body).subscribe(data => {});
	 * post({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * post({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */

	post<T>(url: ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method post<T>
	 * Constructs a `POST` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * post({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * post({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */
	post<T>(url: string | ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}

	/**
	 *
	 * @method put<T>
	 * Constructs a `PUT` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * put('path/to/endpoint', body, options).subscribe(data => {});
	 * ```
	 */
	put<T>(url: string, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method put<T>
	 * Constructs a `PUT` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * put({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * put({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */
	put<T>(url: ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method put<T>
	 * Constructs a `PUT` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param body The resources to edit.
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * put('path/to/endpoint', body).subscribe(data => {});
	 * put({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * put({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */
	put<T>(url: string | ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}

	/**
	 *
	 * @method delete<T>
	 * Constructs a `DELETE` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * delete('path/to/endpoint', options).subscribe(data => {});
	 * ```
	 */
	delete<T>(url: string, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method delete<T>
	 * Constructs a `DELETE` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * delete({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * delete({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	delete<T>(url: ControllerActionScheme, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method delete<T>
	 * Constructs a `DELETE` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * delete('path/to/endpoint').subscribe(data => {});
	 * delete({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * delete({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	delete<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}

	/**
	 *
	 * @method patch<T>
	 * Constructs a `PATCH` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * patch('path/to/endpoint', options).subscribe(data => {});
	 * ```
	 */
	patch<T>(url: string, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method get<T>
	 * Constructs a `PATCH` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * patch({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * patch({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */
	patch<T>(url: ControllerActionScheme, body: any | null, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method patch<T>
	 * Constructs a `PATCH` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * patch('path/to/endpoint', body).subscribe(data => {});
	 * patch({ controller: 'Main', action: 'GetFeatureFlag' }, body, options)
	 * patch({ controller: 'Main', action: 'GetFeatureFlag' }, body)
	 * ```
	 */
	patch<T>(url: string | ControllerActionScheme, body: any | null, options: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}

	/**
	 *
	 * @method head<T>
	 * Constructs a `HEAD` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * head('path/to/endpoint', options).subscribe(data => {});
	 * ```
	 */
	head<T>(url: string, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method head<T>
	 * Constructs a `HEAD` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * head({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * head({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	head<T>(url: ControllerActionScheme, options?: HttpOptions): Observable<T>;
	/**
	 *
	 * @method head<T>
	 * Constructs a `HEAD` request that interprets the body as an `'arraybuffer' | 'json' | 'blob' | 'text'` and returns the
	 * response in an `T`.
	 *
	 * @param url     The endpoint URL (string)
	 * @param url.controller The name of the controller, without 'Controller'. example: 'Main'
	 * @param url.action The name of the method within the controller. example 'GetFeatureFlag'
	 * @param options The HTTP options to send with the request.
	 *
	 * @return An `Observable` of the response, with the response body as an `T`.
	 * @example
	 * ```javascript
	 * head('path/to/endpoint').subscribe(data => {});
	 * head({ controller: 'Main', action: 'GetFeatureFlag' }, options)
	 * head({ controller: 'Main', action: 'GetFeatureFlag' })
	 * ```
	 */
	head<T>(url: string | ControllerActionScheme, options?: HttpOptions): Observable<T> {
		throw new Error('Method not implemented.');
	}
	abstract postWithoutFormattingURL<T>(url: string, body: any | null, options?: HttpOptions): Observable<T>;
}
