<div class="overflow-menu">
    <button #overflowMenuBtn (click)="toggleMenu()" 
        class="btn overflow-menu-btn" [ngClass]="overflowMenuButtonClasses">
        {{overflowMenuButtonText}}
        <i class="material-icons" *ngIf="!!overflowMenuButtonText && isArrowButtonVisible">arrow_drop_down</i>
        <i class="material-icons" *ngIf="!overflowMenuButtonText">more_vert</i>
    </button>
</div>

<ng-template #menu>
    <div class="menu-popover" [ngClass]="menuPopoverClass">
        <ng-template #popTemplate
                [ngTemplateOutlet]="nodeTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: items }">
        </ng-template>

        <ng-template #nodeTemplateRef let-items>
                <ng-container *ngFor="let item of items">
                    <button #overflowMenuItemBtn (click)="onMenuItemClicked(item)" class="btn btn-link btn-link-default overflow-menu-item-btn" [disabled]="item.disabled">
                        <ng-container [ngTemplateOutlet]="overflowMenuItemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
                        </ng-container>

                        <ng-container *ngIf="item.children?.length > 0" overflowMenuGroup>
                            <div class="section menu-popover">
                                <ng-template
                                        [ngTemplateOutlet]="nodeTemplateRef"
                                        [ngTemplateOutletContext]="{ $implicit: item.children}"
                                >
                                </ng-template>
                            </div>
                        </ng-container>
                    </button>

                    <hr class="solid" *ngIf="item.addSeparatorBelow">
                </ng-container>
        </ng-template>
    </div>
</ng-template>
