export interface BeeFreePluginSetupData {
	token: string;
	savedRowsUrl: string;
}

export interface BeePlugin {
	start(arg: any): void;
}

export interface BeeFreeFontsDefinition {
	showDefaultFonts: boolean;
	customFonts: BeeFreeCustomFont[];
}

export interface BeeFreeCustomFont {
	name: string;
	fontFamily: string;
	url: string;
	fontWeight?: {
		[key: number]: string;
	};
}

export const BeeFreeFonts: BeeFreeFontsDefinition = {
	showDefaultFonts: true,
	customFonts: []
};
