import {Injectable, Renderer2} from "@angular/core";
import {RobotoFontSizesService} from "./robotoFontSizes.service";
import {SearchListUtilsService} from "./searchlistUtils.service";

@Injectable()
export class RowCalculationService {
    constructor(private robotoFontSizeService: RobotoFontSizesService,
                private searchListUtilsService: SearchListUtilsService){

    }

    getHeightForText(text: string, containerWidth: number, horizontalPadding: number,
                     verticalPadding: number, lineHeight: number, maxRowsInCell: number, horizontalBorder: number,
                     verticalBorder: number): number {
        if (!text || !text.trim()){
            return this.calcHeight(1, lineHeight, verticalPadding, verticalBorder);;
        }

        const trimmedText = text.trim();
        const words = this.splitBySpaceAndWordHyphens(trimmedText);
        const contentWidth = containerWidth - horizontalPadding - horizontalBorder;
        // we start count from 0 instead of 1 because if we have a very long word
        // that fits into more than 1 line it will get counted twice.
        // 1. once for the initial lines values
        // 2. for the actual line calculation that expends multiple line
        let offset = 0;
        let lines = 0;
        for (let word of words) {
            let wordWidth = this.getWordWidth(word);
            // we trim the word because if a space appears in the end of the word and the word is the last
            // one in line it is ignored by css for size calculations. so we need a
            // similar check. ignore space for determining if word fits line or not.
            // if it doesn't fit line, then take it into account.
            const trimmedWord = word.trim();

            let trimmedWordWidth = this.getWordWidth(trimmedWord);
            if (offset + trimmedWordWidth > contentWidth) {
                lines += Math.ceil((wordWidth / contentWidth));
                offset = wordWidth % contentWidth;
            } else if (offset === 0 && lines === 0) {
                lines += 1;
                offset += wordWidth;
            } else {
                offset += wordWidth;
            }
        }
        if (offset > contentWidth) {
            lines++;
        }
        lines = this.searchListUtilsService.forceInRange(lines, 1, maxRowsInCell);
        return this.calcHeight(lines, lineHeight, verticalPadding, verticalBorder);
    }

    private calcHeight(lines, lineHeight, verticalPadding, verticalBorder): number {
        return lines * lineHeight + verticalPadding + verticalBorder;
    }

    private getWordWidth(word: string): number {
        // for special characters or other languages default to the size of x
        const defaultWidth = this.robotoFontSizeService.getWidth('x');
        let wordWidth = 0;
        for (let char of word) {
            const charWidth = this.robotoFontSizeService.getWidth(char) ? this.robotoFontSizeService.getWidth(char) : defaultWidth;
            wordWidth += charWidth;
        }
        return wordWidth;
    }

    private splitBySpaceAndWordHyphens(text): string[] {
        // we're trying to emulate css break-word that has some non trivial logic
        // 1. new-line on space
        // 2. new-line on mid-words hyphens
        let words = [];
        let word = '';
        for (let i = 0; i < text.length; i++){
            let pc = i !== 0 ? text[i-1] : '';
            let c = text[i];
            let nc = i !== text.length ? text[i+1] : '';

            if (c === ' ') { // space
                word += ' '; // we want to count the space's size as well
                words.push(word);
                word = '';
            } else if (c === '-' && pc !== ' ' && nc !== ' ') { // mid words hyphens
                word += '-';  // we want to count the hyphen's size as well
                words.push(word);
                word = '';
            } else {
                word += c;
            }
        }
        if (word !== '') {
            words.push(word);
        }
        return words;
    }
}