import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateDetails } from '../../../models/templateDetails';

@Component({
	selector: 'template-preview-modal',
	templateUrl: './templatePreviewModal.component.html',
	styleUrls: ['./templatePreviewModal.component.scss']
})
export class TemplatePreviewModalComponent implements OnInit {
	title: string;
	previewEnabled: boolean;
	template: TemplateDetails;
	templateBody: string;
	isOnCustomerData: boolean = true;
	isBrowserPreviewType: boolean = true;

	constructor(private bsModalRef: BsModalRef) {}

	ngOnInit(): void {
		this.templateBody = this.template.html;
	}

	close(): void {
		this.bsModalRef.hide();
	}

	openInNewTab(): void {
		const templateHtml = this.templateBody;
		const base = '<base target="_blank">\n';
		const endHeadIdx = templateHtml.indexOf('</head>');
		const html = templateHtml.substring(0, endHeadIdx) + base + templateHtml.substring(endHeadIdx);

		const newWindow = window.open();
		newWindow.document.write(html);
	}
}
