<opti-logic-modal [modalConf]="modalConfig">
  <div class="panel-body run-now-body generic-event-body" [formGroup]="genericEventModalForm">
    <div class="generic-event-row">
      <div class="generic-event-label">{{ eventLabelText }}</div>
      <opti-select
        data-qa-id="cyGenericWebhookEventSelect"
        class="generic-event-select"
        formControlName="selectGenericEvent"
        [placeholder]="selectEventPlaceholder"
        [items]="eventList"></opti-select>
    </div>
    <div class="generic-event-row small-margin-bottom">
      <div class="generic-event-label">{{ webhookURLLabelText }}</div>
      <div class="generic-event-url-validation">
        <div class="generic-event-values">
          <input
            class="form-control"
            formControlName="webhookUrl"
            placeholder="{{ webhookURLPlaceholderText }}"
            (ngModelChange)="onWebhookURLChanged()"
            data-qa-id="cyGenericEventWebhookURLInput"
            required />
          <loading-button
            *ngIf="!urlValid"
            data-qa-id="cyGenericEventWebhookURLValidateButton"
            [showLoader]="showValidatingLoader"
            (onClick)="validateURL()"
            [isDisabled]="!urlValid && webhookUrlFormControl.pristine"
            [buttonCssClass]="'btn btn-secondary validate-webhook-url'"
            [loadingCssClass]="'saving-loader'"
            [loadingText]="validatingLoadingText"
            [buttonText]="validationButtonText"></loading-button>
          <span *ngIf="urlValid" class="material-icons valid-webhook-url-chevron" data-qa-id="cyGenericEventWebhookURLIsValid">check</span>
        </div>
        <div
          *ngIf="webhookUrlFormControl.invalid && webhookUrlFormControl.touched && webhookUrlFormControl.errors.required"
          class="validation-error"
          data-qa-id="cyGenericEventWebhookUrlErrorMustBeSpecified">
          {{ webhookURLRequiredErrorText }}
        </div>
        <div
          *ngIf="webhookUrlFormControl.invalid && webhookUrlFormControl.errors.notValidated"
          class="validation-error"
          data-qa-id="cyGenericEventWebhookUrlErrorWebhookNotValidated">
          {{ webhookURLNotValidatedErrorText }}
        </div>
        <div
          *ngIf="webhookUrlFormControl.invalid && webhookUrlFormControl.errors.urlInvalid"
          class="validation-error"
          data-qa-id="cyGenericEventWebhookUrlErrorWebhookUrlInvalid">
          {{ webhookURLInvalidErrorText }}
        </div>
      </div>
    </div>
    <div class="generic-event-row copy-main-url-button">
      <loading-button
        data-qa-id="cyGenericEventWebhookCopyURLButton"
        (onClick)="onCopyMainUrl()"
        [isDisabled]="urlValid || isCopyMainUrlDisable()"
        [buttonCssClass]="'btn btn-secondary validate-webhook-url'"
        [buttonText]="copyMainUrlButtonText"></loading-button>
    </div>
  </div>
</opti-logic-modal>
