import {Component, Input, Output, EventEmitter, ViewEncapsulation} from "@angular/core";
import { TranslateKeys } from "./translateKeys.modal";

@Component({
    selector: 'move-tgs-to-stream-save-modal',
    templateUrl: './moveTgsToStreamSaveModal.component.html',
    styleUrls: ['./moveTgsToStreamSaveModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MoveTgsToStreamSaveModalComponent {
    @Input() isTgsHaveFutureCamapaigns: boolean;
    @Input() moveTgsToStreamConfirmed: Function;
    @Input() moveTgsToStreamRejected: Function;
    
    public translateKeys = new TranslateKeys();
    public isSaving = false;

    public onCancel() {
        this.moveTgsToStreamRejected();
    }

    public onSave() {
        this.moveTgsToStreamConfirmed();
        this.isSaving = true;
    }
}