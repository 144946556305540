<div class="js-tree">
  <div class="tree-panel">
    <div *ngIf="enableHeader" class="panel-heading header-section">
      <div class="header-row">
        <div class="input-group input-search">
          <input
            #searchInput
            autocomplete="off"
            [(ngModel)]="filterVal"
            class="form-control"
            id="filter"
            (keyup)="searchValUpdate.next(filterVal)"
            [placeholder]="configuration.searchPlaceholder | translate" />
          <span class="input-search-label material-icons search-text" *ngIf="!filterVal">search</span>
          <span class="input-search-label material-icons delete-text" *ngIf="filterVal" (click)="clearSearch()">close</span>
        </div>
        <div class="tree-menu-col">
          <js-tree-menu [treeButtons]="configuration.buttonsAndTexts" (itemClicked)="onMenuItemClicked($event)"></js-tree-menu>
          <js-tree-dot-menu
            *ngIf="configuration.dotMenuButtons"
            [treeButtons]="configuration.dotMenuButtons"
            [multiSelectedLength]="secondarySelection.length"
            [isDisabled]="isMultiActionDisabled"
            (itemClicked)="onDotMenuItemClicked($event)">
          </js-tree-dot-menu>
          <button *ngIf="configuration.isRefresh && !isRefreshing" title="Refresh templates" (click)="refreshTree()" class="btn-link">
            <i class="material-icons">refresh</i>
          </button>

          <button *ngIf="configuration.isCostumeBtn" (click)="configuration.costumeBtnCallBack()" class="btn-link">
            {{ configuration.costumeBtnText }}
          </button>
          <span *ngIf="isRefreshing" class="loader pull-left"></span>
        </div>
      </div>
    </div>
    <div
      class="panel-body body-section"
      #treeScrollContainer
      style="padding: 0"
      tabindex="0"
      (focus)="onTreeFocus()"
      (mousedown)="onTreeMouseDown()"
      (blur)="onTreeBlur()">
      <div class="col-md-12 text-center text-muted no-search-results" *ngIf="hasNoSearchResults">
        <h3>{{ configuration.noSearchResultsMsg | translate }}</h3>
      </div>
      <span [ngClass]="{'node-in-edit': nodeInEdit != null}">
        <tree-root
          [hidden]="hasNoSearchResults"
          #jsTree
          [nodes]="nodes"
          [(state)]="state"
          [options]="options"
          (initialized)="treeWasInitialized()"
          [focused]="isTreeFocus"
          (updateData)="updateData()">
          <ng-template #treeNodeTemplate let-node let-index="index">
            <div
              class="node-container"
              [ngClass]="{
                'secondary-selection': node.data.secondarySelection,
                'disabled-node': disabledNodesTitlesArr.includes(node.data.text)
              }">
              <div class="mainNode" [ngClass]="node.data.rel" data-id="{{ node.data.id }}">
                <i *ngIf="node.data.rel === 'folder' && !node.data.specialIcon" class="material-icons folder-icon">folder</i>
                <i
                  *ngIf="node.data.rel === 'folder' && node.data.specialIcon"
                  class="material-icons folder-icon"
                  [ngClass]="node.data.specialIcon"
                  >{{ node.data.specialIcon }}</i
                >
                <i *ngIf="node.data.rel === 'leaf' && !node.data.specialIcon" class="material-icons">insert_drive_file</i>
                <i *ngIf="node.data.rel === 'leaf' && node.data.specialIcon" class="material-icons" [ngClass]="node.data.specialIcon">{{
                  node.data.specialIcon
                }}</i>
                <span
                  *ngIf="editId != node.data.id"
                  class="name"
                  title="{{ node.data.text }}"
                  [innerHtml]="node.data.text"
                  [ngClass]="node.data.specialText"></span>
                <input
                  (keydown.esc)="editEscClicked($event, node)"
                  (keydown.enter)="clickOutsideWhileInEdit()"
                  *ngIf="editId == node.data.id"
                  #editNodeInput
                  class="edit-node-name"
                  placeholder="{{ editPlaceholder }}"
                  type="text"
                  [(ngModel)]="nodeText" />
              </div>
              <div
                class="mainNode-hover-buttons"
                *ngIf="secondarySelection.length <= 1"
                (click)="onNodeAction(node, $any($event.target).dataset['eventtype'])">
                <ng-container *ngIf="node.data.rel === 'folder'">
                  <i
                    *ngIf="
                      folderNodeIsButtonDisplayed(
                        treeButtonType.newNode,
                        node.data.categoryId,
                        node.data.streamLevel,
                        node.data.optibotFolder,
                        node.data.importedCustomersFolder
                      )
                    "
                    [attr.data-eventType]="TreeEventType.addNode"
                    [title]="configuration?.buttonsAndTexts.newNode.text | translate"
                    class="material-icons add-new-item-btn node-btn"
                    >library_add</i
                  >
                  <i
                    *ngIf="
                      folderNodeIsButtonDisplayed(
                        treeButtonType.newFolder,
                        node.data.categoryId,
                        node.data.streamLevel,
                        node.data.optibotFolder,
                        node.data.importedCustomersFolder
                      )
                    "
                    [attr.data-eventType]="TreeEventType.addFolder"
                    [title]="configuration?.buttonsAndTexts.newFolder.text | translate"
                    class="material-icons create-new-folder-btn node-btn"
                    >create_new_folder</i
                  >
                  <i
                    *ngIf="
                      folderNodeIsButtonDisplayed(
                        treeButtonType.editNode,
                        node.data.categoryId,
                        node.data.streamLevel,
                        node.data.optibotFolder,
                        node.data.importedCustomersFolder
                      )
                    "
                    [attr.data-eventType]="TreeEventType.editNodeName"
                    [title]="configuration?.buttonsAndTexts.editNode.text | translate"
                    class="material-icons edit-node-name-btn node-btn"
                    >mode_edit</i
                  >
                  <i
                    *ngIf="
                      folderNodeIsButtonDisplayed(
                        treeButtonType.delete,
                        node.data.categoryId,
                        node.data.streamLevel,
                        node.data.optibotFolder,
                        node.data.importedCustomersFolder
                      )
                    "
                    [attr.data-eventType]="TreeEventType.deleteNode"
                    [title]="configuration?.buttonsAndTexts?.delete?.text | translate"
                    class="material-icons delete-node-btn node-btn"
                    >delete</i
                  >
                  <i
                    *ngIf="moreMenuCategoryEnabled[node.data.categoryId] && !node.data.importedCustomersFolder"
                    (click)="onMoreActionsOpen(node, $event)"
                    class="material-icons dot-more-button"
                    >more_vert</i
                  >
                </ng-container>
                <ng-container *ngIf="node.data.rel === 'leaf'">
                  <i
                    *ngIf="leafNodeIsButtonDisplayed(treeButtonType.duplicateNode, node.data.categoryId, node.data.noButtons)"
                    [attr.data-eventType]="TreeEventType.duplicateNode"
                    [title]="configuration?.buttonsAndTexts.duplicateNode.text | translate"
                    class="material-icons duplicate-node-btn node-btn"
                    >content_copy</i
                  >
                  <i
                    *ngIf="leafNodeIsButtonDisplayed(treeButtonType.delete, node.data.categoryId, node.data.noButtons)"
                    [attr.data-eventType]="TreeEventType.deleteNode"
                    [title]="configuration?.buttonsAndTexts?.delete?.text | translate"
                    class="material-icons delete-node-btn node-btn"
                    >delete</i
                  >
                  <i
                    *ngIf="leafNodeIsButtonDisplayed(treeButtonType.editLeafNodeData, node.data.categoryId, node.data.noButtons)"
                    [attr.data-eventType]="TreeEventType.editLeafNodeData"
                    [title]="configuration?.buttonsAndTexts?.editNode?.text | translate"
                    class="material-icons node-btn"
                    >mode_edit</i
                  >
                  <i
                    *ngIf="moreMenuCategoryEnabled[node.data.categoryId]"
                    (click)="onMoreActionsOpen(node, $event)"
                    class="material-icons dot-more-button"
                    >more_vert</i
                  >
                </ng-container>
                <ng-container *ngIf="node.data.rel === 'category'">
                  <i
                    *ngIf="categoryNodeIsButtonDisplayed(treeButtonType.newNode, node.data.categoryId)"
                    [attr.data-eventType]="TreeEventType.addNode"
                    [title]="configuration?.buttonsAndTexts.newNode.text | translate"
                    class="material-icons add-new-item-btn node-btn"
                    >library_add</i
                  >
                  <i
                    *ngIf="categoryNodeIsButtonDisplayed(treeButtonType.newFolder, node.data.categoryId)"
                    [attr.data-eventType]="TreeEventType.addFolder"
                    [title]="configuration?.buttonsAndTexts.newFolder.text | translate"
                    class="material-icons create-new-folder-btn node-btn"
                    >create_new_folder</i
                  >
                </ng-container>
              </div>
              <div class="placeHolder">
                <i
                  *ngIf="configuration?.isDraggable && node.data.rel !== 'category' && !node.data.streamLevel"
                  class="material-icons node-handle"
                  >drag_handle</i
                >
              </div>
            </div>
          </ng-template>
        </tree-root>
      </span>
    </div>
  </div>

  <js-tree-context-menu
    [node]="contextMenuNode"
    [items]="contextMenuItems"
    [rightClickXPosition]="rightClickXPosition"
    [rightClickYPosition]="rightClickYPosition"
    [isTreeScrolling]="isTreeScrolling"
    [isMultiSelected]="secondarySelection.length > 1"
    [multiSelectedButtons]="configuration.dotMenuButtons"
    (itemClick)="onNodeAction($event.node, $event.eventName)">
  </js-tree-context-menu>
  <js-tree-more-actions-menu
    [items]="moreActionsItems"
    [node]="moreActionsMenuNode"
    [isTreeScrolling]="isTreeScrolling"
    [leftClickXPosition]="leftClickXPosition"
    [leftClickYPosition]="leftClickYPosition"
    (itemClicked)="onNodeAction($event.node, $event.eventName)">
  </js-tree-more-actions-menu>
</div>
