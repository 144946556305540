import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {SwitchButtonType} from "../../../../../components/switchButton/switchButtonType.model";
import {BrandsService} from "../BrandsList/brandsService";
import {Router} from "@angular/router";
import {BrandModel} from "../../brandModel";
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import {BsModalRef, ModalOptions} from "ngx-bootstrap/modal";
import {OptiLogicModalService} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'edit-brand',
    templateUrl: './editBrand.component.html',
    styleUrls: ['./editBrand.component.scss'],
})

export class EditBrandComponent implements OnInit{
    public isSaving = false;
    public translateKeys = translateKeys;
    public switchButtonType:SwitchButtonType = SwitchButtonType.Small;
    public brand: BrandModel;
    public editDOIForm: FormGroup
    private isDOISubscription: Subscription

    constructor(
        private brandService: BrandsService,
        private router: Router,
        private formBuilder: FormBuilder,
        private modalService: OptiLogicModalService,
        private translate: TranslateService,
        private bsModalRef: BsModalRef,
    ) {}

    ngOnInit() {
        this.createForm();
        this.setData();
    }

    public createForm(){
        const reg = 'https:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)';
        this.editDOIForm = this.formBuilder.group({
            isDOI: [{value: false,}],
            link:['', [Validators.pattern(reg)]],
        });
    }
    public setData(){
        const brand = this.router.getCurrentNavigation().extras.state as BrandModel;
        if (brand){
            this.brand = brand;
            this.translateKeys.title = brand.BrandName;
            this.isDOIFormControl.setValue(this.brand.DoiEnabled);
            this.linkFormControl.setValue(this.brand.DoiLink);
            this.switchFilters();
        }
    }

    private setupBrandDOI(){
        if(this.brand){
            this.brand.DoiLink = this.linkFormControl.value;
            this.brand.DoiEnabled = this.isDOIFormControl.value;
        }
    }

    public updateBrandDOI(){
        this.setupBrandDOI();
        this.brandService.updateBrandProperty(this.brand).subscribe((res: DefaultAjaxResponse)=>{
            if(res.IsSuccess){
                this.router.navigate(['/user-settings/optimail/doubleOptIn'])
            }
        });
    }
    public switchFilters()
    {
        if(!this.isDOIFormControl.value)
        {
            this.linkFormControl.disable();
        }else
        {
            this.linkFormControl.enable();
        }
    }
    public cancelClick(){
        this.router.navigate(['/user-settings/optimail/doubleOptIn'])
    }

    get isDOIFormControl(): FormControl{
        return this.editDOIForm.get('isDOI') as FormControl;
    }

    get linkFormControl(): FormControl{
        return this.editDOIForm.get('link') as FormControl;
    }
    public openConfirmModal(){
        if(this.editDOIForm.dirty) {
            this.modalService.openModalMessage(
                'sm',
                {
                    message: this.translate.instant(this.translateKeys.confirmBackMessage),
                    buttons: [
                        {
                            class: 'btn-primary',
                            label: this.translate.instant(this.translateKeys.discardChanges),
                            action: this.discardChanges.bind(this),
                        },
                        {
                            class: 'btn-default',
                            label: this.translate.instant(this.translateKeys.continueEditing),
                            action: this.closeModal.bind(this),
                        },
                    ]
                },
                <ModalOptions<any>>{ ignoreBackdropClick: true, keyboard: false }
            );
        }
        else {
            this.discardChanges();
        }
    }
    private closeModal() {
        this.bsModalRef.hide();
    }
    private discardChanges() {
        this.router.navigateByUrl("/user-settings/optimail/doubleOptIn");
        this.bsModalRef.hide();
    }
    ngOnDestroy(){
        this.isDOISubscription.unsubscribe();
    }
}
const translateKeys = {
    title: '',
    switchLabel: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.Enable_DOI',
    urlLabel: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.DOI_URL',
    placeholder: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.placeholder',
    text_under_input: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.Text_Under_Input',
    confirmBackMessage: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.confirmModal.MESSAGE',
    continueEditing: 'general.CONTINUE_EDITING',
    discardChanges: 'general.DISCARD_CHANGES',
    validation_error: 'features.user_settings.body.executionChannelOptimail.Double_OptIn.validation_error',
}