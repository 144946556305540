import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IModalButtonConf,
  OptiLogicModalService,
} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { SwitchButtonType } from "../../../../../components/switchButton/switchButtonType.model";
import { IUniquePromoCode } from "../../models/iuniquePromoCode.model";
import { UniquePromoCodesService } from "../../services/uniquePromoCodes.service";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ImportCsvLoaderModal } from "../importCsvLoaderModal/importCsvLoaderModal.component";
import { TranslateService } from "@ngx-translate/core";
import { UniquePromoAttributeStatus } from "../../models/uniquePromoAttributeStatus.enum";
import { ColoredTagType } from "@optimove/ui-sdk/components/colored-tag";
import { UniquePromoErrors } from "../../models/uniquePromoErrors.enum";
import { v4 as uuid } from "uuid";
import { SignalrService } from "../../../../../services/signalr.service";
import { ICreateNewUniquePromoCodeResults } from "../../models/icreateNewUniquePromoCodeResults.modal";

@Component({
  selector: "add-unique-promo-code",
  templateUrl: "./addUniquePromoCode.component.html",
  styleUrls: ["./addUniquePromoCode.component.scss"],
})
export class AddUniquePromoCodeComponent implements OnInit, OnDestroy {
  @ViewChild("csvFileInput", { static: false }) csvFileInput;
  public addUniquePromoCodeForm: FormGroup;
  public SwitchButtonType = SwitchButtonType;
  public showNotificationSettengs = false;
  public isNotificationControl: FormControl;
  public threshold: FormControl;
  public promotionName: FormControl;
  public email: FormControl;
  public file: FormControl;
  public csvFile: File;
  public isSaving = false;
  private uniquePromoCode: IUniquePromoCode;
  private modalRef: BsModalRef;
  public loading: boolean;
  public fileSelected: boolean;
  private tenantId: number;
  private signalRKey: string;

  //Edit fields
  public editMode = false;
  public promotionTitle: string;
  public coloredTagType: ColoredTagType;
  public editPromotionStatus: UniquePromoAttributeStatus;
  public availableCodes: Number;
  public saveOrCreateBtn: string;
  public hasChange: boolean = false;

  constructor(
    private router: Router,
    private uniquePromoCodesService: UniquePromoCodesService,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private modalService: OptiLogicModalService,
    private signalrService: SignalrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.tenantId = JSON.parse(
      sessionStorage.getItem("tenantDetails")
    ).tenantId;
    var promoName = this.getPromotionNameQueryParams();
    var promoId = this.getPromotionIdQueryParams();
    this.createForm();
    if (this.editMode) {
      this.promotionTitle = promoName;
      this.saveOrCreateBtn = this.translate.instant("general.SAVE");
      this.getUniquePromoCode(promoId);
    } else {
      this.loading = false;
      this.onAddUniquePromoCodeFormValueChange();
    }
    this.uniquePromoCode = {} as IUniquePromoCode;
  }

  public initSignalR(signalRKey: string) {
    this.signalRKey = signalRKey;
    this.signalrService
      .on("uniquePromo","uploadCsvJobFinished", this.signalRHandler.bind(this));
  }

  private signalRHandler(signalRKey: string, res: any) {
    if (signalRKey === this.signalRKey) {
      this.modalRef.content.loadResults(
        JSON.parse(res) as ICreateNewUniquePromoCodeResults
      );
    }
  }

  private getUniquePromoCode(promoId) {
    this.uniquePromoCodesService
      .getUniquePromoCodeById(promoId)
      .subscribe((res) => {
        this.uniquePromoCode.eventListenerId = res.eventListenerId;
        this.setForm(res);
        this.loading = false;
        this.onAddUniquePromoCodeFormValueChange();
      });
  }

  private onAddUniquePromoCodeFormValueChange() {
    const initialValue = this.addUniquePromoCodeForm.value;
    this.addUniquePromoCodeForm.valueChanges.subscribe((value) => {
      this.hasChange = Object.keys(initialValue).some((key) => {
        if (
          initialValue[key] == undefined &&
          this.addUniquePromoCodeForm.value[key] == ""
        ) {
          return false;
        }
        return this.addUniquePromoCodeForm.value[key] != initialValue[key];
      });
    });
  }

  backToLobby() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/user-settings/uniquePromoCodes"])
    );
    window.open("/#" + url, "_self");
  }

  onSubmit() {
    this.setUniquePromoCodeObject();
    if (this.addUniquePromoCodeForm.get("file").value !== null) {
      this.savePromotionWithCsv();
    } else {
      this.isSaving = true;
      const functionName = this.editMode
        ? "UpdateUniquePromoCode"
        : "AddUniquePromoCode";
      this.uniquePromoCodesService[functionName](
        this.uniquePromoCode
      ).subscribe(
        (res) => {
          if (res.isSuccess) {
            this.backToLobby();
          } else {
            this.onErrorInProcess(res.errorCodeStatus);
          }
        },
        (err) => {
          this.onErrorInProcess();
        }
      );
    }
  }

  private savePromotionWithCsv() {
    const signalRKey = ["UPC", uuid()].join("_");
    this.initSignalR(signalRKey);
    this.openImportCsvLoaderModal();
    let formData = new FormData();
    formData.append("csvFile", this.csvFile, this.csvFile.name);
    const functionName = this.editMode
      ? "UpdateUniquePromoCode"
      : "AddUniquePromoCode";
    this.uniquePromoCodesService[functionName](this.uniquePromoCode).subscribe(
      (res) => {
        if (res.isSuccess) {
          formData.append("promotionId", res.uniquePromoId.toString());
          formData.append("signalRKey", signalRKey);
          this.uploadCsvProcess(formData);
        } else {
          this.modalRef.content.loadResults(res);
        }
      },
      (err) => {
        const errorCodeStatus = this.editMode
          ? UniquePromoErrors.FailedToUpdatePromotion
          : UniquePromoErrors.FailedToCreatePromotion;
        this.modalRef.content.loadResults({
          isSuccess: false,
          errorCodeStatus: errorCodeStatus,
        });
      }
    );
  }

  private uploadCsvProcess(formData) {
    this.modalRef.content.createdOrUpdatedSuccessfully = true;
    this.uniquePromoCodesService.uploadCsvFile(formData).subscribe(
      (res) => {
        if (!res.isSuccess) {
          this.modalRef.content.loadResults(res);
          this.reset();
        }
      },
      (err) => {
        this.modalRef.content.loadResults({ isSuccess: false });
      }
    );
  }

  private openImportCsvLoaderModal() {
    this.modalRef = this.modalService.open(ImportCsvLoaderModal, "sm", <
      ModalOptions<any>
    >{
      ignoreBackdropClick: true,
      keyboard: true,
      class: "import-csv-loader-modal-modal",
    });
    this.modalRef.content.editMode = this.editMode;
  }

  openModalLogicMessage(
    title: string,
    message: string,
    size: string,
    buttons: IModalButtonConf[]
  ) {
    return this.modalService.openOptiLogicModal(
      size,
      {
        title: title,
        html: message,
        buttons: buttons,
      },
      <ModalOptions<any>>{
        ignoreBackdropClick: true,
        class: "import-csv-loader-modal-modal",
      }
    );
  }

  showPopUpError(title: string, error: string) {
    return this.openModalLogicMessage(
      this.translate.instant(
        `features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal.${title}`
      ),
      this.translate.instant(
        `features.user_settings.body.uniquePromoCodes.addUniquePromoCode.ImportCsvFileModal.${error}`
      ),
      "sm",
      [
        {
          class: "btn-primary",
          label: this.translate.instant("general.OK"),
          action: () => {},
        },
      ]
    );
  }

  onErrorInProcess(error?: UniquePromoErrors) {
    this.isSaving = false;
    switch (error) {
      case UniquePromoErrors.CSVFileSizeIsTooBig:
        this.showPopUpError("IMPORT_CSV_FILE", "FAILED_TO_UPLOAD_FILE_SIZE");
        break;
      case UniquePromoErrors.CSVFileIncorectType:
        this.showPopUpError(
          "IMPORT_CSV_FILE",
          "FAILED_TO_UPLOAD_FILE_CSV_EXTENSION"
        );
        break;
      case UniquePromoErrors.CSVFileIncorectType:
        this.showPopUpError(
          "IMPORT_CSV_FILE",
          "FAILED_TO_UPLOAD_FILE_CSV_EXTENSION"
        );
        break;
      case UniquePromoErrors.FailedToCreatePromotion:
        this.showPopUpError(
          "NEW_PROMOTION",
          "FAILED_TO_CREATE_PLEASE_TRY_AGAIN"
        );
        break;
      case UniquePromoErrors.FailedToUpdatePromotion:
        this.showPopUpError(
          "EDIT_PROMOTION",
          "FAILED_TO_SAVE_CHANGES_PLEASE_TRY_AGAIN"
        );
        break;
      default:
        if (this.editMode) {
          this.showPopUpError(
            "EDIT_PROMOTION",
            "FAILED_TO_SAVE_CHANGES_PLEASE_TRY_AGAIN"
          );
        } else {
          this.showPopUpError(
            "NEW_PROMOTION",
            "FAILED_TO_CREATE_PLEASE_TRY_AGAIN"
          );
        }
        break;
    }
  }

  setUniquePromoCodeObject() {
    if (this.editMode) {
      this.uniquePromoCode.promotionId = this.getPromotionIdQueryParams();
    }
    this.uniquePromoCode.promotionName =
      this.addUniquePromoCodeForm.controls["promotionName"].value;
    if (this.addUniquePromoCodeForm.controls["defaultPromoCode"] != null) {
      this.uniquePromoCode.defaultPromotionCode =
        this.addUniquePromoCodeForm.controls["defaultPromoCode"].value;
    }
    if (this.isNotificationControl.value) {
      this.uniquePromoCode.threshold =
        this.addUniquePromoCodeForm.controls["threshold"].value;
      this.uniquePromoCode.email =
        this.addUniquePromoCodeForm.controls["email"].value;
    }
  }

  handleFileCsvInput(event: Event) {
    this.loading = true;
    let files = (event.target as HTMLInputElement & EventTarget).files;
    this.csvFile = files[0] as File;
    var extension = files[0].name.split(".").pop().toLowerCase();
    var filesize = files[0].size / (1024 * 1024); //file size in MB
    this.finishSelectCsvFile();
    if (extension != "csv") {
      this.onErrorInProcess(UniquePromoErrors.CSVFileIncorectType);
      this.reset();
    } else if (filesize >= 50) {
      this.onErrorInProcess(UniquePromoErrors.CSVFileSizeIsTooBig);
      this.reset();
    }
  }

  uploadCSV() {
    this.csvFileInput.nativeElement.value = "";
    this.csvFileInput.nativeElement.click();
  }

  finishSelectCsvFile() {
    this.loading = false;
    this.fileSelected = true;
  }

  reset() {
    this.csvFile = null;
    this.addUniquePromoCodeForm.patchValue({ file: null });
    this.fileSelected = false;
    this.loading = false;
    this.isSaving = false;
    this.changeDetectorRef.detectChanges();
  }

  createForm() {
    this.addUniquePromoCodeForm = new FormGroup({
      promotionName: new FormControl(null, Validators.required),
      defaultPromoCode: new FormControl(null),
      isNotification: new FormControl(false),
      email: new FormControl(null, { updateOn: "blur" }),
      threshold: new FormControl(200),
      file: new FormControl(null),
    });

    this.fillComponentFields();
    this.adjustFileValidators();
    this.promotionTitle = this.translate.instant(
      "features.user_settings.body.uniquePromoCodes.addUniquePromoCode.TITLE"
    );
    this.saveOrCreateBtn = this.translate.instant(
      "features.user_settings.body.uniquePromoCodes.addUniquePromoCode.CREATE_PROMOTION"
    );
  }

  adjustFileValidators() {
    if (!this.editMode && !this.file.hasValidator(Validators.required)) {
      this.file.addValidators(Validators.required);
    }
    if (this.editMode && this.file.hasValidator(Validators.required)) {
      this.file.removeValidators(Validators.required);
    }
  }

  setForm(uniquePromoCodeEditResult) {
    this.addUniquePromoCodeForm.patchValue({
      promotionName: this.promotionTitle,
      defaultPromoCode: uniquePromoCodeEditResult
        ? uniquePromoCodeEditResult.defaultCodeValue
        : null,
      isNotification:
        uniquePromoCodeEditResult && uniquePromoCodeEditResult.notifyEmails
          ? true
          : false,
      email:
        uniquePromoCodeEditResult && uniquePromoCodeEditResult.notifyEmails
          ? uniquePromoCodeEditResult.notifyEmails
          : null,
      threshold:
        uniquePromoCodeEditResult && uniquePromoCodeEditResult.threshold
          ? uniquePromoCodeEditResult.threshold
          : 0,
    });
    this.fillComponentFieldsEditMode(uniquePromoCodeEditResult);
  }

  fillComponentFields() {
    this.isNotificationControl = this.addUniquePromoCodeForm.get(
      "isNotification"
    ) as FormControl;
    this.promotionName = this.addUniquePromoCodeForm.get(
      "promotionName"
    ) as FormControl;
    this.email = this.addUniquePromoCodeForm.get("email") as FormControl;
    this.threshold = this.addUniquePromoCodeForm.get(
      "threshold"
    ) as FormControl;
    this.file = this.addUniquePromoCodeForm.get("file") as FormControl;
  }

  fillComponentFieldsEditMode(uniquePromoCodeEditResult?) {
    this.fillComponentFields();

    this.availableCodes =
      uniquePromoCodeEditResult.total - uniquePromoCodeEditResult.used;
    this.editPromotionStatus = uniquePromoCodeEditResult.status;
    this.editPromotionStatus =
      this.editPromotionStatus == null
        ? UniquePromoAttributeStatus.Empty
        : this.editPromotionStatus;
    switch (this.editPromotionStatus) {
      case UniquePromoAttributeStatus.Empty:
        this.coloredTagType = ColoredTagType.Type1;
        break;
      case UniquePromoAttributeStatus.Available:
        this.coloredTagType = ColoredTagType.Type4;
        break;
    }
  }

  onIsNotificationChange(event: boolean) {
    /*commented in US id: 163700
    if (event === true) {
      this.email.addValidators(Validators.required);
      this.email.addValidators([this.mailsValidator()]);
      this.addUniquePromoCodeForm.patchValue({
        email: "",
        threshold: 200,
      });
    } else {
      this.email.removeValidators(Validators.required);
      this.email.removeValidators([this.mailsValidator()]);
      this.email.reset();
      this.addUniquePromoCodeForm.patchValue({
        email: null,
        threshold: 0,
      });
    }
    this.email.updateValueAndValidity();
    */
  }

  onPromotionNameChanged() {
    this.promotionName.setValue(this.promotionName.value.trim());
  }

  mailsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != null) {
        const mailControlValue = control.value;

        const mailsList = mailControlValue.split(",");
        var numberOfMails = mailControlValue.split("@").length - 1;

        if (mailsList.length < numberOfMails && numberOfMails > 0) {
          return { multipleAddressesError: true };
        }

        if (mailsList != null) {
          var regex =
            /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,10}\s*?,?\s*?)+$/;
          for (var i = 0; i < mailsList.length; i++) {
            var trimmedEmail = mailsList[i].trim();
            if (mailsList[i] != "" && !regex.test(trimmedEmail)) {
              return { notValidEmail: true };
            }
          }
        }
      }
      return null;
    };
  }

  private getPromotionNameQueryParams() {
    if (
      Object.keys(this.activeRoute.snapshot.queryParams).length &&
      this.activeRoute.snapshot.queryParams.editMode &&
      this.activeRoute.snapshot.queryParams.editMode == 1
    ) {
      this.editMode = true;
      var promoNameParam = this.activeRoute.snapshot.queryParams.promotionName;
      return promoNameParam;
    }
    return "";
  }

  private getPromotionIdQueryParams() {
    if (
      Object.keys(this.activeRoute.snapshot.queryParams).length &&
      this.activeRoute.snapshot.queryParams.editMode &&
      this.activeRoute.snapshot.queryParams.editMode == 1
    ) {
      this.editMode = true;
      var PromoIdParam = this.activeRoute.snapshot.queryParams.promotionId;
      return PromoIdParam;
    }
    return "";
  }

  formHasChanged() {
    return (
      !this.addUniquePromoCodeForm.valid && !this.addUniquePromoCodeForm.dirty
    );
  }

  OnLostThresholdFocus() {
    if (!this.threshold.value) {
      this.threshold.setValue(0);
    }
  }

  ngOnDestroy(): void {
    this.signalrService
      .off("uniquePromo","uploadCsvJobFinished", this.signalRHandler.bind(this));
  }
}
