import { Component, Input, EventEmitter, Output , ViewEncapsulation } from "@angular/core";
import { AlertType } from "./models/alertType.enum";

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class AlertComponent {
    @Input() message: string;
    @Input() title: string;
    @Input() type: AlertType = AlertType.Warning;
    @Input() show: boolean = true;
    @Output() showChange = new EventEmitter<boolean>()

    public closeAlert() {
        this.show = false;
        this.showChange.emit(this.show);
    }
}
