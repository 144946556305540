import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { ValidationOutput } from '../../../models/templateSaveUpdate';
import { ValidatePersonalizationTagsComponent } from '../validatePersonalizationTags/validatePersonalizationTags.component';

@Component({
	selector: 'validation-error-model',
	templateUrl: './validationErrorModel.component.html',
	styleUrls: ['./validationErrorModel.component.scss']
})
export class ValidationErrorModelComponent {
	@Input() saveCallback: () => {};
	@Input() cancelCallback: () => {};
	@Input() validationOutput: ValidationOutput;
	@Input() isOtherEditor: boolean;

	constructor(private bsModalRef: BsModalRef, private modalService: OptiLogicModalService) {}

	openValidationModal(): void {
		this.modalService.open(ValidatePersonalizationTagsComponent, 'lg', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				validationOutput: this.validationOutput,
				isOtherEditor: this.isOtherEditor
			}
		});
	}

	save(): void {
		this.saveCallback();
		this.bsModalRef.hide();
	}

	close(): void {
		this.cancelCallback();
		this.bsModalRef.hide();
	}
}
