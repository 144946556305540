import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Tag } from '../../models/tag.model';
import { OptiLogicModalService } from '../optiLogicModal/optiLogicModal.service';
import { NotesAndTagsButtonModalComponent } from './modal/notesAndTagsModal.component';
import { NotesAndTagIds } from './model/notesAndTags.model';
import { NotesAndTagsThemes } from './model/notesAndTagsThemes.enum';


@Component({
	selector: 'notes-and-tags-button',
	templateUrl: 'notesAndTagsButton.component.html',
	styleUrls: ['./notesAndTagsButton.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => NotesAndTagsButtonComponent)
		}
	]
})
export class NotesAndTagsButtonComponent implements ControlValueAccessor, OnInit {
    
    @Input() tagsLimit: number;
    @Input() allTags: Tag[];
    @Input() isEditMode: boolean;
    @Input() streamTestName: any;
    @Input() theme: NotesAndTagsThemes;
    @Input() curNewTags: Tag[];
    set disabled(isDisabled: boolean) {
    	this.isDisabled = isDisabled;
    }

    @Output() saveNewTagsAndNotesEmitter: EventEmitter<NotesAndTagIds> = new EventEmitter();

    @Output() saveNewTags = new EventEmitter<IListItem[]>();

    @Output() formInit: EventEmitter<FormControl> = new EventEmitter();

    noteAndTags: NotesAndTagIds = {
    	note: '',
    	tags: []
    };
    isDisabled: boolean;

    private onChangeEmitterFunc: any;
    private onTouchEmitterFunc: any;
    public NotesAndTagsModalRef: BsModalRef;

    ngOnInit() {
    	const notesAndTags: FormControl = new FormControl({ value: this.noteAndTags, disabled: false });
    	this.formInit.emit(notesAndTags);
    }

    constructor(private modalService: OptiLogicModalService) {}

    writeValue(noteAndTags: NotesAndTagIds): void {
    	this.noteAndTags = noteAndTags;
    }

    registerOnChange(fn: any): void {
    	this.onChangeEmitterFunc = fn;
    }

    registerOnTouched(fn: any): void {
    	this.onTouchEmitterFunc = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    	this.isDisabled = isDisabled;
    }

    openNotesAndTagLogicModal() {
    	this.onTouchEmitterFunc && this.onTouchEmitterFunc();
    	this.NotesAndTagsModalRef = this.modalService.open(
    		NotesAndTagsButtonModalComponent,
    		'md',
            <ModalOptions<any>>({
            	ignoreBackdropClick: true,
            	initialState: {
            		tagsLimit: this.tagsLimit,
            		textValue: this.noteAndTags.note,
            		selectedTags: this.noteAndTags.tags ? [...this.noteAndTags.tags] : [],
            		allTags: this.allTags,
            		isEditMode: this.isEditMode,
            		streamTestName: this.streamTestName,
            		curNewTags: this.curNewTags
            	}
            }));
    	const notesAndTagsModal = this.NotesAndTagsModalRef.content as NotesAndTagsButtonModalComponent;
    	notesAndTagsModal.saveNewTags$.subscribe(
    		(newTagsToSave : Tag[]) => this.handleNewTags(newTagsToSave)
    	);
    	notesAndTagsModal.saveNotesAndTags$.subscribe(
    		(newTagsToSave : NotesAndTagIds) => this.handleSaveEvent(newTagsToSave)
    	);
    }

    handleSaveEvent(notesAndTagsToSave: NotesAndTagIds) {
    	this.onChangeEmitterFunc && this.onChangeEmitterFunc(notesAndTagsToSave);
    	this.noteAndTags = notesAndTagsToSave;
    	this.saveNewTagsAndNotesEmitter.emit(notesAndTagsToSave);
    }

    handleNewTags(newTagsToSave: IListItem[]) {
    	this.saveNewTags.emit(newTagsToSave);
    }
}
