<div *ngIf="isPersonalizationTag" class="attribute-settings-container">
    <div class="col-sm-4 pl-0 pr-0 settings-label">
        {{translateKeys.settings | translate}}
    </div>
    <div class="col-sm-8 pl-0 pr-0" style="padding: 0;">
        <div class="checkbox checkbox-primary display-checkbox">
            <input class="settings-checkbox-input" type="checkbox" [checked]="this.showAttribute" (change)="changeAttributeVisibility()" id="IsHidden" [disabled]="this.disableShowHideCheckbox || this.isPersonalization">
            <label class="settings-checkbox-label" for="IsHidden">{{translateKeys.isHidden | translate}}</label>    
            <i class="material-icons info-icon" tooltip="{{translateKeys.tooltip | translate}}" placement="bottom right"> help_outline</i>
        </div>
        <div class="checkbox checkbox-primary" *ngIf="isPersonalizationTag">
            <input class="settings-checkbox-input" type="checkbox" [checked]="this.isPersonalization" (change)="changePersonalizationCheckBox()" id="IsPersonalization">
            <label class="settings-checkbox-label" for="IsPersonalization">{{translateKeys.personalizationTag | translate}}</label>
        </div>
    </div>  
</div>