import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateConditionalLanguageService } from '../../../services/templateConditionalLanguage.service';

@Component({
	selector: 'personalization-modal',
	templateUrl: './personalizationModal.component.html',
	styleUrls: ['./personalizationModal.component.scss']
})
export class PersonalizationModalComponent implements OnInit {
	@Input() subMethodType: number;
	@Input() text: string;
	@Input() title: string;
	@Input() save?: (text: string) => void;
	@Input() showConditionalLanguage: boolean;
	isLoadTags: boolean;
	isLoadConditionalLanguage: boolean;
	conditionalLanguageSnippets: any;

	constructor(private templateConditionalLanguageService: TemplateConditionalLanguageService, private bsModalRef: BsModalRef) {}

	ngOnInit(): void {
		this.conditionalLanguageSnippets = this.templateConditionalLanguageService.getConditionalLanguageSnippets();
	}

	onTagChosen(tag: string): void {
		if (tag) {
			this.updateText(tag);
		}
		this.isLoadTags = false;
	}

	conditionalLanguageModalCallback(snippet: string) {
		if (snippet) {
			const snippetAsText = this.translateHtmlToText(snippet);
			this.updateText(snippetAsText);
		}
		this.isLoadConditionalLanguage = false;
	}

	onClose(): void {
		this.bsModalRef.hide();
	}

	onSave(): void {
		if (this.save) {
			this.save(this.text);
		}
		this.bsModalRef.hide();
	}

	private translateHtmlToText(html: string) {
		var tmp = document.createElement('div');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	}

	private updateText(tag: string): void {
		this.text = this.text ?? '';
		const currentLocation = (document.getElementById('text-text-textarea') as HTMLTextAreaElement).selectionStart;
		this.text = [this.text.slice(0, currentLocation), tag, this.text.slice(currentLocation)].join('');
	}
}
