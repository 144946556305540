import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AvailableIDSelectionValues } from '../../../models/templatePreview';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { TemplateContextService } from '../../../services/templateContext.service';
import { filter, switchMap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorMessage, TemplateTestEmailRequest, TemplateTestEmailResponse } from '../../../models/templateTestEmail';
import { TemplateDetails } from '../../../models/templateDetails';
import { Subject, Subscription } from 'rxjs';
import { TemplateSaveUpdateService } from '../../../services/templateSaveUpdate.service';
import { OptiLogicModalService } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageStatus } from '../../../models/languageStatus';

@Component({
	selector: 'sendTestModal',
	templateUrl: './sendTestModal.component.html',
	styleUrls: ['./sendTestModal.component.scss']
})
export class SendTestModalComponent implements OnInit, OnDestroy {
	sendTestRecipientsKey = 'optimailSendTestRecipientsEmails';

	@ViewChild('emailsInput') emailsInputRef: ElementRef<HTMLInputElement>;

	@Input() subMethodId: number;
	@Input() subMethodType: number;
	@Input() template: TemplateDetails;
	channelId: number;
	shouldDisplayVisitorOption: boolean = false;

	showSendTestError: boolean = false;
	isSendingData: boolean = false;
	shouldShowInvalidCustomerIdError: boolean = false;
	isLoaderDisabled: boolean = true;

	emails: string;
	emails$: Subject<string> = new Subject<string>();
	existingRecipientEmails: string[] = [];
	recipientsEmailsToSend: Set<string> = new Set<string>();

	selectedOption: AvailableIDSelectionValues = AvailableIDSelectionValues.RandomCustomerId;
	customerId: string;
	isShouldAddPrefix: boolean = true;

	subscriptions: Subscription = new Subscription();
	isTemplateDirty: boolean;
	isMultiLanguage: boolean;

	constructor(
		private manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private templateSaveUpdateService: TemplateSaveUpdateService,
		private modalService: OptiLogicModalService,
		private translateService: TranslateService,
		private modalRef: BsModalRef
	) {}

	ngOnInit(): void {
		this.updateScopeExistingRecipientsIDs();
		this.subscriptions.add(
			this.templateContextService.queryTemplateParams
				.pipe(
					filter((params) => !!params.channelId),
					switchMap((params) => {
						this.channelId = +params.channelId;
						return this.manageTemplatesService.getInfoForSendTestAndPreview(params.channelId);
					}),
					filter((sendAndPreviewInfo) => !!sendAndPreviewInfo)
				)
				.subscribe((sendAndPreviewInfo) => {
					this.shouldDisplayVisitorOption =
						sendAndPreviewInfo.SubMethodTypesThatSupportVisitors &&
						sendAndPreviewInfo.SubMethodTypesThatSupportVisitors.includes(this.subMethodId);
				})
		);
		this.subscriptions.add(
			this.emails$.subscribe(([oldValue, newValue]) => {
				this.emailsChanged(oldValue, newValue);
			})
		);
		
		this.isTemplateDirty = this.templateContextService.isDirty();
		this.isMultiLanguage = this.template.languages.length > 0;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public get availableIdSelectionValues(): typeof AvailableIDSelectionValues {
		return AvailableIDSelectionValues;
	}

	public emailsChanged(oldVal, newVal): void {
		this.showSendTestError = false;
		const curEmailsSet = this.getEmailsFromDelimitedString(this.emails);
		if (this.areSetsEqual(this.recipientsEmailsToSend, curEmailsSet)) {
			return;
		}

		const lastIndexOfCommaOldVal = oldVal && typeof oldVal == 'string' ? oldVal.lastIndexOf(',') : -1;

		if (lastIndexOfCommaOldVal >= 0) {
			const lastIndexOfCommaNewVal = newVal && typeof newVal == 'string' ? newVal.lastIndexOf(',') : -1;
			if (lastIndexOfCommaNewVal >= 0) {
				newVal = newVal.substr(lastIndexOfCommaNewVal + 1);
			}
			this.emails = `${oldVal.substr(0, lastIndexOfCommaOldVal)},${newVal}`;
		}

		this.recipientsEmailsToSend = this.getEmailsFromDelimitedString(this.emails);
	}

	public onCustomerIdChanged(): void {
		this.shouldShowInvalidCustomerIdError = false;
	}

	public onRecipientIdChange(): void {
		this.shouldShowInvalidCustomerIdError = false;
	}

	public focusEmailsInput() {
		const control = this.emailsInputRef.nativeElement;
		const pos = this.emails ? this.emails.length : 0;
		if (control.setSelectionRange) {
			control.focus();
			control.setSelectionRange(pos, pos);
		}
	}

	public send(): void {
		this.isSendingData = true;
		this.emails = this.emails.toLowerCase();
		if (!this.isValidInlineEmails(this.emails)) {
			this.showSendTestError = true;
			this.isSendingData = false;
			this.isLoaderDisabled = true;
			return;
		}

		const validRecipientsEmails = Array.from(this.recipientsEmailsToSend)
			.map((email) => email.replace(/\s/g, ''))
			.filter(this.isValidEmail.bind(this));
		this.addRecipientsEmailsToLocalStorage(validRecipientsEmails);
		this.emails = validRecipientsEmails.join(',');
		const templateJson = JSON.stringify(this.templateSaveUpdateService.deleteWhitespacesFromAtomicTags(this.template));
		this.manageTemplatesService
			.sendTestEmail(<TemplateTestEmailRequest>{
				channelID: this.channelId,
				emailAddresses: this.emails,
				templateJson: templateJson,
				idSelectionValue: this.selectedOption,
				isShouldAddPrefix: this.isShouldAddPrefix,
				customerId: this.customerId,
				containsNonVisitorTags: !this.shouldDisplayVisitorOption,
				isMultiLanguage: this.isMultiLanguage
			})
			.subscribe((response) => {
				this.isSendingData = false;
				this.isLoaderDisabled = false;
				const emailResponse = response as TemplateTestEmailResponse;
				if (emailResponse?.isSuccess === false) {
					if (emailResponse?.errorMsg === ErrorMessage.customerNotExists) {
						this.shouldShowInvalidCustomerIdError = true;
						this.showSendTestError = false;
					} else {
						this.showSendTestError = true;
					}
					return;
				}

				this.shouldShowInvalidCustomerIdError = false;
				this.showSendTestError = false;
				this.modalRef.hide();

				this.modalService.openSuccessFailModal('sm', {
					isSuccess: true,
					title: this.translateService.instant('features.manage_templates.components.sendTestMail.SUCCESS_VALIDATION'),
					message: this.translateService.instant('features.manage_templates.components.sendTestMail.SUCCESS_VALIDATION_TXT'),
					primaryButtonText: 'OK'
				});
			});
	}

	public close(): void {
		this.modalRef.hide();
	}

	private areSetsEqual(lhs, rhs) {
		return lhs.size === rhs.size && [...lhs].every((value) => rhs.has(value));
	}

	private updateScopeExistingRecipientsIDs(): void {
		const existingRecipientsEmails = JSON.parse(localStorage.getItem(this.sendTestRecipientsKey));
		this.existingRecipientEmails = existingRecipientsEmails ? Array.from(existingRecipientsEmails) : [];
	}

	private getEmailsFromDelimitedString(commaDelimitedRecipientsEmails) {
		let emails;
		if (commaDelimitedRecipientsEmails) {
			emails = new Set(commaDelimitedRecipientsEmails.split(',').filter((str) => /\S/.test(str)));
		} else {
			emails = new Set();
		}
		return emails;
	}

	private isValidInlineEmails(inlineEmails): boolean {
		let res = true;
		if (inlineEmails == '') {
			return true;
		}
		const emails = inlineEmails.split(',');
		for (let i = 0; i < emails.length; i++) {
			if (!res) {
				break;
			}
			const emailAfterSplit = emails[i].trim();
			const re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			res = re.test(emailAfterSplit);
		}
		return res;
	}

	private isValidEmail(email: string): boolean {
		const re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	private addRecipientsEmailsToLocalStorage(newRecipientEmails: string[]) {
		const existingRecipientsEmails = localStorage.getItem(this.sendTestRecipientsKey);
		let allRecipientsEmails = new Set();
		if (existingRecipientsEmails) {
			allRecipientsEmails = JSON.parse(existingRecipientsEmails);
		}
		allRecipientsEmails = new Set([...newRecipientEmails, ...allRecipientsEmails]);
		localStorage.setItem(this.sendTestRecipientsKey, JSON.stringify(allRecipientsEmails));
	}
}
