import { MicrofrontendModules } from '../microfrontend.constants';

export class RemoteModules {
  public static DATA_STUDIO_MODULE = {
    name: 'dataStudio',
    path: 'category/data_studio',
    options: MicrofrontendModules['dataStudio'],
  };

  public static DATA_INGESTION_MODULE = {
    name: 'dataIngestion',
    path: 'category/data___studio',
    options: MicrofrontendModules['dataIngestion'],
  };
  
  public static MODULES_COLLECTION = [this.DATA_STUDIO_MODULE, this.DATA_INGESTION_MODULE];
  
  public static MF_URLS_SESSION_STORAGE_KEY = "MicrofrontendUrls";
}
