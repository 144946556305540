import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from "rxjs";
import { OverflowMenuItem } from "./overflowMenuItem/models/overflowMenuItem.model";

@Component({
    selector: 'overflow-menu',
    templateUrl: 'overflowMenu.component.html',
    styleUrls: ['./overflowMenu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class OverflowMenuComponent implements OnDestroy {
    @Input() position: ConnectionPositionPair[];
    @Input() menuPopoverClass: string | string[] | Set<string> | { [klass: string]: any; };
    @Input() items: OverflowMenuItem[];
    @Input() overflowMenuButtonClasses: string | string[] | Set<string> | { [klass: string]: any; } = 'btn-link btn-link-default';
    @Input() overflowMenuButtonText: string;
    @Input() isArrowButtonVisible: boolean;
    @Output() menuItemClick = new EventEmitter<number>();
    @Output() menuButtonClick = new EventEmitter<boolean>();

    @ContentChild('overflowMenuItemTemplate') overflowMenuItemTemplate: TemplateRef<any>;
    @ViewChild('overflowMenuBtn', { static: false }) btnElement: ElementRef;
    @ViewChild('menu', { static: false }) menu: TemplateRef<any>;

    clickOutsideSubscription: Subscription;

    private popoverRef: OverlayRef;

    constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) { }

    public toggleMenu() {
        if (this.popoverRef && this.popoverRef.hasAttached()) {
            this.hidePopover();
            return;
        }

        this.show();
    }

    private show() {
        if (!this.popoverRef) {
            this.popoverRef = this.getPopoverConfig(this.btnElement);
        }
        
        if (this.popoverRef.hasAttached()) {
            return;
        }

        const menuTemplate = new TemplatePortal(
            this.menu,
            this.viewContainerRef
        );
        this.popoverRef.attach(menuTemplate);
        this.clickOutsideSubscription = this.popoverRef.outsidePointerEvents().subscribe(this.hidePopover.bind(this));

        this.menuButtonClick.emit(true);
    }

    private getPopoverConfig(btnElement: ElementRef): OverlayRef {
        const position = this.position ?? this.getDefaultPosition();
        
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(btnElement)
            .withPositions(position)
            .withPush(true);

        positionStrategy.detach = this.onOverflowMenuHidden.bind(this);

        return this.overlay.create({
            positionStrategy: positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });
    }

    private getDefaultPosition(): ConnectionPositionPair[] {
        return [
            new ConnectionPositionPair(
                { originX: 'end', originY: 'top' },
                { overlayX: 'start', overlayY: 'top' },
            ),
            new ConnectionPositionPair(
                { originX: 'end', originY: 'top' },
                { overlayX: 'start', overlayY: 'bottom' },
            ),
        ];
    }

    onOverflowMenuHidden() {
        this.btnElement.nativeElement.focus();
        this.menuButtonClick.emit(false);
        if (this.clickOutsideSubscription) {
            this.clickOutsideSubscription.unsubscribe();
        }
    }

    onMenuItemClicked(menuItem: OverflowMenuItem) {
        this.hidePopover();
        menuItem.callback();
    }

    hidePopover() {
        if (!this.popoverRef) {
            return;
        }

        this.popoverRef.detach();
    }

    ngOnDestroy() {
        this.hidePopover();
    }
}
