import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { BrandGroup } from "../../../../settings/preferenceCenter/models/brandGroup.model";

@Injectable({
    providedIn: 'root'
})

export class PreferenceHistoryService {

    private $selectedBrandGroup: Subject<BrandGroup> = new Subject<BrandGroup>();
    public selectedBrandGroup = this.$selectedBrandGroup.asObservable();

    private $selectedDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
    public selectedDate = this.$selectedDate.asObservable();

    public updateSelectedBrandGroup(brandGroup: BrandGroup) {
        this.$selectedBrandGroup.next(brandGroup);
    }

    public updateSelectedDate(date: Date) {
        this.$selectedDate.next(date);
    }
}
