import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from 'rxjs';
import { filter, map } from "rxjs/operators";
import { WindowRefService } from './../services/windowRef.service';
import { HttpService } from './optimove-http/optimove-http.model';

@Injectable({
  providedIn: 'root'
})
export class ZendeskWidgetService {
  public readonly tokenControllerURL = '/ZendeskWidget';
  constructor(
    private http: HttpService,
    private window: WindowRefService, ) {}

  public generateToken(): Observable<string> {
    let url = `${this.tokenControllerURL}/GetZendeskWidgetToken`;
      return this.http.get<AjaxResponse<string>>(url).pipe(
          map((res) => {
              return res.data;
          })
      );
  }

  public authenticateWidget(){
    let getRequest = () => this.generateToken();
    getRequest = getRequest.bind(this);
    this.window.nativeWindow.zESettings = {
       webWidget: {
        position:{horizontal: 'left', vertical: 'top'},
        authenticate: {
          jwtFn: function(callback) {
           getRequest().pipe(filter(token => !!token)).subscribe((token) => {
               callback(token);
             })
          }
        },
        helpCenter: {
          title: {
            '*': 'Optimove Academy'
          }  
        }
       }
    };
  }

  public setZendeskWidgetDefaultState() {
    if (this.isZendeskWidgetDefined()) {
      this.window.nativeWindow.offsetY = 0;
      this.window.nativeWindow.offsetX = 0;
      const zE = this.window.nativeWindow.zE;
      zE('webWidget', 'close');
      zE('webWidget', 'reset');
    }
  }

  public setHelpCenterSuggestions() {
    if (this.isZendeskWidgetDefined()) {
      const zE = this.window.nativeWindow.zE;
      if (zE?.setHelpCenterSuggestions) {
        zE.setHelpCenterSuggestions({url: true});
      }
    }
  }

  private isZendeskWidgetDefined(): boolean {
    return this.window.nativeWindow && this.window.nativeWindow.zE
  }

  public toggleZendeskWidget(){
    const zE = this.window.nativeWindow.zE;
    zE('webWidget', 'toggle');
  }
}
