import { Injectable } from "@angular/core";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";

@Injectable()
export class AndroidNotificationChannelsService {
    private baseUrl: string = '/ManageTemplates';

    constructor(private readonly httpClient: HttpService) { }

    public getOptipushChannels(): Observable<any> {
        return this.httpClient.get<AjaxResponse<any>>(`${this.baseUrl}/GetOptipushChannels`, {}).pipe(
            map(response => {
                if (response.data !== null && response.data !== undefined) {
                    return response.data;
                }
            })
        );
    }
}