import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { first } from 'rxjs/operators';
import { IAnalysisRange, IHeaderMetric } from '../../customer360.models';
import { Customer360DataService } from '../../services/customer360.data.service';
import { Customer360FormatService } from '../../services/customer360.format.service';


@Component({
	selector: 'customer-impact-metrics',
	templateUrl: './customerImpactMetrics.component.html',
	styleUrls: ['./customerImpactMetrics.component.scss']
})
export class CustomerImpactMetricsComponent implements OnChanges {

	@Input() dateRange: IAnalysisRange;
	@Input() clientCustomerId: string;
	@Output() onDataLoaded = new EventEmitter<void>();

	public headerAttributeMetric: IHeaderMetric;
	public metrics: IHeaderMetric[] = [];
	public isHeaderAttributeLoading: boolean = false;

	constructor(private dataService: Customer360DataService, private formatService: Customer360FormatService) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.clientCustomerId && this.dateRange?.startDate && this.dateRange?.endDate) {
			this.dataService.getCampaignKPIsDataByCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate).pipe(first()).subscribe((res) => {
				this.metrics = (res.Data.CampaignsKPIsData.KPIsHeadersList as Array<IHeaderMetric>);
				this.formatMetrics();
				this.onDataLoaded.emit();
			});
		}
	}

	private formatMetrics() {
		for (let i = 0; i < this.metrics.length; i++) {
			this.metrics[i] = this.formatService.formatMetric({ itemToformat: this.metrics[i], titleToUpperCase: false });
		}
	}
}


