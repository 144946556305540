import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PersonalizationTagsService} from "../personalizationTags.service";
import {
    AllCommunityModules,
    AllEnterpriseModules,
    CellClickedEvent,
    GridApi,
    GridOptions,
    ICellRendererParams,
    IServerSideDatasource,
    Module
} from "@ag-grid-enterprise/all-modules";
import {BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';
import {
    ContentTagModalComponent
} from '../../../features/manageTemplates/editors/dialogs/contentTagModal/contentTagModal.component';
import {OptiLogicModalService} from '../../optiLogicModal/optiLogicModal.service';
import {FeatureFlag, FeatureFlagService} from '../../../services/featureFlag.service';
import {PersonalizationTagsLoader} from "./personalizationTagsLoader/personalizationTagsLoader";

@Component({
    selector: 'personalization-tags-modal',
    templateUrl: './personalizationTagsModal.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./personalizationTagsModal.component.scss']
})

export class PersonalizationTagsModalComponent implements OnInit {
    @Input() submethodType: any;
    @Input() isBeeFree: boolean;
    @Input() onTagSelected?: (string) => void;
    public readonly pagingCount = 22;
    public datasource:any[] = ['random initial state'];
    public categories: any[];
    public searchFilter: string = '';
    public isGridReady: boolean = false;
    public modules: Module[] = [...AllCommunityModules,...AllEnterpriseModules];
    public filteredCategories: Set<string>;
    private gridApi: GridApi;
    private gridApi1: GridApi;
    modalRef: BsModalRef;
    public frameworkComponents;


    constructor(private personalizationTagsService: PersonalizationTagsService,
                private bsModalRef: BsModalRef,
                private modalService: OptiLogicModalService,
                private featureFlagService: FeatureFlagService
    ) {
        this.frameworkComponents = {
            loadingComponent: PersonalizationTagsLoader,
        };
        this.modalRef = bsModalRef;
        this.categoriesGridOptions.getRowStyle = function (params){
            if (!this.filteredCategories.has(params.data.category)) {
                return {'color': '#B0BEC5',  'pointer-events':'none'}
            }
            return null;

        }.bind(this);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.isGridReady = true;
        }, 750);
    }

    public isUseContentTagsEnabled(){
        return this.featureFlagService.isEnabled(FeatureFlag.UseContentTags);
    }

    public categoriesGridOptions = <GridOptions>{
        loadingCellRenderer: 'loadingComponent',
        columnDefs: [
            {headerName: 'Category', field: 'category', initialHide: false,  width: 186,
                cellStyle: {'font-weight':'bold'}},
        ],

        onCellClicked: (event: CellClickedEvent) =>
        {
            let datasource = this.createServerSideDataSource(this.submethodType,event.value, this.searchFilter);
            this.gridApi.setServerSideDatasource(datasource);
            this.gridApi.retryServerSideLoads();
        },
        headerHeight: 0,
        rowHeight: 32,
        rowClass: 'personalization-tag-category-row',
        animateRows: false,
        domLayout: 'autoHeight',
        rowSelection: 'single',
        suppressHorizontalScroll: true,

        onGridReady: (params) => {
            this.gridApi1 = params.api;
            this.createCategoriesSource();
        },
        getRowNodeId(data) {
            return data.id;
        },
    };

    public tagsGridOptions = <GridOptions>{
        columnDefs: [
            {
                headerName: 'Tags', field: 'tagFormatted',
                rowDrag: false, suppressMovable: true,
                width: 414,
                cellRenderer: function (params: ICellRendererParams) {
                    if (params.value !== undefined) {
                        return params.value;
                    }
                },
            },
        ],
        loadingCellRenderer: 'loadingComponent',
        overlayNoRowsTemplate: '<span class="overlay-no-rows">No personalization tags in this category</span>',
        headerHeight: 0,
        rowHeight: 24,
        rowClass: 'personalization-tag-row',
        animateRows: false,
        rowSelection: 'single',
        suppressHorizontalScroll: true,
        rowModelType: "serverSide",
        serverSideStoreType: "partial",
        blockLoadDebounceMillis: 1000, // debounce the loading to prevent blocks loading until scrolling has stopped
        cacheBlockSize: this.pagingCount, // how many rows returned from the server at a time
        cacheOverflowSize: 2, // extra blank rows to display to the user at the end of the dataset
        maxConcurrentDatasourceRequests: 1, // requests to hit the server with concurrently
        infiniteInitialRowCount: 2, // How many extra blank rows to display to the user at the end of the dataset
        maxBlocksInCache: this.pagingCount,

        onGridReady: (params) => {
            this.gridApi = params.api;
        },

        getRowClass: (params) => {
            return 'personalization-tag-row';
        }
    };

    public getCategoriesOnSearch(search){
        let brandType = this.submethodType.toString();
        this.personalizationTagsService.GetCategories(brandType, search).subscribe(res => {
            this.filteredCategories = new Set<string>();
            res.Rows.forEach(category=>
            {
                this.filteredCategories.add(category)
            });
            this.gridApi1.redrawRows();
            if (this.filteredCategories.size > 0) {
                let currentCat = this.filteredCategories.values().next().value;
                this.gridApi1.getRowNode(currentCat).setSelected(true);
                let datasource = this.createServerSideDataSource(this.submethodType,currentCat,search);
                this.gridApi.setServerSideDatasource(datasource);
                this.gridApi.retryServerSideLoads();
            } else {
                var dataSource = this.createServerSideDataSource(this.submethodType,"",search);
                this.gridApi.setServerSideDatasource(dataSource);
            }
        });
    }

    public createCategoriesSource():any{
        let brandType = this.submethodType.toString();
        this.personalizationTagsService.GetCategories(brandType,'').subscribe(res => {
            let categories = [];
            this.filteredCategories = new Set<string>();
            res.Rows.forEach(category => {
                categories.push({
                    id: category,
                    category: category
                })
                this.filteredCategories.add(category);
                this.gridApi1.redrawRows();
            });
            this.gridApi1.setRowData(categories);
            this.gridApi1.getRowNode(categories[0].category).setSelected(true);
            let datasource = this.createServerSideDataSource(this.submethodType, categories[0].category, "");
            this.gridApi.setServerSideDatasource(datasource);
            this.categories = categories;
        });
    }


    public createServerSideDataSource(submethodType:number, category, search, count:number = 23):IServerSideDatasource{
        return {
            getRows: (params) => {
                console.log('[Datasource] - rows requested by grid: ', params.request);
                const {startRow, endRow } = params.request;
                this.personalizationTagsService.GetPersonalizationTagsForInfinityScroll(search,submethodType,category,startRow,count)
                    .subscribe(res => {
                        if(!res)
                            params.fail();
                        let tags = [];
                        res.Rows.forEach(tag => {
                            tags.push({
                                tag: tag,
                                tagFormatted: tag.slice(2,-2),
                                isCategory: false,
                                category: category
                            });
                        });
                        this.datasource = [...tags]
                        if(res.Rows.length > 0) {
                            params.success({
                                rowData: tags,
                                rowCount:  endRow - this.pagingCount + res.Rows.length
                            })
                        }
                    });
            },
        };
    }

    public onSelectionChanged(e: any): void {
        let selectedRows = this.gridApi.getSelectedRows();
        if (selectedRows && selectedRows[0]) {
            let row = selectedRows[0];
            this.updateChosenTag(row.tag);
        }
    }

    public onModelUpdated(e: any): void {
        if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
            this.gridApi.showNoRowsOverlay();
        }
        if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
            this.gridApi.hideOverlay();
        }
    }

    public onSearchChange(value: string) {
        if(value.length >= 3 || value == "") {
            this.datasource = ['randon init'];
            this.searchFilter = value;
            this.getCategoriesOnSearch(value);
        }
    }

    public openContentTagModal(): void{
        this.bsModalRef = this.modalService.open(ContentTagModalComponent, 'lg', <ModalOptions<any>>{
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
                onChosen: (tag: string) => {
                    this.updateChosenTag(tag);
                    this.bsModalRef.hide();
                },
                onClose: () => {
                    this.updateChosenTag('');
                    this.bsModalRef.hide();
                }
            }
        });
    }

    private updateChosenTag(tag: string) {
        if (this.onTagSelected)
            this.onTagSelected(tag);
        else
            this.personalizationTagsService.updateChosenTag(tag);
    }
}
