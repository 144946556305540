import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ValidationOutput } from '../../../models/templateSaveUpdate';

@Component({
	selector: 'validate-personalization-tags',
	templateUrl: './validatePersonalizationTags.component.html',
	styleUrls: ['./validatePersonalizationTags.component.scss']
})
export class ValidatePersonalizationTagsComponent {
	@Input() isExecutedInvalid: boolean;
	@Input() validationOutput: ValidationOutput;
	@Input() isOtherEditor: boolean;

	constructor(private bsModalRef: BsModalRef) {}

	getLineNumberString(errorContentType: string, errorLinenumber: number): string {
		if (errorContentType == 'PlainText') return 'Plain text';
		if (errorContentType == 'Subject') return 'Subject line';
		if (errorContentType == 'Preheader') return 'Preview text';
		if (errorContentType == 'FromName') return 'From name';
		if (errorContentType == 'DeepLinks') return 'Deep links';
		if (this.isOtherEditor) return 'Content';
		if (errorLinenumber == null || errorLinenumber == -1) return '';
		return 'Line ' + errorLinenumber;
	}

	close(): void {
		this.bsModalRef.hide();
	}
}
