import { AgFilterComponent } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { AttributeTypeOptions } from "../../attributesList.model";
import { AttributeHelper } from "../../attributesList.helper";

@Component({
  selector: "type-filter",
  templateUrl: "./typeFilter.component.html"
})
export class TypeFilterComponent implements AgFilterComponent {
  params: IFilterParams;
  type: AttributeTypeOptions;
  isSupportedByRealtime: boolean = true;
  isSupportedByAPI: boolean = true;
  isCalculated: boolean = true;
  //isFormulaBased: boolean = true;
  isDefault: boolean = true;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.type = params.context.data;
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    this.type = AttributeHelper.getFullAttributeType(params.data);
    return (this.type.isAPI && this.isSupportedByAPI)
          || (this.type.isBase && this.isDefault)
          || (this.type.isRT && this.isSupportedByRealtime)
          || (this.type.isCalculated && this.isCalculated);
  }

  getModel() {}

  setModel(model: any) {}

  updateFilter() {
    this.params.filterChangedCallback();
  }
}