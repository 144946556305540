import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClientModule, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { APP_INITIALIZER, ApplicationRef, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ColorsService } from '@optimove/ui-sdk/common/services';
import { MultiTranslateHttpLoader } from '@optimove/ui-sdk/common/translation';
import { AngularSplitModule } from "angular-split";
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CookieService } from 'ngx-cookie-service';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { setAppInjector } from '../app-injector';
import { AppComponent } from "./app.component";
import { AcademyLinksService } from "./components/academyLinks/academyLinks.service";
import { ComponentsModule } from './components/components.module';
import { LoaderComponent } from './components/loader/loader.component';
import { ApiErrorHandlingService } from './errorHandling/apiErrorHandling.service';
import { AudienceDiscoveryEditTargetGroupComponent } from './features/audienceDiscovery/audience-discovery-edit-target-group/audience-discovery-edit-target-group.component';
import { AudienceDiscoveryComponent } from './features/audienceDiscovery/audienceDiscovery.component';
import { InfoAlertComponent } from './features/audienceDiscovery/infoAlert/infoAlert.component';
import { BiStudioModule } from './features/biStudio/biStudio.module';
import { BiStudioService } from './features/biStudio/services/biStudio.service';
import { CampaignAnalysisModule } from './features/campaignAnalysis/campaignAnalysis.module';
import { Customer360Module } from './features/customer360/customer360.module';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { DataIngestionComponent } from './features/dataIngestion/dataIngestion.component';
import { ImportedCustomersMFComponent } from './features/importedCustomers/importedCustomers.component';
import { ManageTemplatesModule } from './features/manageTemplates/manageTemplates.module';
import { OptiPromoComponent } from './features/optiPromo/optiPromo.component';
import { OptiPushModule } from './features/optiPush/optiPush.module';
import { OptibotNotificationService } from './features/optibot/services/optibotNotification.service';
import { RealtimeAttributesMFComponent } from './features/realtimeAttributes/realtimeAttributes.component';
import { SettingsModule } from './features/settings/settings.module';
import { NavbarModule } from './modules/navbar.module';
import { NewNavbarModule } from './modules/navbar/newNavbar.module';
import { FormatterPipe } from "./pipes/formatter.pipe";
import { PipesModule } from "./pipes/pipes.module";
import { AngularRouterUIRouterAdapter } from './routing/angularRouterUIRouterAdapter.service';
import { AppRoutingModule } from './routing/app.routing';
import { AudienceDiscoveryGuard } from './routing/guard/audienceDiscovery.guard';
import { ImportedCustomersGuard } from './routing/guard/importedCustomers.guard';
import { RealtimeAttributesGuard } from './routing/guard/realtimeAttributes.guard';
import { RouteRemoteWrapperComponent } from './routing/route-remote-wrapper.component';
import { AddKPIsService } from './services/addKPIs.service';
import { AlertsService } from './services/alerts.service';
import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { FileService } from './services/file.service';
import { FullPageLoaderService } from "./services/fullPageLoader.service";
import { GlobalErrorHandlerService } from './services/globalErrorHandler.service';
import { InternalFeatureFlagService } from "./services/internalFeatureFlag.service";
import { MailService } from './services/mail.service';
import { NavbarWrapperService } from './services/navbarWrapper.service';
import { HttpService } from './services/optimove-http/optimove-http.model';
import { HttpServiceModule } from './services/optimove-http/optimove-http.module';
import { OptitrackConfigurationService } from "./services/optitrackConfiguration.service";
import { PendoEventsService } from "./services/pendoEvents.service";
import { RedirectHandlerService } from './services/redirectHandler.service';
import { RedirectToAuthService } from './services/redirectToAuth.service';
import { SerializationService } from "./services/serialization.service";
import { SessionStorageService } from './services/sessionStorage.service';
import { SignalrService } from './services/signalr.service';
import { SsmService } from "./services/ssm.service";
import { UtilsMethodsService } from './services/utilsMethods.service';
import { WindowRefService } from "./services/windowRef.service";
import { ZendeskWidgetService } from './services/zendeskWidget.service';
import { RemoteComponentLoader } from './utils/remoteModules/remote-component-loader';

declare const VERSION: string;

const FB_CLIENT_ID = "270947323081880"; 
export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json?version=' + VERSION},
        { prefix: './assets/optimove/ui-sdk/i18n/', suffix: '.json'}
    ]);
}

function getRedirectHandlerService(i) {
    return i.get('redirectHandlerService');
}

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        OptiPushModule,
        NavbarModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        FormsModule,
        HttpClientModule,
        PipesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            },
            isolate: true
        }),
        BsDatepickerModule.forRoot(),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        AngularSplitModule.forRoot(),
        CampaignAnalysisModule,
        SettingsModule,
        ManageTemplatesModule,
        SocialLoginModule,
        Customer360Module,
        NewNavbarModule,
        BiStudioModule,
        HttpServiceModule
    ],
    declarations: [
        AppComponent,
        LoaderComponent,
        RouteRemoteWrapperComponent,
        RemoteComponentLoader,
        AudienceDiscoveryComponent,
        ImportedCustomersMFComponent,
        RealtimeAttributesMFComponent,
        DataIngestionComponent,
        AudienceDiscoveryEditTargetGroupComponent,
        InfoAlertComponent,
        DashboardComponent,
        OptiPromoComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: RedirectToAuthService) => { 
                return () => { 
                    let loadApp$: Observable<boolean> = authService.isLoggedIn().pipe(
                        catchError((error: HttpErrorResponse) => {
                            if (error.status !== HttpStatusCode.Unauthorized) {
                                authService.login();
                            }
                           
                            return throwError(error);
                        })
                    );
                    let params = new URLSearchParams(window.location.search);
                    const authCode = params.get(RedirectToAuthService.authCodeKey);
                    if (authCode != null) {
                        let url = new URL(window.location.href);
                        url.searchParams.delete(RedirectToAuthService.authCodeKey); 
                        window.history.replaceState({}, '', url.toString());
                        return authService.exchangeTokenAndInitLoginParameters(authCode);
                    }

                    if (localStorage.getItem('refreshToken') === null) {
                        loadApp$ = NEVER;
                        authService.login();
                    }

                    if (!HttpService.environment.isMonolith) {
                        const apiServerConfigKey = 'apiServerConfig';
                        const prevApiServerConfig = localStorage.getItem(apiServerConfigKey);
                        const currentApiServerConfig = HttpService.environment.apiServer;
                        if (prevApiServerConfig && prevApiServerConfig !== currentApiServerConfig) {
                            localStorage.removeItem(apiServerConfigKey);
                            loadApp$ = NEVER;
                            authService.login();
                        }
                        localStorage.setItem(apiServerConfigKey, currentApiServerConfig);
                    }

                    return loadApp$;
                }
            },
            deps: [RedirectToAuthService],
            multi: true
        },
        Title,
        PendoEventsService,
        SerializationService,
        NavbarWrapperService,
        SsmService,
        FormatterPipe,
        WindowRefService,
        SessionStorageService,
        OptitrackConfigurationService,
        AngularRouterUIRouterAdapter,
        {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
        SignalrService,
        AlertsService,
        ColorsService,
        FullPageLoaderService,
        AcademyLinksService,
        DateService,
        CookiesService,
        UtilsMethodsService,
        FileService,
        MailService,
        RedirectHandlerService,
        CookieService,
        BiStudioService,
        RedirectToAuthService,
        ZendeskWidgetService,
        {provide: 'redirectHandlerService', useFactory: getRedirectHandlerService, deps: ['$injector']},
        {provide: 'navbarUtilityService', useFactory: i => i.get('navbarUtilityService'), deps: ['$injector']},
        {provide: '$scope', useExisting: '$rootScope'},
        {provide: '$timeout', useFactory: i => i.get('$timeout'), deps: ['$injector']},
        {provide: 'ssmService', useFactory: i => i.get('ssmService'), deps: ['$injector']},
        { provide: 'runService', useFactory: i => i.get('runService'), deps: ['$injector'] },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(FB_CLIENT_ID),
                    },
                ]
            } as SocialAuthServiceConfig,
        },
        OptibotNotificationService,
        ApiErrorHandlingService,
        AddKPIsService,
        AudienceDiscoveryGuard,
        ImportedCustomersGuard,
        RealtimeAttributesGuard,
        InternalFeatureFlagService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(private injector: Injector) { }

    ngDoBootstrap(applicationRef: ApplicationRef) {
        setAppInjector(this.injector)
        applicationRef.bootstrap(AppComponent);
    }
}