<div class="biStudioLandingPage">
    <div class="row">
        <div class="col-md-offset-2 col-md-4">
            <div *ngIf="bistudioSendMail">
                <h1>{{'components.biLandingPage.TITLE'|translate}}</h1>
                <h2 class="bi-description h2-lite-txt">
                    {{'components.biLandingPage.PRODUCT_DESCRIPTION'|translate}}
                </h2>

                <h2 class="bi-example-headder">{{'components.biLandingPage.EXAMPLE_TITLE'|translate}}
                </h2>
                <ul class="bi-example-list">
                    <li>
                        <p class="h2-lite-txt">{{'components.biLandingPage.EXAMPLE_LIST.MANAGEMENT'|translate}}</p>
                    </li>
                    <li>
                        <p class="h2-lite-txt">{{'components.biLandingPage.EXAMPLE_LIST.PERIODIC'|translate}}</p>
                    </li>
                    <li>
                        <p class="h2-lite-txt">{{'components.biLandingPage.EXAMPLE_LIST.CAMPIGN_ANALYSIS'|translate}}
                        </p>
                    </li>
                </ul>
                <h2 class="h2-lite-txt bi-more-info">{{'components.biLandingPage.RECIVE_INFORMATON'|translate}}
                </h2>
                <button type="button" class="btn btn-lg btn-grape btn-bi-style" (click)="sendMail()"
                    translate>{{'components.biLandingPage.INTERESTED'|translate}}
                </button>
            </div>

            <div *ngIf="!bistudioSendMail">
                <h1 class="header">
                    {{'components.biLandingPage.THANK_YOU'|translate}}<br>{{'components.biLandingPage.PRODUCT_NAME'|translate}}
                </h1>

                <h2 class="h2-lite-txt">{{'components.biLandingPage.WILL_CONTACT_YOU'|translate}}</h2>

            </div>
        </div>
        <div class="col-md-4">
            <img class="bi-studio-svg" src="assets/images/BI-Studio-LP.svg">
        </div>
        <div class="col-md-2">
        </div>
    </div>
</div>