import { Component, Input, OnInit } from '@angular/core';
import { ToastModel } from '../../../../../missionControl/models/toast.model';
import { locationParameter } from '../../../../../../models/locationParameter.enum';
import { IconType } from '@optimove/ui-sdk/common/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: "path-builder",
  templateUrl: "pathBuilder.component.html",
  styleUrls: ["pathBuilder.component.scss"],
})

export class PathBuilderComponent {

  @Input() pathItems: string[];
  public sortPredicateForDisabledItem(items: string[]) {
    return (index: number) => {
      return index !== items.length - 1;
    };
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
  }
}