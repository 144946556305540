<div class='new-topic'>
  <div class="panel-heading">
    <div class='header-topic'>
      <button class='btn btn-link back-pref' (click)='navigateToPreferenceCenter()'>
        <i class='material-icons back-icon'>arrow_back</i>
        {{ 'general.BACK' | translate }}
      </button>
      <h2 *ngIf='!isEditMode'>{{ 'features.user_settings.body.preference_center.NEW_TOPIC_HEADER' | translate }}</h2>
      <h2 *ngIf='isEditMode'>{{ 'features.user_settings.body.preference_center.UPDATE_TOPIC_HEADER' | translate }}</h2>
    </div>
  </div>
  <div class="panel-body">
    <div class="form">
      <div class='form-input'>
        <p class='input-value'>{{ 'features.user_settings.body.preference_center.TOPIC_NAME' | translate }}</p>
        <div class="input-wrap">
          <input maxlength="15" [(ngModel)]="topic.name" [ngClass]="{'error-border': topic.name === ''}" type="text" placeholder="Enter a unique topic name"
                  class="form-control value-column"/>
        </div>
      </div>
      <div class='form-input'>
        <p class='input-value'>{{ 'features.user_settings.body.preference_center.DESCRIPTION' | translate }}</p>
        <div class="input-wrap">
          <textarea maxlength="50" [(ngModel)]="topic.description" [ngClass]="{'error-border': topic.description === ''}" type="text"
                    class="form-control value-column description"></textarea>
        </div>
      </div>
      <div *ngIf='isEditMode' class='form-input details'>
        <p class='input-value-details'>{{ 'features.user_settings.body.preference_center.DETAILS' | translate }}</p>
        <div class="input-wrap">
          <input readonly value="{{'features.user_settings.body.preference_center.TOPIC_ID' | translate }}                  {{topic.id}}" type="text"
                    class="form-control value-column id-details" />
        </div>
        <div class='copy-id-button-wrap'>
          <button class="copy-id-button" (click)='copyId()'>
           <i class='material-icons copy-id'>content_copy</i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class='footer-buttons'>
      <div class='delete-button-wrapper'>
        <div *ngIf='isEditMode'>
          <button class="delete" (click)='deleteTopic()'>{{ 'features.user_settings.body.preference_center.DELETE_TOPIC' | translate }}</button>
        </div>
      </div>
      <div class="controls col-md-6 pull-right text-right buttons-footer">
        <ul class="list-inline">
          <li>
            <div class="btn btn-default" (click)="navigateToPreferenceCenter()">
              <span>{{ 'general.CANCEL' | translate }}</span>
            </div>
          </li>
          <li>
            <div>
              <loading-button
                [showLoader]="isSaving"
                (onClick)="addUpdateTopic()"
                [buttonCssClass]="'btn btn-primary'"
                [isDisabled] = " !topic.name || topic.name === '' || !topic.description || topic.description === ''"
                [loadingCssClass]="'saving-loader'"
                [loadingText]="isEditMode ? 'Updating' : 'Saving'"
                [buttonText]="isEditMode ? 'Update' : 'Save'">
              </loading-button>
            </div>
          </li>
        </ul>
      </div>
  </div>
  </div>
</div>