import {Inject, Injectable} from "@angular/core";


@Injectable()
export class RedirectHandlerService {
    constructor(@Inject('redirectHandlerService') private redirectService: any){}

    public createAddedConditionUI(conditionType,
        position,
        paramsObject,
        typeDescription,
        descriptionFormat,
        textsObject):any {
        return this.redirectService.createAddedConditionUI(conditionType,
            position,
            paramsObject,
            typeDescription,
            descriptionFormat,
            textsObject);
    }

    public createRedirectFromPageConditionUI(position,
                                             section,
                                             paramsObject,
                                             textsObject):any{
        return this.redirectService.createRedirectFromPageConditionUI(position,
            section,
            paramsObject,
            textsObject);
    }
}