import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { EmailPreview, ErrorResponse, isErrorResponse } from 'src/app/features/manageTemplates/models/emailPreview';
import { TemplateDetails } from 'src/app/features/manageTemplates/models/templateDetails';
import { ManageTemplatesService } from 'src/app/features/manageTemplates/services/manageTemplates.service';

class EmailPreviewViewModel implements EmailPreview {
	fullUrl: string;
	thumbnailUrl: string;
	clientName: string;
	clientIdentifier: string;
	isLoaded: boolean;
	isFailed: boolean;
}

@Component({
	selector: 'preview-template-instant',
	templateUrl: './previewTemplateInstant.component.html',
	styleUrls: ['./previewTemplateInstant.component.scss']
})
export class PreviewTemplateInstantComponent implements OnInit, OnChanges {
	private retryTimeout = 500;
	private retryAttempts = 20;

	@Input() template: TemplateDetails;
	@Input() evaluatedTemplateBody: string;

	clients: EmailPreviewViewModel[] = [];
	selectedClient: EmailPreviewViewModel;
	imageLoadRetries: Map<string, number> = new Map<string, number>();

	constructor(
		private cd: ChangeDetectorRef,
		private manageTemplatesService: ManageTemplatesService,
		private optiModalService: OptiLogicModalService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.loadInstantPreview();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.evaluatedTemplateBody.previousValue === changes.evaluatedTemplateBody.currentValue) return;

		this.loadInstantPreview();
	}

	back(): void {
		this.selectedClient = null;
	}

	loadInstantPreview(): void {
		if (!this.template) return;

		this.manageTemplatesService
			.getEmailPreview({
				fromName: this.template.fromName,
				html: this.evaluatedTemplateBody,
				subject: this.template.subject
			})
			.subscribe((emailClients) => {
				this.imageLoadRetries.clear();
				if (isErrorResponse(emailClients as unknown as ErrorResponse)) {
					this.optiModalService.openModalMessage('sm', {
						message: this.translate.instant('features.manage_templates.components.optimailPreview.clients.CANNOT_LOAD'),
						buttons: [
							{
								class: 'btn-primary',
								label: this.translate.instant('general.OK'),
								action: () => {}
							}
						]
					});
					return;
				}
				this.clients = emailClients.map((client) => {
					const result = { ...client } as EmailPreviewViewModel;

					new Observable((observer: Observer<string>) => {
						this.tryLoadImage(client.fullUrl, observer);
					}).subscribe(
						(imageUrl) => {
							result.fullUrl = imageUrl;
							result.isLoaded = true;
						},
						() => {
							result.isFailed = true;
						}
					);

					return result;
				});
				this.cd.detectChanges();
			});
	}

	private tryLoadImage(imageUrl: string, observer: Observer<string>): void {
		const img = new Image();
		img.onload = (aaa: Event): any => {
			observer.next(imageUrl);
		};
		img.onerror = () => {
			const retryCount = (this.imageLoadRetries.get(imageUrl) ?? 0) + 1;
			if (retryCount < this.retryAttempts) {
				this.imageLoadRetries.set(imageUrl, retryCount);
				setTimeout(() => this.tryLoadImage(imageUrl, observer), this.retryTimeout);
			} else {
				observer.error(imageUrl);
			}
		};
		img.src = imageUrl;
	}
}
