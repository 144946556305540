import { Injectable } from '@angular/core';
import { SsmService } from './ssm.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
    private ssm: any;

    constructor(private ssmService: SsmService) {
    	this.ssm = this.ssmService.getSSM();
    }

    public isEnabled(flag: FeatureFlag) {
    	const featureFlags = this.ssm.GetGeneric(this.ssm.Resx.FeatureFlags);
    	return featureFlags[flag] !== undefined && featureFlags[flag] === true;
    }
}

export enum FeatureFlag {
    UseNewPreferenceCenterHistoryModal = 'UseNewPreferenceCenterHistoryModal',
    UseContentTags = 'UseContentTags',
    skipCsvAndConditionalExecutionValidation = 'skipCsvAndConditionalExecutionValidation',
    isPersonalisationTagAttribute = 'isPersonalisationTagAttribute',
    UseConsent = 'UseConsent',
    useAuditByEmail = 'useAuditByEmail',
    SojAndLeadTimeValidation = 'SojAndLeadTimeValidation',
    multipleConditionAttribute = 'multipleConditionAttribute',
    CohortsAnalysisReport = 'CohortsAnalysisReport',
    ShowBusinessPerformanceOverviewReport = 'ShowBusinessPerformanceOverviewReport',
    uniquePromoCodes = 'UniquePromoCodes',
    TheNewDashboard = 'TheNewDashboard',
    showOrchestrationOverviewPermission = 'showOrchestrationOverviewPermission',
    StreamsMultipleTreatmentsAndAnalysis = 'StreamsMultipleTreatmentsAndAnalysis',
    BeefreeEditorForPopup = 'BeefreeEditorForPopup',
    ManageAPI = 'ManageAPI',
    openApiKeyManagement = 'openApiKeyManagement',
    PurchaseHistoryAttributesNewScenario = 'PurchaseHistoryAttributesNewScenario',
    AudienceDiscoveryPage = 'AudienceDiscoveryPage',
    CEIC = 'CEIC',
    TriggeredCampaignBuilderContactLabels = 'TriggeredCampaignBuilderContactLabels',
    ScheduledCampaignBuilderContactLabels = 'ScheduledCampaignBuilderContactLabels',
    TemplatesOptimailMultiLanguageFlow = 'TemplatesOptimailMultiLanguageFlow',
    DynamicMailBeefreeAddon = 'DynamicMailBeefreeAddon',
    MissionControlMultiDuplicateDrafts = 'MissionControlMultiDuplicateDrafts',
    OptiGenieAMA = 'OptiGenieAMA',
    MissionControlGroupBy = 'MissionControlGroupBy',
    OptiXBeefreeAddon = 'OptiXBeefreeAddon',
    SelfOptimizingStreams = 'SelfOptimizingStreams',
    DataIngestionPage = 'DataIngestionPage',
    ShowRepeatRateAnalysisReport = 'ShowRepeatRateAnalysisReport',
    ShowGamingBenchmarkReport = 'ShowGamingBenchmarkReport',
    TemplatesSearchTranslations = 'TemplatesSearchTranslations',
    TemplatesEnableFallbackLanguage = 'TemplatesEnableFallbackLanguage',
    RealtimeProfileDataManagementForUser = 'RealtimeProfileDataManagementForUser',
    RealtimeProfileDataManagement = 'RealtimeProfileDataManagement',
    Exclude_PH_Attributes = 'Exclude_PH_Attributes',
    TemplatesUseOptimailServiceForSave = 'TemplatesUseOptimailServiceForSave',
    TriggeredCBConditionalExecution = 'TriggeredCBConditionalExecution',
    OptiPromo = 'OptiPromo',
    PromotionModal = 'PromotionModal',
    MCtoUseCampaignStatusService = 'MCtoUseCampaignStatusService',
    OptiWebInboxSettings = 'OptiWebInboxSettings'
}
