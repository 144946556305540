import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { SharedModule } from '../../shared.module';
import { OptiModalModule } from '../../modules/OptiModal.module';
import { TemplateScriptGuardContainerComponent } from './TemplateScriptGuardContainer/templateScriptGuardContainer.component';
import { TemplateScriptGuardModalComponent } from './TemplateScriptGuardContainer/TemplateScriptGuardModal/templateScriptGuardModal.component';
import { CopyTemplateContainerComponent } from './copyTemplateContainer/copyTemplateContainer.component';
import { CopyTemplateModalComponent } from './copyTemplateContainer/copyTemplateModal/copyTemplateModal.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { TemplateScriptGuardModalService } from './TemplateScriptGuardContainer/TemplateScriptGuardModal/templateScriptGuardModal.service';
import { CopyTemplateModalService } from './copyTemplateContainer/copyTemplateModal/copyTemplateModal.service';
import { ManageTemplatesRoutingModule } from './manageTemplates.route';
import { ManageTemplatesPageComponent } from './manageTemplatesPage/manageTemplatesPage.component';
import { MasterPanelComponent } from './masterPanel/masterPanel.component';
import { DetailsPanelComponent } from './detailsPanel/detailsPanel.component';
import { MasterDetailContainerComponent } from './masterDetailContainer/masterDetailContainer.component';
import { OptimailEditorComponent } from './editors/optimailEditor/optimailEditor.component';
import { EditorHeaderComponent } from './editors/editorHeader/editorHeader.component';
import { EditorBodyComponent } from './editors/editorBody/editorBody.component';
import { TemplatePreviewCardComponent } from './folderTemplatePreviewCard/templatePreviewCard.component';
import { TemplatesHeaderComponent } from './templatesHeader/templatesHeader.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { AgGridModule } from '@ag-grid-community/angular';
import { LoadingTemplateCartComponent } from './loadingTemplateCart/loadingTemplateCart.component';
import { SmartPreviewTableComponent } from './smartPreviewTable/smartPreviewTable.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NewFolderModalComponent } from './nestedFoldersPanel/newFolderModal/newFolderModal.component';
import { NewTemplateModalComponent } from './nestedFoldersPanel/newTemplateModal/newTemplateModal.component';
import { FolderRenameComponent } from './nestedFoldersPanel/folderRename/folderRename.component';
import { FolderDeleteComponent } from './nestedFoldersPanel/folderDelete/folderDelete.component';
import { ArrowsScrollComponent } from './arrowScroll/arrowsScroll.component';
import { BeefreeContainerComponent } from './editors/beefreeContainer/beefreeContainer.component';
import { BeefreePopupContainerComponent } from './editors/beefreePopupContainer/beefreePopupContainer.component';
import { TemplateNameValidatorDirective } from './editors/validators/templateNameValidator.directive';
import { UtmParamsComponent } from './editors/dialogs/utmParamsModal/utmParams.component';
import { DetailsPanelHeaderComponent } from './detailsPanel/detailPanelHeader/detailsPanelHeader.component';
import { SaveRowModalComponent } from './editors/dialogs/saveRowModal/saveRowModal.component';
import { ValidationErrorModelComponent } from './editors/dialogs/validationErrorModel/validationErrorModel.component';
import { ValidatePersonalizationTagsComponent } from './editors/dialogs/validatePersonalizationTags/validatePersonalizationTags.component';
import { EditRowModalComponent } from './editors/dialogs/editRowModal/editRowModal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PersonalizationModalComponent } from './editors/dialogs/personalizationModal/personalizationModal.component';
import { TemplatePreviewModalComponent } from './editors/dialogs/templatePreviewModal/templatePreviewModal.component';
import { PreviewTemplateWithDataComponent } from './editors/dialogs/templatePreviewModal/previewTemplateWithData/previewTemplateWithData.component';
import { PreviewTemplateInstantComponent } from './editors/dialogs/templatePreviewModal/previewTemplateInstant/previewTemplateInstant.component';
import { MoveTemplateModalComponent } from './folderTemplatePreviewCard/modals/moveTemplateModal/moveTemplateModal.component';
import { EditPlainTextModalComponent } from './editors/dialogs/editPlainTextModal/editPlainTextModal.component';
import { CopyToTemplateModalComponent } from './folderTemplatePreviewCard/modals/copyToTemplateModalComponent/copyToTemplateModal.component';
import { TemplateInUseModalComponent } from './folderTemplatePreviewCard/modals/templateInUseModal/templateInUseModal.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SendTestModalComponent } from './editors/dialogs/sendTestModal/sendTestModal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TemplateEditorGuard } from './editors/templateEditor.guard';
import { MoveFolderModalComponent } from './folderTemplatePreviewCard/modals/moveFolderModal/moveFolderModal.component';
import { MoveListsComponentComponent } from './folderTemplatePreviewCard/modals/moveListsComponent/moveListsComponent.component';
import { TextareaAutoresizeDirective } from './directives/textAreaResize.derictive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OptimobileEditorComponent } from './editors/optimobileEditor/optimobileEditor.component';
import { UnsubscribeTagsModalComponent } from './editors/dialogs/unsubscribeTagsModal/unsubscribeTagsModal.component';
import { ContentTagModalComponent } from './editors/dialogs/contentTagModal/contentTagModal.component';
import { ContentTagModalContainerComponent } from './editors/dialogs/contentTagModal/container/contentTagModalContainer.component';
import { InsertLinkModalComponent } from './editors/dialogs/insertLinkModal/insertLinkModal.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ContentTagNameValidatorDirective } from './editors/validators/contentTagNameValidator.directive';
import { UpdateContentTagModalComponent } from './editors/dialogs/updateContentTagModal/updateContentTagModal.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ShowTooltipOnElipsisDirective } from './directives/showTooltipOnElipsis.directive';
import { DragComponent } from './dragComponent/drag.component';
import { FolderPopupButtonComponent } from './folderPopup/folderPopupButton/folderPopupButton.component';
import { FolderPopupOverlayComponent } from './folderPopup/folderPopupOverlay/folderPopupOverlay.component';
import { TranslationPanelComponent } from './editors/panels/translationPanel/translationPanel.component';
import { TranslationLanguagesComponent } from './editors/dialogs/translationLanguagesModal/translationLanguages.component';
import { TemplateBatchInUseModalComponent } from './templatesHeader/modals/templateBatchInUseModal/templateBatchInUseModal.component';
import { DefaultLanguageModalComponent } from './editors/dialogs/defaultLanguageModal/defaultLanguageModal.component';
import { ElementClickTrackerDirective } from './directives/elementClickTracker.directive';
import { TemplateMetadataPanelComponent } from './editors/panels/templateMetadataPanel/templateMetadataPanel.component';
import { SearchTranslationsPanelComponent } from './editors/panels/searchTranslationsPanel/searchTranslationsPanel.component';
import { SelectFallbackLanguageComponent } from './editors/dialogs/selectFallbackLanguage/selectFallbackLanguage.component';
import {
	PersonalizationTagsLoader
} from '../../components/personalizationTagsContainer/personalizationTagsModal/personalizationTagsLoader/personalizationTagsLoader';

@NgModule({
	imports: [
		ManageTemplatesRoutingModule,
		ComponentsModule,
		CommonModule,
		OptiModalModule.forRoot(),
		RecaptchaModule,
		SharedModule,
		TreeModule,
		AgGridModule,
		NgSelectModule,
		ReactiveFormsModule,
		PipesModule,
		TypeaheadModule,
		TooltipModule,
		TextFieldModule,
		OverlayModule
	],
	declarations: [
		PersonalizationTagsLoader,
		DefaultLanguageModalComponent,
		TemplateMetadataPanelComponent,
		TextareaAutoresizeDirective,
		CopyToTemplateModalComponent,
		EditRowModalComponent,
		TemplateScriptGuardContainerComponent,
		TemplateScriptGuardModalComponent,
		CopyTemplateContainerComponent,
		CopyTemplateModalComponent,
		ManageTemplatesPageComponent,
		MasterPanelComponent,
		DetailsPanelComponent,
		MasterDetailContainerComponent,
		OptimailEditorComponent,
		EditorHeaderComponent,
		EditorBodyComponent,
		TemplatePreviewCardComponent,
		TemplatesHeaderComponent,
		LoadingTemplateCartComponent,
		SmartPreviewTableComponent,
		NewFolderModalComponent,
		NewTemplateModalComponent,
		FolderRenameComponent,
		FolderDeleteComponent,
		ArrowsScrollComponent,
		BeefreeContainerComponent,
		BeefreePopupContainerComponent,
		TemplateNameValidatorDirective,
		UtmParamsComponent,
		DetailsPanelHeaderComponent,
		PersonalizationModalComponent,
		SaveRowModalComponent,
		ValidationErrorModelComponent,
		ValidatePersonalizationTagsComponent,
		MoveTemplateModalComponent,
		EditPlainTextModalComponent,
		TemplatePreviewModalComponent,
		PreviewTemplateWithDataComponent,
		PreviewTemplateInstantComponent,
		TemplateInUseModalComponent,
		TemplateBatchInUseModalComponent,
		SendTestModalComponent,
		MoveFolderModalComponent,
		MoveListsComponentComponent,
		OptimobileEditorComponent,
		UnsubscribeTagsModalComponent,
		ContentTagModalComponent,
		ContentTagModalContainerComponent,
		InsertLinkModalComponent,
		UpdateContentTagModalComponent,
		ContentTagNameValidatorDirective,
		ShowTooltipOnElipsisDirective,
		DragComponent,
		FolderPopupButtonComponent,
		FolderPopupOverlayComponent,
		TranslationPanelComponent,
		TranslationLanguagesComponent,
		ElementClickTrackerDirective,
		SearchTranslationsPanelComponent,
		SelectFallbackLanguageComponent
	],
	exports: [],
	providers: [TemplateScriptGuardModalService, CopyTemplateModalService, TemplateEditorGuard]
})
export class ManageTemplatesModule {}
