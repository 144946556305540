import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
    selector: 'campaign-grid-action-column',
    templateUrl: 'campaignGridActionColumn.component.html',
    styleUrls: ['./campaignGridActionColumn.component.scss']
})
export class CampaignGridActionColumnComponent implements ICellRendererAngularComp {
    public action: string = '';

    constructor() { }

    agInit(params: any): void {
        this.action = params.data?.Action;
    }

    refresh(): boolean {
        return true;
    }
}
