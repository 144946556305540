import { NgModule } from "@angular/core";
import { ComponentsModule } from "../../../components/components.module";
import { CommonModule } from "@angular/common";
import { AgGridModule } from "@ag-grid-community/angular";
import { SharedModule } from "../../../shared.module";
import { WebhookConfigurationComponent } from "./components/webhookConfiguration.component";
import { WebhookConfigurationEmptyStateComponent } from "./components/webhookConfigurationEmptyState/webhookConfigurationEmptyState.component";
import { WebhookConfigurationService } from "./webhookConfiguration.service";
import { AddWebhookConfigurationComponent } from "./components/addWebhookConfiguration/addWebhookConfiguration.component";
import { AddGenericEventWebhookModal } from "./components/addGenericEventWebhookModal/addGenericEventWebhookModal.component";
import { SettingsSharedModule } from "../settingsShared/settingsShared.module";
import { PipesModule } from "../../../pipes/pipes.module";
import { NewTagLabelCellRendererComponent } from "./components/newTagLabelCellRenderer/newTagLabelCellRenderer.component";
import { OptiModalModule } from "src/app/modules/OptiModal.module";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    AgGridModule.withComponents([NewTagLabelCellRendererComponent]),
    SharedModule,
    SettingsSharedModule,
    PipesModule,
    OptiModalModule.forRoot(),
    TooltipModule
  ],
  declarations:[
    WebhookConfigurationComponent,
    WebhookConfigurationEmptyStateComponent,
    AddWebhookConfigurationComponent,
    NewTagLabelCellRendererComponent,
    AddGenericEventWebhookModal,
  ],
  providers: [WebhookConfigurationService],
})
export class WebhookConfigurationModule {}