import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { GridOptions, GridApi} from "@ag-grid-enterprise/all-modules";
import {PriorityListItemComponent} from "./priorityListItem.component";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { ISOJTypes } from "../../../../models/ISOJTypes.enum";

@Component({
    selector: 'tg-priority-list',
    templateUrl: './priorityList.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./priorityList.component.scss']
})
export class PriorityListComponent implements OnChanges, OnInit {

    public gridOptions: GridOptions;
    public selectedRows;
    public isSojEnabled: boolean;
    public getChange: boolean = true;
    public modules: Module[] = AllModules;

    private gridApi: GridApi;
    private rowData;
    private originalTgs;
    private tgsCostumers;
    private tgsVisitors;
    private lastSelectedRow;
    private lastSelectedRowText;
    private isCustomers;

    @Input() maxTgIndex;
    @Input() filteredTg;
    @Input() firstSelectedTg;
    @Output() firstSelectedTgEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() search;

    constructor(@Inject('manageTargetGroupsDataService') private tgService: any,) {
        this.isSojEnabled = tgService.getIsSojEnabled();
    }

    ngOnInit(): void {
        this.gridOptions = <GridOptions>{
            context: this,
            columnDefs: [{field: "tg", rowDrag: true, cellRendererFramework: PriorityListItemComponent}, {field: 'priority', initialHide: true }, {field: 'id', initialHide: true }],
            headerHeight: 0,
            rowHeight: 40,
            rowDragManaged: false,
            suppressContextMenu: true,
            rowSelection: 'multiple',
            rowBuffer:0,
            animateRows: false, //animate row dragging
            suppressCellSelection: true,
            suppressDragLeaveHidesColumns: true, //no drag col outside (hiding cols)
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        //first init of type
        const hasSearchValue: any = document.querySelectorAll('.priority input')[0]['value'].length > 0;
        if(!changes.search && !changes.filteredTg) return;
        let tgs = changes.filteredTg.currentValue;
        this.isCustomers = tgs.length && tgs[0].categoryId === 1;

        if(this.isCustomers && !this.tgsCostumers) {
            this.tgsCostumers = tgs;
        }
        if(!this.isCustomers && !this.tgsVisitors) {
            this.tgsVisitors = tgs;
        }

        //switch costumers / visitors
        if((changes.search && changes.search.currentValue == '') ||
            (changes.maxTgIndex && tgs && !hasSearchValue)) {
            if(changes.maxTgIndex && changes.maxTgIndex.firstChange || tgs.length === 0) {
                this.originalTgs = tgs;
            }
            else {
                this.originalTgs = this.isCustomers ? this.tgsCostumers : this.tgsVisitors;
            }

            this.getChange = true;
        }
        if(this.getChange && changes.filteredTg && changes.filteredTg.currentValue && this.gridApi) {
            const data = this.sortOnly(this.originalTgs);
            this.updateGrid(data);
        }
        if(!this.getChange)
            this.getChange = true;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.updateGrid(this.filteredTg);
        this.setFirstItemSelected();
    }

    onRowDragEnter(e) {
        let el: any = document.querySelectorAll('.ag-dnd-ghost')[0];
        el.classList.add("my-dnd");
        let selectedNodes = e.api.getSelectedNodes();
        let itemsHtml = '';
        if(selectedNodes.length > 1) {
            itemsHtml = `<div class="items">${selectedNodes.length}</div>`;
        }
        el.innerHTML = `<div class="text">${e.node.data.tg}${itemsHtml}</div>`;
    }

    onRowDragEnd(e) {
        let itemsToMove = (this.selectedRows) ? this.selectedRows : [e.node.data];
        const toElPriority = e.overNode.data.priority;
        const fromPriority = e.node.data.priority;
        const toLocation = (toElPriority === "AUTO") ? 'auto' : toElPriority - 1;
        if (toElPriority == fromPriority) {
            return;
        }

        const data = this.moveItemsAndSortFilter(itemsToMove, toLocation);
        this.getChange = false;
        this.updateGrid(data);
        this.setItemsSelected();
    }

    onItemValueChange(item, newVal) {
        let itemsToMove = (this.selectedRows) ? this.selectedRows : [item];
        const sortedItems = this.moveItemsAndSortFilter(itemsToMove, newVal);
        this.getChange = false;
        this.updateGrid(sortedItems);
        this.setItemsSelected();
    }

    onRowClicked(e) {
        this.selectedRows = this.gridApi.getSelectedRows();
        if(this.selectedRows.length === 1) {
            if(e.node.selected === false) {
                let primary = e.event.target.closest('.primary-selection');
                if(primary) {
                    primary.classList.remove("primary-selection");
                }
                this.lastSelectedRow = document.getElementsByClassName('ag-row-selected')[1];
                this.lastSelectedRowText = this.selectedRows[0].tg;

            } else {
                if(this.lastSelectedRow) {
                    this.lastSelectedRow.classList.remove("primary-selection");
                }
                this.lastSelectedRow = e.event.target.closest('.ag-row-selected');
                this.lastSelectedRowText = e.data.tg;
            }
            this.lastSelectedRow.classList.add("primary-selection");
            this.firstSelectedTg = this.selectedRows[0];
            this.firstSelectedTgEmitter.emit(this.selectedRows[0]);
        }
    }

    private setFirstItemSelected() {
        if(this.firstSelectedTg) {
            this.gridApi.forEachNode(node => {
                if(node.data.id == this.firstSelectedTg.id) {
                    node.setSelected(true);
                    this.gridApi.ensureIndexVisible(Number(node.id));
                }
            });
        }
    }

    private setItemsSelected() {
        if(this.selectedRows) {
            let itemsName: string[] = [];
            for (let i: number = 0; i < this.selectedRows.length; i++) {
                itemsName.push(this.selectedRows[i].tg);
            }

            this.gridApi.forEachNode(node => {
                if(itemsName.includes(node.data.tg)) {
                    node.setSelected(true);
                    if(node.data.id == this.firstSelectedTg.id) {
                        this.gridApi.ensureIndexVisible(Number(node.id));
                        if(this.lastSelectedRow && this.lastSelectedRowText == node.data.tg ) {
                            document.querySelectorAll('div[row-index="' + node.id + '"]')[1].classList.add("primary-selection");
                        }
                    }
                }
            });
        }
    }

    private moveItemsAndSortFilter(from: any[], to: any) {
        let tgs = this.originalTgs;
        let normalItems = [];
        let autoItems = [];
        let movedItems = [];

        //generate items to move array
        let fromIds: number[] = from.map(x => x.id);

        //split auto / normal / moved items
        for (let i: number = 0; i < tgs.length; i++) {
            if(fromIds.includes(tgs[i].id)) { //dont include
                if(to === ISOJTypes.auto) {
                    tgs[i].priority = 1000000;
                    tgs[i].isAutoPriority = true;
                }
                if(to === ISOJTypes.manual) {
                    tgs[i].priority = 999999;
                    tgs[i].isAutoPriority = false;
                }
                movedItems.push(tgs[i]);
            } else if(tgs[i].isAutoPriority) {
                autoItems.push(tgs[i]);
            } else {
                normalItems.push(tgs[i]);
            }
        }

        //after sort insert new items
        if(to === ISOJTypes.auto) {
            autoItems = autoItems.concat(movedItems);
        } else if(to === ISOJTypes.manual) {
            normalItems = normalItems.concat(movedItems);
        } else {
            normalItems.splice(to, 0, ...movedItems);
        }

        //sort and update normal items
        for (let j: number = 0; j < normalItems.length; j++) {
            normalItems[j].priority = j+1;
        }

        //sort and update auto items
        autoItems.sort((a, b) => a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1);

        const dataToUpdate = normalItems.concat(autoItems);
        const isFiltered = (this.filteredTg.length < this.originalTgs.length);

        if(this.isCustomers) {
            this.tgsCostumers = dataToUpdate;
        } else {
            this.tgsVisitors = dataToUpdate;
        }

        this.originalTgs = dataToUpdate;

        if(isFiltered) {
            this.filteredTg = this.filterList(dataToUpdate);
            return this.filteredTg;
        }
        return this.originalTgs;

    }

    private sortOnly(data) {
        let tgs = data ? data : this.originalTgs;
        let normalItems = [];
        let autoItems = [];

        //split auto / normal
        for (let i: number = 0; i < tgs.length; i++) {
            if (tgs[i].isAutoPriority) {
                autoItems.push(tgs[i]);
            } else {
                normalItems.push(tgs[i]);
            }
        }
        //sort and update normal items
        for (let j: number = 0; j < normalItems.length; j++) {
            normalItems[j].priority = j+1;
        }
        //sort and update auto items
        autoItems.sort((a, b) => a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1);

        const dataToUpdate = normalItems.concat(autoItems);
        const isFiltered = (this.filteredTg.length < this.originalTgs.length);

        return isFiltered ? this.filterList(dataToUpdate) : dataToUpdate;
    }

    private filterList(data) {
        let originalData = [];
        const filteredDataIds = this.filteredTg.map(x => x.id);

        for (let i: number = 0; i < data.length; i++) {
            if(filteredDataIds.includes(data[i].id)) {
                originalData.push(data[i]);
            }
        }
        return originalData;
    }

    private updateGrid(data: any[], selectedName?: string[]): void {
        if(data) {
            this.rowData = [];
            for (let i: number = 0; i < data.length; i++) {
                const priority = data[i].isAutoPriority ? ISOJTypes.auto.toUpperCase() : data[i].priority;
                this.rowData.push({ tg: data[i].text, priority:  priority, id: data[i].id, isAutoPriority: data[i].isAutoPriority });
            }
            this.gridApi.setRowData(this.rowData);
            this.gridApi.sizeColumnsToFit();
            if(selectedName) {
                this.gridApi.forEachNode(node => {
                    if(selectedName.includes(node.data.tg)) {
                        node.setSelected(true);
                        this.gridApi.ensureIndexVisible(Number(node.id));
                        if(this.lastSelectedRow && this.lastSelectedRowText == node.data.tg) {
                            document.querySelectorAll('div[row-index="' + node.id + '"]')[1].classList.add("primary-selection");
                        }
                    }
                });
            }
        }
    }
}