import { EventEmitter } from '@angular/core';
import {Component, Input, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { IMultipleOptionsButtonObject } from '../../../../components/multipleOptionsButton/models/multipleOptionsButtonObject.model';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { AttributeToExportModal } from './attribureToExpotModal/attributeToExportModal.component';

@Component({
  selector: 'attributes-export-btn',
  templateUrl: 'attributesExportBtn.component.html',
  styleUrls: ['./attributesExportBtn.component.scss'],
})
export class AttributesExportBtnComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() isVisitorCampaign: boolean;
  @Input() set isExportResolved(isExportResolved: boolean) {if(isExportResolved) this.showLoader = false};
  @Output() export: EventEmitter<void> = new EventEmitter();

  public translateKeys = translateKeys;
  public showLoader: boolean = false;
  private _expordBtnId: string = "1";
  private _openModalBtnId: string = "2";

  public multipleOptionsButtonObject: IMultipleOptionsButtonObject = {
    mainItem: {
      id: this._expordBtnId,
      label: this.translate.instant(this.translateKeys.EXPORT),
      iconName: 'open_in_browser'
    },
    menuItems: [{
      id: this._openModalBtnId,
      label: this.translate.instant(this.translateKeys.POPUP_MENU_BTN),
    }]
  };

  constructor(private translate: TranslateService,
    private modalService: OptiLogicModalService,) { }

  ngOnInit(): void {
  }

  onButtonClick(buttonId: string) {
    if (buttonId === this._expordBtnId) {
      this.startExport();
    }
    else if (buttonId === this._openModalBtnId) {
      this.openManageAttributesModal();
    }
  }

  private openManageAttributesModal() {
    this.modalService.open(
      AttributeToExportModal,
      "md",
      <ModalOptions<any>>{
          initialState: {
            isVisitorsCampaign: this.isVisitorCampaign,
            export: () => this.startExport()
          },
          ignoreBackdropClick: true,
          keyboard: true,
          withScale: false
      });
  }

  private startExport() {
    this.showLoader = true;
    this.export.emit();
  }
}

const translateKeys = {
  EXPORT: "features.campaign_analysis.summary_section.attributeExport.EXPORT",
  POPUP_MENU_BTN: "features.campaign_analysis.summary_section.attributeExport.POPUP_MENU_BTN",
  LOADING_TEXT: "features.campaign_analysis.summary_section.attributeExport.LOADING_TEXT"
}