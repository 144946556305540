import { Component, OnInit } from "@angular/core";
import { OneClickUnsubscribeService } from "../services/oneClickUnsubscribe.service";
import { TranslateService } from "@ngx-translate/core";
import { BrandUnsubscribe, OneClickUnsubscribeItem } from "../models/oneClickUnsubscribeItem";
import { forkJoin } from "rxjs";
import { OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { patternURL } from '../../../../directives/customValidation/urlValidation.directive';
import { patternEmail } from '../../../../directives/customValidation/emailValidation.directive';
import { SecretService } from "../../messageArchiving/services/secret.service";
import { BrandService } from "../../../manageTemplates/services/brand.service";

interface BrandItem {
  name: string;
  isOpen: boolean;
  mailToUnsubscribe: string;
  httpsUnsubscribe: string;
  channelId: number;
  includeTemplateId: boolean;
}

@Component({
  selector: "one-click-unsubscribe",
  templateUrl: "./oneClickUnsubscribe.component.html",
  styleUrls: ["./oneClickUnsubscribe.component.scss"],
})
export class OneClickUnsubscribeComponent implements OnInit {
  isLoading: boolean = true;
  brands: BrandItem[];
  public readonly templateIdHintString = "&templateId=<templateId>";

  private readonly optimailChannelId = 15;

  constructor(private oneClickUnsubscribeService: OneClickUnsubscribeService,
              private translate: TranslateService,
              private modalService: OptiLogicModalService,
              private secretService: SecretService,
              private  brandService: BrandService) {}

  ngOnInit(): void {
    forkJoin([this.brandService.updateBrandsByChannelId(this.optimailChannelId),
        this.oneClickUnsubscribeService.getOneClickUnsubscribe(this.optimailChannelId)])
        .subscribe(([brands, oneClickUnsubscribe]) => {
        this.brands = brands.map((b) => {
          const unsubscribeBrand = oneClickUnsubscribe?.BrandUnsubscribeLinks?.find(ub => ub.BrandName == b.name);
          return {
            name: b.name,
            channelId: this.optimailChannelId,
            httpsUnsubscribe: unsubscribeBrand?.HttpsUnsubscribe,
            mailToUnsubscribe: unsubscribeBrand?.MailToUnsubscribe,
            includeTemplateId: unsubscribeBrand?.IncludeTemplateId
          } as BrandItem
        });

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.openMessage(error);
      });
  }

  public downloadSecretKey(brand: BrandItem){
    this.isLoading = true;
    this.secretService.getOneClickSecret(brand.channelId, brand.name).subscribe((res) => {
      const brandName = brand.name.replace(' ', '-');
      const fileName = `secret-key-${brandName}.txt`
      this.secretService.downloadTxtFile(res, fileName);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.openMessage(this.translate.instant('features.user_settings.body.oneClickUnsubscribe.SECRET_KEY_GENERATION_ERROR'));
    });

  }

  public regenerateSecretKey(brand: BrandItem){
    this.modalService.openModalMessage('sm',{
      message: this.translate.instant('features.user_settings.body.oneClickUnsubscribe.REGENERATE_SECRET_MESSAGE'),
      buttons: [
        {
          class: 'btn-primary',
          label: this.translate.instant('features.user_settings.body.oneClickUnsubscribe.GENERATE'),
          action: () => {
            this.isLoading = true;
            this.secretService.getOneClickSecret(brand.channelId, brand.name, true).subscribe((res) => {
              const brandName = brand.name.replace(' ', '-');
              const fileName = `secret-key-${brandName}.txt`
              this.secretService.downloadTxtFile(res, fileName);
              this.isLoading = false;
            }, () => this.openMessage(this.translate.instant('features.user_settings.body.oneClickUnsubscribe.SECRET_KEY_GENERATION_ERROR'))
            )
          }
        },
        {
          class: 'btn-default',
          label: this.translate.instant('general.CANCEL'),
          action: () => {}
        }
      ]
    })
  }


  save(): void {
    const oneClickUnsubscribe: OneClickUnsubscribeItem = {
      ChannelId: this.optimailChannelId,
      BrandUnsubscribeLinks: this.brands.map<BrandUnsubscribe>((b) => {
        return {
          BrandName: b.name,
          MailToUnsubscribe: b.mailToUnsubscribe,
          HttpsUnsubscribe: b.httpsUnsubscribe,
          IncludeTemplateId: b.includeTemplateId
        };
      })
    }
    this.oneClickUnsubscribeService
    .updateOneClickUnsubscribe(oneClickUnsubscribe)
    .subscribe(
      () => {
        this.openMessage(this.translate.instant("general.SUCCESS"));
      },
      () => {
        this.openMessage(this.translate.instant("general.FAILED"));
      }
    );
  }

  private openMessage(message: string): void {
    this.modalService.openModalMessage('sm', {
      message,
      buttons: [
        {
          class: 'btn-primary',
          label: this.translate.instant('general.OK'),
          action: () => {
          }
        }
      ]
    });
  }

  public isSaveDisabled() {
    if (this.brands) {
      let invalidBrands = this.brands.filter(brand => {
        if (brand.isOpen && ((brand.mailToUnsubscribe && !patternEmail.test(brand.mailToUnsubscribe)) || !patternURL.test(brand.httpsUnsubscribe))) {
          return brand;
        }
      });
      return invalidBrands.length > 0;
    } else {
      return true;
    }
  }
}
