<div class="template-modal-layout">
    <button class="btn-link btn-close" (click)="closeModal()">
        <i data-qa-id="closeHeatMapModalButton" class="material-icons">close</i>
    </button>

    <div class="modal-container">
        <ng-container [ngSwitch]="templateModalData.type">
            <templates-html-carousel-modal *ngSwitchCase="templateModalTypes.HTMLCarousel"
               [templates]="templateModalData.templates"
               [datesData]="datesData"
               [displayTemplateIndex]="templateModalData.displayTemplateIndex"
                (showTemplatesGridViewModal)="onShowTemplatesGridViewModal()">
            </templates-html-carousel-modal>

            <templates-grid-view-modal *ngSwitchCase="templateModalTypes.Grid"
                [toShowChannelName]="toShowChannelName"
                [templates]="templateModalData.templates"
                [datesData]="datesData"
                (showTemplatesCarouselModal)="onShowTemplatesHTMLCarouselModal($event)">
            </templates-grid-view-modal>
        </ng-container>
    </div>
</div>
