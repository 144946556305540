<opti-logic-modal [modalConf]="modalConfig" data-qa-id="cy_opti_logic_modal">
    <div class="column-mapping-content">
        <div class="file-loader">
            <opti-loading [show]="isLoading" [text]="loadingText" [size]="'lg'"></opti-loading>
        </div>
        <div class="column-mapping-item" *ngIf="!isLoading">
            <ng-container *ngFor="let att of attributesPreview; let i=index; first as isFirst">
                <columns-mapping 
                    [attributeItems]="attributeItems" 
                    [attribute]="att" 
                    [(selectedAttribute)]="selectedAttributes[i]"
                    [isSelectionDisabled]="isFirst"
                    (selectedAttributeChange)="onSelectedAttributeChange($event)">
                </columns-mapping>
            </ng-container>
        </div>
    </div>
    <div custom-start-footer *ngIf="!isLoading" class="footer-summary">
        <span>{{
            "features.user_settings.body.importedCustomers.MAPPED_COLUMNS"
              | translate
          }}</span>
          <b class="attributes-counter">{{getSelectedAttributesSummary()}}</b>
          <span *ngIf="isValid else problem" class="material-icons text-success validation-icon">check_circle</span>
          <ng-template #problem>
            <span class="material-icons text-issue validation-icon report-icon" title="{{getWarningTitle()}}">report_problem</span>
          </ng-template>
    </div>
</opti-logic-modal>