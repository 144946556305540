import { Component, Input, OnChanges } from '@angular/core';
import { ChannelIconService } from '@optimove/ui-sdk/components/channel-widget-bar';
import './channelGroupType.component.scss';
import { ChannelGroupType } from './channelGroupTypeModels';

@Component({
  selector: 'channel-group-type',
  templateUrl: './channelGroupType.component.html',
  styleUrls: ['./channelGroupType.component.scss'],
})
export class ChannelGroupTypeComponent implements OnChanges {
  @Input() channelGroupType: ChannelGroupType;
  @Input() isTriggered: boolean;
  
  public imageSrc: string;
  public channelName: string;

  constructor(private channelIconService: ChannelIconService) { }

  ngOnChanges() {
    if (this.isTriggered) {
      this.imageSrc = this.channelIconService.getTriggeredChannelIconImageUrl(this.channelGroupType.channelId, this.channelGroupType.groupType);
    } else {
      this.imageSrc = this.channelIconService.getScheduledChannelIconImageUrl(this.channelGroupType.channelId, this.channelGroupType.groupType);
    }
    this.channelName = this.channelGroupType.useAttributeTooltip ? this.channelGroupType.tooltipText : '';
  }
}
