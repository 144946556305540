<div class="imported-customers-container">
  <div class="panel-heading">
    <h2 data-qa-id="importedCustomersTitle">
      {{ "features.user_settings.body.importedCustomers.TITLE" | translate }}
    </h2>
  </div>

  <div class="panel-body">
    <div class="upload-label">
      {{
        "features.user_settings.body.importedCustomers.UPLOAD_CSV_LABEL" | translate
      }}
    </div>
    <div [innerHTML]="description" class="upload-description"></div>
    <ng-container *ngIf="!showConfirmation else confirmation">
      <div class="browse-csv-file">
        <button class="btn btn-secondary" (click)="uploadCSV()">
          <input #fileInput type="file" class="hidden" (change)="onFileInput($event)" accept=".csv"/>
          {{ "general.BROWSE" | translate }}
        </button>
      </div>
      <div [innerHTML]="validationLabel" class="upload-validation-label"></div>
    </ng-container>

    <ng-template #confirmation>
      <div class="confirmation-area">
        <div class="file">
          <span class="file-name">{{csvFile.name}}</span>
          <a #deleteButton class="btn btn-link btn-link-default delete-icon">
              <i class="material-icons" (click)="clean()">delete_forever</i>
          </a>
        </div>
        <div class="attributes-panel">
          <div class="map-container">
            <div class="mapped-attributes">
              <span class="mapping-summary">{{columnsSummary}}</span>
              <span class="mapped-columns">{{mappedColumns}}/{{totalColumns}}</span>
              <span class="material-icons text-success validation-icon">check_circle</span>
            </div>
    
            <div class="remap-attributes">
              <button class="btn btn-secondary" (click)="reMapAttributes()">
                {{translateKeys.mapColumns| translate}}
              </button>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="map-container" >
              <label>{{translateKeys.tgCreate | translate}}</label>
              <switch [buttonType]="SwitchButtonType.Small" ng-reflect-model="true" (change)="switchAllowCreateTg()"></switch>
            </div>
            <form [formGroup]="form" *ngIf="allowCreateTg">
              <div class="row">
                <input formControlName="name" data-qa-id="target-group-name-input" class="form-control" type="text" 
                placeholder= "{{translateKeys.tgPlacgeholder | translate}}" (blur)="isNameUnique()"/>
              </div>
              <div class="row save-label">
                <span *ngIf="name.valid || (name.untouched && !name.dirty)">
                  {{translateKeys.saveLabel | translate}}
                </span>
                <span *ngIf="name.invalid && (name.dirty || name.touched) && name.value === ''" class="error-input">
                  {{translateKeys.emptyTg | translate}}
                </span>
                <span *ngIf="name.invalid && (name.dirty || name.touched) && name.value !== ''" class="error-input">
                  {{translateKeys.tgNameMustBeUnique | translate}}
                </span>
              </div>
            </form>
          </div>
        </div>
        <div class="checkbox checkbox-primary">
          <input type="checkbox" [checked]="isConsent" #isConsentCheckbox (change)="toggleConsent()" id="isConsent">
          <label for="isConsent">{{"features.user_settings.body.importedCustomers.CONSENT" | translate}}</label>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="panel-footer footer-btns panel-footer-bottom-stick">
      <div class="footer-btn-container">
          <div>
              <button class="btn btn-default" [disabled]="cancelDisabled" (click)="clean()" data-qa-id="cyImportedCustomersCancelButtonLoader">
                  {{'general.CANCEL' | translate }}
              </button>
          </div>
          <div>
            <button class="btn btn-primary save-btn" [disabled]="saveDisabled" (click)="save()" data-qa-id="cyImportedCustomersSaveButtonLoader">
                {{'general.SAVE' | translate }}
            </button>
        </div>
      </div>
  </div>

</div>
