import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'opti-popover',
  templateUrl: './optiPopover.component.html',
  styleUrls: ['./optiPopover.component.scss']
})
export class OptiPopoverComponent implements OnInit, OnDestroy {
  @HostBinding("class.open") @Input() isOpen: boolean = false;
  @Output() closeCallback = new EventEmitter<boolean>();

  private domClickEventSubscription: Subscription;
  constructor() { }

  ngOnInit(): void {
    this.domClickEventSubscription = fromEvent(document, 'click')
      .subscribe((clickEvent: PointerEvent) => this.closeCallback.emit());
  }

  ngOnDestroy(): void {
    this.domClickEventSubscription && this.domClickEventSubscription.unsubscribe();
  }
}
