import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  IModalButtonConf,
  IModalConf,
} from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { IListItem } from "@optimove/ui-sdk/common/models";
import { FormControl, FormGroup, NgModel } from "@angular/forms";
import { WebhookConfigurationService } from "../../webhookConfiguration.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { WebhookEvent } from "../../models/webhookConfiguration";

@Component({
  selector: "add-generic-event-webhook-modal",
  templateUrl: "./addGenericEventWebhookModal.component.html",
  styleUrls: ["./addGenericEventWebhookModal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddGenericEventWebhookModal implements OnInit, OnDestroy {
  @Input() mainWebhookUrl: string;
  @Input() internalConfiguration: boolean;
  @Input() genericEventListInput: WebhookEvent[];
  @Input() genericEventToEdit: WebhookEvent;
  private destroyOrCancelled$: Subject<boolean> = new Subject<boolean>();
  public onSave = new Subject<WebhookEvent>();

  public titleModalText: string;
  public eventLabelText: string;
  public webhookURLLabelText: string;
  public validatingLoadingText: string;
  public validationButtonText: string;
  public saveButtonText: string;
  public cancelButtonText: string;
  public webhookURLPlaceholderText: string;
  public webhookURLRequiredErrorText: string;
  public webhookURLNotValidatedErrorText: string;
  public webhookURLInvalidErrorText: string;
  public copyMainUrlButtonText: string;
  public selectEventPlaceholder: string;

  public modalConfig: IModalConf;
  public eventList: IListItem[];
  public urlValidated: boolean = false;
  public urlValid: boolean = false;
  public showValidatingLoader: boolean = false;

  public genericEventModalForm: FormGroup;
  get selectGenericEventControl(): FormControl {
    return this.genericEventModalForm.get("selectGenericEvent") as FormControl;
  }
  get webhookUrlFormControl(): FormControl {
    return this.genericEventModalForm.get("webhookUrl") as FormControl;
  }
  get selectedGenericEventId(): number {
    return this.selectGenericEventControl.value;
  }

  constructor(
    private translate: TranslateService,
    private bsModalRef: BsModalRef,
    private webhookConfigurationService: WebhookConfigurationService
  ) {}

  ngOnInit(): void {
    this.initTranslateFields();
    this.setModalConfig();
    this.createForm();
    this.filterGenericEventWithUrl();
    this.setEditModeData();
  }

  private initTranslateFields() {
    const addWebhookConfigurationTranslationPrefix =
      "features.user_settings.body.webhookConfiguration.addWebhookConfiguration";
    const genericEventTranslationPrefix = `${addWebhookConfigurationTranslationPrefix}.addGenericEventWebhookModal`;
    const validationTranslationPrefix = `${addWebhookConfigurationTranslationPrefix}.validationErrors`;

    this.titleModalText = this.translate.instant(
      `${genericEventTranslationPrefix}.title`
    );
    this.eventLabelText = this.translate.instant(
      `${genericEventTranslationPrefix}.event_label`
    );
    this.webhookURLLabelText = this.translate.instant(
      `${genericEventTranslationPrefix}.webhook_url_label`
    );
    this.copyMainUrlButtonText = this.translate.instant(
      `${genericEventTranslationPrefix}.copy_from_main_url`
    );
    this.saveButtonText = this.translate.instant(
      `${genericEventTranslationPrefix}.save_btn`
    );
    this.cancelButtonText = this.translate.instant(
      `${genericEventTranslationPrefix}.cancel_btn`
    );
    this.validatingLoadingText = this.translate.instant(
      `${addWebhookConfigurationTranslationPrefix}.validating`
    );
    this.validationButtonText = this.translate.instant(
      `${addWebhookConfigurationTranslationPrefix}.validate`
    );
    this.webhookURLPlaceholderText = this.translate.instant(
      `${addWebhookConfigurationTranslationPrefix}.webhookURLPlaceholder`
    );
    this.webhookURLRequiredErrorText = this.translate.instant(
      `${validationTranslationPrefix}.webhookURLRequired`
    );
    this.webhookURLNotValidatedErrorText = this.translate.instant(
      `${validationTranslationPrefix}.webhookURLNotValidated`
    );
    this.webhookURLInvalidErrorText = this.translate.instant(
      `${validationTranslationPrefix}.webhookURLInvalid`
    );
    this.selectEventPlaceholder = this.translate.instant(
      `${genericEventTranslationPrefix}.select_event`
    );  }

  private setModalConfig() {
    this.modalConfig = {
      hideFooterBorder: false,
      noBodyPadding: true,
      title: this.titleModalText,
      buttons: this.getButtons(),
    };
  }

  createForm(): void {
    this.genericEventModalForm = new FormGroup({
      selectGenericEvent: this.onEditMode()
        ? new FormControl(this.genericEventToEdit.genericEngagerEventTypeID)
        : new FormControl(),
      webhookUrl: this.onEditMode()
        ? new FormControl(this.genericEventToEdit.webhookUrl)
        : new FormControl(),
    });
  }

  filterGenericEventWithUrl() {
    if (this.onEditMode()) {
      this.eventList = [
        {
          id: this.genericEventToEdit.genericEngagerEventTypeID,
          name: this.genericEventToEdit.genericEngagerEventName,
        } as IListItem,
      ];
    } else {
      this.eventList = this.genericEventListInput.reduce(
        (filtered, genericEvent) => {
          if (!genericEvent.webhookUrl) {
            let eventToPush = {
              id: genericEvent.genericEngagerEventTypeID,
              name: genericEvent.genericEngagerEventName,
            } as IListItem;
            filtered.push(eventToPush);
          }
          return filtered;
        },
        []
      );
    }
  }

  setEditModeData() {
    if (this.onEditMode()) {
      this.selectGenericEventControl.disable({ emitEvent: false });
      this.webhookUrlFormControl.setValue(this.genericEventToEdit.webhookUrl);
    }
  }

  private getButtons(): IModalButtonConf[] {
    return [
      {
        action: () => this.saveModal(),
        class: "btn-primary",
        label: `${this.saveButtonText}`,
        isDisabled: () => this.isSaveModalDisabled(),
        dataQaId: "cyGenericModalSaveButton",
      },
      {
        action: () => this.closeModal(),
        class: "btn-default",
        label: `${this.cancelButtonText}`,
        isDisabled: () => false,
        dataQaId: "cyGenericModalCancelButton",
      },
    ];
  }

  saveModal() {
    if(!this.urlValid){
      this.webhookUrlFormControl.setErrors({'notValidated': true});
      return;
    }
    const genericEventModalOutput = this.createModalOutput();
    this.onSave.next(genericEventModalOutput);
    this.closeModal();
  }

  private createModalOutput() {
    let output = {} as WebhookEvent;
    output.genericEngagerEventTypeID = this.selectedGenericEventId;
    output.genericEngagerEventName = this.genericEventListInput.find(
      (event) =>
        event.genericEngagerEventTypeID === output.genericEngagerEventTypeID
    ).genericEngagerEventName;
    output.webhookUrl = this.webhookUrlFormControl.value;
    return output;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  isSaveModalDisabled() {
    return this.selectedGenericEventId == null || this.webhookUrlFormControl.pristine;
  }

  onWebhookURLChanged() {
    this.urlValid = false;
  }

  validateURL() {
    this.showValidatingLoader = true;
    this.webhookConfigurationService
      .validateWebhookEventURL(this.webhookUrlFormControl.value, this.internalConfiguration)
      .pipe(takeUntil(this.destroyOrCancelled$))
      .subscribe((isValid) => {
        this.urlValid = isValid;
        this.showValidatingLoader = false;
        this.webhookUrlFormControl.setErrors(
          !isValid ? { urlInvalid: true } : null
        );
      });
  }

  onCopyMainUrl() {
    this.webhookUrlFormControl.setValue(this.mainWebhookUrl);
    this.webhookUrlFormControl.markAsDirty();
    this.urlValid = true;
  }

  isCopyMainUrlDisable() {
    return (
      this.mainWebhookUrl === null ||
      this.webhookUrlFormControl.value === this.mainWebhookUrl
    );
  }

  private onEditMode() {
    return this.genericEventToEdit !== undefined;
  }

  ngOnDestroy() {
    this.destroyOrCancelled$.next(true);
    this.destroyOrCancelled$.complete();
  }
}
