import { CalculatorConfig, calculatorDatasource, FormulaMetadata, CalculatorActionItems } from "./attributeCalculator.model";
import { SelectAttributePopupComponent } from "../selectAttributePopup/selectAttributePopup.component";
import { OptiLogicModalService } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { AttributeConfiguration, BaseAttribute } from "../../attributesList.model"; 
import { FormulaType } from "../../attributesList.enums";
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { filter, first, pairwise, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AbstractControl, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from "@angular/forms";
import { TagifySettings,Tagify } from "../../../../../components/tagify/tagify.component";
import { TagifyService } from "../../../../../components/tagify/tagify.service";
import { minMaxTagsValidator } from "../../../../../components/tagify/validators/min-max-tags-validator.directive";
import { FormulaValidatorService } from "../../services/formulaValidator.service";
import { IAttributeEditor } from '../../interfaces/editor.interface';
import { AttributesManagerService } from "../../services/attributesManager.service";

@Component({
	selector: 'attribute-calculator',
	templateUrl: './attributeCalculator.component.html',
	styleUrls: ['./tagify.scss', './attributeCalculator.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ {
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AttributeCalculatorComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => AttributeCalculatorComponent),
		multi: true
	}
	]
})
export class AttributeCalculatorComponent implements OnInit , OnDestroy, IAttributeEditor {
    public form: FormGroup;
    @Input() config: CalculatorConfig;
    @Output() changeEmitter: EventEmitter<any> = new EventEmitter<any>();

	FormulaType = FormulaType;

    settings: TagifySettings = {
    	duplicates : true,
    	editTags: false,
    	delimiters: ",| ",
    	templates: {
    		tag(tagData, tagify) {
    			tagData.tag = tagData.tag ? tagData.tag : "text";
    			tagData.class = tagData.class ? tagData.class : "text-attribute";
    			return `<tag title="${(tagData.title || tagData.value)}"
                                contenteditable='false'
                                spellcheck='false'
                                class="${this.settings.classNames.tag} ${tagData.class}"
                                ${this.getAttributes(tagData)}>
                            <x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
                            <div>
                                <span class="${this.settings.classNames.tagText}">${tagData[this.settings.tagTextProp] || tagData.value}</span>
                            </div>
                        </tag>`
    		}
    	},
    	callbacks: {
    		add: (tag) => {
    			if ( this.tagify){
    				this.tagify.DOM.input.focus(); 
    			}
                    
    		},
    		remove: (e) => {
    			// update counter of attributes and decreaseNumberOfAttributeRepetition
    			if (e.detail.data.tag === 'attribute') {
    				let attribute = e.detail.data.attribute;
    				this.decreaseNumberOfAttributeRepetition(attribute.Name);
    			}

    			// customer can use only 9 attributes
    			this.validateIfCanAddMoreAttributes();
    			this.ifTagifyIsEmptyResetActionState();
    		}
    	},
    	hooks: {
    		beforeRemoveTag: tags => {
    			return new Promise((resolve, reject) => {
    				if (this.config.formulaType === FormulaType.DateBase || this.config.isDisabled) {
    					reject(); // disable the abilty to delete inline function tags or in edit mode when attribute was published
    				} else {
    					resolve(tags);
    				}
    			})
    		}
    	}
    };
    private subscriptions: Subscription[] = [];
    private numberOfAttributeRepetition = {};
    private counterNumberOfAttributeTags = 0;
    public isDateSelectedFirst = false;
    public translateKeys = translateKeys;
    public isValuesChanged = false;
    private isCalculatorValuesInit = false;
    private startCalculatorValue;

    public tagify: Tagify;
    public activeModalRef: BsModalRef;
   
    public metadata: FormulaMetadata = {
    	formula: "",
    	details: [],
    	params: []
    };

    constructor(private translate: TranslateService,
        private modalService: OptiLogicModalService,
        private formBuilder: FormBuilder,
        private attrManagerService: AttributesManagerService,
        private tagifyService: TagifyService,
        private formulaValidatorService: FormulaValidatorService) { }

    ngOnInit() {
    	this.form = this.formBuilder.group({
    		equationEditor: ["", [minMaxTagsValidator(0, 'select', 'max', '')]],
			ignoreYear: [this.config.ignoreYear]
    	});

    	let subscription1$ = this.attrManagerService.getAttributes(true)
    		.pipe(first(), filter(x => !!x))
    		.subscribe((res: BaseAttribute[]) => {
    			this.config.attributes = res.filter(x => x.Type !== "customer");
    		});

    	let subscription2$ = this.attrManagerService.getAttributeConfiguration()
    		.subscribe((config: AttributeConfiguration) => {
    			this.config.numberOfAttributesAllowed = config.NumberOfAttributesAllowed;
    			this.config.numberOfShowsPerAttribute = config.NumberOfShowsPerAttribute;
    		});

    	let subscription3$ = this.form
    		.valueChanges
    		.pipe(pairwise())
    		.subscribe(([prev, next]: [any, any]) => {
    			this.checkFormulaChanging(prev, next);
    		});

    	this.subscriptions.push(subscription1$);
    	this.subscriptions.push(subscription2$);
    	this.subscriptions.push(subscription3$);

    	this.initCalculatorButtons();
    }

    ngOnDestroy() {
    	this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    	this.tagifyService.remove('equationEditor');
    }
    
    ngAfterViewInit() {
    	this.initTagify('');
    	if (!this.config.isAddMode){
    		this.setupCalculation(this.config.formula, this.config.params, this.config.formulaType);
    	}

    	this.form.addValidators(minMaxTagsValidator(1, 'attribute', 'min', 'equationEditor'));
    	this.equationEditorFormControl.addValidators([Validators.required, this.formulaValidatorService.validate(this.tagify, this.config.attributes, this.getFormulaType)]);
        
    	if (!this.config.formula) {
    		// Tagify used setInterval for events by design: https://stackoverflow.com/a/1949416/104380
    		setTimeout(() => {
    			this.equationEditorFormControl.reset();
    		});
    	}
    }

    setDisabledState?(isDisabled: boolean): void {
    	isDisabled ? this.form.disable() : this.form.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null{
    	return this.form.valid ? null : { invalidForm: {valid: false, message: "calculatorForm fields are invalid"}};
    }

    getFormulaType = () => {
    	return this.config.formulaType;
    }
  
    setupCalculation(formula: string, params: string, formulaType: FormulaType) {
    	let tags = [];
    	this.config.formulaType = formulaType;

    	let array = params.split(",");
    	if (formulaType === FormulaType.DateBase ) {          
    		const attribute1 = this.config.attributes.find(x => x.FieldName === array[0]);
    		const attribute2 = this.config.attributes.find(x => x.FieldName === array[1]);
    		if (array[0] === "today") {
    			tags = [
    				{ value: "Days since", class: this.config.isDisabled ?  "text-attribute-disabled" : "text-attribute",readonly: true, tag: "text" },
    				{ value: attribute2.DisplayName, class: this.config.isDisabled ? "selected-attribute-disabled" : "selected-attribute", readonly: true, tag: "attribute", attribute: attribute2 }
    			];
    		} else if (array.length === 2 && array[1] === "today") {
    			tags = [
    				{ value: "Days until", class: this.config.isDisabled ?  "text-attribute-disabled" : "text-attribute",readonly: true, tag: "text" },
    				{ value: attribute1.DisplayName, class: this.config.isDisabled ? "selected-attribute-disabled" : "selected-attribute", readonly: true, tag: "attribute", attribute: attribute1 }
    			];
    		} else {
    			tags = [
    				{ value: "Days between", class: this.config.isDisabled ?  "text-attribute-disabled" : "text-attribute",readonly: true, tag: "text" },
    				{ value: attribute1.DisplayName, class: this.config.isDisabled ? "selected-attribute-disabled" : "selected-attribute", readonly: true, tag: "attribute", attribute: attribute1 },
    				{ value: "and", class: this.config.isDisabled ?  "text-attribute-disabled" : "text-attribute",readonly: true, tag: "text" },
    				{ value: attribute2.DisplayName, class: this.config.isDisabled ? "selected-attribute-disabled" : "selected-attribute", readonly: true, tag: "attribute", attribute: attribute2 }
    			];
    		}
    		this.setDateBaseDefault();
    	} else if (formulaType === FormulaType.FormulaBase) {
    		this.setActionItemsState("lockInline");
    		// be on the safe side add whitespace padding to all operators in the formula
    		formula = formula.replace(/[*+-/()]/g, ' \$& ')
    		// split and filter out any empty strings
    		let splitedFormula = formula.split(' ').filter(x => x > "");
            
    		// create the tagify tags formula
    		splitedFormula.forEach((fieldName, index) => {
    			// this is for testing if its a number or (operator+brackets)
    			// if so create the appropriate tag -text type tag
    			if (/[\+\-\/\*\(\)\.]/.test(fieldName) || !isNaN(fieldName as any)) {
    				tags.push({
    					value: fieldName.replace(/\s/g, ''),
    					attribute: null,
    					class: "text-attribute",
    					tag: "text"
    				});
    				// create attribute type tag 
    			} else {
    				const attribute = this.config.attributes.find(x => x.FieldName === fieldName);
    				if (attribute) {
    					this.increaseNumberOfAttributeRepetition(attribute.Name);
    					tags.push({
    						value: attribute.DisplayName,
    						attribute: attribute,
    						class: this.config.isDisabled ? "selected-attribute-disabled" : "selected-attribute",
    						tag: "attribute"
    					});
    				}
    			}
    		});
            
    		this.validateIfCanAddMoreAttributes();
    	}

    	// this is for handling special case when client changes between ifelse or formula attribute
    	// so we won't initialize twice the tagify component
    	if (this.tagify.value.length === 0){
    		this.tagify.addTags(tags);
    	}
    }

    public onSelectedAttribute(attribute, index) {
    	if (attribute === undefined)
    		return;

    	if (this.config.formulaType === FormulaType.FormulaBase) {
    		this.setActionItemsState("lockInline");
    	}

    	this.increaseNumberOfAttributeRepetition(attribute.Name);
    	var newTag = { value: attribute.DisplayName, class: "selected-attribute", tag: "attribute", attribute: attribute };

    	// if there is an index this means that i replace old with new 
    	if (index !== null &&  !isNaN(index) ) {
    		let oldTag = this.tagify.value[index];
    		if (oldTag.tag === 'attribute' && oldTag.attribute !== undefined) {
    			this.decreaseNumberOfAttributeRepetition(oldTag.attribute.Name);
    		}

    		var tags = this.tagify.getTagElms();
    		this.tagify.replaceTag(tags[index], newTag);
    	} 
    	// else add new tag
    	else {
    		this.tagify.addTags([newTag]);
    	}
    }

    public buildAttribute() {
    	this.metadata = { formula: "", params: [], details: [] };
    	const tags = this.tagify.getTagElms();
    	let counter = 0;
    	if (this.config.formulaType === FormulaType.FormulaBase) {
    		tags.map(x => {
    			const attribute = this.config.attributes.find(z => z.DisplayName === x.title);
    			if (attribute) {
    				this.metadata.formula = `${this.metadata.formula} ${attribute.FieldName} `;
    				if (!this.metadata.details.find(x => x.Name === attribute.FieldName)) {
    					this.metadata.params.push(attribute.FieldName);
    					this.metadata.details.push({ 
    						Name: attribute.FieldName, 
    						Type: attribute.AttributeBaseType, 
    						Format: attribute.Formatting 
    					});
    				}
    			} else {
    				this.metadata.formula = `${this.metadata.formula}${x.title}`;
    			}
    		});
    	} else {
    		let useToday = "";
    		tags.map(x => {
    			const attribute = this.config.attributes.find(z => z.DisplayName === x.title);
    			if (attribute) {
    				if (!this.metadata.params.includes(attribute.FieldName)) {
    					this.metadata.params.push(attribute.FieldName);
    					this.metadata.details.push({ 
    						Name: attribute.FieldName, 
    						Type: attribute.AttributeBaseType, 
    						Format: attribute.Formatting
    					});
    				}
    			} else if (x.title.includes("Days since")) {
    				useToday = "since";
    			} else if (x.title.includes("Days until")) {
    				useToday = "until";
    			}
    		});
    		if (useToday === "since") {
    			this.metadata.params.unshift("today");
    			this.metadata.details.unshift({ Name: "today", Type: "date" });
    		} else if (useToday === "until") {
    			this.metadata.params.push("today");
    			this.metadata.details.push({ Name: "today", Type: "date" });
    		}
    		this.metadata.formula = "function";
    	}
    }

    private openAttributePopup(index: number) {
    	const isDateBaseAttribute = this.config.formulaType === FormulaType.DateBase;
    	let attributes = this.config.attributes
    		.filter(x => x.AttributeBaseType === (isDateBaseAttribute ? "date" : "number"))
    		.sort((a, b) => a.DisplayName > b.DisplayName ? 1 : -1);

    	// this is for formula base attribute
    	if (!isDateBaseAttribute) {
    		// enable only 3 attributes
    		if (Object.keys(this.numberOfAttributeRepetition).length === this.config.numberOfAttributesAllowed) {
    			// Get the keys
    			const keys = Object.keys(this.numberOfAttributeRepetition);
    			// filter out all attributes except those 3 that already been selected
    			attributes = attributes.filter((elem) => keys.find(name => elem.Name === name));
    		}

    		// iterate on selected attributes for testing if there is a need to remove for this selection
    		for (var key in this.numberOfAttributeRepetition) {
    			// remove from attribute list those that already been used at least 3 times
    			if (this.numberOfAttributeRepetition[key] === this.config.numberOfShowsPerAttribute) {
    				const attribute = attributes.find(elem => elem.Name === key);
    				if (attribute) {
    					const index = attributes.indexOf(attribute);
    					if (index > -1) {
    						attributes.splice(index, 1);
    					}
    				}
    			}
    		}
    	}

    	this.activeModalRef = this.modalService.open(
    		SelectAttributePopupComponent,
    		"md",
            <ModalOptions<any>>{
            	initialState: {
            		source: attributes,
            		index: index,
            		handleSelect: (attribute, index) => {
            			this.onSelectedAttribute(attribute, index);
            			this.isDateSelectedFirst = false;
            		},
            		handleClose: () => {
            			this.ifTagifyIsEmptyResetActionState();
            			this.isDateSelectedFirst = false;
            		}
            	},
            	ignoreBackdropClick: false,
            	keyboard: true
            });
    }

    private aggregateNumberOrAddOperatorAsTag(value){
    	this.config.formulaType = FormulaType.FormulaBase;
    	var tags = this.tagify.getTagElms();
    	this.form.markAsTouched();
    	// if no tags
    	if (tags.length === 0) {
    		this.tagify.addTags([{ value: value, class: "text-attribute", tag: "text" }]);
    		// in case of number it can be the first number or need to aggrigate     
    	} else if (!isNaN(value as any)) {
    		const lastTag = tags[tags.length - 1];
    		var lastTagValue = this.tagify.value[tags.length - 1];
    		// aggrigate number
    		if (lastTagValue.value.length > 0 && !isNaN(lastTagValue.value as any)) {
    			lastTagValue.value += value;
    			this.tagify.replaceTag(lastTag, lastTagValue);
    			// first number    
    		} else {
    			this.tagify.addTags([{ value: value, class: "text-attribute", tag: "text" }]);
    		}
    		// operator or brackets    
    	} else {
    		this.tagify.addTags([{ value: value, class: "text-attribute", tag: "text" }]);
    	}

    	this.setActionItemsState("lockInline");
    	this.validateIfCanAddMoreAttributes();
    }

    private ifTagifyIsEmptyResetActionState() {
    	let tags = this.tagify.getTagElms();
    	if (tags.length === 0) {
    		this.setActionItemsState("resetAll");
    	}
    }

    private initTagify(mode: string) {
    	this.tagify = this.tagifyService.get('equationEditor');
    	this.tagify.on('click', (item) => {
    		let notAllowedToPopupSelectAttribute = ['text-attribute-disabled','text-attribute','selected-attribute-disabled']
    		if (!notAllowedToPopupSelectAttribute.includes(item.detail.data.class)) {
    			if ( !this.validateIfCanAddMoreAttributes() ) {
    				this.activeModalRef = this.modalService.openModalMessage(
    					'sm',
    					{
    						message: this.translate.instant(translateKeys.reachedLimitOfAttributes),
    						buttons: [
    							{
    								class: 'btn-primary',
    								label: this.translate.instant("general.OK"),
    								action: () => {
    									this.activeModalRef.hide();
    								}
    							}
    						]
    					},
                        <ModalOptions<any>>{ ignoreBackdropClick: false, keyboard: false }
    				);
    			} else {
    				this.openAttributePopup(item.detail.index);
    			}
    		}
    	});

    	// maitain this in inner scope
    	let this$ = this;
    	this.tagify.DOM.input.addEventListener('keydown', function (e) {
    		// can add tags only when in Formula base
    		if (this$.config.formulaType === FormulaType.DateBase) {
    			e.preventDefault();
    			return;
    		}

    		this$.config.formulaType = FormulaType.FormulaBase;
    		// enable only numbers | operators | brackets && Backspace
    		var reg = new RegExp('(^[0-9\.\+\-\/\*\(\)\"."])');
    		if (!reg.test(e.key) && e.key.match(/^Backspace$/) === null || this$.config.isDisabled === true) {
    			e.preventDefault();
    		}
    		// I want to be able to aggregate numbers from key stroke and mouse click
    		// or add operator tags from key stroke
    		else if (e.key.trim() > '' && reg.test(e.key) && e.key.match(/^Backspace$/) === null) {
    			this$.aggregateNumberOrAddOperatorAsTag(e.key);
    			e.preventDefault();
    		}
    	});

    	this.tagify.DOM.input.addEventListener('onpaste ', function (e) {
    		e.preventDefault();
    	});

    	this.tagify.DOM.input.addEventListener('contextmenu', function (e) {
    		e.preventDefault();
    	});
    }

    private increaseNumberOfAttributeRepetition(attributeName: string){
    	this.counterNumberOfAttributeTags++;
    	if (this.numberOfAttributeRepetition[attributeName] !== undefined) {
    		this.numberOfAttributeRepetition[attributeName]++;
    	} else {
    		this.numberOfAttributeRepetition[attributeName] = 1;
    	}

    	this.validateIfCanAddMoreAttributes();
    }

    private decreaseNumberOfAttributeRepetition(attributeName: string){
    	this.counterNumberOfAttributeTags--;
    	this.numberOfAttributeRepetition[attributeName]--;
    	// if no attributes for counter then remove
    	if (this.numberOfAttributeRepetition[attributeName] === 0) {
    		delete this.numberOfAttributeRepetition[attributeName];
    	}
    }

    private setKeyboardState(lock: boolean) {
    	this.config.items = this.config.items.map((x, i) => {
    		x['disabled'] = lock ? x.value !== "Reset" : false;
    		return x;
    	});
    }

    private setActionItemsState(state: ActionState) {
    	this.config.actionItems = this.config.actionItems.map((x, i) => {
    		x['disabled'] = state === "resetAll" ? false : state === "lockAll" ? true : x.value !== "+Add Attribute";
    		return x;
    	});
    }

    // customer can use only 9 attributes
    private validateIfCanAddMoreAttributes():boolean {
    	let canAddMoreAttributes = true;
    	// can add more attributes only when it is FormulaBase
    	if (this.config.formulaType === FormulaType.FormulaBase) {
    		const index = this.config.actionItems.findIndex(item => item.value === '+Add Attribute');
    		if (this.counterNumberOfAttributeTags === (this.config.numberOfAttributesAllowed * this.config.numberOfShowsPerAttribute)) {
    			canAddMoreAttributes = false;
    			this.config.actionItems[index].disabled = true;
    		} else { 
    			this.config.actionItems[index].disabled = false;
    		}
    	}
        
    	return canAddMoreAttributes;
    }

    private setDateBaseDefault(){
    	this.setKeyboardState(true);
    	this.setActionItemsState("lockAll");
    	this.metadata.formula = "function";
    }

    private initCalculatorButtons() { 
    	this.config.items = calculatorDatasource
    		.sort((a, b) => a.orderUI > b.orderUI ? 1 : -1)
    		.map(x => {
    			return {
    				orderUI: x.orderUI,
    				span: x.span,
    				displayName: x.displayName ?? x.value,
    				value: x.value,
    				action: () => {
    					switch (x.value) {
    						case "Reset":
    							this.tagify.removeAllTags();
    							this.setKeyboardState(false);
    							this.setActionItemsState("resetAll");
    							this.numberOfAttributeRepetition = {};
    							this.counterNumberOfAttributeTags = 0;
    							this.config.formulaType = FormulaType.NotSet;

    							this.isDateSelectedFirst = false;
    							// Tagify used setInterval for events by design: https://stackoverflow.com/a/1949416/104380
    							setTimeout(() => {
    								this.equationEditorFormControl.reset();
    							}); 
    							break;
    						default:
    							this.aggregateNumberOrAddOperatorAsTag(x.value);
    							break;
    					}
    					this.equationEditorFormControl.updateValueAndValidity();
    				}
    			};
    		});

    	this.config.actionItems = CalculatorActionItems
    		.map((x, i) => {
    			return {
    				orderUI: x.orderUI,
    				hidden: i === 1,
    				value: x.value,
    				displayName: x.displayName ?? x.value,
    				action: () => {
    					this.config.formulaType = FormulaType.DateBase;
    					switch (x.value) {
    						case "+Add Attribute":
    							this.config.formulaType = FormulaType.FormulaBase;
    							this.openAttributePopup(null);                              
    							this.validateIfCanAddMoreAttributes();
    							break;
    						case "Days since":
    							this.tagify.addTags([
    								{ value: "Days since", class: "text-attribute",readonly: true, tag: "text" },
    								{ value: "Select Attribute #1", class: "select-attribute", readonly: true,tag: "select" }
    							]);
    							break;
    						case "Days until":
    							this.tagify.addTags([
    								{ value: "Days until", class: "text-attribute", readonly: true,tag: "text" },
    								{ value: "Select Attribute #1", class: "select-attribute",readonly: true,tag: "select" }
    							]);
    							break;
    						case "Days between":
    							this.tagify.addTags([
    								{ value: "Days between", class: "text-attribute", tag: "text", readyonly: true },
    								{ value: "Select Attribute #1", class: "select-attribute", tag: "select", readyonly: true },
    								{ value: "and", class: "text-attribute", tag: "text", readyonly: true },
    								{ value: "Select Attribute #2", class: "select-attribute", tag: "select", readyonly: true }
    							]);

    							break;
    					}

    					if (this.config.formulaType === FormulaType.DateBase) {
    						this.setDateBaseDefault();
    						this.isDateSelectedFirst = true;
    					}
    					this.form.updateValueAndValidity();
    				}
    			};
    		});
    }

    private checkFormulaChanging(prev: any, next: any) {
    	// It emits true, if user does changes in formula
    	// And emits false, if user comes back to start stage of the formula 
        
    	if (!this.config.isAddMode && !this.isCalculatorValuesInit) {
    		this.isCalculatorValuesInit = true;
    		this.startCalculatorValue = prev.equationEditor;
    	}

    	// The following value is only used for edit mode, for add mode we check formula length
    	const isNextEquelToStart = this.isCalculatorValuesInit && next.equationEditor && 
            next.equationEditor.length === this.startCalculatorValue.length &&
            next.equationEditor.every((x, i) => x.value === this.startCalculatorValue[i].value)

    	if((!this.config.isAddMode && this.isCalculatorValuesInit && prev.equationEditor != next.equationEditor && !isNextEquelToStart) || 
           (this.config.isAddMode && next.equationEditor && prev.equationEditor != next.equationEditor && next.equationEditor.length != 0)) {
    		this.changeEmitter.emit(true);
    	}
    	else if ((!this.config.isAddMode && this.isCalculatorValuesInit && prev.equationEditor != next.equationEditor && isNextEquelToStart) || 
                 (!this.config.isAddMode && this.isCalculatorValuesInit && prev.equationEditor == next.equationEditor && isNextEquelToStart) ||
                 (this.config.isAddMode && next.equationEditor && prev.equationEditor != next.equationEditor && next.equationEditor.length == 0)) {
    		this.changeEmitter.emit(false);
    	}

    	this.attrManagerService.isAttributeEditorValid.next(this.form.valid);
    }

	get ignoreYearFormControl(): FormControl {
		return this.form.get('ignoreYear') as FormControl;
	}

    get equationEditorFormControl(): FormControl {
    	return this.form.get('equationEditor') as FormControl;
    }

    get formulaTypeFormControl(): FormControl {
    	return this.form.get('formulaType') as FormControl;
    }
    
}

type ActionState = "resetAll" | "lockAll" | "lockInline";
const translateKeys = {
	cancel: 'features.user_settings.body.attributes.addCalculatedAttribute.CANCEL',
	brandLabel: 'features.user_settings.body.subscribers.BRAND_LABEL',
	selectLabel: 'features.user_settings.body.subscribers.SELECT_LABEL',
	headerNotPublishedDesc: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.HEADER_NOT_PUBLISH_DESC',
	headerPublishedDesc: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.HEADER_PUBLISH_DESC',
	learnMore: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.LEARN_MORE',
	testFormula: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.TEST_FORMULA',
	invalidFormula: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.INVALID_FORMULA',
	format: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.FORMAT',
	reachedLimitOfAttributes: 'features.user_settings.body.attributes.addCalculatedAttribute.calculator.REACHED_LIMIT_OF_ATTRIBUTES',
	ignoreYear: "features.user_settings.body.attributes.addCalculatedAttribute.calculator.IGNORE_YEAR"
};