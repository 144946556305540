import { Injectable } from '@angular/core';
import { ManageTemplatesService } from './manageTemplates.service';
import { Observable, Subject } from 'rxjs';
import { TemplateKeysModel } from '../models/templateKeysModel';

@Injectable({
	providedIn: 'root'
})
export class TemplateDeleteService {
	private $deleting: Subject<boolean> = new Subject<boolean>();
	public deleting: Observable<boolean> = this.$deleting.asObservable();
	private $deleted: Subject<TemplateKeysModel[]> = new Subject<TemplateKeysModel[]>();
	public deleted: Observable<TemplateKeysModel[]> = this.$deleted.asObservable();
	private $undoing: Subject<boolean> = new Subject<boolean>();
	public undoing: Observable<boolean> = this.$undoing.asObservable();
	private $undoCompleted: Subject<boolean> = new Subject<boolean>();
	public undoCompleted: Observable<boolean> = this.$undoCompleted.asObservable();

	constructor(private manageTemplatesService: ManageTemplatesService) {}

	updateIsDeleted(templates: TemplateKeysModel[], isDeleted = true): void {
		if (isDeleted) this.$deleting.next(true);
		else this.$undoing.next(true);

		this.manageTemplatesService
			.updateIsDeletedForBatch({
				templateIdsWithSubMethod: templates.map((r) => {
					return { templateId: r.templateID, subMethodId: r.subMethodId };
				}),
				isDeleted: isDeleted
			})
			.subscribe((response) => {
				if (isDeleted) {
					this.$deleting.next(false);

					this.$deleted.next(templates);
				} else {
					this.$undoing.next(false);

					this.$undoCompleted.next(true);
				}
			});
	}
}
