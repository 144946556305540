<div class="channel-metrics-bar">
    <div class="row cms-channel">
        <h5 class="inline">{{channelData.channelName}}</h5>
    </div>
    <channel-metrics-data-bar *ngIf="channelData.metrics?.length"
                              [channelData]="channelData"
                              [datesData]="datesData"
                              [planDetailChannelId]="channelData.planDetailChannelId"
                              [isScheduledCampaign]='isScheduledCampaign'
                              [planIdActionSerial]='planIdActionSerial'
                              [actionType]="actionType"
                              (metricClick)="onMetricClick($event)">
    </channel-metrics-data-bar>
    <div class="empty-channel" *ngIf="!channelData.metrics?.length">
        <span class="empty-channel-text">
            {{unavailableMetricsPath | translate}}
        </span>
    </div>
    <div *ngIf="channelData.templatesData?.length > 1" class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
            <div class="col-md-4 col-md-offset-8">
                <span class="pull-right cmtb" (click)="onTemplatesBreakdown()">
                    {{templatesBreakdownPath | translate}}
                </span>
            </div>
        </div>
    </div>
</div>
