import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'marketing-plan-deprecation',
  templateUrl: './marketingPlanDeprecation.component.html',
  styleUrls: ['./marketingPlanDeprecation.component.scss']
})
export class MarketingPlanDeprecationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  mpDeprecationAcademyClick() {
    window.open('https://academy.optimove.com/en/article/the-mission-control', '_blank');
  }

  redirectToMissionControl() {
    this.router.navigate(['/category/one-to-one_campaigns/mission-control']);
  }

}
