import {Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation} from "@angular/core";
import {
    CHANNEL_METRICS_TRANSLATION_ROOT,
    IChannelMetricsElement,
    ITemplateData,
    MainMetricTypes
} from "../../campaignAnalysis.models";
import {
    TemplateClickMetricsResponse,
    TemplateClicksMetrics,
    TemplateDetailsPreviewData
} from "../templateHTMLModalContainer/models/templateModalData.model";
import {Navigatable} from "../../navigatable";
import {ChannelMetricsUtilitiesService} from "../../channelMetricsUtilities.service";
import {TranslateService} from "@ngx-translate/core";
import {TemplatesService} from "../../campaignTemplates.service";
import {TemplateHTMLModalContainerComponent} from "../templateHTMLModalContainer/templateHTMLModalContainer.component";
import {OptiLogicModalService} from '../../../../components/optiLogicModal/optiLogicModal.service';
import {TemplateModalType} from "../templateHTMLModalContainer/models/templateModalType.enum";
import {Observable} from "rxjs";

@Component({
    selector: 'channel-metrics-number',
    templateUrl: './channelMetricsNumber.component.html',
    styleUrls: ['./channelMetricsNumber.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsNumberComponent extends Navigatable implements OnChanges {

    @Input() channelId: number;
    @Input() templatesData: ITemplateData[];
    @Input() datesData: string[];
    @Input() planIdActionSerial: string[];
    @Input() planDetailChannelId: number;
    @Input() isScheduledCampaign: any;
    @Input() settingDates: string;
    @Input() metric: IChannelMetricsElement;
    @Output() metricClick: EventEmitter<{metricId, metricName}> = new EventEmitter();
    public tooltip: string;
    public collectedTemplateIds: number[] = [];
    public translatedClickMetricsData: TemplateClicksMetrics[] = [];
    public isTemplateMetricsExists: boolean = false;
    metricsTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'METRICS.';
    metricsTooltipTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'METRICS_TOOLTIP.';
    newMetricsTooltipTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'NEW_METRICS_TOOLTIP.';

    constructor(utils: ChannelMetricsUtilitiesService,
                private translate: TranslateService,
                private optiLogicModalService: OptiLogicModalService,
                private templatesService: TemplatesService) {
        super(utils);
    }

    ngOnInit() {
        // only for Optimail and click metric number
        if (this.channelId != 15 || this.metric.id != 3 || this.templatesData === undefined || this.templatesData.length == 0) {
            return;
        }
        this.getTemplateMetrics();
    }

    ngOnChanges() {
        if (this.channelId == 510 /* WebPush */ && this.metric.name === MainMetricTypes.Clicked) {
            this.tooltip = this.translate.instant(this.metricsTooltipTranslatePath + 'Clicked_WebPush');
        } else if (this.channelId == 8 /* Facebook */ && this.metric.name === MainMetricTypes.Clicked) {
            this.tooltip =  this.translate.instant(this.metricsTooltipTranslatePath + 'Clicked_Facebook');
        }
        else if (this.channelId == 441 /* Mobivate */ && this.metric.name === MainMetricTypes.Clicked) {
                this.tooltip = this.translate.instant(this.metricsTooltipTranslatePath + 'Clicked_Mobivate');
        } else if (this.channelId == 29) {
            this.tooltip = this.translate.instant(this.newMetricsTooltipTranslatePath + this.metric.name, {denominator: this.metric.denominatorName.toLowerCase()});
        } else {
            this.tooltip = this.translate.instant(this.metricsTooltipTranslatePath + this.metric.name);
        }
    }

    onMetricClick(metric: IChannelMetricsElement) {

        if (!this.isNavigatable(metric))
            return;

        let payload = {
            metricId: metric.id,
            metricName: metric.name,
        }

        this.metricClick.emit(payload);
    }

    onMapClick(metric: IChannelMetricsElement) {

        if (this.templatesData === undefined || this.templatesData.length == 0) {
            return;
        }
        const collectedTemplateIds = this.templatesData.map(templateData => templateData.templateId);
        var self = this;

        this.templatesService.getTemplateDetails(collectedTemplateIds, this.channelId).subscribe(templateDetails => {

            var fullTemplateDetails: TemplateDetailsPreviewData[] = self.templatesService.getTemplateDetailsPreviewData(self.translatedClickMetricsData, self.templatesData, templateDetails, this.isScheduledCampaign);
            const templateModalDataTemplates = {
                type : (fullTemplateDetails.length == 1) ? TemplateModalType.HTMLCarousel : TemplateModalType.Grid,
                toShowChannelName: false,
                templates : fullTemplateDetails
            };
            this.optiLogicModalService.open(TemplateHTMLModalContainerComponent, 'lg', {
                initialState: {
                    templateModalData: templateModalDataTemplates,
                    datesData: this.datesData
                },
                backdrop: true,
                animated: false,
                keyboard: true,
            });
        });
    }

    private getTemplateMetrics() {
        this.collectedTemplateIds = this.templatesData.map(templateData => templateData.templateId);
        const that = this;
        this.templatesService.getTemplateMetrics(this.datesData[0], this.datesData[1], this.planIdActionSerial[0], this.planIdActionSerial[1], this.isScheduledCampaign).subscribe((templateMetrics: TemplateClickMetricsResponse[]) => {
            if (templateMetrics.length == 0) return;
            that.isTemplateMetricsExists = false;
            templateMetrics.forEach(function (templateUrlOffsetObj: TemplateClickMetricsResponse) {
                const offset = templateUrlOffsetObj.LinkOffset;
                // check if the metrics has the same PlanDetailChannelId of this ui metrics bar. Since same template_id can be in different channels
                if (that.planDetailChannelId != templateUrlOffsetObj.PlanDetailChannelId) return;
                // use the multi id also because it can be same template ids in the campaign of muiltiple templates - concat as strings
                const id = "" + templateUrlOffsetObj.TemplateId + templateUrlOffsetObj.MultiId;
                if (typeof that.translatedClickMetricsData[id] == "undefined") {
                    that.translatedClickMetricsData[id] = {     TemplateId: templateUrlOffsetObj.TemplateId,
                                                                MultiId: templateUrlOffsetObj.MultiId,
                                                                UniqueRateValue: [], UniqueValue: [], TotalValue: []};
                }
                that.translatedClickMetricsData[id].UniqueRateValue[offset] = templateUrlOffsetObj.UniqueRateValue;
                that.translatedClickMetricsData[id].UniqueValue[offset] = templateUrlOffsetObj.UniqueValue;
                that.translatedClickMetricsData[id].TotalValue[offset] = templateUrlOffsetObj.TotalValue;
            });
            // if the relevant clicks metrics exist to enable the flag
            if (Object.keys(that.translatedClickMetricsData).length > 0) {
                that.isTemplateMetricsExists = true;
            }
        });
    }
}
