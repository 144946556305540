import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Observer, Subject } from 'rxjs';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';
import { PersonalizationTagsContainerComponent } from 'src/app/components/personalizationTagsContainer/personalizationTagsContainer.component';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { BeeFreePluginSetupData } from '../../models/beefree';
import { RoutingConsts } from '../../models/routing.consts';
import { TemplateDetails } from '../../models/templateDetails';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { BeefreeEditorService } from '../../services/beefreeEditor.service';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { SubMethodService } from '../../services/subMethodService';
import { TemplateContextService } from '../../services/templateContext.service';
import { TemplateSaveUpdateService } from '../../services/templateSaveUpdate.service';
import { TenantInformationService } from '../../services/tenantInformation.service';
import { EditRowModalComponent } from '../dialogs/editRowModal/editRowModal.component';
import { SaveRowModalComponent } from '../dialogs/saveRowModal/saveRowModal.component';
interface BeePlugin {
	start(arg: any): void;
}

@Component({
	selector: 'beefreePopup-container',
	templateUrl: './beefreePopupContainer.component.html',
	styleUrls: ['./beefreePopupContainer.component.scss']
})
export class BeefreePopupContainerComponent implements OnInit, OnDestroy {
	@Input() isFullscreen: boolean = false;

	readonly BEEJS_URL: string = 'https://loader.getbee.io/v1/api/loader';
	public template: TemplateDetails;
	public templateToChange: TemplateDetails;
	private beePluginInstance: BeePlugin;
	public subMethodTypeName: string;
	public subMethodId: string;
	public brandName: string;
	public tenantId: string;
	private unsubscribe$ = new Subject<any>();
	private queryParams: TemplateQueryParams;

	constructor(
		private readonly manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private translate: TranslateService,
		private modalService: OptiLogicModalService,
		private beeFreeService: BeefreeEditorService,
		private templateSaveUpdateService: TemplateSaveUpdateService,
		private subMethodService: SubMethodService,
		private tenantInformationService: TenantInformationService
	) {}

	ngOnInit(): void {
		this.templateContextService.current.pipe(takeUntil(this.unsubscribe$)).subscribe((response: TemplateDetails) => {
			this.template = JSON.parse(JSON.stringify(response));
			this.templateToChange = response;
		});

		this.templateContextService.queryTemplateParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: TemplateQueryParams) => {
			const tempQueryParams = this.queryParams;
			this.queryParams = params;
			if (!tempQueryParams || tempQueryParams.templateId !== this.queryParams.templateId) {
				this.loadBeeFree()
					.pipe(
						switchMap(() =>
							forkJoin([
								this.manageTemplatesService.getBeeFreePluginSetupData('Popup')
							])
						)
					)
					.subscribe((res) => this.initBeeFree(res[0], params));
			}
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	private beeFreeCreateCallback(beePluginInstance: any): void {
		this.beePluginInstance = beePluginInstance;

		this.templateContextService.current.pipe(take(1)).subscribe((res) => {
			this.beePluginInstance.start(this.beeFreeService.transformExtraDataToClientFormat(res.extraData)[1]);
		});
	}

	private loadBeeFree(): Observable<any> {
		return new Observable((observer: Observer<any>) => {
			var r: any = {
				_queue: []
			};
			if ((<any>window).BeePlugin) {
				observer.next(r);
				return;
			}

			var t = function () {
				var e = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				return Array.apply(null, Array(50))
					.map(function () {
						return e[Math.floor(Math.random() * e.length)];
					})
					.join('');
			};
			var n = document.createElement('script');
			n.type = 'text/javascript';
			(n.src = this.BEEJS_URL + '?v=' + t()), document.getElementsByTagName('head')[0].appendChild(n);
			var a = 'create'.split(',');
			var u = function (e: any, t: any) {
				return function () {
					t.push([e, arguments]);
				};
			};
			for (var i = 0; i < a.length; i++) {
				r[a[i]] = u(a[i], r._queue);
			}

			n.onload = function () {
				const state = (<any>this).readyState;
				if (!state || state == 'loaded' || state == 'complete') {
					// script successfully loaded
					observer.next(r);
				} else {
					observer.error(state);
				}
			};

			(<any>window).BeePlugin = r;
		});
	}

	private initBeeFree(setupData: BeeFreePluginSetupData, queryParams: TemplateQueryParams): void {
		const tenantInfo = this.tenantInformationService.getTenantInformation();
		this.tenantId = tenantInfo.tenantId;
		this.subMethodId = queryParams.typeId;
		const savedRowsUrl = setupData.savedRowsUrl;
		this.brandName = queryParams.brandId;
		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			const subMethodType = this.subMethodService.findSubmethodType(metadata, +this.subMethodId);
			this.subMethodTypeName = `${queryParams.brandId} & ${this.translate.instant(
				this.subMethodService.getSubMethodTypeText(subMethodType, RoutingConsts.WEB_PAGE_POPUP_CHANNEL)
			)}`;
			const beeConfig = {
				uid: '00' + tenantInfo.tenantId,
				container: 'bee-free-editor',
				contentDialog: this.getContentDialog(),
				autosave: 15,
				workspace: {
					popup: {
						layout: 'default',
						theme: 'bootstrap'
					}
				},
				onAutoSave: ((json, bamba) => this.onBeefreeSaveAsTemplate(json, bamba)).bind(this),
				onComment: ((response) => this.onBeefreeComment(response)).bind(this),
				onChange: (jsonFile, response) => {
					this.onBeeFreeChange(jsonFile, response);
				},
				onSaveRow: function onBeefreeSaveRow(jsonVal, html, pagePartial) {
					const jsonObj = JSON.parse(jsonVal);
					const row = {
						...jsonObj,
						metadata: {
							...jsonObj.metadata,
							originTemplateId: this.template.templateId
						}
					};
					const rowJson = JSON.stringify(row);
					this.beeFreeService
						.saveBeefreeSavedRow({
							rowObject: rowJson
						})
						.subscribe((res) => {
							res;
						});
				}.bind(this),
				trackChanges: true,
				username: tenantInfo.name,
				userHandle: btoa(tenantInfo.userName),
				rowsConfiguration: {
					emptyRows: true,
					defaultRows: true,
					externalContentURLs: [
						{
							name: `Custom Rows (${this.subMethodTypeName})`,
							value: savedRowsUrl + tenantInfo.tenantId + '/' + this.subMethodId + '.json',
							handle: this.subMethodId,
							behaviors: {
								canDelete: true,
								canEdit: true
							}
						},
						{
							name: 'Custom Rows (All brands & types)',
							value: savedRowsUrl + tenantInfo.tenantId + '/' + 'custom-rows-cross-brands.json',
							handle: 'cross-brands',
							behaviors: {
								canDelete: true,
								canEdit: true
							}
						}
					]
				},
				editorFonts: this.getEditorFonts()
			};

			(<any>window).BeePlugin.create(JSON.parse(setupData.token), beeConfig, this.beeFreeCreateCallback.bind(this));
		});
	}

	private onBeefreeComment(response) {
		if (response.change.type === 'NEW_COMMENT') {
			if (typeof this.templateSaveUpdateService.beeFreeNewCommentsNotifications === 'undefined') {
				this.templateSaveUpdateService.beeFreeNewCommentsNotifications = {};
			}
			this.collectMentionedUserNotifications(response.change.payload);
			this.collectNotifications(response.change.payload, response.comments);
		}

		// update the json data of comments since the auto-save is run for 15 secs so the data can be not updated
		// onBeefreeChangeCallback is not called for OnComment event
		var extraDataArr = JSON.parse(this.template.extraData);
		extraDataArr[1].comments = response.comments;
		this.template.extraData = JSON.stringify(extraDataArr);
	}
	private collectNotifications(newComment, allComments) {
		for (const key of Object.keys(allComments)) {
			// Find:  1) not same comment element 2) same parent 3) parent but with different author
			if (
				key !== newComment.commentId &&
				(key === newComment.comment.parentCommentId || newComment.comment.parentCommentId === allComments[key].parentCommentId) &&
				newComment.comment.author.userHandle !== allComments[key].author.userHandle
			) {
				const userEmailDecoded = atob(allComments[key].author.userHandle);
				if (this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId] === undefined) {
					this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId] = {
						content: newComment.comment.content,
						author: newComment.comment.author.username,
						recipients: [userEmailDecoded]
					};
				} else {
					// add if still not exist
					if (
						!this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId]['recipients'].includes(
							userEmailDecoded
						)
					) {
						this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId]['recipients'].push(
							userEmailDecoded
						);
					}
				}
			}
		}
	}

	private collectMentionedUserNotifications(newComment) {
		if (!newComment.comment.content.includes('@')) {
			return;
		}
		if (typeof this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'] === 'undefined') {
			this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'] = {};
		}
		this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'][newComment.commentId] = {
			content: newComment.comment.content,
			author: newComment.comment.author.username
		};
	}

	private onBeefreeSaveAsTemplate(json, bamba) {
		var extraDataArr = JSON.parse(this.template.extraData);
		extraDataArr[1] = JSON.parse(json);
		this.template.extraData = JSON.stringify(extraDataArr);
	}

	public onBeeFreeChange(json, response) {
		if (response.description !== 'Row') {
			let parsedJson = JSON.parse(json);
			this.template.extraData = this.beeFreeService.transformExtraDataToClientFormat(this.template.extraData);
			this.template.extraData[1].page = parsedJson.page;
			this.template.extraData = this.beeFreeService.transformExtraDataToServerFormat(this.template.extraData);
			this.templateContextService.updateTemplateExtraData(this.template.extraData);
		}
	}

	private getContentDialog(): any {
		return {
			mergeTags: {
				label: 'Personalization',
				handler: (resolve, reject) => {
					const chosen = new EventEmitter<string>();
					let modalRef;
					chosen.pipe(first()).subscribe((tag: string) => {
						if (tag.includes('_{X}')) {
							tag = this.getRecommendationTemplatesTagNumber(JSON.stringify(this.template.extraData[1]), tag);
						}
						resolve({ name: '', value: tag });
						modalRef?.hide();
					});
					modalRef = this.modalService.open(PersonalizationTagsContainerComponent, 'md', <ModalOptions<any>>{
						initialState: {
							subMethodId: this.template.subMethodType,
							chosen
						}
					});
				}
			},
			saveRow: {
				handler: function (resolve, reject, args) {
					const modalRef = this.modalService.open(SaveRowModalComponent, 'md', <ModalOptions<any>>{
						ignoreBackdropClick: true,
						initialState: {
							resolve: resolve,
							brandId: this.subMethodId,
							brandAndType: this.subMethodTypeName,
							tenantId: this.tenantId,
							brandName: this.brandName
						}
					});
				}.bind(this)
			},
			onDeleteRow: {
				handler: async (resolve, reject, args) => {
					this.beeFreeService.DeleteCustomRow(args.row).subscribe((res) => {
						if (res.isSuccess) {
							resolve(true);
						} else {
							reject(res);
						}
					});
				}
			},
			onEditRow: {
				handler: async (resolve, reject, args) => {
					this.modalService.open(EditRowModalComponent, 'md', <ModalOptions<any>>{
						ignoreBackdropClick: true,
						initialState: {
							rowData: args.row,
							resolve: resolve
						}
					});
				}
			}
		};
	}
	private getEditorFonts(): any {
		return {
			showDefaultFonts: true,
			customFonts: [
				{
					name: 'AntarcticaBeta Regular',
					fontFamily: '\'AntarcticaBeta-Regular\', sans-serif',
					fontWeight: 400,
					url: 'https://www.blu.com/fox-assets/fox-fonts.css'
				},
				{
					name: 'AntarcticaBeta Highlight',
					fontFamily: '\'AntarcticaBeta-Highlight\', \'Arial Narrow\', serif',
					url: 'https://www.blu.com/fox-assets/fox-fonts.css'
				},
				{
					name: 'HurmeGeometricSans3 Regular',
					fontFamily: 'HurmeGeometricSans3-Regular',
					url: 'https://emailblob.blob.core.windows.net/emailblob/GLOBAL/font2.css'
				},
				{
					name: 'HurmeGeometricSans3 SemiBold',
					fontFamily: 'HurmeGeometricSans3-SemiBold',
					url: 'https://emailblob.blob.core.windows.net/emailblob/GLOBAL/font2.css'
				},
				{
					name: 'CrystalBetUni',
					fontFamily: 'CrystalBetUni-Regular, CrystalBetUni-Medium, CrystalBetUni-Bold',
					url: 'https://crystalstatic.com/Content/Styles/fonts.css'
				}
			]
		};
	}

	private getRecommendationTemplatesTagNumber(content: string, tag: string): string {
		if (tag.indexOf('{X}') === -1) return tag;

		let tagName = tag.replace('X}%]', ''),
			maxNum = 1,
			tagNameLen: number = tagName.length,
			contentLen: number = content.length,
			startIndex: number = 0,
			index: number;

		while ((index = content.indexOf(tagName, startIndex)) > -1) {
			startIndex = index + tagNameLen;
			let theRestOfContent: string = content.substring(startIndex, contentLen);
			let tagNumber: number = parseInt(theRestOfContent.match(/\d+/)[0]);

			if (!isNaN(tagNumber) && tagNumber >= maxNum) {
				maxNum = ++tagNumber;
			}
		}

		return tag.replace('{X}', '{' + maxNum + '}');
	}
}
