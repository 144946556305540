import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { IconType } from '@optimove/ui-sdk/common/models';
import { Subscription, timer } from 'rxjs';
import { locationParameter } from '../../models/locationParameter.enum';
@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnChanges, OnDestroy{
  ToastIconTypeEnum = IconType;

  @Input() toastIconType: IconType = IconType.OptimoveIcon;
  @Input() icon: string;
  @Input() message: string;
  @Input() actionText: string;
  @Input() showCloseIcon = true;
  @Input() isOpen: boolean;
  @Input() location: locationParameter;
  @Input() closeTimeout: number = 3000;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() actionBtnClicked = new EventEmitter();
  @Output() closeBtnClicked = new EventEmitter();

  private subsctiptionOnToastClosed$: Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.isOpen){
      if(changes.isOpen.currentValue){
        this.subsctiptionOnToastClosed$.unsubscribe();
        this.subsctiptionOnToastClosed$ = timer(this.closeTimeout).subscribe(val => this.onCloseBtnClicked());
      } 
    } 
  }
  onActionBtnClicked() {
    this.actionBtnClicked.emit();
  }

  onCloseBtnClicked() {
    this.toggle();
    this.closeBtnClicked.emit()
  }

  toggle() {
    this.isOpen = false;
    this.isOpenChange.emit(this.isOpen)
  }
  
  ngOnDestroy(): void{
    this.subsctiptionOnToastClosed$.unsubscribe();
  }
}
