import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'angular-loader',
    templateUrl: 'loader.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./loader.component.scss']	
})

export class LoaderComponent implements OnInit {
    @Input() show = false;

    constructor() {
    }
    
    ngOnInit() {
    }
}