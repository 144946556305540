import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { exhaustMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { BiStudioService } from './services/biStudio.service';
import { models } from "../../../standalone_pages/Scripts/powerbi/powerbi";
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BiEmbedConfig } from './models/biStudio.model';
import { WindowRefService } from '../../services/windowRef.service';
import { TranslateService } from '@ngx-translate/core';
import { OptiLogicModalService } from '../../components/optiLogicModal/optiLogicModal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'bi-studio',
  templateUrl: './biStudio.component.html',
  styleUrls: ['./biStudio.component.scss']
})
export class BiStudioComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  @ViewChild('embedContainer') embedContainer: ElementRef<HTMLDivElement>;
  constructor(
    private readonly router: Router,
    private readonly biStudioService: BiStudioService,
    private readonly activateRoute: ActivatedRoute,
    private readonly windowRefService: WindowRefService,
    private readonly modalService: OptiLogicModalService,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    ).subscribe(event => this.handleSyncActiveReport(event.url));

    this.handleSyncActiveReport(this.activateRoute?.snapshot?.params?.reportName);
  }

  private handleSyncActiveReport(reportName: string) {
    if (reportName) {
      const type = this.biStudioService.getReportTypeByUrl(reportName);

      if (type != 0) {
        reportName = this.biStudioService.getReportNameByType(type).trim().replace(/ /g, '-');
      }

      this.biStudioService.getReports(type).pipe(
        tap(res => {
          if (res.data == null || res.data?.length === 0) {
            this.showNoReportsModal();
          }
        }),
        map(res => res.data?.find(report => reportName.includes(report.name.trim().replace(/ /g, '-')))),
        filter(biReport => biReport != null),
        exhaustMap(report => this.biStudioService.getReportToken(report, type).pipe(map(res => res.data)))
      ).subscribe((reportEmbedConfig: BiEmbedConfig) => this.setIframeContent(reportEmbedConfig));
    }
  }

  private showNoReportsModal() {
    this.modalService.openModalMessage('sm', {
      title: this.translateService.instant('features.bistudio.error_loading_report'),
      message: this.translateService.instant('features.bistudio.error_loading_report_message'),
      buttons: [
        {
          class: 'btn-primary',
          label: this.translateService.instant("general.OK"),
          action: () => {
          }
        }
      ]
    },
      <ModalOptions<any>>{
        ignoreBackdropClick: true,
      }
    );
  }

  private setIframeContent(reportEmbedConfig: BiEmbedConfig) {
    const embedConfig = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: reportEmbedConfig.embedToken.token,
      embedUrl: reportEmbedConfig.embedUrl,
      id: reportEmbedConfig.id,
      permissions: models.Permissions.All,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false
      }
    };

    this.windowRefService.nativeWindow.powerbi.embedNew(this.embedContainer.nativeElement, embedConfig);
    this.embedContainer.nativeElement.querySelector("iframe").style.border = "none";
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}