import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'campaignGridTriggerColumn',
    templateUrl: 'campaignGridTriggerColumn.component.html',
    styleUrls: ['./campaignGridTriggerColumn.component.scss']
})
export class CampaignGridTriggerColumnComponent implements ICellRendererAngularComp {
    public name: string;
    public uniqueId: string;
    public isScheduledCampaign: boolean;

    constructor() { }

    agInit(params: any): void {
        this.name = params.displayName;
    }

    refresh(): boolean {
        return true;
    }
}
