import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Channel } from '../interfaces';

interface TemplateData extends Channel {
    showImage: boolean;
    isImageError: boolean;
    imgUrl: string;
}

@Component({
    selector: 'campaign-grid-preview-column',
    templateUrl: 'campaignGridPreviewColumnHover.component.html',
    styleUrls: ['./campaignGridPreviewColumnHover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CampaignGridPreviewColumnHoverComponent implements ICellRendererAngularComp {
    public dataTemplate: TemplateData[] = [];
    ssm: any;

    constructor(@Inject('ssmService') private ssmService: any) {
        this.ssm = this.ssmService.getSSM();
    }


    agInit(params: any): void {
        this.dataTemplate = params.data?.Channels?.map(x => {
            return {
                showImage: false,
                isImageError: false,
                imgUrl: "",
                Id: x.Id,
                Name: x.Name,
                TemplateId: x.TemplateId,
                TemplateName: x.TemplateName
            }
        }) || [];
        this.preloadImage();
    }

    refresh(): boolean {
        return true;
    }

    preloadImage() {
        this.ssm.getGenericAsync(this.ssm.Resx.CampaignSettings).then((settings) => {
            const timeStamp = Math.floor(Date.now() / 1000);
            this.dataTemplate.forEach(data => {
                data.imgUrl = settings.templatesFolder + "/" + data.Id + "/" + data.TemplateId + '.jpeg?v=' + timeStamp;
                this.doLoadImage(data);
            });
        });
    }

    doLoadImage(data: TemplateData) {
        let image = new Image();
        image.src = data.imgUrl;
        if (image.complete) {
            data.showImage = true;
            data.isImageError = false;
            image.onload = () => { };
        }
        else {
            image.onload = () => {
                data.showImage = true;
                data.isImageError = false;
                //clear onLoad, IE behaves irratically with animated gifs otherwise
                image.onload = () => { };
            };
            image.onerror = () => {
                data.isImageError = true;
                image.onload = () => { };
            };
        }
    }
}