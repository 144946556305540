import {Component} from "@angular/core";
import {ILoadingCellRendererAngularComp} from "@ag-grid-community/angular";
import {ILoadingCellRendererParams} from "@ag-grid-community/all-modules";

@Component({
    selector: 'pt-loader',
    templateUrl: 'personalizationTagsLoader.html',
    styleUrls: ['personalizationTagsLoader.scss'],
})
export class PersonalizationTagsLoader implements ILoadingCellRendererAngularComp {

    agInit(params: ILoadingCellRendererParams): void {}
}
