import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { IListItem } from "@optimove/ui-sdk/common/models";

@Component({
    selector: 'inline-select',
    templateUrl: 'inlineSelect.component.html',
    styleUrls: ['inlineSelect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InlineSelectComponent),
        }
    ]
})
export class InlineSelectComponent implements ControlValueAccessor, OnInit, OnChanges {

    @Input() items: IListItem[];
    @Input() placeholder: string;
    @Input() compareFunction: (a: IListItem, b: IListItem) => number = this.compare;
    @Input() itemName: string = 'item';

    @ViewChild(NgSelectComponent, { static: false }) 
    private ngSelect: NgSelectComponent;

    public selected: any;
    public disabled: boolean;
    public showOnlySelected: boolean = false;
    public selectedItems: IListItem[];
    public isSelectAll: any;
    public isOpen: boolean;
    public groupByProperty: string | null = null;

    private readonly numberOfTooltipItemsToShow = 5;
    private onChangeEmitterFunc: any;
    private onTouchEmitterFunc: any;

    constructor(private translate: TranslateService, private cdRef: ChangeDetectorRef) { }
    
    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit() { }
    
    writeValue(value: any): void {
        this.initSelection(value);
        this.cdRef.markForCheck();
    }

    private initSelection(value: any) {
        this.selected = value;
        let valuesArray: any[] = [];
        if (value !== null) {
            valuesArray = [value];
        }
        
        this.selectedItems = this.items ? this.items.filter(i => valuesArray.includes(i.id)) : [];
    }

    registerOnChange(fn: any): void {
        this.onChangeEmitterFunc = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchEmitterFunc = fn;
    }
    
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdRef.markForCheck();
    }

    onSelectedOptionClicked() {
        this.showOnlySelected = !this.showOnlySelected;
    }

    onSelectAllClicked() {
        this.isSelectAll = !this.isSelectAll;

        if (this.isSelectAll) {
            const values = this.items.map(i => i.id);
            this.onModelChange(values);
        }
        else {
            const values = [];
            this.onModelChange(values);
        }
    }

    onBlur() {
        this.onTouchEmitterFunc();
    }

    trackBy(item: IListItem) {
        return item.id;
    }

    onOpen() {
        this.isOpen = true;
    }
    onClose() {
        this.isOpen = false;
    }

    onModelChange(value: any) {
        this.initSelection(value);
        this.onChangeEmitterFunc(value);
    }

    compare(a: IListItem, b: IListItem) {
        const aValue = a?.name ? a.name.toLocaleLowerCase() : '';
        const bValue = b?.name ? b.name.toLocaleLowerCase() : '';
        if (aValue < bValue) {
            return -1;
        }
        if (aValue > aValue) {
            return 1;
        }
        return 0;
    }

    onInputClick() {
        if (this.ngSelect.isOpen) {
            this.ngSelect.close();
        }
        else {
            this.ngSelect.open();
        }
    }
}