import { SuccessFailModalIconOptions } from './models/SuccessFailModalIconOptions.model';
import { Component, OnInit, Input, OnDestroy , ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { IModalConf } from "../../optiLogicModal.service";
import { WindowRefService } from "../../../../services/windowRef.service";

@Component({
    templateUrl: 'successFailModal.component.html',
    styleUrls: ['successFailModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SuccessFailModalComponent implements OnInit, OnDestroy {
    @Input() isSuccess: boolean;
    @Input() title: string;
    @Input() message: string;
    @Input() primaryButtonText: string;
    @Input() secondaryButtonText: string;
    @Input() thirdButtonText: string;
    @Input() iconOptions?: SuccessFailModalIconOptions;

    public primaryButtonClicked$: Subject<void> = new Subject();
    public secondaryButtonClicked$: Subject<void> = new Subject();
    public thirdButtonClicked$: Subject<void> = new Subject();
    
    public modalConfig: IModalConf;
    public redirectToPlanLabel: string;

    constructor(private translateService: TranslateService, private bsModalRef: BsModalRef, private windowRef: WindowRefService) {
        
    }

    ngOnInit() {
        this.setModalConfig();
    }

    private setModalConfig() {
        this.modalConfig = {
            hideHeaderPanel: true,
            hideFooterBorder: true,
            hideHeaderBorder: true,
            hideFooterPanel: true
        };
    }

    public onPrimaryButtonClick() {
        this.primaryButtonClicked$.next();
        this.closeModal();
    }

    public onSecondaryButtonClick() {
        this.secondaryButtonClicked$.next();
        this.closeModal();
    }

    public onThirdButtonClick() {
        this.thirdButtonClicked$.next();
        this.closeModal();
    }

    private closeModal() {
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.primaryButtonClicked$ && this.primaryButtonClicked$.unsubscribe();
        this.secondaryButtonClicked$ && this.secondaryButtonClicked$.unsubscribe();
        this.thirdButtonClicked$ && this.thirdButtonClicked$.unsubscribe();
    }
}