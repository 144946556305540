import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IModalButtonConf, IModalConf } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { ToastModel } from "src/app/features/missionControl/models/toast.model";
import { IconType } from "@optimove/ui-sdk/common/models";
import { locationParameter } from "src/app/models/locationParameter.enum";
import { ApiKeyManagementService } from "../apiKeyManagement.service";

@Component({
    selector: 'api-key-activated',
    templateUrl: './apiKeyActivated.component.html',
    styleUrls: ['./apiKeyActivated.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ApiKeyActivatedComponent implements OnInit {

    @Input() apiKey: string;
    @Input() redirectUrl: string;
    @Output() showApiCopiedToast = new EventEmitter<boolean>();
    @Output() showApiActivatedToast = new EventEmitter<boolean>();
    @Output() onToast = new EventEmitter<ToastModel>();
    
    public modalConf: IModalConf;
    public translateKeys = translateKeys;
    public loading: boolean = false;

    constructor(private bsModalRef: BsModalRef,
        private router: Router,
        private translateService: TranslateService,
        private apiKeyManagementService: ApiKeyManagementService) {}

    ngOnInit() {
        this.setModalConf();
    }

    getButtonsConf(): IModalButtonConf[] {
        return [
            {
                class: 'btn-primary',
                label: this.translateService.instant('general.OK'),
                action: () => { this.showNewActivatedKey(); },
                dataQaId: 'gotApiKeyBtn'
            }
        ];
    }

    setModalConf(): void {
        this.modalConf = {
            title: this.translateService.instant(this.translateKeys.title),
            buttons: this.getButtonsConf(),
            xButton: {
                action: () => { this.bsModalRef.hide(); }
            },
            withoutMinHeight: true
        };
    }

    async copyToClipboard(): Promise<void> {
        await parent.navigator.clipboard.writeText(this.apiKey);
        this.onToastEmit(this.translateService.instant(this.translateKeys.keyCopiedMsg));
    }

    private showNewActivatedKey() {
        this.router.navigateByUrl(this.redirectUrl);
        this.bsModalRef.hide();
        this.apiKeyManagementService.passShowApiActivatedToast(true);
    }

    private onToastEmit(msg: string){
        this.onToast.emit({isOpen: true, 
            message: msg, 
            icon: "info", 
            showCloseIcon: false, 
            location: locationParameter.Default,
            toastIconType: IconType.AngularMaterial
        });
    }
}

const translateKeys = {
    title: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.TITLE',
    importantNotice: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.IMPORTANCE_NOTICE',
    noticeMsg: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.NOTICE_MSG',
    apiKeyHeader: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.API_KEY_HEADER',
    keyCopiedMsg: 'features.user_settings.body.apiKeyManagement.apiKeyActivated.API_KEY_COPIED'
}
