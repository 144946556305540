import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { FeatureFlagService, FeatureFlag } from "../../services/featureFlag.service";

export class FeatureFlagGuard {

    static forFlags(flags: FeatureFlag[], blockOnDisabled?: boolean){
        
        @Injectable({ providedIn: 'root' })
        class FeatureFlagCheck implements CanActivate {   
           constructor(private featureFlagService: FeatureFlagService) {
           }
           
           canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {              
               if (!blockOnDisabled)
               {
                   return flags.every(flag => this.featureFlagService.isEnabled(flag));
               }
               else
               {
                   return flags.every(flag => !this.featureFlagService.isEnabled(flag));
               }
           }
       }
       return FeatureFlagCheck;
    }
    

}

