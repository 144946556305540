<div class="api-key-activated-modal-container">
    <opti-loading [show]="loading" [size]="'sm'"></opti-loading>
    <opti-logic-modal [modalConf]="modalConf">
        <div class="row msg-info">
            <div class="col-md-12">
                <div class="notice-msg">
                    <b>{{ translateKeys.importantNotice | translate }}</b><br>{{ translateKeys.noticeMsg | translate }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h4>{{ translateKeys.apiKeyHeader | translate }}</h4>
                <div data-qa-id="apiKeyHeader" class="input-box-info">
                    <h5 class="margins-inside-box">{{apiKey}}
                        <a class="copy-ability" (click)="copyToClipboard()">
                            <i class="material-icons pull-right">content_copy</i>
                        </a>
                    </h5>
                </div>
            </div>
        </div>
    </opti-logic-modal>
</div>
