<div class="row form-item justify-content-md-center" data-qa-id="move-template-submethod">
  <label class="col-md-3 right-column-label">
    {{ "features.manage_templates.components.move_template_modal.TEMPLATE_LABEL" | translate }}
  </label>
  <div class="col-md-9">
    <opti-search-list
      [items]="subMethods"
      (ngModelChange)="subMethodChanged($event)"
      [ngModel]="selectedSubMethodId"
      [disabled]="subMethodsDisabled"
      required="true"
      [config]="subMethodSearchConfig"></opti-search-list>
  </div>
</div>
<div class="row form-item justify-content-md-center" data-qa-id="move-template-folder">
  <label class="col-md-3 right-column-label">
    {{ "features.manage_templates.components.move_template_modal.FOLDER_LABEL" | translate }}
  </label>
  <div class="col-md-9">
    <opti-search-list
      [items]="foldersList"
      (ngModelChange)="folderChanged($event)"
      [ngModel]="selectedFolderId"
      [disabled]="false"
      required="true"
      [config]="folderSearchConfig"></opti-search-list>
  </div>
</div>
