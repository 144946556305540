import {Component, OnInit} from "@angular/core";
import './settingsSubscribers.component.scss';
import { SettingsSubscribersService } from "./settingsSubscribers.service";
import { IBrandDetails } from "./models/brandDetails.model";

@Component({
    selector: 'settings-subscribers',
    templateUrl: './settingsSubscribers.component.html'
})
export class SettingsSubscribersComponent implements OnInit {
    public loading: boolean = true;
    public brands: IBrandDetails[];

    constructor(private service: SettingsSubscribersService) { }

    ngOnInit(): void {
        this.service.getBrands()
            .subscribe(response => {
                //the purpose of this change is removing transactional brands from the brands list
                //because transactional brands don't support global unsubscribe list (ticket #160496)
                response = response.filter((brand) => !brand.isTransactional);
                this.brands = response;
                this.loading = false;
            }, (err) => {
                console.log(err);
            });
    }

}
