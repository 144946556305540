<div class="ios-notification-channel">
    <div class="row dynamic-row form-group">
        <p class="col-sm-2">{{'features.manage_templates.components.optipushNotificationChannels.IOS' | translate}}</p>
        <div class="col-sm-9">
            <input class="form-control"
                   id="channel" [ngModel]="channel" (ngModelChange)="channelChanged($event)"
                   type="text" placeholder="{{'features.manage_templates.components.optipushNotificationChannels.IOS_PLACEHOLDER' | translate}}">
            <p class="text-muted small" translate>
                features.manage_templates.components.optipushNotificationChannels.IOS_CAPTION
            </p>

        </div>
        <i class="material-icons notification-channels-tooltip"
           tooltip="{{ 'features.manage_templates.components.optipushNotificationChannels.IOS_TOOLTIP'| translate}}"
           placement="top">
            info_outline
        </i>
    </div>
</div>

