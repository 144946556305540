<div class="slider-container" [ngClass]="{'percentage': valueType === sliderValueType.Percentage}">
    <nouislider
            [ngModel]="sliderValue"
            (ngModelChange)="onSliderDraged($event)"
            [min]="min"
            [max]="max"
            [step]="sliderSteps"
            [tooltips]="[ true ]"
            [config]="{ margin: 0, connect: [true, true] }"
            [disabled]="isDisabled"></nouislider>
</div>
