import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges , ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'panel-with-accordion-sidebar',
    templateUrl: './panelWithAccordionSidebar.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./panelWithAccordionSidebar.scss']
})

export class PanelWithAccordionSidebar implements OnChanges {
    @Input() leftSidebarIsOpen = true;
    @Input() rightSidebarIsOpen = false;
    @Input() leftSizeMaxWidth: number;
    @Input() isResizeDisabled: boolean = false;
    @Input() leftSidebarHidden: boolean = false;

    @Output() toggleSideBar: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeRightSideBar: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.rightSidebarIsOpen && changes.rightSidebarIsOpen.previousValue !== changes.rightSidebarIsOpen.currentValue) {
            if (this.rightSidebarIsOpen) {
                this.leftSidebarIsOpen = false;
            }
        }
    }

    toggleLeftSidebar(){
        this.leftSidebarIsOpen = !this.leftSidebarIsOpen;
        if(this.rightSidebarIsOpen){
            this.rightSidebarIsOpen = !this.rightSidebarIsOpen;
            this.closeRightSideBar.emit(true);
        }
        this.toggleSideBar.emit(this.leftSidebarIsOpen);
    }
}