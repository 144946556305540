import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptiAccordionGroupComponent } from "./optiAccordionGroup.component";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { OptiAccordionBlockComponent } from './optiAccordionBlock/optiAccordionBlock.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    OptiAccordionGroupComponent,
    OptiAccordionBlockComponent
  ],
  exports: [
    OptiAccordionGroupComponent,
    OptiAccordionBlockComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    CommonModule,
    RouterModule,
    TranslateModule
  ]
})
export class OptiAccordionGroupModule { }
