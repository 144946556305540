<div class="top-bar-container">
  <div *ngIf="isOptiGenie">
    <div class="opti-genie" (click)="toggleOptiGenieIcon()"></div>
  </div>
  <div class="academy">
    <span class="material-icons" (click)="toggleAcademyIcon()"> school </span>
  </div>
  <div class="dailystatus" *ngIf="dailyStatus">
    <a href="javascript:void(0)" class="btn btn-link" (click)="$event.stopPropagation(); toggleDailyStatusMenu()">
      <span class="icon-sync-circle"></span>
      <opti-popover
        class="arrow-1 dailystatus-menu"
        *ngIf="isDailyMenuOpen"
        [isOpen]="isDailyMenuOpen"
        (closeCallback)="isDailyMenuOpen = false">
        <div class="day-status">
          <p>
            {{ "components.navbar.LAST_AVAIL_DATA" | translate }} <strong>{{ dailyStatus.lastSuccessfulRun }}</strong>
          </p>
          <p> {{ updateStatus | translate }} </p>

          <div class="status-icon">
            <ng-container *ngIf="!isDemoMode">
              <p>
                <span [ngSwitch]="dailyStatus.dataUpdateStatus" data-qa-id="dstatus-lastRun">
                  <i class="material-icons text-success" *ngSwitchCase="dataUpdateStatusEnum.Updated">check_circle</i>
                  <i class="material-icons text-info" *ngSwitchCase="dataUpdateStatusEnum.Updating">watch_later</i>
                  <i class="material-icons text-warning" *ngSwitchCase="dataUpdateStatusEnum.NotUpdated">error</i>
                </span>
                {{
                  dailyStatus.dataUpdateStatus === dataUpdateStatusEnum.Updated
                    ? ("components.navbarMenu.DATA.UPDATED" | translate)
                    : ("components.navbarMenu.DATA.UPDATE" | translate)
                }}
              </p>
              <p>
                <span [ngSwitch]="dailyStatus.campaignExecutionStatus" data-qa-id="dstatus-campaignExecution">
                  <i class="material-icons text-success" *ngSwitchCase="dataUpdateStatusEnum.Updated">check_circle</i>
                  <i class="material-icons text-info" *ngSwitchCase="dataUpdateStatusEnum.Updating">watch_later</i>
                  <i class="material-icons text-warning" *ngSwitchCase="dataUpdateStatusEnum.NotUpdated">error</i>
                </span>
                {{
                  dailyStatus.campaignExecutionStatus === dataUpdateStatusEnum.Updated ||
                  dailyStatus.campaignExecutionStatus === dataUpdateStatusEnum.NotUpdated
                    ? ("components.navbarMenu.CAMPAIGN.EXECUTED" | translate)
                    : ("components.navbarMenu.CAMPAIGN.EXECUTION" | translate)
                }}
              </p>
              <p>
                <span [ngSwitch]="dailyStatus.analysisStatus" data-qa-id="dstatus-analysis">
                  <i class="material-icons text-success" *ngSwitchCase="dataUpdateStatusEnum.Updated">check_circle</i>
                  <i class="material-icons text-info" *ngSwitchCase="dataUpdateStatusEnum.Updating">watch_later</i>
                  <i class="material-icons text-warning" *ngSwitchCase="dataUpdateStatusEnum.NotUpdated">error</i>
                </span>
                {{
                  dailyStatus.analysisStatus === dataUpdateStatusEnum.Updated
                    ? ("components.navbarMenu.Analysis.COMPLETED" | translate)
                    : ("components.navbarMenu.Analysis.ANALYSIS" | translate)
                }}
              </p>
            </ng-container>
            <ng-container *ngIf="isDemoMode">
              <p>
                <span>
                  <i class="material-icons text-success">check_circle</i>
                </span>
                {{ "components.navbarMenu.DATA.UPDATED" | translate }}
              </p>
              <p>
                <span>
                  <i class="material-icons text-success">check_circle</i>
                </span>
                {{ "components.navbarMenu.CAMPAIGN.EXECUTED" | translate }}
              </p>
              <p>
                <span>
                  <i class="material-icons text-success">check_circle</i>
                </span>
                {{ "components.navbarMenu.Analysis.COMPLETED" | translate }}
              </p>
            </ng-container>
          </div>
        </div>
      </opti-popover>
    </a>
  </div>
  <div class="separator"></div>
  <div class="user-area" (click)="$event.stopPropagation(); toggleUserMenu()">
    <div class="user-account" *ngIf="userFullName">
      <a href="javascript:void(0)" class="btn btn-link">
        {{ userFullName }} <span class="material-icons-outlined chevron">keyboard_arrow_down</span>
        <opti-popover class="arrow-1 user-menu" *ngIf="isUserMenuOpen" [isOpen]="isUserMenuOpen" (closeCallback)="isUserMenuOpen = false">
          <div class="menu">
            <a class="menu-row" href="javascript: void(0);" (click)="mailService.contactSupport()">
              <div class="row-wrap">
                <span class="material-icons-outlined"> 3p </span> {{ "components.navbar.CONTACT_SUPPORT_TITLE" | translate }}
              </div>
            </a>
            <a class="menu-row" target="_blank" href="javascript:void(0)" (click)="openOperationStatus()">
              <div class="row-wrap">
                <span class="material-icons-outlined"> speed </span> {{ "components.navbar.OPERATIONAL_STATUS_REPORT_TITLE" | translate }}
              </div>
            </a>
            <a class="menu-row" routerLink="/success-hub" *ngIf="isSucceessHub">
              <div class="row-wrap">
                <span class="material-icons-outlined"> task_alt </span> {{ "components.navbar.SuccessHub" | translate }}
              </div>
            </a>
            <a class="menu-row" href="https://community.optimove.com/" target="_blank">
              <div class="row-wrap">
                <span class="icon-OptiCircle"></span>
                {{ "components.navbar.OptiCircle" | translate }}
                <div class="navbar-badge opti-circle">New </div>
              </div>
            </a>
            <a class="menu-row" (click)="openCustomerAttributesModal()" href="javascript:void(0)">
              <div class="row-wrap">
                <span class="material-icons-outlined"> list_alt </span> {{ "components.navbar.Customer_Attributes" | translate }}
              </div>
            </a>
            <a class="menu-row" (click)="showAbout()" href="javascript:void(0)">
              <div class="row-wrap"> <span class="material-icons-outlined"> info </span> {{ "components.navbar.About" | translate }} </div>
            </a>
            <a class="menu-row" routerLink="/settings" *ngIf="isSettingsVisible">
              <div class="row-wrap">
                <span class="material-icons-outlined"> build_circle </span> {{ "components.navbar.Configuration_Tool" | translate }}
              </div>
            </a>
            <a class="menu-row" href="javascript:void(0)" (click)="logout()">
              <div class="row-wrap"> <span class="material-icons"> logout </span> {{ "components.navbar.Logout" | translate }} </div>
            </a>
          </div>
        </opti-popover>
      </a>
    </div>
    <div class="user-initial">
      {{ userInitials }}
    </div>
  </div>
</div>
