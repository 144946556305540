
<div class="panel panel-default">
    <customer-data-summary
        [customerId]="customerId"
        [clientCustomerId]="currentClientId"
        (onDataLoaded)="onDataLoadedEvent.emit()"
    ></customer-data-summary>
</div>

<div class="panel panel-default">
    <div class="panel-heading">
        <customer-impact-header
            [disabled]="!isCustomerImpactReady || !isSnapshotTableReady"
            (analysisRangeChanged)="onAnalysisRangeChanged($event)"
        ></customer-impact-header>
    </div>
    <div class="impact-panel-body">
        <customer-impact-metrics
            [clientCustomerId]="currentClientId"
            [dateRange]="currentAnalysisRange"
            (onDataLoaded)="onImpactMetricsLoaded()"
        ></customer-impact-metrics>
        <hr>
        <customer-impact-campaigns-table
            [clientCustomerId]="currentClientId"
            [customerId]="customerId"
            [dateRange]="currentAnalysisRange"
            (onDataLoaded)="onImpactCampaignsTableLoaded()"
        ></customer-impact-campaigns-table>
        <customer-impact-diagrams
            [clientCustomerId]="currentClientId"
            [dateRange]="currentAnalysisRange"
            (onDataLoaded)="onImpactDiagramsLoaded()"
        ></customer-impact-diagrams>
        <opti-loading 
            [show]="!isCustomerImpactReady" 
            [size]="'sm'"
        ></opti-loading>
    </div>
</div>

<div class="panel panel-default">
    <purchase-history-multi-attribute *ngIf="cogwheelNewPurchaseHistoryFF"
        [clientCustomerId]="currentClientId"
    ></purchase-history-multi-attribute>
    <purchase-history *ngIf="!cogwheelNewPurchaseHistoryFF"
         [clientCustomerId]="currentClientId"
    ></purchase-history>
</div>

<div class="panel panel-default">
    <customer-snapshots-table
        [clientCustomerId]="currentClientId"
        [dateRange]="currentAnalysisRange"
        (onDataLoaded)="onSnapshotTableLoaded()"
    ></customer-snapshots-table>
    <opti-loading 
        [show]="!isSnapshotTableReady" 
        [size]="'sm'"
    ></opti-loading>
</div>