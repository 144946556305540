import { ErrorHandler, Injectable } from '@angular/core';
import { WindowRefService } from './windowRef.service';

let newrelic: any;

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
    constructor(private windowRefService: WindowRefService) {
        super();
    }

    handleError(error: any) {
        super.handleError(error);
        this.newRelicErrorHandler(error);
    }
    
    newRelicErrorHandler(error: any) {
        const window = this.windowRefService.nativeWindow;

        if (window.newrelic) {
            try {
                window.newrelic.noticeError(error);
            } catch (newRelicError) {
                console.error(newRelicError);
            }
        }
    }
}