import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import {
    IActionMapping,
    ITreeOptions,
    ITreeState,
    KEYS,
    TREE_ACTIONS,
    TreeComponent,
    TreeModel,
    TreeNode
} from '@circlon/angular-tree-component';
import { EMPTY, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { PendoEventsService } from "../../services/pendoEvents.service";
import { encodeHtmlEntitiesIncludeDoubleSingleQuote } from "../../utils/htmlEncode";
import { JsTreeDataHelperService } from "./jsTree.dataHelper.service";
import { ITreeMenuItem } from "./menuItem.model";
import { TreeItem } from "./models/treeItem.model";
import { TreeStreamLevel } from "./models/treeStreamLevel.enum";

@Component({
    selector: 'js-tree',
    templateUrl: './jsTree.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./jsTree.component.scss']
})

export class JsTreeComponent implements OnChanges, AfterViewInit {
    public state: ITreeState;
    public nodeText: string;
    public hoveredNode: number;
    public actionMapping:IActionMapping = this.setActionMapping();
    public editId: number | null;
    public editPlaceholder: string;
    public hasNoSearchResults = false;
    public options: ITreeOptions;
    public searchValUpdate = new Subject<string>();
    public TreeEventType = treeEventType;
    public isRefreshing: boolean = false;
    public rightClickXPosition: number;
    public rightClickYPosition: number;
    public leftClickXPosition: number;
    public leftClickYPosition: number;
    public contextMenuItems: ITreeMenuItem[];
    public contextMenuNode: TreeNode;
    public moreActionsMenuNode: TreeNode;
    public moreActionsItems: ITreeMenuItem[];
    public treeButtonType = TreeButtonType;
    public isTreeScrolling = false;
    public treeModel:TreeModel;
    public filterVal: string = '';
    public secondarySelection: number[] = [];
    public isMultiActionDisabled: boolean = false;
    public nodeInEdit: any = null;
    public isTreeFocus = false;
    public moreMenuCategoryEnabled: any[] = [];
    public disabledNodesTitlesArr: string[] = [];
    private focusFromMouseEvent = false;

    private selectedNodeId: number;
    private currentTreeEventType: number;
    private dropEvent;

    @HostListener('document:mousedown', ['$event']) documentClick(event) {
        if(this.nodeInEdit &&
            Number(event.target.parentElement.id) !=  this.editId &&
            !event.target.className.includes('create-new-folder-btn') &&
            !event.target.className.includes('edit-node-name-btn') &&
            !event.target.parentElement.className.includes('modal') &&
            Number(event.target.id) != this.editId) {
            this.clickOutsideWhileInEdit();
        }

        this.isTreeScrolling = false;
    }
    @ViewChild('jsTree', {static: false}) treeComponent: TreeComponent;
    @ViewChild('editNodeInput', {static: false}) editNodeInput: any;
    @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
    
    @Input() set disabledNodesTitles(titles: string[] ) {
        this.disabledNodesTitlesArr = titles ? titles : [];
    }
    @Input() nodes: TreeItem[];
    @Input() configuration: ITreeConfiguration;
    @Input() treeActions: ITreeAction;
    @Input() rootNodeId: number = 0;
    @Input() focusOnSearch: boolean = false;
    @Input() enableHeader: boolean = true;
    @Output() treeEvents: EventEmitter<ITreeEvent> = new EventEmitter<ITreeEvent>();
    @Output() nodeSelected = new EventEmitter<TreeNode>();

    constructor(private helperService: JsTreeDataHelperService,
                private pendoEventsService: PendoEventsService,
                private eRef: ElementRef) {
        // Debounce search.
        this.searchValUpdate.pipe(
            debounce(val => (val === '') ? EMPTY : timer(400)),
            distinctUntilChanged())
            .subscribe(val => {
                this.search(val);
            });
    }

    ngAfterViewInit(): void {
        this.treeModel = this.treeComponent.treeModel;

        this.eRef.nativeElement.querySelector('tree-viewport')
            .addEventListener('scroll', this.onTreeScroll.bind(this));
           
        if (this.focusOnSearch) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            }, 250);
        }
    }

    updateData() {
        if(this.selectedNodeId) {
            this.selectANode(this.selectedNodeId);
        }
    }

    treeWasInitialized(): void {
        this.treeEvents.emit({
            node: null,
            eventName: treeEventType.treeInit
        });
    }

    isDragAllowed(node: TreeNode): boolean {
        return node.data.rel != 'category' && this.configuration.isDraggable && !this.isDragForbiddenForSpecialNode(node);
    }

    private isDragForbiddenForSpecialNode(node) {
    	if (node.data.streamLevel)
    		return true;
    	if (node.data.importedCustomersFolder || node.data.isUnderImportedCustomers)
    		return true;
    	return false;
    }

    private initTreeOptions(): void {
        this.options = {
            allowDrag: this.isDragAllowed.bind(this),
            allowDrop: (fromNode: any, to: any): boolean => {
                const toNode = to.parent;               
                return this.isAllowedDrop(fromNode, toNode);
            },
            displayField: 'text',
            actionMapping: this.actionMapping,
            animateSpeed: 30,
            animateAcceleration: 1.2,
            allowDragoverStyling: true,
            animateExpand: true,
            useVirtualScroll: true,
            dropSlotHeight: 0,
            nodeHeight: () => 26
        };

        if (this.configuration.buttonsAndTexts?.more) {
            this.configuration.buttonsAndTexts.more.forCat.forEach((catId: number) => {
                this.moreMenuCategoryEnabled[catId] = true;
            });
        }
    }

    //----- resolve / reject tree action -----

    ngOnChanges(changes: SimpleChanges) {
        if(changes.configuration && changes.configuration.currentValue) {
            this.initTreeOptions();
        }
        //---------- revoke action from outside ----------
        if(changes.treeActions && changes.treeActions.currentValue) {
            const e = changes.treeActions.currentValue;
            switch(e.eventName) {
                case treeEventType.addNode:
                case treeEventType.duplicateNode:
                    this.deselectAllOters(e.node.id);
                    this.addANode(e.node);
                    break;
                case treeEventType.editNodeName:
                    this.editANode(e.node);
                    break;
                case treeEventType.selectNode:
                    this.selectANode(e.nodeId);
                    break;
                case treeEventType.dropNode:
                    this.dropANode(e.fromTo);
                    break;
                case treeEventType.dropMultipleNodes:
                    this.moveMultiNodes(e.fromTo[0], e.fromTo[1]);
                    break;
                case treeEventType.deleteNode:
                case treeEventType.deleteNodes:
                    const isMulti = Array.isArray(e.nodeId);
                    isMulti ? this.deleteMultiNodeOrFolder(e.nodeId) : this.deleteANodeOrFolder(e.nodeId);
                    break;
                case treeEventType.addFolder:
                    this.addAFolder(e.node);
                    break;
                case treeEventType.updateNode:
                    this.updateANode(e.node);
                    break;
                case treeEventType.refresh:
                    this.isRefreshing = false;
                    break;
                case treeEventType.cancelEdit:
                    this.clearEditMode();
                    break;
                case treeEventType.folderWithSelection:
                    this.currentTreeEventType = treeEventType.addFolder;
                    let oldSel = this.secondarySelection;
                    this.addAFolder(e.node, false);

                    const activeNodes = this.getSelectedNodes(oldSel);
                    const toFolder = this.treeModel.getNodeById(e.node.id);
                    const from = this.treeModel.getNodeById(oldSel[0]);
                    const fromTo = {
                        from: from,
                        to: {
                            parent: toFolder,
                            index: 0,
                            dropOnNode: true
                        }
                    };

                    this.treeEvents.emit({
                        node: from,
                        eventName: treeEventType.dropMultipleNodes,
                        arg: [fromTo, this.secondarySelection, activeNodes]
                    });
                    break;
                case treeEventType.deselectNode:
                    this.deselectNode(e.nodeId);
                    break;
            }

            if (e.eventName !== treeEventType.refresh && e.eventName !== treeEventType.selectNode) {
                this.treeEvents.emit({
                    arg: this.nodes,
                    eventName: treeEventType.treeNodesChanged,
                    node: null,
                });
            }
        }

        if(changes.nodes && changes.nodes.currentValue) {
            this.helperService.setFlatTree(changes.nodes.currentValue);
        }
    }

    //---------- tree Mouse Actions ----------

    private setActionMapping(): IActionMapping {
        return {
            mouse: {
                contextMenu: (tree, node, $event) => {
                    $event.preventDefault();
                    if(this.isMultiActionDisabled) return;
                    this.rightClickXPosition = $event.pageX;
                    this.rightClickYPosition = $event.pageY;
                    this.contextMenuItems = this.getContextMenuItems(node.data, node.data.rel);
                    this.contextMenuNode = node;
                },
                dragStart: (tree:TreeModel, node:TreeNode, $event: any) => {
                    const { data } = node;
                    const { rel } = data;
                    if (rel === 'category') {
                        $event.preventDefault();
                        return;
                    }
                    this.setDraggedStyle(node, $event);
                },
                dragEnd: (tree:TreeModel, node:TreeNode, $event:any) => {
                    const el = document.getElementsByClassName("temp-tree-item")[0];
                    el.remove();
                },
                dragLeave: (tree:TreeModel, node:TreeNode, $event:any) => {
                    this.helperService.setDragOverBg(node, $event.event, false);
                },
                dragOver: (tree:TreeModel, node:TreeNode, $event:any) => {
                    this.hoveredNode = node.id;
                    this.helperService.setDragOverBg(node, $event.event, true);
              
                    if(!node.isLeaf) {
                        setTimeout(() => {
                            if(node.id === this.hoveredNode) {
                                TREE_ACTIONS.EXPAND(tree, node, $event);
                            }
                        }, 1499);
                    }
                },
                drop: (tree:TreeModel, node:TreeNode, $event:any, fromTo: IFromTo) => {
                    this.helperService.setDragOverBg(node, $event, false);
                    this.dropEvent = $event;
                    const toNode = fromTo.to.parent;
                    const toNodeParent = fromTo.to.parent.parent;
                    let activeNodesId: number[] = this.getSelectedNodesId(tree.activeNodeIds);

                    //case for dropping a non selected item
                    // if(!activeNodesId.includes(node.data.id)) {
                    //     activeNodesId = [node.data.id];
                    // }
                    if(toNode.data.rel == nodeType.leaf && toNodeParent && toNodeParent.data.rel != nodeType.leaf) {                        
                        fromTo.to.parent = toNodeParent;
                        // Bug #171736
                        if(toNode.isRoot){
                            fromTo.to.parent.id = this.rootNodeId;
                        }
                    }
                    if(activeNodesId.length > 1) {
                        const activeNodes = this.getSelectedNodes(this.secondarySelection);
                        const hasCategories = this.nodes[0].rel === "category";
                        if(hasCategories){
                            let selectedCategoryId = activeNodes[0].data.categoryId;
                            let toCategoryId = fromTo.to.parent.data.categoryId;
                            if(selectedCategoryId != toCategoryId) return;
                        }
                        this.treeEvents.emit({
                            node: node,
                            eventName: treeEventType.dropMultipleNodes,
                            arg: [fromTo, activeNodesId, activeNodes]
                        });
                    } else {
                        this.treeEvents.emit({
                            node: node,
                            eventName: treeEventType.dropNode,
                            arg: fromTo
                        });
                    }
                },
                click: (tree, node, $event) => {
                    this.onNodeClick(tree, node, $event);
                },
                dblClick: (tree, node, $event) => {
                    if(!this.disabledNodesTitlesArr.includes(node.data.text)) {
                        this.onNodeClick(tree, node, $event);

                        this.treeEvents.emit({
                            node: node,
                            eventName: treeEventType.doubleClick
                        });
                    }
                }, 
            },
            keys: {
                [KEYS.ENTER]: (tree, node, $event) => {
                    this.onNodeClick(tree, node, $event);
                }  
            }
        };
    }

    public onMoreActionsOpen(node, $event) {
        $event.preventDefault();
        this.leftClickXPosition = $event.pageX;
        this.leftClickYPosition = $event.pageY;
        this.moreActionsMenuNode = node;
        this.moreActionsItems = this.getMoreMenuItems(node.data);
    }

    private getMoreMenuItems(nodeData) {
        if (!this.configuration.moreActionsButtons) {
            return [];
        }

        const menuItems: ITreeMenuItem[] = [];

        for (let item in this.configuration.moreActionsButtons) {
            let event;
            switch(item) {
                case 'delete':
                    event = treeEventType.deleteNode;
                    break;
                case 'archive':
                    event = treeEventType.archive;
                    break;
                case 'restore':
                    event = treeEventType.restore;
                    break;
                case 'copyNode':
                    event = treeEventType.copyNode;
                    break;
            }

            if (this.configuration.moreActionsButtons[item].isEnable 
                && this.configuration.moreActionsButtons[item].forCat.includes(nodeData?.categoryId)) {
                menuItems.push({
                    text: this.configuration.moreActionsButtons[item].text,
                    icon: this.configuration.moreActionsButtons[item].icon,
                    eventType: event
                });
            }
        }

        return menuItems;
    }

    private onNodeClick(tree, node, $event) {
        if($event.target.className.includes('edit-node-name') || this.disabledNodesTitlesArr.includes(node.data.text)) {
            return;
        }
        if(node.id < 0 && !$event.target.className.includes('node-btn')) {
            if(node.isCollapsed) {
                TREE_ACTIONS.EXPAND(tree, node, $event);
            } else {
                TREE_ACTIONS.COLLAPSE(tree, node, $event);
            }
            return;
        }
        if(this.configuration.isMultiSelectEnable) {
            if($event.ctrlKey || $event.metaKey) {
                if(node.isActive) {
                    let index: number = this.secondarySelection.indexOf(node.data.id);
                    if (index !== -1) this.secondarySelection.splice(index, 1);
                    node.setIsActive(false, true);
                    node.data.secondarySelection = false;
                    node.blur();
                    this.treeEvents.emit({
                        node: node,
                        eventName: treeEventType.deselectNode
                    });
                } else {
                    const hasCategories = this.nodes[0].rel === nodeType.category;
                    if(hasCategories) {
                        if (this.secondarySelection.length > 0) {
                            let categories = this.treeModel.activeNodes.map(item => item.data.categoryId);
                            let currentCategory = categories[0];
                            // not allowed to select from another category for now
                            if (node.data.categoryId != currentCategory) {
                                return;
                            }
                        }
                    }
                    node.data.secondarySelection = true;
                    if(node.data.rel != nodeType.category)
                        this.secondarySelection.push(node.data.id);
                    TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event);
                }

                this.isMultiActionDisabled = this.checkIfDisableForCat();
                return;
            }
            else {
                for (let i: number = 0; i < this.secondarySelection.length; i++) {
                    let node = this.treeModel.getNodeById(this.secondarySelection[i]);
                    if(node)
                        delete node.data.secondarySelection;
                }

                this.secondarySelection = (node.data.rel == nodeType.category || (node.data.rel == nodeType.folder && node.isActive)) ? [] : [node.id];
            }
        }

        this.isMultiActionDisabled = this.checkIfDisableForCat();
        if(!this.nodeInEdit &&
        !$event.target.className.includes('node-btn')) {
            if(node.data.rel === nodeType.folder || node.data.rel === nodeType.category) {
                TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
                if(node.isCollapsed) {
                    TREE_ACTIONS.EXPAND(tree, node, $event);
                } else {
                    TREE_ACTIONS.COLLAPSE(tree, node, $event);
                }
                this.treeEvents.emit({
                    node: node,
                    eventName: treeEventType.selectFolder
                });
            } else {
                this.treeEvents.emit({
                    node: node,
                    eventName: treeEventType.selectNode
                });
            }
        }
    }

    //---------- actions from view ----------

    editEscClicked(e, node): void {
        e.currentTarget.value = node.data.text;
        this.clearEditMode();
    }

    public addFolderToTreeRoot(eventType: treeEventType): void {
        this.editPlaceholder = 'New Folder';
        this.currentTreeEventType = eventType;
        const id = this.helperService.getRandomId();
        this.nodeInEdit = {
            id: id,
            parentId: this.rootNodeId,
            rel: nodeType.folder,
            text: ""
        };

        this.nodes.unshift(this.nodeInEdit);
        this.sortFolder(this.rootNodeId);
        this.editId = id;

        const treeViewPort = this.eRef.nativeElement.querySelector('tree-viewport');
        if (treeViewPort) {
            treeViewPort.scrollTop = 0;
        }
        this.focusEditNodeInput();
    }

    private focusEditNodeInput() {
        setTimeout(()=>{
            if(!this.editNodeInput) {
                const treeViewPort = this.eRef.nativeElement.querySelector('tree-viewport');
                if (treeViewPort) {
                    treeViewPort.scrollTop = 0;
                }
            }

            this.editNodeInput && this.editNodeInput.nativeElement.focus();
        },500);
    }

    private addItemToTreeRoot(): void {
        const parentNode = {
            data: {
                id: this.rootNodeId,
                parentId: null,
                rel: nodeType.folder,
                text: ""
            }
        };
        this.treeEvents.emit(<any>{
            node: parentNode,
            eventName: treeEventType.addNode
        });
    }

    private checkIfDisableForCat(): boolean {
        const hasCategories = this.nodes[0].rel === nodeType.category;
        if(!hasCategories || !this.configuration.dotMenuButtons) return false;
        const forCatArr = this.configuration.dotMenuButtons.folderWithSelection.forCat;
        for (let i: number = 0; i < this.secondarySelection.length; i++) {
            const node = this.treeModel.getNodeById(this.secondarySelection[i]);
            if(!forCatArr.includes(node.data.categoryId)) //is folderWithSelection allowed for cat
                return true;
        }
        return false;
    }

    private addNode(parentNode: TreeNode): void {
        this.treeEvents.emit(<any>{
            node: parentNode,
            eventName: treeEventType.addNode
        });
    }

    public clearSearch() {
        this.filterVal = '';
        this.search('');
    }

    private search(val: string): void {
        if(val === '') {
            this.treeModel.clearFilter();
            //this.treeModel.collapseAll(); //too slow on bigger tree
            for (let i = 0; i < this.treeModel.nodes.length; i++) {
                let node = this.treeModel.getNodeById(this.treeModel.nodes[i].id);
                if (node) node.collapse();
            }
            this.treeModel.activeNodes.length && this.treeModel.activeNodes[0].setActiveAndVisible();
        } else {
            val = encodeHtmlEntitiesIncludeDoubleSingleQuote(val);
            val = val.toLowerCase();
            this.treeModel.filterNodes((node) => this.filterNode(node, val));
        }

        this.hasNoSearchResults = this.eRef.nativeElement.getElementsByClassName('mainNode').length === 0;
    }

    private filterNode(node: any, searchText: string): boolean {
        const nodeText = node.data.text.toLowerCase();
        if (nodeText.includes(searchText)) {
            return true;
        }

        const parentNode = node.parent;
        if (parentNode === null || parentNode.data.text === undefined) {
            return false;
        }

        return this.filterNode(parentNode, searchText);
    }

    refreshTree(): void {
        this.isRefreshing = true;
        this.treeEvents.emit(<any>{
            node: null,
            eventName: treeEventType.refresh
        });
    }

    //blue plus menu
    onMenuItemClicked(treeEvent: treeEventType) {
        const activeFolder = this.getActiveFolderNode();
        if(this.filterVal != '') {
            this.clearSearch();
        }
        switch (treeEvent) {
            case treeEventType.addFolder:
                if(activeFolder) {
                    this.addFolder(activeFolder);
                } else {
                    this.addFolderToTreeRoot(treeEventType.addFolder);
                }
                break;
            case treeEventType.addNode:
                if(activeFolder) {
                    this.addNode(activeFolder);
                } else {
                    this.addItemToTreeRoot();
                }
                break;
        }
    }

    //3 dots top menu
    onDotMenuItemClicked(treeEvent: treeEventType) {
        const activeNodes: TreeNode[] = this.getSelectedNodes(this.secondarySelection);

        switch (treeEvent) {
            case treeEventType.deleteNodes:
                this.treeEvents.emit({
                    node: activeNodes,
                    eventName: treeEvent
                });
                break;
            case treeEventType.folderWithSelection:
                this.addFolderByCategory(activeNodes[0]);
                break;
        }
    }

    //right click menu
    onNodeAction(inputNode: TreeNode | TreeNode[] | null | number[], eventType: treeEventType | string): void {
        let node: TreeNode = inputNode as TreeNode;
        this.currentTreeEventType = Number(eventType);
        switch(this.currentTreeEventType) {
            case treeEventType.deleteNodes:
            case treeEventType.deleteNode:
                const activeNodes: TreeNode[] = this.getSelectedNodes(this.secondarySelection);
                const n = (activeNodes.length > 1) ? activeNodes : node;
                this.treeEvents.emit({
                    node: n,
                    eventName: this.currentTreeEventType
                });
                break;
            case treeEventType.addNode:
                this.treeEvents.emit({
                    node: node,
                    eventName: this.currentTreeEventType
                });
                break;
            case treeEventType.editNodeName:
                this.editNodeName(node);
                break;
            case treeEventType.duplicateNode:
                const newNode = {
                    data: {
                        id: node.data.id,
                        parentId: node.data.parentId,
                        rel: node.data.rel,
                        text: this.helperService.getDuplicateNodeName(node),
                        categoryId: node.data.categoryId
                    },
                    parent: node.parent,
                };
                this.treeEvents.emit(<any>{
                    node: newNode,
                    eventName: this.currentTreeEventType
                });
                break;
            case treeEventType.addFolder:
                this.addFolder(node);
                break;
            case treeEventType.folderWithSelection:
                this.addFolderByCategory(node);
                break;
            case treeEventType.archive:
            case treeEventType.restore: {
                this.archiveOrRestoreItems(node, eventType);
                break;
            }
            case treeEventType.copyNode: {
                this.copyNode(node, eventType);
                break;
            }
            case treeEventType.newInstance: {
                this.newInstance(node, eventType);
                break;
            }
            case treeEventType.editLeafNodeData: {
                this.treeEvents.emit(<any>{ 
                    node: node, 
                    eventName: this.currentTreeEventType
                });
            }
        }
    }

    private copyNode(node, eventType){
        this.treeEvents.emit(<any>{
            node: node,
            eventName: treeEventType.copyNode
        });
    }

    private newInstance(node, eventType){
        this.treeEvents.emit(<any>{
            node: node,
            eventName: treeEventType.newInstance
        });
    }



    private archiveOrRestoreItems(node, eventType) {
        const fromTo = {
            from: node,
            roots: this.treeModel.roots,
            subEventType: eventType,
            to: {
                index: 0
            },
        };
        this.treeEvents.emit({
            node: node,
            eventName: treeEventType.dropNode,
            arg: fromTo
        });
    }

    private addFolderByCategory(node: TreeNode){
        const hasCategories = this.nodes[0].rel === "category";
        if(hasCategories) {
            for (let i: number = 0; i < this.nodes.length; i++) {
                const theRealNodeObj = this.treeModel.getNodeById(this.nodes[i].id);
                if(this.iAmYourFather(theRealNodeObj, node)) {
                    this.addFolder(theRealNodeObj);
                    this.currentTreeEventType = treeEventType.folderWithSelection;
                    break;
                }
            }
        } else {
            this.addFolderToTreeRoot(treeEventType.folderWithSelection);
        }
    }

    private addFolder(parentFolder: TreeNode) {
        if (parentFolder.data.streamLevel === TreeStreamLevel.Root) {
            this.treeEvents.emit({
                node: parentFolder.data,
                eventName: treeEventType.addFolder
            });
            return;
        }
        
        if(this.editId) {
            this.clearEditMode();
            return;
        }
        this.editPlaceholder = 'New Folder';
        this.currentTreeEventType = treeEventType.addFolder;
        this.nodeText = '';
        const id = this.helperService.getRandomId();
        this.nodeInEdit = {
            id: id,
            parentId: parentFolder.id,
            rel: "folder",
            text: "",
            categoryId: parentFolder.data.categoryId
        };
        if(parentFolder.data.children === undefined) {
            parentFolder.data.children = [];
        }
        parentFolder.data.children.unshift(this.nodeInEdit);
        this.treeModel.update();
        this.editId = id;
        this.expandNode(parentFolder.id);
        this.focusEditNodeInput();
    }

    private editNodeName(node: any): void {
        if(this.editId) {
            this.clearEditMode();
            return;
        }
        this.nodeInEdit = node;
        this.options.allowDrag = () => (false);
        this.editPlaceholder = 'please enter';
        this.currentTreeEventType = treeEventType.editNodeName;
        this.nodeText = this.helperService.decodeEntities(node.data.text);
        this.editId = node.data.id;
    }

    public clickOutsideWhileInEdit(): void {
        this.options.allowDrag = () => (true);
        switch(this.currentTreeEventType) {
            case treeEventType.folderWithSelection:
            case treeEventType.addFolder:
                if(!this.nodeText || this.nodeText.trim() == '') {
                    this.clearEditMode();
                } else {
                    this.nodeInEdit.text = this.nodeText;

                    this.treeEvents.emit({
                        node: this.nodeInEdit,
                        eventName: this.currentTreeEventType
                    });
                }
                break;
            case treeEventType.duplicateNode:
            case treeEventType.editNodeName:
                if(this.nodeInEdit && this.nodeInEdit.data.text != this.nodeText && this.nodeText.trim() != '') {
                    this.treeEvents.emit({
                        node: this.nodeInEdit,
                        eventName: this.currentTreeEventType,
                        arg: this.nodeText
                    });
                    const newText = (this.nodeText) ? this.nodeText.trim() : this.nodeInEdit.data.text.trim();
                    if (newText.length >= 850) {
                        this.clearEditMode();
                    }
                } else {
                    this.clearEditMode();
                }
                break;
        }
    }

    //---------- actions from outside ----------

    private addANode(node: any, setSelected?: any): void {
        node.text = this.helperService.encodeEntities(node.text);

        if(node.parentId === undefined) {
            node.parentId = this.rootNodeId;
        }
        if(node.parentId === this.rootNodeId) {
            this.nodes.push(node);
        }
        else {
            const parentFolder = this.treeModel.getNodeById(node.parentId);

            if(parentFolder.data.children === undefined) {
                parentFolder.data.children = [];
            }

            parentFolder.data.children.push(node);

        }

        this.sortFolder(node.parentId);
        const n = this.treeModel.getNodeById(node.id);
        if(setSelected === false) {
            n.scrollIntoView();
        } else {
            n.setActiveAndVisible();
        }
    }

    private deselectAllOters(nodeId: number) {
        let sel: number[] = this.secondarySelection;
        if(sel.length > 1) {
            for(let i: number = 0; i < sel.length; i++) {
                if(sel[i] != nodeId) {
                    this.deselectNode(sel[i]);
                    const originNode = this.treeModel.getNodeById(sel[i]);
                    originNode.data.secondarySelection = false;
                }
            }
        }
        this.secondarySelection = [nodeId];
    }

    private addAFolder(node: any, setSelected?: any): void {
        this.clearEditMode();
        node.children = [];
        this.addANode(node, setSelected);
    }

    private updateANode(node: any): void {
        const originNode = this.treeModel.getNodeById(node.id);
        originNode.data.text = this.helperService.encodeEntities(node.text);
        if(node.parentId != originNode.data.parentId) {
            this.deleteANodeOrFolder(node.id);
            this.addANode({...originNode.data, ...node});
        }
    }

    private editANode(node: any): void {
        node.data.text = this.helperService.encodeEntities(this.nodeText);
        this.clearEditMode();
    }

    private selectANode(nodeId: number):void {
        if (!this.treeModel) return;

        this.selectedNodeId = nodeId;
        const node = this.treeModel.getNodeById(nodeId);
        if (!node) return;
        this.nodeSelected.emit(node);
        node.setActiveAndVisible();
    }

    private deselectNode(nodeId: number): void {
        const node = this.treeModel.getNodeById(nodeId);
        node.setIsActive(false, false);
        node.blur();
        if(this.secondarySelection.length === 1)
            this.secondarySelection = [];
    }

    private dropANode(fromTo: any): void {
        this.treeModel.moveNode(fromTo.from, fromTo.to);
        this.sortFolder(fromTo.to.parent.id);
        const node = this.treeModel.getNodeById(fromTo.from.id);
        node.setActiveAndVisible();
    }

    private deleteMultiNodeOrFolder(nodeIdArr: number[]):void {
        let node, parentNode, index;
        for (let i: number = 0; i < nodeIdArr.length; i++) {
            node = this.treeModel.getNodeById(nodeIdArr[i]);
            parentNode = node.parent.data;
            index = parentNode.children.indexOf(node.data);

            if (index !== -1)
                parentNode.children.splice(index, 1);
        }

        this.nodeText = '';
        this.nodeInEdit = null;
        this.editId = null;
        this.treeModel.update();

        if(this.secondarySelection.length === nodeIdArr.length && nodeIdArr.sort().toString() == this.secondarySelection.sort().toString() )
            this.secondarySelection = [];
    }

    private deleteANodeOrFolder(nodeId: number):void {
        const node = this.treeModel.getNodeById(nodeId);
        const parentNode = node.parent.data;
        const index = parentNode.children.indexOf(node.data);

        if (index !== -1)
            parentNode.children.splice(index, 1);

        this.nodeText = '';
        this.nodeInEdit = null;
        this.editId = null;
        this.treeModel.update();

        if(this.secondarySelection.length === 1 && this.secondarySelection[0] == nodeId)
            this.secondarySelection = [];
    }

    private sortFolder(folderId: number): void {
        const isRoot = (folderId === this.rootNodeId);
        const node = this.treeModel.getNodeById(folderId);
        let folders = [];
        let leafs = [];
        let children = isRoot ? this.nodes : node.data.children;

        for (let i: number = 0; i < children.length; i++) {
            if(children[i].rel === nodeType.folder || children[i].rel === nodeType.category) {
                folders.push(children[i]);
            } else {
                leafs.push(children[i]);
            }
        }

        folders = this.helperService.sortAtoZ(folders);
        leafs = this.helperService.sortAtoZ(leafs);
        if(isRoot) {
            this.nodes = folders.concat(leafs);
        } else {
            node.data.children = folders.concat(leafs);
        }

        this.treeModel.update();
    }

    private expandNode(id: number): void {
        const someNode = this.treeModel.getNodeById(id);
        someNode.expand();
    }

    private clearEditMode(): void {
        if((this.currentTreeEventType === treeEventType.addFolder || this.currentTreeEventType === treeEventType.folderWithSelection) && this.nodeInEdit) {
            this.deleteANodeOrFolder(this.nodeInEdit.id);
        }
        this.currentTreeEventType = null;
        this.nodeText = '';
        this.nodeInEdit = null;
        this.editId = null;
    }

    private setDraggedStyle(node: TreeNode, e: any): void {
        const el = document.getElementById(node.id);
        let crt = <HTMLElement>el.cloneNode(true);
        let txt = crt.children[1].textContent;
        if(txt.length > 15) {
            txt = txt.substring(0, 12) + '...';
        }
        crt.children[1].textContent = txt;
        crt.classList.add("temp-tree-item");
        document.body.appendChild(crt);
        e.dataTransfer.setDragImage(crt, 0, 0);
    }

    private getActiveFolderNode(): TreeNode {
        if (!this.treeModel.activeNodes || this.treeModel.activeNodes.length === 0) {
            return null;
        }

        const activeNode = this.treeModel.activeNodes[0];
        if (activeNode.data.rel === nodeType.folder || activeNode.data.rel === nodeType.category) {
            return activeNode;
        }

        if (activeNode.data.parentId === this.rootNodeId) {
            return null;
        }

        return this.treeModel.getNodeById(activeNode.data.parentId);
    }

    private isButtonDisplayed(btnType: TreeButtonType, categoryId?:number): boolean {
        if (btnType === TreeButtonType.more) return false;
        if(this.configuration && this.configuration.buttonsAndTexts[btnType] && this.configuration.buttonsAndTexts[btnType].isEnable) {
            if(categoryId && this.configuration.buttonsAndTexts[btnType].forCat && !this.configuration.buttonsAndTexts[btnType].forCat.includes(categoryId)) {
                return false;
            }
            return true;
        }
        else if (this.configuration && this.configuration.moreActionsButtons && this.configuration.moreActionsButtons[btnType] && this.configuration.moreActionsButtons[btnType].isEnable) {
            if(categoryId && this.configuration.moreActionsButtons[btnType].forCat && !this.configuration.moreActionsButtons[btnType].forCat.includes(categoryId)) {
                return false;
            }
            return true;
        }
        return false;
    }

    private isVisibleForContextMenuFromActionMenu(btnType, categoryId, importedCustomersFolder? : boolean): boolean {
        if (importedCustomersFolder) return false;
            return this.configuration 
                && this.configuration.moreActionsButtons 
                && this.configuration.moreActionsButtons[btnType] 
                && this.configuration.moreActionsButtons[btnType].forCat?.includes(categoryId);
    }

    public folderNodeIsButtonDisplayed(btnType: TreeButtonType, categoryId?:number, streamLevel?: number, isOptibotFolder?: boolean, isImportedCustomersFolder?: boolean): boolean {
        if (!this.isButtonDisplayed(btnType, categoryId)) {
            return false;
        }

        if (this.configuration 
            && this.configuration.moreActionsButtons 
            && this.configuration.moreActionsButtons.delete 
            && btnType === TreeButtonType.delete) {
            return false;
        }

        switch(btnType) {
            case TreeButtonType.newNode:
                return !streamLevel || streamLevel === TreeStreamLevel.Stream;
            case TreeButtonType.newFolder:
                return (streamLevel != TreeStreamLevel.Test);
            case TreeButtonType.editNode:
                return (!isOptibotFolder && !isImportedCustomersFolder && streamLevel != TreeStreamLevel.Root);
            case TreeButtonType.delete:
            case TreeButtonType.archive:
            case TreeButtonType.restore:
                return (!isOptibotFolder && !isImportedCustomersFolder && streamLevel != TreeStreamLevel.Root && streamLevel != TreeStreamLevel.Stream);
            default:
                return false;
        }
    }

    public leafNodeIsButtonDisplayed(btnType: TreeButtonType, categoryId?:number, isNoButtons?: boolean): boolean {
        if (!this.isButtonDisplayed(btnType, categoryId)) {
            return false;
        }

        if (isNoButtons) {
            return false;
        }

        if (this.configuration 
                && this.configuration.moreActionsButtons 
                && this.configuration.moreActionsButtons.delete 
                && btnType === TreeButtonType.delete) {
            return false;
        }

        return (btnType === TreeButtonType.duplicateNode || btnType === TreeButtonType.newInstance || btnType === TreeButtonType.copyNode || btnType === TreeButtonType.delete || btnType === TreeButtonType.editLeafNodeData);
    }

    public categoryNodeIsButtonDisplayed(btnType: TreeButtonType, categoryId?:number): boolean {
        if (!this.isButtonDisplayed(btnType, categoryId)) {
            return false;
        }

        return (btnType === TreeButtonType.newNode || btnType === TreeButtonType.newFolder);
    }

    private getContextMenuItems(nodeData: any, nodeTypeValue: nodeType): ITreeMenuItem[] {
        const contextMenuItems: ITreeMenuItem[] = [];
        const btnTypes = Object.values(TreeButtonType);

        switch (nodeTypeValue) {
            case nodeType.folder:
                btnTypes.forEach(btnType => {
                    if (this.folderNodeIsButtonDisplayed(btnType, nodeData.categoryId, nodeData.streamLevel, nodeData.optibotFolder, nodeData.importedCustomersFolder)
                        || this.isVisibleForContextMenuFromActionMenu(btnType, nodeData.categoryId, nodeData.importedCustomersFolder)) {
                        contextMenuItems.push({
                            eventType: this.convertToTreeEventType(btnType),
                            text: this.configuration.buttonsAndTexts[btnType]?.text ?? this.configuration.moreActionsButtons[btnType]?.text
                        });
                    }
                });
                break;
            case nodeType.leaf:
                btnTypes.forEach(btnType => {
                    if (this.leafNodeIsButtonDisplayed(btnType, nodeData.categoryId, nodeData.noButtons)
                        || this.isVisibleForContextMenuFromActionMenu(btnType, nodeData.categoryId)) {
                        contextMenuItems.push({
                            eventType: this.convertToTreeEventType(btnType),
                            text: this.configuration.buttonsAndTexts[btnType]?.text ?? this.configuration.moreActionsButtons[btnType]?.text
                        });
                    }
                });
                break;
            case nodeType.category:
                btnTypes.forEach(btnType => {
                    if (this.categoryNodeIsButtonDisplayed(btnType, nodeData.categoryId)) {
                        contextMenuItems.push({
                            eventType: this.convertToTreeEventType(btnType),
                            text: this.configuration.buttonsAndTexts[btnType].text
                        });
                    }
                });
                break;
        }

        return contextMenuItems;
    }

    private convertToTreeEventType(btnType: TreeButtonType): treeEventType {
        switch (btnType) {
            case TreeButtonType.delete:
                return treeEventType.deleteNode;
            case TreeButtonType.duplicateNode:
                return treeEventType.duplicateNode;
            case TreeButtonType.editNode:
                return treeEventType.editNodeName;
            case TreeButtonType.newFolder:
                return treeEventType.addFolder;
            case TreeButtonType.newNode:
                return treeEventType.addNode;
            case TreeButtonType.more:
                return treeEventType.openMore;
            case TreeButtonType.archive:
                return treeEventType.archive;
            case TreeButtonType.restore:
                return treeEventType.restore;
            case TreeButtonType.copyNode:
                return treeEventType.copyNode;
            case TreeButtonType.newInstance:
                return treeEventType.newInstance;


        }
    }

    public onTreeScroll() {
        this.isTreeScrolling = true;
    }

    private isAllowedDrop(from: TreeNode, to: TreeNode) {
        if (from?.id === to?.id) {
            return false;
        }
        let activeNodesId: number[] = this.getSelectedNodesId(this.treeModel.activeNodeIds);
        if(to && activeNodesId.includes(to.id)){
            return false;
        }
        return !this.iAmYourFather(from, to);
    }

    private iAmYourFather(folder: TreeNode, child: TreeNode) {
       let isYourFather: boolean = false;
       if(!child) return false;

       let traverseFolder = child.parent;
       while (traverseFolder && !isYourFather) {
           if (traverseFolder.id === folder.id) {
               isYourFather = true;
           }

           traverseFolder = traverseFolder.parent;
       }

       return isYourFather;
    }

    private getSelectedNodesId(activeNodeIds: any): number[] {
        let activeItemArr = [];
        let activeFoldersArr = [];

        for(let nodeId in activeNodeIds) {
            const node = this.treeModel.getNodeById(nodeId);
            if(node) {
                if(node.data.rel === nodeType.folder || node.data.rel === nodeType.category) {
                    activeFoldersArr.push(node);
                } else {
                    activeItemArr.push(node);
                }
            }
        }

        let finalItemArr = [];
        for (let i:number = 0; i < activeItemArr.length; i++) {
            if(!this.isOneOfFoldersIsYourFather(activeItemArr[i], activeFoldersArr))
                finalItemArr.push(activeItemArr[i]);
        }

        const activeFoldersAndItems = activeFoldersArr.concat(finalItemArr);
        let activeNodesId = [];

        for (let i:number = 0; i < activeFoldersAndItems.length; i++) {
            if(activeFoldersAndItems[i].isActive) activeNodesId.push(activeFoldersAndItems[i].data.id);
        }

        return activeNodesId;
    }

    private isOneOfFoldersIsYourFather(item, foldersArr): boolean {
        for (let i:number = 0; i < foldersArr.length; i++) {
            if(this.iAmYourFather(foldersArr[i], item)) {
                return true;
            }
        }
        return false;
    }

    private getSelectedNodes(activeNodeIds: any): TreeNode[] {
        let activeItemArr = [];
        let activeFoldersArr = [];

        //for(let nodeId in activeNodeIds) {
        for (let i:number = 0; i < activeNodeIds.length; i++) {
            const node = this.treeModel.getNodeById(activeNodeIds[i]);
            if(node) {
                if(node.data.rel === nodeType.folder || node.data.rel === nodeType.category) {
                    activeFoldersArr.push(node);
                } else {
                    activeItemArr.push(node);
                }
            }
        }

        for (let i:number = 0; i < activeItemArr.length; i++) {
            for (let j:number = 0; j < activeFoldersArr.length; j++) {
                if(this.iAmYourFather(activeFoldersArr[j], activeItemArr[i])) {
                    activeItemArr.splice(i, 1);
                }
            }
        }

        const activeFoldersAndItems = activeFoldersArr.concat(activeItemArr);
        let activeNodes = [];

        for (let i:number = 0; i < activeFoldersAndItems.length; i++) {
            activeNodes.push(activeFoldersAndItems[i]);
        }

        return activeNodes;
    }

    private moveMultiNodes(activeNodesId: number[], to: any) {
        let node;

        for (let i: number = 0; i < activeNodesId.length; i++) {
            node = this.treeModel.getNodeById(activeNodesId[i]);
            this.treeModel.moveNode(node, to);
        }

        this.sortFolder(to.parent.id);

        node = this.treeModel.getNodeById(activeNodesId[0]);
        node.setActiveAndVisible(true);

    }

    public onTreeFocus() {
        this.isTreeFocus = true;
        if(!this.focusFromMouseEvent) {
            this.focusOnFirstNode()    
        }
    }

    private focusOnFirstNode() {
        const focusedNode = this.treeModel.focusedNode;
        if (!focusedNode) {
            this.treeModel.focusNextNode();
        } else if (focusedNode.isHidden) {
            focusedNode.blur();
            this.treeModel.focusNextNode();
        }
    }

    public onTreeMouseDown() {
        this.focusFromMouseEvent = true;
    }

    public onTreeBlur() {
        this.focusFromMouseEvent = false;
        this.isTreeFocus = false;
    }
}

export interface ITreeEvent {
    node: TreeNode | TreeNode[] | null | number[],
    eventName: treeEventType,
    arg?: any
}

export interface ITreeAction {
    node?: ITreeNode,
    eventName: treeEventType,
    parentId?: number,
    nodeId?: number | number[],
    fromTo?: any,
    text?: string
}

export interface IFromTo {
    from: TreeNode,
    to: TreeNode
}

export interface ITreeNode {
    text: string,
    rel: nodeType,
    id: number,
    [key: string]: any
}

export enum nodeType {
    leaf = 'leaf',
    folder = 'folder',
    category = 'category'
}

export enum treeEventType {
    selectNode,
    deleteNode,
    deleteNodes,
    folderWithSelection,
    dropNode,
    dropMultipleNodes,
    addNode,
    addFolder,
    editNodeName,
    updateNode,
    duplicateNode,
    treeInit,
    refresh,
    cancelEdit,
    /*treeNodesChanged event is used for ngJS downgrade.
    The @nodes input in ng6 & ngJS are not the same reference.
    This event update the ngJS with the updated nodes*/
    treeNodesChanged,
    selectFolder,
    deselectNode,
    doubleClick,
    openMore,
    archive,
    restore,
    copyNode,
    newInstance,
    editLeafNodeData
}

export interface ITreeConfiguration {
    buttonsAndTexts: ITreeNodeButtons,
    dotMenuButtons?: ITreeDotButtons,
    moreActionsButtons?: ITreeMoreActionsButtons,
    isRefresh: boolean,
    refreshTitle?: string,
    isCostumeBtn?: boolean,
    costumeBtnText?: string,
    costumeBtnCallBack?: Function,
    isDraggable: boolean,
    noSearchResultsMsg: string,
    searchPlaceholder: string,
    isMultiSelectEnable?: boolean
}

export interface ITreeNodeButtons {
    delete?: IEnableAndText,
    editNode?: IEnableAndText,
    newFolder?: IEnableAndText,
    newNode?: IEnableAndText,
    duplicateNode?: IEnableAndText,
    editLeafNodeData?: IEnableAndText,
    more?: IEnableAndText
}

export interface ITreeDotButtons {
    delete?: IEnableAndText,
    folderWithSelection?: IEnableAndText
}

export interface ITreeMoreActionsButtons {
    delete?: IEnableAndText,
    archive?: IEnableAndText,
    restore?: IEnableAndText,
    copyNode?: IEnableAndText
}

export interface IMoreActionsButtons {
    archive?: IEnableAndText,
    restore?: IEnableAndText,
    delete?: IEnableAndText
}


export enum TreeButtonType {
    newNode = 'newNode',
    newFolder = 'newFolder',
    editNode = 'editNode',
    duplicateNode = 'duplicateNode',
    more = 'more',
    archive = 'archive',
    restore = 'restore',
    newInstance = 'newInstance',
    copyNode = 'copyNode',
    delete = 'delete',
    editLeafNodeData = 'editLeafNodeData'
}

export interface IEnableAndText {
    isEnable: boolean,
    text?: string,
    forCat?: number[],
    icon?: string,
    isHeaderMenuEnable: boolean;
}
