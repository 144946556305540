import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AjaxResponse, IListItem } from '@optimove/ui-sdk/common/models';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { OslValueType } from 'src/app/components/optiSearchList/models/oslValueType.enum';
import { LanguageStatus } from 'src/app/features/manageTemplates/models/languageStatus';
import { SubMethodType } from 'src/app/features/manageTemplates/models/metadataResponse';
import { TemplateDetails } from 'src/app/features/manageTemplates/models/templateDetails';
import { AvailableIDSelectionValues } from 'src/app/features/manageTemplates/models/templatePreview';
import { BrandService } from 'src/app/features/manageTemplates/services/brand.service';
import { ManageTemplatesService } from 'src/app/features/manageTemplates/services/manageTemplates.service';
import { TemplateContextService } from 'src/app/features/manageTemplates/services/templateContext.service';
import { TemplateSaveUpdateService } from 'src/app/features/manageTemplates/services/templateSaveUpdate.service';
import { FeatureFlag, FeatureFlagService } from 'src/app/services/featureFlag.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface LanguageItem extends IListItem {
	code: string;
	isDefault: boolean;
}

@Component({
	selector: 'preview-template-with-data',
	templateUrl: './previewTemplateWithData.component.html',
	styleUrls: ['./previewTemplateWithData.component.scss']
})
export class PreviewTemplateWithDataComponent implements OnInit, OnDestroy {
	OPEN_TAG_BRACKET = '[%';
	CLOSE_TAG_BRACKET = '%]';
	VISITOR_TAGS_GROUPS = ['General', 'Visitor Data', 'Realtime data', 'Content Functions', 'Content Managment', 'Activity Data'];
	VISITOR_TAGS_ALLOWED_PREFIXES = ['', 'UPPER:', 'LOWER:', 'TITLE_CASE:'];
	DEFAULT_LANGUAGE_CODE = 'en';

	@Input() template: TemplateDetails;
	@Input() isOnCustomerData: boolean;

	@Output() templateBodyEvaluated = new EventEmitter<string>();
	
	subscriptions: Subscription = new Subscription();

	targetGroupListConfig = {
		keyProperty: 'id',
		valueProperty: 'value',
		isMultiSelect: false,
		itemNameTranslateKey: 'not_required',
		valueType: OslValueType.Key,
		placeholderTranslateKey: 'features.manage_templates.components.optimailPreview.SELECT'
	};

	targetGroup: number;
	targetGroups: IListItem[] = [];
	templateJson: string;
	allowCustomerId: boolean;
	channelId: string;
	brandName: string;
	subMethodId: number;
	selectedLivePersonType: AvailableIDSelectionValues = AvailableIDSelectionValues.RandomCustomerId;
	refreshing: boolean;
	body: string;
	subject: string;
	subjectHtml: SafeHtml;
	customerId: string = '';
	customerIdEdited: boolean;
	customerNotExistsServerValidator: boolean;
	wrongCustomerId: string;
	isTargetGroupsDisabled: boolean;

	fromNameHasntChanged: boolean;
	subjectHasntChanged: boolean;
	preheaderHasntChanged: boolean;
	textHasntChanged: boolean;
	htmlHasntChanged: boolean;
	shouldDisplayVisitorMenu: boolean;

	isLanguageMenuOpen: boolean;
	isLanguageMenuEnabled: boolean;
	languages: LanguageItem[] = [];
	languagesInitialized = new BehaviorSubject<boolean>(false);
	selectedLanguage: number;
	isMultiLanguageEnabled: boolean;
	isTemplateDirty: boolean;
	isICUincorrect: boolean;
	public readonly ICU_INCORRECT = 'icu-syntax-error';
	
	public get availableIDSelectionValues(): typeof AvailableIDSelectionValues {
		return AvailableIDSelectionValues;
	}

	constructor(
		private manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private templateSaveUpdateService: TemplateSaveUpdateService,
		private brandService: BrandService,
		private featureFlagsService: FeatureFlagService,
		private cd: ChangeDetectorRef,
		private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.manageTemplatesService.getTargetGroups().subscribe(
			(res) =>
				(this.targetGroups = res.map<IListItem>((tg) => {
					return { id: tg.id, name: tg.value };
				}))
		);
		this.templateJson = JSON.stringify(this.template);
		this.allowCustomerId = this.template?.subMethodType !== SubMethodType.Transactional;

		this.subscriptions.add(this.templateContextService.queryTemplateParams
			.pipe(filter((params) => !!params.channelId))
			.pipe(
				switchMap((params) => {
					this.channelId = params.channelId;
					this.brandName = params.brandId;
					this.subMethodId = +params.typeId;
					return this.manageTemplatesService.getInfoForSendTestAndPreview(params.channelId);
				})
			)
			.pipe(filter((sendAndPreviewInfo) => !!sendAndPreviewInfo))
			.pipe(
				switchMap((sendAndPreviewInfo) => {
					if (
						this.template?.subMethodType &&
						sendAndPreviewInfo?.SubMethodTypesThatSupportVisitors &&
						sendAndPreviewInfo.SubMethodTypesThatSupportVisitors.includes(this.template.subMethodType)
					) {
						return this.manageTemplatesService.getAggregatedPersonalizationTagsByBrandType(this.template.subMethodType);
					} else {
						this.shouldDisplayVisitorMenu = false;
					}
					return of(null);
				})
			)
			.subscribe((personalizationTags) => {
				if (personalizationTags) {
					const tags = this.getPersonalizationTagsOslObject(personalizationTags);
					this.shouldDisplayVisitorMenu = !this.doesTemplateContainNonVisitorTags(this.template, tags);
				}
				this.evaluateInCustomerLanguage();
			}));

		this.isTemplateDirty = this.templateContextService.isDirty();
		this.isLanguageMenuEnabled = this.template?.languages && this.template.languages.length > 1 && !this.isTemplateDirty;

		this.manageTemplatesService.getTemplateLanguage().subscribe((languages) => {
			if (!languages?.length) {
				return;
			}
			if (this.template?.languages) {
				this.languages = this.template.languages.map((templateLanguage) => {
					const languageDefinition = languages.find((l) => l.Id === templateLanguage.languageId);
					if (!languageDefinition) {
						return;
					}
					if (templateLanguage.isDefault) {
						this.selectedLanguage = templateLanguage.languageId;
					}
					return {
						id: templateLanguage.languageId,
						name: languageDefinition.DisplayName,
						code: languageDefinition.Code,
						disabled:
							!templateLanguage.isDefault &&
							(templateLanguage.status !== LanguageStatus.Translated || this.templateContextService.isDirty()),
						isDefault: templateLanguage.isDefault
					};
				});
			} else {
				const defaultLanguage = languages.find((l) => l.Code === this.DEFAULT_LANGUAGE_CODE);
				this.languages = [];
				if (defaultLanguage) {
					const language = {
						id: defaultLanguage.Id,
						name: defaultLanguage.Name,
						code: defaultLanguage.Code,
						disabled: false,
						isDefault: true
					};
					this.languages.push(language);
				}
			}
			this.languagesInitialized.next(true);
		});

		this.isMultiLanguageEnabled =
			this.template?.otherEditor && this.featureFlagsService.isEnabled(FeatureFlag.TemplatesOptimailMultiLanguageFlow) && this.template.languages?.length > 0;
	}

	ngOnDestroy() {
		this.languagesInitialized.complete();
		this.subscriptions.unsubscribe();
	}

	evaluateInCustomerLanguage(providedCustomerId?: string): void {
		const customerSource = providedCustomerId ?
			of({ customerId: providedCustomerId, customerType: this.selectedLivePersonType, ourCustomerId: '' }) :
			this.manageTemplatesService.getRandomCustomerId()
				.pipe(map(customer => { return { customerId: customer.clientCustomerId, customerType: AvailableIDSelectionValues.ExplicitId, ourCustomerId: customer.customerId }; } ));
		customerSource.subscribe(eventParam => {
			this.evaluateWithParam(eventParam, true, eventParam.ourCustomerId );
		});
	}

	evaluateWithParam(eventParam: { customerId?: string; customerType: AvailableIDSelectionValues }, setCustomerLanguage: boolean = false, ourCustomerId: string = ''): void {
		this.languagesInitialized.subscribe(value => {
			if (value) {
				if (this.languages?.length && this.template?.templateId) {
					if (eventParam.customerId && setCustomerLanguage) {
						this.manageTemplatesService.getCustomerLanguage({ subMethodType: this.template.subMethodType, customerId: ourCustomerId, clientCustomerId: eventParam.customerId }).subscribe(customerLanguage => {
							const language = this.languages.find(l => l.code === customerLanguage);
							if (language && !language.disabled) {
								this.selectedLanguage = language.id as number;
							} else {
								this.selectedLanguage = this.languages.find(l => l.isDefault).id  as number;
							}

							this.loadSelectedLanguage(eventParam.customerId, eventParam.customerType);
						});
					} else {
						this.loadSelectedLanguage(eventParam.customerId, eventParam.customerType);
					}
				} else {
					this.evaluateWithType(eventParam, this.template);
				}
			}
		});
	}

	private evaluateWithType(
		eventParam: { customerId?: string; customerType: AvailableIDSelectionValues },
		template: TemplateDetails,
		useHtmlForBeefree?: boolean
	): void {
		this.customerNotExistsServerValidator = false;
		const clearTemplate = this.templateSaveUpdateService.deleteWhitespacesFromAtomicTags(template);
		this.refreshing = true;
		this.customerIdEdited = false;
		let customerId = eventParam.customerId;
		if (!customerId && this.selectedLivePersonType === this.availableIDSelectionValues.ExplicitId && this.customerId) {
			customerId = this.customerId;
		}

		this.body = '';
		this.manageTemplatesService
			.evaluateTemplate({
				channelID: +this.channelId,
				subMethodType: this.template.subMethodType,
				templateJson: JSON.stringify(clearTemplate),
				idSelectionValue: eventParam.customerType,
				customerId,
				containsNonVisitorTags: !this.shouldDisplayVisitorMenu,
				useHtmlForBeefree
			})
			.subscribe((res: { html: string; subject: string }) => {
				this.refreshing = false;
				if (res) {
					if ((res as unknown as AjaxResponse<any>)?.errorMsg?.toLowerCase() === 'customernotexists') {
						this.customerNotExistsServerValidator = true;
						this.wrongCustomerId = this.customerId;
					} else {
						this.body = res.html;
						this.subject = res.subject;
						this.subjectHtml = this.sanitizer.bypassSecurityTrustHtml(this.subject);
						this.isICUincorrect = (this.subject.includes(this.ICU_INCORRECT) || this.body.includes(this.ICU_INCORRECT));
						this.cd.detectChanges();
						this.templateBodyEvaluated.emit(res.html);
					}
				}
			});
	}

	isPreviousEnabled(languageId: number): boolean {
		return this.isLanguageMenuEnabled && !!this.getPreviousLanguage();
	}

	switchPreviousLanguage(): void {
		const previousLanguage = this.getPreviousLanguage();
		if (!previousLanguage) {
			return;
		}
		this.selectedLanguage = previousLanguage.id as number;
		this.loadSelectedLanguage();
	}

	isNextEnabled(languageId: number): boolean {
		return this.isLanguageMenuEnabled && !!this.getNextLanguage();
	}

	switchNextLanguage(): void {
		const nextLanguage = this.getNextLanguage();
		if (!nextLanguage) {
			return;
		}
		this.selectedLanguage = nextLanguage.id as number;
		this.loadSelectedLanguage();
	}

	loadSelectedLanguage(customerId?: string, customerType?: AvailableIDSelectionValues): void {
		const currentLanguage = this.getCurrentLanguage();
		if (!currentLanguage) {
			return;
		}
		this.body = '';

		this.brandService.updateBrandsByChannelId(+this.channelId).subscribe(() => {
			const brand = this.brandService.getBrandId(this.brandName, this.subMethodId);

			this.manageTemplatesService
				.getExecutionTemplate({
					channel: +this.channelId,
					brand,
					templateId: this.template.templateId,
					languages: [currentLanguage.code]
				})
				.subscribe((templateDetails) => {
					if (templateDetails?.length) {
						const translatedTemplate = templateDetails[0];
						const templateToPreview = {
							...this.template,
							html: translatedTemplate.html,
							subject: translatedTemplate.subject,
							fromName: translatedTemplate.fromName,
							preheader: translatedTemplate.preheader,
							text: translatedTemplate.text
						};

						this.evaluateWithType(
							{ customerId, customerType: customerType ?? this.selectedLivePersonType },
							templateToPreview,
							true
						);
					}
				});
		});
	}

	private doesTemplateContainNonVisitorTags(template: TemplateDetails, personalizationTags): boolean {
		let containsNonVisitorTags = false;
		const tagsAllowedForVisitors = this.getTagsAllowedForVisitors(personalizationTags);
		if (template) {
			if (typeof template === 'string' || template instanceof String) {
				containsNonVisitorTags = this.doesStrContainNonVisitorTags(template, tagsAllowedForVisitors);
			} else {
				if (template.fromName && !this.fromNameHasntChanged) {
					containsNonVisitorTags = this.doesStrContainNonVisitorTags(template.fromName, tagsAllowedForVisitors);
					this.fromNameHasntChanged = true;
				}
				if (template.subject && !this.subjectHasntChanged && !containsNonVisitorTags) {
					containsNonVisitorTags = this.doesStrContainNonVisitorTags(template.subject, tagsAllowedForVisitors);
					this.subjectHasntChanged = true;
				}
				if (template.preheader && !this.preheaderHasntChanged && !containsNonVisitorTags) {
					containsNonVisitorTags = this.doesStrContainNonVisitorTags(template.preheader, tagsAllowedForVisitors);
					this.preheaderHasntChanged = true;
				}
				if (template.text && !this.textHasntChanged && !containsNonVisitorTags) {
					containsNonVisitorTags = this.doesStrContainNonVisitorTags(template.text, tagsAllowedForVisitors);
					this.textHasntChanged = true;
				}
				if (template.html && !this.htmlHasntChanged && !containsNonVisitorTags) {
					containsNonVisitorTags = this.doesStrContainNonVisitorTags(template.html, tagsAllowedForVisitors);
					this.htmlHasntChanged = true;
				}
			}
		}
		return containsNonVisitorTags;
	}
	private getTagsAllowedForVisitors(personalizationTags): any {
		const filtered = personalizationTags.items.filter((t) => this.VISITOR_TAGS_GROUPS.indexOf(t.categoryId) !== -1);
		var tagsAllowerdForVisitors = new Set(
			filtered.map((t) => t.value.split(this.OPEN_TAG_BRACKET).join('').split(this.CLOSE_TAG_BRACKET).join(''))
		);
		tagsAllowerdForVisitors.add('EMAIL');
		var tagsWithPossiblePrefixes = [];
		this.VISITOR_TAGS_ALLOWED_PREFIXES.forEach((prefix) => {
			tagsAllowerdForVisitors.forEach((tag) => {
				tagsWithPossiblePrefixes.push(`${prefix}${tag}`);
			});
		});
		return tagsWithPossiblePrefixes;
	}

	private doesStrContainNonVisitorTags(str, tagsAllowedForvisitors): boolean {
		let containsNonVisitorTags = false;
		if (!(typeof str === 'string' || str instanceof String)) {
			return containsNonVisitorTags;
		}
		let strLeft = str;
		let closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		while (closeBracketIndex >= 0 && !containsNonVisitorTags) {
			let openBracketIndex = strLeft.substring(0, closeBracketIndex).lastIndexOf(this.OPEN_TAG_BRACKET);
			if (openBracketIndex >= 0) {
				let curAtomicTag = strLeft.substring(openBracketIndex + this.OPEN_TAG_BRACKET.length, closeBracketIndex);
				curAtomicTag = curAtomicTag.replace(/\s/g, '');
				if (curAtomicTag.startsWith('IF:')) {
					curAtomicTag = curAtomicTag.substring('IF:'.length);
				} else if (curAtomicTag.startsWith('ELSEIF:')) {
					curAtomicTag = curAtomicTag.substring('ELSEIF:'.length);
				}
				const operatorIndex = this.getFirstOperatorIndex(curAtomicTag);
				if (operatorIndex >= 0) {
					curAtomicTag = curAtomicTag.substring(0, operatorIndex);
				}

				if (curAtomicTag) {
					if (
						!(
							curAtomicTag.startsWith('CURRENT_TIME:') ||
							curAtomicTag.startsWith('CURRENT_DATE:') ||
							curAtomicTag.startsWith('TOMORROW_DATE:') ||
							curAtomicTag == 'ELSE' ||
							curAtomicTag == 'END:IF'
						)
					) {
						containsNonVisitorTags = !tagsAllowedForvisitors.some((t) => curAtomicTag.endsWith(t));
					}
				}
			}

			strLeft = strLeft.substring(closeBracketIndex + this.CLOSE_TAG_BRACKET.length);
			closeBracketIndex = strLeft.indexOf(this.CLOSE_TAG_BRACKET);
		}

		return containsNonVisitorTags;
	}

	private getPersonalizationTagsOslObject(tags) {
		if (!tags) return {};

		let config = this.getPersonalizationTagsOslObjectConfig();
		let items = this.getPersonalizationTagsOslObjectItems(tags);
		return { config, items };
	}

	private getPersonalizationTagsOslObjectConfig() {
		return {
			keyProperty: 'tag',
			valueProperty: 'displayName',
			isMultiSelect: false,
			itemNameTranslateKey: 'em_json_text_not_required',
			groupKeyProperty: 'categoryId',
			groupValueProperty: 'categoryName',
			shouldIgnoreSorting: true
		};
	}

	private getPersonalizationTagsOslObjectItems(tags) {
		let items = [];
		if (Object.keys(tags).length === 0) {
			return items;
		}

		tags.forEach((category) => {
			category.PersonalizationTags.forEach((tag) => {
				items.push({
					tag: category.Name + '/' + tag.value,
					displayName: tag.value.slice(2, -2),
					value: tag.value,
					categoryId: category.Name,
					categoryName: category.Name
				});
			});
		});
		return items;
	}

	private getFirstOperatorIndex(tag) {
		const operatorsIndices = [tag.indexOf('<'), tag.indexOf('>'), tag.indexOf('='), tag.indexOf('.')];
		const existingOperatorsIndices = operatorsIndices.filter((i) => i >= 0);
		if (!existingOperatorsIndices || existingOperatorsIndices.length == 0) {
			return -1;
		} else {
			return Math.min(...existingOperatorsIndices);
		}
	}

	private getCurrentLanguage(): LanguageItem | undefined {
		if (!this.languages) {
			return;
		}
		const currentLanguage = this.languages.find((l) => l.id === this.selectedLanguage);
		return currentLanguage;
	}

	private getNextLanguage(): LanguageItem | undefined {
		const current = this.getCurrentLanguage();
		if (!current) {
			return;
		}
		const nextItems = this.languages.slice(this.languages.indexOf(current) + 1).filter((l) => !l.disabled);
		return nextItems ? nextItems[0] : undefined;
	}

	private getPreviousLanguage(): LanguageItem | undefined {
		const currentLanguage = this.getCurrentLanguage();
		if (!currentLanguage) {
			return;
		}

		const previousLanguages = this.languages.slice(0, this.languages.indexOf(currentLanguage)).filter((l) => !l.disabled);
		if (!previousLanguages) {
			return;
		}
		return previousLanguages[previousLanguages.length - 1];
	}
}
