export enum RelationType {
	leaf = 'leaf',
	folder = 'folder',
	category = 'category'
}

export interface TemplateTreeItem {
	id: number;
	parentId: number;
	rel: RelationType;
	text: string;
	displayText: string | null;
	isRootFolder: boolean;
	children: TemplateTreeItem[] | null;
	subMethodId: number;
	lastModifiedDate?: Date;
	isValid: boolean;
}

export interface RootTemplateTreeItem extends TemplateTreeItem {
	subMethodId: number;
	executionMethodInnerID: number;
	isVirtual: boolean;
}

export interface TemplateSearchResult {
	templateID: number;
	subMethodId: number;
	channelId: number;
	folderId: number;
}
