import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { IModalConf } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { AttributeConfiguration, AttributeTypeInfo, BaseAttribute } from "../../attributesList.model";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { AttributeHelper } from "../../attributesList.helper";
import { AttributeType } from "../../attributesList.enums";
import { forkJoin, Subscription } from 'rxjs';
import { AttributesManagerService } from '../../services/attributesManager.service';
import { map } from 'rxjs/internal/operators';
import { FeatureFlag, FeatureFlagService } from "src/app/services/featureFlag.service";


@Component({
	selector: 'add-calculated-attribute',
	templateUrl: './addAttributePopup.component.html',
	styleUrls: ['./addAttributePopup.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class AddAttributePopupComponent implements OnInit, OnDestroy {

    public types: AttributeTypeInfo[];
    public modalConf: IModalConf;
    public translateKeys = translateKeys;
	private config: AttributeConfiguration;
	private initSubscription: Subscription;
	private attributes: BaseAttribute[] = [];

    constructor(private bsModalRef: BsModalRef, 
        private router: Router, 
        private translateService: TranslateService,
		public service: AttributesManagerService,
		private featureFlagService: FeatureFlagService) {}

    ngOnInit() {
		this.initSubscription = 
		forkJoin([
			this.service.getAttributeConfiguration(),
			this.service.getAttributes(true)
		]).pipe(map(res => {
			return {
				config: res[0],
				attributes: res[1]
			};
		})).subscribe((res) => {
			this.config = res.config;
			this.attributes = res.attributes;
		});
		
    	this.setModalConf();
		
    	this.types = AttributeHelper.setupTypeInfo()
    		.map(x => {
    			x.hidden = x.type === "purchase" && 
				(this.config.MaxCountOfPurchaseHistoryAttributes <= this.attributes.filter(x => x.Type === "purchase").length
				|| this.featureFlagService.isEnabled(FeatureFlag.Exclude_PH_Attributes));
    			return x;
    		});
    }

	ngOnDestroy(): void {
		this.initSubscription.unsubscribe();
	}

    setModalConf(): void {
    	this.modalConf = {
    		title: this.translateService.instant(this.translateKeys.title),
    		xButton: {
    			action: () => { this.bsModalRef.hide(); }
    		},
    		hideFooterBorder: true,
    		hideFooterPanel: true,
    		noBodyPadding: false
    	};
    }

    moveToCreateAttribute(type: AttributeType): void {
		this.router.navigate([`/user-settings/attributes/${type}AddAttribute`]);
		this.bsModalRef.hide();
    }
}

const translateKeys = {
	title: "features.user_settings.body.attributes.popup.TITLE",
	description: {
		first: "features.user_settings.body.attributes.popup.description.FIRST",
		last: "features.user_settings.body.attributes.popup.description.LAST",
		link: "features.user_settings.body.attributes.popup.description.LINK"
	},
	activityHistory: {
		title: "features.user_settings.body.attributes.popup.activityHistory.TITLE",
		description: "features.user_settings.body.attributes.popup.activityHistory.DESCRIPTION",
	},
	purchaseHistory: {
		title: "features.user_settings.body.attributes.popup.purchaseHistory.TITLE",
		description: "features.user_settings.body.attributes.popup.purchaseHistory.DESCRIPTION",
	},
	customerAttributes: {
		title: "features.user_settings.body.attributes.popup.customerAttributes.TITLE",
		description: "features.user_settings.body.attributes.popup.customerAttributes.DESCRIPTION"
	}
}
