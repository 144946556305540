import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'opti-accordion-block',
  template: `
        <ng-template #accordionHeaderTpl>
          <ng-content select="[accordionHeader]"></ng-content>
        </ng-template>
        <ng-template #accordionBodyTpl>
          <ng-content select="section"></ng-content>
        </ng-template>
        `
})
export class OptiAccordionBlockComponent implements OnInit {
  @ViewChild('accordionHeaderTpl', { read: TemplateRef }) headerTemplateRef: TemplateRef<any>;

  @ViewChild('accordionBodyTpl', { read: TemplateRef }) bodyTemplateRef: TemplateRef<any>;

  @Input() isAccordionGroupOpen = false;

  @Input() disabled: boolean = false;

  @Input() dataQaId: string = '';

  @Input() hideChevron: boolean = false;

  @Input() isRouterLink: boolean = false;

  @Input() className: string | string[] | Set<string> | { [klass: string]: any; };

  @Output() isOpenChange = new EventEmitter<boolean>();

  isExpand: boolean = false;

  constructor() { }

  ngOnInit(): void { }
  public onSectionExpandToggle(isExpand: boolean) {
    this.isExpand = isExpand;
    this.isOpenChange.emit(isExpand);
  }
}