import { NgModule } from "@angular/core";
import { CredentialsFormComponent } from "./components/credentialsForm/credentialsForm.component";
import { BrowserModule } from "@angular/platform-browser";
import { ComponentsTransitionalModule } from "../../../components/components_transitional.module";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../../shared.module";
import { OptiModalModule } from "../../../modules/OptiModal.module";
import { PipesModule } from "../../../pipes/pipes.module";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SecretService } from "./services/secret.service";
import { ErrorLogListComponent } from "./components/errorLogList/errorLogList.component";
import { PathBuilderComponent } from './components/credentialsForm/pathBuilder/pathBuilder.component';
import { GcsCredentialsFormComponent } from './components/credentialsForm/gcpCredentialsForm/gcsCredentialsForm.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports:[
        BrowserModule,
        ComponentsTransitionalModule,
        CommonModule,
        SharedModule,
        OptiModalModule.forRoot(),
        PipesModule,
        DragDropModule,
        FormsModule
        ],
    exports:[],
    declarations:[
        CredentialsFormComponent,
        ErrorLogListComponent,
        PathBuilderComponent,
        GcsCredentialsFormComponent
    ],
    providers:[
        SecretService
    ]
})
export class MessageArchivingModule { }
