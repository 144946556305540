import { RecurrenceFormData } from "./recurrenceFormData.model";
import { RecurrenceType } from "./recurrenceType.model";
import { EndingType } from "./endingType.model";

export class DefaultRecurrenceFormData implements RecurrenceFormData {
    frequency = 1;
    timeUnit = RecurrenceType.Day;
    endingType = EndingType.Never;
    endingAfterOccurrences = null;
    endingBy = null;
    weekdays = null;
    monthSelection = null;
}