<form [formGroup]="conditionForm" class="main">
    <opti-search-list data-qa-id="attribute-dropdown"
        class="attribute-cb combo-box"
        [ngClass]="{'red-border-cb': attribute.invalid && attribute.dirty}"
        [items]="attributesSearchItems" 
        [config]="attributeConfig" 
        formControlName="attributeFieldName"
        (searchListHide)="attribute.markAsDirty()"
        (selectedItemsChange)="onAttributeChange($event)">
    </opti-search-list>

    <opti-search-list data-qa-id="condition-dropdown"
        class="condition-cd combo-box"
        [ngClass]="{'red-border-cb': operator.invalid && operator.dirty}"
        [items]="config.operators" 
        [config]="operatorConfig" 
        formControlName="operator"
        (searchListHide)="operator.markAsDirty()"
        (selectedItemsChange)="onOperatorChange($event)">
    </opti-search-list>

    <div class="value-input" [ngSwitch]="valueFormat.value">

        <input data-qa-id="condition-value-input"
               *ngSwitchCase="'disable'"
               class="value"
               type="text"
               disabled
               placeholder="{{ translateKeys.value | translate}}">

        <input data-qa-id="condition-value-input"
            *ngSwitchCase="'number'" 
            class="value"
            [ngClass]="{'red-border': value.invalid && value.touched}"
            type="number"
            formControlName="value"
            (change)="onValueChange()"
            placeholder="{{ translateKeys.value | translate}}">
        
        <input data-qa-id="condition-value-input"
            *ngSwitchCase="'text'" 
            class="value"
            [ngClass]="{'red-border': value.invalid && value.touched}"
            type="text"
            formControlName="value"
            (change)="onValueChange()"
            placeholder="{{ translateKeys.value | translate}}">
            
        <numbers-range-picker data-qa-id="condition-value-input"
            *ngSwitchCase="'between numbers'" 
            class="between-numbers"
            formControlName="value"
            (change)="onValueChange()"
            [disabled]="value.disabled"
            [isPickerTouched]="value.touched">
        </numbers-range-picker>

        <date-range-picker data-qa-id="condition-value-input"
            *ngSwitchCase="'between dates'" 
            class="value"
            formControlName="value"
            (dateRangePickerDataChange)="onValueChange($event)"
            [ngClass]="{'red-border-cb': value.invalid && value.touched}"
            [dateRangePickerData]="dateRangePickerConfig"
        ></date-range-picker>

        <date-picker data-qa-id="condition-value-input"
            *ngSwitchCase="'date'"
            class="value date-picker"
            [ngClass]="{'red-border-cb': value.invalid && value.touched}"
            style="padding: 0;"
            formControlName="value"
            (datePickerDataChange)="onValueChange($event)"
            [datePickerData]="datePickerConfig" >
        </date-picker>

        <div *ngSwitchCase="'dropdown'" class="value">
            <opti-search-list data-qa-id="condition-value-input"
                *ngIf="valueConfig.isMultiSelect"
                class="combo-box"
                [ngClass]="{'red-border-cb': value.invalid && value.touched}"
                [items]="config.possibleValues" 
                [config]="valueConfig" 
                formControlName="value"
                (selectedItemsChange)="onValueChange()">
            </opti-search-list>
            <opti-search-list data-qa-id="condition-value-input"
                *ngIf="!valueConfig.isMultiSelect"
                class="combo-box"
                [ngClass]="{'red-border-cb': value.invalid && value.touched}"
                [items]="config.possibleValues" 
                [config]="valueConfig" 
                formControlName="value"
                (selectedItemsChange)="onValueChange()"
                >
            </opti-search-list>
        </div>
       
        
    </div>
   
</form>
