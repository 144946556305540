import { Injectable } from '@angular/core';
import { RelationType, RootTemplateTreeItem, TemplateTreeItem } from '../models/templateResponse';
import { OverflowMenuItem } from '../../../components/overflowMenu/overflowMenuItem/models/overflowMenuItem.model';
import { MoveFolderModalComponent } from '../folderTemplatePreviewCard/modals/moveFolderModal/moveFolderModal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { FolderRenameComponent } from '../nestedFoldersPanel/folderRename/folderRename.component';
import { FolderDeleteComponent } from '../nestedFoldersPanel/folderDelete/folderDelete.component';
import { NewFolderModalComponent } from '../nestedFoldersPanel/newFolderModal/newFolderModal.component';
import { TranslateService } from '@ngx-translate/core';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import { ManageTemplatesService } from './manageTemplates.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscriber } from 'rxjs';
import { CreateUpdateFolderResponse } from '../models/createUpdateFolder';
import { SubMethod } from '../models/metadataResponse';
import { TemplateContextService } from './templateContext.service';
import { SubMethodService } from './subMethodService';
import { SearchContextService } from './searchContext.service';
import { TreeItem } from 'src/app/components/jsTree/models/treeItem.model';
import { BrandService } from './brand.service';
import { map, switchMap } from 'rxjs/operators';
import { ErrorModalService } from './errorModal.service';
import { TemplateMove, TemplatesBatchMove } from '../models/templateMove';

@Injectable({
	providedIn: 'root'
})
export class FolderService {
	private $folderNameUpdated: Subject<CreateUpdateFolderResponse> = new Subject<CreateUpdateFolderResponse>();
	public folderNameUpdated: Observable<CreateUpdateFolderResponse> = this.$folderNameUpdated.asObservable();
	private $folderDeleted: Subject<number> = new Subject<number>();
	public folderDeleted: Observable<number> = this.$folderDeleted.asObservable();
	private $folderCreated: Subject<CreateUpdateFolderResponse> = new Subject<CreateUpdateFolderResponse>();
	public folderCreated: Observable<CreateUpdateFolderResponse> = this.$folderCreated.asObservable();
	private $folderMoved: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	public folderMoved: Observable<number> = this.$folderMoved.asObservable();
	private folderCache: { [subMethodId: number]: TemplateTreeItem } = {};
	private $foldersList: Subject<TemplateTreeItem[]> = new Subject<TemplateTreeItem[]>();
	public foldersList: Observable<TemplateTreeItem[]> = this.$foldersList.asObservable();
	private $folderTree: BehaviorSubject<TreeItem[]> = new BehaviorSubject<TreeItem[]>([]);
	public folderTree: Observable<TreeItem[]> = this.$folderTree.asObservable();
	public channelId: number;
	private $templateMoved: Subject<TemplateMove> = new Subject<TemplateMove>();
	public templateMoved: Observable<TemplateMove> = this.$templateMoved.asObservable();
	private $templatesBatchMoved: Subject<TemplatesBatchMove> = new Subject<TemplatesBatchMove>();
	public templatesBatchMoved: Observable<TemplatesBatchMove> = this.$templatesBatchMoved.asObservable();

	private $templatesBatchCopied: Subject<TemplatesBatchMove> = new Subject<TemplatesBatchMove>();
	public templatesBatchCopied: Observable<TemplatesBatchMove> = this.$templatesBatchCopied.asObservable();

	constructor(
		private translate: TranslateService,
		private modalService: OptiLogicModalService,
		private manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private subMethodService: SubMethodService,
		private searchContextService: SearchContextService,
		private errorModalService: ErrorModalService,
		private brandService: BrandService
	) {
		this.templateContextService.channelId.subscribe((channelId) => (this.channelId = +channelId));
	}

	public updateFoldersList(folders: TemplateTreeItem[]) {
		let foldersList = this.flatten(folders);
		this.$foldersList.next(foldersList);
	}

	public findSubMethodByFolderId(folderId: number) {
		const subMethodIds = Object.keys(this.folderCache);
		for (const subMethodId of subMethodIds) {
			const templateTreeItem = this.folderCache[parseInt(subMethodId)];
			if (this.findFolder(folderId, [templateTreeItem])) {
				return templateTreeItem.subMethodId;
			}
		}
	}

	public updateFolderDeleted(folderId: number) {
		this.$folderDeleted.next(folderId);
	}

	public updateFolderNameUpdated(update: CreateUpdateFolderResponse): void {
		if (!update) {
			this.errorModalService.openErrorModal(
				this.translate.instant('features.manage_templates.optimail.templateFolderActions.RENAME_FOLDER'),
				this.translate.instant('features.manage_templates.optimail.templateFolderActions.ACTION_RENAME_FOLDER')
			);
			return;
		}
		this.$folderNameUpdated.next(update);
	}

	getRenameMenuItem(
		argsCallback: () => {
			folderId: number;
			currentName: string;
			subMethodId: number;
		}
	): OverflowMenuItem {
		return {
			addSeparatorBelow: false,
			callback: () => {
				const renameArgs = argsCallback();
				this.rename(renameArgs.folderId, renameArgs.currentName, renameArgs.subMethodId);
				return {};
			},
			children: [],
			disabled: false,
			icon: 'mode_edit',
			text: this.translate.instant('general.RENAME')
		};
	}

	decodeTreeItems(treeItems: TemplateTreeItem[] | null): void {
		if (!treeItems) return;
		treeItems.forEach((t) => {
			t.text = this.decode(t.text);
			this.decodeTreeItems(t.children);
		});
	}

	private decode(str) {
		const txt = document.createElement('textarea');
		txt.innerHTML = str;
		return txt.value;
	}

	getDeleteMenuItem(argsCallback: () => number): OverflowMenuItem {
		return {
			addSeparatorBelow: false,
			callback: () => this.delete(argsCallback()) as any,
			children: [],
			disabled: false,
			icon: 'delete_forever',
			text: this.translate.instant('general.DELETE')
		};
	}

	getNewFolderMenuItem(
		argsCallback: () => {
			folderId: number;
			parentFolderId: number;
			subMethodId: number;
		}
	): OverflowMenuItem {
		return {
			addSeparatorBelow: false,
			callback: () => this.newFolder(argsCallback()) as any,
			disabled: false,
			icon: 'create_new_folder',
			text: this.translate.instant('features.manage_templates.components.newFolderModal.TITLE')
		};
	}

	getMoveMenuItem(
		argsCallback: () => {
			clickedFolderId: number;
			selectedChannelId: number;
			parentId: number;
			subMethodId: number;
		}
	): OverflowMenuItem {
		return {
			addSeparatorBelow: false,
			callback: () =>
				this.openMoveModal(
					argsCallback().clickedFolderId,
					argsCallback().selectedChannelId,
					argsCallback().parentId,
					argsCallback().subMethodId
				) as any,
			disabled: false,
			icon: 'forward',
			text: this.translate.instant('general.MOVE')
		};
	}

	openMoveModal(clickedFolderId: number, selectedChannel: number, parentId: number, subMethodId: number): void {
		this.modalService.open(MoveFolderModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				channelId: selectedChannel,
				folderId: clickedFolderId,
				parentFolderId: parentId,
				subMethodId: subMethodId,
				callback: this.updateFolderDeleted.bind(this),
				folderMoved: (folderId: number) => this.$folderMoved.next(folderId),
				isChildNode: (sourceNodeId: number, targetNodeId: number, submethod: number) =>
					this.isChildNode(sourceNodeId, targetNodeId, submethod)
			}
		});
	}
	public templateMovedEvent(templateMove: TemplateMove) {
		this.$templateMoved.next(templateMove);
	}

	public templatesBatchMovedEvent(templates: TemplatesBatchMove) {
		this.$templatesBatchMoved.next(templates);
	}

	public templatesBatchCopiedEvent(templates: TemplatesBatchMove) {
		this.$templatesBatchCopied.next(templates);
	}

	public folderMovedEvent(folderId) {
		this.$folderMoved.next(folderId);
	}

	flatten(treeItems: TemplateTreeItem[] | null): TemplateTreeItem[] {
		const result: TemplateTreeItem[] = [];
		if (!treeItems) return result;
		result.push(...treeItems.filter((t) => t?.rel === RelationType.folder));
		treeItems.forEach((t) => result.push(...this.flatten(t.children)));

		return result;
	}

	reloadFoldersCache(channelId: number, selectedBrand: string): Observable<void> {
		this.resetFolderTreeCache();

		return new Observable((observer: Subscriber<void>) => {
			this.brandService
				.updateBrandsByChannelId(channelId)
				.pipe(
					map((brands) => {
						const brand = brands.find((a) => a.name == selectedBrand);
						return brand?.subMethods ?? [];
					})
				)
				.pipe(
					switchMap((subMethods) =>
						forkJoin(
							subMethods.map((subMethod) =>
								this.manageTemplatesService
									.getTemplates({
										channelId: channelId,
										subMethodId: subMethod.subMethodId
									})
									.pipe(
										map((templates) => {
											return {
												templates,
												subMethod
											};
										})
									)
							)
						)
					)
				)
				.subscribe((results) => {
					results.map((result) => this.normalizeAndCacheFolderTree(result.templates, result.subMethod));
					observer.next();
					observer.complete();
				});
		});
	}

	normalizeAndCacheFolderTree(source: TemplateTreeItem[], subMethod: SubMethod): TemplateTreeItem {
		const isAnyTemplatesOrFolders = source && source.length > 0;
		this.templateContextService.updateIsAnyTemplatesOrFolders(isAnyTemplatesOrFolders);

		const backendRoot = source.find((i) => i.isRootFolder);
		let root: TemplateTreeItem;
		if (backendRoot) {
			root = this.createRootFolder(subMethod, backendRoot.id);
			root.children = backendRoot.children;
		} else {
			root = this.createRootFolder(subMethod, -subMethod.subMethodId);
		}
		root.children = root.children
			.concat(source.filter((i) => i.rel === RelationType.leaf && !i.parentId))
			.sort((t0, t1) => t0.text.localeCompare(t1.text));
		root.children.forEach((c) => (c.parentId = root.id));
		this.decodeTreeItems(root.children);
		this.folderCache[subMethod.subMethodId] = root;
		return root;
	}

	getFullCache(): {
		templateTreeItems: TemplateTreeItem;
		subMethodId: number;
	}[] {
		return Object.keys(this.folderCache)
			.map((sm) => +sm)
			.map((subMethodId) => {
				return { subMethodId, templateTreeItems: this.folderCache[subMethodId] };
			});
	}

	resetFolderTreeCache(): void {
		this.folderCache = {};
	}

	getTemplateIds(folderId: number, subMethodId: number, selectedBrand: string, channelId: number): Observable<number[]> {
		return this.getFolder(folderId, subMethodId, selectedBrand, channelId)
			.pipe(map((folder) => (folder ? folder.children : [])))
			.pipe(
				map((children) =>
					children.filter((c) => c.rel === RelationType.leaf && this.searchContextService.isMatch(c.text)).map((c) => c.id)
				)
			);
	}

	getFolder(folderId: number, subMethodId: number, selectedBrand: string, channelId: number): Observable<TemplateTreeItem | null> {
		const root = this.folderCache[subMethodId];
		const source = root
			? of([root])
			: this.reloadFoldersCache(channelId, selectedBrand).pipe(map(() => [this.folderCache[subMethodId]]));
		return source.pipe(
			map((folders) => {
				const folder = this.findFolder(folderId, folders);
				return folder;
			})
		);
	}

	getFolderTemplates(folderId: number, subMethodId: number, selectedBrand: string, channelId: number): Observable<TemplateTreeItem[]> {
		return this.getFolder(folderId, subMethodId, selectedBrand, channelId).pipe(
			map((folder) => {
				if (!folder) {
					return [];
				}
				return folder.children.filter((c) => c.rel === RelationType.leaf && this.searchContextService.isMatch(c.text));
			})
		);
	}

	getPath(itemId: number, subMethodId: number): string[] {
		const root = this.folderCache[subMethodId];
		const result = this.findPath(itemId, [root], {
			itemFound: false,
			path: []
		});
		return result.path;
	}

	checkFolderExistence(folderId: number, subMethodId: number) {
		const root = this.folderCache[subMethodId];
		if (!root) {
			return;
		}
		const folder = this.findFolder(folderId, [root]);
		if (!folder) {
			return;
		}
		return folder;
	}

	addTemplateToCache(folderId: number, subMethodId: number, template: TemplateTreeItem) {
		if(!template.text)
			template.text = '';
		let folder: TemplateTreeItem;
		if (!folderId) folder = this.getRoot(subMethodId);
		else folder = this.checkFolderExistence(folderId, subMethodId);
		if (!folder) return;
		if (template) {
			folder.children.push(template);
			folder.children = folder.children.sort((a, b) => a.text.localeCompare(b.text));
		}
	}

	removeTemplateFromCache(templateId: number, folderId: number, subMethodId: number) {
		let folder = this.checkFolderExistence(folderId, subMethodId);
		if (!folder) return;

		const templateItem = folder.children.find((c) => c.id === templateId);
		if (templateItem) {
			folder.children.splice(folder.children.indexOf(templateItem), 1);
		}
		return templateItem;
	}

	updateFolderTree(folders: TreeItem[]) {
		this.$folderTree.next(folders);
		this.updateFoldersList(folders as unknown as TemplateTreeItem[]);
	}

	getFolderFromCache(folderId: number, subMethodId: number): TemplateTreeItem {
		const root = this.folderCache[subMethodId];
		if (!root) {
			return;
		}
		return this.findFolder(folderId, [root]);
	}

	private findPath(
		itemId: number,
		source: TemplateTreeItem[],
		previousValue: { itemFound: boolean; path: string[] }
	): { itemFound: boolean; path: string[] } {
		if (!source || source.filter((c) => !!c).length === 0 || previousValue.itemFound) {
			return previousValue;
		}
		if (source.find((c) => c.id === itemId)) {
			previousValue.itemFound = true;
			return previousValue;
		}

		return (
			source
				.filter((i) => i.rel === RelationType.folder)
				.map((currentValue) =>
					this.findPath(itemId, currentValue.children, {
						...previousValue,
						path: [...previousValue.path, currentValue.text]
					})
				)
				.find((value) => value.itemFound) ?? previousValue
		);
	}

	findFolder(folderId: number, source: TemplateTreeItem[]): TemplateTreeItem | null {
		if (!source) {
			return null;
		}
		return source.reduce((previousValue: TemplateTreeItem, currentValue: TemplateTreeItem) => {
			if (previousValue) {
				return previousValue;
			}
			if (currentValue.id == folderId) {
				return currentValue;
			}
			return this.findFolder(folderId, currentValue.children);
		}, null);
	}

	public getFolderParent(folderId: number, submethod: number) {
		const root = this.folderCache[submethod];
		if (!root) {
			return;
		}
		const folder = this.findFolder(folderId, [root]);
		if (!folder) {
			return;
		}
		return folder.parentId;
	}

	public isChildNode(sourceNodeId: number, targetNodeId: number, submethod: number): boolean {
		let parentNodeId = targetNodeId;
		while (parentNodeId && targetNodeId && parentNodeId !== -1) {
			if (parentNodeId === sourceNodeId) {
				return true;
			}
			parentNodeId = this.getFolderParent(parentNodeId, submethod);
		}
		return false;
	}
	getRoot(subMethodId: number): TemplateTreeItem {
		const root = this.folderCache[subMethodId];
		return root;
	}

	countInvalidItems(items: { id: number, subMethodId: number }[]): number {
		var result = items.reduce((previous: number, current: { id: number, subMethodId: number }) => {
			var item = this.findFolder(current.id, this.getRoot(current.subMethodId).children);
			return item?.isValid ? previous : previous + 1;
		}, 0);
		return result;
	}

	private rename(folderId: number, currentName: string, subMethodId: number): void {
		this.modalService.open(FolderRenameComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				title: this.translate.instant('features.manageActions.body.messages.FOLDER_NAME_EXIST_TITLE'),
				folderId,
				currentName,
				subMethodId,
				callback: this.updateFolderNameUpdated.bind(this)
			}
		});
	}

	private delete(folderId: number): void {
		this.modalService.openModalMessage('sm', {
			message: this.translate.instant('features.manage_templates.messages.DELETE_FOLDER'),
			buttons: [
				{
					class: 'btn-primary',
					label: this.translate.instant('general.DELETE'),
					action: () => {
						this.manageTemplatesService.getUsedTemplatesUnderFolder({ folderId }).subscribe((templatesInUse) => {
							if (templatesInUse.length > 0) {
								this.modalService.open(FolderDeleteComponent, 'md', <ModalOptions<any>>{
									ignoreBackdropClick: true,
									initialState: { templatesInUse }
								});
							} else {
								this.manageTemplatesService.deleteFolder({ id: folderId }).subscribe(() => {
									if (!folderId) {
										this.errorModalService.openErrorModal(
											this.translate.instant(
												'features.manage_templates.optimail.templateFolderActions.DELETE_FOLDER'
											),
											this.translate.instant(
												'features.manage_templates.optimail.templateFolderActions.ACTION_DELETE_FOLDER'
											)
										);
										return;
									}
									this.updateFolderDeleted(folderId);
								});
							}
						});
					}
				},
				{
					class: 'btn-default',
					label: this.translate.instant('general.CANCEL'),
					action: () => {}
				}
			]
		});
	}

	private newFolder(initialState: { folderId: number; parentFolderId: number; subMethodId: number }): void {
		const modalRef = this.modalService.open(NewFolderModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				...initialState,
				folderCreated: (createdFolder: CreateUpdateFolderResponse) => {
					if (!createdFolder) {
						this.errorModalService.openErrorModal(
							this.translate.instant('features.manage_templates.optimail.templateFolderActions.NEW_FOLDER'),
							this.translate.instant('features.manage_templates.optimail.templateFolderActions.ACTION_NEW_FOLDER')
						);
						return;
					}
					this.$folderCreated.next(createdFolder);
				}
			}
		});
	}

	private createRootFolder(subMethod: SubMethod, id: number): RootTemplateTreeItem | null {
		if (!subMethod) {
			return;
		}
		let text = this.subMethodService.getSubMethodTypeText(subMethod.subMethodType, this.channelId);

		const root = {
			id,
			isVirtual: true,
			parentId: -1,
			rel: RelationType.folder,
			text: this.translate.instant(text),
			isRootFolder: true,
			children: [],
			displayText: null,
			specialIcon: 'none',
			specialText: 'root-folder',
			subMethodId: subMethod.subMethodId,
			executionMethodInnerID: subMethod.subMethodId,
			lastModifiedDate: null,
			isValid: true
		};
		return root;
	}
}
