import { Component, Input , ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'template-not-available',
    templateUrl: './templateNotAvailable.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./templateNotAvailable.component.scss'],
})
export class TemplateNotAvailableComponent {
    @Input() isLargeSize: boolean;
}