<div class="move-folder-modal">
  <opti-logic-modal [modalConf]="modalConf">
    <move-lists-component
      (selectedFolderIdEmitter)="setFolderId($event)"
      (selectedSubMethodIdEmitter)="setSubMethodId($event)"
      [subMethodsDisabled]="true"
      [subMethodId]="subMethodId"
      [clickedFolderId]="folderId"></move-lists-component>
  </opti-logic-modal>
</div>
