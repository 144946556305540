import { Component,	Input, Output, ViewChild, OnInit, OnChanges, SimpleChanges,	EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from "moment";
import { forkJoin } from "rxjs";
import { IAnalysisRange } from '../../customer360.models';
import { Customer360DataService } from '../../services/customer360.data.service';
import { CampaignKPIsData } from "../../../../models/customer360/campaignKPIsData.model";
import { CampaignsDetailsPerCustomer } from "../../../../models/customer360/campaignDetailsPerCustomer.model";
import { ColorsService } from '@optimove/ui-sdk/common/services';
import { FormatterPipe } from "../../../../pipes/formatter.pipe";
import { SearchListConfig } from "../../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { AddKPIsModalComponent } from "../add-kpis-modal/addKPIsModal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";

@Component({
	selector: 'customer-impact-graph',
	templateUrl: './customerImpactGraph.component.html',
	styleUrls: ['./customerImpactGraph.component.scss']
})
export class CustomerImpactGraphComponent implements OnInit, OnChanges {

	private _trendByValue: any;
	public set trendByValue(value: { viewTrendBy: string, emitEvent: boolean }) {
		this.trendByControl.setValue([this.trendByOptions.find(x => x.Value === value.viewTrendBy)], { emitEvent: value.emitEvent });
		this.router.navigate(
			[],
			{
				relativeTo: this.route,
				queryParams: { viewTrendBy: value.viewTrendBy },
				queryParamsHandling: "merge"
			});
		this._trendByValue = value.viewTrendBy;
	}

	public translateKeys = translateKeys;

	public Highcharts: typeof Highcharts = Highcharts;

	@Input() clientCustomerId: string;
	@Input() dateRange: IAnalysisRange

	@Output('onDataLoaded') onDataLoadedEvent = new EventEmitter<void>();

	@ViewChild('modal', { static: false }) addKPIsModal: AddKPIsModalComponent;

	public dateRangeChanged: boolean;
	public activityTrendChartConfiguration: any;

	public campaignsDetailsPerCustomer: CampaignsDetailsPerCustomer;
	public campaignsKPIsData: CampaignKPIsData;
	public campaignListData: any;
	public trendByOptions: any;
	public campaignMeasures: [];
	public defaultKpi: any;

	public trendByControl = new FormControl();

	public viewTrendBy: string;

	public searchListConfig: SearchListConfig = {
		itemNameTranslateKey: this.translate.instant(this.translateKeys.viewTrendBy),
		isMultiSelect: false,
		keyProperty: "Value",
		valueProperty: "AliasName",
		footerConfig: [{
			textProvider: () => this.translate.instant(this.translateKeys.addKPIsFooterText),
			icon: 'playlist_add',
			action: () => {
				this.addKPIsModal.openModal = true;
			}
		}]
	};

	constructor(
		private dataService: Customer360DataService,
		private colorsService: ColorsService,
		private formatter: FormatterPipe,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.viewTrendBy = this.route.snapshot.queryParamMap.get('viewTrendBy');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.clientCustomerId && this.dateRange?.startDate && this.dateRange?.endDate) {
			this.loadData();
		}
	}

	ngOnInit(): void {
		this.campaignMeasures = this.dataService.getCampaignMeasuresMapping();
		this.defaultKpi = this.dataService.getDefaultKPI();

		this.trendByOptions = this.getTrendByOptions();
		this.trendByValue = { viewTrendBy: this.getTrendByValue(), emitEvent: true };

		this.trendByControl.valueChanges.subscribe(trendByValue => {
			this.trendByValue = { viewTrendBy: trendByValue[0].Value, emitEvent: false };
			this.setActivityTrendChartConfiguration();
		});
	}

	public refreshTrendByOptions(kpiId: string) {
		this.dataService.resetCampaignMeasuresMapping().pipe(first()).subscribe(() => {
			this.campaignMeasures = this.dataService.getCampaignMeasuresMapping();
			this.trendByOptions = this.getTrendByOptions();
			this.dataService.getCampaignKPIsDataByCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate)
				.subscribe((res) => {
					this.handleCampaignKPIsData(res);
					this.trendByValue = { viewTrendBy: kpiId, emitEvent: true };
				});
		});
	};

	private loadData() {
		const promises = {
			campaignsDetails: this.dataService.getCampaignsDetailsPerCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate),
			campaignKPIsData: this.dataService.getCampaignKPIsDataByCustomerBetweenDates(this.clientCustomerId, this.dateRange.startDate, this.dateRange.endDate)
		};

		forkJoin(promises)
			.subscribe((response: { campaignsDetails, campaignKPIsData }) => {
				if (response) {
					this.handleCampaignsDetailsResponse(response.campaignsDetails);
					this.handleCampaignKPIsData(response.campaignKPIsData);
					this.onDataLoadedEvent.emit();
				}
			});
	}

	private handleCampaignsDetailsResponse(res) {
		this.campaignsDetailsPerCustomer = res.Data.CampaignsDetailsPerCustomer;
		this.campaignListData = this.getCampaignListData();
	}

	private handleCampaignKPIsData(res) {
		if (res.Data && res.Data.CampaignsKPIsData) {
			this.campaignsKPIsData = res.Data.CampaignsKPIsData;
			this.buildActivityTrendKPIs();
			this.setActivityTrendChartConfiguration();
		}
		this.dateRangeChanged = false;
	}

	private getCampaignListData() {
		if (this.campaignsDetailsPerCustomer) {
			return this.campaignsDetailsPerCustomer.CampaignList.map(function (item) {
				if (item.Channels.length > 1) {
					item.Priority = 'multi';
				}
				return {
					StartDate: moment(item.CampaignDate).format('YYYY-MM-DD'),
					TargetGroup: item.TargetGroupName,
					IsRecurring: item.IsRecurring,
					Trigger: item.Trigger,
					Action: item.Action,
					Channels: item.Channels,
					Priority: item.Priority,
					PromotionCode: item.PromotionCode,
					PromotionName: item.PromotionName
				};
			});
		}
		return [];
	}

	private buildActivityTrendKPIs() {
		this.campaignsKPIsData.ActivityTrendKPIs = [];
		if (this.campaignsKPIsData.KPIsHeadersList.length > 0) {
			this.campaignsKPIsData.KPIsHeadersList.forEach((kpiValue) => {
				if (kpiValue.TitleText === 'Number of Campaigns') {
					kpiValue.MetricValue = this.campaignListData.length;
				}
				this.campaignsKPIsData.ActivityTrendKPIs.push(kpiValue);
			});
		}
	}

	private setActivityTrendChartConfiguration() {
		const formatter = this.formatter;
		const component = this;

		const activityTrendDates = this.getActivityTrendDates();
		const activityTrendData = this.getActivityTrendData();
		this.activityTrendChartConfiguration = {
			tooltip: {
				borderWidth: 0,
				useHTML: true,
				formatter: function () {
					return component.getActivityTrendTooltip(this);
				}
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			title: {
				text: ''
			},
			legend: {
				itemMarginTop: 5,
				itemMarginBottom: 5,
				itemStyle: {
					width: 2000,
					fontFamily: 'Roboto',
					fontSize: '12px',
					fontWeight: '400',
					cursor: 'auto'
				},
				itemHoverStyle: {
					cursor: 'auto'
				},
				symbolPadding: 10,
				symbolHeight: 10,
				symbolWidth: 10,
				squareSymbol: true,
				backgroundColor: '#fafafa',
				verticalAlign: 'bottom',
				align: 'left'
			},
			xAxis: {
				startOnTick: true,
				min: 0,
				tickInterval: activityTrendDates.length <= 30 ? 1 : activityTrendDates.length <= 60 ? 2 : 3,
				tickmarkPlacement: 'on',
				categories: activityTrendDates
			},
			yAxis: {
				title: {
					text: null
				},
				labels: {
					formatter: function () {
						return formatter.transform(this.value, component.getTrendByObject().Format);
					}
				}
			},
			plotOptions: {
				series: {
					color: this.colorsService.getColorConstants('brand-sky')
				},
				line: {
					events: {
						legendItemClick: function () {
							return false;
						}
					}
				}
			},
			series: [
				{
					showInLegend: false,
					data: activityTrendData,
					marker: {
						lineWidth: 1.5,
						fillColor: 'white',
						lineColor: this.colorsService.getColorConstants('brand-sky')
					}
				},
				{
					name: "Received scheduled campaign(s) this day",
					data: [],
					marker: {
						fillColor: this.colorsService.getColorConstants('brand-sky'),
						symbol: 'circle',
						radius: 5
					}
				},
				{
					name: "Didn't receive a campaign this day",
					data: [],
					marker: {
						lineWidth: 1.5,
						lineColor: this.colorsService.getColorConstants('brand-sky'),
						fillColor: 'white',
						symbol: 'circle',
						radius: 5
					}
				},
				{
					name: "Received triggered campaign(s) this day",
					data: [],
					marker: {
						lineWidth: 0,
						symbol: 'url(assets/images/flash_icon.svg)',
						states: {
							hover: {
								fillColor: 'white',
								lineColor: 'white',
								radius: 5
							}
						},
						fillColor: 'white',
						lineColor: 'white',
						radius: 5
					}
				}]
		};
	}

	private getActivityTrendTooltip(context) {
		let trendByObject = this.getTrendByObject();
		let tooltip = context.x + '<br/>' +
			'<b>' + trendByObject.AliasName + ': </b>' + this.formatter.transform(context.y, trendByObject.Format);
		if (context.key === 'targeted' && context.point.campaignsInfo.ScheduledCount > 0) {
			const channels = context.point.campaignsInfo.ScheduledChannelList.toString().replace(/,/g, ", ");
			tooltip += `<div><hr style="margin-top:6px;margin-bottom:6px;"/><div style="display:flex;"><div><img alt=""/></div><div>Scheduled campaigns: <b>${context.point.campaignsInfo.ScheduledCount}</b><br/>` +
				(('' + channels + '').indexOf(',') === -1 ? 'Channel: ' : 'Channels: ') + `<b>${channels}</b></div></div></div>`;
		}
		if (context.key === 'targeted' && context.point.campaignsInfo.TriggeredCount > 0) {
			const channels = context.point.campaignsInfo.TriggeredChannelList.toString().replace(/,/g, ", ");
			tooltip += '<div><hr style="margin-top:6px;margin-bottom:6px;"/><div style="display:flex;"><div><img alt=""/></div><div>' +
				`Triggered campaigns: <b>${context.point.campaignsInfo.TriggeredCount}</b><br/>` +
				(('' + channels + '').indexOf(',') === -1 ? 'Channel: ' : 'Channels: ') + `<b>${channels}</b></div></div></div>`;
		}
		return tooltip;
	}

	private getTrendByObject() {
		return this.trendByOptions.filter((item) => {
			if (item.Value === this._trendByValue) {
				return item;
			}
		})[0];
	}

	private getTrendByOptions() {
		const trendByOptions = [];
		for (let key in this.campaignMeasures) {
			if (this.campaignMeasures.hasOwnProperty(key) && key != '0') {
				trendByOptions.push(this.campaignMeasures[key]);
			}
		}
		return trendByOptions;
	}

	private getTrendByValue() {
		if (this.viewTrendBy && this.trendByOptions.some(item => item.Value === this.viewTrendBy))
			return this.viewTrendBy;

		return this.defaultKpi.kpiId;
	}

	private getActivityTrendDates() {
		let datesArr = [];
		this.campaignsKPIsData.CampaignKPIsList.forEach(function (campaignKPIs) {
			datesArr.push(moment(campaignKPIs.KPIDataDate).format('YYYY-MM-DD'));
		});
		return datesArr;
	}

	private getActivityTrendData() {
		const activityTrendData = [];
		for (const key in this.campaignsKPIsData.CampaignKPIsList) {
			const kpiValue = this.campaignsKPIsData.CampaignKPIsList[key];
			const campaignsInfo = this.campaignsDetailsPerCustomer.CampaignDatesDic[kpiValue.KPIDataDate];
			if (this.campaignsKPIsData.CampaignKPIsList.hasOwnProperty(key)) {
				let symbol = 'customer360-circle-empty.svg';
				if (campaignsInfo.TriggeredCount > 0 && campaignsInfo.ScheduledCount > 0) {
					symbol = 'customer360-zipper.svg';
				} else if (campaignsInfo.TriggeredCount === 0 && campaignsInfo.ScheduledCount > 0) {
					symbol = 'customer360-circle.svg';
				} else if (campaignsInfo.TriggeredCount > 0 && campaignsInfo.ScheduledCount === 0) {
					symbol = 'customer360-zipper-empty.svg';
				}

				activityTrendData.push({
					marker: {
						symbol: `url(assets/images/${symbol})`,
						states: {
							hover: {
								fillColor: this.colorsService.getColorConstants('brand-sky'),
								lineColor: this.colorsService.getColorConstants('brand-sky')
							}
						},
						fillColor: this.colorsService.getColorConstants('brand-sky'),
						lineColor: this.colorsService.getColorConstants('brand-sky')
					},
					name: 'targeted',
					campaignsInfo: campaignsInfo,
					y: kpiValue.KPIsValues[this._trendByValue]
				});
			}
		}
		return activityTrendData;
	}
}

const translateKeys = {
	subtitle: 'features.customer360.body.customer_activity_trend.SUBTITLE',
	viewTrendBy: 'general.VIEW_TREND_BY',
	addKPIsFooterText: 'features.customer360.body.customer_activity_trend.DROPDOWN_FOOTER'
}