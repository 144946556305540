import { AudienceDiscoveryGuard } from './guard/audienceDiscovery.guard';
import { UnauthorizedComponent } from '../features/errorPages/unauthorized/unauthorized.component';
import { EmptyComponent } from './empty.component';
import { DefaultRoutingActivate } from './defaultRoutingActivate.service';
import { NgModule } from '@angular/core';
import {
	Routes,
	RouterModule,
	UrlSerializer,
	NoPreloading
} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { CustomHashLocationStrategy } from './customHashLocationStrategy.service';
import { AuthorizedService } from './authorizedService.service';
import { legacyAngularJsRoutes } from './app.legacy-routing';
import { CustomUrlSerializer } from './CustomUrlSerializer.service';
import { AngularJsContainer } from '../components/angularJsContainer/AngularJsContainer.component';
import { UpgradedUiViewComponent } from '../components/upgrade/upgradedUiView.component';
import { LegacyGuardDeactived } from './legacyGuardDeactived.service';
import { MarketingPlanDeprecationComponent } from '../features/errorPages/marketingPlanDeprecation/marketingPlanDeprecation.component';
import { MarketingPlanDeprecationGuard } from './guard/marketingPlanDeprecation.guard';
import { LeavingAngularJsPageGuard } from './guard/leavingAngularJsPage.guard';
import { CanDeactivateStateService } from './guard/canDeactivateState.service';
import { DeprecationPageGuard } from './guard/deprecationPage.guard';
import { FeatureFlagRedirect } from './guard/featureFlagRedirect.guard';
import { RouteRemoteWrapperComponent } from './route-remote-wrapper.component'
import { MicrofrontendRouteService, DynamicActivator } from '../utils/remoteModules/microfrontend-route.service';
import { ImportedCustomersGuard } from './guard/importedCustomers.guard';
import { RealtimeAttributesGuard } from './guard/realtimeAttributes.guard';
import { getMicrofrontendUrl } from '../utils/remoteModules/microfrontend-utils';

export const newRoutes: Routes = [
	{
		path: '',
		canActivateChild: [DynamicActivator],
		children: [
			{
				path: 'category/opti-promo',
				loadChildren: () => import('../features/optiPromo/optiPromo.module').then(m => m.OptiPromoModule),
				canActivate: [AuthorizedService],
			},
			{
				path: 'category/analysis_&_research/dashboard',
				loadChildren: () => import('../features/dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthorizedService],
			},
			{
				path: 'category/analysis_&_research/audience-discovery',
				loadChildren: () => import('../features/audienceDiscovery/audienceDiscovery.module').then(m => m.AudienceDiscoveryModule),
				canActivate: [AudienceDiscoveryGuard],
			},
			{
				path: 'category/:category/manage-streams',
				loadChildren: () => import('../features/streams/manage/manageStreams.module').then(m => m.ManageStreamsModule),
			},
			{
				path: 'streams/analysis/:streamTestId',
				loadChildren: () => import('../features/streams/streamAnalysis/streamAnalysis.module').then(m => m.StreamAnalysisModule),
			},
			{
				path: 'category/:category/streams-builder',
				loadChildren: () => import('../features/streams/streamBuilder/streamBuilder.module').then(m => m.StreamBuilderModule),
			},
			{
				path: 'category/:category/manage-actions',
				loadChildren: () => import('../features/manageActions/manageActions.module').then(m => m.ManageActionsModule),
			},
			{
				path: 'optibot',
				canActivate: [AuthorizedService],
				loadChildren: () => import('../features/optibot/optibot.module').then(m => m.OptibotModule),
			},
			{
				path: 'events-stream',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/eventStreamer/event-streamer.module').then(m => m.EventStreamerModule),
			},
			{
				path: 'category/:category/triggered-campaign-builder',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/triggeredCampaignBuilderLegacy/triggeredCampaignBuilderLegacy.module').then(m => m.TriggeredCampaignBuilderLegacyModule),
			},
			{
				path: 'category/:category/campaign-builder',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/campaignBuilder/campaignBuilder.module').then(m => m.CampaignBuilderModule),
			},
			{
				path: 'category/:category/customer-explorer',
				loadChildren: () => import('../features/customerExplorer/customerExplorer.module').then(m => m.CustomerExplorerModule),
			},
			{
				path: 'category/:category/realtime-customer-profile',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/realtimeCustomerProfile/realtimeCustomerProfile.module').then(m => m.RealtimeCustomerProfileModule),
			},
			{
				path: 'category/:category/mission-control',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/missionControl/missionControl.module').then(m => m.MissionControlModule),
			},
			{
				path: 'success-hub',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/successHub/successHub.module').then(m => m.SuccessHubModule),
			},
			{
				path: 'category/:category/templates',
				loadChildren: () => import('../features/manageTemplates/manageTemplates.module').then(m => m.ManageTemplatesModule),
			},
			{
				path: 'category/:category/newBiStudio',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/biStudio/biStudio.module').then(m => m.BiStudioModule),
			},
			{
				path: 'category/data__studio/imported-customers',
				loadChildren: () => import('../features/importedCustomers/importedCustomers.module').then(m => m.ImportedCustomersMFModule),
				canActivate: [ImportedCustomersGuard],
			},
			{
				path: 'category/data__studio/realtime-attributes',
				loadChildren: () => import('../features/realtimeAttributes/realtimeAttributes.module').then(m => m.RealtimeAttributesMFModule),
				canActivate: [RealtimeAttributesGuard],
			},
			{
				path: 'category/:category/experiment-settings',
				redirectTo: 'category/:category/streams-builder/experiment-settings',
			},
			{
				path: 'unauthorized',
				component: UnauthorizedComponent
			},
			{
				path: 'marketing-plan-deprecation',
				component: MarketingPlanDeprecationComponent
			},
			{
				path: 'empty-page',
				canActivateChild: [AuthorizedService],
				loadChildren: () => import('../features/emptyPage/emptyPage.module').then(m => m.EmptyPageModule),
			},
			{
				path: '**',
				canActivate: [DefaultRoutingActivate],
				component: EmptyComponent
			},
			
		],
		canActivate: [AuthorizedService],
	}
];

newRoutes[0].children = [...legacyAngularJsRoutes[0].children, ...newRoutes[0].children];

@NgModule({
	imports: [RouterModule.forRoot(newRoutes, {
		// enableTracing: process.env.ENV !== 'prod',
		onSameUrlNavigation: 'reload',
		preloadingStrategy: NoPreloading,
		initialNavigation: 'disabled', // we want to trigger navigation ourselves after ng1 is done bootstrapping
		relativeLinkResolution: 'legacy'
	})],
	declarations: [EmptyComponent, UnauthorizedComponent, AngularJsContainer, UpgradedUiViewComponent],
	exports: [RouterModule],
	providers: [
		{ provide: LocationStrategy, useClass: CustomHashLocationStrategy }, // # style navigation
		{ provide: UrlSerializer, useClass: CustomUrlSerializer }, // allow equal (=) characters in url
		DefaultRoutingActivate,
		AuthorizedService,
		LegacyGuardDeactived,
		MarketingPlanDeprecationGuard,
		LeavingAngularJsPageGuard,
		CanDeactivateStateService,
		DeprecationPageGuard,
		FeatureFlagRedirect,
		MicrofrontendRouteService,
		DynamicActivator
	]
})

export class AppRoutingModule { }
