import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IModalConf } from '../../../../../components/optiLogicModal/optiLogicModal.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { ManageTemplatesService } from '../../../services/manageTemplates.service';
import { LanguageTemplate } from '../../../models/languageTemplate';

export interface TranslationLanguagesInitialState {
	languagesSelected: (selectedLanguages: LanguageTemplate[]) => void;
	languagesPreSelected: SelectableLanguageTemplate[];
}

export interface SelectableLanguageTemplate extends LanguageTemplate {
	isDefault: boolean;
}

@Component({
	selector: 'translation-languages-modal',
	templateUrl: './translationLanguages.component.html',
	styleUrls: ['./translationLanguages.component.scss']
})
export class TranslationLanguagesComponent implements OnInit, TranslationLanguagesInitialState {
	languagesSelected = (selectedLanguages: SelectableLanguageTemplate[]) => {};
	languagesPreSelected: SelectableLanguageTemplate[];

	public modalConf: IModalConf;
	public selectedIds: number[] = [];
	public selectedLanguages: SelectableLanguageTemplate[] = [];
	public languagesTemplate: LanguageTemplate[];
	public languages: IListItem[] = [];
	public dropDownListId: [];
	private readonly languagesKey = 'recentLanguages';
	public defaultLanguage: SelectableLanguageTemplate;
	constructor(
		private translateService: TranslateService,
		public bsModalRef: BsModalRef,
		private manageTemplatesService: ManageTemplatesService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.selectedLanguages = this.languagesPreSelected.filter((lang) => {
			return !lang.isDefault;
		});
		this.defaultLanguage = this.languagesPreSelected.filter((lang) => {
			return lang.isDefault;
		})[0];
		this.selectedIds = this.selectedLanguages.map((lng) => {
			return lng.Id;
		});
		this.manageTemplatesService.getTemplateLanguage().subscribe((res) => {
			this.languagesTemplate = res;
			this.languages = res.map((languageTemplate) => {
				return { id: languageTemplate.Id, name: languageTemplate.DisplayName };
			});
			this.languages = this.languages.filter((lang) => {
				return lang.id !== this.defaultLanguage.Id;
			});
		});
		this.initModalConfig();
	}

	private initModalConfig(): void {
		this.modalConf = {
			title: this.translateService.instant('features.manage_templates.optimail.translationLanguagesModal.HEADER'),
			hideHeaderPanel: false,
			hideFooterPanel: false,
			hideFooterBorder: false,
			xButton: {
				action: () => {
					this.bsModalRef.hide();
					return;
				}
			},
			buttons: [
				{
					class: 'btn-primary',
					label: this.translateService.instant('features.manage_templates.optimail.translationLanguagesModal.DONE'),
					action: () => {
						this.selectedLanguages = [this.defaultLanguage].concat(this.selectedLanguages);
						localStorage.setItem(this.languagesKey, JSON.stringify(this.selectedLanguages));
						this.languagesSelected(this.selectedLanguages);
						this.bsModalRef.hide();
						return;
					}
				},
				{
					class: 'btn-default',
					label: this.translateService.instant('general.CANCEL'),
					action: () => {
						this.bsModalRef.hide();
						return;
					}
				}
			]
		};
	}
	public clearAllLanguages() {
		this.selectedLanguages = [];
		this.selectedIds = [];
	}
	public selectRecent() {
		let languages = JSON.parse(localStorage.getItem(this.languagesKey));
		this.selectedLanguages = languages.filter((lng) => lng.Id !== this.defaultLanguage.Id);
		this.selectedIds = this.selectedLanguages.map((lng) => lng.Id);
	}
	public selectedIdsChanged($event) {
		this.selectedLanguages = $event.map((languageId) => {
			const isDefault = this.languagesPreSelected.find((preSelected) => preSelected.Id === languageId)?.isDefault ?? false;
			return { ...this.languagesTemplate.find((x) => x.Id === languageId), isDefault };
		});
	}

	public removeClicked(language) {
		const index = this.selectedLanguages.indexOf(language, 0);
		if (index > -1) {
			this.selectedLanguages.splice(index, 1);
		}
		if (index > -1) {
			this.selectedIds = this.selectedIds.filter((id) => id !== language.Id);
		}
		this.cd.detectChanges();
	}
}
