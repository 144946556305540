<div class="panel-body-wrap">
    <div class="panel-body col-6">
        <h3 class="high-chart-header" translate>
            {{ translateKeys.actionsDistributionChartHeader }}
        </h3>
        <section class="no-data-message" *ngIf="dataMessage">
            <div class="row">
                <div class="col-md-12 text-center text-muted">
                    <h1 data-qa-id="ActionsNoCampaignsTitle" translate>
                        {{ translateKeys.noCampaignsFoundTitle }}
                    </h1>
                    <h3 data-qa-id="ActionsNoCampaignsDescription" translate>
                        {{ translateKeys.noCampaignsFoundDescription }}
                    </h3>
                </div>
            </div>
        </section>
        <highcharts-chart
                data-qa-id="ActionsDistributionChart"
                *ngIf="!dataMessage && !dateRangeChanged"
                [options]="actionsDistributionChartConfiguration"
                style="width: 100%; height: 400px; display: block;"
        ></highcharts-chart>
    </div>
    <div class="panel-body col-6">
        <h3 class="high-chart-header" translate>
             {{ translateKeys.channelDistributionChartHeader }}
        </h3>
        <section class="no-data-message" *ngIf="dataMessage">
            <div class="row">
                <div class="col-md-12 text-center text-muted">
                    <h1 data-qa-id="ChannelNoCampaignsTitle" translate>
                        {{ translateKeys.noCampaignsFoundTitle }}
                    </h1>
                    <h3 data-qa-id="ChannelNoCampaignsDescription" translate>
                        {{ translateKeys.noCampaignsFoundDescription }}
                    </h3>
                </div>
            </div>
        </section>
        <highcharts-chart
                data-qa-id="ChannelDistributionChart"
                *ngIf="!dataMessage && !dateRangeChanged"
                [options]="channelDistributionChartConfiguration"
                style="width: 100%; height: 400px; display: block;"
        ></highcharts-chart>
    </div>
</div>