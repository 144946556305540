import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'arrow-scroll',
	templateUrl: 'arrowsScroll.component.html',
	styleUrls: ['arrowsScroll.component.scss']
})
export class ArrowsScrollComponent {
	@Output() indexAgGrid = new EventEmitter<number>();
	@Input() numOfColumns;
	public index: number = 0;

	constructor() {}
	public increaseIndex() {
		if (this.index < this.numOfColumns) {
			this.index++;
			this.indexAgGrid.emit(this.index);
		}
	}
	public decreaseIndex() {
		if (this.index > 0) {
			this.index--;
			this.indexAgGrid.emit(this.index);
		}
	}
}
