import {Injectable} from "@angular/core";

function _window() : any {
    // return the global native browser window object
    return window;
}

@Injectable()
export class WindowRefService {
    static getInstance(): any {
        return new WindowRefService();
    }
    get nativeWindow(): any {
        return _window();
    }
}