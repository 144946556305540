import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { DefaultAjaxResponse } from '@optimove/ui-sdk/common/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SsmService } from '../../services/ssm.service';
import { HttpService } from '../../services/optimove-http/optimove-http.model';

@Injectable()
export class RealtimeAttributesGuard implements CanActivate {
    private ssmService: any;
    private isOptimoveAdmin: boolean;

    constructor(private httpService: HttpService, private ssm: SsmService, private router: Router) {
        this.ssmService = ssm.getSSM();
    }
    
    canActivate(): Observable<boolean> {
        return this.httpService.get<DefaultAjaxResponse>({ controller: 'UserSettings', action: 'GetFeatureFlagData' })
            .pipe(map((res: DefaultAjaxResponse) => {
                const adminCondition = res.Data.find(ff => ff.FeatureName === 'RealtimeProfileDataManagementForUser');
                const optimoveAdminCondition = res.Data.find(ff => ff.FeatureName === 'RealtimeProfileDataManagement');
                
                let tenantDetails = this.ssmService.GetGeneric(this.ssmService.Resx.TenantDetails);
                this.isOptimoveAdmin = tenantDetails.userRole == 'OptimoveAdmin';

                
                if ((adminCondition && adminCondition.IsEnabled) 
                    || (optimoveAdminCondition && optimoveAdminCondition.IsEnabled && this.isOptimoveAdmin)) {
                    return true;
                } else {
                    this.router.navigate(['**']);
                    return false;
                }
            }));
    }
}
