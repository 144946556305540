import {Injectable} from '@angular/core';

function _sessionStorage() : any {
    // return the global native browser session storage object
    return sessionStorage;
}

@Injectable()
export class SessionStorageService {

    // getter for native local storage
    get nativeSessionStorage(): any {
        return _sessionStorage();
    }

}
