import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shorten'
})

export class ShortenPipe implements PipeTransform {
    private readonly MAX_CHARS = 50;

    transform(text: string): any {
        if (text.length > this.MAX_CHARS) {
            return text.substring(0, (this.MAX_CHARS - 3)) + '...';
        }

        return text;
    }
}