import { Customer360PurchaseHistoryService } from '../../services/customer360.purchaseHistory.service';
import { PurchaseHistoryMultiAttributeFlowRowData } from '../../customer360.models';
import { ColumnApi, GridApi, GridOptions } from '@ag-grid-community/all-modules';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { isUndefined } from "angular";
import * as moment from "moment/moment";
import { ModalOptions } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";
import { ActivityAttribute, ActivityType, IAnalysisRange } from '../../customer360.models';
import { purchaseHistoryTranslateKeys } from '../../customer360.translate.key';
import { Customer360DataService } from '../../services/customer360.data.service';
import { Customer360FormatService } from "../../services/customer360.format.service";
import { OptiLogicModalService } from 'src/app/components/optiLogicModal/optiLogicModal.service';
import { SelectAttributesModalComponent } from './selectAttributesModal/selectAttributesModal.component';
@Component({
    selector: 'purchase-history-multi-attribute',
    templateUrl: './purchaseHistoryMultiAttributeFlow.component.html',
    styleUrls: [ './purchaseHistory.component.scss' ]
})
export class PurchaseHistoryMultiAttributeFlowComponent implements OnInit {

    @Input() clientCustomerId: string;
    @Output() onDataLoaded = new EventEmitter<boolean>();

    public checkedActivityAttributesKeys: string = "";
    public activityTypes: ActivityType[] = [];
    public activityAttributes: {[key: string]: ActivityAttribute[]} = {}; //Attributes to select
    public selectedAttributes: {[key: string]: ActivityAttribute[]} = {};
    public purchaseHistoryData: {[activityTypeId: string]: PurchaseHistoryMultiAttributeFlowRowData[]} = {};
    
    public gridOptions: GridOptions| undefined;
    public gridApi: GridApi | undefined;
    public gridColumnApi: ColumnApi | undefined;

    public translateKeys = purchaseHistoryTranslateKeys;
    public rangeUrlParametersPrefix = "PH";
    public tableWidth: number = 1122;
    public selectedActivityTypeId: number;
    public selectedAnalysisRange: IAnalysisRange;
    public isTableLoading: boolean;
    public areNoAttributesSelected: boolean;

    private date = moment().format('YYYY-MM-DD');
    private siteName = sessionStorage['SiteName'].replace(/"/g, "");

    constructor(
        private dataService: Customer360DataService,
        private optiLogicModalService: OptiLogicModalService,
        private formatService: Customer360FormatService,
        private translate: TranslateService,
        private readonly cd: ChangeDetectorRef,
        private purchseHistoryService: Customer360PurchaseHistoryService
    ) { }

    async ngOnInit(): Promise<void> {
        this.isTableLoading = true;
        await this.initActivities();
        if (!this.areNoAttributesSelected) {
            await this.initGridOptions(this.selectedAttributes[this.selectedActivityTypeId]);
        }
        else {
            this.isTableLoading = false;
        }
    }

    public async onAnalysisRangeChanged(range: IAnalysisRange) {
        this.selectedAnalysisRange = range;
        this.isTableLoading = true;
        await this.loadPurchaseHistory();
    }

    public async onActivityTypeChanged(typeId: number) {
        if (this.selectedActivityTypeId !== typeId && !this.isTableLoading) {
            this.selectedActivityTypeId = typeId;
            if (this.selectedAttributes[this.selectedActivityTypeId].length !== 0) {
                this.areNoAttributesSelected = false;
                if (!this.gridApi && !this.gridColumnApi) {
                    this.isTableLoading = true;
                    await this.initGridOptions(this.selectedAttributes[this.selectedActivityTypeId]);
                }
                else {
                    this.gridApi.setColumnDefs(this.purchseHistoryService.createColumnMultiAttributeFlowDefs(this.selectedAttributes[this.selectedActivityTypeId], this.tableWidth));
                    this.gridApi.sizeColumnsToFit();
                    this.gridApi.setRowData(this.purchaseHistoryData[this.selectedActivityTypeId]); 
                }
            }
            else {
                this.areNoAttributesSelected = true;
            }
        }
    }

    //=== CSV export ===
    
    public get hasListData(): boolean {
        return this.purchaseHistoryData[this.selectedActivityTypeId]?.length > 0;
    }

    public get attributesListCsvFileName() {
        return `Optimove ${ this.siteName } CustomerID ${ this.clientCustomerId } Purchase History MultiAttributeFlow ${this.activityTypes.find(x => x.Id === this.selectedActivityTypeId)?.Name } Details ${ this.date }.csv`;
    }

    public get attributesListCsvFileHeaders(): string[] {
        if (this.purchaseHistoryData[this.selectedActivityTypeId]?.length > 0) {
			return Object.keys(this.purchaseHistoryData[this.selectedActivityTypeId][0]);
		} else {
			return [];
		}
    }
    
    public attributesListCsvSerializerFunc = function (data: any) {
        let csvRowParts = [];
        for (let prop of Object.keys(data)) {
            let value = isUndefined(data[prop]) || data[prop] === null ? 'NA' : data[prop];

            if (prop === 'TransactionDate') {
                value = moment(value).format('YYYY-MM-DD');
            }

            this.pushWithQuotes(csvRowParts, value);
        }
        return `${ csvRowParts.join(',') }`;
    }.bind(this);

    public pushWithQuotes(array, value) {
        if (!value.toString().includes('"'))
            array.push('"' + value + '"');
        else
            array.push(value);
    };

    //=== Table ===

    private initActivities(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dataService.getActivityTypes().pipe(first()).subscribe(types => {
                this.activityTypes = types.Data;

                if (this.activityTypes) {
                    this.dataService.getActivityAttributes().pipe(first()).subscribe(attributes => {
                        this.activityTypes.forEach(type => {
                            this.activityAttributes[type.Id] = attributes.Data.filter((attribute: ActivityAttribute) => attribute.ActivityTypeId === type.Id);
                        });
                        this.selectedAttributes = this.getAttributesFromLocalStorage();
                        this.selectedActivityTypeId = this.activityTypes[0]?.Id;
                        this.areNoAttributesSelected = this.selectedAttributes[this.selectedActivityTypeId].length === 0;
                        this.cd.detectChanges();
                        resolve();
                    });
                } else {
                    this.cd.detectChanges();
                    resolve();
                }
            });
        });
    }

    private initGridOptions(selectedAttributes: ActivityAttribute[], preLoadFunc: Function = () => {}): Promise<void> {
        return new Promise((resolve, reject) => {
            this.gridOptions = <GridOptions> {
                columnDefs: this.purchseHistoryService.createColumnMultiAttributeFlowDefs(selectedAttributes, this.tableWidth),
                headerHeight: 30,
                rowData: [],
                rowHeight: 44,
                onGridReady: async (params) => {
                    const isFirstGridInit = !this.gridApi && !this.gridColumnApi;
                    this.gridApi = params.api;
                    this.gridColumnApi = params.columnApi;
                    if (isFirstGridInit || this.purchaseHistoryData[this.selectedActivityTypeId] || (!this.purchaseHistoryData[this.selectedActivityTypeId] || this.purchaseHistoryData[this.selectedActivityTypeId].length === 0)) {
                        preLoadFunc();
                        await this.loadPurchaseHistory();
                    }
                    else {
                        this.gridApi.setRowData(this.purchaseHistoryData[this.selectedActivityTypeId]); 
                    }
                    resolve();
                },
                overlayNoRowsTemplate: `<div class="text-center text-muted">
                    <h1>${this.translate.instant(this.translateKeys.EMPTY_TABLE_MESSAGE)}</h1>
                </div>`,
            }
        });
        
    }

    private loadPurchaseHistory(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.clientCustomerId && this.selectedAnalysisRange && this.selectedAttributes && this.gridApi) {
                let attributesToGet = [];
    
                for (const activityType in this.selectedAttributes) {
                    attributesToGet.push(...this.selectedAttributes[activityType]);
                }

                if (attributesToGet.length === 0) {
                    this.gridApi.setRowData([]);
                    this.isTableLoading = false;
                    resolve();
                }
    
                this.dataService.GetPurchaseHistoryMultiAttributeFlowBetweenDates(this.clientCustomerId, this.selectedAnalysisRange, attributesToGet)
                .pipe(first()).subscribe(res => {
                    if (res.IsSuccess && res.Data?.length > 0) {
                        this.purchaseHistoryData = {};

                        this.formatService.PurchaseHistoryItemToRowData(res.Data, this.selectedAttributes).forEach(rowData => {

                            if (!this.purchaseHistoryData[rowData.ActivityTypeId]) {
                                this.purchaseHistoryData[rowData.ActivityTypeId] = [];
                            }

                            //order properties to show it correctly in an exported csv. BUG-184715
                            let orderExample = {TransactionDate: null, TransactionId: null};

                            this.selectedAttributes[rowData.ActivityTypeId].forEach((x) => {
                                orderExample[x.RealFieldName] = null;
                            });

                            const sortedRow = this.formatService.sortObjectProps(rowData, orderExample)
                            this.purchaseHistoryData[rowData.ActivityTypeId].push(sortedRow as PurchaseHistoryMultiAttributeFlowRowData);
                        });

                        if (this.purchaseHistoryData[this.selectedActivityTypeId] !== undefined) {
                            this.gridApi.setRowData(this.purchaseHistoryData[this.selectedActivityTypeId]); 
                        }

                        resolve();
                        this.isTableLoading = false;
                    } else {
                        this.purchaseHistoryData = {};
                        this.gridApi.setRowData([]);
                        resolve();
                        this.isTableLoading = false;
                    }
                });
            } 
            else if(this.gridApi) {
                this.gridApi.setRowData([]);
                resolve();
                this.isTableLoading = false;
            }
            else {
                this.isTableLoading = false;
                resolve();
            }
        });
    }

    //=== Modal ===

    public openSelectActivityAttributesModal() {
        this.optiLogicModalService.open(
            SelectAttributesModalComponent,
            'md',
            <ModalOptions<any>>{
                ignoreBackdropClick: false,
                initialState:{
                    attributesToSelect: this.activityAttributes[this.selectedActivityTypeId],
                    selectedAttributes: this.selectedAttributes[this.selectedActivityTypeId],
                    activityType: this.activityTypes.find(x => x.Id === this.selectedActivityTypeId).Name,
                    onDone: (attributes: ActivityAttribute[]) => {this.onSelectAttributes(attributes)},
                }
            });
    }

    private async onSelectAttributes(attributes: ActivityAttribute[]) {
        if (attributes.length > 0 && this.areNoAttributesSelected) {
            this.areNoAttributesSelected = false;
            this.isTableLoading = true;
            await this.initGridOptions(attributes, () => {
                this.isTableLoading = true;
                this.refreshselectedAttributes(attributes); 
                this.updateAttributesInLocalStorage();
            });
        }
        else {
            this.isTableLoading = true;
            this.refreshselectedAttributes(attributes); 
            this.updateAttributesInLocalStorage();
            await this.loadPurchaseHistory();
        }
    }

    private refreshselectedAttributes(attributes: ActivityAttribute[]) {
        this.selectedAttributes[this.selectedActivityTypeId] = attributes;
        this.gridApi.setColumnDefs(this.purchseHistoryService.createColumnMultiAttributeFlowDefs(this.selectedAttributes[this.selectedActivityTypeId], this.tableWidth));
        this.gridApi.sizeColumnsToFit();
    }

    private updateAttributesInLocalStorage() {
        let attributes: {[activityType: number]: string[]} = {};

        for (const activityType in this.selectedAttributes) {
            attributes[activityType] = this.selectedAttributes[activityType].map(x => x.RealFieldName);
        }

        this.dataService.setToLocalStorage("activityAttributes.selectedAttrMapping", JSON.stringify(attributes));
    }

    private getAttributesFromLocalStorage(): {[activityType: number]: ActivityAttribute[]} {
        const attributesJSON = this.dataService.getFromLocalStorage("activityAttributes.selectedAttrMapping");
        let selectedAttributes: {[activityType: number]: ActivityAttribute[]} = {};

        if (attributesJSON) {
            const attributes: {[activityType: number]: string[]} = JSON.parse(attributesJSON);
    
            for (const activityType in attributes) {
                selectedAttributes[activityType] = this.activityAttributes[activityType].filter(x => attributes[activityType].includes(x.RealFieldName));
            }
        }
        else {
            for (const activityType in this.activityAttributes) {
                selectedAttributes[activityType] = [];
            }
        }

        return selectedAttributes;
    }
}