import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';

@Injectable()
export class TemplateScriptGuardModalService {
	private isRecaptchaValidSubject = new BehaviorSubject<boolean>(false);
	isRecaptchaValid = this.isRecaptchaValidSubject.asObservable();

	constructor(private readonly http: HttpService) {}
	public setRecaptchaToken(token: string) {
		return this.http.post<AjaxResponse<boolean>>(`/Captcha/SetRecpatchaTokenForSession`, { token: token }).pipe(
			map((res) => {
				this.isRecaptchaValidSubject.next(res.data);
				return res.data;
			})
		);
	}
}
