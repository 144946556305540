import { Component, Input, ViewChild, AfterViewInit , ViewEncapsulation } from "@angular/core";
import { IChannelMetricsElement, MainMetricTypes } from "../../campaignAnalysis.models";
import { FormatterPipe } from "../../../../pipes/formatter.pipe";



@Component({
    selector: 'channel-metrics-rings',
    templateUrl: './channelMetricsRings.component.html',
    styleUrls: ['./channelMetricsRings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsRingsComponent implements AfterViewInit {

    @ViewChild('highchart', {static: false}) highchart: any;

    @Input() delivered: IChannelMetricsElement;
    @Input() opened: IChannelMetricsElement;
    @Input() clicked: IChannelMetricsElement;

    private trackBackgroundColor = '#EEEEEE';
    private trackBorderWidth = 0;
    private radiuses = {
        Delivered: {
            inner: '50%',
            outer: '68%'
        },
        Opened: {
            inner: '72%',
            outer: '90%'
        },
        Clicked: {
            inner: '94%',
            outer: '112%'
        }
    }


    constructor(private formatter: FormatterPipe) {
    }

    get chartOptions() {
        if (this._chartOptions) {
            this.updateSeries();
            this.updateTracks();
        }
        return this._chartOptions;
    }

    private updateSeries() {
        this.updateSerie(this.delivered)
        this.updateSerie(this.opened)
        this.updateSerie(this.clicked)
    }

    private updateTracks() {
        this._chartOptions.pane.background = [];

        if (this.delivered)
            this.addTrack(this.radiuses.Delivered);
        if (this.opened)
            this.addTrack(this.radiuses.Opened);
        if (this.clicked)
            this.addTrack(this.radiuses.Clicked);
    }

    private updateSerie(metricData: IChannelMetricsElement) {
        if (!metricData)
            return;

        let serie = this._chartOptions.series.find(s => s.name === metricData.name);
        if (serie) {
            serie.data[0].y = metricData.percent;
            serie.data[0].color = metricData.color;
        }
    }

    private addTrack(radius) {
        this._chartOptions.pane.background.push({
            outerRadius: radius.outer,
            innerRadius: radius.inner,
            backgroundColor: this.trackBackgroundColor,
            borderWidth: this.trackBorderWidth
        });
    }

    private _chartOptions = {
        channelMetricsRings: this,
        chart: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            type: 'solidgauge',
            height: '110%'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        title: null,
        pane: {
            startAngle: 0,
            endAngle: 360,
            background: []
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: false
            }
        },
        tooltip: {
            followPointer: true,
            distance: 6,
            useHTML: true,
            formatter: function () {
                const formatter = this.series.chart.options.channelMetricsRings.formatter;
                const percent = this.y < 0 ? '-' : formatter.transform(this.y / 100, '0%', { shouldHandleSmallNumbers: true });
                const circleDiameter = 9;
                const circleMargin = 8;

                return `<div style="margin-right:${circleDiameter + circleMargin}px;">
                            <span style="
                                        height:${circleDiameter}px;
                                        width:${circleDiameter}px;
                                        border-radius:50%;float:left;
                                        margin:4px ${circleMargin}px 0 0;
                                        background-color:${this.color}"></span>
                            ${this.series.name}: <b>${percent}</b>
                        </div>`;
            }
        },
        series: [{
            name: MainMetricTypes.Delivered,
            data: [{
                color: null,
                radius: this.radiuses.Delivered.outer,
                innerRadius: this.radiuses.Delivered.inner,
                y: 0
            }]
        }, {
            name: MainMetricTypes.Opened,
            data: [{
                color: null,
                radius: this.radiuses.Opened.outer,
                innerRadius: this.radiuses.Opened.inner,
                y: 0
            }]
        }, {
            name: MainMetricTypes.Clicked,
            data: [{
                color: null,
                radius: this.radiuses.Clicked.outer,
                innerRadius: this.radiuses.Clicked.inner,
                y: 0
            }]
        }]
    }

    ngAfterViewInit() {
        this.highchart.options = this.chartOptions;
    }
}