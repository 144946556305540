import {Directive, Input, HostListener} from "@angular/core";
import {SerializationService} from "../services/serialization.service";

@Directive({
    selector: '[csv-exporter]'
})

export class CsvExporter {
    @Input() fileName: string;
    @Input() exportedData: any[];
    @Input() objectCsvSerializerFunc: (data: any) => string
    @Input() headers: string[];

    constructor(private serializationService: SerializationService){}

    @HostListener('click') clickEvent() {
        let csvContent: string = this.serializationService.serializeToCSV(this.headers, this.exportedData, this.objectCsvSerializerFunc);
        let link: HTMLElement = document.createElement("a");

        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvContent));
        link.setAttribute("download", `${this.fileName}.csv`);
        link.click();
    }
}