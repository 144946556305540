import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { IModalButtonConf, IModalConf, OptiLogicModalService } from "src/app/components/optiLogicModal/optiLogicModal.service";
import { ApiManagementService } from "../apiManagement.service";
import { Subscription } from "rxjs";
import { OptiApiUser } from "../models/optiApiUser.model";
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { first } from "rxjs/operators";


@Component({
    selector: 'update-api-user-popup',
    templateUrl: './updateApiUserPopup.component.html',
    styleUrls: ['./updateApiUserPopup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class UpdateApiUserPopupComponent implements OnInit {

    @Input() recommendToBlock: boolean;
    @Input() apiUser: OptiApiUser;
    @Input() lastLogin: string;
    @Output() updateExistingApiUsersEvent = new EventEmitter<OptiApiUser[]>();

    public modalConf: IModalConf;
    public translateKeys = translateKeys;
    public loading: boolean = false;
    private subscriptionsArr: Subscription[] = [];

    constructor(private bsModalRef: BsModalRef, 
        private translateService: TranslateService,
        private apiManagementService: ApiManagementService,
        private modalService: OptiLogicModalService) {}

    ngOnInit() {
        this.setModalConf();
    }

    openFailedModal(title: string, errorMsg: string) {
        this.loading = false;
        this.bsModalRef.hide();
        this.modalService.openModalMessage(
            'sm',
            {
                title: title,
                message: errorMsg,
                buttons: [{
                    class: 'btn-primary',
                    label: this.translateService.instant('general.OK'),
                    action: () => this.bsModalRef.hide(),
                    dataQaId: 'errorUserOKBtn'
                }]
            },
            <ModalOptions<any>>{
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }

    getButtonsConf(): IModalButtonConf[] {
        return [
            {
                isDisabled: () => { return !this.apiUser.Enabled; },
                class: 'btn-primary',
                label: this.translateService.instant(this.translateKeys.deactivateUserBtn),
                action: () => { return this.deactivateUser(); },
                dataQaId: 'deactivateUserBtn'
            },
            {
                isDisabled: () => { return false; },
                class: 'btn-default',
                label: this.translateService.instant(this.translateKeys.cancel),
                action: () => { this.bsModalRef.hide(); },
                dataQaId: 'cancelModalBtn'
            }
        ];
    }

    private deactivateUser() {
        this.loading = true;
        this.apiUser.Enabled = false;
        var title = this.translateService.instant(this.translateKeys.titleDeactivate);
        var errorMassage = this.translateService.instant(this.translateKeys.errorDeactivateUser);
        this.apiManagementService.updateApiUser(this.apiUser)
                        .pipe(first())
                        .subscribe((response: AjaxResponse<string>) => {
                            (!response.isSuccess) ? this.openFailedModal(title, errorMassage) : this.bsModalRef.hide();
                        }, (_) => this.openFailedModal(title, errorMassage));
    }

    private deleteUser() {
        this.loading = true;
        var title = this.translateService.instant(this.translateKeys.title);
        var errorMassage = this.translateService.instant(this.translateKeys.errorDeleteUser);
        this.subscriptionsArr.push(this.apiManagementService.deleteApiUser(this.apiUser.UserName)
            .subscribe((response: AjaxResponse<string>) => {
                 (!response.isSuccess) 
                    ? this.openFailedModal(title, errorMassage) 
                    : this.successDeleteUser();
            }, (_) => this.openFailedModal(title, errorMassage)));
    }

    private successDeleteUser() {
        // updating the existing api users table in API Management page 
        this.subscriptionsArr.push(this.apiManagementService.getApiUsers()
        .subscribe(existingApiUsers  => {
            this.updateExistingApiUsersEvent.emit(existingApiUsers.Data);
            this.loading = false;
            this.bsModalRef.hide();
        } , (err) => console.log(err)));
    }

    setModalConf(): void {
        this.modalConf = {
            title: this.translateService.instant(this.translateKeys.title),
            buttons: this.getButtonsConf(),
            xButton: {
                action: () => { this.bsModalRef.hide(); }
            },
            linkConf: {
                isDisabled: () => { return false; },
                class: 'btn-delete',
                label: this.translateService.instant(this.translateKeys.deleteUserBtn),
                action: () => { return this.deleteUser(); },
                dataQaId: 'deleteUserBtn'
            },
            withoutMinHeight: true
        };
    }

    ngOnDestroy() {
        this.subscriptionsArr.forEach(sub => sub.unsubscribe());
    }
}

const translateKeys = {
    title: 'features.user_settings.body.apiManagement.updateApiUser.TITLE',
    titleDeactivate: 'features.user_settings.body.apiManagement.updateApiUser.TITLE_DEACTIVATE',
    blockUserMsg: 'features.user_settings.body.apiManagement.updateApiUser.BLOCK_USER_MSG',
    deactivateBlockUserMsg: 'features.user_settings.body.apiManagement.updateApiUser.DEACTIVATE_BLOCK_USER_MSG',
    deleteUserMsg: 'features.user_settings.body.apiManagement.updateApiUser.DELETE_USER_MSG',
    deleteUserBtn: 'features.user_settings.body.apiManagement.updateApiUser.DELETE_USER_BTN',
    deactivateUserBtn: 'features.user_settings.body.apiManagement.updateApiUser.DEACTIVATE_USER_BTN',
    cancel: 'features.user_settings.body.attributes.addAttribute.CANCEL',
    apiUserHeader: 'features.user_settings.body.apiManagement.updateApiUser.API_USER',
    lastLoginHeader: 'features.user_settings.body.apiManagement.updateApiUser.LAST_LOGIN',
    errorDeactivateUser: 'features.user_settings.body.apiManagement.updateApiUser.ERROR_DEACTIVATE_USER',
    errorDeleteUser: 'features.user_settings.body.apiManagement.updateApiUser.ERROR_DELETE_USER',
}
