<div class="add-webhook-configuration" data-qa-id="cyAddWebhookConfigurationPage">
  <div class="panel-heading">
    <a class="btn btn-link" (click)="backToList()">
      <i class="material-icons">arrow_back</i>
      <span>{{ "general.BACK" | translate }}</span>
    </a>
    <h2 *ngIf="!editMode">
      {{ "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.TITLE" | translate }}
    </h2>
    <h2 *ngIf="editMode">{{ webhookConfiguration.webhookName }}</h2>
  </div>
  <div class="panel-body">
    <form #webhookConfigurationForm="ngForm" class="configuration-form">
      <div class="col-md-8">
        <div class="configuration-row">
          <div class="configuration-label" data-qa-id="cyWebhookNameText">
            {{ "features.user_settings.body.webhookConfiguration.webhookName" | translate }}
          </div>
          <div class="configuration-input-with-validation">
            <input
              class="configuration-value form-control"
              name="webhookName"
              #webhookName="ngModel"
              placeholder="{{
                'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.webhookNamePlaceholder' | translate
              }}"
              [(ngModel)]="webhookConfiguration.webhookName"
              [disabled]="editMode"
              required
              maxlength="200"
              data-qa-id="cyWebhookNameInput"
              (change)="onWebhookNameChanged()" />
            <div
              data-qa-id="cyWebhookNameNotSpecified"
              *ngIf="webhookName.invalid && webhookName.touched && webhookName.errors.required"
              class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookNameRequired" | translate
              }}
            </div>
            <div data-qa-id="cyWebhookNameNotUnique" *ngIf="webhookName.invalid && webhookName.errors.isExists" class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookNameUnique" | translate
              }}
            </div>
            <div *ngIf="webhookName.invalid && webhookName.touched && webhookName.errors.maxlength" class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookNameMaxLength" | translate
              }}
            </div>
          </div>
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.webhookURL" | translate }}
          </div>
          <div class="configuration-input-with-validation">
            <div class="configuration-values configuration-values-webhook-url">
              <input
                class="configuration-value form-control"
                name="webhookUrl"
                #webhookUrl="ngModel"
                placeholder="{{
                  'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.webhookURLPlaceholder' | translate
                }}"
                [(ngModel)]="webhookConfiguration.webhookData.webhookUrl"
                (ngModelChange)="onWebhookURLChanged()"
                data-qa-id="cyWebhookURLInput"
                required />
              <loading-button
                *ngIf="!urlValid"
                data-qa-id="cyValidateWebhookURL"
                [showLoader]="isValidating"
                (onClick)="validateURL()"
                [isDisabled]="urlValidated && !urlValid"
                [buttonCssClass]="'btn btn-secondary validate-webhook-url'"
                [loadingCssClass]="'saving-loader'"
                [loadingText]="'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validating' | translate"
                [buttonText]="
                  'features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validate' | translate
                "></loading-button>
              <span class="material-icons valid-webhook-url-chevron" data-qa-id="cyWebhookURLIsValid" *ngIf="urlValid">check</span>
            </div>
            <div
              *ngIf="webhookUrl.invalid && webhookUrl.touched && webhookUrl.errors.required"
              class="validation-error"
              data-qa-id="cyWebhookUrlMustBeSpecified">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookURLRequired" | translate
              }}
            </div>
            <div *ngIf="webhookUrl.invalid && webhookUrl.errors.notValidated" class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookURLNotValidated"
                  | translate
              }}
            </div>
            <div
              *ngIf="webhookUrl.invalid && webhookUrl.errors.urlInvalid && urlValidated"
              data-qa-id="cyWebhookUrlInvalid"
              class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookURLInvalid" | translate
              }}
            </div>
          </div>
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.batchSize" | translate }}
          </div>
          <div class="configuration-input-with-validation">
            <div class="configuration-values">
              <input
                only-digits
                class="configuration-value configuration-number form-control"
                name="batchSize"
                #batchSize="ngModel"
                [ngModel]="webhookConfiguration.webhookData.additionalData.batchSize | formatter : '0,' : {allowBlankValue: true}"
                (ngModelChange)="webhookConfiguration.webhookData.additionalData.batchSize = $event"
                data-qa-id="cyFileSizeInput"
                required />
              <div class="configuration-label">
                {{ "features.user_settings.body.webhookConfiguration.batchSizeMaxRows" | translate }}
              </div>
            </div>
            <div
              *ngIf="batchSize.invalid && batchSize.touched && batchSize.errors.required"
              class="validation-error"
              data-qa-id="cyFileSizeMustBeSpecified">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.batchSizeRequired" | translate
              }}
            </div>
            <div
              *ngIf="batchSize.invalid && batchSize.touched && (batchSize.errors.max === false || batchSize.errors.min === false)"
              class="validation-error"
              data-qa-id="cyFileSizeError">
              {{ batchSizeMinMaxErrorMsg | translate }}
            </div>
          </div>
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.eventId" | translate }}
          </div>
          <input
            class="configuration-value configuration-number form-control"
            name="eventId"
            #eventId="ngModel"
            [ngModel]="webhookConfiguration.webhookData.eventId"
            disabled="true"
            required />
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.channelName" | translate }}
          </div>
          <div class="configuration-input-with-validation" data-qa-id="cyConfigurationInputWithValidation">
            <opti-search-list
              class="configuration-value"
              name="channelName"
              data-qa-id="cyChannelNameDropDown"
              #channelName="ngModel"
              [(ngModel)]="selectedChannels"
              [items]="channelList"
              [config]="channelsConfig"
              [disabled]="editMode"
              (selectedItemsChange)="selectedChannelChanged()"
              required></opti-search-list>
            <div
              *ngIf="channelName.invalid && channelName.touched && channelName.errors.required"
              class="validation-error"
              data-qa-id="cyChannelNameMustBeSpecified">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.channelNameRequired" | translate
              }}
            </div>
            <div
              *ngIf="channelName.invalid && channelName.touched && channelName.errors.isExistsForChannel"
              data-qa-id="cyWebhookAlreadyExistsForChannel"
              class="validation-error">
              {{
                "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.validationErrors.webhookURLExistsForChannel"
                  | translate
              }}
            </div>
          </div>
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.attributes" | translate }}
          </div>
          <opti-search-list
            class="configuration-value"
            name="attributes"
            data-qa-id="cyAttributesDropDown"
            #attributes="ngModel"
            [(ngModel)]="selectedCustomerAttributes"
            [items]="customerAttributes"
            [config]="attributesConfig"
            (selectedItemsChange)="onSelectedAttributesChanged($event)">
            >
          </opti-search-list>
        </div>
        <div class="configuration-row">
          <div class="configuration-label">
            {{ "features.user_settings.body.webhookConfiguration.identifier" | translate }}
          </div>
          <opti-search-list
            class="configuration-value"
            name="identifier"
            data-qa-id="cyIdentifierDropDown"
            #identifier="ngModel"
            [(ngModel)]="webhookIdentifier"
            [items]="webhookIdentifierList"
            [config]="identifierConfig"
            (selectedItemsChange)="onIdentifierSelected($event)"></opti-search-list>
        </div>
      </div>
      <div class="col-md-4">
          <div class="configuration-row">
              <div class="webhook-additional-config" *ngIf="isOptimoveAdmin">
                  <switch [buttonType]="SwitchButtonType.Small"
                          name="internalConfiguration"
                          [ngModel]="webhookConfiguration.webhookData.additionalData.internalConfiguration"
                          [disabledInput]="editMode"
                          (ngModelChange)="onInternalConfigurationChange($event)"></switch>
                  <span [ngClass]="{'webhook-disabled-text': editMode}" class="webhook-additional-config-toggle">{{ "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.internalConfiguration" | translate}}</span>
              </div>
          </div>
          <div class="configuration-row">
              <div class="webhook-additional-config">
                  <switch [buttonType]="SwitchButtonType.Small"
                          name="applyFormatting"
                          [ngModel]="webhookConfiguration.webhookData.additionalData.applyFormatting"
                          (ngModelChange)="onApplyFormattingChange($event)"></switch>
                  <span class="webhook-additional-config-toggle">{{ "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.applyFormatting" | translate}}</span>
              </div>
          </div>
      </div>
      <div class="col-md-12">
        <ag-grid-angular
          *ngIf="genericWebhookEvents && hasEventsRegistered"
          [gridOptions]="eventsGridOptions"
          [modules]="eventsGridModule"
          [rowData]="genericWebhookEvents"
          [getRowNodeId]="getRowIdByEventIdFunc"
          data-qa-id="cyWebhookEventsAgGrid"
          class="ag-theme-balham ag-theme-white"></ag-grid-angular>
        <div class="configuration-row">
          <button
            [disabled]="areAllEventsHaveRegisteredWebhooks()"
            (click)="openGenericWebhookEventModal()"
            class="btn btn-font-size btn-link add-generic-event-btn"
            data-qa-id="addGenericEventWebhook">
            <i class="material-icons">add</i>
            <span>{{ "features.user_settings.body.webhookConfiguration.addWebhookConfiguration.addEvent" | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <settings-footer
    [isSaving]="isSaving"
    [disabled]="
      isValidating ||
      (editMode && !webhookEventsGridChanged && (webhookConfigurationForm.pristine || webhookConfigurationForm.invalid)) ||
      (!editMode &&
        (webhookConfigurationForm.pristine ||
          webhookUrl.invalid ||
          webhookUrl.pristine ||
          webhookName.invalid ||
          webhookName.pristine ||
          batchSize.invalid ||
          channelName.invalid ||
          channelName.pristine))
    "
    data-qa-id="cySaveButton"
    (cancelClicked)="backToList()"
    (saveClicked)="saveConfiguration()"></settings-footer>
</div>
