import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { SearchItem, SearchListConfig } from 'src/app/components/optiSearchList/optiSearchListComponent/optiSearchList.component';
import { SearchType } from 'src/app/features/customer360/customer360.enums';
import { ISearchInputValue } from 'src/app/features/customer360/customer360.models';
import { searchInputTranslateKeys } from 'src/app/features/customer360/customer360.translate.key';

@Component({
	selector: 'search-input',
	templateUrl: './searchInput.component.html',
	styleUrls: ['./searchInput.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class SearchInputComponent implements OnInit {

  @Input() initialSearchInputValue: ISearchInputValue;
  @Output() search: EventEmitter<ISearchInputValue> = new EventEmitter();

  public translateKeys = searchInputTranslateKeys;
  public form: FormGroup;

  public searchTypeConfig: SearchListConfig = {
  	keyProperty: "key",
  	valueProperty: "value",
  	isMultiSelect: false,
  	placeholderTranslateKey: this.translate.instant(this.translateKeys.SEARCH_INPUT_PLACEHOLDER),
  	itemNameTranslateKey: this.translate.instant(this.translateKeys.SEARCH_INPUT_PLACEHOLDER),
  };
  public searchTypes: SearchItem[] = [
  	{ key: SearchType.Contains, value: 'Contains' },
  	{ key: SearchType.Equals, value: 'Equals' }
  ];

  constructor(
	  private translate: TranslateService,
	  private router: Router,
	  private route: ActivatedRoute
  ) {
	  this.subscribeOnRouteReset();
  }

  ngOnInit(): void {
  	this.createForm();
  }

  public onSearchClick() {
	  const event = {
		  SearchStr: this.searchStr.value,
		  SearchType: this.searchType.value[0].key,
	  };

	  if (event.SearchStr === '') {
		return;
	  }

	  const queryParams: Params = { searchStr: event.SearchStr, searchType: event.SearchType };
	  this.router.navigate(
		  [],
		  {
			  relativeTo: this.route,
			  queryParams: queryParams,
			  queryParamsHandling: 'merge'
		  });

	  this.search.emit(event as ISearchInputValue);
  }

  public get searchType(): FormControl {
  	return this.form.get("searchType") as FormControl;
  }

  public get searchStr(): FormControl {
  	return this.form.get("searchStr") as FormControl;
  }

	private subscribeOnRouteReset() {
		this.router.events.subscribe(obj => {
			if (obj instanceof NavigationEnd && obj.url === '/category/Analysis_&_Research/customer360') {
				this.form?.reset({
					searchType: SearchType.Contains,
					searchStr: ''
				});
			}
		});
	}

  private createForm() {
	  if (!this.initialSearchInputValue) {
		  this.initialSearchInputValue = {
			  SearchStr: this.route.snapshot.queryParamMap.get('searchStr'),
			  SearchType: this.route.snapshot.queryParamMap.get('searchType') as SearchType,
		  };
	  }

  	const initialSearchType = this.initialSearchInputValue && this.initialSearchInputValue.SearchType
  		? this.searchTypes.find(x => x.key === this.initialSearchInputValue.SearchType) 
  		: this.searchTypes[0];
  	const initialSearchTStr = this.initialSearchInputValue && this.initialSearchInputValue.SearchStr
  		? this.initialSearchInputValue.SearchStr
  		: "";
      
  	this.form = new FormGroup({
  		searchType: new FormControl([initialSearchType], [Validators.required]),
  		searchStr: new FormControl(initialSearchTStr, [Validators.required]),
  	});
  }
}
