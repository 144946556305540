import {Injectable, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class FullPageLoaderService implements OnDestroy {
    public showLoader = new Subject<boolean>();

    constructor() {}

    ngOnDestroy(): void {
        this.showLoader && this.showLoader.unsubscribe();
    }
}