import { Injectable } from "@angular/core";
import { AjaxResponse } from "@optimove/ui-sdk/common/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../../../services/optimove-http/optimove-http.model";
import { Brand, TemplateMetadata } from "../models/templateMetadata";
import { OneClickUnsubscribeItem } from "../models/oneClickUnsubscribeItem";

@Injectable({
  providedIn: "root",
})
export class OneClickUnsubscribeService {
  constructor(private readonly http: HttpService) {}

  getOneClickUnsubscribe(channelId: number): Observable<OneClickUnsubscribeItem> {
    return this.http.get<OneClickUnsubscribeItem>(`/UserSettings/GetOneClickUnsubscribe`, HttpService.toHttpParams({ channelId }));
  }

  updateOneClickUnsubscribe(oneClickUnsubscribe: OneClickUnsubscribeItem): Observable<OneClickUnsubscribeItem> {
    return this.http.post<OneClickUnsubscribeItem>(`/UserSettings/SaveOneClickUnsubscribe`, oneClickUnsubscribe);
  }
}
