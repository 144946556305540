import {Component, Inject, OnInit, Input, OnDestroy, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'unauthorized',
    templateUrl: './unauthorized.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent implements OnInit {

    constructor() { }
    
    ngOnInit(): void {
        console.log('in UnauthorizedComponent ngOnInit');
    }
}