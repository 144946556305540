import {Component, ViewEncapsulation } from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ISOJTypes } from "../../../../models/ISOJTypes.enum";


@Component({
    selector: 'tg-priority-list-item',
    styleUrls: ['./priorityList.component.scss'],
    encapsulation: ViewEncapsulation.None,
    template: `<form [formGroup]="priorityForm">
                    <div *ngIf="params.data.priority == 'AUTO'" class="priority-auto-bg"></div>
                    <div class="target-group-row">
                        <input (keypress)="onlyNumberKey($event)" (keydown.esc)="escClicked($event)" (keydown.enter)="onPriorityFormValueChanges($event)" formControlName="priority" (blur)="onPriorityFormValueChanges($event)" type="text" class="form-control priorityBox">
                        <label [title]="params.value" [ngClass]="{'sojIsOn': sojIsOn}" class="TGTreeName control-label tooltip-multi-line-1">{{ value }}</label>
                        <span *ngIf="sojIsOn">
                            <button (click)="btnWasClicked($event, false)" *ngIf="params.data.priority == 'AUTO'" title="Move to Manual" class="icon-Manual-priority set-auto-btn"></button>
                            <button (click)="btnWasClicked($event, true)" *ngIf="params.data.priority != 'AUTO'" title="Move to Auto" class="icon-Auto-priority set-manual-btn"></button>
                        </span>
                    </div>
                </form>`
})


export class PriorityListItemComponent implements ICellRendererAngularComp {

    public sojIsOn: boolean = false;
    public cell: any;
    public params: any;
    public priorityForm: FormGroup;
    value: string;

    constructor(private formBuilder: FormBuilder) {}

    agInit(params: any): void {
        this.sojIsOn = params.context.isSojEnabled;
        this.params = params;
        this.cell = {row: params.value, col: params.colDef.headerName};
        const priority = !params.data.isAutoPriority ? params.data.priority : 'AUTO';
            this.priorityForm = this.formBuilder.group({
            priority: [{value: priority, disabled: params.data.isAutoPriority}]
        });
        this.value = this.decodeEntities(params.value)
    }

    private decodeEntities(str: string): string {
        let element: HTMLElement = document.createElement('div');
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }
        return str;
    }

    escClicked(e) {
        e.currentTarget.value = this.params.data.priority;
        e.target.blur();
    }

    onlyNumberKey(event){
        let charCode = (event.query) ? event.query : event.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }

    btnWasClicked(e, isAuto) {
        let treeSelectedNodes = this.params.api.getSelectedNodes();
        let selectedNodes = [];
        let valToSelect = [];

        if(treeSelectedNodes.length > 1 && this.params.node.selected) {
            for (let i: number = 0; i < treeSelectedNodes.length; i++) {
                selectedNodes.push(treeSelectedNodes[i].data);
                valToSelect.push(treeSelectedNodes[i].data.tg);
                selectedNodes[i].priority = isAuto ? 1000000 : 999999;
                selectedNodes[i].isAutoPriority = isAuto;
            }
        } else {
            selectedNodes.push(this.params.node.data);
            selectedNodes[0].priority = isAuto ? 1000000 : 999999;
            selectedNodes[0].isAutoPriority = isAuto;
            valToSelect.push(this.params.value);
        }


        const data = this.params.context.moveItemsAndSortFilter(selectedNodes, isAuto ? ISOJTypes.auto : ISOJTypes.manual);
        this.params.context.getChange = false;
        this.params.context.updateGrid(data, valToSelect);
    }

    onPriorityFormValueChanges(val: any): void {
        if(val.currentTarget.value === "") {
            val.currentTarget.value = this.params.data.priority;
            return;
        }
        const itemNumber = Number(val.currentTarget.value);
        if (itemNumber == this.params.data.priority) {
            return;
        }

        const itemIndex = itemNumber -1;
        this.params.context.onItemValueChange(this.params.data, itemIndex);
    }

    refresh(): boolean {
        return true;
    }
}