import { EventEmitter, forwardRef, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'numbers-range-picker',
  templateUrl: './numbersRangePicker.component.html',
  styleUrls: ['./numbersRangePicker.component.scss'],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumbersRangePickerComponent),
    multi: true
  }]
})
export class NumbersRangePickerComponent implements OnInit, ControlValueAccessor {
  @Input() isPickerTouched;
  @Input() disabled;
  @Input() currencySymbol: string;
  @Output() changeRange: EventEmitter<string[]> = new EventEmitter<string[]>();

  public translateKeys = translateKeys;
  public from = {
    value: null,
    valid: false,
  };
  public to = {
    value: null,
    valid: false,
  };

  constructor() { }

  ngOnInit(): void {}

  onValueChanged() {
    this.from.valid = !(this.from.value == null || (this.to.value != null && this.from.value >= this.to.value));
    this.to.valid = !(this.to.value == null || (this.from.value != null && this.from.value >= this.to.value));
    this.OnChange([this.from.valid ? this.from.value : null,this.to.valid ? this.to.value : null]);
    this.changeRange.emit([this.from.valid ? this.from.value : null,this.to.valid ? this.to.value : null]);
    this.isPickerTouched = true;
  }

  writeValue(startValues: Array<string | number>): void {
    if(startValues && startValues.length == 2) {
        this.from.value = startValues[0].toString();
        this.to.value = startValues[1].toString();
    }
    else {
      this.from.value = null;
      this.to.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.OnChange = fn;
  }

  registerOnTouched(fn: any): void { }

  OnChange = (fn: any) => {}

}

const translateKeys = {
  from: 'features.user_settings.body.attributes.addConditionAttribute.FROM',
  to: 'features.user_settings.body.attributes.addConditionAttribute.TO',
}