<div>
  <div id="topicsListHeader" class="panel-heading">
    <h2>{{ 'features.user_settings.body.preference_center.TITLE' | translate }}</h2>
  </div>
  <div class="panel-body">
    <opti-loading [show]="isLoading" [size]="'md'"></opti-loading>
    <div class='empty-topic-state-one-brand' *ngIf='preparedBrands.length===0'>
      <p class='empty-indicator'>{{ 'features.user_settings.body.preference_center.NO_TOPICS' | translate }}</p>
    </div>
    <div *ngFor='let brandGroup of preparedBrands' class='brands-list'>
      <div *ngIf='preparedBrands.length>1' class='brand-item'>
        <i *ngIf='!brandGroup.isOpen' (click)='updateIsOpen(brandGroup)' class='material-icons brand'>chevron_right</i>
        <i *ngIf='brandGroup.isOpen' (click)='updateIsOpen(brandGroup)' class='material-icons brand'>expand_more</i>
        <p (click)='updateIsOpen(brandGroup)' class='brand-name'>{{brandGroup.name}}</p>
      </div>
      <div *ngIf='brandGroup.isOpen && (!brandGroup.topics || brandGroup.topics.length === 0)' [ngClass]="{'empty-topic-state': preparedBrands.length>1, 'empty-topic-state-one-brand ' : preparedBrands.length===1 }">
        <p class='empty-indicator'>{{ 'features.user_settings.body.preference_center.NO_TOPICS' | translate }}</p>
        <div class='create-wrapper'>
          <button class='btn btn-link create-new-topic' (click)='createNewTopic(brandGroup)'>{{ 'features.user_settings.body.preference_center.CREATE_NEW' | translate }}</button>
        </div>
      </div>
      <div [ngClass]="{'topics-list': preparedBrands.length>1, 'topics-list-one-brand' : preparedBrands.length===1}" *ngIf='brandGroup.isOpen && brandGroup.topics && brandGroup.topics.length > 0' >
        <div class='table-header'>
          <p class='topic-name-column'>{{ 'features.user_settings.body.preference_center.TOPIC_NAME' | translate }}</p>
          <p class='description-column'>{{ 'features.user_settings.body.preference_center.DESCRIPTION' | translate }}</p>
        </div>
        <div *ngFor='let topic of brandGroup.topics' >
          <div class='content-wrapper'>
            <div class="drag-handle">
              <svg class="node-handle" xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
                <g id="Drag_Handler" data-name="Drag Handler" transform="translate(-32 -6)">
                  <rect id="Rectangle_3234" data-name="Rectangle 3234" width="2.5" height="2.5" transform="translate(32 6)" fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3236"
                    data-name="Rectangle 3236"
                    width="2.5"
                    height="2.5"
                    transform="translate(32 10.5)"
                    fill="#cfd8dc" />
                  <rect id="Rectangle_3238" data-name="Rectangle 3238" width="2.5" height="2.5" transform="translate(32 15)" fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3240"
                    data-name="Rectangle 3240"
                    width="2.5"
                    height="2.5"
                    transform="translate(32 19.5)"
                    fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3235"
                    data-name="Rectangle 3235"
                    width="2.5"
                    height="2.5"
                    transform="translate(37.5 6)"
                    fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3237"
                    data-name="Rectangle 3237"
                    width="2.5"
                    height="2.5"
                    transform="translate(37.5 10.5)"
                    fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3239"
                    data-name="Rectangle 3239"
                    width="2.5"
                    height="2.5"
                    transform="translate(37.5 15)"
                    fill="#cfd8dc" />
                  <rect
                    id="Rectangle_3241"
                    data-name="Rectangle 3241"
                    width="2.5"
                    height="2.5"
                    transform="translate(37.5 19.5)"
                    fill="#cfd8dc" />
                </g>
              </svg>
            </div>
          <div class='table-content'>
            <p class='topic-name'>{{topic.name}}</p>
            <p class='description'>{{topic.description}}</p>
            <button class="edit-topic" (click)='editTopic(topic)'>
            <i class='material-icons edit-icon'>edit</i>
            </button>
          </div>
          </div>
        </div>
        <div class='new-topic-wrapper'>
          <button class='btn btn-link new-topic' (click)='createNewTopic(brandGroup)'>{{ 'features.user_settings.body.preference_center.NEW_TOPIC' | translate }}</button>
          <button class='btn btn-link sdkid-button' (click)='showSdkId(brandGroup)'>
            <div>
              <i class='material-icons'>visibility</i>
              {{ 'features.user_settings.body.preference_center.SDK_ID' | translate }}
            </div>
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <div>
      <toast
        [(isOpen)]="toastParams.isOpen"
        [icon]="toastParams.icon"
        [location]="toastParams.location"
        [message]="toastParams.message"
        [showCloseIcon]="toastParams.showCloseIcon"
        [toastIconType]="toastParams.toastIconType"
        [closeTimeout]="toastParams.closeTimeout"></toast>
    </div>
  </div>
</div>