import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
, ViewEncapsulation } from "@angular/core";
import { ITreeDotButtons, ITreeEvent, ITreeNodeButtons, treeEventType } from "../jsTree.component";
import {ITreeMenuItem} from "../menuItem.model";
import {TreeNode} from "@circlon/angular-tree-component";

@Component({
    selector: 'js-tree-context-menu',
    templateUrl: './jsTreeContextMenu.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['../jsTree.component.scss', './jsTreeContextMenu.component.scss']
})
export class JsTreeContextMenuComponent implements OnChanges {
    @Input() node: TreeNode;
    @Input() items: ITreeMenuItem[];
    @Input() rightClickXPosition: number;
    @Input() rightClickYPosition: number;
    @Input() isTreeScrolling: boolean;
    @Input() isMultiSelected: boolean;
    @Input() multiSelectedButtons: ITreeNodeButtons;

    @Output() itemClick: EventEmitter<ITreeEvent> = new EventEmitter();

    public isMenuVisible = false;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.isMenuVisible) {
            return;
        }

        if(!this.getMenuContainer().contains(event.target)) {
            this.closeMenu();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.isMenuVisible) {
            return;
        }

        this.closeMenu();
    }

    constructor(private eRef: ElementRef) {
        this.isMenuVisible = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            if(this.isMultiSelected && this.multiSelectedButtons) {
                this.items = this.getMultiSelectedMenuItems(this.multiSelectedButtons);
            }

            if (this.items && this.items.length > 0) {
                this.showMenu(this.rightClickXPosition, this.rightClickYPosition);
            } else {
                this.closeMenu();
            }
        }

        if (changes.isTreeScrolling &&
            changes.isTreeScrolling.currentValue &&
            !changes.isTreeScrolling.previousValue) {
            this.closeMenu();
        }
    }

    public onItemClick(eventType: treeEventType) {
        this.itemClick.emit({
            node: this.node,
            eventName: eventType
        });

        this.closeMenu();
    }

    private showMenu(rightClickXPosition: number, rightClickYPosition: number) {
        const menuNativeElement = this.getMenuContainer();

        this.isMenuVisible = true;

        const itemHeight = 30;
        const topBorder = 1;
        const bottomBorder = 1;
        const menuHeight = this.items.length * itemHeight + topBorder + bottomBorder;

        const menuYPosition = rightClickYPosition + 10;
        if (this.isMenuInScreen(document.body.clientHeight, menuHeight, menuYPosition)) {
            menuNativeElement.style.top = `${menuYPosition}px`;
        } else {
            const menuUpYPosition = rightClickYPosition - menuHeight - 10;
            menuNativeElement.style.top = `${menuUpYPosition}px`;
        }
        menuNativeElement.style.left = `${rightClickXPosition}px`;
    }

    private getMultiSelectedMenuItems(treeButtons: ITreeDotButtons): ITreeMenuItem[]  {
        const menuItems: ITreeMenuItem[] = [];

        for (let item in treeButtons) {
            let event;
            switch(item) {
                case 'delete':
                    event = treeEventType.deleteNodes;
                    break;
                case 'folderWithSelection':
                    event = treeEventType.folderWithSelection;
                    break;

            }

            if (treeButtons[item].isHeaderMenuEnable && treeButtons[item].isEnable) {
                menuItems.push({
                    text: this.multiSelectedButtons[item].text,
                    eventType: event
                });
            }
        }

        return menuItems;
    }

    private closeMenu() {
        this.isMenuVisible = false;
    }

    private getMenuContainer(): HTMLElement {
        return this.eRef.nativeElement.firstChild;
    }

    private isMenuInScreen(screenHeight, menuHeight, menuYPosition) {
        if (screenHeight > menuHeight + menuYPosition) {
            return true;
        } else {
            return false;
        }
    }
}