<div class="text-modal panel">
  <div class="panel-heading">
    <h2 class="title-text">
      {{ title | translate }}
    </h2>
  </div>
  <div class="panel-body">
    <div class="col-md-12 buttons-container">
      <div class="button-box">
        <div type="button" (click)="isLoadTags = true" class="personalization-tags-button">
          <i class="material-icons icon">portrait</i>
          <p>{{ "features.manage_templates.components.subject.DROPDOWN_PERSONALIZATION" | translate }}</p>
        </div>
      </div>
      <div *ngIf="isLoadTags">
        <personalization-tags-container [subMethodId]="subMethodType" (chosen)="onTagChosen($event)"></personalization-tags-container>
      </div>
      <div class="button-box" *ngIf="showConditionalLanguage">
        <div
          (click)="isLoadConditionalLanguage = true"
          class="conditional-language-button"
          *ngIf="conditionalLanguageSnippets.forSubjectLine">
          <i class="icon-conditional-language"></i>
          <p>{{ "features.manage_templates.components.subject.CONDITIONAL_LANGUAGE" | translate }}</p>
        </div>
      </div>
    </div>
    <textarea rows="12" class="form-control text-text" id="text-text-textarea" [(ngModel)]="text"></textarea>
  </div>
  <div class="panel-footer">
    <div class="row">
      <div class="col-md-4 pull-right text-right">
        <ul class="list-inline">
          <li>
            <button type="button" class="btn btn-default cancel-btn" (click)="onClose()">
              {{ "features.manage_templates.components.subject.CANCEL" | translate }}
            </button>
          </li>
          <li>
            <button type="button" class="btn btn-primary add-btn" (click)="onSave()">
              {{ "features.manage_templates.components.subject.SAVE" | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isLoadConditionalLanguage && showConditionalLanguage">
    <conditional-language-container
      [snippets]="conditionalLanguageSnippets.forSubjectLine"
      (chosen)="conditionalLanguageModalCallback($event)"></conditional-language-container>
  </div>
</div>
