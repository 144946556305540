import { Component, OnDestroy, OnInit } from '@angular/core';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { Subscription, combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { TenantInformationService } from '../../services/tenantInformation.service';
import { SubMethodService } from '../../services/subMethodService';
import { OptimobileEditorAction, OptimobileEditorMessage } from '../../models/optimobileEvent';

@Component({
	selector: 'optimobile-editor',
	templateUrl: './optimobileEditor.component.html',
	styleUrls: ['./optimobileEditor.component.scss']
})
export class OptimobileEditorComponent implements OnInit, OnDestroy {
	private readonly optimobileOrigins = ['^https:\/\/optimove\.app\.delivery', '^https:\/\/optimobile-portal.*\.optimove\.net$'];

	subscriptions: Subscription;
	templateLobbyQueryParams: TemplateQueryParams;
	templateLobbyUrl: string;
	pushTemplatesUrl: string;
	pushTemplatesParams: string;
	messageEventListener: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private manageTemplatesService: ManageTemplatesService,
		private tenantInformationService: TenantInformationService,
		private subMethodService: SubMethodService
	) {}

	ngOnInit(): void {
		this.messageEventListener = this.handleEditorMessage.bind(this);
		window.addEventListener('message', this.messageEventListener, false);
		this.subscriptions = combineLatest([
			this.route.queryParams,
			this.manageTemplatesService.getManageTemplatesUrl(),
			this.manageTemplatesService.getMetadata()
		]).subscribe((res) => {
			const params = res[0];
			const path = this.router.url;
			if (path && params.channelId) {
				const pathToManageTemplates = path.substring(0, path.indexOf('templates'));
				this.templateLobbyQueryParams = params;
				this.templateLobbyUrl = `${pathToManageTemplates}templates/show/${params.channelId}`;
			}

			const refreshToken = localStorage.getItem('refreshToken');
			const templatesUrl = res[1];
			const editTemplatesHost = templatesUrl.url.endsWith('/') ? templatesUrl.url : templatesUrl.url + '/';
			const tenantInformation = this.tenantInformationService.getTenantInformation();
			this.pushTemplatesUrl = `${editTemplatesHost}sso/templates`;
			let executionMethodInnerId = this.subMethodService.findSubmethodInnerId(res[2], +params.typeId);

			let optimobileParams = `tenant=${tenantInformation.tenantId}&appId=${executionMethodInnerId}&channelId=${params.channelId}&token=${templatesUrl.token}`;
			if (params.folderId) {
				optimobileParams += `&folderId=${params.folderId}`;
			}
			if (params.templateId) {
				optimobileParams += `&templateId=${params.templateId}`;
			}
			if (refreshToken) {
				optimobileParams += `&refreshToken=${refreshToken}`;
			}

			this.pushTemplatesParams = optimobileParams;
		});
	}

	handleEditorMessage(event: MessageEvent): void {
		if (!this.optimobileOrigins.find(o => event.origin.match(o))) return;
		const message: OptimobileEditorMessage = event.data;
		switch (message.type) {
			case OptimobileEditorAction.NavigateBack:
				this.router.navigate([this.templateLobbyUrl], {
					queryParams: this.templateLobbyQueryParams
				});
				break;
			case OptimobileEditorAction.NavigateTemplateList:
				break;
			default:
				break;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.unsubscribe();
		window.removeEventListener('message', this.messageEventListener, false);
	}
}
