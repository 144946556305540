<div class="pull-right">
    <i *ngIf="showAttributeVisibilityBtn"
        data-qa-id="visibility-attribute-btn" 
        [ngClass]="{'disable': isAttributeHidden === null || disabled}" 
        class="material-icons visibility-button"
        (click)="changeAttributeVisibility(!isAttributeHidden)"
        title="{{isAttributeHidden ? (translateKeys.showTooltip | translate) : (translateKeys.hideTooltip | translate)}}">
        {{isAttributeHidden ? 'visibility_off' : 'visibility'}}
    </i>

    <span class="multiple-options-menu-container">
        <i  #toggleButton 
            (click)="onMenuButtonClicked()" 
            class="material-icons settings-icon">
            more_vert
        </i>

        <div class="menu" *ngIf="isMenuOpen" #menu >
            <ul>
                <li data-qa-id="edit-attribute-btn" 
                    [ngClass]="disabled ? 'disable' : ''" 
                    class="btn btn-link btn-link-default edit-button" 
                    (click)="moveToEditAttribute()">

                    <i class="material-icons settings-icon">edit</i>
                    <span translate>{{ translateKeys.edit }}</span>
                </li>
                <li data-qa-id="delete-attribute-btn" 
                    [ngClass]="disabled || disabledDelete ? 'disable' : ''" 
                    class="btn btn-link btn-link-default delete-button" 
                    (click)="areYouSure()">

                    <i class="material-icons settings-icon" >delete_forever</i>
                    <span translate>{{ translateKeys.delete }}</span>
                </li>
            </ul>
        </div>
    </span>
</div>
