<div class="setting-btn">
    <i  data-qa-id="SetupButton" 
        (click)="showPopover()" 
        class="material-icons">
        more_vert
    </i>
    <div class="menu" *ngIf="config.isMenuOpen && menuItems.length > 0">
        <ul>
            <li *ngFor="let menuItem of menuItems" 
                [ngClass]="{'disabled': menuItem.disabled}"
                (click)="onItemClicked(menuItem)" tabindex="0">
                <i *ngIf="menuItem.iconName" class="material-icons">{{menuItem.iconName}}</i>
                <span translate>{{ menuItem.label }}</span>
            </li>
        </ul>
    </div>
</div> 

