
import {NgModule} from "@angular/core";
import {FormatterPipe} from "./formatter.pipe";
import {ConvertOrderActionToLetter} from "./convertOrderActionToLetter.pipe";
import {FilterSearchPipe} from "./filterSearch.pipe";
import {ShortenPipe} from './shorten.pipe';
import { SafePipe } from "./safe.pipe";
import { HighlightTextPipe } from "./HighlightText.pipe";

@NgModule({
    imports: [
    ],
    providers: [
        ConvertOrderActionToLetter,
        ShortenPipe,
        SafePipe
    ],
    declarations: [
        FormatterPipe,
        ConvertOrderActionToLetter,
        FilterSearchPipe,
        ShortenPipe,
        SafePipe,
        HighlightTextPipe,
    ],
    exports: [
        FormatterPipe,
        ConvertOrderActionToLetter,
        FilterSearchPipe,
        ShortenPipe,
        SafePipe,
        HighlightTextPipe,
    ]

})
export class PipesModule {
}