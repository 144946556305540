<analysis-range-picker 
    [disabled]="disabled"
    [rangeUrlParametersPrefix]="rangeUrlParametersPrefix" 
    (analysisRangeChanged)="analysisRangeChanged.emit($event)">
    <h2>
        {{translateKeys.TITLE | translate }}
    </h2>
    <p class="small">
        {{translateKeys.DESCRIPTION | translate }}
    </p>
    <p class="small">
        {{translateKeys.NOTE | translate }}
    </p>
</analysis-range-picker>
        
