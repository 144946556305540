import { Injectable, OnDestroy } from '@angular/core';
import { AjaxResponse, IHashTable } from '@optimove/ui-sdk/common/models';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { TemplatePreviewData } from '../../models/channel/template/template-preview-data.model';
import { TemplatePreviewInitData } from '../../models/channel/template/template-preview-init-data.model';
import { HttpService } from '../../services/optimove-http/optimove-http.model';
import { IsTemplateAvailableData } from './models/isTemplateAvailableData.model';

@Injectable()
export class TemplateAvailabilityService implements OnDestroy {
    private templatesResultSubject: Subject<TemplatePreviewData[]>;
    private templatesRequestsHandler = new Subject();
    private templatesRequestSubscription: Subscription;
    private templatesPreviewData: TemplatePreviewData[];
    private downloadTemplatesCounter: number;

    constructor(private readonly httpService: HttpService) {
    	this.templatesResultSubject = new Subject();
    	this.observeTemplatesRequest();
    }

    public getTemplatesPreviewData(templatesFolder: string, templatesInitData: TemplatePreviewInitData[]): Observable<TemplatePreviewData[]> {
    	this.downloadTemplatesCounter = 0;
    	this.initializeTemplatePreviewData(templatesFolder, templatesInitData);

    	const templatesUrl = this.templatesPreviewData.map(template => template.url);
    	this.templatesRequestsHandler.next(templatesUrl);

    	return this.templatesResultSubject.asObservable();
    }

    private initializeTemplatePreviewData(templatesFolder: string, templatesInitData: TemplatePreviewInitData[]) {
    	if (!templatesInitData) {
    		this.templatesPreviewData = [];
    		return;
    	}

    	this.templatesPreviewData = templatesInitData.map(templateInitData => {
    		const templateUrl = this.getTemplateUrl(templatesFolder, templateInitData.channelId, templateInitData.templateId);
    		return {
    			...templateInitData,
    			url: templateUrl,
    			isExists: false
    		};
    	});
    }

    public getTemplateUrl(templateFolder: string, channelId: number, templateId: string): string {
    	let host = document.location.origin;
    	return `${templateFolder}/${channelId}/${templateId}.jpeg`;
    }
    
    private observeTemplatesRequest() {
    	// This handler cancel the previous pending requests with switchMap operator
    	this.templatesRequestSubscription = this.templatesRequestsHandler.asObservable()
    		.pipe(
    			switchMap((templatesUrl: string[]) => {
    				if (templatesUrl.length === 0) {
    					return of([]);
    				}

    				return from(templatesUrl).pipe(
    					mergeMap((templateUrl, index) => this.isTemplateExists(templateUrl, index))
    				);
    			})
    		).subscribe((isTemplateAvailable: IsTemplateAvailableData) => {
    			this.templatesPreviewData[isTemplateAvailable.templateIndex].isExists = isTemplateAvailable.isAvailable;
    			this.onTemplateDownloadComplete();
    		});
    }

    private isTemplateExists(templateUrl: string, templateIndex: number): Observable<IsTemplateAvailableData> {
    	const isTemplateExistsSubject = new Subject<IsTemplateAvailableData>();

    	let img = new Image();
    	img.onload = () => {
    		isTemplateExistsSubject.next({ isAvailable: true, templateIndex: templateIndex });
    		isTemplateExistsSubject.complete();
    	};
    	img.onerror = () => {
    		isTemplateExistsSubject.next({ isAvailable: false, templateIndex: templateIndex });
    		isTemplateExistsSubject.complete();
    	};
    	img.src = templateUrl;

    	return isTemplateExistsSubject.asObservable();
    }

    private onTemplateDownloadComplete() {
    	this.downloadTemplatesCounter++;
    	if (this.downloadTemplatesCounter === this.templatesPreviewData.length) {
    		this.templatesResultSubject.next(this.templatesPreviewData);
    	}
    }

    public isTemplatesHtmlExists(channelId: string, templateIds: string[]): Observable<IHashTable<boolean>> {
    	const templateIdsQueryStringValue = templateIds.join('&templateIds=');
    	return this.httpService.get<AjaxResponse<IHashTable<boolean>>>(`/ManageTemplates/IsTemplatesHTMLFileExist?channelId=${channelId}&templateIds=${templateIdsQueryStringValue}`)
    		.pipe(
    			map(response => response.data)
    		);
    }

    ngOnDestroy() {
    	this.templatesRequestSubscription && this.templatesRequestSubscription.unsubscribe();
    }
}
