<div class="tags-container">
    <form [formGroup]="form" class="form-container">
        <h2 class="second-header">{{'features.JB.vsb_notes_and_tags.tags' | translate}}</h2>
        <opti-select
        data-qa-id="tags-button"
        formControlName="tags"
        [isDisabled]="form.disabled"
        [items]="items"
        [isMultiSelect]="true"
        [isTagsMode]="true"
        (onAddingNewTag)="addNewTagEvent($event)"
        [maxSelectedItems]="tagsLimit"
        [placeholder]="'Add a new tag'">
        </opti-select>
        <h2 class="second-header notes-header">{{'features.JB.vsb_notes_and_tags.notes' | translate}}</h2>
        <div class="textarea-modal form-group">
            <textarea formControlName="note" class="text-area-control form-control" rows="5">{{noteFormControlValue}}</textarea>
        </div>
    </form>
</div>
