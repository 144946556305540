import { Component, OnInit , ViewEncapsulation } from "@angular/core";
import { IChannelData, ActionType, CHANNEL_METRICS_TRANSLATION_ROOT, ITemplateData, IChannelMetricsElement } from "../../campaignAnalysis.models";
import { IModalConf } from "../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { ChannelMetricsTransformationService } from "../../channelMetricsTransformation.service";
import { TemplateImageProvider } from "../../imageProvider.service";
import { Navigatable } from "../../navigatable";
import { ChannelMetricsUtilitiesService } from "../../channelMetricsUtilities.service";
import {PlatformLocation} from "@angular/common";

@Component({
    selector: 'templates-breakdown',
    templateUrl: './templatesBreakdown.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./templatesBreakdown.component.scss']
})
export class TemplatesBreakdownComponent extends Navigatable implements OnInit {
    private fullMetricsList: string[] = [
        "Delivered",
        "Opened",
        "Clicked",
        "Unsubscribed",
        "Dropped",
        "Sent",
        "SpamReport",
        "Bounced",
        "Dismissed",
        "Impressions",
        "Reach"
    ];
    channelData: IChannelData;
    actionType: ActionType;
    actionName: string;
    metricClickCallback: ({ metricId, metricName, templateId, planDetailChannelId, multiId }) => void;

    allMetricsNames: string[];
    modalConf: IModalConf;
    metricsTranslationPath = CHANNEL_METRICS_TRANSLATION_ROOT + 'TEMPLATES_BREAKDOWN_METRICS.';
    templateNameTranslationPath = CHANNEL_METRICS_TRANSLATION_ROOT + 'TEMPLATE_NAME';
    newMetricsTooltipTranslatePath: string = CHANNEL_METRICS_TRANSLATION_ROOT + 'NEW_METRICS_TOOLTIP.';
    specialValue = Number.MAX_SAFE_INTEGER;
    templatePreviews = {};


    constructor(public bsModalRef: BsModalRef,
        private translate: TranslateService,
        private templateImageProvider: TemplateImageProvider,
        private transformationService: ChannelMetricsTransformationService,
        utils: ChannelMetricsUtilitiesService) {

        super(utils);
    }

    ngOnInit() {
        this.modalConf = {
            title: this.translate.instant(CHANNEL_METRICS_TRANSLATION_ROOT + 'TEMPLATES_BREAKDOWN'),
            xButton: { action: () => this.onCancel() },
            hideFooterPanel: true
        };
        const transformedMetricNames = this.transformationService.getAllMetricsNames(this.channelData.templatesData);
        this.allMetricsNames = transformedMetricNames?.filter(metric => this.fullMetricsList.includes(metric)) ?? [];
        this.getTemplatePreviews();
    }

    onCancel(): void {
        this.bsModalRef.hide();
    }

    getMetricValue(templateData: ITemplateData, metricName: string) {
        const metric: IChannelMetricsElement = this.findMetric(templateData, metricName);
        if (metric) {
            return metric.number || this.specialValue;
        }
        else return null;
    }

    getMetricPercent(templateData: ITemplateData, metricName: string) {
        const metric: IChannelMetricsElement = this.findMetric(templateData, metricName);
        if (metric) {
            return metric.percent || this.specialValue;
        }
        else return null;
    }

    getMetricTooltip(metricName: string) {
        const metric: IChannelMetricsElement = this.findMetric(this.channelData.templatesData[0], metricName);
        if (metric) {
            if(this.channelData.channelId == 29){
                return this.translate.instant(this.newMetricsTooltipTranslatePath + metric.name, { denominator: metric.denominatorName.toLowerCase()});
            }
            return metric.tooltip;
        }
        else return null;
    }

    getTemplatePreviews() {
        for (let i = 0; i < this.channelData.templatesData.length; i++) {
            this.getTemplatePreview(this.channelData.templatesData[i].templateId);
        }
    }

    onMetricClick(templateData: ITemplateData, metricName: string) {
        const metric = this.findMetric(templateData, metricName);

        if (!this.isNavigatable(metric))
            return;

        this.metricClickCallback({
            metricId: metric.id,
            metricName: metric.name,
            templateId: templateData.templateId,
            multiId: templateData.multiId,
            planDetailChannelId: this.channelData.planDetailChannelId
        });
    }

    mayNavigate(templateData, metricName) {

        const metric = this.findMetric(templateData, metricName);
        return this.isNavigatable(metric);
    }

    private findMetric(templateData: ITemplateData, metricName: string): IChannelMetricsElement {
        return templateData.metrics.find(metric => metric.name == metricName);
    }

    private getTemplatePreview(templateId: number): void {
        this.templateImageProvider.checkImageExists(templateId, this.channelData.channelId, (exists, url) => {
            this.templatePreviews[templateId] = exists ? url : null;
        });
    }

}