import { Injectable } from "@angular/core";
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpOptions, HttpService } from '../../../../services/optimove-http/optimove-http.model';
import { BrandGroup } from '../models/brandGroup.model';
import { Topic } from '../models/topic.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AjaxResponse } from '@optimove/ui-sdk/common/models/src/ajaxResponse.model';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { SsmService } from "src/app/services/ssm.service";
import { AuditModel } from "../../../customer360/components/preferenceHistoryModal/models/preferenceHistoryAuditModel";
import { BaseAttribute } from "../../attributes/attributesList.model";

@Injectable()
export class PreferenceCenterService {
  private readonly coreApiBasePath: string = '/UserSettings';

  private $topicCreated: Subject<Topic> = new Subject<Topic>();
  public topicCreated: Observable<Topic> = this.$topicCreated.asObservable();
  private $topicUpdated: Subject<Topic> = new Subject<Topic>();
  public topicUpdated: Observable<Topic> = this.$topicUpdated.asObservable();
  private $topicDeleted: Subject<Topic> = new Subject<Topic>();
  public topicDeleted: Observable<Topic> = this.$topicDeleted.asObservable();

  constructor(
    private readonly http: HttpService,
    private readonly httpClient: HttpClient,
    private ssmService: SsmService
  ) { }


  exportAuditsAsCSV(customerId: string, brandGroupId: string, channelId: number): Observable<Blob> {
      const queryParams = {}
      queryParams['customerId'] = customerId;
      queryParams['brandGroupId'] = brandGroupId;
      queryParams['channelId'] = channelId;

    const headers:{ [header: string]: string } = {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
    };

    return this.sendRequest(() => this.httpClient.get(`${this.getPreferenceCenterUrl()}/api/v1/audits/export/`, { headers: headers, params: queryParams, responseType: 'blob' }));
  }

  public getAudits(actionType: string, customerId: string, brandGroupId: string, channelId: number, toDate: Date, take: number = 1): Observable<AuditModel> {
    const queryParams = {}
    queryParams['customerId'] = customerId;
    queryParams['brandGroupId'] = brandGroupId;
    queryParams['channelId'] = channelId;

    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(59);
    toDate.setMilliseconds(999);

    const adjustedDate = new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000));

    queryParams['toDate'] = adjustedDate.toISOString();
    queryParams['take'] = take;

    return this.sendRequest(() => this.httpClient.get<AuditModel>(`${this.getPreferenceCenterUrl()}/api/v1/audits/${actionType}`, { headers: this.getHeaders(), params: queryParams }));
  }

  public getBrandGroups(channelId?: number, brandId?: string): Observable<BrandGroup[]>
  {
    const queryParams = {};
    if (channelId)
      queryParams['channelId'] = channelId;
    if (brandId)
      queryParams['brandId'] = brandId;

    return this.sendRequest(() => this.httpClient.get<BrandGroup[]>(`${this.getPreferenceCenterUrl()}/api/v1/brand-groups`, { headers: this.getHeaders(), params: queryParams }))
    .pipe(catchError(error => of(error)));
  }

  public getTopics(brandGroupId: string): Observable<Topic[]>
  {
    return this.sendRequest(() => this.httpClient.get<Topic[]>(`${this.getPreferenceCenterUrl()}/api/v1/topics`, {
      headers: this.getHeaders(),
      params: { brandGroupId }
    }));
  }

  public addTopic(topic: Topic): Observable<Topic>
  {
    return this.sendRequest(() => this.httpClient.post<Topic>(`${this.getPreferenceCenterUrl()}/api/v1/topics`, topic, { headers: this.getHeaders() }));
  }

  public updateTopic(topic: Topic): Observable<Topic>
  {
    return this.sendRequest(() => this.httpClient.put<Topic>(`${this.getPreferenceCenterUrl()}/api/v1/topics`, topic, { headers: this.getHeaders() }));
  }

  public deleteTopic(topicId: string): Observable<Topic>
  {
    return this.sendRequest(() => this.httpClient.delete<Topic>(`${this.getPreferenceCenterUrl()}/api/v1/topics/${topicId}`, { headers: this.getHeaders() }));
  }

  public isPreferenceEnabled(): Observable<boolean>
  {
    return this.getScalar(`${this.coreApiBasePath}/GetPreferenceSettingsConfigEnabled`);
  }
  
  getVersion(): Observable<string> {
    return this.httpClient.get<any>(`${this.getPreferenceCenterUrl()}/healthcheck`, {observe: 'response'})
      .pipe(map(response => response.headers.get('api-version')));
  }

  getAttributes(): Observable<BaseAttribute[]> {
    return this.http.get<BaseAttribute[]>(`/AttributesManagerProxy/GetAttributes`, {
      params: { types: ["Regular"] }
    }).pipe(catchError(error => of(error)));
  }

  public topicCreatedEvent(topic: Topic) {
    this.$topicCreated.next(topic);
  }

  public topicUpdatedEvent(topic: Topic) {
    this.$topicUpdated.next(topic);
  }

  public topicDeletedEvent(topic: Topic) {
    this.$topicDeleted.next(topic);
  }

  getPreferenceCenterUrl(): string {
    const ssm = this.ssmService.getSSM();
    const config = ssm .GetGeneric(ssm.Resx.PreferenceCenterDetails);
    return config['url'];
  }

  private getScalar<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.http.get<AjaxResponse<string>>(url, options)
    .pipe(map(res => {
      return res.data as unknown as T;
    }));
  }

  private sendRequest<T>(requestFunc: (() => Observable<T>)): Observable<T> {
    return requestFunc()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case HttpStatusCode.Unauthorized:
              if (localStorage.getItem('refreshToken') === null || localStorage.getItem('accessToken') === null) {
                return throwError(error);
              }

              const getNewAccessToken$ = this.getNewAccessToken();
              return getNewAccessToken$.pipe(
                switchMap(() => requestFunc()),
                catchError((refreshError) => {
                  return throwError(refreshError); // Re-throw the error to propagate it further
                })
              );
            default:
              break;
          }
          // Return an observable with a user-facing error message.
          return throwError(error);
        }),
        map((response) => response as any as T)
      );
  }

  private getHeaders(): { [header: string]: string } {
    return {
      "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
    };
  }

  private getNewAccessToken(): Observable<string> {
		return this.http.post<string>('/Auth/Refresh', { refresh: localStorage.getItem('refreshToken') }, HttpService.getOptions()).pipe(
			tap((accessToken: string) => {
				localStorage.setItem('accessToken', accessToken);
			})
		);
	}
}
