import {Injectable, Injector } from '@angular/core';
import { AngularRouterUIRouterAdapter } from '../routing/angularRouterUIRouterAdapter.service';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { DailyStatus } from 'src/app/models/dailyServiceStatus.model'
import moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class NavbarWrapperService {
    // a bunch-load of legacy angularjs services that are imported to the wrapper service
    // they should be gradually rewritten in angular. Notice we can't write a wrapper in angular
    // for them as requring them in consturctor as a service is too early (angularjs ins't binded yet)
    _gtmInitializationService: any
    private get gtmInitializationService(): any {
        if(!this._gtmInitializationService) {
        this._gtmInitializationService = this.injector.get('gtmDataLayerService');
        } 
        return this._gtmInitializationService;
    }

    _navbarUtilityService: any
    private get navbarUtilityService(): any {
        if(!this._navbarUtilityService) {
        this._navbarUtilityService = this.injector.get('navbarUtilityService');
        } 
        return this._navbarUtilityService;
    }
    
    _navbarDataService: any;
    private get navbarDataService(): any {
        if(!this._navbarDataService) {
            this._navbarDataService = this.injector.get('navbarDataService');
        } 
        return this._navbarDataService;
    }

    _dailyStatusService: any;
    private get dailyStatusService(): any {
        if(!this._dailyStatusService) {
            this._dailyStatusService = this.injector.get('dailyStatusService');
        } 
        return this._dailyStatusService;
    }

    _optibotNotificationSystemService: any;
    private get optibotNotificationSystemService(): any {
        if(!this._optibotNotificationSystemService) {
            this._optibotNotificationSystemService = this.injector.get('optibotNotificationSystemService');
        } 
        return this._optibotNotificationSystemService;
    }

    _ssmService: any;
    private get ssmService(): any {
        if(!this._ssmService) {
            this._ssmService = this.injector.get('ssmService');
        } 
        return this._ssmService;
    }

    private optibotNotifications: OptibotNotifications;
    public optibotNotificationsSubject = new Subject<OptibotNotifications>();
    public dailyStatusSubject = new Subject<DailyStatus>();

    constructor(private injector: Injector, private routerAdapater: AngularRouterUIRouterAdapter) {

    }

    public buildUrl(subItem, item){
        return this.navbarUtilityService.buildUrl(subItem, item);
    }

    public initPageAnalytics(navItemName, tenantId){
        this.gtmInitializationService.initPageUserId(navItemName, tenantId);
    }

    public onInitialize(): Observable<NavbarStateFlags> {
        let normalizeBoolean = (obj) => (obj === true || obj === 'true');
        const SSM = this.ssmService.getSSM();
        return forkJoin([
            from(SSM.getGenericAsync(SSM.Resx.IsSettingsAvailable)),
            from(SSM.getGenericAsync(SSM.Resx.DemoTenant))
        ])
            .pipe(
                map(([settingsVisible, isDemoMode]) => {
                    return {
                        isSettingsVisible: normalizeBoolean(settingsVisible),
                        isDemoMode: normalizeBoolean(isDemoMode)
                    }
                })
            );
    }

    public initListeners() {
        this.listenToDailyStatus();
        this.listenToOptibotNotification();
    }

    private async getNavItems(): Promise<any[]> {
        let navItems = this.navbarDataService.getCachedNavItems();
        if (!navItems){
            navItems = await this.navbarDataService.getNavItems();
        }
        return navItems
    }

    private listenToOptibotNotification() {
        let service = this.optibotNotificationSystemService;
        service.init().then((success) => {
            if (!success) {
                return;
            }
            service.register(service.events.updated, (data) => {
                this.setOptibotNotifications(data);
            });

            service.register(service.events.read, (data) => {
                this.notificationsRead(data);
            });
            service.getPending().then((pendingNotifications) => {
                this.setOptibotNotifications(pendingNotifications);
            });
        });
    }
    
    private resetNotifications() {
        this.setOptibotNotifications(this.optibotNotificationSystemService.getDefaultValue());
    }
    
    private notificationsRead(readNotificationsResult) {
        if (readNotificationsResult === true) {
            this.resetNotifications();
        } else {
            this.removeNotifications(readNotificationsResult);
        }
    }
    
    private removeNotifications(notificationsIds) {
        for (var i = 0; i < notificationsIds.length; i++) {
            var notificationId = notificationsIds[i];
            var idx = this.optibotNotifications.results.findIndex(function (activity) {
                return activity.id === notificationId;
            });
            if (idx > -1) {
                this.optibotNotifications.results.splice(idx, 1)
                this.optibotNotifications.unseen--;
                this.optibotNotifications.unread--;
                this.optibotNotificationsSubject.next(this.optibotNotifications);
            }
        }
    }

    private setOptibotNotifications(optibotNotifications: OptibotNotifications){
        this.optibotNotifications = optibotNotifications;
        this.optibotNotificationsSubject.next(optibotNotifications);
    }

    private listenToDailyStatus() {
        if (sessionStorage.dailyStatus){
            let cachedStatus = JSON.parse(sessionStorage.dailyStatus);
            this.dailyStatusSubject.next(cachedStatus);
        }
        this.dailyStatusService.register("lastRunStatusHandler", (success, response) => {
            if (!success){
                return;
            }
            let status: DailyStatus = {
                dataUpdateStatus: response.dataUpdateStatus,
                campaignExecutionStatus: response.campaignExecutionStatus,
                analysisStatus: response.analysisStatus,
                lastSuccessfulRunDate: response.lastSuccessfulRun,
                isRunning: response.isRunning,
                lastSuccessfulRun: moment(response.lastSuccessfulRun).format('D MMM YYYY').toString(),
                dailyScheduleRunTime : response.dailyScheduleRunTime
            };
            this.dailyStatusSubject.next(status);
            sessionStorage.dailyStatus = JSON.stringify(status);
        });
        this.dailyStatusService.init();
    }
}

export interface OptibotNotifications {
    unseen?: number,
    unread: number,
    results: any[],
}

export interface NavbarStateFlags {
    isSettingsVisible: boolean,
    isDemoMode: boolean
}