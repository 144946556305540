import { Component, Input, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { IActionData } from "../../campaignAnalysis.models";
import { ChannelMetricsTransformationService } from "../../channelMetricsTransformation.service";
import {ChannelMetricsInputData} from "./models/channelMetricsInputData";

@Component({
    selector: 'channel-metrics',
    templateUrl: './channelMetrics.component.html',
    styleUrls: ['./channelMetrics.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChannelMetricsComponent {

    public isLoading: boolean = false;
    public isExpanded: boolean = false;
    public showNoMetrics: boolean = false;
    public inputData: any;
    public actions: IActionData[];
    public dates: any;
    public planIdActionSerial: any;
    public _outliersIsOn = false;

    @Output() metricClick: EventEmitter<{ metricId, channelId, planDetailChannelId, metricName, templateId, dates, multiId,executionStartDate,executionEndDate }> = new EventEmitter();

    @Input() set isFetchingChannelMetrics(value: boolean) {
        this.isLoading = value;
        if (value) {
            this.isExpanded = true;
        }
    }

    @Input() set metricsData(value: ChannelMetricsInputData){
        this.inputData = value;
        this.translateServerData();
    }

    constructor(private transformationService: ChannelMetricsTransformationService) {
    }


    onMetricClick(payload) {
        payload.dates = this.dates;
        this.metricClick.emit(payload);
    }

    private translateServerData() {
        if(!this.inputData){
            return;
        }


        if (Object.keys(this.inputData.campaignMetric).length === 0 || this.isNoMetrics()) {
            this.showNoMetrics = true;
            this.isExpanded = false;
        }
        else {
            this.showNoMetrics = false;
            this.isExpanded = true;
        }


        this.dates = this.inputData.dates;
        this.planIdActionSerial = this.inputData.planIdActionSerial;

        this.actions = this.transformationService.transform(this.inputData.campaignMetric, this.inputData.groupSizes, this.inputData.denominatorsByMetric);
    }

    private isNoMetrics() : boolean{
        return this.inputData.campaignMetric.every(x=>x.channels.every(y=>y.templatesData.length == 0));
    }
}
