import { Injectable } from '@angular/core';
import { AjaxResponse } from '@optimove/ui-sdk/common/models';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../../services/optimove-http/optimove-http.model';

@Injectable()
export class CopyTemplateModalService {
	constructor(private readonly http: HttpService) {}
	public copyTemplate(templateId: number, channelId: number, newSubMethodId) {
		return this.http
			.post<AjaxResponse<boolean>>(`/ManageTemplates/DuplicateTemplate`, {
				templateId: templateId,
				channelId: channelId,
				newSubMethodId: newSubMethodId
			})
			.pipe(map((res) => res.data));
	}
}
