import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IListItem } from '@optimove/ui-sdk/common/models';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Observer, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { PersonalizationTagsContainerComponent } from 'src/app/components/personalizationTagsContainer/personalizationTagsContainer.component';
import { FeatureFlag, FeatureFlagService } from 'src/app/services/featureFlag.service';
import { OptiLogicModalService } from '../../../../components/optiLogicModal/optiLogicModal.service';
import { BeeFreeFonts, BeeFreePluginSetupData } from '../../models/beefree';
import { RoutingConsts } from '../../models/routing.consts';
import { TemplateDetails } from '../../models/templateDetails';
import { TemplateQueryParams } from '../../models/templateQueryParams';
import { UnsubscribeMultiLanguageTemplate } from '../../models/unsubscribeMultiLanguageTemplate';
import { BeefreeEditorService } from '../../services/beefreeEditor.service';
import { ManageTemplatesService } from '../../services/manageTemplates.service';
import { SubMethodService } from '../../services/subMethodService';
import { TemplateContextService } from '../../services/templateContext.service';
import { TemplateSaveUpdateService } from '../../services/templateSaveUpdate.service';
import { TenantInformationService } from '../../services/tenantInformation.service';
import { EditRowModalComponent } from '../dialogs/editRowModal/editRowModal.component';
import { SaveRowModalComponent } from '../dialogs/saveRowModal/saveRowModal.component';
import { UnsubscribeTagsModalComponent } from '../dialogs/unsubscribeTagsModal/unsubscribeTagsModal.component';

interface BeePlugin {
	start(arg: any): void;
}

interface UnsubscribeListItem extends IListItem {
	value: string;
}

@Component({
	selector: 'beefree-container',
	templateUrl: './beefreeContainer.component.html',
	styleUrls: ['./beefreeContainer.component.scss']
})
export class BeefreeContainerComponent implements OnInit, OnDestroy {
	@Input() isFullscreen: boolean = false;

	readonly BEEJS_URL: string = 'https://loader.getbee.io/v1/api/loader';
	public template: TemplateDetails;
	private beePluginInstance: BeePlugin;
	private subMethodType: number;
	public subMethodTypeName: string;
	public subMethodId: string;
	public brandName: string;
	public tenantId: string;
	private unsubscribe$ = new Subject<any>();
	private queryParams: TemplateQueryParams;
	public isDynamicMailBeefreeAddonEnabled = false;
	public isOptiXBeefreeAddonEnabled = false;
	private optiXAddonId = 'a29d0319-8712-4201-84c9-f6e7d3557886';
	private dynamicMailAddonId = 'DynamicMailAddon';

	constructor(
		private readonly manageTemplatesService: ManageTemplatesService,
		private templateContextService: TemplateContextService,
		private translate: TranslateService,
		private modalService: OptiLogicModalService,
		private beeFreeService: BeefreeEditorService,
		private templateSaveUpdateService: TemplateSaveUpdateService,
		private subMethodService: SubMethodService,
		private tenantInformationService: TenantInformationService,
		private bsModalService: BsModalService,
		private featureFlagService: FeatureFlagService
	) {
		this.isDynamicMailBeefreeAddonEnabled = this.featureFlagService.isEnabled(FeatureFlag.DynamicMailBeefreeAddon);
		this.isOptiXBeefreeAddonEnabled = this.featureFlagService.isEnabled(FeatureFlag.OptiXBeefreeAddon);
	}

	ngOnInit(): void {
		this.templateContextService.current.pipe(takeUntil(this.unsubscribe$)).subscribe((response: TemplateDetails) => {
			this.template = JSON.parse(JSON.stringify(response));
		});
		this.templateContextService.queryTemplateParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: TemplateQueryParams) => {
			const tempQueryParams = this.queryParams;
			this.queryParams = params;
			if (!tempQueryParams || tempQueryParams.templateId !== this.queryParams.templateId) {
				forkJoin([this.manageTemplatesService.getBeeFreePluginSetupData(), this.loadBeeFree()]).subscribe((res) =>
					this.initBeeFree(res[0], params)
				);
			}
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	iframeClick(event: ElementRef): void {
		this.templateContextService.updateContentActivated();
	}

	private loadBeeFree(): Observable<any> {
		return new Observable((observer: Observer<any>) => {
			var r: any = {
				_queue: []
			};
			if ((<any>window).BeePlugin) {
				observer.next(r);
				observer.complete();
				return;
			}

			var t = function () {
				var e = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				return Array.apply(null, Array(50))
					.map(function () {
						return e[Math.floor(Math.random() * e.length)];
					})
					.join('');
			};
			var n = document.createElement('script');
			n.type = 'text/javascript';
			(n.src = this.BEEJS_URL + '?v=' + t()), document.getElementsByTagName('head')[0].appendChild(n);
			var a = 'create'.split(',');
			var u = function (e: any, t: any) {
				return function () {
					t.push([e, arguments]);
				};
			};
			for (var i = 0; i < a.length; i++) {
				r[a[i]] = u(a[i], r._queue);
			}

			n.onload = function () {
				const state = (<any>this).readyState;
				if (!state || state == 'loaded' || state == 'complete') {
					// script successfully loaded
					observer.next(r);
				} else {
					observer.error(state);
				}
				observer.complete();
			};

			(<any>window).BeePlugin = r;
		});
	}

	private initBeeFree(setupData: BeeFreePluginSetupData, queryParams: TemplateQueryParams): void {
		const tenantInfo = this.tenantInformationService.getTenantInformation();
		this.tenantId = tenantInfo.tenantId;
		this.subMethodId = queryParams.typeId;
		const savedRowsUrl = setupData.savedRowsUrl;
		this.brandName = queryParams.brandId;
		this.manageTemplatesService.getMetadata().subscribe((metadata) => {
			this.subMethodType = this.subMethodService.findSubmethodType(metadata, +this.subMethodId);
			this.subMethodTypeName = `${queryParams.brandId} & ${this.translate.instant(
				this.subMethodService.getSubMethodTypeText(this.subMethodType, RoutingConsts.OPTIMAIL_CHANNEL)
			)}`;
			const beeConfig = {
				contentDefaults: {
					general: {
						contentAreaWidth: '600px'
					}
				},
				uid: '00' + tenantInfo.tenantId,
				container: 'bee-free-editor',
				contentDialog: this.getContentDialog(),
				onComment: ((response) => this.onBeefreeComment(response)).bind(this),
				onChange: (jsonFile, response) => {
					this.onBeeFreeChange(jsonFile, response);
				},
				onSaveRow: function onBeefreeSaveRow(jsonVal, html, pagePartial) {
					const jsonObj = JSON.parse(jsonVal);
					const row = {
						...jsonObj,
						metadata: {
							...jsonObj.metadata,
							originTemplateId: this.template.templateId
						}
					};
					const rowJson = JSON.stringify(row);
					this.beeFreeService
						.saveBeefreeSavedRow({
							rowObject: rowJson
						})
						.subscribe((res) => {
							res;
						});
				}.bind(this),
				trackChanges: true,
				username: tenantInfo.name,
				userHandle: btoa(tenantInfo.userName),
				rowsConfiguration: {
					emptyRows: true,
					defaultRows: true,
					externalContentURLs: [
						{
							name: `Custom Rows (${this.subMethodTypeName})`,
							value: savedRowsUrl + tenantInfo.tenantId + '/' + this.subMethodId + '.json',
							handle: this.subMethodId,
							behaviors: {
								canDelete: true,
								canEdit: true
							}
						},
						{
							name: 'Custom Rows (All brands & types)',
							value: savedRowsUrl + tenantInfo.tenantId + '/' + 'custom-rows-cross-brands.json',
							handle: 'cross-brands',
							behaviors: {
								canDelete: true,
								canEdit: true
							}
						},
						{
							name: 'Conditional Language',
							value: savedRowsUrl + 'conditional-language/conditional-language-preset-saved-rows.json'
						},
						{
							name: 'Gmail Promotions Tab',
							value: savedRowsUrl + 'gmail/promotions-tab-rows.json'
						},
						{
							name: 'COVID-19 Communications',
							value: savedRowsUrl + 'conditional-language/covid-19-communications-saved-rows.json'
						}
					]
				},
				editorFonts: BeeFreeFonts,
				addOns: [
					{
						enabled: false,
						id: this.dynamicMailAddonId
					},
					{
						enabled: false,
						id: this.optiXAddonId
					}
				]
			};

			if (this.isDynamicMailBeefreeAddonEnabled) {
				beeConfig.addOns = beeConfig.addOns.map((addon) =>
					addon.id === this.dynamicMailAddonId ? { ...addon, enabled: true } : addon
				);
			}
			if (this.isOptiXBeefreeAddonEnabled) {
				beeConfig.addOns = beeConfig.addOns.map((addon) => (addon.id === this.optiXAddonId ? { ...addon, enabled: true } : addon));
			}

			this.manageTemplatesService.getBeeFreeFonts().subscribe((fonts) => {
				beeConfig.editorFonts.customFonts = fonts;
				(<any>window).BeePlugin.create(
					JSON.parse(setupData.token),
					beeConfig,
					this.beeFreeCreateCallback.bind(this, +queryParams.templateId)
				);
			});
		});
	}

	private beeFreeCreateCallback(templateId: number, beePluginInstance: any): void {
		if (templateId === this.template.templateId) {
			this.beePluginInstance = beePluginInstance;
			this.beePluginInstance.start(this.beeFreeService.transformExtraDataToClientFormat(this.template.extraData)[1]);
		} else {
			console.warn(
				'Tried to load different template from the one detected during initialization',
				templateId,
				this.template.templateId
			);
		}
	}

	private onBeefreeComment(response) {
		if (response.change.type === 'NEW_COMMENT') {
			if (typeof this.templateSaveUpdateService.beeFreeNewCommentsNotifications === 'undefined') {
				this.templateSaveUpdateService.beeFreeNewCommentsNotifications = {};
			}
			this.collectMentionedUserNotifications(response.change.payload);
			this.collectNotifications(response.change.payload, response.comments);
		}

		// update the json data of comments since the auto-save is run for 15 secs so the data can be not updated
		// onBeefreeChangeCallback is not called for OnComment event
		var extraDataArr = JSON.parse(this.template.extraData);
		extraDataArr[1].comments = response.comments;
		this.template.extraData = JSON.stringify(extraDataArr);
	}
	private collectNotifications(newComment, allComments) {
		for (const key of Object.keys(allComments)) {
			// Find:  1) not same comment element 2) same parent 3) parent but with different author
			if (
				key !== newComment.commentId &&
				(key === newComment.comment.parentCommentId || newComment.comment.parentCommentId === allComments[key].parentCommentId) &&
				newComment.comment.author.userHandle !== allComments[key].author.userHandle
			) {
				const userEmailDecoded = atob(allComments[key].author.userHandle);
				if (this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId] === undefined) {
					this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId] = {
						content: newComment.comment.content,
						author: newComment.comment.author.username,
						recipients: [userEmailDecoded]
					};
				} else {
					// add if still not exist
					if (
						!this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId]['recipients'].includes(
							userEmailDecoded
						)
					) {
						this.templateSaveUpdateService.beeFreeNewCommentsNotifications[newComment.commentId]['recipients'].push(
							userEmailDecoded
						);
					}
				}
			}
		}
	}

	private collectMentionedUserNotifications(newComment) {
		if (!newComment.comment.content.includes('@')) {
			return;
		}
		if (typeof this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'] === 'undefined') {
			this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'] = {};
		}
		this.templateSaveUpdateService.beeFreeNewCommentsNotifications['newComments'][newComment.commentId] = {
			content: newComment.comment.content,
			author: newComment.comment.author.username
		};
	}

	public onBeeFreeChange(json, response) {
		if (response.description !== 'Row') {
			let parsedJson = JSON.parse(json);
			this.template.extraData = this.beeFreeService.transformExtraDataToClientFormat(this.template.extraData);
			this.template.extraData[1].page = parsedJson.page;
			this.template.extraData = this.beeFreeService.transformExtraDataToServerFormat(this.template.extraData);
			this.templateContextService.updateTemplateExtraData(this.template.extraData);
		}
	}

	private getContentDialog(): any {
		return {
			specialLinks: {
				label: 'Subscription',
				handler: (resolve, reject) => {
					this.templateContextService.queryTemplateParams
						.pipe(filter((params) => !!params?.typeId))
						.pipe(first())
						.pipe(
							switchMap((queryParams) =>
								this.manageTemplatesService.getAggregatedUnsubscribeMultiLanguageTemplates(+queryParams.typeId)
							)
						)
						.pipe(first())
						.subscribe((unsubscribeTemplates) => {
							const unsubscribeTags = this.unsubscribeListToUnsubscribeTags(unsubscribeTemplates);
							if (!unsubscribeTags || !unsubscribeTags.length) {
								reject();
								return;
							}
							let isSuccess = false;
							this.bsModalService.onHide.pipe(first()).subscribe(() => {
								if (!isSuccess) {
									reject();
								}
							});
							this.modalService.open(UnsubscribeTagsModalComponent, 'sm', <ModalOptions<any>>{
								initialState: {
									unsubscribeTags,
									tagSelected: (selected: number) => {
										const selectedTag = unsubscribeTags.find((t) => t.id === selected);
										var temporalDivElement = document.createElement('div');
										temporalDivElement.innerHTML = selectedTag.value;
										const lbl = temporalDivElement.textContent;
										let hrefLink = lbl;
										const hrefLinks = selectedTag.value.match('hrefs*=s*["\'\'](.*?)["\']');
										if (hrefLinks != undefined) {
											hrefLink = hrefLinks[1];
										}

										isSuccess = true;
										resolve({
											type: 'custom',
											label: lbl,
											link: hrefLink
										});
									}
								}
							});
						});
				}
			},
			mergeTags: {
				label: 'Personalization',
				handler: (resolve, reject) => {
					const chosen = new EventEmitter<string>();
					let modalRef;
					chosen.pipe(first()).subscribe((tag: string) => {
						if (tag.length === 0)
							reject();

						if (tag.includes('_{X}'))
							tag = this.getRecommendationTemplatesTagNumber(JSON.stringify(this.template.extraData[1]), tag);

						resolve({ name: tag, value: tag });
						modalRef?.hide();
					});
					modalRef = this.modalService.open(PersonalizationTagsContainerComponent, 'md', <ModalOptions<any>>{
						initialState: {
							isBeeFree: true,
							subMethodId: this.subMethodType,
							chosen
						}
					});
				}
			},
			saveRow: {
				handler: function (resolve, reject, args) {
					const modalRef = this.modalService.open(SaveRowModalComponent, 'md', <ModalOptions<any>>{
						ignoreBackdropClick: true,
						initialState: {
							resolve: resolve,
							brandId: this.subMethodId,
							brandAndType: this.subMethodTypeName,
							tenantId: this.tenantId,
							brandName: this.brandName
						}
					});
				}.bind(this)
			},
			onDeleteRow: {
				handler: async (resolve, reject, args) => {
					this.beeFreeService.DeleteCustomRow(args.row).subscribe((res) => {
						if (res.isSuccess) {
							resolve(true);
						} else {
							reject(res);
						}
					});
				}
			},
			onEditRow: {
				handler: async (resolve, reject, args) => {
					this.modalService.open(EditRowModalComponent, 'md', <ModalOptions<any>>{
						ignoreBackdropClick: true,
						initialState: {
							rowData: args.row,
							resolve: resolve
						}
					});
				}
			}
		};
	}

	private getRecommendationTemplatesTagNumber(content: string, tag: string): string {
		if (tag.indexOf('{X}') === -1) return tag;

		let tagName = tag.replace('X}%]', ''),
			maxNum = 1,
			tagNameLen: number = tagName.length,
			contentLen: number = content.length,
			startIndex: number = 0,
			index: number;

		while ((index = content.indexOf(tagName, startIndex)) > -1) {
			startIndex = index + tagNameLen;
			let theRestOfContent: string = content.substring(startIndex, contentLen);
			let tagNumber: number = parseInt(theRestOfContent.match(/\d+/)[0]);

			if (!isNaN(tagNumber) && tagNumber >= maxNum) {
				maxNum = ++tagNumber;
			}
		}

		return tag.replace('{X}', '{' + maxNum + '}');
	}

	private unsubscribeListToUnsubscribeTags(data: UnsubscribeMultiLanguageTemplate[]): UnsubscribeListItem[] {
		const suppressionsDictionary: UnsubscribeListItem[] = [];

		for (let i: number = 0; data && i < data.length; i++) {
			const groupBy = data[i].Name;

			if (!data[i].SuppressionTemplates) {
				continue;
			}
			for (let j: number = 0; j < data[i].SuppressionTemplates.length; j++) {
				suppressionsDictionary.push({
					groupBy,
					id: data[i].SuppressionTemplates[j].id,
					name: data[i].SuppressionTemplates[j].name,
					value: data[i].SuppressionTemplates[j].value
				} as any);
			}
		}

		return suppressionsDictionary;
	}
}
