import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TemplateConditionalLanguageService {
	constructor() {}

	public getConditionalLanguageSnippets(): any {
		const forHtmlFroalaEditor = [
			{
				title: 'Simple Condition (Text Based Attribute)',
				html: `<div>
<p>[%IF:YOUR_TAG=='TEXT'%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Content to display (Image, Text, Any other)</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'Simple Condition (Numerical Based Attribute)',
				html: `<div>
<p>[%IF:YOUR_TAG&gt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Content to display (Image, Text, Any other)</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'Simple Condition (This condition checks if there is any value for the attribute)',
				html: `<div>
<p>[%IF:YOUR_TAG%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Content to display (Image, Text, Any other)</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'IF-ELSE Condition',
				html: `<div>
<p>[%IF:YOUR_TAG=='TEXT'%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Content to display (Image, Text, Any other)</span></em><br/>
[%END:IF%]<br/>
[%ELSE%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative content</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'IF-ELSEIF Condition',
				html: `<div>
<p>[%IF:YOUR_TAG&gt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Content to display (Image, Text, Any other)</span></em><br/>
[%END:IF%]<br/>
[%ELSEIF:YOUR_TAG&lt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative content</span></em><br/>
[%END:IF%]<br/>
[%ELSE%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative content</span></em><br/>
[%END:IF%]
</p>
</div>`
			}
		];

		const forSubjectLine = [
			{
				title: 'Simple Condition (Text Based Attribute)',
				html: `[%IF:YOUR_TAG=='TEXT'%]
Text to display
[%END:IF%]`,
				displayHtml: `<div>
<p>[%IF:YOUR_TAG=='TEXT'%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Text to display</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'Simple Condition (Numerical Based Attribute)',
				html: `[%IF:YOUR_TAG&gt;NUMBER%]
Text to display
[%END:IF%]`,
				displayHtml: `<div>
<p>[%IF:YOUR_TAG&gt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Text to display</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'Simple Condition (This condition checks if there is any value for the attribute)',
				html: `[%IF:YOUR_TAG%]
Text to display
[%END:IF%]`,
				displayHtml: `<div>
<p>[%IF:YOUR_TAG%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Text to display</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'IF-ELSE Condition',
				html: `[%IF:YOUR_TAG=='TEXT'%]
Text to display
[%END:IF%]
[%ELSE%]
Alternative text
[%END:IF%]`,
				displayHtml: `<div>
<p>[%IF:YOUR_TAG=='TEXT'%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Text to display</span></em><br/>
[%END:IF%]<br/>
[%ELSE%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative text</span></em><br/>
[%END:IF%]
</p>
</div>`
			},
			{
				title: 'IF-ELSEIF Condition',
				html: `[%IF:YOUR_TAG&gt;NUMBER%]
Text to display
[%END:IF%]
[%ELSEIF:YOUR_TAG&lt;NUMBER%]
Alternative text
[%END:IF%]
[%ELSE%]
Alternative text
[%END:IF%]`,
				displayHtml: `<div>
<p>[%IF:YOUR_TAG&gt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Text to display</span></em><br/>
[%END:IF%]<br/>
[%ELSEIF:YOUR_TAG&lt;NUMBER%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative text</span></em><br/>
[%END:IF%]<br/>
[%ELSE%]<br/>
<em><span style="color: rgb(144, 164, 174);">&nbsp; &nbsp; Alternative text</span></em><br/>
[%END:IF%]
</p>
</div>`
			}
		];

		return { forHtmlFroalaEditor, forSubjectLine };
	}
}
