import { Operator } from './../../scheduledCampaignBuilder/models/wizardData.model';
import { TimeframePeriod, AttributeFunctions, AttributeFormat, AttributeType, AttributeFamily, AttributeDataTypeConditional, AttributeDataTypePurchase } from "./attributesList.enums";
import { Timeframe } from "./attributesList.model";
import { SearchItem } from "../../../components/optiSearchList/optiSearchListComponent/optiSearchList.component";


// Catch mostly all special sign: https://www.codegrepper.com/code-examples/javascript/remove+special+characters+from+string+javascript
export const specialCharactersRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]|[\s]/gi;

export const AttibuteFunctionDic = {
    SUM: "Sum",
    AVERAGE: "Average",
    MAX: "Max",
    MIN: "Min"
}

export const functions = [
    {
        key: AttributeFunctions.sum,
        value: AttibuteFunctionDic.SUM
    },
    {
        key: AttributeFunctions.average,
        value: AttibuteFunctionDic.AVERAGE
    },
    {
        key: AttributeFunctions.max,
        value: AttibuteFunctionDic.MAX
    },
    {
        key: AttributeFunctions.min,
        value: AttibuteFunctionDic.MIN
    },
];


export const formulaFormatItems: SearchItem[] = [
    { key: AttributeFormat.Percentage, text: 'Percentage' },
    { key: AttributeFormat.Currency, text: 'Currency' },
    { key: AttributeFormat.Number, text: 'Number' },
    { key: AttributeFormat.Decimal, text: 'Decimal' }
];

export const conditionFormatItems: SearchItem[] = [
    { key: AttributeFormat.Number, text: 'Number' },
    { key: AttributeFormat.String, text: 'String' },
    { key: AttributeFormat.Boolean, text: 'Boolean' },  
    { key: AttributeFormat.Decimal, text: 'Decimal' }
];

const operatorsNum: Operator[] = [
    {Name: 'Less', Id: 9, Expression: '<'},
    {Name: 'LessOrEqual', Id: 10, Expression: '<='},
    {Name: 'Does not equal', Id: 6, Expression: '<>'},
    {Name: 'Equals', Id: 5, Expression: '='},
    {Name: 'More', Id: 7, Expression: '>'},
    {Name: 'MoreOrEqual', Id: 8, Expression: '>='},
    {Name: 'Between', Id: 11, Expression: 'Between'},
];
  
const operatorsStr: Operator[] = [
    {Name: 'Begins with', Id: 12, Expression: 'Begins with'},
    {Name: 'Contains', Id: 14, Expression: 'Contains'},
    {Name: 'Does not contain', Id: 15, Expression: 'Does not contain'},
    {Name: 'Does not equal', Id: 6, Expression: 'Does not equal'},
    {Name: 'Ends with', Id: 13, Expression: 'Ends with'},
    {Name: 'Equals', Id: 5, Expression: 'Equals'},
    { Name: 'Is not null', Id: 29, Expression: 'Is not null' },
    { Name: 'Is null', Id: 28, Expression: 'Is null' },
    {Name: 'Not one of', Id: 22, Expression: 'Not one of'},
    {Name: 'One of', Id: 21, Expression: 'One of'},
];

const operatorsDate: Operator[] = [
    {Name: 'After', Id: 17, Expression: 'After'},
    {Name: 'Before', Id: 16, Expression: 'Before'},
    {Name: 'Between', Id: 11, Expression: 'Between'},
    {Name: 'Does not equal', Id: 6, Expression: 'Does not equal'},
    {Name: 'Equals', Id: 5, Expression: 'Equals'},
    {Name: 'This week', Id: 26, Expression: 'This week'},
    {Name: 'Today', Id: 19, Expression: 'Today'},
    {Name: 'Tomorrow', Id: 18, Expression: 'Tomorrow'},
    {Name: 'Yesterday', Id: 20, Expression: 'Yesterday'},
];
  
export const conditionalOperators: {type: AttributeDataTypeConditional, operators: Operator[]}[] = [
    {type: "date", operators: operatorsDate},
    {type: "number", operators: operatorsNum},
    {type: "string", operators: operatorsStr},
    {type: "multiValues", operators: operatorsStr},
] 

export const AttributeTypeFamilyArray: {type: AttributeType, family: AttributeFamily}[] = [
    {type: "calculated", family: "ActivityHistory"},
    {type: "base", family: "Regular"},
    {type: "customer", family: "FormulaBased"},
    {type: "conditional", family: "Conditional"},
    {type: "purchase", family: "PurchaseHistory"},
    {type: "realtime", family: "Realtime"}
]

export const attributePrefixesToHide: string[] = [
    "IF_CA_AH_",
    "_RT",
    "VF_CA_FB_",
    "IF_CA_CC_",
    "IF_CA_PH_",
]

export const OldPurchaseHistoryAttributes: {realFieldName: string, aliasName: string, dataType: AttributeDataTypePurchase, isCurrency: boolean, fieldFormat: string}[] = [
    {realFieldName: 'Amount', aliasName: 'Amount', dataType: 'number', isCurrency: true, fieldFormat: "$#,0;($#,0)"},
    {realFieldName: 'Quantity', aliasName: 'Quantity', dataType: 'number', isCurrency: false, fieldFormat: "0.0"},
]

export const ConditionalAttributesMaxLength = 500;