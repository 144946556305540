import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OptiLogicModalService } from '../../../components/optiLogicModal/optiLogicModal.service';
import { CopyTemplateModalComponent } from './copyTemplateModal/copyTemplateModal.component';

@Component({
	selector: 'copy-template-container',
	templateUrl: './copyTemplateContainer.component.html'
})
export class CopyTemplateContainerComponent implements OnInit, OnDestroy {
	private onHideSubscription: Subscription;

	@Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() brands: any[];
	@Input() template: any;
	@Input() channel: number;

	constructor(private modalService: OptiLogicModalService, private bsModalServiceCL: BsModalService) {
		this.onHideSubscription = this.bsModalServiceCL.onHide.subscribe((reason: string) => {
			this.closed.emit(true);
		});
	}
	ngOnInit(): void {
		this.openModal();
	}

	public openModal(): void {
		this.modalService.open(CopyTemplateModalComponent, 'md', <ModalOptions<any>>{
			ignoreBackdropClick: true,
			initialState: {
				brands: this.brands,
				template: this.template,
				channelId: this.channel
			}
		});
	}
	ngOnDestroy(): void {
		this.onHideSubscription.unsubscribe();
	}
}
