import { FormulaService } from './formula.service';
import { Tagify } from '@yaireo/tagify';
import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormulaType } from "../attributesList.enums";
import { BaseAttribute, CalculatedAttribute } from '../attributesList.model';

@Injectable({
    providedIn: 'root',
})
export class FormulaValidatorService {
    
    constructor(private service: FormulaService) {
    }
    
    validate(tagify: Tagify, attributes: (BaseAttribute | CalculatedAttribute)[], formulaType: () => FormulaType): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            if (control.value === null || control.value === '') {
                return null;
            }
            let formula = this.service.getValidationFormula(tagify, attributes, formulaType);
            return this.service.checkFormula(formula) ? null :  {formula: true};
        }
    }
}
