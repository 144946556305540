import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotesAndTags } from '../notesAndTagsButton/model/notesAndTags.model';
import { OptiLogicModalService } from '../optiLogicModal/optiLogicModal.service';
import { Tag } from '../../models/tag.model';
import { AbstractNestedFormComponent } from '../abstractNestedForm/abstractNestedForm.component';
import { TranslateService } from '@ngx-translate/core';
import { IListItem } from "@optimove/ui-sdk/common/models";

@Component({
  selector: 'notes-and-tags-form',
  templateUrl: './notesAndTagsForm.component.html',
  styleUrls: ['./notesAndTagsForm.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotesAndTagsFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NotesAndTagsFormComponent),
      multi: true
    }
  ]
})
export class NotesAndTagsFormComponent extends AbstractNestedFormComponent<NotesAndTags> implements OnInit, OnChanges {

  @Input() tagsLimit: number;
  @Input() allTags: Tag[] = [];
  @Input() streamTestName: string;
  @Input() curNewTags: Tag[] = [];
  @Output() notifyTagCannotBeRemovedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onAddingNewTag = new EventEmitter<IListItem>();
  isTagsListEmpty = true;
  disableAddingTag = false;
  items: IListItem[]
  newTags: Tag[];

  constructor(cd: ChangeDetectorRef, public modalService: OptiLogicModalService, private translate: TranslateService,) {
    super(cd);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.validateTagsLimit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.items = this.allTags?.map(tag => {
      return {
        id: tag.id,
        name: tag.name,
        disabled: tag.isReadOnly
      } as IListItem;
    }) ?? [];
    this.items = this.items.concat(this.curNewTags.map(tag => {
      return {
        id: tag.id,
        name: tag.name,
        disabled: tag.isReadOnly
      } as IListItem;
    }));
  }

  ngAfterViewInit(): void {
    if(this.form.disabled) {
      this.noteFormControl.disable({ emitEvent:false });
      this.disableAddingTag = true;
    }
    else {
      this.noteFormControl.enable({ emitEvent:false });
      this.disableAddingTag = false;
    }
    this.validateTagsLimit();
  }


  private initForm() {
    this.form = new FormGroup({
      note: new FormControl(),
      tags: new FormControl()
    })
  }

  validateTagsLimit() {
    if(this.tagsFormControl.value) {
      this.isTagsListEmpty = !(this.tagsFormControl.value.length > 0);
    }
  }

  notifyTagCannotBeRemovedEvent(): void {
    this.modalService.openModalMessage(
      'sm',
      {
        message: this.translate.instant('features.JB.vsb_notes_and_tags.tag_cannot_be_removed_message', {streamTestName: this.streamTestName}),
        buttons: [{
          class: 'btn-primary',
          label: 'Ok',
          action: () => { }
        }]
      }
    );
  }

  writeValue(formValue: NotesAndTags) {
    super.writeValue(formValue)
  }

  addNewTagEvent(iListItem: IListItem){
    this.onAddingNewTag.emit(iListItem);
  }


  get tagsFormControl(): FormControl {
    return this.form.get('tags') as FormControl;
  }

  get noteFormControl(): FormControl {
    return this.form.get('note') as FormControl;
  }

  get noteFormControlValue(): string {
    return this.noteFormControl.value;
  }

}
