import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EndingType } from '../models/endingType.model';
import { MonthSelection } from '../models/monethSelection.model';
import { RecurrenceFormData } from '../models/recurrenceFormData.model';
import { AfterOccurrencesRange, DailyRecurrence, EndDateRange, MonthlyRecurrence, Recurrence, RecurrencePattern, RecurrenceRange, WeeklyRecurrence } from '../models/recurrenceServer.model';
import { RecurrenceType } from '../models/recurrenceType.model';

@Injectable()
export class RecurrenceFormService {
	constructor() { }
    
	public getWeeksInMonth(month: number, year: number) {
		var weeks = [],
			firstDate = moment(new Date(year, month, 1)).locale('zh_CN'),
			lastDate = moment(new Date(year, month + 1, 0)).locale('zh_CN'),
			numDays = lastDate.date();
		var start = 1;
		var end = 7 - firstDate.weekday();
		while (start <= numDays) {
			weeks.push({ start: start, end: end });
			start = end + 1;
			end = end + 7;
			if (end > numDays)
				end = numDays;
		}
        
		return weeks;
	}

	public getWeekNumberByDate(date: Date) {
		const momentDate = moment(date);
		const dayOfMonth = momentDate.date();
		const month = momentDate.month();
		const year = momentDate.year();
		const weeksInMonth = this.getWeeksInMonth(month, year);
		let weekNumber = weeksInMonth.findIndex(w => dayOfMonth >= w.start && dayOfMonth <= w.end) + 1;
        
		const firstWeekMomentDate = momentDate.clone().subtract(weekNumber - 1, 'weeks');
		if (month !== firstWeekMomentDate.month()) {
			weekNumber--;
		}
        
		return weekNumber;
	}

	public calcFirstDateOnWeeklyReccurence(fromDate: Date, selectedRecurrenceWeekDayIndices: number[]): Date {
		if (!selectedRecurrenceWeekDayIndices || selectedRecurrenceWeekDayIndices.length === 0) {
			return fromDate;
		}

		selectedRecurrenceWeekDayIndices = selectedRecurrenceWeekDayIndices.map(i => (((i - 1) % 7) + 7) % 7);
		let weekDayStartDate = moment(fromDate).isoWeekday() - 1;
		let found = false;
		let daysToAdd = 0;
		while (!found && daysToAdd < 7) {
			if (selectedRecurrenceWeekDayIndices.includes((weekDayStartDate + daysToAdd) % 7)) {
				found = true;
			} else {
				daysToAdd++;
			}
		}
		const firstDate = moment(fromDate).add((daysToAdd % 7), 'days').toDate();
		return firstDate;
	}

	public getRecurrenceServerObject(recurrence: RecurrenceFormData, startDate: Date, isRecurrence: boolean): Recurrence {
		let recurrenceServerObject: Recurrence = {
			isRecurrence: isRecurrence,
			recurrenceTitle: isRecurrence ? '' : 'None'
		};
        
		if (!isRecurrence) {
			return recurrenceServerObject;
		}
        
		const momentDate = moment.utc(startDate);
		const startDateString = momentDate.toISOString();
        
		if (recurrence.frequency == 1 && recurrence.timeUnit == RecurrenceType.Week && recurrence.weekdays.length == 7) {
			recurrence.timeUnit = RecurrenceType.Day;
		}

		const recurrenceType: RecurrenceType = recurrence.timeUnit;
		const endingType: EndingType = recurrence.endingType;
		let recurrenceRange: RecurrenceRange;
		switch (endingType) {
			case EndingType.After:
				const afterRange: AfterOccurrencesRange = {
					type: 'OccurrencesType',
					numberOfOccurrences: recurrence.endingAfterOccurrences
				};
				recurrenceRange = afterRange;
				break;
			case EndingType.By:
				const endingByRange: EndDateRange = {
					type: 'EndDateType',
					endDate: moment(recurrence.endingBy).format('DD/MM/YYYY')
				};
				recurrenceRange = endingByRange;
				break;
			default:
				const neverRange: EndDateRange = {
					type: 'EndDateType',
					endDate: moment(new Date(2050, 0, 1)).format('DD/MM/YYYY')
				};
				recurrenceRange = neverRange;
				break;
		}

		switch (recurrenceType) {
			case RecurrenceType.Day:
				const dailyRecurrence: DailyRecurrence = {
					recurRange: recurrenceRange,
					days: recurrence.frequency.toString(),
					dailyType: 'Every x Days',
					displayInfo: 'Daily',
					type: 'Daily',
					startDate: startDateString,
					recurrenceText: 'Daily'
				};
				recurrenceServerObject.recurrenceTitle = 'Daily';
				recurrenceServerObject.recurrenceObject = dailyRecurrence;
				break;
			case RecurrenceType.Week:
				let selectedDays = {};
				for (let i = 1; i <= 7; i++) {
					selectedDays[i] = recurrence.weekdays.find(wd => wd == (i - 1)) !== undefined;
				}
                
				const weeklyRecurrence: WeeklyRecurrence = {
					recurRange: recurrenceRange,
					selectedDays: selectedDays,
					weeksFrequency: recurrence.frequency,
					displayInfo: 'Weekly',
					type: 'Weekly',
					startDate: startDateString,
					recurrenceText: 'Weekly'
				};
				recurrenceServerObject.recurrenceTitle = 'Weekly';
				recurrenceServerObject.recurrenceObject = weeklyRecurrence;
				break;
			case RecurrenceType.Month:
				let theDay: number, monthlyWeek: number, monthlyWeekDay: number;
				let monthlyType: string;
				const weekNumber = this.getWeekNumberByDate(startDate);
				switch (recurrence.monthSelection) {
					case MonthSelection.OnDateInMonth:
						theDay = momentDate.date();
						monthlyWeek = 0;
						monthlyWeekDay = momentDate.day();
						monthlyType = 'Day Every Month';
						break;
					case MonthSelection.OnDayInWeek:
						theDay = 0;
						monthlyWeek = weekNumber - 1;
						monthlyWeekDay = momentDate.day();
						monthlyType = 'Weekday Every Month';
						break;
					case MonthSelection.OnDayInLastWeek:
						theDay = 0;
						monthlyWeek = 4;
						monthlyWeekDay = momentDate.day();
						monthlyType = 'Weekday Every Month';
						break;
				}

				const monthlyRecurrence: MonthlyRecurrence = {
					recurRange: recurrenceRange,
					monthlyType: monthlyType,
					theDay: theDay,
					monthFrequency: recurrence.frequency,
					monthlyWeek: monthlyWeek,
					monthlyWeekDay: monthlyWeekDay,
					monthlyWeekFrequency: recurrence.frequency,
					displayInfo: 'Monthly',
					type: 'Monthly',
					startDate: startDateString,
					recurrenceText: 'Monthly'
				};
				recurrenceServerObject.recurrenceTitle = 'Monthly';
				recurrenceServerObject.recurrenceObject = monthlyRecurrence;
				break;
		}

		return recurrenceServerObject;
	}

	public getRecurrenceFormData(recurrenceWizardData: RecurrencePattern): RecurrenceFormData {
		const recurrenceFormData: RecurrenceFormData = {
			frequency: null,
			timeUnit: null,
			endingType: EndingType.Never,
			endingAfterOccurrences: null,
			endingBy: null,
			weekdays: null,
			monthSelection: null
		};

		switch(recurrenceWizardData.type) {
			case 'Daily':
				this.setRecurrenceDailyFormData(recurrenceFormData, recurrenceWizardData as DailyRecurrence);
				break;
			case 'Weekly':
				this.setRecurrenceWeeklyFormData(recurrenceFormData, recurrenceWizardData as WeeklyRecurrence);
				break;
			case 'Monthly':
				this.setRecurrenceMonthlyFormData(recurrenceFormData, recurrenceWizardData as MonthlyRecurrence);
				break;
		}
		this.setRecurrenceRangeFormData(recurrenceFormData, recurrenceWizardData);
        
		return recurrenceFormData;
	}

	private setRecurrenceDailyFormData(recurrenceFormData: RecurrenceFormData, recurrenceWizardData: DailyRecurrence) {
		if (recurrenceWizardData.dailyType === 'Every weekday') {
			recurrenceFormData.timeUnit =  RecurrenceType.Week;
			recurrenceFormData.frequency = 1;
			recurrenceFormData.weekdays = [1,2,3,4,5];
		} else {
			recurrenceFormData.timeUnit = RecurrenceType.Day;
			recurrenceFormData.frequency = Number(recurrenceWizardData.days);
		}
	}

	private setRecurrenceWeeklyFormData(recurrenceFormData: RecurrenceFormData, recurrenceWizardData: WeeklyRecurrence) {
		recurrenceFormData.timeUnit =  RecurrenceType.Week;
		recurrenceFormData.frequency = recurrenceWizardData.weeksFrequency;
		recurrenceFormData.weekdays = Object.entries(recurrenceWizardData.selectedDays)
			.filter(([key, value]) => value)
			.map(([key, value]) => Number(key) - 1);
	}

	private setRecurrenceMonthlyFormData(recurrenceFormData: RecurrenceFormData, recurrenceWizardData: MonthlyRecurrence) {
		recurrenceFormData.timeUnit = RecurrenceType.Month;
		if (recurrenceWizardData.theDay === 0) {
			if (recurrenceWizardData.monthlyWeek === 4) {
				recurrenceFormData.frequency = recurrenceWizardData.monthlyWeekFrequency;
				recurrenceFormData.monthSelection = MonthSelection.OnDayInLastWeek;
			} else {
				recurrenceFormData.frequency = recurrenceWizardData.monthlyWeekFrequency;
				recurrenceFormData.monthSelection = MonthSelection.OnDayInWeek;
			}
		} else {
			recurrenceFormData.frequency = recurrenceWizardData.monthFrequency;
			recurrenceFormData.monthSelection = MonthSelection.OnDateInMonth;
		}
	}

	private setRecurrenceRangeFormData(recurrenceFormData: RecurrenceFormData, recurrenceWizardData: RecurrencePattern) {
		switch(recurrenceWizardData.recurRange.type) {
			case 'OccurrencesType':
				recurrenceFormData.endingType = EndingType.After;
				recurrenceFormData.endingAfterOccurrences = (recurrenceWizardData.recurRange as AfterOccurrencesRange).numberOfOccurrences;
				break;
			case 'EndDateType':
				const momentEndDate = moment((recurrenceWizardData.recurRange as EndDateRange).endDate, 'DD/MM/YYYY');
				if (momentEndDate.year() == 2050) {
					recurrenceFormData.endingType = EndingType.Never;
				} else {
					recurrenceFormData.endingType = EndingType.By;
					recurrenceFormData.endingBy = momentEndDate.toDate() ;
				}
				break;
			default:
				recurrenceFormData.endingType = EndingType.Never;
				break;
		}
	}
}
