import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule
    ],
})
export class SharedModule{}