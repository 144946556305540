<div class="panel-heading" *ngIf="headerAttributeMetric">
    <div class="first-attribute">
        <h2 data-qa-id="HeaderAttribtueMetric">{{headerAttributeMetric.TitleText}}: {{headerAttributeMetric.MetricValue}}</h2>
        <setup-gear-button 
            data-qa-id="SetupButton"
            [defaultAttributeAliasName]="headerAttributeMetric.TitleText"
            (onChangeAttribute)="onFirstAttributeChanged($event)">
        </setup-gear-button>
    </div>
</div>
<div class="metrics">
    <div class="metric">
        <h4 data-qa-id="ConsentStatus">{{ translateKeys.CONSENT_STATUS | translate }}</h4>
        <h2 *ngIf="!useConsentFF" class="consent-mock">—</h2>
        <div *ngIf="useConsentFF" class="consent-link">
            <button 
                data-qa-id="ConsentHistoryButton" 
                type="button" 
                class="consent-history" 
                (click)="isPrefCenterEnabled ? openPreferenceHistoryModal() : openOldConsentModal()"
                [disabled]="!(customerId && clientCustomerId)">
                    {{(isPrefCenterEnabled ? translateKeys.VIEW_PREF_HISTORY : translateKeys.VIEW_CONSENT_HISTORY) | translate }}
            </button>
        </div>
    </div>
    <opti-metric
        *ngFor="let metric of metrics"
        data-qa-id="SummaryMetric"
        class="metric"
        [titleText]="metric.TitleText"
        [metricValue]="metric.MetricValue"
        [titleTooltip]="metric.TitleTooltip"
        [metricClass]="'h2'"
        [valueNumberOfLines]="1">
    </opti-metric>
</div>
