<div class="edit-base-attribute">
    <div class="panel-heading">
        <div class="header">
            <div class="back-button">
                <div class="btn btn-link" (click)="openConfirmModal()">
                    <i class="material-icons">arrow_back</i>
                    <span translate>{{'general.BACK' | translate}}</span>
                </div>
            </div>
            <div class="published-title">
                <h2> 
                    {{ translateKeys.editTitle | translate }}
                </h2> 
                <colored-tag [coloredTagType]="attributeStatus.color" style="padding: 7px;">
                    {{ attributeStatus.description }}
                </colored-tag>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <form [formGroup]="editAttributeForm" class="form-container">
            <div class="form-group">
                <div class="row">
                    <div class="label-column">
                        {{ translateKeys.displayName | translate }}
                    </div>
                    <div>
                        <input 
                            [ngClass]="{'errorBorder': error.displayName}"
                            class="form-control value-column"
                            type="text" 
                            formControlName="displayName" />
                        <p  *ngIf="error.displayName"
                            class="inputs-validations error-msg">
                            {{translateKeys.errorMsg | translate}}
                        </p>
                    </div>   
                </div>
                <div class="row">
                    <div class="label-column">
                        {{translateKeys.description | translate}}
                    </div>
                    <div>
                        <textarea  
                            class="form-control value-column"
                            type="text"
                            formControlName="description" 
                            placeholder="(Optional)">
                        </textarea>            
                    </div>
                </div>
            </div>
            <attribute-settings 
                class="attribute-settings"
                (chengePersonalizationEvent)="changePersonalization($event)" 
                (changeAttributeVisibilityEvent)="changeAttributeVisibility($event)"
                [isPersonalization]="attribute.IsPersonalization"
                [showAttribute]="!attribute.IsHidden"
                [disableShowHideCheckbox]="!canAttributeBeHidden"
                *ngIf="isPublished()">
            </attribute-settings> 
        </form>
    </div>
</div>
<div class="panel-footer panel-footer-bottom-stick footer-btns">
    <div class="last-modified-stamp">
        <label class="small">{{lastUpdate}}</label>
    </div>
    <div class="controls col-md-6 pull-right text-right">
        <ul class="list-inline">
            <li>
                <div class="btn btn-default" (click)="openConfirmModal()">
                    <span>Cancel</span>
                </div>
            </li>
            <li>
                <div data-qa-id="update-button">
                    <loading-button
                            [showLoader]="isSaving"
                            (onClick)="saveAttributeClick()"
                            [isDisabled]="(!editAttributeForm.valid || !editAttributeForm.dirty && !IsPersonalizationCheckBoxChecked && !isAttributeVisibilityChanged)"
                            [buttonCssClass]="'btn btn-primary'"
                            [loadingCssClass]="'saving-loader'"
                            [loadingText]="'Updating'"
                            [buttonText]="'Update'">
                    </loading-button>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="saving-process-disable-inputs-overlay" *ngIf="isSaving"></div>

