<div class="template-modal-layout">
    <button class="btn-link btn-close" (click)="closeModal()">
        <i class="material-icons">close</i>
    </button>
    
    <div class="modal-container">
        <ng-container [ngSwitch]="templateModalData.type">
            <templates-preview-carousel-modal *ngSwitchCase="templateModalTypes.Carousel" 
                [templates]="templateModalData.templates"
                [displayTemplateIndex]="templateModalData.displayTemplateIndex"
                (showTemplatesGridViewModal)="onShowTemplatesGridViewModal()">
            </templates-preview-carousel-modal>
        
            <templates-grid-view-modal *ngSwitchCase="templateModalTypes.Grid"
                [templates]="templateModalData.templates"
                (showTemplatesCarouselModal)="onShowTemplatesCarouselModal($event)">
            </templates-grid-view-modal>
        </ng-container>
    </div>
</div>