<div class="settings-webhook-configuration" data-qa-id="cysetting-webhook-configuration">
    <div class="panel-heading">
        <h2>{{ 'features.user_settings.body.webhookConfiguration.TITLE' | translate}}</h2>
        <button class="btn btn-link" (click)="addOrUpdateWebhook()" *ngIf="!emptyState" data-qa-id="cyAddNewWebhookButton">
            {{ 'features.user_settings.body.webhookConfiguration.emptyState.addLink' | translate}}
        </button>
    </div>
    <div class="panel-body">
        <ag-grid-angular *ngIf="gridOptions && !emptyState"
                         [gridOptions]="gridOptions"
                         [modules]="modules"
                         [rowData]="rowData"
                         data-qa-id="cyWebhookAgGrid"
                         class="ag-theme-balham ag-theme-white">
        </ag-grid-angular>
        <webhook-configuration-empty-state *ngIf="emptyState"></webhook-configuration-empty-state>
    </div>
    <opti-loading [show]="loading" [size]="'md'" ></opti-loading>
</div>