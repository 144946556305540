import {
    Component, Input, Output, EventEmitter, OnInit
, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'loading-button',
    templateUrl: './loadingButton.component.html',
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./loadingButton.component.scss']
})

export class LoadingButtonComponent implements OnInit{

    constructor() {
    }

    @Input() showLoader: boolean;
    @Input() loadingText: string;
    @Input() buttonText: string;
	@Input() isDisabled: boolean;
	@Input() buttonCssClass: string;
	@Input() loadingCssClass: string;
	@Output() onClick = new EventEmitter<void>();

    ngOnInit(): void {
        this.loadingText = this.loadingText || 'Loading';
    }


}