import { InjectionToken } from "@angular/core";
import { Routes } from "@angular/router";

export class AppConstant {
    public static DATE_FORMATS = {
        display: "DD MMM YYYY",
        server: "YYYY-MM-DD",
        month: "MMMM YYYY",
        fullDisplay: "DD MMMM YYYY",
        noTimeZone: "YYYY-MM-DDTHH:mm:ss",
        noTimeZoneStartDay: "YYYY-MM-DDT00:00:00",
        query_param: "YYYY/MM/DD",
        weekDay: "dddd",
        dayWithWeek: "DD MMM YYYY, dddd"
    };
    public static NUMBER_FORMATS = {
        number: "0,",
        percentage: '0%'
    }
}


export const PLATFORM_ROUTES = new InjectionToken<Routes>('Platform routes for module federation');