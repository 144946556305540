import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IModalConf } from "../../../../../components/optiLogicModal/optiLogicModal.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BaseAttribute,  CalculatedAttribute } from "../../attributesList.model";
import { FormControl, FormGroup } from "@angular/forms";


@Component({
    selector: 'select-calculated-attribute',
    templateUrl: './selectAttributePopup.component.html',
    styleUrls: ['./selectAttributePopup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SelectAttributePopupComponent implements OnInit {
    @Input() source: (BaseAttribute | CalculatedAttribute)[];
    @Input() index: number;
    @Output() handleSelect: any;
    @Output() handleClose: any;
    public modalConf: IModalConf;
    public translateKeys = translateKeys;
    public selectedAttribute: BaseAttribute | CalculatedAttribute;
    public attributes: (BaseAttribute | CalculatedAttribute)[] = [];

    constructor(
        private bsModalRef: BsModalRef,
        private translateService: TranslateService) {}

    ngOnInit() {
        this.setModalConf();
        this.attributes = this.source;
    }

    setModalConf(): void {
        this.modalConf = {
            title: this.translateService.instant(translateKeys.selectAttribute.title),
            // xButton: {
            //     action: () => this.close()
            // },
            buttons:[
                {
                    isDisabled: () => {
                        return !this.selectedAttribute;
                    },
                    class: 'btn btn-primary',
                    label: 'Apply',
                    action: this.selectClick.bind(this)
                },
                {
                    isDisabled: () => {
                        return false;
                    },
                    class: 'btn-default',
                    label: 'Cancel',
                    action: this.closeClick.bind(this)
                }
            ],
            hideFooterBorder: false,
            hideFooterPanel: false,
            hideHeaderBorder: false,
            
        };
    }

    public selectClick() {
        if (this.selectedAttribute) {
            this.handleSelect(this.selectedAttribute, this.index);
            this.close();
        }
    }
    public closeClick() {
        this.handleClose();
        this.close();
    }

    public close() {
        this.bsModalRef.hide();
    }

    public onSelect(event: Event) {
        const attributeDisplayName = (event.target as HTMLButtonElement).innerText.replace(/\s/g,'').toLowerCase();
        this.selectedAttribute = this.source.find(x => x.DisplayName.replace(/\s/g,'').toLowerCase() === attributeDisplayName);
    }

    public onFilter(filterText: string) {
        this.attributes = this.source.filter(attribute => !!filterText 
            ? attribute.DisplayName.toLowerCase().indexOf(filterText.toLowerCase()) > -1 
            : true);
    }
}

const translateKeys = {
    selectAttribute: {
        title: "features.user_settings.body.attributes.popup.selectAttribute.TITLE",
        inputPlaceholder: "features.user_settings.body.attributes.popup.selectAttribute.INPUT_PLACEHOLDER"
    }
}
